import {MessageService} from './base/message.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Service} from './base/service';
import { UsuarioEntity } from '../entities/base/BaseEntities';


import { Loggers } from '../util/app-util';
@Injectable({
    providedIn: 'root'
})
export class UsuarioService extends Service<UsuarioEntity> {

    httpClient: HttpClient;
    currentUser: UsuarioEntity;

    constructor(
        http: HttpClient,
        messageService: MessageService,
        protected logger: Loggers
    ) {
        super('UsuarioService', 'usuario', http, messageService, logger);
        this.httpClient = http;
    }

    protected getParams(entity: UsuarioEntity): HttpParams {
        let params: HttpParams = new HttpParams();
        if (null != entity.UsuarioId) { params = params.set('UsuarioId', entity.UsuarioId.toString()); }
        if (null != entity.Login) { params = params.set('Login', entity.Login.toString()); }
        if (null != entity.Nombre) { params = params.set('Nombre', entity.Nombre.toString()); }
        if (null != entity.Email) { params = params.set('Email', entity.Email.toString()); }
        if (null != entity.InstitucionId) { params = params.set('InstitucionId', entity.InstitucionId.toString()); }
        if (null != entity.Password) { params = params.set('Password', entity.Password.toString()); }
        if (null != entity.Activo) { params = params.set('Activo', entity.Activo.toString()); }
        if (null != entity.InstitucionPatmirId) { params = params.set('InstitucionPatmirId', entity.InstitucionPatmirId.toString()); }
        if (null != entity.flag_busqueda) { params = params.set('flag_busqueda', entity.flag_busqueda.toString()); }
        if (null != entity.perfilid) { params = params.set('perfilid', entity.perfilid.toString()); }
        if (null != entity.Id_AT) { params = params.set('Id_AT', entity.Id_AT.toString()); }

        return params;
    }

    public getCurrentUser(): Observable<any> {
        this.usuario_prueba = '?usuario_prueba=' + this.usuario_prueba;
        if (!this.currentUser) {
            return this.httpClient.get<UsuarioEntity>(this.serviceUrl + '/current' + this.usuario_prueba).pipe(
                tap(usuario => {
                    this.logger.log(`UsuarioService: fetched ${usuario}`, false);
                    this.currentUser = usuario;
                }),
                catchError(this.handleError('get', new UsuarioEntity()))
            );
        }
        return of(this.currentUser);
    }
}
