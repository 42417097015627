<div class="w3-container w3-contenedor-template " style="height: 7vh;">
  <div class="w3-row ">
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
      </div>
    </div>

    <!-- BORRAR FILTROS-->
    <div class="w3-rest">
      <div class="w3-right">
        <button mat-icon-button (click)="quitar_filtros()">
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
            <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
    </div>
    <!-- FIN BORRAR FILTROS-->

  </div>
</div>

<!-- Inicia Template 1 -->
<div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">
  <div class="w3-row w3-contenedor-template " style="height: 41vh;">
    <div class="w3-row w3-white" style="height: 100%;">
      <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">

        <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido">Cuentas / Sucursal</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
          </div>
        </div>

        <div class="w3-third w3-right-align_rest ">
          <div class="w3-right hijoderechaTitle">
            <!--<mat-icon *ngIf="showDataLabelActiveT1C1" class="material-icons-outlined" (click)="fvisibility_t1legend1()"
              [ngStyle]="{'color':'gray'}">
              label
            </mat-icon>
            <mat-icon *ngIf="showDataLabelInactiveT1C1" class="material-icons-outlined"
              (click)="fvisibility_t1legend1()" [ngStyle]="{'color':'gray'}">
              label_off
            </mat-icon>-->
            <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
              more_vert</mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
              <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
              <span>Gráficas</span>
            </button>
            <!--<button *ngIf="flag_inspeccionar" (click)="inspeccionar()" class="mat-menu-item" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}">search</mat-icon>
              <span>Inspeccionar</span>
            </button>-->
            <mat-divider></mat-divider>
            <button *ngIf="flag_perspectivas" class="mat-menu-item " mat-menu-item [matMenuTriggerFor]="perspectivas">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
              </mat-icon>
              <span>Perspectiva</span>
            </button>
            <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
              </mat-icon>
              <span>Exportar</span>
            </button>
            <button (click)="modal('id')" class="mat-menu-item" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
              <span>Ampliar</span>
            </button>
            <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" class="mat-menu-item " mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}"><img style="margin-top: -10px;"
                  src="assets/img/graficos/sinfiltro.png"></mat-icon>
              <span>Eliminar Filtros</span>
            </button>
            <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" mat-menu-item>
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                arrow_back
              </mat-icon>
              <span>Regresar</span>
            </button>
            <!--<button *ngIf="mostrandomeses" class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="cambiarmes">
                      <span>Cambiar mes</span>
                      </button>-->
          </mat-menu>
          <mat-menu #graficas="matMenu">
            <button (click)="cambiarGrafica('barras')" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
              <span>Barras</span>
            </button>
            <button (click)="cambiarGrafica('lineal')" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
              <span>Lineal</span>
            </button>
            <button (click)="cambiarGrafica('pastel')" mat-menu-item>
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
              <span>Pastel</span>
            </button>
            <button (click)="cambiarGrafica('tabla')" mat-menu-item>
              <mat-icon><img style="margin-top: -15px;" src="assets/img/elementotemplate/table.png"></mat-icon>
              <span>Tabla</span>
            </button>
          </mat-menu>
          <mat-menu #exportar="matMenu">
            <!--<button
              [ngClass]="{'w3-icon-visibility w3-hide':tabla_informacion1,'w3-icon-visibility w3-show-inline-block':!tabla_informacion1}"
              (click)="ExportarPDFT1_C1()" mat-menu-item>
              <span>PDF</span>
            </button>

            <button *ngIf="flag_inspeccionar" (click)="export_csv()" mat-menu-item>
              <span>CSV</span>
            </button>-->
          </mat-menu>
          <mat-menu #perspectivas="matMenu">
            <button *ngIf="isNotActiveFilter('bandas de morosidad')" class="" (click)="addPerspectiva('bandas de morosidad')" mat-menu-item>
              <span>Bandas de morosidad</span>
            </button>
            <button *ngIf="isNotActiveFilter('estatus')" (click)="addPerspectiva('estatus')" mat-menu-item>
              <span>Estatus</span>
            </button>
            <!--
                      <button class="" (click)="ActividadT2C1()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Actividad/Ocupación</span>
                      </button>
                      <button class="" (click)="EstadoT2C1()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Estado </span>
                      </button>
                      <button class="" (click)="EstadoCivilT2C1()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Estado Civil</span>
                      </button>
                      <button class="" (click)="GeneroT2C1()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Género</span>
                      </button>
                      <button class="" (click)="LocalidadT2C1()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Localidad</span>
                      </button>
                      <button class="" (click)="MunicipioT2C1()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Municipio</span>
                      </button>            
                      <button class="" (click)="RangoEdadT2C1()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Rango Edad</span>
                      </button>
                    -->
          </mat-menu>
          <mat-menu #geografico="matMenu">
            <button class="" (click)="addPerspectiva('geografico_estado')" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
              <span>Estado </span>
            </button>
            <button class="" (click)="addPerspectiva('geografico_municipio')" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
              <span>Municipio</span>
            </button>
            <button class="" (click)="addPerspectiva('geografico_localidad')" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
              <span>Localidad</span>
            </button>
          </mat-menu>
        </div>

      </div>
      <div class="w3-row" style="height: 25vh;width: 100%;">
        <!--<div class="w3-row" style="height: 32vh;width: 99%;"  *ngIf="chartvertical" id="chartvertical">
              <ngx-charts-bar-vertical class="w3-labels-ngx-charts"  style="width: 99%;height: 96%;"-->
        <div class="chart_wrap w3-scroll">
          <ngx-charts-bar-vertical 
            id="chartvertical" 
            class="bar-half-google w3-labels-ngx-charts"
            style="width: 99%;height: 20vh;"
            [scheme]="colorScheme" 
            [results]="datosBarra" 
            [xAxis]="showXAxis"
            [yAxis]="showYAxis" 
            [legend]="showLegend" 
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" 
            [xAxisLabel]="xAxisLabel" 
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="showGridLines" 
            (select)="click_grafica($event)" 
            [customColors]="customColors"
            [showDataLabel]="showDataLabelT1C1" 
            [dataLabelFormatting]="formatDataLabel" 
            [yScaleMax]="barScaleMax" 
            [yAxisTickFormatting]="yLeftTickFormat">
            <ng-template #tooltipTemplate let-model="model">
              <div class="tt">
                {{ model.extra.key_as_string }}
              </div>
          </ng-template>
          </ngx-charts-bar-vertical>
        </div>

      </div>
    </div>

    <div class="w3-row mt-2">

      <div class="w3-half w3-white">

        <div class="w3-row">

        </div>
  
        <div class="w3-row w3-white" style="height: 32vh;width: 100%; margin-bottom: 40px;">
          <div class="w3-row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
            <span class="w3-margin-left-contenido ">Cuentas / clasificación</span>
          </div>
          <div class="chart_wrap w3-scroll">
            <ngx-charts-area-chart id="chartverticalstacked_t31" style="width: 100%;height: 100%;"
              [scheme]="colorSchemeArea"
              [results]="data_lineal"
              [xAxis]="showXAxis_template2"
              [yAxis]="showYAxis_template2"
              [legend]="visibilitylegend1_t3"
              [showXAxisLabel]="showXAxisLabel_template2"
              [showYAxisLabel]="showYAxisLabel_template2"
              [xAxisLabel]="xAxisLabel_template2"
              [yAxisLabel]="yAxisLabel_template2"
              [animations]="animations_template2"
              (select)="click_graficastackedT3($event)"
              [showGridLines]="showGridLines"
              legendTitle=""
              [yAxisTickFormatting]="yLeftTickFormat"
              [legendPosition]="legendPositionverticalstackedt2">
            </ngx-charts-area-chart>
          </div>
        </div>

      </div>

      <div class="w3-half ml-2 " style="height: 32vh;">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh;">
          <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
            <div class="w3-row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
              <span class="w3-margin-left-contenido ">Cuentas / estatus</span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo ">Subtítulo</span>
              <span class="tooltiptext ">Subtítulo</span>
            </div>
          </div>
          <div class="w3-rest w3-right-align_rest ">
            <div class="w3-right hijoderechaTitle" style="z-index: 1;">
              <!--<mat-icon *ngIf="showDataLabelActiveT1C2" class=" material-icons-outlined w3-zindex"
                (click)="fshowDataLabelT1C2()" [ngStyle]="{'color':'gray'}">
                label
              </mat-icon>
              <mat-icon *ngIf="showDataLabelInactiveT1C2" class=" material-icons-outlined w3-zindex"
                (click)="fshowDataLabelT1C2()" [ngStyle]="{'color':'gray'}">
                label_off
              </mat-icon>
              <mat-icon *ngIf="piegrid2" class="w3-disabled material-icons-outlined w3-zindex"
                [ngStyle]="{'color':'gray'}">
                label_off
              </mat-icon>
              <mat-icon *ngIf="chartlineal2" class=" w3-disabled material-icons-outlined w3-zindex"
                [ngStyle]="{'color':'gray'}">
                label_off
              </mat-icon>-->
              <mat-icon class="w3-margin-right-contenido w3-zindex" [ngStyle]="{'color':'gray'}"
                [matMenuTriggerFor]="menu2">more_vert</mat-icon>
            </div>-
            <mat-menu #menu2="matMenu">
              <!--<button *ngIf="setFlagElementoSeleccionadoT1C2()" class="mat-menu-item" mat-menu-item
                [matMenuTriggerFor]="filtro2t1c2">
                <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
                </mat-icon>
                <span>Perspectiva</span>
              </button>-->
              <!--<button (click)="inspeccionar()" class="mat-menu-item w3-disabled" mat-menu-item>
                <mat-icon [ngStyle]="{'color':'gray'}">search</mat-icon>
                <span>Inspeccionar</span>
              </button>
              <mat-divider></mat-divider>-->
              <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar2">
                <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                <span>Exportar</span>
              </button>
              <button (click)="modal('tabla')" class="mat-menu-item" mat-menu-item>
                <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                <span>Ampliar</span>
              </button>
            </mat-menu>
            <!--<mat-menu #graficas2="matMenu">
                        <button class="" (click)="gbarras2()" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                        </button>
                        <button class="" (click)="glineal2()" mat-menu-item>
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                        </button>
                        <button class="w3-disabled" (click)="gpastel2()" mat-menu-item>
                        <mat-icon class="material-icons-outlined " [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                        <span>Pastel</span>
                        </button>
                        <button class="" (click)="gtabla2()" mat-menu-item>
                        <mat-icon><img style="margin-top: -15px;" src="assets/img/elementotemplate/table.png"></mat-icon>
                        <span>Tabla</span>
                        </button>
                    </mat-menu>-->
            <mat-menu #exportar2="matMenu">
              <button
                class="{w3-icon-visibility w3-show-inline-block" mat-menu-item>
                <span>PDF</span>
              </button>
              <button class=""mat-menu-item>
                <span>CSV</span>
              </button>
            </mat-menu>
            <mat-menu #filtro2t1c2="matMenu">
              <!--<button (click)="ClasificacionCartera()" mat-menu-item>
                <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                <span>Clasificación Cartera</span>
              </button>-->
              <!--<button (click)="ClasificacionCaptacion()" mat-menu-item>
                <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                <span>Clasificación Captación</span>
              </button>-->
              <!--<button class="w3-disabled" (click)="flocalidadt1c2()" mat-menu-item>
                        <span>Localidad</span>
                        </button>
                        <button class="w3-disabled" (click)="fmunicipiot1c2()" mat-menu-item>
                        <span>Municipio</span>
                        </button>            
                        <button class="w3-disabled" (click)="frangoedadt1c2()" mat-menu-item>
                        <span>Rango Edad</span>
                        </button>-->
              <button (click)="quitar_filtros()" mat-menu-item>
                <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                <span>Regresar</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="w3-row w3-white  " style="height: 32vh;">

          <div id="tabla_informacion2" class="w3-encabezado-table w3-scroll">
            <table class="w3-table-all ">
              <thead class="w3-zindex w3-light-grey">
                <tr class="w3-table-title w3-center w3-border ">
                  <th class="w3-center ">
                    <span class="w3-elemento-titulo-table">Estatus</span>
                  </th>
                  <th class="w3-center ">
                    <span class="w3-elemento-titulo-table">Conteo - no contrato</span>
                  </th>
                  <th class="w3-center ">
                    <span class="w3-elemento-titulo-table">Suma - cartera total</span>
                  </th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of table_data">
                  <td class=" w3-center">
                    <div class="w3-row">
                      <!--<div class="w3-twothird">-->
                      <div class="w3-left">
                        <span
                          class="w3-elemento-table w3-left">{{ formatearnormal(item.key) }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="w3-center">
                    <div>
                      <div class="w3-center">
                        <span class="w3-elemento-table">{{ formatearnormal(item.total_contratos.value) }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="w3-center">
                    <div>
                      <div class="w3-center">
                        <span class="w3-elemento-table">{{ formatearnormal(item.suma_carteratotal.value) }}</span>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr class="w3-table-title w3-center w3-border ">
                  <th class="w3-left">
                    <span class="w3-elemento-titulo-table">Total Resultado: </span>
                  </th>
                  <th class="w3-center ">
                    <span class="w3-elemento-titulo-table">{{ formatearnormal(getTotalNoContratos()) }}</span>
                  </th>
                  <th class="w3-center ">
                    <span class="w3-elemento-titulo-table">{{ formatearnormal(getTotalSumaCartera()) }}</span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>