import {MessageService} from './../../base/message.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from './../../base/service';
import {SocioClienteQubosEntity} from './../../../entities/base/DashboardEntities';
import {Loggers} from './../../../util/app-util';


@Injectable({
  providedIn: 'root'
})
export class SocioclientequbosService extends Service<SocioClienteQubosEntity> {

  httpClient: HttpClient;

  constructor(
      http: HttpClient,
      messageService: MessageService,
      protected logger: Loggers
  ) {
      super('PatmirService', 'dashboard/socioclientequbos', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: SocioClienteQubosEntity): HttpParams {
      let params: HttpParams = new HttpParams();
      if (null == entity) { return params; }
      if (null != entity.flagfecha) { params = params.set('flagfecha', entity.flagfecha.toString()); }
      if (null != entity.socioclienteid) { params = params.set('socioclienteid', entity.socioclienteid.toString()); }
      if (null != entity.fechacierre) { params = params.set('fechacierre', entity.fechacierre.toString()); }
      if (null != entity.anio) { params = params.set('anio', entity.anio.toString()); }
      if (null != entity.mes) { params = params.set('mes', entity.mes.toString()); }
      if (null != entity.ifid) { params = params.set('ifid', entity.ifid.toString()); }
      if (null != entity.instituciondesc) { params = params.set('instituciondesc', entity.instituciondesc.toString()); }
      if (null != entity.clavesociocliente) { params = params.set('clavesociocliente', entity.clavesociocliente.toString()); }
      if (null != entity.primerap) { params = params.set('primerap', entity.primerap.toString()); }
      if (null != entity.segundoap) { params = params.set('segundoap', entity.segundoap.toString()); }
      if (null != entity.nombre) { params = params.set('nombre', entity.nombre.toString()); }
      if (null != entity.sexoid) { params = params.set('sexoid', entity.sexoid.toString()); }
      if (null != entity.fechanac) { params = params.set('fechanac', entity.fechanac.toString()); }
      if (null != entity.lenguaid) { params = params.set('lenguaid', entity.lenguaid.toString()); }
      if (null != entity.ocupacionid) { params = params.set('ocupacionid', entity.ocupacionid.toString()); }
      if (null != entity.actividadid) { params = params.set('actividadid', entity.actividadid.toString()); }
      if (null != entity.estadocivilid) { params = params.set('estadocivilid', entity.estadocivilid.toString()); }
      if (null != entity.escolaridadid) { params = params.set('escolaridadid', entity.escolaridadid.toString()); }
      if (null != entity.fechaalta) { params = params.set('fechaalta', entity.fechaalta.toString()); }
      if (null != entity.calle) { params = params.set('calle', entity.calle.toString()); }
      if (null != entity.numeroexterior) { params = params.set('numeroexterior', entity.numeroexterior.toString()); }
      if (null != entity.numerointerior) { params = params.set('numerointerior', entity.numerointerior.toString()); }
      if (null != entity.colonia) { params = params.set('colonia', entity.colonia.toString()); }
      if (null != entity.codigopostal) { params = params.set('codigopostal', entity.codigopostal.toString()); }
      if (null != entity.localidadid) { params = params.set('localidadid', entity.localidadid.toString()); }
      if (null != entity.municipioid) { params = params.set('municipioid', entity.municipioid.toString()); }
      if (null != entity.estadoid) { params = params.set('estadoid', entity.estadoid.toString()); }
      if (null != entity.capitalsocialrequerido) { params = params.set('capitalsocialrequerido', entity.capitalsocialrequerido.toString()); }
      if (null != entity.saldodeaportacionrequerido) { params = params.set('saldodeaportacionrequerido', entity.saldodeaportacionrequerido.toString()); }
      if (null != entity.saldodeaportacionexcedente) { params = params.set('saldodeaportacionexcedente', entity.saldodeaportacionexcedente.toString()); }
      if (null != entity.saldodeaportacionvoluntario) { params = params.set('saldodeaportacionvoluntario', entity.saldodeaportacionvoluntario.toString()); }
      if (null != entity.sucursal) { params = params.set('sucursal', entity.sucursal.toString()); }
      if (null != entity.sucursaldesc) { params = params.set('sucursaldesc', entity.sucursaldesc.toString()); }
      if (null != entity.usuariocaptura) { params = params.set('usuariocaptura', entity.usuariocaptura.toString()); }
      if (null != entity.fechabaja) { params = params.set('fechabaja', entity.fechabaja.toString()); }
      if (null != entity.personamoral) { params = params.set('personamoral', entity.personamoral.toString()); }
      if (null != entity.telefono) { params = params.set('telefono', entity.telefono.toString()); }
      if (null != entity.estadosocio) { params = params.set('estadosocio', entity.estadosocio.toString()); }
      if (null != entity.longitud_loc) { params = params.set('longitud_loc', entity.longitud_loc.toString()); }
      if (null != entity.latitud_loc) { params = params.set('latitud_loc', entity.latitud_loc.toString()); }
      if (null != entity.clasificacion_nac) { params = params.set('clasificacion_nac', entity.clasificacion_nac.toString()); }
      if (null != entity.estadocivildesc) { params = params.set('estadocivildesc', entity.estadocivildesc.toString()); }
      if (null != entity.escolaridaddesc) { params = params.set('escolaridaddesc', entity.escolaridaddesc.toString()); }
      if (null != entity.localidaddesc) { params = params.set('localidaddesc', entity.localidaddesc.toString()); }
      if (null != entity.municipiodesc) { params = params.set('municipiodesc', entity.municipiodesc.toString()); }
      if (null != entity.ocupaciondesc) { params = params.set('ocupaciondesc', entity.ocupaciondesc.toString()); }
      if (null != entity.nopagina) { params = params.set('nopagina', entity.nopagina.toString()); }
      if (null != entity.norows) { params = params.set('norows', entity.norows.toString()); }
      if (null != entity.longitud_edo) { params = params.set('longitud_edo', entity.longitud_edo.toString()); }
      if (null != entity.latitud_edo) { params = params.set('latitud_edo', entity.latitud_edo.toString()); }
      if (null != entity.longitud_mun) { params = params.set('longitud_mun', entity.longitud_mun.toString()); }
      if (null != entity.latitud_mun) { params = params.set('latitud_mun', entity.latitud_mun.toString()); }
      if (null != entity.arreglo_anios) { params = params.set('arreglo_anios', entity.arreglo_anios.toString()); }
      if (null != entity.arreglo_mes) { params = params.set('arreglo_mes', entity.arreglo_mes.toString()); }
      if (null != entity.arreglo_dimension) { params = params.set('arreglo_dimension', entity.arreglo_dimension.toString()); }
      if (null != entity.estadodesc) { params = params.set('estadodesc', entity.estadodesc.toString()); }
      if (null != entity.arreglo_sucursal) { params = params.set('arreglo_sucursal', entity.arreglo_sucursal.toString()); }
      if (null != entity.todas_culumnas) { params = params.set('todas_columnas', entity.todas_culumnas.toString()); }
      
      return params;
  }
}
