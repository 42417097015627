import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EsqueletoPeticionModel} from '../../../model/esqueletoPeticion.model';
import {FiltrosService} from '../../filtros/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class MetaVsRealService {
  private apiURL = environment.apiDevURL;
  private apiURL2 = environment.apiDevURL_MR;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }

  public getInformacion(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    var peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-tendencia',
      'tendencia',
      'radiografia'
    );
    var valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    peticion.filter = {
        fechas: [{
          "campo": "fechaApertura",
          "valor": valoresMeses[0]
      },{
          campo: 'fechaArchivo',
          valor: valoresMeses[0]
        }]
      };
      peticion.groupby = {
        sucursales: {
          campo: "codSucCredito-sucursalCredito",
          subagrupacion: {
            operacion: "sumar",
            campo: "saldoCapital"
          }
        }
      } 
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL2}`, body, {headers: this.headers});
  }
  public getLogradoAdulto(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    var peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-tendencia',
      'tendencia',
      'radiografia_meta'
    );
    var valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    peticion.filter = {
      fechas: [{
        campo: 'fechaArchivo',
        valor: valoresMeses[0]
      }]
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL2}`, body, {headers: this.headers});
  }
  public getLogradoAdultoN(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    var peticion = new EsqueletoPeticionModel(
      'colocacion',
      'afiliacion-tendencia',
      'tendencia',
      'colocacion_sucursal'
    );
    var valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    peticion.filter = {
      fechas: [{
        campo: 'fechaArchivo',
        valor: valoresMeses[0]
      }]
    };
    peticion.groupby = {
      sucursales: {
          campo: "nombreSucursal",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroCredito"
          }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL2}`, body, {headers: this.headers});
  }
}
