<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <!-- TITULO DEL COMPONENTE -->
    <div class="w3-quarter padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> {{ dataService.currentModule }} </b></span><!--  -->
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span><!--  -->
      </div>
    </div>
    <!-- FIN TITULO DEL COMPONENTE -->

    <!-- FILTROS-->
    <div class="w3-rest ">
      <!-- DEFINICION FILTROS -->
      <div class="w3-noventa2 w3-row ">
        <!-- AÑOS -->
        <div class="w3-third padreizquierda">
          <!-- FILTRO AÑOS DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Año
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-medium"  #menuanios="matMenu" >
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                (change)="anioChangeListener(anio)">
                    {{anio.value}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO AÑOS DISPONIBLES -->

          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="anios.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let anios of aniosSeleccionados"
                            class="filtroMesAnioSeleccionado">
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>
        <!-- FIN AÑOS -->

        <!-- MESES -->
        <div class="w3-third">
          <!-- FILTRO MESES DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              Mes
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menumeses="matMenu">
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}"
                                (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                    {{mes.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO MESES DISPONIBLES -->

          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnmeses">
              <div class="w3-row chips" >
                <div [ngClass]="meses.check?
                      'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                      *ngFor="let meses of mesesSeleccionados"
                      class="filtroMesAnioSeleccionado">
                      <span class="contenidoChips">{{meses.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        <!-- FIN MESES -->

        <!-- Consolidado sucursal -->
        <div class="w3-third">
          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuentidad">
              <span>Sucursales</span>
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium"  #menuentidad="matMenu" >
              <button mat-menu-item>
                <mat-checkbox name="consolidado"
                              [checked]="consolidado"
                              (click)="$event.stopPropagation();"
                              (change)="consolidadoChangeListener()"
                              value="consolidado" aria-label="First checkbox"> <!---->
                  Consolidado
                </mat-checkbox>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="sucursales">Sucursales</button>
            </mat-menu>
            <mat-menu #sucursales="matMenu">
              <ng-container>
                <mat-form-field style="margin: 1vh; max-width: 20vh;" (click)="$event.stopPropagation();">
                  <mat-label>Buscar</mat-label>
                  <mat-icon matSuffix style="color: gray;">search</mat-icon>
                  <input matInput (keyup)="applyFilter($event)" type="text" autocomplete="off">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="sucursalesDisponibles2 !== undefined">
                <ng-container *ngFor="let sucursal of sucursalesDisponibles2.filteredData">
                  <button mat-menu-item *ngIf="sucursal.name != 'TOTALES'">
                    <mat-checkbox [checked]="sucursal.check"
                      (change)="sucursalChangeListener(sucursal)"
                      (click)="$event.stopPropagation();">
                      {{sucursal.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnmeses">
              <div class="w3-row chips" >
                <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu">
                  <span class="contenidoChips">{{sucursal_seleccionada}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        <!-- Fin consolidado sucursal -->

      </div>
      <!-- FIN DEFINICION FILTROS -->

      <!-- BORRAR FILTROS -->
      <div class="w3-rest w3-right ">
        <button mat-icon-button (click)="borrarFil()">
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
            <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
      <!-- FIN BORRAR FILTROS -->
    </div>
    <!-- FIN FILTROS-->
  </div>
</div>

<div class="w3-row w3-contenedor-template ">
  
  <!-- ZONA DE LA TABLA -->
  <div class="w3-row w3-container w3-contenedor-template" *ngIf="graficatabla" style="height: 85vh;">
    <div id="tabla" class="w3-threequarter">
      <div class="w3-row w3-white" style="width: 99%; height: 84vh;">
        <!--Parte superior-->
        <div class="w3-row">
          <!--Titulo-->
          <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas">Metas vs real</span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="Ampliar()">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                more_vert
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                  <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                  <span>Gráficas</span>
                </button>
                <mat-divider></mat-divider>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportar">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>exportar como</span>
                </button>
              </mat-menu>
              <mat-menu #exportar="matMenu">
                <button mat-menu-item (click)="export_excel()">
                  <span>CSV</span>
                </button>
              </mat-menu>
              <mat-menu #graficas="matMenu">
                <button mat-menu-item (click)="cambioGraf('barrasv')">
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Compuesta</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('barrash')">
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Barras</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('lineal')">
                  <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                  <span>Lineal</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('area')">
                  <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                  <span>Área</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('tabla')">
                  <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                  <span>tabla</span>
                </button>
              </mat-menu>
              <mat-menu #ampliar="matMenu">
                <button mat-menu-item (click)="Ampliar(1)">
                  <mat-icon [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                  <span>Parte de arriba</span>
                </button>
                <button mat-menu-item (click)="Ampliar(2)">
                  <mat-icon [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                  <span>Parte de abajo</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <!--Fin de la parte superior-->
        
        <!--Tabla-->
        <div class="contenedorCuadranteGraficas" style="height: 90%;">

          <div class="w3-row" style="height: 78vh; width: 99%;">
            <div class="w3-encabezado-table w3-scroll" >  
              <div class="w3-table-all w3-white">
                <div class="w3-row w3-white">
                  <table mat-table class=" w3-table w3-white">
                    <!-- Column -->
                    <ng-container matColumnDef="sucursal">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 17%; font-weight: bold;">  </th>
                    </ng-container>              
                    <!-- Column -->
                    <ng-container matColumnDef="proyectado">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="width: 70%; font-weight: bold;"> {{mesTabla(ult_mes[0])}}  </th>
                    </ng-container>
                    <!-- Column -->
                    <ng-container matColumnDef="real">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 13%; font-weight: bold;">   </th>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columsName3; sticky: true" class="w3-elemento-titulo-table"></tr>
                    <tr mat-row *matRowDef="let row; columns: columsName3;"></tr>
                  </table>
                  <table mat-table [dataSource]="dataTabla|| sortData($event)" matSort (matSortChange)="sortData($event)" class=" w3-table w3-white">
                    <!-- Column -->
                    <ng-container matColumnDef="sucursal" sticky>
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">SUCURSAL</span> </th>
                    <td mat-cell class="w3-center" style="width: 17%;" *matCellDef="let element, let i = index" [style]="textoC1(i)"> {{element.sucursal}} </td>
                    </ng-container>              
                    <!-- Column -->
                    <ng-container matColumnDef="proyectado">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">PROYECTADO</span> </th>
                    <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC2C5(i)"> $ {{formatearnormal(element.proyectado)}} </td>
                    </ng-container>
                    <!-- Column -->
                    <ng-container matColumnDef="real">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">REAL</span> </th>
                    <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC3C6(i)"> $ {{formatearnormal(element.real)}} </td>
                    </ng-container>
                    <!-- Column -->
                    <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">LOGRADO</span> </th>
                    <td mat-cell [class]="pintar_borde(element.logrado)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.logrado)}}% </td>
                    </ng-container>
                    <!-- Column -->
                    <ng-container matColumnDef="por_lograr">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">POR LOGRAR</span> </th>
                      <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC2C5(i)">$ {{formatearnormal(element.porLog)}} </td>
                      </ng-container>
                      <!-- Column -->
                    <ng-container matColumnDef="numero_cuentas">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">NÚMERO DE CRÉDITOS</span> </th>
                      <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC3C6(i)"> {{formatearnormal(element.Ncred)}} </td>
                      </ng-container>
                    <!-- Column -->
                    <ng-container matColumnDef="meta_anio">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">META DEL AÑO</span> </th>
                    <td mat-cell [class]="pintar_borde(element.meta)" class="w3-center" style="width: 13%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.meta)}}% </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columsName; sticky: true" class="w3-elemento-titulo-table"></tr>
                    <tr mat-row *matRowDef="let row; columns: columsName;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w3-rest">
      <div class="w3-row w3-right" style="width: 99%; height: 84vh;">
        <div class="w3-row" style="height: 15vh;"></div>

        <div style="height: 1vh;"></div>
        
        <div class="w3-row w3-white w3-margin-top" style="height: 15vh;">
          <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
            <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Cumplimiento del mes</span>
          </div>
          <div class="w3-half w3-right">
            <div class="w3-row w3-center" style="margin-top: 2vh;">
              <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">show_chart</mat-icon> 
              <span class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].logrado)}} %</span>
            </div>
          </div>
          <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
            <span class="w3-subtituloMeta">{{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
          </div>
        </div>

        <div style="height: 1vh;"></div>
        
        <div class="w3-row w3-white w3-margin-top" style="height: 15vh;">
          <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
            <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Cumplimiento mes anterior</span>
          </div>
          <div class="w3-half w3-right">
            <div class="w3-row w3-center" style="margin-top: 2vh;">
              <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">show_chart</mat-icon> 
              <span class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(cumpMesAnt)}} %</span>
            </div>
          </div>
          <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
            <span *ngIf="anioAnt !== undefined" class="w3-subtituloMeta">{{formatoMes(mesAnt)}} | {{formatoAnio(anioAnt[0])}}</span>
          </div>
        </div>

        <div style="height: 1vh;"></div>
        
        <div class="w3-row w3-white w3-margin-top" style="height: 15vh;">
          <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
            <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Meta anual colocación</span>
          </div>
          <div class="w3-half w3-right">
            <div class="w3-row w3-center" style="margin-top: 2vh;">
              <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">flag</mat-icon> 
              <span class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].meta)}} %</span>
            </div>
          </div>
          <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
            <span class="w3-subtituloMeta">ene - {{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN DE LA ZONA DE LA TABLA -->

  <!-- ZONA DE LAS GRAFICAS -->
    <div class="w3-row w3-container w3-contenedor-template " *ngIf="!graficatabla" style="height: 85vh;" >
      <div id="graficas" class="w3-row w3-white " style="width: 99%; height: 67vh;">
        <!--Parte superior-->
        <div class="w3-row ">
          <!--Titulo-->
          <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas">Metas vs real</span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="Ampliar()">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                  <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                  <span>Gráficas</span>
                </button>
                <mat-divider></mat-divider>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu material-icons-outlined" [matMenuTriggerFor]="exportar">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>Exportar como</span>
                </button>
              </mat-menu>
              <mat-menu #exportar="matMenu">
                <button mat-menu-item (click)="export_imagen()">
                  <span>PNG</span>
                </button>
              </mat-menu>
              <mat-menu #graficas="matMenu">
                <button mat-menu-item (click)="cambioGraf('barrasv')">
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Compuesta</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('barrash')">
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Barras</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('lineal')">
                  <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                  <span>Lineal</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('area')">
                  <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                  <span>Área</span>
                </button>
                <button mat-menu-item (click)="cambioGraf('tabla')">
                  <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                  <span>tabla</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <!--Fin de la parte superior-->

        <div class="w3-row contenedorCuadranteGraficas" style="height: 90%;">
          <div class="w3-row" *ngIf="graficabarras" style="height: 95%; width: 98%;">
            <combo-chart-component
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [results]="databarrascom"
            [animations]="animations"
            [lineChart]="datalinealcom"
            [yAxisTickFormatting]="yLeftTickFormat"
            [yLeftAxisScaleFactor]="yLeftAxisScale"
            [yRightAxisScaleFactor]="yRightAxisScale"
            [yRightAxisTickFormatting]="yRightTickFormat"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel1"
            >
            </combo-chart-component>
          </div>
          <div class="w3-row" *ngIf="graficalineal" style="height: 95%; width: 98%;">
            <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="datalineal"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
          </div>
          <div class="w3-row" *ngIf="graficaarea" style="height: 95%; width: 98%;">
            <ngx-charts-area-chart
            [scheme]="colorScheme"
            [results]="datalineal"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-area-chart>
          </div>
          <div class="w3-row"  *ngIf="graficabarra" style="height: 95%; width: 98%;">
            <ngx-charts-bar-vertical-2d
            [scheme]="colorScheme"
            [results]="databarras"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [groupPadding]="groupPadding"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical-2d>
          </div>
        </div>
      </div>

    <div class="w3-row" style="height: 15vh; width: 99%;">
      
      <div class="w3-third w3-margin-top" style="height: 15vh;">
        <div class="w3-row w3-white" style="width: 96%; height: 15vh;">
          <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
            <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Cumplimiento del mes</span>
          </div>
          <div class="w3-half w3-right">
            <div class="w3-row w3-center" style="margin-top: 2vh;">
              <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">show_chart</mat-icon> 
              <span class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].logrado)}} %</span>
            </div>
          </div>
          <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
            <span class="w3-subtituloMeta">{{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
          </div>
        </div>
      </div>
      
      <div class="w3-third w3-margin-top" style="height: 15vh;">
        <div class="w3-row w3-white" style="width: 96%; height: 15vh;">
          <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
            <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Cumplimiento mes anterior</span>
          </div>
          <div class="w3-half w3-right">
            <div class="w3-row w3-center" style="margin-top: 2vh;">
              <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">show_chart</mat-icon> 
              <span class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(cumpMesAnt)}} %</span>
            </div>
          </div>
          <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
            <span class="w3-subtituloMeta">{{formatoMes(mesAnt)}} | {{formatoAnio(anioAnt)}}</span>
          </div>
        </div>
      </div>
      
      <div class="w3-rest w3-margin-top" style="height: 15vh;">
        <div class="w3-row w3-white" style="width: 96%; height: 15vh;">
          <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
            <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Meta anual colocación</span>
          </div>
          <div class="w3-half w3-right">
            <div class="w3-row w3-center" style="margin-top: 2vh;">
              <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">flag</mat-icon>
              <span class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].meta)}} %</span>
            </div>
          </div>
          <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
            <span class="w3-subtituloMeta">ene - {{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
          </div>
        </div>
      </div>

    </div>
  <!-- FIN DE LA ZONA DE GRAFICAS -->

</div>
<!-- FIN CONTENIDO DEL COMPONENTE -->

<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <!--Parte superior-->
              <div class="w3-row">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                  <div class="hijoizquierdaTitle">
                    <span class="w3-margin-left-contenido">Cumplimiento metas sucursales</span>
                    <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                    <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                  </div>
                </div>
              </div>
              <!--Fin de la parte superior-->
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <div class="w3-row" *ngIf="graficabarras_modal" style="height: 50vh;">
          <combo-chart-component
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [results]="databarrascom"
            [animations]="animations"
            [lineChart]="datalinealcom"
            [yAxisTickFormatting]="yLeftTickFormat"
            [yLeftAxisScaleFactor]="yLeftAxisScale"
            [yRightAxisScaleFactor]="yRightAxisScale"
            [yRightAxisTickFormatting]="yRightTickFormat"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel1">
            </combo-chart-component>
        </div>
        <div class="w3-row"  *ngIf="graficalineal_modal" style="height: 50vh;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="datalineal"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
        </div>
        <div class="w3-row"  *ngIf="graficaarea_modal" style="height: 50vh;">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [results]="datalineal"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-area-chart>
        </div>
        <div class="w3-row"  *ngIf="graficabarra_modal" style="height: 50vh;">
          <ngx-charts-bar-vertical-2d
          [scheme]="colorScheme"
          [results]="databarras"
          [xAxis]="showXAxis"
          [legend]="true"
          [legendTitle]="legendTitle"
          [legendPosition]="'right'"
          [noBarWhenZero]="false"
          [yAxis]="showYAxis"
          [groupPadding]="groupPadding"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-vertical-2d>
        </div>
        <div class="w3-row" *ngIf="graficatabla_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >  
            <div class="w3-table-all w3-white">
              <div class="w3-row w3-white">
                <table mat-table class=" w3-table w3-white">
                  <!-- Column -->
                  <ng-container matColumnDef="sucursal">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">  </th>
                  </ng-container>              
                  <!-- Column -->
                  <ng-container matColumnDef="proyectado">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="width: 60%; font-weight: bold;"> {{mesTabla(ult_mes[0])}}  </th>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="real">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">   </th>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columsName3; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columsName3;"></tr>
                </table>
                <table mat-table [dataSource]="dataTabla || sortData($event)" matSort (matSortChange)="sortData($event)" class=" w3-table w3-white">
                  <!-- Column -->
                  <ng-container matColumnDef="sucursal">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 60%;">SUCURSAL</span> </th>
                  <td mat-cell class="w3-center" style="width: 17%;" *matCellDef="let element, let i = index" [style]="textoC1(i)"> {{element.sucursal}} </td>
                  </ng-container>              
                  <!-- Column -->
                  <ng-container matColumnDef="proyectado">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">PROYECTADO</span> </th>
                  <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC2C5(i)"> $ {{formatearnormal(element.proyectado)}} </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="real">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">REAL</span> </th>
                  <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC3C6(i)"> $ {{formatearnormal(element.real)}} </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">LOGRADO</span> </th>
                  <td mat-cell [class]="pintar_borde(element.logrado)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.logrado)}}% </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="por_lograr">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">POR LOGRAR</span> </th>
                    <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC2C5(i)">$ {{formatearnormal(element.porLog)}} </td>
                    </ng-container>
                    <!-- Column -->
                  <ng-container matColumnDef="numero_cuentas">
                    <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">NÚMERO DE CRÉDITOS</span> </th>
                    <td mat-cell class="w3-center" style="width: 15%;" *matCellDef="let element, let i = index" [style]="textoC3C6(i)"> {{formatearnormal(element.Ncred)}} </td>
                    </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="meta_anio">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">META DEL AÑO</span> </th>
                  <td mat-cell [class]="pintar_borde(element.meta)" class="w3-center" style="width: 13%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.meta)}}% </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columsName; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columsName;"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- The Modal -->

