<div class="w3-container w3-contenedor-template  " style="height: 7vh;">
    <div class="w3-row ">
        <div class="w3-quarter padreizquierda ">
            <div class="w3-row hijoizquierda">
                <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
                <span class="w3-text-deep-orange">&bull;</span>
                <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
            </div>
        </div>

        <!-- BORRAR FILTROS-->
        <div class="w3-rest ">
          <div class="w3-noventa2 w3-row ">
            <!-- anios-->
            <div class="w3-third padreizquierda">
              <div class="w3-quarter_e hijoizquierda  ">
                Año
                <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
                  <mat-icon class="w3-tiny">expand_more</mat-icon>
                </button>
                <!--
                  Se contruye el menu de años en relación a la información que tiene la entidad
                  Composición del json:
                  arreglo_anios : any [] = [
                        {"name":"2018","value":"2018","check":true},
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];

                  
                -->
                  <mat-menu class="w3-tiny"  #menuanios="matMenu" >
                    <ng-container *ngFor="let anio of arreglo_anios">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                          value="{{anio.name}}" aria-label="First checkbox"
                          (change)="event_anio(anio.name)">
                          {{anio.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container> 
                  </mat-menu>
              </div>
              <div class="w3-rest  ">
                <div class="w3-row padreizquierda" id="contenedor_btnanios">
                  <div class="w3-row hijoizquierda ">
                    <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':anios.check,'w3-hide ':!anios.check}"
                            *ngFor="let anios of anios_seleccionados"
                            (click)="event_anio(anios.name)"
                            style="height: 20px;width: 42px;margin-left: 10px;">
                            {{anios.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- meses -->
            <div [class]="VerificarAniosSeleccionados()" >
              <div class="w3-quarter_e hijoizquierda  ">
                Mes
                <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
                  <mat-icon class="w3-tiny">expand_more</mat-icon>
                </button>
                <!--
                  Se contruye el menu de años en relación a la información que tiene la entidad
                  Composición del json:
                  arreglo_anios : any [] = [
                        {"name":"2018","value":"2018","check":true},
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];

                  
                -->
                  <mat-menu class="w3-tiny"  #menumeses="matMenu" >
                    <ng-container *ngFor="let mes of arreglo_meses">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                          value="{{mes.name}}" aria-label="First checkbox"
                          (change)="event_mes(mes.name)">
                          {{mes.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container> 
                  </mat-menu>
              </div>
              <div class="w3-rest  ">
                <div class="w3-row padreizquierda" id="contenedor_btnmeses">
                  <div [class]="ContarMeses()" >
                      <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':meses.check,'w3-hide ':!meses.check}"
                              *ngFor="let meses of meses_seleccionados"
                              (click)="event_mes(meses.name)"
                              style="height: 20px;width: 42px;margin-left: 10px;">
                              {{meses.name}}
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- consolidado sucursal -->
            <div class="w3-third">
              <div class="w3-half hijoizquierda  ">
                Entidad
                <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuentidad" aria-label="Example icon-button with a menu">
                  <mat-icon class="w3-tiny">expand_more</mat-icon>
                </button>
                <!--
                  Se contruye el menu de años en relación a la información que tiene la entidad
                  Composición del json:
                  arreglo_anios : any [] = [
                        {"name":"2018","value":"2018","check":true},
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];
                -->
                  <mat-menu class="w3-tiny"  #menuentidad="matMenu" >
                    <button mat-menu-item>
                      <mat-checkbox name="consolidado" [checked]="ent_consolidado"
                        value="consolidado" aria-label="First checkbox" (change)="event_consolidado()">
                        Consolidado
                      </mat-checkbox>
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="sucursales">Sucursales</button>
                  </mat-menu>
                  <mat-menu #sucursales="matMenu">
                    <ng-container *ngFor="let sucursal of data_sucursales">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="sucursal.check" name="{{sucursal.name}}"
                          value="{{sucursal.name}}" aria-label="First checkbox"
                          (change)="event_sucursal(sucursal.name)">
                          {{sucursal.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
              </div>
            </div>
          </div>
          <!-- quitar filtros -->
          <div class="w3-rest w3-right ">
              <button mat-icon-button (click)="quitar_filtros()">
                  <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                      <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
                  </mat-icon>
              </button>
          </div>
        </div>
        <!-- FIN BORRAR FILTROS-->

    </div>
</div>

<div id="cont_template1" class="w3-container w3-contenedor-template  " style="height: 83vh;">
    <div class="w3-row w3-contenedor-template  " style="height: 82vh;">
        <div class="w3-row w3-white" style="height: 100%;">
            <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
                <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido">Tendencia</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <!--<span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>-->
                    </div>
                </div>
                <div class="w3-third w3-right-align_rest ">       
                    <div class="w3-right hijoderechaTitle">
                      <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                        more_vert</mat-icon>
                    </div>
                    <mat-menu #menu="matMenu">
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                            <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                            <span>Gráficas</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button *ngIf="flag_perspectivas" class="mat-menu-item " mat-menu-item [matMenuTriggerFor]="perspectivas">
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
                            </mat-icon>
                            <span>Perspectiva</span>
                        </button>
                        <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
                            </mat-icon>
                            <span>Exportar</span>
                        </button>
                        <button (click)="modal('id')" class="mat-menu-item" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                            <span>Ampliar</span>
                        </button>
                        <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" class="mat-menu-item " mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"><img style="margin-top: -10px;"
                                src="assets/img/graficos/sinfiltro.png"></mat-icon>
                            <span>Eliminar Filtros</span>
                        </button>
                        <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" mat-menu-item>
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                            arrow_back
                            </mat-icon>
                            <span>Regresar</span>
                        </button>
                    </mat-menu>
                    <mat-menu #graficas="matMenu">
                    <button (click)="cambiarGrafica('barras')" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                    </button>
                    <button (click)="cambiarGrafica('lineal')" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                    </button>
                    </mat-menu>
                    <mat-menu #exportar="matMenu">

                    </mat-menu>
                    <mat-menu #perspectivas="matMenu">
                    <button *ngIf="isNotActiveFilter('bandas de morosidad')" class="" (click)="addPerspectiva('bandas de morosidad')" mat-menu-item>
                        <span>Bandas de morosidad</span>
                    </button>
                    <button *ngIf="isNotActiveFilter('estatus')" (click)="addPerspectiva('estatus')" mat-menu-item>
                        <span>Estatus</span>
                    </button>
                    </mat-menu>
                    <mat-menu #geografico="matMenu">
                    <button class="" (click)="addPerspectiva('geografico_estado')" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Estado </span>
                    </button>
                    <button class="" (click)="addPerspectiva('geografico_municipio')" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Municipio</span>
                    </button>
                    <button class="" (click)="addPerspectiva('geografico_localidad')" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                        <span>Localidad</span>
                    </button>
                    </mat-menu>
                </div>
            </div>
            
            <div class="w3-row contendor_scroll" >
                <div class="w3-threequarter ">
                    <div id="tabla_informacion2" class="w3-encabezado-table w3-scroll  tableFixHead" >
                        <table mat-table [dataSource]="data_sucursales" class="mat-elevation-z8">
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                          
                            <!-- Position Column display none-->
                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">
                                Sucursal
                              </th>
                              <td class="" mat-cell *matCellDef="let element">{{element.name}}</td>
                              <td class="w3-right" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">TOTAL: </span><br>
                              </td>
                            </ng-container>
                          
                            <ng-container matColumnDef="Sucursal">
                              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">SUCURSAL</th>
                            </ng-container>
                          
                            <!-- Name Column -->
                            <ng-container matColumnDef="pro">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef>PROYECTADO</th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.pro}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">
                                  {{CalculoTotalElementos(1)}} 
                                </span><br>
                                <span class="w3-hide"></span><br>
                                <span class="w3-hide"></span><br>
                              </td>
                            </ng-container>
                          
                            <!-- Weight Column -->
                            <ng-container matColumnDef="reac">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef>REACTIVADOS</th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.reac}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">
                                  {{CalculoTotalElementos(2)}} 
                                </span><br>
                                <span class="w3-hide"></span><br>
                                <span class="w3-hide"></span><br>
                              </td>
                            </ng-container>
                          
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="so_new">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef>SOCIOS NUEVOS</th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.so_new}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">
                                  {{CalculoTotalElementos(3)}} 
                                </span><br>
                                <span class="w3-hide"></span><br>
                                <span class="w3-hide"></span><br>
                              </td>
                            </ng-container>
                            
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="to_ingre">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef>TOTAL INGRESOS</th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.to_ingre}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">
                                  {{CalculoTotalElementos(4)}} 
                                </span><br>
                                <span class="w3-hide"></span><br>
                                <span class="w3-hide"></span><br>
                              </td>
                            </ng-container>
                            <!-- Header row first group -->
                          
                            <!-- Header row second group -->
                            <ng-container matColumnDef="header-row-second-group">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef [attr.colspan]="4">INGRESOS O REACTIVACIONES</th>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">
                                  {{CalculoTotalElementos(5)}} 
                                </span><br>
                                <span class="w3-hide"></span><br>
                                <span class="w3-hide"></span><br>
                              </td>
                            </ng-container>
                          
                            <!-- Position Column display none-->
                            <ng-container matColumnDef="inac_mes">
                              <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">
                                camp
                              </th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.inac_mes}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">
                                  {{CalculoTotalElementos(5)}} 
                                </span><br>
                                <span class="w3-hide"></span><br>
                                <span class="w3-hide"></span><br>
                              </td>
                            </ng-container>
                          
                            <ng-container matColumnDef="to_ingree">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">INACTIVACIONES DEL MES</th>
                            </ng-container>
                          
                            <!-- Camps Column -->
                            <ng-container matColumnDef="proyectado">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef>PROYECTADO</th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.proyectado}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">{{CalculoTotalElementos(6)}}</span><br>
                                <span class="w3-tipografia-altas-bajas">INC. SO</span><br>
                                <span class="w3-tipografia-altas-bajas">INC. AS</span>
                              </td>
                            </ng-container>
                          
                            <!-- Segundo Column -->
                            <ng-container matColumnDef="logrado">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef>LOGRADO</th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.logrado}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">{{CalculoTotalElementos(7)}}</span><br>
                                <span class="w3-tipografia-altas-bajas">160</span><br>
                                <span class="w3-tipografia-altas-bajas">28</span>
                              </td>
                            </ng-container>
                          
                            <!-- Header row second group -->
                            <ng-container matColumnDef="header-row-tercer-group">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">INCREMENTO</th>
                            </ng-container>

                            <ng-container matColumnDef="men_adu">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef>MEN - ADU</th>
                              <td class="w3-center" mat-cell *matCellDef="let element">{{element.men_adu}}</td>
                              <td class="w3-center" mat-footer-cell *matFooterCellDef> 
                                <span class="w3-tipografia-altas-bajas">
                                  {{CalculoTotalElementos(8)}} 
                                </span><br>
                                <span class="w3-hide"></span><br>
                                <span class="w3-hide"></span><br>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="traspasos">
                              <th class="w3-center" mat-header-cell *matHeaderCellDef [attr.colspan]="1">TRASPASOS</th>
                            </ng-container>
                          
                            <tr class="w3-table-title"
                              mat-header-row
                              *matHeaderRowDef="['Sucursal', 'header-row-second-group','to_ingree','header-row-tercer-group','traspasos']; sticky: true">
                            </tr>
                            <tr class="w3-table-title" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            <tr class="w3-table-title" mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                        </table>

                    </div>
                </div>
                <div  class="w3-rest ">
                  <div class="w3-row padreAltasBajas " style="height: 20vh;">
                    <div class="w3-content hijoAltasBajas">
                      <table class="w3-table w3-content" style="width: 50%;">
                        <thead>
                          <tr>
                            <th class="w3-border-bottom">DEPURADOS</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr><td class="w3-center">COB. ADMON</td><td><span class="w3-tipografia-altas-bajas">100</span></td></tr>
                          <tr><td class="w3-center">PISA</td><td><span class="w3-tipografia-altas-bajas">100</span></td></tr>
                          <tr><td class="w3-center">JURIDICO</td><td><span class="w3-tipografia-altas-bajas">100</span></td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="w3-row padreAltasBajas " style="height: 20vh;">
                    <div class="w3-content hijoAltasBajas">
                      <table class="w3-table w3-content" style="width: 50%;">
                        <thead>
                          <tr>
                            <th class="w3-border-bottom w3-label-activoAltasBajas">
                              <span class="w3-tipografia-altas-bajas">CASTIGADOS</span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr><td class="w3-center">0</td><td></td></tr>
                          <tr><td colspan="2" class="w3-label-activoAltasBajas">
                            <span class="w3-tipografia-altas-bajas">DEFUNCIONES</span>
                          </td></tr>
                          <tr><td class="w3-center"><span class="w3-tipografia-altas-bajas">82</span></td><td>SO</td></tr>
                          <tr><td class="w3-center"><span class="w3-tipografia-altas-bajas">82</span></td><td>SA</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="w3-row padreAltasBajas" style="height: 20vh;">
                    <div class="w3-content hijoAltasBajas">
                      <div class="w3-content " style="width: 60%;height: 10vh;">
                        <div class="w3-twothird padreTendencia" style="height: 98%;">
                          <div class="hijo w3-center">
                            <span class="w3-tipografia-altas-bajas">TOTAL INACTIVACIONES</span>
                          </div>
                        </div>
                        <div class="w3-third w3-black w3-text-white w3-right padreTendencia " style="height: 98%;">
                          <div class="hijo w3-center">
                            622
                          </div>  
                        </div>

                      </div>
                      <!--<table class="w3-table w3-content" style="width: 50%;">
                        <thead>
                          <tr>
                            <th class="w3-border-bottom w3-border-top w3-border-right w3-center w3-label-activoAltasBajas">TOTAL INACTIVACIONES</th>
                            <th class="w3-black w3-text-white w3-right" style="line-height: 11pt;font-size:10pt;">622</th>
                          </tr>
                        </thead>
                      </table>-->
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>