import { Injectable } from '@angular/core';
import { DataRowOutlet } from '@angular/cdk/table';

export class ConstantesQubos  {
    constructor() {}
    public static strPATH = 'app';
    public static strDashboardPATH = 'app/dashboards';
    public static signosmatematicos = ['(',')','/','*','+','-','<','>',','];
}

export class CommonVariables {
    static get MesesArr() { return ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']; }

    static get MesesJson() { return [
        { mes: 'ene', value: 1 },
        { mes: 'feb', value: 2 },
        { mes: 'mar', value: 3 },
        { mes: 'abr', value: 4 },
        { mes: 'may', value: 5 },
        { mes: 'jun', value: 6 },
        { mes: 'jul', value: 7 },
        { mes: 'ago', value: 8 },
        { mes: 'sep', value: 9 },
        { mes: 'oct', value: 10 },
        { mes: 'nov', value: 11 },
        { mes: 'dic', value: 12 },
    ]; }
    static f_switchmes(mes){
        switch(mes){
            case '01': mes = 'ENERO';break;
            case '02': mes = 'FEBRERO';break;
            case '03': mes = 'MARZO';break;
            case '04': mes = 'ABRIL';break;
            case '05': mes = 'MAYO';break;
            case '06': mes = 'JUNIO';break;
            case '07': mes = 'JULIO';break;
            case '08': mes = 'AGOSTO';break;
            case '09': mes = 'SEPTIEMBRE';break;
            case '10': mes = 'OCTUBRE';break;
            case '11': mes = 'NOVIEMBRE';break;
            case '12': mes = 'DICIEMBRE';break;
        }
        return mes;
    }
    static f_switchmesA(mes){
        switch(mes){
            case '01': mes = 'Enero';break;
            case '02': mes = 'Febrero';break;
            case '03': mes = 'Marzo';break;
            case '04': mes = 'Abril';break;
            case '05': mes = 'Mayo';break;
            case '06': mes = 'Junio';break;
            case '07': mes = 'Julio';break;
            case '08': mes = 'Agosto';break;
            case '09': mes = 'Septiembre';break;
            case '10': mes = 'Octubre';break;
            case '11': mes = 'Noviembre';break;
            case '12': mes = 'Diciembre';break;
        }
        return mes;
    }
    static f_switchmesm(mes){
        switch(mes){
            case '01': mes = 'ene';break;
            case '02': mes = 'feb';break;
            case '03': mes = 'mar';break;
            case '04': mes = 'abr';break;
            case '05': mes = 'may';break;
            case '06': mes = 'jun';break;
            case '07': mes = 'jul';break;
            case '08': mes = 'ago';break;
            case '09': mes = 'sep';break;
            case '10': mes = 'oct';break;
            case '11': mes = 'nov';break;
            case '12': mes = 'dic';break;
        }
        return mes;
    }
    static f_switchmesEntero(mes){
        switch(mes){
            case '1': mes = 'ene';break;
            case '2': mes = 'feb';break;
            case '3': mes = 'mar';break;
            case '4': mes = 'abr';break;
            case '5': mes = 'may';break;
            case '6': mes = 'jun';break;
            case '7': mes = 'jul';break;
            case '8': mes = 'ago';break;
            case '9': mes = 'sep';break;
            case '10': mes = 'oct';break;
            case '11': mes = 'nov';break;
            case '12': mes = 'dic';break;
        }
        return mes;
    }
    static f_switchmesNumerico(mes){
        switch(mes){
            case 'ene': mes = '1';break;
            case 'feb': mes = '2';break;
            case 'mar': mes = '3';break;
            case 'abr': mes = '4';break;
            case 'may': mes = '5';break;
            case 'jun': mes = '6';break;
            case 'jul': mes = '7';break;
            case 'ago': mes = '8';break;
            case 'sep': mes = '9';break;
            case 'oct': mes = '10';break;
            case 'nov': mes = '11';break;
            case 'dic': mes = '12';break;
        }
        return mes;
    }
    static formatear(num){
        var separador =  ","; // separador para los miles
        var sepDecimal = '.';
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
          splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
        }
        /*if(splitRight == ""){
            splitRight = ".00";
        }*/
        return  splitLeft + splitRight;
      }
    static porcentaje(arreglo,dato){
        var suma = 0;
        for(var i = 0; i < arreglo.length; i++){
            if(dato.name == arreglo[i].name){
                suma = arreglo[i].value;
            }
        }
        return ((dato.value*100)/suma);
    }
    static porcentajec(arreglo,item_tabla,dato){
        var suma = 0;
        for(var i = 0; i < arreglo.length; i++){
            if(item_tabla == arreglo[i].name){
                for(var j = 0; j < arreglo[i].series.length; j ++){
                    suma = suma + arreglo[i].series[j].value;
                }
            }
        }
        console.log("Porcentajec --> " + JSON.stringify(arreglo) + "  " + JSON.stringify(dato) + " item tabla " + item_tabla +" suma --> " + suma + " valor " + dato.value );
        return ((dato.value*100)/suma);
    }
}

export class BalanceFunctions {
    static getActivoIcon(bnActivo: boolean) {
        return (null == bnActivo) ? 'more_horiz'
              : bnActivo ? 'done' : 'block';
    }
    static getBnFromString(strValue: string): boolean {
        return (
                null == strValue
                || undefined === strValue
                || 'false' === strValue.toLowerCase().trim()
                || '0' === strValue.toLowerCase().trim()
              ) ? false : true;
    }
}

export class IndicadoresFunctions {
    constructor() {}
    static buscarsignomatematico(signo){
        var flag = false;
        for(var i = 0; i < ConstantesQubos.signosmatematicos.length;i++){
            if(signo == ConstantesQubos.signosmatematicos[i]){
                flag = true;
            }
        }
        return flag;
    }
    static buscarCuentaId(cuentaid,cuenta,variable,vartemp){
        var descripcion = cuentaid;
        var flag = false;
        for(var i = 0; i < cuenta.length;i++){
            if(cuentaid == cuenta[i].Id_Cuenta){
                descripcion = cuenta[i].Descripcion;
                flag = true;
            }
        }
        if(!flag)
            descripcion = this.buscarVariable(cuentaid,variable,vartemp);
        return descripcion;
    }
    static buscarVariable(cuentaid,variable,vartemp){
        var descripcion = cuentaid;
        var flag = false;
        for(var i = 0; i < variable.length;i++){
            if(cuentaid == variable[i].Id_Variable){
                descripcion = variable[i].Descripcion;
                flag = true;
            }
        }
        if(!flag){
            descripcion = this.buscarVarTemp(cuentaid,vartemp);
        }
        return descripcion;
    }
    static buscarVarTemp(cuentaid,vartemp){
        var descripcion = cuentaid;
        var flag = false;
        //console.log("VarTemp --> " + cuentaid + " VarTemp " + JSON.stringify(vartemp));
        for(var i = 0; i < vartemp.length;i++){
            if(cuentaid == vartemp[i].Id_VarTemp){
                descripcion = vartemp[i].Descripcion;
                flag = true;
            }
        }
        /*if(!flag){
            descripcion = this.buscarVarTemp(cuentaid,vartemp);
        }*/
        return descripcion;
    }
    static BuscarCuentaIdInFormula(formula,cuenta,variable,vartemp){
        var inicio = 0, fin = 0;
        var cuentaid = "";
        var formulaFinal = "";
        //console.log("Formula --> " + formula);
        if(formula.includes("IIF")){
            formulaFinal = "(activo - cartera vencida neta - bienes adjudicados - otros activos) / (depósitos + capital contable)";
        }else{
            for(var i = 0; i < formula.length;i++){
                if(this.buscarsignomatematico(formula[i])){
                    formulaFinal = formulaFinal.concat(this.buscarCuentaId(cuentaid,cuenta,variable,vartemp).toLocaleLowerCase() + " " + formula[i] + " ");
                    //console.log("Cuenta Id " + cuentaid);
                    inicio = fin;
                    cuentaid = "";
                }else{
                    cuentaid = cuentaid.concat(formula[i]);
                    fin = i;
                }
            }
        }
        formulaFinal = formulaFinal.concat(this.buscarCuentaId(cuentaid,cuenta,variable,vartemp).toLocaleLowerCase());
        formulaFinal = formulaFinal.replace("fca1","promedio activo")
        formulaFinal = formulaFinal.replace(" * 100","").replace("","").replace("","").replace("   "," ").replace("  "," ").replace("abs","").replace("iif","IIF");
        //console.log("FormulaFinal ---> " + formulaFinal);
        return formulaFinal;
    }


    static getActivoIcon(bnActivo: boolean) {
        return (null == bnActivo) ? 'more_horiz'
              : bnActivo ? 'done' : 'block';
    }
    static getBnFromString(strValue: string): boolean {
        return (
                null == strValue
                || undefined === strValue
                || 'false' === strValue.toLowerCase().trim()
                || '0' === strValue.toLowerCase().trim()
              ) ? false : true;
    }
   
}
