import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WelcomeComponent} from '../../components/welcome/welcome.component';
import {PermisosSubmodulosGuard} from '../../guards/permisos-submodulos/permisos-submodulos.guard';
import {GeoReferenciacionComponent} from './geo-referenciacion/geo-referenciacion.component';

const routes: Routes = [
  {
    path: 'app/geografico',
    component: WelcomeComponent,
    children: [
      { path: 'geo-referenciacion', component: GeoReferenciacionComponent, canActivate: [PermisosSubmodulosGuard] },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeograficoRoutingModule { }
