import {Component, OnInit, Input} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() title: string;
  @Input() icon = 'repeat';
  constructor(
      private router: Router,
      public dataService: DataService
  ) {}

  ngOnInit(): void {
  }

  logout(): void {
    sessionStorage.clear();
    location.assign(`/app`);
  }


  home(): void{
    this.router.navigateByUrl(`/app`);
  }

}

