import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css']
})
export class LegendComponent implements OnInit {

  @Input() datos: any[];
  @Input() small: boolean;
  @Input() colorScheme: any;
  @Input() columnName: any;

  public elementos: any[] = [];

  constructor() { }

  ngOnInit(): void {

    var nuevo_elemento;

    this.datos.forEach(elemento => {
      nuevo_elemento = { name: elemento[this.columnName]};
      this.elementos.push(nuevo_elemento);
    });
  }

}
