import { Injectable } from '@angular/core';
import {MessageService} from '../base/message.service';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {Loggers} from '../../util/app-util';
import {zonaEntity} from './../../modules/riesgos/entities_riesgos/entities_riesgos';
@Injectable({
  providedIn: 'root'
})
export class ZonaService extends Service<zonaEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('zona', '', http, messageService, logger);
      this.httpClient = http;
  }
  protected getParams(entity: zonaEntity):HttpParams{
    let params: HttpParams = new HttpParams();
    if (null == entity){return params;}
    if (null != entity.Id_Zona){params = params.set('Id_Zona', entity.Id_Zona.toString())}

    return params;
  }
}
