<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!--TITULO DEL SUBMODULO-->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>

    <!--FILTROS-->
    <div class="w3-rest">
      <!--AÑOS Y MES-->
      <div class="w3-noventa2">

        <!--ANIOS-->
        <div class="w3-half">
          <!--ANIOS DISPONIBLES-->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Año
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuanios="matMenu">
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                (change)="cambiodeAnio(anio)">
                    {{ anio.value }}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!--FIN DE ANIOS DISPONIBLES-->
          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips " id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="anios.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let anios of aniosSeleccionados"
                            style="margin-left: 5px;" class="filtroMesAnioSeleccionado" >
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>

        <!--MESES-->
        <div class="w3-half">
          <!--MESES DISPONIBLES-->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              Mes
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menumeses="matMenu">
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}" aria-label="First checkbox"
                                (change)="cambiodeMes(mes)">
                    {{ mes.name }}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!--FIN DE MESES DISPONIBLES-->
          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda ">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="meses.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let meses of mesesSeleccionados"
                            class="filtroMesAnioSeleccionado" 
                            style="height: 20px;width: 42px;margin-left: 10px;">
                            {{meses.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>

      </div>
      <!--BORRAR FILTROS-->
      <div class="w3-rest w3-right">
        <button mat-icon-button>
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="borrarFil()">
            <img src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="w3-container w3-contenedor-template" style="height: 85vh;">
  <!--PERATE DE ARRIBA-->
  <div class="w3-row" style="height: 42.5vh;">
    <div id="zon1" class="w3-row w3-white " style="width: 99%;">
      <div class="w3-row ">
        <!--Titulo-->
        <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido">Estadístico</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtitulo}}</span>
          </div>
        </div>
        <!--Menú-->
        <div class="w3-rest w3-right-align_rest">
          <div class="w3-rest hijoderechaTitle w3-tiny ">
            <mat-icon class="w3-margin-right-contenido " [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
              more_vert
            </mat-icon>
            <mat-menu #menu="matMenu">
              <button class="mat-menu-item w3-caja-menu" mat-menu-item [matMenuTriggerFor]="camGraf">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span> Gráficas </span>
              </button>
              <button class="mat-menu-item w3-caja-menu" mat-menu-item [matMenuTriggerFor]="perspectivas">
                <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree</mat-icon>
                <span> Perspectiva </span>                
              </button>
              <button class="mat-menu-item w3-caja-menu" mat-menu-item (click)="exportar_img('zon1')">
                <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                <span> Exportar </span>
              </button>
              <button mat-menu-item (click)="ampliar('C1')" onclick="document.getElementById('graficasModal').style.display='block'">
                <mat-icon [ngStyle]="{'color':'gray'}">zoom_out_map</mat-icon>
                <span> Ampliar </span>
              </button>
            </mat-menu>
            <mat-menu #camGraf="matMenu">
              <!--<button mat-menu-item (click)="cambioGraf('comp')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span> Compuesta </span>
              </button>-->
              <button mat-menu-item (click)="cambioGraf('barras')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span> Barras </span>
              </button>
              <button mat-menu-item (click)="cambioGraf('lineal')">
                <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                <span> Lineal </span>
              </button>
              <button mat-menu-item (click)="cambioGraf('area')">
                <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                <span> Area </span>
              </button>
            </mat-menu>
            <mat-menu #perspectivas="matMenu">
              <button mat-menu-item (click)="cambioPer('caso')">
                <span> Capital social </span>
              </button>
              <button mat-menu-item (click)="cambioPer('ahso')">
                <span> Ahorro socio </span> 
              </button>
              <button mat-menu-item (click)="cambioPer('ahcc')">
                <span> Ahorro cuenta corriente </span>
              </button>
              <button mat-menu-item (click)="cambioPer('crece')">
                <span> Ahorro cuenta crece </span>
              </button>
              <button mat-menu-item (click)="cambioPer('ahme')">
                <span> Ahorro menor </span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <!--GRAFICAS DE LA ZONA 1-->
      <div class="w3-row" style="height: 35vh;">
        <div class="w3-row" *ngIf="grafcompuesta" style="height: 31vh; width: 98%;">
          <combo-chart-component
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [results]="databarrascom"
            [animations]="animations"
            [lineChart]="datalinealcom"
            [yAxisTickFormatting]="yLeftTickFormat"
            [yLeftAxisScaleFactor]="yLeftAxisScale"
            [yRightAxisScaleFactor]="yRightAxisScale"
            [yRightAxisTickFormatting]="yRightTickFormat"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="'Logrado en captación'"
            >
          </combo-chart-component>
        </div>
        <div class="w3-row" *ngIf="grafBarras" style="height: 30vh; width: 99%;">
          <ngx-charts-bar-vertical-2d
            [scheme]="colorScheme"
            [results]="dataBar"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [yScaleMin]="menorG1"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [groupPadding]="'5'">
          </ngx-charts-bar-vertical-2d>
        </div>
        <div class="w3-row" *ngIf="grafLineal" style="height: 34vh; width: 99%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [legend]="showLegendlin"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [yScaleMin]="menorG1"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="dataLin"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="grafArea" style="height: 34vh; width: 99%;">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [legend]="showLegendlin"
            [legendTitle]="legendTitle"
            [yScaleMin]="menorG1"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xlabellin"
            [yAxisLabel]="ylabellin"
            [results]="dataAre"
            >
          </ngx-charts-area-chart>
        </div>
      </div>
    </div>    
  </div>
  <!--PERATE DE ABAJO-->
  <div class="w3-row" style="height: 42.5vh;">
    <!--ZONA 2-->
    <div id="zon2" class="w3-half" style="height: 42vh;">
      <div class="w3-row w3-white" style="height: 6vh; width: 99%;">
        <!--TITULO-->
        <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p" matTooltipPosition="above" matTooltipShowDelay="1000">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido">Estadístico</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtitulo}}</span>
          </div>
        </div>
        <!--MENU-->
        <div class="w3-rest w3-right-align_rest">
          <div class="w3-rest hijoderechaTitle w3-tiny ">
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC4">
              more_vert
            </mat-icon>
            <mat-menu #menuC4="matMenu">
              <button class="mat-menu-item w3-caja-menu" mat-menu-item [matMenuTriggerFor]="camGraf2">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span> Gráficas </span>
              </button>
              <button class="mat-menu-item w3-caja-menu" mat-menu-item (click)="export_excel('zon2')">
                <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                <span> Exportar </span>
              </button>
              <button mat-menu-item (click)="ampliar('C2')" onclick="document.getElementById('graficasModal').style.display='block'">
                <mat-icon [ngStyle]="{'color':'gray'}">zoom_out_map</mat-icon>
                <span> Ampliar </span>
              </button>
            </mat-menu>
            <mat-menu #camGraf2="matMenu">
              <button mat-menu-item (click)="cambioGraf2('tabla')">
                <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                <span> Tabla </span>
              </button>
              <button mat-menu-item (click)="cambioGraf2('barras')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span> Barras </span>
              </button>
              <button mat-menu-item (click)="cambioGraf2('lineal')">
                <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                <span> Lineal </span>
              </button>
              <button mat-menu-item (click)="cambioGraf2('area')">
                <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                <span> Area </span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <!--GRAFICAS ZONA 2-->
      <div class="w3-row w3-white" style="height: 35vh; width: 99%;">
        <div *ngIf="grafTabla" class="w3-encabezado-table w3-scroll" >
          <table class="w3-table-all" mat-table [dataSource]="dataTab">
            <ng-container matColumnDef="mes">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Periodo </th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{element.mes}} </td>
            </ng-container>

            <ng-container matColumnDef="salAnt">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Saldo anterior </th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{formatearnormal(element.salAnt)}} </td>
            </ng-container>
            
            <ng-container matColumnDef="salAct">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Saldo actual</th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{formatearnormal(element.salAct)}} </td>
            </ng-container>

            <ng-container matColumnDef="log">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Logrado en captación </th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{formatearnormal(element.log)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columna;sticky: true;" class="w3-table-title"></tr>
            <tr mat-row *matRowDef="let row; columns: columna;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
          </table>
        </div>
        <div class="w3-row" *ngIf="grafBarras2" style="height: 34vh; width: 99%;">
          <ngx-charts-bar-vertical
            [scheme]="comboBarScheme"
            [results]="dataBar2"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [groupPadding]="'5'">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="grafLineal2" style="height: 34vh; width: 99%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="dataLin2"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="grafArea2" style="height: 34vh; width: 99%;">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xlabellin"
            [yAxisLabel]="ylabellin"
            [results]="dataLin2"
            >
          </ngx-charts-area-chart>
        </div>
      </div>
    </div>
    <!--INICIO DE LAS TARJETAS-->
    <div class="w3-rest" style="height: 42vh;">
      <div class="w3-row" style="height: 99%; width: 99%;">
        
        <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 13vh;">
          <div class="w3-half">
            <div class="w3-row textLeft">
              <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Saldo anterior</span>
            </div>
            <div class="w3-row textLeft" style="vertical-align: bottom;">
              <span class="w3-subtituloMeta"> {{obtMesTab(aniosSelec[1])}} | {{aniosSelec[1].split('-')[0].substring(2,4)}} </span>
            </div>
          </div>
          <div class="w3-rest">
            <div class="w3-row w3-right textRight">
              <span class="w3-total-cantidadEstadistico w3-balance-bluep">$ {{formatearnormal(dataTab[dataTab.length-2].salAnt)}} </span>
            </div>
          </div>
        </div>

        <div style="height: 1vh;"></div>
        
        <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 13vh;">
          <div class="w3-half">
            <div class="w3-row textLeft">
              <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Saldo actual</span>
            </div>
            <div class="w3-row textLeft">
              <span class="w3-subtituloMeta"> {{obtMesTab(aniosSelec[0])}} | {{aniosSelec[0].split('-')[0].substring(2,4)}} </span>
            </div>
          </div>
          <div class="w3-rest">
            <div class="w3-row w3-right textRight">
              <span class="w3-total-cantidadEstadistico w3-balance-bluep">$ {{formatearnormal(dataTab[dataTab.length-2].salAct)}} </span>
            </div>
          </div>
        </div>

        <div style="height: 1vh;"></div>
        
        <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 13vh;">
          <div class="w3-half">
            <div class="w3-row textLeft">
              <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Logrado</span>
            </div>
            <div class="w3-row textLeft">
              <span class="w3-subtituloMeta"> {{obtMesTab(aniosSelec[0])}} | {{aniosSelec[0].split('-')[0].substring(2,4)}} </span>
            </div>
          </div>
          <div class="w3-rest">
            <div class="w3-row w3-right textRight">
              <span class="w3-total-cantidadEstadistico w3-balance-bluep">$ {{formatearnormal(dataTab[dataTab.length-2].log)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MODAL-->
<div id="graficasModal" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <!--ENCABEZADO-->
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <!--TITULO-->
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda "
          style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{titulo}}</span><span
                class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span><span
                class=" w3-margin-left-filter w3-subtitulo">{{subtitMod}}</span>
            </div>
          </div>
        </div>
      </div>
      <!--CERRAR-->
      <div class="w3-rest ">
        <mat-icon (click)="closeAmpliar()" onclick="document.getElementById('graficasModal').style.display='none'"
          class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <!--CUERPO-->
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <!--GRAFICAS DE LA ZONA 1-->
        <div class="w3-row" *ngIf="grafcompuesta_modal" style="height: 54vh; width: 98%;">
          <combo-chart-component
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [results]="databarrascom"
            [animations]="animations"
            [lineChart]="datalinealcom"
            [yAxisTickFormatting]="yLeftTickFormat"
            [yLeftAxisScaleFactor]="yLeftAxisScale"
            [yRightAxisScaleFactor]="yRightAxisScale"
            [yRightAxisTickFormatting]="yRightTickFormat"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="'Saldo'"
            >
          </combo-chart-component>
        </div>
        <div class="w3-row" *ngIf="grafBarras_modal" style="height: 54vh; width: 99%;">
          <ngx-charts-bar-vertical-2d
            [scheme]="colorScheme"
            [results]="dataBar"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [groupPadding]="'5'">
          </ngx-charts-bar-vertical-2d>
        </div>
        <div class="w3-row" *ngIf="grafLineal_modal" style="height: 54vh; width: 99%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [legend]="showLegendlin"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="dataLin"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="grafArea_modal" style="height: 54vh; width: 99%;">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [legend]="showLegendlin"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xlabellin"
            [yAxisLabel]="ylabellin"
            [results]="dataAre"
            >
          </ngx-charts-area-chart>
        </div>
        <!--GRAFICAS DE LA ZONA 2-->
        <div class="w3-row w3-encabezado-table w3-scroll" *ngIf="grafTabla_modal" style="height: 54vh; width: 99%;">
          <table class="w3-table-all" mat-table [dataSource]="dataTab">
            <ng-container matColumnDef="mes">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Periodo </th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{element.mes}} </td>
            </ng-container>

            <ng-container matColumnDef="salAnt">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Saldo anterior </th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{formatearnormal(element.salAnt)}} </td>
            </ng-container>
            
            <ng-container matColumnDef="salAct">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Saldo actual</th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{formatearnormal(element.salAct)}} </td>
            </ng-container>

            <ng-container matColumnDef="log">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Logrado en captación </th>
              <td mat-cell style="width: 24%;" *matCellDef="let element let i = index" [style]="texto(i)"> {{formatearnormal(element.log)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columna;sticky: true;" class="w3-table-title"></tr>
            <tr mat-row *matRowDef="let row; columns: columna;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
          </table>
        </div>
        <div class="w3-row" *ngIf="grafBarras2_modal" style="height: 54vh; width: 99%;">
          <ngx-charts-bar-vertical
            [scheme]="comboBarScheme"
            [results]="dataBar2"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [groupPadding]="'5'">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="grafLineal2_modal" style="height: 54vh; width: 99%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="dataLin2"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="grafArea2_modal" style="height: 54vh; width: 99%;">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xlabellin"
            [yAxisLabel]="ylabellin"
            [results]="dataLin2"
            >
          </ngx-charts-area-chart>
        </div>
      </div>
    </div>
  </div>
</div>