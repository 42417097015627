import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {EsqueletoPeticionModel} from '../../model/esqueletoPeticion.model';

@Injectable({
  providedIn: 'root'
})

export class FiltrosService {
  private apiURL = environment.apiDevURL;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient) { }

  /**
   * Se obtienen los años y meses disponibles de la informacion para construir los filtros
   * de manera dinamica. Una vez obtenidos del back, se mapean al formato utilizado por los tableros.
   */
  public getAniosMesesDisponibles(source: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'general',
      '-',
      '-',
      source // aseguradora | radiografia
    );
    peticion.filter = {
      aniomes: {
        campo: 'fechaArchivo',
          mayorque: 0
      }
    };
    peticion.groupby = {
      aniosmeses: {
        campo: 'fechaArchivo'
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  public formatAniosMesesDisponibles(response: object): { aniosDisponibles: [], mesesDisponibles: [] } {
    const aniosDisponibles = [];
    const mesesDisponibles = [];
    // @ts-ignore
    if (response.http.code === 200) {
      // @ts-ignore
      const valoresDisponibles = response.message;
      for (const valor of valoresDisponibles) {
        const anio = valor.key.split('-')[0];
        const mes = valor.key.split('-')[1];
        if (!aniosDisponibles.find(value => value.name === anio)){
          aniosDisponibles.push({
            name: anio, value: Number(anio), check: true, selected: false
          });
        }
        mesesDisponibles.push({
          name: this.getMesPrefijo(mes), value: valor.key, year: anio, check: false
        });
      }
    }
    // @ts-ignore
    return { aniosDisponibles, mesesDisponibles };
  }
  public formatAnioMesDisponible(response: object): { aniosDisponibles: [], mesesDisponibles: [], mesesAniosDisponibles: [], ult_anio: [], ult_mes: [] } {
    const aniosDisponibles = [];
    const mesesDisponibles = [];
    const mesesAniosDisponibles = [];
    // @ts-ignore
    if (response.http.code === 200) {
      // @ts-ignore
      const valoresDisponibles = response.message;
      for (const valor of valoresDisponibles) {
        const anio = valor.key.split('-')[0];
        const mes = valor.key.split('-')[1];
        if (!aniosDisponibles.find(value => value.name === anio)){
          aniosDisponibles.push({
            name: anio, value: Number(anio), check: false, selected: false
          });
        }
        mesesAniosDisponibles.push({
          name: this.getMesPrefijo(mes), value: valor.key, year: anio, check: false
        });
      }
    }
    // @ts-ignore
    const valoresDisponibles = response.message;
    aniosDisponibles[aniosDisponibles.length - 1].check = true;
    const ult_anio = [];
    ult_anio.push(aniosDisponibles[aniosDisponibles.length - 1].name);
    for (const valor of valoresDisponibles){
      const anio = valor.key.split('-')[0];
      if (anio == ult_anio){
        const mes = valor.key.split('-')[1];
        mesesDisponibles.push({
          name: this.getMesPrefijo(mes), value: valor.key, year: anio, check: false
        });        
      }
    }
    mesesDisponibles[mesesDisponibles.length - 1].check = true;
    const ult_mes = [];
    ult_mes.push(mesesDisponibles[mesesDisponibles.length - 1].name);
    // @ts-ignore
    return { aniosDisponibles, mesesDisponibles, mesesAniosDisponibles, ult_anio, ult_mes };
  }
  public changeElementCheckStatus(element: object, elementArray: object[]): void{
    const index = elementArray.indexOf(element);
    // @ts-ignore
    elementArray[index].check = !elementArray[index].check;
  }

  public changeElementCheckStatusFiltroUnAnio(element: object, elementArray: object[]){
    const index = elementArray.indexOf(element);
    for (const valor of elementArray){
      valor['check'] = false;
    }
    // @ts-ignore
    elementArray[index].check = !elementArray[index].check;
  }
  public changeElementCheckStatusFiltroUnAnio_return(element: object, elementArray: object[]){
    const index = elementArray.indexOf(element);
    for (const valor of elementArray){
      valor['check'] = false;
    }
    // @ts-ignore
    elementArray[index].check = !elementArray[index].check;
    return elementArray;
  }

  public changeAllElementsCheckStatus(elementArray: object[], value: boolean): void {
    for (const e of elementArray) {
      // @ts-ignore
      e.check = value;
    }
  }

  public getElmentosSeleccionados(elementArray: object[]): any[] {
    // @ts-ignore
    const elementCheckTrue = elementArray.filter(element => element.check);
    const elementCheckTrueValues = [];
    for (const e of elementCheckTrue) {
      // @ts-ignore
      elementCheckTrueValues.push(e.value);
    }
    return elementCheckTrueValues;
  }

  public getMesPrefijo(valor: string): string {
    switch (valor.substring(valor.length - 2, valor.length)) {
      case '01':
        return 'ene';
      case '02':
        return 'feb';
      case '03':
        return 'mar';
      case '04':
        return 'abr';
      case '05':
        return 'may';
      case '06':
        return 'jun';
      case '07':
        return 'jul';
      case '08':
        return 'ago';
      case '09':
        return 'sep';
      case '10':
        return 'oct';
      case '11':
        return 'nov';
      case '12':
        return 'dic';
    }
  }
  public getMesPrefijoReves(valor: string): string {
    switch (valor) {
      case 'ene':
        return '01';
      case 'feb':
        return '02';
      case 'mar':
        return '03';
      case 'abr':
        return '04';
      case 'may':
        return '05';
      case 'jun':
        return '06';
      case 'jul':
        return '07';
      case 'ago':
        return '08';
      case 'sep':
        return '09';
      case 'oct':
        return '10';
      case 'nov':
        return '11';
      case 'dic':
        return '12';
    }
  }

  public getSucursalesDisponibles(source: string, campo: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'general',
      '-',
      '-',
      source // aseguradora | radiografia
    );
    peticion.groupby = {
      sucursales: {
        campo // sucursal-descripcion | codSucCredito-sucursalCredito
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  public formatSucursalesDisponibles(response: object): object[] {
    const sucursalesDisponibles = [];
    // @ts-ignore
    if (response.http.code === 200) {
      // @ts-ignore
      const valoresDisponibles = response.message;
      for (const valor of valoresDisponibles) {
        if (valor.key !== 'Sin dato')
          sucursalesDisponibles.push({
            name: valor.key, value: valor.key, check: false
          });
      }
    }
    return sucursalesDisponibles;
  }

  public formatSucursalesSeleccionadas(sucursalesdisponibles: object[], campoElasticSearch: string): object[] {
    const sucursalesSeleccionadas = [];
    // @ts-ignore
    const sucursalesCheckTrue = sucursalesdisponibles.filter(element => element.check);
    for (const sucursal of sucursalesCheckTrue) {
      sucursalesSeleccionadas.push({
        campo: campoElasticSearch,
        // @ts-ignore
        valor: sucursal.name
      });
    }
    return sucursalesSeleccionadas;
  }
}
