import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {VariableEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class VariableService extends Service<VariableEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/Variable', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: VariableEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_Variable) { params = params.set('Id_Variable', entity.Id_Variable.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Id_Tipo_Variable) { params = params.set('Id_Tipo_Variable', entity.Id_Tipo_Variable.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }
    if (null != entity.Id_Tipo_Cooperativa) { params = params.set('Id_Tipo_Cooperativa', entity.Id_Tipo_Cooperativa.toString()); }
    
    return params;
  }
}
