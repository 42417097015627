import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service/data.service';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import { PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import { MetaVsRealService } from './../../../services/comercial/MetaVsReal/meta-vs-real.service';
import { MatSort ,Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface UserData {
  name: string,
  value: string,
  check: boolean,
}

@Component({
  selector: 'app-meta-vs-real',
  templateUrl: './meta-vs-real.component.html',
  styleUrls: ['./meta-vs-real.component.css']
})

export class MetaVsRealComponent implements OnInit {
  /** Opciones de la grafica compuesta */
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = '';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Sucursales';
  showYAxisLabel = true;
  yAxisLabel1 = 'Monto';
  yAxisLabel = 'Saldos';
  showGridLines = true;
  innerPadding = '20%';
  animations: boolean = true;
  lineChartScheme = {
    domain: ['#3BB0C9','#004A97']
  };

  comboBarScheme = {
    domain: ['#004A97']
  };
  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';

  colorScheme = {
    domain: ['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6','#301CDCFF', '#67C2F6FF','#301CDCFF', '#67C2F6FF'],
  };

  groupPadding = 1;
  /** Variables para formatear datos */
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /** Variables filtros */
  public aniosDisponibles: any [] = [];
  public aniosSeleccionados: any [] = [];
  public mesesDisponibles: any [] = [];
  public sucursalesDisponibles2: MatTableDataSource<UserData>;
  public mesesDeAnioDisponibles: any[] = [];
  public mesesSeleccionados: any [] = [];
  public sucursalesDisponibles: any[];
  public puedeFiltrarPorSucursales = false;
  public numeroDeSucursalesSeleccionadas = 0;
  public ult_anio: any [] = [];
  public ult_mes: any [] = [];

  public consolidado = true;
  public subtituloGrafica1: string =  "";
  public sucursal_seleccionada: string = "CONSOLIDADO";
  /** Variables de graficación */
  public graficatabla = true;
  public graficabarras = false;
  public graficalineal = false;
  public graficaarea = false;
  public graficabarra = false;
  // Modal
  public graficatabla_modal = false;
  public graficabarras_modal = false;
  public graficalineal_modal = false;
  public graficaarea_modal = false;
  public graficabarra_modal = false;
  /** Variables de datos */
  public data: any [] = [];
  public datalinealcom: any[] = [];
  public databarrascom: any[] = [];
  public datalineal: any[] = [];
  public databarras: any[] = [];
  public dataTabla: any[] = [{sucursal: 'prueba',
  proyectado: Number(100),
  real: Number(80),
  logrado: Number(80.10),
  meta: Number(90)}];
  /** Variables del modal */
  public titulo = [];
  public showModal: boolean = false
  public titulomodal = '';
  public subtitulomodal = '';
  /** Variables para la tabla */
  public columsName: string[] = ['sucursal', 'proyectado', 'real', 'porcentaje', 'por_lograr','numero_cuentas' ,'meta_anio'];
  public columsName3: string[] = ['sucursal', 'proyectado', 'real'];

  public cumpMesAnt = 0;
  public mesAnt: any;
  public anioAnt: any;
  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private excelservices: ExcelService,
    private metavsrealservice: MetaVsRealService,
  ){
    this.ObtDatos();
  }
  ngOnInit(): void {  }

  /** Obtención de información */
  public ObtDatos(){
    var ult_anio = [];
    var ult_mes = [];
    this.filtrosService.getAniosMesesDisponibles('radiografia_meta').subscribe( response =>{
      const tiempoFiltros = this.filtrosService.formatAnioMesDisponible(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesAniosDisponibles;
      ult_anio = tiempoFiltros.ult_anio;
      ult_mes = tiempoFiltros.ult_mes;
      this.ult_anio = ult_anio;
      this.ult_mes = ult_mes;
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio[0]);
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length-1].check =  true;
      this.aniosMesesSeleccionados();
      this.obtencionInformacion1();
    });
  }
  public obtencionInformacion(){
    this.data = [];
    /*this.metavsrealservice.getLogradoAdulto(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.formateoData(this.data);
        }
      }
    });*/
    this.metavsrealservice.getLogradoAdultoN(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        var data2 = response.message;
        if (data2){
          this.conteNcuentas(data2);
          this.metavsrealservice.getLogradoAdulto(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
            if (response.http.code === 200) {
              this.data = response.message;
              if (this.data){
                this.formateoData(this.data);
              }
            }
          });
        }
      }
    });
    this.mesanioAnt();
    this.metavsrealservice.getLogradoAdulto(this.anioAnt,this.filtrosService.getMesPrefijoReves(this.mesAnt),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.obtmesAnt(this.data);
        }
      }
    });
  }
  public conteNcuentas(arreglo){
    arreglo.sort(function (a, b) {
      if (a.nombreSucursal < b.nombreSucursal) {
        return 1;
      }
      if (a.nombreSucursal > b.nombreSucursal) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    var suma = 0, j = 0;
    this.Ncred = [];
    for(const sum of arreglo){
      if(suma == 0){
        this.Ncred.push({Sucursal: sum['nombreSucursal'], nCred: 1, numSuc: sum['codigoSucursal']});
        suma++;
      }else{
        if(this.Ncred[j].Sucursal == sum['nombreSucursal']){
          this.Ncred[j].nCred = this.Ncred[j].nCred + 1;
        }else{
          if(sum['nombreSucursal'] != ''){
            if(sum['nombreSucursal'] != "NEGOCIOS ESPECIALIZADOS"){
              this.Ncred.push({Sucursal: sum['nombreSucursal'], nCred: 1, numSuc: sum['codigoSucursal']});
              j++;
            }else{
              this.Ncred.push({Sucursal: sum['nombreSucursal'], nCred: 1, numSuc: "013"});
              j++;
            }
          }
        }
      }
    }
    this.Ncred.sort(function (a, b) {
      if (a.numSuc > b.numSuc) {
        return 1;
      }
      if (a.numSuc < b.numSuc) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    if(this.Ncred.length == 21){
      var aux = [], i = 0;
      for(const suc of this.Ncred){
        aux.push(suc);
        if(i == 10){
          aux.push({Sucursal: "NEGOCIOS ESPECIALIZADOS", nCred: 0});
        }
        i++;
      }
      this.Ncred = aux;
    }
    if(this.Ncred.length == 0){
      for(const suc of this.sucursalesDisponibles){
        this.Ncred.push({Sucursal: suc['name'], nCred: 0});
      }
    }
  }
  public Ncred = [];
  public obtencionInformacion1(){
    this.metavsrealservice.getLogradoAdultoN(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        var data2 = response.message;
        if (data2){
          this.conteNcuentas(data2);
          this.metavsrealservice.getLogradoAdulto(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
            if (response.http.code === 200) {
              this.data = response.message;
              if (this.data){
                this.sucursalesDisponibles = [];
                for(const suc of this.data){
                  if(suc['sucursal'] != 'TOTALES'){
                    this.sucursalesDisponibles.push({
                      name: suc['sucursal'],
                      value:suc['sucursal'],
                      check: true,
                    });
                  }else{
                    this.sucursalesDisponibles.push({
                      name: suc['sucursal'],
                      value:suc['sucursal'],
                      check: false,
                    });
                  }
                }
                this.sucursalesDisponibles2 = new MatTableDataSource(this.sucursalesDisponibles);
                this.formateoData(this.data);
                this.mesanioAnt();
                this.metavsrealservice.getLogradoAdulto(this.anioAnt,this.filtrosService.getMesPrefijoReves(this.mesAnt),this.sucursal_seleccionada).subscribe(response => {
                  if (response.http.code === 200) {
                    this.data = response.message;
                    if (this.data){
                      this.obtmesAnt(this.data);
                    }
                  }
                });
              }
            }
          });
          //this.formateoData(this.data);
        }
      }
    });
    
  }
  public mesanioAnt(){
    var i=0;
    var pos: any;
    for(const mes of this.mesesDisponibles){
      if(mes.value == this.mesesSeleccionados[0].value){
        pos = i;
      }
      i++;
    }
    this.mesAnt = this.mesesDisponibles[pos-1].name;
    this.anioAnt = [];
    this.anioAnt .push(this.mesesDisponibles[pos-1].year);
  } 
  public obtmesAnt(data: any){
    var suma = 0;
    var sumreal = 0;
    var sumpro = 0;
    var i = 0;
    for(const dato of data){
      if(dato.sucursal !== 'TOTALES' && this.sucursalesDisponibles[i].check == true){
        suma += Number((dato['logrado']*100).toFixed(2));
        sumreal += Number((dato['real']*100).toFixed(2));
        sumpro += Number((dato['proyectado']*100).toFixed(2));
      }
      i++;
    }
    this.cumpMesAnt = Math.round(Number(((sumreal/sumpro)*100).toFixed(2)));
    //this.cumpMesAnt = Number((suma/(this.dataTabla.length-1)).toFixed(2));
  }
  public formateoData(array: any){
    var auxLin = [];
    var auxLin2 = [];
    this.dataTabla = [];
    this.datalinealcom = [];
    this.databarrascom = [];
    this.databarras = []
    this.datalineal = [];
    var i = 0;
    for(const data of array){
      const suc = this.sucursalesDisponibles.filter(suc => data.sucursal === suc.name);
      if( suc.length !== 0 && suc[0].check === true){
        const nCred = this.Ncred.filter(cred => quitarAcentos(data.sucursal) === quitarAcentos(cred.Sucursal));
        var nncred = 0;
        if( nCred.length !== 0 ){
          nncred = nCred[0].nCred;
        }else if(data.sucursal !== 'MATRIZ'){
          const s = this.Ncred.filter(cred => cred.Sucursal.includes(data.sucursal));
          if(s.length !== 0){
            nncred = s[0].nCred;
          }
        }
        if(data.sucursal === 'MATRIZ'){
          const s = this.Ncred.filter(cred => 'SAN RAFAEL' === cred.Sucursal);
          if(s.length !== 0){
            nncred = s[0].nCred;
          }
        }
        this.dataTabla.push({
          sucursal: data['sucursal'],
          proyectado: data['proyectado'],
          real: Math.round(data['real']),
          logrado: Math.round(Number((data['logrado']*100).toFixed(2))),
          porLog: (Number(data['proyectado'] - Math.round(data['real']))),
          Ncred: Number(nncred),
          meta: Math.round(Number((data['meta']*100).toFixed(2)))
        });
        auxLin.push({
          name: data['sucursal'],
          value: data['proyectado'] 
        });
        this.databarrascom.push({
          name: data['sucursal'],
          value: data['real'] 
        });
        this.databarras.push({
          name: data['sucursal'],
          series: [
            {
              name: 'Proyectado',
              value: data['proyectado']
            },
            {
              name: 'Real',
              value: data['real']
            }
          ]
        });
      }
      i++;
    }
    this.datalinealcom.push({name: 'Proyectado', series: auxLin});
    this.datalineal.push({name: 'Proyectado', series: auxLin});
    this.datalineal.push({name: 'Real', series: this.databarrascom});
    var sum1 = 0,sum2 = 0,sum3 = 0,sum4 = 0,sum5 = 0,sum6 = 0;
    for(const data of this.dataTabla){
      sum1 += data['proyectado'];
      sum2 += data['real'];
      sum3 += data['logrado'];
      sum4 += data['meta'];
      sum5 += data['porLog'];
      sum6 += data['Ncred'];
    }
    this.dataTabla.push({
      sucursal: "TOTAL",
      proyectado: sum1,
      real: sum2,
      logrado: Math.round(Number(((sum2/sum1*100)).toFixed(2))),
      porLog: sum5,
      Ncred: sum6,
      meta: Math.round(Number((sum4/this.dataTabla.length).toFixed(2)))
    });
  }
  /** Filtros */
  //Años
  private aniosMesesSeleccionados(): void {
    this.aniosSeleccionados = [];
    this.mesesSeleccionados = [];
    for (const valor of this.aniosDisponibles) {
      if (valor.check == true)
        this.aniosSeleccionados.push(valor);
    }
    for (const valor of this.mesesDeAnioDisponibles) {
      if (valor.check == true)
        this.mesesSeleccionados.push(valor);
    }
    this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];

  }
  public anioChangeListener(anioClick: object): void {
    this.ult_anio = [];
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(anioClick, this.aniosDisponibles);
    const anio_selecc = this.aniosDisponibles.find(anio => anio.name === anioClick['name']);
    this.ult_anio.push(anio_selecc['name'])
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    this.mesesDeAnioDisponibles.forEach(dat => dat.check = false);
    this.buscarMesSeleccionado();
    this.aniosMesesSeleccionados();
    this.FiltrosAnioMesSucursalSeleccionado();
    this.obtencionInformacion();
  }
  //Sucursal
  public consolidadoChangeListener(): void {
    if(!this.consolidado){
      this.consolidado = !this.consolidado;
      this.sucursal_seleccionada = "CONSOLIDADO";
      this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
    }
    for(const suc of this.sucursalesDisponibles){
        suc['check'] = true;
    }
    this.FiltrosAnioMesSucursalSeleccionado();
    this.obtencionInformacion();
  }
  public sucursalChangeListener(sucursal: any){
    this.consolidado = false;
    this.sucursal_seleccionada = "SUCURSALES MULTIPLES";
    this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
    for(const suc of this.sucursalesDisponibles){
      if(suc['name'] == sucursal.name){
        suc['check'] = !suc['check'];
      }
    }
    this.obtencionInformacion();
  }
  //Meses
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(mesClick, elementArray);
    this.ult_mes = [];
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']== true){
        this.ult_mes.push(valor['name'])
      }
    }
    this.aniosMesesSeleccionados();
    this.FiltrosAnioMesSucursalSeleccionado();
    this.obtencionInformacion();
  }
  public FiltrosAnioMesSucursalSeleccionado(){
      this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];

  }
  public buscarMesSeleccionado(){
    var flag = false;
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['name'] == this.ult_mes[0]){
        valor['check'] = true;
        flag = true;
      }
    }
    if(!flag){
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
    }
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']){
        this.ult_mes = [valor['name']];
      }
    }
  }
  public ContarMeses(){
    if(this.mesesSeleccionados.length < 5 ){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny ";
    }
  }
  /** tabla */
  public formatoMes(mes){
    switch(mes){
      case 'ene': return 'ene';
      case 'feb': return 'feb';
      case 'mar': return 'mar';
      case 'abr': return 'abr';
      case 'may': return 'may';
      case 'jun': return 'jun';
      case 'jul': return 'jul';
      case 'ago': return 'ago';
      case 'sep': return 'sep';
      case 'oct': return 'oct';
      case 'nov': return 'nov';
      case 'dic': return 'dic';
    }
  }
  public mesTabla(mes){
    switch(mes){
      case 'ene': return 'ENERO';
      case 'feb': return 'FEBRERO';
      case 'mar': return 'MARZO';
      case 'abr': return 'ABRIL';
      case 'may': return 'MAYO';
      case 'jun': return 'JUNIO';
      case 'jul': return 'JULIO';
      case 'ago': return 'AGOSTO';
      case 'sep': return 'SEPTIEMBRE';
      case 'oct': return 'OCTUBRE';
      case 'nov': return 'NOVIEMBRE';
      case 'dic': return 'DICIEMBRE';
    }
  }
  public formatoAnio(anio){
    if( anio !== undefined ){
      return anio[anio.length-2]+''+anio[anio.length-1];
    }else{
      return '';
    }
  }
  public formatearnormal(num: any){
    num = Number(num).toFixed(2);
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if(splitRight != '.00')
      return  splitLeft + splitRight;
    else
      return  splitLeft;
  }
  public pintar_borde(porcentaje){
    if(porcentaje >= 90)
      return "w3-border-verdee";
    if(porcentaje >=80 && porcentaje < 90)
      return "w3-border-amarilloo";
    if(porcentaje < 80)
      return "w3-border-rojoo";
  }
  public texto(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
  }
  public textoC3C6(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
    else
    return 'background-color:#E7E6E6'
  }
  public textoC2C5(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
    else
    return 'background-color:#f1f1f1'
  }
  public textoC1(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
    else
    return 'background-color:#fff'
  }
  /** Cambio graficas */
  public cambioGraf(graf: any){
    switch(graf){
      case 'tabla':
        this.graficatabla = true;
        this.graficabarras = false;
        this.graficaarea = false;
        this.graficalineal = false;
        this.graficabarra = false;
      break;
      case 'barrasv':
        this.graficabarras = true;
        this.graficatabla =  false;
        this.graficalineal = false;
        this.graficaarea = false;
        this.graficabarra = false;
      break;
      case 'lineal':
        this.graficalineal = true;
        this.graficabarras = false;
        this.graficatabla =  false;
        this.graficaarea = false;
        this.graficabarra = false;
      break;
      case 'area':
        this.graficaarea = true;
        this.graficabarras = false;
        this.graficatabla =  false;
        this.graficalineal = false;
        this.graficabarra = false;
      break;
      case 'barrash':
        this.graficabarra = true;
        this.graficaarea = false;
        this.graficabarras = false;
        this.graficatabla =  false;
        this.graficalineal = false;
      break;
    }
  }
  public Ampliar(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Clasificación";
    this.subtitulomodal = this.subtituloGrafica1;

    if(this.graficatabla)
      this.graficatabla_modal = true;
    if(this.graficabarras)
      this.graficabarras_modal = true;
    if(this.graficalineal)
      this.graficalineal_modal = true;
    if(this.graficaarea)
      this.graficaarea_modal = true;
    if(this.graficabarra)
      this.graficabarra_modal = true;
  }
  public hideGraficasModal(){
    this.graficatabla_modal = false;
    this.graficabarras_modal = false;
    this.graficalineal_modal = false;
    this.graficaarea_modal = false;
    this.graficabarra_modal = false;
  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  //exportar
  public export_excel(){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    name = 'Metas vs real';
    var perspectiva = '';
    var encabezado_excel = ['SUCURSAL','PROYECTADO','REAL','LOGRADO','POR LOGRAR','NÚMERO DE CRÉDITOS','META DEL AÑO'];
    var cadena_evaluacion = encabezado_excel;
    var mes = this.mesTabla(this.ult_mes[0]);
    for(const dato of this.dataTabla){
      aux.push(dato['sucursal']);
      aux.push(dato['proyectado']);
      aux.push(dato['real']);
      aux.push(dato['logrado']/100);
      aux.push(dato['porLog']);
      aux.push(dato['Ncred']);
      aux.push(dato['meta']/100);
      data_excel.push(aux);
      aux = [];
    }
    this.excelservices.generateExcelMetavsRealcomercial(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva,mes);
  }
  public export_imagen(zon: any){
    var grafica: string = "";
    var name: string = "";
    grafica = 'graficas';
    name = 'Cumplimiento metas adultos';

    PDF.exportimagenes(grafica,name);
  }
  //borrar filtros
  public borrarFil(){
    this.graficatabla = true;
    this.graficabarras = false;
    this.graficalineal = false;
    this.graficaarea = false;
    this.graficabarra = false;
    this.sucursal_seleccionada = "CONSOLIDADO";
    this.ObtDatos();
  }
  public sortData(sort: Sort) {
    const total = this.dataTabla.pop();
    const datas = this.dataTabla;
    this.dataTabla = [];
    if (!sort.active || sort.direction === '') {
      datas.push(total);
      this.dataTabla = datas;
      return;
    }
    var aux = [];
    
    aux = datas.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sucursal': return compare(a.sucursal, b.sucursal, isAsc);
        case 'proyectado': return compare(a.proyectado, b.proyectado, isAsc);
        case 'real': return compare(a.real, b.real, isAsc);
        case 'porcentaje': return compare(a.logrado, b.logrado, isAsc);
        case 'por_lograr': return compare(a.porLog, b.porLog, isAsc);
        case 'numero_cuentas': return compare(a.Ncred, b.Ncred, isAsc);
        case 'meta_anio': return compare(a.meta, b.meta, isAsc);
        default: return 0;
      }
    });

    aux.push(total);
    setTimeout(() =>  this.dataTabla = aux, 1);
    
  }
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sucursalesDisponibles2.filter = filterValue.trim().toLowerCase();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
function quitarAcentos(cadena){
	const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
	return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
}