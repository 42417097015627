<div class="contenedor">

    <div class="w3-row" style="height: 100vh;">

        <div class="w3-col s4 contenedor-left" style="height: 100%;">

            <div class="flex-center">
                <img src="assets/img/logoblanco.png" alt="">
            </div>

            <div class="w3-text-white w3-frase slogan">
                <b>Quien todo lo sabe</b>
            </div>
            
        </div>
        <div class="w3-col w3-white s8 flex-center" style="height: 100%;">

            <div class="formulario">
                <span class="w3-bienvenido"><b>Bienvenid@</b></span>
                <form class="mt-2" (ngSubmit)="onSubmit()" [formGroup]="myForm">
                    <mat-card-content fxLayout="column">
                        <mat-form-field appearance="outline" class="col-12">
                            <input  [errorStateMatcher]="matcher" autocomplete="true" formControlName="inputEmail"
                                matInput placeholder="Correo eletrónico" required type="email">
                            <span matPrefix>
                                <mat-icon style="color: gray;">face</mat-icon>
                            </span>
                            <mat-label>&nbsp;&nbsp;&nbsp;Correo eletrónico</mat-label>
                            <mat-error
                                *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Correo eletrónico <strong>Inválido</strong>
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                Correo eletrónico <strong> es obligatorio</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-12">
                            <input id="pass" type="password" [errorStateMatcher]="matcher" formControlName="inputPassword" matInput
                                placeholder="Contraseña" required>

                            <span matPrefix>
                                <mat-icon style="color: gray;">lock</mat-icon>
                            </span>
                            <span matSuffix style="cursor: pointer;" (click)="revelar_password()">
                                <mat-icon *ngIf="!passVis" style="color: gray;">visibility</mat-icon>
                                <mat-icon *ngIf="passVis" style="color: gray;">visibility_off</mat-icon>
                            </span>

                            <mat-label>&nbsp;&nbsp;&nbsp;Contraseña</mat-label>

                            <mat-error *ngIf="passwordFormControl.hasError('required')">
                                Contraseña <strong> es obligatoria.
                                </strong>
                            </mat-error>
                        </mat-form-field>

                    </mat-card-content>

                    <div class="flex-row-between">
                        <div>
                            <input type="checkbox" class="w3-check" style="height: 15px;width: 15px;margin-top: 5px;">
                            <label class="w3-letra-recordarme w3-margin-left">recordarme</label>
                        </div>

                        <div class=" w3-row w3-right">
                            <a [routerLink]="['/', 'forgot-password']" class="w3-letra-olvpassword w3-margin-left">olvidaste tu contrase&ntilde;a?</a>
                        </div>
                    </div>

                    <div class="mt-2 flex-center">
                        <!--<a mat-button (keydown.enter)="$event.preventDefault()" routerLink="forgotPassword" color="primary">¿Olvidaste tu contraseña?</a>-->
                        <button (keydown.enter)="$event.preventDefault()" class="w3-button" type="submit"><b>ENTRAR</b></button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>

<!--<div class="container">
    <section class="card-container">
        <div class="login-main" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="mat-elevation-z8">
                <mat-card-header fxLayoutAlign="center">
                    <mat-card-title><h1>Login</h1></mat-card-title>
                </mat-card-header>
                <form (ngSubmit)="onSubmit()" [formGroup]="myForm">
                    <mat-card-content fxLayout="column">
                        <mat-form-field appearance="outline" class="col-12">
                            <input [errorStateMatcher]="matcher" autocomplete="true" formControlName="inputEmail"
                                matInput placeholder="Correo eletrónico" required type="email">
                            <mat-label>Correo eletrónico</mat-label>
                            <mat-error
                                *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Correo eletrónico <strong>Inválido</strong>
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                Correo eletrónico <strong> es obligatorio</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-12">
                            <input [errorStateMatcher]="matcher" formControlName="inputPassword" matInput
                                placeholder="Contraseña" required type="password">
                            <mat-label>Contraseña</mat-label>

                            <mat-error *ngIf="passwordFormControl.hasError('required')">
                                Contraseña <strong> es obligatoria.
                                </strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card-content>

                    <div class="botones">
                        <!--<a mat-button (keydown.enter)="$event.preventDefault()" routerLink="forgotPassword" color="primary">{{ 'login.forgot' |
                            translate }}</a>->
                        <button mat-raised-button (keydown.enter)="$event.preventDefault()" color="primary"
                            mat-stroked-button type="submit">Enviar</button>
                    </div>
                </form>
            </mat-card>
        </div>
    </section>

</div> -->