export class FiltroMes {

    cadena: string;
    valor: number;
    selected: boolean;

    constructor(
        valor: number,
    ){
        this.valor = this.catalogoMeses.filter(mes => mes.valor === valor)[0].valor;
        this.cadena = this.catalogoMeses.filter(mes => mes.valor === valor)[0].cadena;
        this.selected = false;
    }

    private catalogoMeses?: FiltroMes[] = [
        {
            cadena: 'ene',
            valor: 1,
            selected: false
        },
        {
            cadena: 'feb',
            valor: 2,
            selected: false
        },
        {
            cadena: 'mar',
            valor: 3,
            selected: false
        },
        {
            cadena: 'abr',
            valor: 4,
            selected: false
        },
        {
            cadena: 'may',
            valor: 5,
            selected: false
        },
        {
            cadena: 'jun',
            valor: 6,
            selected: false
        },
        {
            cadena: 'jul',
            valor: 7,
            selected: false
        },
        {
            cadena: 'ago',
            valor: 8,
            selected: false
        },
        {
            cadena: 'sep',
            valor: 9,
            selected: false
        },
        {
            cadena: 'oct',
            valor: 10,
            selected: false
        },
        {
            cadena: 'nov',
            valor: 11,
            selected: false
        },
        {
            cadena: 'dic',
            valor: 12,
            selected: false
        },
    ];
}
