export class Tabla_Productos{
    name: string;
    value: number;
    ihh: number;
    porcentaje: number;
}

export class Tabla_TendenciaPerdida{
    Periodo: string;
    PerdidaEsperada: number;
    PerdidaNoEsperada95: number;
    VaR95: number;
}

export class Tabla_Sector{
    name: string;
    value: number;
    porcentaje: number;
}
export class FuncionesVariablesRiesgos {
    static sepDecimal = '.';
    static separador = ',';

    static get MesesArr() { return ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']; }

    static f_switchmes(mes){
        switch(mes){
            case 'ene': mes = 'Enero';break;
            case 'feb': mes = 'Febrero';break;
            case 'mar': mes = 'Marzo';break;
            case 'abr': mes = 'Abril';break;
            case 'may': mes = 'Mayo';break;
            case 'jun': mes = 'Junio';break;
            case 'jul': mes = 'Julio';break;
            case 'ago': mes = 'Agosto';break;
            case 'sep': mes = 'Septiembre';break;
            case 'oct': mes = 'Octubre';break;
            case 'nov': mes = 'Noviembre';break;
            case 'dic': mes = 'Diciembre';break;
        }
        return mes;
    }
    static f_switchmesNumber(mes){
        let meslet = "";
        switch(mes){
            case '1': meslet = 'ene'; break;
            case '2': meslet = 'feb'; break;
            case '3': meslet = 'mar'; break;
            case '4': meslet = 'abr'; break;
            case '5': meslet = 'may'; break;
            case '6': meslet = 'jun'; break;
            case '7': meslet = 'jul'; break;
            case '8': meslet = 'ago'; break;
            case '9': meslet = 'sep'; break;
            case '10': meslet = 'oct'; break;
            case '11': meslet = 'nov'; break;
            case '12': meslet = 'dic'; break;
        }
        return meslet;
    }
    static f_switchmesMeses(mes){
        let meslet = 0;
        switch(mes){
            case 'ene': meslet = 1; break;
            case 'feb': meslet = 2; break;
            case 'mar': meslet = 3; break;
            case 'abr': meslet = 4; break;
            case 'may': meslet = 5; break;
            case 'jun': meslet = 6; break;
            case 'jul': meslet = 7; break;
            case 'ago': meslet = 8; break;
            case 'sep': meslet = 9; break;
            case 'oct': meslet = 10; break;
            case 'nov': meslet = 11; break;
            case 'dic': meslet = 12; break;
        }
        return meslet;
    }      
    static formatearnormal(num: any){
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return  splitLeft + splitRight;
    }
}