import {MessageService} from './../../base/message.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from './../../base/service';
import {CaptacionQubosEntity} from './../../../entities/base/DashboardEntities';

import {Loggers} from './../../../util/app-util';
@Injectable({
  providedIn: 'root'
})
export class CaptacionQubosService extends Service<CaptacionQubosEntity> {

  httpClient: HttpClient;

  constructor(
      http: HttpClient,
      messageService: MessageService,
      protected logger: Loggers
  ) {
      super('PatmirService', 'dashboard/captacionqubos', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: CaptacionQubosEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }

    if (null != entity.captacionid) { params = params.set('captacionid', entity.captacionid.toString()); }
    if (null != entity.fechacierre) { params = params.set('fechacierre', entity.fechacierre.toString()); }
    if (null != entity.anio) { params = params.set('anio', entity.anio.toString()); }
    if (null != entity.mes) { params = params.set('mes', entity.mes.toString()); }
    if (null != entity.socioclienteid) { params = params.set('socioclienteid', entity.socioclienteid.toString()); }
    if (null != entity.ifid) { params = params.set('ifid', entity.ifid.toString()); }
    if (null != entity.clavesociocliente) { params = params.set('clavesociocliente', entity.clavesociocliente.toString()); }
    if (null != entity.nocontrato) { params = params.set('nocontrato', entity.nocontrato.toString()); }
    if (null != entity.sucursal) { params = params.set('sucursal', entity.sucursal.toString()); }
    if (null != entity.fechaapertura) { params = params.set('fechaapertura', entity.fechaapertura.toString()); }
    if (null != entity.tipo) { params = params.set('tipo', entity.tipo.toString()); }
    if (null != entity.fechaultdeposito) { params = params.set('fechaultdeposito', entity.fechaultdeposito.toString()); }
    if (null != entity.fechavencimiento) { params = params.set('fechavencimiento', entity.fechavencimiento.toString()); }
    if (null != entity.plazo) { params = params.set('plazo', entity.plazo.toString()); }
    if (null != entity.formapagorendimientos) { params = params.set('formapagorendimientos', entity.formapagorendimientos.toString()); }
    if (null != entity.tasa) { params = params.set('tasa', entity.tasa.toString()); }
    if (null != entity.monto) { params = params.set('monto', entity.monto.toString()); }
    if (null != entity.intdevnopagcierremes) { params = params.set('intdevnopagcierremes', entity.intdevnopagcierremes.toString()); }
    if (null != entity.saldototal) { params = params.set('saldototal', entity.saldototal.toString()); }
    if (null != entity.estadoid) { params = params.set('estadoid', entity.estadoid.toString()); }
    if (null != entity.estadodesc) { params = params.set('estadodesc', entity.estadodesc.toString()); }
    if (null != entity.municipioid) { params = params.set('municipioid', entity.municipioid.toString()); }
    if (null != entity.municipiodesc) { params = params.set('municipiodesc', entity.municipiodesc.toString()); }
    if (null != entity.localidadid) { params = params.set('localidadid', entity.localidadid.toString()); }
    if (null != entity.localidaddesc) { params = params.set('localidaddesc', entity.localidaddesc.toString()); }
    if (null != entity.sucursalid) { params = params.set('sucursalid', entity.sucursalid.toString()); }
    if (null != entity.sucursaldesc) { params = params.set('sucursaldesc', entity.sucursaldesc.toString()); }
    if (null != entity.escolaridadid) { params = params.set('escolaridad', entity.escolaridadid.toString()); }
    if (null != entity.escolaridaddesc) { params = params.set('escolaridaddesc', entity.escolaridaddesc.toString()); }
    if (null != entity.clasificacion_nac) { params = params.set('clasificacion_nac', entity.clasificacion_nac.toString()); }
    if (null != entity.estadocivildesc) { params = params.set('estadocivildesc', entity.estadocivildesc.toString()); }
    if (null != entity.ocupaciondesc) { params = params.set('ocupaciondesc', entity.ocupaciondesc.toString()); }
    if (null != entity.arreglo_sucursal) { params = params.set('arreglo_sucursal', entity.arreglo_sucursal.toString()); }
    if (null != entity.arreglo_anios) { params = params.set('arreglo_anios', entity.arreglo_anios.toString()); }
    if (null != entity.arreglo_mes) { params = params.set('arreglo_mes', entity.arreglo_mes.toString()); }
    if (null != entity.sexoid) { params = params.set('sexoid', entity.sexoid.toString()); }
    
    
    return params;
  }
}