import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {CuentaEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class CuentaService extends Service<CuentaEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/Cuenta', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: CuentaEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_Cuenta) { params = params.set('Id_Cuenta', entity.Id_Cuenta.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Tipo) { params = params.set('Tipo', entity.Tipo.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }

    
    return params;
  }
}
