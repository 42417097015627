import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EsqueletoPeticionModel} from '../../../model/esqueletoPeticion.model';
import {FiltrosService} from '../../filtros/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class ColocacionPerfilService {
  private apiURL = environment.apiDevURL;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }
  /** Consultas para tablas de sucursales */
  public getTendenciaGraficaGeneroSucursales(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'genero',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    if(sucursal != "CONSOLIDADO"){
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        },
        sucursales: [{
          campo: "sucursalCredito.keyword",
          valor: sucursal
        }],
      };
    }else{
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "sexo.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaClasificacionSucursales(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'producto',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }

      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
        sucursales: con,
        perspectivas:per,
      };


    peticion.groupby = {
      perspectiva: {
        campo: "nombreProducto.keyword",
        subagrupacion:{
          operacion: "contar",
          campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    //console.log(''+JSON.stringify(body));
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEstatusSucursales(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
     const valoresMeses = [];
     for (const anio of valoresAnios) {
       valoresMeses.push(anio.toString() + "-" + ultimoValor);
     }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: 'estado1.keyword',
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        },
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacionSucursales(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'perfil',
      'ocupacion',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion:{
          operacion: "contar",
          campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    //console.log(''+JSON.stringify(body));
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaFinalidadSucursales(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string,ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    if(ocupacion != ""){
      per.push( {
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "finalidad.keyword",
        subagrupacion:{
          operacion: "contar",
          campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaServicioSucursales(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string,ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    if(ocupacion != ""){
      per.push( {
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "servicio.keyword",
        subagrupacion:{
          operacion: "contar",
          campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaMunicipioSucursales(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string,ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'municipio',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    if(ocupacion != ""){
      per.push( {
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "municipio1.keyword",
        subagrupacion:{
          operacion: "contar",
          campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Consultas normales */
  public getTendenciaGraficaGenero(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'genero',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    if(sucursal != "CONSOLIDADO"){
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        },
        sucursales: [{
          campo: "sucursalCredito.keyword",
          valor: sucursal
        }],
      };
    }else{
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "sexo.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaClasificacion(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'producto',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        },
        sucursales: [{
          campo: "sucursalCredito.keyword",
          valor: sucursal
        }],
      };
    }else{
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "nombreProducto.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEstatus(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    /**
     * Podemos filtrar incluso por perspectiva, solo en el filter hay que añadir otra propiedad como:
     * "perspectiva": {
     *    "campo": "nombreProducto.keyword"
     * }
     */
     const valoresMeses = [];
     for (const anio of valoresAnios) {
       valoresMeses.push(anio.toString() + "-" + ultimoValor);
     }
    if (sucursal != "CONSOLIDADO") {
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        },
        sucursales: [{
            campo: 'sucursalCredito.keyword',
            valor: sucursal
          }]
      };
    }else {
      peticion.filter = {
        aniosmeses: {valores: valoresMeses}
      };
    }
    peticion.groupby = {
      perspectiva: {
        // Preguntar a Lalo por el formato 'nombreProducto.keyword'
        campo: 'estado1.keyword',
          subagrupacion: {
          operacion: 'contar',
            campo: 'numeroSocio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacion(valoresAnios: number[], ultimoValor: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'perfil',
      'ocupacion',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        },
        sucursales: [{
          campo: "sucursalCredito.keyword",
          valor: sucursal
        }],
      };
    }else{
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaFinalidad(valoresAnios: number[], ultimoValor: string, sucursal: string ): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        },
        sucursales: [{
          campo: "sucursalCredito.keyword",
          valor: sucursal
        }],
      };
    }else{
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "finalidad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaServicio(valoresAnios: number[], ultimoValor: string, sucursal: string ): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        },
        sucursales: [{
          campo: "sucursalCredito.keyword",
          valor: sucursal
        }],
      };
    }else{
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "servicio.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaMunicipio(valoresAnios: number[], ultimoValor: string, sucursal: string ): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'municipio',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        },
        sucursales: [{
          campo: "sucursalCredito.keyword",
          valor: sucursal
        }],
      };
    }else{
      peticion.filter = {
        aniosmeses:{
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "municipio1.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Consultas filtrando por genero */ 
  public getTendenciaGraficaClasificacionPerspectiva(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'producto',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }

      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
        sucursales: con,
        perspectivas:per,
      };


    peticion.groupby = {
      perspectiva: {
        campo: "nombreProducto.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    //console.log(''+JSON.stringify(body));
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEstatusPerspectiva(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    /**
     * Podemos filtrar incluso por perspectiva, solo en el filter hay que añadir otra propiedad como:
     * "perspectiva": {
     *    "campo": "nombreProducto.keyword"
     * }
     */
     const valoresMeses = [];
     for (const anio of valoresAnios) {
       valoresMeses.push(anio.toString() + "-" + ultimoValor);
     }
    if (sucursal != "CONSOLIDADO") {
      if(genero == ""){
        peticion.filter = {
          aniosmeses: {
            valores: valoresMeses
          },
          sucursales: [{
              campo: 'sucursalCredito.keyword',
              valor: sucursal
            }]
        };
      }else{
        peticion.filter = {
          aniosmeses: {
            valores: valoresMeses
          },
          sucursales: [{
              campo: 'sucursalCredito.keyword',
              valor: sucursal
          }],
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }else {
      if(genero == ""){
        peticion.filter = {
          aniosmeses: {valores: valoresMeses}
        };
      }else{
        peticion.filter = {
          aniosmeses: {valores: valoresMeses},
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
      
    }
    peticion.groupby = {
      perspectiva: {
        // Preguntar a Lalo por el formato 'nombreProducto.keyword'
        campo: 'estado1.keyword',
        subagrupacion: {
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
              operacion: 'contar',
              campo: 'numeroSocio'
            }
          }
        },
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacionPerspectiva(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'perfil',
      'ocupacion',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }else{
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }
    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaFinalidadPerspectiva(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string ): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }else{
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }
    peticion.groupby = {
      perspectiva: {
        campo: "finalidad.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaServicioPerspectiva(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string ): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }else{
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }
    peticion.groupby = {
      perspectiva: {
        campo: "servicio.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaMunicipioPerspectiva(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string ): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'municipio',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(sucursal != "CONSOLIDADO"){
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "sucursalCredito.keyword",
            valor: sucursal
          }],
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }else{
      if(genero == ""){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas:[{
            campo: "sexo.keyword",
            valor: genero
          }],
        };
      }
    }
    peticion.groupby = {
      perspectiva: {
        campo: "municipio1.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** consultas filtrando por clasificación */
  public getTendenciaGraficaEstatusPerspectiva2(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
     const valoresMeses = [];
     for (const anio of valoresAnios) {
       valoresMeses.push(anio.toString() + "-" + ultimoValor);
     }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        // Preguntar a Lalo por el formato 'nombreProducto.keyword'
        campo: 'estado1.keyword',
        subagrupacion: {
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
              operacion: 'contar',
                campo: 'numeroSocio'
              }
          }
        },
      }
    };
    const body = JSON.stringify(peticion);
    //console.log(''+JSON.stringify(body));
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacionPerspectiva2(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'perfil',
      'ocupacion',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaFinalidadPerspectiva2(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "finalidad.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaServicioPerspectiva2(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "servicio.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaMunicipioPerspectiva2(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'municipio',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "municipio1.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Consultas filtrado estatus */
  public getTendenciaGraficaOcupacionPerspectiva3(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'perfil',
      'ocupacion',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    //console.log(''+JSON.stringify(body));
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaFinalidadPerspectiva3(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "finalidad.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaServicioPerspectiva3(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "servicio.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaMunicipioPerspectiva3(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'municipio',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "municipio1.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Consultas filtrado por ocupación */
  public getTendenciaGraficaFinalidadPerspectiva4(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string,ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    if(ocupacion != ""){
      per.push( {
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "finalidad.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaServicioPerspectiva4(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string,ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'finalidad',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    if(ocupacion != ""){
      per.push( {
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "servicio.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaMunicipioPerspectiva4(valoresAnios: number[], ultimoValor: string, sucursal: string, genero: string, clasificacion: string, estatus: string,ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'municipio',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursal
      });
    }
    var per :any = [];
    if(genero != ""){
      per.push({
        campo: "sexo.keyword",
        valor: genero
      });
    }
    if(clasificacion != ""){
     per.push( {
        campo: "nombreProducto.keyword",
        valor: clasificacion
      });
    } 
    if(estatus != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: estatus
      });
    }
    if(ocupacion != ""){
      per.push( {
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "municipio1.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "sexo.keyword",
            subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
}
