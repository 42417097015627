<div class="w3-container w3-contenedor-template  " style="height: 7vh;">
  <div class="w3-row ">
    <!-- TITULO DEL COMPONENTE -->
    <div class="w3-quarter padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
      </div>
    </div>
    <!-- FIN TITULO DEL COMPONENTE -->

    <!-- FILTROS-->
    <div class="w3-rest ">
      <!-- DEFINICION FILTROS -->
      <div class="w3-noventa2 w3-row ">
        <!-- AÑOS -->
        <div class="w3-half padreizquierda">
          <!-- FILTRO AÑOS DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda  ">
            Año
            <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios"
                    aria-label="Example icon-button with a menu">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-tiny"  #menuanios="matMenu" >
              <ng-container *ngFor="let anio of data_anios">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                               >
                    {{anio.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO AÑOS DISPONIBLES -->

          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest  ">
            <div class="w3-row padreizquierda" id="contenedor_btnanios">
              <div class="w3-row hijoizquierda ">
                <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':
                  anios.check, 'w3-hide':!anios.check}"
                     *ngFor="let anios of data_anios_selec"
                     class="filtroMesAnioSeleccionado">
                  {{anios.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>
        <!-- FIN AÑOS -->

        <!-- MESES -->
        <div [class]="VerificarAniosSeleccionados()" >
          <!-- FILTRO MESES DISPONIBLES -->
          <div class="w3-half hijoizquierda  ">
            Mes
            <button class="w3-button w3-tiny"
                    [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-tiny"  #menumeses="matMenu" >
              <ng-container *ngFor="let mes of data_meses">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}" aria-label="First checkbox"
                                >
                    {{mes.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO MESES DISPONIBLES -->

          <!-- MES SELECCIONADO -->
          <div class="w3-rest  ">
            <div class="w3-row padreizquierda" id="contenedor_btnmeses">
              <div [class]="ContarMeses()" >
                <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':
                  meses.check, 'w3-hide ':!meses.check}"
                     *ngFor="let meses of data_meses_selec"
                     class="filtroMesAnioSeleccionado">
                  {{meses.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        <!-- FIN MESES -->
      </div>
      <!-- FIN DEFINICION FILTROS -->

      <!-- BORRAR FILTROS -->
      <div class="w3-rest w3-right padreizquierda ">
        <button class="hijoizquierdad" mat-icon-button (click)="quitar_filtros()">
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
            <img  src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
      <!-- FIN BORRAR FILTROS -->
    </div>
    <!-- FIN FILTROS-->
  </div>
</div>

<!-- cuerpo rantabilidad tabla-->
<div id="cont_template1" class="w3-container w3-contenedor-template  " style="height: 83vh;">
    <div class="w3-row w3-contenedor-template w3-border" style="height: 58vh;">
        <div class="w3-row w3-white" style="height: 100%;">
          <!-- ENCABEZADO DEL CONTENDOR DE LAS GRAFICAS-->
          <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
              <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
              <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
                  <div class="hijoizquierdaTitle">
                    <span class="w3-margin-left-contenido">Rentabilidad</span>
                    <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                    <!--<span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>-->
                  </div>
              </div>
              <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
              <!-- SEGUNDO DIV EN EL QUE SE PONDRÀ EL APARTADO DEL MENÙ Y PARA MOSTRAR LOS LABELS DE LAS GRÀFICAS-->
            <div class="w3-third w3-right-align_rest ">
              <!--DIV EN EL QUE SE PONDRÀ EL GLOBITO PARA MOSTRAR LOS LABEL DE LAS GRÀFICAS CUANDO SE TENGA-->
              <div class="w3-right hijoderechaTitle">
                <!-- LABEL PARA LAS GRÀFICAS-->
                <mat-icon class=" w3-disabled material-icons-outlined w3-zindex"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                </mat-icon>
                <!-- LABEL PARA LAS GRÀFICAS-->
                <!-- MENU PARA APLICAR LOS FILTROS-->
                <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                  more_vert</mat-icon>
                <!-- MENU PARA APLICAR LOS FILTROS-->
              </div>
              <!-- ESTOS SON LOS CUERPOS DEL MENU DONDE SE AGREGAN LAS PERSPECTIVAS-->
              <mat-menu #menu="matMenu">
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                    <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                    <span>Gráficas</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button *ngIf="flag_perspectivas" class="mat-menu-item " mat-menu-item [matMenuTriggerFor]="perspectivas">
                    <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
                    </mat-icon>
                    <span>Perspectiva</span>
                  </button>
                  <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
                    <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
                    </mat-icon>
                    <span>Exportar</span>
                  </button>
                  <button (click)="modal('id')" class="mat-menu-item" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                    <span>Ampliar</span>
                  </button>
                  <button *ngIf="contador_mes > 1 || contador_perspectiva > 0" (click)="quitar_filtros()" class="mat-menu-item " mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"><img style="margin-top: -10px;"
                        src="assets/img/graficos/sinfiltro.png"></mat-icon>
                    <span>Eliminar Filtros</span>
                  </button>
                  <button *ngIf="contador_mes > 1 || contador_perspectiva > 0" (click)="quitar_filtros()" mat-menu-item>
                    <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                      arrow_back
                    </mat-icon>
                    <span>Regresar</span>
                  </button>

              </mat-menu>
              <mat-menu #graficas="matMenu">
                <button (click)="cambiarGrafica('barras')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Barras</span>
                </button>
                <button (click)="cambiarGrafica('lineal')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                  <span>Lineal</span>
                </button>
              </mat-menu>
              <mat-menu #exportar="matMenu">

              </mat-menu>
              <mat-menu #perspectivas="matMenu">
                <button *ngIf="isNotActiveFilter('bandas de morosidad')" class="" (click)="addPerspectiva('bandas de morosidad')" mat-menu-item>
                  <span>Bandas de morosidad</span>
                </button>
                <button *ngIf="isNotActiveFilter('estatus')" (click)="addPerspectiva('estatus')" mat-menu-item>
                  <span>Estatus</span>
                </button>
              </mat-menu>
              <mat-menu #geografico="matMenu">
                <button class="" (click)="addPerspectiva('geografico_estado')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>Estado </span>
                </button>
                <button class="" (click)="addPerspectiva('geografico_municipio')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>Municipio</span>
                </button>
                <button class="" (click)="addPerspectiva('geografico_localidad')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>Localidad</span>
                </button>
              </mat-menu>
              <!-- ESTOS SON LOS CUERPOS DEL MENU-->
            </div>
            <!-- SEGUNDO DIV EN EL QUE SE PONDRÀ EL APARTADO DEL MENÙ Y PARA MOSTRAR LOS LABELS DE LAS GRÀFICAS-->
          </div>
          <div class="w3-row mat-elevation-z8 w3-scroll" style="height: 52vh;">
            <table mat-table [dataSource]="dataSource" style="width: 100%;">
              <!-- Position Column -->
              <ng-container matColumnDef="sucursal">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> SUCURSAL </th>
                <td mat-cell *matCellDef="let element"> 
                  <span>
                    {{element.sucursal}} 
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="montocaptacion">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> MONTO CAPTACIÓN </th>
                <td class="w3-row" mat-cell *matCellDef="let element"> 
                    <div class="w3-col s1 w3-left">
                      <span class="w3-left"> $</span>
                    </div>
                    <div class="w3-col s9 w3-center">
                      <span class=""> {{ convertir_moneda(element.montocaptacion) }}</span>
                    </div>
                </td>
              </ng-container>
              
              <ng-container matColumnDef="gastosinteres">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> GASTO POR INTERESES </th>
                <td class="w3-row" mat-cell *matCellDef="let element"> 
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.gastosinteres)}} </span>
                  </div>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="montocarteracredito">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> MONTO CARTERA DE CRÉDITO </th>
                <td class="w3-row" mat-cell *matCellDef="let element"> 
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.montocarteracredito)}} </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="ingresosinteres">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> INGRESOS POR INTERESES </th>
                <td class="w3-row" mat-cell *matCellDef="let element"> 
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.ingresosinteres)}} </span>
                  </div>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="marcofinanciero">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> MARGEN FINANCIERO </th>
                <td class="w3-center" mat-cell *matCellDef="let element"> 
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.marcofinanciero)}} </span>
                  </div>
                </td>
              </ng-container>

              <ng-container style="width: 10%" matColumnDef="estimacionprc">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> 
                  ESTIMACIÓN PREVENTIVA
                  RIESGO CREDITICIO 
                </th>
                <td class="w3-row" mat-cell *matCellDef="let element"> 
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.estimacionprc)}} </span>
                  </div>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="otrosingresosoperacion">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> OTROS INGRESOS (EGRESOS) DE LA OPERACIÓN </th>
                <td class="w3-row" mat-cell *matCellDef="let element">
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.otrosingresosoperacion)}} </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="gastisadmon">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> GASTOS ADMINISTRACIÓN </th>
                <td class="w3-row" mat-cell *matCellDef="let element">
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.gastisadmon)}} </span>
                  </div>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="resultadoneto">
                <th class="w3-center" mat-header-cell *matHeaderCellDef> RESULTADO NETO SUCURSAL </th>
                <td class="w3-row" mat-cell *matCellDef="let element"> 
                  <div class="w3-col s1 w3-left">
                    <span class="w3-left"> $</span>
                  </div>
                  <div class="w3-col s9 w3-center">
                    <span class="w3-right"> {{convertir_moneda(element.resultadoneto)}} </span>
                  </div>
                </td>
              </ng-container>

              <tr class="w3-table-trtd-rentabilidad" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr class="w3-table-trtd-rentabilidad" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
    </div>

    <!-- Parte de abajo-->
    <div class="w3-row mt-2 w3-contenedor-template" style="height: 22vh;">
      <div class="w3-third  " style="height: 21vh;">
        <div class="w3-container">
          <div class="w3-row padreizquierda" style="height: 5vh;">
            <div class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
              CIRCUNVALACIÓN
            </div>
          </div>
          <div class="w3-row w3-center" >
            <div class="w3-row padreRentabilidad">
              <div class="w3-row hijoRentabilidad w3-total-cantidadRentabilidad w3-balance-bluep">
                $ 5,987,264.22
              </div>

            </div>
          </div>
          <div class="w3-row" style="height: 5vh;">
            <div class="w3-half w3-center">
              A OCT 21
            </div>
            <div class="w3-half w3-center">
              +- RENTABLE
            </div>
          </div>
        </div>
      </div>
      <div class="w3-third  " style="height: 21vh;">
        <div class="w3-container">
          <div class="w3-row padreizquierda" style="height: 5vh;">
            <div class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
              CONSOLIDADO
            </div>
          </div>
          <div class="w3-row w3-center" >
            <div class="w3-row padreRentabilidad">
              <div class="w3-row hijoRentabilidad w3-total-cantidadRentabilidad w3-balance-bluep">
                $ 62,342,467.34
              </div>

            </div>
          </div>
          <div class="w3-row" style="height: 5vh;">
            <div class="w3-half w3-center">
              A OCT 21
            </div>
            <div class="w3-half w3-center">
              
            </div>
          </div>
        </div>
      </div>
      <div class="w3-third  " style="height: 21vh;">
        <div class="w3-container">
          <div class="w3-row padreizquierda" style="height: 5vh;">
            <div class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
              VALLARTA
            </div>
          </div>
          <div class="w3-row w3-center" >
            <div class="w3-row padreRentabilidad">
              <div class="w3-row hijoRentabilidad w3-total-cantidadRentabilidad w3-balance-bluep"> <!--w3-balance-red-->
                -$ 26,344.10
              </div>

            </div>
          </div>
          <div class="w3-row" style="height: 5vh;">
            <div class="w3-half w3-center">
              A OCT 21
            </div>
            <div class="w3-half w3-center">
              - RENTABLE
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
