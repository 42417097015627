import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data-service/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private serverUrl = 'https://api-adminclient.rflatina.com/rfl/api/v1/'

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService
  ) { }

  login(email, password) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('origen', 'aleema');
    const body = JSON.stringify({ email, password });
    return this.httpClient.post(this.serverUrl + 'login', body, { headers: headers }).pipe(
      map((response: any) => {
        // Se guarda en sesion la informacion del usuario
        sessionStorage.setItem('auth_token', response.token);
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  logout(): void {
    sessionStorage.clear();
  }

  isLoggedIn(): boolean {
    return !!sessionStorage.getItem('auth_token');
  }

  forgotPassword(email) {
    return this.httpClient.get(this.serverUrl + 'reset?email=' + email);
  }

}
