import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EsqueletoPeticionModel} from '../../../model/esqueletoPeticion.model';
import {FiltrosService} from '../../filtros/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class EstadisticoService {

  private apiURL = environment.apiDevURL;
  private headers = new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }

  public getTendenciaGraficaPorAnio(valoresAnios: number[], campo: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'aseguradora'
    );
    const valoresMeses = valoresAnios;
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: campo
        }}};
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
}
