import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent } from '@angular/common/http';
import { IService } from './serviceTemplate';
import { Loggers } from '../../util/app-util';
import { environment } from './../../../environments/environment.prod';
import { stringify } from '@angular/compiler/src/util';
// import { ConsoleReporter } from 'jasmine';
export abstract class Service<T> implements IService<T> { // T: --> Entity
    protected headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    protected httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
    };    
    //protected urlBase: String = 'http://aleema.rflatina.com:8080/qubos/webapi/'; //producción qubos
    //protected urlBase_Riesgos: String = 'http://bs.rflatina.com:8085/sicws/webapi'; //producción Riesgos
    protected urlBase: String = environment.baseUrl_aleema; //producción qubos
    protected urlBase_Riesgos: String = environment.baseUrlRiesgos; //producción Riesgos
    //protected urlBase: String = 'http://localhost:8080/qubos/webapi/'; //Pruebas qubos

    protected serviceUrl: string;
    protected serviceUrl_Riesgos: string;
    protected usuario_prueba = "admin";
    constructor(
      protected name: string,
      protected serviceUrlApi: string,
      protected http: HttpClient,
      protected messageService: MessageService,
      protected logger: Loggers
      ) {
          this.serviceUrl = this.urlBase + this.serviceUrlApi;
          this.serviceUrl_Riesgos = this.urlBase_Riesgos + this.serviceUrlApi;
    }
    protected abstract getParams(entity: T): HttpParams;
    public get(entity: T, bnShow: boolean = false): Observable<T[]> {
        return this.getByUrl(this.serviceUrl, entity, bnShow);
    }
    public getByAddUrl(strUrl: string, entity: T, bnShow: boolean = false): Observable<T[]> {
      //console.log(JSON.stringify(`${this.serviceUrl}/${strUrl}`))
      return this.getByUrl(`${this.serviceUrl}/${strUrl}`, entity, bnShow);
    }
    public getByAddUrl_Riesgo(strUrl: string, entity: T, bnShow: boolean = false): Observable<T[]> {
        return this.getByUrl(`${this.serviceUrl_Riesgos}/${strUrl}`, entity, bnShow);
      }
    public getByUrl(strUrl: string, entity: T, bnShow: boolean = false): Observable<T[]> {
        if (bnShow) { this.messageService.indefinite.next('Cargando datos ...'); }
        const httpOptions = { headers: this.headers, params: this.getParams(entity) };
        return this.http.get<T[]>(strUrl, httpOptions).pipe(
            tap(entities => {
                this.logger.log(`${this.name}: fetched ${entities}`, false);
                if (bnShow) { this.messageService.close.next('Datos cargados ...'); }
            }),
            catchError(this.handleError('get', []))
        );
    }
    public append(entities: T[], bnShow: boolean = true): Observable<T[]> {
      this.logger.log('Agregando elemento...', bnShow);
        return this.http.post<T[]>(this.serviceUrl, entities, this.httpOptions).pipe(
            tap((entitiesSaved: T[]) => this.logger.log(`${this.name}: Elemento Agregado ${entitiesSaved}`, false)),
            tap((entitiesSaved: T[]) => this.logger.log(`Elemento Agregado ... `, bnShow)),
            catchError(this.handleError('append', []))
        );
    }
    public save(entities: T[], bnShow: boolean = true): Observable<T[]> {   // update
      this.logger.log('Guardando datos...', bnShow);
        return this.http.put<T[]>(this.serviceUrl, entities, this.httpOptions).pipe(
            tap((entitiesSaved: T[]) => this.logger.log(`${this.name}: Datos guardados ${entitiesSaved}`, false)),
            tap((entitiesSaved: T[]) => this.logger.log(`Datos guardados ... `, bnShow)),
            catchError(this.handleError('save', []))
        );
    }
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    protected handleError<TT>(operation = 'operation', result?: TT) {
        return (error: any): Observable<TT> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            console.error(error.status); // log to console instead
            console.error(error.message); // log to console instead
            console.error(error.message); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.logError(`${this.name}: ${operation} Error: ${error.message}`, true);
            this.logger.logError(`${this.name}: ${operation}; Error: ${error.status} ${error.error}`, true);

            // Let the app keep running by returning an empty result.
            // return of(null); // of(result as TT);
            return of(result as TT);
        };
    }

    /** Log a Service message with the MessageService * /
    protected log(message: string, show: boolean) {
        console.log(message);
        if (show) {
            this.messageService.subject.next(message);
        }
    }

    /** Log a Service message with the MessageService * /
    protected logError(message: string, show: boolean) {
        console.log(message);
        if (show) {
            this.messageService.subjectError.next(message);
        }
    }
    // */
}
