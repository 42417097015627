<!--Encabezado de la página-->
<div class="w3-container w3-contenedor-template w3-light-grey" style="height: 6vh;">
  <!--Titulo de la página-->
  <div class="w3-quarter padreizquierda">
    <div class="w3-row hijoizquierda">
      <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b>Riesgo de crédito</b> </span>
      <span class="w3-text-deep-orange"> &bull; </span>
      <span class="w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> Tendencia</span>
    </div>
  </div>
  <!--Filtros de años-->
  <div class="w3-quarter w3-margin-left-filter">
    <div class="w3-quarter hijoizquierda">
      <button class="w3-button w3-right" [mat-menu-trigger-for]="menuAniosP2">
        Año
        <mat-icon class="iconoMen">keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu class="w3-medium" #menuAniosP2="matMenu" xPosition="before">
        <button mat-menu-item *ngFor="let an of aniosDisponibles">
          <mat-checkbox [checked]="an.check" (click)=" $event.stopPropagation();" (change)="anioChange(an);">{{an.name}}
          </mat-checkbox>
        </button>
      </mat-menu>
    </div>
    <div class="w3-rest hijoizquierda" style="height: 4vh;">
      <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
        <div class="w3-row hijoizquierda chips">
          <div [ngClass]="anfil.check?
          'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
            *ngFor="let anfil of aniosDisponibles"
            class="filtroMesAnioSeleccionado">
            <span class="contenidoChips">{{anfil.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Filtros de meses-->
  <div class="w3-quarter w3-margin-left-filter">
    <div class="w3-quarter hijoizquierda">
      <button class="w3-button w3-right" [mat-menu-trigger-for]="menumeses">
        Mes
        <mat-icon class="iconoMen">keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu class="w3-medium" #menumeses="matMenu" xPosition="before">
        <button mat-menu-item *ngFor="let mes of mesesDisponibles">
          <mat-checkbox [checked]="mes.check" (click)=" $event.stopPropagation();"
            (change)="mesChange(mes, mesesDeAnioDisponibles);">{{mes.name}}</mat-checkbox>
        </button>
      </mat-menu>
    </div>
    <div class="w3-rest hijoizquierda" style="height: 4vh;">
      <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
        <div class="w3-row hijoizquierda chips">
          <div [ngClass]="mesfil.check?
          'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
            *ngFor="let mesfil of mesesDisponibles"
            class="filtroMesAnioSeleccionado">
            <span class="contenidoChips">{{mesfil.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Borrar filtros-->
  <div class="w3-rest w3-right">
    <button mat-icon-button>
      <mat-icon title="limpiar_filtros" (click)="quitar_filtros()">
        <img src="assets/img/graficos/eraser.png">
      </mat-icon>
    </button>
  </div>
</div>
<!--División de la pantalla-->
<div class="w3-row">
  <div class="w3-twothird" id="Zona1">
    <div class="w3-row">
      <!--Titulo y subtuitulo de la grafica de deudores-->
      <div class="w3-twothird hijoIzquierdaTitle w3-titulo-contenidomenu">
        <div class="hijoizquierdaTitle">
          <span class="w3-margin-left-contenido" *ngIf="gtablas">{{milTab1}}{{Title}}</span>
          <span class="w3-margin-left-contenido" *ngIf="!gtablas">{{Title}}</span>
          <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
          <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
          <span class=" w3-margin-left-filter w3-subtitulo">{{Subtitulo_grafica}}</span>
        </div>
      </div>
      <!--Menú de la grafica de deudores-->
      <div class="w3-rest w3-right">
        <div class="w3-right hijoderechaTitle">
          <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliar()" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
          <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuGB">more_vert</mat-icon>
        </div>
        <mat-menu #menuGB="matMenu" xPosition="before">
          <button mat-menu-item>
            <mat-icon>search</mat-icon>
            <span>Buscar</span>
          </button>
          <button mat-menu-item [matMenuTriggerFor]="graficas">
            <mat-icon>assessment</mat-icon>
            Graficas
          </button>
          <button mat-menu-item [matMenuTriggerFor]="Deudores">
            <mat-icon>tag</mat-icon>
            <span>No. Deudores</span>
          </button>
          <button mat-menu-item [matMenuTriggerFor]="Perspectiva">
            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree</mat-icon>Perspectiva
          </button>
          <!--[matMenuTriggerFor]="Exportar"-->
          <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" *ngIf="gtablas" [matMenuTriggerFor]="moneda">
            <mat-icon [ngStyle]="{'color':'gray'}">paid</mat-icon>
            <span>mil/pes</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item *ngIf="!gtablas" (click)="export_img(1)">
            <mat-icon>description</mat-icon>
            <span>Exportar</span>
          </button>
          <button mat-menu-item *ngIf="gtablas" (click)="export_csv()">
            <mat-icon>description</mat-icon>
            <span>Exportar</span>
          </button>
        </mat-menu>
        <mat-menu #Perspectiva="matMenu">
          <button (click)="perspectivaC(2)" mat-menu-item>Mayores deudores</button>
          <button (click)="perspectivaC(1)" mat-menu-item>Grupos de riesgo</button>
        </mat-menu>
        <mat-menu #moneda="matMenu">
          <button mat-menu-item (click)="CambMon('Mil',1)">
            <span>Miles</span>
          </button>
          <button mat-menu-item (click)="CambMon('Pesos',1)">
            <span>Pesos</span>
          </button>
        </mat-menu>
        <mat-menu #graficas="matMenu">
          <button mat-menu-item (click)="gbarrasHor()">Barras horizontal</button>
          <button mat-menu-item (click)="gbarrasVer()">Barras vertical</button>
          <button mat-menu-item (click)="gTabla()">Tabla</button>
        </mat-menu>
        <mat-menu #Deudores="matMenu">
          <button mat-menu-item>
            <mat-checkbox [checked]="ndeud10" (click)="$event.stopPropagation();" (change)="Ndeudores(10)">10
            </mat-checkbox>
          </button>
          <button mat-menu-item>
            <mat-checkbox [checked]="ndeud20" (click)="$event.stopPropagation();" (change)="Ndeudores(20)">20
            </mat-checkbox>
          </button>
          <button mat-menu-item>
            <mat-checkbox [checked]="ndeud30" (click)="$event.stopPropagation();" (change)="Ndeudores(30)">30
            </mat-checkbox>
          </button>
          <button mat-menu-item>
            <mat-checkbox [checked]="ndeud40" (click)="$event.stopPropagation();" (change)="Ndeudores(40)">40
            </mat-checkbox>
          </button>
          <button mat-menu-item *ngIf="!persepectiva">
            <mat-checkbox [checked]="ndeud50" (click)="$event.stopPropagation();" (change)="Ndeudores(50)">50
            </mat-checkbox>
          </button>
        </mat-menu>
        <mat-menu #Exportar="matMenu">
          <button mat-menu-item>PDF</button>
          <button mat-menu-item>Word</button>
        </mat-menu>
      </div>
    </div>

    <div class="w3-row w3-withe">
      <div style="width: 96%; height: 76vh; margin-left: 1vh;">
        <ngx-charts-bar-horizontal *ngIf="gbarrashor" [scheme]="colorScheme2" [results]="multi2" [gradient]="gradient"
          [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks" [maxYAxisTickLength]="maxYAxisTickLength"
          [xAxis]="xAxis" [yAxis]="yAxis" [legendPosition]="legendPosition" [showXAxisLabel]="showXAxisLabel"
          [rotateXAxisTicks]="rotateXAxisTicks" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
        </ngx-charts-bar-horizontal>
        <div class="w3-encabezado-table w3-scroll" *ngIf="gtablas">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> No. </th>
                <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.position}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Nombre del Socio </th>
                <td mat-cell style="width: 75%;" *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Saldo </th>
                <td mat-cell style="width: 20%;" *matCellDef="let element"> $ {{element.weight}} </td>
              </ng-container>
              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="w3-elemento-titulo-table">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <ngx-charts-bar-vertical *ngIf="gbarrasver" [scheme]="colorScheme2" [results]="multi2" [gradient]="gradient"
          [xAxis]="yAxis" [yAxis]="xAxis" [legendPosition]="legendPosition" [showXAxisLabel]="showYAxisLabel"
          [showYAxisLabel]="showXAxisLabel" [xAxisLabel]="yAxisLabel" [yAxisLabel]="xAxisLabel"
          (select)="onSelect($event)">
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </div>
  <div class="w3-rest w3-row w3-light-grey">
    <!--Zona de las Tarjetas-->
    <div class="w3-row w3-light-grey">
      <div class="w3-right" style="width: 98%;">

        <div class="w3-row">

          <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 14vh;">
            <div class="w3-half">
              <div class="w3-row textLeft">
                <span class="w3-total-mayoresDeudores" style="color: #8B8E93;">Cartera total</span>
              </div>
              <div class="w3-row textLeft" style="vertical-align: bottom;">
                <span class="w3-subtituloMeta"> {{mesAnio}} </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-row w3-right" style="margin-top: 5vh; margin-right: 1vh;">
                <span class="w3-total-mayoresDeudores2 w3-balance-bluep"> $ {{formatearnormal2(total)}} </span>
              </div>
            </div>
          </div>
        </div>

        <div class="w3-row" style="margin-top: 2vh;">

          <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 14vh;">
            <div class="w3-half">
              <div class="w3-row textLeft">
                <span class="w3-total-mayoresDeudores" style="color: #8B8E93;">% vs cartera total</span>
              </div>
              <div class="w3-row textLeft" style="vertical-align: bottom;">
                <span class="w3-subtituloMeta"> {{mesAnio}} </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-row w3-right" style="margin-top: 5vh; margin-right: 1vh;">
                <span class="w3-total-mayoresDeudores2 w3-balance-bluep"> {{PorcentajeTot(suma)}} % </span>
              </div>
            </div>
          </div>

        </div>

        <div class="w3-row" style="margin-top: 2vh;">

          <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 14vh;">
            <div class="w3-half">
              <div class="w3-row textLeft">
                <span class="w3-total-mayoresDeudores" style="color: #8B8E93;">{{Mayores_deudores}}</span>
              </div>
              <div class="w3-row textLeft" style="vertical-align: bottom;">
                <span class="w3-subtituloMeta"> {{mesAnio}} </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-row w3-right" style="margin-top: 5vh; margin-right: 1vh;">
                <span class="w3-total-mayoresDeudores2 w3-balance-bluep"> $ {{formatearnormal2(suma.toFixed(2))}} </span>
              </div>
            </div>
          </div> 

        </div>
      </div>
    </div>
    <div class="w3-row w3-light-grey">
      <div class="w3-right" style=" width: 98%;" id="Zona2">
        <div class="w3-row w3-white" style="height: 6vh; margin-top: 2vh;">
          <!--Titulo y subtuitulo de la grafica de pie-->
          <div class="w3-twothird hijoIzquierdaTitle w3-titulo-contenidomenu">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">Cartera vs {{Mayores_deudores}}</span>
            </div>
          </div>
          <!--Menú de la grafica-->
          <div class="w3-rest w3-right">
            <div class="w3-right hijoderechaTitle">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarG2()" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuG3">more_vert</mat-icon>
            </div>
            <mat-menu #menuG3="matMenu" xPosition="before">
              <button mat-menu-item>
                <mat-icon>search</mat-icon>
                <span>Buscar</span>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="graficas3">
                <mat-icon>assessment</mat-icon>
                Graficas
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="export_img(2)">
                <mat-icon>description</mat-icon>
                <span>Exportar</span>
              </button>
            </mat-menu>
            <mat-menu #graficas3="matMenu">
              <button (click)="gPie()" mat-menu-item>Pie</button>
              <button (click)="gBarhor()" mat-menu-item>Barras Horizontales</button>
              <button (click)="gBarver()" mat-menu-item>Barras verticales</button>
            </mat-menu>
            <mat-menu #Exportar3="matMenu">
              <button mat-menu-item>PDF</button>
              <button mat-menu-item>Word</button>
            </mat-menu>
          </div>
        </div>
        <!--Graficas zona 3-->
        <div class="w3-row w3-white" style="height: 32vh;">
          <div class="w3-row w3-white" style="height: 25vh;">
            <ngx-charts-pie-chart class="piechart-googles" *ngIf="gpie" [scheme]="colorSchemePie" [results]="datos_pie"
              [gradient]="gradient" [legend]="labels" [legendTitle]="''" [legendPosition]="lePos"
              [doughnut]="isDoughnut" [arcWidth]="arcWidth">
            </ngx-charts-pie-chart>
            <ngx-charts-bar-vertical *ngIf="gbarver" [scheme]="colorScheme2" [results]="datos_pie" [gradient]="gradient"
              [xAxis]="xAxis" [yAxis]="yAxis" [legendPosition]="legendPosition" [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="yAxisLabel2" [yAxisLabel]="xAxisLabel2">
            </ngx-charts-bar-vertical>
            <ngx-charts-bar-horizontal *ngIf="gbarhor" [scheme]="colorScheme2" [results]="datos_pie"
              [gradient]="gradient" [xAxis]="xAxis" [yAxis]="yAxis" [legendPosition]="legendPosition"
              [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel2"
              [yAxisLabel]="yAxisLabel2">
            </ngx-charts-bar-horizontal>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- The Modal -->
<div id="graficasModal" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda "
          style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{Title}}</span><span
                class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span><span
                class=" w3-margin-left-filter w3-subtitulo">{{Subtitulo_grafica}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-rest ">
        <mat-icon (click)="cerrarAmp()" onclick="document.getElementById('graficasModal').style.display='none'"
          class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class=" w3-row" style="height: 65vh;">
      <div class="w3-row w3-margin" style="height: 56vh;">
        <div *ngIf="gbarrashorModal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-bar-horizontal class="w3-border" style="width: 100%;" [scheme]="colorScheme2" [results]="multi2"
            [gradient]="gradient" [xAxis]="xAxis" [yAxis]="yAxis" [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-horizontal>
        </div>
        <div *ngIf="gbarrasverModal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-bar-vertical class="w3-border" style="width: 100%;" [scheme]="colorScheme2" [results]="multi2"
            [gradient]="gradient" [xAxis]="xAxis" [yAxis]="yAxis" [legendPosition]="legendPosition"
            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-vertical>
        </div>
        <div *ngIf="gpieModal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-pie-chart class="piechart-googles" [scheme]="colorSchemePie" [results]="datos_pie"
            [gradient]="gradient" [legend]="labels" [legendPosition]="lePos" [doughnut]="isDoughnut"
            [arcWidth]="arcWidth">
          </ngx-charts-pie-chart>
        </div>
        <div *ngIf="gtablasModal" class="w3-rest w3-right" style="height: 55vh; width: 100%;">
          <div class="w3-encabezado-table w3-scroll">
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataSource" class=" w3-table">
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> No. </th>
                  <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.position}}
                  </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Nombre del Socio </th>
                  <td mat-cell style="width: 75%;" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="weight">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Saldo </th>
                  <td mat-cell style="width: 20%;" *matCellDef="let element"> $ {{element.weight}} </td>
                </ng-container>
                <!-- Symbol Column -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="w3-elemento-titulo-table">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="gbarhorModal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-bar-horizontal [scheme]="colorScheme2" [results]="datos_pie" [gradient]="gradient" [xAxis]="xAxis"
            [yAxis]="yAxis" [legendPosition]="legendPosition" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel2" [yAxisLabel]="yAxisLabel2">
          </ngx-charts-bar-horizontal>
        </div>
        <div *ngIf="gbarverModal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-bar-vertical [scheme]="colorScheme2" [results]="datos_pie" [gradient]="gradient" [xAxis]="xAxis"
            [yAxis]="yAxis" [legendPosition]="legendPosition" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="yAxisLabel2" [yAxisLabel]="xAxisLabel2">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
  </div>
</div>