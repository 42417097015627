import {MessageService} from './../../base/message.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from './../../base/service';
import {CarteraQubosEntity} from './../../../entities/base/DashboardEntities';

import {Loggers} from './../../../util/app-util';


@Injectable({
  providedIn: 'root'
})
export class CarteraQubosService extends Service<CarteraQubosEntity> {

  httpClient: HttpClient;

  constructor(
      http: HttpClient,
      messageService: MessageService,
      protected logger: Loggers
  ) {
      super('PatmirService', 'dashboard/carteraqubos', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: CarteraQubosEntity): HttpParams {
      let params: HttpParams = new HttpParams();
      if (null == entity) { return params; }

      if (null != entity.flagfecha) { params = params.set('flagfecha', entity.flagfecha.toString()); }
      if (null != entity.flag_grafica) { params = params.set('flag_grafica', entity.flag_grafica.toString()); }
      if (null != entity.creditoid) { params = params.set('creditoid', entity.creditoid.toString()); }
      if (null != entity.fechacierre) { params = params.set('fechacierre', entity.fechacierre.toString()); }
      if (null != entity.anio) { params = params.set('anio', entity.anio.toString()); }
      if (null != entity.mes) { params = params.set('mes', entity.mes.toString()); }
      if (null != entity.socioclienteid) { params = params.set('socioclienteid', entity.socioclienteid.toString()); }
      if (null != entity.ifid) { params = params.set('ifid', entity.ifid.toString()); }
      if (null != entity.instituciondesc) { params = params.set('instituciondesc', entity.instituciondesc.toString()); }
      if (null != entity.clavesociocliente) { params = params.set('clavesociocliente', entity.clavesociocliente.toString()); }
      if (null != entity.nocontrato) { params = params.set('nocontrato', entity.nocontrato.toString()); }
      if (null != entity.sucursal) { params = params.set('sucursal', entity.sucursal.toString()); }
      if (null != entity.sucursaldesc) { params = params.set('sucursaldesc', entity.sucursaldesc.toString()); }
      if (null != entity.clasificacion) { params = params.set('clasificacion', entity.clasificacion.toString()); }
      if (null != entity.tipoproducto) { params = params.set('tipoproducto', entity.tipoproducto.toString()); }
      if (null != entity.modalidadpago) { params = params.set('modalidadpago', entity.modalidadpago.toString()); }
      if (null != entity.fechaotorgamiento) { params = params.set('fechaotorgamiento', entity.fechaotorgamiento.toString()); }
      if (null != entity.montooriginal) { params = params.set('montooriginal', entity.montooriginal.toString()); }
      if (null != entity.fechavencimiento) { params = params.set('fechavencimiento', entity.fechavencimiento.toString()); }
      if (null != entity.tasaordinaria) { params = params.set('tasaordinaria', entity.tasaordinaria.toString()); }
      if (null != entity.tasamoratoria) { params = params.set('tasamoratoria', entity.tasamoratoria.toString()); }
      if (null != entity.plazo) { params = params.set('plazo', entity.plazo.toString()); }
      if (null != entity.frecpagocapital) { params = params.set('frecpagocapital', entity.frecpagocapital.toString()); }
      if (null != entity.frecpagointereses) { params = params.set('frecpagointereses', entity.frecpagointereses.toString()); }
      if (null != entity.diasmora) { params = params.set('diasmora', entity.diasmora.toString()); }
      if (null != entity.capitalvigente) { params = params.set('capitalvigente', entity.capitalvigente.toString()); }
      if (null != entity.capitalvencido) { params = params.set('capitalvencido', entity.capitalvencido.toString()); }
      if (null != entity.intdevnocobvig) { params = params.set('intdevnocobvig', entity.intdevnocobvig.toString()); }
      if (null != entity.intdevnocobvenc) { params = params.set('intdevnocobvenc', entity.intdevnocobvenc.toString()); }
      if (null != entity.intdevnocobctasorden) { params = params.set('intdevnocobctasorden', entity.intdevnocobctasorden.toString()); }
      if (null != entity.fechaultpagocapital) { params = params.set('fechaultpagocapital', entity.fechaultpagocapital.toString()); }
      if (null != entity.montoultpagocapital) { params = params.set('montoultpagocapital', entity.montoultpagocapital.toString()); }
      if (null != entity.fechaultpagointereses) { params = params.set('fechaultpagointereses', entity.fechaultpagointereses.toString()); }
      if (null != entity.montoultpagointereses) { params = params.set('montoultpagointereses', entity.montoultpagointereses.toString()); }
      if (null != entity.tipo) { params = params.set('tipo', entity.tipo.toString()); }
      if (null != entity.estatus) { params = params.set('estatus', entity.estatus.toString()); }
      if (null != entity.montogarantialiquida) { params = params.set('montogarantialiquida', entity.montogarantialiquida.toString()); }
      if (null != entity.tipotasaid) { params = params.set('tipotasaid', entity.tipotasaid.toString()); }
      if (null != entity.tipodegarantiaadicional) { params = params.set('tipodegarantiaadicional', entity.tipodegarantiaadicional.toString()); }
      if (null != entity.montodegarantiaadicional) { params = params.set('montodegarantiaadicional', entity.montodegarantiaadicional.toString()); }
      if (null != entity.asesordecredito) { params = params.set('asesordecredito', entity.asesordecredito.toString()); }
      if (null != entity.cat) { params = params.set('cat', entity.cat.toString()); }
      if (null != entity.flag_metrica) { params = params.set('flag_metrica', entity.flag_metrica.toString()); }
      if (null != entity.clasificacion_nac) { params = params.set('clasificacion_nac', entity.clasificacion_nac.toString()); }
      if (null != entity.ocupaciondesc) { params = params.set('ocupaciondesc', entity.ocupaciondesc.toString()); }
      if (null != entity.estadocivilid) { params = params.set('estadocivilid', entity.estadocivilid.toString()); }
      if (null != entity.estadocivildesc) { params = params.set('estadocivildesc', entity.estadocivildesc.toString()); }
      if (null != entity.localidadid) { params = params.set('localidadid', entity.localidadid.toString()); }
      if (null != entity.localidaddesc) { params = params.set('localidaddesc', entity.localidaddesc.toString()); }
      if (null != entity.municipioid) { params = params.set('municipioid', entity.municipioid.toString()); }
      if (null != entity.municipiodesc) { params = params.set('municipiodesc', entity.municipiodesc.toString()); }
      if (null != entity.escolaridadid) { params = params.set('escolaridadid', entity.escolaridadid.toString()); }
      if (null != entity.escolaridaddesc) { params = params.set('escolaridaddesc', entity.escolaridaddesc.toString()); }
      if (null != entity.arreglo_anios) { params = params.set('arreglo_anios', entity.arreglo_anios.toString()); }
      if (null != entity.arreglo_mes) { params = params.set('arreglo_mes', entity.arreglo_mes.toString()); }
      if (null != entity.arreglo_dimension) { params = params.set('arreglo_dimension', entity.arreglo_dimension.toString()); }
      if (null != entity.nopagina) { params = params.set('nopagina', entity.nopagina.toString()); }
      if (null != entity.norows) { params = params.set('norows', entity.norows.toString()); }
      if (null != entity.longitud_loc) { params = params.set('longitud_loc', entity.longitud_loc.toString()); }
      if (null != entity.latitud_loc) { params = params.set('latitud_loc', entity.latitud_loc.toString()); }
      if (null != entity.longitud_edo) { params = params.set('longitud_edo', entity.longitud_edo.toString()); }
      if (null != entity.latitud_edo) { params = params.set('latitud_edo', entity.latitud_edo.toString()); }
      if (null != entity.longitud_mun) { params = params.set('longitud_mun', entity.longitud_mun.toString()); }
      if (null != entity.latitud_mun) { params = params.set('latitud_mun', entity.latitud_mun.toString()); }
      if (null != entity.estadodesc) { params = params.set('estadodesc', entity.estadodesc.toString()); }
      if (null != entity.arreglo_sucursal) { params = params.set('arreglo_sucursal', entity.arreglo_sucursal.toString()); }
      if (null != entity.bandadiasmora) { params = params.set('bandadiasmora', entity.bandadiasmora.toString()); }
      if (null != entity.sexoid) { params = params.set('sexoid', entity.sexoid.toString()); }
      if (null != entity.sexodesc) { params = params.set('sexodesc', entity.sexodesc.toString()); }
      if (null != entity.arreglo_estados) { params = params.set('arreglo_estados', entity.arreglo_estados.toString()); }
      if (null != entity.arreglo_municipios) { params = params.set('arreglo_municipios', entity.arreglo_municipios.toString()); }
      if (null != entity.arreglo_localidades) { params = params.set('arreglo_localidades', entity.arreglo_localidades.toString()); }
      if (null != entity.saldocartera) { params = params.set('saldocartera', entity.saldocartera.toString()); }
      if (null != entity.todas_columnas) { params = params.set('todas_columnas', entity.todas_columnas.toString()); }
      /* Relaciones */
      if (null != entity.estadosocio) { params = params.set('estadosocio', entity.estadosocio.toString()); }
      if (null != entity.estadocivildesc) { params = params.set('estadocivildesc', entity.estadocivildesc.toString()); }
      /* Relaciones */
      
      return params;
  }

}

