import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {IndicadorEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class IndicadorService extends Service<IndicadorEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/Indicador', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: IndicadorEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    
    if (null != entity.Id_Indicador) { params = params.set('Id_Indicador', entity.Id_Indicador.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Nivel1) { params = params.set('Nivel1', entity.Nivel1.toString()); }
    if (null != entity.Nivel2) { params = params.set('Nivel2', entity.Nivel2.toString()); }
    if (null != entity.Nivel3) { params = params.set('Nivel3', entity.Nivel3.toString()); }
    if (null != entity.Nivel4) { params = params.set('Nivel4', entity.Nivel4.toString()); }
    if (null != entity.Nivel) { params = params.set('Nivel', entity.Nivel.toString()); }
    if (null != entity.R0_Usar) { params = params.set('R0_Usar', entity.R0_Usar.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.R0_Oplogico1) { params = params.set('R0_Oplogico1', entity.R0_Oplogico1.toString()); }
    if (null != entity.R0_Opcomparacion2) { params = params.set('R0_Opcomparacion2', entity.R0_Opcomparacion2.toString()); }
    if (null != entity.R0_Valor2) { params = params.set('R0_Valor2', entity.R0_Valor2.toString()); }
    if (null != entity.R1_Usar) { params = params.set('R1_Usar', entity.R1_Usar.toString()); }
    if (null != entity.R1_Opcomparacion1) { params = params.set('R1_Opcomparacion1', entity.R1_Opcomparacion1.toString()); }
    if (null != entity.R1_Valor1) { params = params.set('R1_Valor1', entity.R1_Valor1.toString()); }
    if (null != entity.R1_Oplogico1) { params = params.set('R1_Oplogico1', entity.R1_Oplogico1.toString()); }
    if (null != entity.R1_Opcomparacion2) { params = params.set('R1_Opcomparacion2', entity.R1_Opcomparacion2.toString()); }
    if (null != entity.R1_Valor2) { params = params.set('R1_Valor2', entity.R1_Valor2.toString()); }
    if (null != entity.R2_Usar) { params = params.set('R2_Usar', entity.R2_Usar.toString()); }
    if (null != entity.R2_Opcomparacion1) { params = params.set('R2_Opcomparacion1', entity.R2_Opcomparacion1.toString()); }
    if (null != entity.R2_Valor1) { params = params.set('R2_Valor1', entity.R2_Valor1.toString()); }
    if (null != entity.R2_Oplogico1) { params = params.set('R2_Oplogico1', entity.R2_Oplogico1.toString()); }
    if (null != entity.R2_Opcomparacion2) { params = params.set('R2_Opcomparacion2', entity.R2_Opcomparacion2.toString()); }
    if (null != entity.R2_Valor2) { params = params.set('R2_Valor2', entity.R2_Valor2.toString()); }
    if (null != entity.R2_Variable1) { params = params.set('R2_Variable1', entity.R2_Variable1.toString()); }
    if (null != entity.R3_Usar) { params = params.set('R3_Usar', entity.R3_Usar.toString()); }
    if (null != entity.R4_Usar) { params = params.set('R4_Usar', entity.R4_Usar.toString()); }
    if (null != entity.R5_Usar) { params = params.set('R5_Usar', entity.R5_Usar.toString()); }
    if (null != entity.R5_Opcomparacion1) { params = params.set('R5_Opcomparacion1', entity.R5_Opcomparacion1.toString()); }
    if (null != entity.R5_Valor1) { params = params.set('R5_Valor1', entity.R5_Valor1.toString()); }
    if (null != entity.R5_Oplogico1) { params = params.set('R5_Oplogico1', entity.R5_Oplogico1.toString()); }
    if (null != entity.R5_Opcomparacion2) { params = params.set('R5_Opcomparacion2', entity.R5_Opcomparacion2.toString()); }
    if (null != entity.R5_Valor2) { params = params.set('R5_Valor2', entity.R5_Valor2.toString()); }
        
    return params;
  }
}
