import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WelcomeComponent } from './../../components/welcome/welcome.component';
import { PermisosSubmodulosGuard } from './../../guards/permisos-submodulos/permisos-submodulos.guard';
import { ConstantesQubos } from './../../util/app-util';
import { RiesgosTendenciaComponent} from './riesgos-tendencia/riesgos-tendencia.component';
import { MatrizTransicionComponent} from './matriz-transicion/matriz-transicion.component';
import { CosechasComponent} from './cosechas/cosechas.component';
import { ConcentracionComponent} from './concentracion/concentracion.component';
import { MayoresDeudoresComponent} from './mayores-deudores/mayores-deudores.component';

const PATH = ConstantesQubos.strPATH;

const routes: Routes = [
  {
    path: PATH + '/riesgos',
    component: WelcomeComponent,
    children: [
      { path: 'tendencia', component: RiesgosTendenciaComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'matriz-transicion', component: MatrizTransicionComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'cosechas', component: CosechasComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'concentracion', component: ConcentracionComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'mayores-deudores', component: MayoresDeudoresComponent, canActivate: [PermisosSubmodulosGuard] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiesgosRoutingModule { }

