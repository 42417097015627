import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ElasticSociosService {

  private url = environment.baseUrl;
  private token = sessionStorage.getItem('auth_token');
  private nombreDashboard = "afiliacion";

  public body = {
    index: "socio_captacion_credito_202108",
    content: {
      size: 0,
      query: {
        bool: {
          must: []
        }
      },
      aggs: {}
    }
  }

  constructor(
    private _http: HttpClient
  ) { }

  // Consulta general elastic search. Doc:
  consultaGeneral(tipo: string, datos?: any, perspectivas?: Array<any>): Observable<any> {

    this.body.content.query = { bool: { must: [] } };
    this.body.content.aggs = {};

    let agg = null;
    let query = null;
    let pers = null;

    switch (tipo) {

      case 'fechas_registradas':
        agg = {
          agrupar_por_aniomes: {
            date_histogram: {
              field: "Time_Id",
              calendar_interval: "month",
              format: "yyyy-MM"
            }
          }
        }
        break;

      case 'grafica_inicial':
        agg = {
          agrupar_por_sucursal: {
            terms: {
              field: "sucursal.keyword",
              size: 1000
            },
            aggs: {
              total_socios: {
                cardinality: {
                  field: "clave socio cliente.keyword"
                }
              }
            }
          }
        }
        break;

      case 'grafica_inicial_producto':
        agg = {
          agrupar_por_perspectiva: {
            terms: {
              field: "actividad.keyword",
              size: 1000
            },
            aggs: {
              total_socios: {
                cardinality: {
                  field: "clave socio cliente.keyword"
                }
              }
            }
          }
        }
        break;

      case 'grafica_pie_genero':
        agg = {
          filters_agg: {
            filters: {
              filters: {
                first: {
                  match_all: {}
                }
              }
            },
            aggs: {
              agrupar_por_genero: {
                terms: {
                  field: "genero_Desc.keyword",
                  size: 1000
                }
              },
              porcentaje_mujeres: {
                bucket_script: {
                  buckets_path: {
                    femenino: "agrupar_por_genero['Femenino']>_count",
                    masculino: "agrupar_por_genero['Masculino']>_count"
                  },
                  script: "params.femenino / (params.femenino + params.masculino) * 100"
                }
              },
              porcentaje_hombres: {
                bucket_script: {
                  buckets_path: {
                    femenino: "agrupar_por_genero['Femenino']>_count",
                    masculino: "agrupar_por_genero['Masculino']>_count"
                  },
                  script: "params.masculino / (params.femenino + params.masculino) * 100"
                }
              }
            }
          }
        }
        break;

      case 'grafica_perspectivas':

        query = {
          bool: {
            must: [
              {
                // Modificar el valor de sucursal, por el que sea seleccionado
                term: {
                  "sucursal.keyword": {
                    value: datos.sucursal_id
                  }
                }
              },
            ]
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

            switch (perspectiva.perspectiva) {

              case 'Ciudad':
                pers = {
                  term: {
                    "Ciudad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'rangoedad':
                pers = {
                  term: {
                    "rangoedad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'genero_Desc':
                pers = {
                  term: {
                    "genero_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'escolaridad_Desc':
                pers = {
                  term: {
                    "escolaridad_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'Departamento':
                pers = {
                  term: {
                    "Departamento.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              default:
                break;
            }

            query.bool.must.push(pers);
          });
        }

        agg = {
          agrupar_por_perspectiva: {
            terms: {
              // Cambiar el valor por la perspectiva seleccionada
              field: datos.perspectiva + ".keyword",
              size: 1000
            },
            aggs: {
              total_socios: {
                cardinality: {
                  field: "clave socio cliente.keyword"
                }
              }
            }
          }
        }

        break;

      case 'grafica2_perspectivas':
        query = {
          bool: {
            must: [
              {
                // Modificar el valor de sucursal, por el que sea seleccionado
                term: {
                  "sucursal.keyword": {
                    value: datos.sucursal_id
                  }
                }
              },
            ]
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

            switch (perspectiva.perspectiva) {

              case 'Ciudad':
                pers = {
                  term: {
                    "Ciudad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'rangoedad':
                pers = {
                  term: {
                    "rangoedad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'genero_Desc':
                pers = {
                  term: {
                    "genero_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'escolaridad_Desc':
                pers = {
                  term: {
                    "escolaridad_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'Departamento':
                pers = {
                  term: {
                    "Departamento.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              default:
                break;
            }

            query.bool.must.push(pers);
          });
        }

        agg = {
          agrupar_por_perspectiva: {
            terms: {
              // Cambiar el valor por la perspectiva seleccionada
              field: "actividad.keyword",
              size: 1000
            },
            aggs: {
              total_socios: {
                cardinality: {
                  field: "clave socio cliente.keyword"
                }
              }
            }
          }
        }
        break;

      case 'grafica_pie_perspectivas':
        query = {
          bool: {
            must: [
              {
                // Modificar el valor de sucursal, por el que sea seleccionado
                term: {
                  "sucursal.keyword": {
                    value: datos.sucursal_id
                  }
                }
              },
            ]
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

            switch (perspectiva.perspectiva) {

              case 'Ciudad':
                pers = {
                  term: {
                    "Ciudad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'rangoedad':
                pers = {
                  term: {
                    "rangoedad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'genero_Desc':
                pers = {
                  term: {
                    "genero_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'escolaridad_Desc':
                pers = {
                  term: {
                    "escolaridad_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'Departamento':
                pers = {
                  term: {
                    "Departamento.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              default:
                break;
            }

            query.bool.must.push(pers);
          });
        }

        agg = {
          total_socios: {
            cardinality: {
              field: "clave socio cliente.keyword"
            }
          },
          agrupar_por_perspectiva: {
            terms: {
              field: "rangoedad.keyword",
              size: 1000
            },
            aggs: {
              total_socios_sucursal: {
                cardinality: {
                  field: "clave socio cliente.keyword"
                }
              }
            }
          }
        }
        break;

      case 'grafica_pie_perspectivas_genero':
        query = {
          bool: {
            must: [
              {
                // Modificar el valor de sucursal, por el que sea seleccionado
                term: {
                  "sucursal.keyword": {
                    value: datos.sucursal_id
                  }
                }
              },
            ]
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

            switch (perspectiva.perspectiva) {

              case 'Ciudad':
                pers = {
                  term: {
                    "Ciudad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'rangoedad':
                pers = {
                  term: {
                    "rangoedad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'genero_Desc':
                pers = {
                  term: {
                    "genero_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'escolaridad_Desc':
                pers = {
                  term: {
                    "escolaridad_Desc.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'Departamento':
                pers = {
                  term: {
                    "Departamento.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              default:
                break;
            }

            query.bool.must.push(pers);
          });
        }

        agg = {
          filters_agg: {
              filters: {
                  filters: {
                      first: {
                          match_all: {}
                      }
                  }
              },
              aggs: {
                  agrupar_por_genero: {
                      terms: {
                          field: "genero_Desc.keyword",
                          size: 1000
                      }
                  },
                  porcentaje_mujeres: {
                      bucket_script: {
                          buckets_path: {
                              femenino: "agrupar_por_genero['Femenino']>_count",
                              masculino: "agrupar_por_genero['Masculino']>_count"
                          },
                          script: "params.femenino / (params.femenino + params.masculino) * 100"
                      }
                  },
                  porcentaje_hombres: {
                      bucket_script: {
                          buckets_path: {
                              femenino: "agrupar_por_genero['Femenino']>_count",
                              masculino: "agrupar_por_genero['Masculino']>_count"
                          },
                          script: "params.masculino / (params.femenino + params.masculino) * 100"
                      }
                  }
              }
          }
      }
        break;

      case 'grafica_pie':

        agg = {
          total_socios: {
            cardinality: {
              field: "clave socio cliente.keyword"
            }
          },
          agrupar_por_perspectiva: {
            terms: {
              field: "rangoedad.keyword",
              size: 1000
            },
            aggs: {
              total_socios_sucursal: {
                cardinality: {
                  field: "clave socio cliente.keyword"
                }
              }
            }
          }
        };

        break;

      case 'cuentas/clasificacion':
        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_clasificacion: {
                terms: {
                  field: "Creditos_Info.clasificacion.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  }
                }
              }
            }
          }
        }
        break;

      
      case 'cuentas/estatus':
        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_clasificacion: {
                terms: {
                  field: "Creditos_Info.clasificacion.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  }
                }
              }
            }
          }
        }
        break;
  
      default:
        break;
    }

    if (agg != null && agg != undefined) {
      this.body.content.aggs = agg;
    }

    if (query != null && query != undefined) {
      this.body.content.query = query;
    }


    let newBody = JSON.stringify(this.body)
    console.log(newBody)
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('NombreDashboard', 'dashboard1').set('Authorization', 'Bearer ' + this.token).set('TokenUsuario', '1234');
    return this._http.post(this.url + 'busquedageneral', newBody, { headers: headers });

  }

  generateBody(tipo, datos, perspectivas?: Array<string>): void {

  }

  elasticToBarChart(data): Array<any> {

    let datosBarraAux = [];

    data.forEach(elemento => {
      let elementoCadena = elemento.key;
      let newElemento = {
        name: elementoCadena,
        value: elemento.doc_count
      }
      datosBarraAux.push(newElemento);
    });

    return datosBarraAux;
  }

  elasticToLinealSimpleChart(data: Array<any>, value?: string): any {

    let datosBarraAux = [{
      name: 'Gráfica lineal',
      series: []
    }];

    if (value && value != '') {
      data.forEach(elemento => {
        let elementoCadena = elemento.key;
        let newElemento = {
          name: elementoCadena,
          value: elemento[value]
        }
        datosBarraAux[0].series.push(newElemento);
      });
    } else {
      data.forEach(elemento => {
        let elementoCadena = elemento.key;
        let newElemento = {
          name: elementoCadena,
          value: elemento.doc_count
        }
        datosBarraAux[0].series.push(newElemento);
      });
    }

    return datosBarraAux;
  }
}
