<div class="w3-container w3-contenedor-template" style="height: 7vh;">
    <div class="w3-row ">
      <!-- TITULO DEL COMPONENTE -->
      <div class="w3-quarter padreizquierda ">
        <div class="w3-row hijoizquierda">
          <!--<span class="w3-text-deep-orange w3-margin-right-niveles"><b>{{ dataService.currentModule }} </b></span>-->
          <span class="w3-text-deep-orange w3-margin-right-niveles w3-tituloPrincipal"><b> Riesgo de crédito </b></span>
          <span class="w3-text-deep-orange">&bull;</span>
          <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal">{{ dataService.currentSubmodule }}</span>
        </div>
      </div>
      <!-- FIN TITULO DEL COMPONENTE -->
  
      <!-- FILTROS-->
      <div class="w3-rest ">
        <!-- DEFINICION FILTROS -->
        <div class="w3-noventa2 w3-row ">
          <!-- AÑOS -->
          <div class="w3-third padreizquierda">
            <!-- FILTRO AÑOS DISPONIBLES -->
            <div class="w3-quarter_e hijoizquierda">
              <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios">
                Año
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium"  #menuanios="matMenu" >
                <ng-container *ngFor="let anio of aniosDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{anio.name}}" aria-label="First checkbox"
                                  (change)="anioChangeListener(anio)">
                      {{anio.value}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO AÑOS DISPONIBLES -->
  
            <!-- AÑO SELECCIONADO -->
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda chips">
                  <div [ngClass]="anios.check?
                  'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                    *ngFor="let anios of aniosDisponibles"
                    class="filtroMesAnioSeleccionado">
                    <span class="contenidoChips">{{anios.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN AÑO SELECCIONADO -->
          </div>
          <!-- FIN AÑOS -->
  
          <!-- MESES -->
          <div class="w3-third " >
            <!-- FILTRO MESES DISPONIBLES -->
            <div class="w3-quarter_e hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu"> Mes<mat-icon class="iconoMen">expand_more</mat-icon></button>
              <mat-menu class="w3-medium"  #menumeses="matMenu" >
                <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{mes.name}}"
                                  (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                      {{mes.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO MESES DISPONIBLES -->
  
            <!-- MES SELECCIONADO -->
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda chips">
                  <div class="filtroMesAnioSeleccionado w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu">
                      <span class="contenidoChips">{{mesSeleccionado.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN MES SELECCIONADO -->
          </div>
          <!-- FIN MESES -->
  
          <!-- CONSOLIDADO Y SUCURSALES -->
          <div class="w3-third" *ngIf="puedeFiltrarPorSucursales">
            <div class="w3-half hijoizquierda">
              Entidad
              <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuentidad">
                <mat-icon class="w3-medium">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-tiny"  #menuentidad="matMenu" >
                <button mat-menu-item>
                  <mat-checkbox name="consolidado" [checked]="consolidado"
                                (click)="$event.stopPropagation();"
                                (change)="consolidadoChangeListener()"
                                aria-label="First checkbox">
                    Consolidado
                  </mat-checkbox>
                </button>
                <button mat-menu-item *ngIf="!consolidado" [matMenuTriggerFor]="sucursales">Sucursales</button>
              </mat-menu>
              <mat-menu #sucursales="matMenu">
                <ng-container *ngFor="let sucursal of sucursalesDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="sucursal.check"
                      (change)="sucursalChangeListener(sucursal)"
                      (click)="$event.stopPropagation();">
                      {{sucursal.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <div class="w3-rest">
              <div class="w3-row padreizquierda">
                <div class="w3-row hijoizquierda ">
                  <div *ngIf="sucursalSeleccionada"
                       [ngClass]="'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu'"
                       class="filtroMesAnioSeleccionado">
                    {{sucursalSeleccionada.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN CONSOLIDADO Y SUCURSALES -->
        </div>
        <!-- FIN DEFINICION FILTROS -->
  
        <!-- BORRAR FILTROS -->
        <div class="w3-rest w3-right ">
          <button mat-icon-button>
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitarFiltros()">
              <img src="assets/img/graficos/eraser.png">
            </mat-icon>
          </button>
        </div>
        <!-- FIN BORRAR FILTROS -->
      </div>
      <!-- FIN FILTROS-->
    </div>
</div>

<!-- contenedor de las graficas del tablero -->
<div class="w3-container w3-contenedor-template " style="height: 85vh;">
    <div class="w3-row w3-contenedor-template " style="height: 41vh;">
      <!-- PARTE DE ARRIBA -->
      <div class="w3-row" style="height: 100%;">
        <!-- PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
        <div class="w3-setenta2 w3-white" style="height: 40vh">
          <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
            <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido">Matriz de transición monto</span>
                <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
                <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica}}</span>
              </div>
            </div>
            <div class="w3-rest w3-right-align_rest">
              <div class="w3-right hijoderechaTitle">
                <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarGraficaC1()" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuP2">more_vert</mat-icon>
              </div>
              <mat-menu #menuP2="matMenu" xPosition="before">
                  <button mat-menu-item [matMenuTriggerFor]="PerspectivaP2"><mat-icon class="material-icons-outlined">account_tree</mat-icon>Perspectiva</button>
                  <button mat-menu-item (click)="exportar_csv('monto')">
                      <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
                      <span>Exportar</span>
                  </button>
              </mat-menu>
              <mat-menu  #PerspectivaP2="matMenu">
                <button mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="productomenu" >
                  <mat-icon class="material-icons-outlined"></mat-icon>
                  <span>Producto</span>
                </button>
                <button  class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="sectormenu" >
                    <mat-icon></mat-icon>
                    <span>Sector</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="zonamenu" >
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>Zona</span>
                </button>             
              </mat-menu>
              <mat-menu #zonamenu="matMenu">
                <button *ngFor="let zona of dataZona" mat-menu-item (click)="filtrarZona(zona)">
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>{{zona.Nombre_Zona}}</span>
                </button>
              </mat-menu>
              <mat-menu #productomenu="matMenu">
                <button *ngFor="let producto of dataProducto" mat-menu-item (click)="filtrarProducto(producto)">
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>{{producto.Nombre_Producto}}</span>
                </button>
              </mat-menu>
              <mat-menu #sectormenu="matMenu">
                <button *ngFor="let sec of sectors" mat-menu-item (click)="filtrarSectores(sec)">
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>{{sec.Nombre_Sector}}</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="w3-row" style="width: 99%;">
            <div class="w3-encabezado-table w3-scroll" style="height: 33vh;">
              <div class="">
                <table mat-table [dataSource]="dataSource" class="w3-table-all">
                    <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->
                    
                    <!-- Position Column -->
                    <ng-container style="width: 6vh;" matColumnDef="name">
                      <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef></th>
                      <td class="w3-table-title w3-center" style="width: 10vh;" mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="A-1">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-1 </th>
                        <td [class]="PintarCelda(0,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles " *ngIf="formatearnormal(element.A1) != 0"> {{formatearnormal(element.A1)}} </div> </td>
                    </ng-container>
                
                    <!-- Name Column -->
                    <ng-container matColumnDef="A-2">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-2 </th>
                        <td [class]="PintarCelda(1,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles " *ngIf="formatearnormal(element.A2) != 0">{{formatearnormal(element.A2)}}</div> </td>
                    </ng-container>
                
                    <!-- Weight Column -->
                    <ng-container  matColumnDef="B-1">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-1 </th>
                        <td [class]="PintarCelda(2,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B1) != 0">{{formatearnormal(element.B1)}}</div> </td>
                    </ng-container>
                
                    <!-- Symbol Column -->
                    <ng-container  matColumnDef="B-2">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-2 </th>
                        <td [class]="PintarCelda(3,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B2) != 0">{{formatearnormal(element.B2)}}</div> </td>
                    </ng-container>
                    
                    <!-- Symbol Column -->
                    <ng-container  matColumnDef="B-3">
                      <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-3 </th>
                      <td [class]="PintarCelda(4,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B3) != 0">{{formatearnormal(element.B3)}}</div> </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="C-1">
                      <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-1 </th>
                      <td [class]="PintarCelda(5,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.C1) != 0">{{formatearnormal(element.C1)}}</div> </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container  matColumnDef="C-2">
                      <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-2 </th>
                      <td [class]="PintarCelda(6,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.C2) != 0">{{formatearnormal(element.C2)}}</div> </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container  matColumnDef="D">
                      <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> D </th>
                      <td [class]="PintarCelda(7,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.D) != 0">{{formatearnormal(element.D)}}</div> </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="E">
                      <th  class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> E </th>
                      <td [class]="PintarCelda(8,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.E) != 0">{{formatearnormal(element.E)}}</div> </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container  matColumnDef="total">
                      <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> Total </th>
                      <td mat-cell *matCellDef="let element"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.total) != 0">{{element.total}}</div> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row " ></tr>
                </table>              
              </div>
            </div>
          </div>
        </div>
        <div class="w3-rest">
          <div class="w3-row w3-right " style="width: 98%;">

            <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
              <div class="w3-third">
                <div class="w3-row textLeft">
                  <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Monto con mejora</span>
                </div>
                <div class="w3-row textLeft" style="vertical-align: bottom;">
                  <span class="w3-subtituloMeta"> {{mesAnio}} </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row w3-right" style="margin-top: 5vh;">
                  <mat-icon class="material-icons-outlined mat-icono" style="margin-right: 1vh;" [ngStyle]="{'color':'#8bc34a'}">thumb_up</mat-icon>
                  <span class="w3-total-cantidadRiesgos w3-balance-verde"> $ {{formatearnormal(total_abajodiagonal.toFixed(2))}} </span>
                </div>
              </div>
            </div>

            <div style="height: 1vh;"></div>

            <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
              <div class="w3-third">
                <div class="w3-row textLeft">
                  <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Monto sin cambio</span>
                </div>
                <div class="w3-row textLeft" style="vertical-align: bottom;">
                  <span class="w3-subtituloMeta"> {{mesAnio}} </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row w3-right" style="margin-top: 5vh;">
                  <mat-icon class="material-icons-outlined mat-icono" style="margin-right: 1vh;" [ngStyle]="{'color':'#0075C9'}">touch_app</mat-icon>
                  <span class="w3-total-cantidadRiesgos w3-balance-azul"> $ {{formatearnormal(total_diagonal.toFixed(2))}} </span>
                </div>
              </div>
            </div>

            <div style="height: 1vh;"></div>

            <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
              <div class="w3-third">
                <div class="w3-row textLeft">
                  <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Monto con deterioro</span>
                </div>
                <div class="w3-row textLeft" style="vertical-align: bottom;">
                  <span class="w3-subtituloMeta"> {{mesAnio}} </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row w3-right" style="margin-top: 5vh;">
                  <mat-icon class="material-icons-outlined mat-icono" style="margin-right: 1vh;" [ngStyle]="{'color':'#ff9012'}">thumb_down</mat-icon>
                  <span class="w3-total-cantidadRiesgos w3-balance-naranja"> $ {{formatearnormal(total_arribadiagonal.toFixed(2))}} </span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- FIN PARTE DE ARRIBA, CONTENDOR DE LAS GRAFICAS -->
      </div>
      <!-- FIN PARTE DE ARRIBA -->
      <div class="w3-row" style="height: 100%;">
        <!-- PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
        <div class="w3-setenta2 w3-white" style="height: 40vh">
            <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
              <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido">Matriz de transición créditos</span>
                  <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                  <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
                  <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGraficaII}}</span>
                </div>
              </div>
              <div class="w3-rest w3-right-align_rest">
                <div class="w3-right hijoderechaTitle">
                  <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarGraficaC2()" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
                  <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuP3">more_vert</mat-icon>
                </div>
                <mat-menu #menuP3="matMenu" xPosition="before">
                  <button mat-menu-item (click)="exportar_csv('creditos')">
                      <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
                      <span>Exportar</span>
                  </button>
              </mat-menu>
              </div>
            </div>

            <div class="w3-row" style="width: 99%;">
              <div class="w3-encabezado-table w3-scroll"  style="height: 33vh;">
                <div class="">
                  <table mat-table [dataSource]="dataSourceII" class="w3-table-all">

                      <ng-container matColumnDef="name">
                          <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> </th>
                          <td class="w3-table-title w3-center" style="width: 10vh;" mat-cell *matCellDef="let element">  {{element.name}} </td>
                      </ng-container>                    
                    
                      <ng-container matColumnDef="A-1">
                          <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-1 </th>
                          <td [class]="PintarBorder(0,i)" *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.A1 != 0">{{element.A1}}</div> </td>
                      </ng-container>                    
                      
                      <ng-container matColumnDef="A-2">
                          <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-2 </th>
                          <td [class]="PintarBorder(1,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.A2 != 0">{{element.A2}}</div> </td>
                      </ng-container>                    
                      
                      <ng-container matColumnDef="B-1">
                          <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-1 </th>
                          <td [class]="PintarBorder(2,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B1 != 0">{{element.B1}}</div> </td>
                      </ng-container>                  
                      
                      <ng-container matColumnDef="B-2">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-2 </th>
                        <td [class]="PintarBorder(3,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B2 != 0">{{element.B2}}</div> </td>
                      </ng-container>                    

                      <ng-container matColumnDef="B-3">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-3 </th>
                        <td [class]="PintarBorder(4,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B3 != 0">{{element.B3}}</div> </td>
                      </ng-container>                    

                      <ng-container matColumnDef="C-1">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-1 </th>
                        <td [class]="PintarBorder(5,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.C1 != 0">{{element.C1}}</div> </td>
                      </ng-container>                    
                      
                      <ng-container matColumnDef="C-2">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-2 </th>
                        <td [class]="PintarBorder(6,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.C2 != 0">{{element.C2}}</div> </td>
                      </ng-container>                    

                      <ng-container matColumnDef="D">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> D </th>
                        <td [class]="PintarBorder(7,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.D != 0">{{element.D}}</div> </td>
                      </ng-container>                    

                      <ng-container matColumnDef="E">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> E </th>
                        <td [class]="PintarBorder(8,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.E != 0">{{element.E}}</div> </td>
                      </ng-container>              
                      
                      <ng-container matColumnDef="total">
                        <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> Total </th>
                        <td  mat-cell *matCellDef="let element"> <div class="w3-right w3-margin-right-niveles" *ngIf="element.total != 0"> {{element.total}}</div> </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row" ></tr>
                  </table>
                </div>
              </div>
            </div>
        </div>
        <div class="w3-rest">
          <div class="w3-row w3-right " style="width: 98%;">

            <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
              <div class="w3-third">
                <div class="w3-row textLeft">
                  <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Crédito con mejora</span>
                </div>
                <div class="w3-row textLeft" style="vertical-align: bottom;">
                  <span class="w3-subtituloMeta"> {{mesAnio}} </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row w3-center" style="margin-top: 5vh;">
                  <mat-icon class="material-icons-outlined mat-icono" style="margin-right: 1vh;" [ngStyle]="{'color':'#8bc34a'}">thumb_up</mat-icon>
                  <span class="w3-total-cantidadRiesgos w3-balance-verde"> {{formatearnormalCredito(total_abajodiagonalII.toFixed(0))}} </span>
                </div>
              </div>
            </div>

            <div style="height: 1vh;"></div>

            <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
              <div class="w3-third">
                <div class="w3-row textLeft">
                  <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Crédito sin cambio</span>
                </div>
                <div class="w3-row textLeft" style="vertical-align: bottom;">
                  <span class="w3-subtituloMeta"> {{mesAnio}} </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row w3-center" style="margin-top: 5vh;">
                  <mat-icon class="material-icons-outlined mat-icono" style="margin-right: 1vh;" [ngStyle]="{'color':'#0075C9'}">touch_app</mat-icon>
                  <span class="w3-total-cantidadRiesgos w3-balance-azul"> {{formatearnormalCredito(total_diagonalII.toFixed(0))}}  </span>
                </div>
              </div>
            </div>

            <div style="height: 1vh;"></div>

            <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
              <div class="w3-third">
                <div class="w3-row textLeft">
                  <span class="w3-total-textoEstadistico" style="color: #8B8E93;">Crédito con deterioro</span>
                </div>
                <div class="w3-row textLeft" style="vertical-align: bottom;">
                  <span class="w3-subtituloMeta"> {{mesAnio}} </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row w3-center" style="margin-top: 5vh;">
                  <mat-icon class="material-icons-outlined mat-icono" style="margin-right: 1vh;" [ngStyle]="{'color':'#ff9012'}">thumb_down</mat-icon>
                  <span class="w3-total-cantidadRiesgos w3-balance-naranja"> {{formatearnormalCredito(total_arribadiagonalII.toFixed(0))}} </span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- FIN PARTE DE ARRIBA, CONTENDOR DE LAS GRAFICAS -->
      </div>
    </div>
</div>

<!-- Modal para las gráficas -->
<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-noventa">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{titulomodal}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" >&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulomodal}}</span>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 68vh;" >
      <div class="w3-row w3-margin" style="height: 67vh;">
        <div class="w3-encabezado-table w3-scroll" style="height: 66vh;">
          <div *ngIf="tablamonto_modal" class="">
            <table mat-table [dataSource]="dataSource" class="w3-table-all">
              <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
              
              <!-- Position Column -->
              <ng-container style="width: 6vh;" matColumnDef="name">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef></th>
                <td class="w3-table-title" mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="A-1">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-1 </th>
                  <td [class]="PintarCelda(0,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles " *ngIf="formatearnormal(element.A1) != 0"> {{formatearnormal(element.A1)}} </div> </td>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="A-2">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-2 </th>
                  <td [class]="PintarCelda(1,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles " *ngIf="formatearnormal(element.A2) != 0">{{formatearnormal(element.A2)}}</div> </td>
              </ng-container>
          
              <!-- Weight Column -->
              <ng-container  matColumnDef="B-1">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-1 </th>
                  <td [class]="PintarCelda(2,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B1) != 0">{{formatearnormal(element.B1)}}</div> </td>
              </ng-container>
          
              <!-- Symbol Column -->
              <ng-container  matColumnDef="B-2">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-2 </th>
                  <td [class]="PintarCelda(3,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B2) != 0">{{formatearnormal(element.B2)}}</div> </td>
              </ng-container>
              
              <!-- Symbol Column -->
              <ng-container  matColumnDef="B-3">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-3 </th>
                <td [class]="PintarCelda(4,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B3) != 0">{{formatearnormal(element.B3)}}</div> </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="C-1">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-1 </th>
                <td [class]="PintarCelda(5,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.C1) != 0">{{formatearnormal(element.C1)}}</div> </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container  matColumnDef="C-2">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-2 </th>
                <td [class]="PintarCelda(6,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.C2) != 0">{{formatearnormal(element.C2)}}</div> </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container  matColumnDef="D">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> D </th>
                <td [class]="PintarCelda(7,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.D) != 0">{{formatearnormal(element.D)}}</div> </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="E">
                <th  class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> E </th>
                <td [class]="PintarCelda(8,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.E) != 0">{{formatearnormal(element.E)}}</div> </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container  matColumnDef="total">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> Total </th>
                <td mat-cell *matCellDef="let element"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.total) != 0">{{element.total}}</div> </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row " ></tr>
            </table>              
          </div>
          <div *ngIf="tablacreditos_modal" class="">
            <table mat-table [dataSource]="dataSourceII" class="w3-table-all">

              <ng-container matColumnDef="name">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> </th>
                  <td class="w3-table-title w3-center" mat-cell *matCellDef="let element">  {{element.name}} </td>
              </ng-container>                    
            
              <ng-container matColumnDef="A-1">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-1 </th>
                  <td [class]="PintarBorder(0,i)" *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.A1 != 0">{{element.A1}}</div> </td>
              </ng-container>                    
              
              <ng-container matColumnDef="A-2">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> A-2 </th>
                  <td [class]="PintarBorder(1,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.A2 != 0">{{element.A2}}</div> </td>
              </ng-container>                    
              
              <ng-container matColumnDef="B-1">
                  <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-1 </th>
                  <td [class]="PintarBorder(2,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B1 != 0">{{element.B1}}</div> </td>
              </ng-container>                  
              
              <ng-container matColumnDef="B-2">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-2 </th>
                <td [class]="PintarBorder(3,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B2 != 0">{{element.B2}}</div> </td>
              </ng-container>                    

              <ng-container matColumnDef="B-3">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> B-3 </th>
                <td [class]="PintarBorder(4,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B3 != 0">{{element.B3}}</div> </td>
              </ng-container>                    

              <ng-container matColumnDef="C-1">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-1 </th>
                <td [class]="PintarBorder(5,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.C1 != 0">{{element.C1}}</div> </td>
              </ng-container>                    
              
              <ng-container matColumnDef="C-2">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> C-2 </th>
                <td [class]="PintarBorder(6,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.C2 != 0">{{element.C2}}</div> </td>
              </ng-container>                    

              <ng-container matColumnDef="D">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> D </th>
                <td [class]="PintarBorder(7,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.D != 0">{{element.D}}</div> </td>
              </ng-container>                    

              <ng-container matColumnDef="E">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> E </th>
                <td [class]="PintarBorder(8,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.E != 0">{{element.E}}</div> </td>
              </ng-container>              
              
              <ng-container matColumnDef="total">
                <th class="w3-table-title w3-center" mat-header-cell *matHeaderCellDef> Total </th>
                <td  mat-cell *matCellDef="let element"> <div class="w3-right w3-margin-right-niveles" *ngIf="element.total != 0"> {{element.total}}</div> </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row" ></tr>
            </table>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>