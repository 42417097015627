import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {InstitucionEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class InstitucionService extends Service<InstitucionEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/Institucion', http, messageService, logger);
    this.httpClient = http;
  }
  protected getParams(entity: InstitucionEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    
    if (null != entity.Id_Institucion) { params = params.set('Id_Institucion', entity.Id_Institucion.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Id_Tipo_Cooperativa) { params = params.set('Id_Tipo_Cooperativa', entity.Id_Tipo_Cooperativa.toString()); }
    if (null != entity.Id_Tipo_Institucion) { params = params.set('Id_Tipo_Institucion', entity.Id_Tipo_Institucion.toString()); }
    if (null != entity.Id_Federacion) { params = params.set('Id_Federacion', entity.Id_Federacion.toString()); }
    if (null != entity.Id_Cooperativa) { params = params.set('Id_Cooperativa', entity.Id_Cooperativa.toString()); }
    if (null != entity.Id_Sucursal) { params = params.set('Id_Sucursal', entity.Id_Sucursal.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }
    if (null != entity.Permitir_Bloqueo) { params = params.set('Permitir_Bloqueo', entity.Permitir_Bloqueo.toString()); }
    if (null != entity.Acronimo) { params = params.set('Acronimo', entity.Acronimo.toString()); }
    if (null != entity.Codigo_Auxiliar) { params = params.set('Codigo_Auxiliar', entity.Codigo_Auxiliar.toString()); }  
    return params;
  }
}
