export class PermisosModulosEntity {
  clavemodulo: string;
  descripcion: string;
  usuario: string;
  ifid: string;
  permiso: string;
}
export class PermisosModulos {
  clavemodulo: string;
  descripcion: string;
}

export class Info_AM{
  anio: string;
  mes: string;
  check: boolean;
}



export class PermisosDimensionEntity {
  clavedimension: string;
  clavemodulo: string;
  descripciondimension: string;
  usuario: string;
  ifid: string;
  permiso: string;
}
export class PermisosSubModuloEntity {
  clavesubmodulo: string;
  clavemodulo: string;
  descripcionsubmodulo: string;
  usuario: string;
  ifid: string;
  permiso: string;
}

export class PermisosMetricaEntity {
  clavemetrica: string;
  clavemodulo: string;
  descripcionmetrica: string;
  usuario: string;
  ifid: string;
  permiso: string;
}
export class Entity_Dimension{
  name: string;
  check: boolean;
  value: string;
  name2: string;
}

export class ModulosEntity{
  clavemodulo: string;
  modulo: string;
  descripcionmodulo: string;
}

export class SubModulosEntity{
  idsubmodulo: number;
  clavesubmodulo: string;
  submodulo: string;
  modulo: string;
  descripcionsubmodulo: string;
  activo: boolean;
  orden: number;
}
export class PermisosPerfilEntity{
  permisoperfilid: number;
  idperfil: number;
  clavemodulo: string;
  clavesubmodulo: string;
  permiso: string;
  clavedimension: string;
}
export class PerfilDimensionEntity{
  perfildimensionid: number;
  clavedimension: string;
  clavemodulo: string;
  descripcion: string;
  orden: number;
  permiso: string;
  perfilid: number;
}

export class DimensionEntity{
  dimensionid: number;
  clavedimension: string;
  clavemodulo: string;
  descripcion: string;
  descripcionlarga: string;
  orden: number;
  clavesubmodulo: string;
  
}
export class MetricaEntity{
  clavemetrica: string;
  clavemodulo: string;
  metrica: string;
  tipo: string;
  descripcion: string;

}

export class AsesorCreditoEntity{
  AsesorCreditoId: string;
  ifid: string;
  SucursalId: string;
  Nombre: string;
  Activo: number;
}

export class CuentaQEntity{
  Id_Cuenta: string;
  Descripcion: string;
  Tipo: number;
  Id_Plantilla: number;
  Id_Pais: number;
  Formula: string;
}
export class TablaReservaEntity{
  tablareservaid: number;
  descripcion: string;
  diainicial: number;
  diafinal: number;
  porcentajereserva: number;
  factordisminucion: number;
  finalidaddefault: string;
  porcentajereservaidnc: number;
  tipocartera: string;
  cuentasiti: string;
  orden: number;
}