import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ElasticAhorroService {

  private url = environment.baseUrl;
  private token = sessionStorage.getItem('auth_token');
  private nombreDashboard = "afiliacion";

  public body = {
    index: "socio_captacion_credito_202108",
    content: {
      size: 0,
      query: {
        bool: {
          must: []
        }
      },
      aggs: {}
    }
  }

  constructor(
    private _http: HttpClient
  ) { }

  // Consulta general elastic search. Doc:
  consultaGeneral(tipo: string, datos?: any, perspectivas?: Array<string>): Observable<any> {

    this.body.content.query = { bool: { must: [] } };
    this.body.content.aggs = {};

    let agg = null;
    let query = null;

    switch (tipo) {

      case 'fechas_registradas':
        agg = {
          agrupar_por_aniomes: {
            date_histogram: {
              field: "Time_Id",
              calendar_interval: "month",
              format: "yyyy-MM"
            }
          }
        }
        break;

      case 'grafica_inicial':
        agg = {
          agrupar_por_captacion: {
            nested: {
              path: "Captacion_Info"
            },
            aggs: {
              agrupar_por_sucursal: {
                terms: {
                  field: "Captacion_Info.tiposucursal.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Captacion_Info.no contrato.keyword"
                    }
                  }
                }
              }
            }
          }
        }
        break;

      case 'grafica_perspectivas':

        query = {
          bool: {
            must: [
              {
                // Modificar el valor de tiposucursal, por el que sea seleccionado
                term: {
                  "Captacion_Info.tiposucursal.keyword": {
                    value: datos.sucursal_id
                  }
                }
              }
            ]
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

          });
        }

        agg = {
          agrupar_por_captacion: {
            nested: {
              path: "Captacion_Info"
            },
            aggs: {
              total_contratos: {
                cardinality: {
                  field: "Captacion_Info." + datos.perspectiva + ".keyword"
                }
              }
            }
          }
        }

        break;

      case 'tabla_sucursal':

        query = {
          "nested": {
            "path": "Captacion_Info",
            "query": {
              "bool": {
                "must": [
                  {

                    "term": {
                      "Captacion_Info.tiposucursal.keyword": {
                        "value": datos
                      }
                    }
                  }
                ]
              }
            }
          }
        }

        agg = {
          agrupar_por_captacion: {
            nested: {
              path: "Captacion_Info"
            },
            aggs: {
              agrupar_por_tipodeposito: {
                terms: {
                  field: "Captacion_Info.tipo de deposito.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Captacion_Info.no contrato.keyword"
                    }
                  },
                  suma_saldototal: {
                    sum: {
                      field: "Captacion_Info.saldo total"
                    }
                  }
                }
              }
            }
          }
        };

        break;

      case 'tipodeposito':

        agg = {
          agrupar_por_captacion: {
            nested: {
              path: "Captacion_Info"
            },
            aggs: {
              agrupar_por_tipodeposito: {
                terms: {
                  field: "Captacion_Info.tipo de deposito.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Captacion_Info.no contrato.keyword"
                    }
                  },
                  suma_saldototal: {
                    sum: {
                      field: "Captacion_Info.saldo total"
                    }
                  }
                }
              }
            }
          }
        };

        break;

      case 'cuentas/clasificacion':
        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_clasificacion: {
                terms: {
                  field: "Creditos_Info.clasificacion.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  }
                }
              }
            }
          }
        }
        break;

      case 'total_socios':
        agg = {
          "total_socios": {
            "cardinality": {
              "field": "clave socio cliente.keyword"
            }
          }
        }
        break;

      case 'total_socios_sucursal':
        query = {
          "nested": {
            "path": "Captacion_Info",
            "query": {
              "bool": {
                "must": [
                  {
                    // Modificar el valor de tiposucursal, por el que sea seleccionado
                    "term": {
                      "Captacion_Info.tiposucursal.keyword": {
                        "value": datos
                      }
                    }
                  }
                ]
              }
            }
          }
        }

        agg = {
          "total_socios": {
            "cardinality": {
              "field": "clave socio cliente.keyword"
            }
          }
        }
        break;

      case 'total_cuentas':

        agg = {
          "agrupar_por_captacion": {
            "nested": {
              "path": "Captacion_Info"
            },
            "aggs": {
              "total_contratos": {
                "cardinality": {
                  "field": "Captacion_Info.no contrato.keyword"
                }
              }
            }
          }
        }
        break;

      case 'total_cuentas_sucursal':

        query = {
          "nested": {
            "path": "Captacion_Info",
            "query": {
              "bool": {
                "must": [
                  {
                    // Modificar el valor de tiposucursal, por el que sea seleccionado
                    "term": {
                      "Captacion_Info.tiposucursal.keyword": {
                        "value": datos
                      }
                    }
                  }
                ]
              }
            }
          }
        }

        agg = {
          "agrupar_por_captacion": {
            "nested": {
              "path": "Captacion_Info"
            },
            "aggs": {
              "total_contratos": {
                "cardinality": {
                  "field": "Captacion_Info.no contrato.keyword"
                }
              }
            }
          }
        }
        break;

      case 'total_sucursales':
        agg = {
          "agrupar_por_captacion": {
            "nested": {
              "path": "Captacion_Info"
            },
            "aggs": {
              "total_contratos": {
                "cardinality": {
                  "field": "Captacion_Info.tiposucursal.keyword"
                }
              }
            }
          }
        }
        break;

      default:
        break;
    }

    if (agg != null && agg != undefined) {
      this.body.content.aggs = agg;
    }

    if (query != null && query != undefined) {
      this.body.content.query = query;
    }


    let newBody = JSON.stringify(this.body)
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('NombreDashboard', 'dashboard1').set('Authorization', 'Bearer ' + this.token).set('TokenUsuario', '1234');
    return this._http.post(this.url + 'busquedageneral', newBody, { headers: headers });

  }

  generateBody(tipo, datos, perspectivas?: Array<string>): void {

  }

  elasticToBarChart(data): Array<any> {

    let datosBarraAux = [];

    data.forEach(elemento => {
      let elementoCadena = elemento.key;
      let newElemento = {
        name: elementoCadena,
        value: elemento.doc_count
      }
      datosBarraAux.push(newElemento);
    });

    return datosBarraAux;
  }

  elasticToLinealSimpleChart(data: Array<any>, value?: string): any {

    let datosBarraAux = [{
      name: 'Gráfica lineal',
      series: []
    }];

    if (value && value != '') {
      data.forEach(elemento => {
        let elementoCadena = elemento.key;
        let newElemento = {
          name: elementoCadena,
          value: elemento[value]
        }
        datosBarraAux[0].series.push(newElemento);
      });
    } else {
      data.forEach(elemento => {
        let elementoCadena = elemento.key;
        let newElemento = {
          name: elementoCadena,
          value: elemento.doc_count
        }
        datosBarraAux[0].series.push(newElemento);
      });
    }

    return datosBarraAux;
  }

}
