import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service/data.service';
//src/app/services/data-service/data.service

/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { FuncionesAfiliacion, MasculinoFemenino } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { Datosdummy} from '../funciones_f_pruebas';
import { CommonFunctions } from '../../../util/app-util';
/* Importación de Funciones */

@Component({
  selector: 'app-razones',
  templateUrl: './razones.component.html',
  styleUrls: ['./razones.component.css']
})
export class RazonesComponent implements OnInit {

  // Filtros perspectivas
  public filtrosPerspectivasActivos: Array<any>;
  public filtrosPerspectivasAnidados: Array<any>;
  public flag_perspectivas = false;
  public sucursal_id: string;
  public valor_seleccionado;

  // Arreglos anio y mes
  public contador_anios = 0;
  data_anios: any [] = [
    {name: '2019', value: '2019', check: true},
    {name: '2020', value: '2020', check: true},
    {name: '2021', value: '2021', check: true}
    ];
  data_meses: any [] = [
        {name: 'ene', value: 'ene', check: false},
        {name: 'feb', value: 'feb', check: false},
        {name: 'mar', value: 'mar', check: false},
        {name: 'abr', value: 'abr', check: false},
        {name: 'may', value: 'ene', check: false},
        {name: 'jun', value: 'jun', check: false},
        {name: 'jul', value: 'jul', check: false},
        {name: 'ago', value: 'ago', check: true}
        ];
  data_anios_selec: any [] = [];
  data_meses_selec: any [] = [];
  data_sucursales: any [] = [
      {name: 'suc0', value: 'suc0', check: false},
      {name: 'suc1', value: 'suc1', check: false},
      {name: 'suc2', value: 'suc2', check: false},
      {name: 'suc3', value: 'suc3', check: false},
      {name: 'suc4', value: 'suc4', check: false},
      {name: 'suc5', value: 'suc5', check: false},
      {name: 'suc6', value: 'suc6', check: false},
      {name: 'suc7', value: 'suc7', check: false}
  ];
  data_sucursales_select: any [] = [];

  ent_consolidado = true;
  public subtituloGrafica1 = 'Todos';
  public datosBarra: Array<any>;
  public datosLineal: Array<any>;


  constructor(
    public dataService: DataService,
  ) { 
    // this.rangoFecha = new RangoFecha();
    this.data_anios_selec = this.data_anios;
    // this.data_meses_selec = this.data_meses;
    this.data_meses_selec.push(this.data_meses[this.data_meses.length - 1]);
  }

  ngOnInit(): void {
  }

  // eventos de anios y mes
  VerificarAniosSeleccionados(): string{
    if (this.contador_anios > 1) {
      return 'w3-third w3-disabled';
    }
    else {
      return 'w3-third ';
    }
  }

  ContarMeses(){
    if (this.data_meses_selec.length < 5 ){
      return 'w3-row w3-tiny hijoizquierda';
    }else{
      return 'w3-row w3-tiny ';
    }
  }

  quitar_filtros() {
    // this.inicializarValores();
    this.filtrosPerspectivasActivos = [];
    this.filtrosPerspectivasAnidados = [];
    this.flag_perspectivas = false;
  }
  // eventos de anios y mes

  event_sucursalTodasFalse(){
    this.data_sucursales_select = [];
    for (let i = 0; i < this.data_sucursales.length; i++){
      this.data_sucursales[i].check = false;
      this.data_sucursales_select.push(this.data_sucursales[i]);
    }
    this.datosBarra = Datosdummy.ConstruirArregloAniosSeleccionados(this.data_sucursales_select);
  }

  event_consolidado(): void{
    this.ent_consolidado = !this.ent_consolidado;
    if (this.ent_consolidado){
      // regresar todas las sucursales a check = false
      this.event_sucursalTodasFalse();
    }
  }
}
