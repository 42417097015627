import { Component, OnInit, ViewChild, ElementRef, Renderer2, } from '@angular/core';
import { SocioclientequbosService } from './../../../services/comercial/afiliacion/afiliacion.service';
import { SocioClienteQubosEntity } from './../../../entities/base/DashboardEntities';
import { CaptacionQubosService } from './../../../services/comercial/captacion/captacion.service';
import { CarteraQubosEntity,CaptacionQubosEntity } from './../../../entities/base/DashboardEntities';

import { PermisosDimensionService } from './../../../services/qubos/permisosdimension.service';
import {UsuarioService} from './../../../services/usuario.service';
import { SucursalService } from './../../../services/sucursal.service';
import {PermisosDimensionEntity,Info_AM } from './../../../entities/base/QubosEntities';
import {PermisosDimensionCheck,ElemMap } from './../../../entities/base/DashboardEntities';
import * as $ from 'jquery';

import { pagination } from './../../../entities/base/DashboardEntities';
import {TimeEntity, InstitucionPatmirEntity, PatmirEntity} from './../../../entities/base/DashboardEntities';
import { PatmirService } from './../../../services/dashboard/patmir.service';
import { InstitucionPatmirService } from './../../../services/dashboard/institucion-patmir.service';
import { InstitucionpatmirIIService } from './../../../services/qubos/institucionpatmir-ii.service';

import { SucursalEntity } from './../../../entities/base/BaseEntities';
import { IndicadoresFunctions,CommonVariables,ConstantesQubos } from './../funciones';
import { CarteraQubosService } from './../../../services/comercial/colocacion/cartera.service';

import * as CanvasJS from './../../../../assets/canvasjs.min';


import { ChartDataSets, ChartOptions,ChartType } from 'chart.js';
import { Color, BaseChartDirective, ThemeService, } from 'ng2-charts';

import html2canvas from 'html2canvas';
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import { ignoreElements, retry } from 'rxjs/operators';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { DetalleEvaluacionIndicadorService} from './../../../services/alertatemprana/detalle-evaluacion-indicador.service';
import { Detalle_Evaluacion_IndicadorEntity } from './../../../entities/base/AlertaTemprana';
import { EvaluacionService} from './../../../services/alertatemprana/evaluacion.service';
import { EvaluacionEntity } from './../../../entities/base/AlertaTemprana';
import { IndicadorService} from './../../../services/alertatemprana/indicador.service';
import { IndicadorEntity } from './../../../entities/base/AlertaTemprana';
import { CuentaService} from './../../../services/alertatemprana/cuenta.service';
import { CuentaEntity } from './../../../entities/base/AlertaTemprana';
import { VariableService} from './../../../services/alertatemprana/variable.service';
import { VariableEntity } from './../../../entities/base/AlertaTemprana';
import { VarTempService} from './../../../services/alertatemprana/vartemp.service';
import { VarTempEntity } from './../../../entities/base/AlertaTemprana';
import { InstitucionEntity } from './../../../entities/base/AlertaTemprana';
import { InstitucionService} from './../../../services/alertatemprana/institucion.service';

// nuevos services
import {RazonesService} from './../../../services/financiero/razones.service';


/*Librería excel*/
/*import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
let workbook: ExcelProper.Workbook = new Excel.Workbook();
import { Workbook } from 'exceljs';
*/
import { ExcelService } from './../../../services/exportar/excel.service';
import { FuncionesMes } from './../funcionesAfiliacion';
import { JsonPipe } from '@angular/common';
/*Librería excel*/

import { InfoAnioMesEntitty} from './../../../entities/base/DashboardEntities';
import { InfoaniomesService } from './../../../services/dashboard/infoaniomes.service';

import {SemaforoRojo,SemaforoYellow,SemaforoGreen, SemaforoBlue} from './../SwitchIndicadores';

@Component({
  selector: 'app-balance',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.scss']
})
export class IndicadoresComponent implements OnInit {
  informacion_datos: any[] = [];
  arreglo_complete: any[] = [];
  primer_arreglo: any[] = [];
  segundo_arreglo: any[] = [];
  tercer_arreglo: any[] = [];
  arreglo_sucursales: any [] = [];
  dataPatmir_Sucursales: any[] = [];

  info_AM: any[] = [];
  selectedPeriodo: string;
  ult_mes: string;
  suma_total: number;
  aling_der: boolean;

  carteraqubos = new CarteraQubosEntity;
  sucursalEntitty = new SucursalEntity;


  data_tipoproducto: any [] = [];
  data_tipoproducto_infomes: any [] = [];

  data_indicadoresOrdenPresentacion: string[] = ['EMP','CF','CF1','CF2','CF3','CF4','CA','CA1','CA2','EF','EF1','R','R1','R2','R3','R4'];

  /* Click Fuera de componente */
  /*
  @ViewChild('demoInstituciones') demoInstituciones: ElementRef;
  @ViewChild('demoPeriodo') demoPeriodo: ElementRef;
  @ViewChild('demoDropindicadores') demoDropindicadores: ElementRef;
  @ViewChild('cuadrantecontenedor') cuadrantecontenedor: ElementRef;
  @ViewChild('headercontenedor') headercontenedor: ElementRef;
  */
  /* Click Fuera de componente */
  constructor(
    private socioclientequbosservice: SocioclientequbosService,
    private permisosdimensionservice: PermisosDimensionService,
    private usuariosService: UsuarioService,
    private sucursalservice: SucursalService,
    private patmirService: PatmirService,
    private institucionPatmirService: InstitucionPatmirService,
    private carteraqubosservice: CarteraQubosService,
    private captacionqubosservice: CaptacionQubosService,
    private detalle_evaluacion_indicadorService: DetalleEvaluacionIndicadorService,
    private evaluacionService: EvaluacionService,
    private indicadorService: IndicadorService,
    private excelService: ExcelService,
    private cuentaService: CuentaService,
    private variableService: VariableService,
    private vartempService: VarTempService,
    private renderer: Renderer2,
    private InstitucionService: InstitucionService, 
    private infoaniomesService: InfoaniomesService,
    private institucionServiceII: InstitucionpatmirIIService,
    //nuevos servicios
    private razonesFinancieras: RazonesService,
  ){}
  detalleevaluacionindicadorEntity = new Detalle_Evaluacion_IndicadorEntity;
  evaluacionEntity = new EvaluacionEntity;
  indicadorEntity = new IndicadorEntity;
  cuentaEntity = new CuentaEntity;
  variableEntity = new VariableEntity;
  vartempEntity = new VarTempEntity;
  institucionEntity = new InstitucionEntity;
  infoaniomesEntitty = new InfoAnioMesEntitty;
  data_instituciones: any [] =  [
                          {"id":5,"name":"Cosauqui","checked":false},
                          {"id":8,"name":"ItaViko","checked":false},
                          {"id":3,"name":"Ciudad Real","checked":false},
                          {"id":11,"name":"Vicente Villagrán","checked":true},
                          {"id":7,"name":"Horizontes Seguros","checked":false},
                          {"id":4,"name":"Coroneo","checked":false},
                          {"id":10,"name":"Tonaya","checked":false},
                          {"id":9,"name":"La Esperanza","checked":false},
                          {"id":2,"name":"Caja Occidente","checked":false},
                        ];
  institucionChange(name){
    $("#demoInstituciones").hide();
    for(var i = 0; i < this.data_instituciones.length;i++){
      if(name == this.data_instituciones[i].id){
        this.data_instituciones[i].checked = true;
      }else{
        this.data_instituciones[i].checked = false;
      }
    }
    this.id_at = name;
    this.getCurrentInstitucionPatmir();
    this.data_ordenado = [];
    this.contador_ejercicioperiodo = 1;
    this.cadena_evaluacion = [];
    /*this.evaluacionEntity.Id_Institucion = this.id_at;
    this.getTodasEvaluaciones(this.evaluacionEntity);
    this.getEvaluacion(this.evaluacionEntity);
    this.getVariable(this.variableEntity);
    this.getVarTemp(this.vartempEntity);
    */
    this.evaluacionEntity = new EvaluacionEntity;
    this.cuentaEntity = new CuentaEntity;
    this.indicadorEntity = new IndicadorEntity;
    this.cuentaEntity.Id_Plantilla = 5;
    this.evaluacionEntity.Id_Institucion = this.id_at;
    this.evaluacionEntity.Id_Plantilla = 5;
    this.indicadorEntity.Id_Plantilla = 5;
    this.getEvaluacion(this.evaluacionEntity);
    this.getVariable(this.variableEntity);
    this.getVarTemp(this.vartempEntity);
    this.getInstitucion(this.institucionEntity);
  }
  ngOnInit() {
    /*$(document).on("click",function(e) {
      var institucion = $("#demoInstituciones");
      var periodo = $("#demoPeriodo");
      var ejercicio = $("#demoEjercicio");
      var indicadores = $("#demoDropindicadores");
      var idindicadores = $("#demoIndicadores");
      
      var maticonIndicadores = $("#maticonIndicadores");
      var maticonInstituciones = $("#maticonInstituciones");
      var maticonPeriodo = $("#maticonPeriodo");
      var maticonEjercicio = $("#maticonEjercicio");

      var dataMes = ['ene','feb','mar','abr','jun','jul','ago','sep','oct','sep','oct','nov','dic'];
      var dataAnioadd = [$("#add_2020"),$("#add_2019"),$("#add_2018"),$("#add_2017"),$("#add_2016"),$("#add_2015"),$("#add_2014"),$("#add_2013"),$("#add_2012"),$("#add_2011"),
			  $("#add_2010"),$("#add_2009"),$("#add_2008"),$("#add_2007"),$("#add_2006"),$("#add_2005"),$("#add_2004"),$("#add_2003"),$("#add_2002"),$("#add_2001"),
			  $("#add_2000"),$("#add_1999"),$("#add_1998"),$("#add_1997"),$("#add_1996"),$("#add_1995"),$("#add_1994"),$("#add_1993"),$("#add_1992"),$("#add_1991")];
      
      var dataAnioremove = [$("#remove_2020"),$("#remove_2019"),$("#remove_2018"),$("#remove_2017"),$("#remove_2016"),$("#remove_2015"),$("#remove_2014"),$("#remove_2013"),$("#remove_2012"),$("#remove_2011"),
			  $("#remove_2010"),$("#remove_2009"),$("#remove_2008"),$("#remove_2007"),$("#remove_2006"),$("#remove_2005"),$("#remove_2004"),$("#remove_2003"),$("#remove_2002"),$("#remove_2001"),
			  $("#remove_2000"),$("#remove_1999"),$("#remove_1998"),$("#remove_1997"),$("#remove_1996"),$("#remove_1995"),$("#remove_1994"),$("#remove_1993"),$("#remove_1992"),$("#remove_1991")];

      var dataAniocheck = [$("#check_2020"),$("#check_2019"),$("#check_2018"),$("#check_2017"),$("#check_2016"),$("#check_2015"),$("#check_2014"),$("#check_2013"),$("#check_2012"),$("#check_2011"),
			  $("#check_2010"),$("#check_2009"),$("#check_2008"),$("#check_2007"),$("#check_2006"),$("#check_2005"),$("#check_2004"),$("#check_2003"),$("#check_2002"),$("#check_2001"),
			  $("#check_2000"),$("#check_1999"),$("#check_1998"),$("#check_1997"),$("#check_1996"),$("#check_1995"),$("#check_1994"),$("#check_1993"),$("#check_1992"),$("#check_1991")];

      var dataAnioMescheck = [$("#check_2020ene"),$("#check_2020feb"),$("#check_2020mar"),$("#check_2020abr"),$("#check_2020may"),$("#check_2020jun"),$("#check_2020jul"),$("#check_2020ago"),$("#check_2020sep"),$("#check_2020oct"),$("#check_2020nov"),$("#check_2020dic"),
        $("#check_2019ene"),$("#check_2019feb"),$("#check_2019mar"),$("#check_2019abr"),$("#check_2019may"),$("#check_2019jun"),$("#check_2019jul"),$("#check_2019ago"),$("#check_2019sep"),$("#check_2019oct"),$("#check_2019nov"),$("#check_2019dic"),
        $("#check_2018ene"),$("#check_2018feb"),$("#check_2018mar"),$("#check_2018abr"),$("#check_2018may"),$("#check_2018jun"),$("#check_2018jul"),$("#check_2018ago"),$("#check_2018sep"),$("#check_2018oct"),$("#check_2018nov"),$("#check_2018dic"),
        $("#check_2017ene"),$("#check_2017feb"),$("#check_2017mar"),$("#check_2017abr"),$("#check_2017may"),$("#check_2017jun"),$("#check_2017jul"),$("#check_2017ago"),$("#check_2017sep"),$("#check_2017oct"),$("#check_2017nov"),$("#check_2017dic"),
        $("#check_2020ene"),$("#check_2020feb"),$("#check_2020mar"),$("#check_2020abr"),$("#check_2020may"),$("#check_2020jun"),$("#check_2020jul"),$("#check_2020ago"),$("#check_2020sep"),$("#check_2020oct"),$("#check_2020nov"),$("#check_2020dic"),
      ];

      var dataaddindicador = [$("#add2N_CA"),$("#add2N_RT"),$("#add2N_EF"),$("#add2N_CF")];
      var dataremoveindicador = [$("#remove2N_CA"),$("#remove2N_RT"),$("#remove2N_EF"),$("#remove2N_CF")];
      var datacheck1N = [ $("#check2N_CF"),
                          $("#check2N_CA"),
                          $("#check2N_RT"),
                          $("#check2N_EF")];
      var datacheck2N = [ $("#check3N_CF1"),$("#check3N_CF2"),$("#check3N_CF3"),$("#check3N_CF4"),
                          $("#check3N_CA1"),$("#check3N_CA2"),
                          $("#check3N_RT1"),$("#check3N_RT2"),$("#check3N_RT3"),$("#check3N_RT4"),
                          $("#check3N_EF1"),$("#check3N_EF2"),$("#check3N_EF3")];

      var IndicadorNivel1 = [$("#add_EMP"),$("#remove_EMP")];

      var flaganioadd = false;
      var flaganioremove = false;
      var flaganiocheck = false;
      var flaganiomescheck = false;
      var flagaddindicador = false;
      var flagremoveindicador = false;
      var flagcheck1N = false;
      var flagcheck2N = false;
      var flagindicadorNivel1 = false;
      
      for(var i = 0; i < IndicadorNivel1.length;i++){
        if(IndicadorNivel1[i].is(e.target)){
          flagindicadorNivel1 = true;
        }
      }

      for(var i = 0; i < dataAnioadd.length;i++){
        if(dataAnioadd[i].is(e.target)){
          flaganioadd = true;
        }
      }
      for(var i = 0; i < dataAnioremove.length;i++){
        if(dataAnioremove[i].is(e.target)){
          flaganioremove = true;
        }
      }
      for(var i = 0; i < dataAniocheck.length;i++){
        if(dataAniocheck[i].is(e.target)){
          flaganiocheck = true;
        }
      }
      for(var i = 0; i < dataAnioMescheck.length;i++){
        if(dataAnioMescheck[i].is(e.target)){
          flaganiomescheck = true;
        }
      }
      for(var i = 0; i < dataaddindicador.length;i++){
        if(dataaddindicador[i].is(e.target)){
          flagaddindicador = true;
        }
      }
      for(var i = 0; i < dataremoveindicador.length;i++){
        if(dataremoveindicador[i].is(e.target)){
          flagremoveindicador = true;
        }
      }
      for(var i = 0; i < datacheck1N.length;i++){
        if(datacheck1N[i].is(e.target)){
          flagcheck1N = true;
        }
      }
      for(var i = 0; i < datacheck2N.length;i++){
        if(datacheck2N[i].is(e.target)){
          flagcheck2N = true;
        }
      }

                  " indicadores ---> " + maticonIndicadores.is(e.target) +
                  " instituciones ---> " + maticonInstituciones.is(e.target) + 
                  " Periodo ---> " + maticonPeriodo.is(e.target) +
                  " Indicadores ----> " + indicadores.is(e.target) +
                  " ID Indicadores ----> " + idindicadores.is(e.target));

      if( (maticonIndicadores.is(e.target)) || 
          (maticonInstituciones.is(e.target)) ||
          (maticonPeriodo.is(e.target)) ||
          (maticonEjercicio.is(e.target)) || 
          indicadores.is(e.target) ||
          idindicadores.is(e.target) ||
          flaganioadd || flaganioremove || flaganiocheck || flaganiomescheck || flagaddindicador || flagremoveindicador || flagcheck2N || flagcheck1N || flagindicadorNivel1){
      }else{
        if (!institucion.is(e.target) && institucion.has(e.target).length === 0) { 
          $("#demoInstituciones").hide();  
          $("#demoPeriodo").hide();
          $("#demoDropindicadores").hide();
        }
        if (!periodo.is(e.target) && periodo.has(e.target).length === 0) { 
          $("#demoInstituciones").hide();  
          $("#demoEjercicio").hide();
          $("#demoDropindicadores").hide();
        }
        if (!indicadores.is(e.target) && indicadores.has(e.target).length === 0) { 
          $("#demoInstituciones").hide();  
          $("#demoPeriodo").hide();
          $("#demoDropindicadores").hide();
        }
        if (!ejercicio.is(e.target) && ejercicio.has(e.target).length === 0) { 
          $("#demoInstituciones").hide();  
          $("#demoPeriodo").hide();
          $("#demoDropindicadores").hide();
        }
      }
    });*/

    this.detalleevaluacionindicadorEntity = new Detalle_Evaluacion_IndicadorEntity;
    this.evaluacionEntity = new EvaluacionEntity;
    this.indicadorEntity = new IndicadorEntity;
    this.cuentaEntity = new CuentaEntity;
    this.cuentaEntity.Id_Plantilla = 5;
    this.variableEntity = new VariableEntity;
    this.vartempEntity = new VarTempEntity;
    this.institucionEntity = new InstitucionEntity;
    
    this.aling_der = true;
    this.getCurrentUser();
  }
  arreglo_anios: string = "";

  socioclientequbos = new SocioClienteQubosEntity;
  captacionqubos = new CaptacionQubosEntity;
  f_switchmes(mes){
    return CommonVariables.f_switchmesA(mes);
  }
  f_switchmesm(mes){
    return CommonVariables.f_switchmesm(mes);
  }
  checked_anio: boolean = false;
  checked_mes: boolean = false;
  puntot1c1: boolean = false;
  flag_inspeccionar: boolean = false;
  subtitulot1c1: string = "";
  flag_sucursal_piechart: boolean = false;
  visibility_legend1(){}
  
  downloadChart1(cadena){
    this.exportaimg(cadena);
  }
  exportaimg(id){
    var cadena = "Some description Entidad " ;
    setTimeout(() => {
      // Charts are now rendered
      const chart = document.getElementById(id);
      html2canvas(chart, {
        height: 500,
        width: 1000,
        scale: 2,
        backgroundColor: null,
        logging: false,
        onclone: (document) => {
          //document.getElementById(id).style.visibility = 'visible';
        }
      }).then((canvas) => {
        // Get chart data so we can append to the pdf
        const chartData = canvas.toDataURL();
        // Prepare pdf structure
        const docDefinition = { content: [],
        styles: {
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
            alignment: 'left'
          },
          subsubheader: {
            fontSize: 12,
            italics: true,
            margin: [0, 10, 0, 25],
            alignment: 'left'
          },
          table: {
            margin: [0, 5, 0, 15]
          }
        },
        defaultStyle: {
          alignment: 'justify'
        },
        pageOrientation: 'portrait',  // vertical
        //pageOrientation: 'landscape', // horizontal
      };
      
      // Add some content to the pdf
      var title = {text: 'Afiliación de socios', style: 'subheader'};
      cadena = "La colocación, o también llamada colocación bancaria, permite la puesta de dinero en circulación en la economía, es decir, la banca genera un nuevo dinero del capital o los recursos que obtiene a través de la captación y, con estos recursos, se otorgan créditos a las personas, empresas u organizaciones que los soliciten. Por dar estos préstamos el banco cobra unas cantidades de dinero que se llaman intereses, o intereses de colocación, y comisiones.";
      var description = {text: cadena, style: 'subsubheader'};
      docDefinition.content.push(title);
      docDefinition.content.push(description);
      // Push image of the chart
      // Push image of the chart
      docDefinition.content.push({image: chartData, width: 500});

      // vamos aquí ----> 
      this.docDefinition = docDefinition;

      pdfMake.createPdf(docDefinition).download('chartToPdf' + '.pdf');
      });
    }, 100);
  }
  docDefinition: any;
  sucursal_seleccionada(name){
    var contador_sucursal = 0;
    this.carteraqubos.arreglo_sucursal = "";
    this.captacionqubos.arreglo_sucursal = "";
    if(name == 0){
      if(this.checked_anio){
        this.checked_anio = false;
      }else{
        this.checked_anio = true;
      }

      for(var i = 0; i < this.arreglo_sucursales.length; i ++){
        if(this.checked_anio){
          this.arreglo_sucursales[i].dbCheck = true;
        }else{
          this.arreglo_sucursales[i].dbCheck = false;
        }
      }
      this.puntobalancec1 = true;
      this.subtitulobalancec1 = "TODAS";
    }else{
      for(var i = 0; i < this.arreglo_sucursales.length; i ++){
        if(this.arreglo_sucursales[i].dbId == name.dbId){
          if(this.arreglo_sucursales[i].dbCheck){
            this.arreglo_sucursales[i].dbCheck = false;
          }else{
            this.arreglo_sucursales[i].dbCheck = true;
          }
        }
      }
      for(var i = 0; i < this.arreglo_sucursales.length; i++){
        if(this.arreglo_sucursales[i].dbCheck){
          contador_sucursal = contador_sucursal + 1;
          if(contador_sucursal == 1){

            this.carteraqubos.sucursal = this.arreglo_sucursales[i].dbId;
            this.captacionqubos.sucursaldesc = "'" + this.buscar_sucursal(this.arreglo_sucursales[i].dbId) + "'";
            this.captacionqubos.arreglo_sucursal = "'" + this.buscar_sucursal(this.arreglo_sucursales[i].dbId) + "'";
            this.carteraqubos.arreglo_sucursal = "'" + this.arreglo_sucursales[i].dbId + "'";
            this.subtitulobalancec1 = this.arreglo_sucursales[i].strName;
          }else{
            this.carteraqubos.sucursal = null;
            this.carteraqubos.arreglo_sucursal = this.carteraqubos.arreglo_sucursal + ",'" + this.arreglo_sucursales[i].dbId + "'";
            this.captacionqubos.arreglo_sucursal =  this.captacionqubos.arreglo_sucursal + ",'" + this.buscar_sucursal(this.arreglo_sucursales[i].dbId) + "'";
            this.subtitulobalancec1 = this.subtitulobalancec1 + ' | ' + this.arreglo_sucursales[i].strName;
          }
        }
      }
    }
    this.puntobalancec1 = true;
    this.subtitulobalancec1 = this.arreglo_anios + " | " + this.subtitulobalancec1;
    if(contador_sucursal > 1){
      this.carteraqubos.sucursal = null;
      this.captacionqubos.sucursaldesc = null;
    }else{
      if(contador_sucursal == 0){
        this.carteraqubos.sucursal = null;
        this.carteraqubos.arreglo_sucursal = null;
        this.captacionqubos.sucursaldesc = null;
      }else{
        this.carteraqubos.sucursal = name.dbId;
        this.captacionqubos.sucursaldesc = this.buscar_sucursal(name.dbId);
      }
    }
    this.getPrestamoTipoProducto_infomes(this.carteraqubos);
    this.getPrestamoTipoProducto(this.carteraqubos);
    this.getSaldoCaptacionMes(this.captacionqubos);
    this.getSaldoCaptacionTipoProductoMes(this.captacionqubos);
  }
  //ejercicio --> mes 
  ejercicio_periodo_seleccionado2parametros(periodo,ejercicio){
    this.carteraqubos.anio = ejercicio;
    this.carteraqubos.mes = periodo;
    for(var i = 0; i < this.informacion_datos.length; i ++){
      if(this.informacion_datos[i].strName == ejercicio){
        this.informacion_datos[i].dbCheck = true;
        for(var j = 0; j < this.informacion_datos[i].arrSeries.length; j ++){
          if(this.informacion_datos[i].arrSeries[j].strName == periodo){
            this.informacion_datos[i].arrSeries[j].dbCheck =  true;
          }
        }
      }else{
        if(!this.informacion_datos[i].dbCheck){
          this.informacion_datos[i].dbCheck = false;
          for(var j = 0; j < this.informacion_datos[i].arrSeries.length; j ++){
            this.informacion_datos[i].arrSeries[j].dbCheck = false;
          }
        }
      }
    }
    var contador_anio = 0;
    var contador_mes = 0;
    for(var i = 0; i < this.informacion_datos.length; i ++){
      if(this.informacion_datos[i].dbCheck){
        contador_anio = contador_anio + 1;
        if(contador_anio == 1){
          this.carteraqubos.anio = this.informacion_datos[i].strName;
          this.captacionqubos.anio = this.carteraqubos.anio;
          this.carteraqubos.arreglo_anios = this.informacion_datos[i].strName;
          this.arreglo_anios = this.carteraqubos.arreglo_anios;
          this.captacionqubos.arreglo_anios = this.carteraqubos.arreglo_anios;
        }else{
          this.carteraqubos.arreglo_anios = this.carteraqubos.arreglo_anios + "," + this.informacion_datos[i].strName;
          this.captacionqubos.arreglo_anios = this.carteraqubos.arreglo_anios;
          this.arreglo_anios = this.carteraqubos.arreglo_anios;
        }
        for(var j = 0; j < this.informacion_datos[i].arrSeries.length; j ++){
          if(this.informacion_datos[i].arrSeries[j].dbCheck){
            contador_mes = contador_mes + 1;
            if(contador_mes == 1){
              this.carteraqubos.mes = this.informacion_datos[i].arrSeries[j].strName;
              this.carteraqubos.arreglo_mes = this.informacion_datos[i].arrSeries[j].strName;
              this.captacionqubos.arreglo_mes = this.carteraqubos.mes;
              this.subtitulobalancec1 = this.informacion_datos[i].arrSeries[j].strName;
              this.captacionqubos.arreglo_mes = this.carteraqubos.arreglo_mes;
            }else{
              this.carteraqubos.arreglo_mes = this.carteraqubos.arreglo_mes + "," + this.informacion_datos[i].arrSeries[j].strName;
              this.captacionqubos.arreglo_mes = this.carteraqubos.arreglo_mes;
            }   
          }
        }
      }
    }
    if(contador_anio == 1){
      this.carteraqubos.arreglo_anios = null;
    }else{
      this.carteraqubos.anio = null;
    }
    if(contador_mes == 1){
      this.carteraqubos.arreglo_mes = null;
    }else{
      this.carteraqubos.mes = null;
    }


    this.getPrestamoTipoProducto_infomes(this.carteraqubos);
    this.getPrestamoTipoProducto(this.carteraqubos);
    this.getSaldoCaptacionMes(this.captacionqubos);
    this.getSaldoCaptacionTipoProductoMes(this.captacionqubos);


  }
  contador_ejercicioperiodo: number = 1; 
  cadena_ejercicioperiodo: string = "";
  cadena_idevaluacion: string = "";
  Id_Evaluacion: string = "";

  cadena_evaluacion: any [] = [];
  cadena_evaluacionTodas: any [] = [];
  data_evaluacionaux: any [] = [];

  data_ejercicio: any [] = []; //representación a año
  data_ejercicioaux: any [] = []; //representación a año
  data_periodo: any = []; //representación a mes
  data_periodoaux: any = []; //representación a mes

  DATA_PERIODOSERIES: any [] = [];


  /*ejercicio_periodo_seleccionado(idevaluacion){
    this.contador_ejercicioperiodo = 0;
    this.data_evaluacionaux = [];
    this.cadena_evaluacion = [];
    for(var i = 0; i < this.data_evaluacion.length; i++){
      if(this.data_evaluacion[i].Id_Evaluacion == idevaluacion){
        if(this.data_evaluacion[i].check){
          this.data_evaluacion[i].check = false;
        }else{
          this.data_evaluacion[i].check = true;
        }
      }
    }
    for(var i = 0; i < this.data_evaluacion.length;i++){
      if(this.data_evaluacion[i].check){
        if(this.contador_ejercicioperiodo == 0){
          this.cadena_idevaluacion = "" + this.data_evaluacion[i].Id_Evaluacion + "";
          this.Id_Evaluacion = this.data_evaluacion[i].Id_Evaluacion;
          this.subtitulobalancec1 = FuncionesMes.f_switchmes2(this.data_evaluacion[i].Fecha_Contable.substring(5,7)) + " " + this.data_evaluacion[i].Fecha_Contable.substring(0,4);
        }else{
          this.cadena_idevaluacion = this.cadena_idevaluacion  + "," + this.data_evaluacion[i].Id_Evaluacion + "";
          this.subtitulobalancec1 = this.subtitulobalancec1 + " . " + FuncionesMes.f_switchmes2(this.data_evaluacion[i].Fecha_Contable.substring(5,7)) + " " + this.data_evaluacion[i].Fecha_Contable.substring(0,4);
        }
        this.cadena_evaluacion.push({"evaluacion":FuncionesMes.f_switchmes2(this.data_evaluacion[i].Fecha_Contable.substring(5,7)) + " " + this.data_evaluacion[i].Fecha_Contable.substring(0,4),"Id_Evaluacion":this.data_evaluacion[i].Id_Evaluacion});
        this.contador_ejercicioperiodo ++;
      }
    } 
    if(this.contador_ejercicioperiodo == 0){
      this.contador_ejercicioperiodo = 1;
      this.Id_Evaluacion = "";
      //this.cadena_evaluacion.push({"evaluacion":this.data_evaluacion[this.data_evaluacion.length - 1].Fecha_Contable.substring(0,10)});
      this.cadena_evaluacion.push({"evaluacion":FuncionesMes.f_switchmes2(this.data_evaluacion[this.data_evaluacion.length - 1].Fecha_Contable.substring(5,7,) + " " + this.data_evaluacion[this.data_evaluacion.length - 1].Fecha_Contable.substring(0,4)),"Id_Evaluacion":this.data_evaluacion[i].Id_Evaluacion});
    }
    if(this.contador_ejercicioperiodo == 1){
      this.detalleevaluacionindicadorEntity.Id_Evaluacion = this.Id_Evaluacion;
      this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = null;
    }else{
      this.detalleevaluacionindicadorEntity.Id_Evaluacion = null;
      this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = this.cadena_idevaluacion;
    }
    for(var i = 0; i < this.data_evaluacion.length;i++){
      if(this.data_evaluacion[i].check){
        //this.data_evaluacionaux.push(this.data_evaluacion[i]);
      }
    }
    //this.detalleevaluacionindicadorEntity.Id_Evaluacion = idevaluacion;
    this.getDetalle_Evaluacion_Indicador(this.detalleevaluacionindicadorEntity);
    $("#demoDropindicadores").hide();
    //this.reiniciar_checkIndicadores();
  }*/
  reiniciar_checkIndicadores(){
    for(var i = 0; i < this.data_indicadorSeries.length; i++){
      this.data_indicadorSeries[i].check = true;
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
        }
      }
    }
  }

  puntobalancec1: boolean = true;
  subtitulobalancec1: string = "";
  getpatmir_Sucursales(socioclientequbosEntitty: SocioClienteQubosEntity):void{
    this.socioclientequbosservice.getByAddUrl('sucursales_informacion',socioclientequbosEntitty).subscribe(dataArr => {
        this.arreglo_sucursales = dataArr;
    });
  }
  data_sucursales_informacion: any [] = [];
  getGraficaSucursalInformacion(sucursalEntitty: SucursalEntity){
    /*this.sucursalservice.getByAddUrl('sucursales_informacion',sucursalEntitty).subscribe(dataArr => {      
      for(var i = 0; i < dataArr.length;i++){
        this.data_sucursales_informacion.push({"strName":dataArr[i].SucursalId,"dbId":dataArr[i].SucID,"dbCheck": false});
      }
      if(this.data_sucursales_informacion.length > 10){
        this.flag_sucursal_piechart = false;
      }else{
        this.flag_sucursal_piechart = true;
      }
    });*/
  }
  data_evaluacionEntidad: any [] = [];
  getDetalle_Evaluacion_Indicador(detalle_evaluacion_indicadorEntity: Detalle_Evaluacion_IndicadorEntity):void{
    this.razonesFinancieras.getDetalleEvaluacionIndicador(detalle_evaluacion_indicadorEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          datos = datos.sort((a, b) => (a.Id_Evaluacion > b.Id_Evaluacion ? -1 : 1));
          this.actualizartreemap(datos);
          this.getIndicadorOrdenado(this.indicadorEntity,datos);
        }
      }
    });
  }
  data_evaluacionEntidadTodas: any [] = [];
  getDetalle_Evaluacion_IndicadorTodas(detalle_evaluacion_indicadorEntity: Detalle_Evaluacion_IndicadorEntity):void{
    /*this.detalle_evaluacion_indicadorService.getByAddUrl('evaluacion',detalle_evaluacion_indicadorEntity).subscribe(dataArr => {
        this.data_evaluacionEntidadTodas = dataArr;
        //this.actualizartreemap(dataArr);
        this.getIndicadorOrdenadoTodas(this.indicadorEntity,dataArr);
    });*/
  }
  data_evaluacion: any [] = [];
  getEvaluacion(evaluacionEntity: EvaluacionEntity):void{
    this.razonesFinancieras.getEvaluacion(evaluacionEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.obtenerultimaevaluacion(datos);
          this.getCuenta(this.cuentaEntity);
        }
      }
    });
  }

  getEvaluacionTodas(evaluacionEntity: EvaluacionEntity):void{
    /*this.evaluacionService.getByAddUrl('evaluacion',evaluacionEntity).subscribe(dataArr => {
        //this.obtenerultimaevaluacion(dataArr);
        let detalleevaluaciontodas = this.detalleevaluacionindicadorEntity;

        var contador = 0;
        var cadena = "";
        this.cadena_evaluacionTodas = [];
        for(var i = 0; i < dataArr.length;i++){
          if(contador == 0){
            cadena = "" + dataArr[i].Id_Evaluacion;
          }else{
            cadena = cadena + "," + dataArr[i].Id_Evaluacion;
          }
          contador ++;
          this.cadena_evaluacionTodas.push({"evaluacion":FuncionesMes.f_switchmes2(dataArr[i].Fecha_Contable.substring(5,7)) + " " + dataArr[i].Fecha_Contable.substring(2,4),"Id_Evaluacion":dataArr[i].Id_Evaluacion});
        }
        detalleevaluaciontodas.ArregloID_evaluacion = cadena
        this.getDetalle_Evaluacion_IndicadorTodas(detalleevaluaciontodas);
        //this.getCuenta(this.cuentaEntity);
    });*/
  }
  getTodasEvaluaciones(evaluacionEntity: EvaluacionEntity):void{
    /*this.evaluacionService.getByAddUrl('evaluacion',evaluacionEntity).subscribe(dataArr => {
      //this.obtenerultimaevaluacion(dataArr);
      //this.getCuenta(this.cuentaEntity);
    });*/
  }
  data_cuenta: any [] = [];
  getCuenta(cuentaEntity: CuentaEntity):void{
    this.razonesFinancieras.getCuenta(cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_cuenta = datos;
        }
      }
    });
  }
  data_variable: any [] = [];
  getVariable(variableEntity: VariableEntity):void{
    this.razonesFinancieras.getVariable(variableEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_variable = datos;
        }
      }
    });
  }
  data_vartemp: any [] = [];
  getVarTemp(vartempEntity: VarTempEntity):void{
    this.razonesFinancieras.getVariableTemporal(vartempEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_vartemp = datos;
        }
      }
    });
  }
  data_institucion: any [] = [];
  getInstitucion(institucionEntity: InstitucionEntity):void{
    /*this.InstitucionService.getByAddUrl('institucion',institucionEntity).subscribe(dataArr => {
      this.data_institucion = dataArr;
    });*/
  }
  getInstituciones(institucionEntity: InstitucionEntity):void{
    /*this.InstitucionService.getByAddUrl('institucion',institucionEntity).subscribe(dataArr => {
      this.ConstruirInstituciones(dataArr);
    });*/
  }
  ConstruirInstituciones(arreglo){
    this.data_instituciones = [];
    var flag = false;
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Id_Institucion != 1){
        if(this.id_at == arreglo[i].Id_Institucion){
          flag = true;
        }else{
          flag = false;
        }
        this.data_instituciones.push({"id":arreglo[i].Id_Institucion,"name":arreglo[i].Descripcion,"checked":flag});
      }
    }

  }
  
  FuncionEjercicioPeriodo(arreglo){
    var AuxArreglo = [];
    for(var i = 0; i < arreglo.length;i++){
      AuxArreglo.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":true,"ejercicioperiodo":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4),"Fecha_Contable":arreglo[i].Fecha_Contable});

      if(!this.BuscarAnioPeriodoSeries(arreglo[i].Fecha_Contable.substring(0,4))){
        this.DATA_PERIODOSERIES.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":false,"anio":arreglo[i].Fecha_Contable.substring(0,4)});
      }
      if(!this.BuscarAnio(arreglo[i].Fecha_Contable.substring(0,4)))
        this.data_ejercicio.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":false,"anio":arreglo[i].Fecha_Contable.substring(0,4)});
    }

    this.CovertirAnioPeriodoSeries(arreglo);

    this.data_ejercicio[this.data_ejercicio.length - 1 ].check = true;
    this.data_ejercicioaux.push(this.data_ejercicio[this.data_ejercicio.length - 1]);
    this.ConstuirMesAnio(this.data_ejercicio[this.data_ejercicio.length - 1 ].anio,AuxArreglo);
    return AuxArreglo;
  }
  CovertirAnioPeriodoSeries(arreglo){
    var aux = [];
    var data = [];
    for(var i = 0;i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < arreglo.length;j++){
        if(this.DATA_PERIODOSERIES[i].anio == arreglo[j].Fecha_Contable.substring(0,4)){
          aux.push({"Id_Evaluacion":arreglo[j].Id_Evaluacion,
                    "check":false,
                    "mes": FuncionesMes.f_switchmes2(arreglo[j].Fecha_Contable.substring(5,7)),
                    "anio":arreglo[j].Fecha_Contable.substring(0,4)});
        }
      }
      data.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].Id_Evaluacion,"check":false,"anio":this.DATA_PERIODOSERIES[i].anio,"series":aux});
      aux = [];
    }
    this.DATA_PERIODOSERIES = data;
    this.data_evaluacionaux = [];
    var inicio = 0;
    var fin = 0;
    if(this.TipoInstitucion == 'SO'){
      if(arreglo.length > 6){
        inicio = 0;
        fin = 6;
      }else{
        inicio = 0;
        fin = arreglo.length;
      }
    }else{
      if(arreglo.length > 6){
        inicio = arreglo.length - 6;
        fin = arreglo.length;
      }else{
        inicio = 0;
        fin = arreglo.length;
      }
    }
    for(var i = inicio; i < fin;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES.length;j++){
        for(var k = 0; k < this.DATA_PERIODOSERIES[j].series.length;k++){
          if(arreglo[i].Id_Evaluacion == this.DATA_PERIODOSERIES[j].series[k].Id_Evaluacion){
            this.DATA_PERIODOSERIES[j].series[k].check = true;            
            this.data_evaluacionaux.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"ejercicioperiodo":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4)});
          }
        }
      }
    }
  } 
  ConstuirMesAnio(anio,arreglo){
    this.data_periodo = [];
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Fecha_Contable.substring(0,4) == anio){
        if(!this.BuscarMes(FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)))){
          this.data_periodo.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":true,"mes":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7))});
        }
      }
    }
    this.data_periodoaux = this.data_periodo;
  }
  BuscarMes(mes){
    var flag = false;
    for(var i = 0; i < this.data_periodo.length;i++){
      if(this.data_periodo[i].mes == mes){
        flag = true;
      }
    }
    return flag;
  }
  BuscarAnio(anio){
    var flag = false;
    for(var i = 0; i < this.data_ejercicio.length;i++){
      if(this.data_ejercicio[i].anio == anio){
        flag = true;
      }
    }
    return flag;
  }
  BuscarAnioPeriodoSeries(anio){
    var flag = false;
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      if(this.DATA_PERIODOSERIES[i].anio == anio){
        flag = true;
      }
    }
    return flag;
  }
  /*TodasEvaluacion(arreglo){
    var cadena = "", cad = "";
    this.cadena_evaluacion = [];
    for(var i = 0; i < arreglo.length;i++){
      if(i == 0){
        cadena = arreglo[i].Id_Evaluacion;
        cad = FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4);
      }else{
        cadena = cadena + "," + arreglo[i].Id_Evaluacion;
        cad = cad + " . " + FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4);
      }
      this.cadena_evaluacion.push({"evaluacion":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4),"Id_Evaluacion":arreglo[i].Id_Evaluacion});
    }
    this.subtitulobalancec1 = cad;
    return cadena;
  }*/
  UltimasSeisEvaluaciones(arreglo){
    var cadena = "", cad = "";
    var inicio = 0;
    var fin = 0;
    if(this.TipoInstitucion == 'SO'){
      if(arreglo.length > 6){
        inicio = 0;
        fin = 6;
      }else{
        inicio = 0;
        fin = arreglo.length;
      }
    }else{
      if(arreglo.length > 6){
        inicio = arreglo.length - 6;
        fin = arreglo.length;
      }else{
        inicio = 0;
        fin = arreglo.length;
      }
    }
    this.cadena_evaluacion = [];
    var contador = 0;
    for(var i = inicio; i < fin;i++){
      if(contador == 0){
        cadena = arreglo[i].Id_Evaluacion;
        cad = FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4);
      }else{
        cadena = cadena + "," + arreglo[i].Id_Evaluacion;
        cad = cad + " | " + FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4);
      }
      contador ++;
      this.cadena_evaluacion.push({"evaluacion":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4),"Id_Evaluacion":arreglo[i].Id_Evaluacion});
    }
    this.subtitulobalancec1 = cad;
    return cadena;
  }
  obtenerultimaevaluacion(arreglo){
    arreglo.sort(function (a, b) {
      if (a.Fecha_Contable.substring(0,7) > b.Fecha_Contable.substring(0,7)) {
        return 1;
      }
      if (a.Fecha_Contable.substring(0,7) < b.Fecha_Contable.substring(0,7)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = null;
    //this.detalleevaluacionindicadorEntity.Id_Evaluacion = arreglo[arreglo.length - 1].Id_Evaluacion;
    this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = this.UltimasSeisEvaluaciones(arreglo);

    var UltSixEval = [];
    var inicio = 0;
    if(this.TipoInstitucion == 'SO'){
      if(arreglo.length > 6){
        inicio = 0;
      }
      for(var i = inicio;i < 6;i++){
        UltSixEval.push(arreglo[i]);
      }

      arreglo[arreglo.length - 1].check = true;
    }else{
      if(arreglo.length > 6){
        inicio = arreglo.length - 6;
      }
      for(var i = inicio;i < arreglo.length;i++){
        UltSixEval.push(arreglo[i]);
      }

      arreglo[arreglo.length - 1].check = true;
    }

      
    
    this.data_evaluacion = this.FuncionEjercicioPeriodo(arreglo);
    
    this.getDetalle_Evaluacion_Indicador(this.detalleevaluacionindicadorEntity);
  }

  data_indicador: any [] = [];
  getIndicador(indicadorEntity: IndicadorEntity):void{
    this.razonesFinancieras.getIndicador(indicadorEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_indicador = datos;
          this.function_ordenarporniveles(datos);
        }
      }
    });
  }
  getIndicadorOrdenado(indicadorEntity: IndicadorEntity,arreglo):void{
    this.razonesFinancieras.getIndicador(indicadorEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;//estos son los indicadores
        if (datos){
          this.data_indicador = datos;
          this.convertirIndicadorSeries(datos);
          let arreglo_aux:any [] = [];
          for (var i = 0; i < arreglo.length; i++){
            arreglo_aux.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"Id_Indicador":arreglo[i].Id_Indicador,
                              "Valor":arreglo[i].Valor,"Respuesta":arreglo[i].Respuesta,"EstadoSemaforo":arreglo[i].EstadoSemaforo,
                              "ResultadoIndicador":arreglo[i].ResultadoIndicador,"Rojos":arreglo[i].Rojos,"Amarillos":arreglo[i].Amarillos,
                              "Verdes":arreglo[i].Verdes,"Nota":arreglo[i].Nota,"TextoSemaforo":arreglo[i].TextoSemaforo,
                              "indicadorEntity":datos.filter(ele => ele.Id_Indicador === arreglo[i].Id_Indicador)});
                              //this.sucursalesDisponibles.filter(e => e.check).length;
          }
          this.function_ordenarporniveles_IndicadorEvaluacion(datos,arreglo_aux);//original
        }
      }
    });
  }
  getIndicadorOrdenadoTodas(indicadorEntity: IndicadorEntity,arreglo):void{
    this.razonesFinancieras.getIndicador(indicadorEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_indicador = datos;
          this.convertirIndicadorSeries(datos);
          let arreglo_aux:any [] = [];
          for (var i = 0; i < arreglo.length; i++){
            arreglo_aux.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"Id_Indicador":arreglo[i].Id_Indicador,
                              "Valor":arreglo[i].Valor,"Respuesta":arreglo[i].Respuesta,"EstadoSemaforo":arreglo[i].EstadoSemaforo,
                              "ResultadoIndicador":arreglo[i].ResultadoIndicador,"Rojos":arreglo[i].Rojos,"Amarillos":arreglo[i].Amarillos,
                              "Verdes":arreglo[i].Verdes,"Nota":arreglo[i].Nota,"TextoSemaforo":arreglo[i].TextoSemaforo,
                              "indicadorEntity":datos.filter(ele => ele.Id_Indicador === arreglo[i].Id_Indicador)});
                              //this.sucursalesDisponibles.filter(e => e.check).length;
          }
          //this.convertirIndicadorSeriesTodas(datos);
          this.function_ordenarporniveles_IndicadorEvaluacionTodas(datos,arreglo_aux);//original
        }
      }
    });
    /*this.indicadorService.getByAddUrl('indicador',indicadorEntity).subscribe(dataArr => {
        this.data_indicador = dataArr;
        this.convertirIndicadorSeriesTodas(dataArr);
        this.function_ordenarporniveles_IndicadorEvaluacionTodas(dataArr,arreglo);//original
    });*/
  }

  data_ordenado: any [] = [];
  data_indicadorSeries: any [] = [];
  data_indicadorSeriesTodas: any [] = [];
  convertirIndicadorSeries(arreglo){
    this.data_indicadorSeries = [];
    var aux = [];
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Nivel == 1){
        if(arreglo[i].Id_Indicador != "GESTION")
          this.data_indicadorSeries.push({"Id_Indicador":arreglo[i].Id_Indicador,
                                        "Descripcion":arreglo[i].Descripcion,
                                        "Nivel1":arreglo[i].Nivel1,
                                        "Nivel2":arreglo[i].Nivel2,
                                        "Nivel3":arreglo[i].Nivel3,
                                        "Nivel4":arreglo[i].Nivel4,
                                        "Nivel":arreglo[i].Nivel,
                                        "check":true,
                                        "R0_Opcomparacion1":arreglo[i].R0_Opcomparacion1,
                                        "R0_Valor1":arreglo[i].R0_Valor1,
                                        "SeriesNivel1": this.convertirIndicadoresSeriesNivel2(arreglo,i,arreglo[i].Id_Indicador)
                                      });
          aux = [];
        }
    }
  }
  convertirIndicadorSeriesTodas(arreglo){
    this.data_indicadorSeriesTodas = [];
    var aux = [];
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Nivel == 1){
      if(arreglo[i].Id_Indicador != "GESTION")
        this.data_indicadorSeriesTodas.push({"Id_Indicador":arreglo[i].Id_Indicador,
                                      "Descripcion":arreglo[i].Descripcion,
                                      "Nivel1":arreglo[i].Nivel1,
                                      "Nivel2":arreglo[i].Nivel2,
                                      "Nivel3":arreglo[i].Nivel3,
                                      "Nivel4":arreglo[i].Nivel4,
                                      "Nivel":arreglo[i].Nivel,
                                      "check":true,
                                      "R0_Opcomparacion1":arreglo[i].R0_Opcomparacion1,
                                      "R0_Valor1":arreglo[i].R0_Valor1,
                                      "SeriesNivel1": this.convertirIndicadoresSeriesNivel2(arreglo,i,arreglo[i].Id_Indicador)
                                    });
        aux = [];
      }
    }
  }
  convertirIndicadoresSeriesNivel2(arreglo,posicion,indi){
    var aux = [];
    for(var i = posicion; i < arreglo.length;i++){
      if(arreglo[i].Nivel == 2 && arreglo[i].Nivel1 == 1  ){
        //aux.push(arreglo[i]);
        aux.push({"Id_Indicador":arreglo[i].Id_Indicador,
                  "Descripcion":arreglo[i].Descripcion,
                  "Nivel1":arreglo[i].Nivel1,
                  "Nivel2":arreglo[i].Nivel2,
                  "Nivel3":arreglo[i].Nivel3,
                  "Nivel4":arreglo[i].Nivel4,
                  "Nivel":arreglo[i].Nivel,
                  "check":true,
                  "SeriesNivel2": this.convertirIndicadoresSeriesNivel3(arreglo,i,arreglo[i].Id_Indicador)
                });
        //this.convertirIndicadoresSeriesNivel3(arreglo,i,arreglo[i].Id_Indicador.substring(0,2));
      }
    }
    return aux;
  }
  convertirIndicadoresSeriesNivel3(arreglo,posicion,subcadena){
    var aux = [];
    for(var i = posicion; i < arreglo.length;i++){
      //if(arreglo[i].Nivel == 3 && arreglo[i].Nivel1 == 1 && subcadena == arreglo[i].Id_Indicador.substring(0,2)){
        if(arreglo[i].Nivel == 3 && arreglo[i].Nivel1 == 1 && arreglo[i].Id_Indicador.includes(subcadena)){
        aux.push({"Id_Indicador":arreglo[i].Id_Indicador,
                  "Descripcion":arreglo[i].Descripcion,
                  "Nivel1":arreglo[i].Nivel1,
                  "Nivel2":arreglo[i].Nivel2,
                  "Nivel3":arreglo[i].Nivel3,
                  "Nivel4":arreglo[i].Nivel4,
                  "Nivel":arreglo[i].Nivel,
                  "check":true
        });
      }
    }
    return aux;
  }
  function_ordenarporniveles_IndicadorEvaluacionVillagran(indicador,evaluacion){
    for(var i = 0; i < indicador.length; i ++){
      this.buscarmaselementosVillagran(evaluacion,indicador[i]);
    }
  }
  function_ordenarporniveles_IndicadorEvaluacion(indicador,evaluacion){
    
    this.data_ordenado = [];
    if(this.contador_ejercicioperiodo == 1){
      for(var i = 0; i < indicador.length; i ++){
          this.buscarmaselementos(evaluacion,indicador[i]);
      }
    }else{
      var contador_elementos = 0;
      var aux = [];
      for(var i = 0; i < indicador.length; i ++){
        this.buscarmaselementos(evaluacion,indicador[i]);
      }
    }
    let data_ordenadoRespaldo = [];
    for(var i = 0; i < this.data_indicadoresOrdenPresentacion.length;i++){
      for (var j = 0; j < this.data_ordenado.length;j++){
        if (this.data_indicadoresOrdenPresentacion[i] == this.data_ordenado[j].Id_Indicador){
          data_ordenadoRespaldo.push(this.data_ordenado[j]);
        }
      }
    }
    this.data_ordenado = data_ordenadoRespaldo;
    this.datosTarjeta();

    this.data_ordenadoFormulas = this.data_ordenado;
    //this.convertirIndicadorSeriesTodas(this.data_ordenado);
    this.convertirIndicadorSeries(this.data_ordenado);
    //this.ocultar_primerNivel();
  }
  public dataTarjeta = [];
  public dataNomTarj = [];
  datosTarjeta(){
    this.dataTarjeta = [];
    for(const d of this.data_ordenado){
      const dat = d['Series'][d.Series.length-1];
      this.dataTarjeta.push(dat);
      const nom = d['Descripcion'];
      this.dataNomTarj.push(nom);
    }

  }

  data_ordenadoTodas: any [] = [];
  contador_ejercicioperiodoTodas: number = 0;
  function_ordenarporniveles_IndicadorEvaluacionTodas(indicador,evaluacion){//este es para convertir a Excel
    this.data_ordenadoTodas = [];
    if(this.contador_ejercicioperiodoTodas == 1){
      for(var i = 0; i < indicador.length; i ++){
        this.buscarmaselementosTodas(evaluacion,indicador[i]);
      }
    }else{
      var contador_elementos = 0;
      var aux = [];
      for(var i = 0; i < indicador.length; i ++){
        this.buscarmaselementosTodas(evaluacion,indicador[i]);
      }
    }    
    this.data_ordenadoFormulas = this.data_ordenadoTodas;

    for(var i = 0; i < this.data_ordenadoTodas.length;i++){
      if(this.data_ordenadoTodas[i].Nivel == 1){
        aux.push(this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Nivel == 2){
        aux.push("    " + this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Nivel == 3){
        aux.push("        " + this.data_ordenadoTodas[i].Descripcion);
      }
      aux.push(this.data_ordenadoTodas[i].Id_Indicador);
      aux.push(this.FormulaRoja(this.data_ordenadoTodas[i].Id_Indicador));
      aux.push(this.FormulaYellow(this.data_ordenadoTodas[i].Id_Indicador));
      aux.push(this.FormulaGreen(this.data_ordenadoTodas[i].Id_Indicador));
      for(var j = 0; j < this.data_ordenadoTodas[i].Series.length;j++){
        if(this.data_ordenadoTodas[i].Series[j].TextoSemaforo == "ERROR"){
          aux.push(this.data_ordenadoTodas[i].Series[j].Valor + " "+ "-");
        }else{
          aux.push(this.data_ordenadoTodas[i].Series[j].Valor + " "+ this.data_ordenadoTodas[i].Series[j].TextoSemaforo);
        }
      }
      this.data_excel.push(aux)
      aux = [];
    }
    this.encabezado_excel = ["Descripción","Código","V","A+","A","R"];
    for(var i = 0; i < this.cadena_evaluacionTodas.length; i++){
      this.encabezado_excel.push(this.cadena_evaluacionTodas[i].evaluacion);
    }
    this.excelService.generateExcelIndicadores(this.encabezado_excel,this.data_excel,this.cadena_evaluacionTodas.length,this.instituciondesc);
  }
  instituciondesc: string = "";
  ocultar_primerNivel(){
    this.data_ordenadoFormulas = [];
    for(var i = 0; i < this.data_ordenado.length; i ++){
      if(this.data_ordenado[i].Nivel != 1){
        this.data_ordenadoFormulas.push(this.data_ordenado[i]);
      }
    }
    /*for(var i = 0; i < this.data_ordenadoFormulas.length; i++){
      if(this.data_ordenadoFormulas[i].Nivel == 1){
        this.data_ordenadoFormulas[i].Check = false;
      }
    }*/
  }
  buscarmaselementos(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    //this.cadena_evaluacion = this.cadena_evaluacion.sort((a, b) => (a.Id_Evaluacion > b.Id_Evaluacion ? -1 : 1));
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
            if(elemento.Id_Indicador == arreglo[i].indicadorEntity[0].Id_Indicador && this.TipoInstitucion == 'SO' && elemento.Id_Plantilla == 5){
              contador ++;
              aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
              aux = arreglo[i];
            }
            if(elemento.Id_Indicador == arreglo[i].indicadorEntity[0].Id_Indicador && this.TipoInstitucion != 'SO' && arreglo[i].indicadorEntity[0].Id_Plantilla == 5){
              contador ++;
              aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
              aux = arreglo[i];
            }
        }
      }
    }
    //if(aux.Id_Indicador != "GESTION"){
       /*if(this.TipoInstitucion == 'SO' && aux.indicadorEntity[0].Id_Plantilla == 5){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Indicador":aux.Id_Indicador,
          "Descripcion":aux.indicadorEntity.Descripcion,
          "Formula":aux.indicadorEntity.Formula,
          "Formula_Mate":IndicadoresFunctions.BuscarCuentaIdInFormula(aux.indicadorEntity.Formula,this.data_cuenta,this.data_variable,this.data_vartemp), //sucessfull
          "Nivel": aux.indicadorEntity.Nivel,
          "Check":true,
          "R0_Opcomparacion1":aux.indicadorEntity.R0_Opcomparacion1,
          "R0_Valor1":aux.indicadorEntity.R0_Valor1,
          "R0_Oplogico1":aux.indicadorEntity.R0_Oplogico1,
          "R0_Opcomparacion2":aux.indicadorEntity.R0_Opcomparacion2,
          "R0_Valor2":aux.indicadorEntity.R0_Valor2,
          "R0_Usar":aux.indicadorEntity.R0_Usar,
          "R1_Usar":aux.indicadorEntity.R1_Usar,
          "R1_Opcomparacion1":aux.indicadorEntity.R1_Opcomparacion1,
          "R1_Valor1":aux.indicadorEntity.R1_Valor1,
          "R1_Oplogico1":aux.indicadorEntity.R1_Oplogico1,
          "R1_Opcomparacion2":aux.indicadorEntity.R1_Opcomparacion2,
          "R1_Valor2":aux.indicadorEntity.R1_Valor2,
          "R2_Usar":aux.indicadorEntity.R2_Usar,
          "R2_Opcomparacion1":aux.indicadorEntity.R2_Opcomparacion1,
          "R2_Valor1":aux.indicadorEntity.R2_Valor1,
          "R2_Oplogico1":aux.indicadorEntity.R2_Oplogico1,
          "R2_Opcomparacion2":aux.indicadorEntity.R2_Opcomparacion2,
          "R2_Valor2":aux.indicadorEntity.R2_Valor2,
          "R3_Usar":aux.indicadorEntity.R3_Usar,
          "R4_Usar":aux.indicadorEntity.R4_Usar,
          "R5_Usar":aux.indicadorEntity.R5_Usar,
          "R5_Opcomparacion1":aux.indicadorEntity.R5_Opcomparacion1,
          "R5_Valor1":aux.indicadorEntity.R5_Valor1,
          "R5_Oplogico1":aux.indicadorEntity.R5_Oplogico1,
          "R5_Opcomparacion2":aux.indicadorEntity.R5_Opcomparacion2,
          "R5_Valor2":aux.indicadorEntity.R5_Valor2,
          "Series": aux_elemento
        });

       }
       if(this.TipoInstitucion != 'SO' && aux.indicadorEntity[0].Id_Plantilla == 5){*/
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Indicador":aux.Id_Indicador,
          "Descripcion":aux.indicadorEntity[0].Descripcion,
          "Formula":aux.indicadorEntity[0].Formula,
          "Formula_Mate":IndicadoresFunctions.BuscarCuentaIdInFormula(aux.indicadorEntity[0].Formula,this.data_cuenta,this.data_variable,this.data_vartemp), //sucessfull
          "Nivel": aux.indicadorEntity[0].Nivel,
          "Nivel1": aux.indicadorEntity[0].Nivel1,
          "Nivel2": aux.indicadorEntity[0].Nivel2,
          "Nivel3": aux.indicadorEntity[0].Nivel3,
          "Nivel4": aux.indicadorEntity[0].Nivel4,
          "Check":true,
          "R0_Opcomparacion1":aux.indicadorEntity[0].R0_Opcomparacion1,
          "R0_Valor1":aux.indicadorEntity[0].R0_Valor1,
          "R0_Oplogico1":aux.indicadorEntity[0].R0_Oplogico1,
          "R0_Opcomparacion2":aux.indicadorEntity[0].R0_Opcomparacion2,
          "R0_Valor2":aux.indicadorEntity[0].R0_Valor2,
          "R0_Oplogico2":aux.indicadorEntity[0].R0_Oplogico2,
          "R0_Opcomparacion3":aux.indicadorEntity[0].R0_Opcomparacion3,
          "R0_Valor3":aux.indicadorEntity[0].R0_Valor3,
          "R0_Oplogico3":aux.indicadorEntity[0].R0_Oplogico3,
          "R0_Opcomparacion4":aux.indicadorEntity[0].R0_Opcomparacion4,
          "R0_Valor4":aux.indicadorEntity[0].R0_Valor4,
          "R0_Usar":aux.indicadorEntity[0].R0_Usar,
          "R1_Usar":aux.indicadorEntity[0].R1_Usar,
          "R1_Opcomparacion1":aux.indicadorEntity[0].R1_Opcomparacion1,
          "R1_Valor1":aux.indicadorEntity[0].R1_Valor1,
          "R1_Oplogico1":aux.indicadorEntity[0].R1_Oplogico1,
          "R1_Opcomparacion2":aux.indicadorEntity[0].R1_Opcomparacion2,
          "R1_Valor2":aux.indicadorEntity[0].R1_Valor2,
          "R2_Usar":aux.indicadorEntity[0].R2_Usar,
          "R2_Opcomparacion1":aux.indicadorEntity[0].R2_Opcomparacion1,
          "R2_Valor1":aux.indicadorEntity[0].R2_Valor1,
          "R2_Oplogico1":aux.indicadorEntity[0].R2_Oplogico1,
          "R2_Opcomparacion2":aux.indicadorEntity[0].R2_Opcomparacion2,
          "R2_Valor2":aux.indicadorEntity[0].R2_Valor2,
          "R2_Oplogico2":aux.indicadorEntity[0].R2_Oplogico2,
          "R2_Opcomparacion3":aux.indicadorEntity[0].R2_Opcomparacion3,
          "R2_Valor3":aux.indicadorEntity[0].R2_Valor3,
          "R2_Oplogico3":aux.indicadorEntity[0].R2_Oplogico3,
          "R2_Opcomparacion4":aux.indicadorEntity[0].R2_Opcomparacion4,
          "R2_Valor4":aux.indicadorEntity[0].R2_Valor4,
          "R3_Usar":aux.indicadorEntity[0].R3_Usar,
          "R3_Opcomparacion1":aux.indicadorEntity[0].R3_Opcomparacion1,
          "R3_Valor1":aux.indicadorEntity[0].R3_Valor1,
          "R3_Oplogico1":aux.indicadorEntity[0].R3_Oplogico1,
          "R3_Opcomparacion2":aux.indicadorEntity[0].R3_Opcomparacion2,
          "R3_Valor2":aux.indicadorEntity[0].R3_Valor2,
          "R3_Oplogico2":aux.indicadorEntity[0].R3_Oplogico2,
          "R3_Opcomparacion3":aux.indicadorEntity[0].R3_Opcomparacion3,
          "R3_Valor3":aux.indicadorEntity[0].R3_Valor3,
          "R3_Oplogico3":aux.indicadorEntity[0].R3_Oplogico3,
          "R3_Opcomparacion4":aux.indicadorEntity[0].R3_Opcomparacion4,
          "R3_Valor4":aux.indicadorEntity[0].R3_Valor4,
          "R4_Usar":aux.indicadorEntity[0].R4_Usar,
          "R5_Usar":aux.indicadorEntity[0].R5_Usar,
          "R5_Opcomparacion1":aux.indicadorEntity[0].R5_Opcomparacion1,
          "R5_Valor1":aux.indicadorEntity[0].R5_Valor1,
          "R5_Oplogico1":aux.indicadorEntity[0].R5_Oplogico1,
          "R5_Opcomparacion2":aux.indicadorEntity[0].R5_Opcomparacion2,
          "R5_Valor2":aux.indicadorEntity[0].R5_Valor2,
          "R5_Oplogico2":aux.indicadorEntity[0].R5_Oplogico2,
          "R5_Opcomparacion3":aux.indicadorEntity[0].R5_Opcomparacion3,
          "R5_Valor3":aux.indicadorEntity[0].R5_Valor3,
          "R5_Oplogico3":aux.indicadorEntity[0].R5_Oplogico3,
          "R5_Opcomparacion4":aux.indicadorEntity[0].R5_Opcomparacion4,
          "R5_Valor4":aux.indicadorEntity[0].R5_Valor4,
          "Series": aux_elemento
        });

       //}
     //}
   }
  data_formulas: any[] =[{"id":"CF",}];
  buscarmaselementosVillagran(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var i = 0; i < arreglo.length; i++){
      if(elemento.Id_Indicador == arreglo[i].indicadorEntity.Id_Indicador){
        contador ++;
        aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
        aux = arreglo[i];
      }
    }
    /*if(aux.Id_Indicador != "EMP" && aux.Id_Indicador != "EF2" && aux.Id_Indicador != "GESTION")
      this.data_ordenado.push({
        "Id_Evaluacion":aux.Id_Evaluacion,
        "Id_Indicador":aux.Id_Indicador,
        "Descripcion":aux.indicadorEntity.Descripcion,
        "Formula":aux.indicadorEntity.Formula,
        "Formula_Mate":IndicadoresFunctions.BuscarCuentaIdInFormula(aux.indicadorEntity.Formula,this.data_cuenta,this.data_variable,this.data_vartemp), //sucessfull
        "Nivel": aux.indicadorEntity.Nivel,
        "Check":true,
        "Series": augx_elemento
      });
    */
   if(aux.Id_Indicador != "GESTION")
      this.data_ordenado.push({
        "Id_Evaluacion":aux.Id_Evaluacion,
        "Id_Indicador":aux.Id_Indicador,
        "Descripcion":aux.indicadorEntity.Descripcion,
        "Formula":aux.indicadorEntity.Formula,
        "Formula_Mate":IndicadoresFunctions.BuscarCuentaIdInFormula(aux.indicadorEntity.Formula,this.data_cuenta,this.data_variable,this.data_vartemp), //sucessfull
        "Nivel": aux.indicadorEntity.Nivel,
        "Nivel1": aux.indicadorEntity.Nivel1,
        "Nivel2": aux.indicadorEntity.Nivel2,
        "Nivel3": aux.indicadorEntity.Nivel3,
        "Nivel4": aux.indicadorEntity.Nivel4,
        "Check":true,
        "R0_Opcomparacion1":aux.indicadorEntity.R0_Opcomparacion1,
        "R0_Valor1":aux.indicadorEntity.R0_Valor1,
        "R0_Oplogico1":aux.indicadorEntity.R0_Oplogico1,
        "R0_Usar":aux.indicadorEntity.R0_Usar,
        "R1_Usar":aux.indicadorEntity.R1_Usar,
        "R1_Oplogico1":aux.indicadorEntity.R1_Oplogico1,
        "R1_Opcomparacion2":aux.indicadorEntity.R1_Opcomparacion2,
        "R1_Valor2":aux.indicadorEntity.R1_Valor2,
        "R2_Usar":aux.indicadorEntity.R2_Usar,
        "R2_Opcomparacion1":aux.indicadorEntity.R2_Opcomparacion1,
        "R2_Valor1":aux.indicadorEntity.R2_Valor1,
        "R2_Oplogico1":aux.indicadorEntity.R2_Oplogico1,
        "R2_Opcomparacion2":aux.indicadorEntity.R2_Opcomparacion2,
        "R2_Valor2":aux.indicadorEntity.R2_Valor2,
        "R3_Usar":aux.indicadorEntity.R3_Usar,
        "R4_Usar":aux.indicadorEntity.R4_Usar,
        "R5_Usar":aux.indicadorEntity.R5_Usar,
        "R5_Opcomparacion1":aux.indicadorEntity.R5_Opcomparacion1,
        "R5_Valor1":aux.indicadorEntity.R5_Valor1,
        "R5_Oplogico1":aux.indicadorEntity.R5_Oplogico1,
        "R5_Opcomparacion2":aux.indicadorEntity.R5_Opcomparacion2,
        "R5_Valor2":aux.indicadorEntity.R5_Valor2,
        "Series": aux_elemento
      });
  }

  buscarmaselementosTodas(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacionTodas.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacionTodas[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Indicador == arreglo[i].indicadorEntity.Id_Indicador){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }
     //if(aux.Id_Indicador != "GESTION")
        this.data_ordenadoTodas.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Indicador":aux.Id_Indicador,
          "Descripcion":aux.indicadorEntity.Descripcion,
          "Formula":aux.indicadorEntity.Formula,
          "Formula_Mate":IndicadoresFunctions.BuscarCuentaIdInFormula(aux.indicadorEntity.Formula,this.data_cuenta,this.data_variable,this.data_vartemp), //sucessfull
          "Nivel": aux.indicadorEntity.Nivel,
          "Check":true,
          "R0_Opcomparacion1":aux.indicadorEntity.R0_Opcomparacion1,
          "R0_Valor1":aux.indicadorEntity.R0_Valor1,
          "R0_Oplogico1":aux.indicadorEntity.R0_Oplogico1,
          "R0_Opcomparacion2":aux.indicadorEntity.R0_Opcomparacion2,
          "R0_Valor2":aux.indicadorEntity.R0_Valor2,
          "R0_Usar":aux.indicadorEntity.R0_Usar,
          "R1_Usar":aux.indicadorEntity.R1_Usar,
          "R1_Opcomparacion1":aux.indicadorEntity.R1_Opcomparacion1,
          "R1_Valor1":aux.indicadorEntity.R1_Valor1,
          "R1_Oplogico1":aux.indicadorEntity.R1_Oplogico1,
          "R1_Opcomparacion2":aux.indicadorEntity.R1_Opcomparacion2,
          "R1_Valor2":aux.indicadorEntity.R1_Valor2,
          "R2_Usar":aux.indicadorEntity.R2_Usar,
          "R2_Opcomparacion1":aux.indicadorEntity.R2_Opcomparacion1,
          "R2_Valor1":aux.indicadorEntity.R2_Valor1,
          "R2_Oplogico1":aux.indicadorEntity.R2_Oplogico1,
          "R2_Opcomparacion2":aux.indicadorEntity.R2_Opcomparacion2,
          "R2_Valor2":aux.indicadorEntity.R2_Valor2,
          "R3_Usar":aux.indicadorEntity.R3_Usar,
          "R4_Usar":aux.indicadorEntity.R4_Usar,
          "R5_Usar":aux.indicadorEntity.R5_Usar,
          "R5_Opcomparacion1":aux.indicadorEntity.R5_Opcomparacion1,
          "R5_Valor1":aux.indicadorEntity.R5_Valor1,
          "R5_Oplogico1":aux.indicadorEntity.R5_Oplogico1,
          "R5_Opcomparacion2":aux.indicadorEntity.R5_Opcomparacion2,
          "R5_Valor2":aux.indicadorEntity.R5_Valor2,
          "Series": aux_elemento
        });
  }
  buscarenordenado(elemento){
    for(var i = 0; i < this.data_ordenado.length; i++){
      if(elemento.Id_Indicador == this.data_ordenado[i].Id_Indicador){
        this.data_ordenado[i].push(elemento);
      }
    }
  }
  data_constructor_indicador: any [] = [];
  function_ordenarporniveles(arreglo){
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Nivel == 1){
        for(var j = 0; j < arreglo.length; j++){
          if(arreglo[j].Nivel == 2 && arreglo[j].Nivel1 == 1){
          }
        }
      }
    }
  }
  buscarnivel(nivel){
    var flag = false;
    if(nivel == 1){
      flag = true;
    }
    return flag;
  }
  buscarnivel1(nivel){
    var flag = false;
    if(nivel == 2){
      flag = true;
    }
    return flag;
  }
  buscarnivel2(nivel){
    var flag = false;
    if(nivel == 3){
      flag = true;
    }
    return flag;
  }
  getPrestamoTipoProducto(carteraqubosEntitty: CarteraQubosEntity):void{
    /*this.carteraqubosservice.getByAddUrl('carteraxtipoproductomes',carteraqubosEntitty).subscribe(dataArr => {
        //this.data_tipoproducto = dataArr;
        this.data_tipoproducto = this.completarvacios(this.data_tipoproducto_infomes,dataArr);
    });*/
  }
  suma: number = 0;
  data_tipoproducto_infomeslineal: any [] = [];
  data_tipoproducto_infomes_aux: any [] = [];
  data_saldoscaptacion: any[] = [];
  data_tipoproductocaptacionmes: any[] = [];
  getPrestamoTipoProducto_infomes(carteraqubosEntitty: CarteraQubosEntity):void{
    /*this.carteraqubosservice.getByAddUrl('carteraxtipoproducto_infomes',carteraqubosEntitty).subscribe(dataArr => {
        this.data_tipoproducto_infomes = dataArr;
        this.colocarlineal(dataArr);
        for(var i = 0; i < this.data_tipoproducto_infomes.length; i++){
          this.suma = this.suma + this.data_tipoproducto_infomes[i].value;
        }
    });*/
  }
  completarvacios(meses,producto){
    for(var i = 0; i < meses.length; i ++){
      for(var j = 0; j < producto.length; j ++){
        if(!this.busqueda(meses[i].name,producto[j].series)){
          producto[j].series.push({"name":meses[i].name,"value":0});
        }
      }
    }
    //alert("Meses --> " + JSON.stringify(meses) + " producto --> " + JSON.stringify(producto));
    return producto;
  } 
  busqueda(mes,cadena){
    var flag = false;
    for(var i = 0; i < cadena.length; i ++){
      if(mes == cadena[i].name){
        flag = true;
      }
    }
    return flag;
  }
  saldocaptaciontotal: number = 0;
  getSaldoCaptacionMes(captacionqubosEntitty: CaptacionQubosEntity):void{
    /*this.captacionqubosservice.getByAddUrl('captacionxmes',captacionqubosEntitty).subscribe(dataArr => {
        this.data_saldoscaptacion = dataArr;
        this.saldocaptaciontotal = 0;
        for(var i = 0; i < this.data_saldoscaptacion.length; i++){
          this.saldocaptaciontotal = this.saldocaptaciontotal + this.data_saldoscaptacion[i].value;
        }
    });*/
  }
  getSaldoCaptacionTipoProductoMes(captacionqubosEntitty: CaptacionQubosEntity):void{
    /*this.captacionqubosservice.getByAddUrl('captacionxtipoproductomes',captacionqubosEntitty).subscribe(dataArr => {
        this.data_tipoproductocaptacionmes = dataArr;
        //this.contruir(this.data_tipoproducto);
    });*/
  }
  colocarlineal(data){
    this.data_tipoproducto_infomeslineal = [];
    this.data_tipoproducto_infomeslineal.push({"name":this.institucionid,"series":this.data_tipoproducto_infomes});
  }
  formatear(num){
    return CommonVariables.formatear(num);
  }
  porcentaje_total(e){
    return ((e*100)/this.suma).toFixed(2);
  }
  porcentaje_totalc(e){
    return ((e*100)/this.saldocaptaciontotal).toFixed(2);
  }
  updateData(): void {
    const socio = new SocioClienteQubosEntity();
    if(this.institucionid != '0000')
      socio.ifid = this.institucionid;
    //this.getpatmir_Sucursales(socio);
  }
  institucionid: string;
  usuarioid: string;
  id_at: number = 20;
  TipoInstitucion: string = "";
  getTipoInstitucion(int){
    //this.institucionServiceII.getByAddUrl('',int).subscribe(data => {

      this.TipoInstitucion = 'SC'; //data[0].Tipo;
      /*if(this.TipoInstitucion == 'SO'){
        this.indicadorEntity.Id_Plantilla = 5;
        this.evaluacionEntity.Id_Plantilla = 5;
        this.cuentaEntity.Id_Plantilla = 5;
        this.variableEntity.Id_Plantilla = 5;
        this.vartempEntity.Id_Plantilla = 5;
        //this.detalleevaluacionindicadorEntity.Id_Plantilla = 4;
      }else{*/
        this.indicadorEntity.Id_Plantilla = 5;
        this.evaluacionEntity.Id_Plantilla = 5;
        this.cuentaEntity.Id_Plantilla = 5;
        this.variableEntity.Id_Plantilla = 5;
        this.vartempEntity.Id_Plantilla = 5;
      //}

      this.construir_primerarreglo();
      this.arreglo_informaciondatos();
      this.contadores();
      this.arreglo_general();
      this.updateData();
      
      if(this.TipoInstitucion == 'SO'){
        this.indicadorEntity.Id_Plantilla = 5;
      }else{
        this.indicadorEntity.Id_Plantilla = 5;
      }
      //this.indicadorEntity.Id_Plantilla = 3;

      this.socioclientequbos.ifid = this.institucionid;
      //this.getpatmir_Sucursales(this.socioclientequbos);//original
      
          
      this.evaluacionEntity.Id_Institucion = this.id_at;
      if(this.TipoInstitucion == 'SO'){
        this.evaluacionEntity.Id_Plantilla = 5;
      }else{
        this.evaluacionEntity.Id_Plantilla = 5;
      }
      this.getEvaluacion(this.evaluacionEntity);
      this.getVariable(this.variableEntity);
      this.getVarTemp(this.vartempEntity);
      this.getInstitucion(this.institucionEntity);

      this.carteraqubos.ifid = this.institucionid;
      this.carteraqubos.anio = '2019';
      //this.getPrestamoTipoProducto_infomes(this.carteraqubos);
      //this.getPrestamoTipoProducto(this.carteraqubos);
      this.captacionqubos.mes = this.carteraqubos.mes;
      this.captacionqubos.anio = this.carteraqubos.anio;
      this.captacionqubos.ifid = this.carteraqubos.ifid;
      //this.getSaldoCaptacionMes(this.captacionqubos);
      //this.getSaldoCaptacionTipoProductoMes(this.captacionqubos);

      this.arreglo_anios = this.selectedPeriodo;
      this.crear_lineal();
    //});
  }
  getCurrentUser() {
    //this.usuariosService.getCurrentUser().subscribe(usuario => {
        this.institucionid = '0345';//usuario.InstitucionPatmirId;
        this.usuarioid = 'DEMO2';//usuario.Login;
        //this.id_at = usuario.Id_AT;
        this.getCurrentInstitucionPatmir();
        let int = new InstitucionPatmirEntity();
        int.InstitucionPatmirId = this.institucionid;
        this.getTipoInstitucion(int);

        this.getInstituciones(this.institucionEntity);
        this.sucursalEntitty = new SucursalEntity;
        //this.sucursalEntitty.InstitucionPatmirId = this.institucionid;
        //this.getGraficaSucursalInformacion(this.sucursalEntitty);
    //});
  }
  data_SucursalesInstitucion: any [] = [];
  sucursal = new SucursalEntity;
  getCurrentSucursal(sucursalinstitucionEntity: SucursalEntity){
    /*this.sucursalservice.getByAddUrl('',sucursalinstitucionEntity).subscribe(sucursales =>{
      this.data_SucursalesInstitucion = sucursales;
    });*/
    /*this.sucursalservice.getCurrentSucursal().subscribe(sucursal => {
        this.data_SucursalesInstitucion = sucursal;
    });*/
  }
  arreglo_informaciondatos(){
    const socio = new SocioClienteQubosEntity();
    if(this.institucionid != '0000'){
      socio.ifid = this.institucionid;
      this.infoaniomesEntitty.ifid = this.institucionid;
    }
    //this.getarreglo_informaciondatos(socio);//Se cambia por infoaniomes
    this.getarreglo_informaciondatos(this.infoaniomesEntitty);
  }
  /*getarreglo_informaciondatos(socioclientequbosEntitty: SocioClienteQubosEntity):void{
    this.socioclientequbosservice.getByAddUrl('socios_aniomes',socioclientequbosEntitty).subscribe(dataArr => {*/
  getarreglo_informaciondatos(infoaniomesEntitty: InfoAnioMesEntitty):void{
    /*this.infoaniomesService.getByAddUrl('socios_aniomes',infoaniomesEntitty).subscribe(dataArr => {
      this.informacion_datos = dataArr;
      this.seleccionar_ult_aniomes();
    });*/
  }
  
  seleccionar_ult_aniomes(){
    var pos_anio = this.informacion_datos.length - 1;
    var pos_mes = this.informacion_datos[pos_anio].arrSeries.length - 1;
    this.selectedPeriodo = this.informacion_datos[pos_anio].strName;
    this.informacion_datos[pos_anio].dbCheck = true;
    this.ult_mes = this.informacion_datos[pos_anio].arrSeries[pos_mes].strName;
    this.informacion_datos[pos_anio].arrSeries[pos_mes].dbCheck = true;
    this.arreglo_anios = this.selectedPeriodo;
  }
  construir_primerarreglo(){
    this.primer_arreglo = [
      {
        "strName": "Riesgo",
        "Check": true,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Cobertura Financiera",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Solvencia",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Indice",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Coeciciente",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      }
    ];
    for(var i = 0; i < this.primer_arreglo.length;i++){
      for(var j = 0; j < this.primer_arreglo[i].arrSeries.length; j++){
        this.primer_arreglo[i].arrSeries[j].value = this.aleatorio(55,100);
      }
    }
    this.construir_AM();
    this.construir_segundoarreglo();
    this.construir_tercerarreglo();
  }
  construir_segundoarreglo(){
    this.segundo_arreglo = [
      {
        "strName": "Gestión",
        "Check": true,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Gastos",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Gastos Admon.",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Ingresos Operación",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      }
    ];
    for(var i = 0; i < this.segundo_arreglo.length;i++){
      for(var j = 0; j < this.segundo_arreglo[i].arrSeries.length; j++){
        this.segundo_arreglo[i].arrSeries[j].value = this.aleatorio(55,100);
      }
    }
  }
  construir_tercerarreglo(){
    this.tercer_arreglo = [
      {
        "strName": "Tendencia",
        "Check": true,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Tendencias",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Tendencias de cartera",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      }
    ];
    for(var i = 0; i < this.tercer_arreglo.length;i++){
      for(var j = 0; j < this.tercer_arreglo[i].arrSeries.length; j++){
        this.tercer_arreglo[i].arrSeries[j].value = this.aleatorio(55,100);
      }
    }
  }
  construir_AM(){
    var i = 1;
    for(var j = 0; j < this.primer_arreglo[i].arrSeries.length; j++){
      const infoam = new Info_AM();
      infoam.anio = this.primer_arreglo[i].arrSeries[j].anio;
      infoam.mes = this.primer_arreglo[i].arrSeries[j].mes;
      infoam.check = false;
      this.info_AM.push(infoam);
    }
  }
  aleatorio(minimo,maximo){
    return Math.floor(Math.random() * ((maximo+1)-minimo)+minimo);
  }
  contador_green: number = 0;
  contador_yellow: number = 0;
  contador_red: number = 0;
  contador_azul: number = 0;
  img(valor){
    var cad = "";
    if(valor <= 60){
      cad = "warning";
    }
    if(valor > 60 && valor < 90 ){
      cad = "square";
    }
    if(valor >= 90 && valor <= 100){
      cad = "circle";
    }
    var img = "assets/img/graficos/"+cad+".png";
    return img;
  }
  bverde(valor){
    var retorno = false;
    if(valor <= 60){
      retorno = false;
    }
    if(valor > 60 && valor < 90 ){
      retorno = false;
    }
    if(valor >= 90 && valor <= 100){
      retorno = true;
    }
    return retorno;
  }
  bred(valor){
    var retorno = false;
    if(valor <= 60){
      retorno = true;
    }
    if(valor > 60 && valor < 90 ){
      retorno = false;
    }
    if(valor >= 90 && valor <= 100){
      retorno = false;
    }
    return retorno;
  }
  byellow(valor){
    var retorno = false;
    if(valor <= 60){
      retorno = false;
    }
    if(valor > 60 && valor < 90 ){
      retorno = true;
    }
    if(valor >= 90 && valor <= 100){
      retorno = false;
    }
    return retorno;
  }
  contadores(){
    this.contador_green = 0;
    this.contador_red = 0;
    this.contador_yellow = 0;
    for(var i = 0; i < this.primer_arreglo.length;i++){
      for(var j = 0; j < this.primer_arreglo[i].arrSeries.length; j++){
        if(this.primer_arreglo[i].arrSeries[j].value  <= 60){
          this.contador_red ++;
        }
        if(this.primer_arreglo[i].arrSeries[j].value >= 90 && this.primer_arreglo[i].arrSeries[j].value <= 100 ){
          this.contador_green ++;
        }
        if(this.primer_arreglo[i].arrSeries[j].value > 60 && this.primer_arreglo[i].arrSeries[j].value < 90 ){
          this.contador_yellow ++;
        }
      }
    }
    for(var i = 0; i < this.segundo_arreglo.length;i++){
      for(var j = 0; j < this.segundo_arreglo[i].arrSeries.length; j++){
        if(this.segundo_arreglo[i].arrSeries[j].value  <= 60){
          this.contador_red ++;
        }
        if(this.segundo_arreglo[i].arrSeries[j].value >= 90 && this.segundo_arreglo[i].arrSeries[j].value <= 100 ){
          this.contador_green ++;
        }
        if(this.segundo_arreglo[i].arrSeries[j].value > 60 && this.segundo_arreglo[i].arrSeries[j].value < 90 ){
          this.contador_yellow ++;
        }
      }
    }
    for(var i = 0; i < this.tercer_arreglo.length;i++){
      for(var j = 0; j < this.tercer_arreglo[i].arrSeries.length; j++){
        if(this.tercer_arreglo[i].arrSeries[j].value  <= 60){
          this.contador_red ++;
        }
        if(this.tercer_arreglo[i].arrSeries[j].value >= 90 && this.tercer_arreglo[i].arrSeries[j].value <= 100 ){
          this.contador_green ++;
        }
        if(this.tercer_arreglo[i].arrSeries[j].value > 60 && this.tercer_arreglo[i].arrSeries[j].value < 90 ){
          this.contador_yellow ++;
        }
      }
    }
  }
  arreglo_general(){
    this.arreglo_complete =[
      {
        "strName": "Riesgo",
        "Check": true,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Cobertura Financiera",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Solvencia",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Indice",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Coeciciente",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Gestión",
        "Check": true,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Gastos",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Gastos Admon.",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Ingresos Operación",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Tendencia",
        "Check": true,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Tendencias",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      },
      {
        "strName": "Tendencias de cartera bruta",
        "Check": false,
        "arrSeries": [
          {
            "anio": "2018",
            "mes": "04",
            "value": 0
          },
          {
            "anio": "2018",
            "mes": "05",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "06",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "07",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "08",
            "value": 0
          },
        {
            "anio": "2018",
            "mes": "09",
            "value": 0
          }
        ]
      }
    ];
    for(var i = 0; i < this.arreglo_complete.length;i++){
      for(var j = 0; j < this.arreglo_complete[i].arrSeries.length; j++){
        this.arreglo_complete[i].arrSeries[j].value = this.aleatorio(55,100);
      }
    }
    
    this.titulo_indicador = "";
    this.titulo_indicador = this.obtener_sucursal_selec();
    this.titulo_indicador = this.titulo_indicador + " " + this.obtener_ejercicio_selec();
    this.titulo_indicador = this.titulo_indicador + " " + this.obtener_periodo_selec();
    $("#titulo_indicadores").html(this.titulo_indicador);
  }
  flag_combosucursal: boolean = false;
  mostrar_sucursal(){
    if(!this.flag_combosucursal){
      $("#Demo").show();
      this.flag_combosucursal = true;
    }else{
      $("#Demo").hide();
      this.flag_combosucursal = false;
    }
  }
  contruir_arreglo_sucursales(){
  }
  flag_comboejercicio: boolean = false;
  mostrar_ejercicio(){
    if(!this.flag_comboejercicio){
      $("#ejercicio").show();
      this.flag_comboejercicio = true;
    }else{
      $("#ejercicio").hide();
      this.flag_comboejercicio = false;
    }
  }
  flag_comboperiodo: boolean = false;
  mostrar_periodo(){
    if(!this.flag_comboperiodo){
      $("#periodo").show();
      this.flag_comboperiodo = true;
    }else{
      $("#periodo").hide();
      this.flag_comboperiodo = false;
    }
  }
  seleccion_ejercicioperiodo(val){
    //this.info_AM = [];
    /*for(var i = 0; i < this.informacion_datos.length; i ++){
      this.informacion_datos[i].dbCheck = false;
    }*/
    for(var i = 0; i < this.info_AM.length; i ++){
      if(this.info_AM[i].mes == val){
        if(this.info_AM[i].dbCheck){
          this.info_AM[i].dbCheck = false;
        }else{
          this.info_AM[i].dbCheck = true;
        }
      }
    }
    //this.construir_AM();
    this.arreglo_general();
  }
  obtener_sucursal_selec(){
    var cadena = "";
    for(var i = 0;i < this.arreglo_sucursales.length;i++){
      if(this.arreglo_sucursales[i].dbCheck){
        cadena = this.arreglo_sucursales[i].strName;
      }
    }
    return cadena;
  }
  obtener_ejercicio_selec(){
    var cadena = "";
    for(var i = 0;i < this.informacion_datos.length;i++){
      if(this.informacion_datos[i].dbCheck){
        cadena = this.informacion_datos[i].strName;
      }
    }
    return cadena;
  }
  obtener_periodo_selec(){
    var cadena = "";
    var contador = 0;
    for(var j = 0; j < this.info_AM.length; j ++){
      if(this.info_AM[j].dbCheck){
        if(contador == 0){
          cadena = this.info_AM[j].mes;
        }else{
          cadena = cadena + "," + this.info_AM[j].mes;
        }
        contador ++;
      }
    }
    return cadena;
  }
  titulo_indicador: string = "";
  seleccion_ejercicio(val){
    this.info_AM = [];
    for(var i = 0; i < this.informacion_datos.length; i ++){
      this.informacion_datos[i].dbCheck = false;
    }
    for(var i = 0; i < this.informacion_datos.length; i ++){
      if(this.informacion_datos[i].strName == val){
        if(this.informacion_datos[i].dbCheck){
          this.informacion_datos[i].dbCheck = false;
        }else{
          this.informacion_datos[i].dbCheck = true;
        }
      }
    }
    this.construir_AM();
    this.arreglo_general();
  }
  flag_dropsucursal: boolean = false;
  flag_dropmes: boolean = false;
  flag_dropindicadores: boolean = false;
  demoDropindicadores: boolean = false;
  myDropFuncSucursal(){
    if(this.flag_dropsucursal){
      $("#dropSucursal").hide();
      this.flag_dropsucursal = false;
    }else{
      this.flag_dropsucursal = true;
      $("#dropSucursal").show();
    }
  }
  myDropFuncmes(){
    //$("#demoDropindicadores").hide();
    //$("#demoPeriodo").hide();
    if(this.flag_dropmes){
      $("#demoInstituciones").hide();
      this.flag_dropmes = false;
    }else{
      this.flag_dropmes = true;
      $("#demoInstituciones").show();
    }
    this.demoInstituciones = this.flag_dropmes;
  }
  flag_dropPerriodo: boolean = false;
  flag_dropEjercicio: boolean = false;
  DropPeriodo(){
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
        $("#remove_"+ this.DATA_PERIODOSERIES[i].anio).hide();
        $("#add_"+ this.DATA_PERIODOSERIES[i].anio).show();
        $("#contenedor"+ this.DATA_PERIODOSERIES[i].anio).hide();
        //$("#remove2N_"+ this.DATA_PERIODOSERIES[i].series[j].anio + this.DATA_PERIODOSERIES[i].series[j].mes).hide(); 
      }
      $("#remove_"+ this.DATA_PERIODOSERIES[i].anio).hide();
      $("#add_"+ this.DATA_PERIODOSERIES[i].anio).show();
      $("#contenedor"+ this.DATA_PERIODOSERIES[i].anio).hide();
    }
    //alert("ShowPeriodo --> " + this.flag_dropPerriodo + " --> " + JSON.stringify(this.data_periodo));
    $("#demoPeriodo").show();
    //alert("ShowPeriodo --> " + this.flag_dropPerriodo + " --> " + JSON.stringify(this.data_periodo));
    //$("#demoDropindicadores").hide();
    //$("#demoInstituciones").hide();
    if(this.flag_dropPerriodo){
      $("#demoPeriodo").hide();
      this.flag_dropPerriodo = false;
    }else{
      this.flag_dropPerriodo = true;
      $("#demoPeriodo").show();
    }
  }
  DropEjercicio(){
    if(this.flag_dropEjercicio){
      $("#demoEjercicio").hide();
      this.flag_dropEjercicio = false;
    }else{
      $("#demoEjercicio").show();
      this.flag_dropEjercicio = true;
    }
  }
  myDropFuncindicadores(){
    //$("#demoPeriodo").hide();
    //$("#demoInstituciones").hide();
    if(this.flag_dropindicadores){
      $("#demoDropindicadores").hide();
      this.flag_dropindicadores = false;
      this.demoDropindicadores = false;
    }else{
      this.flag_dropindicadores = true;
      $("#demoDropindicadores").show();
      this.demoDropindicadores = true;
      for(var i = 0; i < this.data_indicadorSeries.length;i++){
        $("#contenedor"+this.data_indicadorSeries[i].Id_Indicador).show();
        $("#remove_"+this.data_indicadorSeries[i].Id_Indicador).show();
        $("#add_"+this.data_indicadorSeries[i].Id_Indicador).hide();
        for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
          $("#subcontenedor"+this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador).hide();
          $("#remove2N_"+this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador).hide();
          $("#add2N_"+this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador).show();
        }
      }
    }
  }
  checked_sucursal: boolean = false;
  sucursal_seleccionado(e){
    if(e == 0){
      if(this.checked_sucursal){
        this.checked_sucursal = false;
      }else{
        this.checked_sucursal = true;
      }
      for(var i = 0; i < this.arreglo_sucursales.length; i++){
        if(this.checked_sucursal){
          this.arreglo_sucursales[i].dbCheck = true;
        }else{
          this.arreglo_sucursales[i].dbCheck = false;
        }
      }
    }else{
      for(var i = 0; i < this.arreglo_sucursales.length; i ++){
        if(e == this.arreglo_sucursales[i].dbId){
          if(this.arreglo_sucursales[i].dbCheck){
            this.arreglo_sucursales[i].dbCheck = false;
          }else{
            this.arreglo_sucursales[i].dbCheck = true;
          }
        }
      }
    }
  }
  porcentaje(item){
    return CommonVariables.porcentaje(this.data_tipoproducto_infomes,item).toFixed(2);
  }
  porcentajec(item_tabla,item){
    return CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item).toFixed(2);
  }
  border_verde(item){
    var verde = false;
    //tendra un promedio de 61 a 100
    /*var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    if(porce > 20 && porce <= 100){
      verde = true;
      this.contador_green = this.contador_green + 1;
    }*/
    if(item == 'V'){
      verde = true;
      //this.contador_green = this.contador_green + 1;
    }

    return verde;
  }
  border_rojo(item){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    /*var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    if(porce >= 0 && porce <= 8){
      rojo = true;
      this.contador_red = this.contador_red + 1;
      //this.contruir();
    }*/
    if(item == 'R'){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
    }
    return rojo;
  }
  border_rojo_estatico(){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    //var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    //if(porce >= 0 && porce <= 8){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
      //this.contruir();
    //}
    return rojo;
  }
  border_amarillo(item){
    var amarillo = false;
    //tendrá un promedio de 21 a 60
    /*var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    if(porce > 8 && porce <= 20){
      amarillo = true;
      this.contador_yellow = this.contador_yellow + 1;
      //this.contruir();
    }*/

    if(item == 'A-'){
      amarillo = true;
      //this.contador_yellow = this.contador_yellow + 1;
    }
    return amarillo;
  }
  border_azul(item){
    var azul = false;

    if(item == 'A+'){
      azul = true;
    }
    return azul;
  }
  tabla_informacion1: boolean = true;
  tabla_informacion2: boolean = false;
  border_verde2(value){
    var verde = false;
    //tendra un promedio de 61 a 100
    var porce = (value*100)/this.suma;
    if(porce > 20 && porce <= 100){
      verde = true;
      //this.contador_green = this.contador_green + 1;
    }
    return verde;
  }
  border_rojo2(value){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    var porce = (value*100)/this.suma;
    if(porce >= 0 && porce <= 8){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
    }
    return rojo;
  }
  border_amarillo2(value){
    var amarillo = false;
    //tendrá un promedio de 21 a 60
    var porce = (value*100)/this.suma;
    if(porce > 8 && porce <= 20){
      amarillo = true;
      this.contador_yellow = this.contador_yellow + 1;
    }
    return amarillo;
  }
  border_verdec(value){
  //border_verdec(item_tabla,item){
    var verde = false;
    //tendra un promedio de 61 a 100
    var porce = (value*100)/this.saldocaptaciontotal;
    //var porce = CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item);
    
    if(porce > 20 && porce <= 100){
      verde = true;
      //this.contador_green = this.contador_green + 1;
    }
    return verde;
  }
  
  border_rojoc(value){
  //border_rojoc(item_tabla,item){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    var porce = (value*100)/this.saldocaptaciontotal;
    //var porce = CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item);
    if(porce >= 0 && porce <= 8){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
    }
    return rojo;
  }
  border_amarilloc(value){
  //border_amarilloc(item_tabla,item){
    var amarillo = false;
    //tendrá un promedio de 21 a 60
    var porce = (value*100)/this.saldocaptaciontotal;
    //var porce = CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item);
    if(porce > 8 && porce <= 20){
      amarillo = true;
      //this.contador_yellow = this.contador_yellow + 1;
    }
    return amarillo;
  }

  // options
  gradient: boolean = false;
  animations: boolean = true;

  colorScheme_balance = {
    domain: ['#72D54A', '#FFCB03','#F74C58','#0075C9']
  };
  balance_results = [
    {
      "name": "Alto",
      "value": 8940000
    },
    {
      "name": "Medium",
      "value": 5000000
    },
    {
      "name": "Bajo",
      "value": 7200000
    }
  ];
  //balance_results: any [] = [];
  labelFormatting(c) {
    return `<h5>${(c.label)} </h5>`;
  }
  valueFormatting(c){
    return `${(c)}`;
  }
  public showXAxis = true;
  public showYAxis = true;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel: "Producto de Crédito";
  public showYAxisLabel = true;
  public yAxisLabel: "Colocación";
  public graphDataChart: any[];
  showGridLines:boolean = false;
  legendTitle: string = "Institución";
  public colorScheme = {
    domain: ['#FF8300']
  };
  contruir(data){
    var por = 0;
    var sumak = 0;
    this.contador_green = 0;
    this.contador_red = 0;
    this.contador_yellow = 0;
    for(var i = 0; i < this.data_tipoproducto_infomes.length; i++){
      for(var j = 0; j < data.length; j++){
        for(var k = 0; k < data[j].series.length;k++){
          if(this.data_tipoproducto_infomes[i].name == data[j].series[k].name){
            sumak = sumak + data[j].series[k].value;
          }
        }
        por = (sumak*100)/this.data_tipoproducto_infomes[i].value;
        sumak = 0;
        if(por > 0 && por <= 8){
          this.contador_red = this.contador_red + 1;
        }else{
          if(por > 8 && por <= 20){
            this.contador_yellow = this.contador_yellow + 1;
          }else{
            this.contador_green = this.contador_green + 1;
          }
        }
      }
      por = (this.data_tipoproducto_infomes[i].value*100)/this.suma;
      if(por > 0 && por <= 8){
        this.contador_red = this.contador_red + 1;
      }else{
        if(por > 8 && por <= 20){
          this.contador_yellow = this.contador_yellow + 1;
        }else{
          this.contador_green = this.contador_green + 1;
        }
      }
    }

    //this.balance_results = [];
    //this.balance_results.push({"name":"Alto","value":this.contador_green},{"name":"Medio","value":this.contador_yellow},{"name":"Bajo","value":this.contador_red});
  }
  grafica_lineal: boolean = false;
  visibility_legend_balance(){
    if(this.grafica_lineal){
      this.grafica_lineal = false;
    }else{
      this.grafica_lineal = true;
    }
  }

  quitar_filtros(){
    this.grafTarjeta = false;
    this.grafTabla = true;
    //this.institucionChange(this.id_at);
    this.getCurrentUser();
  }


  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType: string = 'line';
  public chartData: any[];
  public chartLabels: any[];
  public chartColors: any[];
  public chartOptions: any;
  chart;
  ctxL;
  crear_lineal(){

    this.chartData = [
      {data: [120, 130, 180, 70,15], label: '2017'}
    ];
    this.chartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May'];
    this.chartColors = [{
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderColor: 'rgba(0, 0, 0, 1)'
    }];
    this.chartType = 'line';
    this.chartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1
          }
        }]
      },
      annotation: {
         drawTime: 'beforeDatasetsDraw',
         annotations: [{
            type: 'box',
            id: 'a-box-1',
            yScaleID: 'y-axis-0',
            yMin: 0,
            yMax: 1,
            backgroundColor: '#4cf03b'
         }, {
            type: 'box',
            id: 'a-box-2',
            yScaleID: 'y-axis-0',
            yMin: 1,
            yMax: 2.7,
            backgroundColor: '#fefe32'
         }, {
            type: 'box',
            id: 'a-box-3',
            yScaleID: 'y-axis-0',
            yMin: 2.7,
            yMax: 5,
            backgroundColor: '#fe3232'
         }]
      }
    }

    /*this.chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      theme: "light2",
      title:{
        text: "Simple Line Chart"
      },
      axisY:{
        includeZero: false
      },
      data: [{        
        type: "line",
            indexLabelFontSize: 16,
        dataPoints: [
          { y: 450 },
          { y: 414 },
          //{ y: 520, indexLabel: "\u2191 highest",markerColor: "red", markerType: "triangle" },
          { y: 520 },
          { y: 460 },
          { y: 450 },
          { y: 500 },
          { y: 480 },
          { y: 480 },
          //{ y: 410 , indexLabel: "\u2193 lowest",markerColor: "DarkSlateGrey", markerType: "cross" },
          { y: 410 },
          { y: 500 },
          { y: 480 },
          { y: 510 }
        ]
      }]
    });
    this.chart.render();*/
  }

  
  
  /**/
  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Colocación' },
  ];
  public lineChartLabels: any[] = ['S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7'];
  public lineChartColors: Color[] = [
    { // grey
      //backgroundColor: 'rgba(148,159,177,0.2)',
      backgroundColor: 'transparent',
      borderColor: "#ff8300",
      //pointBackgroundColor: 'rgba(148,159,177,1)',
      //pointBorderColor: '#fff',
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  
  public lineChartLegend = true;
  public lineChartType = 'line';
  lineChartOptions: (ChartOptions & {  }) = {
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
          color: "black"
        },
        scaleLabel: {
          display: true,
          labelString: "Sucursales",
          fontColor: "gray"
        }
      }],
      yAxes: [{
        gridLines: {
          display: false,
          color: "black",
          borderDash: [2, 5],
        },
        scaleLabel: {
          display: true,
          labelString: "Colocación",
          fontColor: "gray"
        }
      }]
    }
  };
  actualizartreemap(arreglo){
    this.contador_green = 0;
    this.contador_red = 0; 
    this.contador_yellow = 0; 
    this.contador_azul = 0;
    var idval = this.data_evaluacionaux[ this.data_evaluacionaux.length - 1].Id_Evaluacion;
    for(var i = 0; i < arreglo.length; i++){
      if(arreglo[i].Id_Evaluacion == idval){
        if(arreglo[i].TextoSemaforo == 'V' && this.esAgrup(arreglo[i])){
          this.contador_green ++;
        }
        if(arreglo[i].TextoSemaforo == 'R' && this.esAgrup(arreglo[i])){
          this.contador_red ++;
        }
        if(arreglo[i].TextoSemaforo == "A-" && this.esAgrup(arreglo[i])){
          this.contador_yellow ++;
        }
        if(arreglo[i].TextoSemaforo == "A+" && this.esAgrup(arreglo[i])){
          this.contador_azul ++;
        }
      }
    }    
    this.balance_results = [];
    this.balance_results.push({"name":"V","value":this.contador_green},{"name":"A-","value":this.contador_yellow},{"name":"R","value":this.contador_red},{"name":"A+","value":this.contador_azul});
    


  }
  esAgrup(dato){
    if (dato.Id_Indicador != 'EMP' && dato.Id_Indicador != 'CF' && dato.Id_Indicador != 'CA' && dato.Id_Indicador != 'EF' && dato.Id_Indicador !='R')
      return true;
    return false;
  }
  buscar_sucursal(name){
    //alert("Vamos a ver " + name);
    var retorno = "";
    for(var i = 0; i < this.arreglo_sucursales.length;i ++){
      if(this.arreglo_sucursales[i].dbId == name){
        retorno = this.arreglo_sucursales[i].strName;
      }
    }
    return retorno;
  }
  mapBalance: boolean = true;
  chartverticalBalance: boolean = false;
  piechartBalance: boolean = false;
  gbarras(){
    this.mapBalance = false;
    this.chartverticalBalance = true;
    this.piechartBalance = false;
    this.flag_exportar2 = "chartverticalBalance";
    this.showDataLabelBalanceActive = false;
    this.showDataLabelBalanceInactive = true;
    this.showDataLabelBalance = false;
  }
  gmapacalor(){
    this.mapBalance = true;
    this.chartverticalBalance = false;
    this.piechartBalance = false;
    this.flag_exportar2 = "mapBalance";
    this.showDataLabelBalanceActive = false;
    this.showDataLabelBalanceInactive = false;
    this.showDataLabelBalance = false;
  }  
  schemeType = 'ordinal';
  visibilitylegendBalance: boolean = true;
  showDataLabelBalance: boolean = false;
  showDataLabelBalanceActive: boolean = false;
  showDataLabelBalanceInactive: boolean = false;
  visibility_semaforo(){
    if(this.showDataLabelBalance){
      this.showDataLabelBalance = false;
      this.showDataLabelBalanceActive = false;
      this.showDataLabelBalanceInactive = true;
    }else{
      this.showDataLabelBalance = true;
      this.showDataLabelBalanceActive = true;
      this.showDataLabelBalanceInactive = false;
    }
  }
  public doughnutChartLabels  = ['V', 'A', 'R'];
  public doughnutChartData  = [[350, 450, 100]];
  //public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartColors:any = [
    {
      backgroundColor: ['#72D54A','#FFCB03','#F74C58']
    },
    { 
      backgroundColor: '#FFCB03',
    },
    { 
      backgroundColor: '#F74C58',
    },
  ];
  public doughnutChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true,
    borderSkipped: 'bottom',
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
      fullWidth: true, 
    },
    tooltips: {
      callbacks: {
          label: function(tooltipItem, data) {
                var allData = data.datasets[tooltipItem.datasetIndex].data;
                var tooltipLabel = data.labels[tooltipItem.index];
                var tooltipData = allData[tooltipItem.index];
                var total = 0;
                for (var i in allData) {
                    total += allData[i];
                }
                var tooltipPercentage = Math.round((tooltipData / total) * 100);
                return tooltipLabel + ': ' + tooltipPercentage + '%';
          }
      }
    },
  };
  gpastel(){
    this.mapBalance = false;
    this.chartverticalBalance = false;
    this.piechartBalance = true;
    this.convertir_pastel();
    this.flag_exportar2 = "piechartBalance";
    this.showDataLabelBalanceActive = false;
    this.showDataLabelBalanceInactive = false;
    this.showDataLabelBalance = false;
  }
  convertir_pastel(){
    for(var i = 0; i < this.balance_results.length; i ++){
      this.doughnutChartData[0][i] = this.balance_results[i].value;
    }
  }
  flag_exportar: string = "linealsucursal";
  flag_exportar2: string = "mapBalance";
  existe(item,name){
    var retorno = true;
    return retorno;
  }
  glineal(){}
  gtabla(){}
  indicador_seleccionado(idindicador){
    for(var i = 0; i < this.data_indicadorSeries.length; i++){
      if(this.data_indicadorSeries[i].Id_Indicador == idindicador){
        if(this.data_indicadorSeries[i].check){
          this.data_indicadorSeries[i].check = false;
          for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
            this.data_indicadorSeries[i].SeriesNivel1[j].check = false;
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = false;
            }
          }
        }else{
          this.data_indicadorSeries[i].check = true;
          for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
            this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
            }
          }
        }
      }

    }
    this.ocultarmostrarrenglones();
  }
  Indicador_Nivel1(id_indicador){
    //contar los indicadores
    //$("#demoPeriodo").hide();
    if(this.grafTabla){
      var contador = 0;
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && !this.DATA_PERIODOSERIES[i].series[j].check){
            this.DATA_PERIODOSERIES[i].series[j].check = true;
          }else{
            if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && this.DATA_PERIODOSERIES[i].series[j].check){
              this.DATA_PERIODOSERIES[i].series[j].check = false;
            }
          }
          if(this.DATA_PERIODOSERIES[i].series[j].check){
            contador++;
          }
        }
      }
      if(contador > 6){
        alert("solo es posible seleccionar 6 períodos");
        for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
          for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
            if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador){
              this.DATA_PERIODOSERIES[i].series[j].check = false;
              //$("#check_"+this.DATA_PERIODOSERIES[i].anio+this.DATA_PERIODOSERIES[i].series[j].mes).prop('checked', false).removeAttr('checked');
              $("#check_"+this.DATA_PERIODOSERIES[i].anio+this.DATA_PERIODOSERIES[i].series[j].mes).prop('checked', false);
            }
          }
        }
      }else{
        this.cadena_idevaluacion = "";
        var contador = 0;
        this.cadena_evaluacion = [];
        this.subtitulobalancec1 = "";
        this.data_evaluacionaux = [];
        for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
          for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
            if(this.DATA_PERIODOSERIES[i].series[j].check){
              if(contador == 0){
                this.cadena_idevaluacion = this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion;
                this.cadena_evaluacion.push({"evaluacion":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4),"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion});
                this.subtitulobalancec1 = this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4);
                this.data_evaluacionaux.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion,"ejercicioperiodo":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4)});
              }else{
                this.cadena_idevaluacion = this.cadena_idevaluacion + "," + this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion;
                this.cadena_evaluacion.push({"evaluacion":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4),"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion});
                this.subtitulobalancec1 = this.subtitulobalancec1 + " | " + this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4);
                this.data_evaluacionaux.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion,"ejercicioperiodo":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4)});
              }
              contador ++;
            }
          }
        }
        this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = this.cadena_idevaluacion;
        this.getDetalle_Evaluacion_Indicador(this.detalleevaluacionindicadorEntity);
      }
    }else{
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          this.DATA_PERIODOSERIES[i].series[j].check = false;
          $("#check_"+this.DATA_PERIODOSERIES[i].anio+this.DATA_PERIODOSERIES[i].series[j].mes).prop('checked', false);
        }
      }
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && !this.DATA_PERIODOSERIES[i].series[j].check){
            this.DATA_PERIODOSERIES[i].series[j].check = true;
          }else{
            if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && this.DATA_PERIODOSERIES[i].series[j].check){
              this.DATA_PERIODOSERIES[i].series[j].check = false;
            }
          }
        }
      }
      this.cadena_idevaluacion = "";
      var contador = 0;
      this.cadena_evaluacion = [];
      this.subtitulobalancec1 = "";
      this.data_evaluacionaux = [];
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          if(this.DATA_PERIODOSERIES[i].series[j].check){
            this.cadena_idevaluacion = this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion;
            this.cadena_evaluacion.push({"evaluacion":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4),"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion});
            this.subtitulobalancec1 = this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4);
            this.data_evaluacionaux.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion,"ejercicioperiodo":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4)});
          }
        }
      }
      this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = this.cadena_idevaluacion;
      this.getDetalle_Evaluacion_Indicador(this.detalleevaluacionindicadorEntity);
    }
  }
  indicador_seleccionadoN2(idindicador){
    var contador_j = 0;
    for(var i = 0; i < this.data_indicadorSeries.length; i++){
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        if(this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador == idindicador){
          if(this.data_indicadorSeries[i].SeriesNivel1[j].check){
            this.data_indicadorSeries[i].SeriesNivel1[j].check = false;
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = false;
            }
          }else{
            this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
            if(!this.data_indicadorSeries[i].check){
              this.data_indicadorSeries[i].check = true;
            }
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
            }
          }
        }
      }
      contador_j = 0;
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        if(this.data_indicadorSeries[i].SeriesNivel1[j].check){
          contador_j ++;
        }
      }
      if(contador_j == 0 && this.data_indicadorSeries[i].SeriesNivel1.length > 0){
        this.data_indicadorSeries[i].check = false;
      }
    }
    this.ocultarmostrarrenglones();
  }
  ocultarmostrarrenglones(){
    for(var i = 0; i < this.data_indicadorSeries.length;i++){
      this.ocultarmostrarfila(this.data_indicadorSeries[i].check,this.data_indicadorSeries[i].Id_Indicador);
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        this.ocultarmostrarfila(this.data_indicadorSeries[i].SeriesNivel1[j].check,this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador);
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          this.ocultarmostrarfila(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check,this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].Id_Indicador);
        }
      }
    }
  }

  indicador_seleccionadoep(idindicador){
    for(var i = 0; i < this.data_EjercicioPeriodo.length; i++){
      if(this.data_EjercicioPeriodo[i].name == idindicador){
        if(this.data_EjercicioPeriodo[i].check){
          this.data_EjercicioPeriodo[i].check = false;
          for(var j = 0; j < this.data_EjercicioPeriodo[i].series.length;j++){
            this.data_EjercicioPeriodo[i].series[j].check = false;
          }
        }else{
          this.data_EjercicioPeriodo[i].check = true;
          for(var j = 0; j < this.data_EjercicioPeriodo[i].series.length;j++){
            this.data_EjercicioPeriodo[i].series[j].check = true;
          }
        }
      }else{
        this.data_EjercicioPeriodo[i].check = false;
        for(var j = 0; j < this.data_EjercicioPeriodo[i].series.length;j++){
          this.data_EjercicioPeriodo[i].series[j].check = false;
        }
      }

    }
    this.ocultarmostrarrenglones();
  }

  indicador_seleccionadoN2ep(anio,idindicador){
    var contador_j = 0;
    for(var i = 0; i < this.data_EjercicioPeriodo.length; i++){
      if(anio == this.data_EjercicioPeriodo[i].name){
        this.data_EjercicioPeriodo[i].check = true;
        for(var j = 0; j < this.data_EjercicioPeriodo[i].series.length;j++){
          if(this.data_EjercicioPeriodo[i].series[j].name == idindicador){
            if(this.data_EjercicioPeriodo[i].series[j].check){
              this.data_EjercicioPeriodo[i].series[j].check = false;
            }else{
              this.data_EjercicioPeriodo[i].series[j].check = true;
            }
          }
        }
      }else{
          this.data_EjercicioPeriodo[i].check = false;
          for(var j = 0; j < this.data_EjercicioPeriodo[i].series.length;j++){
            this.data_EjercicioPeriodo[i].series[j].check = false;
          }
      }
      contador_j = 0;
      for(var j = 0; j < this.data_EjercicioPeriodo[i].series.length;j++){
        if(this.data_EjercicioPeriodo[i].series[j].check){
          contador_j ++;
        }
      }
      if(contador_j == 0 && this.data_EjercicioPeriodo[i].series.length > 0){
        this.data_EjercicioPeriodo[i].check = false;
      }
    }
    //this.ocultarmostrarrenglones();
  }
  ocultarmostrarfila(flag,indicador){
    for(var i = 0; i < this.data_ordenado.length; i++){
      if(this.data_ordenado[i].Id_Indicador == indicador){
        this.data_ordenado[i].Check = flag;
      }
    }

  }
  mostrar_primernivel(id_indicador){
    $("#contenedor"+id_indicador).show();
    $("#add_"+id_indicador).hide();
    $("#remove_"+id_indicador).show();
  }
  ocultar_primernivel(id_indicador){
    $("#contenedor"+id_indicador).hide();
    $("#add_"+id_indicador).show();
    $("#remove_"+id_indicador).hide();
  }
  mostrar_primernivelanio(anio){
    $("#contenedor"+anio).show();
    $("#add_"+anio).hide();
    $("#remove_"+anio).show();
  }
  ocultar_primernivelanio(anio){
    $("#contenedor"+anio).hide();
    $("#add_"+anio).show();
    $("#remove_"+anio).hide();
  }

  mostrar_segundonivel(id_indicador){
    $("#subcontenedor"+id_indicador).show();
    $("#add2N_"+id_indicador).hide();
    $("#remove2N_"+id_indicador).show();
  }
  ocultar_segundonivel(id_indicador){
    $("#subcontenedor"+id_indicador).hide();
    $("#add2N_"+id_indicador).show();
    $("#remove2N_"+id_indicador).hide();
  }
  mostrar_segundonivelep(id_indicador){
    $("#subcontenedorep_"+id_indicador).show();
    $("#addep2N_"+id_indicador).hide();
    $("#removeep2N_"+id_indicador).show();
  }
  ocultar_segundonivelep(id_indicador){
    $("#subcontenedorep_"+id_indicador).hide();
    $("#addep2N_"+id_indicador).show();
    $("#removeep2N_"+id_indicador).hide();
  }
  indicador_seleccionadoN3(id_indicador){
    var contador_k = 0,contador_j = 0;
    for(var i = 0; i < this.data_indicadorSeries.length;i++){
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        contador_k = 0;
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          if(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check){
            contador_k ++;
          }
        }
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          if(id_indicador == this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].Id_Indicador){
            if(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = false;
            }else{
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
              if(contador_k == 0){
                //no habia ninguno seleccionado entonces se habilita el nivel anterior
                this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
                if(!this.data_indicadorSeries[i].check){
                  this.data_indicadorSeries[i].check = true;
                }
              }
            }
          }
        }
        contador_k = 0;
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          if(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check){
            contador_k ++;
          }
        }
        if(contador_k == 0){
          //No hay ninguno seleccionado entonces sehabilitamos el primero 
          this.data_indicadorSeries[i].SeriesNivel1[j].check = false;
        }
      }
      contador_j = 0
      for( var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        if(this.data_indicadorSeries[i].SeriesNivel1[j].check){
          contador_j ++;
        }
      }
      if(contador_j == 0){
        this.data_indicadorSeries[i].check = false;
      }
    }
    this.ocultarmostrarrenglones();
  }
  data_excel: any [] = [];
  encabezado_excel: any [] = [];
  export_csv(){
    var aux = [];
    this.data_excel = [];
    if(this.opction1){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Nivel == 1){
          aux.push(this.data_ordenado[i].Descripcion);
        }
        if(this.data_ordenado[i].Nivel == 2){
          aux.push("    " + this.data_ordenado[i].Descripcion);
        }
        if(this.data_ordenado[i].Nivel == 3){
          aux.push("        " + this.data_ordenado[i].Descripcion);
        }
        aux.push(this.data_ordenado[i].Id_Indicador);
        aux.push(this.FormulaGreen(this.data_ordenado[i].Id_Indicador));
        aux.push(this.FormulaBlue(this.data_ordenado[i].Id_Indicador));
        aux.push(this.FormulaYellow(this.data_ordenado[i].Id_Indicador));
        aux.push(this.FormulaRoja(this.data_ordenado[i].Id_Indicador));
        for(var j = 0; j < this.data_ordenado[i].Series.length;j++){
          if(this.data_ordenado[i].Series[j].TextoSemaforo == "ERROR"){
            aux.push(this.data_ordenado[i].Series[j].Valor + " "+ "-");
          }else{
            aux.push(this.data_ordenado[i].Series[j].Valor + " "+ this.data_ordenado[i].Series[j].TextoSemaforo);
          }
        }
        this.data_excel.push(aux)
        aux = [];
      }
      this.encabezado_excel = ["Descripción","Código","V","A+","A-","R"];
      for(var i = 0; i < this.cadena_evaluacion.length; i++){
        this.encabezado_excel.push(this.cadena_evaluacion[i].evaluacion);
      }
      this.excelService.generateExcelIndicadores(this.encabezado_excel,this.data_excel,this.cadena_evaluacion.length,this.instituciondesc);
    }else{
      for(var i = 0; i < this.data_ordenadoFormulas.length;i++){
        if(this.data_ordenadoFormulas[i].Nivel == 1){
          aux.push(this.data_ordenadoFormulas[i].Descripcion);
        }
        if(this.data_ordenadoFormulas[i].Nivel == 2){
          aux.push("    " + this.data_ordenadoFormulas[i].Descripcion);
        }
        if(this.data_ordenadoFormulas[i].Nivel == 3){
          aux.push("        " + this.data_ordenadoFormulas[i].Descripcion);
        }
        //aux.push(this.data_ordenadoFormulas[i].Id_Indicador);
        aux.push(this.data_ordenadoFormulas[i].Formula_Mate);
        for(var j = 0; j < this.data_ordenadoFormulas[i].Series.length;j++){
          if(this.data_ordenadoFormulas[i].Series[j].TextoSemaforo == "ERROR"){
            aux.push(this.data_ordenadoFormulas[i].Series[j].Valor + " "+ "-");
          }else{
            aux.push(this.data_ordenadoFormulas[i].Series[j].Valor + " "+ this.data_ordenadoFormulas[i].Series[j].TextoSemaforo);
          }
        }
        this.data_excel.push(aux)
        aux = [];
      }
      this.encabezado_excel = ["Descripción","Fórmula"];
      for(var i = 0; i < this.cadena_evaluacion.length; i++){
        this.encabezado_excel.push(this.cadena_evaluacion[i].evaluacion);
      }
      this.excelService.generateExcelIndicadoresFormula(this.encabezado_excel,this.data_excel,this.cadena_evaluacion.length);
    }
  }
  export_png(){
    var grafica: string = "";
    var name: string = "";
    grafica = 'Tarjetas';
    name = 'Catalogo_Indicadores';
    PDF.exportimagenes(grafica,name);
  }
  getCurrentInstitucionPatmir(){
    let institucion = new InstitucionEntity;
    institucion.Id_Institucion = this.id_at;
    /*this.InstitucionService.getByAddUrl("",institucion).subscribe(instituciones =>{
      this.instituciondesc = instituciones[0].Descripcion;
    });*/
  }
  export_csvTodos(){
    this.getCurrentInstitucionPatmir();
    this.getEvaluacionTodas(this.evaluacionEntity);
  }
  opction1: boolean = true;
  opction2: boolean = false;
  data_ordenadoFormulas: any [] = [];

  cambiaroption(){
    if(this.opction1){
      this.opction1 = false;
      this.opction2 = true;
      this.tabla_informacion1 = false;
      this.tabla_informacion2 = true;
      this.ocultar_primerNivel();
      //IndicadoresFunctions.BuscarCuentaIdInFormula("P32/A1*100",this.data_cuenta); //sucessfull
      //IndicadoresFunctions.BuscarCuentaIdInFormula("ABS(A132)/V10*100",this.data_cuenta,this.data_variable); //sucessfull
      //IndicadoresFunctions.BuscarCuentaIdInFormula("P3/(A13*0.08)*100",this.data_cuenta,this.data_variable); //sucessfull
      //IndicadoresFunctions.BuscarCuentaIdInFormula("V50/A131*100",this.data_cuenta,this.data_variable); //sucessfull
      //IndicadoresFunctions.BuscarCuentaIdInFormula("P2/VT10",this.data_cuenta,this.data_variable,this.data_vartemp); //sucessfull
      //IndicadoresFunctions.BuscarCuentaIdInFormula("(A1-IIF(A1312+A132<0,0,A1312+A132)-A15)/(P21+P3)*100",this.data_cuenta,this.data_variable,this.data_vartemp); //sucessfull
      //IndicadoresFunctions.BuscarCuentaIdInFormula("P323/FCA1*100",this.data_cuenta,this.data_variable,this.data_vartemp); //sucessfull
    }else{
      this.opction1 = true;
      this.opction2 = false;
      this.tabla_informacion2 = false;
      this.tabla_informacion1 = true;
    }
  }
  public grafTabla = true; //------------------------------------------------------------------> Cambiar para que tarjetas sean lo segundo
  public grafTarjeta = false;
  public indice1 = 450;
  public mesesAux = [];
  public colorTit(){
    if(this.grafTabla)
      return 'w3-row w3-white w3-header-cuadrante';
    return 'w3-row w3-grey-fon w3-header-cuadrante';
  }
  cambiargrafica(op){
    switch(op){
      case 'tabla':
        /*for(var i = this.mesesAux.length-2; i>=0; i--){
          this.Indicador_Nivel1(this.mesesAux[i].Id_Evaluacion);
        }*/
        this.grafTarjeta = false;
        this.grafTabla = true;
      break;
      case 'tarjeta':
        this.mesesAux = this.data_evaluacionaux; 
        for(var i = this.data_evaluacionaux.length-2; i>=0; i--){
          this.Indicador_Nivel1(this.data_evaluacionaux[i].Id_Evaluacion);
        }
        if(this.data_evaluacionaux.length == 1){
          this.grafTabla = false;
          this.grafTarjeta = true;
        }else{
          alert("Es necesario seleccionar solo un mes");
        }
      break;
    }
  }
  colorTarjeta(tarjeta){
    switch(tarjeta.TextoSemaforo){
      case 'V': return 'w3-green';
      case 'A+': return 'w3-blue';
      case 'R': return 'w3-red';
      case 'A-': return 'w3-yellow';
    }
  }

  colorTextoTarjeta(tarjeta){
    switch(tarjeta.TextoSemaforo){
      case 'V': return 'color: #4CAF50';
      case 'A+': return 'color: #2196F3';
      case 'R': return 'color: #f44336';
      case 'A-': return 'color: #ffeb3b';
    }
  }

  descripcionformula(formula){
    var descformula = "";
    for(var i = 0; i< this.data_cuenta.length; i++){
      if(formula.indexOf(this.data_cuenta[i].Id_Cuenta) != -1){
        //descformula = this.data_cuenta[i].Descripcion.toLowerCase();
        formula.replace(this.data_cuenta[i].Id_Cuenta,this.data_cuenta[i].Descripcion);
      }
    }
    descformula = formula;
    return descformula;
  }
  data_EjercicioPeriodo: any [] = [
    {
      "name":2017,
      "check":false,
      "series": [{
                  "name":"mar",
                  "check":false
                },
                {
                  "name":"jun",
                  "check":false
                },
                {
                  "name":"sep",
                  "check":false
                },
                {
                  "name":"dic",
                  "check":false
                },
              ]
    },
    {
      "name":2018,
      "check":false,
      "series": [{
                  "name":"mar",
                  "check":false
                },
                {
                  "name":"jun",
                  "check":false
                },
                {
                  "name":"sep",
                  "check":false
                },
                {
                  "name":"dic",
                  "check":false
                },
              ]
    },
    {
      "name":2019,
      "check":true,
      "series": [{
                  "name":"mar",
                  "check":true
                },
                {
                  "name":"jun",
                  "check":true
                },
                {
                  "name":"sep",
                  "check":true
                },
                {
                  "name":"dic",
                  "check":true
                },
              ]
    },
  ];
  InstitucionSelect(){
    var cad = "";
    if(this.institucionid != "0000"){
      for(var i = 0; i < this.data_instituciones.length;i++){
        if(this.data_instituciones[i].id == this.id_at){
          cad = this.data_instituciones[i].name;
          this.data_instituciones[i].checked = true;
        }else{
          this.data_instituciones[i].checked = false;
        }
      }
    }else{
      for(var i = 0; i < this.data_instituciones.length;i++){
        if(this.data_instituciones[i].checked){
          cad = this.data_instituciones[i].name;
        }
      }
    }
    if(cad == ""){
      for(var i = 0; i < this.data_institucion.length;i++){
        if(this.data_institucion[i].Id_Institucion == this.id_at){
          cad = this.data_institucion[i].Descripcion;
        }
      }
    }
    if(this.usuarioid == 'DEMO2' || this.usuarioid == "DEMO"){
      //cad = "I. DEMO";
      cad = "SAN RAFAEL";
    }
    
    return cad;
  }
  ContarEjercicioPeriodo(){
    if(this.data_evaluacionaux.length < 7){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny";
    }    
  }
  SubtituloUltimoPeriodo(){
    if(this.data_evaluacionaux.length == 0){
      return "";
    }else{
      return this.data_evaluacionaux[this.data_evaluacionaux.length - 1].ejercicioperiodo;
    }
  }
  ClassAdministrador(){
    if(this.institucionid == '0000'){
      return "w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu ";
    }else{
      return "w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-disabled";
    }
  }
  IndicadoresAdministrador(){
    if(this.institucionid == '0000'){
      return "w3-dropdown-content w3-dropindicadores2 w3-row w3-light-gray w3-scroll";
    }else{
      return "w3-dropdown-content w3-dropindicadores w3-row w3-light-gray w3-scroll";
    }
  }
  administrador(){
    if(this.institucionid == '0000'){
      return true;
    }else{
      return false;
    }
  }
  EspacioEntidad(){
    if(this.institucionid == '0000'){
      return "w3-quarter ";
    }else{
      return "w3-col s2 ";
    }
  }
  FontIndicadores(idcuenta){
    if(idcuenta.length == 3 || idcuenta.length == 0){
      return "w3-elemento-titulo-tableBalance";
    }else{
      return "w3-elemento-tableBalance";
    }
  }
  demoInstituciones: boolean = false;
  clearDrop(){

  }

  /* Formulas Indicadores*/
  red: number = 0;
  yellow: number = 0;
  green: number = 0;
  blue: number = 0;
  FormulaRoja(id_indicador){
    var cadena = "";
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Indicador == id_indicador){
        this.PositionSemaforo(this.data_ordenado[i]);
        cadena = SemaforoRojo.BanderaSemaforoRojo(this.data_ordenado[i],this.red,this.evaluacionEntity.Id_Plantilla);
      }
    }
    return cadena;
  }
  FormulaYellow(id_indicador){
    var cadena = "";
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Indicador == id_indicador){
        this.PositionSemaforo(this.data_ordenado[i]);
        cadena = SemaforoYellow.BanderaSemaforoYellow(this.data_ordenado[i],this.yellow,this.evaluacionEntity.Id_Plantilla );
      }
    }
    return cadena;
  }
  FormulaGreen(id_indicador){
    var cadena = "";
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Indicador == id_indicador){
        this.PositionSemaforo(this.data_ordenado[i]);
        cadena = SemaforoGreen.BanderaSemaforoGreen(this.data_ordenado[i],this.green,this.evaluacionEntity.Id_Plantilla );
      }
    }
    return cadena;
  }
  FormulaBlue(id_indicador){
    var cadena = "";
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Indicador == id_indicador){
        this.PositionSemaforo(this.data_ordenado[i]);
        cadena = SemaforoBlue.BanderaSemaforoBlue(this.data_ordenado[i],this.blue,this.evaluacionEntity.Id_Plantilla);
      }
    }
    return cadena;
  }
  PositionSemaforo(arreglo){
    var arregloUsar = [];
    arregloUsar.push(arreglo.R0_Usar);
    arregloUsar.push(arreglo.R1_Usar);
    arregloUsar.push(arreglo.R2_Usar);
    arregloUsar.push(arreglo.R3_Usar);
    arregloUsar.push(arreglo.R4_Usar);
    arregloUsar.push(arreglo.R5_Usar);
    var contador = 0;
    for(var i = 0; i < arregloUsar.length;i++){
      if(arregloUsar[i] == 1){
        contador ++;
        if(contador == 1){
          this.red = i;
        }
        if(contador == 2){
          this.yellow = i;
        }
        if(contador == 4){
          this.green = i;
        }
        if(contador == 3){
          this.blue = i;
        }
      }
    }
  }

  /* Formulas Indicadores*/
}