import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service/data.service';

/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { FuncionesAfiliacion, Tabla_EdoResultado } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { Datosdummy} from '../funciones_f_pruebas';
import { CommonFunctions } from '../../../util/app-util';
/* Importación de Funciones */

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

// nuevos services
import {RazonesService} from './../../../services/financiero/razones.service';
import { CuentaEntity, Detalle_Evaluacion_CuentaEntity, Detalle_Evaluacion_IndicadorEntity, EvaluacionEntity, IndicadorEntity,  } from './../../../entities/base/AlertaTemprana';
import { CuentaQEntity } from './../../../entities/base/QubosEntities';
import { InfoAnioMesEntitty} from './../../../entities/base/DashboardEntities';
import { EvaluacionService } from './../../../services/alertatemprana/evaluacion.service';
import { DetalleEvaluacionCuentaService } from './../../../services/alertatemprana/detalle-evaluacion-cuenta.service';
import { DetalleEvaluacionIndicadorService } from './../../../services/alertatemprana/detalle-evaluacion-indicador.service';
import { CuentaService } from './../../../services/alertatemprana/cuenta.service';
import { FuncionesMes } from './..//funcionesAfiliacion';
import { ExcelService } from './../../../services/exportar/excel.service';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import * as $ from 'jquery';
import {CommonVariables} from './../funciones';
import { ConstantPool } from '@angular/compiler';

@Component({
  selector: 'app-estado-de-resultados',
  templateUrl: './estado-de-resultados.component.html',
  styleUrls: ['./estado-de-resultados.component.css']
})
export class EstadoDeResultadosComponent implements OnInit {

  // Filtros perspectivas
  public filtrosPerspectivasActivos: Array<any>;
  public filtrosPerspectivasAnidados: Array<any>;
  public flag_perspectivas = false;
  public sucursal_id: string;
  public valor_seleccionado;

  // Arreglos anio y mes
  public contador_anios = 0;
  data_anios: any [] = [{name: '2019', value: '2019', check: true},{name: '2020', value: '2020', check: true},{name: '2021', value: '2021', check: true}];
  data_meses: any [] = [{name: 'ene', value: 'ene', check: false},{name: 'feb', value: 'feb', check: false},{name: 'mar', value: 'mar', check: false},{name: 'abr', value: 'abr', check: false},{name: 'may', value: 'ene', check: false},{name: 'jun', value: 'jun', check: false},{name: 'jul', value: 'jul', check: false},{name: 'ago', value: 'ago', check: true}];
  data_anios_selec: any [] = [];
  data_meses_selec: any [] = [];
  data_sucursales: any [] = [{name: 'suc0', value: 'suc0', check: false},{name: 'suc1', value: 'suc1', check: false},{name: 'suc2', value: 'suc2', check: false},{name: 'suc3', value: 'suc3', check: false},{name: 'suc4', value: 'suc4', check: false},{name: 'suc5', value: 'suc5', check: false},{name: 'suc6', value: 'suc6', check: false},{name: 'suc7', value: 'suc7', check: false}];
  data_sucursales_select: any [] = [];
  data_edoresultado: any [] = [];
  source_edoresultado: any;
  column_edoresultado: String[] = ["may20","jun20","jul20","ago20","sep20","oct20"];

  ent_consolidado = true;
  public subtituloGrafica1 = 'Todos';
  public datosBarra: Array<any>;
  public datosLineal: Array<any>;

  id_at:number = 20;
  detalleevaluacionindicadorEntity = new Detalle_Evaluacion_IndicadorEntity;
  detalleevaluacioncuentaEntity = new Detalle_Evaluacion_CuentaEntity;
  evaluacionEntity = new EvaluacionEntity;
  indicadorEntity = new IndicadorEntity;
  cuentaEntity = new CuentaEntity;
  cuentaqEntity = new CuentaQEntity;
  infoaniomesEntitty = new InfoAnioMesEntitty;
  data_institucion: any [] = [];
  cadena_evaluacion: any [] = [];
  subtitulobalancec1: string = "";
  data_evaluacion: any [] = [];
  contador_ejercicioperiodo: number = 1; 
  data_evaluacionaux: any [] = [];
  DATA_PERIODOSERIES: any [] = [];
  data_ejercicio: any [] = []; //representación a año
  data_ejercicioaux: any [] = []; //representación a año
  data_periodo: any = []; //representación a mes
  data_periodoaux: any = []; //representación a mes
  contador_green: number = 0;
  contador_yellow: number = 0;
  contador_red: number = 0;
  gradient: boolean = false;
  animations: boolean = true;

  colorScheme_balance = {domain: ['#72D54A', '#FFCB03','#F74C58']};
  balance_results = [{"name": "Alto","value": 8940000},{"name": "Medium","value": 5000000},{"name": "Bajo","value": 7200000}];
  data_evaluacionEntidad: any [] = [];
  data_cuenta: any [] = [];
  data_ordenado: any [] = [];
  data_ordenadoTodas: any [] = [];
  data_indicadorSeries: any [] = [];

  singleActivo: any [] = [];
  multiActivo: any [] = [];
  public barActivo = true;
  public linActivo = false;
  public areActivo = false;
  public barActivo_modal = true;
  public linActivo_modal = false;
  public areActivo_modal = false;

  showLegendActivo: boolean = false;
  showLabelsActivo: boolean = false;
  isDoughnutActivo: boolean = true;
  legendPositionActivo: string = 'below';
  colorSchemeActivo = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  public showXAxisActivo = true;
  public showYAxisActivo = true;
  public showXAxisLabelActivo = '';
  public showYAxisLabelActivo = '';
  public xAxisLabelActivo = true;
  public yAxisLabelActivo = true;
  public showDataLabelActivo = false;
  

  singlePasivo: any [] = [];
  multiPasivo: any [] = [];
  public barPasivo = false;
  public linPasivo = true;
  public arePasivo = false;
  public barPasivo_modal = true;
  public linPasivo_modal = false;
  public arePasivo_modal = false;
  showLegendPasivo: boolean = false;
  showLabelsPasivo: boolean = false;
  isDoughnutPasivo: boolean = true;
  legendPositionPasivo: string = 'below';
  colorSchemePasivo = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  public showXAxisPasivo = true;
  public showYAxisPasivo = true;
  public showXAxisLabelPasivo = '';
  public showYAxisLabelPasivo = '';
  public xAxisLabelPasivo = true;
  public yAxisLabelPasivo = true;
  public showDataLabelPasivo = false;

  singleCapitalContable: any [] = [];
  multiCapitalContable: any [] = [];
  public barCapitalContable = false;
  public linCapitalContable = false;
  public areCapitalContable = true;
  public barCapitalContable_modal = true;
  public linCapitalContable_modal = false;
  public areCapitalContable_modal = false;
  showLegendCapitalContable: boolean = false;
  showLabelsCapitalContable: boolean = false;
  isDoughnutCapitalContable: boolean = true;
  legendPositionCapitalContable: string = 'below';
  colorSchemeCapitalContable = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  public showXAxisCapitalContable = true;
  public showYAxisCapitalContable = true;
  public showXAxisLabelCapitalContable = '';
  public showYAxisLabelCapitalContable = '';
  public xAxisLabelCapitalContable = true;
  public yAxisLabelCapitalContable = true;
  public showDataLabelCapitalContable = false;

  TipoInstitucion: string = "";
  subtituloActivo: string = "";

  Id_Plantilla: number = 5;
  institucionid: string;
  usuarioid: string;
  data_instituciones =  [{"id":5,"name":"Cosauqui","checked":false},{"id":8,"name":"ItaViko","checked":false},{"id":3,"name":"Ciudad Real","checked":false},{"id":11,"name":"Vicente Villagrán","checked":true},{"id":7,"name":"Horizontes Seguros","checked":false},{"id":4,"name":"Coroneo","checked":false},{"id":10,"name":"Tonaya","checked":false},{"id":9,"name":"La Esperanza","checked":false},{"id":2,"name":"Caja Occidente","checked":false}];
  flag_dropPerriodo: boolean = false;
  puntobalancec1: boolean = true;

  showhide_modalAmpliar: boolean = false;
  pieActivoModal: boolean = false;
  piePasivoModal: boolean = false;
  pieCapitalContableModal: boolean = false;
  barActivoModal: boolean = false;
  barPasivoModal: boolean = false;
  barCapitalContableModal: boolean = false;
  lineActivoModal: boolean = false;
  linePasivoModal: boolean = false;
  lineCapitalContableModal: boolean = false;
  
  data_excel: any [] = [];
  encabezado_excel: any [] = [];

  cadena_evaluacionTodas: any [] = [];
  option1: boolean = true;
  option2: boolean = false;
  titulomodal: string = "";
  puntomodal: boolean = true;
  puntoActivo: boolean = true;
  subtitulomodal: string = "";

  tabla_informacion1: boolean = true;
  cadena_ejercicioperiodo: string = "";
  cadena_idevaluacion: string = "";

  constructor(
    public dataService: DataService,
    private evaluacionService: EvaluacionService,
    private detalle_evaluacion_cuentaService: DetalleEvaluacionCuentaService,
    private detalle_evaluacion_indicadorService: DetalleEvaluacionIndicadorService,
    private cuentaService: CuentaService,
    private excelService: ExcelService,
    //nuevos servicios
    private razonesFinancieras: RazonesService,
  ) { 
    this.data_edoresultado = [
      {"codigo":"I5010","name":"Ingresos por interes","may20":"886.0","jun20":"812.1","jul20":"3125.2","ago20":"4356.2","sep20":"9812.2","oct20":"1255.3"},
      {"codigo":"G6010","name":"Gastos por interes","may20":"886.2","jun20":"812.3","jul20":"3125.0","ago20":"4356.4","sep20":"98123.33","oct20":"1255.3"},
      {"codigo":"","name":"Resultado Financiero","may20":"886.4","jun20":"812.12","jul20":"3125.3","ago20":"4356.44","sep20":"9812.12","oct20":"1255"},
    ];
    this.source_edoresultado = new MatTableDataSource<Tabla_EdoResultado>(this.data_edoresultado);
    this.getInicioServicios();
    this.setColorSaldoColocacion('gama1');
    console.log("usuario logueado:   ", dataService.currentUser)
  }

  ngOnInit(): void {
  } 
  getInicioServicios() {
    this.evaluacionEntity = new EvaluacionEntity;
    this.cuentaEntity = new CuentaEntity;
    this.evaluacionEntity.Id_Institucion = this.id_at;
    this.evaluacionEntity.Id_Plantilla = 5;
    this.cuentaEntity.Id_Plantilla = 5;
    this.getEvaluacion(this.evaluacionEntity);
    this.getCuenta(this.cuentaEntity);
  }
  getEvaluacion(evaluacionEntity: EvaluacionEntity):void{
    this.razonesFinancieras.getEvaluacion(evaluacionEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.obtenerultimaevaluacion(datos);
        }
      }
    });
  }
  getCuenta(cuentaEntity: CuentaEntity):void{
    this.razonesFinancieras.getCuenta(cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_cuenta = datos;
        }
      }
    });
  }
  setColorSaldoColocacion(name){
    this.colorSchemeActivo = FuncionesColores.setpaleta_azul(name);
    this.colorSchemePasivo = FuncionesColores.setpaleta_azul(name);
    this.colorSchemeCapitalContable = FuncionesColores.setpaleta_azul(name);
  }
  obtenerultimaevaluacion(arreglo){
    arreglo.sort(function (a, b) {
      if (a.Fecha_Contable.substring(0,7) > b.Fecha_Contable.substring(0,7)) {
        return 1;
      }
      if (a.Fecha_Contable.substring(0,7) < b.Fecha_Contable.substring(0,7)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = this.UltimasSeisEvaluaciones(arreglo);
    arreglo[arreglo.length - 1].check = true;
    for(var i = 0; i < arreglo.length;i++){
      this.data_evaluacion.push({
        "Id_Evaluacion":arreglo[i].Id_Evaluacion,
        "Id_Institucion":arreglo[i].Id_Institucion,
        "Id_Usuario":arreglo[i].Id_Usuario,
        "Fecha_Evaluacion":arreglo[i].Fecha_Evaluacion,
        "Fecha_Contable":arreglo[i].Fecha_Contable,
        "Descripcion":arreglo[i].Descripcion,
        "Observacion":arreglo[i].Observacion,
        "Id_Tipo_Evaluacion":arreglo[i].Id_Tipo_Evaluacion,
        "Id_Plantilla":arreglo[i].Id_Plantilla,
        "Id_Pais":arreglo[i].Id_Pais,
        "Id_Tipo_Institucion":arreglo[i].Id_Tipo_Institucion,
        "Estado":arreglo[i].Estado,
        "VistoBueno":arreglo[i].VistoBueno,
        "check":true,
        "ejercicioperiodo": FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4)
        });
    }


    this.cadena_evaluacion = this.CadenaEvaluacionInicio(this.data_evaluacion);
    this.contador_ejercicioperiodo = this.data_evaluacion.length;
    for(var i = 0; i < this.data_evaluacion.length;i++){
      if(i == 0){
        this.subtitulobalancec1 = this.data_evaluacion[i].ejercicioperiodo;
      }else{
        this.subtitulobalancec1 = this.subtitulobalancec1 + " | " + this.data_evaluacion[i].ejercicioperiodo;
      }
    }


    this.data_evaluacionaux = this.data_evaluacion;
    this.data_evaluacion = this.FuncionEjercicioPeriodo(arreglo);
    this.detalleevaluacioncuentaEntity.ArregloID_evaluacion = this.UltimasSeisEvaluaciones(arreglo);
    this.detalleevaluacioncuentaEntity.cuentaEntity = this.cuentaEntity;
    this.getDetalle_Evaluacion_Cuenta(this.detalleevaluacioncuentaEntity);
  }
  UltimasSeisEvaluaciones(arreglo){
    var cadena = "", cad = "";
    var inicio = 0;
    if(arreglo.length > 6){
      inicio = arreglo.length - 6;
    }
    this.cadena_evaluacion = [];
    var contador = 0;
    for(var i = inicio; i < arreglo.length;i++){
      if(contador == 0){
        cadena = arreglo[i].Id_Evaluacion;
        cad = FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4);
      }else{
        cadena = cadena + "," + arreglo[i].Id_Evaluacion;
        cad = cad + " | " + FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4);
      }
      contador ++;
      this.cadena_evaluacion.push({"evaluacion":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4),"Id_Evaluacion":arreglo[i].Id_Evaluacion,"Porcentaje":'%'});
    }
    
    this.subtitulobalancec1 = cad;
    return cadena;
  }
  CadenaEvaluacionInicio(arreglo){
    var cad = [];
    for(var i = 0; i < arreglo.length;i++){
      cad.push({"evaluacion":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4)});
    }
    return cad;
  }
  FuncionEjercicioPeriodo(arreglo){
    var AuxArreglo = [];
    for(var i = 0; i < arreglo.length;i++){
      AuxArreglo.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":true,"ejercicioperiodo":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4),"Fecha_Contable":arreglo[i].Fecha_Contable});

      if(!this.BuscarAnioPeriodoSeries(arreglo[i].Fecha_Contable.substring(0,4))){
        this.DATA_PERIODOSERIES.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":false,"anio":arreglo[i].Fecha_Contable.substring(0,4)});
      }
      if(!this.BuscarAnio(arreglo[i].Fecha_Contable.substring(0,4)))
        this.data_ejercicio.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":false,"anio":arreglo[i].Fecha_Contable.substring(0,4)});
    }

    this.CovertirAnioPeriodoSeries(arreglo);

    this.data_ejercicio[this.data_ejercicio.length - 1 ].check = true;
    this.data_ejercicioaux.push(this.data_ejercicio[this.data_ejercicio.length - 1]);
    this.ConstuirMesAnio(this.data_ejercicio[this.data_ejercicio.length - 1 ].anio,AuxArreglo);
    return AuxArreglo;
  }
  getDetalle_Evaluacion_Cuenta(detalle_evaluacion_cuentaEntity: Detalle_Evaluacion_CuentaEntity):void{
    this.razonesFinancieras.getDetalleEvaluacionCuenta(detalle_evaluacion_cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_evaluacionEntidad = datos;
          this.getCuentaOrdenado(this.cuentaEntity,datos);
        }
      }
    });
  }
  BuscarAnioPeriodoSeries(anio){
    var flag = false;
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      if(this.DATA_PERIODOSERIES[i].anio == anio){
        flag = true;
      }
    }
    return flag;
  }
  BuscarAnio(anio){
    var flag = false;
    for(var i = 0; i < this.data_ejercicio.length;i++){
      if(this.data_ejercicio[i].anio == anio){
        flag = true;
      }
    }
    return flag;
  }
  CovertirAnioPeriodoSeries(arreglo){
    var aux = [];
    var data = [];
    for(var i = 0;i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < arreglo.length;j++){
        if(this.DATA_PERIODOSERIES[i].anio == arreglo[j].Fecha_Contable.substring(0,4)){
          aux.push({"Id_Evaluacion":arreglo[j].Id_Evaluacion,
                    "check":false,
                    "mes": FuncionesMes.f_switchmes2(arreglo[j].Fecha_Contable.substring(5,7)),
                    "anio":arreglo[j].Fecha_Contable.substring(0,4)});
        }
      }
      data.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].Id_Evaluacion,"check":false,"anio":this.DATA_PERIODOSERIES[i].anio,"series":aux});
      aux = [];
    }
    this.DATA_PERIODOSERIES = data;
    this.data_evaluacionaux = [];
    var inicio = 0;
    if(arreglo.length > 6){
      inicio = arreglo.length - 6;
    }
    for(var i = inicio; i < arreglo.length;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES.length;j++){
        for(var k = 0; k < this.DATA_PERIODOSERIES[j].series.length;k++){
          if(arreglo[i].Id_Evaluacion == this.DATA_PERIODOSERIES[j].series[k].Id_Evaluacion){
            this.DATA_PERIODOSERIES[j].series[k].check = true;            
            this.data_evaluacionaux.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"ejercicioperiodo":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4)});
          }
        }
      }
    }
  } 
  ConstuirMesAnio(anio,arreglo){
    this.data_periodo = [];
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Fecha_Contable.substring(0,4) == anio){
        if(!this.BuscarMes(FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)))){
          this.data_periodo.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":true,"mes":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7))});
        }
      }
    }
    this.data_periodoaux = this.data_periodo;
  }
  getDetalle_Evaluacion_Indicador(detalle_evaluacion_indicadorEntity: Detalle_Evaluacion_IndicadorEntity):void{
    this.detalle_evaluacion_indicadorService.getByAddUrl('evaluacion',detalle_evaluacion_indicadorEntity).subscribe(dataArr => {
        this.data_evaluacionEntidad = dataArr;
        this.actualizartreemap(dataArr);
    });
  }
  getCuentaOrdenado(cuentaEntity: CuentaEntity,arreglo):void{
    this.razonesFinancieras.getCuenta(cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          var aux = [], i =0;
          for(const data of arreglo){
            for(const d of datos){
              if(data['Id_Cuenta'] == d['Id_Cuenta']){
                aux.push(data);
                aux[i].cuentaEntity = d;
              }
            }
            i++;
          }
          arreglo = aux;
          this.data_cuenta = datos;
          this.function_ordenarporniveles_CuentaEvaluacion(datos,arreglo);
        }
      }
    });
  }
  BuscarMes(mes){
    var flag = false;
    for(var i = 0; i < this.data_periodo.length;i++){
      if(this.data_periodo[i].mes == mes){
        flag = true;
      }
    }
    return flag;
  }
  actualizartreemap(arreglo){
    this.contador_green = 0;
    this.contador_red = 0; 
    this.contador_yellow = 0; 
    for(var i = 0; i < arreglo.length; i++){
      if(arreglo[i].TextoSemaforo == 'V'){
        this.contador_green ++;
      }
      if(arreglo[i].TextoSemaforo == 'R'){
        this.contador_red ++;
      }
      if(arreglo[i].TextoSemaforo == 'A'){
        this.contador_yellow ++;
      }
    }    
    this.balance_results = [];
    this.balance_results.push({"name":"Alto","value":this.contador_green},{"name":"Medio","value":this.contador_yellow},{"name":"Bajo","value":this.contador_red});
  }

  function_ordenarporniveles_CuentaEvaluacion(cuenta,evaluacion){
    this.data_ordenado = [];
    if(this.contador_ejercicioperiodo == 1){
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementos(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosG(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosI(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosO(evaluacion,cuenta[i]);
      }
      this.data_ordenado.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenado)
      });
      this.OcultarIni();
      var strg = '';
      var sumG = [], sumI = [];
      var series1 = [], series2 = [];
      for(const d of this.data_ordenado[0].Series){
        series1.push({
          Valor: 0, 
          TextoSemaforo: undefined
        });
      }
      for(const d of this.data_ordenado[0].Series){
        series2.push({
          Valor: 0, 
          TextoSemaforo: undefined
        });
      }
      sumG.push({
        Check: true,
        Descripcion: 'GASTOS',
        Id_Cuenta: 'G6',
        Id_Evaluacion: 298,
        Nivel: 1,
        dbCheck: false,
        Series: series1
      });
      sumI.push({
        Check: true,
        Descripcion: 'INGRESOS',
        Id_Cuenta: 'I5',
        Id_Evaluacion: 298,
        Nivel: 1,
        dbCheck: false,
        Series: series2
      });

      for(const data of this.data_ordenado){
        for(var j = 601; j<614; j++){
          strg = 'G'+j;
          if(data['Id_Cuenta'] == strg){
            for(var i=0; i < data['Series'].length; i++){
              sumG[0].Series[i].Valor +=  data['Series'][i].Valor;
            }
          }
        }
        for(var j = 501; j<514; j++){
          strg = 'I'+j;
          if(data['Id_Cuenta'] == strg){
            for(var i=0; i < data['Series'].length; i++){
              sumI[0].Series[i].Valor +=  data['Series'][i].Valor;
            }
          }
        }
      }    
      var aux = [];
      for(const data of this.data_ordenado){
        if(data['Id_Cuenta'] == 'G601'){
          aux.push(sumG[0]);
        }
        if(data['Id_Cuenta'] == 'I501'){
          aux.push(sumI[0]);
        }
        aux.push(data);
      }
      
      this.data_ordenado = aux;
      aux = [];
      aux = this.data_ordenado.pop();
      this.data_ordenado.sort(function (a, b) {
        if (a.Id_Cuenta.substring(1,2) > b.Id_Cuenta.substring(1,2)) {
          return 1;
        }
        if (a.Id_Cuenta.substring(1,2) < b.Id_Cuenta.substring(1,2)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      this.data_ordenado.push(aux);
    }else{
      var contador_elementos = 0;
      var aux = [];
      for(var i = 0; i < cuenta.length; i ++){
          this.buscarmaselementos(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosG(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosI(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosO(evaluacion,cuenta[i]);
      }
      this.data_ordenado.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenado)
      });

      this.OcultarIni();
      var strg = '';
      var sumG = [], sumI = [];
      var series1 = [], series2 = [];
      for(const d of this.data_ordenado[0].Series){
        series1.push({
          Valor: 0, 
          TextoSemaforo: undefined
        });
      }
      for(const d of this.data_ordenado[0].Series){
        series2.push({
          Valor: 0, 
          TextoSemaforo: undefined
        });
      }
      sumG.push({
        Check: true,
        Descripcion: 'GASTOS',
        Id_Cuenta: 'G6',
        Id_Evaluacion: 298,
        Nivel: 1,
        dbCheck: false,
        Series: series1
      });
      sumI.push({
        Check: true,
        Descripcion: 'INGRESOS',
        Id_Cuenta: 'I5',
        Id_Evaluacion: 298,
        Nivel: 1,
        dbCheck: false,
        Series: series2
      });

      for(const data of this.data_ordenado){
        for(var j = 601; j<614; j++){
          strg = 'G'+j;
          if(data['Id_Cuenta'] == strg){
            for(var i=0; i < data['Series'].length; i++){
              sumG[0].Series[i].Valor +=  data['Series'][i].Valor;
            }
          }
        }
        for(var j = 501; j<514; j++){
          strg = 'I'+j;
          if(data['Id_Cuenta'] == strg){
            for(var i=0; i < data['Series'].length; i++){
              sumI[0].Series[i].Valor +=  data['Series'][i].Valor;
            }
          }
        }
      }    
      var aux = [];
      for(const data of this.data_ordenado){
        if(data['Id_Cuenta'] == 'G601'){
          aux.push(sumG[0]);
        }
        if(data['Id_Cuenta'] == 'I501'){
          aux.push(sumI[0]);
        }
        aux.push(data);
      }
      
      this.data_ordenado = aux;
      aux = [];
      aux = this.data_ordenado.pop();
      this.data_ordenado.sort(function (a, b) {
        if (a.Id_Cuenta.substring(1,2) > b.Id_Cuenta.substring(1,2)) {
          return 1;
        }
        if (a.Id_Cuenta.substring(1,2) < b.Id_Cuenta.substring(1,2)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      this.data_ordenado.push(aux);
    }
    this.Activos(this.data_ordenado);
    this.Pasivos(this.data_ordenado);
    this.CapitalContable(this.data_ordenado);
  }
  public OcultarIni(){
    for(const data of this.data_ordenado){
      var strg = '';
      for(var j = 101; j<114; j++){
        strg = 'A'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 601; j<614; j++){
        strg = 'G'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 501; j<514; j++){
        strg = 'I'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 701; j<714; j++){
        strg = 'O'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 201; j<207; j++){
        strg = 'P'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 301; j<304; j++){
        strg = 'P'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
    }
  }
  buscarmaselementos(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [];
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }
    var flag = true;
    if(this.TipoInstitucion == 'SO'){
      if(aux.Id_Cuenta.substring(0,2) != 'G6' && aux.Id_Cuenta.substring(0,2) != 'I5' && aux.Id_Cuenta.substring(0,2) != 'O7' && aux.Id_Cuenta.substring(0,5) != 'A1010' ){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":false,
          "Series": aux_elemento
        });
      }
    }else{
        /*this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":false,
          "Series": aux_elemento
        });*/
        switch(aux.Id_Cuenta.length){
          case 2:
            this.data_ordenado.push({
              "Id_Evaluacion":aux.Id_Evaluacion,
              "Id_Cuenta":aux.Id_Cuenta,
              "Descripcion":aux.cuentaEntity.Descripcion,
              "Nivel": 1,
              "Check":flag,
              "dbCheck":false,
              "Series": aux_elemento
            });
          break;
          case 4:
            this.data_ordenado.push({
              "Id_Evaluacion":aux.Id_Evaluacion,
              "Id_Cuenta":aux.Id_Cuenta,
              "Descripcion":aux.cuentaEntity.Descripcion,
              "Nivel": 2,
              "Check":flag,
              "dbCheck":false,
              "Series": aux_elemento
            });
          break;
          case 6:
            this.data_ordenado.push({
              "Id_Evaluacion":aux.Id_Evaluacion,
              "Id_Cuenta":aux.Id_Cuenta,
              "Descripcion":aux.cuentaEntity.Descripcion,
              "Nivel": 3,
              "Check":flag,
              "dbCheck":false,
              "Series": aux_elemento
            });
          break;
          case 8:
            this.data_ordenado.push({
              "Id_Evaluacion":aux.Id_Evaluacion,
              "Id_Cuenta":aux.Id_Cuenta,
              "Descripcion":aux.cuentaEntity.Descripcion,
              "Nivel": 4,
              "Check":flag,
              "dbCheck":false,
              "Series": aux_elemento
            });
          break;
        }
    }
  }
  PasivomasCapital2(arreglo){
    var suma = [];
    var pasivo = 0, capital = 0;
    this.contador_ejercicioperiodo = arreglo[0].Series.length;
    for(var i = 0; i < (this.contador_ejercicioperiodo);i++){
      for(var j = 0; j < arreglo.length;j++){
        if(this.TipoInstitucion == 'SO'){
          if(arreglo[j].Id_Cuenta == 'P2'){
            pasivo = arreglo[j].Series[i].Valor;
          }
          if(arreglo[j].Id_Cuenta == 'P30'){
            capital = arreglo[j].Series[i].Valor;
          }
        }else{
          if(arreglo[j].Id_Cuenta == 'P2'){
            pasivo = arreglo[j].Series[i].Valor;
          }
          if(arreglo[j].Id_Cuenta == 'P3'){
            capital = arreglo[j].Series[i].Valor;
          }
        }
      }
      suma.push({"Valor":pasivo + capital});
    }    
    return suma;
  }
  buscarmaselementosG(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }

    var flag = true;
    if(aux.Id_Cuenta.length == 2){
      flag = true;
    }
    if(this.TipoInstitucion == 'SO'){
      if(aux.Id_Cuenta.substring(0,2) == 'G6'){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":false,
          "Series": aux_elemento
        });
      }
    }
  }
  buscarmaselementosI(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }


    var flag = true;
    if(aux.Id_Cuenta.length == 2){
      flag = true;
    }
    if(this.TipoInstitucion == 'SO'){
      if(aux.Id_Cuenta.substring(0,2) == 'I5'){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":true,
          "Series": aux_elemento
        });
      }
    }
  }
  buscarmaselementosO(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }


    var flag = true;
    if(aux.Id_Cuenta.length == 2){
      flag = true;
    }
  }
  Activos(arreglo){
    this.subtituloActivo = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    this.singleActivo = [];
    for(var i = 0; i < arreglo.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(arreglo[i].Id_Cuenta.substring(0,2) == "A1" && arreglo[i].Id_Cuenta.length < 17 && arreglo[i].Id_Cuenta != 'A1' ){
          this.singleActivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }else{
        /*if(arreglo[i].Id_Cuenta.substring(0,3) == "A10" && arreglo[i].Id_Cuenta.length < 7 && arreglo[i].Id_Cuenta != 'A10' ){
          this.singleActivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }*/
        if(arreglo[i].Id_Cuenta == 'A1'){
            for(var j = 0; j < arreglo[i].Series.length; j++){
              this.singleActivo.push({"name": this.data_evaluacionaux[j].ejercicioperiodo, "value":arreglo[i].Series[j].Valor});
            }
            this.multiActivo = [];
            this.multiActivo.push({name: 'Áctivo', series: this.singleActivo});
        }
      }
    }
  }
  Pasivos(arreglo){
    this.subtituloActivo = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    this.singlePasivo = [];
    for(var i = 0; i < arreglo.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(arreglo[i].Id_Cuenta.substring(0,2) == "P2" && arreglo[i].Id_Cuenta.length < 17 && arreglo[i].Id_Cuenta != 'P2'){
          this.singlePasivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }else{
        /*if(arreglo[i].Id_Cuenta.substring(0,3) == "P20" && arreglo[i].Id_Cuenta.length < 7 && arreglo[i].Id_Cuenta != 'P20'){
          this.singlePasivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }*/
        if(arreglo[i].Id_Cuenta == 'P2'){
          for(var j = 0; j < arreglo[i].Series.length; j++){
            this.singlePasivo.push({"name": this.data_evaluacionaux[j].ejercicioperiodo, "value":arreglo[i].Series[j].Valor});
          }
          this.multiPasivo = [];
          this.multiPasivo.push({name: 'Pasivo', series: this.singlePasivo});
        }
      }
    }
  }
  CapitalContable(arreglo){
    this.subtituloActivo = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    this.singleCapitalContable = [];
    for(var i = 0; i < arreglo.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(arreglo[i].Id_Cuenta.substring(0,2) == "P4" && arreglo[i].Id_Cuenta.length < 17 && arreglo[i].Id_Cuenta != 'P4'){
          this.singleCapitalContable.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }else{
        /*if(arreglo[i].Id_Cuenta.substring(0,3) == "P30" && arreglo[i].Id_Cuenta.length < 7 && arreglo[i].Id_Cuenta != 'P30'){
          this.singleCapitalContable.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }*/
        if(arreglo[i].Id_Cuenta == 'P3'){
          for(var j = 0; j < arreglo[i].Series.length; j++){
            this.singleCapitalContable.push({"name": this.data_evaluacionaux[j].ejercicioperiodo, "value":arreglo[i].Series[j].Valor});
          }
          this.multiCapitalContable = [];
          this.multiCapitalContable.push({name: 'Capital contable', series: this.singleCapitalContable});
        }
      }
    }
  }
  /** CAMBIO DE GRAFICAS */
  /** ACTIVO */
  public camGrafAct(op){
    switch(op){
      case 'lin':
        this.linActivo = true;
        this.areActivo = false;
        this.barActivo = false;
      break;
      case 'are':
        this.areActivo = true;
        this.barActivo = false;
        this.linActivo = false;
      break;
      case 'bar':
        this.barActivo = true;
        this.linActivo = false;
        this.areActivo = false;
      break;
    }
  }
  /** PASIVO */
  public camGrafPas(op){
    switch(op){
      case 'lin':
        this.linPasivo = true;
        this.arePasivo = false;
        this.barPasivo = false;
      break;
      case 'are':
        this.arePasivo = true;
        this.barPasivo = false;
        this.linPasivo = false;
      break;
      case 'bar':
        this.barPasivo = true;
        this.linPasivo = false;
        this.arePasivo = false;
      break;
    }
  }
  /** CAPITAL CONTABLE */
  public camGrafCapCont(op){
    switch(op){
      case 'lin':
        this.linCapitalContable = true;
        this.areCapitalContable = false;
        this.barCapitalContable = false;
      break;
      case 'are':
        this.areCapitalContable = true;
        this.barCapitalContable = false;
        this.linCapitalContable = false;
      break;
      case 'bar':
        this.barCapitalContable = true;
        this.linCapitalContable = false;
        this.areCapitalContable = false;
      break;
    }
  }
  /** AMPLIAR GRAFICAS */
  /** PASIVO */
  AmpliarPasivo(){
    this.modal();
    this.titulomodal = 'Pasivo';
    this.subtitulomodal = this.subtituloActivo;
    this.showhide_modalAmpliarCerrar();
    if(this.linPasivo)
      this.linPasivo_modal = true;
    if(this.arePasivo)
      this.arePasivo_modal = true;
    if(this.barPasivo)
      this.barPasivo_modal = true;
  }
  /** ACTIVO */
  AmpliarActivo(){
    this.modal();
    this.titulomodal = 'Activo';
    this.subtitulomodal = this.subtituloActivo;
    this.showhide_modalAmpliarCerrar();
    if(this.linActivo)
      this.linActivo_modal = true;
    if(this.areActivo)
      this.areActivo_modal = true;
    if(this.barActivo)
      this.barActivo_modal = true;
  }
  /** CAPITAL CONTABLE */
  AmpliarCapitalContable(){
    this.modal();
    this.titulomodal = 'Capital contable';
    this.subtitulomodal = this.subtituloActivo;
    this.showhide_modalAmpliarCerrar();
    if(this.linCapitalContable)
      this.linCapitalContable_modal = true;
    if(this.areCapitalContable)
      this.areCapitalContable_modal = true;
    if(this.barCapitalContable)
      this.barCapitalContable_modal = true;
  }
  /** CERRAR MODAL */
  modal(){
    this.linActivo_modal = false;
    this.areActivo_modal = false;
    this.barActivo_modal = false;

    this.linPasivo_modal = false;
    this.arePasivo_modal = false;
    this.barPasivo_modal = false;
    
    this.linCapitalContable_modal = false;
    this.areCapitalContable_modal = false;
    this.barCapitalContable_modal = false;
  }
  administrador(){
    if(this.institucionid == '0000'){
      return true;
    }else{
      return false;
    }
  }
  ClassAdministrador(){
    if(this.institucionid == '0000'){
      return "w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu ";
    }else{
      return "w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-disabled";
    }
  }
  InstitucionSelect(){
    var cad = "";
    if(this.institucionid != "0000"){
      for(var i = 0; i < this.data_instituciones.length;i++){
        if(this.data_instituciones[i].id == this.id_at){
          cad = this.data_instituciones[i].name;
          this.data_instituciones[i].checked = true;
        }else{
          this.data_instituciones[i].checked = false;
        }
      }
    }else{
      for(var i = 0; i < this.data_instituciones.length;i++){
        if(this.data_instituciones[i].checked){
          cad = this.data_instituciones[i].name;
        }
      }
    }
    
    if(cad == ""){
      for(var i = 0; i < this.data_institucion.length;i++){
        if(this.data_institucion[i].Id_Institucion == this.id_at){
          cad = this.data_institucion[i].Descripcion;
        }
      }
    }

    if(this.usuarioid == 'DEMO2' || this.usuarioid == "DEMO"){
      cad = "I. DEMO";
    }
    if (this.id_at == 20){
      cad = "SAN RAFAEL";
    }

    return cad;
  }
  DropPeriodo(){
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
        $("#remove_"+ this.DATA_PERIODOSERIES[i].series[j].anio).hide();
        $("#add_"+ this.DATA_PERIODOSERIES[i].series[j].anio).show();
        $("#contenedor"+ this.DATA_PERIODOSERIES[i].series[j].anio).hide();
      }
    }
    $("#demoPeriodo").show();
    $("#demoDropindicadores").hide();
    $("#demoInstituciones").hide();
    if(this.flag_dropPerriodo){
      $("#demoPeriodo").hide();
      this.flag_dropPerriodo = false;
    }else{
      this.flag_dropPerriodo = true;
      $("#demoPeriodo").show();
    }
  }
  ContarEjercicioPeriodo(){
    if(this.data_evaluacionaux.length < 7){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny";
    }    
  }
  /** Exportar */
  export_png(zon){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 'A':
        grafica = 'A';
        name = 'Catalogo_cuentas';
      break
      case 'P':
        grafica = 'P';
        name = 'Catalogo_cuentas';
      break
      case 'C':
        grafica = 'C';
        name = 'Catalogo_cuentas';
      break
    }
    PDF.exportimagenes(grafica,name);
  }
  export_csv(){
    var aux = [];
    this.data_excel = [];
    for(var i = 0; i < this.data_ordenado.length;i++){
      aux.push(this.data_ordenado[i].Id_Cuenta);

      if(this.data_ordenado[i].Id_Cuenta.length == 2 || this.data_ordenado[i].Id_Cuenta.length == 0){
        aux.push(this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 4){
        aux.push("    " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 6){
        aux.push("        " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 8){
        aux.push("            " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 10){
        aux.push("                " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 12){
        aux.push("                    " + this.data_ordenado[i].Descripcion);
      }

      for(var j = 0; j < this.data_ordenado[i].Series.length;j++){
        if(this.data_ordenado[i].Series[j].TextoSemaforo == "ERROR"){
          aux.push(this.data_ordenado[i].Series[j].Valor + " "+ "-");
        }else{
          if(this.data_ordenado[i].Series[j].Valor < 0){
            aux.push("$ ".concat(this.formatear(this.data_ordenado[i].Series[j].Valor)));
          }else{
            aux.push("$ ".concat(this.formatear(this.data_ordenado[i].Series[j].Valor)));
          }
        }
      }
      this.data_excel.push(aux)
      aux = [];
    }
    this.encabezado_excel = ["Código","Descripción"];
    for(var i = 0; i < this.cadena_evaluacion.length; i++){
      this.encabezado_excel.push(this.cadena_evaluacion[i].evaluacion);
    }
    var cadena = "";
    
    for(var i = 0; i < this.data_evaluacionaux.length;i++){
      if(i == 0){
        cadena = this.data_evaluacionaux[i].ejercicioperiodo;
      }else{
        cadena = cadena + " . " + this.data_evaluacionaux[i].ejercicioperiodo;
      }
    }
    this.excelService.generateExcelBalance(this.encabezado_excel,this.data_excel,this.cadena_evaluacion.length,cadena);
  }
  export_csvTodos(){
    this.getEvaluacionTodas(this.evaluacionEntity);
  }
  formatear(num){
    return CommonVariables.formatear(num.toFixed(0));
  }
  getEvaluacionTodas(evaluacionEntity: EvaluacionEntity):void{
    this.razonesFinancieras.getEvaluacion(evaluacionEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          let detalleevaluaciontodas= this.detalleevaluacioncuentaEntity;
          var cadena = "";
          this.cadena_evaluacionTodas = [];
          for(var i = 0; i < datos.length;i++){
            if(i == 0){
              cadena = "" + datos[i].Id_Evaluacion;
            }else{
              cadena = cadena + "," + datos[i].Id_Evaluacion;
            }
            this.cadena_evaluacionTodas.push({"evaluacion":FuncionesMes.f_switchmes2(datos[i].Fecha_Contable.substring(5,7)) + " " + datos[i].Fecha_Contable.substring(2,4),"Id_Evaluacion":datos[i].Id_Evaluacion});
          }
          detalleevaluaciontodas.ArregloID_evaluacion = cadena
          this.getDetalle_Evaluacion_CuentaTodas(detalleevaluaciontodas);
        }
      }
    });
  }
  getDetalle_Evaluacion_CuentaTodas(detalle_evaluacion_cuentaEntity: Detalle_Evaluacion_CuentaEntity):void{
    this.razonesFinancieras.getDetalleEvaluacionCuenta(detalle_evaluacion_cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.getCuentaOrdenadoTodas(this.cuentaEntity,datos);
        }
      }
    });
  }
  getCuentaOrdenadoTodas(cuentaEntity: CuentaEntity,arreglo):void{
    this.razonesFinancieras.getCuenta(cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_cuenta = datos;
          this.function_ordenarporniveles_CuentaEvaluacionTodas(datos,arreglo);
        }
      }
    });
  }
  function_ordenarporniveles_CuentaEvaluacionTodas(cuenta,evaluacion){
    this.data_ordenadoTodas = [];
    if(this.contador_ejercicioperiodo == 1){
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosTodas(evaluacion,cuenta[i]);
      }
      this.data_ordenadoTodas.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenadoTodas)
      });
    }else{
      var contador_elementos = 0;
      var aux = [];
      for(var i = 0; i < cuenta.length; i ++){
          this.buscarmaselementosTodas(evaluacion,cuenta[i]);
      }
      this.data_ordenadoTodas.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenadoTodas)
      });
    }


    /* Export CSV */
    var aux = [];
    this.data_excel = [];
    for(var i = 0; i < this.data_ordenadoTodas.length;i++){
      aux.push(this.data_ordenadoTodas[i].Id_Cuenta);

      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 3 || this.data_ordenadoTodas[i].Id_Cuenta.length == 0){
        aux.push(this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 5){
        aux.push("    " + this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 7){
        aux.push("        " + this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 9){
        aux.push("            " + this.data_ordenadoTodas[i].Descripcion);
      }
      
      for(var j = 0; j < this.data_ordenadoTodas[i].Series.length;j++){
        if(this.data_ordenadoTodas[i].Series[j].TextoSemaforo == "ERROR"){
          aux.push(this.data_ordenadoTodas[i].Series[j].Valor + " "+ "-");
        }else{
          if(this.data_ordenadoTodas[i].Series[j].Valor < 0){
            aux.push("$ ".concat(this.formatear(this.data_ordenadoTodas[i].Series[j].Valor)));
          }else{
            aux.push("$ ".concat(this.formatear(this.data_ordenadoTodas[i].Series[j].Valor)));
          }
        }
      }
      this.data_excel.push(aux)
      aux = [];
    }
    this.encabezado_excel = ["Código","Descripción"];
    for(var i = 0; i < this.cadena_evaluacionTodas.length; i++){
      this.encabezado_excel.push(this.cadena_evaluacionTodas[i].evaluacion);
    }
    var cadena = "";
    
    for(var i = 0; i < this.data_evaluacionaux.length;i++){
      if(i == 0){
        cadena = this.data_evaluacionaux[i].ejercicioperiodo;
      }else{
        cadena = cadena + " . " + this.data_evaluacionaux[i].ejercicioperiodo;
      }
    }
    this.excelService.generateExcelBalance(this.encabezado_excel,this.data_excel,this.cadena_evaluacionTodas.length,cadena);
  }
  buscarmaselementosTodas(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacionTodas.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacionTodas[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }
    var flag = true;
    if(aux.Id_Cuenta.length == 3){
      flag = true;
    }
    if(aux.Id_Cuenta.substring(0,3) != 'G60' && aux.Id_Cuenta.substring(0,3) != 'I50'){
      this.data_ordenadoTodas.push({
        "Id_Evaluacion":aux.Id_Evaluacion,
        "Id_Cuenta":aux.Id_Cuenta,
        "Descripcion":aux.cuentaEntity.Descripcion,
        "Nivel": 1,
        "Check":flag,
        "dbCheck":false,
        "Series": aux_elemento
      });
    }
  }
  showhide_modalAmpliarCerrar(){
    /*this.showhide_modalAmpliar = false;
    $("#showhide_modalAmpliar").hide();*/
    if(this.showhide_modalAmpliar){
      this.showhide_modalAmpliar = false;
      $("#showhide_modalAmpliar").hide();
    }else{
      this.showhide_modalAmpliar = true;
      $("#showhide_modalAmpliar").show();
    }
  }
  buscarnivelEncabezado(idcuenta){
    var flag = false;
    
    if(this.TipoInstitucion == 'SO'){
      if(idcuenta.length == 2 || idcuenta.length == 0 )
        flag = true;
    }else{
      if(idcuenta.length == 2 || idcuenta.length == 0 )
        flag = true;
    }

    return flag;
  }
  buscarnivelEncabezado2(idcuenta){
    var flag = false;
    var len = 0;
    if(this.TipoInstitucion == 'SO'){
      len = 2;
    }else{
      len = 4;
    }
    if(idcuenta.length > len)
      flag = true;
    return flag;
  }
  cuentaprincipal(idcuenta){
    var flag = false;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 2){
          flag = true;
        }
      }else{
        if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 2){
          flag = true;
        }
      }
    }
    var flag2 = this.CuentaPrincipalSubcuentas(idcuenta);
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 4 && flag2){
        flag = true;
      }
    }
    var flag3 = this.CuentaPrincipalSubcuentas2(idcuenta);
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 6 && flag3){
        flag = true;
      }
    }
    return flag;
  }
  CuentaPrincipalSubcuentas(idcuenta){
    var con = 0;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta.substring(0,4) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
        con ++;
      }
    }
    if(con > 0){
      return true;
    }else{
      return false;
    }
  }
  CuentaPrincipalSubcuentas2(idcuenta){
    var con = 0;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta.substring(0,6) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
        con ++;
      }
    }
    if(con > 0){
      return true;
    }else{
      return false;
    }
  }
  FontBalance(idcuenta,monto){
    var len = 0;
    if(this.TipoInstitucion == 'SO'){
      len = 2;
    }else{
      len = 2;
    }
      if(idcuenta.length == len || idcuenta.length == 0){
        return "w3-elemento-titulo-tableBalance w3-encabezado-balance";
      }else{
        if(monto < 0 ){
          return "w3-elemento-tableBalance";
        }else{
          return "w3-elemento-tableBalance";
        }
      }
  }
  buscarnivel0(idcuenta){
    var flag = false;
    if(this.TipoInstitucion == 'SO'){
      if(idcuenta.length == 2 || idcuenta.length == 0){
        flag = true;
      }
    }else{
      if(idcuenta.length == 2 || idcuenta.length == 0){
        flag = true;
      }
    }
    return flag;
  }
  buscarnivel20(idcuenta){
    var flag = false;
    if(idcuenta.length == 3)
      flag = true;
    if(idcuenta.trim() == "I5" || idcuenta.trim() == "G6")
      flag = false;

    return flag;
  }
  buscarnivel201(idcuenta){
    if(idcuenta == "I5" || idcuenta == "G6")
      return true;
    else
      return false;
  }
  buscarnivel1(idcuenta){
    var flag = false;
    if(idcuenta.length == 4)
      flag = true;
    return flag;
  }
  buscarnivel2(idcuenta){
    var flag = false;
    if(idcuenta.length == 6)
      flag = true;
    return flag;
  }
  buscarnivel3(idcuenta){
    var flag = false;
    if(idcuenta.length == 7)
      flag = true;
    return flag;
  }
  buscarnivel4(idcuenta){
    var flag = false;
    if(idcuenta.length == 8)
      flag = true;
    return flag;
  }
  buscarnivel5(idcuenta){
    var flag = false;
    if(idcuenta.length == -7)
      flag = true;
    return flag;
  }
  cadenaIdCuenta(idcuenta){
    var cad = "";
    var flag = false;
    var len = 0;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.TipoInstitucion == 'SO'){
        len = 2;
      }else{len = 2}
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && idcuenta.length == len){
        if(this.data_ordenado[i].dbCheck){
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && idcuenta.length == 4){
        if(this.data_ordenado[i].dbCheck){
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && idcuenta.length == 6){
        if(this.data_ordenado[i].dbCheck){
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    if(flag){
      cad = "expand_more";//"add";
    }else{
      cad = "expand_less";//"remove";
    }
    return cad;
  }
  mostrarocultarCuenta(idcuenta){
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta){
        if(this.data_ordenado[i].dbCheck){
          this.data_ordenado[i].dbCheck = false;
        }else{
          this.data_ordenado[i].dbCheck = true;
        }
      }
    }

    /*if(this.TipoInstitucion == 'SO'){
      if(idcuenta.length == 2){
        for(var i = 0; i < this.data_ordenado.length;i++){
          if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
            if(!flagcuenta){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = false;
            }else{
              this.data_ordenado[i].Check = false;
              this.data_ordenado[i].dbCheck = true;
            }
          }
          if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == 'P4' && this.data_ordenado[i].Id_Cuenta != idcuenta ){
            if(!flagcuenta){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = false;
            }else{
              this.data_ordenado[i].Check = false;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }else{
      if(idcuenta.length == 2){
        for(var i = 0; i < this.data_ordenado.length;i++){
          if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
            if(!flagcuenta){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = false;
            }else{
              this.data_ordenado[i].Check = false;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }*/
    
    if(idcuenta.length == 2){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            this.data_ordenado[i].dbCheck = false;
          }else{
            if(this.data_ordenado[i].Nivel == 2){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }
    if(idcuenta.length == 4){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,4) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            this.data_ordenado[i].dbCheck = false;
          }else{
            if(this.data_ordenado[i].Nivel == 3){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }
    if(idcuenta.length == 6){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,6) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            this.data_ordenado[i].dbCheck = false;
          }else{
            if(this.data_ordenado[i].Nivel == 4){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }
  }
  Indicador_Nivel1(id_indicador){
    var contador = 0;
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
        if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && !this.DATA_PERIODOSERIES[i].series[j].check){
          this.DATA_PERIODOSERIES[i].series[j].check = true;
        }else{
          if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && this.DATA_PERIODOSERIES[i].series[j].check){
            this.DATA_PERIODOSERIES[i].series[j].check = false;
          }
        }
        if(this.DATA_PERIODOSERIES[i].series[j].check){
          contador++;
        }
      }
    }
    if(contador > 6){
      alert("solo es posible seleccionar 6 períodos");
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador){
            this.DATA_PERIODOSERIES[i].series[j].check = false;
            $("#check_"+this.DATA_PERIODOSERIES[i].anio+this.DATA_PERIODOSERIES[i].series[j].mes).prop('checked', false);
          }
        }
      }
    }else{
      this.cadena_idevaluacion = "";
      var contador = 0;
      this.cadena_evaluacion = [];
      this.subtitulobalancec1 = "";
      this.data_evaluacionaux = [];
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          if(this.DATA_PERIODOSERIES[i].series[j].check){
            if(contador == 0){
              this.cadena_idevaluacion = this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion;
              this.cadena_evaluacion.push({"evaluacion":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4),"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion});
              this.subtitulobalancec1 = this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4);
              this.data_evaluacionaux.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion,"ejercicioperiodo":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4)});
            }else{
              this.cadena_idevaluacion = this.cadena_idevaluacion + "," + this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion;
              this.cadena_evaluacion.push({"evaluacion":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4),"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion});
              this.subtitulobalancec1 = this.subtitulobalancec1 + " | " + this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4);
              this.data_evaluacionaux.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion,"ejercicioperiodo":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4)});
            }
            contador ++;
          }
        }
      }
      this.detalleevaluacioncuentaEntity.ArregloID_evaluacion = this.cadena_idevaluacion;
      
      this.getDetalle_Evaluacion_Cuenta(this.detalleevaluacioncuentaEntity);
    }
  }
  mostrar_primernivel(id_indicador){
    $("#contenedor"+id_indicador).show();
    $("#add_"+id_indicador).hide();
    $("#remove_"+id_indicador).show();
  }
  ocultar_primernivel(id_indicador){
    $("#contenedor"+id_indicador).hide();
    $("#add_"+id_indicador).show();
    $("#remove_"+id_indicador).hide();
  }
  mostrar_segundonivel(id_indicador){
    $("#subcontenedor"+id_indicador).show();
    $("#add2N_"+id_indicador).hide();
    $("#remove2N_"+id_indicador).show();
  }
  ocultar_segundonivel(id_indicador){
    $("#subcontenedor"+id_indicador).hide();
    $("#add2N_"+id_indicador).show();
    $("#remove2N_"+id_indicador).hide();
  }

  VerificarAniosSeleccionados(): string{
    if (this.contador_anios > 1) {
      return 'w3-third w3-disabled';
    }
    else {
      return 'w3-third ';
    }
  }

  ContarMeses(){
    if (this.data_meses_selec.length < 5 ){
      return 'w3-row w3-tiny hijoizquierda';
    }else{
      return 'w3-row w3-tiny ';
    }
  }

  quitar_filtros() {
    this.filtrosPerspectivasActivos = [];
    this.filtrosPerspectivasAnidados = [];
    this.flag_perspectivas = false;
  }
  // eventos de anios y mes

  event_sucursalTodasFalse(){
    this.data_sucursales_select = [];
    for (let i = 0; i < this.data_sucursales.length; i++){
      this.data_sucursales[i].check = false;
      this.data_sucursales_select.push(this.data_sucursales[i]);
    }
    this.datosBarra = Datosdummy.ConstruirArregloAniosSeleccionados(this.data_sucursales_select);
  }

  event_consolidado(): void{
    this.ent_consolidado = !this.ent_consolidado;
    if (this.ent_consolidado){
      this.event_sucursalTodasFalse();
    }
  }

  convertir_moneda(numero){
    let cant = new Intl.NumberFormat('es-MX').format(numero);
    if (cant.indexOf('.') == -1 ){
      cant = cant + '.00';
    }
    return this.formatearNormal(numero);
  }
  formatearNormal(num){
    return FuncionesAfiliacion.formatearnormal(num);
  }
}
