<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!--TITULO DEL SUBMODULO-->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class="w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>

    <!--FILTROS-->
    <div class="w3-rest">
      <div class="w3-noventa2">

        <!--MÉTRICAS-->
        <div class="w3-quarter padreizquierda">
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="metrica" aria-label="Example icon-button with a menu">
              Métrica
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-medium"  #metrica="matMenu" >
              <ng-container *ngFor="let met of metricas">
                <button mat-menu-item>
                  <mat-checkbox [checked]="met.check" name="{{met.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{met.name}}" aria-label="First checkbox"
                                (change)="CambMet(met)">
                    {{met.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>

          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="met.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let met of metricas"
                            class="filtroMesAnioSeleccionado">
                            <span class="contenidoChips">{{met.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--MUNICIPIOS-->
        <div class="w3-quarter padreizquierda">
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="municipio" aria-label="Example icon-button with a menu">
              Municipios
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-medium"  #municipio="matMenu" >
              <ng-container *ngFor="let mun of municipios">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mun.check" name="{{mun.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mun.name}}" aria-label="First checkbox"
                                (change)="municipioChange(mun)">
                    {{mun.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>

          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <!--<div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="mun.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let mun of municipios"
                            class="filtroMesAnioSeleccionado">
                            <span class="contenidoChips">{{mun.name}}</span>
                </div>
              </div>
            </div>-->
          </div>
        </div>

        <!--ANIOS-->
        <div class="w3-quarter padreizquierda">
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Años
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-medium"  #menuanios="matMenu" >
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}"
                                (change)="cambiodeAnio(anio)">
                    {{anio.value}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>

          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="anios.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let anios of aniosSeleccionados"
                            class="filtroMesAnioSeleccionado">
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--MESES-->
        <div class="w3-rest">
          <!-- FILTRO MESES DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              Mes
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menumeses="matMenu">
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}"
                                (change)="cambiodeMes(mes, mesesDeAnioDisponibles)">
                    {{mes.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>

          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnmeses">
              <div class="w3-row chips" >
                <div [ngClass]="meses.check?
                      'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                      *ngFor="let meses of mesesSeleccionados"
                      class="filtroMesAnioSeleccionado">
                      <span class="contenidoChips">{{meses.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--BORRAR FILTROS-->
      <div class="w3-rest w3-right">
        <button mat-icon-button>
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="borrarFil()">
            <img src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<div class="w3-row w3-contenedor-template" style="height: 85vh;">
  <!-- Parte de arriba -->
  <div id="Mapa" class="w3-row w3-white" style="width: 99%; height: 68vh;">
    <!-- ENCABEZADO DEL CONTENDOR DE LAS GRAFICAS-->
    <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
      <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
      <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
        <div class="hijoizquierdaTitle">
          <span class="w3-margin-left-contenido">Distribución de Geoestadísticas</span>
          <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
          <span class="w3-margin-left-filter w3-subtitulo">{{subtit}}</span>
        </div>
      </div>
      <!-- SEGUNDO DIV EN EL QUE SE PONDRÀ EL APARTADO DEL MENÙ Y PARA MOSTRAR LOS LABELS DE LAS GRÀFICAS-->
      <div class="w3-third w3-right-align_rest ">
        <div class="w3-right hijoderechaTitle">
          <mat-icon class=" w3-disabled material-icons-outlined w3-zindex" [ngStyle]="{'color':'gray'}">label_off</mat-icon>
          <mat-icon class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <!-- MENU PARA APLICAR LOS FILTROS-->
        </div>
        <!-- ESTOS SON LOS CUERPOS DEL MENU DONDE SE AGREGAN LAS PERSPECTIVAS-->
        <mat-menu #menu="matMenu">
          <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
            </mat-icon>
            <span>Exportar como</span>
          </button>

        </mat-menu>
        <mat-menu #exportar="matMenu">
          <!--<button mat-menu-item (click)="export_imagen()">
            <span>PNG</span>
          </button>-->
          <button mat-menu-item (click)="export_excel()">
            <span>CSV</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <!-- CUADRANTE DONDE SE CONSTRUIRA EL MAPA-->
    <div class="w3-row graficas" >
      <div class="map" #map id="mapa"></div>
    </div>
    <!-- CUADRANTE DONDE SE CONSTRUIRA LA GRAFICA-->
  </div>
  <!-- Parte de abajo -->

  <div class="w3-third w3-margin-top" style="height: 15vh;">
    <div class="w3-row w3-white padreTendencia" style="width: 96%; height: 15vh;">
      <div class="w3-quarter hijoTendencia w3-center">
        <mat-icon class="w3-icon-display iconos">
          <img style="margin-left: 2vh;" style="height: 100%; width: 70%;" src="../../../assets/img/geografico/localidades.png" >
        </mat-icon>
      </div>
      <div class="w3-quarter hijoTendenciaV2 O" style="margin-top: 1vh;">
        <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">
          {{titulo_tarjeta}}
        </span>
        <br>
        <div style="height: 2vh;"></div>
        <span class="w3-subtituloMeta"> {{anioMes}} </span>
      </div>
      <div class="w3-rest  hijoTendenciaV2" style="margin-top: 2.5vh; margin-right: 1vh;">
        <span class="w3-text-cifras w3-margin-left-contenido cifra w3-balance-bluep" style="font-weight: bold;">
          {{formatearnormal(datosSuma)}}
        </span>
      </div>
    </div>
  </div>

  <div class="w3-third w3-margin-top" style="height: 15vh;">
    <div class="w3-row w3-white padreTendencia" style="width: 96%; height: 15vh;">
      <div class="w3-third hijoTendencia w3-center">
        <mat-icon class="w3-icon-display iconos">
          <img style="margin-left: 2vh;" style="height: 100%; width: 90%;" src="../../../assets/img/geografico/estados.png" >
        </mat-icon>
      </div>
      <div class="w3-third hijoTendenciaV2 O" style="margin-top: 1vh;">
        <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">
          Estados
        </span>
        <br>
        <div style="height: 2vh;"></div>
        <span class="w3-subtituloMeta"> {{anioMes}} </span>
        <span class="w3-subtituloMeta" *ngIf="estSelec != ''"> | {{estSelec}} </span>
      </div>
      <div class="w3-rest  hijoTendenciaV2" style="margin-top: 2.5vh; margin-right: 1vh;">
        <span class="w3-text-cifras w3-margin-left-contenido cifra w3-balance-bluep" style="font-weight: bold;">
          {{nEst}}
        </span>
      </div>
    </div>
  </div>

  <div class="w3-rest w3-margin-top" style="height: 15vh;">
    <div class="w3-row w3-white padreTendencia" style="width: 96%; height: 15vh;">
      <div class="w3-third hijoTendencia w3-center">
        <mat-icon class="w3-icon-display iconos">
          <img style="margin-left: 2vh;" style="height: 100%; width: 90%;" src="../../../assets/img/geografico/municipios.png" >
        </mat-icon>
      </div>
      <div class="w3-third hijoTendenciaV2 O" style="margin-top: 1vh;">
        <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">
          Municipios
        </span>
        <br>
        <div style="height: 2vh;"></div>
        <span class="w3-subtituloMeta"> {{anioMes}} </span>
        <span class="w3-subtituloMeta" *ngIf="municipioSelec != ''"> | {{municipioSelec}} </span>
      </div>
      <div class="w3-rest  hijoTendenciaV2" style="margin-top: 2.5vh; margin-right: 1vh;">
        <span class="w3-text-cifras w3-margin-left-contenido cifra w3-balance-bluep" style="font-weight: bold;">
          {{nMun}}
        </span>
      </div>
    </div>
  </div>
  
  <div class="w3-row ">
  </div>
  <!-- FIN Parte de abajo -->
</div>
