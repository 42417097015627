import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router'
import * as $ from 'jquery';

import pdfMake from "pdfmake/build/pdfmake.js";
import html2canvas from 'html2canvas';
import { SucursalEntity } from './../../../entities/base/BaseEntities';
import { SucursalService } from './../../../services/sucursal/sucursal.service';
import { InfoAnioMesEntitty } from './../../../entities/base/DashboardEntities';
import { InfoaniomesService } from './../../../services/dashboard/infoaniomes.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { FiltroAnio } from 'src/app/model/filtro-anio';
import { FiltroMes } from 'src/app/model/filtro-mes';
import { RangoFecha } from 'src/app/model/rango-fechas';
import { ElasticSociosService } from 'src/app/services/elastic-socios/elastic-socios.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { AfiliacionServiceFunciones } from '../../shared/funciones-afiliacion/serviciosafiliacion';
import { FuncionesAfiliacion, AfiliacionVariablesConstantes, ConstantesAfiliacion, FuncionesArreglos, FuncionesMes, FuncionesCadenaAnioMes } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesBubble, FuncionAgruparSucursales, FuncionesExportCSV, FuncionesGenero, FuncionesBusquedasT1 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesTemplate4 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesFiltrosTemplate2 } from "../../shared/funciones-afiliacion/filtros_template2";
import { FuncionesTemplateIII } from "../../shared/funciones-afiliacion/filtros_template3";
import { PDF, PDFSecond } from './../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import { FuncionesCartera } from './../funciones_cartera';
import { Datosdummy} from './../funciones_c_pruebas';
import { CommonFunctions } from './../../../util/app-util';
import { concat } from 'rxjs';
/* Importación de Funciones */



import {FiltrosService} from '../../../services/filtros/filtros.service';
import {PerfilService} from './../../../services/comercial/perfil/perfil.service';
import { MatTableDataSource } from '@angular/material/table';

export interface UserData {
  name: string,
  value: string,
  check: boolean,
}

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  constructor(
    private sucursalService: SucursalService,
    public dataService: DataService,
    private elasticSearch: ElasticSociosService,
    private filtrosService: FiltrosService,
    private perfilService: PerfilService,
    private excelservices: ExcelService,
  ) { 
    FuncionesColores.inicioColores();
    //this.colorBarras = FuncionesColores.setpaleta_azul('rf16');
    //this.colorScheme = FuncionesColores.setpaleta_azul('paleta_azul');
    //this.colorScheme_barras = FuncionesColores.setpaleta_azul('rf16');

    // Se inicializan arreglos
    //this.rangoFecha = new RangoFecha();
    this.arreglo_anios_seleccionados = this.arreglo_anios;
    //this.data_meses_selec = this.data_meses;
    this.arreglo_anios_seleccionados.push(this.arreglo_meses[this.arreglo_meses.length - 1]);
    this.contador_anios = this.arreglo_anios_seleccionados.length;
    this.contador_mes = this.arreglo_meses_seleccionados.length;
    //this.ObtenerTotalMasculinoFemenino(this.table_data);

    this.data_rangoedad = [
      {"name": "Germany","value": 40632,"extra": {"code": "de"}},
      {"name": "United States","value": 50000,"extra": {"code": "us"}},
      {"name": "France","value": 36745,"extra": {"code": "fr"}},
      {"name": "United Kingdom","value": 36240,"extra": {"code": "uk"}},
      {"name": "Spain","value": 33000,"extra": {"code": "es"}},
      {"name": "Italy","value": 35800,"extra": {"code": "it"}}
    ];
    this.data_ocupacion = [
      {"name": "Empleado","value": 22000},
      {"name": "Ama de Casa","value": 32000},
      {"name": "Obrero","value": 12000},
      {"name": "Maestro","value": 7000},
      {"name": "Comerciante","value": 12780},
      {"name": "Estudiante","value": 6400}
    ];
    this.data_ingreso = [
      {"name": "50001-60000","value": 8940},
      {"name": "40001-50000","value": 12500},
      {"name": "31001-40000","value": 24576},
      {"name": "21001-30000","value": 47812},
      {"name": "10000-20000","value": 13965}
    ];
    this.getFiltros();
  }

  ngOnInit(): void {
  }
  //encabezado
  public colorScheme ={
    domain: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
             '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
             '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
  public colorScheme_barras ={
    domain: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
             '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
             '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
  public colorBarras ={
    domain: ['#79B2D1'],
  };
  contador_anios: number = 0;
  contador_mes: number = 0;
  data_sucursales:any [] = [];
  arreglo_anios_seleccionados: any[] = [];
  arreglo_anios: any[] = [];
  arreglo_meses: any [] = [];
  arreglo_meses_seleccionados:any [] = [];
  ent_consolidado: boolean = false;
  /**Filtro de tipo */
  public tipos_socio = false;
  public tipo_socio = false;
  public tipo_ahorrador = false;
  public tipo_aspirante = false;

  estatus_socio: boolean = false;
  estatus_socioActivo:boolean = false;
  estatus_socioInactivo:boolean = false;

  public aniosDisponibles: any [] = [];
  public aniosSeleccionados: any [] = [];
  public mesesDisponibles: any [] = [];
  public mesesDeAnioDisponibles: any[] = [];
  public mesesSeleccionados: any [] = [];
  public sucursalesDisponibles: any[];
  public sucursalesDisponibles2: MatTableDataSource<UserData>;

  public consolidado = true;
  public subtituloGrafica1: string =  "";
  public sucursal_seleccionada: string = "CONSOLIDADO";
  public EstadoSocioCadena: string = "";
  public ult_anio: any [] = [];
  public ult_mes: any [] = [];

  /* Variables para los cambios de gráficas */
  //C1
  public graficapieC1: boolean = true;
  public graficabarraC1: boolean = false;
  public graficalinealC1: boolean = false;
  public graficatablaC1: boolean = false;
  //C2
  public mapacalorC2: boolean = true;
  public graficabarraC2: boolean = false;
  public graficalinealC2: boolean = false;
  public graficatablaC2: boolean = false;
  //C3
  public graficapieC3: boolean = true;
  public graficabarraC3: boolean = false;
  public graficalinealC3: boolean = false;
  public graficatablaC3: boolean = false;
  //C4
  public graficabarraC4: boolean = true;
  public graficalinealC4: boolean = false;
  public graficapieC4: boolean = false;
  public graficatablaC4: boolean = false;
  //C5
  public graficapastelC5: boolean = true;
  public graficabarraC5: boolean = false;
  public graficalinealC5: boolean = false;
  public graficatablaC5: boolean = false;

  //C6
  public graficabarraC6: boolean = true;
  public graficalinealC6: boolean = false;
  public graficapieC6: boolean = false;
  public graficatablaC6: boolean = false;
  /** Modal */
  //C1
  public graficapieC1_modal: boolean = false;
  public graficabarraC1_modal: boolean = false;
  public graficalinealC1_modal: boolean = false;
  public graficatablaC1_modal: boolean = false;
  //C2
  public mapacalorC2_modal: boolean = false;
  public graficabarraC2_modal: boolean = false;
  public graficalinealC2_modal: boolean = false;
  public graficatablaC2_modal: boolean = false;
  //C3
  public graficapieC3_modal: boolean = false;
  public graficabarraC3_modal: boolean = false;
  public graficalinealC3_modal: boolean = false;
  public graficatablaC3_modal: boolean = false;
  //C4
  public graficabarraC4_modal: boolean = false;
  public graficalinealC4_modal: boolean = false;
  public graficapieC4_modal: boolean = false;
  public graficatablaC4_modal: boolean = false;
  //C5
  public graficapastelC5_modal: boolean = false;
  public graficabarraC5_modal: boolean = false;
  public graficalinealC5_modal: boolean = false;
  public graficatablaC5_modal: boolean = false;
  //C6
  public graficabarraC6_modal: boolean = false;
  public graficalinealC6_modal: boolean = false;
  public graficapieC6_modal: boolean = false;
  public graficatablaC6_modal: boolean = false;


  /* Options Lineal C1 */
  visibilityLegend: boolean = false;
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Género';
  yAxisLabel: string = 'Socios';
  /* Options Lineal C1 */
  /* Options Lineal C2 */
  xAxisLabelC2: string = 'Rango Edad';
  /* Options Lineal C3 */
  xAxisLabelC3: string = 'Generacional';
  /* Options Lineal C4 */
  xAxisLabelC4: string = 'Ocupación';
  /* Options Lineal C5 */
  xAxisLabelC5: string = 'Escolaridad';
  /* Options Lineal C6 */
  xAxisLabelC6: string = 'Ingreso';
  /* variables modal */
  titulomodal: string = "";
  subtitulomodal: string = "";
  /** Variables de filtrado ------------------------------------*/
  public genero: string = "";
  public edad: string = "";
  public generacion: string = "";
  public ocupacion: string = "";
  public escolaridad: string = "";

  public showModal: boolean = false;
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

  ContarMeses(){
    if(this.mesesSeleccionados.length < 5 ){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny ";
    }
  }
  event_consolidado(){}

  VerificarAniosSeleccionados(){
    if(this.contador_anios > 1)
      return "w3-third w3-disabled";
    else
      return "w3-third ";
  }
  event_estatus(e){
    this.tipos_socio = true;
    switch(e){
      case 1:
        this.EstadoSocioCadena = "SOCIO";
        this.tipo_socio = !this.tipo_socio;
        this.tipo_ahorrador = false;
        this.tipo_aspirante = false;
      break;
      case 2:
        this.EstadoSocioCadena = "AHORRADOR MENOR";
        this.tipo_socio = false;
        this.tipo_ahorrador = !this.tipo_ahorrador;
        this.tipo_aspirante = false;
      break;
      case 3:
        this.EstadoSocioCadena = "ASPIRANTE";
        this.tipo_socio = false;
        this.tipo_ahorrador = false;
        this.tipo_aspirante = !this.tipo_aspirante;
      break;
    }
    if(!this.tipo_socio && !this.tipo_ahorrador && !this.tipo_aspirante){
      this.EstadoSocioCadena = "";
    }
    this.llamado_datos();
  }
  //encabezado

   /* GooglePieChart */
   type = 'PieChart';
   data = [
     ['Activos', 1],
     ['Inactivos', 1],
   ];
   public data_barra: any[] = [];
   public data_lineal: any[] = [];
   columnNames = ['Browser', 'Percentage'];
   size=['auto','32vh'];
   options = {
     legend: { position: 'bottom', alignment: 'center'},
     chartArea:{left:30,top:20,width:'90%',height:'80%'},
     pieHole:0.5,
     resize: 'horizontal',
     responsive: true,
     colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
     '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
     '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
   };
   options2 = {
    legend: { position: 'right', alignment: 'center'},
    chartArea:{left:30,top:20,width:'90%',height:'80%'},
    pieHole:0.5,
    resize: 'horizontal',
    responsive: true,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
    '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
    '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
   /* GooglePieChart */

  /* Mapa de calor */
    data_rangoedad: any [] = [];
    data_rangoedad_lineal: any [] = [];
    gradient: boolean = false;
    onSelect(e){}
    labelFormatting(c) {
      return `${(c.label)} `;
    }

  /* Mapa de calor */
  /* GooglePieChart */
    typev2 = 'PieChart';
    datav2 = [
      ['Generación X', 46],
      ['Millenials', 22],
      ['Generación Z', 4],
      ['Silenciosa', 9],
      ['Baby Boomers', 17],
    ];
    datav2_barra:any[] = [];
    datav2_lineal:any[] = [];
    columnNamesv2 = ['Browser', 'Percentage'];
    optionsv2 = {   
      legend: { position: 'bottom', alignment: 'center'},
      chartArea:{left:30,top:20,width:'90%',height:'80%'},
      pieHole:0.5,
      colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
      '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
      '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
      is3D: true,//esto es para verlo en 3D, asi de ladito
      pieStartAngle: 100,//con esto giramos para que el de mayor porcentaje quede en la parte de abajo
    };
  /* GooglePieChart */

  /* primer cuadrante parte 2 */
    cantidad4 = 'quince';
    data4completo = [];
    data_ocupacion: any[] = [];
    data_ocupacion_lineal:any[] = [];
    data_pieOcupacion: any[] = [];
    showXAxis = true;
    showYAxis = true;
    showLegend = false;
    showGridLines: boolean = false;
  /* primer cuadrante parte 2 */

  /* GooglePieChart segundo cuadrante parte 2*/
    typev3 = 'PieChart';
    datav3 = [
      ['Generación X', 46],
      ['Millenials', 22],
      ['Generación Z', 4],
      ['Silenciosa', 9],
      ['Baby Boomers', 17],
    ];
    datav3_barra:any[] = [];
    datav3_lineal:any[] = [];
    columnNamesv3 = ['Browser', 'Percentage'];
    optionsv3 = {   
      legend: { position: 'bottom', alignment: 'center'},
      chartArea:{left:30,top:20,width:'90%',height:'80%'},
      colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
      '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
      '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
      slices: { 1: {offset: 0.1},
                2: {offset: 0.1},
                3: {offset: 0.1},
                4: {offset: 0.1},
      },
    };
    optionsv32 = {   
      legend: { position: 'right', alignment: 'center'},
      chartArea:{left:30,top:20,width:'90%',height:'80%'},
      colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
      '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
      '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
      slices: { 1: {offset: 0.1},
                2: {offset: 0.1},
                3: {offset: 0.1},
                4: {offset: 0.1},
      },
    };
  /* GooglePieChart segundo cuadrante parte 2*/
  /* tercer cuadrante parte 2 */
  data_pieIngreso: any[] = [];
  data_ingreso: any[] = [];
  data_ingreso_lineal: any[] = [];
  showXAxis_t3 = true;
  showYAxis_t3 = true;
  showLegend_t3 = false;
  showXAxisLabel_t3 = false;
  xAxisLabel_t3 = 'Country';
  showYAxisLabel_t3 = true;
  yAxisLabel_t3 = '';
  showGridLines_t3: boolean = false;
  /* tercer cuadrante parte 2 */
  /** Variables para porcentajes */
  sumC1 = 0;
  sumC2 = 0;
  sumC3 = 0;
  sumC4 = 0;
  sumC5 = 0;
  sumC6 = 0;
  public datatablaC1 = [];
  public datatablaC2 = [];
  public datatablaC3 = [];
  public datatablaC4 = [];
  public datatablaC5 = [];
  public datatablaC6 = [];
  /** Titulos de las tablas */
  columnasC1 = ['genero','valor','porcentaje'];
  columnasC2 = ['edad','valor','porcentaje'];
  columnasC3 = ['generacion','valor','porcentaje'];
  columnasC4 = ['ocupacion','valor','porcentaje'];
  columnasC5 = ['escolaridad','valor','porcentaje'];
  columnasC6 = ['ingreso','valor','porcentaje'];

  public selcetPieC1(e){
    if(this.graficapieC1){
      this.genero = this.data[e.selection[0].row][0].toString();
    }else{
      this.genero = e.name;
    }
    this.llamado_datos()
  }
  public selcetC2(e){
    if(this.genero != "")
      this.edad = e.name;
    this.llamado_datos()
  }
  public selcetC3(e){
    if(this.genero != "" && this.edad != ""){
      if(this.graficapieC3){
        this.generacion = this.datav2[e.selection[0].row][0].toString();
      }else{
        this.generacion = e.name;
      }
    }
    this.llamado_datos()
  }
  public selcetC4(e){
    if(this.genero != "" && this.edad != "" && this.generacion != ""){
      if(this.graficapieC4){
        this.ocupacion = this.data_pieOcupacion[e.selection[0].row][0].toString();
      }else{
        this.ocupacion = e.name;
      }
    }
    this.llamado_datos()
  }
  public selcetC5(e){
    if(this.genero != "" && this.edad != "" && this.generacion != "" && this.ocupacion != ""){
      if(this.graficapastelC5){
        this.escolaridad = this.datav3[e.selection[0].row][0].toString();
      }else{
        this.escolaridad = e.name;
      }
    }
    this.llamado_datos()
  }
  public llamado_datos(){
    if(this.genero != "" && this.edad != "" && this.generacion != "" && this.ocupacion != "" && this.escolaridad != ""){
      this.filtrosEstadoSocioSucursalPerspectivaEscolaridad();
    }
    if(this.genero != "" && this.edad != "" && this.generacion != "" && this.ocupacion != "" && this.escolaridad == ""){
      this.filtrosEstadoSocioSucursalPerspectivaOcupacion();
    }
    if(this.genero != "" && this.edad != "" && this.generacion != "" && this.ocupacion == "" && this.escolaridad == ""){
      this.filtrosEstadoSocioSucursalPerspectivaGeneracion();
    }
    if(this.genero != "" && this.edad != "" && this.generacion == "" && this.ocupacion == "" && this.escolaridad == ""){
      this.filtrosEstadoSocioSucursalPerspectivaEdad();
    }
    if(this.genero != "" && this.edad == "" && this.generacion == "" && this.ocupacion == "" && this.escolaridad == ""){
      this.filtrosEstadoSocioSucursalPerspectiva();
    }
    if(this.genero == "" && this.edad == "" && this.generacion == "" && this.ocupacion == "" && this.escolaridad == ""){
      this.filtrosEstadoSocioSucursal();
    }
  }
  public subtitulos(){
    this.subtituloGrafica1 = this.sucursal_seleccionada + " | ";

    if(this.genero != "")
      this.subtituloGrafica1 += this.genero + " | ";
    if(this.edad != "")	
      this.subtituloGrafica1 += this.edad + " | ";
    if(this.generacion != "")
      this.subtituloGrafica1 += this.generacion + " | ";
    if(this.ocupacion != "")
      this.subtituloGrafica1 += this.ocupacion + " | ";
    if(this.escolaridad != "")
      this.subtituloGrafica1 += this.escolaridad + " | ";

    this.subtituloGrafica1 += this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
  }
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  /** Obtención de los datos */
  private getFiltros(): void {
    var ult_anio = [];
    var ult_mes = [];
    this.filtrosService.getSucursalesDisponibles('radiografia', 'codSucCredito-sucursalCredito').subscribe(response => {
      this.sucursalesDisponibles = this.filtrosService.formatSucursalesDisponibles(response);
      this.sucursalesDisponibles.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      this.sucursalesDisponibles2 = new MatTableDataSource(this.sucursalesDisponibles);
    });
    this.filtrosService.getAniosMesesDisponibles('aseguradora').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAnioMesDisponible(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesAniosDisponibles;
      ult_anio = tiempoFiltros.ult_anio;
      ult_mes = tiempoFiltros.ult_mes;
      this.ult_anio = ult_anio;
      this.ult_mes = ult_mes;
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio[0]);
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.aniosMesesSeleccionados();
      //this.getGraficaPorAnio();
      this.subtitulos();

      this.perfilService.getTendenciaGraficaGenero(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response => {
          if (response.http.code === 200) {
            this.data = response.message;
            if (this.data){
              this.formatDatosPieChartGoogle(this.data);
            }
          }
      });
      this.perfilService.getTendenciaGraficaRangoEdad(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosMapaCalor(datos);
          }
        }
      });
      this.perfilService.getTendenciaGraficaGeneracion(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosPieChartGoogleGeneracional(datos);
          }
        }
      });
      this.perfilService.getTendenciaGraficaOcupacion(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosOcupacion(datos);
          }
        }
      });
      this.perfilService.getTendenciaGraficaEscolaridad(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridad(datos);
          }
        }
      });
      this.perfilService.getTendenciaGraficaIngreso(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosIngreso(datos);
          }
        }
      });
    });
  }
  public clickexportCSV(zon){
    var sucursal = "";
    var auxsuc = [], i = 0;
    switch(zon){
      case 'C1':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaGeneroSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,sucursal).subscribe(response => {
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                this.exportCSVSuc(auxsuc,'Genero');
              }
            }
          });
        }
      break;
      case 'C2':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaRangoEdadSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,sucursal, this.genero).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                this.exportCSVSuc(auxsuc,'Rango_edad');
              }
            }
          });
        }
      break;
      case 'C3':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaGeneracionSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,sucursal,this.genero,this.edad).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                this.exportCSVSuc(auxsuc,'Generacional');
              }
            }
          });
        }
      break;
      case 'C4':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaOcupacionSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,sucursal,this.genero,this.edad,this.generacion).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                var empleos = [];
                for(var j = 0; j<10; j++){
                  empleos.push(this.data_ocupacion[j].name);
                }
                var auxEmpleos, auxSeries = [];
                for(const dato of auxsuc){
                  for(var j = 0; j<empleos.length; j++){
                    auxEmpleos = dato.series.find(emp => emp.key == empleos[j]);
                    if(auxEmpleos !=  undefined){
                      auxSeries.push(auxEmpleos);
                    }else{
                      auxSeries.push({key: empleos[j], value: 0});
                    }
                  }
                  dato.series = auxSeries;
                  auxSeries = [];
                }
                this.exportCSVSuc(auxsuc,'Ocupacion');
              }
            }
          });
        }
      break;
      case 'C5':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaEscolaridadSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,sucursal,this.genero,this.edad,this.generacion,this.ocupacion).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                var estudios = [];
                for(var j = 0; j<this.datav3_barra.length; j++){
                  if(j<10){
                    estudios.push(this.datav3_barra[j].name);
                  }
                }
                var auxEmpleos, auxSeries = [];
                for(const dato of auxsuc){
                  for(var j = 0; j<estudios.length; j++){
                    auxEmpleos = dato.series.find(emp => emp.key == estudios[j]);
                    if(auxEmpleos !=  undefined){
                      auxSeries.push(auxEmpleos);
                    }else{
                      auxSeries.push({key: estudios[j], value: 0});
                    }
                  }
                  dato.series = auxSeries;
                  auxSeries = [];
                }
                this.exportCSVSuc(auxsuc,'Escolaridad');
              }
            }
          });
        }
      break;
      case 'C6':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;        
          this.perfilService.getTendenciaGraficaIngresoSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,sucursal,this.genero,this.edad,this.generacion,this.ocupacion,this.escolaridad).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                var ingresos = [];
                for(var j = 0; j<this.data_ingreso.length; j++){
                  if(j<8){
                    ingresos.push(''+this.data_ingreso[j].name);
                  }
                }
                var auxEmpleos, auxSeries = [];
                for(const dato of auxsuc){
                  for(var j = 0; j<ingresos.length; j++){
                    auxEmpleos = dato.series.find(emp => emp.key == ingresos[j]);
                    if(auxEmpleos !=  undefined){
                      auxSeries.push(auxEmpleos);
                    }else{
                      auxSeries.push({key: ingresos[j], value: 0});
                    }
                  }
                  dato.series = auxSeries;
                  auxSeries = [];
                }
                this.exportCSVSuc(auxsuc,'Ingreso');
              }
            }
          });
        }
      break;
    }
  }
  public exportCSVSuc(arreglo, names){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    var perspectiva = '';
    var encabezado_excel = ['Sucursal'];
    for(const data of arreglo[0].series){
      encabezado_excel.push(data.key);
    }
    var cadena_evaluacion = encabezado_excel;
    name = names;
    perspectiva = this.subtituloGrafica1;
    for(const dat of arreglo){
      aux.push(dat.name);
      for(const data of dat.series){
        aux.push(data.value);
      }
      data_excel.push(aux);
      aux = [];
    }
    this.excelservices.generateExcelPerfil(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva);
  }
  private aniosMesesSeleccionados(): void {
    this.aniosSeleccionados = [];
    this.mesesSeleccionados = [];
    for (const valor of this.aniosDisponibles) {
      if (valor.check == true)
        this.aniosSeleccionados.push(valor);
    }
    for (const valor of this.mesesDeAnioDisponibles) {
      if (valor.check == true)
        this.mesesSeleccionados.push(valor);
    }
  }
  private filtrosEstadoSocioSucursal(){
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.formatDatosPieChartGoogle(this.data);
        }
      }
    });
    this.perfilService.getTendenciaGraficaRangoEdad(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMapaCalor(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaGeneracion(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracional(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacion(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacion(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEscolaridad(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosEscolaridad(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaIngreso(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosIngreso(datos);
        }
      }
    });
    this.subtitulos();
  }
  private filtrosEstadoSocioSucursalPerspectiva(){
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.formatDatosPieChartGoogle(this.data);
        }
      }
    });
    this.perfilService.getTendenciaGraficaRangoEdadPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada, this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMapaCalor(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaGeneracionPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracional(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacion(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEscolaridadPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosEscolaridad(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaIngresoPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosIngreso(datos);
        }
      }
    });
    this.subtitulos();
  }
  private filtrosEstadoSocioSucursalPerspectivaEdad(){
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.formatDatosPieChartGoogle(this.data);
        }
      }
    });
    this.perfilService.getTendenciaGraficaRangoEdadPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada, this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMapaCalor(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaGeneracionPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracional(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacion(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEscolaridadPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosEscolaridad(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaIngresoPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosIngreso(datos);
        }
      }
    });
    this.subtitulos();
  }
  private filtrosEstadoSocioSucursalPerspectivaGeneracion(){
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.formatDatosPieChartGoogle(this.data);
        }
      }
    });
    this.perfilService.getTendenciaGraficaRangoEdadPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada, this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMapaCalor(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaGeneracionPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracional(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacion(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEscolaridadPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosEscolaridad(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaIngresoPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosIngreso(datos);
        }
      }
    });
    this.subtitulos();
  }
  private filtrosEstadoSocioSucursalPerspectivaOcupacion(){
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.formatDatosPieChartGoogle(this.data);
        }
      }
    });
    this.perfilService.getTendenciaGraficaRangoEdadPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada, this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMapaCalor(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaGeneracionPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracional(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacion(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEscolaridadPerspectiva4(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion,this.ocupacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosEscolaridad(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaIngresoPerspectiva4(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion,this.ocupacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosIngreso(datos);
        }
      }
    });
    this.subtitulos();
  }
  private filtrosEstadoSocioSucursalPerspectivaEscolaridad(){
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.formatDatosPieChartGoogle(this.data);
        }
      }
    });
    this.perfilService.getTendenciaGraficaRangoEdadPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada, this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMapaCalor(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaGeneracionPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracional(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacion(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEscolaridadPerspectiva4(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion,this.ocupacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosEscolaridad(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaIngresoPerspectiva5(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.EstadoSocioCadena,this.sucursal_seleccionada,this.genero,this.edad,this.generacion,this.ocupacion,this.escolaridad).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosIngreso(datos);
        }
      }
    });
    this.subtitulos();
  }
  //filtros
  // AÑOS
  public anioChangeListener(anioClick: object): void {
    this.ult_anio = [];
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(anioClick, this.aniosDisponibles);
    const anio_selecc = this.aniosDisponibles.find(anio => anio.name === anioClick['name']);
    this.ult_anio.push(anio_selecc['name'])
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    this.buscarMesSeleccionado();
    this.aniosMesesSeleccionados();
    this.llamado_datos();
  }
  public buscarMesSeleccionado(){
    for (const valor of this.mesesDeAnioDisponibles){
      valor['check'] = false;
    }
    var flag = false;
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['name'] == this.ult_mes[0]){
        valor['check'] = true;
        flag = true;
      }
    }
    if(!flag){
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.ult_mes = [];
      this.ult_mes.push(this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].name);
    }
  }
  //Mes
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(mesClick, elementArray);
    this.ult_mes = [];
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']== true){
        this.ult_mes.push(valor['name'])
      }
    }
    this.aniosMesesSeleccionados();
    this.llamado_datos();
  }
  public consolidadoChangeListener(): void {
    if(!this.consolidado){
      this.consolidado = !this.consolidado;
      this.sucursal_seleccionada = "CONSOLIDADO";
      this.subtitulos();
      for(const valor of this.sucursalesDisponibles)
        valor['check'] = false;
    }
    //this.FiltrosAnioMesSucursalSeleccionado();
    this.llamado_datos();
  }
  public sucursalChangeListener(sucursalClick: object): void {
    if(this.consolidado)
      this.consolidado = !this.consolidado;

    this.filtrosService.changeElementCheckStatusFiltroUnAnio(sucursalClick, this.sucursalesDisponibles);
    for(const valor of this.sucursalesDisponibles){
      if(valor['check']){
        this.sucursal_seleccionada = valor['name']
      }
    }
    
    this.llamado_datos();
  }

  public porcentaje(dato,zon){
    switch(zon){
      case 'C1':
        return (dato*100/this.sumC1).toFixed(2);
      case 'C2':
        return (dato*100/this.sumC2).toFixed(2);
      case 'C3':
        return (dato*100/this.sumC3).toFixed(2);
      case 'C4':
        return (dato*100/this.sumC4).toFixed(2);
      case 'C5':
        return (dato*100/this.sumC5).toFixed(2);
      case 'C6':
        return (dato*100/this.sumC6).toFixed(2);
    }
  }
  public suma(arreglo,zon){
    switch(zon){
      case 'C1':
        this.sumC1 = 0;
        for(const dato of arreglo){
          this.sumC1 += dato['value'];
        }
      break;
      case 'C2':
        this.sumC2 = 0;
        for(const dato of arreglo){
          this.sumC2 += dato['value'];
        }
      break;
      case 'C3':
        this.sumC3 = 0;
        for(const dato of arreglo){
          this.sumC3 += dato['value'];
        }
      break;
      case 'C4':
        this.sumC4 = 0;
        for(const dato of arreglo){
          this.sumC4 += dato['value'];
        }
      break;
      case 'C5':
        this.sumC5 = 0;
        for(const dato of arreglo){
          this.sumC5 += dato['value'];
        }
      break;
      case 'C6':
        this.sumC6 = 0;
        for(const dato of arreglo){
          this.sumC6 += dato['value'];
        }
      break;
    }
  }
  /* Funciones para agrupar los datos de las gráficas */
  //Genero
  private formatDatosPieChartGoogle(data): void {
    this.data = [];
    this.data_barra = [];
    this.data_lineal = [];
    for (const elemento of data) {
      var arr = [elemento['key'], elemento['value']];
      this.data.push(arr)
      this.data_barra.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.data_lineal.push({"name":"Género","series":this.data_barra});
    this.suma(this.data_barra,'C1');

    this.datatablaC1 = [];
    var porT = 0;
    for(const dat of this.data_barra){
      this.datatablaC1.push({
        name: dat['name'],
        value: dat['value'],
        porcentaje: Number(this.porcentaje(dat['value'],'C1'))
      });
      porT += this.datatablaC1[this.datatablaC1.length-1].porcentaje;
    }
    this.datatablaC1.push({
      name: "",
      value: this.sumC1,
      porcentaje: Number(Math.round(porT))
    });
  }
  // Grafica Mapa de Calor RandoEdad
  private formatDatosMapaCalor(datos): void {
    this.data_rangoedad = [];
    this.data_rangoedad_lineal = [];
    for (const elemento of datos) {
      this.data_rangoedad.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.data_rangoedad_lineal.push({"name":"RangoEdad","series":this.data_rangoedad});
    this.suma(this.data_rangoedad,'C2');

    this.datatablaC2 = [];
    var porT = 0;
    for(const dat of this.data_rangoedad){
      this.datatablaC2.push({
        name: dat['name'],
        value: dat['value'],
        porcentaje: Number(this.porcentaje(dat['value'],'C2'))
      });
      porT += this.datatablaC2[this.datatablaC2.length-1].porcentaje;
    }
    this.datatablaC2.push({
      name: "",
      value: this.sumC2,
      porcentaje: Number(Math.round(porT))
    });
  }
  //Gráfica generacional
  private formatDatosPieChartGoogleGeneracional(datos): void {
    this.datav2 = [];
    this.datav2_barra = [];
    this.datav2_lineal = [];
    for (const elemento of datos) {
      var arr = [elemento['key'], elemento['value']];
      this.datav2.push(arr)
      this.datav2_barra.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.datav2_lineal.push({"name":"Generación","series":this.datav2_barra});
    this.suma(this.datav2_barra,'C3');

    this.datatablaC3 = [];
    var porT = 0;
    for(const dat of this.datav2_barra){
      this.datatablaC3.push({
        name: dat['name'],
        value: dat['value'],
        porcentaje: Number(this.porcentaje(dat['value'],'C3'))
      });
      porT += this.datatablaC3[this.datatablaC3.length-1].porcentaje;
    }
    this.datatablaC3.push({
      name: "",
      value: this.sumC3,
      porcentaje: Number(Math.round(porT))
    });
  }
  //Grafica Ocupación
  public cambioCant4(dat: any){
    switch(dat){
      case 1:
          this.cantidad4 = 'quince';
      break;
      case 2:
          this.cantidad4 = 'all';
      break;
    }
    this.cantidadDatZon4();
  }
  public cantidadDatZon4(){
    this.data_ocupacion = [];
    this.data_pieOcupacion = [];
    switch(this.cantidad4){
      case 'quince':
        var i = 0;
        for (const elemento of this.data4completo) {
          if(i < 15){
            var arr = [ elemento['name'],elemento['value'] ];
            this.data_pieOcupacion.push(arr);
            this.data_ocupacion.push({
              // @ts-ignore
              name:  elemento['name'],
              // @ts-ignore
              value: elemento['value'],
              // @ts-ignore
              //count: elemento.key_count
            });
          }
          i++;
        }
      break;
      case 'all':
        for (const elemento of this.data4completo) {
          var arr = [ elemento['name'],elemento['value'] ];
          this.data_pieOcupacion.push(arr);
          this.data_ocupacion.push({
            // @ts-ignore
            name:  elemento['name'],
            // @ts-ignore
            value: elemento['value'],
            // @ts-ignore
            //count: elemento.key_count
          });
        }
      break;
    }
    this.suma(this.data_ocupacion,'C4');

    this.datatablaC4 = [];
    var porT = 0;
    for(const dat of this.data_ocupacion){
      this.datatablaC4.push({
        name: dat['name'],
        value: dat['value'],
        porcentaje: Number(this.porcentaje(dat['value'],'C4'))
      });
      porT += this.datatablaC4[this.datatablaC4.length-1].porcentaje;
    }
    this.datatablaC4.push({
      name: "",
      value: this.sumC4,
      porcentaje: Number(Math.round(porT))
    });
  }
  private formatDatosOcupacion(datos): void {
    this.data4completo = [];
    this.data_ocupacion_lineal = [];
    
    for (const elemento of datos) {
      this.data4completo.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.cantidadDatZon4();
    
    this.data_ocupacion_lineal.push({"name":"Ocupación","series":this.data_ocupacion});
  }
  //Grafica Escolaridad
  private formatDatosEscolaridad(datos): void {
    this.datav3 = [];
    this.datav3_barra = [];
    this.datav3_lineal = [];
    for (const elemento of datos) {
      var arr2 = [elemento['key'], elemento['value']];
      this.datav3.push(arr2);
      this.datav3_barra.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.datav3_lineal.push({"name":"Escolaridad","series":this.datav3_barra});
    this.suma(this.datav3_barra,'C5');

    this.datatablaC5 = [];
    var porT = 0;
    for(const dat of this.datav3_barra){
      this.datatablaC5.push({
        name: dat['name'],
        value: dat['value'],
        porcentaje: Number(this.porcentaje(dat['value'],'C5'))
      });
      porT += this.datatablaC5[this.datatablaC5.length-1].porcentaje;
    }
    this.datatablaC5.push({
      name: "",
      value: this.sumC5,
      porcentaje: Number(Math.round(porT))
    });
  }
  //Gráfica dataIngreso
  private formatDatosIngreso(datos): void {
    this.data_ingreso = [];
    this.data_pieIngreso = [];
    this.data_ingreso_lineal = [];
    var i = 0;
    var sum = 0;
    for (const elemento of datos) {
      var arr = [ elemento['key'].toString(),elemento['value'] ];
      if(Number(elemento['key'])<=35000){
        this.data_pieIngreso.push(arr);
        this.data_ingreso.push({
          // @ts-ignore
          name:  elemento['key'],
          // @ts-ignore
          value: elemento['value'],
          // @ts-ignore
          //count: elemento.key_count
        });
        i++;
      }else{
        sum += elemento['value'];
      }
    }
    if(sum != 0){
      this.data_ingreso.push({
        // @ts-ignore
        name:  '+35000',
        // @ts-ignore
        value: sum,
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.data_ingreso_lineal.push({"name":"Ingreso","series":this.data_ingreso});
    this.suma(this.data_ingreso,'C6');

    this.datatablaC6 = [];
    var porT = 0;
    for(const dat of this.data_ingreso){
      this.datatablaC6.push({
        name: dat['name'],
        value: dat['value'],
        porcentaje: Number(this.porcentaje(dat['value'],'C6'))
      });
      porT += this.datatablaC6[this.datatablaC6.length-1].porcentaje;
    }
    this.datatablaC6.push({
      name: "",
      value: this.sumC6,
      porcentaje: Number(Math.round(porT))
    });
  }
  /* Funciones para agrupar los datos de las gráficas */

  /* Eventos para cambiar graficas C1*/
  public cambiarGraficaC1(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC1 = true;
        this.graficapieC1 = false;
        this.graficalinealC1 = false;
        this.graficatablaC1 = false;
      break;
      case 'lineal':
        this.graficalinealC1 = true;
        this.graficabarraC1 = false;
        this.graficapieC1 = false;
        this.graficatablaC1 = false;
      break;
      case 'pastel':
        this.graficapieC1 = true;
        this.graficalinealC1 = false;
        this.graficabarraC1 = false;
        this.graficatablaC1 = false;
      break;
      case 'tabla':
        this.graficatablaC1 = true;
        this.graficapieC1 = false;
        this.graficalinealC1 = false;
        this.graficabarraC1 = false;
      break;
    }

  }
  /* Eventos para cambiar graficas C2*/
  public cambiarGraficaC2(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC2 = true;
        this.mapacalorC2 = false;
        this.graficalinealC2 = false;
        this.graficatablaC2 = false;
      break;
      case 'lineal':
        this.graficalinealC2 = true;
        this.graficabarraC2 = false;
        this.mapacalorC2 = false;
        this.graficatablaC2 = false;
      break;
      case 'mapacalor':
        this.mapacalorC2 = true;
        this.graficalinealC2 = false;
        this.graficabarraC2 = false;
        this.graficatablaC2 = false;
      break;
      case 'tabla':
        this.graficatablaC2 = true;
        this.mapacalorC2 = false;
        this.graficalinealC2 = false;
        this.graficabarraC2 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C3*/
  public cambiarGraficaC3(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC3 = true;
        this.graficapieC3 = false;
        this.graficalinealC3 = false;
        this.graficatablaC3 = false;
      break;
      case 'lineal':
        this.graficalinealC3 = true;
        this.graficabarraC3 = false;
        this.graficapieC3 = false;
        this.graficatablaC3 = false;
      break;
      case 'pastel':
        this.graficapieC3 = true;
        this.graficalinealC3 = false;
        this.graficabarraC3 = false;
        this.graficatablaC3 = false;
      break;
      case 'tabla':
        this.graficatablaC3 = true;
        this.graficapieC3 = false;
        this.graficalinealC3 = false;
        this.graficabarraC3 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C4*/
  public cambiarGraficaC4(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC4 = true;
        this.graficapieC4 = false;
        this.graficalinealC4 = false;
        this.graficatablaC4 = false;
      break;
      case 'lineal':
        this.graficalinealC4 = true;
        this.graficabarraC4 = false;
        this.graficapieC4 = false;
        this.graficatablaC4 = false;
      break;
      case 'pastel':
        this.graficapieC4 = true;
        this.graficalinealC4 = false;
        this.graficabarraC4 = false;
        this.graficatablaC4 = false;
      break;
      case 'tabla':
        this.graficatablaC4 = true;
        this.graficapieC4 = false;
        this.graficalinealC4 = false;
        this.graficabarraC4 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C5*/
  public cambiarGraficaC5(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC5 = true;
        this.graficapastelC5 = false;
        this.graficalinealC5 = false;
        this.graficatablaC5 = false
      break;
      case 'lineal':
        this.graficalinealC5 = true;
        this.graficabarraC5 = false;
        this.graficapastelC5 = false;
        this.graficatablaC5 = false
      break;
      case 'pastel':
        this.graficapastelC5 = true;
        this.graficalinealC5 = false;
        this.graficabarraC5 = false;
        this.graficatablaC5 = false
      break;
      case 'tabla':
        this.graficatablaC5 = true
        this.graficapastelC5 = false;
        this.graficalinealC5 = false;
        this.graficabarraC5 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C6*/
  public cambiarGraficaC6(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC6 = true;
        this.graficapieC6 = false;
        this.graficalinealC6 = false;
        this.graficatablaC6 = false;
      break;
      case 'lineal':
        this.graficalinealC6 = true;
        this.graficabarraC6 = false;
        this.graficapieC6 = false;
        this.graficatablaC6 = false;
      break;
      case 'pastel':
        this.graficapieC6 = true;
        this.graficalinealC6 = false;
        this.graficabarraC6 = false;
        this.graficatablaC6 = false;
      break;
      case 'tabla':
        this.graficatablaC6 = true;
        this.graficapieC6 = false;
        this.graficalinealC6 = false;
        this.graficabarraC6 = false;
      break;
    }
  }
  /* Ampliar gráficas */
  public AmpliarC1(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Género";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficapieC1)
      this.graficapieC1_modal = true;
    if(this.graficabarraC1)
      this.graficabarraC1_modal = true;
    if(this.graficalinealC1)
      this.graficalinealC1_modal = true;
    if(this.graficatablaC1)
      this.graficatablaC1_modal = true;
  }
  public AmpliarC2(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Rango Edad";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.mapacalorC2)
      this.mapacalorC2_modal = true;
    if(this.graficabarraC2)
      this.graficabarraC2_modal = true;
    if(this.graficalinealC2)
      this.graficalinealC2_modal = true;
    if(this.graficatablaC2)
      this.graficatablaC2_modal = true;
  }
  public AmpliarC3(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Estatus";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficapieC3)
      this.graficapieC3_modal = true;
    if(this.graficabarraC3)
      this.graficabarraC3_modal = true;
    if(this.graficalinealC3)
      this.graficalinealC3_modal = true;
    if(this.graficatablaC3)	
      this.graficatablaC3_modal = true;
  }
  public AmpliarC4(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Ocupación";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficabarraC4)
      this.graficabarraC4_modal = true;
    if(this.graficalinealC4)
      this.graficalinealC4_modal = true;
    if(this.graficapieC4)
      this.graficapieC4_modal = true;
    if(this.graficatablaC4)
      this.graficatablaC4_modal = true;
  }
  public AmpliarC5(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Finalidad";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficapastelC5)
      this.graficapastelC5_modal = true;
    if(this.graficabarraC5)
      this.graficabarraC5_modal = true;
    if(this.graficalinealC5)
      this.graficalinealC5_modal = true;
    if(this.graficatablaC5)
      this.graficatablaC5_modal = true;
  }
  public AmpliarC6(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Ingreso";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficabarraC6)
      this.graficabarraC6_modal = true;
    if(this.graficalinealC6)
      this.graficalinealC6_modal = true;
    if(this.graficapieC6)
      this.graficapieC6_modal = true;
    if(this.graficatablaC6)
      this.graficatablaC6_modal = true;
  }

  public hideGraficasModal(){
    //C1
    this.graficapieC1_modal = false;
    this.graficabarraC1_modal = false;
    this.graficalinealC1_modal = false;
    this.graficatablaC1_modal = false;
    //C2
    this.mapacalorC2_modal = false;
    this.graficalinealC2_modal = false;
    this.graficabarraC2_modal = false;
    this.graficatablaC2_modal = false;
    //C3
    this.graficapieC3_modal = false;
    this.graficabarraC3_modal = false;
    this.graficalinealC3_modal = false;
    this.graficatablaC3_modal = false;
    //C4
    this.graficalinealC4_modal = false;
    this.graficabarraC4_modal = false;
    this.graficapieC4_modal = false;    
    this.graficatablaC4_modal = false;
    //C5
    this.graficapastelC5_modal = false;
    this.graficabarraC5_modal = false;
    this.graficalinealC5_modal = false;
    this.graficatablaC5_modal = false;
    //C6
    this.graficalinealC6_modal = false;
    this.graficabarraC6_modal = false;
    this.graficapieC6_modal = false;
    this.graficatablaC6_modal = false;

  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  /* Ampliar gráficas */
  public quitar_filtros(){
    //C1
    this.graficapieC1 = true;
    this.graficabarraC1 = false;
    this.graficalinealC1 = false;
    this.graficatablaC1 = false;
    //C2
    this.mapacalorC2 = true;
    this.graficalinealC2 = false;
    this.graficabarraC2 = false;
    this.graficatablaC2 = false;
    //C3
    this.graficapieC3 = true;
    this.graficabarraC3 = false;
    this.graficalinealC3 = false;
    this.graficatablaC3 = false;
    //C4
    this.graficalinealC4 = false;
    this.graficabarraC4 = true;
    this.graficapieC4 = false;    
    this.graficatablaC4 = false;
    //C5
    this.graficapastelC5 = true;
    this.graficabarraC5 = false;
    this.graficalinealC5 = false;
    this.graficatablaC5 = false;
    //C6
    this.graficalinealC6 = false;
    this.graficabarraC6 = true;
    this.graficapieC6 = false;
    this.graficatablaC6 = false;

    this.tipos_socio = false;
    this.tipo_socio = false;
    this.tipo_ahorrador = false;
    this.tipo_aspirante = false;
    this.EstadoSocioCadena = "";

    this.sucursal_seleccionada = "CONSOLIDADO";
    this.genero = "";
    this.edad = "";
    this.generacion = "";
    this.ocupacion = "";
    this.escolaridad = "";
    this.llamado_datos();
    this.consolidado = true;

  }
  /** exportación */
  public export_excel(zon: any){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    var perspectiva = '';
    switch(zon){
      case 'C1':
        var encabezado_excel = ['Género','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por genero';
        perspectiva = this.subtituloGrafica1;
        for(var i = 0; i < this.datatablaC1.length; i++){
          aux.push(this.datatablaC1[i].name);
          aux.push(this.datatablaC1[i].value);
          aux.push(this.datatablaC1[i].porcentaje);
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C2':
        var encabezado_excel = ['Rango de edad','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por edad';
        perspectiva = this.subtituloGrafica1;
        for(var i = 0; i < this.datatablaC2.length; i++){
          aux.push(this.datatablaC2[i].name);
          aux.push(this.datatablaC2[i].value);
          aux.push(this.datatablaC2[i].porcentaje);
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C3':
        var encabezado_excel = ['Generación','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil generacional';
        perspectiva = this.subtituloGrafica1;
        for(var i = 0; i < this.datatablaC3.length; i++){
          aux.push(this.datatablaC3[i].name);
          aux.push(this.datatablaC3[i].value);
          aux.push(this.datatablaC3[i].porcentaje);
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C4':
        var encabezado_excel = ['Ocupación','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por ocupación';
        perspectiva = this.subtituloGrafica1;
        for(var i = 0; i < this.datatablaC4.length; i++){
          aux.push(this.datatablaC4[i].name);
          aux.push(this.datatablaC4[i].value);
          aux.push(this.datatablaC4[i].porcentaje);
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C5':
        var encabezado_excel = ['Escolaridad','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por escolaridad';
        perspectiva = this.subtituloGrafica1;
        for(var i = 0; i < this.datatablaC5.length; i++){
          aux.push(this.datatablaC5[i].name);
          aux.push(this.datatablaC5[i].value);
          aux.push(this.datatablaC5[i].porcentaje);
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C6':
        var encabezado_excel = ['Ingreso','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por ingreso';
        perspectiva = this.subtituloGrafica1;
        for(var i = 0; i < this.datatablaC6.length; i++){
          aux.push(this.datatablaC6[i].name);
          aux.push(this.datatablaC6[i].value);
          aux.push(this.datatablaC6[i].porcentaje);
          data_excel.push(aux);
          aux = [];
        }
      break;
    }
    this.excelservices.generateExcelPerfil(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva);
  }
  public export_imagen(zon: any){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 'C1':
        grafica = "Zona1";
        if(this.graficapieC1)
          name = 'GraficaPieC1';
        if(this.graficabarraC1)
          name = 'GraficaBarrasC1';
        if(this.graficalinealC1)
          name = 'GraficaLinealC1';  
      break;
      case 'C2':
        grafica = "Zona2";
        if(this.mapacalorC2)
          name = 'GraficaCalorC2';
        if(this.graficabarraC2)
          name = 'GraficaBarrasC2';
        if(this.graficalinealC2)
          name = 'GraficaLinealC2';  
      break;
      case 'C3':
        grafica = "Zona3";
        if(this.graficapieC3)
          name = 'GraficaPieC3';
        if(this.graficabarraC3)
          name = 'GraficaBarrasC3';
        if(this.graficalinealC3)
          name = 'GraficaLinealC3';  
      break;
      case 'C4':
        grafica = "Zona4";
        if(this.graficapieC4)
          name = 'GraficaPieC4';
        if(this.graficabarraC4)
          name = 'GraficaBarrasC4';
        if(this.graficalinealC4)
          name = 'GraficaLinealC4';  
      break;
      case 'C5':
        grafica = "Zona5";
        if(this.graficapastelC5)
          name = 'GraficaPieC5';
        if(this.graficabarraC5)
          name = 'GraficaBarrasC5';
        if(this.graficalinealC5)
          name = 'GraficaLinealC5';  
      break;
      case 'C6':
        grafica = "Zona6";
        if(this.graficapieC6)
          name = 'GraficaPieC6';
        if(this.graficabarraC6)
          name = 'GraficaBarrasC6';
        if(this.graficalinealC6)
          name = 'GraficaLinealC6';  
      break;
    }
    PDF.exportimagenes(grafica,name);
  }
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sucursalesDisponibles2.filter = filterValue.trim().toLowerCase();
  }
}
