import { JsonpClientBackend } from '@angular/common/http';
import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  constructor(
    private router: Router,
    public dataService: DataService,
    ) {
    /*FUNCIONES MENÚ V2*/
    this.dataService.fillerNav.forEach(module => {
      this.arrowState.push('keyboard_arrow_down');
    });
  }

  @Input() sidenav: MatSidenav;

  public arrowState: Array<string> = [];

  ngOnInit(): void {
  }

  private removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public isActiveModule(menu: object): boolean {
    const rutaActual = this.router.url;
    const rutas = rutaActual.split('/');
    rutas.shift();
    rutas.shift();
    const moduloActual = this.dataService.fillerNav
      // @ts-ignore
      .find(module => this.removeAccents(module.name) === this.removeAccents(menu.name)
        && rutas[0] === this.removeAccents(module.name).toLowerCase()
      );
    if (moduloActual) {
      this.dataService.currentModule = moduloActual.name;
      return true;
    }else {
      return false;
    }
  }
  public isActiveSubmodule(submenu: object): boolean {
    let rutaActual = this.router.url;
    const rutas = rutaActual.split('/');
    rutas.shift();
    rutas.shift();
    rutaActual = rutas.join('/');
    // @ts-ignore
    if (submenu.route === rutaActual) {
      // @ts-ignore
      this.dataService.currentSubmodule = submenu.name;
      return true;
    }else {
      return false;
    }
  }

}
