import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {Detalle_Evaluacion_IndicadorEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class DetalleEvaluacionIndicadorService extends Service<Detalle_Evaluacion_IndicadorEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/Detalle_Evaluacion_Indicador', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: Detalle_Evaluacion_IndicadorEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    
    if (null != entity.Id_Evaluacion) { params = params.set('Id_Evaluacion', entity.Id_Evaluacion.toString()); }
    if (null != entity.Id_Indicador) { params = params.set('Id_Indicador', entity.Id_Indicador.toString()); }
    if (null != entity.Valor) { params = params.set('Valor', entity.Valor.toString()); }
    if (null != entity.Respuesta) { params = params.set('Respuesta', entity.Respuesta.toString()); }
    if (null != entity.EstadoSemaforo) { params = params.set('EstadoSemaforo', entity.EstadoSemaforo.toString()); }
    if (null != entity.ResultadoIndicador) { params = params.set('ResultadoIndicador', entity.ResultadoIndicador.toString()); }
    if (null != entity.Rojos) { params = params.set('Rojos', entity.Rojos.toString()); }
    if (null != entity.Amarillos) { params = params.set('Amarillos', entity.Amarillos.toString()); }
    if (null != entity.Verdes) { params = params.set('Verdes', entity.Verdes.toString()); }
    if (null != entity.Nota) { params = params.set('Nota', entity.Nota.toString()); }
    if (null != entity.TextoSemaforo) { params = params.set('TextoSemaforo', entity.TextoSemaforo.toString()); }
    if (null != entity.ArregloID_evaluacion) { params = params.set('ArregloID_evaluacion', entity.ArregloID_evaluacion.toString()); }

    
    return params;
  }
}
