import { Component, OnInit } from '@angular/core';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { MatMenuModule } from '@angular/material/menu';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import {perdidaEntity, concentracionEntity, mayoresDeudoresEntity} from './../entities_riesgos/entities_riesgos';
import {ConcentracionService} from './../../../services/riesgos/concentracion.service';
import { mayoresdeudoresService } from './../../../services/riesgos/mayoresdeudores.service';
import {ThemePalette} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import { ExcelService } from './../../../services/exportar/excel.service';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';

export interface PeriodicElement { 
  periodo: string;
  servicios: string;
  producto1: string;
  producto2: string;
  producto3: string;
  producto4: string;
  producto5: string;
  producto6: string;
}
export interface Task {
  name: string;
  check: boolean;
}
@Component({
  selector: 'app-concentracion',
  templateUrl: './concentracion.component.html',
  styleUrls: ['./concentracion.component.css']
})
export class ConcentracionComponent implements OnInit {
  // **** NUevo tipo de filtrado ****
  filtroNorm = true;
  nperiodos = 12;
  // **** Cambio de miles a pesos ****
  miles1 = true;
  miles2 = true;
  milTab1 = '(Miles)';
  milTab2 = '(Miles)';
  // **** Variables de titulos y subtitulos ****
  pop = false;
  elemagrup = 'Sector';
  eslAgrupSec = true;
  eslAgrupZon = false;
  eslAgrupGen = false;
  eslAgrupEstat = false;
  eslAgrupGar = false;
  elemagrup2 = 'Producto';
  eslAgrupPro = true;
  eslAgrupBan = false;
  // **** Variables del web service ****
  concentracion = new concentracionEntity;
  periodo = 22022;
  // **** Variables de los contenedores de datos ****
  multi : any = [];
  multi2 : any = [];
  lineal : any = [];
  DatosProduct : any;
  DatosProductinv : any;
  DatosProduct2 : any;
  data: any = [];
  data2: any = [];
  datTab1 = [];
  datTab2 = [];
  DatosBanda : any = [];
  // **** Variables de filtros
  flag_filtrar_anio: boolean = false;
  checked_anio: boolean = false;
  flag_dropanio: boolean = false;
  // **** meses ****
  public DatosAnios = {"aniosDisponibles":[
    {"name":"2020","value":2020,"check":false,"selected":false},
    {"name":"2021","value":2021,"check":false,"selected":false},
    {"name":"2022","value":2022,"check":true,"selected":false},],
    "mesesDisponibles":[
    {"name":"nov","value":"112020","year":"2020","check":false},
    {"name":"dic","value":"122020","year":"2020","check":false},
    {"name":"ene","value":"012021","year":"2021","check":false},
    {"name":"feb","value":"022021","year":"2021","check":false},
    {"name":"mar","value":"032021","year":"2021","check":false},
    {"name":"abr","value":"042021","year":"2021","check":false},
    {"name":"may","value":"052021","year":"2021","check":false},
    {"name":"jun","value":"062021","year":"2021","check":false},
    {"name":"jul","value":"072021","year":"2021","check":false},
    {"name":"ago","value":"082021","year":"2021","check":false},
    {"name":"sep","value":"092021","year":"2021","check":false},
    {"name":"oct","value":"102021","year":"2021","check":false},
    {"name":"nov","value":"112021","year":"2021","check":false},
    {"name":"dic","value":"122021","year":"2021","check":false},
    {"name":"ene","value":"012022","year":"2022","check":false},
    {"name":"feb","value":"022022","year":"2022","check":false},
    {"name":"mar","value":"032022","year":"2022","check":false},
    {"name":"abr","value":"042022","year":"2022","check":false},
    {"name":"may","value":"052022","year":"2022","check":false},
    {"name":"jun","value":"062022","year":"2022","check":false},
    {"name":"jul","value":"072022","year":"2022","check":false},
    {"name":"ago","value":"082022","year":"2022","check":false},
    {"name":"sep","value":"092022","year":"2022","check":false},
    {"name":"oct","value":"102022","year":"2022","check":false},
    {"name":"nov","value":"112022","year":"2022","check":false},]};
  public aniosDisponibles: any [];
  public mesesDisponibles: any [];
  public mes = 'feb';
  public anio = '2022';
  TituloGrafPrinc = ' Concentración por '+ this.elemagrup;
  subTituloGrafPrinc = this.anio.substring(2,4) + ' | '  + this.mes +' | matriz';
  TituloGrafSec = ' Concentración por '+this.elemagrup2;
  subTituloGrafSec = this.anio.substring(2,4) + ' | '  + this.mes +' | matriz';
  TituloGrafTer = 'Concentración bandas';
  subTituloGrafTer = this.anio.substring(2,4) + ' | '  + this.mes +' | matriz';
  mesesallComplete: boolean = false;
  // **** Variables del metodo de separacion de datos ****
  separador:string =  ","; // separador para los miles
  sepDecimal:string = '.'; // separador para los decimales
  // **** VAriables de tabla
  Titulos : any = [];
  displayedColumns: string[] = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3',
  'producto4', 'producto5', 'producto6'];
  displayedColumns2: string[] = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3',
  'producto4'];
  dataSource : any = [];
  dataSource2 : any = [];
  // ****  Variables de las graficas de pie 
  gradient: boolean = false;
  showLegend: boolean = true;
  isDoughnut: boolean = true;
  arcWidth: number = 0.35; 
  legendPosition: any = 'right';
  lePos = 'below';
  labels : boolean = true;
  colorSchemePie : any = {  
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  //Variables de graficas de barras
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  showGridLines: boolean = false;
  legendTitle: string = '';
  xAxisLabel: string = 'Meses';
  yAxisLabel: string = 'Porcentaje';
  schemeType = 'ordinal';
  title= 'Concentración por sector';
  colorScheme : any = {  
    domain: [
      '#004A97', '#68B4D6', '#82CC60', '#FFCB03', '#FC8A33', '#F74C58'
    ]
  };
  colorSchemeBarras : any = {  
    domain: [
      '#002F7C', '#0075C9', '#5EAEE5', '#002F7C', '#FC8A33'
    ]
  };
  colorSchemeBarras2 : any = {  
    domain: [
      '#002F7C', '#0075C9', '#5EAEE5', '#FC8A33'
    ]
  };
  // **** Variables de activación de graficas normales y del modal ****
  Title = '';
  Subtitulo_grafica = '';
  //Variables de las gráficas principales
  graficaBarrasP2= true;
  graficaLinearP2= false;
  graficaAreaP2 = false;
  gtablaP = false;
  graficaBarrasP2Modal= false;
  graficaLinearP2Modal= false;
  graficaAreaP2Modal = false;
  gtablaPModal = false;
  //Variables de las gráficas secundarias
  grafBarrasver = false;
  grafBarrashor = true;
  gtablas = false;
  grafBarrasverModal = false;
  grafBarrashorModal = false;
  gtablasModal = false;
  //Variables de las gráficas tercearias
  graficaPie2 = true;
  graficaCalor2 = false;
  graficaBarras2 = false;
  graficaPie2Modal = false;
  graficaCalor2Modal = false;
  graficaBarras2Modal = false;
  // **** Constructor ****
  constructor(
    private concentracionService: ConcentracionService,
    private mayoresdeudoresService: mayoresdeudoresService,
    private excelservices: ExcelService){
    this.periodo = Number(this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].value);
    this.mes = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].name;
    this.anio = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].year;
    this.FuncionesDataSource();
    this.getDatos();
  }
  ngOnInit(): void {
  }
  // **** Funciones del web service y tratamiento de datos
  public FuncionesDataSource(){
    this.concentracion.Id_Institucion = 1;
    this.concentracion.Periodo = this.periodo;
    this.concentracion.ElementoAgrupador = this.elemagrup;//'Zona';
    this.concentracion.NPeriodos = this.nperiodos;
    this.concentracion.F_NDatosLimite= 3;
    this.concentracion.BnConIntereses = 1;
    this.funcionConcentracionSector(this.concentracion);
    this.concentracion.ElementoAgrupador = this.elemagrup2;
    this.funcionConcentracionProducto(this.concentracion);
    this.concentracion.ElementoAgrupador = 'CategoriaCredito';
    this.funcionConcentracionBandas(this.concentracion);
    this.actsubtitulos();
  }
  funcionConcentracionSector(concentracionEntity: any){
  this.concentracionService.getByAddUrl_Riesgo('concentracion',concentracionEntity).subscribe(dataArr => {
      this.Datos(dataArr);
    });
  }
  funcionConcentracionProducto(concentracionEntity: any){
    this.concentracionService.getByAddUrl_Riesgo('concentracion',concentracionEntity).subscribe(dataArr => {
      this.DatosProducto(dataArr);
    });
  } 
  funcionConcentracionBandas(concentracionEntity: any){
    this.concentracionService.getByAddUrl_Riesgo('concentracion',concentracionEntity).subscribe(dataArr => {
        this.DatosBandas(dataArr);
      });
  }
  filtroAnios(arreglo: any){
    var aux =[];
    for(var i = 0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].check == true){
        var nameT = this.DatosAnios.mesesDisponibles[i].name+' '+this.DatosAnios.mesesDisponibles[i].year.substr(2,2);
        for(var j = 0; j<arreglo.length; j++){
          if(arreglo[j].name == nameT){
            aux.push(arreglo[j]);
            break;
          }
        }
      }
    }
    var aux2 = [];
    for(var i = aux.length-1; i>=0; i-- ){
      aux2.push(aux[i]);
    }
    return aux2;
  }
  seleccionarmes(mes: any){
    for(var i = 0; i< this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].name == mes.name && this.DatosAnios.mesesDisponibles[i].year == mes.year){
        this.DatosAnios.mesesDisponibles[i].check = !this.DatosAnios.mesesDisponibles[i].check;
      }
    }
    for(var i = this.DatosAnios.mesesDisponibles.length-1; i>=0; i-- ){
      if(this.DatosAnios.mesesDisponibles[i].check == true){
        this.mes = this.DatosAnios.mesesDisponibles[i].name;
        this.anio = this.DatosAnios.mesesDisponibles[i].year;
        break;
      }
    }
    this.FuncionesDataSource();
  }
  obtTit(titulo: any){
    if(this.Titulos.length ==0){
      this.Titulos.push(titulo);
    }else{
      var ex = 0;
      for(var i = 0; i < this.Titulos.length; i++){
        if(this.Titulos[i] == titulo){
          ex++;
        }
      }
      if(ex == 0){
        this.Titulos.push(titulo);
      }
    }
  }
  Datos(arreglo: any []){
    this.Titulos = [];
    this.DatosTablasec(arreglo);
    var aux = [];
    var j = 0;
    for(var i= 0; i<arreglo.length; i++){
      if(i>0){
        if(arreglo[i].Periodo == arreglo[(i-1)].Periodo){
          aux[(j)].series.push( {name: arreglo[i].Nombre_Elemento, value: arreglo[i].Saldo} );
        }else{
          aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: this.Nombre(arreglo[i].Nombre_Elemento) , value: arreglo[i].Saldo}]});
          j++;
        }
      }else{
        aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: this.Nombre(arreglo[i].Nombre_Elemento) , value: arreglo[i].Saldo}]});
      }
      this.obtTit(arreglo[i].Nombre_Elemento);
    }
    if(!this.filtroNorm){
      aux = this.filtroAnios(aux);
    }
    this.multi = aux;
    aux = [];
    for(var i= this.multi.length-1; i>=0; i--){
      aux.push(this.multi[i]);
    }
    this.multi2 = aux;
    this.lineal = [];
    aux=[];
    var aux2 = []
    j=0;
    for(var i= 0; i<this.multi.length; i++){
      for(var j=0; j<this.multi[i].series.length; j++){
        if(aux2.length == this.multi[i].series.length || i == 0){
          if(i==0){
            aux2.push({name: this.multi[i].series[j].name, series: [{name: this.multi[i].name, value: this.multi[i].series[j].value}]});
          }else{
            aux2[j].series.push({name: this.multi[i].name, value: this.multi[i].series[j].value});
          }
        }else{
          if(j>aux2.length-1){
            aux2.push({name: this.multi[i].series[j].name, series: [{name: this.multi[i].name, value: this.multi[i].series[j].value}]});
          }else{
            aux2[j].series.push({name: this.multi[i].name, value: this.multi[i].series[j].value});
          }
        }
      }
    }
    this.lineal = aux2;
    aux = [];
    aux2 = [];
    for(var i= 0; i<this.lineal.length; i++){
      for(var j= this.lineal[i].series.length-1; j>=0; j--){
        aux.push(this.lineal[i].series[j]);
      }
      aux2.push({name: this.lineal[i].name, series: aux});
      aux=[];
    }
    this.lineal = aux2;
  }
  DatosProducto(arreglo: any []){
    var aux = [];
    var j = 0;
    for(var i= 0; i<arreglo.length; i++){
      if( i>0){
        if(arreglo[i].Periodo == arreglo[(i-1)].Periodo){
          aux[(j)].series.push( {name: arreglo[i].Nombre_Elemento, value: arreglo[i].Saldo, ihh: arreglo[i].IHH} );
        }else{
          aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: arreglo[i].Nombre_Elemento , value: arreglo[i].Saldo, ihh: arreglo[i].IHH}]});
          j++
        }
        }else{
        aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: arreglo[i].Nombre_Elemento , value: arreglo[i].Saldo, ihh: arreglo[i].IHH}]});
      } 
    }
    if(!this.filtroNorm){
      aux = this.filtroAnios(aux);
    }
    this.DatosProduct = aux;
    aux = [];
    for(var i= this.DatosProduct.length-1; i>=0; i--){
      aux.push(this.DatosProduct[i]);
    }
    this.DatosProduct2 = aux;
   this.DatosTabla(arreglo);
  }
  DatosBandas(arreglo: any []){
    var aux = [];
    for(var i= 0; i<arreglo.length; i++){
      aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: arreglo[i].Nombre_Elemento , value: arreglo[i].Saldo}]});
    }
    this.DatosBanda = aux;
    this.data = this.ConvertirGoogleChartPie(aux,this.mes+' '+this.anio.substr(2,2));
    aux = [];
    for(var i=0; i<this.data.length; i++){
      aux.push({name: this.data[i].name, series: [{name: this.data[i].name, value: this.data[i].value}] });
    }
    this.data2 = aux;
  }
  separacion(data: any){
    var aux =  Array.from(data.toString());
    var mes: any;
    var an: any;
    if(aux.length == 5){
      mes = aux[0];
    }else{
      mes = aux[0]+''+aux[1];
    }
    var meslet: any;
    switch(mes){
      case '1': meslet = 'ene'; break;
      case '2': meslet = 'feb'; break;
      case '3': meslet = 'mar'; break;
      case '4': meslet = 'abr'; break;
      case '5': meslet = 'may'; break;
      case '6': meslet = 'jun'; break;
      case '7': meslet = 'jul'; break;
      case '8': meslet = 'ago'; break;
      case '9': meslet = 'sep'; break;
      case '10': meslet = 'oct'; break;
      case '11': meslet = 'nov'; break;
      case '12': meslet = 'dic'; break;
    }
    var anio;
    if(aux.length == 5){
      anio = aux[3]+''+aux[4];//aux[1]+''+aux[2]+''+aux[3]+''+aux[4];
    }else{
      anio = aux[4]+''+aux[5];//aux[2]+''+aux[3]+''+aux[4]+''+aux[5];
    }
    
    meslet+= ' '+anio;
    return meslet;
  }
  Nombre(nombre: any){
    if(nombre == 'Sin Datos'){
      return 'Persona moral';
    }else{
      return nombre;
    }
  }
  DatosTablasec(arreglo: any []){
    var aux = [];
    var j=0;
    for(var i= 0; i<arreglo.length; i++){
      if( i>0){
        if(arreglo[i].Periodo == arreglo[(i-1)].Periodo){
          aux[(j)].series.push( {name: arreglo[i].Nombre_Elemento, value: arreglo[i].Saldo,ihh: arreglo[i].IHH} );
        }else{
          aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: this.Nombre(arreglo[i].Nombre_Elemento) , value: arreglo[i].Saldo,ihh: arreglo[i].IHH}]});
          j++;
        }
        }else{
        aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: this.Nombre(arreglo[i].Nombre_Elemento) , value: arreglo[i].Saldo,ihh: arreglo[i].IHH}]});
      } 
    }
    if(!this.filtroNorm){
      aux = this.filtroAnios(aux);
    }
    var datTab = aux;
    var aux2 = [];
    aux = [];
    for(var i = 0; i < datTab.length;i++){
      for(var j =0; j< datTab[i].series.length; j++){
        aux2.push({name: datTab[i].series[j].name, saldo: datTab[i].series[j].value, ihh: datTab[i].series[j].ihh});
      }
      aux.push({name: datTab[i].name, series: aux2});
      aux2=[];
    }
    this.datTab2 = aux;
    if(this.eslAgrupGen){
      for(var i =0; i< aux.length; i++){
        aux2.push({ periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:', producto1: ''+aux[i].series[0].saldo, producto11: ''+aux[i].series[0].ihh,
        producto2: ''+aux[i].series[1].saldo, producto21:''+aux[i].series[1].ihh,producto3: ''+aux[i].series[2].saldo, producto31:''+aux[i].series[2].ihh});
      }
      this.displayedColumns2 = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3'];
    }
    if(this.eslAgrupEstat){
      for(var i =0; i< aux.length; i++){
        aux2.push({ periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:', producto1: ''+aux[i].series[0].saldo, producto11: ''+aux[i].series[0].ihh,
        producto2: ''+aux[i].series[1].saldo, producto21:''+aux[i].series[1].ihh});
      }
      this.displayedColumns2 = ['periodo', 'servicios',  'producto1',  'producto2'];
    }
    if(this.eslAgrupSec){
      for(var i =0; i< aux.length; i++){
        aux2.push({ periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:', producto1: ''+aux[i].series[0].saldo, producto11: ''+aux[i].series[0].ihh,
        producto2: ''+aux[i].series[1].saldo, producto21:''+aux[i].series[1].ihh,producto3: ''+aux[i].series[2].saldo, producto31:''+aux[i].series[2].ihh,
        producto4: ''+aux[i].series[3].saldo, producto41:''+aux[i].series[3].ihh});
      }
      this.displayedColumns2 = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3',
      'producto4'];
    }
    if(this.eslAgrupGar){
      for(var i =0; i< aux.length; i++){
        aux2.push({ periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:', producto1: ''+aux[i].series[0].saldo, producto11: ''+aux[i].series[0].ihh,
        producto2: ''+aux[i].series[1].saldo, producto21:''+aux[i].series[1].ihh,producto3: ''+aux[i].series[2].saldo, producto31:''+aux[i].series[2].ihh,
        producto4: ''+aux[i].series[3].saldo, producto41:''+aux[i].series[3].ihh,producto5: ''+aux[i].series[4].saldo, producto51:''+aux[i].series[4].ihh,
        producto6: ''+aux[i].series[5].saldo, producto61:''+aux[i].series[5].ihh});
      }
      this.displayedColumns2 = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3',
      'producto4', 'producto5', 'producto6'];
    }
    if(this.eslAgrupZon){
      for(var i =0; i< aux.length; i++){
        aux2.push({ periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:', producto1: ''+aux[i].series[0].saldo, producto11: ''+aux[i].series[0].ihh,
        producto2: ''+aux[i].series[1].saldo, producto21:''+aux[i].series[1].ihh,producto3: ''+aux[i].series[2].saldo, producto31:''+aux[i].series[2].ihh,
        producto4: ''+aux[i].series[3].saldo, producto41:''+aux[i].series[3].ihh,producto5: ''+aux[i].series[4].saldo, producto51:''+aux[i].series[4].ihh,
        producto6: ''+aux[i].series[5].saldo, producto61:''+aux[i].series[5].ihh, producto7: ''+aux[i].series[0].saldo, producto71: ''+aux[i].series[0].ihh,
        producto8: ''+aux[i].series[1].saldo, producto81:''+aux[i].series[1].ihh,producto9: ''+aux[i].series[2].saldo, producto91:''+aux[i].series[2].ihh,
        producto10: ''+aux[i].series[3].saldo, producto101:''+aux[i].series[3].ihh,producto110: ''+aux[i].series[4].saldo, producto111:''+aux[i].series[4].ihh,
        producto12: ''+aux[i].series[5].saldo, producto121:''+aux[i].series[5].ihh});
      }
      this.displayedColumns2 = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3',
      'producto4','producto5','producto6','producto7',  'producto8',  'producto9',
      'producto10','producto11','producto12'];
    }
    this.dataSource2 = aux2;
  }
  DatosTabla(arreglo: any []){
    var aux = [];
    var j=0;
    for(var i= 0; i<arreglo.length; i++){
      if( i>0){
        if(arreglo[i].Periodo == arreglo[(i-1)].Periodo){
          aux[(j)].series.push( {name: arreglo[i].Nombre_Elemento, value: arreglo[i].Saldo,ihh: arreglo[i].IHH} );
        }else{
          aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: arreglo[i].Nombre_Elemento , value: arreglo[i].Saldo,ihh: arreglo[i].IHH}]});
          j++;
        }
        }else{
        aux.push({name: ''+this.separacion(arreglo[i].Periodo), series: [{ name: arreglo[i].Nombre_Elemento , value: arreglo[i].Saldo,ihh: arreglo[i].IHH}]});
      } 
    }
    if(!this.filtroNorm){
      aux = this.filtroAnios(aux);
    }
    var datTab = aux;
    var aux2 = [];
    aux = [];
    for(var i = 0; i < datTab.length;i++){
      for(var j =0; j< datTab[i].series.length; j++){
        aux2.push({name: datTab[i].series[j].name, saldo: datTab[i].series[j].value, ihh: datTab[i].series[j].ihh});
      }
      aux.push({name: datTab[i].name, series: aux2});
      aux2=[];
    }
    this.datTab1 = aux;
    if(this.eslAgrupPro){
      for(var i =0; i< aux.length; i++){
        if(aux[i].series.length == 6){
          aux2.push({ periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:', producto1: ''+aux[i].series[0].saldo, producto11: ''+aux[i].series[0].ihh,
          producto2: ''+aux[i].series[1].saldo, producto21:''+aux[i].series[1].ihh,producto3: ''+aux[i].series[2].saldo, producto31:''+aux[i].series[2].ihh,
          producto4: ''+aux[i].series[3].saldo, producto41:''+aux[i].series[3].ihh,producto5: ''+aux[i].series[4].saldo, producto51:''+aux[i].series[4].ihh,
          producto6: ''+aux[i].series[5].saldo, producto61:''+aux[i].series[5].ihh});
        }else{
          aux2.push({ periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:', producto1: ''+aux[i].series[0].saldo, producto11: ''+aux[i].series[0].ihh,
          producto2: ''+aux[i].series[1].saldo, producto21:''+aux[i].series[1].ihh,producto3: ''+aux[i].series[2].saldo, producto31:''+aux[i].series[2].ihh,
          producto4: ''+aux[i].series[3].saldo, producto41:''+aux[i].series[3].ihh,producto5: ''+aux[i].series[4].saldo, producto51:''+aux[i].series[4].ihh,
          producto6: ''+0, producto61:''+0});
        }
      }
      this.displayedColumns = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3',
  'producto4', 'producto5', 'producto6'];
    }else{
      var aux3 = [];
      for(var i =0; i< aux.length; i++){
        aux3.push({ name: 1, series: [{periodo: aux[i].name, servicios: 'Saldo:', servicios1: 'IHH:'}]});
        for(var j = 0; j < aux[i].series.length; j++){
          switch(aux[i].series[j].name){
            case 'A-1': aux3[i].series.push({producto1: ''+aux[i].series[j].saldo, producto11: ''+aux[i].series[j].ihh}); break;
            case 'A-2': aux3[i].series.push({producto2: ''+aux[i].series[j].saldo, producto21: ''+aux[i].series[j].ihh}); break;
            case 'B-1': aux3[i].series.push({producto3: ''+aux[i].series[j].saldo, producto31: ''+aux[i].series[j].ihh}); break;
            case 'B-2': aux3[i].series.push({producto4: ''+aux[i].series[j].saldo, producto41: ''+aux[i].series[j].ihh}); break;
            case 'B-3': aux3[i].series.push({producto5: ''+aux[i].series[j].saldo, producto51: ''+aux[i].series[j].ihh}); break;
            case 'C-1': aux3[i].series.push({producto6: ''+aux[i].series[j].saldo, producto61: ''+aux[i].series[j].ihh}); break;
            case 'E'  : aux3[i].series.push({producto7: ''+aux[i].series[j].saldo, producto71: ''+aux[i].series[j].ihh}); break;
            case 'D'  : aux3[i].series.push({producto8: ''+aux[i].series[j].saldo, producto81: ''+aux[i].series[j].ihh}); break;
          }
        }
        if(aux3[i].series.length == 8){
          aux2.push(Object.assign(aux3[i].series[0],aux3[i].series[1],aux3[i].series[2],aux3[i].series[3],aux3[i].series[4],aux3[i].series[5],aux3[i].series[6],aux3[i].series[7]));
        }
        if(aux3[i].series.length == 7){
          aux2.push(Object.assign(aux3[i].series[0],aux3[i].series[1],aux3[i].series[2],aux3[i].series[3],aux3[i].series[4],aux3[i].series[5],aux3[i].series[6]));
        }
        if(aux3[i].series.length == 6){
          aux2.push(Object.assign(aux3[i].series[0],aux3[i].series[1],aux3[i].series[2],aux3[i].series[3],aux3[i].series[4],aux3[i].series[5]));
        }
      }
      this.displayedColumns = ['periodo', 'servicios',  'producto1',  'producto2',  'producto3',
  'producto4', 'producto5', 'producto6', 'producto7','producto8'];
    }
    this.dataSource = aux2;
  }
  dato(num : any){
    if(num == undefined){
      return 0;
    }else{
      return num;
    }
  }
  perspectiva(agrup: any){
    this.elemagrup = agrup;
    this.FuncionesDataSource();
    if(this.elemagrup == 'Sector'){
      this.eslAgrupSec = true;
      this.eslAgrupZon = false;
      this.eslAgrupGen = false;
      this.eslAgrupEstat = false;
      this.eslAgrupGar = false;
    }
    if(this.elemagrup == 'Zona'){
      this.eslAgrupSec = false;
      this.eslAgrupZon = true;
      this.eslAgrupGen = false;
      this.eslAgrupEstat = false;
      this.eslAgrupGar = false;
    }
    if(this.elemagrup == 'Genero'){
      this.eslAgrupSec = false;
      this.eslAgrupZon = false;
      this.eslAgrupGen = true;
      this.eslAgrupEstat = false;
      this.eslAgrupGar = false;
    }
    if(this.elemagrup == 'EstatusCredito'){
      this.eslAgrupSec = false;
      this.eslAgrupZon = false;
      this.eslAgrupGen = false;
      this.eslAgrupEstat = true;
      this.eslAgrupGar = false;
    }
    if(this.elemagrup == 'Garantia'){
      this.eslAgrupSec = false;
      this.eslAgrupZon = false;
      this.eslAgrupGen = false;
      this.eslAgrupEstat = false;
      this.eslAgrupGar = true;
    }
  }
  perspectiva2(agrup: any){
    this.elemagrup2 = agrup;
    if(this.elemagrup2 == 'Producto'){
      this.eslAgrupPro = true;
      this.eslAgrupBan = false; 
    }else{
      this.eslAgrupPro = false;
      this.eslAgrupBan = true;
    }
    this.FuncionesDataSource();
  }
  prepararDatos(datos) {
    datos = datos.split("\n").join("<br />");
    return datos;
  }
  ConvertirGoogleChartPie(arreglo: any,mes: any){
    var suma = 0;
    for(var i = 0; i < arreglo.length;i++){
      suma += arreglo[i].value;
    }
    var aux = [];
    this.data = [];
    for(var u = 0; u < arreglo.length;u++){
      if(arreglo[u].name == mes){
        aux.push({name: arreglo[u].series[0].name, value: arreglo[u].series[0].value});
      }
    }
    this.data = aux;
    aux = [];
    return this.data;
  }
  // **** Funciones de Filtros ****
  getDatos(){
    this.aniosDisponibles = this.DatosAnios.aniosDisponibles;
    var aux = [];
    var pos;
    for(var i = 0; i < this.aniosDisponibles.length; i ++){
      if(this.aniosDisponibles[i].check == true){
        pos = i;
      }
    }
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i])
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
  }
  /* funciones para los filtros */
  obtmes(anio: any){
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == anio.value){
        this.aniosDisponibles[i].check = !this.aniosDisponibles[i].check;
      }
    }
  }
  cambioFiltro(){
    if(this.filtroNorm){
      this.filtroNorm = false;
      for(var i = this.DatosAnios.mesesDisponibles.length-1; i>this.DatosAnios.mesesDisponibles.length-12; i--){
        this.DatosAnios.mesesDisponibles[i].check = true;
      }
      for(var i = 0; i<this.DatosAnios.aniosDisponibles.length; i++){
        this.DatosAnios.aniosDisponibles[i].check = false;
      }
      this.nperiodos = this.DatosAnios.mesesDisponibles.length;
      this.periodo = Number(this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].value);
    }else{
      for(var i = 0; i<this.DatosAnios.aniosDisponibles.length; i++){
        this.DatosAnios.aniosDisponibles[i].check = false;
      }
      this.filtroNorm = true;
      for(var i = 0; i<this.DatosAnios.mesesDisponibles.length; i++){
        this.DatosAnios.mesesDisponibles[i].check = false;
      }
      this.DatosAnios.aniosDisponibles[this.DatosAnios.aniosDisponibles.length-1].check = true;
      this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].check = true;
      this.periodo = Number(this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].value);
      this.mes = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].name;
      this.anio = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].year;
      this.nperiodos = 12;
    }
    this.FuncionesDataSource();
  }
  anioEsc(anio: any){
    return anio[2]+''+anio[3];
  }
  //AÑOS
  public anioChange(anio: any){
    var pos = 0;
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == anio.value){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i])
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
    this.mes = this.mesesDisponibles[this.mesesDisponibles.length-1].name;
    this.anio = this.aniosDisponibles[pos].name;
    this.periodo = Number(this.mesesDisponibles[this.mesesDisponibles.length-1].value);
    this.FuncionesDataSource();
  }
  public mesChange(mes: any){
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false
    }
    var pos =0;
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      if(this.mesesDisponibles[i].name == mes.name){
        this.mesesDisponibles[i].check = true;
        pos = i;
      }
    }
    this.mes = this.mesesDisponibles[pos].name;
    this.periodo = Number(this.mesesDisponibles[pos].value);
    this.FuncionesDataSource();
  }
  quitar_filtros(){
    var pos = 0;
    this.DatosAnios.mesesDisponibles.forEach(mes => mes.check=false);
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == '2022'){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i])
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
    this.mes = this.mesesDisponibles[this.mesesDisponibles.length-1].name;
    this.anio = this.aniosDisponibles[pos].name;

    this.graficaBarrasP2= true;
    this.graficaLinearP2= false;
    this.graficaAreaP2 = false;
    this.gtablaP = false;

    this.grafBarrasver = false;
    this.grafBarrashor = true;
    this.gtablas = false;
    this.grafBarrasverModal = false;
    this.grafBarrashorModal = false;
    this.gtablasModal = false;

    this.graficaPie2 = true;
    this.graficaCalor2 = false;
    this.graficaBarras2 = false;
    this.graficaPie2Modal = false;
    this.graficaCalor2Modal = false;
    this.graficaBarras2Modal = false;

    this.elemagrup = 'Sector'
    this.eslAgrupSec = true;
    this.eslAgrupZon = false;
    this.elemagrup2 = 'Producto'
    this.eslAgrupPro = true;
    this.eslAgrupBan = false;

    this.miles1 = true;
    this.miles2 = true;
    this.milTab1 = '(Miles)';
    this.milTab2 = '(Miles)';
    this.filtroNorm = true;
    this.nperiodos = 12;
    this.periodo = Number(this.mesesDisponibles[this.mesesDisponibles.length-1].value);
    this.FuncionesDataSource();
  }
  mesAbrev(mes: any){
    switch(mes){
      case 'ene': return 'ene'; 
      case 'feb': return 'feb'; 
      case 'mar': return 'mar'; 
      case 'abr': return 'abr'; 
      case 'may': return 'may'; 
      case 'jun': return 'jun'; 
      case 'jul': return 'jul'; 
      case 'ago': return 'ago'; 
      case 'sep': return 'sep'; 
      case 'oct': return 'oct'; 
      case 'nov': return 'nov'; 
      case 'dic': return 'dic';
      default : return '';
    }
  }
  actsubtitulos(){
    this.TituloGrafPrinc = ' Concentración por '+this.elemagrup;
    this.TituloGrafSec = ' Concentración por '+this.elemagrup2;
    this.subTituloGrafPrinc = this.anio.substring(2,4) + ' | '  + this.mes +' | matriz';
    this.subTituloGrafSec = this.anio.substring(2,4) + ' | '  + this.mes +' | matriz';
    this.subTituloGrafTer = this.anio.substring(2,4) + ' | '  + this.mes +' | matriz';
  }
  // **** Función de formateo de numeros ****
  formatearnormal2(num: any){
    num = Number(num).toFixed(2);
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft +splitRight;
  }
  formatearnormal(num: any, miles: any){
    if(miles == true)
      num = (num/1000).toFixed(2)
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
      return  splitLeft;
  }
  desForm(num: any){
    if(num.includes(',')){  
      var pos = num.indexOf(',');
      var aux = num.slice(0,pos)+num.slice(pos+1);
      num = aux
      while(num.includes(',')){
        var pos = num.indexOf(',');
        var aux = num.slice(0,pos)+num.slice(pos+1);
        num = aux;
      }
    }
    num = Number(num);
    return num;
  }
  CambMon( moneda: any, tab: any ){
    if(moneda == 'Mil' && tab == 1){
      this.miles1 = true;
      this.milTab1 = '(Miles)';
    }
    if(moneda == 'Mil' && tab == 2){
      this.miles2 = true;
      this.milTab2 = '(Miles)';
    }
    if(moneda == 'Pesos' && tab == 1 ){
      this.miles1 = false;
      this.milTab1 = '';
    }
    if(moneda == 'Pesos' && tab == 2 ){
      this.miles2 = false;
      this.milTab2 = '';
    }
    this.FuncionesDataSource();
  }
  // **** Exportar ****
  export_csv(tab: any){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    var encabezado_excel = [];
    if(tab == 1){
      if(this.eslAgrupSec){
        encabezado_excel = ['Periodo','',this.Titulos[0],this.Titulos[1],this.Titulos[2],this.Titulos[3]];
        var cadena_evaluacion = this.datTab2[0].series;
        for(var i = 0; i < this.dataSource2.length; i++ ){
          aux.push(this.dataSource2[i].periodo);
          aux.push(this.dataSource2[i].servicios);
          aux.push(this.desForm(this.dataSource2[i].producto1));
          aux.push(this.desForm(this.dataSource2[i].producto2));
          aux.push(this.desForm(this.dataSource2[i].producto3));
          aux.push(this.desForm(this.dataSource2[i].producto4));
          data_excel.push(aux);
          aux = [];
          aux.push('');
          aux.push(this.dataSource2[i].servicios1);
          aux.push(this.desForm(this.dataSource2[i].producto11));
          aux.push(this.desForm(this.dataSource2[i].producto21));
          aux.push(this.desForm(this.dataSource2[i].producto31));
          aux.push(this.desForm(this.dataSource2[i].producto41));
          data_excel.push(aux);
          aux = [];
        }
      }
      if(this.eslAgrupGen){
        encabezado_excel = ['Periodo','',this.Titulos[0],this.Titulos[1],this.Titulos[2]];
        var cadena_evaluacion = this.datTab2[0].series;
        for(var i = 0; i < this.dataSource2.length; i++ ){
          aux.push(this.dataSource2[i].periodo);
          aux.push(this.dataSource2[i].servicios);
          aux.push(this.desForm(this.dataSource2[i].producto1));
          aux.push(this.desForm(this.dataSource2[i].producto2));
          aux.push(this.desForm(this.dataSource2[i].producto3));
          data_excel.push(aux);
          aux = [];
          aux.push('');
          aux.push(this.dataSource2[i].servicios1);
          aux.push(this.desForm(this.dataSource2[i].producto11));
          aux.push(this.desForm(this.dataSource2[i].producto21));
          aux.push(this.desForm(this.dataSource2[i].producto31));
          data_excel.push(aux);
          aux = [];
        }
      }
      if(this.eslAgrupEstat){
        encabezado_excel = ['Periodo','',this.Titulos[0],this.Titulos[1]];
        var cadena_evaluacion = this.datTab2[0].series;
        for(var i = 0; i < this.dataSource2.length; i++ ){
          aux.push(this.dataSource2[i].periodo);
          aux.push(this.dataSource2[i].servicios);
          aux.push(this.desForm(this.dataSource2[i].producto1));
          aux.push(this.desForm(this.dataSource2[i].producto2));
          data_excel.push(aux);
          aux = [];
          aux.push('');
          aux.push(this.dataSource2[i].servicios1);
          aux.push(this.desForm(this.dataSource2[i].producto11));
          aux.push(this.desForm(this.dataSource2[i].producto21));
          data_excel.push(aux);
          aux = [];
        }
      }
      if(this.eslAgrupZon){
        encabezado_excel = ['Periodo','',this.Titulos[0],this.Titulos[1],this.Titulos[2],this.Titulos[3],this.Titulos[4],this.Titulos[5],
        this.Titulos[6],this.Titulos[7],this.Titulos[8],this.Titulos[9],this.Titulos[10],this.Titulos[11]];
        var cadena_evaluacion = this.datTab2[0].series;
        for(var i = 0; i < this.dataSource2.length; i++ ){
          aux.push(this.dataSource2[i].periodo);
          aux.push(this.dataSource2[i].servicios);
          aux.push(this.desForm(this.dataSource2[i].producto1));
          aux.push(this.desForm(this.dataSource2[i].producto2));
          aux.push(this.desForm(this.dataSource2[i].producto3));
          aux.push(this.desForm(this.dataSource2[i].producto4));
          aux.push(this.desForm(this.dataSource2[i].producto5));
          aux.push(this.desForm(this.dataSource2[i].producto6));
          aux.push(this.desForm(this.dataSource2[i].producto7));
          aux.push(this.desForm(this.dataSource2[i].producto8));
          aux.push(this.desForm(this.dataSource2[i].producto9));
          aux.push(this.desForm(this.dataSource2[i].producto10));
          aux.push(this.desForm(this.dataSource2[i].producto110));
          aux.push(this.desForm(this.dataSource2[i].producto12));
          data_excel.push(aux);
          aux = [];
          aux.push('');
          aux.push(this.dataSource2[i].servicios1);
          aux.push(this.desForm(this.dataSource2[i].producto11));
          aux.push(this.desForm(this.dataSource2[i].producto21));
          aux.push(this.desForm(this.dataSource2[i].producto31));
          aux.push(this.desForm(this.dataSource2[i].producto41));
          aux.push(this.desForm(this.dataSource2[i].producto51));
          aux.push(this.desForm(this.dataSource2[i].producto61));
          aux.push(this.desForm(this.dataSource2[i].producto71));
          aux.push(this.desForm(this.dataSource2[i].producto81));
          aux.push(this.desForm(this.dataSource2[i].producto91));
          aux.push(this.desForm(this.dataSource2[i].producto101));
          aux.push(this.desForm(this.dataSource2[i].producto111));
          aux.push(this.desForm(this.dataSource2[i].producto121));
          data_excel.push(aux);
          aux = [];
        }
      }
      if(this.eslAgrupGar){
        encabezado_excel = ['Periodo','',this.Titulos[0],this.Titulos[1],this.Titulos[2],this.Titulos[3],this.Titulos[4],this.Titulos[5],
        this.Titulos[6]];
        var cadena_evaluacion = this.datTab2[0].series;
        for(var i = 0; i < this.dataSource2.length; i++ ){
          aux.push(this.dataSource2[i].periodo);
          aux.push(this.dataSource2[i].servicios);
          aux.push(this.desForm(this.dataSource2[i].producto1));
          aux.push(this.desForm(this.dataSource2[i].producto2));
          aux.push(this.desForm(this.dataSource2[i].producto3));
          aux.push(this.desForm(this.dataSource2[i].producto4));
          aux.push(this.desForm(this.dataSource2[i].producto5));
          aux.push(this.desForm(this.dataSource2[i].producto6));
          data_excel.push(aux);
          aux = [];
          aux.push('');
          aux.push(this.dataSource2[i].servicios1);
          aux.push(this.desForm(this.dataSource2[i].producto11));
          aux.push(this.desForm(this.dataSource2[i].producto21));
          aux.push(this.desForm(this.dataSource2[i].producto31));
          aux.push(this.desForm(this.dataSource2[i].producto41));
          aux.push(this.desForm(this.dataSource2[i].producto51));
          aux.push(this.desForm(this.dataSource2[i].producto61));
          data_excel.push(aux);
          aux = [];
        }
      } 
      name = this.elemagrup;
    }else{
      if(this.eslAgrupPro){
        encabezado_excel = ['Periodo','','C SIMPLE','C CUENTA CORRIENTE','C QUIROGRAFARIO','A FINANCIERO','C REFACCIONARIO','C AVIO'];
        var cadena_evaluacion = this.datTab1[0].series;
        for(var i = 0; i < this.dataSource.length; i++ ){
          aux.push(this.dataSource[i].periodo);
          aux.push(this.dataSource[i].servicios);
          aux.push(this.desForm(this.dataSource[i].producto1));
          aux.push(this.desForm(this.dataSource[i].producto2));
          aux.push(this.desForm(this.dataSource[i].producto3));
          aux.push(this.desForm(this.dataSource[i].producto4));
          aux.push(this.desForm(this.dataSource[i].producto5));
          aux.push(this.desForm(this.dataSource[i].producto6));
          data_excel.push(aux);
          aux = [];
          aux.push('');
          aux.push(this.dataSource[i].servicios1);
          aux.push(this.desForm(this.dataSource[i].producto11));
          aux.push(this.desForm(this.dataSource[i].producto21));
          aux.push(this.desForm(this.dataSource[i].producto31));
          aux.push(this.desForm(this.dataSource[i].producto41));
          aux.push(this.desForm(this.dataSource[i].producto51));
          aux.push(this.desForm(this.dataSource[i].producto61));
          data_excel.push(aux);
          aux = [];
        }
      }
      else{
        encabezado_excel = ['Periodo','',this.datTab1[0].series[0].name,'A-2','B-1','B-2','B-3','C-1','E','D'];
        var cadena_evaluacion = this.datTab1[0].series;
        for(var i = 0; i < this.dataSource.length; i++ ){
          aux.push(this.dataSource[i].periodo);
          aux.push(this.dataSource[i].servicios);
          if(this.dataSource[i].producto1 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto1));
          else
            aux.push(0);
          if(this.dataSource[i].producto2 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto2));
          else
            aux.push(0);
          if(this.dataSource[i].producto3 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto3));
          else
            aux.push(0);
          if(this.dataSource[i].producto4 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto4));
          else
            aux.push(0);
          if(this.dataSource[i].producto5 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto5));
          else
            aux.push(0);
          if(this.dataSource[i].producto6 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto6));
          else
            aux.push(0);
          if(this.dataSource[i].producto7 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto7));
          else
            aux.push(0);
          if(this.dataSource[i].producto8 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto8));
          else
            aux.push(0);
          data_excel.push(aux);
          aux = [];
          aux.push('');
          aux.push(this.dataSource[i].servicios1);
          if(this.dataSource[i].producto11 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto11));
          else
            aux.push(0);
          if(this.dataSource[i].producto21 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto21));
          else
            aux.push(0);
          if(this.dataSource[i].producto31 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto31));
          else
            aux.push(0);
          if(this.dataSource[i].producto41 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto41));
          else
            aux.push(0);
          if(this.dataSource[i].producto51 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto51));
          else
            aux.push(0);
          if(this.dataSource[i].producto61 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto61));
          else
            aux.push(0);
          if(this.dataSource[i].producto71 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto71));
          else
            aux.push(0);
          if(this.dataSource[i].producto81 != undefined)
            aux.push(this.desForm(this.dataSource[i].producto81));
          else
            aux.push(0);
          data_excel.push(aux);
          aux = [];
        }
      }
      name = this.elemagrup2;
    }
    this.excelservices.generateExcelConcentracion(encabezado_excel,data_excel,cadena_evaluacion.length,name);
    
  }
  export_img(zon: any){
    var grafica: string = "";
    var name: string = "";
    if(zon == 1){
        grafica = "Zona1";
        if(this.graficaBarrasP2)
          name = 'GraficaBrarras';
        if(this.graficaLinearP2) 
          name = 'GraficaLineal';
        if(this.graficaAreaP2)
          name = 'GraficaArea';
    }
    if(zon == 2){
      grafica = "Zona2";
      if(this.grafBarrasver)
          name = 'GraficaBrrasVer';
      if(this.grafBarrashor)
        name = 'GraficaBarrasHor';
    }
    if(zon == 3){
      grafica = "Zona3";
      if(this.graficaPie2)
        name = 'GraficaPie';
      if(this.graficaCalor2) 
        name = 'GraficaCalor';
      if(this.graficaBarras2)
        name = 'GraficaBarras';
    }
    PDF.exportimagenes(grafica,name);
  }
  // **** Funciones de Activación de graficas normales y Ampliadas
  //Segunda pagina
  gbarrasP2(){
    this.graficaBarrasP2 = true;
    this.graficaLinearP2 = false;
    this.graficaAreaP2 = false;
    this.gtablaP = false;
  }
  glinealP2(){
    this.graficaBarrasP2 = false;
    this.graficaLinearP2 = true;
    this.graficaAreaP2 = false;
    this.gtablaP = false;
  }
  gareaP2(){
    this.graficaBarrasP2 = false;
    this.graficaLinearP2 = false;
    this.graficaAreaP2 = true;
    this.gtablaP = false;
  }
  gtablaP2(){
    this.graficaBarrasP2 = false;
    this.graficaLinearP2 = false;
    this.graficaAreaP2 = false;
    this.gtablaP = true;
  }
  gbarrasver(){
    this.grafBarrasver = true;
    this.grafBarrashor = false;
    this.gtablas = false;
  }
  gbarrashor(){
    this.grafBarrasver = false;
    this.grafBarrashor = true;
    this.gtablas = false;
  }
  gTablas(){
    this.grafBarrasver = false;
    this.grafBarrashor = false;
    this.gtablas = true;
  }
  gpie2(){
    this.graficaPie2 = true;
    this.graficaCalor2 = false;
    this.graficaBarras2 = false;
  }
  gcalor2(){
    this.graficaPie2 = false;
    this.graficaCalor2 = true;
    this.graficaBarras2 = false;
  }
  gbarras2(){
    this.graficaPie2 = false;
    this.graficaCalor2 = false;
    this.graficaBarras2 = true;
  }
  ampliar(){
    this.Title = this.TituloGrafPrinc;
    this.Subtitulo_grafica = this.subTituloGrafPrinc;
      if(this.graficaBarrasP2){
        this.graficaBarrasP2Modal= true;
        this.graficaLinearP2Modal= false;
        this.graficaAreaP2Modal= false;
        this.gtablaPModal = false;
        this.grafBarrasverModal = false;
        this.grafBarrashorModal = false;
        this.gtablasModal = false;
        this.graficaPie2Modal = false;
        this.graficaCalor2Modal = false;
        this.graficaBarras2Modal = false;
      }
      if(this.graficaLinearP2){
        this.graficaBarrasP2Modal= false;
        this.graficaLinearP2Modal= true;
        this.graficaAreaP2Modal= false;
        this.gtablaPModal = false;
        this.grafBarrasverModal = false;
        this.grafBarrashorModal = false;
        this.gtablasModal = false;
        this.graficaPie2Modal = false;
        this.graficaCalor2Modal = false;
        this.graficaBarras2Modal = false;
      }
      if(this.graficaAreaP2){
        this.graficaBarrasP2Modal= false;
        this.graficaLinearP2Modal= false;
        this.graficaAreaP2Modal = true;
        this.gtablaPModal = false;
        this.grafBarrasverModal = false;
        this.grafBarrashorModal = false;
        this.gtablasModal = false;
        this.graficaPie2Modal = false;
        this.graficaCalor2Modal = false;
        this.graficaBarras2Modal = false;
      }
      if(this.gtablaP){
        this.graficaBarrasP2Modal= false;
        this.graficaLinearP2Modal= false;
        this.graficaAreaP2Modal = false;
        this.gtablaPModal = true;
        this.grafBarrasverModal = false;
        this.grafBarrashorModal = false;
        this.gtablasModal = false;
        this.graficaPie2Modal = false;
        this.graficaCalor2Modal = false;
        this.graficaBarras2Modal = false;
      }
  }
  ampliarG2(){
    this.Title = this.TituloGrafSec;
    this.Subtitulo_grafica = this.subTituloGrafSec;
    if(this.grafBarrasver){
      this.graficaBarrasP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.graficaAreaP2Modal= false;
      this.gtablaPModal = false;
      this.grafBarrasverModal = true;
      this.grafBarrashorModal = false;
      this.gtablasModal = false;
      this.graficaPie2Modal = false;
      this.graficaCalor2Modal = false;
      this.graficaBarras2Modal = false;
    }
    if(this.grafBarrashor){
      this.graficaBarrasP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.gtablaPModal = false;
      this.grafBarrasverModal = false;
      this.grafBarrashorModal = true;
      this.gtablasModal = false;
      this.graficaPie2Modal = false;
      this.graficaCalor2Modal = false;
      this.graficaBarras2Modal = false;
    }
    if(this.gtablas){
      this.graficaBarrasP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.gtablaPModal = false;
      this.grafBarrasverModal = false;
      this.grafBarrashorModal = false;
      this.gtablasModal = true;
      this.graficaPie2Modal = false;
      this.graficaCalor2Modal = false;
      this.graficaBarras2Modal = false;
    }
  }
  ampliarG3(){
    this.Title = this.TituloGrafTer;
    this.Subtitulo_grafica = this.subTituloGrafTer;
    if(this.graficaPie2){
      this.graficaBarrasP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.gtablaPModal = false;
      this.grafBarrasverModal = false;
      this.grafBarrashorModal = false;
      this.gtablasModal = false;
      this.graficaPie2Modal = true;
      this.graficaCalor2Modal = false;
      this.graficaBarras2Modal = false;
    }
    if(this.graficaCalor2){
      this.graficaBarrasP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.gtablaPModal = false;
      this.grafBarrasverModal = false;
      this.grafBarrashorModal = false;
      this.gtablasModal = false;
      this.graficaPie2Modal = false;
      this.graficaCalor2Modal = true;
      this.graficaBarras2Modal = false;
    }
    if(this.graficaBarras2){
      this.graficaBarrasP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.graficaLinearP2Modal= false;
      this.gtablaPModal = false;
      this.grafBarrasverModal = false;
      this.grafBarrashorModal = false;
      this.gtablasModal = false;
      this.graficaPie2Modal = false;
      this.graficaCalor2Modal = false;
      this.graficaBarras2Modal = true;
    }
  }
  cerrarAmp(){
    this.graficaBarrasP2Modal= false;
    this.graficaLinearP2Modal= false;
    this.graficaLinearP2Modal= false;
    this.gtablaPModal = false;
    this.grafBarrasverModal = false;
    this.grafBarrashorModal = false;
    this.gtablasModal = false;
    this.graficaPie2Modal = false;
    this.graficaCalor2Modal = false;
    this.graficaBarras2Modal = false;
  }
  popChange(){
    this.pop = !this.pop;
  }
  public aniosSelec(){
    var i = 0;
    let element = document.getElementById('contenedor_btnanios')
    this.DatosAnios.mesesDisponibles.forEach( an => { if(an.check){ i++; }});
    if(element.offsetWidth > 600)
      return i>7
    return i>6
  }
}