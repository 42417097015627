<div class="w3-container w3-contenedor-template " style="height: 7vh;">
    <div class="w3-row ">
        <div class="w3-quarter padreizquierda">
            <div class="w3-row hijoizquierda">
                <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
                <span class="w3-text-deep-orange">&bull;</span>
                <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
            </div>
        </div>

        <!-- BORRAR FILTROS-->
        <div class="w3-rest">
            <div class="w3-right">
                <button mat-icon-button (click)="quitar_filtros()">
                    <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                        <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
                    </mat-icon>
                </button>
            </div>
        </div>
        <!-- FIN BORRAR FILTROS-->

    </div>
</div>

<!-- Inicia Template 1 -->
<div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">

    <div class="w3-row w3-contenedor-template " style="height: 41vh;">
        <div class="w3-threequarter w3-white" style="height: 100%;">
            <div class="w3-row">
                <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                        <span class="w3-margin-left-contenido">Cuentas / Sucursal</span>
                        <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                        <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>

                <div class="w3-third w3-right-align_rest ">
                    <div class="w3-right hijoderechaTitle">
                        <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"
                            [matMenuTriggerFor]="menu">
                            more_vert</mat-icon>
                    </div>
                    <mat-menu #menu="matMenu">
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                            <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                            <span>Gráficas</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button *ngIf="flag_perspectivas" class="mat-menu-item " mat-menu-item
                            [matMenuTriggerFor]="perspectivas">
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
                            </mat-icon>
                            <span>Perspectiva</span>
                        </button>
                        <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
                            </mat-icon>
                            <span>Exportar</span>
                        </button>
                        <button (click)="modal('id')" class="mat-menu-item" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                            <span>Ampliar</span>
                        </button>
                        <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0"
                            (click)="quitar_filtros()" class="mat-menu-item " mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"><img style="margin-top: -10px;"
                                    src="assets/img/graficos/sinfiltro.png"></mat-icon>
                            <span>Eliminar Filtros</span>
                        </button>
                        <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0"
                            (click)="quitar_filtros()" mat-menu-item>
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                                arrow_back
                            </mat-icon>
                            <span>Regresar</span>
                        </button>
                        <!--<button *ngIf="mostrandomeses" class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="cambiarmes">
                        <span>Cambiar mes</span>
                        </button>-->
                    </mat-menu>
                    <mat-menu #graficas="matMenu">
                        <button (click)="cambiarGrafica('barras')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                            <span>Barras</span>
                        </button>
                        <button (click)="cambiarGrafica('lineal')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                            <span>Lineal</span>
                        </button>
                        <button (click)="cambiarGrafica('pastel')" mat-menu-item>
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                            <span>Pastel</span>
                        </button>
                        <button (click)="cambiarGrafica('tabla')" mat-menu-item>
                            <mat-icon><img style="margin-top: -15px;" src="assets/img/elementotemplate/table.png">
                            </mat-icon>
                            <span>Tabla</span>
                        </button>
                    </mat-menu>
                    <mat-menu #exportar="matMenu">
                    </mat-menu>
                    <mat-menu #perspectivas="matMenu">
                        <button *ngIf="isNotActiveFilter('rangoedad')" (click)="addPerspectiva('rangoedad')" mat-menu-item>
                            <span>Rango de edad</span>
                        </button>
                        <button *ngIf="isNotActiveFilter('ciudades')" (click)="addPerspectiva('Ciudad')" mat-menu-item>
                            <span>Ciudades</span>
                        </button>
                        <button *ngIf="isNotActiveFilter('departamentos')" (click)="addPerspectiva('Departamento')" mat-menu-item>
                            <span>Departamentos</span>
                        </button>
                        <button *ngIf="isNotActiveFilter('genero_Desc')" (click)="addPerspectiva('genero_Desc')" mat-menu-item>
                            <span>Género</span>
                        </button>
                        <button *ngIf="isNotActiveFilter('escolaridad_Desc')" (click)="addPerspectiva('escolaridad_Desc')" mat-menu-item>
                            <span>Escolaridad</span>
                        </button>
                        <button *ngIf="isNotActiveFilter('actividad')" (click)="addPerspectiva('actividad')" mat-menu-item>
                            <span>Actividad</span>
                        </button>
                    </mat-menu>
                    <mat-menu #geografico="matMenu">
                        <button class="" (click)="addPerspectiva('geografico_estado')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                            <span>Estado </span>
                        </button>
                        <button class="" (click)="addPerspectiva('geografico_municipio')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                            <span>Municipio</span>
                        </button>
                        <button class="" (click)="addPerspectiva('geografico_localidad')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                            <span>Localidad</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="w3-row" style="height: 25vh;width: 100%;">
                    <div class="chart_wrap w3-scroll">
                        <ngx-charts-bar-vertical id="chartvertical"
                            class="bar-half-google w3-labels-ngx-charts"
                            style="width: 95%;height: 20vh;"
                            [scheme]="colorScheme"
                            [results]="datosBarra"
                            [xAxis]="showXAxis"
                            [yAxis]="showYAxis"
                            [legend]="showLegend"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [showGridLines]="showGridLines"
                            (select)="click_grafica($event)"
                            [customColors]="customColors"
                            [showDataLabel]="showDataLabelT1C1"
                            [dataLabelFormatting]="formatDataLabel"
                            [yScaleMax]="barScaleMax"
                            [yAxisTickFormatting]="yLeftTickFormat">
                        </ngx-charts-bar-vertical>
                    </div>
            </div>
        </div>

        <div class="w3-quarter_e w3-white ml-2" style="height: 100%;">
            <div class="w3-row w3-titulo-contenidomenu padreizquierda">
                <div class="hijoizquierdaTitle">
                    <span class="w3-margin-left-contenido">Edades</span>
                </div>
            </div>

            <div class="chart_wrap w3-white" style="z-index: 1;">
                <ngx-charts-pie-chart
                    style="height: 20vh; width: 80%;"
                    class="w3-labels-ngx-charts piechart-google"
                    id="piechart3"
                    [scheme]="colorScheme"
                    [results]="data_piechart"
                    [legend]="visibilitylegend3"
                    legendPosition="below"
                    [legendTitle]="legend3"
                    [doughnut]="doughnut"
                    [arcWidth]="arcWidth">
                </ngx-charts-pie-chart>
            </div>
        </div>

    </div>

    <div class="w3-row w3-contenedor-template mt-2" style="height: 41vh;">
        <div class="w3-threequarter w3-white" style="height: 100%;">
            <div class="w3-row">
                <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                        <span class="w3-margin-left-contenido">Distribución por actividad</span>
                        <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                        <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>

                <div class="w3-third w3-right-align_rest ">
                    <div class="w3-right hijoderechaTitle">
                        <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"
                            [matMenuTriggerFor]="menu">
                            more_vert</mat-icon>
                    </div>
                    <mat-menu #menu="matMenu">
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                            <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                            <span>Gráficas</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
                            </mat-icon>
                            <span>Exportar</span>
                        </button>
                        <button (click)="modal('id')" class="mat-menu-item" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                            <span>Ampliar</span>
                        </button>
                        <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0"
                            (click)="quitar_filtros()" class="mat-menu-item " mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"><img style="margin-top: -10px;"
                                    src="assets/img/graficos/sinfiltro.png"></mat-icon>
                            <span>Eliminar Filtros</span>
                        </button>
                        <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0"
                            (click)="quitar_filtros()" mat-menu-item>
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                                arrow_back
                            </mat-icon>
                            <span>Regresar</span>
                        </button>
                        <!--<button *ngIf="mostrandomeses" class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="cambiarmes">
                        <span>Cambiar mes</span>
                        </button>-->
                    </mat-menu>
                    <mat-menu #graficas="matMenu">
                        <button (click)="cambiarGrafica('barras')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                            <span>Barras</span>
                        </button>
                        <button (click)="cambiarGrafica('lineal')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                            <span>Lineal</span>
                        </button>
                        <button (click)="cambiarGrafica('pastel')" mat-menu-item>
                            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                            <span>Pastel</span>
                        </button>
                        <button (click)="cambiarGrafica('tabla')" mat-menu-item>
                            <mat-icon><img style="margin-top: -15px;" src="assets/img/elementotemplate/table.png">
                            </mat-icon>
                            <span>Tabla</span>
                        </button>
                    </mat-menu>
                    <mat-menu #exportar="matMenu">
                    </mat-menu>
                    <mat-menu #perspectivas="matMenu">
                        <button class="" (click)="addPerspectiva('bandas de morosidad')" mat-menu-item>
                            <span>Bandas de morosidad</span>
                        </button>
                        <button class="" (click)="addPerspectiva('estatus')" mat-menu-item>
                            <span>Estatus</span>
                        </button>
                        <!--
                        <button class="" (click)="ActividadT2C1()" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                          <span>Actividad/Ocupación</span>
                        </button>
                        <button class="" (click)="EstadoT2C1()" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                          <span>Estado </span>
                        </button>
                        <button class="" (click)="EstadoCivilT2C1()" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                          <span>Estado Civil</span>
                        </button>
                        <button class="" (click)="GeneroT2C1()" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                          <span>Género</span>
                        </button>
                        <button class="" (click)="LocalidadT2C1()" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                          <span>Localidad</span>
                        </button>
                        <button class="" (click)="MunicipioT2C1()" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                          <span>Municipio</span>
                        </button>
                        <button class="" (click)="RangoEdadT2C1()" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                          <span>Rango Edad</span>
                        </button>
                      -->
                    </mat-menu>
                    <mat-menu #geografico="matMenu">
                        <button class="" (click)="addPerspectiva('geografico_estado')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                            <span>Estado </span>
                        </button>
                        <button class="" (click)="addPerspectiva('geografico_municipio')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                            <span>Municipio</span>
                        </button>
                        <button class="" (click)="addPerspectiva('geografico_localidad')" mat-menu-item>
                            <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                            <span>Localidad</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="w3-row" style="height: 25vh;width: 100%;">
                    <div class="chart_wrap w3-scroll">
                        <ngx-charts-bar-vertical
                            id="chartvertical"
                            class="bar-half-google w3-labels-ngx-charts"
                            style="width: 95%;height: 20vh;"
                            [scheme]="colorScheme"
                            [results]="datosBarra_producto"
                            [xAxis]="showXAxis"
                            [yAxis]="showYAxis"
                            [legend]="showLegend"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [showGridLines]="showGridLines"
                            (select)="click_grafica2($event)"
                            [customColors]="customColors"
                            [showDataLabel]="showDataLabelT1C1"
                            [dataLabelFormatting]="formatDataLabel"
                            [yScaleMax]="barScaleMax"
                            [yAxisTickFormatting]="yLeftTickFormat">
                        </ngx-charts-bar-vertical>
                    </div>
            </div>
        </div>

        <div class="w3-quarter_e w3-white ml-2" style="height: 100%;">
            <div class="w3-row w3-titulo-contenidomenu padreizquierda">
                <div class="hijoizquierdaTitle">
                    <span class="w3-margin-left-contenido">Género</span>
                </div>
            </div>

            <div class="chart_wrap w3-white" style="z-index: 1;">
                <ngx-charts-pie-chart
                    style="height: 20vh; width: 80%;"
                    class="w3-labels-ngx-charts piechart-google"
                    id="piechart3"
                    [scheme]="colorScheme"
                    [results]="data_piechart_genero"
                    [legend]="visibilitylegend3"
                    legendPosition="right"
                    [legendTitle]="legend3"
                    [doughnut]="doughnut"
                    [arcWidth]="arcWidth">
                </ngx-charts-pie-chart>
            </div>
        </div>

    </div>
</div>
