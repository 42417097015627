export class Datosdummy{
    static ConstruirArregloAniosSeleccionados(data_anios_selecccionados){
        var incremento = 6000;
        var datosBarra = [];
        for(var i = 0; i < data_anios_selecccionados.length;i++){
            datosBarra.push({"name":data_anios_selecccionados[i].name,"value":78789+incremento,"extra":{"key_as_string":data_anios_selecccionados[i].name}});
            incremento += Math.random() * (8000 - 3874) + 2000;
        }
        console.log("DatosBarraConstruido ---->    " + JSON.stringify(datosBarra));
        return datosBarra;
    }
}