import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router'
import * as $ from 'jquery';

import { CommonFunctions } from './../../../util/app-util';
import { Resume, Experience, Education, Skill } from './../../../entities/base/exportarpdf';

/* Import Entities */
import { SocioClienteQubosEntity, CaptacionQubosEntity, CarteraQubosEntity, SocioclienteEntity, InstitucionPatmirEntity, EstadoEntitty } from './../../../entities/base/DashboardEntities';
import { ActivosEntity } from './../../../entities/base/DashboardEntities';
import { pagination } from './../../../entities/base/DashboardEntities';
import { UsuarioService } from '../../../services/usuario.service';
import { InstitucionPatmirService } from '../../../services/institucionpatmir.service';
/* Import Entities */

/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { AfiliacionServiceFunciones } from '../../shared/funciones-afiliacion/serviciosafiliacion';
import { FuncionesAfiliacion, AfiliacionVariablesConstantes, ConstantesAfiliacion, FuncionesArreglos, FuncionesMes, FuncionesCadenaAnioMes } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesBubble, FuncionAgruparSucursales, FuncionesExportCSV, FuncionesGenero, FuncionesBusquedasT1 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesTemplate4 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesFiltrosTemplate2 } from "../../shared/funciones-afiliacion/filtros_template2";
import { FuncionesTemplateIII } from "../../shared/funciones-afiliacion/filtros_template3";
import { PDF, PDFSecond } from './../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
/* Importación de Funciones */

/* Importación de servicios */
import { SocioclientequbosService } from './../../../services/comercial/afiliacion/afiliacion.service';
import { CaptacionQubosService } from './../../../services/comercial/captacion/captacion.service';
import { CarteraQubosService } from './../../../services/comercial/colocacion/cartera.service';
import { ActivosService } from './../../../services/comercial/activos.service';
import { EstadoService } from './../../../services/dashboard/estado.service';
/* Importación de servicios */

/* Importación de Objetos VO */
import { SocioClienteQubosVO } from './../../../entities/dashboard/socioclientequbosVO';
/* Importación de Objetos VO */

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MAT_SORT_HEADER_INTL_PROVIDER_FACTORY } from '@angular/material/sort';
//import { MatTableDataSource } from '@angular/material/table';

import { ChartDataSets, ChartOptions, ChartType, RadialChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import { ColorHelper } from '@swimlane/ngx-charts';


/* Import función para scalas de las gráficas */
import { FuncionesScaleMax } from './../../../util/app-util';
/* Import función para scalas de las gráficas */

import { PermisosSubModuloEntity } from '../../../entities/base/QubosEntities';
import { PermisosSubModuloService } from './../../../services/qubos/permisossubmodulo.service';


import pdfMake from "pdfmake/build/pdfmake.js";
import html2canvas from 'html2canvas';
import { SucursalEntity } from './../../../entities/base/BaseEntities';
import { SucursalService } from './../../../services/sucursal/sucursal.service';
import { InfoAnioMesEntitty } from './../../../entities/base/DashboardEntities';
import { InfoaniomesService } from './../../../services/dashboard/infoaniomes.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { FiltroAnio } from 'src/app/model/filtro-anio';
import { FiltroMes } from 'src/app/model/filtro-mes';
import { RangoFecha } from 'src/app/model/rango-fechas';
import { ElasticAhorroService } from 'src/app/services/elastic-ahorro/elastic-ahorro.service';

@Component({
  selector: 'app-ahorro',
  templateUrl: './ahorro.component.html',
  styleUrls: ['./ahorro.component.css']
})
export class AhorroComponent implements OnInit {

  // Filtro años
  public flag_filtrar_anio: boolean = false;
  public arreglo_anios: Array<FiltroAnio>;
  public anios_seleccionados: Array<FiltroAnio>;

  // Filtro meses
  public arreglo_meses: Array<FiltroMes>;
  public meses_seleccionados: Array<FiltroMes>;

  // Filtros perspectivas
  public filtrosPerspectivasActivos: Array<any>;
  public filtrosPerspectivasAnidados: Array<any>
  public flag_perspectivas: boolean = false;
  public sucursal_id: string;
  public valor_seleccionado;

  // Gráfica1
  public subtituloGrafica1: string = "subtítulo";
  public datosBarra: Array<any>;
  public colorScheme: string;
  public showXAxis = false;
  public showYAxis = true;
  public showLegend = false;
  public showXAxisLabel = true;
  public xAxisLabel = 'Sucursales';
  public xAxisLabelBarraFiltroT5: string = "";
  public xAxisLabelT2C3: string = "Sucursales";
  public showYAxisLabel = true;
  public yAxisLabel = '# Socios';
  public timeline = true;
  public showGridLines: boolean = false;
  public customColors = [{ "name": "", "value": 0 }];
  public showDataLabelT1C1: boolean = false;
  public barScaleMax: number = 5000;
  public activeEntries = [];

  // Tabla
  public table_data: Array<any>;

  // Gráfica lineal
  public data_lineal: Array<any>;
  public showXAxis_template2: boolean = false;
  public showYAxis_template2: boolean = true;
  public visibilitylegend1_t3: boolean = true;
  public showXAxisLabel_template2: boolean = true;
  public showYAxisLabel_template2: boolean = true;
  public xAxisLabel_template2 = "Cuentas/clasificación";
  public yAxisLabel_template2: string = '';
  public animations_template2: boolean = true;
  public barPadding_template2: number = 1;
  public showDataLabelT3C1 = true;
  public legendPositionverticalstackedt2: string = "below";//below
  public colorSchemeArea;

  //Elastic Search
  private rangoFecha = new RangoFecha();

  // Util
  private separador: string = ","; // separador para los miles
  private sepDecimal: string = '.'; // separador para los decimales

  //Totales
  public total_socios;
  public total_cuentas;
  public total_sucursales;

  constructor(
    private sucursalService: SucursalService,
    public dataService: DataService,
    private elasticSearch: ElasticAhorroService
  ) {
    FuncionesColores.inicioColores();

    this.colorScheme = FuncionesColores.setpaleta_azul('paleta_azul');
    this.colorSchemeArea = FuncionesColores.setpaleta_azul('monoGreen');

    // Se inicializan arreglos
    this.arreglo_anios = new Array<FiltroAnio>();
    this.arreglo_meses = new Array<FiltroMes>();
    this.anios_seleccionados = new Array<FiltroAnio>();
    this.meses_seleccionados = new Array<FiltroMes>();
    this.datosBarra = new Array<any>();
    this.data_lineal = new Array<any>();
    this.filtrosPerspectivasActivos = new Array<string>();
    this.filtrosPerspectivasAnidados = new Array<any>();
    this.table_data = new Array<string>();

    this.inicializarValores();

    this.rangoFecha = new RangoFecha();
  }

  ngOnInit(): void {
  }

  inicializarValores() { // Valores iniciales del dashboard
    //this.getfiltrosAnioMes();
    this.setSubtitulo();

    this.getGrafica();
    //this.getGraficaLineal();
    this.getTabla();

    this.getTotalSocios();
    this.getTotalCuentas();
    this.getTotalSucursales();
  }

  getTotalSocios(){
    this.elasticSearch.consultaGeneral('total_socios').subscribe(
      response => {

        this.total_socios = response.message.total_socios.value;

      }
    )
  }

  getTotalCuentas(){
    this.elasticSearch.consultaGeneral('total_cuentas').subscribe(
      response => {

        this.total_cuentas = response.message.agrupar_por_captacion.total_contratos.value;

      }
    )
  }

  getTotalSucursales(){
    this.elasticSearch.consultaGeneral('total_sucursales').subscribe(
      response => {

        this.total_sucursales = response.message.agrupar_por_captacion.total_contratos.value;

      }
    )
  }

  getTabla() {
    this.elasticSearch.consultaGeneral('tipodeposito').subscribe(
      response => {

        this.table_data = response.message.agrupar_por_captacion.agrupar_por_tipodeposito.buckets;

      }
    )
  }

  getGraficaLineal() {
    this.elasticSearch.consultaGeneral('cuentas/clasificacion').subscribe(
      response => {

        //this.data_lineal = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets);

        let datosBarraAux = [{
          name: 'Conteo - no contrato',
          series: []
        }];

        response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.total_contratos.value
          }
          datosBarraAux[0].series.push(newElemento);
        });

        this.data_lineal = datosBarraAux;

      }
    )
  }

  getfiltrosAnioMes() { // Obtiene los meses y años disponibles para filtrar

    // Consulta general de elastic search
    this.elasticSearch.consultaGeneral('fechas_registradas').subscribe(
      response => {
        response.message.agrupar_por_aniomes.buckets.forEach(element => {

          // Obtiene año y mes de key_as_string y los separa
          let cadenaAux = element.key_as_string;
          let aniomes = cadenaAux.split("-");
          let anioCadena = aniomes[0];
          let mesCadena = aniomes[1];
          let mesValor = parseInt(mesCadena); // Se convierte en int para generar el modelo FiltroMes

          if (this.arreglo_meses.filter(mes => mes.valor == mesValor).length == 0) {
            this.arreglo_meses.push(new FiltroMes(mesValor)); // Agrega el mes sin repetir valores
          }

          if (this.arreglo_anios.filter(anio => anio.cadena == anioCadena).length == 0) {
            this.arreglo_anios.push(new FiltroAnio(anioCadena, false));// Agrega el año sin repetir valores
          }

        });

        //Se asignan como valores iniciales el último año y último mes para consultar sucursales
        this.arreglo_anios[this.arreglo_anios.length - 1].selected = true;
        this.arreglo_meses[this.arreglo_meses.length - 1].selected = true;
        this.FiltrarAniosMeses();

        this.setSubtitulo();

        this.getGrafica();
      }
    );
  }

  setSubtitulo(): void {

    // Se reinicia el subitulo
    this.subtituloGrafica1 = "";

    // Se agregan todos los años
    this.anios_seleccionados.forEach(anio => {
      this.subtituloGrafica1 += this.subtituloGrafica1.length == 0 ? anio.cadena : ' . ' + anio.cadena;
    });

    this.subtituloGrafica1 += ' | '

    for (let index = 0; index < this.meses_seleccionados.length - 1; index++) {
      this.subtituloGrafica1 += (index == 0) ? this.meses_seleccionados[index].cadena : ' . ' + this.meses_seleccionados[index];
    }

    if (this.filtrosPerspectivasActivos && this.filtrosPerspectivasActivos.length > 0) {

      this.subtituloGrafica1 += ' | '

      for (let index = 0; index < this.filtrosPerspectivasActivos.length - 1; index++) {
        this.subtituloGrafica1 += index == 0 ? this.filtrosPerspectivasActivos[index] : ' . ' + this.filtrosPerspectivasActivos[index];

      }
    }
  }

  getGrafica() {
    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_inicial').subscribe(
      response => {

        //this.datosBarra = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets, 'total_contratos');

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(this.datosBarra);

        // Versión manual por si falla servicio Elastictobarchart
        let datosBarraAux = [];

        response.message.agrupar_por_captacion.agrupar_por_sucursal.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.total_contratos.value
          }
          datosBarraAux.push(newElemento);
        });

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(datosBarraAux);
        this.datosBarra = datosBarraAux;

      }
    )
  }

  getGraficaPerspectiva(perspectiva?: string) {

    let datos = {
      perspectiva: perspectiva,
      sucursal_id: this.sucursal_id
    }

    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_perspectiva', datos, this.filtrosPerspectivasActivos).subscribe(
      response => {

        //this.datosBarra = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets, 'total_contratos');

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(this.datosBarra);

        // Versión manual por si falla servicio Elastictobarchart
        let datosBarraAux = [];

        response.message.agrupar_por_captacion.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.total_contratos.value
          }
          datosBarraAux.push(newElemento);
        });

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(datosBarraAux);
        this.datosBarra = datosBarraAux;

      }
    )
  }

  getLastAnioMes(): Array<number> {

    let aniomes: Array<number> = new Array<number>();

    aniomes.push(parseInt(this.arreglo_anios[this.arreglo_anios.length - 1].cadena));
    aniomes.push(parseInt(this.arreglo_meses[this.arreglo_meses.length - 1].cadena));

    return aniomes;

  }

  FiltrarAniosMeses() {
    // Se actualiza arreglo de años seleccionados
    this.anios_seleccionados = this.arreglo_anios.filter(anio => anio.selected);
    this.meses_seleccionados = this.arreglo_meses.filter(mes => mes.selected);

  }

  quitar_filtros() {
    this.inicializarValores();
  }

  modal(id: string): void {

  }

  cambiarGrafica(grafica: string): void {

  }

  addPerspectiva(perspectiva: string) {

    this.getGraficaPerspectiva(perspectiva);
    this.filtrosPerspectivasActivos.push(perspectiva);
    this.filtrosPerspectivasAnidados.push({ perspectiva: perspectiva, value: this.valor_seleccionado });

  }

  setPerspectiva(perspectiva: string, value: any) {

    this.filtrosPerspectivasActivos;


    this.filtrosPerspectivasActivos.push(perspectiva);

  }

  formatearnormal(num) {
    return FuncionesAfiliacion.formatearnormal(num.toFixed(0));
    /*num += '';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return splitLeft + splitRight;*/
  }

  buscarSucursal(nombre: string){
    return this.datosBarra.filter(sucursal => sucursal.name == nombre);
  }

  getTotalSociosPorSucursal(sucursal_id){
    this.elasticSearch.consultaGeneral('total_socios_sucursal', sucursal_id).subscribe(
      response => {

        this.total_socios = response.message.total_socios.value;

      }
    )
  }

  getTotalCuentasPorSucursal(sucursal_id){
    this.elasticSearch.consultaGeneral('total_cuentas_sucursal', sucursal_id).subscribe(
      response => {

        this.total_cuentas = response.message.agrupar_por_captacion.total_contratos.value;

      }
    )
  }

  getTotalSucursalesPorSucursal(sucursal_id){
    this.total_sucursales = 1;
  }

  getTablaPorSucursal(sucursal_id) {
    this.elasticSearch.consultaGeneral('tabla_sucursal', sucursal_id).subscribe(
      response => {

        this.table_data = response.message.agrupar_por_captacion.agrupar_por_tipodeposito.buckets;

      }
    )
  }

  /******************
  FUNCIONES GRÁFICAS:
  *******************/

  selected = new EventEmitter();
 
  click_grafica(event) {
      this.sucursal_id = event.name;
      this.getTotalSociosPorSucursal(event.name);
      this.getTotalCuentasPorSucursal(event.name);
      this.getTotalSucursalesPorSucursal(event.name);
      this.getTablaPorSucursal(event.name);;
      this.activeEntries = [event, ...this.activeEntries];
      this.selected.emit({ value: event, entries: this.activeEntries });
  }

  formatDataLabel(num) {
    num = num.toFixed(0);
    num += '';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? "." + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + "," + '$2');
    }
    return splitLeft + splitRight;
  }

  yLeftTickFormat(valor) {
    return CommonFunctions.retornarformatolefty(valor);
  }

}
