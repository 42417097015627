export class UsuarioEntity {
  UsuarioId: number;
  Login: string;
  Nombre: string;
  Email: string;
  InstitucionId: number;
  Password: string;
  Activo: boolean;
  InstitucionPatmirId: string;
  Id_AT: number

  institucionEntity: InstitucionEntity;
  Acronimo: string;

  flag_busqueda: boolean;
  perfilid: number;
}

export class InstitucionEntity {
  public InstitucionId: number;
  public InstitucionDsc: string;
  public Acronimo: string;
  public Activo: boolean;
}
export class InstitucionPatmirEntity{
  InstitucionPatmirId: string;
  Acronimo: string;
  Nombre: string;
  Tipo: string;
  FechaConvenio: string;
  Activa: string;
  Bansefi_InstitucionPatmirId: string;
  UltPeriodoActualizable: number;
  InstitucionPatmir_Order_By: string;
  Acro_bk: string;
}

export class RolEntity {
  RolId: number;
  RolDsc: string;
  Descripcion: string;
  Activo: boolean;
}

export class UsuarioRolEntity {
  Usuario_RolId: number;
  UsuarioId: number;
  RolId: number;

  usuarioEntity: UsuarioEntity;
  Login: string;
  rolEntity: RolEntity;
  RolDsc: string;
}


export class UsuarioSucursalEntity{
  Usuario_SucursalId: number;
  Usuarioid: number;
  SucursalId: string;
}


/*export class SucursalEntity {
  SucursalId: number;
  SucursalDsc: string;
  InstitucionId: number;
  Activo: boolean;
  institucionEntity: InstitucionEntity;
  InstitucionDsc: string;
}*/
export class SucursalEntity{
  SucursalId: string;
  SucursalDsc: string;
  SucursalDsc_II: string;
  InstitucionPatmirId: string;
  Estatus: boolean;
  Bansefi_PA_Consecutivo: string;
  Bansefi_PAId: string;
  Meta_Total: number;
  EsMatriz: boolean;
  InstitucionPatmirSucursalId: string;
  SucursalId_Anterior: string;
  ElegiblePATMIR: boolean;
  EstadoId: string;
  institucionEntity: InstitucionPatmirEntity;
  InstitucionDsc: string;
  Nombre: string;
  SucID: string;
}

