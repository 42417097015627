import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {EsqueletoPeticionModel} from '../../model/esqueletoPeticion.model';
import {FiltrosService} from '../filtros/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class GeograficoService {
  private apiURL2 = environment.apiDevURL_Geografico;
  private headers2 = new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  private apiURL = environment.apiDevURL;
  private headers = new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  private calves_estado = [
    {clave: '001', estado: 'AGUASCALIENTES', latitud: 21.88234, longitud: -102.28259, check: true, data: 0},
    {clave: '002', estado: 'BAJA CALIFORNIA',latitud: 31.87149, longitud: -116.60071, check: true, data: 0},
    {clave: '003', estado: 'BAJA CALIFORNIA SUR',latitud: 24.14437, longitud: -110.3005, check: true, data: 0},
    {clave: '004', estado: 'CAMPECHE',latitud: 19.84386, longitud: -90.52554, check: true, data: 0},
    {clave: '005', estado: 'COAHUILA DE ZARAGOZA', latitud: 25.42321, longitud: -101.0053, check: true, data: 0},
    {clave: '006', estado: 'COLIMA', latitud: 19.24997, longitud: -103.72714, check: true, data: 0},
    {clave: '007', estado: 'CHIAPAS', latitud: 16.75973, longitud: -93.11308, check: true, data: 0},
    {clave: '008', estado: 'CHIHUAHUA', latitud: 28.63528, longitud: -106.08889, check: true, data: 0},
    {clave: '009', estado: 'DISTRITO FEDERAL', latitud: 19.431312, longitud: -99.138876, check: true, data: 0},
    {clave: '010', estado: 'DURANGO', latitud: 24.02032, longitud: -104.65756, check: true, data: 0},
    {clave: '011', estado: 'GUANAJUATO', latitud: 21.01858, longitud: -101.2591, check: true, data: 0},
    {clave: '012', estado: 'GUERRERO', latitud: 17.4362, longitud: -99.46483, check: true, data: 0},
    {clave: '013', estado: 'HIDALGO', latitud: 20.482132, longitud: -99.217999, check: true, data: 0},
    {clave: '014', estado: 'JALISCO', latitud: 20.66682, longitud:-103.39182, check: true, data: 0},
    {clave: '015', estado: 'MEXICO', latitud: 19.42847, longitud: -99.12766, check: true, data: 0},
    {clave: '016', estado: 'MICHOACAN DE OCAMPO', latitud: 19.57158, longitud: -102.48066, check: true, data: 0},
    {clave: '017', estado: 'MORELOS', latitud: 18.9261, longitud: -99.23075, check: true, data: 0},
    {clave: '018', estado: 'NAYARIT', latitud: 21.50951, longitud: -104.89569, check: true, data: 0},
    {clave: '019', estado: 'NUEVO LEON', latitud: 25.67507, longitud: -100.31847, check: true, data: 0},
    {clave: '020', estado: 'OAXACA', latitud: 17.06542, longitud: -96.72365, check: true, data: 0},
    {clave: '021', estado: 'PUEBLA', latitud: 19.03793, longitud: -98.20346, check: true, data: 0},
    {clave: '022', estado: 'QUERETARO DE ARTEAGA', latitud: 20.58806, longitud: -100.38806, check: true, data: 0},
    {clave: '023', estado: 'QUINTANA ROO', latitud: 18.67417, longitud: -88.47278, check: true, data: 0},
    {clave: '024', estado: 'SAN LUIS POTOSI', latitud: 22.14982, longitud: -100.97916, check: true, data: 0},
    {clave: '025', estado: 'SINALOA', latitud: 23.2329, longitud: -106.4062, check: true, data: 0},
    {clave: '026', estado: 'SONORA', latitud: 29.1026, longitud: -110.97732, check: true, data: 0},
    {clave: '027', estado: 'TABASCO', latitud: 17.98689, longitud: -92.93028, check: true, data: 0},
    {clave: '028', estado: 'TAMAULIPAS', latitud: 22.36094, longitud: -97.89997, check: true, data: 0},
    {clave: '029', estado: 'TLAXCALA', latitud: 19.31905, longitud: -98.19982, check: true, data: 0},
    {clave: '030', estado: 'VERACRUZ', latitud: 19.163648, longitud: -96.351859, check: true, data: 0},
    {clave: '031', estado: 'YUCATAN', latitud: 20.945481, longitud: -89.595223, check: true, data: 0},
    {clave: '032', estado: 'ZACATECAS', latitud: 22.765141, longitud: -102.584445, check: true, data: 0},];

  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }

  public getGeograficoSocios(valoresAnios: number[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'aseguradora'
    );
    const valoresMeses = valoresAnios;

    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
    };

    peticion.groupby = {
      perspectiva: {
        campo: "municipio.keyword",
        subagrupacion: {
          operacion: "contar",
          campo: "numeroSocio"
        }
      }      
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getGeograficoCodPos(valoresAnios: number[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'aseguradora'
    );
    const valoresMeses = valoresAnios;

    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
    };

    peticion.groupby = {
      perspectiva: {
        campo: "municipio.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
            perspectiva: {
              campo: "codigoPostal.keyword",
              subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
              }
            }
        }
      }      
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** consultas cartera */
  public getGeograficoSaldoCap(valoresAnios: number[], estado?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'radiografia'
    );
    const valoresMeses = valoresAnios;

    if(estado != '' && estado != undefined){
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: "codigoEstado.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "codigoMunicipio1.keyword",
            subagrupacion: {
              operacion: "sumar",
              campo: "saldoCapital"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getGeograficoIntVen(valoresAnios: number[], estado?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'radiografia'
    );
    const valoresMeses = valoresAnios;

    if(estado != '' && estado != undefined){
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: "codigoEstado.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "codigoMunicipio1.keyword",
            subagrupacion: {
              operacion: "sumar",
              campo: "interesesVencidos1"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getGeograficoIntVig(valoresAnios: number[], estado?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'radiografia'
    );
    const valoresMeses = valoresAnios;

    if(estado != '' && estado != undefined){
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: "codigoEstado.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "codigoMunicipio1.keyword",
            subagrupacion: {
              operacion: "sumar",
              campo: "intVigCobrado"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getGeograficoIntMor(valoresAnios: number[], estado?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'radiografia'
    );
    const valoresMeses = valoresAnios;

    if(estado != '' && estado != undefined){
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: valoresMeses},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: "codigoEstado.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
          perspectiva: {
            campo: "codigoMunicipio1.keyword",
            subagrupacion: {
              operacion: "sumar",
              campo: "interesMoratorio"
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /** consultas numero de socios */
  public getGeograficoCodEst(valoresAnios: number[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-estadistico',
      'estadistico',
      'radiografia'
    );
    const valoresMeses = valoresAnios;

    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
    };

    peticion.groupby = {
      perspectiva: {
        campo: "codigoEstado.keyword",
        subagrupacion:{
          operacion: "agrupar-perspectiva",
            perspectiva: {
              campo: "codigoMunicipio1.keyword",
              subagrupacion: {
                operacion: "contar",
                campo: "numeroSocio"
              }
            }
        }
      } 
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /** Consultas de latitud y longitud */
  public obtener_datos(estado){
    var api = this.apiURL2 + 'estado='+estado;
    return this.http.get(`${api}`, {headers: this.headers2});
  }
  /** Consulta para codigo estado */
  public obtener_estado(clave){
    const auxclave = Number(clave);
    var aux; 
    this.calves_estado.forEach(clav => { if (Number(clav.clave) === auxclave){ aux = clav; } });
    return aux;
  }

}
