import { Component, OnInit } from '@angular/core';

import { DataService } from '../../../services/data-service/data.service';
//src/app/services/data-service/data.service

/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { FuncionesAfiliacion, MasculinoFemenino, FinancieroSucursal } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { Datosdummy,  } from '../funciones_f_pruebas';
import { CommonFunctions } from '../../../util/app-util';
/* Importación de Funciones */

import { FiltroAnio } from 'src/app/model/filtro-anio';
import { FiltroMes } from 'src/app/model/filtro-mes';
import { ElasticSociosService } from 'src/app/services/elastic-socios/elastic-socios.service';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


@Component({
  selector: 'app-rentabilidad',
  templateUrl: './rentabilidad.component.html',
  styleUrls: ['./rentabilidad.component.css']
})
export class RentabilidadComponent implements OnInit {

  // Filtros perspectivas
  public filtrosPerspectivasActivos: Array<any>;
  public filtrosPerspectivasAnidados: Array<any>;
  public flag_perspectivas = false;
  public sucursal_id: string;
  public valor_seleccionado;

  // Arreglos anio y mes
  public contador_anios = 0;
  public contador_mes = 0;
  public contador_perspectiva = 0;
  data_anios: any [] = [
    {name: '2019', value: '2019', check: true},
    {name: '2020', value: '2020', check: true},
    {name: '2021', value: '2021', check: true}
    ];
  data_meses: any [] = [
        {name: 'ene', value: 'ene', check: false},
        {name: 'feb', value: 'feb', check: false},
        {name: 'mar', value: 'mar', check: false},
        {name: 'abr', value: 'abr', check: false},
        {name: 'may', value: 'ene', check: false},
        {name: 'jun', value: 'jun', check: false},
        {name: 'jul', value: 'jul', check: false},
        {name: 'ago', value: 'ago', check: true}
        ];
  data_anios_selec: any [] = [];
  data_meses_selec: any [] = [];
  data_sucursales: any [] = [
      {name: 'suc0', value: 'suc0', check: false},
      {name: 'suc1', value: 'suc1', check: false},
      {name: 'suc2', value: 'suc2', check: false},
      {name: 'suc3', value: 'suc3', check: false},
      {name: 'suc4', value: 'suc4', check: false},
      {name: 'suc5', value: 'suc5', check: false},
      {name: 'suc6', value: 'suc6', check: false},
      {name: 'suc7', value: 'suc7', check: false}
  ];
  data_sucursales_select: any [] = [];

  ent_consolidado = true;
  public subtituloGrafica1 = 'Todos';
  public datosBarra: Array<any>;
  public datosLineal: Array<any>;
  // Filtro años
  public flag_filtrar_anio = false;
  public arreglo_anios: Array<FiltroAnio>;
  public anios_seleccionados: Array<FiltroAnio>;

  // Filtro meses
  public arreglo_meses: Array<FiltroMes>;
  public meses_seleccionados: Array<FiltroMes>;
  public sucursales: Array<any>;

  //mat-table
  dataSource: any;
  displayedColumns = ['sucursal','montocaptacion','gastosinteres','montocarteracredito','ingresosinteres', 'marcofinanciero', 'estimacionprc', 'otrosingresosoperacion', 'gastisadmon', 'resultadoneto'];



  constructor(
    public dataService: DataService,
    private elasticSearch: ElasticSociosService
  ) {
    this.inicializarValores();
    // this.rangoFecha = new RangoFecha();
    this.data_anios_selec = this.data_anios;
    // this.data_meses_selec = this.data_meses;
    this.data_meses_selec.push(this.data_meses[this.data_meses.length - 1]);
    
    //this.dataSource = new MatTableDataSource<MasculinoFemenino>(this.table_data);
    //this.dataSource.sort = this.sort;
    

    this.datosBarra = [
      {name: '2019', value: 78789, extra: {key_as_string: 2019}},
      {name: '2020', value: 85789, extra: {key_as_string: 2020}},
      {name: '2021', value: 91086, extra: {key_as_string: 2021}},
    ];
    this.contador_anios = this.data_anios_selec.length;
    this.contador_mes = this.data_meses_selec.length;
  }


  ngOnInit(): void {
  }
  inicializarValores(): void{
    this.getGrafica();
  }
  getGrafica() {
    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_inicial').subscribe(
      response => {
        // Versión manual por si falla servicio Elastictobarchart
        if (response.http.code === 200) {
          const datosBarraAux = [];
          this.data_sucursales = [];

          this.sucursales = response.message.agrupar_por_sucursal.buckets;
          let cont = 1;
          response.message.agrupar_por_sucursal.buckets.forEach(elemento => {
            const elementoCadena = 'suc' + cont.toString();
            this.data_sucursales.push({sucursal: elemento.key, montocaptacion: 666666712.15, gastosinteres: 10231231.23,montocarteracredito:562342353.24,ingresosinteres: 77105231.23, marcofinanciero: 66123123.12, estimacionprc:-1288405.72, otrosingresosoperacion: 2831512.33, gastisadmon: 123689231.62, resultadoneto: -294005.26});
            const newElemento = {
              name: elementoCadena,
              value: elemento.doc_count,
              extra: {
                key_as_string: elemento.key
              }
            };
            datosBarraAux.push(newElemento);
            cont++;
          });
          this.dataSource = new MatTableDataSource<FinancieroSucursal>(this.data_sucursales);
          this.datosLineal = [{name: 'entidad', series: this.datosBarra}];
        }
      }
    );
  }

  // eventos de anios y mes
  VerificarAniosSeleccionados(): string{
    if (this.contador_anios > 1) {
      return 'w3-third w3-disabled';
    }
    else {
      return 'w3-third ';
    }
  }

  ContarMeses(){
    if (this.data_meses_selec.length < 5 ){
      return 'w3-row w3-tiny hijoizquierda';
    }else{
      return 'w3-row w3-tiny ';
    }
  }

  quitar_filtros() {
    // this.inicializarValores();
    this.filtrosPerspectivasActivos = [];
    this.filtrosPerspectivasAnidados = [];
    this.flag_perspectivas = false;
  }
  // eventos de anios y mes

  event_sucursalTodasFalse(){
    this.data_sucursales_select = [];
    for (let i = 0; i < this.data_sucursales.length; i++){
      this.data_sucursales[i].check = false;
      this.data_sucursales_select.push(this.data_sucursales[i]);
    }
    this.datosBarra = Datosdummy.ConstruirArregloAniosSeleccionados(this.data_sucursales_select);
  }

  event_consolidado(): void{
    this.ent_consolidado = !this.ent_consolidado;
    if (this.ent_consolidado){
      // regresar todas las sucursales a check = false
      this.event_sucursalTodasFalse();
    }
  } 
  addPerspectiva(perspectiva: string) {
    /*this.getGraficaPerspectiva(perspectiva);
    this.getTablaPerspectiva(perspectiva);
    this.getGraficaLinealPerspectiva(perspectiva);
    this.filtrosPerspectivasActivos.push(perspectiva);
    this.filtrosPerspectivasAnidados.push({ perspectiva: perspectiva, value: this.valor_seleccionado });
    this.setSubtitulo();
    this.flag_perspectivas = false;*/
  }
  isNotActiveFilter(perspectiva: string): boolean {
    return false;
    //return this.filtrosPerspectivasActivos.filter(filtro => filtro == perspectiva).length == 0;
  }
  cambiarGrafica(grafica: string): void {
    /*switch (grafica){
      case 'barras':
        this.g1_barra = true;
        this.g1_linea = false;
        break;
      case 'lineal':
        this.g1_barra = false;
        this.g1_linea = true;
        break;
    }*/
  }
  modal(x){}

   // Tabla cuadrante2
   convertir_moneda(numero){
    let cant = new Intl.NumberFormat('es-MX').format(numero);
    if (cant.indexOf('.') == -1 ){// si se cumple el número tiene decimales
      cant = cant + '.00';
    }
    return this.formatearNormal(numero);
  }
  convertir_porcentaje(numero){
    let cant = new Intl.NumberFormat('es-MX').format(numero);
    if (cant.indexOf('.') == -1 ){// si se cumple el número tiene decimales
      cant = cant + '.00';
    }
    return FuncionesAfiliacion.formatearnormal(numero.toFixed(2));
  }
  formatearNormal(num){
    return FuncionesAfiliacion.formatearnormal(num.toFixed(0));
  }
}
