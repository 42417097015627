<!--LEYENDA-->
<div class="w3-row">

    <ng-container *ngFor="let elemento of elementos; let i = index">

        <button mat-icon-button>
            <mat-icon [ngStyle]="{'color': colorScheme.domain[i]}">stop</mat-icon>
        </button>

        <span *ngIf="small"><small>{{ elemento.name }}</small></span>

        <span *ngIf="!small">{{ elemento.name }}</span>

    </ng-container>

  </div>