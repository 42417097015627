import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EsqueletoPeticionModel} from '../../../model/esqueletoPeticion.model';
import {FiltrosService} from '../../filtros/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class ColocacionTendenciaService {
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  
  private apiURL = environment.apiDevURL;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }
  
  /** Grafica compuesta */
  // Año mes
  public salCapCom(valoresAnios: number[], mesesDisponibles , estado, fecha?): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }

    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoCapital'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVenCom(valoresAnios: number[], mesesDisponibles , estado, fecha?): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }

    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesesVencidos1'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVigCom(valoresAnios: number[], mesesDisponibles , estado, fecha?): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }

    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'intVigCobrado'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intMorCom(valoresAnios: number[], mesesDisponibles , estado, fecha?): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }

    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesMoratorio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTabla(valoresAnios: number[], fecha?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio);
    }
    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroCredito'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablaV2(valoresAnios: number[], fecha?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-tabla',
      'radiografia'
    );
    const valoresMeses = [];
    if(!fecha){
      for (const anio of valoresAnios) {
        valoresMeses.push(anio);
      }
    }else{
      valoresMeses.push(fecha);
    }
    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar-contar',
          campos: ['saldoCapital','numeroCredito']
        }
      },
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
          subagrupacion: {
            operacion: 'agrupar-aniomeses',
              aniomeses: {
                valores: valoresMeses,
                  subagrupacion: {
                  operacion: 'sumar',
                    campo: 'saldoCapital'
                  }
              }
          }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  //Sucursales

  public saldoCapSuc(valoresMeses: string[], sucursalesSeleccionadas: object[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    if(estado != ''){
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        },
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoCapital'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVenSuc(valoresMeses: string[], sucursalesSeleccionadas: object[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    if(estado != ''){
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        },
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesesVencidos1'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVigSuc(valoresMeses: string[], sucursalesSeleccionadas: object[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    if(estado != ''){
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        },
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'sumar',
          campo: 'intVigCobrado'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intMorSuc(valoresMeses: string[], sucursalesSeleccionadas: object[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    if(estado != ''){
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        },
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }else{
      peticion.filter = {
        aniosmeses: {
          valores: valoresMeses
        }
      };
    }
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesMoratorio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public sucursales(valoresAnios: number[], mesesDisponibles, fecha?): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      },
    };
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito'
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  public getTendenciaTablaV2Suc(valoresMeses: string[], sucursalesSeleccionadas: object[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'radiografia'
    );var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      },
      sucursales: {
        campo: 'sucursalCredito.keyword',
        valor: sucursalesSeleccionadas
      }
    };
    
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroCredito'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Actualización de graficas */

  /** AÑOS */
  public saldoCapAnio(valoresAnios: number[], ultimoValor: string, ultanio, estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    //valoresMeses.pop();
    // @ts-ignore
    //valoresMeses.push(ultimoValor.value);
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoCapital'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVenAnio(valoresAnios: number[], ultimoValor: string,ultanio, estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    //valoresMeses.pop();
    // @ts-ignore
    //valoresMeses.push(ultimoValor.value);
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesesVencidos1'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVigAnio(valoresAnios: number[], ultimoValor: string,ultanio, estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    //valoresMeses.pop();
    // @ts-ignore
    //valoresMeses.push(ultimoValor.value);
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'intVigCobrado'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intMorAnio(valoresAnios: number[], ultimoValor: string,ultanio, estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    //valoresMeses.pop();
    // @ts-ignore
    //valoresMeses.push(ultimoValor.value);
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesMoratorio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public capVenAnio(valoresAnios: number[], ultimoValor: string,ultanio): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    //valoresMeses.pop();
    // @ts-ignore
    //valoresMeses.push(ultimoValor.value);
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'sdoInsolutoCapVenc'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public salInsAnio(valoresAnios: number[], ultimoValor: string,ultanio): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    //valoresMeses.pop();
    // @ts-ignore
    //valoresMeses.push(ultimoValor.value);
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'sdoInsolutoCapVig'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }


  public getTendenciaTablaAnio(valoresAnios: number[], ultimoValor: string, ultanio): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroCredito'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** MESES */
  public saldoCapMes(valoresMeses: string[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoCapital'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVenMes(valoresMeses: string[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesesVencidos1'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVigMes(valoresMeses: string[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'intVigCobrado'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intMorMes(valoresMeses: string[], estado): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    if(estado != ''){
      peticion.filter = {
        perspectivas:[{
          campo: "estado1.keyword",
          valor: estado
        },]
      };
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'interesMoratorio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public capVenMes(valoresMeses: string[]): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'sdoInsolutoCapVenc'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public salInsMes(valoresMeses: string[]): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'sdoInsolutoCapVig'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  

  public getTendenciaTablaMes(valoresMeses: string[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'radiografia'
    );
    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroCredito'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /** SUCURSALES */
  
  public capVenSuc(valoresMeses: string[], sucursalesSeleccionadas: object[]): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    
    var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      },
    };
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'sumar',
          campo: 'sdoInsolutoCapVenc'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public salInsSuc(valoresMeses: string[], sucursalesSeleccionadas: object[]): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    
    var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      },
    };
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'sumar',
          campo: 'sdoInsolutoCapVig'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }


  public getTendenciaTablaSuc(valoresMeses: string[], sucursalesSeleccionadas: object[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'radiografia'
    );var sucursales = [];
    // @ts-ignore
    sucursalesSeleccionadas.forEach(suc => sucursales.push(suc.valor));
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      },
    };
    
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroCredito'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /** PERSPECTIVA */
  public saldoCapPer(perspectiva: string, mesSeleccionado: string, perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, estado, sucursalSeleccionada?: string): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var con : any = []
    if(sucursalSeleccionada){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }else if(estado != ''){
      per.push( {
        campo: "estado1.keyword",
        valor: estado
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }

    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "sumar",
            campo: "saldoCapital"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVenPer(perspectiva: string, mesSeleccionado: string, perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, estado, sucursalSeleccionada?: string): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var con : any = []
    if(sucursalSeleccionada){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }else if(perspectiva != "estado1.keyword" && estado != ''){
      per.push( {
        campo: "estado1.keyword",
        valor: estado
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }
    
    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "sumar",
            campo: "interesesVencidos1"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intVigPer(perspectiva: string, mesSeleccionado: string, perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, estado, sucursalSeleccionada?: string): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var con : any = []
    if(sucursalSeleccionada){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }else if(perspectiva != "estado1.keyword" && estado != ''){
      per.push( {
        campo: "estado1.keyword",
        valor: estado
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "sumar",
            campo: "intVigCobrado"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public intMorPer(perspectiva: string, mesSeleccionado: string, perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, estado, sucursalSeleccionada?: string): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var con : any = []
    if(sucursalSeleccionada){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }else if(perspectiva != "estado1.keyword" && estado != ''){
      per.push( {
        campo: "estado1.keyword",
        valor: estado
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "sumar",
            campo: "interesMoratorio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public capVenPer(perspectiva: string, mesSeleccionado: string, perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, sucursalSeleccionada?: string): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var con : any = []
    if(sucursalSeleccionada){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "sumar",
            campo: "sdoInsolutoCapVenc"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public salInsPer(perspectiva: string, mesSeleccionado: string, perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, sucursalSeleccionada?: string): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var con : any = []
    if(sucursalSeleccionada){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "sumar",
            campo: "sdoInsolutoCapVig"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }


  public getTendenciaTablaPer(perspectiva: string, mesSeleccionado: string, perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, sucursalSeleccionada?: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'radiografia'
    )
    var con : any = []
    if(sucursalSeleccionada){
      con.push({
          campo: 'sucursalCredito.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroCredito"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  public getTendenciaTablaPorAnio(valoresAnios: number[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-tabla',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-09');
    }
    peticion.groupby = {
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
          subagrupacion: {
          operacion: 'agrupar-aniomeses',
            aniomeses: {
            valores: valoresMeses,
              subagrupacion: {
              operacion: 'sumar',
                campo: 'saldoCapital'
            }
          }
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  public getTendenciaTablaPorAnioV2(valoresAnios: number[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-tabla',
      'radiografia'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio);
    }
    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar-contar',
          campos: ['saldoCapital','numeroCredito']
        }
      },
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
          subagrupacion: {
            operacion: 'agrupar-aniomeses',
              aniomeses: {
                valores: valoresMeses,
                  subagrupacion: {
                  operacion: 'sumar',
                    campo: 'saldoCapital'
                  }
              }
          }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  
  public getTendenciaTablaPorMes(valoresMeses: string[], perGen: string, perSer: string, perPro: string, perEst: string, perMun: string, sucursalSeleccionada?: any): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-tabla',
      'radiografia'
    );
    var con : any = []
    if(sucursalSeleccionada != undefined){
      if(sucursalSeleccionada.name != ''){
        con.push({
          campo: "sucursalCredito.keyword",
          valor: sucursalSeleccionada.name
        });
      }
    }
    var per :any = [];
    if(perGen != ""){
      per.push({
        campo: "sexo.keyword",
        valor: perGen
      });
    }
    if(perSer != ""){
     per.push( {
        campo: "servicio.keyword",
        valor: perSer
      });
    } 
    if(perPro != ""){
      per.push( {
        campo: "nombreProducto.keyword",
        valor: perPro
      });
    }
    if(perEst != ""){
      per.push( {
        campo: "estado1.keyword",
        valor: perEst
      });
    }
    if(perMun != ""){
      per.push( {
        campo: "municipio1.keyword",
        valor: perMun
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        sucursales: con,
      };
    }

    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar-contar',
          campos: ['saldoCapital','numeroCredito']
        }
      },
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
          subagrupacion: {
            operacion: 'agrupar-aniomeses',
              aniomeses: {
                valores: valoresMeses,
                  subagrupacion: {
                  operacion: 'sumar',
                    campo: 'saldoCapital'
                  }
              }
          }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }


  public getdataTablaAnio(valoresAnios: number[], ultimoValor: string, ultanio): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-tabla',
      'radiografia'
    );
    
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar-contar',
          campos: ['saldoCapital','numeroCredito']
        }
      },
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
          subagrupacion: {
            operacion: 'agrupar-aniomeses',
              aniomeses: {
                valores: valoresMeses,
                  subagrupacion: {
                  operacion: 'sumar',
                    campo: 'saldoCapital'
                  }
              }
          }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getdataTablaMes(valoresMeses: string[]): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-tabla',
      'radiografia'
    );
    peticion.groupby = {
      aniomeses:{
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar-contar',
          campos: ['saldoCapital','numeroCredito']
        }
      },
      sucursales: {
        campo: 'codSucCredito-sucursalCredito',
          subagrupacion: {
            operacion: 'agrupar-aniomeses',
              aniomeses: {
                valores: valoresMeses,
                  subagrupacion: {
                  operacion: 'sumar',
                    campo: 'saldoCapital'
                  }
              }
          }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  public datosTablaFormatoDePeticionHaciaFormatoMatTable(formatoPeticion: object[]): object[]{
    /**
     * Este es el formato recibido por la peticion:
     * [
     *    {
     *      "id_key": "1",
     *      "key": "SAN RAFAEL",
     *      "value": [
     *        {
     *            "key": "2019",
     *             "key_count": 136829,
     *             "value": 6576898816.836759
     *        },
     *        {
     *            "key": "2020",
     *            "key_count": 125233,
     *            "value": 6674792139.507898
     *        },
     *        {
     *            "key": "2021",
     *            "key_count": 85093,
     *            "value": 5021127768.8866005
     *        }
     *      ]
     *    }
     * ]
     */
    /**
     * Este es el formato que recibira angularMatTable:
     * [
     *    {sucursal: 'MATRIZ', 2019: '23456876.40', 2020: '24565890.34', 2021: '23345769.21'},
     * ];
     */
    const formatoParaMatTable = [];
    for (const sucursal of formatoPeticion) {
      const nuevaFila: {[k: string]: any} = {};
      // @ts-ignore
      nuevaFila.sucursal = sucursal.key;
      // @ts-ignore
      for (const anioConDatos of sucursal.value) {
        const mes = this.filtrosService.getMesPrefijo(anioConDatos.key);
        if (mes) {
          nuevaFila[mes] = this.formatearnormal(anioConDatos.value.toFixed(2));
        } else {
          nuevaFila[anioConDatos.key] = this.formatearnormal(anioConDatos.value.toFixed(2));
        }
      }
      formatoParaMatTable.push(nuevaFila);
    }
    return formatoParaMatTable;
  }
  /** Formateo normal nuero */
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft + splitRight;
  }

}
