import {MessageService} from './../base/message.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from './../base/service';
import {InfoAnioMesEntitty } from './../../entities/base/DashboardEntities';
import {Loggers} from './../../util/app-util';


@Injectable({
  providedIn: 'root'
})
export class InfoaniomesService extends Service<InfoAnioMesEntitty> {

  httpClient: HttpClient;

  constructor(
      http: HttpClient,
      messageService: MessageService,
      protected logger: Loggers
  ) {
      super('PatmirService', 'dashboard/infoaniomes', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: InfoAnioMesEntitty): HttpParams {
      let params: HttpParams = new HttpParams();
      if (null == entity) { return params; }
      if (null != entity.anio) { params = params.set('anio', entity.anio.toString()); }
      if (null != entity.mes) { params = params.set('mes', entity.mes.toString()); }
      if (null != entity.ifid) { params = params.set('ifid', entity.ifid.toString()); }
      return params;
  }
}
