import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SucursalService {
  
  private url = "https://6709-2806-107e-15-4a7b-acd3-fb5-cd17-d06e.ngrok.io/rfl/api/v1/busquedageneral";
  private token = sessionStorage.getItem('auth_token');
  private nombreDashboard = "afiliacion";

  constructor(
    private _http: HttpClient
  ) { }

  // GET sucursales
  consultaGeneral(body): Observable<any> {
    let newBody = JSON.stringify(body)
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + this.token).set('NombreDashboard', 'Mi pilinga');
    return this._http.post(this.url, newBody, { headers: headers });
  }
}