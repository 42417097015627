export class Datosdummy{
    static ConstruirArregloAniosSeleccionados(data_anios_selecccionados){
        let incremento = 6000;
        let datosBarra = [];
        for(var i = 0; i < data_anios_selecccionados.length;i++){
            datosBarra.push({"name":data_anios_selecccionados[i].name,"value":78789+incremento,"extra":{"key_as_string":data_anios_selecccionados[i].name}});
            incremento += Math.random() * (8000 - 3874) + 2000;
        }
        console.log("DatosBarraConstruido ---->    " + JSON.stringify(datosBarra));
        return datosBarra;
    }
    static ConstruirTableroFinancieroCuenta(){
        let tableroCuentas = [];
        tableroCuentas = [{
            "name":"Normativos",
            "series":[{
                "name":"Cobertura financiera",
                "series":[{
                    "name":"Ìndice de capitalización",
                    "A":">=150%",
                    "B":"75% - 95%",
                    "C":"51% - 74%",
                    "D":"0% - 50%",
                }
                ]}
            ]}
        ];
    }
}

