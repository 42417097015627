import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EsqueletoPeticionModel} from '../../../model/esqueletoPeticion.model';
import {FiltrosService} from '../../filtros/filtros.service';
@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  private apiURL = environment.apiDevURL;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }
  /** Tablas por sucursales */
  public getTendenciaGraficaGeneroSucursales(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'tipo',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(tipo == ""){
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "genero.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }else{
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
          perspectivas: [{
            campo: "tipo.keyword",
            valor: tipo
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas: [{
                campo: "tipo.keyword",
                valor: tipo
          }]
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "genero.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaRangoEdadSucursales(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'rangoedad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "rangoedad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaGeneracionSucursales(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-perfil',
      'generacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "generacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacionSucursales(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ocupacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEscolaridadSucursales(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string, ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'escolaridad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
  
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    if(ocupacion != ""){
      per.push({
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "escolaridad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaIngresoSucursales(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string, ocupacion: string, escolaridad: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ingresos',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
   
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    if(ocupacion != ""){
      per.push({
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    if(escolaridad != ""){
      per.push({
        campo: "escolaridad.keyword",
        valor: escolaridad
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      intervalo: {
        campo: "ingresosOrdinarios",
        valor: 5000,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** sin filtro */
  public getTendenciaGraficaGenero(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'tipo',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(tipo == ""){
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "genero.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }else{
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
          perspectivas: [{
            campo: "tipo.keyword",
            valor: tipo
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas: [{
                campo: "tipo.keyword",
                valor: tipo
          }]
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "genero.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaRangoEdad(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'rangoedad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(tipo == ""){
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "rangoedad.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }else{
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
          perspectivas: [{
            campo: "tipo.keyword",
            valor: tipo
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas: [{
                campo: "tipo.keyword",
                valor: tipo
          }]
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "rangoedad.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }  
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaGeneracion(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-perfil',
      'generacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(tipo == ""){
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "generacion.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }else{
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
          perspectivas: [{
            campo: "tipo.keyword",
            valor: tipo
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas: [{
                campo: "tipo.keyword",
                valor: tipo
          }]
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "generacion.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacion(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ocupacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(tipo == ""){
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "ocupacion.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }else{
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
          perspectivas: [{
            campo: "tipo.keyword",
            valor: tipo
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas: [{
                campo: "tipo.keyword",
                valor: tipo
          }]
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "ocupacion.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEscolaridad(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'escolaridad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(tipo == ""){
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          }
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "escolaridad.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }else{
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
          perspectivas: [{
            campo: "tipo.keyword",
            valor: tipo
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas: [{
                campo: "tipo.keyword",
                valor: tipo
          }]
        };
      }
      peticion.groupby = {
        perspectiva: {
          campo: "escolaridad.keyword",
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaIngreso(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ingresos',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    if(tipo == ""){
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
        };
      }
      peticion.groupby = {
        intervalo: {
          campo: "ingresosOrdinarios",
          valor: 5000,
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }else{
      if(sucursal != "CONSOLIDADO"){
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          sucursales: [{
            campo: "descripcion.keyword",
            valor: sucursal
          }],
          perspectivas: [{
            campo: "tipo.keyword",
            valor: tipo
          }]
        };
      }else{
        peticion.filter = {
          aniosmeses:{
            valores: valoresMeses
          },
          perspectivas: [{
                campo: "tipo.keyword",
                valor: tipo
          }]
        };
      }
      peticion.groupby = {
        intervalo: {
          campo: "ingresosOrdinarios",
          valor: 5000,
          subagrupacion: {
              operacion: "contar",
              campo: "numeroSocio"
          }
        }
      };
    }  
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Filtro por genero */
  public getTendenciaGraficaRangoEdadPerspectiva(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'rangoedad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "rangoedad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaGeneracionPerspectiva(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-perfil',
      'generacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "generacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacionPerspectiva(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ocupacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEscolaridadPerspectiva(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'escolaridad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
  
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "escolaridad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaIngresoPerspectiva(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ingresos',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
   
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      intervalo: {
        campo: "ingresosOrdinarios",
        valor: 5000,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Filtro por edad */
  public getTendenciaGraficaGeneracionPerspectiva2(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-perfil',
      'generacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }

    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "generacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaOcupacionPerspectiva2(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ocupacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEscolaridadPerspectiva2(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'escolaridad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
  
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "escolaridad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaIngresoPerspectiva2(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ingresos',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
   
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      intervalo: {
        campo: "ingresosOrdinarios",
        valor: 5000,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Filtro por generacion */
  public getTendenciaGraficaOcupacionPerspectiva3(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ocupacion',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "ocupacion.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaEscolaridadPerspectiva3(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'escolaridad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
  
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "escolaridad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaIngresoPerspectiva3(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ingresos',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
   
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      intervalo: {
        campo: "ingresosOrdinarios",
        valor: 5000,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Filtro por ocupación */
  public getTendenciaGraficaEscolaridadPerspectiva4(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string, ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'escolaridad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
  
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    if(ocupacion != ""){
      per.push({
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      perspectiva: {
        campo: "escolaridad.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaGraficaIngresoPerspectiva4(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string, ocupacion: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ingresos',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
   
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    if(ocupacion != ""){
      per.push({
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      intervalo: {
        campo: "ingresosOrdinarios",
        valor: 5000,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Filtro por escolaridad */
  public getTendenciaGraficaIngresoPerspectiva5(valoresAnios: number[], ultimoValor: string, tipo: string, sucursal: string, genero: string, edad: string, generacion: string, ocupacion: string, escolaridad: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-perfil',
      'ingresos',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
   
    var con : any = []
    if(sucursal != "CONSOLIDADO"){
      con.push({
          campo: "descripcion.keyword",
          valor: sucursal
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push( {
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
      per.push({
        campo: "genero.keyword",
        valor: genero
      });
    }
    if(edad != ""){
      per.push({
        campo: "rangoedad.keyword",
        valor: edad
      });
    }
    if(generacion != ""){
      per.push({
        campo: "generacion.keyword",
        valor: generacion
      });
    }
    if(ocupacion != ""){
      per.push({
        campo: "ocupacion.keyword",
        valor: ocupacion
      });
    }
    if(escolaridad != ""){
      per.push({
        campo: "escolaridad.keyword",
        valor: escolaridad
      });
    }
    peticion.filter = {
      aniosmeses: {valores: valoresMeses},
      sucursales: con,
      perspectivas:per,
    };

    peticion.groupby = {
      intervalo: {
        campo: "ingresosOrdinarios",
        valor: 5000,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };

    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
}
