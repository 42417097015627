//import { BrowserModule } from '@angular/platform-browser';
import { BrowserModule } from '@angular/platform-browser';
//import { NgModule } from '@angular/core';
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService, MessageDoneComponent, MessageWaitComponent, MessageErrorComponent } from './services/base/message.service';
import {LOCALE_ID} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
/* Importaciones  Angular Material */
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule} from '@angular/material/dialog';
//import { FormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';


import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';


/* Importaciones Modulos */
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { InicioComponent } from './inicio/inicio.component';
/* Importaciones Modulos */

/* Importación de gráficos */
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { GoogleChartsModule } from 'angular-google-charts';
/* Importación de gráficos */

/* Importación de ComboChart */
import { SharedModule } from './modules/shared/shared.module';
import { LoginModule } from './modules/login/login.module';
import { ComercialModule } from './modules/comercial/comercial.module';
import { ColocacionModule } from './modules/colocacion/colocacion.module';
import { FinancieroModule } from './modules/financiero/financiero.module';
import { GeograficoModule } from './modules/geografico/geografico.module';
import { RiesgosModule} from './modules/riesgos/riesgos.module';
import { InformeModule} from './modules/informes/informe.module';



@NgModule({
  declarations: [
    AppComponent,
    MessageDoneComponent,
    MessageWaitComponent,
    MessageErrorComponent,
    WelcomeComponent,
    MenuComponent,
    HeaderComponent,
    InicioComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    MatChipsModule,
    MatMenuModule,
    MatDividerModule,
    NgxChartsModule,
    ChartsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    GoogleChartsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    SharedModule,
    LoginModule,
    ComercialModule,
    ColocacionModule,
    FinancieroModule,
    GeograficoModule,
    RiesgosModule,
    InformeModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es-MX'},
    MessageService,
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MessageDoneComponent,
    MessageWaitComponent,
    MessageErrorComponent,
  ]
})
export class AppModule { }
