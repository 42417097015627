<!--<div class="w3-container w3-contenedor-template " >
    <div class="w3-row" id="filtros">
      <div class="w3-col m2 hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter"><b>Financiero</b></span><span class="w3-text-deep-orange">&bull;</span><span class=" w3-margin-left-filter w3-text-orange"> Razones Financieras </span> 
      </div> 
      <div class="w3-rest">
        <div [class]="EspacioEntidad()">
          <div class="w3-row ">
            <div class="w3-col w3-tiny " style="width: 100%;" >
              <div *ngIf="administrador()"  class=" w3-half" >
                <div class="w3-bar-item" >
                  <div class="w3-col s8 w3-filtro-aniomes hijoizquierda">
                    Instituciones
                  </div>
                  <div class="w3-rest ">
                    <mat-icon id="maticonInstituciones" class="w3-tiny w3-margin-indicadores  w3-button w3-dropdown-click" (click)="myDropFuncmes()">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div id="demoInstituciones" class="w3-dropdown-content w3-light-gray w3-scroll" style="height: 25vh;width: 200px;">
                  <table class="w3-table w3-border">
                    <tr class="w3-row " *ngFor="let institucion of data_instituciones">
                      <td class="w3-margin-left-contenido">
                        <span class="w3-margin-left-contenido w3-label-inactivo">{{institucion.name}} </span>
                      </td>
                      <td>
                        <input class="w3-filtro-aniomes w3-check w3-margin-top-check w3-margin-right-contenido" 
                              (change)="institucionChange(institucion.id)" 
                              [checked]="institucion.checked" 
                              type="checkbox">
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="w3-half">
                <div class="w3-row padreizquierda">
                  <div class="w3-row hijoizquierda">
                    <div [class]="ClassAdministrador()">
                      <div style="width: 120px;">
                        {{InstitucionSelect()}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w3-col m7 ">
          <div class="w3-row ">
            <div class="w3-col w3-tiny " style="width: 100%;">
              <div class="w3-col s2 ">
                <div class="w3-bar-item" >
                  <div class="w3-twothird hijoizquierda w3-filtro-aniomes">Períodos</div>
                  <div class="w3-rest">
                      <mat-icon id="maticonPeriodo" class="w3-tiny w3-margin-indicadores  w3-button w3-dropdown-click" (click)="DropPeriodo()">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div id="demoPeriodo" class="w3-dropdown-content w3-light-gray w3-scroll" style="height: auto;width: 180px;">
                  <div class="w3-container w3-row w3-left-align" *ngFor="let itemEjercicioPeriodo of DATA_PERIODOSERIES">
                    <div class="w3-row">
                      <div class="w3-row">
                        <div class="w3-noventa w3-margin-lelf-1roN w3-label-inactivo">
                          <mat-icon [id]="'add_'+itemEjercicioPeriodo.anio" class="w3-tiny w3-button w3-dropdown-click" (click)="mostrar_primernivel(itemEjercicioPeriodo.anio); $event.stopPropagation();">add</mat-icon>
                          <mat-icon [id]="'remove_'+itemEjercicioPeriodo.anio" class="w3-tiny w3-button w3-dropdown-click" (click)="ocultar_primernivel(itemEjercicioPeriodo.anio); $event.stopPropagation();">remove</mat-icon>
                          <span> {{itemEjercicioPeriodo.anio}}</span>
                        </div>
                      </div>
                    </div>
                    <div [id]="'contenedor'+itemEjercicioPeriodo.anio" class="w3-row">
                      <div class="w3-left-align" *ngFor="let nivel1 of itemEjercicioPeriodo.series">
                        <div class="w3-table">
                          <div class="w3-row">
                            <div class="w3-half w3-margin-lelf-2doN w3-label-inactivo">
                              <span>{{nivel1.mes}}</span>
                            </div>
                            <div class="w3-rest w3-margin-top w3-right-align" >
                              <input [id]="'check_'+itemEjercicioPeriodo.anio+nivel1.mes" class="w3-filtro-aniomes w3-check w3-dropdown-click" 
                                     (change)="Indicador_Nivel1(nivel1.Id_Evaluacion)"
                                     (click)="$event.stopPropagation();"
                                     [checked]="nivel1.check" type="checkbox">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row padreizquierda">
                    <div [class]="ContarEjercicioPeriodo()">
                    <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center" 
                          style="margin-left: 10px; width: 62px; "
                          *ngFor="let am of data_evaluacionaux"
                          (click)="Indicador_Nivel1(am.Id_Evaluacion)"
                          >
                           <span class="w3-elemento-menu ">{{am.ejercicioperiodo}}</span>
                    </div>
                  </div>
                </div>              
              </div>
            </div>
          </div>
        </div>
        <div class="w3-col m1  " style="margin-left: 2vh;">
          <div class="w3-row ">
            <div class="w3-col w3-tiny" style="width: 180px;">
              <div class="">
                <div class="w3-bar-item" >
                  <div class="w3-third hijoizquierda w3-filtro-aniomes">Indicador</div> 
                  <div class="w-row w3-rest">
                    <div class="w3-row">
                      <mat-icon id="maticonIndicadores" class="w3-tiny w3-margin-indicadores w3-button w3-dropdown-click" (click)="myDropFuncindicadores()">keyboard_arrow_down</mat-icon>
                    </div>
                    <!--<div id="demoDropindicadores" [class]="IndicadoresAdministrador()">--
                    <div id="demoDropindicadores" class="w3-dropdown-content w3-dropindicadores  w3-light-gray w3-scroll" style="height: 15vh;width: 35vh;">
                      <div id="demoIndicadores" class="w3-container w3-row w3-left-align" *ngFor="let item_indicador of data_indicadorSeries"> 
                        <div class="w3-row">
                          <div class="w3-row">
                            <div class="w3-noventa  w3-margin-lelf-1roN">
                              <mat-icon [id]="'add_'+item_indicador.Id_Indicador" class="w3-tiny w3-button w3-dropdown-click" (click)="mostrar_primernivel(item_indicador.Id_Indicador)">add</mat-icon>
                              <mat-icon [id]="'remove_'+item_indicador.Id_Indicador" class="w3-tiny w3-button w3-dropdown-click" (click)="ocultar_primernivel(item_indicador.Id_Indicador)">remove</mat-icon>
                              <span> {{item_indicador.Descripcion}}</span>
                            </div>
                            <div class="w3-rest w3-right-align" style="right: 0;"><input class=" w3-filtro-aniomes w3-check w3-dropdown-click"  (change)="indicador_seleccionado(item_indicador.Id_Indicador)" [checked]="item_indicador.check"  type="checkbox"></div>
                          </div>
                        </div>
                        <div [id]="'contenedor'+item_indicador.Id_Indicador" class="w3-row">
                          <div class="w3-left-align" *ngFor="let itemN1 of item_indicador.SeriesNivel1">
                            <div class="w3-table">
                              <div class="w3-row ">
                                <div class="w3-noventa  w3-margin-lelf-2doN">
                                  <mat-icon [id]="'add2N_'+itemN1.Id_Indicador" class="w3-tiny w3-button w3-dropdown-click" (click)="mostrar_segundonivel(itemN1.Id_Indicador)">add</mat-icon>
                                  <mat-icon [id]="'remove2N_'+itemN1.Id_Indicador" class="w3-tiny w3-button w3-dropdown-click" (click)="ocultar_segundonivel(itemN1.Id_Indicador)">remove</mat-icon>
                                  <span>{{itemN1.Descripcion}}</span></div>
                                <div class="w3-rest w3-right-align" style="right: 0;">
                                  <input [id]="'check2N_'+itemN1.Id_Indicador" class="w3-filtro-aniomes w3-check w3-dropdown-click"  (change)="indicador_seleccionadoN2(itemN1.Id_Indicador)" [checked]="itemN1.check" type="checkbox"></div>
                              </div>
                            </div>
                            <div [id]="'subcontenedor'+itemN1.Id_Indicador"  class="w3-row">
                              <div  id="itemN1.Id_Indicador" class="w3-row w3-left-align" *ngFor="let itemN2 of itemN1.SeriesNivel2">
                                <div class=" w3-row">
                                  <div class="w3-noventa ">
                                    <div class="w3-margin-lelf-3roN " style="height: 2vh;">
                                      <span>{{itemN2.Descripcion}}</span>
                                    </div>
                                  </div>
                                  <div class="w3-rest w3-right-align ">
                                    <input [id]="'check3N_'+itemN2.Id_Indicador" class="w3-filtro-aniomes w3-check w3-dropdown-click" style="right: 0;" (change)="indicador_seleccionadoN3(itemN2.Id_Indicador)" [checked]="itemN2.check" type="checkbox">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w3-col m11 w3-margin-top w3-right">
          <div class="w3-right ">
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitar_filtros()"
                      [ngStyle]="{'color':'gray'}">
              <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <div class="w3-container w3-contenedor-template" style="height: 7vh;">
    <div class="w3-row">
      <!-- titulo del tablero -->
      <div class="w3-quarter padreizquierda">
        <div class="w3-row hijoizquierda">
          <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> Financiero </b></span> <!--  -->
          <span class="w3-text-deep-orange">&bull;</span>
          <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> Razones Financieras </span> <!--  -->
        </div>
      </div>
      <!-- filtros -->
      <div class="w3-rest">
        <!-- periodicidad y análisis -->
        <div class="w3-noventa2 w3-row">
          <!-- espacio en blanco para sucursales -->
          <div class="w3-quince padreizquierda" style="margin-right: 1vh;"></div>
          <!-- Filtro por periodicidad -->
          <div class="w3-twothird padreizquierda" style="margin-right: 1vh;">
            <!-- Periodos disponibles -->
            <div class="w3-quarter_e hijoizquierda">
              <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer"> <!--  -->
                Períodos
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium" #menuPer="matMenu">
                <ng-container *ngFor="let an of DATA_PERIODOSERIES">
                  <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                    <span>{{an.anio}}</span>
                  </button>
                  <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                    <span *ngFor="let child of an.series">
                      <span *ngIf="!child.children || child.children.length === 0">
                        <button mat-menu-item (click)="$event.stopPropagation(); Indicador_Nivel1(child.Id_Evaluacion)">
                          <div class="w3-quarter">
                            <input [id]="'check_'+child.anio+child.mes" style="cursor: pointer; margin-top: 7px;"
                                      (change)="Indicador_Nivel1(child.Id_Evaluacion)"
                                      [checked]="child.check" type="checkbox"
                                      (click)="$event.stopPropagation();">
                          </div>
                          <div class="w3-rest">
                            <span style="margin-left: 5px;">{{child.mes}}</span> 
                          </div>           
                        </button>
                      </span>
                    </span>
                  </mat-menu>
                </ng-container>
              </mat-menu>
            </div>
            <!-- Periodos seleccionado -->
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row chips">
                  <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button filtroMesAnioSeleccionado"
                        *ngFor="let am of data_evaluacionaux"
                        (click)="Indicador_Nivel1(am.Id_Evaluacion)">
                        <span style="margin-left: 5px;">{{am.ejercicioperiodo}}</span>
                        <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- filtro por análisis -->
          <div class="w3-rest">
            <div class="w3-half hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios">
                Indicador
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium"  #menuanios="matMenu" >
                <ng-container *ngFor="let ind of data_indicadorSeries">
                  <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenuInd1" (click)="$event.stopPropagation(); indicador_seleccionado(ind.Id_Indicador)">
                    <div class="w3-quarter">
                      <input (change)="indicador_seleccionado(ind.Id_Indicador)"
                        style="cursor: pointer; margin-top: 7px;"
                        [checked]="ind.check" type="checkbox"
                        (click)="$event.stopPropagation();">
                    </div>
                    <div class="w3-rest">
                      <span style="margin-left: 10px;">{{ind.Descripcion}}</span> 
                    </div> 
                  </button>
                  
                  <mat-menu #childMenuInd1="matMenu" [overlapTrigger]="false">
                    <span *ngFor="let itemN1 of ind.SeriesNivel1">
                      <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenuInd2" (click)="$event.stopPropagation(); indicador_seleccionadoN2(itemN1.Id_Indicador)">
                        <div class="w3-quarter">
                          <input [id]="'check2N_'+itemN1.Id_Indicador" style="cursor: pointer; margin-top: 7px;"
                            (change)="indicador_seleccionadoN2(itemN1.Id_Indicador)" 
                            [checked]="itemN1.check" type="checkbox"
                            (click)="$event.stopPropagation();">
                        </div>
                        <div class="w3-rest p" matTooltip="{{itemN1.Descripcion}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                          <span>{{itemN1.Descripcion}}</span>
                        </div>           
                      </button>

                      <mat-menu #childMenuInd2="matMenu" [overlapTrigger]="false">
                        <span *ngFor="let itemN2 of itemN1.SeriesNivel2">
                          <button mat-menu-item (click)="$event.stopPropagation(); indicador_seleccionadoN3(itemN2.Id_Indicador)">
                            <div class="w3-quarter">
                              <input [id]="'check3N_'+itemN2.Id_Indicador" style="cursor: pointer; margin-top: 7px;"
                              (change)="indicador_seleccionadoN3(itemN2.Id_Indicador)" 
                              [checked]="itemN2.check" type="checkbox"
                              (click)="$event.stopPropagation();">
                            </div>
                            <div class="w3-rest p" matTooltip="{{itemN2.Descripcion}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                              <span>{{itemN2.Descripcion}}</span>
                            </div>           
                          </button>
                        </span>
                      </mat-menu>

                    </span>
                  </mat-menu>

                </ng-container>
              </mat-menu>
            </div>
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips">
                <div class="w3-row chips ">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Eliminar filtros -->
        <div class="w3-rest w3-right ">
          <button mat-icon-button>
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitar_filtros()"> <!--(click)="quitar_filtros()"-->
              <img src="assets/img/graficos/eraser.png">
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="w3-container " > 
    <div class="w3-row ">
      <div [ngClass]="colorTit()">
        <div class="w3-noventa w3-titulo-contenidomenu ">
          <span class="w3-margin-left-contenido w3-tituloZonas">Indicadores financieros</span>
          <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntobalancec1">&bull;</span>
          <span class=" w3-margin-left-filter w3-subtitulo">{{subtitulobalancec1}}</span>
        </div>
        <div class="w3-rest w3-right " >
          <div class="w3-right  w3-margin-top-icon-menu">
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menut1Balance">more_vert</mat-icon>
          </div>
          <mat-menu #menut1Balance="matMenu">
            <!--<button class="mat-menu-item" (click)="cambiaroption()" mat-menu-item>
              <span>Grafica</span>
            </button>-->
            <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="graficas">
              <mat-icon [ngStyle]="{'color':'gray'}">poll</mat-icon>
              <span>Grafica</span>
            </button>
            <mat-divider></mat-divider>
            <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportart1Balance">
              <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
              <span>Exportar como</span>
            </button>
            <!--<button class="mat-menu-item disable" mat-menu-item >
              <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
              <span>Ampliar</span>
            </button>-->
          </mat-menu>
          <mat-menu #exportart1Balance="matMenu">
            <button (click)="export_csv()" *ngIf="grafTabla" mat-menu-item>
              <span>CSV</span>
            </button>
            <button (click)="export_csvTodos()"  *ngIf="grafTabla"mat-menu-item>
              <span>CSV Todos</span>
            </button>
            <button (click)="export_png()"  *ngIf="grafTarjeta"mat-menu-item>
              <span> PNG </span>
            </button>
          </mat-menu>
          <mat-menu #graficas="matMenu">
            <button (click)="cambiargrafica('tabla')" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
              <span>Tabla</span>
            </button>
            <button (click)="cambiargrafica('tarjeta')" mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}">subtitles</mat-icon>
              <span>Tarjetas</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngIf="grafTabla" class="w3-row w3-white">
          <div id="tabla_informacion1" class="w3-encabezado-table "  >
            <table class="w3-table" >
              <thead>
                <tr class="w3-row w3-table-title " >
                  <th class="w3-half "><span class="w3-elemento-titulo-table ">Descripción</span></th>
                  <th class="w3-center"><span class="w3-elemento-titulo-table ">V</span></th>
                  <th class="w3-center"><span class="w3-elemento-titulo-table ">A+</span></th>
                  <th class="w3-center"><span class="w3-elemento-titulo-table ">A</span></th>
                  <th class="w3-center"><span class="w3-elemento-titulo-table ">R</span></th>
                  
                  
                  <th class="w3-center"><span class="w3-elemento-titulo-table "></span></th>
                  <th class="w3-rest w3-center" *ngFor="let evaluacion of cadena_evaluacion" >
                    <span class="w3-elemento-titulo-table">{{evaluacion.evaluacion}}</span>
                  </th>
                  <th class="w3-center"><span class="w3-elemento-titulo-table "></span></th>
                </tr>
              </thead>
              <tbody *ngFor="let item_tabla of data_ordenado">
                <tr *ngIf="item_tabla.Check" class="w3-table-trtd" >
                  <td class="w3-noventa ">
                    <div class="w3-row ">
                      <span
                        [ngClass]="{'w3-elemento-tableIndicador w3-margin-lelf-1roIndicador ':buscarnivel(item_tabla.Nivel),
                                    'w3-elemento-tableIndicador w3-margin-lelf-2doIndicador ':buscarnivel1(item_tabla.Nivel),
                                    'w3-elemento-tableIndicador w3-margin-lelf-3roIndicador ':buscarnivel2(item_tabla.Nivel)}">
                        {{item_tabla.Descripcion}}
                      </span>
                    </div>
                  </td>
                  <td class="w3-center"><span class="w3-elemento-tableIndicador ">{{FormulaGreen(item_tabla.Id_Indicador)}}</span></td>
                  <td class="w3-center"><span class="w3-elemento-tableIndicador ">{{FormulaBlue(item_tabla.Id_Indicador)}}</span></td>
                  <td class="w3-center"><span class="w3-elemento-tableIndicador ">{{FormulaYellow(item_tabla.Id_Indicador)}}</span></td>
                  <td class="w3-center"><span class="w3-elemento-tableIndicador ">{{FormulaRoja(item_tabla.Id_Indicador)}}</span></td>
                  <td></td>
                  <td class=""  *ngFor="let item of item_tabla.Series" >
                    <div class="w3-row w3-noventa w3-right-align ">
                      <div [ngClass]="{'w3-border-verde ':border_verde(item.TextoSemaforo),' w3-border-rojo':border_rojo(item.TextoSemaforo),' w3-border-amarillo ':border_amarillo(item.TextoSemaforo),' w3-border-azul ':border_azul(item.TextoSemaforo)}">
                        <!--<div>-->
                        <span class="w3-elemento-tableIndicador ">{{item.Valor}}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
      <div *ngIf="grafTarjeta" id="Tarjetas" class="w3-row w3-grey-fon " >
        <div class="w3-row" *ngIf="this.data_ordenado[0].Check">
          <!--<div [class]="colorTarjeta(dataTarjeta[0])" class="w3-col " style="margin-left: 4vh; height: 3vh; width: 0.5vh;"></div>-->
          <span class="w3-margin-left-contenidotot w3-titulo-titulotarjetas">{{dataNomTarj[0]}}</span>
        </div>
        <div class="w3-quarter" style="margin-top: 1vh;" *ngIf="this.data_ordenado[1].Check">
          <!--<div [class]="colorTarjeta(dataTarjeta[1])" class="w3-col " style="margin-left: 4vh; height: 3vh; width: 0.5vh;"></div>-->
          <span class="w3-margin-left-contenidotot w3-titulo-contenidotarjetas">Cobertura financiera</span>
          <div class="w3-row w3-grey-fon" style="height: 2vh;"></div>
          <div class="w3-row w3-grey-fon" style="height: 46vh;">
            <div class="w3-row" *ngIf="this.data_ordenado[2].Check">
              <div [class]="colorTarjeta(dataTarjeta[2])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span class="w3-center" style="font-size: 10pt;"> Cobertura Cartera Vencida </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[2].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell;"> Cobertura cartera vencida </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[2])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[2].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="margin-top: 1vh;" *ngIf="this.data_ordenado[3].Check">
              <div [class]="colorTarjeta(dataTarjeta[3])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Solvencia </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[3].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Solvencia </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[3])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[3].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="margin-top: 1vh;" *ngIf="this.data_ordenado[4].Check">
              <div [class]="colorTarjeta(dataTarjeta[4])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Coeficiente de Liquidez </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[4].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Coeficiente de liquidez </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[4])" class="w3-rest w3-right"  style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[4].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="margin-top: 1vh;" *ngIf="this.data_ordenado[5].Check">
              <div [class]="colorTarjeta(dataTarjeta[5])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Coeficiente de Liquidez </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[4].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Índice de capitalización </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[5])" class="w3-rest w3-right"  style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[5].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="height: 2vh;"></div>
          </div>
        </div>
        <div class="w3-quarter" style="margin-top: 1vh;" *ngIf="this.data_ordenado[6].Check">
          <!--<div [class]="colorTarjeta(dataTarjeta[6])" class="w3-col " style="margin-left: 4vh; height: 3vh; width: 0.5vh;"></div>-->
          <span class="w3-margin-left-contenidotot w3-titulo-contenidotarjetas"> Calidad de Activos </span>
          <div class="w3-row w3-grey-fon" style="height: 2vh;"></div>
          <div class="w3-row w3-grey-fon" style="height: 46vh;">
            <div class="w3-row" *ngIf="this.data_ordenado[7].Check">
              <div [class]="colorTarjeta(dataTarjeta[7])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Indice de Morosidad </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[6].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Índice de morosidad </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[7])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[7].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="margin-top: 1vh;" *ngIf="this.data_ordenado[8].Check">
              <div [class]="colorTarjeta(dataTarjeta[8])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Fondeo de Activos Improductivos </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[7].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Fondeo de activos improductivos </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[8])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[8].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="height: 2vh;"></div>
          </div>
        </div>
        <div class="w3-quarter" style="margin-top: 1vh;" *ngIf="this.data_ordenado[9].Check">
          <!--<div [class]="colorTarjeta(dataTarjeta[9])" class="w3-col " style="margin-left: 4vh; height: 3vh; width: 0.5vh;"></div>-->
          <span class="w3-margin-left-contenidotot w3-titulo-contenidotarjetas"> Estructura Financiera </span>
          <div class="w3-row w3-grey-fon" style="height: 2vh;"></div>
          <div class="w3-row w3-grey-fon" style="height: 46vh;">
            <div class="w3-row" *ngIf="this.data_ordenado[10].Check">
              <div [class]="colorTarjeta(dataTarjeta[10])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Credito neto </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[9].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Crédito neto </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[10])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle; ">{{dataTarjeta[10].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="height: 2vh;"></div>
          </div>
        </div>
        <div class="w3-rest" style="margin-top: 1vh;" *ngIf="this.data_ordenado[11].Check">
          <!--<div [class]="colorTarjeta(dataTarjeta[11])" class="w3-col " style="margin-left: 4vh; height: 3vh; width: 1vh;"></div>-->
          <span class="w3-margin-left-contenidotot w3-titulo-contenidotarjetas"> Rentabilidad </span>
          <div class="w3-row w3-grey-fon" style="height: 2vh;"></div>
          <div class="w3-row w3-grey-fon" style="height: 46vh;">
            <div class="w3-row" *ngIf="this.data_ordenado[12].Check">
              <div [class]="colorTarjeta(dataTarjeta[12])" class="w3-col" style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Autosuficiencia Operativa </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[11].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Autosuficiencia operativa </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[12])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[12].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="margin-top: 1vh;" *ngIf="this.data_ordenado[13].Check">
              <div [class]="colorTarjeta(dataTarjeta[13])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Gastos de Administración y Promoción </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[12].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Gastos de administración y promoción </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[13])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[13].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="margin-top: 1vh;" *ngIf="this.data_ordenado[14].Check">
              <div [class]="colorTarjeta(dataTarjeta[14])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Rendimiento sobre los Activos </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[13].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Rendimiento sobre los activos </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[14])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[14].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="margin-top: 1vh;" *ngIf="this.data_ordenado[15].Check">
              <div [class]="colorTarjeta(dataTarjeta[15])" class="w3-col " style="margin-left: 4vh; height: 10vh; width: 1vh;"></div>
              <div class="w3-col w3-container w3-white" style="height: 10vh; width: 26vh;">
                <!--<span style="font-size: 10pt;"> Margen Financiero </span>
                <br>
                <span class="w3-right" style="font-size: 26pt;">{{dataTarjeta[14].Valor}}</span>-->
                <div class="w3-half" style="line-height: 15pt; height: 10vh; display: table; margin-top: 1vh;"> <span style="font-size: 0.8vw; display: table-cell; "> Margen financiero </span> </div>
                <div [style]="colorTextoTarjeta(dataTarjeta[15])" class="w3-rest w3-right" style="height: 10vh; display: table;"> <span style="font-size: 1.8vw; display: table-cell; vertical-align: middle;">{{dataTarjeta[15].Valor}}</span> </div>
              </div>
            </div>
            <div class="w3-row" style="height: 2vh;"></div>
          </div>
        </div>
      </div>
    
    </div>
  </div>
  
    <div class="w3-row " > 
      <div class="w3-row">
        <div class="w3-row w3-white w3-header-cuadrante w3-margin-top" style="height: 34vh;width: 100%;">
          <div class="w3-row">
            <div class="w3-twothird w3-titulo-contenidomenu ">
              <span class="w3-margin-left-contenido w3-tituloZonas">Semáforo</span><span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntobalancec1">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo">{{SubtituloUltimoPeriodo()}}</span>
            </div>
            <div class="w3-third_e w3-right " >
              <div class="w3-right w3-margin-top-icon-menu">
                <mat-icon *ngIf="showDataLabelBalanceActive" class="material-icons-outlined "
                  (click)="visibility_semaforo()"
                  [ngStyle]="{'color':'gray'}">
                  label
                </mat-icon>
                <mat-icon *ngIf="showDataLabelBalanceInactive" class="material-icons-outlined "
                  (click)="visibility_semaforo()"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                </mat-icon>
                <!--<mat-icon *ngIf="mapBalance" class="material-icons-outlined w3-disabled"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                </mat-icon>
                <mat-icon *ngIf="piechartBalance" class="material-icons-outlined w3-disabled"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                </mat-icon>-->
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menut2Balance">more_vert</mat-icon>
              </div>
              <mat-menu #menut2Balance="matMenu">
                <button mat-menu-item class="w3-caja-menu mat-menu-item" [matMenuTriggerFor]="graficastBalance">
                  <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                  <span>Gráficas</span>
                </button>
                <button class="mat-menu-item" *ngIf="flag_inspeccionar" (click)="inspeccionar()" mat-menu-item >
                  <mat-icon [ngStyle]="{'color':'gray'}">search</mat-icon>
                  <span>Inspeccionar</span>
                </button>
                <mat-divider></mat-divider>
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportartsemaforo">
                  <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                  <span>Exportar como</span>
                </button>
                <!--<button class="mat-menu-item w3-disabled" mat-menu-item >
                  <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                  <span>Ampliar</span>
                </button>-->
              </mat-menu>
              <mat-menu  #graficastBalance="matMenu">
                <button (click)="gbarras()" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Barras</span>
                </button>
                <button (click)="gmapacalor()" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                  <span>Mapa Calor</span>
                </button>
              </mat-menu>
              <mat-menu #exportartsemaforo="matMenu">
                <button (click)="downloadChart1(flag_exportar2)" mat-menu-item>
                  <span>PDF</span>
                </button>
                <button *ngIf="flag_inspeccionar" (click)="export_csv()" mat-menu-item>
                  <span>CSV</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="w3-row w3-white " style="width: 100%;height: 27vh;">
            <div class=" w3-row" style="width: 98%;">
                <ngx-charts-tree-map id="mapBalance" *ngIf="mapBalance" class="w3-col w3-border w3-border-white" style="height: 27vh; width: 96%; margin-top: -10px;"
                  [scheme]="colorScheme_balance"
                  [results]="balance_results"
                  [gradient]="gradient"
                  [animations]="animations"
                  [labelFormatting]="labelFormatting"
                  [valueFormatting]="valueFormatting"
                >
                </ngx-charts-tree-map>
                <ngx-charts-bar-vertical id="chartverticalBalance" *ngIf="chartverticalBalance"  class="w3-col w3-border w3-border-white" style="height: 27vh; margin-top: -10px;"
                  [results]="balance_results"
                  xAxis="true"
                  yAxis="true"
                  [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel"
                  [showGridLines]="showGridLines"
                  [xAxisLabel]="xAxisLabel"
                  yAxisLabel="No. Registros"
                  [schemeType]="schemeType"
                  [scheme]="colorScheme_balance"
                  [legend]="visibilitylegendBalance"
                  legendTitle="Semáforo"
                  [yAxisTickFormatting]="yLeftTickFormat"
                  [showDataLabel]="showDataLabelBalance"
                  >
                </ngx-charts-bar-vertical>
                <div style="display: block;" *ngIf="piechartBalance" id="piechartBalance"  class="w3-col w3-border w3-border-white" style="height: 27vh; margin-top: -10px;">
                  <canvas baseChart  width="400" height="60" 
                    [data]="doughnutChartData"
                    [labels]="doughnutChartLabels"
                    [colors]="doughnutChartColors"
                    [options]="doughnutChartOptions"
                    [chartType]="doughnutChartType">
                  </canvas>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
