<div id="" class="w3-container w3-fondomenu w3-orange w3-container-cuerpotrabajo">
  <div class="w3-container w3-center " style="margin-top: 13%;">
    <div class="w3-content w3-center " style="color: #FFF">
      <img src="assets/img/logoblanco.png" style="width: 60%;">
    </div>
    <div class="w3-content w3-center" style="height: 20vh;width: 50%;">
      <div class="w3-row">
        <div *ngFor="let mod of modulosAct;let i = index">
          <div [ngClass]="lado(i)" style="cursor: pointer;" (click)="enviar(mod)">
            <div class="padre" style="cursor: pointer;">
              <div class="w3-center hijo" style="cursor: pointer;">
                <label class="w3-center w3-label-menuinicio" style="cursor: pointer;"> {{mod.name}} </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
