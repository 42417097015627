export class PatmirEntity {
    TimeId: string;
    AnioDsc: string;
    MesDsc: string;
    SexoId: string;
    SexoDsc: string;
    InstitucionPatmirId: string;
    Acronimo: string;
    SucursalId: string;
    SucursalDsc: string;
    EstatusPatmirId: number;
    EstatusPatmirDsc: string;
    TipoGradId: number;
    TipoGradDsc: string;
    EstadoId: string;
    EstadoDsc: string;
    MunicipioId: string;
    MunicipioDsc: string;
    LocalidadId: string;
    LocalidadDsc: string;
    AdultoMenorId: string;
    AdultoMenorDsc: string;
    EdadId: string;
    EdadDsc: string;
    SegmentoId: string;
    SegmentoDsc: string;
    saldo: number;
    socGradPlus: number;
    socGrad: number;
    socPre: number;
    socProc: number;
    socGradEnMes: number;
    socios: number;
}

export class InstitucionPatmirEntity {
    InstitucionPatmirId: string;
    Acronimo: string;
    Nombre: string;
    Tipo: string;
    FechaConvenio: string;
    Activa: string;
    Bansefi_InstitucionPatmirId: string;
    UltPeriodoActualizable: number;
    InstitucionPatmir_Order_By: string;
    Acro_bk: string;
}

export class TimeEntity {
    TimeId: string;
    AnioDsc: string;
    MesDsc: string;
}

export class AniosEntity {
    AnioId: string;
    AnioDsc: string;    
}
export class MesesEntity {
    MesId: string;
    MesDsc: string;    
}
export class AniosCheckEntity {
    AnioId: string;
    Check: boolean;    
}
export class MesesCheckEntity {
    MesId: string;
    MesDsc: string;
    Check: boolean;    
}
export class SucursalesCheckEntity {
    SucursalId: string;
    SucursalDsc: string;
    Check: boolean;    
}
export class QubosEntity{
    flagfecha: boolean;
    creditoid: string;
    fechacierre: string;
    anio: string;
    mes: string;
    socioclienteid: string;
    ifid: string;
    clavesociocliente: string;
    nocontrato: string;
    sucursal: string;
    clasificacion: string;
    tipoproducto: string;
    modalidadpago: string;
    fechaotorgamiento: string;
    montooriginal: number;
    fechavencimiento: string;
    tasaordinaria: number;
    tasamoratoria: number;
    plazo: number;
    frecpagocapital: number;
    frecpagointereses: number;
    diasmora: number;
    capitalvigente: number;
    capitalvencido: number;
    intdevnocobvig: number;
    intdevnocobvenc: number;
    intdevnocobctasorden: number;
    fechaultpagocapital: string;
    montoultpagocapital: number;
    fechaultpagointereses: string;
    montoultpagointereses: number;
    tipo: string;
    estatus: string;
    montogarantialiquida: number;
    tipotasaid: string;
    tipodegarantiaadicional: string;
    montodegarantiaadicional: number;
    asesordecredito: string;
    cat: number;

}
export class SocioclienteEntity{
    flagfecha: boolean;
    socioclienteid: string;
    fechacierre: string;
    anio: string;
    mes: string;
    ifid: string;
    clavesociocliente: string;
    primerap: string;
    segundoap: string;
    nombre: string;
    sexoid: string;
    fechanac: string;
    lenguaid: string;
    ocupacionid: string;
    actividadid: string;
    estadocivilid: string;
    escolaridadid: string;
    fechaalta: string;
    calle: string;
    numeroexterior: string;
    numerointerior: string;
    colonia: string;
    codigopostal: number;
    localidadid: string;
    municipioid: string;
    estadoid: string;
    capitalsocialrequerido: number;
    saldodeaportacionrequerido: number;
    saldodeaportacionexcedente: number;
    saldodeaportacionvoluntario: number;
    sucursal: string;
    usuariocaptura: string;
    fechabaja: string;
    personamoral: string;
    telefono: string;
}
export class CarteraQubosEntity{
    flagfecha: boolean;
    flag_grafica: boolean;
    creditoid: string;
    fechacierre: string;
    anio: string;
    mes: string;
    socioclienteid: string;
    ifid: string;
    instituciondesc: string;
    clavesociocliente: string;
    nocontrato: string;
    sucursal: string;
    sucursaldesc: string;
    clasificacion: string;
    tipoproducto: string;
    modalidadpago: string;
    fechaotorgamiento: string;
    montooriginal: number;
    fechavencimiento: string;
    tasaordinaria: number;
    tasamoratoria: number;
    plazo: number;
    frecpagocapital: number;
    frecpagointereses: number;
    diasmora: number;
    capitalvigente: number;
    capitalvencido: number;
    intdevnocobvig: number;
    intdevnocobvenc: number;
    intdevnocobctasorden: number;
    fechaultpagocapital: string;
    montoultpagocapital: number;
    fechaultpagointereses: string;
    montoultpagointereses: number;
    tipo: string;
    estatus: string;
    montogarantialiquida: number;
    tipotasaid: string;
    tipodegarantiaadicional: string;
    montodegarantiaadicional: number;
    asesordecredito: string;
    flag_metrica: string;
    cat: number;
    clasificacion_nac: string;
    
    estadocivilid: string;
    estadocivildesc: string;
    municipioid: string;
    municipiodesc: string;
    localidadid: string;
    localidaddesc: string;
    escolaridadid: string;
    escolaridaddesc: string;
    arreglo_anios: string;
    arreglo_mes: string;
    arreglo_dimension: string;
    arreglo_estados: string;
    arreglo_municipios: string;
    arreglo_localidades: string;
    nopagina: number;
    norows: number;
    latitud_mun: string;
    longitud_mun: string;
    latitud_edo: string;
    longitud_edo: string;
    latitud_loc: string;
    longitud_loc: string;
    estadodesc: string;
    arreglo_sucursal: string;
    bandadiasmora: string;
    sexoid: string;
    sexodesc: string;
    saldocartera: number;

    todas_columnas: boolean;

    /* Relaciones */
    estadosocio: string;
    estadocivil: string;
    ocupaciondesc: string;
    nombre: string;
    primerap: string;
    segundoap: string;
    /* Relaciones */
}

export class SocioClienteQubosEntity{
    flagfecha: boolean;
    socioclienteid: string;
    fechacierre: string;
    anio: string;
    mes: string;
    ifid: string;
    instituciondesc: string;
    clavesociocliente: string;
    primerap: string;
    segundoap: string;
    nombre: string;
    sexoid: string;
    fechanac: string;
    lenguaid: string;
    ocupacionid: string;
    actividadid: string;
    estadocivilid: string;
    escolaridadid: string;
    fechaalta: string;
    calle: string;
    numeroexterior: string;
    numerointerior: string;
    colonia: string;
    codigopostal: number;
    localidadid: string;
    municipioid: string;
    estadoid: string;
    capitalsocialrequerido: number;
    saldodeaportacionrequerido: number;
    saldodeaportacionexcedente: number;
    saldodeaportacionvoluntario: number;
    sucursal: string;
    sucursaldesc: string;
    usuariocaptura: string;
    fechabaja: string;
    personamoral: string;
    telefono: string;
    estadosocio: string;
    latitud_loc: string;
    longitud_loc: string;
    clasificacion_nac: string;
    estadocivildesc: string;
    escolaridaddesc: string;
    localidaddesc: string;
    municipiodesc: string;
    ocupaciondesc: string;
    latitud_mun: string;
    longitud_mun: string;
    latitud_edo: string;
    longitud_edo: string;
    nopagina: number;
    norows: number;
    arreglo_anios: string;
    arreglo_mes: string;
    arreglo_dimension: string;
    estadodesc: string;
    arreglo_sucursal: string;
    todas_culumnas: boolean;
}


export class auxactiveentities{
    name: string;
    value: string;
}
export class pagination{
    value: number;
}

export class ActivosInactivos {
    Sucursal: string;
    Activo: string;
    Inactivo: string;
}
export class AnioMesSocio {
    Anio: string;
    Mes: string;
    NumSocio: string;
}
export class ExportCSV {
    Descripcion1: string;
    Descripcion2: string;
    Descripcion3: string;
}
export class CarteraVCVG {
    Sucursal: string;
    Vencido: string;
    Vigente: string;
}

export class PermisosDimensionCheck{
    Name: string;
    Check: boolean;
    Value: String;

}
export class ElemMap{
    id: string;
    name: string;
    latitud_loc: string;
    longitud_loc: string;
    value: number;
}
export class Dimension{
    Id: string;
    Name: string;
    Check: boolean;
}

export class PerfilesEntity{
    perfilid: number;
    nombre: string;
    descripcion: string;
    activo: number;
}
export class Class_geografico{
    id: number;
    name: string;
    latitud_loc: string;
    longitud_loc: string;
    value: number;
}

export class Class_tabla{
    entidad: string;
    sucursal: string;
    anio: string;
    mes: string;
    colocacion: number;
    porcentaje: string;
}
export class AfiliacionCSV{
    SocioClienteID: string;
    Anio: string;
    Mes: string;
    Institucion: string;
    Sucursal: string;
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    EstadoSocio: string;
    ClasificacionEdad: string;
    Ocupacion: string;
    EstadoCivil: string;
    Escolaridad: string;
    Estado: string;
    Municipio: string;
    Localidad: string;

}

export class CaptacionQubosEntity{
    captacionid: string;
    fechacierre: string;
    anio: string;
    mes: string;
    socioclienteid: string;
    ifid: string;
    clavesociocliente: string;
    nocontrato: string;
    sucursal: string;
    fechaapertura: string;
    tipo: string;
    fechaultdeposito: string;
    fechavencimiento: string;
    plazo: number;
    formapagorendimientos: number;
    tasa: number;
    monto: number;
    intdevnopagcierremes: number;
    saldototal: number;
    estadoid: string;
    estadodesc: string;
    municipioid: string;
    municipiodesc: string;
    localidadid: string;
    localidaddesc: string;
    sucursalid: string;
    sucursaldesc: string;
    escolaridadid: string;
    escolaridaddesc: string;
    clasificacion_nac: string;
    estadocivildesc: string;
    ocupaciondesc: string;
    arreglo_sucursal: string;
    arreglo_anios: string;
    arreglo_mes: string;
    sexoid: string;

}

export class ActivosEntity{
    InstitucionId: string;
    Ejercicio: string;
    Periodo: string;
    ClaveSocioCliente: string;
    Sucursal: string;
    Municipio: string;
    Localidad: string;
    RangoEdad: string;
    Genero: string;
    ActividadOcupacion: string;
    Estado: string;
    ArregloMes: string;
    ArregloAnio: string;
    EstadoCivil: string;
}

export class RangoEdadEntity{
    Edad: number;
    RangoEdad: string;
}

export class EstadoEntitty{
    EstadoId: string;
    EstadoDsc: string;
    Estado_INEGI: string;
    Estado_RENAPO: string;
    GAIM: string;
}

export class InfoAnioMesEntitty{
    anio: string;
    mes: string;
    ifid: string;
}