/*import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tendenciav2',
  templateUrl: './tendenciav2.component.html',
  styleUrls: ['./tendenciav2.component.css']
})
export class Tendenciav2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}*/
import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router'
import * as $ from 'jquery';

import pdfMake from "pdfmake/build/pdfmake.js";
import html2canvas from 'html2canvas';
import { SucursalEntity } from './../../../entities/base/BaseEntities';
import { SucursalService } from './../../../services/sucursal/sucursal.service';
import { InfoAnioMesEntitty } from './../../../entities/base/DashboardEntities';
import { InfoaniomesService } from './../../../services/dashboard/infoaniomes.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { FiltroAnio } from 'src/app/model/filtro-anio';
import { FiltroMes } from 'src/app/model/filtro-mes';
import { RangoFecha } from 'src/app/model/rango-fechas';
import { ElasticSociosService } from 'src/app/services/elastic-socios/elastic-socios.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { AfiliacionServiceFunciones } from '../../shared/funciones-afiliacion/serviciosafiliacion';
import { FuncionesAfiliacion, AfiliacionVariablesConstantes, ConstantesAfiliacion, FuncionesArreglos, FuncionesMes, FuncionesCadenaAnioMes } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesBubble, FuncionAgruparSucursales, FuncionesExportCSV, FuncionesGenero, FuncionesBusquedasT1 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesTemplate4 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesFiltrosTemplate2 } from "../../shared/funciones-afiliacion/filtros_template2";
import { FuncionesTemplateIII } from "../../shared/funciones-afiliacion/filtros_template3";
import { PDF, PDFSecond } from './../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import { FuncionesCartera } from './../funciones_cartera';
import { Datosdummy} from './../funciones_c_pruebas';
import { CommonFunctions } from './../../../util/app-util';
import { concat } from 'rxjs';
/* Importación de Funciones */



@Component({
  selector: 'app-tendenciav2',
  templateUrl: './tendenciav2.component.html',
  styleUrls: ['./tendenciav2.component.css']
})
export class Tendenciav2Component implements OnInit {
  // Filtro años
  public flag_filtrar_anio: boolean = false;
  public arreglo_anios: Array<FiltroAnio>;
  public anios_seleccionados: Array<FiltroAnio>;

  // Filtro meses
  public arreglo_meses: Array<FiltroMes>;
  public meses_seleccionados: Array<FiltroMes>;

  // Filtros perspectivas
  public filtrosPerspectivasActivos: Array<any>;
  public filtrosPerspectivasAnidados: Array<any>;
  public flag_perspectivas: boolean = false;
  public sucursal_id: string;
  public valor_seleccionado;


  // Arreglos anio y mes
  data_anios : any [] = [
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];
  data_meses : any [] = [
                          {"name":"ene","value":"ene","check":false},
                          {"name":"feb","value":"feb","check":false},
                          {"name":"mar","value":"mar","check":false},
                          {"name":"abr","value":"abr","check":false},
                          {"name":"may","value":"ene","check":false},
                          {"name":"jun","value":"jun","check":false},
                          {"name":"jul","value":"jul","check":false},
                          {"name":"ago","value":"ago","check":true}
                          ];
  data_anios_selec: any [] = [];
  data_meses_selec: any [] = [];
  data_sucursales: any [] = [
                        {"name":"suc0","value":"suc0","check":false},
                        {"name":"suc1","value":"suc1","check":false},
                        {"name":"suc2","value":"suc2","check":false},
                        {"name":"suc3","value":"suc3","check":false},
                        {"name":"suc4","value":"suc4","check":false},
                        {"name":"suc5","value":"suc5","check":false},
                        {"name":"suc6","value":"suc6","check":false},
                        {"name":"suc7","value":"suc7","check":false}
  ];
  data_sucursales_select: any [] = [];

  //eventos de anios y mes
  event_anio(anio){
    //console.log(JSON.stringify(this.data_anios));
    this.event_act_anio(anio);
    //console.log(anio);
    //console.log(JSON.stringify(this.data_anios));
  }
  event_act_anio(anio){
    //if(this.contador_anios > 1){
      this.data_anios_selec = [];
      for(var i = 0; i < this.data_anios.length;i++){
        if(this.data_anios[i].name == anio)
          this.data_anios[i].check = !this.data_anios[i].check;
        if(this.data_anios[i].check)
          this.data_anios_selec.push(this.data_anios[i]);
      }
    //}

    this.contador_anios = this.data_anios_selec.length;
    console.log("contador anios --> " + this.contador_anios);
    this.datosBarra = Datosdummy.ConstruirArregloAniosSeleccionados(this.data_anios_selec);
  }
  event_mes(mes){
    //console.log(JSON.stringify(this.data_anios));
    if(this.contador_anios > 1){
      alert("Debe de seleccionar primero el año!!!!");
      for(var i = 0; i < this.data_meses.length;i++){
        if(this.data_meses[i].name == mes)
          this.data_meses[i].check = false;
      }

    }else{
      this.event_act_mes(mes);
    }
    //console.log(anio);
    //console.log(JSON.stringify(this.data_anios));
  }
  event_act_mes(mes){
    this.data_meses_selec = [];
    for(var i = 0; i < this.data_meses.length;i++){
      if(this.data_meses[i].name == mes)
        this.data_meses[i].check = !this.data_meses[i].check;
      if(this.data_meses[i].check)
        this.data_meses_selec.push(this.data_meses[i]);
    }
    this.datosBarra = Datosdummy.ConstruirArregloAniosSeleccionados(this.data_meses_selec);
  }
  ContarMeses(){
    if(this.data_meses_selec.length < 5 ){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny ";
    }
  }
  event_sucursal(sucursal){
    this.ent_consolidado = false;
    this.data_sucursales_select = [];
    for(var i = 0; i < this.data_sucursales.length;i++){
      if(this.data_sucursales[i].name == sucursal)
        this.data_sucursales[i].check = !this.data_sucursales[i].check;
      if(this.data_sucursales[i].check)
        this.data_sucursales_select.push(this.data_sucursales[i]);
    }
    //data_sucursales
    console.log("--->  " +  this.contador_anios + "    ------>   " + this.contador_mes );
    if(this.contador_anios == 1 && this.contador_mes == 1){
      this.datosBarra = Datosdummy.ConstruirArregloAniosSeleccionados(this.data_sucursales_select);
    }
  }
  event_sucursalTodasFalse(){
    this.data_sucursales_select = [];
    for(var i = 0; i < this.data_sucursales.length;i++){
      this.data_sucursales[i].check = false;
      this.data_sucursales_select.push(this.data_sucursales[i]);
    }
    this.datosBarra = Datosdummy.ConstruirArregloAniosSeleccionados(this.data_sucursales_select);
  }
  event_consolidado(){
    this.ent_consolidado = !this.ent_consolidado;
    if(this.ent_consolidado){
      //regresar todas las sucursales a check = false
      this.event_sucursalTodasFalse();
    }
  }
  VerificarAniosSeleccionados(){
    if(this.contador_anios > 1)
      return "w3-third w3-disabled";
    else
      return "w3-third ";
  }
  //eventos de anios y mes



  constructor(
    private sucursalService: SucursalService,
    public dataService: DataService,
    private elasticSearch: ElasticSociosService
  ) {
    FuncionesColores.inicioColores();

    this.colorScheme = FuncionesColores.setpaleta_azul('paleta_azul');

    // Se inicializan arreglos
    this.arreglo_anios = new Array<FiltroAnio>();
    this.arreglo_meses = new Array<FiltroMes>();
    this.anios_seleccionados = new Array<FiltroAnio>();
    this.meses_seleccionados = new Array<FiltroMes>();
    //this.datosBarra = new Array<any>();
    //this.data_piechart = new Array<any>();
    this.filtrosPerspectivasActivos = new Array<string>();
    this.filtrosPerspectivasAnidados = new Array<any>();
    //this.table_data = new Array<string>();
    //this.datosBarra_producto = new Array<any>();
    //this.data_piechart_genero = new Array<any>();

    this.inicializarValores();

    //this.rangoFecha = new RangoFecha();
    this.data_anios_selec = this.data_anios;
    //this.data_meses_selec = this.data_meses;
    this.data_meses_selec.push(this.data_meses[this.data_meses.length - 1]);
    this.table_data = [
      {"name":"2019","masculino":18945,"femenino":15653},
      {"name":"2020","masculino":22343,"femenino":18459},
      {"name":"2021","masculino":24354,"femenino":21342}
    ];
    this.datosBarra = [
      {"name":"2019","value":78789,"extra":{"key_as_string":2019}},
      {"name":"2020","value":85789,"extra":{"key_as_string":2020}},
      {"name":"2021","value":91086,"extra":{"key_as_string":2021}},
    ];
    this.contador_anios = this.data_anios_selec.length;
    this.contador_mes = this.data_meses_selec.length;
    console.log("Contador anios --->  " + this.contador_anios);
    this.ObtenerTotalMasculinoFemenino(this.table_data);
  }
  ObtenerTotalMasculinoFemenino(data){
    for(var i = 0; i < data.length;i++){
      this.total_masculino += data[i].masculino;
      this.total_femenino += data[i].femenino;
    }
    //console.log(this.total_masculino + "-------" + this.total_femenino );
  }
  ngOnInit(): void {
  }
  inicializarValores() { // Valores iniciales del dashboard
    //this.getfiltrosAnioMes();
    this.subtituloGrafica1 = "Todas";
    this.getfiltrosAnioMes();
    this.getGrafica();
    //this.getGraficaLineal();
    //this.getTabla();
    this.data_pie_chart = [
      {"name": "Germany","value": 40632,"extra": {"code": "de"}},
      {"name": "United States","value": 50000,"extra": {"code": "us"}},
      {"name": "France","value": 36745,"extra": {"code": "fr"}},
      {"name": "United Kingdom","value": 36240,"extra": {"code": "uk"}},
      {"name": "Spain","value": 33000,"extra": {"code": "es"}},
      {"name": "Italy","value": 35800,"extra": {"code": "it"}}
    ];
  }

  quitar_filtros() {
    //this.inicializarValores();
    this.filtrosPerspectivasActivos = [];
    this.filtrosPerspectivasAnidados = [];
    this.flag_perspectivas = false;
  }
  modal(id: string): void {}
  isNotActiveFilter(perspectiva: string): boolean {
    return this.filtrosPerspectivasActivos.filter(filtro => filtro == perspectiva).length == 0;
  }
  addPerspectiva(perspectiva: string) {
    /*this.getGraficaPerspectiva(perspectiva);
    this.getTablaPerspectiva(perspectiva);
    this.getGraficaLinealPerspectiva(perspectiva);
    this.filtrosPerspectivasActivos.push(perspectiva);
    this.filtrosPerspectivasAnidados.push({ perspectiva: perspectiva, value: this.valor_seleccionado });
    this.setSubtitulo();
    this.flag_perspectivas = false;*/

  }


  //anio y mes
  getfiltrosAnioMes() { // Obtiene los meses y años disponibles para filtrar

    // Consulta general de elastic search
    this.elasticSearch.consultaGeneral('fechas_registradas').subscribe(
      response => {
        response.message.agrupar_por_aniomes.buckets.forEach(element => {

          // Obtiene año y mes de key_as_string y los separa
          let cadenaAux = element.key_as_string;
          let aniomes = cadenaAux.split("-");
          let anioCadena = aniomes[0];
          let mesCadena = aniomes[1];
          let mesValor = parseInt(mesCadena); // Se convierte en int para generar el modelo FiltroMes

          if (this.arreglo_meses.filter(mes => mes.valor == mesValor).length == 0) {
            this.arreglo_meses.push(new FiltroMes(mesValor)); // Agrega el mes sin repetir valores
          }

          if (this.arreglo_anios.filter(anio => anio.cadena == anioCadena).length == 0) {
            this.arreglo_anios.push(new FiltroAnio(anioCadena, false));// Agrega el año sin repetir valores
          }

        });

        //Se asignan como valores iniciales el último año y último mes para consultar sucursales
        //this.arreglo_anios[this.arreglo_anios.length - 1].selected = true;
        //this.arreglo_meses[this.arreglo_meses.length - 1].selected = true;
        //this.FiltrarAniosMeses();

        //this.setSubtitulo();

        //this.getGrafica();
      }
    );
  }
  //anio y mes


  //grafica 1
  public contador_anios: number = 0;
  public contador_mes: number = 0;
  public sucursales: Array<any>;
  public showXAxis = true;
  public showYAxis = true;
  public showLegend = false;
  public showXAxisLabel = false;
  public xAxisLabel = '';
  public xAxisLabelBarraFiltroT5: string = "";
  public xAxisLabelT2C3: string = "";
  public showYAxisLabel = true;
  public yAxisLabel = '# Cuentas';
  public timeline = true;
  public showGridLines: boolean = false;
  public customColors = [{ "name": "", "value": 0 }];
  public showDataLabelT1C1: boolean = false;
  public barScaleMax: number = 5000;
  public activeEntries = [];
  public subtituloGrafica1: string = "Todos";
  public datosBarra: Array<any>;
  public datosLineal: Array<any>;
  public colorScheme: string;
  ent_consolidado: boolean = true;
    //graficas barras, lineal
    g1_barra: boolean = true;
    g1_linea: boolean = false;
    //graficas barras, lineal
  getGrafica() {
    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_inicial').subscribe(
      response => {

        //this.datosBarra = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets, 'total_contratos');

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(this.datosBarra);

        // Versión manual por si falla servicio Elastictobarchart
        let datosBarraAux = [];
        this.data_sucursales = [];

        //this.sucursales = response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets;
        this.sucursales = response.message.agrupar_por_sucursal.buckets;
        var cont = 1;
        //response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets.forEach(elemento => {
        response.message.agrupar_por_sucursal.buckets.forEach(elemento => {
          //let elementoCadena = elemento.key[0];
          //let elementoCadena = elemento.key;
          let elementoCadena = "suc" + cont.toString();
          //console.log("abre--->" + elementoCadena + " ---> " + cont );
          this.data_sucursales.push({"name":elementoCadena,"value":elementoCadena,"check":false});
          let newElemento = {
            name: elementoCadena,
            value: elemento.doc_count,
            extra: {
              //key_as_string: elemento.key_as_string
              key_as_string: elemento.key
            }
          }
          datosBarraAux.push(newElemento);
          cont++;
        });

        //this.datosBarra = datosBarraAux;
        this.datosLineal = [{"name":"entidad","series":this.datosBarra}];
        //this.datosLineal.push({"name":"entidad","series":this.datosBarra});
      }
    )
  }
  click_grafica(e) {
    console.log(JSON.stringify(e));
  }
  onSelect(e){
    console.log(JSON.stringify(e));
  }
  buscarSucursal(nombre) {
    return this.sucursales.filter(sucursal => sucursal.key == nombre)[0].key_as_string;
  }
  yLeftTickFormat(valor) {
    return CommonFunctions.retornarformatolefty(valor);
  }
  formatDataLabel(num) {
    num = num.toFixed(0);
    num += '';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? "." + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + "," + '$2');
    }
    return splitLeft + splitRight;
  }
  cambiarGrafica(grafica: string): void {
    switch(grafica){
      case 'barras':
        this.g1_barra = true;
        this.g1_linea = false;
      break;
      case 'lineal':
        this.g1_barra = false;
        this.g1_linea = true;
      break;
    }
  }
  //grafica 1
  // Tabla Cuadrante 2
  public table_data: Array<any>;
  public total_no_contratos;
  public total_suma_cartera;
  public total_masculino = 0;
  public total_femenino = 0;
  showDataLabelActiveT1C2: boolean = true;
  showDataLabelInactiveT1C2: boolean = false;
  subtitulot1c2: string = "";
  tabla_informacion2: boolean = true;
  puntot1c2: boolean = false;
  chartlineal2: boolean = false;
  piegrid2: boolean = false;

  fshowDataLabelT1C2(){}
  fregresart1c2(){}
  ClasificacionCaptacion(){}
  ClasificacionCartera(){}
  export_csvT1C2(){}
  ExportarPDFT1_C2(){}
  setFlagElementoSeleccionadoT1C2 (){}
  inspeccionar(){}
  getTabla() {
    this.elasticSearch.consultaGeneral('cuentas/estatus').subscribe(
      response => {

        this.table_data = response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets;
        response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets.forEach(row => {
          //this.total_no_contratos += row.total_contratos.value;
          //this.total_suma_cartera += row.suma_carteratotal.value;
        });
        console.log(JSON.stringify(this.table_data));

      }
    )
  }
  mostrarocultarmes(anio){
    /*
    $("#2019_04").hide();
    var flag = this.buscaranioseleccionado(anio);
    console.log("----> Anio   -----> " + anio + "  flag ---> " + flag + "\n----> " + JSON.stringify(this.data_volumenes));
    if(flag){
      //quiere decir que se habilita y se muestran las filas de meses;
      for(var i = 0; i < this.data_volumenes.length; i++){
        if(this.data_volumenes[i].name == anio){
          for(var j = 0; j < this.data_volumenes[i].series.length;j++){
            $("#"+this.data_volumenes[i].name+"_"+this.data_volumenes[i].series[j].name).show();
          }
        }
      }
    }else{
      //quiere decir que se deshabilita y se ocultan las filas de meses
      for(var i = 0; i < this.data_volumenes.length; i++){
        if(this.data_volumenes[i].name == anio){
          for(var j = 0; j < this.data_volumenes[i].series.length;j++){
            $("#"+this.data_volumenes[i].name+"_"+this.data_volumenes[i].series[j].name).hide();
          }
        }
      }
    }*/
  }
  //Tabla cuadrante2
  convertir_moneda(numero){
    var cant = new Intl.NumberFormat('es-MX').format(numero);
    if(cant.indexOf('.') == -1 ){//si se cumple el número tiene decimales
      cant = cant + ".00";
    }
    return this.formatearNormal(numero);
  }
  convertir_porcentaje(numero){
    var cant = new Intl.NumberFormat('es-MX').format(numero);
    if(cant.indexOf('.') == -1 ){//si se cumple el número tiene decimales
      cant = cant + ".00";
    }
    return FuncionesAfiliacion.formatearnormal(numero.toFixed(2));
  }
  formatearNormal(num){
    return FuncionesAfiliacion.formatearnormal(num.toFixed(0));
  }


  //tercer cuadrante
  total_afiliacioninstitucionFM: number = 0;
  data_pie_chart: any[] = [];
  estudio: string = "";
  cadena_total_socios: string = "";
  colocacion_nocontratos: number = 0;
  captacion_nocuentas: number = 0;
  total_sucursal(){return 0;}
  //tercer cuadrante
}
