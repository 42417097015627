import { Injectable } from '@angular/core';
import {MessageService} from '../base/message.service';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {Loggers} from '../../util/app-util';
import {perdidaEntity} from './../../modules/riesgos/entities_riesgos/entities_riesgos';
@Injectable({
  providedIn: 'root'
})
export class PerdidaService extends Service<perdidaEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('perdida', '', http, messageService, logger);
      this.httpClient = http;
  }
  protected getParams(entity: perdidaEntity):HttpParams{
    let params: HttpParams = new HttpParams();
    if (null == entity){return params;}
    if (null != entity.Id_Institucion){params = params.set('Id_Institucion', entity.Id_Institucion.toString())}
    if (null != entity.Periodo){params = params.set('Periodo', entity.Periodo.toString())}
    if (null != entity.NPeriodosHst){params = params.set('NPeriodosHst', entity.NPeriodosHst.toString())}
    if (null != entity.NPeriodos){params = params.set('NPeriodos', entity.NPeriodos.toString())}
    if (null != entity.Id_Zona){params = params.set('Id_Zona', entity.Id_Zona.toString())}
    if (null != entity.Id_Producto){params = params.set('Id_Producto', entity.Id_Producto.toString())}
    if (null != entity.BnConIntereses){params = params.set('BnConIntereses', entity.BnConIntereses.toString())}

    return params;
  }
}
