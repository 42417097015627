import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InformeRoutingModule } from "./informe-routing.module";
import { InformefinancieroComponent } from './informefinanciero/informefinanciero.component';
import { InformeriesgosComponent } from './informeriesgos/informeriesgos.component';
import { InformecarteraComponent } from './informecartera/informecartera.component';
import { SharedModule} from '../shared/shared.module';
import { GoogleChartsModule } from 'angular-google-charts';
import { MatChipsModule} from '@angular/material/chips';



@NgModule({
  declarations: [
    InformefinancieroComponent,
    InformeriesgosComponent,
    InformecarteraComponent
  ],
  imports: [
    CommonModule,
    InformeRoutingModule,
    SharedModule,
    GoogleChartsModule,
    MatChipsModule,
  ]
})
export class InformeModule { }
