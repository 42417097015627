import { Component, OnInit } from '@angular/core';
import {NgxChartsModule} from '@swimlane/ngx-charts'
import {ThemePalette} from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu'
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { DataSource } from '@angular/cdk/collections';
import { NgForm } from '@angular/forms';
import { NgIf } from '@angular/common';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import {perdidaEntity, concentracionEntity, mayoresDeudoresEntity} from './../entities_riesgos/entities_riesgos';
import {ConcentracionService} from './../../../services/riesgos/concentracion.service';
import { mayoresdeudoresService } from './../../../services/riesgos/mayoresdeudores.service';
import { ExcelService } from './../../../services/exportar/excel.service';
import html2canvas from 'html2canvas';
import pdfMake from "pdfmake/build/pdfmake.js";
import * as Chart from 'chart.js';

export interface PeriodicElement {
  position: number;
  name: string;
  weight: number;
}

export interface Task {
  name: string;
  check: boolean;
}

@Component({
  selector: 'app-mayores-deudores',
  templateUrl: './mayores-deudores.component.html',
  styleUrls: ['./mayores-deudores.component.css']
})
export class MayoresDeudoresComponent implements OnInit {
  // **** Variables de las graficas ****
  miles1 = true
  milTab1 = '(Miles)'
  //Variables de graficas pie
  gradient: boolean = false;
  showLegend: boolean = true;
  isDoughnut: boolean = true;
  arcWidth: number = 0.35;
  lePos = 'below'
  labels : boolean = true;
  legendPosition: any = 'right';
  colorSchemePie : any = {  
      domain: [
        '#004A97', '#68B4D6', '#82CC60', '#FFCB03', '#FC8A33', '#F74C58'
      ]
  };
  //Variables de graficas barras
  multi : any = [];
  multi2: any;
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  trimXAxisTicks: boolean = false;
  trimYAxisTicks: boolean = true;
  maxYAxisTickLength: number = 50;
  rotateXAxisTicks: boolean = true;
  showXAxisLabel: boolean = true;
  showGridLines: boolean = false;
  legendTitle: string = 'Nombre socio'
  xAxisLabel: string = 'Saldo';
  yAxisLabel: string = 'Nombre socio';
  xAxisLabel2: string = 'Saldo';
  yAxisLabel2: string = 'Cartera';
  title= 'Tendencia de perdida';
  colorScheme : any = {  
    domain: [
      '#647c8a', '#3f51b5', '#2196f3', '#00b862', '#afdf0a', '#a7b61a', '#f3e562', '#ff9800', '#ff5722', '#ff4514'
    ]
  };
  colorScheme2 : any = {  
    domain: [
      '#5EAEE5'
    ]
  };
  // Variables tarjetas
  Mayores_deudores = 'Mayores deudores';
  suma : number = 0;
  total: number;
  mayordeud: number = 0;
  persepectiva = false;
  // variable de la tabla
  displayedColumns: string[] = ['position','name','weight'];
  // **** Variables de titulos y subtitulos
  Title: string = this.milTab1+'Mayores deudores';
  numDeud = 20;
  
  // **** Variables filtros ****
  filtrosAnios:any = [];
  filtrosMeses:any = [];
  flag_filtrar_anio: boolean = false;
  checked_anio: boolean = false;
  flag_dropanio: boolean = false;
  public DatosAnios = {"aniosDisponibles":[
    {"name":"2020","value":2020,"check":false,"selected":false},
    {"name":"2021","value":2021,"check":false,"selected":false},
    {"name":"2022","value":2022,"check":true,"selected":false},],
    "mesesDisponibles":[
    {"name":"nov","value":"112020","year":"2020","check":false},
    {"name":"dic","value":"122020","year":"2020","check":false},
    {"name":"ene","value":"012021","year":"2021","check":false},
    {"name":"feb","value":"022021","year":"2021","check":false},
    {"name":"mar","value":"032021","year":"2021","check":false},
    {"name":"abr","value":"042021","year":"2021","check":false},
    {"name":"may","value":"052021","year":"2021","check":false},
    {"name":"jun","value":"062021","year":"2021","check":false},
    {"name":"jul","value":"072021","year":"2021","check":false},
    {"name":"ago","value":"082021","year":"2021","check":false},
    {"name":"sep","value":"092021","year":"2021","check":false},
    {"name":"oct","value":"102021","year":"2021","check":false},
    {"name":"nov","value":"112021","year":"2021","check":false},
    {"name":"dic","value":"122021","year":"2021","check":false},
    {"name":"ene","value":"012022","year":"2022","check":false},
    {"name":"feb","value":"022022","year":"2022","check":false},
    {"name":"mar","value":"032022","year":"2022","check":false},
    {"name":"abr","value":"042022","year":"2022","check":false},
    {"name":"may","value":"052022","year":"2022","check":false},
    {"name":"jun","value":"062022","year":"2022","check":false},
    {"name":"jul","value":"072022","year":"2022","check":false},
    {"name":"ago","value":"082022","year":"2022","check":false},
    {"name":"sep","value":"092022","year":"2022","check":false},
    {"name":"oct","value":"102022","year":"2022","check":false},
    {"name":"nov","value":"112022","year":"2022","check":false},]};
  public aniosDisponibles: any [];
  public mesesDisponibles: any [];
  public mes = 'feb';
  public anio = '2022';
  Subtitulo_grafica : string = this.anio.substring(2,4)+' | '+ this.mes +' | matriz | '+this.numDeud+' mayores';
  mesAnio = '';

  allComplete: boolean = false;
  mesesallComplete: boolean = false;
  // **** Variables de numero de deudores ****
  ndeud10 = false;
  ndeud20 = true;
  ndeud30 = false;
  ndeud40 = false;
  ndeud50 = false;
  // **** Variables para el formateo de numeros
  separador:string =  ","; // separador para los miles
  sepDecimal:string = '.'; // separador para los decimales
  //
  dataSource: any = []; 
  data: any;
  datos_pie = [{name: 'Deuda', value: 0.00001},{name: 'Cartera restante', value: 0.00001}];
  // **** Variables del web service **** 
  mayoresdeudores = new mayoresDeudoresEntity;
  concentracion = new concentracionEntity;
  concentracionPer = new concentracionEntity;
  periodo = 22022;
  // **** Variables de activación de graficas normales y modales  ****
  //Variavles grafizas zoana 1
  gbarrashor: boolean = true;
  gtablas: boolean = false;
  gbarrasver: boolean = false;
  //Variables graficas zona 2
  gpie: boolean = true;
  gbarhor: boolean = false;
  gbarver: boolean = false;
  //Variables graficas Modal
  gbarrashorModal: boolean = false;
  gtablasModal: boolean = false;
  gbarrasverModal: boolean = false;
  gpieModal: boolean = false;
  gbarhorModal: boolean = false;
  gbarverModal: boolean = false;
  // **** Constructor **** 
  constructor(
              private concentracionService: ConcentracionService,
              private mayoresdeudoresService: mayoresdeudoresService,
              private excelservices: ExcelService,
              /*private pdfService: PDF*/){
      this.periodo = Number(this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].value);
      this.mes = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].name;
      this.anio = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].year;
      this.datosdelPie();
      this.FuncionesDataSource();
      this.datosdelPie();
      this.getDatos();
  }
  ngOnInit(): void {
  }
  // **** Funciones de la consulta y tratamiento de datos ****
  FuncionesDataSource(){
    this.concentracion.Id_Institucion = 1;
    this.concentracion.Periodo = this.periodo;
    this.concentracion.ElementoAgrupador = 'EstatusCredito';
    this.concentracion.BnConIntereses = 1;
    this.funcionConcentracion(this.concentracion);
    if(!this.persepectiva){
      this.mayoresdeudores.Id_Institucion = 1;
      this.mayoresdeudores.Periodo = this.periodo;
      this.mayoresdeudores.NDatos = ''+this.numDeud;
      this.mayoresdeudores.BnAgruparDatos= 1;
      this.mayoresdeudores.BnConIntereses= 1;
      this.funcionMayoresDeudoresSinInteres(this.mayoresdeudores);
    }else{
      this.concentracionPer.Id_Institucion = 1;
      this.concentracionPer.Periodo = this.periodo;
      this.concentracionPer.ElementoAgrupador = 'agrupadorDos';
      this.concentracionPer.BnConIntereses = 1;
      this.funcionMayoresDeudoresSinInteres(this.concentracionPer);
    }
    this.actsubtitulos();
  }
  funcionConcentracion(concentracionEntity: any){
    this.concentracionService.getByAddUrl_Riesgo('concentracion',concentracionEntity).subscribe(dataArr => {
      this.maydeud(dataArr);
    });
  }
  funcionMayoresDeudoresSinInteres(mayoresdeudoresEntity: any){
    if(!this.persepectiva){
      this.mayoresdeudoresService.getByAddUrl_Riesgo('mayoresDeudores',mayoresdeudoresEntity).subscribe(dataArr => {
        this.Datos(dataArr);
      });
    }else{
      this.concentracionService.getByAddUrl_Riesgo('concentracion',mayoresdeudoresEntity).subscribe(dataArr => {
        this.Datos(dataArr);
      });
    }
  }
  maydeud(arreglo: any []){
    var aux: number =0;
    for(var i= 0; i<arreglo.length; i++){
      aux+=arreglo[i].Saldo;
    }
    this.total = aux;
  }
  Datos(arreglo: any []){
    var aux = [];
    this.multi2 = [];
    if(!this.persepectiva){
      for(var i= 0; i<arreglo.length; i++){
        aux.push({name: arreglo[i].Nombre_Socio, value: arreglo[i].Saldo});
      }
    }else{
      var tam = this.numDeud+1;
      if(tam > arreglo.length ){
        tam = arreglo.length;
      }
      for(var i= 0; i<tam; i++){
        if(arreglo[i].Nombre_Elemento != 'Sin Datos')
          aux.push({name: arreglo[i].Nombre_Elemento, value: arreglo[i].Saldo});
      }
    }
    this.multi = aux;
    var aux2 = [];
    for(var j= aux.length-1; j>=0; j--){
      if(!this.persepectiva)
        aux2.push({name: (j+1)+'-'+aux[j].name, value: aux[j].value});
      else
        aux2.push({name: aux[j].name, value: aux[j].value});
    }
    this.multi2 = aux2;
    this.mayordeud = this.multi2[0].value;
    this.DatosTabla(this.multi);
  }
  DatosTabla(arreglo: any []){
    var aux = [];
    this.data = [];
    this.suma =0;
    if(!this.persepectiva){
    this.Mayores_deudores = 'Mayores deudores';
    }
    for(var u = 0; u < arreglo.length;u++){
      aux.push({position: u+1, name: arreglo[u].name, weight: this.formatearnormal(arreglo[u].value,this.miles1)});
      this.suma += arreglo[u].value;
    }
    aux.push({position: '' ,name: 'Total:', weight: this.formatearnormal(this.suma.toFixed(2),this.miles1)});
    this.dataSource = aux;
    aux = [];
    this.datosdelPie();
  }
  perspectivaC(per: any){
    if(per == 1){
      this.persepectiva = true;
      this.colorScheme2 = {  
        domain: [
          '#0075C9'
        ]
      };
      this.Mayores_deudores = 'Grupos de riesgo';
      this.yAxisLabel= 'Grupo de riesgo';
    }
    if(per == 2){
      this.persepectiva = false;
      this.colorScheme2 = {  
        domain: [
          '#5EAEE5'
        ]
      };
      this.Mayores_deudores = 'Mayores deudores';
      this.yAxisLabel= 'Nombre socio';
    }
    this.actsubtitulos();
    this.FuncionesDataSource();
  }
  // **** Funciones de filtrado ****
  quitar_filtros(){
    var pos = 0;
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == '2022'){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false;
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i]);
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
    this.mes = this.mesesDisponibles[this.mesesDisponibles.length-1].name;
    this.anio = this.aniosDisponibles[pos].name;
    //Variavles grafizas zoana 1
    this.gbarrashor = true;
    this.gtablas = false;
    this.gbarrasver = false;
    //Variables graficas zona 2
    this.gpie = true;
    this.gbarhor = false;
    this.gbarver = false;

    this.miles1 = true
    this.milTab1 = '(Miles)'
    this.persepectiva = false;

    this.colorScheme2 = {  
      domain: [
        '#5EAEE5'
      ]
    };
    
    this.Ndeudores(20);
    this.actsubtitulos();
    this.periodo = Number(this.mesesDisponibles[this.mesesDisponibles.length-1].value);
    this.FuncionesDataSource();
  }
  getDatos(){
    this.aniosDisponibles = this.DatosAnios.aniosDisponibles;
    var aux = [];
    var pos;
    for(var i = 0; i < this.aniosDisponibles.length; i ++){
      if(this.aniosDisponibles[i].check == true){
        pos = i;
      }
    }
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i]);
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
  }
  /* funciones para los filtros */
  //AÑOS
  public anioChange(anio: any){
    var pos = 0;
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == anio.value){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i])
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
    this.mes = this.mesesDisponibles[this.mesesDisponibles.length-1].name;
    this.anio = this.aniosDisponibles[pos].name;
    this.periodo = Number(this.mesesDisponibles[this.mesesDisponibles.length-1].value);
    this.FuncionesDataSource();
  }
  public mesChange(mes: any){
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false;
    }
    var pos =0;
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      if(this.mesesDisponibles[i].name == mes.name){
        this.mesesDisponibles[i].check = true;
        pos = i;
      }
    }
    this.mes = this.mesesDisponibles[pos].name;
    this.periodo = Number(this.mesesDisponibles[pos].value);
    this.FuncionesDataSource();
  }
  actsubtitulos(){
    if(!this.persepectiva){
      this.Title = 'Mayores deudores';
      this.Subtitulo_grafica = this.anio.substring(2,4)+' | '+ this.mes +' | matriz | '+this.numDeud+' mayores deudores';
    }else{
      this.Title = 'Grupos de riesgo';
      this.Subtitulo_grafica = this.anio.substring(2,4)+' | '+ this.mes +' | matriz | '+this.numDeud+' mayores grupos de riesgo';
    }
    this.mesAnio = this.mes + ' | ' + this.anio.substring(2,4);
  }
  mesAbrev(mes: any){
    switch(mes){
      case 'ene': return 'ene'; 
      case 'feb': return 'feb'; 
      case 'mar': return 'mar'; 
      case 'abr': return 'abr'; 
      case 'may': return 'may'; 
      case 'jun': return 'jun'; 
      case 'jul': return 'jul'; 
      case 'ago': return 'ago'; 
      case 'sep': return 'sep'; 
      case 'oct': return 'oct'; 
      case 'nov': return 'nov'; 
      case 'dic': return 'dic';
      default : return '';
    }
  }
  // **** Funciones numero de deudores ****
  Ndeudores(e: any){
    if(e == 10){
      this.numDeud = 10;
      this.ndeud10 = true;
      this.ndeud20 = false;
      this.ndeud30 = false;
      this.ndeud40 = false;
      this.ndeud50 = false;    
    }
    if(e == 20){
      this.numDeud = 20;
      this.ndeud10 = false;
      this.ndeud20 = true;
      this.ndeud30 = false;
      this.ndeud40 = false;
      this.ndeud50 = false; 
    }
    if(e == 30){
      this.numDeud = 30;
      this.ndeud10 = false;
      this.ndeud20 = false;
      this.ndeud30 = true;
      this.ndeud40 = false;
      this.ndeud50 = false; 
    }
    if(e == 40){
      this.numDeud = 40;
      this.ndeud10 = false;
      this.ndeud20 = false;
      this.ndeud30 = false;
      this.ndeud40 = true;
      this.ndeud50 = false; 
    }
    if(e == 50){
      this.numDeud = 50;
      this.ndeud10 = false;
      this.ndeud20 = false;
      this.ndeud30 = false;
      this.ndeud40 = false;
      this.ndeud50 = true; 
    }
    this.actsubtitulos();
    this.FuncionesDataSource();
  }
  // **** Funcion de seleccion ****
  onSelect(e: any){
    for(var i=this.multi.length-1; i>=0; i--){
      if(this.multi2[i].name == e.name){
        this.Mayores_deudores = 'Deudor '+(20-i);
        this.suma = e.value;
      }
    }
    this.datosdelPie();
  }
  // **** Función de formateo de datos ****
  formatearnormal2(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  formatearnormal(num: any, miles: any){
    if(miles == true)
      num = (num/1000).toFixed(2);
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  desForm(num: any){
    if(num.includes(',')){  
      var pos = num.indexOf(',');
      var aux = num.slice(0,pos)+num.slice(pos+1);
      num = aux
      while(num.includes(',')){
        var pos = num.indexOf(',');
        var aux = num.slice(0,pos)+num.slice(pos+1);
        num = aux
      }
    }
    num = Number(num);
    return num;
  }
  CambMon( moneda: any, tab: any ){
    if(moneda == 'Mil' && tab == 1){
      this.miles1 = true;
      this.milTab1 = '(Miles)';
    }
    if(moneda == 'Pesos' && tab == 1 ){
      this.miles1 = false;
      this.milTab1 = '';
    }
    this.FuncionesDataSource();
  }
  // **** Función de porcentaje ****
  PorcentajeTot(num: any){
    num=num*100/this.total;
    return num.toFixed(2);
  }
  // **** Exportar ****
  export_csv(){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    //this.desForm(this.dataSource[0].mes2)
    var encabezado_excel = ['No','Nombre del socio','Saldo'];
    var cadena_evaluacion = encabezado_excel;
    name = this.numDeud+' '+this.Title ;
    for(var i = 0; i < this.dataSource.length; i++){
      aux.push(this.dataSource[i].position);
      aux.push(this.dataSource[i].name);
      aux.push(this.desForm(this.dataSource[i].weight));
      data_excel.push(aux);
      aux = [];
    }
    this.excelservices.generateExcelMayDeud(encabezado_excel,data_excel,cadena_evaluacion.length,name);
  }
  export_img(zon: any){
    var grafica: string = "";
    var name: string = "";
    if(zon == 1){
        grafica = "Zona1";
        if(this.gbarrashor)
          name = 'GraficaBrarrasHor';
        else
          name = 'GraficaBarrasVer' ;
    }
    if(zon == 2){
      grafica = "Zona2";
      if(this.gpie)
          name = 'GraficaPie';
      if(this.gbarver)
        name = 'GraficaBarrasVer';
      if(this.gbarhor)
        name = 'GraficaBarrasHor';
    }
    PDF.exportimagenes(grafica,name);
  }
  // **** Actualización de los datos del pie ****
  datosdelPie(){
    var Saux2: any = this.suma;
    var Saux: any = this.total-this.suma;
    var aux: number = Number(Saux);
    var aux2: number = Number(Saux2);
    if(!this.persepectiva){
      this.datos_pie = [{name: 'Mayores deudores', value: aux2},{name: 'Cartera restante', value: aux}];
    }else{
      this.datos_pie = [{name: 'Grupos de riesgo', value: aux2},{name: 'Cartera restante', value: aux}];
    }
  }
  // **** Funcones de activación de graficas normales y modales ****
  //Graficas zona 1
  gbarrasHor(){
    this.gbarrashor = true;
    this.gtablas = false;
    this.gbarrasver = false;
  }
  gTabla(){
    this.gbarrashor = false;
    this.gtablas = true;
    this.gbarrasver = false;
  }
  gbarrasVer(){
    this.gbarrashor = false;
    this.gtablas = false;
    this.gbarrasver = true;
  }
  ampliar(){
      if(this.gbarrashor){
        this.gbarrashorModal = true;
        this.gtablasModal = false;
        this.gbarrasverModal = false;
        this.gpieModal = false;
      }
      if(this.gbarrasver){
        this.gbarrashorModal = false;
        this.gtablasModal = false;
        this.gbarrasverModal = true;
        this.gpieModal = false;
      }
      if(this.gtablas){
        this.gbarrashorModal = false;
        this.gtablasModal = true;
        this.gbarrasverModal = false;
        this.gpieModal = false;
      }
  }
  //Graficas zona 2
  gPie(){
    this.gpie = true;
    this.gbarhor = false;
    this.gbarver = false;
  }
  gBarhor(){
    this.gpie = false;
    this.gbarhor = true;
    this.gbarver = false;
  }
  gBarver(){
    this.gpie = false;
    this.gbarhor = false;
    this.gbarver = true;
  }
  //Graficas del Modal
  ampliarG2(){
    if(this.gpie){
      this.gbarrashorModal = false;
      this.gtablasModal = false;
      this.gbarrasverModal = false;
      this.gpieModal = true;
      this.gbarverModal = false;
      this.gbarhorModal = false;
    }
    if(this.gbarhor){
      this.gbarrashorModal = false;
      this.gtablasModal = false;
      this.gbarrasverModal = false;
      this.gpieModal = false;
      this.gbarverModal = false;
      this.gbarhorModal = true;
    }
    if(this.gbarver){
      this.gbarrashorModal = false;
      this.gtablasModal = false;
      this.gbarrasverModal = false;
      this.gpieModal = false;
      this.gbarverModal = true;
      this.gbarhorModal = false;
    }
  }
  cerrarAmp(){
    this.gbarrashorModal = false;
    this.gtablasModal = false;
    this.gbarrasverModal = false;
    this.gpieModal = false;
    this.gbarverModal = false;
    this.gbarhorModal = false;
  }
}