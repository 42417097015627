<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!--TITULO DEL SUBMODULO-->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>

    <!--FILTROS-->
    <div class="w3-rest">
      <!--AÑOS Y MES-->
      <div class="w3-noventa2">

        <!--ANIOS-->
        <div class="w3-half">
          <!--ANIOS DISPONIBLES-->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Año
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuanios="matMenu">
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                (change)="cambiodeAnio(anio)">
                    {{ anio.value }}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!--FIN DE ANIOS DISPONIBLES-->
          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips " id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="anios.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let anios of aniosSeleccionados"
                            style="margin-left: 5px;" class="filtroMesAnioSeleccionado" >
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>

        <!--MESES-->
        <div class="w3-half">
          <!--MESES DISPONIBLES-->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              Mes
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menumeses="matMenu">
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}" aria-label="First checkbox"
                                (change)="cambiodeMes(mes)">
                    {{ mes.name }}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!--FIN DE MESES DISPONIBLES-->
          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda ">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="meses.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let meses of mesesSeleccionados"
                            class="filtroMesAnioSeleccionado" 
                            style="height: 20px;width: 42px;margin-left: 10px;">
                            {{meses.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>

      </div>
      <!--BORRAR FILTROS-->
      <div class="w3-rest w3-right">
        <button mat-icon-button>
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="borrarFil()">
            <img src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="w3-container w3-contenedor-template" style="height: 85vh;">

  <div class="w3-row zonaTabla">
    <div class="w3-half">
      <div class="w3-row w3-white">
        <div class="w3-noventa w3-titulo-contenidomenu">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo}} </span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
          </div>
        </div>
        <!--Menú-->
        <div class="w3-rest w3-right-align_rest">
          <div class="w3-rest hijoderechaTitle w3-tiny ">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModal(0)">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
              more_vert
            </mat-icon>
            <mat-menu #menu="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportar">
                <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                <span>Exportar como</span>
              </button>
            </mat-menu>
            <mat-menu #exportar="matMenu">
              <button mat-menu-item (click)="export_excel(0)">
                <span>CSV</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class="w3-row w3-encabezado-table w3-scroll tablas">
        <table mat-table [dataSource]="dataSource4" class="w3-table-all">
          <ng-container matColumnDef="diasMor">
            <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span> {{element.dMora}} </span> 
            </td>
          </ng-container>
          <ng-container matColumnDef="epcr1">
            <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
            <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span *ngIf="i != (dataSource4.length-1)"> {{element.Epcr1}} % </span>
            </td>
          </ng-container>
          <!--<ng-container matColumnDef="epcr2">
            <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
            <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
            </td>
          </ng-container>-->
          <ng-container matColumnDef="salC">
            <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
            <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span> {{formatearnormal(element.slCap)}} </span>
            </td>
          </ng-container>
          <!--<ng-container matColumnDef="carT1">
            <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
            <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span> {{formatearnormal(element.cart1)}} </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="carT2">
            <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
            <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span> {{formatearnormal(element.cart2)}} </span>
            </td>
          </ng-container>-->
          <ng-container matColumnDef="sEpcr">
            <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
            <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span> {{formatearnormal(element.epcrT)}} </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="porcen">
            <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
            <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
              <span> {{formatearnormalDec(element.porce)}} % </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
    <div class="w3-rest" id="grafGene">
      <div class="w3-row w3-white" style="margin-left: 1vw;">
        <div class="w3-noventa w3-titulo-contenidomenu">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo}} </span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
          </div>
        </div>
        <!--Menú-->
        <div class="w3-rest w3-right-align_rest">
          <div class="w3-rest hijoderechaTitle w3-tiny ">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModalGraf(0)">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuGraf">
              more_vert
            </mat-icon>
            <mat-menu #menuGraf="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarImg">
                <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                <span>Exportar como</span>
              </button>
            </mat-menu>
            <mat-menu #exportarImg="matMenu">
              <button mat-menu-item (click)="exportar_img(0)">
                <span>PGN</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class="w3-row graficasLat">
        <div *ngIf="dataGraf1.length != 0" class="w3-row" style="height: 35vh; width: 95%; margin: auto;">
          <ngx-charts-pie-grid style="height: 27vh; width: 96%; margin-top: -10px;"
            [scheme]="colorScheme_balance"
            [results]="dataGraf1">
          </ngx-charts-pie-grid>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="cartCons">
    <div class="w3-row zonaTabla">
      <div class="w3-half">
        <div class="w3-row w3-white">
          <div class="w3-noventa w3-titulo-contenidomenu">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo1}} </span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModal(1)">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                more_vert
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportar">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>Exportar como</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspec">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">account_tree</mat-icon>
                  <span>Perspectivas</span>
                </button>
              </mat-menu>
              <mat-menu #exportar="matMenu">
                <button mat-menu-item (click)="export_excel(1)">
                  <span>CSV</span>
                </button>
              </mat-menu>
              <mat-menu #perspec="matMenu">
                <button mat-menu-item (click)="perspective(1)">
                  <span>Consumo</span>
                </button>
                <button mat-menu-item (click)="perspective(2)">
                  <span>Comercial</span>
                </button>
                <button mat-menu-item (click)="perspective(3)">
                  <span>Vivienda</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="w3-row w3-encabezado-table w3-scroll tablas">
          <table mat-table [dataSource]="dataSource" class="w3-table-all">
            <ng-container matColumnDef="diasMor">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
              <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{element.dMora}} </span> 
              </td>
            </ng-container>
            <ng-container matColumnDef="epcr1">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
              <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr1}} % </span>
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="epcr2">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
              <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
              </td>
            </ng-container>-->
            <ng-container matColumnDef="salC">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.slCap)}} </span>
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="carT1">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.cart1)}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="carT2">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.cart2)}} </span>
              </td>
            </ng-container>-->
            <ng-container matColumnDef="sEpcr">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.epcrT)}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="porcen">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormalDec(element.porce)}} % </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
      <div class="w3-rest" id="grafCons">
        <div class="w3-row w3-white" style="margin-left: 1vw;">
          <div class="w3-noventa w3-titulo-contenidomenu">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo1}} </span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModalGraf(1)">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuG">
                more_vert
              </mat-icon>
              <mat-menu #menuG="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarG1">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>Exportar como</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspec">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">account_tree</mat-icon>
                  <span>Perspectivas</span>
                </button>
              </mat-menu>
              <mat-menu #exportarG1="matMenu">
                <button mat-menu-item (click)="exportar_img(1)">
                  <span>PGN</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="w3-row graficasLat">
          <div *ngIf="dataGraf2.length != 0" class="w3-row" style="height: 35vh; width: 95%; margin: auto;">
            <ngx-charts-pie-grid style="height: 27vh; width: 96%; margin-top: -10px;"
              [scheme]="colorScheme_balance"
              [results]="dataGraf2">
            </ngx-charts-pie-grid>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="cartCome">
    <div class="w3-row zonaTabla">
      <div class="w3-half">
        <div class="w3-row w3-white">
          <div class="w3-noventa w3-titulo-contenidomenu">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo2}} </span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModal(2)">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu2">
                more_vert
              </mat-icon>
              <mat-menu #menu2="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportar2">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>Exportar como</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspec">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">account_tree</mat-icon>
                  <span>Perspectivas</span>
                </button>
              </mat-menu>
              <mat-menu #exportar2="matMenu">
                <button mat-menu-item (click)="export_excel(2)">
                  <span>CSV</span>
                </button>
              </mat-menu>
              <mat-menu #perspec="matMenu">
                <button mat-menu-item (click)="perspective(1)">
                  <span>Consumo</span>
                </button>
                <button mat-menu-item (click)="perspective(2)">
                  <span>Comercial</span>
                </button>
                <button mat-menu-item (click)="perspective(3)">
                  <span>Vivienda</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="w3-row w3-encabezado-table w3-scroll tablas">
          <table mat-table [dataSource]="dataSource2" class="w3-table-all">
            <ng-container matColumnDef="diasMor">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
              <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{element.dMora}} </span> 
              </td>
            </ng-container>
            <ng-container matColumnDef="epcr1">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
              <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span *ngIf="i != (dataSource2.length-1)"> {{element.Epcr1}} % </span>
              </td>
            </ng-container>-
            <!--<ng-container matColumnDef="epcr2">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
              <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
              </td>
            </ng-container>-->
            <ng-container matColumnDef="salC">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.slCap)}} </span>
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="carT1">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.cart1)}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="carT2">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.cart2)}} </span>
              </td>
            </ng-container>-->
            <ng-container matColumnDef="sEpcr">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.epcrT)}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="porcen">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormalDec(element.porce)}} % </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
      <div class="w3-rest" id="grafCome">
        <div class="w3-row w3-white" style="margin-left: 1vw;">
          <div class="w3-noventa w3-titulo-contenidomenu">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo2}} </span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModalGraf(2)">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuG2">
                more_vert
              </mat-icon>
              <mat-menu #menuG2="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarG2">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>Exportar como</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspec">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">account_tree</mat-icon>
                  <span>Perspectivas</span>
                </button>
              </mat-menu>
              <mat-menu #exportarG2="matMenu">
                <button mat-menu-item (click)="exportar_img(2)">
                  <span>PGN</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="w3-row graficasLat">
          <div *ngIf="dataGraf3.length != 0" class="w3-row" style="height: 35vh; width: 95%; margin: auto;">
            <ngx-charts-pie-grid style="height: 27vh; width: 96%; margin-top: -10px;"
              [scheme]="colorScheme_balance"
              [results]="dataGraf3">
            </ngx-charts-pie-grid>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="cartVivi">
    <div class="w3-row zonaTabla">
      <div class="w3-half">
        <div class="w3-row w3-white">
          <div class="w3-noventa w3-titulo-contenidomenu">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo3}} </span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModal(3)">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu3">
                more_vert
              </mat-icon>
              <mat-menu #menu3="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportar3">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>Exportar como</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspec">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">account_tree</mat-icon>
                  <span>Perspectivas</span>
                </button>
              </mat-menu>
              <mat-menu #exportar3="matMenu">
                <button mat-menu-item (click)="export_excel(3)">
                  <span>CSV</span>
                </button>
              </mat-menu>
              <mat-menu #perspec="matMenu">
                <button mat-menu-item (click)="perspective(1)">
                  <span>Consumo</span>
                </button>
                <button mat-menu-item (click)="perspective(2)">
                  <span>Comercial</span>
                </button>
                <button mat-menu-item (click)="perspective(3)">
                  <span>Vivienda</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="w3-row w3-encabezado-table w3-scroll tablas">
          <table mat-table [dataSource]="dataSource3" class="w3-table-all">
            <ng-container matColumnDef="diasMor">
              <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
              <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{element.dMora}} </span> 
              </td>
            </ng-container>
            <ng-container matColumnDef="epcr1">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
              <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span *ngIf="i != (dataSource3.length-1)"> {{element.Epcr1}} % </span>
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="epcr2">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
              <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
              </td>
            </ng-container>-->
            <ng-container matColumnDef="salC">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.slCap)}} </span>
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="carT1">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.cart1)}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="carT2">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.cart2)}} </span>
              </td>
            </ng-container>-->
            <ng-container matColumnDef="sEpcr">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormal(element.epcrT)}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="porcen">
              <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
              <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                <span> {{formatearnormalDec(element.porce)}} % </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
      <div class="w3-rest" id="grafVivi">
        <div class="w3-row w3-white" style="margin-left: 1vw;">
          <div class="w3-noventa w3-titulo-contenidomenu">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido w3-tituloZonas"> {{Titulo3}} </span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo"> {{subtitulo}} </span>
            </div>
          </div>
          <!--Menú-->
          <div class="w3-rest w3-right-align_rest">
            <div class="w3-rest hijoderechaTitle w3-tiny ">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarModalGraf(3)">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuG3">
                more_vert
              </mat-icon>
              <mat-menu #menuG3="matMenu">
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarG3">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                  <span>Exportar como</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspec">
                  <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">account_tree</mat-icon>
                  <span>Perspectivas</span>
                </button>
              </mat-menu>
              <mat-menu #exportarG3="matMenu">
                <button mat-menu-item (click)="exportar_img(3)">
                  <span>PGN</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="w3-row graficasLat">
          <div *ngIf="dataGraf4.length != 0" class="w3-row" style="height: 35vh; width: 95%; margin: auto;">
            <ngx-charts-pie-grid style="height: 27vh; width: 96%; margin-top: -10px;"
              [scheme]="colorScheme_balance"
              [results]="dataGraf4">
            </ngx-charts-pie-grid>
          </div>
        </div>
      </div>
    </div>
  </ng-container >
</div>

<div id="modalTablas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> {{Titulo_modal}} </span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtitulo}}</span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="tabla1_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <table mat-table [dataSource]="dataSource" class="w3-table-all">
              <ng-container matColumnDef="diasMor">
                <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
                <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{element.dMora}} </span> 
                </td>
              </ng-container>
              <ng-container matColumnDef="epcr1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr1}} % </span>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="epcr2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="salC">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.slCap)}} </span>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="carT1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart1)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="carT2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart2)}} </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="sEpcr">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.epcrT)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="porcen">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormalDec(element.porce)}} % </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="tabla2_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <table mat-table [dataSource]="dataSource2" class="w3-table-all">
              <ng-container matColumnDef="diasMor">
                <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
                <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{element.dMora}} </span> 
                </td>
              </ng-container>
              <ng-container matColumnDef="epcr1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr1}} % </span>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="epcr2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="salC">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.slCap)}} </span>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="carT1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart1)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="carT2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart2)}} </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="sEpcr">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.epcrT)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="porcen">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource2.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormalDec(element.porce)}} % </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="tabla3_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <table mat-table [dataSource]="dataSource3" class="w3-table-all">
              <ng-container matColumnDef="diasMor">
                <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
                <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{element.dMora}} </span> 
                </td>
              </ng-container>
              <ng-container matColumnDef="epcr1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr1}} % </span>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="epcr2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="salC">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.slCap)}} </span>
                </td>
              </ng-container>
             <!--<ng-container matColumnDef="carT1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart1)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="carT2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart2)}} </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="sEpcr">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.epcrT)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="porcen">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource3.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormalDec(element.porce)}} % </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="tabla_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <table mat-table [dataSource]="dataSource4" class="w3-table-all">
              <ng-container matColumnDef="diasMor">
                <th class="w3-elemento-titulo-table" mat-header-cell *matHeaderCellDef> Días de mora </th>
                <td mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{element.dMora}} </span> 
                </td>
              </ng-container>
              <ng-container matColumnDef="epcr1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 1 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr1}} % </span>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="epcr2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % EPRC Cartera tipo 2 </th>
                <td class="textCen" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span *ngIf="i != (dataSource.length-1)"> {{element.Epcr2}} % </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="salC">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Saldo de capital </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.slCap)}} </span>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="carT1">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 1 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart1)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="carT2">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> Cartera tipo 2 </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.cart2)}} </span>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="sEpcr">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> EPRC </th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormal(element.epcrT)}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="porcen">
                <th class="w3-elemento-titulo-table textCen" mat-header-cell *matHeaderCellDef> % de la cartera total</th>
                <td class="textDer" mat-cell *matCellDef="let element; let i = index" [ngClass]="i === (dataSource4.length-1)? 'w3-elemento-titulo-table': ''"> 
                  <span> {{formatearnormalDec(element.porce)}} % </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="grafi_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <ngx-charts-pie-grid style="height: 59vh; width: 96%; margin-top: -10px;"
              [scheme]="colorScheme_balance"
              [results]="dataGraf1">
            </ngx-charts-pie-grid>
          </div>
        </div>
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="grafi1_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <ngx-charts-pie-grid style="height: 59vh; width: 96%; margin-top: -10px;"
              [scheme]="colorScheme_balance"
              [results]="dataGraf2">
            </ngx-charts-pie-grid>
          </div>
        </div>
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="grafi2_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <ngx-charts-pie-grid style="height: 59vh; width: 96%; margin-top: -10px;"
              [scheme]="colorScheme_balance"
              [results]="dataGraf3">
            </ngx-charts-pie-grid>
          </div>
        </div>
        <div class="w3-row" style="width: 98%; margin-left: 1%;" *ngIf="grafi3_modal">
          <div class="w3-row w3-encabezado-table w3-scroll" style="height: 59vh;">
            <ngx-charts-pie-grid style="height: 59vh; width: 96%; margin-top: -10px;"
              [scheme]="colorScheme_balance"
              [results]="dataGraf4">
            </ngx-charts-pie-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>