import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComercialRoutingModule } from './comercial-routing.module';
import { CarteraComponent } from './cartera/cartera.component';
import { SharedModule } from '../shared/shared.module';
import { AhorroComponent } from './ahorro/ahorro.component';
import { SociosComponent } from './socios/socios.component';
import { TendenciaComponent } from './tendencia/tendencia.component';
import { MetaVsRealComponent } from './meta-vs-real/meta-vs-real.component';
import { Tendenciav2Component } from './tendenciav2/tendenciav2.component';
import { PerfilComponent } from './perfil/perfil.component';
import { AltasBajasComponent } from './altas-bajas/altas-bajas.component';
import {MatSortModule} from '@angular/material/sort';
import { EstadisticoComponent } from './estadistico/estadistico.component';
import { Tendencia2Component } from './tendencia2/tendencia2.component';


@NgModule({
  declarations: [CarteraComponent,
    AhorroComponent,
    SociosComponent,
    TendenciaComponent,
    MetaVsRealComponent,
    Tendenciav2Component,
    PerfilComponent,
    AltasBajasComponent,
    EstadisticoComponent,
    Tendencia2Component],
  imports: [
    CommonModule,
    ComercialRoutingModule,
    SharedModule,
    MatSortModule
  ]
})
export class ComercialModule { }
