import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data-service/data.service';
import {CommonFunctions} from '../../../util/app-util';
import {FuncionesColores} from '../../shared/funciones-colores/funciones_colores';
import {MatTableDataSource} from '@angular/material/table';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import {ColocacionTendenciaService} from '../../../services/colocacion/tendencia/colocacion-tendencia.service';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-tendencia',
  templateUrl: './tendencia.component.html',
  styleUrls: ['./tendencia.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TendenciaComponent implements OnInit {
  /** Opciones de la grafica compuesta */
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = '';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Años';
  showYAxisLabel = true;
  yAxisLabel1 = 'Saldo total';
  yAxisLabel = 'Saldos';
  showGridLines = true;
  innerPadding = '20%';
  animations: boolean = true;
  lineChartScheme = {
    domain: ['#3BB0C9','#004A97']
  };

  comboBarScheme = {
    domain: ['#3BB0C9']
  };
  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';

  /*colorScheme = {
    domain: ['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6','#301CDCFF', '#67C2F6FF','#301CDCFF', '#67C2F6FF'],
  };*/

  groupPadding = 1;


  expandedDetail = 'expanded';
  expandedElement: 'expanded' | 'expanded';
  // options
  /*showXAxis = true;
  showYAxis = true;
  showLegend = true;
  legendPosition = 'below';
  legendTitle = '';
  showYAxisLabel = true;
  yAxisLabel = 'Metas';
  groupPadding = 1;*/
  /**
   * variables de filtros
   */
  public puedeFiltrarPorPerspectivas = false;
  public consolidado = true;
  public aniosDisponibles: any [];
  private mesesDisponibles: any [];
  public mesesDeAnioDisponibles: any[];
  public puedeFiltrarPorMeses = false;
  public sucursalesDisponibles: any[];
  public puedeFiltrarPorSucursales = false;
  public numeroDeSucursalesSeleccionadas = 0;
  private anioSeleccionado: object;
  public mesSeleccionado: object;
  public sucursalSeleccionada: object;
  public subtituloGraficaTabla = '';
  public subtituloGrafica2 = '';
  public subtituloTarjeta2 = '';
  /**
   * variables de graficas
   */
  public esGraficaDeBarras = true;
  public colorScheme: string = FuncionesColores.setpaleta_azul('paleta_azul');
  private datosGrafica = [];
  public minimo = 0;
  
  public customColors: object[];
  public selecAll = false;
  public datosBarra: object[];
  public datalinealcuentas = [];
  public dataLineal1: any[] = [];
  public datazona2: any[] = [];
  public databarrazon2: any[] = [];
  public datalinealzon2: any[] = [];


  title = '';
  type = 'ComboChart';
  data = [
    ["2012", 900, 390],
    ["2013", 1000, 400],
    ["2014", 1170, 440],
    ["2015", 1250, 480],
    ["2016", 1530, 540]
  ];
  columnNames = ['Year', 'Asia','Europe'];
  options = {     
     hAxis: {  
        title: ''  
     },  
     vAxis:{  
        title: ''  
     },  
     seriesType: 'bars',  
     series: {1: {type: 'line'}},
     colors: ['#004A97', '#3BB0C9', '#f3b49f', '#f6c7b6'],
     legend: {position: 'right', textStyle: {color: 'blue', fontSize: 16}}
  };


  /**
   * variables de tabla
   */
  public tableDataSource = new MatTableDataSource<object>();
  public columns: Array<object>;
  public displayedColumns: object;

  public showModal: boolean = false;
  /* Variables de la tabla de c1 */
  public datatabla = [];
  public columna = ['anio','mes','ncuentas','saldo','cvig','cven','imor']
  public columna2 = ['per','saldCap','numeroCred']
  // grafica
  public graficatabla1 = false;
  // modal
  public  graficatabla1_modal = false;
  /** Variables de las graficas de c2 */
  public dataSalCapBar = [];
  public dataSalCapLin = [];
  public dataNumCreBar = [];
  public dataNumCreLin = [];

  /** Variables de las graficas */
  // C1
  public graficacompuesta = false;
  public graficabarras = true;
  public graficalineal = false;
  public graficaarea = false;
  // C2
  public graficatabla = true;
  public graficabarrasSal = false;
  public graficabarrasCre = false;
  public graficalinealSal = false;
  public graficalinealCre = false;
  /** Modal */
  // C1
  public graficabarras_modal = false;
  public graficalineal_modal = false;
  public graficacompuesta_modal = false;
  public graficaarea_modal = false;
  // C2
  public graficatabla_modal = false;
  public graficabarrasSal_modal = false;
  public graficabarrasCre_modal = false;
  public graficalinealSal_modal = false;
  public graficalinealCre_modal = false;
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /** Variables tarjetas */
  public cartera_vigente = 0;
  public cartera_vencida = 0;
  public indice_morosidad = 0;
  public cartTot = 0;
  
  public cartera_vigente_tot = [];
  public cartera_vencida_tot = [];
  public indice_morosidad_tot = [];

  public perspectivaTi = "";
  public perGen = "";
  public perSer = "";
  public perPro = "";
  public perEst = "";
  public perMun = "";
  
  public saldo = true;
  public numer = false;

  public dataBarraNum = [];
  public dataLinealNum = [];
  /**
   * constructor y onInit (se ejecutan cada que es mandado a llamar el componente
   */

  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private tendenciaService: ColocacionTendenciaService,
    private excelservices: ExcelService,
  ) {
    this.getFiltros();
  }
  ngOnInit(): void {}
  /** funciones de filtros */
  private getFiltros(): void {
    this.filtrosService.getAniosMesesDisponibles('radiografia').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAniosMesesDisponibles(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesDisponibles;
      this.getGraficaPorAnio();
      this.actsub();
      this.actsub2();
      this.actsub3();
    });
    this.filtrosService.getSucursalesDisponibles('radiografia', 'codSucCredito-sucursalCredito').subscribe(response => {
      this.sucursalesDisponibles = this.filtrosService.formatSucursalesDisponibles(response);
      this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.length;
    });
  }
  // AÑOS
  public anioChangeListener(anioClick: object): void {
    this.filtrosService.changeElementCheckStatus(anioClick, this.aniosDisponibles);
    this.puedeFiltrarPorMeses = (this.aniosDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorSucursales = false;
    this.puedeFiltrarPorPerspectivas = false;
    this.getGraficaPorAnio();
    if (this.puedeFiltrarPorMeses) {
      this.anioSeleccionado = this.aniosDisponibles.find(e => e.check);
    }
    //this.mesesDeAnioDisponibles.forEach(anio => anio.check = false);
    this.perspectivaTi = '';
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public VerificarAniosSeleccionados(): string {
    return this.puedeFiltrarPorMeses ? 'w3-third' : 'w3-third w3-disabled';
  }
  public hizoPers(): string {
    return this.perspectivaTi == "" ? 'w3-third' : 'w3-third w3-disabled';
  }
  public Complete(){
    var i = 0;
    if(this.mesesDeAnioDisponibles != undefined){
      for(const an of this.mesesDeAnioDisponibles){
        if(an.check)
          i++;
      }
      if(i < (this.mesesDeAnioDisponibles.length))
        return false;
      else
        return true;
    }else{
      return false;
    }
  }
  public nummessel(){
    var i = 0;
    if(this.mesesDeAnioDisponibles != undefined){
      for(const an of this.mesesDeAnioDisponibles){
        if(an.check)
          i++;
      }
      if(i == (this.mesesDeAnioDisponibles.length) || i == 1)
        return true;
      else
        return false;
    }else{
      return false;
    }
  }
  // MESES
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    if (this.anioSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.aniosDisponibles, false);
      // @ts-ignore
      this.anioSeleccionado.check = true;
    }
    this.filtrosService.changeElementCheckStatus(mesClick, elementArray);
    this.puedeFiltrarPorSucursales = (this.mesesDeAnioDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorPerspectivas = false;
    this.getGraficaPorMes();
    if (this.puedeFiltrarPorSucursales) {
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.perspectivaTi = '';
    this.sucursalSeleccionada = [];
    for(const suc of this.sucursalesDisponibles){
      suc.check = false;
    }
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public mesChangeAll(valor: boolean): void{
    this.mesesDeAnioDisponibles.forEach(anio => anio.check = valor);
    if (this.anioSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.aniosDisponibles, false);
      // @ts-ignore
      this.anioSeleccionado.check = true;
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.puedeFiltrarPorSucursales = (this.mesesDeAnioDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorPerspectivas = false;
    this.getGraficaPorMes();
    if (this.puedeFiltrarPorSucursales) {
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.perspectivaTi = '';
    this.sucursalSeleccionada = [];
    for(const suc of this.sucursalesDisponibles){
      suc.check = false;
    }
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  // SUCURSALES
  public consolidadoChangeListener(): void {
    this.consolidado = !this.consolidado;
    if (!this.consolidado) {
      for(const suc of this.sucursalesDisponibles){
        suc.check = true;
      }
      if (this.mesSeleccionado) {
        this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
        // @ts-ignore
        this.mesSeleccionado.check = true;
        this.getTablaPorMes();
      }
      this.getGraficaPorSucursales();
    }else {
      // Si es consolidado entonces volvemos a la grafica por mes
      this.getGraficaPorMes();
    }
    this.perspectivaTi = '';
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public sucursalChangeListener(sucursalClick: object): void {
    if (this.mesSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
      // @ts-ignore
      this.mesSeleccionado.check = true;
    }
    this.filtrosService.changeElementCheckStatus(sucursalClick, this.sucursalesDisponibles);
    this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.filter(e => e.check).length;
    this.puedeFiltrarPorPerspectivas = (this.numeroDeSucursalesSeleccionadas === 1);
    this.getGraficaPorSucursales();
    //if (this.puedeFiltrarPorPerspectivas) {
      this.sucursalSeleccionada = this.sucursalesDisponibles.find(e => e.check);
    //}
    this.perspectivaTi = '';
    this.actsub();
  }
  public quitarFiltros(): void {
    // C1
    this.graficabarras = true;
    this.graficalineal = false;
    this.graficacompuesta = false;
    this.graficaarea = false;
    this.graficatabla1 = false;
    // C2
    this.graficatabla = true;
    this.graficabarrasCre = false
    this.graficalinealCre = false;
    this.graficabarrasSal = false
    this.graficalinealSal = false;
    this.perspectivaTi = '';
    this.perGen = '';
    this.perPro = '';
    this.perSer = '';
    this.perEst = '';
    this.perMun = '';
    this.mesesDeAnioDisponibles = [];

    this.saldo = true;
    this.numer = false;

    this.puedeFiltrarPorPerspectivas = false;
    this.puedeFiltrarPorMeses = false;
    this.puedeFiltrarPorSucursales = false;
    this.mesSeleccionado = undefined;
    this.anioSeleccionado = undefined;
    this.sucursalSeleccionada = undefined;
    this.consolidado = true;
    this.sucursalesDisponibles = [];
    this.getFiltros();
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  /** funciones para las graficas */
  public abrebMes(mes: any){
    switch(mes){
      case '01': return 'ene';
      case '02': return 'feb';
      case '03': return 'mar';
      case '04': return 'abr';
      case '05': return 'may';
      case '06': return 'jun';
      case '07': return 'jul';
      case '08': return 'ago';
      case '09': return 'sep';
      case '10': return 'oct';
      case '11': return 'nov';
      case '12': return 'dic';
    }
    return mes;
  }
  /**  */
  ySacaleMin(){
    var men = 10000000000;
    this.datosBarra.forEach(dat => {
      //@ts-ignore
      if(dat.value < men){
        //@ts-ignore
        men = dat.value;
      }
    });
    console.log(men)
    this.minimo = men-40000000;
  }
  // Consultas para recibir los datos segun sea el filtro aplicado
  /** CONSULTAS POR AÑO */
  private getGraficaPorAnio(): void {
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    var suma = [];
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];
    this.tendenciaService.saldoCapAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
      if (response.http.code === 200) {
        suma = response.message;
        this.tendenciaService.intVigAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
          if (response.http.code === 200) {
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            this.tendenciaService.intVenAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                this.tendenciaService.intMorAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
                  if (response.http.code === 200) {
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    //
                    this.datosGrafica = [];
                    this.datosGrafica = suma;

                    var suma2 = [];
                    this.tendenciaService.saldoCapAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        suma2 = response.message;
                        this.tendenciaService.intVigAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name,'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            this.tendenciaService.intVenAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name,'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                this.tendenciaService.intMorAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name,'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    this.cartera_vencida = suma2[suma2.length-1].value;
                                    this.cartera_vigente = suma[suma.length-1].value.toFixed(2) - suma2[suma2.length-1].value.toFixed(2);
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                    this.datosGrafica.forEach(e => {e.key = e.key.split('-')[0]; });

                                    suma2.forEach(dat => this.cartera_vencida_tot.push(dat.value))
                                    for(var i = 0; i < suma.length; i++){
                                      this.cartera_vigente_tot.push(suma[i].value - suma2[i].value);
                                    }
                    
                                    this.tendenciaService.getTendenciaTablaAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name).subscribe(response =>{
                                      if (response.http.code === 200) {
                                        if (this.esGraficaDeBarras){
                                          this.formatDatosBarra();
                                        }
                                        this.ySacaleMin();
                                        this.obtTablaZon1(response.message);
                                      }
                                    });

                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.getTablaPorAnio();
  }
  private actTarjetasAnio(aniosSeleccionados){
    var suma = [];
    this.tendenciaService.saldoCapAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
      if (response.http.code === 200) {
        suma = response.message;
        this.tendenciaService.intVigAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
          if (response.http.code === 200) {
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            this.tendenciaService.intVenAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                this.tendenciaService.intMorAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, '').subscribe( response => {
                  if (response.http.code === 200) {
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    var suma2 = [];
                    this.tendenciaService.saldoCapAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        suma2 = response.message;
                        this.tendenciaService.intVigAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name,'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            this.tendenciaService.intVenAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name,'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                this.tendenciaService.intMorAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name,'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    this.cartera_vencida = suma2[suma2.length-1].value;
                                    this.cartera_vigente = suma[suma.length-1].value.toFixed(2) - suma2[suma2.length-1].value.toFixed(2);
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;

                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  /** CONSULTAS POR MES */
  private getGraficaPorMes(): void {
    const mesesSeleccionados = this.filtrosService.getElmentosSeleccionados(this.mesesDeAnioDisponibles);
    var suma = [];
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];
    this.tendenciaService.saldoCapMes(mesesSeleccionados, '').subscribe( response => {
      if (response.http.code === 200) {
        suma = response.message;
        this.tendenciaService.intVigMes(mesesSeleccionados, '').subscribe( response => {
          if (response.http.code === 200) {
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            this.tendenciaService.intVenMes(mesesSeleccionados, '').subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                this.tendenciaService.intMorMes(mesesSeleccionados, '').subscribe( response => {
                  if (response.http.code === 200) {
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    this.datosGrafica = [];
                    this.datosGrafica = suma;

                    var suma2 = [];
                    this.tendenciaService.saldoCapMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        suma2 = response.message;
                        this.tendenciaService.intVigMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            this.tendenciaService.intVenMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                this.tendenciaService.intMorMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    this.cartera_vencida = suma2[suma2.length-1].value;
                                    this.cartera_vigente = suma[suma.length-1].value.toFixed(2) - suma2[suma2.length-1].value.toFixed(2);
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                    suma2.forEach(dat => this.cartera_vencida_tot.push(dat.value))
                                    for(var i = 0; i < suma.length; i++){
                                      this.cartera_vigente_tot.push(suma[i].value - suma2[i].value);
                                    }
                                    this.tendenciaService.getTendenciaTablaMes(mesesSeleccionados).subscribe(response =>{
                                      if (response.http.code === 200) {
                                        if (this.esGraficaDeBarras){
                                          this.formatDatosBarra();
                                        }
                                        this.ySacaleMin();
                                        this.obtTablaZon1(response.message);
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.getTablaPorMes();
  }
  private actTarjetasMes(mesesSeleccionados){
    var suma = [];
    this.tendenciaService.saldoCapMes(mesesSeleccionados, '').subscribe( response => {
      if (response.http.code === 200) {
        suma = response.message;
        this.tendenciaService.intVigMes(mesesSeleccionados, '').subscribe( response => {
          if (response.http.code === 200) {
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            this.tendenciaService.intVenMes(mesesSeleccionados, '').subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                this.tendenciaService.intMorMes(mesesSeleccionados, '').subscribe( response => {
                  if (response.http.code === 200) {
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }

                    var suma2 = [];
                    this.tendenciaService.saldoCapMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        suma2 = response.message;
                        this.tendenciaService.intVigMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            this.tendenciaService.intVenMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                this.tendenciaService.intMorMes(mesesSeleccionados, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    this.cartera_vencida = suma2[suma2.length-1].value;
                                    this.cartera_vigente = suma[suma.length-1].value.toFixed(2) - suma2[suma2.length-1].value.toFixed(2);
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  /** CONSULTAS POR SUCURSALES */
  private getGraficaPorSucursales(): void {
    const sucursalesSeleccionadas = this.filtrosService.formatSucursalesSeleccionadas(this.sucursalesDisponibles, 'sucursalCredito');
    // @ts-ignore

    var suma = [];
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];
    // @ts-ignore
    this.tendenciaService.saldoCapSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
      if (response.http.code === 200) {
        suma = response.message;
        // @ts-ignore
        this.tendenciaService.intVigSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
          if (response.http.code === 200) {
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            // @ts-ignore
            this.tendenciaService.intVenSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                // @ts-ignore
                this.tendenciaService.intMorSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
                  if (response.http.code === 200) {
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    var aux = [];
                    for(const d of suma){
                      for(const suc of this.sucursalesDisponibles){
                        if( d.key == suc.name && suc.check ){
                          aux.push(d);
                        }
                      }
                    }
                    this.datosGrafica = [];
                    this.datosGrafica = aux;

                    var suma2 = [];
                    //@ts-ignore
                    this.tendenciaService.saldoCapSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        suma2 = response.message;
                        // @ts-ignore
                        this.tendenciaService.intVigSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            // @ts-ignore
                            this.tendenciaService.intVenSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                // @ts-ignore
                                this.tendenciaService.intMorSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    var aux2 = [];
                                    for(const d of suma2){
                                      for(const suc of this.sucursalesDisponibles){
                                        if( d.key == suc.name && suc.check ){
                                          aux2.push(d);
                                        }
                                      }
                                    }
                                    var tot = 0;
                                    aux.forEach(dat => tot += dat.value)
                                    this.datazona2[1].salC = Number(tot).toFixed(2);
                                    this.cartera_vencida = 0;
                                    this.cartera_vigente = 0;
                                    aux2.forEach(sum => this.cartera_vencida += sum.value);
                                    for(var i = 0; i < aux.length; i++){
                                      this.cartera_vigente += (aux[i].value - aux2[i].value);
                                    }
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                    aux2.forEach(dat => this.cartera_vencida_tot.push(dat.value))
                                    for(var i = 0; i < aux.length; i++){
                                      this.cartera_vigente_tot.push(aux[i].value - aux2[i].value);
                                    }

                                    // @ts-ignore
                                    this.tendenciaService.getTendenciaTablaSuc([this.mesSeleccionado.value], sucursalesSeleccionadas).subscribe(response =>{
                                      if (response.http.code === 200) {
                                        if (this.esGraficaDeBarras){
                                          this.formatDatosBarra();
                                        }
                                        var aux = [];
                                        for(const d of response.message){
                                          for(const suc of this.sucursalesDisponibles){
                                            if( d.key == suc.name && suc.check ){
                                              aux.push(d);
                                            }
                                          }
                                        }
                                        this.obtTablaZon1(aux);
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  private actTarjetasSuc(sucursalesSeleccionadas){
    var suma = [];
    //@ts-ignore
    this.tendenciaService.saldoCapSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
      if (response.http.code === 200) {
        suma = response.message;
        // @ts-ignore
        this.tendenciaService.intVigSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
          if (response.http.code === 200) {
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            // @ts-ignore
            this.tendenciaService.intVenSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                // @ts-ignore
                this.tendenciaService.intMorSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, '').subscribe( response => {
                  if (response.http.code === 200) {
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    var aux = [];
                    for(const d of suma){
                      for(const suc of this.sucursalesDisponibles){
                        if( d.key == suc.name && suc.check ){
                          aux.push(d);
                        }
                      }
                    }
                    var suma2 = [];
                    //@ts-ignore
                    this.tendenciaService.saldoCapSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        suma2 = response.message;
                        // @ts-ignore
                        this.tendenciaService.intVigSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            // @ts-ignore
                            this.tendenciaService.intVenSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                // @ts-ignore
                                this.tendenciaService.intMorSuc([this.mesSeleccionado.value], sucursalesSeleccionadas, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    var aux2 = [];
                                    for(const d of suma2){
                                      for(const suc of this.sucursalesDisponibles){
                                        if( d.key == suc.name && suc.check ){
                                          aux2.push(d);
                                        }
                                      }
                                    }
                                    var tot = 0;
                                    aux.forEach(dat => tot += dat.value)
                                    this.datazona2[1].salC = Number(tot).toFixed(2);

                                    this.cartera_vencida = 0;
                                    this.cartera_vigente = 0;
                                    aux2.forEach(sum => this.cartera_vencida += sum.value);
                                    for(var i = 0; i < aux.length; i++){
                                      this.cartera_vigente += (aux[i].value - aux2[i].value);
                                    }
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  /** CONSULTAS POR PERSPECTIVA */
  public getGraficaPorPerspectiva(perspectiva: string): void {
    switch(perspectiva.split('.')[0]){
      case 'sexo':
        this.perspectivaTi = 'Genero';
      break;
      case 'servicio':
        this.perspectivaTi = 'Servicio';
      break;
      case 'nombreProducto':
        this.perspectivaTi = 'Producto';
      break;
      case 'estado1':
        this.perspectivaTi = 'Estatus';
      break;
      case 'municipio1':
        this.perspectivaTi = 'Municipio';
      break;
    }
    this.actsub()
    // @ts-ignore
    if (this.sucursalSeleccionada.name != '') {
      // @ts-ignore
      this.filtrosService.changeAllElementsCheckStatus(this.sucursalesDisponibles, false);
      // @ts-ignore
      this.sucursalSeleccionada.check = true;
      this.numeroDeSucursalesSeleccionadas = 1;

      var suma = [];
      var intVig = 0;
      var intVig_tot = [];
      this.cartera_vencida_tot = [];
      this.cartera_vigente_tot = [];
      // @ts-ignore
      this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
        if (response.http.code === 200) {
          suma = response.message;
          // 1599540333
          // @ts-ignore
          this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
            if (response.http.code === 200) {
              for(var i = 0; i < suma.length; i++){
                suma[i].value += response.message[i].value;
              }
              //1616768332.9962752
              // @ts-ignore
              this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
                if (response.http.code === 200) {
                  for(var i = 0; i < suma.length; i++){
                    suma[i].value += response.message[i].value;
                  }
                  //1618645300.9962752
                  // @ts-ignore
                  this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
                    if (response.http.code === 200) {
                      for(var i = 0; i < suma.length; i++){
                        suma[i].value += response.message[i].value;
                      }
                      var tot = 0;
                      suma.forEach(dat => tot += dat.value)
                      this.datazona2[1].salC = Number(tot).toFixed(2);
                      //
                      this.datosGrafica = [];
                      this.datosGrafica = suma;

                      if(this.perspectivaTi != 'Estatus' && this.perEst == ''){
                        var suma2 = [];
                        //@ts-ignore
                        this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                          if (response.http.code === 200) {
                            suma2 = response.message;
                            // 1599540333
                            // @ts-ignore
                            this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                //1616768332.9962752
                                // @ts-ignore
                                this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    //1618645300.9962752
                                    // @ts-ignore
                                    this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                                      if (response.http.code === 200) {
                                        for(var i = 0; i < suma2.length; i++){
                                          suma2[i].value += response.message[i].value;
                                        }
                                        
                                        this.cartera_vencida = 0;
                                        this.cartera_vigente = 0;
                                        suma2.forEach(sum => this.cartera_vencida += sum.value);
                                        for(var i = 0; i < suma.length; i++){
                                          this.cartera_vigente += (suma[i].value);
                                        }
                                        this.cartera_vigente -= this.cartera_vencida;
                                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                        var ex = 0;
                                        for(var i = 0; i < suma.length; i++){
                                          for(var j =0; j<suma2.length; j++){
                                            if(suma[i].key == suma2[j].key){
                                              this.cartera_vigente_tot.push(suma[i].value - suma2[j].value);
                                              this.cartera_vencida_tot.push(suma2[j].value);
                                              ex++;
                                            }
                                          }
                                          if(ex == 0){
                                            this.cartera_vigente_tot.push(suma[i].value);
                                            this.cartera_vencida_tot.push(0)
                                          }
                                          ex = 0;
                                        }

                                        // @ts-ignore
                                        this.tendenciaService.getTendenciaTablaPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun,this.sucursalSeleccionada.name).subscribe(response =>{
                                          if (response.http.code === 200) {
                                            if (this.esGraficaDeBarras){
                                              this.formatDatosBarra();
                                            }
                                            this.ySacaleMin();
                                            this.obtTablaZon1(response.message);
                                          }
                                        });
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }else if(this.perspectivaTi == 'Estatus'){
                        this.cartera_vencida = suma[suma.length-1].value;
                        this.cartera_vigente = suma[suma.length-2].value + suma[suma.length-3].value;
                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                        for(var i = 0; i < suma.length; i++){
                          if(i != (suma.length-1)){
                            this.cartera_vencida_tot.push(0);
                            this.cartera_vigente_tot.push(suma[i].value)
                          }else{
                            this.cartera_vencida_tot.push(suma[i].value);
                            this.cartera_vigente_tot.push(0)
                          }
                        }
                        // @ts-ignore
                        this.tendenciaService.getTendenciaTablaPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun,this.sucursalSeleccionada.name).subscribe(response =>{
                          if (response.http.code === 200) {
                            if (this.esGraficaDeBarras){
                              this.formatDatosBarra();
                            }
                            
                            this.ySacaleMin();
                            this.obtTablaZon1(response.message);
                          }
                        });
                      }if(this.perEst != ''){
                        switch(this.perEst){
                          case 'VIGENTE':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                            suma.forEach(dat => this.cartera_vigente_tot.push(dat.value));
                            suma.forEach(dat => this.cartera_vencida_tot.push(0));
                          break;
                          case 'ATRASADA':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;                            
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                            suma.forEach(dat => this.cartera_vigente_tot.push(dat.value));
                            suma.forEach(dat => this.cartera_vencida_tot.push(0));
                          break;
                          case 'VENCIDA':
                            this.cartera_vigente = 0;
                            this.cartera_vencida = 0;                            
                            suma.forEach(dat => this.cartera_vencida += dat.value);
                            suma.forEach(dat => this.cartera_vigente_tot.push(0));
                            suma.forEach(dat => this.cartera_vencida_tot.push(dat.value));
                          break;
                        }
                        // @ts-ignore
                        this.tendenciaService.getTendenciaTablaPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun,this.sucursalSeleccionada.name).subscribe(response =>{
                          if (response.http.code === 200) {
                            if (this.esGraficaDeBarras){
                              this.formatDatosBarra();
                            }
                            this.ySacaleMin();
                            this.obtTablaZon1(response.message);
                          }
                        });
                      }


                    }
                  });
                }
              });
            }
          });
        }
      });
    } else {
      var suma = [];
      var intVig = 0;
      var intVig_tot = [];
      this.cartera_vencida_tot = [];
      this.cartera_vigente_tot = [];
      // @ts-ignore
      this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
        if (response.http.code === 200) {
          suma = response.message;
          // @ts-ignore
          this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
            if (response.http.code === 200) {
              for(var i = 0; i < suma.length; i++){
                suma[i].value += response.message[i].value;
              }
              //1616768332.9962752
              // @ts-ignore
              this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
                if (response.http.code === 200) {
                  for(var i = 0; i < suma.length; i++){
                    suma[i].value += response.message[i].value;
                  }
                  //1618645300.9962752
                  // @ts-ignore
                  this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
                    if (response.http.code === 200) {
                      for(var i = 0; i < suma.length; i++){
                        suma[i].value += response.message[i].value;
                      }
                      //
                      var tot = 0;
                      suma.forEach(dat => tot += dat.value)
                      this.datazona2[1].salC = Number(tot).toFixed(2);
                      this.datosGrafica = [];
                      this.datosGrafica = suma;
                      
                      if(this.perspectivaTi != 'Estatus' && this.perEst == ''){
                        var suma2 = [];
                        //@ts-ignore
                        this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            suma2 = response.message;
                            // @ts-ignore
                            this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                response.message.forEach(dat => intVig += dat.value);
                                intVig_tot = response.message;
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                //1616768332.9962752
                                // @ts-ignore
                                this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    //1618645300.9962752
                                    // @ts-ignore
                                    this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                                      if (response.http.code === 200) {
                                        for(var i = 0; i < suma2.length; i++){
                                          suma2[i].value += response.message[i].value;
                                        }
                                        
                                        this.cartera_vencida = 0;
                                        this.cartera_vigente = 0;
                                        suma2.forEach(sum => this.cartera_vencida += sum.value);
                                        for(var i = 0; i < suma.length; i++){
                                          this.cartera_vigente += (suma[i].value);
                                        }
                                        this.cartera_vigente -= this.cartera_vencida;
                                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;


                                        //suma2.forEach(dat => this.cartera_vencida_tot.push(dat.value))
                                        var ex = 0;
                                        for(var i = 0; i < suma.length; i++){
                                          for(var j =0; j<suma2.length; j++){
                                            if(suma[i].key == suma2[j].key){
                                              this.cartera_vigente_tot.push(suma[i].value - suma2[j].value);
                                              this.cartera_vencida_tot.push(suma2[j].value)
                                              ex++;
                                            }
                                          }
                                          if(ex == 0){
                                            this.cartera_vigente_tot.push(suma[i].value);
                                            this.cartera_vencida_tot.push(0)
                                          }
                                          ex = 0;
                                        }
                                        //@ts-ignore
                                        this.tendenciaService.getTendenciaTablaPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun).subscribe(response =>{
                                          if (response.http.code === 200) {
                                            if (this.esGraficaDeBarras){
                                              this.formatDatosBarra();
                                            }
                                            this.ySacaleMin();
                                            this.obtTablaZon1(response.message);
                                          }
                                        });
                                        
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }else if(this.perspectivaTi == 'Estatus'){
                        this.cartera_vencida = suma[suma.length-1].value;
                        this.cartera_vigente = suma[suma.length-2].value + suma[suma.length-3].value;
                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                        for(var i = 0; i < suma.length; i++){
                          if(i != (suma.length-1)){
                            this.cartera_vencida_tot.push(0);
                            this.cartera_vigente_tot.push(suma[i].value)
                          }else{
                            this.cartera_vencida_tot.push(suma[i].value);
                            this.cartera_vigente_tot.push(0)
                          }
                        }
                        // @ts-ignore
                        this.tendenciaService.getTendenciaTablaPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun,this.sucursalSeleccionada.name).subscribe(response =>{
                          if (response.http.code === 200) {
                            if (this.esGraficaDeBarras){
                              this.formatDatosBarra();
                            }
                            this.ySacaleMin();
                            this.obtTablaZon1(response.message);
                          }
                        });
                      }if(this.perEst != ''){
                        switch(this.perEst){
                          case 'VIGENTE':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                            suma.forEach(dat => this.cartera_vigente_tot.push(dat.value));
                            suma.forEach(dat => this.cartera_vencida_tot.push(0));
                          break;
                          case 'ATRASADA':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;                            
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                            suma.forEach(dat => this.cartera_vigente_tot.push(dat.value));
                            suma.forEach(dat => this.cartera_vencida_tot.push(0));
                          break;
                          case 'VENCIDA':
                            this.cartera_vigente = 0;
                            this.cartera_vencida = 0;                            
                            suma.forEach(dat => this.cartera_vencida += dat.value);
                            suma.forEach(dat => this.cartera_vigente_tot.push(0));
                            suma.forEach(dat => this.cartera_vencida_tot.push(dat.value));
                          break;
                        }
                        // @ts-ignore
                        this.tendenciaService.getTendenciaTablaPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun,this.sucursalSeleccionada.name).subscribe(response =>{
                          if (response.http.code === 200) {
                            if (this.esGraficaDeBarras){
                              this.formatDatosBarra();
                            }
                            this.ySacaleMin();
                            this.obtTablaZon1(response.message);
                          }
                        });
                      }

                    }
                  });
                }
              });
            }
          });
        }
      });
    }
    this.getTablaPorMes();
  }
  private actTarjetasPer(perspectiva){
    this.perspectivaTi = '';
    switch(perspectiva.split('.')[0]){
      case 'sexo':
        this.perspectivaTi = 'Genero';
      break;
      case 'servicio':
        this.perspectivaTi = 'Servicio';
      break;
      case 'nombreProducto':
        this.perspectivaTi = 'Producto';
      break;
      case 'estado1':
        this.perspectivaTi = 'Estatus';
      break;
      case 'municipio1':
        this.perspectivaTi = 'Municipio';
      break;
    }
    this.actsub()
    // @ts-ignore
    if (this.sucursalSeleccionada.name != '') {
      // @ts-ignore
      this.filtrosService.changeAllElementsCheckStatus(this.sucursalesDisponibles, false);
      // @ts-ignore
      this.sucursalSeleccionada.check = true;
      this.numeroDeSucursalesSeleccionadas = 1;

      var suma = [];
      var intVig = 0;
      // @ts-ignore
      this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
        if (response.http.code === 200) {
          suma = response.message;
          // 1599540333
          // @ts-ignore
          this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
            if (response.http.code === 200) {
              for(var i = 0; i < suma.length; i++){
                suma[i].value += response.message[i].value;
              }
              //1616768332.9962752
              // @ts-ignore
              this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
                if (response.http.code === 200) {
                  for(var i = 0; i < suma.length; i++){
                    suma[i].value += response.message[i].value;
                  }
                  //1618645300.9962752
                  // @ts-ignore
                  this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', this.sucursalSeleccionada.name).subscribe( response => {
                    if (response.http.code === 200) {
                      for(var i = 0; i < suma.length; i++){
                        suma[i].value += response.message[i].value;
                      }
                      var tot = 0;
                      suma.forEach(dat => tot += dat.value)
                      this.datazona2[1].salC = Number(tot).toFixed(2);
                      //

                      if(this.perspectivaTi != 'Estatus' && this.perEst == ''){
                        var suma2 = [];
                        //@ts-ignore
                        this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                          if (response.http.code === 200) {
                            suma2 = response.message;
                            // 1599540333
                            // @ts-ignore
                            this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                //1616768332.9962752
                                // @ts-ignore
                                this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    //1618645300.9962752
                                    // @ts-ignore
                                    this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', this.sucursalSeleccionada.name).subscribe( response => {
                                      if (response.http.code === 200) {
                                        for(var i = 0; i < suma2.length; i++){
                                          suma2[i].value += response.message[i].value;
                                        }
                                        
                                        this.cartera_vencida = 0;
                                        this.cartera_vigente = 0;
                                        suma2.forEach(sum => this.cartera_vencida += sum.value);
                                        for(var i = 0; i < suma.length; i++){
                                          this.cartera_vigente += (suma[i].value - suma2[i].value);
                                        }
                                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }else if(this.perspectivaTi == 'Estatus'){
                        switch(suma[0].key){
                          case 'VIGENTE':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = suma[0].value;
                          break;
                          case 'ATRASADA':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = suma[0].value;
                          break;
                          case 'VENCIDA':
                            this.cartera_vencida = suma[0].value;
                            this.cartera_vigente = 0;
                          break;
                        }
                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                      }if(this.perEst != ''){
                        switch(this.perEst){
                          case 'VIGENTE':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                          break;
                          case 'ATRASADA':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;                            
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                          break;
                          case 'VENCIDA':
                            this.cartera_vigente = 0;
                            this.cartera_vencida = 0;                            
                            suma.forEach(dat => this.cartera_vencida += dat.value);
                          break;
                        }
                      }

                    }
                  });
                }
              });
            }
          });
        }
      });
      
    } else {
      var suma = [];
      var intVig = 0;
      // @ts-ignore
      this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
        if (response.http.code === 200) {
          suma = response.message;
          // @ts-ignore
          this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
            if (response.http.code === 200) {
              for(var i = 0; i < suma.length; i++){
                suma[i].value += response.message[i].value;
              }
              //1616768332.9962752
              // @ts-ignore
              this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
                if (response.http.code === 200) {
                  for(var i = 0; i < suma.length; i++){
                    suma[i].value += response.message[i].value;
                  }
                  //1618645300.9962752
                  // @ts-ignore
                  this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '').subscribe( response => {
                    if (response.http.code === 200) {
                      for(var i = 0; i < suma.length; i++){
                        suma[i].value += response.message[i].value;
                      }
                      //
                      var tot = 0;
                      suma.forEach(dat => tot += dat.value)
                      this.datazona2[1].salC = Number(tot).toFixed(2);

                      if(this.perspectivaTi != 'Estatus' && this.perEst == ''){
                        var suma2 = [];
                        //@ts-ignore
                        this.tendenciaService.saldoCapPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            suma2 = response.message;
                            // @ts-ignore
                            this.tendenciaService.intVigPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                //1616768332.9962752
                                // @ts-ignore
                                this.tendenciaService.intVenPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    //1618645300.9962752
                                    // @ts-ignore
                                    this.tendenciaService.intMorPer(perspectiva, this.mesSeleccionado.value, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA').subscribe( response => {
                                      if (response.http.code === 200) {
                                        for(var i = 0; i < suma2.length; i++){
                                          suma2[i].value += response.message[i].value;
                                        }
                                        this.cartera_vencida = 0;
                                        this.cartera_vigente = 0;
                                        suma2.forEach(sum => this.cartera_vencida += sum.value);
                                        for(var i = 0; i < suma.length; i++){
                                          this.cartera_vigente += (suma[i].value - suma2[i].value);
                                        }
                                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }else if(this.perspectivaTi == 'Estatus'){
                        switch(suma[0].key){
                          case 'VIGENTE':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = suma[0].value;
                          break;
                          case 'ATRASADA':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = suma[0].value;
                          break;
                          case 'VENCIDA':
                            this.cartera_vencida = suma[0].value;
                            this.cartera_vigente = 0;
                          break;
                        }
                        
                        this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                        
                      }if(this.perEst != ''){
                        switch(this.perEst){
                          case 'VIGENTE':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                          break;
                          case 'ATRASADA':
                            this.cartera_vencida = 0;
                            this.cartera_vigente = 0;                            
                            suma.forEach(dat => this.cartera_vigente += dat.value);
                          break;
                          case 'VENCIDA':
                            this.cartera_vigente = 0;
                            this.cartera_vencida = 0;                            
                            suma.forEach(dat => this.cartera_vencida += dat.value);
                          break;
                        }
                      }


                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }
  /** FIN CONSULTAS */
  // Grafica de Barras
  private formatDatosBarra(): void {
    this.datosBarra = [];
    this.dataLineal1 = [];
    var dataprov = [];
    for (const elemento of this.datosGrafica) {
      if(elemento.key.includes('-')){
        this.datosBarra.push({
          // @ts-ignore
          name:  this.abrebMes(elemento.key.split('-')[1]),
          // @ts-ignore
          value: elemento.value,
          extra:  elemento.key
        });
      }else{
        this.datosBarra.push({
          // @ts-ignore
          name:  elemento.key,
          // @ts-ignore
          value: elemento.value,
          extra:  elemento.key
        });
      }
    }
    var l = 0;
    for(const data of this.datosBarra){
      for(const tabla of this.datazona2){
        //@ts-ignore
        if((data.name == tabla.name || data.name == tabla.anioN) && tabla.salC != undefined){
          //@ts-ignore
          tabla.salC = data.value.toFixed(2);
          l++;
          break;
        }
      }
    }
    /*if(l == 0){
      for(var i=0; i<this.datazona2.length; i++){
        if(this.datazona2[i].salC != undefined){
          this.getGraficaPorMestabla2(i);
          break;
        }
      }
    }*/
    if (!this.consolidado){
      for(const suc of this.sucursalesDisponibles){

      }
    }
    //////
  }
  /*private getGraficaPorMestabla2(pos: any): void {
    const mesesSeleccionados = this.filtrosService.getElmentosSeleccionados(this.mesesDeAnioDisponibles);
    var suma = [];
    var intVig = [];
    
    var intVig_tot = [];
    //this.cartera_vencida_tot = [];
    //this.cartera_vigente_tot = [];
    this.tendenciaService.saldoCapMes(mesesSeleccionados).subscribe( response => {
      if (response.http.code === 200) {
        suma = response.message;
        // 1599540333
        this.tendenciaService.intVigMes(mesesSeleccionados).subscribe( response => {
          if (response.http.code === 200) {
            intVig = response.message[response.message.length-1].value;
            intVig_tot = response.message;
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            //1616768332.9962752
            this.tendenciaService.intVenMes(mesesSeleccionados).subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                //1618645300.9962752
                this.tendenciaService.intMorMes(mesesSeleccionados).subscribe( response => {
                  if (response.http.code === 200) {
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    //
                    this.datosGrafica = [];
                    
                    this.datazona2[pos].salC = suma[0].value.toFixed(2);
                  }
                });
              }
            });
          }
        });
      }
    });
    var suma2 = [];
    this.tendenciaService.intVenMes(mesesSeleccionados).subscribe( response => {
      if (response.http.code === 200) {
        suma2 = response.message;
        this.tendenciaService.capVenMes(mesesSeleccionados).subscribe( response => {
          if (response.http.code === 200) {
            for(var i = 0; i < suma2.length; i++){
              suma2[i].value += response.message[i].value;
            }
            this.tendenciaService.intMorMes(mesesSeleccionados).subscribe( response => {
              if (response.http.code === 200) {
                for(var i = 0; i < suma2.length; i++){
                  suma2[i].value += response.message[i].value;
                }
                //
                //this.cartera_vencida = suma2[suma2.length-1].value;
                /*for(var i = 0; i < suma2.length; i++){
                  //this.cartera_vencida_tot.push(suma2[i].value);
                }
              }
            });
          }
        });
      }
    });
    this.getTablaPorMes();
  }*/
  public metrica(cambio){
    if(cambio == 'num' && this.saldo){
      if(this.graficabarras){
        this.graficabarras = false;
        this.graficalineal = true;
        this.saldo = false;
        this.numer = true;
      }else if(this.graficalineal){
        this.graficabarras = true;
        this.graficalineal = false;
        this.saldo = false;
        this.numer = true;
      }
    }else if(cambio == 'sal' && this.numer){
      if(this.graficabarras){
        this.graficabarras = false;
        this.graficalineal = true;
        this.saldo = true;
        this.numer = false;
      }else if(this.graficalineal){
        this.graficabarras = true;
        this.graficalineal = false;
        this.saldo = true;
        this.numer = false;
      }
    }
    this.actsub();
  }
  private obtTablaZon1(data): void {
    this.datatabla = [];
    var aux = [];
    var i = 0;
    this.data = [];
    for(const d of data){
      if(d['key'].includes('-') && this.perspectivaTi == ''){
        var anio = d['key'].split('-')[0];
        var mes = d['key'].split('-')[1];
        this.datatabla.push({
          anio: anio,
          mes: this.abrebMes(mes),
          // @ts-ignore
          saldo:  this.datosBarra[i].value,
          ncuen: d['value'],
          cvig: Number(this.cartera_vigente_tot[i]),
          cven: Number(this.cartera_vencida_tot[i]),
          imor: Number((Number(this.cartera_vencida_tot[i])/(Number(this.cartera_vencida_tot[i])+Number(this.cartera_vigente_tot[i]))*100).toFixed(2))
        });
        if(!this.puedeFiltrarPorMeses){
          aux.push(anio);
          // @ts-ignore
          aux.push(this.datosBarra[i].value);
          aux.push(d['value']*10000);
          this.data.push(aux);
          aux = [];
        }else{
          aux.push(this.abrebMes(mes));
          // @ts-ignore
          aux.push(this.datosBarra[i].value);
          aux.push(d['value']*10000);
          this.data.push(aux);
          aux = [];
        }
      }else{
        // @ts-ignore
        var anio = this.anioSeleccionado.name + ' ' + this.mesSeleccionado.name;
        // @ts-ignore
        this.datatabla.push({
          anio: anio,
          mes: d['key'],
          // @ts-ignore
          saldo:  Number(this.datosBarra[i].value),
          ncuen: d['value'],
          cvig: Number(this.cartera_vigente_tot[i]),
          cven: Number(this.cartera_vencida_tot[i]),
          imor: Number((Number(this.cartera_vencida_tot[i])/(Number(this.cartera_vencida_tot[i])+Number(this.cartera_vigente_tot[i]))*100).toFixed(2))
        });
        aux.push(anio);
        // @ts-ignore
        aux.push(this.datosBarra[i].value);
        aux.push(d['value']*10000);
        this.data.push(aux);
        aux = [];
      }
      i++;
    }
    aux = [];
    this.datalinealcuentas = [];
    for(const d of data){
      if(!this.puedeFiltrarPorMeses){
        aux.push({
          name: d['key'].split('-')[0],
          value: (d['value']).toFixed(2),
          extra:  d.key.split('-')[0]
        });
      }else if(!this.puedeFiltrarPorSucursales){
        aux.push({
          name: this.abrebMes(d['key'].split('-')[1]),
          value: (d['value']).toFixed(2),
          extra:  d.key
        });
      }else{
        aux.push({
          name: d['key'],
          value: (d['value']).toFixed(2),
          extra:  d.key
        });
      }
    }
    this.dataBarraNum = aux;
    this.datalinealcuentas.push({name: 'Número de cuentas', series: aux});

    if(this.puedeFiltrarPorSucursales){
      this.ordLin(this.datosBarra);
      this.datosBarra = this.dataAux;
    }
    this.dataLineal1.push({name: 'Saldo total', series: this.datosBarra});
  }
  public graficaCom(e: any){
  }
  public dataAux = [];
  public ordLin(datos: any): void{
    datos.sort(function (a, b) {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    this.dataAux = [];
    this.dataAux = datos;
  }
  public cambiarGrafica(grafica: string): void {
    this.esGraficaDeBarras = (grafica === 'barras');
  }
  public cambioGrafC1(graf: any){
    switch(graf){
      case 'barra':
        this.graficabarras = true;
        this.graficalineal = false;
        this.graficacompuesta = false;
        this.graficaarea = false;
        this.graficatabla1 = false;
      break;
      case 'lineal':
        this.graficalineal = true;
        this.graficacompuesta = false;
        this.graficabarras = false;
        this.graficaarea = false;
        this.graficatabla1 = false;
      break;
      case 'area':
        this.graficaarea = true;
        this.graficabarras = false;
        this.graficalineal = false;
        this.graficacompuesta = false;
        this.graficatabla1 = false;
      break;
      case 'tabla':
        this.graficatabla1 = true;
        this.graficaarea = false;
        this.graficabarras = false;
        this.graficalineal = false;
        this.graficacompuesta = false;
      break;
      case 'compuesta':
        this.graficacompuesta = true;
        this.graficalineal = false;
        this.graficatabla1 = false;
        this.graficaarea = false;
        this.graficabarras = false;
      break;
    }
  }
  public cambioGrafC2(graf: any){
    switch(graf){
      case 'barraS':
        this.graficabarrasSal = true;
        this.graficalinealSal = false;
        this.graficabarrasCre = false;
        this.graficalinealCre = false;
        this.graficatabla = false;
      break;
      case 'linealS':
        this.graficalinealSal = true;
        this.graficabarrasSal = false;
        this.graficabarrasCre = false;
        this.graficalinealCre = false;
        this.graficatabla = false;
      break;
      case 'barraC':
        this.graficabarrasCre = true;
        this.graficalinealCre = false;
        this.graficabarrasSal = false;
        this.graficalinealSal = false;
        this.graficatabla = false;
      break;
      case 'linealC':;
        this.graficalinealCre = true;
        this.graficabarrasCre = false
        this.graficalinealSal = false;
        this.graficabarrasSal = false;
        this.graficatabla = false;
      break;
      case 'tabla':
        this.graficatabla = true;
        this.graficabarrasSal = false;
        this.graficalinealSal = false;
        this.graficabarrasCre = false;
        this.graficalinealCre = false;
      break;
    }
  }
  public yLeftTickFormat(valor): string {
    return CommonFunctions.retornarformatolefty(valor);
  }
  public clickOnBarra(e: any): void {
    // Cambia el color de la barra seleccionada
    this.customColors = [{ name: e.name, value: '#9E9E9E'}];
    if (!isNaN(e.extra)) { // Es un año sobre el que se hizo click
      /*this.anioSeleccionado = this.aniosDisponibles.find(anio => anio.name === e.name);
      // @ts-ignore
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.anioSeleccionado.name);
      this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
      this.puedeFiltrarPorMeses = true;*/
      this.aniosDisponibles.forEach(e => e.check = false);
      for(var i = 0; i < this.aniosDisponibles.length; i++){
        if(this.aniosDisponibles[i].name == e.extra)
          this.aniosDisponibles[i].check = true;
      }
      this.puedeFiltrarPorMeses = true;
      this.anioSeleccionado = this.aniosDisponibles.find(anio => anio.name === e.extra);
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === e.extra);
      this.actTarjetasAnio([e.extra]);
    } else if (e.extra.toString().includes('-')) { // Es un mes
      var aux = [];
      aux.push(this.mesSeleccionado);
      for(const mes of this.mesesDisponibles){
        if(mes.year == aux[0].year){
          mes.check = false;
        }
        if(mes.value == e.extra){
          mes.check = true;
        }
      }
      this.mesSeleccionado = this.mesesDisponibles.find(mes => mes.value === e.extra);
      this.puedeFiltrarPorSucursales = true;
      this.puedeFiltrarPorPerspectivas = true;
      this.sucursalSeleccionada = {name: '', check: true};
      this.actTarjetasMes([e.extra]);
    } else if (this.sucursalesDisponibles.find(sucursal => sucursal.name === e.extra)) { // Es una sucursal
      for(const suc of this.sucursalesDisponibles){
        suc.check = false;
        if(suc.name == e.extra){
          suc.check = true;
        }
      }
      this.sucursalSeleccionada = this.sucursalesDisponibles.find(sucursal => sucursal.name === e.extra);
      this.puedeFiltrarPorPerspectivas = true;
      this.actTarjetasSuc([e.extra]);
    }else{
      switch(this.perspectivaTi){
        case 'Genero':
          this.perGen = e.name;
          this.actTarjetasPer('sexo.keyword');
        break;
        case 'Servicio':
          this.perSer = e.name;
          this.actTarjetasPer('servicio.keyword');
        break;
        case 'Producto':
          this.perPro = e.name;
          this.actTarjetasPer('nombreProducto.keyword');
        break;
        case 'Estatus':
          this.perEst = e.name;
          this.actTarjetasPer('estado1.keyword');
        break;
        case 'Municipio':
          this.perMun = e.name;
          this.actTarjetasPer('municipio1.keyword');
        break;
      }
      this.getTablaPorMes();
    }
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public actsub3(){
    var aux = '', i = '';
    this.subtituloTarjeta2 = '';
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        this.subtituloTarjeta2 = ' '+anio.name.substring(2,4);
        i = anio.name;
      }
    }
    var est = this.Complete();
    if(this.mesSeleccionado != undefined){
      if(this.mesesDisponibles != undefined){
        for(const mes of this.mesesDisponibles){
          if(mes.check){
            aux = ' | '+mes.name;
          }
        }
      }
    }else{
      for(const mes of this.mesesDisponibles){
        if(mes.year == i){
          aux = ' | '+mes.name;
        }
      }
    }
    this.subtituloTarjeta2 += aux;
    aux = '';
    // @ts-ignore
    if(this.consolidado && this.puedeFiltrarPorSucursales){
      //this.subtituloTarjeta2 += ' | CONSOLIDADO';
    }else{
      // @ts-ignore
      if(!this.consolidado && this.puedeFiltrarPorSucursales && this.sucursalSeleccionada.name == ''){
        //this.subtituloTarjeta2 += ' | SELECCIÓN';
      }else{
        if(this.sucursalesDisponibles != undefined){
          for(const suc of this.sucursalesDisponibles){
            if(suc.check){
              aux= ' | '+suc.name;
            }
          }
        }
      }
    }
    this.subtituloTarjeta2 += aux;
    aux = '';
    if(this.perGen != ''){
      this.subtituloTarjeta2 += ' | ' + this.perGen;
    }
    if(this.perSer != ''){
      this.subtituloTarjeta2 += ' | ' + this.perSer;
    }
    if(this.perPro != ''){
      this.subtituloTarjeta2 += ' | ' + this.perPro;
    }
    if(this.perEst != ''){
      this.subtituloTarjeta2 += ' | ' + this.perEst;
    }
    if(this.perMun != ''){
      this.subtituloTarjeta2 += ' | ' + this.perMun;
    }
    if(this.perspectivaTi != ''){
      if( this.perspectivaTi != 'Genero'){
        this.subtituloTarjeta2 += ' | '+this.perspectivaTi;
      }else{
        this.subtituloTarjeta2 += ' | Género';
      }
    }
  }
  public actsub2(){
    this.subtituloGrafica2 = '';
    this.subtituloGrafica2 += 'Cartera total';
    var i = 0;
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        if(i == 0){
          this.subtituloGrafica2 += ' '+anio.name.substring(2,4);
          i++;
        } else{
          this.subtituloGrafica2 += ' | '+anio.name.substring(2,4);
        }
      }
    }
    var est = this.Complete();
    if(est && this.puedeFiltrarPorMeses){
        this.subtituloGrafica2 += ' | TODOS';
    } else if(this.mesSeleccionado != undefined){
      if(this.mesesDisponibles != undefined){
        for(const mes of this.mesesDisponibles){
          if(mes.check){
            this.subtituloGrafica2 += ' | '+mes.name;
          }
        }
      }
    }
    if(this.consolidado && this.puedeFiltrarPorSucursales){
      this.subtituloGrafica2 += ' | CONSOLIDADO';
    }else{
      if(!this.consolidado && this.puedeFiltrarPorSucursales && !this.sucursalSeleccionada){
        this.subtituloGrafica2 += ' | SELECCIÓN';
      }else{
        if(this.sucursalesDisponibles != undefined){
          for(const suc of this.sucursalesDisponibles){
            if(suc.check){
              this.subtituloGrafica2 += ' | '+suc.name;
            }
          }
        }
      }
    }
    if(this.perGen != ''){
      this.subtituloGrafica2 += ' | ' + this.perGen;
    }
    if(this.perSer != ''){
      this.subtituloGrafica2 += ' | ' + this.perSer;
    }
    if(this.perPro != ''){
      this.subtituloGrafica2 += ' | ' + this.perPro;
    }
    if(this.perEst != ''){
      this.subtituloGrafica2 += ' | ' + this.perEst;
    }
    if(this.perMun != ''){
      this.subtituloGrafica2 += ' | ' + this.perMun;
    }
    if(this.perspectivaTi != ''){
      if( this.perspectivaTi != 'Genero'){
        this.subtituloGrafica2 += ' | '+this.perspectivaTi;
      }else{
        this.subtituloGrafica2 += ' | Género';
      }
    }
  }
  public actsub(){
    this.subtituloGraficaTabla = '';
    var i = 0;
    if(this.saldo){
      this.subtituloGraficaTabla += 'Cartera total ';
    }else if(this.numer){
      this.subtituloGraficaTabla += 'Número de cuentas ';
    }
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        if(i == 0){
          this.subtituloGraficaTabla += ' | '+anio.name.substring(2,4);
          i++;
        } else{
          this.subtituloGraficaTabla += ' | '+anio.name.substring(2,4);
        }
      }
    }
    var est = this.Complete();
    if(est && this.puedeFiltrarPorMeses){
        this.subtituloGraficaTabla += ' | TODOS';
    } else if(this.mesSeleccionado != undefined){
      if(this.mesesDisponibles != undefined){
        for(const mes of this.mesesDisponibles){
          if(mes.check){
            this.subtituloGraficaTabla += ' | '+mes.name;
          }
        }
      }
    }
    // @ts-ignore
    if(this.consolidado && this.puedeFiltrarPorSucursales){
      this.subtituloGraficaTabla += ' | CONSOLIDADO';
    }else{
      if(!this.consolidado && this.puedeFiltrarPorSucursales && !this.sucursalSeleccionada){
        this.subtituloGraficaTabla += ' | SELECCIÓN';
      }else{
        if(this.sucursalesDisponibles != undefined){
          for(const suc of this.sucursalesDisponibles){
            if(suc.check){
              this.subtituloGraficaTabla += ' | '+suc.name;
            }
          }
        }
      }
    }
    if(this.perGen != ''){
      this.subtituloGraficaTabla += ' | ' + this.perGen;
    }
    if(this.perSer != ''){
      this.subtituloGraficaTabla += ' | ' + this.perSer;
    }
    if(this.perPro != ''){
      this.subtituloGraficaTabla += ' | ' + this.perPro;
    }
    if(this.perEst != ''){
      this.subtituloGraficaTabla += ' | ' + this.perEst;
    }
    if(this.perMun != ''){
      this.subtituloGraficaTabla += ' | ' + this.perMun;
    }
    if(this.perspectivaTi != ''){
      if( this.perspectivaTi != 'Genero'){
        this.subtituloGraficaTabla += ' | '+this.perspectivaTi;
      }else{
        this.subtituloGraficaTabla += ' | Género';
      }
    }
  }
  public tit1(){
    if(!this.consolidado && this.puedeFiltrarPorSucursales){
      return 'Periodo';
    }
    if(this.perspectivaTi != ""){
      return 'Periodo';
    }
    return 'Año';
  }
  public tit2(){
    if(!this.consolidado && this.puedeFiltrarPorSucursales){
      return 'Sucursal';
    }
    if(this.perspectivaTi != ""){
      switch(this.perspectivaTi){
        case 'Genero':
          return 'Genero';
        case 'Servicio':
          return 'Servicio';
        case 'Producto':
          return 'Producto';
        case 'Estatus':
          return 'Estatus';
        case 'Municipio':
          return 'Municipio';
      }
    }
    return 'Mes';
  }
  /** funciones de la tabla */
  private getTablaPorAnio(): void {
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    var ultmeses = [];
    var meses = [];
    for(const anio of aniosSeleccionados){
      meses = [];
      for(const mes of this.mesesDisponibles){
        if(anio == mes.year)
          meses.push(mes.value);
      }
      ultmeses.push( meses[meses.length-1] );
    }
    this.tendenciaService.getTendenciaTablaPorAnioV2(ultmeses).subscribe( response => {
      this.datazona2 = []
      if (response.http.code === 200) {
        this.obtdatosZon2(response.message);
        this.tableDataSource.data = this.tendenciaService.datosTablaFormatoDePeticionHaciaFormatoMatTable(response.message);
        this.setColumnasTabla();
      }
    });
  }
  private getTablaPorMes(): void {
    const mesesSeleccionados = this.filtrosService.getElmentosSeleccionados(this.mesesDeAnioDisponibles);
    this.tendenciaService.getTendenciaTablaPorMes(mesesSeleccionados, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, this.sucursalSeleccionada).subscribe( response => {
      if (response.http.code === 200) {
        this.obtdatosZon2(response.message);
        this.tableDataSource.data = this.tendenciaService.datosTablaFormatoDePeticionHaciaFormatoMatTable(response.message);
        this.setColumnasTabla();
      }
    });
  }
  public obtdatosZon2(array: any){
    this.datazona2 = [];
    this.dataSalCapBar = [];
    this.dataSalCapLin = [];
    this.dataNumCreBar = [];
    this.dataNumCreLin = [];
    for(const dat of array){
      if(this.datazona2.length == 0 ){
        var anio = dat['key'].split('-')[0];   
        this.datazona2.push({
          name: anio,
          anioN: anio,
          salC: undefined,
          numC: undefined,
          check: true,
          anio: true,
          exp: true,
        });
        var mes = dat['key'].split('-')[1];
        var ser = dat['value']; 
        this.datazona2.push({
          name: this.abrebMes(mes),
          anioN: anio,
          salC: 0,//(ser[0].value).toFixed(2),
          numC: (ser[1].value),
          check: true,
          anio: false,
          exp: false,
        });
      }else if(anio ==  dat['key'].split('-')[0]){
        var ser = dat['value']; 
        var mes = dat['key'].split('-')[1];
        this.datazona2.push({
          name: this.abrebMes(mes),
          anioN: anio,
          salC: 0,//(ser[0].value).toFixed(2),
          numC: (ser[1].value),
          check: true,
          anio: false,
          exp: false,
        });
      }else{
        var anio = dat['key'].split('-')[0];
        var mes = dat['key'].split('-')[1];
        this.datazona2.push({
          name: anio,
          anioN: anio,
          salC: undefined,
          numC: undefined,
          check: true,
          anio: true,
          exp: true,
        });
        var ser = dat['value']; 
        this.datazona2.push({
          name: this.abrebMes(mes),
          anioN: anio,
          salC: 0,//(ser[0].value).toFixed(2),
          numC: (ser[1].value),
          check: true,
          anio: false,
          exp: false,
        });
      }

      this.dataSalCapBar.push({
        name: dat['key'],
        value: ser[0].value
      });
      this.dataNumCreBar.push({
        name: dat['key'],
        value: ser[1].value
      });

    }
    this.dataSalCapLin.push({name: 'Cartera total', series: this.dataSalCapBar});
    this.dataNumCreLin.push({name: 'Número de creditos', series: this.dataNumCreBar});
  }
  public clictabla(anio: any,zon){
    switch(zon){
      case 2:
        for(const data of this.datazona2){
          if(data.anioN == anio.name && data.anio){
            data.exp = !data.exp;
          }
          if(data.anioN == anio.name && !data.anio){
            data.check = !data.check;
          }
        }
      break;
      case 1:
        for(const data of this.datatabla){
          if(data.anioN == anio.name && data.anio){
            data.exp = !data.exp;
          }
          if(data.anioN == anio.name && !data.anio){
            data.check = !data.check;
          }
        }
      break;
    }
  }
  private setColumnasTabla(): void {
    const rowExample = this.tableDataSource.data[0];
    var titulos = ['PERIODO', ' SALDO CAPITAL', 'NÚMERO DE CREDITO']
    var i = 0;
    this.columns = [];
    // tslint:disable-next-line:forin
    for (const property in rowExample) {
      this.columns.push({
        columnDef: property, header: titulos[i].toString().toUpperCase(),
        cell: (element: object) => `${element[property]}`,
      });
      i++;
    }
    // @ts-ignore
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }
  /** Cambio graficas */
  public AmpliarC1(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    if(this.graficabarras)
      this.graficabarras_modal = true;
    if(this.graficalineal)
      this.graficalineal_modal = true;
    if(this.graficaarea)
      this.graficaarea_modal = true;
    if(this.graficatabla1)
      this.graficatabla1_modal = true;
    if(this.graficacompuesta)
      this.graficacompuesta_modal = true;
  }
  public AmpliarC2(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    if(this.graficatabla)
      this.graficatabla_modal = true;
    if(this.graficabarrasSal)
      this.graficabarrasSal_modal = true;
    if(this.graficalinealSal)
      this.graficalinealSal_modal = true
      if(this.graficabarrasCre)
      this.graficabarrasCre_modal = true;
    if(this.graficalinealCre)
      this.graficalinealCre_modal = true
  }
  public hideGraficasModal(){
    // C1
    this.graficacompuesta_modal = false;
    this.graficabarras_modal = false
    this.graficalineal_modal = false;
    this.graficaarea_modal = false;
    this.graficatabla1_modal = false;
    // C2
    this.graficatabla_modal = false;
    this.graficabarrasSal_modal = false;
    this.graficabarrasCre_modal = false;
    this.graficalinealSal_modal = false;
    this.graficalinealCre_modal = false;
  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  /** Exportación */
  public export_excel(zon){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    name = 'Tendencia';
    var perspectiva = '';
    switch(zon){
      case 1:
        var encabezado_excel = [this.tit1(),this.tit2(),'Número de cuentas','Cartera total','Cartera vigente','Cartera vencida','Índice de morosidad'];
        var cadena_evaluacion = encabezado_excel;
        var mes = 0;
        for(const dato of this.datatabla){
          aux.push(dato['anio']);
          aux.push(dato['mes']);
          if(dato['ncuen'] != undefined){
            aux.push(Number(dato['ncuen']));
          }else{
            aux.push('');
          }
          if(dato['saldo'] != undefined){
            aux.push(Number(dato['saldo']));
          }else{
            aux.push('');
          }
          if(dato['cvig'] != undefined){
            aux.push(Number(dato['cvig']));
          }else{
            aux.push('');
          }
          if(dato['cven'] != undefined){
            aux.push(Number(dato['cven']));
          }else{
            aux.push('');
          }
          if(dato['imor'] != undefined){
            aux.push(Number(dato['imor']));
          }else{
            aux.push('');
          }
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 2:
        var encabezado_excel = ['PERIODO','SALDO CAPITAL','NUMERO CREDITO'];
        var cadena_evaluacion = encabezado_excel;
        var mes = 0;
        for(const dato of this.datazona2){
          aux.push(dato['name'].toString());
          if(dato['salC'] != undefined){
            aux.push(Number(dato['salC']));
          }else{
            aux.push('');
          }
          if(dato['numC'] != undefined){
            aux.push(Number(dato['numC']));
          }else{
            aux.push('');
          }
            data_excel.push(aux);
          aux = [];
        }
      break;
    }
    this.excelservices.generateExcelTenenciaColocacion(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva,mes);
  }
  public export_imagen(zon: any){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 1:
        grafica = 'zona1';
        name = 'Tendencia';
      break
      case 2:
        grafica = 'zona2';
        name = 'Tendencia';
      break
    }
    PDF.exportimagenes(grafica,name);
  }
  public formatearnormal(num: any){
    if(num != undefined){
      num +='';
      var splitStr = num.split('.');
      var splitLeft = splitStr[0];
      var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
      var regx = /(\d+)(\d{3})/;
      while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
      }
      return  splitLeft + splitRight;
    } else {
      return num;
    }
  }
}

