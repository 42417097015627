//import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {MessageService} from './../base/message.service';

import {Service} from './../base/service';
import {Loggers} from './../../util/app-util';
import { cosechaEntity } from './../../modules/riesgos/entities_riesgos/entities_riesgos';

@Injectable({
  providedIn: 'root'
})
export class CosechasService extends Service<cosechaEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers,
  ) { 
    super('cosecha','',http, messageService, logger);
    this.httpClient = http;
  }

  protected getParams(entity: cosechaEntity):HttpParams{
    let params: HttpParams = new HttpParams();
    if (null == entity){return params;}
    if (null != entity.Id_Institucion){params = params.set('Id_Institucion', entity.Id_Institucion.toString())}
    if (null != entity.Periodo){params = params.set('Periodo', entity.Periodo.toString())}
    if (null != entity.PAR){params = params.set('PAR', entity.PAR.toString())}
    if (null != entity.BnPAR_Inclusive){params = params.set('BnPAR_Inclusive', entity.BnPAR_Inclusive.toString())}
    if (null != entity.NPeriodos){params = params.set('NPeriodos', entity.NPeriodos.toString())}
    if (null != entity.Id_Zona){params = params.set('Id_Zona', entity.Id_Zona.toString())}
    if (null != entity.Id_Producto){params = params.set('Id_Producto', entity.Id_Producto.toString())}
    if (null != entity.Id_Sector){params = params.set('Id_Sector', entity.Id_Sector.toString())}
    if (null != entity.BnConIntereses){params = params.set('BnConIntereses', entity.BnConIntereses.toString())}
    return params;
  }

}
