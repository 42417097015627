import { Component, OnInit } from '@angular/core';

import {DataService} from '../../../services/data-service/data.service';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import {FuncionesColores} from '../../shared/funciones-colores/funciones_colores';
import {MatTableDataSource} from '@angular/material/table';


import {PerdidaService} from './../../../services/riesgos/perdida.service';
import {ZonaService} from './../../../services/riesgos/zona.service';
import {ProductoService} from './../../../services/riesgos/producto.service';
import { perdidaEntity,GraficaPedidaEntity, zonaEntity,productoEntity } from './../entities_riesgos/entities_riesgos';
import { NumberFormatStyle } from '@angular/common';
import {CommonVariables} from './../../financiero/funciones';

import {FuncionesRiesgo} from './../funciones_riesgos';
import { ExcelService } from './../../../services/exportar/excel.service';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';

@Component({
  selector: 'app-riesgos-tendencia',
  templateUrl: './riesgos-tendencia.component.html',
  styleUrls: ['./riesgos-tendencia.component.css']
})
export class RiesgosTendenciaComponent implements OnInit {
  /* Declaración de variables */
  public DatosAnios = {"aniosDisponibles":[
    {"name":"2020","value":2020,"check":false,"selected":false},
    {"name":"2021","value":2021,"check":false,"selected":false},
    {"name":"2022","value":2022,"check":true,"selected":false}],
"mesesDisponibles":[
    {"name":"nov","value":"112020","year":"2020","check":false},
    {"name":"dic","value":"122020","year":"2020","check":false},
    {"name":"ene","value":"012021","year":"2021","check":false},
    {"name":"feb","value":"022021","year":"2021","check":false},
    {"name":"mar","value":"032021","year":"2021","check":false},
    {"name":"abr","value":"042021","year":"2021","check":false},
    {"name":"may","value":"052021","year":"2021","check":false},
    {"name":"jun","value":"062021","year":"2021","check":false},
    {"name":"jul","value":"072021","year":"2021","check":false},
    {"name":"ago","value":"082021","year":"2021","check":false},
    {"name":"sep","value":"092021","year":"2021","check":false},
    {"name":"oct","value":"102021","year":"2021","check":false},
    {"name":"nov","value":"112021","year":"2021","check":false},
    {"name":"dic","value":"122021","year":"2021","check":false},
    {"name":"ene","value":"012022","year":"2022","check":false},
    {"name":"feb","value":"022022","year":"2022","check":false},
    {"name":"mar","value":"032022","year":"2022","check":false},
    {"name":"abr","value":"042022","year":"2022","check":false},
    {"name":"may","value":"052022","year":"2022","check":false},
    {"name":"jun","value":"062022","year":"2022","check":false},
    {"name":"jul","value":"072022","year":"2022","check":false},
    {"name":"ago","value":"082022","year":"2022","check":false},
    {"name":"sep","value":"092022","year":"2022","check":false},
    {"name":"oct","value":"102022","year":"2022","check":false},
    {"name":"nov","value":"112022","year":"2022","check":false},]};
  public aniosDisponibles: any [];
  public mesesDisponibles: any [];
  public mesesDeAnioDisponibles: any[];
  public puedeFiltrarPorMeses = false;
  public puedeFiltrarPorSucursales = false;
  public sucursalesDisponibles: any[];
  public anioSeleccionado: object;
  public mesSeleccionado: any;
  public sucursalSeleccionada: object;
  public subtituloGraficaTabla:string = "";
  public subtituloGrafica:string = "";
  public colorScheme = {
    domain: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
             '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
             '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };

  public ult_anio: any [] = [];
  public ult_mes: any [] = [];

  //nombre de las gráficas
  lineal_opt1: boolean = false;
  lineal_opt2: boolean = true;
  lineal_opt1_modal: boolean = false;
  lineal_opt2_modal: boolean = true;
  //declaración de variables gráfica lineal opt 1
  title = '';
  type = 'LineChart';
  data = [ ];
  columnNames = ["Periodo"];
  options = {   
    hAxis: {
      title: 'Periodo',
      textStyle: {
        color: '#000',
        fontSize: 12,
        fontName: 'Roboto',
        bold: false,
        italic: false
      },
      titleTextStyle: {
        color: '#000',
        fontSize: 12,
        fontName: 'Roboto',
        bold: false,
        italic: false
      },
    },
    vAxis:{
      title: 'Pérdida',
      gridlines: { display: false,color: 'transparent'},
      textStyle: {
        color: '#000',
        fontSize: 12,
        fontName: 'Roboto',
        bold: false,
        italic: false
      },
      titleTextStyle: {
        color: '#000',
        fontSize: 12,
        fontName: 'Roboto',
        bold: false,
        italic: false
      },
    },
    legend: { position: 'top' },
    pointSize:5,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
    '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
    '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
  colorLinealGoogle = ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
             '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
             '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0']; 
  //declaración de variables gráfica lineal opt 2
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Periodo';
  yAxisLabel: string = 'Monto';
  timeline: boolean = true;
  showGridLines: boolean = false;
  multi = [];
  /**
   * variables de tabla
   */
  public tableDataSource :any []= [];
  dataSource: any[] = [];
  public columns: Array<object>;
  public displayedColumns: any[] = [];

  perdida = new perdidaEntity;
  zona = new zonaEntity;
  producto = new productoEntity;
  dataPerdidaEsperada: any [] = [];
  PerdidaEsperada: boolean = true;
  PerdidaNoEsperada95: boolean = false;
  VaR95: boolean = false;

  suma_PerdidaEsperada: number = 0;
  suma_PerdidaNoEsperada95: number = 0;
  suma_VaR95: number = 0;
  
  todas_perdidas: boolean = false;
  dataZona: any [] = [];
  dataProducto: any [] = [];
  perspectiva: boolean = false;
  titulomodal: string = "";
  subtitulomodal: string = "";
  showModal: boolean = false;

  flag_zona: boolean = false;
  zona_name: string = "";
  flag_producto: boolean = false;
  producto_name: string = "";
  data_excel: any [] = [];
  aniomesSeleccionado: string = "";
  /* Declaración de variables */
  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private perdidaService: PerdidaService,
    private zonaService: ZonaService,
    private productoService: ProductoService,
    private excelService: ExcelService,
  ){
    this.FuncionZona();
    this.FuncionProducto();
    this.FuncionesDataSource();
  }
  FuncionZona(){
    this.zonaService.getByAddUrl_Riesgo('zona',this.zona).subscribe(dataArr => {
      this.dataZona = dataArr;
    });
  }
  filtrarZona(zona){
    this.flag_zona = true;
    this.flag_producto = false;
    this.perdida.Id_Zona = zona.Id_Zona;
    this.zona_name = zona.Nombre_Zona;
    this.perdida.Id_Producto = null;
    this.funcionPerdida(this.perdida);

  }
  filtrarProducto(producto){
    this.flag_producto = true;
    this.flag_zona = false;
    this.perdida.Id_Zona = null;
    this.perdida.Id_Producto = producto.Id_Producto;
    this.producto_name = producto.Nombre_Producto;
    this.funcionPerdida(this.perdida);
  }
  FuncionProducto(){
    this.productoService.getByAddUrl_Riesgo('producto',this.producto).subscribe(dataArr => {
      this.dataProducto = dataArr;
    });
  }

  ngOnInit(): void {}
  /* funciones para los filtros */
  //AÑOS
  public anioChangeListener(anioClick: object): void {
    this.ult_anio = [];
    this.aniosDisponibles = this.filtrosService.changeElementCheckStatusFiltroUnAnio_return(anioClick, this.aniosDisponibles);
    const anio_selecc = this.aniosDisponibles.find(anio => anio.name === anioClick['name']);
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === anio_selecc.name);
    for (const valor of this.mesesDeAnioDisponibles){
      valor['check'] = false;
    }
    this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
    this.mesSeleccionado = this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1];
    this.perdida.Periodo=this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].value;
    this.funcionPerdida(this.perdida);
    this.aniomesSeleccionado = this.mesSeleccionado["name"]+" | "+this.mesSeleccionado["year"].substring(2,4)
  }
  public VerificarAniosSeleccionados(): string {
    return this.puedeFiltrarPorMeses ? 'w3-third' : 'w3-third w3-disabled';
  }
  // MESES
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(mesClick, elementArray);
    this.ult_mes = [];
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']== true){
        this.ult_mes.push(valor['value'])
      }
    }
    this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    this.perdida.Periodo = Number(this.ult_mes[0]);
    this.funcionPerdida(this.perdida);
    this.aniomesSeleccionado = this.mesSeleccionado["name"]+" | "+this.mesSeleccionado["year"].substring(2,4)
  }
  // SUCURSALES
  public consolidadoChangeListener(tipo): void {
    switch(tipo){
      case 'todas_perdidas':
        if(this.todas_perdidas){
          this.todas_perdidas = !this.todas_perdidas;
          this.PerdidaEsperada = true;
          this.PerdidaNoEsperada95 = false;
          this.VaR95 = false;
        }else{
          this.todas_perdidas = !this.todas_perdidas;
          this.PerdidaEsperada = true;
          this.PerdidaNoEsperada95 = true;
          this.VaR95 = true;
        }
      break;
      case 'PerdidaEsperada':
        this.PerdidaEsperada = !this.PerdidaEsperada;
        if(this.PerdidaEsperada == false || this.PerdidaNoEsperada95 || this.VaR95){
          this.todas_perdidas = false;
        }
      break;
      case 'PerdidaNoEsperada95':
        this.PerdidaNoEsperada95 = !this.PerdidaNoEsperada95;
        if(this.PerdidaNoEsperada95 == false || this.PerdidaEsperada || this.VaR95){
          this.todas_perdidas = false;
        }
      break;
      case 'VaR95':      
        this.VaR95 = !this.VaR95;
        if(this.VaR95 == false || this.PerdidaEsperada || this.PerdidaNoEsperada95){
          this.todas_perdidas = false;
        }
      break;
    }
    if(this.VaR95 && this.PerdidaEsperada && this.PerdidaNoEsperada95){
      this.todas_perdidas = true;
    }
    if(!this.VaR95 && !this.PerdidaEsperada && !this.PerdidaNoEsperada95){
      this.todas_perdidas = false;
      this.PerdidaEsperada = true;
    }
    this.construirArregloGraficaAreaLineal(this.dataPerdidaEsperada);
  }
  public quitarFiltros(): void {

    var pos = 0;
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == '2022'){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false;
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i]);
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;

    this.lineal_opt1 = false;
    this.lineal_opt2 = true;

    this.flag_producto = false;
    this.flag_zona = false;
    this.perspectiva = false;
    this.PerdidaEsperada = true;
    this.PerdidaNoEsperada95 = false;
    this.VaR95 = false;
    this.perdida.Id_Zona = null;
    this.perdida.Id_Producto = null;

    this.FuncionesDataSource();
  }
  /* funciones para los filtros */
  /* funciones para los cambios de gráficas  */ 
  cambiarGraficaC1(grafica){
    switch(grafica){
      case 'lineal':
        if(this.lineal_opt1){
          this.lineal_opt1 = false;
          this.lineal_opt2 = true;
        }else{
          this.lineal_opt1 = true;
          this.lineal_opt2 = false;
        }
      break;
    }
  }
  /* funciones para los cambios de gráficas  */ 
  /* funciones eventos de las gráficas */
  onSelectG(e){
    this.perspectiva = true;
    let aux = this.tableDataSource[e.selection[0].row];
    this.dataSource = [];
    this.dataSource.push(aux);
    this.sumasTotales();
    this.FuncionsubTituloGraficaDos(this.dataSource,this.tableDataSource[e.selection[0].row].periodo.split(' ')[0]);
    this.aniomesSeleccionado = this.tableDataSource[e.selection[0].row].periodo.split(' ')[0] +" | "+ this.mesSeleccionado["year"].substring(2,4)
  }
  onSelect(event){
    this.perspectiva = true;
    let RespaldoDataSource = this.tableDataSource;
    this.dataSource = [];
    let variable = RespaldoDataSource.find(name => name.periodo == event.name);
    this.dataSource.push(variable);
    this.sumasTotales();
    this.FuncionsubTituloGraficaDos(this.dataSource,event.name.split(' ')[0]);
    this.aniomesSeleccionado = event.name.split(' ')[0] +" | "+ this.mesSeleccionado["year"].substring(2,4)
  }
  sumasTotales(){
    this.suma_PerdidaEsperada = 0;
    this.suma_PerdidaNoEsperada95 = 0;
    this.suma_VaR95 = 0;
    for (const d of this.dataSource){
      this.suma_PerdidaEsperada += d['perdidaesperada'];
      this.suma_PerdidaNoEsperada95 += d['perdidanoesperada'];
      this.suma_VaR95 += d['var90'];
    }
  }
  /* funciones eventos de las gráficas */

  /* Funciones para construir los datos de graficas, tablas y variables */
  public FuncionesDataSource(){
    this.aniosDisponibles = this.DatosAnios.aniosDisponibles;
    this.mesesDisponibles = this.DatosAnios.mesesDisponibles;
    let ult_anio = this.aniosDisponibles[2].name;
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio);
    let ult_mes = this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].name;
    this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
    this.mesSeleccionado = this.mesesDeAnioDisponibles.find(mes => mes.name === ult_mes);
    this.subtituloGraficaTabla = ult_anio + " | " + ult_mes; 
    this.perdida.Id_Institucion = 1;
    this.perdida.Periodo = Number(this.mesSeleccionado.value);//Number(CommonVariables.f_switchmesNumerico(ult_mes) + "" + ult_anio);
    this.perdida.NPeriodosHst= 12;
    this.perdida.NPeriodos = 12;
    this.perdida.BnConIntereses = 1;
    this.funcionPerdida(this.perdida);
    this.aniomesSeleccionado = this.mesSeleccionado["name"]+" | "+this.mesSeleccionado["year"].substring(2,4)

  }
  FuncionsubTituloGrafica(dataSource, mes?){
    let arr_anio = [];
    let cadena_anio_meses = "";
    for (const data of dataSource){
      for(const data1 of data.series)
        if(!arr_anio.includes(data1.name.substr(-2))){
          arr_anio.push(data1.name.substr(-2));
        }
    }
    let contador = 0;
    cadena_anio_meses += '' + this.mesSeleccionado.year.substring(2,4);
    if(!mes)
      cadena_anio_meses += ' | '+ this.mesSeleccionado.name;
    else
      cadena_anio_meses += ' | '+ mes;
    /*for(const i of arr_anio){
      cadena_anio_meses += i; 
      for (const data of dataSource){
        for(const data1 of data.series){
          if(i == data1.name.substr(-2)){
            cadena_anio_meses += " . " + data1.name.substr(0,3);
          }
        }
        break;
      }
      if(contador < (arr_anio.length - 1))
        cadena_anio_meses += " | "; 
      contador += 1;
    }*/
    this.subtituloGrafica = cadena_anio_meses;
    if(this.PerdidaEsperada)
      this.subtituloGrafica += " | Pérdida esperada"
    if(this.PerdidaNoEsperada95)
      this.subtituloGrafica += " | Pérdida no esperada"
    if(this.VaR95)
      this.subtituloGrafica += " | Variación "
    if (this.flag_zona)
      this.subtituloGrafica += " | Zona " + this.zona_name;
    if (this.flag_producto)
      this.subtituloGrafica += " | Producto " + this.producto_name;
  }
  FuncionsubTituloGraficaDos(dataSource, mes?){
    let arr_anio = [];
    let cadena_anio_meses = "";
    for (const data of dataSource){
      if(!arr_anio.includes(data['periodo'].substr(-2))){
        arr_anio.push(data['periodo'].substr(-2));
      }
    }
    let contador = 0;
    cadena_anio_meses += '' + this.mesSeleccionado.year.substring(2,4);
    if(!mes)
      cadena_anio_meses += ' | '+ this.mesSeleccionado.name;
    else
      cadena_anio_meses += ' | '+ mes;
    /*for(const i of arr_anio){
      cadena_anio_meses += i; 
      for (const data of dataSource){
        if(i == data['periodo'].substr(-2)){
          cadena_anio_meses += " . " + data['periodo'].substr(0,3);
        }
      }
      if(contador < (arr_anio.length - 1))
        cadena_anio_meses += " | "; 
      contador += 1;
    }*/
    this.subtituloGraficaTabla = cadena_anio_meses;
    this.subtituloGraficaTabla += " | Pérdida esperada";
    this.subtituloGraficaTabla += " | Pérdida no esperada";
    this.subtituloGraficaTabla += " | Variación ";
    if (this.flag_zona)
      this.subtituloGraficaTabla += " | Zona " + this.zona_name;
    if (this.flag_producto)
      this.subtituloGraficaTabla += " | Producto " + this.producto_name;
  }
  funcionPerdida(perdidaEntity){
    this.perdidaService.getByAddUrl_Riesgo('perdida',perdidaEntity).subscribe(dataArr => {
      this.dataPerdidaEsperada = dataArr.reverse();
      this.construirGraficaAreaLineal(dataArr);
    });
  }
  construirGraficaAreaLineal(dataArr){
    this.construirArregloGraficaAreaLineal(dataArr);
  }
  construirArregloGraficaAreaLineal(dataArr){
    this.multi = [];
    let arrPerdidaEsperada: any[] = [];
    let arrPerdidaNoEsperada: any[] = [];
    let arrVar: any[] = [];
    let arraux: any[] = [];
    this.multi = [];
    let dataSourceTable:any[] = [];
    this.data = [];
    this.columnNames = ['Periodo'];
    this.displayedColumns = ['Periodo'];

    this.suma_PerdidaEsperada = 0;
    this.suma_PerdidaNoEsperada95 = 0;
    this.suma_VaR95 = 0;
    //
    for(const data of dataArr){
      let anio = data["Periodo"].toString().substr(-4);
      let mes = CommonVariables.f_switchmesEntero(data["Periodo"].toString().replace(anio,""));
      arraux.push((mes+" "+anio.substring(2,4)).toString());
      if(this.todas_perdidas){
        dataSourceTable.push({"periodo":mes+" "+anio.substring(2,4),"perdidaesperada":data['PerdidaEsperada'],"perdidanoesperada":data["PerdidaNoEsperada95"],"var90":data["VaR95"]});
        arrPerdidaEsperada.push({"name":mes+" "+anio.substring(2,4),"value":data['PerdidaEsperada']});
        arrPerdidaNoEsperada.push({"name":mes+" "+anio.substring(2,4),"value":data['PerdidaNoEsperada95']});
        arrVar.push({"name":mes+" "+anio.substring(2,4),"value":data['VaR95']});
        arraux.push(data['VaR95']);
        arraux.push(data['PerdidaNoEsperada95']);
        arraux.push(data['PerdidaEsperada']);
        
        if(data['Periodo'] == this.mesSeleccionado.value){
          this.suma_PerdidaEsperada += data['PerdidaEsperada'];
          this.suma_PerdidaNoEsperada95 += data['PerdidaNoEsperada95'];
          this.suma_VaR95 += data['VaR95'];
        }
      }else{
        let grafica = new GraficaPedidaEntity;
        grafica.periodo = mes+" "+anio.substring(2,4);
        if(this.PerdidaEsperada){
          arrPerdidaEsperada.push({"name":mes+" "+anio.substring(2,4),"value":data['PerdidaEsperada']});
          arraux.push(data['PerdidaEsperada']);
        }
        if(this.PerdidaNoEsperada95){
          arrPerdidaNoEsperada.push({"name":mes+" "+anio.substring(2,4),"value":data['PerdidaNoEsperada95']});
          arraux.push(data['PerdidaNoEsperada95']);
        }
        if(this.VaR95){
          arrVar.push({"name":mes+" "+anio.substring(2,4),"value":data['VaR95']});
          arraux.push(data['VaR95']);
        }
        if(data['Periodo'] == this.mesSeleccionado.value){
          this.suma_PerdidaEsperada += data['PerdidaEsperada'];
          this.suma_PerdidaNoEsperada95 += data['PerdidaNoEsperada95'];
          this.suma_VaR95 += data['VaR95'];
        }
        //
        grafica.perdidaesperada = data['PerdidaEsperada'];
        grafica.perdidanoesperada = data['PerdidaNoEsperada95'];
        grafica.var90 = data['VaR95'];
        //
        dataSourceTable.push(grafica);
      }      
      this.data.push(arraux);
      arraux = [];

    }
    this.displayedColumns.push('PerdidaEsperada');
    this.displayedColumns.push('PerdidaNoEsperada');
    this.displayedColumns.push('VaR');
    if(this.VaR95){
      this.multi.push({"name":"Var",series:arrVar});
      this.columnNames.push('VaR');
    }
    if(this.PerdidaNoEsperada95){
      this.multi.push({"name":"PerdidaNoEsperada",series:arrPerdidaNoEsperada});
      this.columnNames.push('PerdidaNoEsperada');
    }
    if(this.PerdidaEsperada){
      this.multi.push({"name":"PerdidaEsperada",series:arrPerdidaEsperada});
      this.columnNames.push('PerdidaEsperada');
    }
    this.dataSource = dataSourceTable;
    this.tableDataSource = this.dataSource;
    this.subtituloGraficaTabla = "";
    this.subtituloGrafica = "";
    this.FuncionsubTituloGrafica(this.multi);
    this.FuncionsubTituloGraficaDos(this.dataSource);
  }
  formatearnormal(num){
    return FuncionesRiesgo.formatearnormal(num.toFixed(0));
  }
  /* Funciones para construir los datos de graficas, tablas y variables */
  /* Ampliar gráficas */
  public AmpliarGraficaC1(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Tendencia Pérdida";
    this.subtitulomodal = this.subtituloGrafica;
    if(this.lineal_opt1)
      this.lineal_opt1_modal = true;
    if(this.lineal_opt2)
      this.lineal_opt2_modal = true;

  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  hideGraficasModal(){
    this.lineal_opt1_modal = false;
    this.lineal_opt2_modal = false;

  }
  /* Ampliar gráficas */
  /* Exportar CSV*/
  encabezado_excel: any [] = [];
  export_csv(){
    var aux = [];
    this.data_excel = [];
    for (const ele of this.dataSource){
      let aux = [];
      aux.push(ele["periodo"])
      aux.push(this.formatearnormal(ele["perdidaesperada"]));
      aux.push(this.formatearnormal(ele["perdidanoesperada"]));
      aux.push(this.formatearnormal(ele["var90"]));
      this.data_excel.push(aux)
    }
    this.excelService.generatoExcelRiesgoTendencia(this.displayedColumns,this.data_excel,this.mesSeleccionado["name"]+"-"+this.mesSeleccionado["year"]);
  }
  /* Exportar CSV */

  /* Exportar img */
  export_img(zon: any){
    var grafica: string = ""
    var name: string = ""
    if(zon == 1){
        grafica = "Zona1"
        if(this.lineal_opt1)
          name = 'GraficaLineal'
        if(this.lineal_opt2) 
          name = 'GraficaLineal'
    }
    PDF.exportimagenes(grafica,name)
  }
  /* Exportar img */
}

