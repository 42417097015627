<!--<div class="w3-container w3-contenedor-template" >
    <div class="w3-row" id="filtros">
      <div class="w3-quarter hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter"><b>Financiero</b></span><span class="w3-text-deep-orange">&bull;</span><span class=" w3-margin-left-filter w3-text-orange"> Estados Financieros </span> 
      </div> 
      <div class="w3-rest">
        <div class="w3-quarter">
          <div class="w3-row">
            <div class="w3-col w3-tiny w3-hide" style="width: 100%;" >
              <div *ngIf="administrador()" class=" w3-half" >
                <div class="w3-bar-item" >
                  <div class="w3-cincuentaycinco w3-filtro-aniomes hijoizquierda">
                    Instituciones
                  </div>
                  <div class="w3-rest ">
                    <mat-icon id="maticonInstituciones" class="w3-tiny w3-margin-indicadores  w3-button w3-dropdown-click" (click)="DropInstituciones()">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div id="demoInstituciones" class="w3-dropdown-content w3-light-gray w3-scroll" style="height: 23vh;width: 200px;">
                  <table id="table_balance_intituciones" class="w3-table w3-border">
                    <tr [id]="'institucion_'+i" class="w3-row" *ngFor="let institucion of data_instituciones;let i = index">
                      <td class="w3-margin-left-contenido w3-label-inactivo w3-margin-top">
                        <span class="w3-margin-left-contenido">{{institucion.name}} </span>
                      </td>
                      <td>
                        <div class="w3-rest w3-margin-top w3-right-align">
                          <input class="w3-filtro-aniomes w3-check w3-margin-top-check w3-margin-right-contenido" 
                                (change)="institucionChange(institucion.id)" 
                                [checked]="institucion.checked" 
                                type="checkbox">
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="w3-half">
                <div class="w3-row padreizquierda">
                  <div class="w3-row hijoizquierda">
                    <div [class]="ClassAdministrador()">
                      <div style="width: 120px;">
                        {{InstitucionSelect()}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w3-col m7 ">
          <div class="w3-row ">
            <div class="w3-col w3-tiny " style="width: 100%;">
              <div class="w3-col s2 ">
                !--<div class="w3-bar-item" >
                  <div class="w3-twothird hijoizquierda w3-filtro-aniomes">Períodos</div>
                  <div class="w3-rest">
                      <mat-icon id="maticonPeriodo" class="w3-tiny w3-margin-indicadores  w3-button w3-dropdown-click" (click)="DropPeriodo()">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div id="demoPeriodo" class="w3-dropdown-content w3-light-gray w3-scroll w3-border" style="height: 15vh;width: 180px;">
                  <div class="w3-container w3-row w3-left-align " *ngFor="let itemEjercicioPeriodo of DATA_PERIODOSERIES">
                    <div class="w3-row ">
                      <div class="w3-row">
                        <div class="w3-noventa w3-margin-lelf-1roN">
                          <mat-icon [id]="'add_'+itemEjercicioPeriodo.anio" class="w3-tiny"  (click)="mostrar_primernivel(itemEjercicioPeriodo.anio)">add</mat-icon>
                          <mat-icon [id]="'remove_'+itemEjercicioPeriodo.anio" class="w3-tiny" (click)="ocultar_primernivel(itemEjercicioPeriodo.anio)">remove</mat-icon>
                          <span class="w3-label-inactivo"> {{itemEjercicioPeriodo.anio}}</span>
                        </div>
                      </div>
                    </div>
                    <div [id]="'contenedor'+itemEjercicioPeriodo.anio" class="w3-row">
                      <div class="w3-left-align" *ngFor="let nivel1 of itemEjercicioPeriodo.series">
                        <div class="w3-table">
                          <div class="w3-row ">
                            <div class="w3-half w3-margin-lelf-2doN">
                              <span class="w3-elemento-menu w3-filtro-aniomes">{{nivel1.mes}}</span>
                            </div>
                            <div class="w3-rest w3-right-align" >
                              <input [id]="'check_'+itemEjercicioPeriodo.anio+nivel1.mes" class="w3-filtro-aniomes w3-check " 
                                    (change)="Indicador_Nivel1(nivel1.Id_Evaluacion)"
                                    [checked]="nivel1.check" type="checkbox">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>--
                <div class="w3-bar-item" >
                  <div class="w3-twothird hijoizquierda w3-filtro-aniomes">Períodos</div>
                  <div class="w3-rest">
                      <mat-icon id="maticonPeriodo" class="w3-tiny w3-margin-indicadores  w3-button w3-dropdown-click" (click)="DropPeriodo()">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div id="demoPeriodo" class="w3-dropdown-content w3-light-gray w3-scroll w3-border" style="height: auto;width: 180px;">
                  <div class="w3-container w3-row w3-left-align " *ngFor="let itemEjercicioPeriodo of DATA_PERIODOSERIES">
                    <div class="w3-row ">
                      <div class="w3-row">
                        <div class="w3-noventa w3-margin-lelf-1roN">
                          <mat-icon [id]="'add_'+itemEjercicioPeriodo.anio" class="w3-tiny w3-button w3-dropdown-click" (click)="mostrar_primernivel(itemEjercicioPeriodo.anio); $event.stopPropagation();">add</mat-icon>
                          <mat-icon [id]="'remove_'+itemEjercicioPeriodo.anio" class="w3-tiny w3-button w3-dropdown-click" (click)="ocultar_primernivel(itemEjercicioPeriodo.anio); $event.stopPropagation();">remove</mat-icon>
                          <span class="w3-label-inactivo"> {{itemEjercicioPeriodo.anio}}</span>
                        </div>
                      </div>
                    </div>
                    <div [id]="'contenedor'+itemEjercicioPeriodo.anio" class="w3-row">
                      <div class="w3-left-align" *ngFor="let nivel1 of itemEjercicioPeriodo.series">
                        <div class="w3-table">
                          <div class="w3-row ">
                            <div class="w3-half w3-margin-lelf-2doN">
                              <span class="w3-elemento-menu w3-filtro-aniomes">{{nivel1.mes}}</span>
                            </div>
                            <div class="w3-rest w3-right-align" >
                              <input [id]="'check_'+itemEjercicioPeriodo.anio+nivel1.mes" class="w3-filtro-aniomes w3-check w3-dropdown-click" 
                                    (change)="Indicador_Nivel1(nivel1.Id_Evaluacion)"
                                    (click)="$event.stopPropagation();"
                                    [checked]="nivel1.check" type="checkbox">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row padreizquierda">
                    <div [class]="ContarEjercicioPeriodo()">
                    <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center" 
                          style="margin-left: 10px; width: 62px; "
                          *ngFor="let am of data_evaluacionaux"
                          (click)="Indicador_Nivel1(am.Id_Evaluacion)">
                          <span class="w3-elemento-menu ">{{am.ejercicioperiodo}}</span>
                    </div>
                  </div>
                </div>              
              </div>
            </div>
          </div>
        </div>
        <div class="w3-rest">
          <div class="w3-row hijoizquierda">
            Analísis
            <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-tiny"  #menuanios="matMenu" >
              <ng-container>
                <button mat-menu-item>
                  <mat-checkbox [id]="'checkV'"
                                [checked]="analisis_vertical"
                                (click)="$event.stopPropagation();"
                                (change)="AnalisisVertical($event.checked)">
                    Vertical
                  </mat-checkbox>
                </button>
                <button mat-menu-item>
                  <mat-checkbox [id]="'checkH'"
                                [checked]="analisis_horizontal"
                                (click)="$event.stopPropagation();"
                                (change)="AnalisisHorizontal($event.checked)">
                    Horizontal
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>-->

<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del tablero -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> Financiero </b></span> <!--  -->
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> Estados Financieros </span> <!--  -->
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <!-- periodicidad y análisis -->
      <div class="w3-noventa2 w3-row">
        <!-- espacio en blanco para sucursales -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;"></div>
        <!-- Filtro por periodicidad -->
        <div class="w3-half padreizquierda" style="margin-right: 1vh;">
          <!-- Periodos disponibles -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer"> <!--  -->
              Períodos
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPer="matMenu">
              <ng-container *ngFor="let an of DATA_PERIODOSERIES">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); Indicador_Nivel1(child.Id_Evaluacion)">
                        <div class="w3-quarter">
                          <input [id]="'check_'+child.anio+child.mes" style="cursor: pointer; margin-top: 7px;"
                                    (change)="Indicador_Nivel1(child.Id_Evaluacion)"
                                    [checked]="child.check" type="checkbox"
                                    (click)="$event.stopPropagation();">
                        </div>
                        <div class="w3-rest">
                          <span style="margin-left: 5px;">{{child.mes}}</span> 
                        </div>           
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          <!-- Periodos seleccionado -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button filtroMesAnioSeleccionado"
                      *ngFor="let am of data_evaluacionaux"
                      (click)="Indicador_Nivel1(am.Id_Evaluacion)">
                      <span style="margin-left: 5px;">{{am.ejercicioperiodo}}</span>
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- filtro por análisis -->
        <div class="w3-rest">
          <div class="w3-third hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios">
              Analísis
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium"  #menuanios="matMenu" >
              <ng-container>
                <button mat-menu-item>
                  <mat-checkbox [id]="'checkV'"
                                [checked]="analisis_vertical"
                                (click)="$event.stopPropagation();"
                                (change)="AnalisisVertical($event.checked)">
                    Vertical
                  </mat-checkbox>
                </button>
                <button mat-menu-item>
                  <mat-checkbox [id]="'checkH'"
                                [checked]="analisis_horizontal"
                                (click)="$event.stopPropagation();"
                                (change)="AnalisisHorizontal($event.checked)">
                    Horizontal
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips">
              <div class="w3-row chips ">
                <div *ngIf="analisis_vertical"
                     [ngClass]="'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu'"
                     class="filtroMesAnioSeleccionado">
                  <span class="contenidoChips">Vertical</span> 
                </div>
                <div *ngIf="analisis_horizontal"
                     [ngClass]="'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu'"
                     class="filtroMesAnioSeleccionado">
                  <span class="contenidoChips"> Horizontal</span> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Eliminar filtros -->
      <div class="w3-rest w3-right ">
        <button mat-icon-button>
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitar_filtros()"> <!--(click)="quitar_filtros()"-->
            <img src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
  
  <div class="w3-container" >
    <div class="w3-row w3-white w3-header-cuadrante">
      <div class="w3-twothird w3-titulo-contenidomenu ">
        <span class="w3-margin-left-contenido w3-tituloZonas">{{tituloTabla}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntobalancec1">&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulobalancec1}}</span>
      </div>
      <div class="w3-third w3-right " style="margin-right: -11px;">
        <div class="w3-right w3-margin-top-icon-menu">
          <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menut1Balance">more_vert</mat-icon>
        </div>
        <mat-menu #menut1Balance="matMenu">
          <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="tipos">
            <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree</mat-icon>
            <span>Perspectiva</span>
          </button>
          <mat-divider></mat-divider>
          <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportart1Balance">
            <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
            <span>Exportar como</span>
          </button>
        </mat-menu>
        <mat-menu #exportart1Balance="matMenu">
          <button (click)="export_csv()" mat-menu-item>
            <span>CSV</span>
          </button>
          <button (click)="export_csvTodos()" mat-menu-item>
            <span>CSV Todas</span>
          </button>
        </mat-menu>
        <mat-menu #tipos="matMenu">
          <button (click)="cambTipo('bal')" mat-menu-item>
            <span>Balance general</span>
          </button>
          <button (click)="cambTipo('est')" mat-menu-item>
            <span>Estado de resultados</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div *ngIf="option1" class="w3-row w3-white" >
      <div class="w3-row " style="height: 78vh;">
        <div *ngIf="tabla_informacion1" id="tabla_informacion1" class="w3-encabezado-table w3-scroll" style="background-color: #F9F9F9;" >
          <table class="w3-table" > <!--*ngIf="analisis_horizontal"-->
            <thead class="" > <!-- style="background-color: #A7A9AC;" -->
              <tr class="w3-table-titleBalance" *ngIf="analisis_horizontal">
                <th class="" style="background-color: #FFFFFF;"></th>
                <th class="" style="background-color: #FFFFFF;"></th>
                <ng-container *ngFor="let evaluacion of cadena_evaluacion">
                  <th class="w3-center"  style="background-color: #FFFFFF;" ></th>
                </ng-container>
                <ng-container *ngFor="let analisis of cadena_evaluacionHorizontal">
                  <th colspan="2" class="w3-border-right-balance" style="text-align: center; background-color: #E0E1E3;">
                    <div ><span class="w3-table-title2">{{analisis.evaten}} </span></div>
                  </th>
                </ng-container>
              </tr>
              <tr class="w3-table-titleBalance"> <!--  style="background-color: #A7A9AC;" -->
                <th class="w3-elemento-titulo-table"><span>Código</span></th> <!-- style="background-color: #A7A9AC;" -->
                <th class="w3-elemento-titulo-table"><span>Descripción</span></th> <!-- style="background-color: #A7A9AC;" -->
                <ng-container *ngFor="let evaluacion of cadena_evaluacion">
                  <th class="w3-center w3-elemento-titulo-table"  ><span>{{evaluacion.evaluacion}}</span></th>
                  <th *ngIf="analisis_vertical" class="w3-center w3-elemento-titulo-table"  ><span>{{evaluacion.Porcentaje}}</span></th>
                </ng-container>
                <ng-container *ngFor="let analisis of cadena_evaluacionHorizontal">
                  <!--<th *ngIf="analisis_horizontal" class="w3-center"  style="background-color: #A7A9AC;" ><span class="w3-table-titleB">{{analisis.evaten}} </span></th>
                  <th *ngIf="analisis_horizontal" class="w3-center"  style="background-color: #A7A9AC;" ><span class="w3-table-titleB">{{analisis.porcentajeHor}} </span></th>-->
                  <th *ngIf="analisis_horizontal" colspan="2" class="w3-border-right-balance" style="text-align: center; background-color: #E0E0E0;">
                    <div class="w3-half w3-elemento-titulo-table" style="height: 4vh;"><span>Variación</span></div>
                    <div class="w3-rest w3-elemento-titulo-table" style="height: 4vh;"><span class="w3-right" style="margin-right: 1vh;">%</span></div>
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody *ngFor="let item_tabla of data_ordenado" >
              <tr *ngIf="item_tabla.Check" id="item_tabla.Id_Cuenta"
                [ngClass]="{'w3-table-trtdBalanceBlue w3-encabezado-balance w3-border-Nivel0Balance':buscarnivelEncabezado(item_tabla.Id_Cuenta),
                            'w3-table-trtdBalance w3-cuerpo-balance':!buscarnivelEncabezado2(item_tabla.Id_Cuenta)
                            }">
                <td class="" [class]="FontBalance(item_tabla.Id_Cuenta)">
                  <div class="w3-row ">
                    <mat-icon (click)="mostrarocultarCuenta(item_tabla.Id_Cuenta)" *ngIf="cuentaprincipal(item_tabla.Id_Cuenta)" class="iconoMen w3-button">{{cadenaIdCuenta(item_tabla.Id_Cuenta)}}</mat-icon>
                    <mat-icon (click)="mostrarocultarCuenta(item_tabla.Id_Cuenta)" *ngIf="!cuentaprincipal(item_tabla.Id_Cuenta)" class="iconoMen w3-button"></mat-icon>
                    <span [class]="FontBalance(item_tabla.Id_Cuenta)">{{item_tabla.Id_Cuenta}}</span>
                  </div>
                </td>
                <td class="" [class]="FontBalance(item_tabla.Id_Cuenta)">
                  <div class="w3-row ">
                    <div class="w3-row">
                      <div class="w3-row w3-elemento-tableBalance">
                        <span [ngClass] = "{'  w3-margin-lelf-1roB':buscarnivel0(item_tabla.Id_Cuenta),
                                            '  w3-margin-lelf-2doB':buscarnivel1(item_tabla.Id_Cuenta),
                                            '  w3-margin-lelf-3roB':buscarnivel2(item_tabla.Id_Cuenta),
                                            '  w3-margin-lelf-4toB':buscarnivel3(item_tabla.Id_Cuenta),
                                            '  w3-margin-lelf-5toB':buscarnivel4(item_tabla.Id_Cuenta),
                                            '  w3-margin-lelf-6toB':buscarnivel5(item_tabla.Id_Cuenta)
                                    }">{{item_tabla.Descripcion}} </span>
                      </div>
                    </div>
                  </div>
                </td>
                <ng-container *ngFor="let item of item_tabla.Series; index as i">
                  <td class=""  [class]="FontBalance(item_tabla.Id_Cuenta)">
                    <div class="w3-row w3-noventa w3-right-align">
                      <div class="w3-row">
                        <div class="w3-row">
                          <span [class]="FontBalance(item_tabla.Id_Cuenta,item.Valor)" > {{formatear(item.Valor)}}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="analisis_vertical" [class]="FontBalance(item_tabla.Id_Cuenta)">
                    <div class="w3-row w3-noventa w3-right-align">
                      <div class="w3-row">
                        <div class="w3-row">
                          <span [class]="FontBalance(item_tabla.Id_Cuenta,item.Valor)" > {{formatear(PorcentajeCuentasBalance(item_tabla.Id_Cuenta,item.Valor,i))}} %</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <ng-container *ngFor="let evah of item_tabla.EvaHorizontal">
                  <td *ngIf="analisis_horizontal" [class]="FontBalance(item_tabla.Id_Cuenta)">
                    <div class="w3-row w3-noventa w3-right-align">
                      <div class="w3-row">
                        <div class="w3-row">
                          <span [class]="FontBalance(item_tabla.Id_Cuenta,evah.Valor)" > {{formatear(evah.evaluacionHorizontal)}}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="analisis_horizontal" [class]="FontBalance(item_tabla.Id_Cuenta)">
                    <div class="w3-row w3-noventa w3-right-align">
                      <div class="w3-row">
                        <div class="w3-row">
                          <span [class]="FontBalance(item_tabla.Id_Cuenta,evah.Valor)" > {{formatear(evah.porcentajeValorH)}} %</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
          <div class="w3-row " style="height: 33vh;margin-top: 2vh;background-color: #F9F9F9;">
            <div id="A" class="w3-third" style="height: 100%;">
              <div class="w3-container w3-white" style="margin: 0vh 1vh 1vh 1vh ;height: auto;">
                <div class="w3-row w3-white w3-header-cuadrante padreizquierda" style="height: 6vh;">
                  <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
                    <div class="row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                      <span class="w3-margin-left-contenidoBalance w3-tituloZonas" *ngIf="balanceEdos">Activo</span>
                      <span class="w3-margin-left-contenidoBalance w3-tituloZonas" *ngIf="!balanceEdos">Margen financiero</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntoActivo">&bull;</span>
                        <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloActivo}}</span>
                    </div>
                  </div>
                  <div class="w3-rest w3-right-align_rest " >
                    <div class="w3-right hijoderechaTitle">
                      <!--<mat-icon (click)="ClickActivo()" *ngIf="barActivo" class="material-icons-outlined"
                          [ngStyle]="{'color':'gray'}">
                          {{labelActivo()}}
                      </mat-icon>-->
                      <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarActivo()">zoom_out_map</mat-icon>
                      <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuActivo">more_vert</mat-icon>
                      <mat-menu #menuActivo="matMenu" class="w3-blue">
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficaActivo">
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                          <span>Gráficas</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button (click)="export_png('A')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                          <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                          <span>Exportar como png</span>
                        </button>
                      </mat-menu>
                      <mat-menu #graficaActivo="matMenu">
                        <button (click)="barraActivo()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                          <span>Barra</span>
                        </button>
                        <button (click)="linealActivo()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                          <span>Lineal</span>
                        </button>
                        <button (click)="pastelActivo()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                          <span>Área</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="w3-row w3-white" style="height: 27vh;">
                  <ngx-charts-area-chart *ngIf="pieActivo" id="pieActivo"
                    [results]="multiActivo"
                    [xAxis]="true"
                    [scheme]="colorSchemeActivo"
                    [xAxis]="showXAxisActivo"
                    [yAxis]="showYAxisActivo"
                    [legend]="showLegendActivo"
                    [showXAxisLabel]="showXAxisLabelActivo"
                    [showYAxisLabel]="showYAxisLabelActivo"
                    [xAxisLabel]="xAxisLabelActivo"
                    [yAxisLabel]="yAxisLabelActivo"
                    [showGridLines]="false">
                    <ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiActivo; let i = index">
                          <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiActivo; let i = index">
                          <span>{{buscarCuentasLineal(model)}}</span>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-charts-area-chart>
                  <ngx-charts-bar-vertical *ngIf="barActivo" id="barActivo"
                    [scheme]="colorSchemeActivo"
                    [results]="singleActivo"
                    [xAxis]="showXAxisActivo"
                    [yAxis]="showYAxisActivo"
                    [legend]="showLegendActivo"
                    [showXAxisLabel]="showXAxisLabelActivo"
                    [showYAxisLabel]="showYAxisLabelActivo"
                    [xAxisLabel]="xAxisLabelActivo"
                    [yAxisLabel]="yAxisLabelActivo"
                    [showDataLabel]="showDataLabelActivo"
                    (select)="onSelect($event)">
                    <ng-template  #tooltipTemplate let-model="model" > 
                      <div style="text-align: left;"> 
                        <div *ngFor="let data of singleActivo; let i = index">
                          <span *ngIf="data.name === model.name">
                            {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                          </span> 
                        </div> 
                      </div>
                    </ng-template> 
                  </ngx-charts-bar-vertical>
                  <ngx-charts-line-chart *ngIf="lineActivo" id="lineActivo"
                    [results]="multiActivo"
                    [xAxis]="true"
                    [scheme]="colorSchemeActivo"
                    [xAxis]="showXAxisActivo"
                    [yAxis]="showYAxisActivo"
                    [legend]="showLegendActivo"
                    [showXAxisLabel]="showXAxisLabelActivo"
                    [showYAxisLabel]="showYAxisLabelActivo"
                    [xAxisLabel]="xAxisLabelActivo"
                    [yAxisLabel]="yAxisLabelActivo"
                    [showGridLines]="false">
                    <ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiActivo; let i = index">
                          <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiActivo; let i = index">
                          <span>{{buscarCuentasLineal(model)}}</span>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-charts-line-chart>
                </div>
              </div>
            </div>
            <div id="B" class="w3-third" style="height: 100%;">
              <div class="w3-container w3-white" style="margin: 0vh 1vh 1vh 1vh ;height: auto;">
                <div class="w3-row w3-white w3-header-cuadrante padreizquierda" style="height: 6vh;">
                  <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
                    <div class="row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                      <span class="w3-margin-left-contenidoBalance w3-tituloZonas" *ngIf="balanceEdos">Pasivo</span>
                      <span class="w3-margin-left-contenidoBalance w3-tituloZonas" *ngIf="!balanceEdos">Resultados de operación</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntoActivo">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloActivo}}</span>
                    </div>
                  </div>
                  <div class="w3-rest w3-right-align_rest " >
                    <div class="w3-right hijoderechaTitle">
                      <mat-icon (click)="ClickPasivo()" *ngIf="barPasivo" class="material-icons-outlined"
                          [ngStyle]="{'color':'gray'}">
                          {{labelPasivo()}}
                      </mat-icon>
                      <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarPasivo()">zoom_out_map</mat-icon>
                      <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuPasivo">more_vert</mat-icon>
                      <mat-menu #menuPasivo="matMenu" class="w3-blue">
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficaPasivo">
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                          <span>Gráficas</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button (click)="export_png('B')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                          <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                          <span>Exportar como png</span>
                        </button>
                      </mat-menu>
                      <mat-menu #graficaPasivo="matMenu">
                        <button (click)="barraPasivo()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                          <span>Barra</span>
                        </button>
                        <button (click)="linealPasivo()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                          <span>Lineal</span>
                        </button>
                        <button (click)="pastelPasivo()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                          <span>Área</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="w3-row w3-white" style="height: 27vh;">
                  <ngx-charts-area-chart *ngIf="piePasivo" id="piePasivo"
                    [results]="multiPasivo"
                    [xAxis]="true"
                    [scheme]="colorSchemePasivo"
                    [xAxis]="showXAxisPasivo"
                    [yAxis]="showYAxisPasivo"
                    [legend]="showLegendPasivo"
                    [showXAxisLabel]="showXAxisLabelPasivo"
                    [showYAxisLabel]="showYAxisLabelPasivo"
                    [xAxisLabel]="xAxisLabelPasivo"
                    [yAxisLabel]="yAxisLabelPasivo"
                    [showGridLines]="false">
                    <ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiPasivo; let i = index">
                          <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiPasivo; let i = index">
                          <span>{{buscarCuentasLineal(model)}}</span>
                        </div>
                      </div>
                    </ng-template> 
                  </ngx-charts-area-chart>
                  <ngx-charts-bar-vertical *ngIf="barPasivo" id="barPasivo"
                    [scheme]="colorSchemePasivo"
                    [showGridLines]="false"
                    [results]="singlePasivo"
                    [xAxis]="showXAxisPasivo"
                    [yAxis]="showYAxisPasivo"
                    [legend]="showLegendPasivo"
                    [showXAxisLabel]="showXAxisLabelPasivo"
                    [showYAxisLabel]="showYAxisLabelPasivo"
                    [xAxisLabel]="xAxisLabelPasivo"
                    [yAxisLabel]="yAxisLabelPasivo"
                    [showDataLabel]="showDataLabelPasivo"
                    (select)="onSelect($event)">
                    <ng-template  #tooltipTemplate let-model="model" > 
                      <div style="text-align: left;"> 
                        <div *ngFor="let data of singlePasivo; let i = index">
                          <span *ngIf="data.name === model.name">
                            {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                          </span> 
                        </div> 
                      </div>
                    </ng-template> 
                  </ngx-charts-bar-vertical>
                  <ngx-charts-line-chart *ngIf="linePasivo" id="linePasivo"
                    [results]="multiPasivo"
                    [xAxis]="true"
                    [scheme]="colorSchemePasivo"
                    [xAxis]="showXAxisPasivo"
                    [yAxis]="showYAxisPasivo"
                    [legend]="showLegendPasivo"
                    [showXAxisLabel]="showXAxisLabelPasivo"
                    [showYAxisLabel]="showYAxisLabelPasivo"
                    [xAxisLabel]="xAxisLabelPasivo"
                    [yAxisLabel]="yAxisLabelPasivo"
                    [showGridLines]="false">
                    <ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiPasivo; let i = index">
                          <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiPasivo; let i = index">
                          <span>{{buscarCuentasLineal(model)}}</span>
                        </div>
                      </div>
                    </ng-template> 
                  </ngx-charts-line-chart>
                </div>
              </div>
            </div>
            <div id="C" class="w3-rest " style="height: 100%;">
              <div class="w3-container w3-white" style="margin: 0vh 1vh 1vh 1vh ;height: auto;">
                <div class="w3-row w3-white w3-header-cuadrante padreizquierda" style="height: 6vh;">
                  <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
                    <div class="row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                      <span class="w3-margin-left-contenidoBalance w3-tituloZonas" *ngIf="balanceEdos">Capital contable</span>
                      <span class="w3-margin-left-contenidoBalance w3-tituloZonas" *ngIf="!balanceEdos">Resultado neto</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntoActivo">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloActivo}}</span>
                    </div>
                  </div>
                  <div class="w3-rest w3-right-align_rest " >
                    <div class="w3-right hijoderechaTitle">
                      <mat-icon (click)="ClickCapitalContable()" *ngIf="barCapitalContable" class="material-icons-outlined"
                          [ngStyle]="{'color':'gray'}">
                          {{labelCapitalContable()}}
                      </mat-icon>
                      <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarCapitalContable()">zoom_out_map</mat-icon>
                      <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuCapitalContable">more_vert</mat-icon>
                      <mat-menu #menuCapitalContable="matMenu" class="w3-blue">
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficaCapitalContable">
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                          <span>Gráficas</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button (click)="export_png('C')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                          <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                          <span>Exportar como png</span>
                        </button>
                      </mat-menu>
                      <mat-menu #graficaCapitalContable="matMenu">
                        <button (click)="barraCapitalContable()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                          <span>Barra</span>
                        </button>
                        <button (click)="linealCapitalContable()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                          <span>Lineal</span>
                        </button>
                        <button (click)="pastelCapitalContable()" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                          <span>Área</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="w3-row w3-white" style="height: 27vh;">
                  <ngx-charts-area-chart *ngIf="pieCapitalContable" id="pieCapitalContable"
                    [results]="multiCapitalContable"
                    [xAxis]="true"
                    [scheme]="colorSchemeCapitalContable"
                    [xAxis]="showXAxisCapitalContable"
                    [yAxis]="showYAxisCapitalContable"
                    [legend]="showLegendCapitalContable"
                    [showXAxisLabel]="showXAxisLabelCapitalContable"
                    [showYAxisLabel]="showYAxisLabelCapitalContable"
                    [xAxisLabel]="xAxisLabelCapitalContable"
                    [yAxisLabel]="yAxisLabelCapitalContable"
                    [showGridLines]="false">
                    <ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiCapitalContable; let i = index">
                          <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiCapitalContable; let i = index">
                          <span>{{buscarCuentasLineal(model)}}</span>
                        </div>
                      </div>
                    </ng-template> 
                  </ngx-charts-area-chart>
                  <ngx-charts-bar-vertical *ngIf="barCapitalContable" id="barCapitalContable"
                    [scheme]="colorSchemeCapitalContable"
                    [showGridLines]="false"
                    [results]="singleCapitalContable"
                    [xAxis]="showXAxisCapitalContable"
                    [yAxis]="showYAxisCapitalContable"
                    [legend]="showLegendCapitalContable"
                    [showXAxisLabel]="showXAxisLabelCapitalContable"
                    [showYAxisLabel]="showYAxisLabelCapitalContable"
                    [xAxisLabel]="xAxisLabelCapitalContable"
                    [yAxisLabel]="yAxisLabelCapitalContable"
                    [showDataLabel]="showDataLabelCapitalContable"
                    (select)="onSelect($event)">
                    <ng-template  #tooltipTemplate let-model="model" > 
                      <div style="text-align: left;"> 
                        <div *ngFor="let data of singleCapitalContable; let i = index">
                          <span *ngIf="data.name === model.name">
                            {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                          </span> 
                        </div> 
                      </div>
                    </ng-template> 
                  </ngx-charts-bar-vertical>
                  <ngx-charts-line-chart *ngIf="lineCapitalContable" id="linealCapitalContable"
                    [results]="multiCapitalContable"
                    [xAxis]="true"
                    [scheme]="colorSchemeCapitalContable"
                    [xAxis]="showXAxisCapitalContable"
                    [yAxis]="showYAxisCapitalContable"
                    [legend]="showLegendCapitalContable"
                    [showXAxisLabel]="showXAxisLabelCapitalContable"
                    [showYAxisLabel]="showYAxisLabelCapitalContable"
                    [xAxisLabel]="xAxisLabelCapitalContable"
                    [yAxisLabel]="yAxisLabelCapitalContable"
                    [showGridLines]="false">
                    <ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiCapitalContable; let i = index">
                          <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of multiCapitalContable; let i = index">
                          <span>{{buscarCuentasLineal(model)}}</span>
                        </div>
                      </div>
                    </ng-template> 
                  </ngx-charts-line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div *ngIf="option2" class="w3-row w3-white" >
      <div class="w3-row " style="height: 550px;">
        <div *ngIf="tabla_informacion2" id="tabla_informacion2" class="w3-encabezado-table w3-scroll"  >
          <table class="w3-table" >
            <thead class="" style="background-color: #A7A9AC;">
              <tr class="w3-table-titleBalance" style="background-color: #A7A9AC;">
                <th class="" style="background-color: #A7A9AC;"><span class="w3-table-titleB">Código 2</span></th>
                <th class="" style="background-color: #A7A9AC;"><span  class="w3-table-titleB">Descripción2</span></th>
                <th class="w3-center" *ngFor="let evaluacion of cadena_evaluacion" style="background-color: #A7A9AC;" ><span class="w3-table-titleB">{{evaluacion.evaluacion}}</span></th>
              </tr>
            </thead>
            <tbody *ngFor="let item_tabla2 of data_ordenadoOption2" >
              <tr id="item_tabla2.Id_Cuenta"
                [ngClass]="{'w3-table-trtdBalance w3-encabezado-balance w3-border-Nivel0Balance':buscarnivelEncabezadoformula(item_tabla2.Id_Cuenta,item_tabla2.Formula),
                            'w3-table-trtdBalance w3-encabezado-balance':!buscarnivelEncabezadoformula2(item_tabla2.Id_Cuenta,item_tabla2.Formula)
                            }">
                <td class="">
                  <div class="w3-row ">
                    <span class="w3-elemento-tableBalance w3-margin-lelf-1ro">
                      {{buscarIdCuenta(item_tabla2.Id_Cuenta)}}
                    </span>
                  </div>
                </td>
                <td class="">
                  <div class="w3-row ">
                    <span 
                      [ngClass] = "{'w3-elemento-tableBalance w3-margin-lelf-1roB':buscarnivel20(item_tabla2.Id_Cuenta),
                                    'w3-elemento-tableBalance w3-margin-lelf-1roB2':buscarnivel201(item_tabla2.Id_Cuenta),
                                    'w3-elemento-tableBalance w3-margin-lelf-2doB':buscarnivel1(item_tabla2.Id_Cuenta),
                                    'w3-elemento-tableBalance w3-margin-lelf-3roB':buscarnivel2(item_tabla2.Id_Cuenta),
                                    'w3-elemento-tableBalance w3-margin-lelf-4toB':buscarnivel3(item_tabla2.Id_Cuenta),
                                    'w3-elemento-tableBalance w3-margin-lelf-5toB':buscarnivel4(item_tabla2.Id_Cuenta),
                                    'w3-elemento-tableBalance w3-margin-lelf-6toB':buscarnivel5(item_tabla2.Id_Cuenta)
                                }">
                      {{item_tabla2.Descripcion}}
                    </span>
                  </div>
                </td>
                <td class=""  *ngFor="let item2 of item_tabla2.Series" >
                  <div class="w3-row w3-twothird w3-right-align">
                    <div class="w3-row">
                      <span class="w3-elemento-tableBalance "> $ {{formatear(item2.Valor)}}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>-->
  </div>

  <div id="showhide_modalAmpliar" class="w3-modal">
    <div class="w3-modal-content" style="width: 90%;">
      <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
        <div class="w3-twothird">
          <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
            <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido">{{titulomodal}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntomodal">&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulomodal}}</span>
              </div>
            </div>
          </div>
        </div>        
        <div class="w3-rest " style="height: 100%;">
          <div class="w3-row" style="height: 100%;">
            <div class="w3-third w3-right" style="height: 100%;">
              <div class="w3-quarter " style="height: 100%;">              
              </div>
              <div class="w3-quarter " style="height: 100%;">
                <mat-icon (click)="ClickLabelModal()" *ngIf="barCapitalContableModal || barPasivoModal || barActivoModal" class="material-icons-outlined w3-margin-indicadores w3-button"
                  [ngStyle]="{'color':'gray'}">
                  {{labelModal()}}
                </mat-icon>
                <mat-icon (click)="showhide_modalAmpliarCerrar()" class="w3-display-topright w3-margin-indicadores w3-button" style="margin-right: 24px;">clear</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-row" style="height: 60vh;" >
        <div class="w3-row w3-margin" style="height: 51vh;">
          <ngx-charts-area-chart *ngIf="pieActivoModal" id="pieActivoModal"
            [results]="multiActivo"
            [xAxis]="true"
            [scheme]="colorSchemeActivo"
            [xAxis]="showXAxisActivo"
            [yAxis]="showYAxisActivo"
            [legend]="showLegendActivo"
            [showXAxisLabel]="showXAxisLabelActivo"
            [showYAxisLabel]="showYAxisLabelActivo"
            [xAxisLabel]="xAxisLabelActivo"
            [yAxisLabel]="yAxisLabelActivo"
            [showGridLines]="false">
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiActivo; let i = index">
                  <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiActivo; let i = index">
                  <span>{{buscarCuentasLineal(model)}}</span>
                </div>
              </div>
            </ng-template>
          </ngx-charts-area-chart>
          <ngx-charts-bar-vertical *ngIf="barActivoModal" id="barActivoModal"
            [scheme]="colorSchemeActivo"
            [showGridLines]="false"
            [results]="singleActivo"
            [xAxis]="showXAxisActivo"
            [yAxis]="showYAxisActivo"
            [legend]="showLegendActivo"
            [showXAxisLabel]="showXAxisLabelActivo"
            [showYAxisLabel]="showYAxisLabelActivo"
            [xAxisLabel]="xAxisLabelActivo"
            [yAxisLabel]="yAxisLabelActivo"
            [showDataLabel]="showDataLabelModal"
            (select)="onSelect($event)">
            <ng-template  #tooltipTemplate let-model="model" > 
              <div style="text-align: left;"> 
                <div *ngFor="let data of singleActivo; let i = index">
                  <span *ngIf="data.name === model.name">
                    {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                  </span> 
                </div> 
              </div>
            </ng-template> 
          </ngx-charts-bar-vertical>
          <ngx-charts-line-chart *ngIf="lineActivoModal" id="lineActivoMomdal"
            [results]="multiActivo"
            [xAxis]="true"
            [scheme]="colorSchemeActivo"
            [xAxis]="showXAxisActivo"
            [yAxis]="showYAxisActivo"
            [legend]="showLegendActivo"
            [showXAxisLabel]="showXAxisLabelActivo"
            [showYAxisLabel]="showYAxisLabelActivo"
            [xAxisLabel]="xAxisLabelActivo"
            [yAxisLabel]="yAxisLabelActivo"
            [showGridLines]="false">
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiActivo; let i = index">
                  <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiActivo; let i = index">
                  <span>{{buscarCuentasLineal(model)}}</span>
                </div>
              </div>
            </ng-template>
          </ngx-charts-line-chart>

          <ngx-charts-area-chart *ngIf="piePasivoModal" id="piePasivoModal"
            [results]="multiPasivo"
            [xAxis]="true"
            [scheme]="colorSchemePasivo"
            [xAxis]="showXAxisPasivo"
            [yAxis]="showYAxisPasivo"
            [legend]="showLegendPasivo"
            [showXAxisLabel]="showXAxisLabelPasivo"
            [showYAxisLabel]="showYAxisLabelPasivo"
            [xAxisLabel]="xAxisLabelPasivo"
            [yAxisLabel]="yAxisLabelPasivo"
            [showGridLines]="false">
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiPasivo; let i = index">
                  <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiPasivo; let i = index">
                  <span>{{buscarCuentasLineal(model)}}</span>
                </div>
              </div>
            </ng-template> 
          </ngx-charts-area-chart>  
          <ngx-charts-bar-vertical *ngIf="barPasivoModal" id="barPasivoModal"
            [scheme]="colorSchemePasivo"
            [showGridLines]="false"
            [results]="singlePasivo"
            [xAxis]="showXAxisPasivo"
            [yAxis]="showYAxisPasivo"
            [legend]="showLegendPasivo"
            [showXAxisLabel]="showXAxisLabelPasivo"
            [showYAxisLabel]="showYAxisLabelPasivo"
            [xAxisLabel]="xAxisLabelPasivo"
            [yAxisLabel]="yAxisLabelPasivo"
            [showDataLabel]="showDataLabelModal"
            (select)="onSelect($event)">
            <ng-template  #tooltipTemplate let-model="model" > 
              <div style="text-align: left;"> 
                <div *ngFor="let data of singlePasivo; let i = index">
                  <span *ngIf="data.name === model.name">
                    {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                  </span> 
                </div> 
              </div>
            </ng-template> 
          </ngx-charts-bar-vertical>
          <ngx-charts-line-chart *ngIf="linePasivoModal" id="linePasivoModal"
            [results]="multiPasivo"
            [xAxis]="true"
            [scheme]="colorSchemePasivo"
            [xAxis]="showXAxisPasivo"
            [yAxis]="showYAxisPasivo"
            [legend]="showLegendPasivo"
            [showXAxisLabel]="showXAxisLabelPasivo"
            [showYAxisLabel]="showYAxisLabelPasivo"
            [xAxisLabel]="xAxisLabelPasivo"
            [yAxisLabel]="yAxisLabelPasivo"
            [showGridLines]="false">
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiPasivo; let i = index">
                  <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiPasivo; let i = index">
                  <span>{{buscarCuentasLineal(model)}}</span>
                </div>
              </div>
            </ng-template> 
          </ngx-charts-line-chart>  

          <ngx-charts-area-chart *ngIf="pieCapitalContableModal" id="pieCapitalContableModal"
            [results]="multiCapitalContable"
            [xAxis]="true"
            [scheme]="colorSchemeCapitalContable"
            [xAxis]="showXAxisCapitalContable"
            [yAxis]="showYAxisCapitalContable"
            [legend]="showLegendCapitalContable"
            [showXAxisLabel]="showXAxisLabelCapitalContable"
            [showYAxisLabel]="showYAxisLabelCapitalContable"
            [xAxisLabel]="xAxisLabelCapitalContable"
            [yAxisLabel]="yAxisLabelCapitalContable"
            [showGridLines]="false">
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiCapitalContable; let i = index">
                  <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiCapitalContable; let i = index">
                  <span>{{buscarCuentasLineal(model)}}</span>
                </div>
              </div>
            </ng-template> 
          </ngx-charts-area-chart> 
          <ngx-charts-bar-vertical *ngIf="barCapitalContableModal" id="barCapitalContableModal"
            [scheme]="colorSchemeCapitalContable"
            [showGridLines]="false"
            [results]="singleCapitalContable"
            [xAxis]="showXAxisCapitalContable"
            [yAxis]="showYAxisCapitalContable"
            [legend]="showLegendCapitalContable"
            [showXAxisLabel]="showXAxisLabelCapitalContable"
            [showYAxisLabel]="showYAxisLabelCapitalContable"
            [xAxisLabel]="xAxisLabelCapitalContable"
            [yAxisLabel]="yAxisLabelCapitalContable"
            [showDataLabel]="showDataLabelModal"
            (select)="onSelect($event)">
            <ng-template  #tooltipTemplate let-model="model" > 
              <div style="text-align: left;"> 
                <div *ngFor="let data of singleCapitalContable; let i = index">
                  <span *ngIf="data.name === model.name">
                    {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                  </span> 
                </div> 
              </div>
            </ng-template> 
          </ngx-charts-bar-vertical>
          <ngx-charts-line-chart *ngIf="lineCapitalContableModal" id="lineCapitalContableModal"
            [results]="multiCapitalContable"
            [xAxis]="true"
            [scheme]="colorSchemeCapitalContable"
            [xAxis]="showXAxisCapitalContable"
            [yAxis]="showYAxisCapitalContable"
            [legend]="showLegendCapitalContable"
            [showXAxisLabel]="showXAxisLabelCapitalContable"
            [showYAxisLabel]="showYAxisLabelCapitalContable"
            [xAxisLabel]="xAxisLabelCapitalContable"
            [yAxisLabel]="yAxisLabelCapitalContable"
            [showGridLines]="false">
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiCapitalContable; let i = index">
                  <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of multiCapitalContable; let i = index">
                  <span>{{buscarCuentasLineal(model)}}</span>
                </div>
              </div>
            </ng-template> 
          </ngx-charts-line-chart>  
        </div>
      </div>
    </div>
  </div>