import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Importaciones  Angular Material */
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule} from '@angular/material/menu';
import { MatDividerModule} from '@angular/material/divider';
import { MatInputModule} from '@angular/material/input';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule} from '@angular/material/radio';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule} from '@angular/material/button';
import { MatSelectModule} from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

/* Importación de gráficos */
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { GoogleChartsModule } from 'angular-google-charts';
/* Importación de gráficos */

/* Importación de ComboChart */
import { ComboChartComponent } from '../../combo-chart';
import { ComboSeriesVerticalComponent } from '../../combo-chart';
import { StackedChartComponent } from '../../stacked-chart';
import { StackedSeriesVerticalComponent } from '../../stacked-chart';
import { GroupBarComponent } from '../../group-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LegendComponent } from './legend/legend.component';

@NgModule({
  declarations: [
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    StackedChartComponent,
    StackedSeriesVerticalComponent,
    GroupBarComponent,
    LegendComponent,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    FormsModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    NgxChartsModule,
    ChartsModule,
    GoogleChartsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCardModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    FormsModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    NgxChartsModule,
    ChartsModule,
    GoogleChartsModule,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    StackedChartComponent,
    StackedSeriesVerticalComponent,
    GroupBarComponent,
    MatExpansionModule,
    LegendComponent,
    MatTooltipModule,
    MatCardModule,
    ReactiveFormsModule,
  ]
})
export class SharedModule { }
