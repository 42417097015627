<!--Encabezado de la página-->
<div class="w3-container w3-contenedor-template w3-light-grey" style="height: 6vh;">
  <!--Titulo de la página-->
  <div class="w3-quarter padreizquierda">
    <div class="w3-row hijoizquierda">
      <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b>Riesgo de crédito</b></span>
      <span class="w3-text-deep-orange"> &bull; </span>
      <span class="w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> Concentración</span>
    </div>
  </div>
  <div class="w3-rest">
    <div class="w3-twothird" *ngIf="filtroNorm">
      <!--Filtros de años-->
      <div class="w3-half w3-margin-left-filter">
        <div class="w3-third hijoizquierda">
          <button class="w3-button w3-right" [mat-menu-trigger-for]="menuAniosP2">
            Año
            <mat-icon class="iconoMen">keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu class="w3-medium" #menuAniosP2="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let an of aniosDisponibles">
              <mat-checkbox [checked]="an.check" 
                            (click)=" $event.stopPropagation();"
                            (change)="anioChange(an);">{{an.name}}</mat-checkbox>
            </button>
          </mat-menu>
        </div>
        <div class="w3-rest hijoizquierda" style="height: 4vh;">
          <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
            <div class="w3-row hijoizquierda chips">
              <div [ngClass]="anios.check?
              'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                *ngFor="let anios of aniosDisponibles"
                class="filtroMesAnioSeleccionado">
                <span class="contenidoChips">{{anios.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Filtros de meses-->
      <div class="w3-rest w3-margin-left-filter">
        <div class="w3-third  hijoizquierda">
          <button class="w3-button w3-right" [mat-menu-trigger-for]="menumeses">
            Mes
            <mat-icon class="iconoMen">keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu class="w3-medium" #menumeses="matMenu">
            <button mat-menu-item *ngFor="let mes of mesesDisponibles">
              <mat-checkbox [checked]="mes.check" 
                            (click)=" $event.stopPropagation();"
                            (change)="mesChange(mes, mesesDeAnioDisponibles);">{{mes.name}}</mat-checkbox>
            </button>
          </mat-menu>
        </div>
        <div class="w3-rest hijoizquierda" style="height: 4vh;">
          <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
            <div class="w3-row hijoizquierda chips">
              <div [ngClass]="mesfil.check?
              'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                *ngFor="let mesfil of mesesDisponibles"
                class="filtroMesAnioSeleccionado">
                <span class="contenidoChips">{{mesfil.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w3-twothird" *ngIf="!filtroNorm">
      <div class="w3-quarter_e hijoizquierda">
        <button class="w3-button w3-right" [mat-menu-trigger-for]="menuPeriodos">
          Periodos
          <mat-icon class="iconoMen">keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menuPeriodos="matMenu" xPosition="before">
          <ng-container *ngFor="let an of aniosDisponibles">
            <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
              <span>{{an.name}}</span>
            </button>
            <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
              <span *ngFor="let child of DatosAnios.mesesDisponibles">
                <span *ngIf="an.name === child.year">
                  <button mat-menu-item (click)="$event.stopPropagation(); seleccionarmes(child)">
                    <div class="w3-quarter">
                      <input style="cursor: pointer; margin-top: 7px;"
                            (change)="seleccionarmes(child)"
                            [checked]="child.check" type="checkbox"
                            (click)="$event.stopPropagation();">
                    </div>
                    <div class="w3-rest">
                      <span style="margin-left: 5px;">{{child.name}}</span> 
                    </div>           
                  </button>
                </span>
              </span>
            </mat-menu>
          </ng-container>
        </mat-menu>
      </div>
      <div class="w3-rest hijoizquierda" style="height: 4vh;">
        <div class="w3-noventa2" id="Cchip">
          <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
            <div class="w3-row hijoizquierda chips">
              <div [ngClass]="mesfil.check?
              'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                *ngFor="let mesfil of DatosAnios.mesesDisponibles" (click)="seleccionarmes(mesfil)"
                class="filtroMesAnioSeleccionado">
                <span class="contenidoChips">{{mesfil.name}}-{{anioEsc(mesfil.year)}} <mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
              </div>
            </div>
          </div>
        </div>
        <div class="w3-rest" *ngIf="aniosSelec()">
          <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuanios2">more_horiz</mat-icon>
        </div> 
        <mat-menu #menuanios2="matMenu" xPosition="before">
          <div *ngFor="let mesfil of DatosAnios.mesesDisponibles"
              [ngClass]="mesfil.check?
                   'w3-round-btn-large w3-gris-btn2 w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
              class="filtroMesAnioSeleccionado w3-border" (click)="seleccionarmes(mesfil); $event.stopPropagation();">
              <span style="margin-left: 5px;">{{mesfil.name}}-{{anioEsc(mesfil.year)}}</span> 
              <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
          </div>
        </mat-menu>
      </div>
    </div>
    <!--Cambio de tipo de Filtro-->
    <div class="w3-quarter_e w3-margin-left-filter">
      <div class="hijoizquierda" style="margin-left: 1vh;">
        <mat-checkbox [checked]="!filtroNorm" (change)="cambioFiltro();">
          Filtrado
        </mat-checkbox>
      </div>
    </div>
    <!--Borrar filtros-->
    <div class="w3-rest w3-right">
      <button mat-icon-button>
        <mat-icon title="limpiar_filtros" (click)="quitar_filtros()">
          <img src="assets/img/graficos/eraser.png">
        </mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="w3-row" style="height: 42vh;" id="Zona1">
  <!--Encabezado del primer cuadrante-->
  <div class="w3-row w3-write w3-header-cuadrante" style="height: 6vh;">
    <!--Titulo de la primer gráfica-->
    <div class="w3-twothird hijoIzquierdaTitle w3-titulo-contenidomenu">
      <div class="hijoizquierdaTitle">
        <span class="w3-margin-left-contenido" *ngIf="gtablaP">{{milTab1}}{{TituloGrafPrinc}}</span>
        <span class="w3-margin-left-contenido" *ngIf="!gtablaP">{{TituloGrafPrinc}}</span>
        <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
        <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
        <span class=" w3-margin-left-filter w3-subtitulo">{{subTituloGrafPrinc}}</span>
      </div>
    </div>
    <!--Menu Gráfica principal-->
    <div class="w3-rest w3-right-align_rest">
      <div class="w3-right hijoderechaTitle">
        <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliar()" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
        <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuP2">more_vert</mat-icon>
      </div>
      <mat-menu #menuP2="matMenu" xPosition="before">
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">search</mat-icon>
          <span>Buscar</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="GraficasP2">
          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
          Gráficas
        </button>
        <button mat-menu-item [matMenuTriggerFor]="PerspectivaP2">
          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree</mat-icon>Perspectiva
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="export">
          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
          <span>Exportar</span>
        </button>
        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" *ngIf="gtablaP" [matMenuTriggerFor]="moneda">
          <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">paid</mat-icon>
          <span>mil/pes</span>
        </button>
      </mat-menu>

      <mat-menu #export="matMenu">
        <button mat-menu-item *ngIf="!gtablaP" (click)="export_img(1)">
          <span>PNG</span>
        </button>
        <button mat-menu-item *ngIf="gtablaP" (click)="export_csv(1)">
          <span>CSV</span>
        </button>
      </mat-menu>

      <mat-menu #moneda="matMenu">
        <button mat-menu-item (click)="CambMon('Mil',1)">
          <span>Miles</span>
        </button>
        <button mat-menu-item (click)="CambMon('Pesos',1)">
          <span>Pesos</span>
        </button>
      </mat-menu>

      <mat-menu #GraficasP2="matMenu">
        <button (click)="gbarrasP2()" mat-menu-item>Barras</button>
        <button (click)="glinealP2()" mat-menu-item>Lineal</button>
        <button (click)="gareaP2()" mat-menu-item>Área</button>
        <button (click)="gtablaP2()" mat-menu-item>Tabla</button>
      </mat-menu>

      <mat-menu #PerspectivaP2="matMenu">
        <button (click)="perspectiva('Sector')" mat-menu-item>Sector</button>
        <button (click)="perspectiva('Zona')" mat-menu-item>Zona</button>
        <button (click)="perspectiva('Genero')" mat-menu-item>Genero</button>
        <button (click)="perspectiva('EstatusCredito')" mat-menu-item>Estatus credito</button>
        <button (click)="perspectiva('Garantia')" mat-menu-item>Garantia</button>
      </mat-menu>
      <mat-menu #ZonasP2="matMenu">
        <button mat-menu-item>Aguascalientes</button>
        <button mat-menu-item>Ciudad de México</button>
        <button mat-menu-item>Jalisco</button>
        <button mat-menu-item>México</button>
        <button mat-menu-item>Nuevop León</button>
      </mat-menu>
    </div>
  </div>
  <!--Gráfica principal -->
  <div class="w3-row" style="height: 31vh;">
    <ngx-charts-bar-vertical-normalized *ngIf="graficaBarrasP2"
    [scheme]="colorSchemeBarras" 
    [results]="multi2"
    [gradient]="gradient" 
    [legend]="showLegend" 
    [legendTitle]="legendTitle"
    [legendPosition]="legendPosition"
    [xAxis]="xAxis" 
    [yAxis]="yAxis" 
    [showXAxisLabel]="showXAxisLabel" 
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel" 
    [yAxisLabel]="yAxisLabel"
    >
    </ngx-charts-bar-vertical-normalized>
    <ngx-charts-line-chart *ngIf="graficaLinearP2" 
    [scheme]="colorSchemeBarras" 
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel" 
    [xAxis]="xAxis" 
    [yAxis]="yAxis" 
    [showGridLines]="showGridLines"
    [legend]="showLegend" 
    [legendTitle]="legendTitle" 
    [legendPosition]="legendPosition" 
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [results]="lineal"
    >
    </ngx-charts-line-chart>
    <div class="w3-encabezado-table w3-scroll" *ngIf="gtablaP && eslAgrupSec">
      <div class="w3-table-all">
        <table mat-table [dataSource]="dataSource2" class=" w3-table">
          <!-- Position Column -->
          <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
            <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="servicios">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
            <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}} <br>
              {{element.servicios1}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto1">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[0]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto1,miles1)}}
              <br> {{formatearnormal2(element.producto11)}}
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto2">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[1]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto2,miles1)}}
              <br> {{formatearnormal2(element.producto21)}}
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto3">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[2]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto3,miles1)}}
              <br> {{formatearnormal2(element.producto31)}}
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto4">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[3]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto4,miles1)}}
              <br> {{formatearnormal2(element.producto41)}}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
      </div>
    </div>
    <div class="w3-encabezado-table w3-scroll" *ngIf="gtablaP && eslAgrupGen">
      <div class="w3-table-all">
        <table mat-table [dataSource]="dataSource2" class=" w3-table">
          <!-- Position Column -->
          <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
            <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="servicios">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
            <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}} <br>
              {{element.servicios1}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto1">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[0]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto1,miles1)}}
              <br> {{formatearnormal2(element.producto11)}}
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto2">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[1]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto2,miles1)}}
              <br> {{formatearnormal2(element.producto21)}}
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto3">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[2]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto3,miles1)}}
              <br> {{formatearnormal2(element.producto31)}}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
      </div>
    </div>
    <div class="w3-encabezado-table w3-scroll" *ngIf="gtablaP && eslAgrupEstat">
      <div class="w3-table-all">
        <table mat-table [dataSource]="dataSource2" class=" w3-table">
          <!-- Position Column -->
          <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
            <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="servicios">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
            <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}} <br>
              {{element.servicios1}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto1">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[0]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto1,miles1)}}
              <br> {{formatearnormal2(element.producto11)}}
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto2">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[1]}} </th>
            <td mat-cell style="width: 9%;" *matCellDef="let element"> $ {{formatearnormal(element.producto2,miles1)}}
              <br> {{formatearnormal2(element.producto21)}}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
      </div>
    </div>
    <div class="w3-encabezado-table w3-responsive" *ngIf="gtablaP && eslAgrupZon">
      <div class="w3-table-all">
        <table mat-table [dataSource]="dataSource2" class=" w3-table">
          <!-- Position Column -->
          <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
            <td mat-cell class="w3-medium" style="width: 7%; font-weight: bold;" *matCellDef="let element">
              {{element.periodo}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="servicios">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
            <td mat-cell class="w3-medium" style="width: 5%; font-weight: bold;" *matCellDef="let element">
              {{element.servicios}} <br> {{element.servicios1}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto1">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[0]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto1,miles1)}} <br> {{formatearnormal2(element.producto11)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto2">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[1]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto2,miles1)}} <br> {{formatearnormal2(element.producto21)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto3">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[2]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto3,miles1)}} <br> {{formatearnormal2(element.producto31)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto4">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[3]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto4,miles1)}} <br> {{formatearnormal2(element.producto41)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto5">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[4]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto5,miles1)}} <br> {{formatearnormal2(element.producto51)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto6">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[5]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto6,miles1)}} <br> {{formatearnormal2(element.producto61)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto7">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[6]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto7,miles1)}} <br> {{formatearnormal2(element.producto71)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto8">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[7]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto8,miles1)}} <br> {{formatearnormal2(element.producto81)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto9">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[8]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto9,miles1)}} <br> {{formatearnormal2(element.producto91)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto10">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[9]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto10,miles1)}} <br> {{formatearnormal2(element.producto101)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto11">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[10]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto110,miles1)}} <br> {{formatearnormal2(element.producto111)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto12">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[11]}} </th>
            <td mat-cell class="w3-medium" style="width: 9%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto12,miles1)}} <br> {{formatearnormal2(element.producto121)}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
      </div>
    </div>
    <div class="w3-encabezado-table w3-responsive" *ngIf="gtablaP && eslAgrupGar">
      <div class="w3-table-all">
        <table mat-table [dataSource]="dataSource2" class=" w3-table">
          <!-- Position Column -->
          <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
            <td mat-cell class="w3-medium" style="width: 7%; font-weight: bold;" *matCellDef="let element">
              {{element.periodo}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="servicios">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
            <td mat-cell class="w3-medium" style="width: 5%; font-weight: bold;" *matCellDef="let element">
              {{element.servicios}} <br> {{element.servicios1}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto1">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[0]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto1,miles1)}} <br> {{formatearnormal2(element.producto11)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto2">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[1]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto2,miles1)}} <br> {{formatearnormal2(element.producto21)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto3">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[2]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto3,miles1)}} <br> {{formatearnormal2(element.producto31)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto4">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[3]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto4,miles1)}} <br> {{formatearnormal2(element.producto41)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto5">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[4]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto5,miles1)}} <br> {{formatearnormal2(element.producto51)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto6">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[5]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto6,miles1)}} <br> {{formatearnormal2(element.producto61)}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="producto7">
            <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[6]}} </th>
            <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
              {{formatearnormal(element.producto7,miles1)}} <br> {{formatearnormal2(element.producto71)}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
      </div>
    </div>
    <ngx-charts-area-chart *ngIf="graficaAreaP2" 
    [scheme]="colorSchemeBarras" 
    [legend]="showLegend"
    [legendTitle]="legendTitle" 
    [showXAxisLabel]="showXAxisLabel" 
    [showYAxisLabel]="showYAxisLabel" 
    [xAxis]="xAxis"
    [yAxis]="yAxis" 
    [xAxisLabel]="xAxisLabel" 
    [yAxisLabel]="yAxisLabel"
    [results]="lineal"
    >
    </ngx-charts-area-chart>
    <div class="w3-row" style="width: 99.6%;">
      <div class="w3-row w3-right" *ngIf="graficaBarrasP2">
        <button mat-icon-button (click)="popChange()"
          onclick="document.getElementById('graficasModal').style.display='block'">
          <mat-icon [ngStyle]="{'color':'gray'}">info</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<!--Zona secundaria de la pagina-->
<div class="w3-row">
  <div class="w3-row w3-light-grey" style="height: 2vh;"></div>
  <div class="w3-row">
    <!--Zona 2-->
    <div class="w3-twothird" id="Zona2">
      <!--Encabezado de zona 2-->
      <div class="w3-row" style="height: 6vh;">
        <!--Titulo de la zona 2-->
        <div class="w3-twothird hijoIzquierdaTitle w3-titulo-contenidomenu">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido" *ngIf="gtablas">{{milTab2}} {{TituloGrafSec}}</span>
            <span class="w3-margin-left-contenido" *ngIf="!gtablas">{{TituloGrafSec}}</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
            <span class=" w3-margin-left-filter w3-subtitulo">{{subTituloGrafSec}}</span>
          </div>
        </div>
        <!--Menú de la zona 2-->
        <div class="w3-rest w3-right">
          <div class="w3-right hijoderechaTitle">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarG2()" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuG2p2">more_vert</mat-icon>
          </div>
          <mat-menu #menuG2p2="matMenu" xPosition="before">
            <button mat-menu-item>
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">search</mat-icon>
              <span>Buscar</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="graficasZon2" mat-menu-item>
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
              <span>Graficas</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="Perspectiva">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree</mat-icon>Perspectiva
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="export2">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar</span>
            </button>
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" *ngIf="gtablas"
              [matMenuTriggerFor]="moneda2">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">paid</mat-icon>
              <span>mil/pes</span>
            </button>
          </mat-menu>
          <mat-menu #export2="matMenu">
            <button mat-menu-item *ngIf="!gtablas" (click)="export_img(2)">
              <span>PNG</span>
            </button>
            <button mat-menu-item *ngIf="gtablas" (click)="export_csv(2)">
              <span>CSV</span>
            </button>
          </mat-menu>
          <mat-menu #moneda2="matMenu">
            <button mat-menu-item (click)="CambMon('Mil',2)">
              <span>Miles</span>
            </button>
            <button mat-menu-item (click)="CambMon('Pesos',2)">
              <span>Pesos</span>
            </button>
          </mat-menu>
          <mat-menu #graficasZon2="matMenu">
            <button (click)="gbarrashor()" mat-menu-item>Barras horizontales</button>
            <button (click)="gbarrasver()" mat-menu-item>Barras verticales</button>
            <button (click)="gTablas()" mat-menu-item>Tabla</button>
          </mat-menu>
          <mat-menu #Exportar2p2="matMenu">
            <button mat-menu-item>PDF</button>
            <button mat-menu-item>Word</button>
          </mat-menu>
          <mat-menu #Perspectiva="matMenu">
            <button (click)="perspectiva2('Producto')" mat-menu-item>Producto</button>
            <button (click)="perspectiva2('CategoriaCredito')" mat-menu-item>Bandas</button>
            <!--[matMenuTriggerFor]="ZonasP2"-->
          </mat-menu>
        </div>
      </div>
      <!--graficos de la zona 2-->
      <div class="w3-row" style="height: 34vh;" *ngIf="grafBarrashor">
        <ngx-charts-bar-horizontal-normalized 
        [scheme]="colorSchemeBarras2" 
        [results]="DatosProduct"
        [gradient]="gradient" 
        [legend]="showLegend" 
        [legendTitle]="legendTitle" 
        [legendPosition]="legendPosition"
        [xAxis]="yAxis" 
        [yAxis]="xAxis" 
        [showXAxisLabel]="showXAxisLabel" 
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="yAxisLabel" 
        [yAxisLabel]="xAxisLabel"
        >
        </ngx-charts-bar-horizontal-normalized>
      </div>
      <div class="w3-row" style="height: 34vh;" *ngIf="gtablas && eslAgrupPro">
        <div class="w3-encabezado-table w3-scroll">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="periodo">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="servicios">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}} <br>
                  {{element.servicios1}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto1">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[0].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto1,miles2)}} <br> {{formatearnormal2(element.producto11)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto2">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[1].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto2,miles2)}} <br> {{formatearnormal2(element.producto21)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto3">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[2].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto3,miles2)}} <br> {{formatearnormal2(element.producto31)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto4">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[3].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto4,miles2)}} <br> {{formatearnormal2(element.producto41)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto5">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[4].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto5,miles2)}} <br> {{formatearnormal2(element.producto51)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto6">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> C AVIO </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto6,miles2)}} <br> {{formatearnormal2(element.producto61)}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="w3-elemento-titulo-table">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
      <div class="w3-row" style="height: 34vh;" *ngIf="gtablas && eslAgrupBan">
        <div class="w3-encabezado-table w3-scroll">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="periodo" class="w3-table-title">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                <td mat-cell class="w3-tiny" style="width: 10%; font-weight: bold;" *matCellDef="let element">
                  {{element.periodo}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="servicios">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                <td mat-cell class="w3-tiny" style="width: 5%; font-weight: bold;" *matCellDef="let element">
                  {{element.servicios}} <br> {{element.servicios1}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto1">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> A-1 </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto1),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto11))}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto2">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> A-2 </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto2),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto21))}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto3">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> B-1 </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto3),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto31))}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto4">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> B-2 </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto4),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto41))}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto5">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> B-3 </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto5),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto51))}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto6">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> C-1 </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto6),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto61))}} </td>
              </ng-container>
              <ng-container matColumnDef="producto7">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> E </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto7),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto71))}} </td>
              </ng-container>
              <ng-container matColumnDef="producto8">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> D </th>
                <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(dato(element.producto8),miles2)}} <br>
                  {{formatearnormal2(dato(element.producto81))}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="w3-elemento-titulo-table">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
      <div class="w3-row" style="height: 34vh;" *ngIf="grafBarrasver">
        <ngx-charts-bar-vertical-normalized 
        [scheme]="colorSchemeBarras2" 
        [results]="DatosProduct2"
        [legend]="showLegend" 
        [legendTitle]="legendTitle" 
        [legendPosition]="legendPosition" 
        [gradient]="gradient"
        [xAxis]="xAxis" 
        [yAxis]="yAxis" 
        [showXAxisLabel]="showXAxisLabel" 
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel" 
        [yAxisLabel]="yAxisLabel"
        >
        </ngx-charts-bar-vertical-normalized>
      </div>
    </div>
    <!--gráfica-->
    <div class="w3-rest w3-light-grey">
      <!--Zona 3-->
      <div class="w3-row" id="Zona3">
        <div class="w3-row w3-right w3-white" style="width: 98%;">
          <!--Encabezado de la zona 3-->
          <div class="w3-row" style="height: 6vh;">
            <!--Titulo de la zona 3-->
            <div class="w3-noventa hijoIzquierdaTitle w3-titulo-contenidomenu">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido">{{TituloGrafTer}}</span>
                <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
                <span class=" w3-margin-left-filter w3-subtitulo">{{subTituloGrafTer}}</span>
              </div>
            </div>
            <!--Menú de la zona 3-->
            <div class="w3-rest w3-right">
              <div class="w3-right hijoderechaTitle">
                <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliarG3()" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuG3p2">more_vert</mat-icon>
              </div>
              <mat-menu #menuG3p2="matMenu" xPosition="before">
                <button mat-menu-item>
                  <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">search</mat-icon>
                  <span>Buscar</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="Graficas3p2">
                  <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>Gráficas
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item [matMenuTriggerFor]="export3">
                  <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
                  <span>Exportar</span>
                </button>
              </mat-menu>
              <mat-menu #export3="matMenu">
                <button mat-menu-item (click)="export_img(3)">
                  <span>PNG</span>
                </button>
              </mat-menu>
              <mat-menu #Graficas3p2="matMenu">
                <button (click)="gpie2()" mat-menu-item>Pie</button>
                <button (click)="gcalor2()" mat-menu-item>Calor</button>
                <button (click)="gbarras2()" mat-menu-item>Barras</button>
              </mat-menu>
              <mat-menu #Exportar3p2="matMenu">
                <button mat-menu-item>PDF</button>
                <button mat-menu-item>Word</button>
              </mat-menu>
            </div>
          </div>
          <!--Graficas de la tercer zona-->
          <div class="w3-row w3-white" style="height: 35vh;">
            <!--Grafica de PIE-->
            <div class="w3-withe" style="height: 28vh;" *ngIf="graficaPie2">
              <ngx-charts-pie-chart class="piechart-googles" 
              [scheme]="colorScheme" 
              [results]="data" 
              [legend]="labels"
              [legendPosition]="lePos" 
              [gradient]="gradient" 
              [doughnut]="isDoughnut" 
              [arcWidth]="arcWidth"
              >
              </ngx-charts-pie-chart>
            </div>
            <!--Grafica de calor-->
            <ngx-charts-tree-map *ngIf="graficaCalor2" 
            [scheme]="colorScheme" 
            [results]="data" 
            [gradient]="gradient"
            [animations]="animations"
            >
            </ngx-charts-tree-map>
            <ngx-charts-bar-vertical-stacked *ngIf="graficaBarras2" 
            [scheme]="colorSchemeBarras" 
            [results]="data2"
            [gradient]="gradient" 
            [xAxis]="xAxis" 
            [yAxis]="yAxis" 
            [xAxisLabel]="xAxisLabel" 
            [yAxisLabel]="yAxisLabel"
            >
            </ngx-charts-bar-vertical-stacked>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- The Modal -->
<div id="graficasModal" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%" *ngIf="!pop">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda "
          style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{Title}}</span><span
                class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span><span
                class=" w3-margin-left-filter w3-subtitulo">{{Subtitulo_grafica}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-rest ">
        <mat-icon (click)="cerrarAmp()" onclick="document.getElementById('graficasModal').style.display='none'"
          class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class=" w3-row" style="height: 65vh;">
      <div class="w3-row w3-margin" style="height: 56vh;">
        <div *ngIf="graficaBarrasP2Modal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-bar-vertical-normalized 
            [scheme]="colorSchemeBarras" 
            [results]="multi2" 
            [gradient]="gradient"
            [legend]="showLegend" 
            [legendTitle]="legendTitle" 
            [xAxis]="xAxis" 
            [yAxis]="yAxis"
            [showXAxisLabel]="showXAxisLabel" 
            [showYAxisLabel]="showYAxisLabel" 
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-vertical-normalized>
        </div>
        <div *ngIf="graficaLinearP2Modal" class="w3-rest w3-right w3-encabezado-table" style="height: 55vh; width: 100%;">
          <ngx-charts-line-chart 
            [scheme]="colorSchemeBarras" 
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" 
            [xAxis]="xAxis" 
            [yAxis]="yAxis" 
            [showGridLines]="showGridLines"
            [legend]="showLegend" 
            [legendTitle]="legendTitle" 
            [xAxisLabel]="xAxisLabel" 
            [yAxisLabel]="yAxisLabel"
            [results]="lineal">
          </ngx-charts-line-chart>
        </div>
        <div *ngIf="graficaAreaP2Modal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-area-chart 
            [scheme]="colorSchemeBarras" 
            [legend]="legend" 
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" 
            [xAxis]="xAxis" 
            [yAxis]="yAxis" 
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [results]="lineal">
          </ngx-charts-area-chart>
        </div>
        <div *ngIf="grafBarrasverModal" class="w3-rest w3-right w3-encabezado-table" style="height: 55vh; width: 100%;">
          <ngx-charts-bar-vertical-normalized 
            [scheme]="colorSchemeBarras2" 
            [results]="DatosProduct2"
            [legend]="showLegend" 
            [legendTitle]="legendTitle" 
            [legendPosition]="legendPosition" 
            [gradient]="gradient"
            [xAxis]="xAxis" 
            [yAxis]="yAxis" 
            [showXAxisLabel]="showXAxisLabel" 
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel" 
            [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-vertical-normalized>
        </div>
        <div *ngIf="grafBarrashorModal" class="w3-rest w3-right" style="height: 55vh; width: 100%;">
          <ngx-charts-bar-horizontal-normalized 
            [scheme]="colorSchemeBarras2" 
            [results]="DatosProduct"
            [gradient]="gradient" 
            [legend]="showLegend" 
            [legendTitle]="legendTitle" 
            [legendPosition]="legendPosition"
            [xAxis]="yAxis" 
            [yAxis]="xAxis" 
            [showXAxisLabel]="showXAxisLabel" 
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="yAxisLabel" 
            [yAxisLabel]="xAxisLabel">
          </ngx-charts-bar-horizontal-normalized>
        </div>
        <div class="w3-encabezado-table w3-scroll" *ngIf="gtablasModal && eslAgrupPro">
          <div class="w3-encabezado-table w3-scroll">
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataSource" class=" w3-table">
                <!-- Position Column -->
                <ng-container matColumnDef="periodo">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                  <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}}
                  </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="servicios">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                  <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}}
                    <br> {{element.servicios1}}
                  </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto1">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[0].name}} </th>
                  <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(element.producto1,miles2)}} <br> {{formatearnormal2(element.producto11)}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto2">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[1].name}} </th>
                  <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(element.producto2,miles2)}} <br> {{formatearnormal2(element.producto21)}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto3">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[2].name}} </th>
                  <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(element.producto3,miles2)}} <br> {{formatearnormal2(element.producto31)}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto4">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[3].name}} </th>
                  <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(element.producto4,miles2)}} <br> {{formatearnormal2(element.producto41)}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto5">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab1[0].series[4].name}} </th>
                  <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(element.producto5,miles2)}} <br> {{formatearnormal2(element.producto51)}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto6">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> C AVIO </th>
                  <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(element.producto6,miles2)}} <br> {{formatearnormal2(element.producto61)}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="w3-elemento-titulo-table">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-encabezado-table w3-scroll" *ngIf="gtablasModal && eslAgrupBan">
          <div class="w3-encabezado-table w3-scroll">
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataSource" class=" w3-table">
                <!-- Position Column -->
                <ng-container matColumnDef="periodo" class="w3-table-title">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                  <td mat-cell class="w3-tiny" style="width: 10%; font-weight: bold;" *matCellDef="let element">
                    {{element.periodo}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="servicios">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                  <td mat-cell class="w3-tiny" style="width: 5%; font-weight: bold;" *matCellDef="let element">
                    {{element.servicios}} <br> {{element.servicios1}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto1">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> A-1 </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto1),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto11))}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto2">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> A-2 </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto2),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto21))}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto3">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> B-1 </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto3),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto31))}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto4">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> B-2 </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto4),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto41))}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto5">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> B-3 </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto5),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto51))}} </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="producto6">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> C-1 </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto6),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto61))}} </td>
                </ng-container>
                <ng-container matColumnDef="producto7">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> E </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto7),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto71))}} </td>
                </ng-container>
                <ng-container matColumnDef="producto8">
                  <th mat-header-cell *matHeaderCellDef class="w3-table-title"> D </th>
                  <td mat-cell class="w3-tiny" style="width: 9%;" *matCellDef="let element"> $
                    {{formatearnormal(dato(element.producto8),miles2)}} <br>
                    {{formatearnormal2(dato(element.producto81))}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="w3-elemento-titulo-table">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-encabezado-table w3-responsive" *ngIf="gtablaPModal && eslAgrupZon">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource2" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="periodo">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                <td mat-cell class="w3-medium" style="width: 7%; font-weight: bold;" *matCellDef="let element">
                  {{element.periodo}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="servicios">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                <td mat-cell class="w3-medium" style="width: 5%; font-weight: bold;" *matCellDef="let element">
                  {{element.servicios}} <br> {{element.servicios1}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto1">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[0]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto1,miles1)}} <br> {{formatearnormal2(element.producto11)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto2">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[1]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto2,miles1)}} <br> {{formatearnormal2(element.producto21)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto3">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[2]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto3,miles1)}} <br> {{formatearnormal2(element.producto31)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto4">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[3]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto4,miles1)}} <br> {{formatearnormal2(element.producto41)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto5">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[4]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto5,miles1)}} <br> {{formatearnormal2(element.producto51)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto6">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[5]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto6,miles1)}} <br> {{formatearnormal2(element.producto61)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto7">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[6]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto7,miles1)}} <br> {{formatearnormal2(element.producto71)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto8">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[7]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto8,miles1)}} <br> {{formatearnormal2(element.producto81)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto9">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[8]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto9,miles1)}} <br> {{formatearnormal2(element.producto91)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto10">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[9]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto10,miles1)}} <br> {{formatearnormal2(element.producto101)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto11">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[10]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto110,miles1)}} <br> {{formatearnormal2(element.producto111)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto12">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[11]}} </th>
                <td mat-cell class="w3-medium" style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto12,miles1)}} <br> {{formatearnormal2(element.producto121)}} </td>
              </ng-container>
    
              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-encabezado-table w3-responsive" *ngIf="gtablaPModal && eslAgrupGar">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource2" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="periodo">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                <td mat-cell class="w3-medium" style="width: 7%; font-weight: bold;" *matCellDef="let element">
                  {{element.periodo}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="servicios">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                <td mat-cell class="w3-medium" style="width: 5%; font-weight: bold;" *matCellDef="let element">
                  {{element.servicios}} <br> {{element.servicios1}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto1">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[0]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto1,miles1)}} <br> {{formatearnormal2(element.producto11)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto2">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[1]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto2,miles1)}} <br> {{formatearnormal2(element.producto21)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto3">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[2]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto3,miles1)}} <br> {{formatearnormal2(element.producto31)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto4">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[3]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto4,miles1)}} <br> {{formatearnormal2(element.producto41)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto5">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[4]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto5,miles1)}} <br> {{formatearnormal2(element.producto51)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto6">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[5]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto6,miles1)}} <br> {{formatearnormal2(element.producto61)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto7">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{Titulos[6]}} </th>
                <td mat-cell class="w3-medium" style="width: 7%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto7,miles1)}} <br> {{formatearnormal2(element.producto71)}} </td>
              </ng-container>
    
              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-encabezado-table w3-scroll" *ngIf="gtablaPModal && eslAgrupSec">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource2" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="periodo">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="servicios">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}} <br>
                  {{element.servicios1}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto1">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[0].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto1,miles1)}} <br> {{formatearnormal2(element.producto11)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto2">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[1].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto2,miles1)}} <br> {{formatearnormal2(element.producto21)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto3">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[2].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto3,miles1)}} <br> {{formatearnormal2(element.producto31)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto4">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[3].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto4,miles1)}} <br> {{formatearnormal2(element.producto41)}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-encabezado-table w3-scroll" *ngIf="gtablaPModal && eslAgrupGen">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource2" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="periodo">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="servicios">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}} <br>
                  {{element.servicios1}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto1">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[0].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto1,miles1)}} <br> {{formatearnormal2(element.producto11)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto2">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[1].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto2,miles1)}} <br> {{formatearnormal2(element.producto21)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto3">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[2].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto3,miles1)}} <br> {{formatearnormal2(element.producto31)}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-encabezado-table w3-scroll" *ngIf="gtablaPModal && eslAgrupEstat">
          <div class="w3-table-all">
            <table mat-table [dataSource]="dataSource2" class=" w3-table">
              <!-- Position Column -->
              <ng-container matColumnDef="periodo">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> Periodo </th>
                <td mat-cell style="width: 10%; font-weight: bold;" *matCellDef="let element"> {{element.periodo}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="servicios">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> </th>
                <td mat-cell style="width: 5%; font-weight: bold;" *matCellDef="let element"> {{element.servicios}} <br>
                  {{element.servicios1}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto1">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[0].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto1,miles1)}} <br> {{formatearnormal2(element.producto11)}} </td>
              </ng-container>
              <!-- Weight Column -->
              <ng-container matColumnDef="producto2">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title"> {{datTab2[0].series[1].name}} </th>
                <td mat-cell style="width: 9%;" *matCellDef="let element"> $
                  {{formatearnormal(element.producto2,miles1)}} <br> {{formatearnormal2(element.producto21)}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="w3-elemento-titulo-table">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
          </div>
        </div>
        <div *ngIf="graficaPie2Modal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
          <ngx-charts-pie-chart class="piechart-googles" [scheme]="colorScheme" [results]="data" [legend]="labels"
            [legendPosition]="lePos" [gradient]="gradient" [doughnut]="isDoughnut" [arcWidth]="arcWidth">
          </ngx-charts-pie-chart>
        </div>
        <div *ngIf="graficaCalor2Modal" class="w3-rest w3-right w3-encabezado-table "
          style="height: 55vh; width: 100%;">
          <ngx-charts-tree-map [scheme]="colorScheme" [results]="data" [gradient]="gradient" [animations]="animations">
          </ngx-charts-tree-map>
        </div>
        <div *ngIf="graficaBarras2Modal" class="w3-rest w3-right w3-encabezado-table "
          style="height: 55vh; width: 100%;">
          <ngx-charts-bar-vertical-stacked [scheme]="colorSchemeBarras" [results]="data2" [gradient]="gradient"
            [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-vertical-stacked>
        </div>
      </div>
    </div>
  </div>
  <div class="w3-modal-content" (click)="popChange()" style="width: 30%" *ngIf="pop">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda "
          style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">Información</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-rest ">
        <mat-icon (click)="cerrarAmp()" onclick="document.getElementById('graficasModal').style.display='none'"
          class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row">
      <div class="w3-row" style="height: 98%;">
        <div class="w3-thirty">
          <div class="w3-row w3-right w3-titulo-contenidomenu">
            <span class="w3-margin-left-contenido">Concentración: </span>
          </div>
        </div>
        <div class="w3-rest">
          <div class="w3-row">
            <b class="w3-titulo-contenidomenu">Por {{elemagrup}}</b>
          </div>
        </div>
      </div>
      <div class="w3-row" style="height: 2vh;"></div>
      <div class="w3-row" style="height: 98%;">
        <div class="w3-thirty">
          <div class="w3-row w3-right w3-titulo-contenidomenu">
            <span class="w3-margin-left-contenido">IHH: </span>
          </div>
        </div>
        <div class="w3-rest">
          <div class="w3-row" style="height: 95%;">
            <b class="w3-titulo-contenidomenu">El Índice Herfindahl-Hirschman (IHH) es una medición empleada en el
              estudio económico. Esta se centra en los niveles de concentración existentes en los mercados. Es decir, el
              número de empresas que operan en los mismos y su capacidad de poder o de control en ellos.</b>
          </div>
        </div>
      </div>
      <div class="w3-row" style="height: 2vh;"></div>
      <div class="w3-row" style="height: 98%;">
        <div class="w3-thirty">
          <div class="w3-row w3-right w3-titulo-contenidomenu">
            <span class="w3-margin-left-contenido">*Nota: </span>
          </div>
        </div>
        <div class="w3-rest">
          <div class="w3-row">
            <b class="w3-titulo-contenidomenu">Para estos graficos no se <br> consideran los intereses</b>
          </div>
        </div>
      </div>
      <div class="w3-row" style="height: 2vh;"></div>
      <div class="w3-row" style="height: 2vh;"></div>
    </div>
  </div>
</div>