import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { TokenModel } from 'src/app/model/token-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private token = sessionStorage.getItem('auth_token');
  public modules: Array<string>;
  public currentUser: string;

  public currentModule: string;
  public currentSubmodule: string;

  public submodules = [
    {
      identifier: '',
      name: '',
      route: '',
      module: ''
    }
  ];

  public fillerNav = [
    {
      identifier: '',
      name: '',
      route: '',
      submodules: [],
      module: ''
    }
  ];

  constructor() {
  }

  initializeMenu(): void {
    this.fillerNav = [];
    this.submodules = [];
    if (this.token) {
      const decoded = jwtDecode<TokenModel>(this.token); // Returns with the JwtPayload type
      this.currentUser = decoded.email;
      this.modules = decoded.modules.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      //console.log("🚀 ~ DataService ~ this.modules=decoded.modules.filter ~ this.modules", this.modules)
      
      this.fillerNav.push({ identifier: 'Inicio', name: 'Inicio', route: 'app', submodules: [], module: '' });
      this.fillerNav.push({ identifier: 'Inicio', name: 'Inicio', route: 'inicio', submodules: [], module: '' });

      this.modules.forEach(module => {
        const navModule = environment.modules.find(result => result.identifier === module);
        // el filtro es para evitar que se repitan módulos cuyo nombre se repite en los submódulos (ej. cartera)
        if (navModule && this.fillerNav.filter(result => result.identifier === module).length === 0) {
          this.fillerNav.push(navModule);
        }else {
          // Submodulos
          const navSubmodules = environment.submodules.filter(result => result.identifier === module);
          // el filtro es para evitar que se repitan módulos cuyo nombre se repite en los submódulos (ej. cartera)
          if (navSubmodules) {
            this.submodules = this.submodules.concat(navSubmodules);
          }
        }
      });
      this.fillerNav.forEach(module => {
        module.submodules = this.submodules.filter(result => result.module === module.name);
      });
      this.fillerNav.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    
  }
}
