import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { PermisosSubmodulosGuard } from 'src/app/guards/permisos-submodulos/permisos-submodulos.guard';
import { ConstantesQubos } from 'src/app/util/app-util';
import { AhorroComponent } from './ahorro/ahorro.component';
import { CarteraComponent } from './cartera/cartera.component';
import { SociosComponent } from './socios/socios.component';
import { TendenciaComponent } from './tendencia/tendencia.component';
import { Tendencia2Component } from './tendencia2/tendencia2.component';
import { MetaVsRealComponent } from './../colocacion/meta-vs-real/meta-vs-real.component';
import { PerfilComponent } from './perfil/perfil.component';
import { AltasBajasComponent } from './altas-bajas/altas-bajas.component';
import { EstadisticoComponent } from './estadistico/estadistico.component';

const PATH = ConstantesQubos.strPATH;

const routes: Routes = [
  {
    //path: PATH + '/comercial',
    path: PATH + '/captacion',
    component: WelcomeComponent,
    children: [
      //{ path: 'cartera', component: CarteraComponent, canActivate: [PermisosSubmodulosGuard] },
      //{ path: 'ahorro', component: AhorroComponent, canActivate: [PermisosSubmodulosGuard] },
      //{ path: 'socios', component: SociosComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'tendencia', component: TendenciaComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'tendenciav2', component: Tendencia2Component, canActivate: [PermisosSubmodulosGuard] },
      { path: 'perfil', component: PerfilComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'altas-bajas', component: AltasBajasComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'meta-vs-real', component: MetaVsRealComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'estadistico', component: EstadisticoComponent, canActivate: [PermisosSubmodulosGuard] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComercialRoutingModule { }

