import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {VarTempEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class VarTempService extends Service<VarTempEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/VarTemp', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: VarTempEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_VarTemp) { params = params.set('Id_VarTemp', entity.Id_VarTemp.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Formula) { params = params.set('Formula', entity.Formula.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }
    
    
    return params;
  }
}
