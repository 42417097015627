import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router'
import * as $ from 'jquery';

import { CommonFunctions } from './../../../util/app-util';
import { Resume, Experience, Education, Skill } from './../../../entities/base/exportarpdf';

/* Import Entities */
import { SocioClienteQubosEntity, CaptacionQubosEntity, CarteraQubosEntity, SocioclienteEntity, InstitucionPatmirEntity, EstadoEntitty } from './../../../entities/base/DashboardEntities';
import { ActivosEntity } from './../../../entities/base/DashboardEntities';
import { pagination } from './../../../entities/base/DashboardEntities';
import { UsuarioService } from '../../../services/usuario.service';
import { InstitucionPatmirService } from '../../../services/institucionpatmir.service';
/* Import Entities */

/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { AfiliacionServiceFunciones } from '../../shared/funciones-afiliacion/serviciosafiliacion';
import { FuncionesAfiliacion, AfiliacionVariablesConstantes, ConstantesAfiliacion, FuncionesArreglos, FuncionesMes, FuncionesCadenaAnioMes } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesBubble, FuncionAgruparSucursales, FuncionesExportCSV, FuncionesGenero, FuncionesBusquedasT1 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesTemplate4 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesFiltrosTemplate2 } from "../../shared/funciones-afiliacion/filtros_template2";
import { FuncionesTemplateIII } from "../../shared/funciones-afiliacion/filtros_template3";
import { PDF, PDFSecond } from './../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
/* Importación de Funciones */

/* Importación de servicios */
import { SocioclientequbosService } from './../../../services/comercial/afiliacion/afiliacion.service';
import { CaptacionQubosService } from './../../../services/comercial/captacion/captacion.service';
import { CarteraQubosService } from './../../../services/comercial/colocacion/cartera.service';
import { ActivosService } from './../../../services/comercial/activos.service';
import { EstadoService } from './../../../services/dashboard/estado.service';
/* Importación de servicios */

/* Importación de Objetos VO */
import { SocioClienteQubosVO } from './../../../entities/dashboard/socioclientequbosVO';
/* Importación de Objetos VO */

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MAT_SORT_HEADER_INTL_PROVIDER_FACTORY } from '@angular/material/sort';
//import { MatTableDataSource } from '@angular/material/table';

import { ChartDataSets, ChartOptions, ChartType, RadialChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import { ColorHelper } from '@swimlane/ngx-charts';


/* Import función para scalas de las gráficas */
import { FuncionesScaleMax } from './../../../util/app-util';
/* Import función para scalas de las gráficas */

import { PermisosSubModuloEntity } from '../../../entities/base/QubosEntities';
import { PermisosSubModuloService } from './../../../services/qubos/permisossubmodulo.service';


import pdfMake from "pdfmake/build/pdfmake.js";
import html2canvas from 'html2canvas';
import { SucursalEntity } from './../../../entities/base/BaseEntities';
import { SucursalService } from './../../../services/sucursal/sucursal.service';
import { InfoAnioMesEntitty } from './../../../entities/base/DashboardEntities';
import { InfoaniomesService } from './../../../services/dashboard/infoaniomes.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { FiltroAnio } from 'src/app/model/filtro-anio';
import { FiltroMes } from 'src/app/model/filtro-mes';
import { RangoFecha } from 'src/app/model/rango-fechas';
import { ElasticSociosService } from 'src/app/services/elastic-socios/elastic-socios.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-socios',
  templateUrl: './socios.component.html',
  styleUrls: ['./socios.component.css']
})
export class SociosComponent implements OnInit {

  // Filtro años
  public flag_filtrar_anio: boolean = false;
  public arreglo_anios: Array<FiltroAnio>;
  public anios_seleccionados: Array<FiltroAnio>;

  // Filtro meses
  public arreglo_meses: Array<FiltroMes>;
  public meses_seleccionados: Array<FiltroMes>;

  // Filtros perspectivas
  public filtrosPerspectivasActivos: Array<any>;
  public filtrosPerspectivasAnidados: Array<any>
  public flag_perspectivas: boolean = false;
  public sucursal_id: string;
  public valor_seleccionado;

  // Gráfica1
  public subtituloGrafica1: string = "Todos";
  public datosBarra: Array<any>;
  public colorScheme: string;
  public showXAxis = false;
  public showYAxis = true;
  public showLegend = false;
  public showXAxisLabel = false;
  public xAxisLabel = '';
  public xAxisLabelBarraFiltroT5: string = "";
  public xAxisLabelT2C3: string = "";
  public showYAxisLabel = true;
  public yAxisLabel = '# Socios';
  public timeline = true;
  public showGridLines: boolean = false;
  public customColors = [{ "name": "", "value": 0 }];
  public showDataLabelT1C1: boolean = false;
  public barScaleMax: number = 5000;
  public activeEntries = [];

  //Gráfica 2
  public datosBarra_producto: Array<any>;

  // Tabla
  public table_data: Array<any>;
  public total_conteo;
  public cartera_total;

  // Gráfica pie
  public data_piechart: Array<any>;
  public visibilitylegend3 = true;
  public legend3 = "";
  public doughnut: boolean = true;
  public arcWidth = 0.35;

  //Gráfica pie 2
  public data_piechart_genero: Array<any>

  //Elastic Search
  private rangoFecha = new RangoFecha();

  public sucursales: Array<any>;

  // Util
  private separador: string = ","; // separador para los miles
  private sepDecimal: string = '.'; // separador para los decimales

  constructor(
    private sucursalService: SucursalService,
    public dataService: DataService,
    private elasticSearch: ElasticSociosService
  ) {
    FuncionesColores.inicioColores();

    this.colorScheme = FuncionesColores.setpaleta_azul('paleta_azul');

    // Se inicializan arreglos
    this.arreglo_anios = new Array<FiltroAnio>();
    this.arreglo_meses = new Array<FiltroMes>();
    this.anios_seleccionados = new Array<FiltroAnio>();
    this.meses_seleccionados = new Array<FiltroMes>();
    this.datosBarra = new Array<any>();
    this.data_piechart = new Array<any>();
    this.filtrosPerspectivasActivos = new Array<string>();
    this.filtrosPerspectivasAnidados = new Array<any>();
    this.table_data = new Array<string>();
    this.datosBarra_producto = new Array<any>();
    this.data_piechart_genero = new Array<any>();

    this.inicializarValores();

    this.rangoFecha = new RangoFecha();
  }

  ngOnInit(): void {
  }

  inicializarValores() { // Valores iniciales del dashboard
    //this.getfiltrosAnioMes();
    this.subtituloGrafica1 = "Todas";

    this.getGrafica();
    this.getGrafica2();
    this.getGraficaPie();
    this.getTabla();
    this.getGraficaPieGenero();
  }

  getTabla() {
    this.elasticSearch.consultaGeneral('cuentas/estatus').subscribe(
      response => {

        //this.table_data = response.message.agrupar_por_creditos.agrupar_por_estatus.buckets

      }
    )
  }

  getGraficaPie() {
    this.elasticSearch.consultaGeneral('grafica_pie').subscribe(
      response => {

        //this.data_lineal = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets);

        let datosBarraAux = [];

        response.message.agrupar_por_perspectiva.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.total_socios_sucursal.value
          }
          datosBarraAux.push(newElemento);
        });

        this.data_piechart = datosBarraAux;

      }
    )
  }

  getGraficaPieGenero() {
    this.elasticSearch.consultaGeneral('grafica_pie_genero').subscribe(
      response => {

        console.log(response);
        //this.data_lineal = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets);

        let datosPieAuxGenero = [];

        response.message.filters_agg.buckets.first.agrupar_por_genero.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.doc_count
          }
          datosPieAuxGenero.push(newElemento);
        });

        this.data_piechart_genero = datosPieAuxGenero;

      }
    )
  }

  addPerspectiva(perspectiva: string) {

    this.getGraficaPerspectiva(perspectiva);
    this.getGrafica2Perspectiva(perspectiva);
    this.getPieEdadesPerspectiva(perspectiva);
    this.getPieGeneroPerspectiva(perspectiva);
    this.filtrosPerspectivasActivos.push(perspectiva);
    this.filtrosPerspectivasAnidados.push({ perspectiva: perspectiva, value: this.valor_seleccionado });
    this.setSubtitulo();
    this.flag_perspectivas = false;

  }

  getPieEdadesPerspectiva(perspectiva){

    let datos = {
      perspectiva: perspectiva,
      sucursal_id: this.sucursal_id,
      valor_seleccionado: this.valor_seleccionado
    }

    this.elasticSearch.consultaGeneral('grafica_pie_perspectivas', datos, this.filtrosPerspectivasAnidados).subscribe(
      response => {

        //this.data_lineal = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets);

        let datosBarraAux = [];

        response.message.agrupar_por_perspectiva.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.total_socios_sucursal.value
          }
          datosBarraAux.push(newElemento);
        });

        this.data_piechart = datosBarraAux;

      }
    )
  }

  getPieGeneroPerspectiva(perspectiva){
    let datos = {
      perspectiva: perspectiva,
      sucursal_id: this.sucursal_id,
      valor_seleccionado: this.valor_seleccionado
    }

    this.elasticSearch.consultaGeneral('grafica_pie_perspectivas_genero', datos, this.filtrosPerspectivasAnidados).subscribe(
      response => {

        //this.data_lineal = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets);

        let datosBarraAux = [];

        response.message.filters_agg.buckets.first.agrupar_por_genero.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.doc_count
          }
          datosBarraAux.push(newElemento);
        });

        this.data_piechart_genero = datosBarraAux;

      }
    )
  }

  getGrafica2Perspectiva(perspectiva){
    let datos = {
      perspectiva: perspectiva,
      sucursal_id: this.sucursal_id,
      valor_seleccionado: this.valor_seleccionado
    }

    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica2_perspectivas', datos, this.filtrosPerspectivasAnidados).subscribe(
      response => {

        let datosBarraAux = [];

        console.log(response.message.agrupar_por_perspectiva.buckets);
        response.message.agrupar_por_perspectiva.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.doc_count
          }
          datosBarraAux.push(newElemento);
        });

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(datosBarraAux);
        this.datosBarra_producto = datosBarraAux;

      }
    )
  }

  getfiltrosAnioMes() { // Obtiene los meses y años disponibles para filtrar

    // Consulta general de elastic search
    this.elasticSearch.consultaGeneral('fechas_registradas').subscribe(
      response => {
        response.message.agrupar_por_aniomes.buckets.forEach(element => {

          // Obtiene año y mes de key_as_string y los separa
          let cadenaAux = element.key_as_string;
          let aniomes = cadenaAux.split("-");
          let anioCadena = aniomes[0];
          let mesCadena = aniomes[1];
          let mesValor = parseInt(mesCadena); // Se convierte en int para generar el modelo FiltroMes

          if (this.arreglo_meses.filter(mes => mes.valor == mesValor).length == 0) {
            this.arreglo_meses.push(new FiltroMes(mesValor)); // Agrega el mes sin repetir valores
          }

          if (this.arreglo_anios.filter(anio => anio.cadena == anioCadena).length == 0) {
            this.arreglo_anios.push(new FiltroAnio(anioCadena, false));// Agrega el año sin repetir valores
          }

        });

        //Se asignan como valores iniciales el último año y último mes para consultar sucursales
        this.arreglo_anios[this.arreglo_anios.length - 1].selected = true;
        this.arreglo_meses[this.arreglo_meses.length - 1].selected = true;
        this.FiltrarAniosMeses();

        this.setSubtitulo();

        this.getGrafica();
      }
    );
  }

  setSubtitulo(): void {

    this.subtituloGrafica1 = "Sucursal: " + this.sucursal_id + ' | ';

    if(this.filtrosPerspectivasActivos && this.filtrosPerspectivasActivos.length > 0){
      let loop = 0;
      this.filtrosPerspectivasActivos.forEach(filtro => {
        this.subtituloGrafica1 += (loop == 0) ? this.filtrosPerspectivasActivos[loop] : ' | ' + this.filtrosPerspectivasActivos[loop]; 
        loop++;
      });
    }
  }

  isNotActiveFilter(perspectiva: string): boolean {
    return this.filtrosPerspectivasActivos.filter(filtro => filtro == perspectiva).length == 0;
  }

  getGrafica() {
    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_inicial').subscribe(
      response => {

        //this.datosBarra = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets, 'total_contratos');

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(this.datosBarra);

        // Versión manual por si falla servicio Elastictobarchart
        let datosBarraAux = [];

        this.sucursales = response.message.agrupar_por_sucursal.buckets;

        response.message.agrupar_por_sucursal.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.total_socios.value
          }
          datosBarraAux.push(newElemento);
        });

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(datosBarraAux);
        this.datosBarra = datosBarraAux;

      }
    )
  }

  getGrafica2() {
    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_inicial_producto').subscribe(
      response => {

        //this.datosBarra = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets, 'total_contratos');

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(this.datosBarra);

        // Versión manual por si falla servicio Elastictobarchart
        let datosBarraAuxProducto = [];

        response.message.agrupar_por_perspectiva.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.total_socios.value
          }
          datosBarraAuxProducto.push(newElemento);
        });

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(datosBarraAuxProducto);
        this.datosBarra_producto = datosBarraAuxProducto;
        console.log(datosBarraAuxProducto.length);

      }
    )
  }

  buscarSucursal(nombre){
    return this.sucursales.filter(sucursal => sucursal.key_as_string == nombre)[0].key[0];
  }

  getLastAnioMes(): Array<number> {

    let aniomes: Array<number> = new Array<number>();

    aniomes.push(parseInt(this.arreglo_anios[this.arreglo_anios.length - 1].cadena));
    aniomes.push(parseInt(this.arreglo_meses[this.arreglo_meses.length - 1].cadena));

    return aniomes;

  }

  FiltrarAniosMeses() {
    // Se actualiza arreglo de años seleccionados
    this.anios_seleccionados = this.arreglo_anios.filter(anio => anio.selected);
    this.meses_seleccionados = this.arreglo_meses.filter(mes => mes.selected);

  }

  quitar_filtros() {
    this.inicializarValores();
    this.filtrosPerspectivasActivos = [];
    this.filtrosPerspectivasAnidados = [];
    this.flag_perspectivas = false;
  }

  modal(id: string): void {

  }

  cambiarGrafica(grafica: string): void {

  }

  getGraficaPerspectiva(perspectiva?: string) {

    let datos = {
      perspectiva: perspectiva,
      sucursal_id: this.sucursal_id,
      valor_seleccionado: this.valor_seleccionado
    }

    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_perspectivas', datos, this.filtrosPerspectivasAnidados).subscribe(
      response => {

        let datosBarraAux = [];

        console.log(response.message.agrupar_por_perspectiva.buckets);
        response.message.agrupar_por_perspectiva.buckets.forEach(elemento => {
          let elementoCadena = elemento.key;
          let newElemento = {
            name: elementoCadena,
            value: elemento.doc_count
          }
          datosBarraAux.push(newElemento);
        });

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(datosBarraAux);
        this.datosBarra = datosBarraAux;

      }
    )
  }

  /******************
  FUNCIONES GRÁFICAS:
  *******************/
  selected = new EventEmitter();
 
  click_grafica(event) {
    if(this.filtrosPerspectivasAnidados.length == 0){
      this.sucursal_id = event.name;
    }
      this.valor_seleccionado = event.name
      console.log(this.valor_seleccionado);
      this.flag_perspectivas = true;
      this.activeEntries = [event, ...this.activeEntries];
      this.selected.emit({ value: event, entries: this.activeEntries });
  }

  formatDataLabel(num) {
    num = num.toFixed(0);
    num += '';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? "." + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + "," + '$2');
    }
    return splitLeft + splitRight;
  }

  formatearnormal(num) {
    num += '';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return splitLeft + splitRight;
  }

  yLeftTickFormat(valor) {
    return CommonFunctions.retornarformatolefty(valor);
  }

  click_grafica2(event){

  }

}
