import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service/data.service';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import { EstadisticoService } from '../../../services/comercial/estadistico/estadistico.service';
import { PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';

@Component({
  selector: 'app-estadistico',
  templateUrl: './estadistico.component.html',
  styleUrls: ['./estadistico.component.css'],
})
export class EstadisticoComponent implements OnInit {
  /** Obciones de f=graficas */
  xlabellin = 'Periodo';
  ylabellin = 'Salso';
  xAxis = true;
  yAxis = true;
  showLegendlin = true;
  legendTitlelin = '';
  /** Opciones de la grafica compuesta */
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = '';
  legendPosition = 'below';
  showXAxisLabel = true;
  xAxisLabel = 'Periodo';
  showYAxisLabel = true;
  yAxisLabel1 = 'Logrado en captación';
  yAxisLabel = 'Saldo';
  showGridLines = true;
  innerPadding = '20%';
  animations: boolean = true;
  lineChartScheme = {
    domain: ['#3BB0C9','#ff8300','#004A97']
  };
  comboBarScheme = {
    domain: ['#004A97']
  };
  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';
  colorScheme = {
    domain: ['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6','#301CDCFF', '#67C2F6FF','#301CDCFF', '#67C2F6FF'],
  };
  groupPadding = 1;
  public datalinealcom: any[] = [];
  public databarrascom: any[] = [];
  /**Variables de las graficas*/
  title = '';
  type = 'ComboChart';
  data = [
     ["Account", 3, 2, 2.5],
     ["HR",2, 3, 3],
     ["He",2, 3, 2.5],
     ["Ht",2, 3, 3],
  ];
  options = {
     hAxis: {
        title: 'Periodos'
     },
     vAxis:{
        title: 'Saldo'  
     },
     seriesType: 'bars',
     series: {2: {type: 'line'}},
     legend: {position: 'bottom', textStyle: {color: 'black', fontSize: 16}}
  };
  columnNames = ['Periodos','Saldo anterior','Saldo actual','Logrado en captación'];
  /** Variables de años y meses */
  public aniosDisponibles: any [] = [];
  public aniosSeleccionados: any [] = [];
  public mesesDisponibles: any [] = [];
  public mesesDeAnioDisponibles: any[] = [];
  public mesesSeleccionados: any [] = [];
  public sucursalesDisponibles: any[];
  public ult_anio: any [] = [];
  public ult_mes: any [] = [];
  public aniosSelec = [];
  /** Variables de datos */
  public dataTab = [];
  public columna = ['mes','salAnt','salAct','log'];
  public dataBar = [];
  public dataLin = [];
  public dataAre = [];
  public dataBar2 = [];
  public dataLin2 = [];
  /** Variables para el formateo de numeros */
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /** Variables subtitulos */
  public subtitulo = '';
  public campo = 'caso';
  /** Variables de las graficas */
  public grafcompuesta = false;
  public grafBarras = false;
  public grafLineal = false;
  public grafArea = true;
  public grafTabla = true;
  public grafBarras2 = false;
  public grafLineal2 = false;
  public grafArea2 = false;

  public grafcompuesta_modal = false;
  public grafBarras_modal = false;
  public grafLineal_modal = false;
  public grafArea_modal = false;
  public grafTabla_modal = false;
  public grafBarras2_modal = false;
  public grafLineal2_modal = false;
  public grafArea2_modal = false;
  /** Variables del modal */
  public titulo = '';
  public subtitMod = '';
  public menorG1 = 0;
  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private estadisticoService: EstadisticoService,
    private excelservices: ExcelService,
  ) {
    this.getFiltros();
  }
  ngOnInit(): void {}
  
  public getFiltros(){
    var ult_anio = [], ult_mes = [];
    this.filtrosService.getAniosMesesDisponibles('aseguradora').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAnioMesDisponible(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesAniosDisponibles;
      ult_anio = tiempoFiltros.ult_anio;
      ult_mes = tiempoFiltros.ult_mes;
      this.ult_anio = ult_anio;
      this.ult_mes = ult_mes;
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio[0]);
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.aniosMesesSeleccionados();

      this.obtenerMeses();
      this.estadisticoService.getTendenciaGraficaPorAnio(this.aniosSelec,this.campo).subscribe( response => {
        if (response.http.code === 200) {
          this.obtDataTab(response.message);
        }
      });
    });
  }
  public getDatos(){
    this.obtenerMeses();
    this.estadisticoService.getTendenciaGraficaPorAnio(this.aniosSelec, this.campo).subscribe( response => {
      if (response.http.code === 200) {
        this.obtDataTab(response.message);
      }
    });
  }
  public obtenerMeses(){
    var pos = 0, meses = 0;
    this.aniosSelec = [];
    for(var i = 0; i < this.mesesDisponibles.length; i++){
      if(this.mesesDisponibles[i].value == this.mesesSeleccionados[0].value){
        pos = i;
      }
    }
    for(var i = pos; i >= 0; i-- ){
      if(meses != 13){
        this.aniosSelec.push(this.mesesDisponibles[i].value);
        meses++;
      }
    }
  }
  public obtDataTab(array){
    this.dataTab = [];
    for(var i = 1; i < array.length; i++){
      this.dataTab.push({
        mes: this.obtMes(array[i].key),
        salAnt: array[i-1].value,
        salAct: array[i].value,
        log: array[i].value - array[i-1].value
      });
      this.dataBar2.push({
        name: this.obtMes(array[i].key),
        value: array[i].value - array[i-1].value
      });
      this.dataBar.push({
        name: this.obtMes(array[i].key),
        series: [
          {name: 'Saldo anterior', value: array[i-1].value},
          {name: 'Saldo actual', value: array[i].value}
        ]
      });
    }

    var sumSAn = 0, sumSAc = 0, sumL = 0;
    for(const dat of this.dataTab){
      sumSAn += dat.salAnt;
      sumSAc += dat.salAct;
      sumL += dat.log;
    }
    this.datosComboChart(this.dataTab);
    this.dataTab.push({
      mes: 'Acumulado',
      salAnt: sumSAn,
      salAct: sumSAc,
      log: sumL
    });
    this.dataLin2.push({name: 'Logrado', series: this.dataBar2});
    this.yminGraf1();
    this.sub();
  }
  public datosComboChart(array){
    this.data = [];
    this.databarrascom = [];
    this.datalinealcom = [];
    var aux = [], salAct = [], salAnt = [], log = [];
    for(const dat of array){
      salAct.push({name: dat.mes, value: dat.salAct});
      salAnt.push({name: dat.mes, value: dat.salAnt});
      log.push({name: dat.mes, value: dat.log});
      aux.push(dat.mes);
      aux.push(dat.salAnt);
      aux.push(dat.salAct);
      aux.push(dat.log);
      this.data.push(aux);
      aux = [];
    }
    this.databarrascom = log;
    this.datalinealcom.push({name: 'Saldo actual', series: salAct});
    this.datalinealcom.push({name: 'Saldo anterior', series: salAnt});
    this.dataAre = this.datalinealcom;
    this.dataLin = this.datalinealcom;
  }
  public obtMes(mes){
    switch(mes.split('-')[1]){
      case '01': return 'ene ' + mes.split('-')[0].substring(2,4);
      case '02': return 'feb ' + mes.split('-')[0].substring(2,4);
      case '03': return 'mar ' + mes.split('-')[0].substring(2,4);
      case '04': return 'abr ' + mes.split('-')[0].substring(2,4);
      case '05': return 'may ' + mes.split('-')[0].substring(2,4);
      case '06': return 'jun ' + mes.split('-')[0].substring(2,4);
      case '07': return 'jul ' + mes.split('-')[0].substring(2,4);
      case '08': return 'ago ' + mes.split('-')[0].substring(2,4);
      case '09': return 'sep ' + mes.split('-')[0].substring(2,4);
      case '10': return 'oct ' + mes.split('-')[0].substring(2,4);
      case '11': return 'nov ' + mes.split('-')[0].substring(2,4);
      case '12': return 'dic ' + mes.split('-')[0].substring(2,4);
    }
    return mes;
  }
  public obtMesTab(mes){
    switch(mes.split('-')[1]){
      case '01': return 'ene'
      case '02': return 'feb'
      case '03': return 'mar'
      case '04': return 'abr'
      case '05': return 'may'
      case '06': return 'jun'
      case '07': return 'jul'
      case '08': return 'ago'
      case '09': return 'sep'
      case '10': return 'oct'
      case '11': return 'nov'
      case '12': return 'dic' 
    }
    return mes;
  }
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  public sub(){
    switch(this.campo){
      case 'caso':
        this.subtitulo = 'Capital social';
      break;
      case 'ahso':
        this.subtitulo = 'Ahorro socio';
      break;
      case 'ahcc':
        this.subtitulo = 'Ahorro cuenta corriente';
      break;
      case 'crece':
        this.subtitulo = 'Ahorro cuenta crece';
      break;
      case 'ahme':
        this.subtitulo = 'Ahorro menor';
      break;
    }
    for(const mes of this.mesesSeleccionados){
      this.subtitulo += ' | ' + mes.year.substring(2,4); 
      this.subtitulo += ' | ' + mes.name;     
    }
  }
  public texto(indice){
    if(indice == this.dataTab.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
  }
  /** Filtros */
  private aniosMesesSeleccionados(): void {
    this.aniosSeleccionados = [];
    this.mesesSeleccionados = [];
    for (const valor of this.aniosDisponibles) {
      if (valor.check == true)
        this.aniosSeleccionados.push(valor);
    }
    for (const valor of this.mesesDeAnioDisponibles) {
      if (valor.check == true)
        this.mesesSeleccionados.push(valor);
    }
  }
  public cambiodeMes(mes){
    this.mesesSeleccionados = [];
    this.mesesDeAnioDisponibles.forEach(mess => mess.check = false);
    for(var i = 0; i < this.mesesDeAnioDisponibles.length; i++){
      if(this.mesesDeAnioDisponibles[i].value == mes.value){
        this.mesesDeAnioDisponibles[i].check = true;
        this.mesesSeleccionados.push(this.mesesDeAnioDisponibles[i]);
        this.ult_mes[0] = this.mesesDeAnioDisponibles[i].name;
      }
    }
    this.getDatos();
  }
  public cambiodeAnio(anio){
    this.ult_anio = [];this.aniosSeleccionados = [];
    this.aniosDisponibles.forEach(mess => mess.check = false);
    for(var i = 0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == anio.value){
        this.aniosDisponibles[i].check = true;
      }
    }
    const anio_selecc = this.aniosDisponibles.find(anios => anios.name === anio['name']);
    this.ult_anio.push(anio_selecc['name'])
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    this.buscarMesSeleccionado();
    this.aniosMesesSeleccionados();
    this.getDatos();
  }
  public buscarMesSeleccionado(){
    for (const valor of this.mesesDeAnioDisponibles){
      valor['check'] = false;
    }
    var flag = false;
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['name'] == this.ult_mes[0]){
        valor['check'] = true;
        flag = true;
      }
    }
    if(!flag){
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.ult_mes = [];
      this.ult_mes.push(this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].name);
    }
  }
  /** Cambio de graficas */
  public cambioGraf(grafCamb){
    switch(grafCamb){
      case 'comp':
        this.grafcompuesta = true;
        this.grafBarras = false;
        this.grafLineal = false;
        this.grafArea = false;
      break;
      case 'barras':
        this.grafcompuesta = false;
        this.grafBarras = true;
        this.grafLineal = false;
        this.grafArea = false;
      break;
      case 'lineal':
        this.grafcompuesta = false;
        this.grafBarras = false;
        this.grafLineal = true;
        this.grafArea = false;
      break;
      case 'area':
        this.grafcompuesta = false;
        this.grafBarras = false;
        this.grafLineal = false;
        this.grafArea = true;
      break;
    }
  }
  public cambioGraf2(grafCamb){
    switch(grafCamb){
      case 'barras':
        this.grafTabla = false;
        this.grafBarras2 = true;
        this.grafLineal2 = false;
        this.grafArea2 = false;
      break;
      case 'lineal':
        this.grafTabla = false;
        this.grafBarras2 = false;
        this.grafLineal2 = true;
        this.grafArea2 = false;
      break;
      case 'area':
        this.grafTabla = false;
        this.grafBarras2 = false;
        this.grafLineal2 = false;
        this.grafArea2 = true;
      break;
      case 'tabla':
        this.grafTabla = true;
        this.grafBarras2 = false;
        this.grafLineal2 = false;
        this.grafArea2 = false;
      break;
    }
  }
  /** Cambio de perspectivas */
  public cambioPer(perCamb){
    this.campo = perCamb;
    this.getDatos();
  }
  /** Exportación */
  public exportar_img(zona){
    var name = 'Estadístico '+this.subtitulo;
    PDF.exportimagenes(zona,name);
  }
  public export_excel(zon: any){
    var aux = [];
    var data_excel: any = [];
    var name = 'Estadistico';
    var perspectiva = this.subtitulo;
    var encabezado_excel = ['Periodo','Saldo anterior','Saldo actual', 'Logrado en captación'];
    var cadena_evaluacion = encabezado_excel;

    for(const lin of this.dataTab){
      aux.push(lin.mes);
      aux.push(lin.salAct);
      aux.push(lin.salAnt);
      aux.push(lin.log);
      data_excel.push(aux);
      aux = [];
    }
    this.excelservices.generateExcelEstadistico(encabezado_excel, data_excel, cadena_evaluacion.length, name, perspectiva);
  }
  /** Ampliar */
  public ampliar(zon){
    this.titulo = 'Estadístico';
    this.subtitMod = this.subtitulo;
    this.closeAmpliar();
    switch(zon){
      case 'C1':
        if(this.grafcompuesta)
          this.grafcompuesta_modal = true;
        if(this.grafBarras)
          this.grafBarras_modal = true;
        if(this.grafLineal)
          this.grafLineal_modal = true;
        if(this.grafArea)
          this.grafArea_modal = true;
      break;
      case 'C2':
        if(this.grafTabla)
          this.grafTabla_modal = true;
        if(this.grafBarras2)
          this.grafBarras2_modal = true;
        if(this.grafLineal2)
          this.grafLineal2_modal = true;
        if(this.grafArea2)
          this.grafArea2_modal = true;
      break;
    }
  }
  public closeAmpliar(){
    this.grafcompuesta_modal = false;
    this.grafBarras_modal = false;
    this.grafLineal_modal = false;
    this.grafArea_modal = false;

    this.grafTabla_modal = false;
    this.grafBarras2_modal = false;
    this.grafLineal2_modal = false;
    this.grafArea2_modal = false;
  }
  /** Borrar filtros */
  public borrarFil(){
    this.grafBarras = true;
    this.grafLineal = false;
    this.grafArea = false;
    this.grafTabla = true;
    this.grafBarras2 = false;
    this.grafLineal2 = false;
    this.grafArea2 = false;

    this.closeAmpliar();

    this.campo = 'caso';

    this.getFiltros();
  }
  /** busqueda de los minimos */
  public yminGraf1(){
    var men, menor = 10000000000;
    for(const dat of this.dataBar){
      dat.series[0].value > dat.series[1].value? men = dat.series[1].value: men = dat.series[0].value;
      men < menor? menor = men: '';
    }
    this.menorG1 = menor - menor*.01;
  }
  public yminGraf2(){

  }

}
