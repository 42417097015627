import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informecartera',
  templateUrl: './informecartera.component.html',
  styleUrls: ['./informecartera.component.css']
})
export class InformecarteraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
