import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InformefinancieroComponent } from './informefinanciero/informefinanciero.component';
import { InformeriesgosComponent } from './informeriesgos/informeriesgos.component';
import { InformecarteraComponent } from './informecartera/informecartera.component';
import {WelcomeComponent} from '../../components/welcome/welcome.component';
import {PermisosSubmodulosGuard} from '../../guards/permisos-submodulos/permisos-submodulos.guard';

const routes: Routes = [
  {
    path: 'app/informes',
    component: WelcomeComponent,
    children: [
      { path: 'informefinanciero', component: InformefinancieroComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'informecartera', component: InformecarteraComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'informeriesgos', component: InformeriesgosComponent, canActivate: [PermisosSubmodulosGuard] },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformeRoutingModule { }
