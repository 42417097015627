import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RiesgosRoutingModule } from './riesgos-routing.module';
import { RiesgosTendenciaComponent } from './riesgos-tendencia/riesgos-tendencia.component';
import { SharedModule} from './../shared/shared.module';
import { MatrizTransicionComponent } from './matriz-transicion/matriz-transicion.component';
import { CosechasComponent } from './cosechas/cosechas.component';
import { ConcentracionComponent } from './concentracion/concentracion.component';
import { MayoresDeudoresComponent } from './mayores-deudores/mayores-deudores.component';
import {MatChipsModule} from '@angular/material/chips';
@NgModule({
  declarations: [
    RiesgosTendenciaComponent,
    MatrizTransicionComponent,
    CosechasComponent,
    ConcentracionComponent,
    MayoresDeudoresComponent,
  ],
  imports: [
    CommonModule,
    RiesgosRoutingModule,
    SharedModule,
    MatChipsModule,
  ]
})
export class RiesgosModule { }
