import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {

  private url = environment.baseUrl;
  private token = sessionStorage.getItem('auth_token');
  private nombreDashboard = "afiliacion";

  public body = {
    index: "socio_captacion_credito_202108",
    content: {
      size: 0,
      query: {
        bool: {
          must: []
        }
      },
      aggs: {}
    }
  }

  constructor(
    private _http: HttpClient
  ) { }

  // Consulta general elastic search. Doc:
  consultaGeneral(tipo: string, datos?: any, perspectivas?: Array<any>): Observable<any> {

    this.body.content.query = { bool: { must: [] } };
    this.body.content.aggs = {};

    let agg = null;
    let query = null;
    let pers = null;

    switch (tipo) {

      case 'fechas_registradas':
        agg = {
          agrupar_por_aniomes: {
            date_histogram: {
              field: "Time_Id",
              calendar_interval: "month",
              format: "yyyy-MM"
            }
          }
        }
        break;

      case 'grafica_inicial':
        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_sucursal: {
                multi_terms: {
                  terms: [
                    {
                      field: "Creditos_Info.sucursal_id.keyword"
                    },
                    {
                      field: "Creditos_Info.sucursal.keyword"
                    }
                  ],
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  }
                }
              }
            }
          }
        }
        break;

      case 'tabla_perspectivas':
        query = {
          nested: {
            path: "Creditos_Info",
            query: {
              bool: {
                must: [
                  {
                    // Modificar el valor de sucursal_id, por el que sea seleccionado
                    term: {
                      "Creditos_Info.sucursal_id.keyword": {
                        value: datos.sucursal_id
                      }
                    }
                  },
                ]
              }
            }
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

            switch (perspectiva.perspectiva) {
              case 'bandas de morosidad':
                pers = {
                  term: {
                    "Creditos_Info.bandas de morosidad.keyword": {
                      "value": datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'estatus':
                pers = {
                  term: {
                    "Creditos_Info.estatus.keyword": {
                      "value": datos.valor_seleccionado
                    }
                  }
                }
                break;
              default:
                break;
            }

            query.nested.query.bool.must.push(pers);
          });
        }

        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_estatus: {
                terms: {
                  field: "Creditos_Info." + datos.perspectiva + ".keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  },
                  suma_carteratotal: {
                      sum: {
                          field: "Creditos_Info.cartera total"
                      }
                  }
                }
              }
            }
          }
        }
        break;

      case 'grafica_perspectivas_lineal':

        query = {
          nested: {
            path: "Creditos_Info",
            query: {
              bool: {
                must: [
                  {
                    // Modificar el valor de sucursal_id, por el que sea seleccionado
                    term: {
                      "Creditos_Info.sucursal_id.keyword": {
                        value: datos.sucursal_id
                      }
                    }
                  },
                ]
              }
            }
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

            switch (perspectiva.perspectiva) {
              case 'bandas de morosidad':
                pers = {
                  term: {
                    "Creditos_Info.bandas de morosidad.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'estatus':
                pers = {
                  term: {
                    "Creditos_Info.estatus.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }
                break;
              case 'clasificacion':
                pers = {
                  term: {
                    "Creditos_Info.casificacion.keyword": {
                      value: datos.valor_seleccionado
                    }
                  }
                }
                break;
              default:
                break;
            }

            query.nested.query.bool.must.push(pers);
          });
        }

        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_clasificacion: {
                terms: {
                  field: "Creditos_Info.clasificacion.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  },
                  suma_carteratotal: {
                    sum: {
                      field: "Creditos_Info.cartera total"
                    }
                  }
                }
              }
            }
          }
        }

        break;

      case 'grafica_perspectivas':

        query = {
          nested: {
            path: "Creditos_Info",
            query: {
              bool: {
                must: [
                  {
                    // Modificar el valor de sucursal_id, por el que sea seleccionado
                    term: {
                      "Creditos_Info.sucursal_id.keyword": {
                        value: datos.sucursal_id
                      }
                    }
                  },
                ]
              }
            }
          }
        }

        if (perspectivas && perspectivas.length > 0) {
          perspectivas.forEach(perspectiva => {

            switch (perspectiva.perspectiva) {
              case 'bandas de morosidad':
                pers = {
                  term: {
                    "Creditos_Info.bandas de morosidad.keyword": {
                      "value": datos.valor_seleccionado
                    }
                  }
                }

                break;

              case 'estatus':
                pers = {
                  term: {
                    "Creditos_Info.estatus.keyword": {
                      "value": datos.valor_seleccionado
                    }
                  }
                }
                break;
              default:
                break;
            }

            query.nested.query.bool.must.push(pers);
          });
        }

        agg = {
          "agrupar_por_creditos": {
            "nested": {
              "path": "Creditos_Info"
            },
            "aggs": {
              "agrupar_por_perspectiva": {
                "terms": {
                  "field": "Creditos_Info." + datos.perspectiva + ".keyword",
                  "size": 1000
                },
                "aggs": {
                  "total_contratos": {
                    "cardinality": {
                      "field": "Creditos_Info.no contrato.keyword"
                    }
                  }
                }
              }
            }
          }
        }

        break;
        

        case 'tabla_sucursal':
          query = {
            nested: {
              path: "Creditos_Info",
              query: {
                bool: {
                  must: [
                    {
                      // Modificar el valor de sucursal_id, por el que sea seleccionado
                      term: {
                        "Creditos_Info.sucursal_id.keyword": {
                          value: datos
                        }
                      }
                    },
                  ]
                }
              }
            }
          }
  
          agg = {
            agrupar_por_creditos: {
              nested: {
                path: "Creditos_Info"
              },
              aggs: {
                agrupar_por_estatus: {
                  terms: {
                    field: "Creditos_Info.estatus.keyword",
                    size: 1000
                  },
                  aggs: {
                    total_contratos: {
                      cardinality: {
                        field: "Creditos_Info.no contrato.keyword"
                      }
                    },
                    suma_carteratotal: {
                      sum: {
                        field: "Creditos_Info.cartera total"
                      }
                    }
                  }
                }
              }
            }
          };
  
          break;

      case 'cuentas/estatus':
        query = {
          nested: {
            path: "Creditos_Info",
            query: {
              bool: {
                must: [
                ]
              }
            }
          }
        };

        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_estatus: {
                terms: {
                  field: "Creditos_Info.estatus.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  },
                  suma_carteratotal: {
                    sum: {
                      field: "Creditos_Info.cartera total"
                    }
                  }
                }
              }
            }
          }
        };

        break;

        case 'lineal_sucursal':

          query = {
            nested: {
              path: "Creditos_Info",
              query: {
                bool: {
                  must: [
                    {
                      // Modificar el valor de sucursal_id, por el que sea seleccionado
                      term: {
                        "Creditos_Info.sucursal_id.keyword": {
                          value: datos                        }
                      }
                    },
                  ]
                }
              }
            }
          }

          agg = {
            agrupar_por_creditos: {
              nested: {
                path: "Creditos_Info"
              },
              aggs: {
                agrupar_por_clasificacion: {
                  terms: {
                    field: "Creditos_Info.clasificacion.keyword",
                    size: 1000
                  },
                  aggs: {
                    total_contratos: {
                      cardinality: {
                        field: "Creditos_Info.no contrato.keyword"
                      }
                    }
                  }
                }
              }
            }
          }
          break;

      case 'cuentas/clasificacion':
        agg = {
          agrupar_por_creditos: {
            nested: {
              path: "Creditos_Info"
            },
            aggs: {
              agrupar_por_clasificacion: {
                terms: {
                  field: "Creditos_Info.clasificacion.keyword",
                  size: 1000
                },
                aggs: {
                  total_contratos: {
                    cardinality: {
                      field: "Creditos_Info.no contrato.keyword"
                    }
                  }
                }
              }
            }
          }
        }
        break;

      default:
        break;
    }

    if (agg != null && agg != undefined) {
      this.body.content.aggs = agg;
    }

    if (query != null && query != undefined) {
      this.body.content.query = query;
    }


    let newBody = JSON.stringify(this.body)
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('NombreDashboard', 'dashboard1').set('Authorization', 'Bearer ' + this.token).set('TokenUsuario', '1234');
    return this._http.post(this.url + 'busquedageneral', newBody, { headers: headers });

  }

  generateBody(tipo, datos, perspectivas?: Array<string>): void {

  }

  elasticToBarChart(data): Array<any> {

    let datosBarraAux = [];

    data.forEach(elemento => {
      let elementoCadena = elemento.key;
      let newElemento = {
        name: elementoCadena,
        value: elemento.doc_count
      }
      datosBarraAux.push(newElemento);
    });

    return datosBarraAux;
  }

  elasticToLinealSimpleChart(data: Array<any>, value?: string): any {

    let datosBarraAux = [{
      name: 'Gráfica lineal',
      series: []
    }];

    if (value && value != '') {
      data.forEach(elemento => {
        let elementoCadena = elemento.key;
        let newElemento = {
          name: elementoCadena,
          value: elemento[value]
        }
        datosBarraAux[0].series.push(newElemento);
      });
    } else {
      data.forEach(elemento => {
        let elementoCadena = elemento.key;
        let newElemento = {
          name: elementoCadena,
          value: elemento.doc_count
        }
        datosBarraAux[0].series.push(newElemento);
      });
    }

    return datosBarraAux;
  }

}
