export class matrizTransicionEntity {
    Id_Institucion: number;
    Periodo: number;
    PeriodoAnterior: number;
    NDatos: number;
    BnConIntereses: number;
    Id_Zona: number;
    Id_Producto: number;
    Id_Sector: number;
}
export class perdidaEntity {
    Id_Institucion: number;
    Periodo: number;
    NPeriodosHst: number;
    NPeriodos: number;
    Id_Zona: number;
    Id_Producto: number;
    BnConIntereses: number;
}

export class cosechaEntity {
    Id_Institucion: number | undefined;
    Periodo: number | undefined;
    PAR: number | undefined;
    BnPAR_Inclusive: boolean | undefined;
    BnConIntereses: number;
    NPeriodos: number | undefined;
    Id_Zona: number | undefined;
    Id_Producto: number | undefined;
    Id_Sector: number | undefined;
}
export class cosechaClickEntity {
    Id_Institucion: number | undefined;
    Periodo: number | undefined;
    PeriodoCosecha: number | undefined;
    PAR: number | undefined;
    BnPAR_Inclusive: boolean;
    BnConIntereses: number | undefined;
}

export class concentracionEntity {
    Id_Institucion: number | undefined;
    Periodo: number | undefined;
    ElementoAgrupador: string | undefined;
    F_NDatosLimite: number | undefined;
    NPeriodos : number | undefined;
    BnConIntereses : number |undefined;
}

export class mayoresDeudoresEntity {
    Id_Institucion: number | undefined;
    Periodo: number | undefined;
    NDatos: string | undefined;
    BnConIntereses: number | undefined;
    BnAgruparDatos: number | undefined;
}
export class GraficaPedidaEntity{
    periodo: string;
    perdidaesperada: number;
    perdidanoesperada: number;
    var90: number;
}
export class zonaEntity{
    Id_Zona: number;
}
export class productoEntity{
    Id_Producto: number;
}
export class sectorEntity{
    Id_Sector: number;
}