export class EsqueletoPeticionModel {
  public groupby?: object;
  public filter?: object;
  constructor(
    public module: string,
    public submodule: string,
    public dashboard: string,
    public source: string
  ) {
  }

}
