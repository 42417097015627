import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data-service/data.service';
import {FuncionesColores} from '../../shared/funciones-colores/funciones_colores';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import {ColocacionPerfilService} from './../../../services/colocacion/perfil/colocacion-perfil.service'
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import {MatTableDataSource} from '@angular/material/table';

export interface UserData {
  name: string,
  value: string,
  check: boolean,
}

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private perfilService: ColocacionPerfilService,
    private excelservices: ExcelService,
  ) {
    //this.colorBarras = FuncionesColores.setpaleta_azul('rf16');
    this.dataMapaDeCalor = [
      {name: 'Germany', value: 40632, extra: {code: 'de'}},
      {name: 'United States', value: 50000, extra: {code: 'us'}},
      {name: 'France', value: 36745, extra: {code: 'fr'}},
      {name: 'United Kingdom', value: 36240, extra: {code: 'uk'}},
      {name: 'Spain', value: 33000, extra: {code: 'es'}},
      {name: 'Italy', value: 35800, extra: {code: 'it'}}
    ];
    this.dataOcupacion = [
      {name: 'Empleado', value: 22000},
      {name: 'Ama de Casa', value: 32000},
      {name: 'Obrero', value: 12000},
      {name: 'Maestro', value: 7000},
      {name: 'Comerciante', value: 12780},
      {name: 'Estudiante', value: 6400}
    ];
    this.dataOcupacion_lineal = [];
    this.dataMunicipio = [
      {name: '50001-60000', value: 8940},
      {name: '40001-50000', value: 12500},
      {name: '31001-40000', value: 24576},
      {name: '21001-30000', value: 47812},
      {name: '10000-20000', value: 13965}
    ];

    this.getFiltros();
  }

  /**
   * GooglePieChart variables
   */
  public data1 = [
    ['M', 51],
    ['F', 49],
  ];
  public data1_barra: any[] = [];
  public data1_lineal: any[] = [];
  public options = {
    chartArea: {
      height: '80%',
      width: '50%'
    },
    pieHole: 0.5,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755',],/*['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6'],*/
    legend: {position: 'bottom'},
    backgroundColor: '#fff',
  };
  public options22 = {
    chartArea: {
      height: '80%',
      width: '70%'
    },
    pieHole: 0.5,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755',],/*['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6']*/
    legend: {position: 'right'},
    backgroundColor: '#fff',
  };
  public optionsV5 = {
    chartArea: {
      height: '80%',
      width: '80%'
    },
    pieHole: 0.5,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755',],/*['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6'],*/
    
    backgroundColor: '#fff',
  };
  public options2 = {
    chartArea: {
      height: '80%',
      width: '50%'
    },
    legend: {position: 'bottom'},
    backgroundColor: '#fff'
  };
  public dataTablaGenero: any[];
  /**
   * Grafica segundo cuadrante
   * Mapa de calor
   */
  public colorScheme ={
    domain: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
             '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
             '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
  public data2completo: any [];
  public cantidad = 'quince';
  public dataMapaDeCalor: any [];
  public dataMapaDeCalor_lineal: any [];
  public dataTablaClasificacion: any[];
  /**
   * Frafica Tercer cuadrante
   */
  public datav3 = [
    ['Generación X', 46],
    ['Millenials', 22],
    ['Generación Z', 4],
    ['Silenciosa', 9],
    ['Baby Boomers', 17],
  ];
  public datav3_barra: any [] = [];
  public datav3_lineal: any [] = [];
  public optionsv3 = {
    chartArea: {
      height: '80%',
      width: '50%'
    },
    pieHole: 0.5,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755',],/*['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6'],*/
    is3D: true,
    pieStartAngle: 100,
    legend: {position: 'bottom'},
    backgroundColor: '#fff'
  };
  public dataTablaEstatus: any[];
  /**
   * Grafica cuarto cuadrante
   */
  public colorBarras  ={
    domain: ['#79B2D1'],
  }
  public data4completo: any [];
  public cantidad4 = 'quince';
  public dataOcupacion: any[];
  public dataOcupacion_lineal: any[] = [];
  public dataPie4: any[] = [];
  public dataTablaOcupacion: any[];
  public labelFormatting(c): string {
    return `${(c.label)} `;
  }
  /**
   * Grafica quinto cuadrante
   */
   public dataEstadoCivil = [
    ['M', 51],
    ['F', 49],
  ];
  public dataEstadoCivil_barra: any[] = [];
  public dataEstadoCivil_lineal: any[] = [];
  public dataTablaFinalidad: any[];
  /**
   * Grafica sexto cuadrante
   */
   public data6completo: any [];
   public cantidad6 = 'quince';
  public dataMunicipio: any[];
  public dataMunicipio_lineal: any[];
  public dataMunicipio_Pie: any [];
  public dataTablaMunicipio: any[];

  public aniosDisponibles: any [] = [];
  public aniosSeleccionados: any [] = [];
  public mesesDisponibles: any [] = [];
  public mesesDeAnioDisponibles: any[] = [];
  public mesesSeleccionados: any [] = [];
  public sucursalesDisponibles2: MatTableDataSource<UserData>;
  public sucursalesDisponibles: any[];
  public puedeFiltrarPorSucursales = false;
  public numeroDeSucursalesSeleccionadas = 0;
  public ult_anio: any [] = [];
  public ult_mes: any [] = [];
  public consolidado = true;
  public subtituloGrafica1: string =  "";
  public sucursal_seleccionada: string = "CONSOLIDADO";
  public columnasC1: string[] = ['genero','valor','porcentaje'];
  public columnasC2: string[] = ['nomCred','valor','porcentaje'];
  public columnasC3: string[] = ['estatus','valor','porcentaje'];
  public columnasC4: string[] = ['ocupacion','valor','porcentaje'];
  public columnasC5: string[] = ['finalidad','valor','porcentaje'];
  public columnasC6: string[] = ['municipio','valor','porcentaje'];
  public totalCred: number = 0;
  public sumC1: number = 0;
  public sumC2: number = 0;
  public sumC3: number = 0;
  public sumC4: number = 0;
  public sumC5: number = 0;
  public sumC6: number = 0;
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /**
   * variables de graficas
   */
   public esGraficaDeBarras = true;
   private datosGrafica: [];
   public datosPieChartGoogle: object[];
   public customColors: object[];
  /* Variables para los cambios de gráficas */
  //C1
  public graficapieC1: boolean = true;
  public graficabarraC1: boolean = false;
  public graficalinealC1: boolean = false;
  public graficaTablaC1: boolean =  false;
  //C2
  public mapacalorC2: boolean = true;
  public graficabarraC2: boolean = false;
  public graficalinealC2: boolean = false;
  public graficatablaC2: boolean = false;
  //C3
  public graficapieC3: boolean = true;
  public graficabarraC3: boolean = false;
  public graficalinealC3: boolean = false;
  public graficatablaC3: boolean = false;
  //C4
  public graficabarraC4: boolean = true;
  public graficalinealC4: boolean = false;
  public graficapieC4: boolean = false;
  public graficatablaC4: boolean = false;
  //C5
  public graficapastelC5: boolean = true;
  public graficabarraC5: boolean = false;
  public graficalinealC5: boolean = false;
  public graficatablaC5: boolean = false;
  //C6
  public graficabarraC6: boolean = true;
  public graficalinealC6: boolean = false;
  public graficapieC6: boolean = false;
  public graficatablaC6: boolean = false;

  /* Options Lineal C1 */
  visibilityLegend: boolean = false;
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Género';
  yAxisLabel: string = 'Socios';
  /* Options Lineal C1 */
  /* Options Lineal C2 */
  xAxisLabelC2: string = 'Rango Edad';
  /* Options Lineal C2 */
  /* Options Lineal C3 */
  xAxisLabelC3: string = 'Generacional';
  /* Options Lineal C3 */
  /* Options Lineal C4 */
  xAxisLabelC4: string = 'Ocupación';
  /* Options Lineal C4 */
  /* Options Lineal C5 */
  xAxisLabelC5: string = 'Escolaridad';
  /* Options Lineal C5 */
  /* Options Lineal C6 */
  xAxisLabelC6: string = 'Municipio';
  /* Options Lineal C6 */

  /* variables modal */
  titulomodal: string = "";
  subtitulomodal: string = "";
  //C1
  public graficapieC1_modal: boolean = false;
  public graficabarraC1_modal: boolean = false;
  public graficalinealC1_modal: boolean = false;
  public graficaTablaC1_modal: boolean =  false;
  //C2
  public mapacalorC2_modal: boolean = false;
  public graficabarraC2_modal: boolean = false;
  public graficalinealC2_modal: boolean = false;
  public graficatablaC2_modal: boolean = false;
  //C3
  public graficapieC3_modal: boolean = false;
  public graficabarraC3_modal: boolean = false;
  public graficalinealC3_modal: boolean = false;
  public graficatablaC3_modal: boolean = false;
  //C4
  public graficabarraC4_modal: boolean = false;
  public graficalinealC4_modal: boolean = false;
  public graficapieC4_modal: boolean = false;
  public graficatablaC4_modal: boolean = false;
  //C5
  public graficapastelC5_modal: boolean = false;
  public graficabarraC5_modal: boolean = false;
  public graficalinealC5_modal: boolean = false;
  public graficatablaC5_modal: boolean = false;
  //C6
  public graficabarraC6_modal: boolean = false;
  public graficalinealC6_modal: boolean = false;
  public graficapieC6_modal: boolean = false;
  public graficatablaC6_modal: boolean = false;

  public genero: string = "";
  public clasificacion = "";
  public estatus = "";
  public ocupacion = "";

  public mosFinalidad = true;
  public mosServicio = false;

  public showModal: boolean = false;
  public ngOnInit(): void {
  }

  private getFiltros(): void {
    var ult_anio = [];
    var ult_mes = [];
    this.filtrosService.getAniosMesesDisponibles('radiografia').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAnioMesDisponible(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesAniosDisponibles;
      ult_anio = tiempoFiltros.ult_anio;
      ult_mes = tiempoFiltros.ult_mes;
      this.ult_anio = ult_anio;
      this.ult_mes = ult_mes;
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio[0]);
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;

      //console.log("ult_anio ult_mes " +  JSON.stringify(tiempoFiltros))
      this.aniosMesesSeleccionados();
      //this.getGraficaPorAnio();
      this.perfilService.getTendenciaGraficaGenero(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
        //console.log("Regresar Genero ---->     ", ult_anio + " mes -->  " + this.filtrosService.getMesPrefijoReves(ult_mes[0]))
        if (response.http.code === 200) {
          this.datosPieChartGoogle = response.message;
          //console.log("Regresar Genero message ---->     ", JSON.stringify(response.message))
          if (this.datosPieChartGoogle){
            this.formatDatosPieChartGoogle();
          }}
      });
      this.perfilService.getTendenciaGraficaClasificacion(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosBarra(datos);
          }
        }
      });
      this.perfilService.getTendenciaGraficaOcupacion(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosOcupacion(datos);
          }
        }
      });
      this.perfilService.getTendenciaGraficaEstatus(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosPieChartGoogleGeneracional(datos);
          }
        }
      });
      if(this.mosFinalidad){
        this.perfilService.getTendenciaGraficaFinalidad(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
          if (response.http.code === 200) {
            let datos = response.message;
            if (datos){
              this.formatDatosEscolaridad(datos);
            }
          }
        });
      }else{
        this.perfilService.getTendenciaGraficaServicio(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
          if (response.http.code === 200) {
            let datos = response.message;
            if (datos){
              this.formatDatosServicio(datos);
            }
          }
        });
      }
      this.perfilService.getTendenciaGraficaMunicipio(ult_anio,this.filtrosService.getMesPrefijoReves(ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
        //console.log(" Ingreso ----->    " + JSON.stringify(response) + " ult_mes " + JSON.stringify(this.ult_mes));
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosMunicipio(datos);
          }
        }
      });

      this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];

    });
    this.filtrosService.getSucursalesDisponibles('radiografia', 'codSucCredito-sucursalCredito').subscribe(response => {
      this.sucursalesDisponibles = this.filtrosService.formatSucursalesDisponibles(response);
      this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.length;
      this.sucursalesDisponibles.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      this.sucursalesDisponibles2 = new MatTableDataSource(this.sucursalesDisponibles);
    });
    
  }
  public ContarMeses(){
    if(this.mesesSeleccionados.length < 5 ){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny ";
    }
  }
  public mostrartit(){
    console.log('mostrar');
  }
  private getGraficaGenero(): void {
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
  }
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  // Grafica de PieChartGoogle
  private formatDatosPieChartGoogle(): void {
    this.data1 = [];
    this.data1_barra = [];
    this.data1_lineal = [];
    for (const elemento of this.datosPieChartGoogle) {
      /* gráfica de google */
      var genero = "";
      if (elemento['key'] == "MASCULINO")
        genero = elemento['key'];
      else
        genero = elemento['key'];
      var arr = [genero, elemento['value']];
      this.data1.push(arr)
      /* gráfica de google */
      /* gráfica de barras */
      this.data1_barra.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
      /* gráfica de barras */
    }
    this.data1_lineal.push({"name":"Género","series":this.data1_barra});
    this.suma(this.data1_barra,'C1');

    this.dataTablaGenero = [];
    var porT = 0;
    for(const data of this.data1_barra){
      this.dataTablaGenero.push({
        name: data['name'],
        value: data['value'],
        porcentaje: Number(this.porcentaje(data['value'],'C1'))
      });
      porT += this.dataTablaGenero[this.dataTablaGenero.length-1].porcentaje;
    }
    this.dataTablaGenero.push({
      name: "",
      value: this.sumC1,
      porcentaje: Number(porT.toFixed(2)),
    });
  }
  // Grafica de Barras
  public cambioCant2(dat: any){
    switch(dat){
      case 1:
          this.cantidad = 'quince';
      break;
      case 2:
          this.cantidad = 'all';
      break;
    }
    this.cantidadDatZon2();
  }
  public cantidadDatZon2(){
    this.dataMapaDeCalor = [];
    switch(this.cantidad){
      case 'quince':
        var i = 0;
        for (const elemento of this.data2completo) {
          if(i < 15){
            this.dataMapaDeCalor.push({
              // @ts-ignore
              name:  elemento['name'],
              // @ts-ignore
              value: elemento['value'],
              // @ts-ignore
              //count: elemento.key_count
            });
          }
          i++;
        }
      break;
      case 'all':
        for (const elemento of this.data2completo) {
          this.dataMapaDeCalor.push({
            // @ts-ignore
            name:  elemento['name'],
            // @ts-ignore
            value: elemento['value'],
            // @ts-ignore
            //count: elemento.key_count
          });
        }
      break;
    }
    this.suma(this.dataMapaDeCalor,'C2');

    this.dataTablaClasificacion = [];
    var porT = 0;
    for(const data of this.dataMapaDeCalor){
      this.dataTablaClasificacion.push({
        name: data['name'],
        value: data['value'],
        porcentaje: Number(this.porcentaje(data['value'],'C2'))
      });
      porT += this.dataTablaClasificacion[this.dataTablaClasificacion.length-1].porcentaje;
    }
    this.dataTablaClasificacion.push({
      name: "",
      value: this.sumC2,
      porcentaje: Number(porT.toFixed(2)),
    });
  }
  private formatDatosBarra(datos): void {
    this.data2completo = [];
    this.dataMapaDeCalor_lineal = [];

    for (const elemento of datos) {
      this.data2completo.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.cantidadDatZon2();

    this.dataMapaDeCalor_lineal.push({"name":"RangoEdad","series":this.dataMapaDeCalor});
    
  }
  private formatDatosBarraPerspectiva(datos): void {
    this.data2completo = [];
    this.dataMapaDeCalor_lineal = [];
    
    for (const elemento of datos) {
      this.data2completo.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'][0]['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.cantidadDatZon2();
    
    this.dataMapaDeCalor_lineal.push({"name":"RangoEdad","series":this.dataMapaDeCalor});
  }
  // Grafica Ocupación
  public cambioCant4(dat: any){
    switch(dat){
      case 1:
          this.cantidad4 = 'quince';
      break;
      case 2:
          this.cantidad4 = 'all';
      break;
    }
    this.cantidadDatZon4();
  }
  public cantidadDatZon4(){
    this.dataOcupacion = [];
    this.dataPie4 = [];
    switch(this.cantidad4){
      case 'quince':
        var i = 0;
        for (const elemento of this.data4completo) {
          //console.log(elemento);
          if(i < 15){
            var arr = [elemento['name'], elemento['value']];
            this.dataPie4.push(arr);
            this.dataOcupacion.push({
              // @ts-ignore
              name:  elemento['name'],
              // @ts-ignore
              value: elemento['value'],
              // @ts-ignore
              //count: elemento.key_count
            });
          }
          i++;
        }
      break;
      case 'all':
        for (const elemento of this.data4completo) {
          //console.log(elemento);
          var arr = [elemento['name'], elemento['value']];
          this.dataPie4.push(arr);
          this.dataOcupacion.push({
            // @ts-ignore
            name:  elemento['name'],
            // @ts-ignore
            value: elemento['value'],
            // @ts-ignore
            //count: elemento.key_count
          });
        }
      break;
    }
    this.suma(this.dataOcupacion,'C4');
    this.dataTablaOcupacion = [];
    var porT = 0;
    for(const data of this.dataOcupacion){
      this.dataTablaOcupacion.push({
        name: data['name'],
        value: data['value'],
        porcentaje: Number(this.porcentaje(data['value'],'C4'))
      });
      porT += this.dataTablaOcupacion[this.dataTablaOcupacion.length-1].porcentaje;
    }
    this.dataTablaOcupacion.push({
      name: "",
      value: this.sumC4,
      porcentaje: Number(porT.toFixed(2)),
    });
  }
  private formatDatosOcupacion(datos): void {
    this.data4completo = [];
    this.dataOcupacion_lineal = [];

    for (const elemento of datos) {
      //console.log(elemento);
      this.data4completo.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.cantidadDatZon4();

    this.dataOcupacion_lineal.push({"name":"Ocupación","series":this.dataOcupacion});
    
  }
  private formatDatosOcupacionPerspectiva(datos): void {
    this.data4completo = [];
    this.dataOcupacion_lineal = [];

    for (const elemento of datos) {
      this.data4completo.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'][0]['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.cantidadDatZon4();

    this.dataOcupacion_lineal.push({"name":"Ocupación","series":this.dataOcupacion});
  }
  //Grafica Finalidad
  private formatDatosEscolaridad(datos): void {
    this.dataEstadoCivil = [];
    this.dataEstadoCivil_barra = [];
    this.dataEstadoCivil_lineal = [];
      for (const elemento of datos) {
        var arr2 = [elemento['key'], elemento['value']];
        this.dataEstadoCivil.push(arr2)
        this.dataEstadoCivil_barra.push({
          // @ts-ignore
          name:  elemento['key'],
          // @ts-ignore
          value: elemento['value'],
          // @ts-ignore
          //count: elemento.key_count
        });
      }
      this.dataEstadoCivil_lineal.push({"name":"Finalidad","series":this.dataEstadoCivil_barra});
      this.suma(this.dataEstadoCivil_barra,'C5');

      this.dataTablaFinalidad = [];
      var porT = 0;
      for(const data of this.dataEstadoCivil_barra){
        this.dataTablaFinalidad.push({
          name: data['name'],
          value: data['value'],
          porcentaje: Number(this.porcentaje(data['value'],'C5'))
        });
        porT += this.dataTablaFinalidad[this.dataTablaFinalidad.length-1].porcentaje;
      }
      this.dataTablaFinalidad.push({
        name: "",
        value: this.sumC5,
        porcentaje: Number(porT.toFixed(2)),
      });
  }
  private formatDatosEscolaridadPerspectiva(datos): void {
    this.dataEstadoCivil = [];
    this.dataEstadoCivil_barra = [];
    this.dataEstadoCivil_lineal = [];
      for (const elemento of datos) {
        var arr2 = [elemento['key'], elemento['value'][0]['value']];
        this.dataEstadoCivil.push(arr2)
        this.dataEstadoCivil_barra.push({
          // @ts-ignore
          name:  elemento['key'],
          // @ts-ignore
          value: elemento['value'][0]['value'],
          // @ts-ignore
          //count: elemento.key_count
        });
      }
      this.dataEstadoCivil_lineal.push({"name":"Finalidad","series":this.dataEstadoCivil_barra});
      this.suma(this.dataEstadoCivil_barra,'C5');

      this.dataTablaFinalidad = [];
      var porT = 0;
      for(const data of this.dataEstadoCivil_barra){
        this.dataTablaFinalidad.push({
          name: data['name'],
          value: data['value'],
          porcentaje: Number(this.porcentaje(data['value'],'C5'))
        });
        porT += this.dataTablaFinalidad[this.dataTablaFinalidad.length-1].porcentaje;
      }
      this.dataTablaFinalidad.push({
        name: "",
        value: this.sumC5,
        porcentaje: Number(porT.toFixed(2)),
      });
  }
  private formatDatosServicio(datos): void {
    this.dataEstadoCivil = [];
    this.dataEstadoCivil_barra = [];
    this.dataEstadoCivil_lineal = [];
      for (const elemento of datos) {
        var arr2 = [elemento['key'], elemento['value']];
        this.dataEstadoCivil.push(arr2)
        this.dataEstadoCivil_barra.push({
          // @ts-ignore
          name:  elemento['key'],
          // @ts-ignore
          value: elemento['value'],
          // @ts-ignore
          //count: elemento.key_count
        });
      }
      this.dataEstadoCivil_lineal.push({"name":"Finalidad","series":this.dataEstadoCivil_barra});
      this.suma(this.dataEstadoCivil_barra,'C5');

      this.dataTablaFinalidad = [];
      var porT = 0;
      for(const data of this.dataEstadoCivil_barra){
        this.dataTablaFinalidad.push({
          name: data['name'],
          value: data['value'],
          porcentaje: Number(this.porcentaje(data['value'],'C5'))
        });
        porT += this.dataTablaFinalidad[this.dataTablaFinalidad.length-1].porcentaje;
      }
      this.dataTablaFinalidad.push({
        name: "",
        value: this.sumC5,
        porcentaje: Number(porT.toFixed(2)),
      });
  }
  private formatDatosServicioPerspectiva(datos): void {
    this.dataEstadoCivil = [];
    this.dataEstadoCivil_barra = [];
    this.dataEstadoCivil_lineal = [];
      for (const elemento of datos) {
        var arr2 = [elemento['key'], elemento['value'][0]['value']];
        this.dataEstadoCivil.push(arr2)
        this.dataEstadoCivil_barra.push({
          // @ts-ignore
          name:  elemento['key'],
          // @ts-ignore
          value: elemento['value'][0]['value'],
          // @ts-ignore
          //count: elemento.key_count
        });
      }
      this.dataEstadoCivil_lineal.push({"name":"Finalidad","series":this.dataEstadoCivil_barra});
      this.suma(this.dataEstadoCivil_barra,'C5');

      this.dataTablaFinalidad = [];
      var porT = 0;
      for(const data of this.dataEstadoCivil_barra){
        this.dataTablaFinalidad.push({
          name: data['name'],
          value: data['value'],
          porcentaje: Number(this.porcentaje(data['value'],'C5'))
        });
        porT += this.dataTablaFinalidad[this.dataTablaFinalidad.length-1].porcentaje;
      }
      this.dataTablaFinalidad.push({
        name: "",
        value: this.sumC5,
        porcentaje: Number(porT.toFixed(2)),
      });
  }
  //dataMunicipio
  private formatDatosIngreso(datos): void {
    this.dataMunicipio = []
    for (const elemento of datos) {
      this.dataMunicipio.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
  }
  //Municipio
  public cambioCant6(dat: any){
    switch(dat){
      case 1:
          this.cantidad6 = 'quince';
      break;
      case 2:
          this.cantidad6 = 'all';
      break;
    }
    this.cantidadDatZon6();
  }
  public cantidadDatZon6(){
    this.dataMunicipio = [];
    this.dataMunicipio_Pie = [];
    switch(this.cantidad6){
      case 'quince':
        var i = 0;
        for (const elemento of this.data6completo) {
          //console.log(elemento);
          if(i < 15){
            var arr = [elemento['name'], elemento['value']];
            this.dataMunicipio_Pie.push(arr);
            this.dataMunicipio.push({
              // @ts-ignore
              name:  elemento['name'],
              // @ts-ignore
              value: elemento['value'],
              // @ts-ignore
              //count: elemento.key_count
            });
          }
          i++;
        }
      break;
      case 'all':
        for (const elemento of this.data6completo) {
          var arr = [elemento['name'], elemento['value']];
          this.dataMunicipio_Pie.push(arr);
          this.dataMunicipio.push({
            // @ts-ignore
            name:  elemento['name'],
            // @ts-ignore
            value: elemento['value'],
            // @ts-ignore
            //count: elemento.key_count
          });
        }
      break;
    }
    this.suma(this.dataMunicipio,'C6');

    this.dataTablaMunicipio = [];
      var porT = 0;
      for(const data of this.dataMunicipio){
        this.dataTablaMunicipio.push({
          name: data['name'],
          value: data['value'],
          porcentaje: Number(this.porcentaje(data['value'],'C6'))
        });
        porT += this.dataTablaMunicipio[this.dataTablaMunicipio.length-1].porcentaje;
      }
      this.dataTablaMunicipio.push({
        name: "",
        value: this.sumC6,
        porcentaje: Number(porT.toFixed(2)),
      });
  }
  private formatDatosMunicipio(datos): void {
    this.data6completo = [];
    this.dataMunicipio_lineal = [];
    
    for (const elemento of datos) {
      this.data6completo.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.cantidadDatZon6();

    this.dataMunicipio_lineal.push({"name":"Municipio","series":this.dataMunicipio});
  }
  private formatDatosMunicipioPerspectiva(datos): void {
    this.data6completo = [];
    this.dataMunicipio_lineal = [];

    for (const elemento of datos) {
      this.data6completo.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'][0]['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
    }
    this.cantidadDatZon6();

    this.dataMunicipio_lineal.push({"name":"Municipio","series":this.dataMunicipio});
    /*this.suma(this.dataMunicipio,'C6');

    this.dataTablaMunicipio = [];
      var porT = 0;
      for(const data of this.dataMunicipio){
        this.dataTablaMunicipio.push({
          name: data['name'],
          value: data['value'],
          porcentaje: Number(this.porcentaje(data['value'],'C6'))
        });
        porT += this.dataTablaMunicipio[this.dataTablaMunicipio.length-1].porcentaje;
      }
      this.dataTablaMunicipio.push({
        name: "",
        value: this.sumC6,
        porcentaje: Number(porT.toFixed(2)),
      });*/
  }
  // Data Estatus
  private formatDatosPieChartGoogleGeneracional(datos): void {
    this.datav3 = [];
    this.datav3_barra = [];
    this.datav3_lineal = [];
    for (const elemento of datos) {
      var arr = [elemento['key'], elemento['value']];
      this.datav3.push(arr)
      /* gráfica de barras */
      this.datav3_barra.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
      /* gráfica de barras */
    }
    this.datav3_lineal.push({"name":"Estatus","series":this.datav3_barra});
    this.suma(this.datav3_barra,'C3');

    this.dataTablaEstatus = [];
    var porT = 0;
    for(const data of this.datav3_barra){
      this.dataTablaEstatus.push({
        name: data['name'],
        value: data['value'],
        porcentaje: Number(this.porcentaje(data['value'],'C3'))
      });
      porT += this.dataTablaEstatus[this.dataTablaEstatus.length-1].porcentaje;
    }
    this.dataTablaEstatus.push({
      name: "",
      value: this.sumC3,
      porcentaje: Number(porT.toFixed(2)),
    });
  }
  private formatDatosPieChartGoogleGeneracionalPerspectiva(datos): void {
    this.datav3 = [];
    this.datav3_barra = [];
    this.datav3_lineal = [];
    for (const elemento of datos) {
      var arr = [elemento['key'], elemento['value'][0]['value']];
      this.datav3.push(arr)
      /* gráfica de barras */
      this.datav3_barra.push({
        // @ts-ignore
        name:  elemento['key'],
        // @ts-ignore
        value: elemento['value'][0]['value'],
        // @ts-ignore
        //count: elemento.key_count
      });
      /* gráfica de barras */
    }
    this.datav3_lineal.push({"name":"Estatus","series":this.datav3_barra});
    this.suma(this.datav3_barra,'C3');

    this.dataTablaEstatus = [];
    var porT = 0;
    for(const data of this.datav3_barra){
      this.dataTablaEstatus.push({
        name: data['name'],
        value: data['value'],
        porcentaje: Number(this.porcentaje(data['value'],'C3'))
      });
      porT += this.dataTablaEstatus[this.dataTablaEstatus.length-1].porcentaje;
    }
    this.dataTablaEstatus.push({
      name: "",
      value: this.sumC3,
      porcentaje: Number(porT.toFixed(2)),
    });
  }

  //filtros
  // AÑOS
  public anioChangeListener(anioClick: object): void {
    this.ult_anio = [];
    //console.log("  ult_mes  ---->    " + JSON.stringify(this.ult_mes) + "   " + JSON.stringify(this.mesesDeAnioDisponibles))
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(anioClick, this.aniosDisponibles);
    const anio_selecc = this.aniosDisponibles.find(anio => anio.name === anioClick['name']);
    this.ult_anio.push(anio_selecc['name'])
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    //console.log("  ult_mes  ---->    " + JSON.stringify(this.ult_mes) + "   " + JSON.stringify(this.mesesDeAnioDisponibles))
    this.buscarMesSeleccionado();
    this.aniosMesesSeleccionados();
    
    this.llamado_datos();
  }
  public buscarMesSeleccionado(){
    for (const valor of this.mesesDeAnioDisponibles){
      valor['check'] = false;
    }
    var flag = false;
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['name'] == this.ult_mes[0]){
        valor['check'] = true;
        flag = true;
      }
    }
    if(!flag){
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.ult_mes = [];
      this.ult_mes.push(this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].name);
    }
  }
  private aniosMesesSeleccionados(): void {
    this.aniosSeleccionados = [];
    this.mesesSeleccionados = [];
    for (const valor of this.aniosDisponibles) {
      if (valor.check == true)
        this.aniosSeleccionados.push(valor);
    }
    for (const valor of this.mesesDeAnioDisponibles) {
      if (valor.check == true)
        this.mesesSeleccionados.push(valor);
    }
    //console.log("mesesSeleccionados ---->   " + JSON.stringify(this.mesesSeleccionados    ))
  }
  //Mes
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(mesClick, elementArray);
    this.ult_mes = [];
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']== true){
        this.ult_mes.push(valor['name'])
      }
    }
    this.aniosMesesSeleccionados();

    this.llamado_datos();
  }
  //Sucursales
  public consolidadoChangeListener(): void {
    if(!this.consolidado){
      this.consolidado = !this.consolidado;
      this.sucursal_seleccionada = "CONSOLIDADO";
      this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
      for(const valor of this.sucursalesDisponibles)
        valor['check'] = false;
    }

    this.llamado_datos();
  }
  public sucursalChangeListener(sucursalClick: object): void {
    if(this.consolidado)
      this.consolidado = !this.consolidado;

    this.filtrosService.changeElementCheckStatusFiltroUnAnio(sucursalClick, this.sucursalesDisponibles);
    for(const valor of this.sucursalesDisponibles){
      if(valor['check']){
        this.sucursal_seleccionada = valor['name']
      }
    }

    this.llamado_datos();

  }
  public FiltrosAnioMesSucursalSeleccionado(){
    this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.datosPieChartGoogle = response.message;
        //console.log("Regresar Genero message ---->     ", JSON.stringify(response.message))
        if (this.datosPieChartGoogle){
          this.formatDatosPieChartGoogle();
        }}
    });
    this.perfilService.getTendenciaGraficaClasificacion(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosBarra(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacion(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacion(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEstatus(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracional(datos);
        }
      }
    });
    if(this.mosFinalidad){
      this.perfilService.getTendenciaGraficaFinalidad(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridad(datos);
          }
        }
      });
    }else{
      this.perfilService.getTendenciaGraficaServicio(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosServicio(datos);
          }
        }
      });
    }
    this.perfilService.getTendenciaGraficaMunicipio(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response=>{
      //(" Ingreso ----->    " + JSON.stringify(response) + " ult_mes " + JSON.stringify(this.ult_mes));
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMunicipio(datos);
        }
      }
    });
  }
  public crearSubtitulo(){
    this.subtituloGrafica1 = this.sucursal_seleccionada + " | ";

    if(this.genero != "")
      this.subtituloGrafica1 += this.genero + " | ";
    if(this.clasificacion != "")	
      this.subtituloGrafica1 += this.clasificacion + " | ";
    if(this.estatus != "")
      this.subtituloGrafica1 += this.estatus + " | ";
    if(this.ocupacion != "")
      this.subtituloGrafica1 += this.ocupacion + " | ";

    this.subtituloGrafica1 += this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
  }
  public FiltrosAnioMesSucursalSeleccionadoPerspectivaGenero(genero){
    this.crearSubtitulo();
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.datosPieChartGoogle = response.message;
        //console.log("Regresar Genero message ---->     ", JSON.stringify(response.message))
        if (this.datosPieChartGoogle){
          this.formatDatosPieChartGoogle();
        }}
    });   
    this.perfilService.getTendenciaGraficaClasificacionPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosBarraPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEstatusPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada, genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracionalPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada, genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacionPerspectiva(datos);
        }
      }
    });
    if(this.mosFinalidad){
      this.perfilService.getTendenciaGraficaFinalidadPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,genero).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });
    }else{
      this.perfilService.getTendenciaGraficaServicioPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,genero).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });
    }
    this.perfilService.getTendenciaGraficaMunicipioPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada, genero).subscribe(response=>{
      //console.log(" Ingreso ----->    " + JSON.stringify(response) + " ult_mes " + JSON.stringify(this.ult_mes));
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMunicipioPerspectiva(datos);
        }
      }
    });
  }
  public FiltrosAnioMesSucursalSeleccionadoPerspectivaClasificación(){
    this.crearSubtitulo();
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.datosPieChartGoogle = response.message;
        //console.log("Regresar Genero message ---->     ", JSON.stringify(response.message))
        if (this.datosPieChartGoogle){
          this.formatDatosPieChartGoogle();
        }}
    });
    this.perfilService.getTendenciaGraficaClasificacionPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosBarraPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEstatusPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero, this.clasificacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracionalPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacionPerspectiva(datos);
        }
      }
    });
    if(this.mosFinalidad){
      this.perfilService.getTendenciaGraficaFinalidadPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });  
    }else{
      this.perfilService.getTendenciaGraficaServicioPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });
    }
    this.perfilService.getTendenciaGraficaMunicipioPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion).subscribe(response=>{
      //console.log(" Ingreso ----->    " + JSON.stringify(response) + " ult_mes " + JSON.stringify(this.ult_mes));
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMunicipioPerspectiva(datos);
        }
      }
    });
  }  
  public FiltrosAnioMesSucursalSeleccionadoPerspectivaEstatus(){
    this.crearSubtitulo();
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.datosPieChartGoogle = response.message;
        //console.log("Regresar Genero message ---->     ", JSON.stringify(response.message))
        if (this.datosPieChartGoogle){
          this.formatDatosPieChartGoogle();
        }}
    });
    this.perfilService.getTendenciaGraficaClasificacionPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosBarraPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEstatusPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero, this.clasificacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracionalPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacionPerspectiva(datos);
        }
      }
    }); 
    if(this.mosFinalidad){
      this.perfilService.getTendenciaGraficaFinalidadPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });
    }else{
      this.perfilService.getTendenciaGraficaServicioPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });
    }
    this.perfilService.getTendenciaGraficaMunicipioPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus).subscribe(response=>{
      //console.log(" Ingreso ----->    " + JSON.stringify(response) + " ult_mes " + JSON.stringify(this.ult_mes));
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMunicipioPerspectiva(datos);
        }
      }
    });
  }
  public FiltrosAnioMesSucursalSeleccionadoPerspectivaOcupacion(){
    this.crearSubtitulo();
    this.perfilService.getTendenciaGraficaGenero(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.datosPieChartGoogle = response.message;
        //console.log("Regresar Genero message ---->     ", JSON.stringify(response.message))
        if (this.datosPieChartGoogle){
          this.formatDatosPieChartGoogle();
        }}
    });
    this.perfilService.getTendenciaGraficaClasificacionPerspectiva(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosBarraPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaEstatusPerspectiva2(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero, this.clasificacion).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosPieChartGoogleGeneracionalPerspectiva(datos);
        }
      }
    });
    this.perfilService.getTendenciaGraficaOcupacionPerspectiva3(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosOcupacionPerspectiva(datos);
        }
      }
    });
    if(this.mosFinalidad){
      this.perfilService.getTendenciaGraficaFinalidadPerspectiva4(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus,this.ocupacion).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });
    }else{
      this.perfilService.getTendenciaGraficaServicioPerspectiva4(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus,this.ocupacion).subscribe(response=>{
        if (response.http.code === 200) {
          let datos = response.message;
          if (datos){
            this.formatDatosEscolaridadPerspectiva(datos);
          }
        }
      });
    }
    this.perfilService.getTendenciaGraficaMunicipioPerspectiva4(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada,this.genero,this.clasificacion,this.estatus,this.ocupacion).subscribe(response=>{
      //console.log(" Ingreso ----->    " + JSON.stringify(response) + " ult_mes " + JSON.stringify(this.ult_mes));
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.formatDatosMunicipioPerspectiva(datos);
        }
      }
    });
  }

  public cambioPerC5(pers: any){
    switch(pers){
      case 'f':
        this.mosFinalidad = true;
        this.mosServicio = false;
      break;
      case 's':
        this.mosServicio = true;  
        this.mosFinalidad = false;
      break;
    }
    this.llamado_datos();
  }
  /* Eventos para cambiar graficas C1*/
  public cambiarGraficaC1(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC1 = true;
        this.graficapieC1 = false;
        this.graficalinealC1 = false;
        this.graficaTablaC1 = false;
      break;
      case 'lineal':
        this.graficalinealC1 = true;
        this.graficabarraC1 = false;
        this.graficapieC1 = false;
        this.graficaTablaC1 = false;
      break;
      case 'pastel':
        this.graficapieC1 = true;
        this.graficalinealC1 = false;
        this.graficabarraC1 = false;
        this.graficaTablaC1 = false;
      break;
      case 'tabla':
        
        this.graficaTablaC1 = true;
        this.graficapieC1 = false;
        this.graficalinealC1 = false;
        this.graficabarraC1 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C1*/
  /* Eventos para cambiar graficas C2*/
  public cambiarGraficaC2(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC2 = true;
        this.mapacalorC2 = false;
        this.graficalinealC2 = false;
        this.graficatablaC2 = false;
      break;
      case 'lineal':
        this.graficalinealC2 = true;
        this.graficabarraC2 = false;
        this.mapacalorC2 = false;
        this.graficatablaC2 = false;
      break;
      case 'mapacalor':
        this.mapacalorC2 = true;
        this.graficalinealC2 = false;
        this.graficabarraC2 = false;
        this.graficatablaC2 = false;
      break;
      case 'tabla':
        this.graficatablaC2 = true;
        this.mapacalorC2 = false;
        this.graficalinealC2 = false;
        this.graficabarraC2 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C2*/
  /* Eventos para cambiar graficas C3*/
  public cambiarGraficaC3(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC3 = true;
        this.graficapieC3 = false;
        this.graficalinealC3 = false;
        this.graficatablaC3 = false;
      break;
      case 'lineal':
        this.graficalinealC3 = true;
        this.graficabarraC3 = false;
        this.graficapieC3 = false;
        this.graficatablaC3 = false;    
      break;
      case 'pastel':
        this.graficapieC3 = true;
        this.graficalinealC3 = false;
        this.graficabarraC3 = false;
        this.graficatablaC3 = false;
      break;
      case 'tabla':
        this.graficatablaC3 = true;  
        this.graficapieC3 = false;
        this.graficalinealC3 = false;
        this.graficabarraC3 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C3*/
  /* Eventos para cambiar graficas C4*/
  public cambiarGraficaC4(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC4 = true;
        this.graficalinealC4 = false;
        this.graficapieC4 = false;
        this.graficatablaC4 = false;
      break;
      case 'lineal':
        this.graficalinealC4 = true;
        this.graficapieC4 = false;
        this.graficatablaC4 = false;
        this.graficabarraC4 = false;
      break;
      case 'pastel':
        this.graficapieC4 = true;
        this.graficatablaC4 = false;
        this.graficabarraC4 = false;
        this.graficalinealC4 = false;
      break;
      case 'tabla':
        this.graficatablaC4 = true;
        this.graficabarraC4 = false;
        this.graficalinealC4 = false;
        this.graficapieC4 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C4*/
  /* Eventos para cambiar graficas C5*/
  public cambiarGraficaC5(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC5 = true;
        this.graficapastelC5 = false;
        this.graficalinealC5 = false;
        this.graficatablaC5 = false;
      break;
      case 'lineal':
        this.graficalinealC5 = true;
        this.graficatablaC5 = false;
        this.graficabarraC5 = false;
        this.graficapastelC5 = false;
      break;
      case 'pastel':
        this.graficapastelC5 = true;
        this.graficatablaC5 = false;
        this.graficalinealC5 = false;
        this.graficabarraC5 = false;
      break;
      case 'tabla':
        this.graficatablaC5 = true;
        this.graficapastelC5 = false;
        this.graficalinealC5 = false;
        this.graficabarraC5 = false;
      break;
    }
  }
  /* Eventos para cambiar graficas C5*/
  /* Eventos para cambiar graficas C6*/
  public cambiarGraficaC6(tipografica){
    switch(tipografica){
      case 'barra':
        this.graficabarraC6 = true;
        this.graficalinealC6 = false;
        this.graficapieC6 = false;
        this.graficatablaC6 = false;
      break;
      case 'lineal':
        this.graficalinealC6 = true;
        this.graficapieC6 = false;
        this.graficatablaC6 = false;
        this.graficabarraC6 = false;
      break;
      case 'pastel':
        this.graficapieC6 = true;
        this.graficatablaC6 = false;
        this.graficalinealC6 = false;
        this.graficabarraC6 = false;
      break;
      case 'tabla':
        this.graficatablaC6 = true;
        this.graficalinealC6 = false;
        this.graficabarraC6 = false;
        this.graficapieC6 = false;
      break;
    }
  }
  /** Eventos de C1*/
  public clickPieChartC1(event){
    if(this.graficapieC1){
      this.genero = this.data1[event.selection[0].row][0].toString();
    }else{
      this.genero = event.name;
    }

    this.llamado_datos();
  }
  /** Eventos de C2*/
  public clickMapaCalor(event: any): void {
    if(this.genero != ""){ 
      this.clasificacion = event.name;
    }

    this.llamado_datos();
  }
  /** Eventos de C3*/
  public clickPieC3(event: any): void{
    if(this.genero != "" && this.clasificacion != ""){
      if(this.graficapieC3){
        this.estatus = this.datav3[event.selection[0].row][0].toString();
      }else{
        this.estatus = event.name;
      }
    }

    this.llamado_datos();
  }
  /** Eventos de C4 */
  public clickPieC4(event: any): void{
    if(this.genero != "" && this.clasificacion != "" && this.estatus != ""){
      if(this.graficapieC4){
        this.ocupacion = this.dataOcupacion[event.selection[0].row][0].toString();
      }else{
        this.ocupacion = event.name;
      }
    }
    this.llamado_datos();
  }
  public llamado_datos(){
    if(this.genero != "" && this.clasificacion != "" && this.estatus != "" && this.ocupacion != ""){
      this.FiltrosAnioMesSucursalSeleccionadoPerspectivaOcupacion();
    }
    if(this.genero != "" && this.clasificacion != "" && this.estatus != "" && this.ocupacion == ""){
      this.FiltrosAnioMesSucursalSeleccionadoPerspectivaEstatus();
    }
    if(this.genero != "" && this.clasificacion != "" && this.estatus == "" && this.ocupacion == ""){
      this.FiltrosAnioMesSucursalSeleccionadoPerspectivaClasificación();
    }
    if(this.genero != "" && this.clasificacion == "" && this.estatus == "" && this.ocupacion == ""){
      this.FiltrosAnioMesSucursalSeleccionadoPerspectivaGenero(this.genero);
    }
    if(this.genero == "" && this.clasificacion == "" && this.estatus == "" && this.ocupacion == ""){
      this.FiltrosAnioMesSucursalSeleccionado();
    }
  }
  /** */
  public perspectiva(ini){
    var pers = ini + ' ' + this.sucursal_seleccionada + " | " + this.ult_anio[0] + " | " + this.ult_mes[0] ;
    return pers;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  public clickexportCSV(zon){
    var sucursal = "";
    var auxsuc = [], i = 0;
    switch(zon){
      case 'C1':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaGeneroSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),sucursal).subscribe(response => {
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                this.exportCSVSuc(auxsuc,'Genero');
              }
            }
          });
        }
      break;
      case 'C2':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaClasificacionSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),sucursal,this.genero).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                var clasificacion = [];
                for(var j = 0; j<this.dataMapaDeCalor.length; j++){
                  if(j<10){
                    clasificacion.push(''+this.dataMapaDeCalor[j].name);
                  }
                }
                var auxEmpleos, auxSeries = [];
                for(const dato of auxsuc){
                  for(var j = 0; j<clasificacion.length; j++){
                    auxEmpleos = dato.series.find(emp => emp.key == clasificacion[j]);
                    if(auxEmpleos !=  undefined){
                      auxSeries.push(auxEmpleos);
                    }else{
                      auxSeries.push({key: clasificacion[j], value: 0});
                    }
                  }
                  dato.series = auxSeries;
                  auxSeries = [];
                }
                this.exportCSVSuc(auxsuc,'Clasificacion');
              }
            }
          });
        }
      break;
      case 'C3':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;      
          this.perfilService.getTendenciaGraficaEstatusSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),sucursal,this.genero, this.clasificacion).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                var estatus = [];
                for(var j = 0; j<this.datav3_barra.length; j++){
                  if(j<10){
                    estatus.push(''+this.datav3_barra[j].name);
                  }
                }
                var auxEmpleos, auxSeries = [];
                for(const dato of auxsuc){
                  for(var j = 0; j<estatus.length; j++){
                    auxEmpleos = dato.series.find(emp => emp.key == estatus[j]);
                    if(auxEmpleos !=  undefined){
                      auxSeries.push(auxEmpleos);
                    }else{
                      auxSeries.push({key: estatus[j], value: 0});
                    }
                  }
                  dato.series = auxSeries;
                  auxSeries = [];
                }
                this.exportCSVSuc(auxsuc,'Estuatus');
              }
            }
          });
        }
      break;
      case 'C4':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaOcupacionSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),sucursal,this.genero,this.clasificacion,this.estatus).subscribe(response=>{
            if (response.http.code === 200) {
              var aux = response.message;
              aux.sort(function (a, b) {
                if (a.key > b.key) {
                  return 1;
                }
                if (a.key < b.key) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              auxsuc.push({name: suc.name, series: aux});
              i++;
              if(i == 22){
                var ocupacion = [];
                for(var j = 0; j<this.dataOcupacion.length; j++){
                  if(j<10){
                    ocupacion.push(''+this.dataOcupacion[j].name);
                  }
                }
                var auxEmpleos, auxSeries = [];
                for(const dato of auxsuc){
                  for(var j = 0; j<ocupacion.length; j++){
                    auxEmpleos = dato.series.find(emp => emp.key == ocupacion[j]);
                    if(auxEmpleos !=  undefined){
                      auxSeries.push(auxEmpleos);
                    }else{
                      auxSeries.push({key: ocupacion[j], value: 0});
                    }
                  }
                  dato.series = auxSeries;
                  auxSeries = [];
                }
                this.exportCSVSuc(auxsuc,'Ocupacion');
              }
            }
          });
        }
      break;
      case 'C5':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          if(this.mosFinalidad){
            this.perfilService.getTendenciaGraficaFinalidadSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),sucursal,this.genero,this.clasificacion,this.estatus,this.ocupacion).subscribe(response=>{
              if (response.http.code === 200) {
                var aux = response.message;
                aux.sort(function (a, b) {
                  if (a.key > b.key) {
                    return 1;
                  }
                  if (a.key < b.key) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });
                auxsuc.push({name: suc.name, series: aux});
                i++;
                if(i == 22){
                  var finalidad = [];
                  for(var j = 0; j<this.dataEstadoCivil_barra.length; j++){
                    if(j<10){
                      finalidad.push(''+this.dataEstadoCivil_barra[j].name);
                    }
                  }
                  var auxEmpleos, auxSeries = [];
                  for(const dato of auxsuc){
                    for(var j = 0; j<finalidad.length; j++){
                      auxEmpleos = dato.series.find(emp => emp.key == finalidad[j]);
                      if(auxEmpleos !=  undefined){
                        auxSeries.push(auxEmpleos);
                      }else{
                        auxSeries.push({key: finalidad[j], value: 0});
                      }
                    }
                    dato.series = auxSeries;
                    auxSeries = [];
                  }
                  this.exportCSVSuc(auxsuc,'Finalidad');
                }
              }
            });
          }else{
            this.perfilService.getTendenciaGraficaServicioSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),sucursal,this.genero,this.clasificacion,this.estatus,this.ocupacion).subscribe(response=>{
              if (response.http.code === 200) {
                var aux = response.message;
                aux.sort(function (a, b) {
                  if (a.key > b.key) {
                    return 1;
                  }
                  if (a.key < b.key) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });
                auxsuc.push({name: suc.name, series: aux});
                i++;
                if(i == 22){
                  var finalidad = [];
                  for(var j = 0; j<this.dataEstadoCivil_barra.length; j++){
                    if(j<10){
                      finalidad.push(''+this.dataEstadoCivil_barra[j].name);
                    }
                  }
                  var auxEmpleos, auxSeries = [];
                  for(const dato of auxsuc){
                    for(var j = 0; j<finalidad.length; j++){
                      auxEmpleos = dato.series.find(emp => emp.key == finalidad[j]);
                      if(auxEmpleos !=  undefined){
                        auxSeries.push(auxEmpleos);
                      }else{
                        auxSeries.push({key: finalidad[j], value: 0});
                      }
                    }
                    dato.series = auxSeries;
                    auxSeries = [];
                  }
                  this.exportCSVSuc(auxsuc,'Servicios');
                }
              }
            });
          }
        }
      break;
      case 'C6':
        for(const suc of this.sucursalesDisponibles){
          sucursal = suc.name;
          this.perfilService.getTendenciaGraficaMunicipioSucursales(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),sucursal,this.genero,this.clasificacion,this.estatus,this.ocupacion).subscribe(response=>{
            //console.log(" Ingreso ----->    " + JSON.stringify(response) + " ult_mes " + JSON.stringify(this.ult_mes));
            if (response.http.code === 200) {
              var aux = response.message;
                aux.sort(function (a, b) {
                  if (a.key > b.key) {
                    return 1;
                  }
                  if (a.key < b.key) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });
                auxsuc.push({name: suc.name, series: aux});
                i++;
                if(i == 22){
                  var municipio = [];
                  for(var j = 0; j<this.dataMunicipio.length; j++){
                    if(j<10){
                      municipio.push(''+this.dataMunicipio[j].name);
                    }
                  }
                  var auxEmpleos, auxSeries = [];
                  for(const dato of auxsuc){
                    for(var j = 0; j<municipio.length; j++){
                      auxEmpleos = dato.series.find(emp => emp.key == municipio[j]);
                      if(auxEmpleos !=  undefined){
                        auxSeries.push(auxEmpleos);
                      }else{
                        auxSeries.push({key: municipio[j], value: 0});
                      }
                    }
                    dato.series = auxSeries;
                    auxSeries = [];
                  }
                  this.exportCSVSuc(auxsuc,'Municipio');
                }
            }
          });
        }
      break;
    }
  }
  public exportCSVSuc(arreglo, names){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    var perspectiva = '';
    var encabezado_excel = ['Sucursal'];
    for(const data of arreglo[0].series){
      encabezado_excel.push(data.key);
    }
    var cadena_evaluacion = encabezado_excel;
    name = names;
    perspectiva = this.subtituloGrafica1;
    for(const dat of arreglo){
      aux.push(dat.name);
      for(const data of dat.series){
        aux.push(data.value);
      }
      data_excel.push(aux);
      aux = [];
    }
    console.log(data_excel, encabezado_excel)
    this.excelservices.generateExcelPerfil(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  public export_excel(zon: any){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    var perspectiva = '';
    switch(zon){
      case 'C1':
        var encabezado_excel = ['Genero','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por género';
        perspectiva = this.perspectiva(name);
        for(var i = 0; i < this.data1_barra.length; i++){
          aux.push(this.data1_barra[i].name);
          aux.push(this.data1_barra[i].value);
          aux.push(Number(this.porcentaje(this.data1_barra[i].value,'C1')));
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C2':
        var encabezado_excel = ['Nombre credito','Números de credito','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Clasificación';
        perspectiva = this.perspectiva(name);
        for(var i = 0; i < this.dataMapaDeCalor.length; i++){
          aux.push(this.dataMapaDeCalor[i].name);
          aux.push(this.dataMapaDeCalor[i].value);
          aux.push(Number(this.porcentaje(this.dataMapaDeCalor[i].value,'C2')));
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C3':
        var encabezado_excel = ['Estado','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por estatus';
        perspectiva = this.perspectiva(name);
        for(var i = 0; i < this.datav3_barra.length; i++){
          aux.push(this.datav3_barra[i].name);
          aux.push(this.datav3_barra[i].value);
          aux.push(Number(this.porcentaje(this.datav3_barra[i].value,'C3')));
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C4':
        var encabezado_excel = ['Ocupación','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por ocupación';
        perspectiva = this.perspectiva(name);
        for(var i = 0; i < this.dataOcupacion.length; i++){
          aux.push(this.dataOcupacion[i].name);
          aux.push(this.dataOcupacion[i].value);
          aux.push(Number(this.porcentaje(this.dataOcupacion[i].value,'C4')));
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C5':
        var encabezado_excel = ['Finalidad','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por ocupación';
        perspectiva = this.perspectiva(name);
        for(var i = 0; i < this.dataEstadoCivil_barra.length; i++){
          aux.push(this.dataEstadoCivil_barra[i].name);
          aux.push(this.dataEstadoCivil_barra[i].value);
          aux.push(Number(this.porcentaje(this.dataEstadoCivil_barra[i].value,'C2')));
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 'C6':
        var encabezado_excel = ['Municipio','Número','Porcentaje'];
        var cadena_evaluacion = encabezado_excel;
        name = 'Perfil por municipio';
        perspectiva = this.perspectiva(name);
        for(var i = 0; i < this.dataMunicipio.length; i++){
          aux.push(this.dataMunicipio[i].name);
          aux.push(this.dataMunicipio[i].value);
          aux.push(Number(this.porcentaje(this.dataMunicipio[i].value,'C2')));
          data_excel.push(aux);
          aux = [];
        }
      break;
    }
    this.excelservices.generateExcelPerfil(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva);
  }
  public export_imagen(zon: any){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 'C1':
        grafica = "GrafC1";
        if(this.graficapieC1)
          name = 'GraficaPieC1';
        if(this.graficabarraC1)
          name = 'GraficaBarrasC1';
        if(this.graficalinealC1)
          name = 'GraficaLinealC1';  
      break;
      case 'C2':
        grafica = "GrafC2";
        if(this.mapacalorC2)
          name = 'GraficaCalorC2';
        if(this.graficabarraC2)
          name = 'GraficaBarrasC2';
        if(this.graficalinealC2)
          name = 'GraficaLinealC2';  
      break;
      case 'C3':
        grafica = "GrafC3";
        if(this.graficapieC3)
          name = 'GraficaPieC3';
        if(this.graficabarraC3)
          name = 'GraficaBarrasC3';
        if(this.graficalinealC3)
          name = 'GraficaLinealC3';  
      break;
      case 'C4':
        grafica = "GrafC4";
        if(this.graficapieC4)
          name = 'GraficaPieC4';
        if(this.graficabarraC4)
          name = 'GraficaBarrasC4';
        if(this.graficalinealC4)
          name = 'GraficaLinealC4';  
      break;
      case 'C5':
        grafica = "GrafC5";
        if(this.graficapastelC5)
          name = 'GraficaPieC5';
        if(this.graficabarraC5)
          name = 'GraficaBarrasC5';
        if(this.graficalinealC5)
          name = 'GraficaLinealC5';  
      break;
      case 'C6':
        grafica = "GrafC6";
        if(this.graficapieC6)
          name = 'GraficaPieC6';
        if(this.graficabarraC6)
          name = 'GraficaBarrasC6';
        if(this.graficalinealC6)
          name = 'GraficaLinealC6';  
      break;
    }
    PDF.exportimagenes(grafica,name);
  }
  public calPorcentaje(dat: any, zon: any){
    var por: number = 0;
    switch(zon){
      case 'C2':
        por = dat*100/this.totalCred;
        dat = por.toFixed(2);
        return Number(dat);
    }
  }
  public porcentaje(dato,zon){
    switch(zon){
      case 'C1':
        return (dato*100/this.sumC1).toFixed(2);
      case 'C2':
        return (dato*100/this.sumC2).toFixed(2);
      case 'C3':
        return (dato*100/this.sumC3).toFixed(2);
      case 'C4':
        return (dato*100/this.sumC4).toFixed(2);
      case 'C5':
        return (dato*100/this.sumC5).toFixed(2);
      case 'C6':
        return (dato*100/this.sumC6).toFixed(2);
    }
  }
  public suma(arreglo,zon){
    switch(zon){
      case 'C1':
        this.sumC1 = 0;
        for(const dato of arreglo){
          this.sumC1 += dato['value'];
        }
      break;
      case 'C2':
        this.sumC2 = 0;
        for(const dato of arreglo){
          this.sumC2 += dato['value'];
        }
      break;
      case 'C3':
        this.sumC3 = 0;
        for(const dato of arreglo){
          this.sumC3 += dato['value'];
        }
      break;
      case 'C4':
        this.sumC4 = 0;
        for(const dato of arreglo){
          this.sumC4 += dato['value'];
        }
      break;
      case 'C5':
        this.sumC5 = 0;
        for(const dato of arreglo){
          this.sumC5 += dato['value'];
        }
      break;
      case 'C6':
        this.sumC6 = 0;
        for(const dato of arreglo){
          this.sumC6 += dato['value'];
        }
      break;
    }
  }
  
  /* Ampliar gráficas */
  public AmpliarC1(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Género";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficapieC1)
      this.graficapieC1_modal = true;
    if(this.graficabarraC1)
      this.graficabarraC1_modal = true;
    if(this.graficalinealC1)
      this.graficalinealC1_modal = true;
    if(this.graficaTablaC1)
      this.graficaTablaC1_modal = true;
  }
  public AmpliarC2(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Clasificación";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.mapacalorC2)
      this.mapacalorC2_modal = true;
    if(this.graficabarraC2)
      this.graficabarraC2_modal = true;
    if(this.graficalinealC2)
      this.graficalinealC2_modal = true;
    if(this.graficatablaC2)
      this.graficatablaC2_modal = true;
  }
  public AmpliarC3(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Estatus";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficapieC3)
      this.graficapieC3_modal = true;
    if(this.graficabarraC3)
      this.graficabarraC3_modal = true;
    if(this.graficalinealC3)
      this.graficalinealC3_modal = true;
    if(this.graficatablaC3)
      this.graficatablaC3_modal = true;
  }
  public AmpliarC4(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Ocupación";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficabarraC4)
      this.graficabarraC4_modal = true;
    if(this.graficalinealC4)
      this.graficalinealC4_modal = true;
    if(this.graficapieC4)
      this.graficapieC4_modal = true;
    if(this.graficatablaC4)
      this.graficatablaC4_modal = true;
  }
  public AmpliarC5(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Finalidad";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficapastelC5)
      this.graficapastelC5_modal = true;
    if(this.graficabarraC5)
      this.graficabarraC5_modal = true;
    if(this.graficalinealC5)
      this.graficalinealC5_modal = true;
    if(this.graficatablaC5)
      this.graficatablaC5_modal = true;
  }
  public AmpliarC6(){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Municipio";
    this.subtitulomodal = this.subtituloGrafica1;
    if(this.graficabarraC6)
      this.graficabarraC6_modal = true;
    if(this.graficalinealC6)
      this.graficalinealC6_modal = true;
    if(this.graficapieC6)
      this.graficapieC6_modal = true;
    if(this.graficatablaC6)
      this.graficatablaC6_modal = true;
  }
  public hideGraficasModal(){
    //C1
    this.graficapieC1_modal = false;
    this.graficabarraC1_modal = false;
    this.graficalinealC1_modal = false;
    this.graficaTablaC1_modal = false;
    //C2
    this.mapacalorC2_modal = false;
    this.graficalinealC2_modal = false;
    this.graficabarraC2_modal = false;
    this.graficatablaC2_modal = false;
    //C3
    this.graficapieC3_modal = false;
    this.graficabarraC3_modal = false;
    this.graficalinealC3_modal = false;
    this.graficatablaC3_modal = false;
    //C4
    this.graficalinealC4_modal = false;
    this.graficabarraC4_modal = false;
    this.graficapieC4_modal = false;
    this.graficatablaC4_modal = false;
    //C5
    this.graficapastelC5_modal = false;
    this.graficabarraC5_modal = false;
    this.graficalinealC5_modal = false;
    this.graficatablaC5_modal = false;
    //C6
    this.graficalinealC6_modal = false;
    this.graficabarraC6_modal = false;
    this.graficapieC6_modal = false;
    this.graficatablaC6_modal = false;

  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  /* Ampliar gráficas */

  public quitarFiltros(){
    this.sucursal_seleccionada = "CONSOLIDADO";
    this.genero = "";
    this.clasificacion = "";
    this.estatus = "";
    this.ocupacion = "";
    this.consolidado = true;
    this.mosFinalidad = true;
    this.mosServicio = false;
    this.FiltrosAnioMesSucursalSeleccionado();
    //C1
    this.graficapieC1 = true;
    this.graficabarraC1 = false;
    this.graficalinealC1 = false;
    this.graficaTablaC1 = false;
    //C2
    this.mapacalorC2 = true;
    this.graficalinealC2 = false;
    this.graficabarraC2 = false;
    this.graficatablaC2 = false;
    //C3
    this.graficapieC3 = true;
    this.graficabarraC3 = false;
    this.graficalinealC3 = false;
    this.graficatablaC3 = false;
    //C4
    this.graficalinealC4 = false;
    this.graficabarraC4 = true;
    this.graficapieC4 = false;
    this.graficatablaC4 = false;
    //C5
    this.graficapastelC5 = true;
    this.graficabarraC5 = false;
    this.graficalinealC5 = false;
    this.graficatablaC5 = false;
    //C6
    this.graficalinealC6 = false;
    this.graficabarraC6 = true;
    this.graficapieC6 = false;
    this.graficatablaC6 = false;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sucursalesDisponibles2.filter = filterValue.trim().toLowerCase();
  }
}
