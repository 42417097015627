import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data-service/data.service';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import {FuncionesColores} from '../../shared/funciones-colores/funciones_colores';
import {MatTableDataSource} from '@angular/material/table';

import {MatrizTransicionService} from './../../../services/riesgos/matriz-transicion.service';
import { matrizTransicionEntity, zonaEntity, productoEntity,sectorEntity } from './../entities_riesgos/entities_riesgos';
import {ZonaService} from './../../../services/riesgos/zona.service';
import {ProductoService} from './../../../services/riesgos/producto.service';
import { NumberFormatStyle } from '@angular/common';

import {CommonVariables} from './../../financiero/funciones';

import {FuncionesRiesgo} from './../funciones_riesgos';
import { ExcelService } from './../../../services/exportar/excel.service';
import { SectorService } from '../../../services/riesgos/sector.service'
@Component({
  selector: 'app-matriz-transicion',
  templateUrl: './matriz-transicion.component.html',
  styleUrls: ['./matriz-transicion.component.css']
})
export class MatrizTransicionComponent implements OnInit {
  /* Declaración de variables */
  public DatosAnios = {"aniosDisponibles":[
                                        {"name":"2020","value":2020,"check":false,"selected":false},
                                        {"name":"2021","value":2021,"check":false,"selected":false},
                                        {"name":"2022","value":2022,"check":true,"selected":false}],
                        "mesesDisponibles":[
                                        {"name":"nov","value":"112020","year":"2020","check":false},
                                        {"name":"dic","value":"122020","year":"2020","check":false},
                                        {"name":"ene","value":"012021","year":"2021","check":false},
                                        {"name":"feb","value":"022021","year":"2021","check":false},
                                        {"name":"mar","value":"032021","year":"2021","check":false},
                                        {"name":"abr","value":"042021","year":"2021","check":false},
                                        {"name":"may","value":"052021","year":"2021","check":false},
                                        {"name":"jun","value":"062021","year":"2021","check":false},
                                        {"name":"jul","value":"072021","year":"2021","check":false},
                                        {"name":"ago","value":"082021","year":"2021","check":false},
                                        {"name":"sep","value":"092021","year":"2021","check":false},
                                        {"name":"oct","value":"102021","year":"2021","check":false},
                                        {"name":"nov","value":"112021","year":"2021","check":false},
                                        {"name":"dic","value":"122021","year":"2021","check":false},
                                        {"name":"ene","value":"012022","year":"2022","check":false},
                                        {"name":"feb","value":"022022","year":"2022","check":false},
                                        {"name":"mar","value":"032022","year":"2022","check":false},
                                        {"name":"abr","value":"042022","year":"2022","check":false},
                                        {"name":"may","value":"052022","year":"2022","check":false},
                                        {"name":"jun","value":"062022","year":"2022","check":false},
                                        {"name":"jul","value":"072022","year":"2022","check":false},
                                        {"name":"ago","value":"082022","year":"2022","check":false},
                                        {"name":"sep","value":"092022","year":"2022","check":false},
                                        {"name":"oct","value":"102022","year":"2022","check":false},
                                        {"name":"nov","value":"112022","year":"2022","check":false},]};
  public aniosDisponibles: any [];
  public mesesDisponibles: any [];
  public mesesDeAnioDisponibles: any[];
  public puedeFiltrarPorMeses = false;
  public puedeFiltrarPorSucursales = false;
  public sucursalesDisponibles: any[];
  public anioSeleccionado: object;
  public mesSeleccionado: any;
  public sucursalSeleccionada: object;
  public subtituloGrafica:string = "";
  public subtituloGraficaII:string = "";
  public mesAnio:string = "";
  public colorScheme: string = FuncionesColores.setpaleta_azul('paleta_azul');

  displayedColumns: string[] = [];
  dataSource:any[] = [];
  dataSourceII:any[] = [];
  total_diagonal: number = 0;
  total_arribadiagonal: number = 0;
  total_abajodiagonal: number = 0;
  total_diagonalII: number = 0;
  total_arribadiagonalII: number = 0;
  total_abajodiagonalII: number = 0;
  matrizTransicionEntity = new matrizTransicionEntity;
  public ult_anio: any [] = [];
  public ult_mes: any [] = [];


  array_total:number [] = [0,0,0,0,0,0,0,0,0];
  array_totalII:any [] = [0,0,0,0,0,0,0,0,0];

  dataZona: any [] = [];
  dataProducto: any [] = [];
  perspectiva: boolean = false;

  flag_zona: boolean = false;
  zona_name: string = "";
  flag_producto: boolean = false;
  producto_name: string = "";
  zona = new zonaEntity;
  producto = new productoEntity;
  sector = new sectorEntity;
  sectors: any[] = [];


  showModal: boolean = false;
  titulomodal: string = "";
  subtitulomodal: string = "";
  tablamonto_modal:boolean = false;
  tablacreditos_modal: boolean = false;
  data_excel: any  [] = [];
  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private matrizTransicionService: MatrizTransicionService,
    private zonaService: ZonaService,
    private productoService: ProductoService,
    private excelService: ExcelService,
    private sectorService: SectorService,
  ) { 
    this.matrizTransicionEntity.BnConIntereses = 1;
    this.FuncionZona();
    this.FuncionProducto();
    this.FuncionSector();
    this.getConsultas();

   }
   ngOnInit(): void {}
   FuncionZona(){
    this.zonaService.getByAddUrl_Riesgo('zona',this.zona).subscribe(dataArr => {
      this.dataZona = dataArr;
      //console.log("Zona ---->   "+JSON.stringify(dataArr));
    });
  }
  filtrarZona(zona){
    this.flag_zona = true;
    this.flag_producto = false;
    //console.log("Zona a filtrarl : " + zona);
    this.matrizTransicionEntity.Id_Zona = zona.Id_Zona;
    this.zona_name = zona.Nombre_Zona;
    this.matrizTransicionEntity.Id_Producto = null;
    this.matrizTransicionEntity.Id_Sector = null;
    this.Funtion_matrizTransicion(this.matrizTransicionEntity);
    this.subtituloGrafica = this.mesSeleccionado["year"].substring(2,4) + " | " + CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | matriz | Zona " + zona.Nombre_Zona;
    this.subtituloGraficaII = this.mesSeleccionado["year"].substring(2,4) + " | " + CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | matriz | Zona " + zona.Nombre_Zona;
    this.mesAnio = CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | " +  this.mesSeleccionado["year"].substring(2,4);
  }
  filtrarProducto(producto){
    this.flag_producto = true;
    this.flag_zona = false;
    //console.log("Producto a filtrarl : " + producto);
    this.matrizTransicionEntity.Id_Zona = null;
    this.matrizTransicionEntity.Id_Producto = producto.Id_Producto;
    this.matrizTransicionEntity.Id_Sector = null;
    this.producto_name = producto.Nombre_Producto;
    this.Funtion_matrizTransicion(this.matrizTransicionEntity);
    this.subtituloGrafica = this.mesSeleccionado["year"].substring(2,4) + " | " + CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | matriz | Producto " + producto.Nombre_Producto;
    this.subtituloGraficaII = this.mesSeleccionado["year"].substring(2,4) + " | " + CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | matriz | Producto " + producto.Nombre_Producto;
    this.mesAnio = CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | " +  this.mesSeleccionado["year"].substring(2,4);
  }
  FuncionProducto(){
    this.productoService.getByAddUrl_Riesgo('producto',this.producto).subscribe(dataArr => {
      this.dataProducto = dataArr;
      //console.log("Producto---->   "+JSON.stringify(dataArr));
    });
  }
  FuncionSector(){
    this.sectorService.getByAddUrl_Riesgo('sector',this.sector).subscribe(dataArr => {
      this.sectores(dataArr);
    });
  }
  sectores(arreglo: any){
    this.sectors = arreglo;
  }
  filtrarSectores(sector){
    this.flag_producto = true;
    this.flag_zona = false;
    //console.log("Producto a filtrarl : " + JSON.stringify(sector));
    this.matrizTransicionEntity.Id_Zona = null;
    this.matrizTransicionEntity.Id_Producto = null;
    this.matrizTransicionEntity.Id_Sector = sector.Id_Sector;
    this.Funtion_matrizTransicion(this.matrizTransicionEntity);
    this.subtituloGrafica = this.mesSeleccionado["year"].substring(2,4) + " | " + CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | matriz | Sector " + sector.Nombre_Sector;
    this.subtituloGraficaII = this.mesSeleccionado["year"].substring(2,4) + " | " + CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | matriz | Sector " + sector.Nombre_Sector;
    this.mesAnio = CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | " +  this.mesSeleccionado["year"].substring(2,4);
  }

  public getConsultas(){
    this.aniosDisponibles = this.DatosAnios.aniosDisponibles;
    this.mesesDisponibles = this.DatosAnios.mesesDisponibles;
    let ult_anio = this.aniosDisponibles[2].name;
    //console.log("mesSeleccionado --> ult anio    " + JSON.stringify(ult_anio))
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio);
    let ult_mes = this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].name;
    this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
    this.mesSeleccionado = this.mesesDeAnioDisponibles.find(mes => mes.name === ult_mes);
    //console.log("mesSeleccionado ---> " + JSON.stringify(this.mesSeleccionado) + " ult_mes ----> " + this.ult_mes);
    this.matrizTransicionEntity.Id_Institucion = 1;
    this.matrizTransicionEntity.Periodo = this.mesSeleccionado.value;
    
    let anio = this.matrizTransicionEntity.Periodo.toString().substr(-4);
    ult_mes = this.matrizTransicionEntity.Periodo.toString();
    let mes = Number(ult_mes.toString().replace(anio,""));
    let aux_mes = mes;
    if(mes == 1){
      mes = 12;
      anio = (Number(anio) - 1).toString();
    }else{
      mes -= 1;
    }
    this.matrizTransicionEntity.PeriodoAnterior = Number(mes.toString().concat(anio));
    this.matrizTransicionEntity.NDatos = 2;
    this.matrizTransicionEntity.BnConIntereses = 1;
    this.Funtion_matrizTransicion(this.matrizTransicionEntity);
    this.subtituloGrafica = anio.substring(2,4) + " | " + CommonVariables.f_switchmesEntero(aux_mes.toString()) + " | matriz";
    this.subtituloGraficaII = anio.substring(2,4) + " | " + CommonVariables.f_switchmesEntero(aux_mes.toString()) + " | matriz";
    this.mesAnio = CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | " +  anio.substring(2,4);
  }
  public Funtion_matrizTransicion(matrizTransicionEntity){
    this.matrizTransicionService.getByAddUrl_Riesgo('matrizTransicion',matrizTransicionEntity).subscribe(dataArr => {
      //console.log("MatrizTransición ---->   "+JSON.stringify(dataArr));
      this.ConvertirMatrizTransicionTabla(dataArr)
    });
  }
  public ConvertirMatrizTransicionTabla(dataArr){
    //primero verificar el número de row del arreglo 
    let rows = [];
    let columnas: any[] = [];
    for(const dato of dataArr){
      if(!columnas.includes(dato['Nombre_CategoriaCredito_R'])){
        columnas.push(dato['Nombre_CategoriaCredito_R']);
      }
    }
    for(const dato of dataArr){
      if(!rows.includes(dato['Renglon'])){
        rows.push(dato['Renglon']);
      }                                       
    }
    this.total_diagonal = 0;
    this.total_abajodiagonal = 0;
    this.total_arribadiagonal = 0;
    this.total_diagonalII = 0;
    this.total_abajodiagonalII = 0;
    this.total_arribadiagonalII = 0;
    let dataSource_table = [];
    let dataSource_tableII = [];
    for( let i = 0; i < rows.length;i++){
      let aux_arr = [];
      let aux_arrII = [];
      let total:number = 0;
      let totalII:number = 0;

      this.total_diagonal = 0;
      this.total_abajodiagonal = 0;
      this.total_arribadiagonal = 0;
      this.total_diagonalII = 0;
      this.total_abajodiagonalII = 0;
      this.total_arribadiagonalII = 0;
      for (const data of dataArr){
        let saldo = 0;
        let numeroRegistros = 0;
        
        if (!data['Saldo']){saldo = 0}else{saldo = data['Saldo'];}
        if (!data['NumeroRegistros']){numeroRegistros = 0}else{numeroRegistros = data['NumeroRegistros'];}

        if(data['Renglon'] == data['Columna']){
          this.total_diagonal += saldo;
          this.total_diagonalII += numeroRegistros;
        }
        if(data['Renglon'] > data['Columna']){
          this.total_abajodiagonal += saldo;
          this.total_abajodiagonalII += numeroRegistros;
        }
        if((data['Renglon'] +1) < (data['Columna']+1)){
          //console.log(this.total_arribadiagonal + "+= " + saldo);
          this.total_arribadiagonal += saldo;
          this.total_arribadiagonalII += numeroRegistros;
        }

        if(data['Renglon'] == (i + 1 )){                                                                                                                                        
          aux_arr.push(saldo);
          total = total + saldo;
          if(numeroRegistros == 0)
            aux_arrII.push("");
          else
          aux_arrII.push(numeroRegistros);
          totalII = totalII + numeroRegistros;
        }
      }
      


      dataSource_table.push({"name":columnas[i],"A1":aux_arr[0],"A2":aux_arr[1],"B1":aux_arr[2],"B2":aux_arr[3],"B3":aux_arr[4],"C1":aux_arr[5],"C2":aux_arr[6],"D":aux_arr[7],"E":aux_arr[8],"total":this.formatearnormal(total.toFixed(2))});
      let cantidad = this.formatearnormalCredito(totalII.toFixed(0));
      if(cantidad == "0"){
        cantidad = "";
      }
      dataSource_tableII.push({"name":columnas[i],"A1":aux_arrII[0],"A2":aux_arrII[1],"B1":aux_arrII[2],"B2":aux_arrII[3],"B3":aux_arrII[4],"C1":aux_arrII[5],"C2":aux_arrII[6],"D":aux_arrII[7],"E":aux_arrII[8],"total":cantidad});
      total = 0;
      totalII = 0;
      
    }

    this.dataSource = dataSource_table;
    this.displayedColumns = columnas;    
    this.displayedColumns.unshift("name");
    this.displayedColumns.push("total");
    //this.dataSource.push({"name":"Total","A1":this.sumaColumna("A1"),"A2":aux_arr[1],"B1":aux_arr[2],"B2":aux_arr[3],"B3":aux_arr[4],"C1":aux_arr[5],"C2":aux_arr[6],"D":aux_arr[7],"E":aux_arr[8],"total":FuncionesRiesgo.formatearnormal(total)})
    this.sumaColumna(dataSource_table,dataSource_tableII);
  }
  sumaColumna(dataSource_table,dataSource_tableII){
    //console.log("dimension--->  " + dataSource_table.length)
    let array_total = [0,0,0,0,0,0,0,0,0];
    let array_totalII = [0,0,0,0,0,0,0,0,0];
    for( const data of dataSource_table){
      array_total[0] = array_total[0] + data['A1']
      array_total[1] = array_total[1] + data['A2']
      array_total[2] = array_total[2] + data['B1']
      array_total[3] = array_total[3] + data['B2']
      array_total[4] = array_total[4] + data['B3']
      array_total[5] = array_total[5] + data['C1']
      array_total[6] = array_total[6] + data['C2']
      array_total[7] = array_total[7] + data['D']
      array_total[8] = array_total[8] + data['E']
    }
    for( const data of dataSource_tableII){
      array_totalII[0] = array_totalII[0] + Number(data['A1'])
      array_totalII[1] = array_totalII[1] + Number(data['A2'])
      array_totalII[2] = array_totalII[2] + Number(data['B1'])
      array_totalII[3] = array_totalII[3] + Number(data['B2'])
      array_totalII[4] = array_totalII[4] + Number(data['B3'])
      array_totalII[5] = array_totalII[5] + Number(data['C1'])
      array_totalII[6] = array_totalII[6] + Number(data['C2'])
      array_totalII[8] = array_totalII[8] + Number(data['E'])
      array_totalII[7] = array_totalII[7] + Number(data['D'])
    }
    this.array_total = array_total;
    this.array_totalII = array_totalII;
    dataSource_table.push({"name":"Total","A1":array_total[0].toFixed(2),"A2":array_total[1].toFixed(2),"B1":array_total[2].toFixed(2),"B2":array_total[3].toFixed(2),"B3":array_total[4].toFixed(2),"C1":array_total[5].toFixed(2),"C2":array_total[6].toFixed(2),"D":array_total[7].toFixed(2),"E":array_total[8].toFixed(2),"total":this.formatearnormal((this.total_diagonal + this.total_abajodiagonal + this.total_arribadiagonal).toFixed(2))})
    dataSource_tableII.push({"name":"Total","A1":array_totalII[0],"A2":array_totalII[1],"B1":array_totalII[2],"B2":array_totalII[3],"B3":array_totalII[4],"C1":array_totalII[5],"C2":array_totalII[6],"D":array_totalII[7],"E":array_totalII[8],"total":this.formatearnormalCredito(this.total_diagonalII + this.total_abajodiagonalII + this.total_arribadiagonalII)})
    this.dataSource = dataSource_table;
    this.dataSourceII = dataSource_tableII;
  }
  /* funciones para los filtros */
  //AÑOS
  public anioChangeListener(anioClick: object): void {
    this.ult_anio = [];
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(anioClick, this.aniosDisponibles);
    const anio_selecc = this.aniosDisponibles.find(anio => anio.name === anioClick['name']);
    this.ult_anio.push(anio_selecc.name)
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
    this.matrizTransicionEntity.Periodo = this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].value;
    let anio = this.matrizTransicionEntity.Periodo.toString().substr(-4);
    let ult_mes = this.matrizTransicionEntity.Periodo.toString();
    let mes = Number(ult_mes.toString().replace(anio,""));
    this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    let aux_mes = mes;
    if(mes == 1){
      mes = 12;
      anio = (Number(anio) - 1).toString();
    }else{
      mes -= 1;
    }
    this.matrizTransicionEntity.PeriodoAnterior = Number(mes.toString().concat(anio));
    this.matrizTransicionEntity.Id_Producto = null;
    this.matrizTransicionEntity.Id_Zona = null;
    this.Funtion_matrizTransicion(this.matrizTransicionEntity);
    this.subtituloGrafica = anio.substring(2,4) + " | " + CommonVariables.f_switchmesEntero(aux_mes.toString()) + " | matriz";
    this.subtituloGraficaII = anio.substring(2,4) + " | " + CommonVariables.f_switchmesEntero(aux_mes.toString()) + " | matriz";
    this.mesAnio = CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | " +  anio.substring(2,4);
  }
  public VerificarAniosSeleccionados(): string {
    return this.puedeFiltrarPorMeses ? 'w3-third' : 'w3-third w3-disabled';
  }
  // MESES
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    //console.log("mesChangeListener---->  " + JSON.stringify(mesClick))
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(mesClick, elementArray);
    this.ult_mes = [];
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']== true){
        this.ult_mes.push(valor['value'])
      }
    }
    this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    //console.log("mesChangeListener --->   " + JSON.stringify(this.mesSeleccionado))
    this.matrizTransicionEntity.Periodo = this.ult_mes[0];
    let anio = this.ult_mes.toString().substr(-4);
    let mes = CommonVariables.f_switchmesEntero(Number(this.ult_mes.toString().replace(anio,"")));
    //console.log("mesChangeListener --->   " + JSON.stringify(this.mesSeleccionado) + "    " + mes + "    " + this.ult_mes.toString().replace(anio,""))
    let aux_mes = this.ult_mes.toString().replace(anio,"");
    if(mes == 1){
      mes = 12;
      anio = (Number(anio) - 1).toString();
    }else{
      mes -= 1;
    }
    //console.log("mesChangeListener --->   " + Number(mes.toString().concat('',anio)))
    this.matrizTransicionEntity.PeriodoAnterior = Number(mes.toString().concat('',anio));
    this.matrizTransicionEntity.Id_Producto = null;
    this.matrizTransicionEntity.Id_Zona = null;
    this.Funtion_matrizTransicion(this.matrizTransicionEntity);
    this.subtituloGrafica = anio.substring(2,4) + " | " + CommonVariables.f_switchmesm(aux_mes.toString()) + " | matriz";
    this.subtituloGraficaII = anio.substring(2,4) + " | " + CommonVariables.f_switchmesm(aux_mes.toString()) + " | matriz";
    this.mesAnio = CommonVariables.f_switchmesEntero(this.mesSeleccionado["name"]) + " | " +  anio.substring(2,4);
  }
  public mesChangeAll(valor: boolean): void{
    /*this.mesesDeAnioDisponibles.forEach(anio => anio.check = valor);
    if (this.anioSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.aniosDisponibles, false);
      // @ts-ignore
      this.anioSeleccionado.check = true;
    }
    this.puedeFiltrarPorSucursales = (this.mesesDeAnioDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorPerspectivas = false;
    this.getGraficaPorMes();
    if (this.puedeFiltrarPorSucursales) {
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }*/
  }
  // SUCURSALES
  public consolidadoChangeListener(): void {
    /*this.consolidado = !this.consolidado;
    if (!this.consolidado) {
      if (this.mesSeleccionado) {
        this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
        // @ts-ignore
        this.mesSeleccionado.check = true;
        this.getTablaPorMes();
      }
      this.getGraficaPorSucursales();
    }else {
      // Si es consolidado entonces volvemos a la grafica por mes
      this.getGraficaPorMes();
    }*/
  }
  public sucursalChangeListener(sucursalClick: object): void {
    /*if (this.mesSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
      // @ts-ignore
      this.mesSeleccionado.check = true;
    }
    this.filtrosService.changeElementCheckStatus(sucursalClick, this.sucursalesDisponibles);
    this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.filter(e => e.check).length;
    this.puedeFiltrarPorPerspectivas = (this.numeroDeSucursalesSeleccionadas === 1);
    this.getGraficaPorSucursales();
    if (this.puedeFiltrarPorPerspectivas) {
      this.sucursalSeleccionada = this.sucursalesDisponibles.find(e => e.check);
    }*/
  }
  public quitarFiltros(): void {

    var pos = 0;
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == '2022'){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false;
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i]);
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;

    this.matrizTransicionEntity.BnConIntereses = 1;
    this.matrizTransicionEntity.Id_Producto = null;
    this.matrizTransicionEntity.Id_Sector = null;
    this.matrizTransicionEntity.Id_Zona = null;
    this.FuncionZona();
    this.FuncionProducto();
    this.FuncionSector();
    this.getConsultas();
    /*this.puedeFiltrarPorPerspectivas = false;
    this.puedeFiltrarPorMeses = false;
    this.puedeFiltrarPorSucursales = false;
    this.getFiltros();
    this.mesSeleccionado = undefined;
    this.anioSeleccionado = undefined;
    this.sucursalSeleccionada = undefined;
    this.consolidado = true;*/
  }
  /* funciones para los filtros */

  /* Otras funciones */
  MostrarOcultarTotalSaldosMayoresCero(num){
    if(num == 0){
      return "w3-hide";
    }else{
      return "w3-show";
    }

  }
  MostrarOcultarSaldosMayoresCero(i){
    //console.log("comparación --->   " + this.array_total[i] + " ==== 0 " + i )
    if(this.array_total[i] == 0){
      return "w3-hide"
    }else{
      return "w3-show";
    }
  }
  PintarCelda(x,y){
    if (y == 9)
      return "";
    if(x == y){
      return "w3-light-gray"
    }
    if(x > y){
      return "w3-orange2"
    }
    if(x < y){
      return "w3-light-green2"
    }
  }
  PintarBorder(x,y){
    if (y == 9)
      return "";
    if(x == y){
      return "w3-border-azul2"
    }
    if(x > y){
      return "w3-border-naranjaa"
    }
    if(x < y){
      return "w3-border-verdee2"
    }
  }
  formatearnormal(num){
    let cantidad = FuncionesRiesgo.formatearnormal(num);
    if(cantidad == "0.00"){
      cantidad ="0";
    }
    return cantidad;
  }
  formatearnormalCredito(num){
    let cantidad = FuncionesRiesgo.formatearnormalCredito(num);
    //console.log("cantidad creditos ---->   " + cantidad)
    if(cantidad == "0"){
      cantidad = "0";
    }
    return cantidad;
  }
  public AmpliarGraficaC1(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Matriz de transición monto";
    this.subtitulomodal = this.subtituloGrafica;
    this.tablamonto_modal = true;
  }
  public AmpliarGraficaC2(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Matriz de transición créditos";
    this.subtitulomodal = this.subtituloGrafica;
    this.tablacreditos_modal = true;
  }
  hideGraficasModal(){
    this.tablamonto_modal = false;
    this.tablacreditos_modal = false;
  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  exportar_csv(tipo){
    if(tipo == "monto"){
      var aux = [];
      this.data_excel = [];
      for (const ele of this.dataSource){
        let aux = [];
        aux.push(ele["name"])
        aux.push(this.formatearnormal(ele["A1"]));
        aux.push(this.formatearnormal(ele["A2"]));
        aux.push(this.formatearnormal(ele["B1"]));
        aux.push(this.formatearnormal(ele["B2"]));
        aux.push(this.formatearnormal(ele["B3"]));
        aux.push(this.formatearnormal(ele["C1"]));
        aux.push(this.formatearnormal(ele["C2"]));
        aux.push(this.formatearnormal(ele["D"]));
        aux.push(this.formatearnormal(ele["E"]));
        aux.push(this.formatearnormal(ele["total"]));
        this.data_excel.push(aux)
      }
      //console.log("encabezados --->   " + JSON.stringify(this.dataSource));
      this.excelService.generatoExcelMatrizTransicionMonto(this.displayedColumns,this.data_excel,this.mesSeleccionado["name"]+"-"+this.mesSeleccionado["year"]);
    }
    if(tipo == "creditos"){
      var aux = [];
      this.data_excel = [];
      for (const ele of this.dataSourceII){
        let aux = [];
        aux.push(ele["name"])
        aux.push(this.formatearnormal(ele["A1"]));
        aux.push(this.formatearnormal(ele["A2"]));
        aux.push(this.formatearnormal(ele["B1"]));
        aux.push(this.formatearnormal(ele["B2"]));
        aux.push(this.formatearnormal(ele["B3"]));
        aux.push(this.formatearnormal(ele["C1"]));
        aux.push(this.formatearnormal(ele["C2"]));
        aux.push(this.formatearnormal(ele["D"]));
        aux.push(this.formatearnormal(ele["E"]));
        aux.push(this.formatearnormal(ele["total"]));
        this.data_excel.push(aux)
      }
      //console.log("encabezados --->   " + JSON.stringify(this.dataSource));
      this.excelService.generatoExcelMatrizTransicionCredito(this.displayedColumns,this.data_excel,this.mesSeleccionado["name"]+"-"+this.mesSeleccionado["year"]);
    }
  }
  

}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'}
];
