import html2canvas from 'html2canvas';
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";

//pdfMake.vfs = pdfFonts.pdfMake.vfs;


export class PDF{
    static docDefinition: any;
    static exportimagenes(grafica,name){
      var cadena = "Some description Entidad " ;

      //console.log("Entrando a exportar IMG -----> " + id + " <------ ");
      setTimeout(() => {
      // Charts are now rendered
      
      const chart = document.getElementById(grafica);
      console.log(chart);
      html2canvas(chart, {
          //height: 500,
          //width: 1000,
          scale: 2,
          backgroundColor: 'white',
          logging: false,
          onclone: (document) => {
          //document.getElementById(id).style.visibility = 'visible';
          }
      }).then((canvas) => {
          // Get chart data so we can append to the pdf
        const chartData = canvas.toDataURL('image/PNG');
          
        var a = document.createElement('a');
        a.href = chartData;
        a.download = name+'.png'
        a.click();
      });
      }, 100);

  }
    static exportarpdf(grafica){
        var cadena = "Some description Entidad " ;

        //console.log("Entrando a exportar IMG -----> " + id + " <------ ");
        setTimeout(() => {
        // Charts are now rendered
        const chart = document.getElementById(grafica);
        html2canvas(chart, {
            height: 500,
            width: 1000,
            scale: 2,
            backgroundColor: null,
            logging: false,
            onclone: (document) => {
            //document.getElementById(id).style.visibility = 'visible';
            }
        }).then((canvas) => {
            // Get chart data so we can append to the pdf
            const chartData = canvas.toDataURL();
            // Prepare pdf structure
            
            const docDefinition = { content: [],
            styles: {
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
                alignment: 'left'
            },
            subsubheader: {
                fontSize: 12,
                italics: true,
                margin: [0, 10, 0, 25],
                alignment: 'left'
            },
            table: {
                margin: [0, 5, 0, 15]
            }
            },
            defaultStyle: {
            alignment: 'justify'
            },
            pageOrientation: 'portrait',  // vertical
            //pageOrientation: 'landscape', // horizontal
        };

        // Add some content to the pdf
        var title = {text: 'Afiliación de socios', style: 'subheader'};
        cadena = "La colocación, o también llamada colocación bancaria, permite la puesta de dinero en circulación en la economía, es decir, la banca genera un nuevo dinero del capital o los recursos que obtiene a través de la captación y, con estos recursos, se otorgan créditos a las personas, empresas u organizaciones que los soliciten. Por dar estos préstamos el banco cobra unas cantidades de dinero que se llaman intereses, o intereses de colocación, y comisiones.";
        var description = {text: cadena, style: 'subsubheader'};
        docDefinition.content.push(title);
        docDefinition.content.push(description);
        // Push image of the chart
        
        // Push image of the chart
        docDefinition.content.push({image: chartData, width: 500,alignment: "center"});
        docDefinition.content.push({image: chartData, width: 500,alignment: "center"});

        // vamos aquí ---->
        this.docDefinition = docDefinition;

        pdfMake.createPdf(docDefinition).download('chartToPdf' + '.pdf');
        });
        }, 100);

    }
    public static cad_text = "Se presenta la información de la gráfica del crecimiento de socios por medio de las sucursales contenidas en la institución en el año 2019 y mes de septiembre";
    static exportarpdfRow(grafica){
        var cadena = "Some description Entidad " ;

        //console.log("Entrando a exportar IMG -----> " + id + " <------ ");
        setTimeout(() => {
        // Charts are now rendered
        const chart = document.getElementById(grafica);
        html2canvas(chart, {
            height: 400,
            width: 800,
            scale: 6,
            backgroundColor: null,
            logging: false,
            onclone: (document) => {
            }
        }).then((canvas) => {
            // Get chart data so we can append to the pdf
            const chartData = canvas.toDataURL();
            // Prepare pdf structure
            console.log("Dentro creación de la imagen exportarpdfRow  --->  "+ chartData);
            const docDefinition = { content: [],
                styles: {
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    alignment: 'left'
                },
                subsubheader: {
                    fontSize: 12,
                    italics: true,
                    margin: [0, 10, 0, 25],
                    alignment: 'left'
                },
                table: {
                    margin: [0, 5, 0, 15]
                }
                },
                defaultStyle: {
                alignment: 'justify'
                },
                pageOrientation: 'portrait',  // vertical
                //pageOrientation: 'landscape', // horizontal
            };

        // Add some content to the pdf
        var title = {text: 'Afiliación de socios', style: 'subheader'};
        cadena = "La colocación, o también llamada colocación bancaria, permite la puesta de dinero en circulación en la economía, es decir, la banca genera un nuevo dinero del capital o los recursos que obtiene a través de la captación y, con estos recursos, se otorgan créditos a las personas, empresas u organizaciones que los soliciten. Por dar estos préstamos el banco cobra unas cantidades de dinero que se llaman intereses, o intereses de colocación, y comisiones.\n" + this.cad_text;
        var description = {text: cadena, style: 'subsubheader'};
        docDefinition.content.push(title);
        docDefinition.content.push(description);
        // Push image of the chart
        
        // Push image of the chart
        docDefinition.content.push({image: chartData, width: 500,alignment: "center"});
        docDefinition.content.push({image: chartData, width: 500,alignment: "center"});

        // vamos aquí ---->
        this.docDefinition = docDefinition;

        console.log("Dentro creación de la imagen PDF ---> " + JSON.stringify(this.docDefinition));    

        pdfMake.createPdf(docDefinition).download('chartToPdf' + '.pdf');
        });
        }, 100);
    }

    static exportarpdfRowPrueba(grafica,img1,img2){
      var cadena = "Some description Entidad " ;
      this.docDefinition = [];
      const docDef = { content: [],
        styles: {
        tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
        },
        subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
            alignment: 'left'
        },
        subsubheader: {
            fontSize: 12,
            italics: true,
            margin: [0, 10, 0, 25],
            alignment: 'left'
        },
        table: {
            margin: [0, 5, 0, 5]
        }
        },
        defaultStyle: {
        alignment: 'justify'
        },
        pageOrientation: 'portrait',  // vertical
        //pageOrientation: 'landscape', // horizontal
      };
      var title = {text: 'Afiliación de socios', style: 'subheader'};
      cadena = "La colocación, o también llamada colocación bancaria, permite la puesta de dinero en circulación en la economía, es decir, la banca genera un nuevo dinero del capital o los recursos que obtiene a través de la captación y, con estos recursos, se otorgan créditos a las personas, empresas u organizaciones que los soliciten. Por dar estos préstamos el banco cobra unas cantidades de dinero que se llaman intereses, o intereses de colocación, y comisiones.\n" + this.cad_text;
      var description = {text: cadena, style: 'subsubheader'};
      docDef.content.push(title);
      docDef.content.push(description);

      docDef.content.push({image: img1, width: 450,height:200,alignment: "center"});
      description = {text: cadena + " ----> otro parrafo ", style: 'subsubheader'};
      docDef.content.push(description);
     
      //docDef.content.push({image: img2, width: 390,height:330,alignment: "center"});
      docDef.content.push({image: img2, width: 290,height:230,alignment: "center"});
      this.docDefinition = docDef;
      console.log("Dentro creación de la imagen PDF ---> " + JSON.stringify(this.docDefinition));
      pdfMake.createPdf(this.docDefinition).download('pruebapdf' + '.pdf');

    }
    static exportarpdfTwoThird(grafica){
        var cadena = "Some description Entidad " ;
        //console.log("Entrando a exportar IMG -----> " + id + " <------ ");
        setTimeout(() => {
        // Charts are now rendered
        const chart = document.getElementById(grafica);
        html2canvas(chart, {
            height: 250,
            width: 1000,
            scale: 2,
            backgroundColor: null,
            logging: false,
            onclone: (document) => {
            //document.getElementById(id).style.visibility = 'visible';
            }
        }).then((canvas) => {
            // Get chart data so we can append to the pdf
            const chartData = canvas.toDataURL();
            // Prepare pdf structure
            const docDefinition = { content: [],
            styles: {
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
                alignment: 'left'
            },
            subsubheader: {
                fontSize: 12,
                italics: false,
                margin: [0, 10, 0, 25],
                alignment: 'left'
            },
            table: {
                margin: [0, 5, 0, 15]
            }
            },
            defaultStyle: {
            alignment: 'justify'
            },
            pageOrientation: 'portrait',  // vertical
            //pageOrientation: 'landscape', // horizontal
        };

        // Add some content to the pdf
        var title = {text: 'Afiliación de socios', style: 'subheader'};
        cadena = "La colocación, o también llamada colocación bancaria, permite la puesta de dinero en circulación en la economía, es decir, la banca genera un nuevo dinero del capital o los recursos que obtiene a través de la captación y, con estos recursos, se otorgan créditos a las personas, empresas u organizaciones que los soliciten. Por dar estos préstamos el banco cobra unas cantidades de dinero que se llaman intereses, o intereses de colocación, y comisiones.";
        var description = {text: cadena, style: 'subsubheader'};
        docDefinition.content.push(title);
        docDefinition.content.push(description);
        // Push image of the chart
        
        // Push image of the chart
        docDefinition.content.push({image: chartData, width: 500, height:200 ,alignment: "center"});
        var description2 = {text: " Segundo Parrafo " + cadena, style: 'subsubheader'};
        docDefinition.content.push(description2);
        docDefinition.content.push({image: chartData, width: 500, height:200, alignment: "center"});

        // vamos aquí ---->
        this.docDefinition = docDefinition;

        pdfMake.createPdf(docDefinition).download('chartToPdf' + '.pdf');
        });
        }, 100);

    }
    static exportarpdfThird(grafica){
        var cadena = "Some description Entidad " ;
        //console.log("Entrando a exportar IMG -----> " + id + " <------ ");
        setTimeout(() => {
        // Charts are now rendered
        const chart = document.getElementById(grafica);
        html2canvas(chart, {
            height: 300,
            width: 500,
            scale: 2,
            backgroundColor: null,
            logging: false,
            onclone: (document) => {
            //document.getElementById(id).style.visibility = 'visible';
            }
        }).then((canvas) => {
            // Get chart data so we can append to the pdf
            const chartData = canvas.toDataURL();
            // Prepare pdf structure
            const docDefinition = { content: [],
            styles: {
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
                alignment: 'left'
            },
            subsubheader: {
                fontSize: 12,
                italics: false,
                margin: [0, 10, 0, 25],
                alignment: 'left'
            },
            table: {
                margin: [0, 5, 0, 15]
            }
            },
            defaultStyle: {
            alignment: 'justify'
            },
            pageOrientation: 'portrait',  // vertical
            //pageOrientation: 'landscape', // horizontal
        };

        // Add some content to the pdf
        var title = {text: 'Afiliación de socios', style: 'subheader'};
        cadena = "La colocación, o también llamada colocación bancaria, permite la puesta de dinero en circulación en la economía, es decir, la banca genera un nuevo dinero del capital o los recursos que obtiene a través de la captación y, con estos recursos, se otorgan créditos a las personas, empresas u organizaciones que los soliciten. Por dar estos préstamos el banco cobra unas cantidades de dinero que se llaman intereses, o intereses de colocación, y comisiones.";
        var description = {text: cadena, style: 'subsubheader'};
        docDefinition.content.push(title);
        docDefinition.content.push(description);
        // Push image of the chart
        
        // Push image of the chart
        docDefinition.content.push({image: chartData, width: 500, height:200 ,alignment: "center"});
        var description2 = {text: " Segundo Parrafo " + cadena, style: 'subsubheader'};
        docDefinition.content.push(description2);
        docDefinition.content.push({image: chartData, width: 500, height:200, alignment: "center"});

        // vamos aquí ---->
        this.docDefinition = docDefinition;

        pdfMake.createPdf(docDefinition).download('chartToPdf' + '.pdf');
        });
        }, 100);

    }
}

declare let pdfMake: any ;
export class PDFSecond{
    static getDocumentDefinition(archivo,img) {
        sessionStorage.setItem('resume', JSON.stringify(archivo));
        return {
          content: [
            {
              text: 'Reporte Afiliación',
              bold: true,
              fontSize: 20,
              alignment: 'center',
              margin: [0, 0, 0, 20]
            },
            {
              columns: [
                [{
                  text: archivo.name,
                  style: 'name'
                },
                {
                  text: archivo.address
                },
                {
                  text: 'Email : ' + archivo.email,
                },
                {
                  text: 'Contant No : ' + archivo.contactNo,
                },
                {
                  text: 'GitHub: ' + archivo.socialProfile,
                  link: archivo.socialProfile,
                  color: 'blue',
                },
                {
                  text: 'Aquí irá la imagen de la gráfica ' + archivo.socialProfile,
                }
                ],
                /*[
                  img
                ]*/
              ]
            },
            {
              text: 'Skills',
              style: 'header'
            },
            {
              columns : [
                {
                  ul : [
                    ...archivo.skills.filter((value, index) => index % 3 === 0).map(s => s.value)
                  ]
                },
                {
                  ul : [
                    ...archivo.skills.filter((value, index) => index % 3 === 1).map(s => s.value)
                  ]
                },
                {
                  ul : [
                    ...archivo.skills.filter((value, index) => index % 3 === 2).map(s => s.value)
                  ]
                }
              ]
            },
            {
              text: 'Experience',
              style: 'header'
            },
            //this.getExperienceObject(archivo.experiences),
    
            {
              text: 'Education',
              style: 'header'
            },
            //this.getEducationObject(archivo.educations),
            {
              text: 'Other Details',
              style: 'header'
            },
            {
              text: archivo.otherDetails
            },
            {
              text: 'Signature',
              style: 'sign'
            },
            {
              columns : [
                  { qr: archivo.name + ', Contact No : ' + archivo.contactNo, fit : 100 },
                  {
                  text: `(${archivo.name})`,
                  alignment: 'right',
                  }
              ]
            }
          ],
          info: {
            title: archivo.name + '_RESUME',
            author: archivo.name,
            subject: 'RESUME',
            keywords: 'RESUME, ONLINE RESUME',
          },
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 20, 0, 10],
                decoration: 'underline'
              },
              name: {
                fontSize: 16,
                bold: true
              },
              jobTitle: {
                fontSize: 14,
                bold: true,
                italics: true
              },
              sign: {
                margin: [0, 50, 0, 10],
                alignment: 'right',
                italics: true
              },
              tableHeader: {
                bold: true,
              }
            }
        };
      }
    
}