import { FiltroAnio } from '../model/filtro-anio';
import { FiltroMes } from '../model/filtro-mes';

export class RangoFecha {

    public range: {
        Time_Id: {
            format: String,
            gte: String,
            lte: String
        }
    };

    public ranges: [
        {
            from: string,
            to: string
        }
    ];

    formatearFecha(anio: FiltroAnio, mes: FiltroMes): string {

        let anioCadena = anio.cadena;
        let mesCadena = mes.valor.toString().length == 1 ? '0' + mes.valor.toString() : mes.valor.toString();

        return anioCadena + '-' + mesCadena;
    }

    setRango(anios, meses): any {

        anios.forEach(anio => {

            meses.forEach(mes => {

                let fechaInicial = this.formatearFecha(anio, mes);
                let fechaFinal = this.formatearFecha(anio, mes + 1);

                this.range = {
                    Time_Id: {
                        format: 'yyyy-MM',
                        gte: fechaInicial,
                        lte: fechaFinal
                    }
                }

            });

        });

        return this.range;
    }

    setRangos(anios, meses): any {

        anios.forEach(anio => {

            meses.forEach(mes => {

                let fechaInicial = this.formatearFecha(anio, mes);
                let fechaFinal = this.formatearFecha(anio, mes + 1);

                this.range = {
                    Time_Id: {
                        format: 'yyyy-MM',
                        gte: fechaInicial,
                        lte: fechaFinal
                    }
                }

            });

        });

        return this.range;
    }

    constructor() { }

}
