import { MessageService } from '../services/base/message.service';
import { Injectable } from '@angular/core';

export class ConstantesQubos  {
    constructor() {
    }
    public static strPATH = 'app';
    public static strDashboardPATH = 'app/dashboards';
}

export class CommonVariables {
    static get MesesArr() { return ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']; }

    static get MesesJson() { return [
        { mes: 'ene', value: 1 },
        { mes: 'feb', value: 2 },
        { mes: 'mar', value: 3 },
        { mes: 'abr', value: 4 },
        { mes: 'may', value: 5 },
        { mes: 'jun', value: 6 },
        { mes: 'jul', value: 7 },
        { mes: 'ago', value: 8 },
        { mes: 'sep', value: 9 },
        { mes: 'oct', value: 10 },
        { mes: 'nov', value: 11 },
        { mes: 'dic', value: 12 },
    ]; }
}

export class CommonFunctions {
    static getActivoIcon(bnActivo: boolean) {
        return (null == bnActivo) ? 'more_horiz'
              : bnActivo ? 'done' : 'block';
    }

    static getBnFromString(strValue: string): boolean {
        return (
                null == strValue
                || undefined === strValue
                || 'false' === strValue.toLowerCase().trim()
                || '0' === strValue.toLowerCase().trim()
              ) ? false : true;
    }

    static chkUniqueItemInArrayService(items: any[], logger?: Loggers): boolean {
        if (null == items) {
            if (logger) { logger.logError('Error en servicio de consulta', true); }
            return false;
        }
        if (items.length === 0) {
          if (logger) { logger.logError('No existen datos con esa clave', true); }
            return false ;
        }
        if (items.length > 1) {
          if (logger) { logger.logError('Error, existen varios registros con esa clave, consulte a un administrador', true); }
          return false;
        }
        return true;
    }

    static chkEmptyItemInArrayService(items: any[], logger?: Loggers): boolean {
      if (null == items) {
          if (logger) { logger.logError('Error en servicio de consulta', true); }
          return false;
      }
      if (items.length === 1) {
        if (logger) { logger.logError('Ya existen datos con esa clave', true); }
          return false ;
      }
      if (items.length > 1) {
        if (logger) { logger.logError('Error, existen varios registros con esa clave, consulte a un administrador', true); }
        return false;
      }
      return true;
  }
    static retornarformatolefty(valor): string{
        if (valor >= 1000000000000){
            valor =  valor / 1000000000 + ' Billón -';
        }else{
            if (valor >= 1000000000){
                valor =  valor / 1000000000 + ' Mil Millones -';
            }else{
                if (valor >= 1000000){
                    valor =  valor / 1000000 + ' Millones -';
                }else{
                    if (valor >= 10000){
                        valor =  valor / 1000 + ' Mil -';
                    }else{
                        if (valor >= 1000){
                            valor = valor / 1000 + ' Mil -';
                        }
                    }
                }
            }
        }
        return valor;
    }


}


@Injectable({
  providedIn: 'root'
})
export class Loggers {
    constructor (protected messageService: MessageService) { }

    //Log a Service message with the MessageService
    log(message: string, show: boolean) {
        //console.log(message);
        if (show) {
            this.messageService.subject.next(message);
        }
    }

    //Log a Service message with the MessageService
    logError(message: string, show: boolean) {
        console.log(message);
        if (show) {
            this.messageService.subjectError.next(message);
        }
    }
}

export class FuncionesScaleMax{
    public static BarScaleMax(arreglo){
        var num = arreglo[0].value;
        for(var i = 1; i < arreglo.length;i++){
            //console.log(" ---->  " + arreglo[i].value + " ---> " + num);
            if(arreglo[i].value > num){
                num = arreglo[i].value;
            }
        }
        //num = num + 1000;
        if(num < 1){
            num = num * 2;
        }else{
            //num = num*1.3;
            num = (num/2)*2.1;
        }

        //console.log("yScaleMax Mayor ---> " + num + " round ---> " + this.BuscarDecimas(num) + " ---> * 1.2 " + this.BuscarDecimas(num) + " --->  " + arreglo[0].value );
        return this.BuscarDecimas(num);
    }
    public static MultiScaleMax(arreglo){
        var num = 0;
        //console.log("MultiScaleMax ---> " + JSON.stringify(arreglo));
        if(arreglo[0].series){
            num = arreglo[0].series[0].value;
            for(var i = 0; i < arreglo.length;i++){
                for(var j = 0; j < arreglo[i].series.length;j++){
                    //console.log("yScaleMaxT2C1 multi ---> " + arreglo[i].series[j].value + " ---- " + num);
                    if(arreglo[i].series[j].value > num){
                        num = arreglo[i].series[j].value;
                    }
                }
            }
        }else{
            num = arreglo[0].value;
            for(var i = 0; i < arreglo.length;i++){
                for(var j = 0; j < arreglo[i].length - 1;j++){
                    if(arreglo[j].value > num){
                        num = arreglo[j].value;
                    }
                }
            }
        }
        num = num*1.3;
        //console.log("yScaleMaxT2C1 multi yScaleMax Mayor ---> " + num + " round ---> " + this.BuscarDecimas(num) + " ---> * 1.2 " + this.BuscarDecimas(num)*1.2);
        return this.BuscarDecimas(num);
    }
    public static BuscarDecimas(valor){
        if(valor >= 1000000){
            valor = Math.ceil(valor/1000000);
            valor = valor*1000000;
        }else{
            if(valor >= 10000){
                valor = Math.ceil(valor/1000);
                valor = valor*1000;
            }else{
                if(valor >= 1000){
                    valor = Math.ceil(valor/1000);
                    valor = valor*1000;
                    if(valor <= 3000){
                        valor += 1000;
                    }
                }else{
                    if(valor >= 100){
                        valor = Math.ceil(valor/100);
                        valor = valor*100;
                    }else{
                        if(valor >= 10){
                            valor = Math.ceil(valor/10);
                            valor = valor*10;
                        }
                    }
                }
            }
        }
        return valor;
    }
    public static BuscarDecimas2(valor){
        if(valor >= 1000000){
            valor = (valor/1000000).toFixed(2);
            valor = valor*1000000;
        }else{
            if(valor >= 10000){
                valor = (valor/1000).toFixed(2);
                valor = valor*1000;
            }else{
                if(valor >= 1000){
                    valor = (valor/1000).toFixed(2);
                    valor = valor*1000;
                }else{
                    if(valor >= 100){
                        valor = (valor/100).toFixed(2);
                        valor = valor*100;
                    }else{
                        if(valor >= 10){
                            valor = (valor/10).toFixed(2);
                            valor = valor*10;
                        }
                    }
                }
            }
        }
        return valor;
    }
}
