// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
  production: false
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
const com = 'comercial/';
const colo = 'cartera/';
const geo = 'geografico/';
const finan = 'financiero/';
const riesgos = 'riesgos/';
const informes = 'informes/';
const captacion = 'captacion/';
export const environment = {
  production: false,
  // baseUrl: 'http://localhost:8080/qubos/app',
  // baseUrl: 'https://rfl-crm.mybluemix.net/rfl/api/v1/busquedageneral',
  baseUrl: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/',
  // imgPath: '../assets/img/'

  modules: [
    { identifier: 'ale-comercial', name: 'Comercial', route: '', submodules: [], module: '' },
    { identifier: 'ale-captación', name: 'Captación', route: '', submodules: [], module: '' },
    { identifier: 'ale-colocación', name: 'Cartera', route: '', submodules: [], module: '' },
    { identifier: 'ale-financiero', name: 'Financiero', route: '', submodules: [], module: '' },
    { identifier: 'ale-geográfico', name: 'Geográfico', route: '', submodules: [], module: '' },
    { identifier: 'ale-riesgodecrédito', name: 'Riesgo de crédito', route: '', submodules: [], module: '' },
    { identifier: 'ale-reportes', name: 'Informes', route: '', submodules: [], module: '' },
  ],

  submodules: [
    //{ identifier: 'ale-perfil', name: 'Perfil', route: com + 'perfil', module: 'Comercial' },
    //{ identifier: 'ale-tendencia', name: 'Tendencia', route: com + 'tendencia', module: 'Comercial' },
    //{ identifier: 'ale-metavsreal', name: 'Meta vs Real', route: com + 'meta-vs-real', module: 'Comercial' },
    //{ identifier: 'ale-cartera', name: 'Cartera', route: com + 'cartera', module: 'Comercial' },
    //{ identifier: 'ale-ahorro', name: 'Ahorro', route: com + 'ahorro', module: 'Comercial' },
    //{ identifier: 'ale-socios', name: 'Socios', route: com + 'socios', module: 'Comercial' },
    
    //Captación
    { identifier: 'ale-perfil', name: 'Perfil', route: captacion+ 'perfil', module: 'Captación' },
    //{ identifier: 'ale-tendencia', name: 'Tendencia', route: captacion + 'tendencia', module: 'Captación' },
    { identifier: 'ale-tendencia', name: 'Tendencia', route: captacion + 'tendenciav2', module: 'Captación' },
    { identifier: 'ale-metavsreal', name: 'Meta vs Real', route: captacion + 'meta-vs-real', module: 'Captación' },
    { identifier: 'ale-estimacionespreventivas', name: 'Estadístico', route: captacion + 'estadistico', module: 'Captación' },

    // Colocacion
    { identifier: 'ale-perfil', name: 'Perfil', route: colo + 'perfil', module: 'Cartera' },
    //{ identifier: 'ale-tendencia', name: 'Tendencia', route: colo + 'tendencia', module: 'Cartera' },
    { identifier: 'ale-metavsreal', name: 'Meta vs Real', route: colo + 'meta-vs-real', module: 'Cartera' },
    { identifier: 'ale-tendencia', name: 'Tendencia', route: colo + 'tendenciav2', module: 'Cartera' },
    { identifier: 'ale-tendencia', name: 'Estimaciones preventivas', route: colo + 'estimaciones', module: 'Cartera' },
    //{ identifier: 'ale-morosidad', name: 'Morosidad', route: colo + 'morosidad', module: 'Colocación' },
    //{ identifier: 'ale-tasaponderada', name: 'Tasa Ponderada', route: colo + 'tasa-ponderada', module: 'Colocación' },
    //{ identifier: 'ale-solicitudes', name: 'Solicitudes', route: colo + 'solicitudes', module: 'Colocación' },
    // Geográfico
    { identifier: 'ale-geo-referenciación', name: 'Geo-referenciación', route: geo + 'geo-referenciacion', module: 'Geográfico' },
    // Financiero
    //{ identifier: 'ale-razones', name: 'Razones', route: finan + 'razones', module: 'Financiero' },
    { identifier: 'ale-razones', name: 'Razones Financieras', route: finan + 'razones', module: 'Financiero' },
    //{ identifier: 'ale-balancegeneral', name: 'Balance General', route: finan + 'balance-general', module: 'Financiero' },
    { identifier: 'ale-balancegeneral', name: 'Estados Financieros', route: finan + 'balance-general', module: 'Financiero' },
    //{ identifier: 'ale-estadoresultado', name: 'Estado de Resultado', route: finan + 'estado-de-resultados', module: 'Financiero' },
    { identifier: 'ale-estadoresultado', name: 'Catálogo de Cuentas', route: finan + 'estado-de-resultados', module: 'Financiero' },
    { identifier: 'ale-rentabilidad', name: 'Rentabilidad', route: finan + 'rentabilidad', module: 'Financiero' },
    // Riesgos
    { identifier: 'ale-tendenciadepérdida', name: 'Tendencia Pérdida', route: riesgos + 'tendencia', module: 'Riesgo de crédito' },
    { identifier: 'ale-matriztransición', name: 'Matriz Transición', route: riesgos + 'matriz-transicion', module: 'Riesgo de crédito' },
    { identifier: 'ale-cosechas', name: 'Cosechas', route: riesgos + 'cosechas', module: 'Riesgo de crédito' },
    { identifier: 'ale-concentración', name: 'Concentración', route: riesgos + 'concentracion', module: 'Riesgo de crédito' },
    { identifier: 'ale-mayoresdeudores', name: 'Mayores Deudores', route: riesgos + 'mayores-deudores', module: 'Riesgo de crédito' },
    // Informes
    { identifier: 'ale-informefinanciero', name: 'Informe Financiero', route: informes + 'informefinanciero', module: 'Informes' },
    { identifier: 'ale-informeriesgos', name: 'Informe Riesgos', route: informes + 'informeriesgos', module: 'Informes' },
    { identifier: 'ale-informecartera', name: 'Informe Cartera', route: informes + 'informecartera', module: 'Informes' },
  ],

  apiDevURL: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/busquedageneral',
  apiDevURL_MR: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/busquedageneral?sinAggs=true',
  apiDevURL_Evaluacion: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/alerta-temprana/evaluacion/all?',
  apiDevURL_Cuenta: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/alerta-temprana/cuentas/all?',
  apiDevURL_Variable: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/alerta-temprana/variable/all?',
  apiDevURL_VariableTemporal: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/alerta-temprana/var-temp/all?',
  apiDevURL_Indicador: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/alerta-temprana/indicador/all?',
  apiDevURL_DetalleEvaluacionIndicador: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/alerta-temprana/detalleEvaluacionIndicador/all?',
  apiDevURL_DetalleEvaluacionCuenta: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/alerta-temprana/detalleEvaluacionCuenta/all?',
  apiDevURL_Geografico: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/geografico/municipios/?',
  apiDevURL_Estimaciones: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/busquedaEspecial',

};

