import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data-service/data.service';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import { PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import { EstimacionesService } from '../../../services/colocacion/estimaciones/estimaciones.service';

@Component({
  selector: 'app-estimaciones',
  templateUrl: './estimaciones.component.html',
  styleUrls: ['./estimaciones.component.css']
})

export class EstimacionesComponent implements OnInit {
  /*** Variables graficas */

  gradient: boolean = false;
  animations: boolean = true;

  colorScheme_balance: any = {
    domain: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6']
  };
  //public colorScheme: string = FuncionesColores.setpaleta_azul('paleta_azul');

  labelFormatting(c: any) {
    return `<h5>${(c.label)} </h5>`;
  }
  valueFormatting(c: any){
    return `${(c)}`;
  }

  public dataGraf1 = [];
  public dataGraf2 = [];
  public dataGraf3 = [];
  public dataGraf4 = [];
  public options = {
    chartArea: {
      height: '90%',
      width: '60%'
    },
    pieHole: 0.5,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6'],/*['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6'],*/
    legend: {position: 'right'},
    backgroundColor: '#fff',
  };
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /** */
  public displayedColumns: string[] = ['diasMor', 'epcr1', 'salC', 'sEpcr', 'porcen']; //['diasMor', 'epcr1', 'epcr2', 'salC', 'carT1', 'carT2', 'sEpcr'];
  public dataSource = [];
  public dataSource2 = [];
  public dataSource3 = [];
  public dataSource4 = [];

  /** Variables de años y meses */
  public aniosDisponibles: any [] = [];
  public aniosSeleccionados: any [] = [];
  public mesesDisponibles: any [] = [];
  public mesesDeAnioDisponibles: any[] = [];
  public mesesSeleccionados: any [] = [];
  public sucursalesDisponibles: any[];
  public ult_anio: any [] = [];
  public ult_mes: any [] = [];
  public aniosSelec = [];
  /** subtitulos */
  public subtitulo = '';
  public Titulo = 'Cálculo de EPRC de la cartera total';
  public Titulo1 = 'Cálculo de EPRC de la cartera de consumo';
  public Titulo2 = 'Cálculo de EPRC de la cartera de comercial';
  public Titulo3 = 'Cálculo de EPRC de la cartera de vivienda';
  public Titulo_modal = '';
  /** ampliar */
  public tabla_modal = false;
  public tabla1_modal = false;
  public tabla2_modal = false;
  public tabla3_modal = false;

  public grafi_modal = false;
  public grafi1_modal = false;
  public grafi2_modal = false;
  public grafi3_modal = false;

  private rangos1 = [{from: 0, to: 1},{from: 1, to: 8},{from: 8, to: 31},{from: 31, to: 61},{from: 61, to: 91},{from: 91, to: 121},{from: 121, to: 181},{from: 181}];
  private rangos2 = [{from: 0, to: 1},{from: 1, to: 31},{from: 31, to: 61},{from: 61, to: 91},{from: 91, to: 121},{from: 121, to: 151},{from: 151, to: 181},{from: 181, to: 211},{from: 211, to: 241},{from: 241}];
  private rangos3 = [{from: 0, to: 1},{from: 1, to: 31},{from: 31, to: 61},{from: 61, to: 91},{from: 91, to: 121},{from: 121, to: 151},{from: 151, to: 181},{from: 181, to: 1461},{from: 1461}];

  /** Variables de tablas **/
  public cartCons = true;
  public cartCome = false;
  public cartVivi = false;
  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private excelservices: ExcelService,
    public estmiacionesservice: EstimacionesService,
  ) {
    this.getFiltros();
  }

  ngOnInit(): void {
  }

  public getFiltros(){
    var ult_anio = [], ult_mes = [];
    this.filtrosService.getAniosMesesDisponibles('radiografia').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAnioMesDisponible(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesAniosDisponibles;
      ult_anio = tiempoFiltros.ult_anio;
      ult_mes = tiempoFiltros.ult_mes;
      this.ult_anio = ult_anio;
      this.ult_mes = ult_mes;
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio[0]);
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.aniosMesesSeleccionados();
      this.obtenerMeses();
      this.getInformacion();
    });
  }
  /** Obtener datos */
  public getInformacion(){
    this.subTit();
    this.estmiacionesservice.getEstGlobal([''+this.mesesSeleccionados[0].value], 'CONSUMO').subscribe(response => {
      this.obtTabla(response.message)
    });
    this.estmiacionesservice.getServicios([''+this.mesesSeleccionados[0].value], 'VIVIENDA').subscribe(response => {
      var servicios = response.message;
      this.estmiacionesservice.getEstServi([''+this.mesesSeleccionados[0].value], servicios[0].key, this.rangos1).subscribe(response =>{
        this.obtTabla1(response.message[0].result)
      });
      this.estmiacionesservice.getEstServi([''+this.mesesSeleccionados[0].value], servicios[1].key, this.rangos2).subscribe(response =>{
        this.obtTabla2(response.message[0].result)
      });
      this.estmiacionesservice.getEstServi([''+this.mesesSeleccionados[0].value], servicios[2].key, this.rangos3).subscribe(response =>{
        this.obtTabla3(response.message[0].result)
      });
    });
  }
  private clasDat(array){
    var arrayConsumo = [], arrayComercial = [], arrayVivienda = [], arrayTotal = [];
    for(const dat of array){
      if(dat.servicio === 'CONSUMO' || dat.servicio === 'CARTERA DE CONSUMO'){
        arrayConsumo.push({
          diasMora: dat.diasMora,
          estado1: dat.estado1,
          estado2: dat.estado2,
          saldoCapital: dat.saldoCapital + dat.interesesVencidos1 + dat.intVigCobrado + dat.interesMoratorio,
          servicio: dat.servicio
        });
      }else if(dat.servicio === 'COMERCIAL' || dat.servicio === 'CARTERA COMERCIAL'){
        arrayComercial.push({
          diasMora: dat.diasMora,
          estado1: dat.estado1,
          estado2: dat.estado2,
          saldoCapital: dat.saldoCapital + dat.interesesVencidos1 + dat.intVigCobrado + dat.interesMoratorio,
          servicio: dat.servicio
        });
      }else if(dat.servicio === 'VIVIENDA' || dat.servicio === 'CARTERA DE VIVIENDA'){
        arrayVivienda.push({
          diasMora: dat.diasMora,
          estado1: dat.estado1,
          estado2: dat.estado2,
          saldoCapital: dat.saldoCapital + dat.interesesVencidos1 + dat.intVigCobrado + dat.interesMoratorio,
          servicio: dat.servicio
        });
      }
      /*arrayTotal.push({
        diasMora: dat.diasMora,
        estado1: dat.estado1,
        estado2: dat.estado2,
        saldoCapital: dat.saldoCapital + dat.interesesVencidos1 + dat.intVigCobrado + dat.interesMoratorio,
        servicio: dat.servicio
      });*/
    }
    //this.obtTabla(arrayTotal);
    this.obtTabla1(arrayConsumo);
    this.obtTabla2(arrayComercial);
    this.obtTabla3(arrayVivienda);
  }
  private obtTabla(array){
    var dmor = [ '0', '1 a 7', '8 a 30', '31 a 60', '61 a 90', '91 a 120', '121 a 150', '151 a 180', '181 o más' ];
    var epc1 = [ '1', '4', '15', '30', '50', '75', '90', '90', '100' ];

    this.dataSource4 = [];
    this.dataSource4.push({dMora: dmor[0], Epcr1: epc1[0], slCap: array["0.0-1.0"].saldoCapital.value, epcrT: array["0.0-1.0"].saldoCapital.value*(Number(epc1[0])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[1], Epcr1: epc1[1], slCap: array["1.0-8.0"].saldoCapital.value, epcrT: array["1.0-8.0"].saldoCapital.value*(Number(epc1[1])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[2], Epcr1: epc1[2], slCap: array["8.0-31.0"].saldoCapital.value, epcrT: array["8.0-31.0"].saldoCapital.value*(Number(epc1[2])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[3], Epcr1: epc1[3], slCap: array["31.0-61.0"].saldoCapital.value, epcrT: array["31.0-61.0"].saldoCapital.value*(Number(epc1[3])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[4], Epcr1: epc1[4], slCap: array["61.0-91.0"].saldoCapital.value, epcrT: array["61.0-91.0"].saldoCapital.value*(Number(epc1[4])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[5], Epcr1: epc1[5], slCap: array["91.0-121.0"].saldoCapital.value, epcrT: array["91.0-121.0"].saldoCapital.value*(Number(epc1[5])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[6], Epcr1: epc1[6], slCap: array["121.0-151.0"].saldoCapital.value, epcrT: array["121.0-151.0"].saldoCapital.value*(Number(epc1[6])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[7], Epcr1: epc1[7], slCap: array["151.0-181.0"].saldoCapital.value, epcrT: array["151.0-181.0"].saldoCapital.value*(Number(epc1[7])/100), porce: 0,});
    this.dataSource4.push({dMora: dmor[8], Epcr1: epc1[8], slCap: array["181.0-*"].saldoCapital.value, epcrT: array["181.0-*"].saldoCapital.value*(Number(epc1[8])/100), porce: 0,});

    var tot1 = 0,tot2 = 0,tot3 = 0,tot4 = 0;
    this.dataSource4.forEach(dat => { tot1 += dat.slCap; tot2 += dat.cart1; tot3 += dat.cart2; tot4 += dat.epcrT; });
    this.dataGraf1 = [];
    var dmord = [ '0 días', '1 a 7 días', '8 a 30 días', '31 a 60 días', '61 a 90 días', '91 a 120 días', '121 a 150 días', '151 a 180 días', '181 o más días' ];
    for(var i = 0; i < this.dataSource4.length; i++){
      this.dataSource4[i].porce = (this.dataSource4[i].slCap*100/tot1).toFixed(2);
      this.dataGraf1.push({name:dmord[i], value: Number(this.dataSource4[i].slCap)});
    }
    this.dataSource4.push({
      dMora: 'Total',
      Epcr1: '',
      slCap: tot1,
      epcrT: tot4,
      porce: '100',
    });
  }
  private obtTabla1(array){
    this.dataSource = [];
    var dmor = [ '0', '1 a 7', '8 a 30', '31 a 60', '61 a 90', '91 a 120', '121 a 180', '181 o más' ];
    var epc1 = [ '1', '2', '10', '20', '40', '70', '85', '100' ];

    this.dataSource = [];
    this.dataSource.push({dMora: dmor[0], Epcr1: epc1[0], slCap: array["0.0-1.0"].carteraTipo1.value, epcrT: array["0.0-1.0"].carteraTipo1.value*(Number(epc1[0])/100), porce: 0,});
    this.dataSource.push({dMora: dmor[1], Epcr1: epc1[1], slCap: array["1.0-8.0"].carteraTipo1.value, epcrT: array["1.0-8.0"].carteraTipo1.value*(Number(epc1[1])/100), porce: 0,});
    this.dataSource.push({dMora: dmor[2], Epcr1: epc1[2], slCap: array["8.0-31.0"].carteraTipo1.value, epcrT: array["8.0-31.0"].carteraTipo1.value*(Number(epc1[2])/100), porce: 0,});
    this.dataSource.push({dMora: dmor[3], Epcr1: epc1[3], slCap: array["31.0-61.0"].carteraTipo1.value, epcrT: array["31.0-61.0"].carteraTipo1.value*(Number(epc1[3])/100), porce: 0,});
    this.dataSource.push({dMora: dmor[4], Epcr1: epc1[4], slCap: array["61.0-91.0"].carteraTipo1.value, epcrT: array["61.0-91.0"].carteraTipo1.value*(Number(epc1[4])/100), porce: 0,});
    this.dataSource.push({dMora: dmor[5], Epcr1: epc1[5], slCap: array["91.0-121.0"].carteraTipo1.value, epcrT: array["91.0-121.0"].carteraTipo1.value*(Number(epc1[5])/100), porce: 0,});
    this.dataSource.push({dMora: dmor[6], Epcr1: epc1[6], slCap: array["121.0-181.0"].carteraTipo1.value, epcrT: array["121.0-181.0"].carteraTipo1.value*(Number(epc1[6])/100), porce: 0,});
    this.dataSource.push({dMora: dmor[7], Epcr1: epc1[7], slCap: array["181.0-*"].carteraTipo1.value, epcrT: array["181.0-*"].carteraTipo1.value*(Number(epc1[7])/100), porce: 0,});
 
    var tot1 = 0,tot2 = 0,tot3 = 0,tot4 = 0;
    this.dataSource.forEach(dat => { tot1 += dat.slCap; tot2 += dat.cart1; tot3 += dat.cart2; tot4 += dat.epcrT; });
    var dmord = [ '0 días', '1 a 7 días', '8 a 30 días', '31 a 60 días', '61 a 90 días', '91 a 120 días', '121 a 180 días', '181 o más días' ];
    this.dataGraf2 = [];
    for(var i = 0; i < this.dataSource.length; i++){
      this.dataSource[i].porce = (this.dataSource[i].slCap*100/tot1).toFixed(2);
      this.dataGraf2.push({name:dmord[i], value: Number(this.dataSource[i].slCap)});
    }
    this.dataSource.push({
      dMora: 'Total',
      Epcr1: '',
      Epcr2: '',
      slCap: tot1,
      cart1: tot2,
      cart2: tot3,
      epcrT: tot4,
      porce: '100',
    });
  }
  private obtTabla2(array){
    var dmor = [ '0', '1 a 30', '31 a 60', '61 a 90', '91 a 120', '121 a 150', '151 a 180', '181 a 210', '211 a 240', 'Más de 240' ];
    var epc1 = [ '0.5', '2.5', '15', '30', '40', '60', '75', '85', '95', '100' ];

    this.dataSource2 = [];
    this.dataSource2.push({dMora: dmor[0], Epcr1: epc1[0], slCap: array["0.0-1.0"].carteraTipo1.value, epcrT: array["0.0-1.0"].carteraTipo1.value*(Number(epc1[0])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[1], Epcr1: epc1[1], slCap: array["1.0-31.0"].carteraTipo1.value, epcrT: array["1.0-31.0"].carteraTipo1.value*(Number(epc1[1])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[2], Epcr1: epc1[2], slCap: array["31.0-61.0"].carteraTipo1.value, epcrT: array["31.0-61.0"].carteraTipo1.value*(Number(epc1[2])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[3], Epcr1: epc1[3], slCap: array["61.0-91.0"].carteraTipo1.value, epcrT: array["61.0-91.0"].carteraTipo1.value*(Number(epc1[3])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[4], Epcr1: epc1[4], slCap: array["91.0-121.0"].carteraTipo1.value, epcrT: array["91.0-121.0"].carteraTipo1.value*(Number(epc1[4])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[5], Epcr1: epc1[5], slCap: array["121.0-151.0"].carteraTipo1.value, epcrT: array["121.0-151.0"].carteraTipo1.value*(Number(epc1[5])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[6], Epcr1: epc1[6], slCap: array["151.0-181.0"].carteraTipo1.value, epcrT: array["151.0-181.0"].carteraTipo1.value*(Number(epc1[6])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[7], Epcr1: epc1[7], slCap: array["181.0-211.0"].carteraTipo1.value, epcrT: array["181.0-211.0"].carteraTipo1.value*(Number(epc1[7])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[8], Epcr1: epc1[8], slCap: array["211.0-241.0"].carteraTipo1.value, epcrT: array["211.0-241.0"].carteraTipo1.value*(Number(epc1[8])/100), porce: 0,});
    this.dataSource2.push({dMora: dmor[9], Epcr1: epc1[9], slCap: array["241.0-*"].carteraTipo1.value, epcrT: array["241.0-*"].carteraTipo1.value*(Number(epc1[9])/100), porce: 0,});

    var tot1 = 0,tot2 = 0,tot3 = 0,tot4 = 0;
    var dmord = [ '0 días', '1 a 30 días', '31 a 60 días', '61 a 90 días', '91 a 120 días', '121 a 150 días', '151 a 180 días', '181 a 210 días', '211 a 240 días', 'Más de 240 días' ];
    this.dataSource2.forEach(dat => { tot1 += dat.slCap; tot2 += dat.cart1; tot3 += dat.cart2; tot4 += dat.epcrT; });
    this.dataGraf3 = [];
    for(var i = 0; i < this.dataSource2.length; i++){
      this.dataSource2[i].porce = (this.dataSource2[i].slCap*100/tot1).toFixed(2);
      this.dataGraf3.push({name:dmord[i], value: Number(this.dataSource2[i].slCap)});
    }
    this.dataSource2.push({
      dMora: 'Total',
      Epcr1: '',
      Epcr2: '',
      slCap: tot1,
      cart1: tot2,
      cart2: tot3,
      epcrT: tot4,
      porce: '100',
    });
  }
  private obtTabla3(array){
    var dmor = [ '0', '1 a 30', '31 a 60', '61 a 90', '91 a 120', '121 a 150', '151 a 180', '181 a 1460', 'Más de 1460' ];
    var epc1 = [ '0.35', '1.05', '2.45', '8.75', '17.5', '33.25', '34.3', '70', '100' ];
    
    this.dataSource3 = [];
    this.dataSource3.push({dMora: dmor[0], Epcr1: epc1[0], slCap: array["0.0-1.0"].carteraTipo1.value, epcrT: array["0.0-1.0"].carteraTipo1.value*(Number(epc1[0])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[1], Epcr1: epc1[1], slCap: array["1.0-31.0"].carteraTipo1.value, epcrT: array["1.0-31.0"].carteraTipo1.value*(Number(epc1[1])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[2], Epcr1: epc1[2], slCap: array["31.0-61.0"].carteraTipo1.value, epcrT: array["31.0-61.0"].carteraTipo1.value*(Number(epc1[2])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[3], Epcr1: epc1[3], slCap: array["61.0-91.0"].carteraTipo1.value, epcrT: array["61.0-91.0"].carteraTipo1.value*(Number(epc1[3])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[4], Epcr1: epc1[4], slCap: array["91.0-121.0"].carteraTipo1.value, epcrT: array["91.0-121.0"].carteraTipo1.value*(Number(epc1[4])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[5], Epcr1: epc1[5], slCap: array["121.0-151.0"].carteraTipo1.value, epcrT: array["121.0-151.0"].carteraTipo1.value*(Number(epc1[5])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[6], Epcr1: epc1[6], slCap: array["151.0-181.0"].carteraTipo1.value, epcrT: array["151.0-181.0"].carteraTipo1.value*(Number(epc1[6])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[7], Epcr1: epc1[7], slCap: array["181.0-1461.0"].carteraTipo1.value, epcrT: array["181.0-1461.0"].carteraTipo1.value*(Number(epc1[7])/100), porce: 0,});
    this.dataSource3.push({dMora: dmor[8], Epcr1: epc1[8], slCap: array["1461.0-*"].carteraTipo1.value, epcrT: array["1461.0-*"].carteraTipo1.value*(Number(epc1[8])/100), porce: 0,});


    var tot1 = 0,tot2 = 0,tot3 = 0,tot4 = 0;
    var dmord = [ '0 días', '1 a 30 días', '31 a 60 días', '61 a 90 días', '91 a 120 días', '121 a 150 días', '151 a 180 días', '181 a 1460 días', 'Más de 1460 días' ];
    this.dataSource3.forEach(dat => { tot1 += dat.slCap; tot2 += dat.cart1; tot3 += dat.cart2; tot4 += dat.epcrT; });
    this.dataGraf4 = [];
    for(var i = 0; i < this.dataSource3.length; i++){
      this.dataSource3[i].porce = (this.dataSource3[i].slCap*100/tot1).toFixed(2);
      this.dataGraf4.push({name:dmord[i], value: Number(this.dataSource3[i].slCap)});
    }
    this.dataSource3.push({
      dMora: 'Total',
      Epcr1: '',
      Epcr2: '',
      slCap: tot1,
      cart1: tot2,
      cart2: tot3,
      epcrT: tot4,
      porce: '100',
    });
  }
  /** Filtros */
  public obtenerMeses(){
    var pos = 0, meses = 0;
    this.aniosSelec = [];
    for(var i = 0; i < this.mesesDisponibles.length; i++){
      if(this.mesesDisponibles[i].value == this.mesesSeleccionados[0].value){
        pos = i;
      }
    }
    for(var i = pos; i >= 0; i-- ){
      if(meses != 13){
        this.aniosSelec.push(this.mesesDisponibles[i].value);
        meses++;
      }
    }
  }
  private aniosMesesSeleccionados(): void {
    this.aniosSeleccionados = [];
    this.mesesSeleccionados = [];
    for (const valor of this.aniosDisponibles) {
      if (valor.check == true)
        this.aniosSeleccionados.push(valor);
    }
    for (const valor of this.mesesDeAnioDisponibles) {
      if (valor.check == true)
        this.mesesSeleccionados.push(valor);
    }
  }
  public cambiodeMes(mes){
    this.mesesSeleccionados = [];
    this.mesesDeAnioDisponibles.forEach(mess => mess.check = false);
    for(var i = 0; i < this.mesesDeAnioDisponibles.length; i++){
      if(this.mesesDeAnioDisponibles[i].value == mes.value){
        this.mesesDeAnioDisponibles[i].check = true;
        this.mesesSeleccionados.push(this.mesesDeAnioDisponibles[i]);
        this.ult_mes[0] = this.mesesDeAnioDisponibles[i].name;
      }
    }
    this.dataSource = [];
    this.dataSource2 = [];
    this.dataSource3 = [];
    this.getInformacion();
  }
  public cambiodeAnio(anio){
    this.ult_anio = [];this.aniosSeleccionados = [];
    this.aniosDisponibles.forEach(mess => mess.check = false);
    for(var i = 0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == anio.value){
        this.aniosDisponibles[i].check = true;
      }
    }
    const anio_selecc = this.aniosDisponibles.find(anios => anios.name === anio['name']);
    this.ult_anio.push(anio_selecc['name'])
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    this.buscarMesSeleccionado();
    this.aniosMesesSeleccionados();
    this.dataSource = [];
    this.dataSource2 = [];
    this.dataSource3 = [];
    this.getInformacion();
  }
  public buscarMesSeleccionado(){
    for (const valor of this.mesesDeAnioDisponibles){
      valor['check'] = false;
    }
    var flag = false;
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['name'] == this.ult_mes[0]){
        valor['check'] = true;
        flag = true;
      }
    }
    if(!flag){
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.ult_mes = [];
      this.ult_mes.push(this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].name);
    }
  }
  /** Modal */
  public ampliarModal(amp){
    document.getElementById('modalTablas').style.display='block';
    this.ocultar_modal();
    switch(amp){
      case 0:
        this.tabla_modal = true;
        this.Titulo_modal = this.Titulo;
      break;
      case 1:
        this.tabla1_modal = true;
        this.Titulo_modal = this.Titulo1;
      break;
      case 2:
        this.tabla2_modal = true;
        this.Titulo_modal = this.Titulo2;
      break;
      case 3:
        this.tabla3_modal = true;
        this.Titulo_modal = this.Titulo3;
      break;
    }
  }
  public ampliarModalGraf(amp){
    document.getElementById('modalTablas').style.display='block';
    this.ocultar_modal();
    switch(amp){
      case 0:
        this.grafi_modal = true;
        this.Titulo_modal = this.Titulo;
      break;
      case 1:
        this.grafi1_modal = true;
        this.Titulo_modal = this.Titulo1;
      break;
      case 2:
        this.grafi2_modal = true;
        this.Titulo_modal = this.Titulo2;
      break;
      case 3:
        this.grafi3_modal = true;
        this.Titulo_modal = this.Titulo3;
      break;
    }
  }
  public closeModal(){
    document.getElementById('modalTablas').style.display='none';
    this.ocultar_modal();
  }
  public ocultar_modal(){
    this.tabla_modal = false;
    this.tabla1_modal = false;
    this.tabla2_modal = false;
    this.tabla3_modal = false;
    this.grafi_modal = false;
    this.grafi1_modal = false;
    this.grafi2_modal = false;
    this.grafi3_modal = false;
  }
  /** Cambio de tablas */
  public perspective(per){
    switch(per){
      case 1:
        this.cartCons = true;
        this.cartCome = false;
        this.cartVivi = false; 
      break;
      case 2:
        this.cartCons = false;
        this.cartCome = true;
        this.cartVivi = false; 
      break;
      case 3:
        this.cartCons = false;
        this.cartCome = false;
        this.cartVivi = true; 
      break;
    }
  }
  /** subtitulos */
  private subTit(){
    this.subtitulo = ''+this.aniosSeleccionados[0].name.substring(2,4) + ' | ' + this.mesesSeleccionados[0].name;
  }
  /** Exportación */
  public export_excel(amp){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    var perspectiva = '';
    var encabezado_excel = ['Días de mora','% EPRC Cartera tipo 1','Saldo de capital','EPRC','% de la cartera total']; //['Días de mora','% EPRC Cartera tipo 1','% EPRC Cartera tipo 2','Saldo de capital','Cartera tipo 1','Cartera tipo 2','EPRC'];
    var cadena_evaluacion = encabezado_excel;
    var mes = this.mesesSeleccionados[0].name;

    switch(amp){
      case 0:
        for(const dato of this.dataSource4){
          aux.push(dato['dMora']);
          aux.push(Number(dato['Epcr1'])/100);
          aux.push(dato['slCap']);
          aux.push(dato['epcrT']);
          aux.push(Number(dato['porce'])/100);
          data_excel.push(aux);
          aux = [];
        }
        name = this.Titulo;
      break;
      case 1:
        for(const dato of this.dataSource){
          aux.push(dato['dMora']);
          aux.push(Number(dato['Epcr1'])/100);
          aux.push(dato['slCap']);
          aux.push(dato['epcrT']);
          aux.push(Number(dato['porce'])/100);
          data_excel.push(aux);
          aux = [];
        }
        name = this.Titulo1;
      break;
      case 2:
        for(const dato of this.dataSource2){
          aux.push(dato['dMora']);
          aux.push(Number(dato['Epcr1'])/100);
          aux.push(dato['slCap']);
          aux.push(dato['epcrT']);
          aux.push(Number(dato['porce'])/100);
          data_excel.push(aux);
          aux = [];
        }
        name = this.Titulo2;
      break;
      case 3:
        for(const dato of this.dataSource3){
          aux.push(dato['dMora']);
          aux.push(Number(dato['Epcr1'])/100);
          aux.push(dato['slCap']);
          aux.push(dato['epcrT']);
          aux.push(Number(dato['porce'])/100);
          data_excel.push(aux);
          aux = [];
        }
        name = this.Titulo3;
      break;
    }

    this.excelservices.generateEstimaciones(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva,mes);
  }
  public exportar_img(graf){
    var grafica = '', name = '';
    switch(graf){
      case 0:
        grafica = 'grafGene';
        name = this.Titulo;
      break;
      case 1:
        grafica = 'grafCons';
        name = this.Titulo1;
      break;
      case 2:
        grafica = 'grafCome';
        name = this.Titulo2;
      break; 
      case 3:
        grafica = 'grafVivi';
        name = this.Titulo3;
      break;  
    }
    PDF.exportimagenes(grafica, name);
  }
  public formatearnormal(num: any){
    num = Number(num).toFixed(2);
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  public formatearnormalDec(num: any){
    num = Number(num).toFixed(2);
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft + splitRight;
  }
  public borrarFil(){
    this.dataSource = [];
    this.dataSource2 = [];
    this.dataSource3 = [];

    this.getFiltros();
  }
}
