<!--<div class="w3-container w3-contenedor-template" >
  <div class="w3-row" id="filtros">
    <div class="w3-quarter hijoizquierda">
      <span class="w3-text-deep-orange w3-margin-right-filter"><b>Financiero</b></span><span class="w3-text-deep-orange">&bull;</span><span class=" w3-margin-left-filter w3-text-orange"> Catálogo de cuentas </span> 
    </div> 
    <div class="w3-rest">
      <div class="w3-quarter">
        <div class="w3-row ">
          <div class="w3-col w3-tiny " style="width: 100%;" >
            <div *ngIf="administrador()" class=" w3-half" >
              <div class="w3-bar-item" >
                <div class="w3-cincuentaycinco w3-filtro-aniomes hijoizquierda">
                  Instituciones
                </div>
                <div class="w3-rest ">
                  <mat-icon id="maticonInstituciones" class="w3-tiny w3-margin-indicadores  w3-button w3-dropdown-click" (click)="DropInstituciones()">keyboard_arrow_down</mat-icon>
                </div>
              </div>
              <div id="demoInstituciones" class="w3-dropdown-content w3-light-gray w3-scroll" style="height: 23vh;width: 200px;">
                <table id="table_balance_intituciones" class="w3-table w3-border">
                  <tr [id]="'institucion_'+i" class="w3-row" *ngFor="let institucion of data_instituciones;let i = index">
                    <td class="w3-margin-left-contenido w3-label-inactivo w3-margin-top">
                      <span class="w3-margin-left-contenido">{{institucion.name}} </span>
                    </td>
                    <td>
                      <div class="w3-rest w3-margin-top w3-right-align">
                        <input class="w3-filtro-aniomes w3-check w3-margin-top-check w3-margin-right-contenido" 
                              (change)="institucionChange(institucion.id)" 
                              [checked]="institucion.checked" 
                              type="checkbox">
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="w3-half">
              <div class="w3-row padreizquierda">
                <div class="w3-row hijoizquierda">
                  <div [class]="ClassAdministrador()">
                    <div style="width: 120px;">
                      {{InstitucionSelect()}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-col m7 ">
        <div class="w3-row ">
          <div class="w3-col w3-tiny " style="width: 100%;">
            <div class="w3-col s2 ">
              <div class="w3-bar-item" >
                <div class="w3-twothird hijoizquierda w3-filtro-aniomes">Períodos</div>
                <div class="w3-rest">
                    <mat-icon id="maticonPeriodo" class="w3-tiny w3-margin-indicadores  w3-button w3-dropdown-click" (click)="DropPeriodo()">keyboard_arrow_down</mat-icon>
                </div>
              </div>
              <div id="demoPeriodo" class="w3-dropdown-content w3-light-gray w3-scroll w3-border" style="height: auto;width: 180px;">
                <div class="w3-container w3-row w3-left-align " *ngFor="let itemEjercicioPeriodo of DATA_PERIODOSERIES">
                  <div class="w3-row ">
                    <div class="w3-row">
                      <div class="w3-noventa w3-margin-lelf-1roN">
                        <mat-icon [id]="'add_'+itemEjercicioPeriodo.anio" class="w3-tiny w3-button w3-dropdown-click" (click)="mostrar_primernivel(itemEjercicioPeriodo.anio); $event.stopPropagation();">add</mat-icon>
                        <mat-icon [id]="'remove_'+itemEjercicioPeriodo.anio" class="w3-tiny w3-button w3-dropdown-click" (click)="ocultar_primernivel(itemEjercicioPeriodo.anio); $event.stopPropagation();">remove</mat-icon>
                        <span class="w3-label-inactivo"> {{itemEjercicioPeriodo.anio}}</span>
                      </div>
                    </div>
                  </div>
                  <div [id]="'contenedor'+itemEjercicioPeriodo.anio" class="w3-row">
                    <div class="w3-left-align" *ngFor="let nivel1 of itemEjercicioPeriodo.series">
                      <div class="w3-table">
                        <div class="w3-row ">
                          <div class="w3-half w3-margin-lelf-2doN">
                            <span class="w3-elemento-menu w3-filtro-aniomes">{{nivel1.mes}}</span>
                          </div>
                          <div class="w3-rest w3-right-align" >
                            <input [id]="'check_'+itemEjercicioPeriodo.anio+nivel1.mes" class="w3-filtro-aniomes w3-check w3-dropdown-click" 
                                  (change)="Indicador_Nivel1(nivel1.Id_Evaluacion)"
                                  (click)="$event.stopPropagation();"
                                  [checked]="nivel1.check" type="checkbox">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-row padreizquierda">
                  <div [class]="ContarEjercicioPeriodo()">
                  <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center" 
                        style="margin-left: 10px; width: 62px; "
                        *ngFor="let am of data_evaluacionaux"
                        (click)="Indicador_Nivel1(am.Id_Evaluacion)"
                        >
                        <span class="w3-elemento-menu ">{{am.ejercicioperiodo}}</span>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del tablero -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> Financiero </b></span> <!--  -->
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> Catálogo de cuentas </span> <!--  -->
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <!-- periodicidad y análisis -->
      <div class="w3-noventa2 w3-row">
        <!-- espacio en blanco para sucursales -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;"></div>
        <!-- Filtro por periodicidad -->
        <div class="w3-rest padreizquierda" style="margin-right: 1vh;">
          <!-- Periodos disponibles -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer"> <!--  -->
              Períodos
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPer="matMenu">
              <ng-container *ngFor="let an of DATA_PERIODOSERIES">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); Indicador_Nivel1(child.Id_Evaluacion)">
                        <div class="w3-quarter">
                          <input [id]="'check_'+child.anio+child.mes" style="cursor: pointer; margin-top: 7px;"
                                    (change)="Indicador_Nivel1(child.Id_Evaluacion)"
                                    [checked]="child.check" type="checkbox"
                                    (click)="$event.stopPropagation();">
                        </div>
                        <div class="w3-rest">
                          <span style="margin-left: 5px;">{{child.mes}}</span> 
                        </div>           
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          <!-- Periodos seleccionado -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button filtroMesAnioSeleccionado"
                      *ngFor="let am of data_evaluacionaux"
                      (click)="Indicador_Nivel1(am.Id_Evaluacion)">
                      <span style="margin-left: 5px;">{{am.ejercicioperiodo}}</span>
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- filtro por análisis -->
        <!--<div class="w3-rest">
          <div class="w3-third hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios">
              Indicador
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium"  #menuanios="matMenu" >
              <ng-container *ngFor="let ind of data_indicadorSeries">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenuInd1" (click)="$event.stopPropagation(); indicador_seleccionado(ind.Id_Indicador)">
                  <div class="w3-quarter">
                    <input (change)="indicador_seleccionado(ind.Id_Indicador)"
                      style="cursor: pointer; margin-top: 7px;"
                      [checked]="ind.check" type="checkbox"
                      (click)="$event.stopPropagation();">
                  </div>
                  <div class="w3-rest">
                    <span style="margin-left: 10px;">{{ind.Descripcion}}</span> 
                  </div> 
                </button>
                
                <mat-menu #childMenuInd1="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let itemN1 of ind.SeriesNivel1">
                    <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenuInd2" (click)="$event.stopPropagation(); indicador_seleccionadoN2(itemN1.Id_Indicador)">
                      <div class="w3-quarter">
                        <input [id]="'check2N_'+itemN1.Id_Indicador" style="cursor: pointer; margin-top: 7px;"
                          (change)="indicador_seleccionadoN2(itemN1.Id_Indicador)" 
                          [checked]="itemN1.check" type="checkbox"
                          (click)="$event.stopPropagation();">
                      </div>
                      <div class="w3-rest p" matTooltip="{{itemN1.Descripcion}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                        <span>{{itemN1.Descripcion}}</span>
                      </div>           
                    </button>

                    <mat-menu #childMenuInd2="matMenu" [overlapTrigger]="false">
                      <span *ngFor="let itemN2 of itemN1.SeriesNivel2">
                        <button mat-menu-item (click)="$event.stopPropagation(); indicador_seleccionadoN3(itemN2.Id_Indicador)">
                          <div class="w3-quarter">
                            <input [id]="'check3N_'+itemN2.Id_Indicador" style="cursor: pointer; margin-top: 7px;"
                            (change)="indicador_seleccionadoN3(itemN2.Id_Indicador)" 
                            [checked]="itemN2.check" type="checkbox"
                            (click)="$event.stopPropagation();">
                          </div>
                          <div class="w3-rest p" matTooltip="{{itemN2.Descripcion}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                            <span>{{itemN2.Descripcion}}</span>
                          </div>           
                        </button>
                      </span>
                    </mat-menu>

                  </span>
                </mat-menu>

              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips">
              <div class="w3-row chips ">
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <!-- Eliminar filtros -->
      <div class="w3-rest w3-right ">
        <button mat-icon-button>
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitar_filtros()"> <!--(click)="quitar_filtros()"-->
            <img src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="w3-container" >
  <div class="w3-row w3-white w3-header-cuadrante">
    <div class="w3-twothird w3-titulo-contenidomenu ">
      <span class="w3-margin-left-contenido">Cuentas</span><span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntobalancec1">&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulobalancec1}}</span>
    </div>
    <div class="w3-third w3-right " style="margin-right: -11px;">
      <div class="w3-right w3-margin-top-icon-menu">
        <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menut1Balance">more_vert</mat-icon>
      </div>
      <mat-menu #menut1Balance="matMenu">
        <mat-divider></mat-divider>
        <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportart1Balance">
          <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
          <span>Exportar</span>
        </button>
      </mat-menu>
      <mat-menu #exportart1Balance="matMenu">
        <button (click)="export_csv()" mat-menu-item>
          <span>CSV</span>
        </button>
        <button (click)="export_csvTodos()" mat-menu-item>
          <span>CSV Todas</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="option1" class="w3-row w3-white" >
    <div class="w3-row " style="height: 78vh;">
      <div *ngIf="tabla_informacion1" id="tabla_informacion1" class="w3-encabezado-table w3-scroll" style="background-color: #F9F9F9;" >
        <table class="w3-table" >
          <thead class="" style="background-color: #A7A9AC;">
            <tr class="w3-table-titleBalance" style="background-color: #A7A9AC;">
              <th class="w3-elemento-titulo-table"><span>Código</span></th>
              <th class="w3-elemento-titulo-table"><span >Descripción</span></th>
              <ng-container *ngFor="let evaluacion of cadena_evaluacion">
                <th class="w3-center w3-elemento-titulo-table" ><span>{{evaluacion.evaluacion}}</span></th>
              </ng-container>
              </tr>
          </thead>
          <tbody *ngFor="let item_tabla of data_ordenado" >
            <tr *ngIf="item_tabla.Check" id="item_tabla.Id_Cuenta"
              [ngClass]="{'w3-table-trtdBalanceBlue w3-encabezado-balance w3-border-Nivel0Balance':buscarnivelEncabezado(item_tabla.Id_Cuenta),
                          'w3-table-trtdBalance w3-cuerpo-balance':!buscarnivelEncabezado2(item_tabla.Id_Cuenta)
                          }">
              <td class="" [class]="FontBalance(item_tabla.Id_Cuenta)">
                <div class="w3-row ">
                  <mat-icon (click)="mostrarocultarCuenta(item_tabla.Id_Cuenta)" *ngIf="cuentaprincipal(item_tabla.Id_Cuenta)" class="iconoMen w3-button">{{cadenaIdCuenta(item_tabla.Id_Cuenta)}}</mat-icon>
                  <mat-icon (click)="mostrarocultarCuenta(item_tabla.Id_Cuenta)" *ngIf="!cuentaprincipal(item_tabla.Id_Cuenta)" class="iconoMen w3-button"></mat-icon>
                  <span [class]="FontBalance(item_tabla.Id_Cuenta)">{{item_tabla.Id_Cuenta}}</span>
                </div>
              </td> 
              <td class="" [class]="FontBalance(item_tabla.Id_Cuenta)">
                <div class="w3-row ">
                  <div class="w3-row">
                    <div class="w3-row w3-elemento-tableBalance">
                      <span [ngClass] = "{'  w3-margin-lelf-1roB':buscarnivel0(item_tabla.Id_Cuenta),
                                      '  w3-margin-lelf-2doB':buscarnivel1(item_tabla.Id_Cuenta),
                                      '  w3-margin-lelf-3roB':buscarnivel2(item_tabla.Id_Cuenta),
                                      '  w3-margin-lelf-4toB':buscarnivel3(item_tabla.Id_Cuenta),
                                      '  w3-margin-lelf-5toB':buscarnivel4(item_tabla.Id_Cuenta),
                                      '  w3-margin-lelf-6toB':buscarnivel5(item_tabla.Id_Cuenta)
                                  }">{{item_tabla.Descripcion}} </span>
                    </div>
                  </div>
                </div>
              </td>
              <ng-container *ngFor="let item of item_tabla.Series; index as i">
                <td class="" [class]="FontBalance(item_tabla.Id_Cuenta)">
                  <div class="w3-row w3-noventa w3-right-align">
                    <div class="w3-row">
                      <div class="w3-row">
                        <span [class]="FontBalance(item_tabla.Id_Cuenta,item.Valor)" > {{formatear(item.Valor)}}</span>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        <div class="w3-row " style="height: 33vh;margin-top: 2vh;background-color: #F9F9F9;">
          <div id="A" class="w3-third" style="height: 100%;">
            <div class="w3-container w3-white" style="margin: 0vh 1vh 1vh 1vh ;height: auto;">
              <div class="w3-row w3-white w3-header-cuadrante padreizquierda" style="height: 6vh;">
                <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
                  <div class="row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                    <span class="w3-margin-left-contenidoBalance">Activo</span>
                    <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntoActivo">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloActivo}}</span>
                  </div>
                </div>
                <div class="w3-rest w3-right-align_rest " >
                  <div class="w3-right hijoderechaTitle">
                    <!--<mat-icon (click)="ClickActivo()" *ngIf="barActivo" class="material-icons-outlined"
                        [ngStyle]="{'color':'gray'}">
                        {{labelActivo()}}
                    </mat-icon>-->
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarActivo()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuActivo">more_vert</mat-icon>
                    <mat-menu #menuActivo="matMenu" class="w3-blue">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficaActivo">
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button (click)="export_png('A')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                        <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                        <span>Exportar como png</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficaActivo="matMenu">
                      <button (click)="camGrafAct('bar')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barra</span>
                      </button>
                      <button (click)="camGrafAct('lin')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button (click)="camGrafAct('are')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                        <span>Área</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="w3-row w3-white" style="height: 27vh;">
                <ngx-charts-bar-vertical *ngIf="barActivo" id="barActivo"
                  [scheme]="colorSchemeActivo"
                  [results]="singleActivo"
                  [xAxis]="showXAxisActivo"
                  [yAxis]="showYAxisActivo"
                  [legend]="showLegendActivo"
                  [showXAxisLabel]="showXAxisLabelActivo"
                  [showYAxisLabel]="showYAxisLabelActivo"
                  [xAxisLabel]="xAxisLabelActivo"
                  [yAxisLabel]="yAxisLabelActivo"
                  [showDataLabel]="showDataLabelActivo"
                  [maxYAxisTickLength]="8"
                  (select)="onSelect($event)">
                  <!--<ng-template  #tooltipTemplate let-model="model" > 
                    <div style="text-align: left;"> 
                      <div *ngFor="let data of singleActivo; let i = index">
                        <span *ngIf="data.name === model.name">
                          {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                        </span> 
                      </div> 
                    </div>
                  </ng-template>--> 
                </ngx-charts-bar-vertical>
                <ngx-charts-line-chart *ngIf="linActivo" id="lineActivo"
                  [results]="multiActivo"
                  [xAxis]="true"
                  [scheme]="colorSchemeActivo"
                  [xAxis]="showXAxisActivo"
                  [yAxis]="showYAxisActivo"
                  [legend]="showLegendActivo"
                  [showXAxisLabel]="showXAxisLabelActivo"
                  [showYAxisLabel]="showYAxisLabelActivo"
                  [xAxisLabel]="xAxisLabelActivo"
                  [yAxisLabel]="yAxisLabelActivo"
                  [maxYAxisTickLength]="8"
                  [showGridLines]="false">
                  <!--<ng-template #tooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiActivo; let i = index">
                        <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiActivo; let i = index">
                        <span>{{buscarCuentasLineal(model)}}</span>
                      </div>
                    </div>
                  </ng-template>-->
                </ngx-charts-line-chart>
                <ngx-charts-area-chart *ngIf="areActivo" id="lineActivo"
                  [results]="multiActivo"
                  [xAxis]="true"
                  [scheme]="colorSchemeActivo"
                  [xAxis]="showXAxisActivo"
                  [yAxis]="showYAxisActivo"
                  [legend]="showLegendActivo"
                  [showXAxisLabel]="showXAxisLabelActivo"
                  [showYAxisLabel]="showYAxisLabelActivo"
                  [xAxisLabel]="xAxisLabelActivo"
                  [yAxisLabel]="yAxisLabelActivo"
                  [maxYAxisTickLength]="8"
                  [showGridLines]="false">
                  <!--<ng-template #tooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiActivo; let i = index">
                        <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiActivo; let i = index">
                        <span>{{buscarCuentasLineal(model)}}</span>
                      </div>
                    </div>
                  </ng-template>-->
                </ngx-charts-area-chart>
              </div>
            </div>
          </div>
          <div id="P" class="w3-third" style="height: 100%;">
            <div class="w3-container w3-white" style="margin: 0vh 1vh 1vh 1vh ;height: auto;">
              <div class="w3-row w3-white w3-header-cuadrante padreizquierda" style="height: 6vh;">
                <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
                  <div class="row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                    <span class="w3-margin-left-contenidoBalance">Pasivo</span>
                    <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntoActivo">&bull;</span>
                    <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloActivo}}</span>
                  </div>
                </div>
                <div class="w3-rest w3-right-align_rest " >
                  <div class="w3-right hijoderechaTitle">
                    <!--<mat-icon (click)="ClickPasivo()" *ngIf="barPasivo" class="material-icons-outlined"
                        [ngStyle]="{'color':'gray'}">
                        {{labelPasivo()}}
                    </mat-icon>-->
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarPasivo()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuPasivo">more_vert</mat-icon>
                    <mat-menu #menuPasivo="matMenu" class="w3-blue">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficaPasivo">
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button (click)="export_png('P')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                        <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                        <span>Exportar como png</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficaPasivo="matMenu">
                      <button (click)="camGrafPas('bar')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barra</span>
                      </button>
                      <button (click)="camGrafPas('lin')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button (click)="camGrafPas('are')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                        <span>Área</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="w3-row w3-white" style="height: 27vh;">
                <ngx-charts-bar-vertical *ngIf="barPasivo" id="barPasivo"
                  [scheme]="colorSchemePasivo"
                  [showGridLines]="false"
                  [results]="singlePasivo"
                  [xAxis]="showXAxisPasivo"
                  [yAxis]="showYAxisPasivo"
                  [legend]="showLegendPasivo"
                  [showXAxisLabel]="showXAxisLabelPasivo"
                  [showYAxisLabel]="showYAxisLabelPasivo"
                  [xAxisLabel]="xAxisLabelPasivo"
                  [yAxisLabel]="yAxisLabelPasivo"
                  [showDataLabel]="showDataLabelPasivo"
                  [maxYAxisTickLength]="8"
                  (select)="onSelect($event)">
                  <!--<ng-template  #tooltipTemplate let-model="model" > 
                    <div style="text-align: left;"> 
                      <div *ngFor="let data of singlePasivo; let i = index">
                        <span *ngIf="data.name === model.name">
                          {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                        </span> 
                      </div> 
                    </div>
                  </ng-template>-->
                </ngx-charts-bar-vertical>
                <ngx-charts-line-chart *ngIf="linPasivo" id="linePasivo"
                  [results]="multiPasivo"
                  [xAxis]="true"
                  [scheme]="colorSchemePasivo"
                  [xAxis]="showXAxisPasivo"
                  [yAxis]="showYAxisPasivo"
                  [legend]="showLegendPasivo"
                  [showXAxisLabel]="showXAxisLabelPasivo"
                  [showYAxisLabel]="showYAxisLabelPasivo"
                  [xAxisLabel]="xAxisLabelPasivo"
                  [yAxisLabel]="yAxisLabelPasivo"
                  [maxYAxisTickLength]="8"
                  [showGridLines]="false">
                  <!--<ng-template #tooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiPasivo; let i = index">
                        <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiPasivo; let i = index">
                        <span>{{buscarCuentasLineal(model)}}</span>
                      </div>
                    </div>
                  </ng-template>--> 
                </ngx-charts-line-chart>
                <ngx-charts-area-chart *ngIf="arePasivo" id="linePasivo"
                  [results]="multiPasivo"
                  [xAxis]="true"
                  [scheme]="colorSchemePasivo"
                  [xAxis]="showXAxisPasivo"
                  [yAxis]="showYAxisPasivo"
                  [legend]="showLegendPasivo"
                  [showXAxisLabel]="showXAxisLabelPasivo"
                  [showYAxisLabel]="showYAxisLabelPasivo"
                  [xAxisLabel]="xAxisLabelPasivo"
                  [yAxisLabel]="yAxisLabelPasivo"
                  [maxYAxisTickLength]="8"
                  [showGridLines]="false">
                  <!--<ng-template #tooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiPasivo; let i = index">
                        <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiPasivo; let i = index">
                        <span>{{buscarCuentasLineal(model)}}</span>
                      </div>
                    </div>
                  </ng-template>--> 
                </ngx-charts-area-chart>
              </div>
            </div>
          </div>
          <div id="C" class="w3-rest " style="height: 100%;">
            <div class="w3-container w3-white" style="margin: 0vh 1vh 1vh 1vh ;height: auto;">
              <div class="w3-row w3-white w3-header-cuadrante padreizquierda" style="height: 6vh;">
                <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle ">
                  <div class="row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                    <span class="w3-margin-left-contenidoBalance">Capital contable</span>
                    <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntoActivo">&bull;</span>
                    <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloActivo}}</span>
                  </div>
                </div>
                <div class="w3-rest w3-right-align_rest " >
                  <div class="w3-right hijoderechaTitle">
                    <!--<mat-icon (click)="ClickCapitalContable()" *ngIf="barCapitalContable" class="material-icons-outlined"
                        [ngStyle]="{'color':'gray'}">
                        {{labelCapitalContable()}}
                    </mat-icon>-->
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarCapitalContable()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuCapitalContable">more_vert</mat-icon>
                    <mat-menu #menuCapitalContable="matMenu" class="w3-blue">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficaCapitalContable">
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button (click)="export_png('C')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                        <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                        <span>Exportar como png</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficaCapitalContable="matMenu">
                      <button (click)="camGrafCapCont('bar')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barra</span>
                      </button>
                      <button (click)="camGrafCapCont('lin')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button (click)="camGrafCapCont('are')" class="mat-menu-item" mat-menu-item class="w3-caja-menu" >
                        <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                        <span>Área</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="w3-row w3-white" style="height: 27vh;">
                <ngx-charts-bar-vertical *ngIf="barCapitalContable" id="barCapitalContable"
                  [scheme]="colorSchemeCapitalContable"
                  [showGridLines]="false"
                  [results]="singleCapitalContable"
                  [xAxis]="showXAxisCapitalContable"
                  [yAxis]="showYAxisCapitalContable"
                  [legend]="showLegendCapitalContable"
                  [showXAxisLabel]="showXAxisLabelCapitalContable"
                  [showYAxisLabel]="showYAxisLabelCapitalContable"
                  [xAxisLabel]="xAxisLabelCapitalContable"
                  [yAxisLabel]="yAxisLabelCapitalContable"
                  [showDataLabel]="showDataLabelCapitalContable"
                  [maxYAxisTickLength]="8"
                  (select)="onSelect($event)">
                  <!--<ng-template  #tooltipTemplate let-model="model" > 
                    <div style="text-align: left;"> 
                      <div *ngFor="let data of singleCapitalContable; let i = index">
                        <span *ngIf="data.name === model.name">
                          {{buscarCuentasPastel(data.name)}}: $ {{data.value}} 
                        </span> 
                      </div> 
                    </div>
                  </ng-template>-->
                </ngx-charts-bar-vertical>
                <ngx-charts-line-chart *ngIf="linCapitalContable" id="linealCapitalContable"
                  [results]="multiCapitalContable"
                  [xAxis]="true"
                  [scheme]="colorSchemeCapitalContable"
                  [xAxis]="showXAxisCapitalContable"
                  [yAxis]="showYAxisCapitalContable"
                  [legend]="showLegendCapitalContable"
                  [showXAxisLabel]="showXAxisLabelCapitalContable"
                  [showYAxisLabel]="showYAxisLabelCapitalContable"
                  [xAxisLabel]="xAxisLabelCapitalContable"
                  [yAxisLabel]="yAxisLabelCapitalContable"
                  [maxYAxisTickLength]="8"
                  [showGridLines]="false">
                  <!--<ng-template #tooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiCapitalContable; let i = index">
                        <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiCapitalContable; let i = index">
                        <span>{{buscarCuentasLineal(model)}}</span>
                      </div>
                    </div>
                  </ng-template>--> 
                </ngx-charts-line-chart>
                <ngx-charts-area-chart *ngIf="areCapitalContable" id="linealCapitalContable"
                  [results]="multiCapitalContable"
                  [xAxis]="true"
                  [scheme]="colorSchemeCapitalContable"
                  [xAxis]="showXAxisCapitalContable"
                  [yAxis]="showYAxisCapitalContable"
                  [legend]="showLegendCapitalContable"
                  [showXAxisLabel]="showXAxisLabelCapitalContable"
                  [showYAxisLabel]="showYAxisLabelCapitalContable"
                  [xAxisLabel]="xAxisLabelCapitalContable"
                  [yAxisLabel]="yAxisLabelCapitalContable"
                  [maxYAxisTickLength]="8"
                  [showGridLines]="false">
                  <!--<ng-template #tooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiCapitalContable; let i = index">
                        <span>{{buscarCuentasPastel(model.name)}} : {{model.value}}</span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                      <div *ngFor="let data of multiCapitalContable; let i = index">
                        <span>{{buscarCuentasLineal(model)}}</span>
                      </div>
                    </div>
                  </ng-template>--> 
                </ngx-charts-area-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="option2" class="w3-row w3-white" >
    <div class="w3-row " style="height: 550px;">
      <div *ngIf="tabla_informacion2" id="tabla_informacion2" class="w3-encabezado-table w3-scroll"  >
        <table class="w3-table" >
          <thead class="" style="background-color: #A7A9AC;">
            <tr class="w3-table-titleBalance" style="background-color: #A7A9AC;">
              <th class="w3-elemento-titulo-table"><span>Código</span></th>
              <th class="w3-elemento-titulo-table"><span >Descripción2</span></th>
              <th class="w3-center" *ngFor="let evaluacion of cadena_evaluacionw3-elemento-titulo-table" ><span>{{evaluacion.evaluacion}}</span></th>
            </tr>
          </thead>
          <tbody *ngFor="let item_tabla2 of data_ordenadoOption2" >
            <tr id="item_tabla2.Id_Cuenta"
              [ngClass]="{'w3-table-trtdBalance w3-encabezado-balance w3-border-Nivel0Balance':buscarnivelEncabezadoformula(item_tabla2.Id_Cuenta,item_tabla2.Formula),
                          'w3-table-trtdBalance w3-encabezado-balance':!buscarnivelEncabezadoformula2(item_tabla2.Id_Cuenta,item_tabla2.Formula)
                          }">
              <td class="">
                <div class="w3-row ">
                  <span class="w3-elemento-tableBalance w3-margin-lelf-1ro">
                    {{buscarIdCuenta(item_tabla2.Id_Cuenta)}}
                  </span>
                </div>
              </td>
              <td class="">
                <div class="w3-row ">
                  <span 
                    [ngClass] = "{'w3-elemento-tableBalance w3-margin-lelf-1roB':buscarnivel20(item_tabla2.Id_Cuenta),
                                  'w3-elemento-tableBalance w3-margin-lelf-1roB2':buscarnivel201(item_tabla2.Id_Cuenta),
                                  'w3-elemento-tableBalance w3-margin-lelf-2doB':buscarnivel1(item_tabla2.Id_Cuenta),
                                  'w3-elemento-tableBalance w3-margin-lelf-3roB':buscarnivel2(item_tabla2.Id_Cuenta),
                                  'w3-elemento-tableBalance w3-margin-lelf-4toB':buscarnivel3(item_tabla2.Id_Cuenta),
                                  'w3-elemento-tableBalance w3-margin-lelf-5toB':buscarnivel4(item_tabla2.Id_Cuenta),
                                  'w3-elemento-tableBalance w3-margin-lelf-6toB':buscarnivel5(item_tabla2.Id_Cuenta)
                              }">
                    <!---->
                    {{item_tabla2.Descripcion}}
                  </span>
                </div>
              </td>
              <td class=""  *ngFor="let item2 of item_tabla2.Series" >
                <div class="w3-row w3-twothird w3-right-align">
                  <div class="w3-row">
                    <span class="w3-elemento-tableBalance "> $ {{formatear(item2.Valor)}}</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div id="showhide_modalAmpliar" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{titulomodal}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntomodal">&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulomodal}}</span>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-rest " style="height: 100%;">
        <div class="w3-row" style="height: 100%;">
          <div class="w3-third w3-right" style="height: 100%;">
            <div class="w3-quarter " style="height: 100%;">
              
            </div>
            <div class="w3-quarter " style="height: 100%;">
              <mat-icon (click)="ClickLabelModal()" *ngIf="barCapitalContableModal || barPasivoModal || barActivoModal" class="material-icons-outlined w3-margin-indicadores w3-button"
                [ngStyle]="{'color':'gray'}">
                {{labelModal()}}
              </mat-icon>
              <mat-icon (click)="showhide_modalAmpliarCerrar()" class="w3-display-topright w3-margin-indicadores w3-button" style="margin-right: 24px;">clear</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 51vh;">
        <!-- GRAFICAS DE ACTIVO -->
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="barActivo_modal">
          <ngx-charts-bar-vertical class="w3-row w3-center"
            [scheme]="colorSchemeActivo"
            [results]="singleActivo"
            [xAxis]="showXAxisActivo"
            [yAxis]="showYAxisActivo"
            [legend]="showLegendActivo">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="linActivo_modal">
          <ngx-charts-line-chart id="lineActivo"
            [results]="multiActivo"
            [xAxis]="true"
            [scheme]="colorSchemeActivo"
            [xAxis]="showXAxisActivo"
            [yAxis]="showYAxisActivo"
            [legend]="showLegendActivo"
            [showXAxisLabel]="showXAxisLabelActivo"
            [showYAxisLabel]="showYAxisLabelActivo"
            [xAxisLabel]="xAxisLabelActivo"
            [yAxisLabel]="yAxisLabelActivo"
            [showGridLines]="false">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="areActivo_modal">
          <ngx-charts-area-chart id="lineActivo"
            [results]="multiActivo"
            [xAxis]="true"
            [scheme]="colorSchemeActivo"
            [xAxis]="showXAxisActivo"
            [yAxis]="showYAxisActivo"
            [legend]="showLegendActivo"
            [showXAxisLabel]="showXAxisLabelActivo"
            [showYAxisLabel]="showYAxisLabelActivo"
            [xAxisLabel]="xAxisLabelActivo"
            [yAxisLabel]="yAxisLabelActivo"
            [showGridLines]="false">
          </ngx-charts-area-chart>
        </div>
        <!-- FIN GRAFICAS ACTIVO -->
        <!-- GRAFICAS PASIVO -->
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="barPasivo_modal">
          <ngx-charts-bar-vertical id="barPasivo" class="w3-row w3-center"
            [scheme]="colorSchemePasivo"
            [showGridLines]="false"
            [results]="singlePasivo"
            [xAxis]="showXAxisPasivo"
            [yAxis]="showYAxisPasivo"
            [legend]="showLegendPasivo"
            [showXAxisLabel]="showXAxisLabelPasivo"
            [showYAxisLabel]="showYAxisLabelPasivo"
            [xAxisLabel]="xAxisLabelPasivo"
            [yAxisLabel]="yAxisLabelPasivo"
            [showDataLabel]="showDataLabelPasivo"
            [maxYAxisTickLength]="8">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="linPasivo_modal">
          <ngx-charts-line-chart id="linePasivo" class="w3-row w3-center"
            [results]="multiPasivo"
            [xAxis]="true"
            [scheme]="colorSchemePasivo"
            [xAxis]="showXAxisPasivo"
            [yAxis]="showYAxisPasivo"
            [legend]="showLegendPasivo"
            [showXAxisLabel]="showXAxisLabelPasivo"
            [showYAxisLabel]="showYAxisLabelPasivo"
            [xAxisLabel]="xAxisLabelPasivo"
            [yAxisLabel]="yAxisLabelPasivo"
            [maxYAxisTickLength]="8"
            [showGridLines]="false">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="arePasivo_modal">
          <ngx-charts-area-chart id="linePasivo" class="w3-row w3-center"
            [results]="multiPasivo"
            [xAxis]="true"
            [scheme]="colorSchemePasivo"
            [xAxis]="showXAxisPasivo"
            [yAxis]="showYAxisPasivo"
            [legend]="showLegendPasivo"
            [showXAxisLabel]="showXAxisLabelPasivo"
            [showYAxisLabel]="showYAxisLabelPasivo"
            [xAxisLabel]="xAxisLabelPasivo"
            [yAxisLabel]="yAxisLabelPasivo"
            [maxYAxisTickLength]="8"
            [showGridLines]="false">
          </ngx-charts-area-chart>
        </div>
        <!-- FIN GRAFICAS PASIVO -->
        <!-- GRAFICAS CAPITAL CONTABLE -->
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="barCapitalContable_modal">
          <ngx-charts-bar-vertical id="barCapitalContable" class="w3-row w3-center"
            [scheme]="colorSchemeCapitalContable"
            [showGridLines]="false"
            [results]="singleCapitalContable"
            [xAxis]="showXAxisCapitalContable"
            [yAxis]="showYAxisCapitalContable"
            [legend]="showLegendCapitalContable"
            [showXAxisLabel]="showXAxisLabelCapitalContable"
            [showYAxisLabel]="showYAxisLabelCapitalContable"
            [xAxisLabel]="xAxisLabelCapitalContable"
            [yAxisLabel]="yAxisLabelCapitalContable"
            [showDataLabel]="showDataLabelCapitalContable"
            [maxYAxisTickLength]="8">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="linCapitalContable_modal">
          <ngx-charts-line-chart id="linealCapitalContable" class="w3-row w3-center"
            [results]="multiCapitalContable"
            [xAxis]="true"
            [scheme]="colorSchemeCapitalContable"
            [xAxis]="showXAxisCapitalContable"
            [yAxis]="showYAxisCapitalContable"
            [legend]="showLegendCapitalContable"
            [showXAxisLabel]="showXAxisLabelCapitalContable"
            [showYAxisLabel]="showYAxisLabelCapitalContable"
            [xAxisLabel]="xAxisLabelCapitalContable"
            [yAxisLabel]="yAxisLabelCapitalContable"
            [maxYAxisTickLength]="8"
            [showGridLines]="false">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" style="height: 50vh; width: 98%;" *ngIf="areCapitalContable_modal">
          <ngx-charts-area-chart id="linealCapitalContable" class="w3-row w3-center"
            [results]="multiCapitalContable"
            [xAxis]="true"
            [scheme]="colorSchemeCapitalContable"
            [xAxis]="showXAxisCapitalContable"
            [yAxis]="showYAxisCapitalContable"
            [legend]="showLegendCapitalContable"
            [showXAxisLabel]="showXAxisLabelCapitalContable"
            [showYAxisLabel]="showYAxisLabelCapitalContable"
            [xAxisLabel]="xAxisLabelCapitalContable"
            [yAxisLabel]="yAxisLabelCapitalContable"
            [maxYAxisTickLength]="8"
            [showGridLines]="false">
          </ngx-charts-area-chart>
        </div>
        <!-- FIN GRAFICAS CAPITAL CONTABLE -->
      </div>
    </div>
  </div>
</div>