import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data-service/data.service';

import jwtDecode from 'jwt-decode';
import { TokenModel } from 'src/app/model/token-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent{
  public modulosAct = [];
  constructor(
    public dataService: DataService,
    private router: Router,) {
    this.menu();
  }
  menu(): void{
    var aux = [];
    for(const mod of this.dataService.fillerNav){
      if(mod.name != "Inicio" && mod.submodules.length > 0){
        aux.push(mod);
      }
    }
    this.modulosAct = aux;
  }
  lado(index){
    if(index%2 === 0){
      return 'w3-half w3-border w3-border-white w3-hover-inicio';
    }else{
      return 'w3-rest w3-border w3-border-white w3-hover-inicio';
    }
  }
  enviar(modulo){
    this.router.navigateByUrl('/app/'+ modulo.submodules[0].route);
  }
}
