<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <!-- TITULO DEL COMPONENTE -->
    <div class="w3-quarter padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
      </div>
    </div>
    <!-- FIN TITULO DEL COMPONENTE -->

    <!-- FILTROS-->
    <div class="w3-rest ">
      <!-- DEFINICION FILTROS -->
      <div class="w3-noventa2 w3-row ">
        <!-- AÑOS -->
        <div class="w3-third padreizquierda">
          <!-- FILTRO AÑOS DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            Año
            <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-tiny"  #menuanios="matMenu" >
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                (change)="anioChangeListener(anio)">
                    {{anio.value}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO AÑOS DISPONIBLES -->

          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest" style="height: 4.5vh;">
            <div class="w3-row padreizquierda" style="overflow-y:auto; height: 3vh; margin-top: 2vh;">
              <div class="w3-row " >
                <div *ngFor="let anio of aniosDisponibles"
                     [ngClass]="anio.check?
                          'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                     class="filtroMesAnioSeleccionado" style="margin-bottom: 0.8vh;" (click)="anioChangeListener(anio)">
                  {{anio.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>
        <!-- FIN AÑOS -->

        <!-- MESES -->
        <div [class]="VerificarAniosSeleccionados()" >
          <!-- FILTRO MESES DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            Mes
            <button class="w3-button w3-tiny"
                    [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-tiny"  #menumeses="matMenu" >
              <ng-container>
                <button mat-menu-item>
                  <mat-checkbox [checked]="Complete()"
                                (click)="$event.stopPropagation();"
                                (change)="mesChangeAll($event.checked)">
                    Todos los meses
                  </mat-checkbox>
                </button>
              </ng-container>
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}"
                                (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                    {{mes.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO MESES DISPONIBLES -->

          <!-- MES SELECCIONADO -->
          <div class="w3-rest" style="height: 4.5vh;">
            <div class="w3-row padreizquierda" style="overflow-y:auto; height: 3vh; margin-top: 2vh;">
              <div class="w3-row " >
                <!--*ngIf="mesSeleccionado"-->
                <div  *ngFor="let mes of mesesDeAnioDisponibles"
                  [ngClass]="mes.check?
                  'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                  class="filtroMesAnioSeleccionado" style="margin-bottom: 0.8vh;" (click)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                <span>{{mes.name}}</span>
                <!--<span *ngIf="Complete() && nummessel()">Todos</span>
                <span *ngIf="!Complete() && !nummessel()">Selección</span>--> 
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        <!-- FIN MESES -->

        <!-- CONSOLIDADO Y SUCURSALES -->
        <div [class]="hizoPers()">
          <div class="w3-third" *ngIf="puedeFiltrarPorSucursales">
            <div class="w3-half hijoizquierda">
              Entidad
              <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuentidad">
                <mat-icon class="w3-tiny">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-tiny"  #menuentidad="matMenu" >
                <button mat-menu-item>
                  <mat-checkbox name="consolidado" [checked]="consolidado"
                                (click)="$event.stopPropagation();"
                                (change)="consolidadoChangeListener()"
                                aria-label="First checkbox">
                    Consolidado
                  </mat-checkbox>
                </button>
                <button mat-menu-item *ngIf="!consolidado" [matMenuTriggerFor]="sucursales">Sucursales</button>
              </mat-menu>
              <mat-menu #sucursales="matMenu">
                <ng-container *ngFor="let sucursal of sucursalesDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="sucursal.check"
                      (change)="sucursalChangeListener(sucursal)"
                      (click)="$event.stopPropagation();">
                      {{sucursal.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <div class="w3-rest">
              <div class="w3-row padreizquierda">
                <div class="w3-row hijoizquierda ">
                  <div *ngIf="consolidado"
                       [ngClass]="'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu'"
                       class="filtroMesAnioSeleccionado">
                    <span>CONSOLIDADO</span> 
                  </div>
                  <div *ngIf="!consolidado && !sucursalSeleccionada"
                       [ngClass]="'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu'"
                       class="filtroMesAnioSeleccionado">
                    <span>Selección</span> 
                  </div>
                  <div *ngIf="sucursalSeleccionada"
                       [ngClass]="'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu'"
                       class="filtroMesAnioSeleccionado">
                    <span>{{sucursalSeleccionada.name}}</span> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIN CONSOLIDADO Y SUCURSALES -->
      </div>
      <!-- FIN DEFINICION FILTROS -->

      <!-- BORRAR FILTROS -->
      <div class="w3-rest w3-right ">
        <button mat-icon-button>
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitarFiltros()">
            <img src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
      <!-- FIN BORRAR FILTROS -->
    </div>
    <!-- FIN FILTROS-->
  </div>
</div>

<div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">
  <div class="w3-row w3-contenedor-template " style="height: 41vh;">
    <!-- PARTE DE ARRIBA -->
    <div id="zona1" class="w3-row w3-white" style="height: 100%;">
      <!-- PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
      <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
        <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
          <div style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;" class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido">Tendencia</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGraficaTabla}}</span>
          </div>
        </div>

        <div class="w3-third w3-right-align_rest ">

          <div class="w3-right hijoderechaTitle">
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
              more_vert</mat-icon>
          </div>

          <mat-menu #menu="matMenu">
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
              <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
              <span>Gráficas</span>
            </button>
            <button *ngIf="puedeFiltrarPorPerspectivas" class="mat-menu-item " mat-menu-item [matMenuTriggerFor]="perspectivas">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
              </mat-icon>
              <span>Perspectiva</span>
            </button>
            <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
              <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
              <span>Exportar</span>
            </button>
            <mat-divider></mat-divider>
            <!-- BOTON ELIMINAR FILTROS -->
            <button (click)="quitarFiltros()" class="mat-menu-item " mat-menu-item>
              <mat-icon [ngStyle]="{'color':'gray'}">
                <img style="margin-top: -10px;" src="assets/img/graficos/sinfiltro.png">
              </mat-icon>
              <span>Eliminar Filtros</span>
            </button>
            <button class="mat-menu-item" mat-menu-item (click)="AmpliarC1()">
              <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
              <span>Ampliar</span>
            </button>
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="metric">
              <mat-icon [ngStyle]="{'color':'gray'}">tag</mat-icon>
              <span>Métrica</span>
            </button>
          </mat-menu>
          
          <mat-menu #graficas="matMenu">

            <button mat-menu-item (click)="cambioGrafC1('barra')">
              <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
              <span>Barras</span>
            </button>
            <button mat-menu-item (click)="cambioGrafC1('lineal')">
              <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
              <span>Lineal</span>
            </button>
            <button mat-menu-item (click)="cambioGrafC1('area')">
              <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
              <span>Área</span>
            </button>
            <button mat-menu-item (click)="cambioGrafC1('tabla')">
              <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
              <span>Tabla</span>
            </button>
            <!--<button mat-menu-item (click)="cambioGrafC1('compuesta')">
              <mat-icon [ngStyle]="{'color':'gray'}">leaderboard</mat-icon>
              <span>Compuesta</span>
            </button>-->

          </mat-menu>

          <mat-menu #metric="matMenu">

            <button mat-menu-item (click)="metrica('sal')">
              <span>Saldo total</span>
            </button>
            <button mat-menu-item (click)="metrica('num')">
              <span>Número de cuentas</span>
            </button>

          </mat-menu>

          <mat-menu #perspectivas="matMenu">

            <button mat-menu-item
                    (click)="getGraficaPorPerspectiva('sexo.keyword')">
              <span>Género</span>
            </button>
            <button mat-menu-item
                    (click)="getGraficaPorPerspectiva('servicio.keyword')">
              <span>Servicio</span>
            </button>
            <button mat-menu-item
                    (click)="getGraficaPorPerspectiva('nombreProducto.keyword')">
              <span>Producto</span>
            </button>
            <button mat-menu-item
                    (click)="getGraficaPorPerspectiva('estado1.keyword')">
              <span>Estatus</span>
            </button>
            <button mat-menu-item
                    (click)="getGraficaPorPerspectiva('municipio1.keyword')">
              <span>Municipio</span>
            </button>

          </mat-menu>

          <mat-menu #exportar="matMenu">
            <button clas="w3-icon-visibility w3-hide"
                    mat-menu-item *ngIf="!graficatabla1" (click)="export_imagen(1)">
              <span>PNG</span>
            </button>
            <button mat-menu-item *ngIf="graficatabla1" (click)="export_excel(1)">
              <span>CSV</span>
            </button>
          </mat-menu>

        </div>
      </div>
      <!-- FIN PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->

      <!-- PARTE DE ARRIBA, CONTENDOR DE LAS GRAFICAS -->
      <div class="w3-row" >
        <!-- GRAFICA DE BARRAS -->
        <div *ngIf="esGraficaDeBarras && datosBarra && graficabarras" class="chart_wrap w3-scroll">
          <ngx-charts-bar-vertical *ngIf="saldo"
            class="bar-half-google w3-labels-ngx-charts"
            [scheme]="colorScheme"
            [results]="datosBarra"
            [xAxis] = "true"
            [yAxis] = "true" 
            [animations]="true"            
            [yScaleMin] = "minimo"
            (select)="clickOnBarra($event)"
            [customColors]="customColors"
            [yAxisTickFormatting]="yLeftTickFormat">
          </ngx-charts-bar-vertical>
          <ngx-charts-bar-vertical *ngIf="numer"
            class="bar-half-google w3-labels-ngx-charts"
            [scheme]="colorScheme"
            [results]="dataBarraNum"
            [xAxis] = "true"
            [yAxis] = "true" [animations]="true"
            (select)="clickOnBarra($event)"
            [customColors]="customColors"
            [yAxisTickFormatting]="yLeftTickFormat">
          </ngx-charts-bar-vertical>
          
        </div>
        <div *ngIf="graficacompuesta" style="height: 35vh; width: 98%;">
          <combo-chart-component
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [results]="datosBarra"
            [animations]="animations"
            [lineChart]="datalinealcuentas"
            [yAxisTickFormatting]="yLeftTickFormat"
            [yLeftAxisScaleFactor]="yLeftAxisScale"
            [yRightAxisScaleFactor]="yRightAxisScale"
            [yRightAxisTickFormatting]="yRightTickFormat"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel1"
            (select)="clickOnBarra($event)">
          </combo-chart-component>
        </div>
        <div *ngIf="graficalineal" class="chart_wrap w3-scroll">
          <ngx-charts-line-chart *ngIf="saldo"
            [scheme]="colorScheme"
            [results]="dataLineal1"
            [xAxis]="showXAxis"
            [legend]="'true'"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="clickOnBarra($event)"
            [customColors]="customColors"
            [yAxisTickFormatting]="yLeftTickFormat">
          </ngx-charts-line-chart>
          <ngx-charts-line-chart *ngIf="numer"
            [scheme]="colorScheme"
            [results]="datalinealcuentas"
            [xAxis]="showXAxis"
            [legend]="'true'"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="clickOnBarra($event)"
            [customColors]="customColors"
            [yAxisTickFormatting]="yLeftTickFormat">
          </ngx-charts-line-chart>
        </div>
        <div *ngIf="graficaarea" class="chart_wrap w3-scroll">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [results]="dataLineal1"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="clickOnBarra($event)"
            [customColors]="customColors"
            [yAxisTickFormatting]="yLeftTickFormat">
            </ngx-charts-area-chart>
        </div>
        <div class="w3-row" *ngIf="graficatabla1" style="height: 36vh;width: 98%; overflow-y:auto;">

          <table class="w3-table-all" mat-table [dataSource]="datatabla" >
            <ng-container matColumnDef="anio">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> {{tit1()}} </th>
              <td mat-cell style="width: 10%;" *matCellDef="let element"> {{element.anio}} </td>
            </ng-container>

            <ng-container matColumnDef="mes">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> {{tit2()}} </th>
              <td mat-cell style="width: 10%;" *matCellDef="let element"> {{element.mes}} </td>
            </ng-container>

            <ng-container matColumnDef="ncuentas">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Número de cuentas </th>
              <td mat-cell style="width: 15%;" *matCellDef="let element"> {{formatearnormal(element.ncuen)}} </td>
            </ng-container>

            <ng-container matColumnDef="saldo">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Cartera total </th>
              <td mat-cell style="width: 20%;" *matCellDef="let element"> {{formatearnormal(element.saldo.toFixed(2))}} </td>
            </ng-container>

            <ng-container matColumnDef="cvig">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Cartera vigente </th>
              <td mat-cell style="width: 20%;" *matCellDef="let element"> {{formatearnormal(element.cvig.toFixed(2))}} </td>
            </ng-container>

            <ng-container matColumnDef="cven">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Cartera vencida</th>
              <td mat-cell style="width: 15%;" *matCellDef="let element"> {{formatearnormal(element.cven.toFixed(2))}} </td>
            </ng-container>

            <ng-container matColumnDef="imor">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Índice de morosidad </th>
              <td mat-cell style="width: 10%;" *matCellDef="let element">% {{formatearnormal(element.imor)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columna;sticky: true;" class="w3-table-title"></tr>
            <tr mat-row *matRowDef="let row; columns: columna;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
          </table>

        </div>
      </div>
      <!-- FIN PARTE DE ARRIBA, CONTENDOR DE LAS GRAFICAS -->
    </div>
    <!-- FIN PARTE DE ARRIBA -->
    <!-- PARTE DE ABAJO -->
    <div class="w3-row mt-2 ">
      <div id="zona2" class="w3-half_e w3-white">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh;">
          <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="w3-row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
              <span class="w3-margin-left-contenido ">Tendencia</span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
              <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica2}}</span>
            </div>
          </div>
          <div class="w3-right hijoderechaTitle">
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu2">
              more_vert</mat-icon>
          </div>
          <div class="w3-rest w3-right-align_rest " >
            <mat-menu #menu2="matMenu">
              <!--<mat-divider></mat-divider>-->
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas2">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span>Gráficas</span>
              </button>
              <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar2">
                <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                <span>Exportar</span>
              </button>
              <button class="mat-menu-item" mat-menu-item (click)="AmpliarC2()">
                <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                <span>Ampliar</span>
              </button>
            </mat-menu>
            <mat-menu #graficas2="matMenu">

              <button mat-menu-item [matMenuTriggerFor]="tipoBar">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span>Barras</span>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="tipoLin">
                <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                <span>Lineal</span>
              </button>
              <button mat-menu-item (click)="cambioGrafC2('tabla')">
                <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                <span>Tabla</span>
              </button>
            </mat-menu>

            <mat-menu #tipoLin=""matMenu>
              <button mat-menu-item (click)="cambioGrafC2('linealS')">
                <span>Cartera total</span>
              </button>
              <button mat-menu-item (click)="cambioGrafC2('linealC')">
                <span>Número de credito</span>
              </button>
            </mat-menu>

            <mat-menu #tipoBar=""matMenu>
              <button mat-menu-item (click)="cambioGrafC2('barraS')">
                <span>Cartera total</span>
              </button>
              <button mat-menu-item (click)="cambioGrafC2('barraC')">
                <span>Número de credito</span>
              </button>
            </mat-menu>

            <mat-menu #exportar2="matMenu">
              <button clas="w3-icon-visibility w3-hide" *ngIf="!graficatabla" (click)="export_imagen(2)"
                      mat-menu-item>
                <span>PNG</span>
              </button>
              <button mat-menu-item *ngIf="graficatabla" (click)="export_excel(2)">
                <span>CSV</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="w3-row w3-white" style="height: 32vh">
          <div class="w3-row" *ngIf="graficatabla" style="height: 32vh;">
                
            <div class="w3-encabezado-table" style="overflow-y:auto; background-color: #F9F9F9;">
              <div class="mat-elevation-z8">
                <table class=" w3-table">
                  <thead style="background-color: #FFFFFF;">
                      <tr style="height: 4vh;">
                        <th class="w3-center w3-text-deep-orange"  style=" font-size: 14pt; background-color: #FFFFFF;" ><span>Periodo</span></th>
                        <th class="w3-center w3-text-deep-orange"  style=" font-size: 14pt; background-color: #FFFFFF;" ><span>Cartera total</span></th>
                        <th class="w3-center w3-text-deep-orange"  style=" font-size: 14pt; background-color: #FFFFFF;" ><span>Número de cuenta</span></th>
                      </tr>
                  </thead>
                  <tbody *ngFor="let element of datazona2">
                    <tr *ngIf="element.check">
                      <td *ngIf="element.anio && element.exp"> <mat-icon (click)="clictabla(element,2)" class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"> minimize</mat-icon> {{element.name}} </td>
                      <td *ngIf="element.anio && !element.exp"> <mat-icon (click)="clictabla(element,2)" class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"> add</mat-icon> {{element.name}} </td>
                      <td *ngIf="!element.anio" style="margin-left: 10vh;"> <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"></mat-icon> {{element.name}}</td>
                      <td class="w3-center"> <span *ngIf="!element.anio">$</span> {{formatearnormal(element.salC)}}</td>
                      <td class="w3-center">{{element.numC}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div class="w3-row" *ngIf="graficabarrasSal" style="height: 28vh;">
            <ngx-charts-bar-vertical
              [scheme]="colorScheme"
              [results]="dataSalCapBar"
              [xAxis]="true"
              [yAxis]="true" 
              [animations]="true">
            </ngx-charts-bar-vertical>
          </div>
          <div class="w3-row" *ngIf="graficalinealSal" style="height: 28vh;">
            <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataSalCapLin"
            [xAxis]="showXAxis"
            [legend]="'true'"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
          </div>
          <div class="w3-row" *ngIf="graficabarrasCre" style="height: 28vh;">
            <ngx-charts-bar-vertical
              [scheme]="colorScheme"
              [results]="dataNumCreBar"
              [xAxis]="true"
              [yAxis]="true" 
              [animations]="true">
            </ngx-charts-bar-vertical>
          </div>
          <div class="w3-row" *ngIf="graficalinealCre" style="height: 28vh;">
            <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataNumCreLin"
            [xAxis]="showXAxis"
            [legend]="'true'"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>

      <div class="w3-half_i w3-right w3-contenedor-template contenedorTarjetas" style="height: 40vh;">
        <!-- TARJETA SUPERIOR -->
        <div class="w3-right w3-white tarjetas" style="height: 11vh;">
          <div class="w3-row padreTendencia">
            <div class="w3-quarter hijoTendencia" >
              <mat-icon [ngStyle]="{'color':'#72D54A'}" class="w3-icon-display w3-icon-template4 w3-right iconos">verified_user</mat-icon>
            </div>
            <div class="w3-third hijoTendenciaV2 O" style="margin-top: 2vh; text-align: center;" matTooltip="{{subtituloTarjeta2}}" matTooltipPosition="above" matTooltipShowDelay="1000">
              <span class="w3-tipografia-cifras w3-margin-left-contenido textoCifra">
                Cartera vigente
              </span>
              <br>
              <span class="w3-subtitulo">{{subtituloTarjeta2}}</span>
            </div>
            <div class="w3-rest  hijoTendenciaV2" style="margin-top: 2vh; margin-right: 1vh;">
              <mat-icon class="w3-margin-right-contenido"></mat-icon>
              <span class="w3-text-cifras w3-margin-left-contenido cifra w3-balance-bluep" style="font-weight: bold;">
                $ {{formatearnormal(cartera_vigente.toFixed(2))}}
              </span>
            </div>
          </div>
        </div>
        <!-- FIN TARJETA SUPERIOR -->
        <!-- TARJETA DE EN MEDIO -->
        <div class="w3-white w3-right tarjetas" style="margin-top: 1%; height: 11vh;">
          <div class="w3-row padreTendencia">
            <div class="w3-quarter hijoTendencia">
              <mat-icon [ngStyle]="{'color':'#ff6b00'}" class="w3-icon-display w3-icon-template4 w3-right iconos">gpp_maybe</mat-icon>
            </div>
            <div class="w3-third hijoTendenciaV2 O" style="margin-top: 2vh; text-align: center;" matTooltip="{{subtituloTarjeta2}}" matTooltipPosition="above" matTooltipShowDelay="1000">
              <span class="w3-tipografia-cifras w3-margin-left-contenido textoCifra">
                Cartera vencida
              </span>
              <br>
              <span class="w3-subtitulo">{{subtituloTarjeta2}}</span>
            </div>
            <div class="w3-rest  hijoTendenciaV2" style="margin-top: 2vh; margin-right: 1vh;">
              <mat-icon class="w3-margin-right-contenido"></mat-icon>
              <span class="w3-text-cifras w3-margin-left-contenido cifra w3-balance-bluep" style="font-weight: bold;">
                $ {{formatearnormal(cartera_vencida.toFixed(2))}}
              </span>
            </div>
          </div>
        </div>
        <!-- FIN TARJETA DE EN MEDIO -->
        <!-- TARJETA INFERIOR -->
        <div class="w3-white w3-right tarjetas" style="margin-top: 1%; height: 11vh;">
          <div class="w3-row padreTendencia">
            <div class="w3-quarter hijoTendencia2">
              <p class="iconos2 w3-right" style="margin-right: 2vh;"><mat-icon [ngStyle]="{'color':'#0075C9'}" class="w3-icon-display w3-icon-template4 iconos">recommend</mat-icon></p>
            </div>
            <div class="w3-third hijoTendenciaV2 O" style="margin-top: 2vh; text-align: center; height: 2.5vh;" matTooltip="{{subtituloTarjeta2}}" matTooltipPosition="above" matTooltipShowDelay="1000">
              <span class="w3-tipografia-cifras w3-margin-left-contenido textoCifra">
                Índice de morosidad
              </span>
              <br>
              <span class="w3-subtitulo">{{subtituloTarjeta2}}</span>
            </div>
            <div class="w3-rest  hijoTendenciaV2" style="margin-top: 2vh; margin-right: 1vh;">
              <mat-icon class="w3-margin-right-contenido"></mat-icon>
              <span class="w3-text-cifras w3-margin-left-contenido cifra w3-balance-bluep" style="font-weight: bold;">
                % {{formatearnormal(indice_morosidad.toFixed(2))}}
              </span>
            </div>
          </div>
        </div>
        <!-- FIN TARJETA INFERIOR -->
      </div>
    </div>
    <!-- FIN PARTE DE ABAJO -->
  </div>
</div>

<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <!--Parte superior-->
              <div class="w3-row">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                  <div class="hijoizquierdaTitle">
                    <span class="w3-margin-left-contenido">Tendencia</span>
                    <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGraficaTabla}}</span>
                  </div>
                </div>
              </div>
              <!--Fin de la parte superior-->
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <div class="w3-row" *ngIf="graficabarras_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="datosBarra"
            [xAxis] = "true"
            [yAxis] = "true" [animations]="true"
            (select)="clickOnBarra($event)"
            [customColors]="customColors"
            [yAxisTickFormatting]="yLeftTickFormat">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalineal_modal" style="height: 52vh;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataLineal1"
            [xAxis]="showXAxis"
            [legend]="'true'"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficaarea_modal" style="height: 52vh;">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [results]="dataLineal1"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-area-chart>
        </div>
        <div class="w3-row" *ngIf="graficatabla1_modal" style="height: 52vh; width: 98%;">
          <div class="w3-encabezado-table" style="overflow-y:auto; background-color: #F9F9F9;">
            <div class="mat-elevation-z8">
              <table class=" w3-table">
                <thead>
                    <tr style="height: 4vh;">
                      <th class="w3-center w3-text-deep-orange w3-elemento-titulo-table"  style=" font-size: 14pt;" ><span>Periodo</span></th>
                      <th class="w3-center w3-text-deep-orange w3-elemento-titulo-table"  style=" font-size: 14pt;" ><span>Cartera total</span></th>
                      <th class="w3-center w3-text-deep-orange w3-elemento-titulo-table"  style=" font-size: 14pt;" ><span>Número de cuenta</span></th>
                    </tr>
                </thead>
                <tbody *ngFor="let element of datatabla">
                  <tr *ngIf="element.check">
                    <td *ngIf="element.anio && element.exp"> <mat-icon (click)="clictabla(element,1)" class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"> minimize</mat-icon> {{element.name}} </td>
                    <td *ngIf="element.anio && !element.exp"> <mat-icon (click)="clictabla(element,1)" class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"> add</mat-icon> {{element.name}} </td>
                    <td *ngIf="!element.anio" style="margin-left: 10vh;"> <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"></mat-icon> {{element.name}}</td>
                    <td class="w3-center"> <span *ngIf="!element.anio">$</span> {{formatearnormal(element.salC)}}</td>
                    <td class="w3-center">{{formatearnormal(element.numC)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row" *ngIf="graficacompuesta_modal" style="height: 52vh; width: 98%;">
          <combo-chart-component
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [results]="datosBarra"
            [animations]="animations"
            [lineChart]="datalinealcuentas"
            [yAxisTickFormatting]="yLeftTickFormat"
            [yLeftAxisScaleFactor]="yLeftAxisScale"
            [yRightAxisScaleFactor]="yRightAxisScale"
            [yRightAxisTickFormatting]="yRightTickFormat"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel1">
          </combo-chart-component>
        </div>
        <!--Segundas graficas-->
        <div class="w3-row" *ngIf="graficatabla_modal" style="height: 52vh;">
          <div class="w3-encabezado-table" style="overflow-y:auto; background-color: #F9F9F9;">
            <div class="mat-elevation-z8">
              <table class=" w3-table">
                <thead>
                    <tr style="height: 4vh;">
                      <th class="w3-center w3-text-deep-orange"  style=" font-size: 14pt; background-color: #FFFFFF;" ><span>Periodo</span></th>
                      <th class="w3-center w3-text-deep-orange"  style=" font-size: 14pt; background-color: #FFFFFF;" ><span>Cartera total</span></th>
                      <th class="w3-center w3-text-deep-orange"  style=" font-size: 14pt; background-color: #FFFFFF;" ><span>Número de cuenta</span></th>
                    </tr>
                </thead>
                <tbody *ngFor="let element of datazona2">
                  <tr *ngIf="element.check">
                    <td *ngIf="element.anio && element.exp"> <mat-icon (click)="clictabla(element,2)" class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"> minimize</mat-icon> {{element.name}} </td>
                    <td *ngIf="element.anio && !element.exp"> <mat-icon (click)="clictabla(element,2)" class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"> add</mat-icon> {{element.name}} </td>
                    <td *ngIf="!element.anio" style="margin-left: 10vh;"> <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}"></mat-icon> {{element.name}}</td>
                    <td class="w3-center"> <span *ngIf="!element.anio">$</span> {{formatearnormal(element.salC)}}</td>
                    <td class="w3-center">{{formatearnormal(element.numC)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row" *ngIf="graficabarrasSal_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="dataSalCapBar"
            [xAxis]="true"
            [yAxis]="true" 
            [animations]="true">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalinealSal_modal" style="height: 52vh;">
          <ngx-charts-line-chart
          [scheme]="colorScheme"
          [results]="dataSalCapLin"
          [xAxis]="showXAxis"
          [legend]="'true'"
          [legendTitle]="legendTitle"
          [legendPosition]="'right'"
          [yAxis]="showYAxis"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficabarrasCre_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="dataNumCreBar"
            [xAxis]="true"
            [yAxis]="true" 
            [animations]="true">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalinealCre_modal" style="height: 52vh;">
          <ngx-charts-line-chart
          [scheme]="colorScheme"
          [results]="dataNumCreLin"
          [xAxis]="showXAxis"
          [legend]="'true'"
          [legendTitle]="legendTitle"
          [legendPosition]="'right'"
          [yAxis]="showYAxis"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-line-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- The Modal -->