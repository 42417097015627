import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {EvaluacionEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionService extends Service<EvaluacionEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/Evaluacion', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: EvaluacionEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    
    if (null != entity.Id_Evaluacion) { params = params.set('Id_Evaluacion', entity.Id_Evaluacion.toString()); }
    if (null != entity.Id_Institucion) { params = params.set('Id_Institucion', entity.Id_Institucion.toString()); }
    if (null != entity.Id_Usuario) { params = params.set('Id_Usuario', entity.Id_Usuario.toString()); }
    if (null != entity.Fecha_Evaluacion) { params = params.set('Fecha_Evaluacion', entity.Fecha_Evaluacion.toString()); }
    if (null != entity.Fecha_Contable) { params = params.set('Fecha_Contable', entity.Fecha_Contable.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Observacion) { params = params.set('Observacion', entity.Observacion.toString()); }
    if (null != entity.Id_Tipo_Evaluacion) { params = params.set('Id_Tipo_Evaluacion', entity.Id_Tipo_Evaluacion.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }
    if (null != entity.Id_Tipo_Institucion) { params = params.set('Id_Tipo_Institucion', entity.Id_Tipo_Institucion.toString()); }
    if (null != entity.Estado) { params = params.set('Estado', entity.Estado.toString()); }
    if (null != entity.VistoBueno) { params = params.set('VistoBueno', entity.VistoBueno.toString()); }
    if (null != entity.check) { params = params.set('check', entity.check.toString()); }

    
    return params;
  }
}
