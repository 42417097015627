<div class="w3-row w3-container-header w3-border w3-border-gray " >
  <div class="w3-half w3-margin-top-header">
    <table class="">
      <tr>
        <td>
          <mat-icon class="w3-margin-left-header" style="cursor: pointer;" (click)="sidenav.toggle()" [ngStyle]="{'color':'gray'}">menu</mat-icon>
        </td>
        <td>
          <mat-icon  (click)="home()" class="w3-margin-left-header"><img style=" margin-top: -10px;" src="assets/img/logoaleema.png" > </mat-icon>
        </td>
      </tr>
    </table>
  </div>
  <div class="w3-half w3-right w3-right-align w3-margin-top-header">
    <div class="w3-half w3-right " >
      <div class="w3-row ">
        <div class="w3-threequarter w3-border-right w3-icon-header " style="height: 100%;">
          <div class="w3-twothird" style="margin-top: 4px;">
            <span class='w3-letra_header w3-icon-header'>{{dataService.currentUser}} </span>
          </div>
          <div class="w3-third_e">
            <mat-icon class="w3-margin-right-header" [ngStyle]="{'color':'orange'}">face</mat-icon>
          </div>
        </div>
        <div class="w3-quarter_e">
          <button mat-icon-button class="w3-margin-right-header" (click)="logout()" style="margin: 4px;">
            <mat-icon [ngStyle]="{'color':'gray'}">exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
