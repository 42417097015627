<div class="w3-container w3-contenedor-template" style="height: 7vh;">
    <div class="w3-row ">
      <!-- TITULO DEL COMPONENTE -->
      <div class="w3-quarter padreizquierda ">
        <div class="w3-row hijoizquierda">
          <!--<span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }} </b></span>-->
          <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> Riesgo de crédito </b></span>
          <span class="w3-text-deep-orange">&bull;</span>
          <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal">{{ dataService.currentSubmodule }}</span>
        </div>
      </div>
      <!-- FIN TITULO DEL COMPONENTE -->
      <!-- FILTROS-->
      <div class="w3-rest ">
        <!-- DEFINICION FILTROS -->
        <div class="w3-noventa2 w3-row ">
          <!-- AÑOS -->
          <div class="w3-third padreizquierda">
            <!-- FILTRO AÑOS DISPONIBLES -->
            <div class="w3-third hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios">
                Año
                <mat-icon class="iconoMen" >expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium" #menuanios="matMenu" >
                <ng-container *ngFor="let anio of aniosDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{anio.name}}" aria-label="First checkbox"
                                  (change)="anioChange(anio)">
                      {{anio.value}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO AÑOS DISPONIBLES -->
  
            <!-- AÑO SELECCIONADO -->
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda chips">
                  <div [ngClass]="anios.check?
                  'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                    *ngFor="let anios of aniosDisponibles"
                    class="filtroMesAnioSeleccionado">
                    <span class="contenidoChips">{{anios.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN AÑO SELECCIONADO -->
          </div>
          <!-- FIN AÑOS -->
  
          <!-- MESES -->
          <div class="w3-third " >
            <!-- FILTRO MESES DISPONIBLES -->
            <div class="w3-third hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
                Mes
                <mat-icon class="iconoMen" >expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium" #menumeses="matMenu" >
                <ng-container *ngFor="let mes of mesesDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{mes.name}}"
                                  (change)="mesChange(mes, mesesDeAnioDisponibles)">
                      {{mes.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO MESES DISPONIBLES -->
  
            <!-- MES SELECCIONADO -->
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda chips">
                  <div [ngClass]="mes.check?
                  'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                    *ngFor="let mes of mesesDisponibles"
                    class="filtroMesAnioSeleccionado">
                    <span class="contenidoChips">{{mes.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN MES SELECCIONADO -->
          </div>
          <!-- FIN MESES -->
        </div>
        <!-- FIN DEFINICION FILTROS -->
  
        <!-- BORRAR FILTROS -->
        <div class="w3-rest w3-right ">
          <button mat-icon-button>
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitarFiltros()">
              <img src="assets/img/graficos/eraser.png">
            </mat-icon>
          </button>
        </div>
        <!-- FIN BORRAR FILTROS -->
      </div>
      <!-- FIN FILTROS-->
    </div>
</div>

<div class="w3-container w3-contenedor-template " style="height: 85vh;">
  <div class="w3-row w3-contenedor-template " style="height: 41vh;">
    <!-- PARTE DE ARRIBA -->
    <div class="w3-row w3-white" style="height: 100%;" id="Zona1">
      <!-- PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
      <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
        <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido" *ngIf="!tabla1 && !porPorcent"> Saldo por mora</span>
            <span class="w3-margin-left-contenido" *ngIf="!tabla1 && porPorcent"> Porcentaje por mora</span>
            <span class="w3-margin-left-contenido" *ngIf="tabla1 && !porPorcent">{{milTab1}} Saldo por mora</span>
            <span class="w3-margin-left-contenido" *ngIf="tabla1 && porPorcent"> Porcentaje por mora</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica}}</span>
          </div>
        </div>
        <div class="w3-rest w3-right-align_rest ">
          <div class="w3-right hijoderechaTitle">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliar('tabla1')" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="salOpor">
              <span>mostrar por</span>
            </button>
            <mat-divider></mat-divider>
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
              <span>Gráficas</span>
            </button>
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" *ngIf="tabla1 && !porPorcent" [matMenuTriggerFor]="moneda2">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">paid</mat-icon>
              <span>mil/pes</span>
            </button>
            <button mat-menu-item *ngIf="tabla1" [matMenuTriggerFor]="export">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar</span>
            </button>
            <button mat-menu-item *ngIf="!tabla1" [matMenuTriggerFor]="export">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="Perspectiva">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree</mat-icon>
              <span>Perspectiva</span>
            </button>
            <!-- BOTON ELIMINAR FILTROS -->
            <button *ngIf="perspectiva" (click)="quitarFiltros()" class="mat-menu-item " mat-menu-item>
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                <img style="margin-top: -10px;" src="assets/img/graficos/sinfiltro.png">
              </mat-icon>
              <span>Eliminar Filtros</span>
            </button>
          </mat-menu>

          <mat-menu #export="matMenu">
            <button mat-menu-item *ngIf="!tabla1" (click)="export_img(1)">
              <span>PNG</span>
            </button>
            <button mat-menu-item *ngIf="tabla1" (click)="export_csv(1)">
              <span>CSV</span>
            </button>
          </mat-menu>

          <mat-menu #moneda2="matMenu">
            <button mat-menu-item (click)="CambMon('Mil',1)">
              <span>Miles</span>
            </button>
            <button mat-menu-item (click)="CambMon('Pesos',1)">
              <span>Pesos</span>
            </button>
          </mat-menu>

          <mat-menu #salOpor="matMenu">
            <button mat-menu-item (click)="MostrarPor('saldo')">
              <span>Saldo</span>
            </button>
            <button mat-menu-item (click)="MostrarPor('porcent')">
              <span>Porcentaje</span>
            </button>
          </mat-menu>

          <mat-menu #Perspectiva="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="Products">
              <span>Productos</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="Zonass">
              <span>Zonas </span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="Sector">
              <span>Sector</span>
            </button>
          </mat-menu>

          <mat-menu #Sector="matMenu">
            <ng-container *ngFor="let sec of sectors">
              <button mat-menu-item (click)="cambioSec(sec)">
                {{sec.name}}
              </button>
            </ng-container>
          </mat-menu>

          <mat-menu #Products="matMenu">
            <ng-container *ngFor="let prod of products">
              <button mat-menu-item (click)="cambioProd(prod)">
                {{prod.name}}
              </button>
            </ng-container>
          </mat-menu>

          <mat-menu #Zonass="matMenu">
            <ng-container *ngFor="let zon of zonas">
              <button mat-menu-item (click)="cambioZon(zon)">
                {{zon.name}}
              </button>
            </ng-container>
          </mat-menu>

          <mat-menu #graficas="matMenu">
            <button mat-menu-item (click)="Graficas1('T')">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
              <span>Tabla</span>
            </button>
            <button mat-menu-item (click)="Graficas1('L')">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
              <span>Lineal</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <!-- FIN PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
      <!-- PARTE DE ARRIBA, CONTENDOR DE LAS GRAFICAS -->
      <div class="w3-row" >
          <!-- PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
          <div class="w3-row w3-white" style="height: 35vh">
              <div class="w3-encabezado-table w3-scroll" >
                <div *ngIf="tabla1">
                    <table mat-table [dataSource]="dataSource" class="w3-table-all">

                      <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->
                  
                      <!-- Position Column -->
                      <ng-container matColumnDef="cosecha">
                      <th mat-header-cell *matHeaderCellDef class="w3-table-title w3-center" style="font-size: 0.8vw;"> Cosecha </th>
                      <td mat-cell style="width: 7%;" *matCellDef="let element" class="" style="font-size: 0.8vw;"> {{element.cosecha}} </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="montocolocado">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> Monto colocado </th>
                      <td mat-cell style="width: 9%; font-size: 0.8vw;" *matCellDef="let element" class="w3-light-grey w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.montocolocado}}</span>  </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes1">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-1]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,11)" [ngStyle]="{'color': colorText(element.mes1)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes1) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-1], element.cosecha)" class="w3-button">{{element.mes1}} %</span>
                        <span *ngIf="pintarPorcent(element.mes1) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-1], element.cosecha)" class="w3-button">{{element.mes1}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes2">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-2]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,10)" [ngStyle]="{'color': colorText(element.mes2)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes2) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-2], element.cosecha)" class="w3-button">{{element.mes2}} %</span>
                        <span *ngIf="pintarPorcent(element.mes2) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-2], element.cosecha)" class="w3-button">{{element.mes2}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes3">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-3]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,9)" [ngStyle]="{'color': colorText(element.mes3)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes3) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-3], element.cosecha)" class="w3-button">{{element.mes3}} %</span>
                        <span *ngIf="pintarPorcent(element.mes3) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-3], element.cosecha)" class="w3-button">{{element.mes3}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes4">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-4]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,8)" [ngStyle]="{'color': colorText(element.mes4)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes4) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-4], element.cosecha)" class="w3-button">{{element.mes4}} %</span>
                        <span *ngIf="pintarPorcent(element.mes4) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-4], element.cosecha)" class="w3-button">{{element.mes4}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes5">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-5]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,7)" [ngStyle]="{'color': colorText(element.mes5)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes5) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-5], element.cosecha)" class="w3-button">{{element.mes5}} %</span>
                        <span *ngIf="pintarPorcent(element.mes5) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-5], element.cosecha)" class="w3-button">{{element.mes5}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes6">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-6]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,6)" [ngStyle]="{'color': colorText(element.mes6)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes6) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-6], element.cosecha)" class="w3-button">{{element.mes6}} %</span>
                        <span *ngIf="pintarPorcent(element.mes6) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-6], element.cosecha)" class="w3-button">{{element.mes6}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes7">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-7]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,5)" [ngStyle]="{'color': colorText(element.mes7)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes7) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-7], element.cosecha)" class="w3-button">{{element.mes7}} %</span>
                        <span *ngIf="pintarPorcent(element.mes7) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-7], element.cosecha)" class="w3-button">{{element.mes7}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes8">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-8]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,4)" [ngStyle]="{'color': colorText(element.mes8)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes8) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-8], element.cosecha)" class="w3-button">{{element.mes8}} %</span>
                        <span *ngIf="pintarPorcent(element.mes8) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-8], element.cosecha)" class="w3-button">{{element.mes8}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes9">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-9]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,3)" [ngStyle]="{'color': colorText(element.mes9)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes9) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-9], element.cosecha)" class="w3-button">{{element.mes9}} %</span>
                        <span *ngIf="pintarPorcent(element.mes9) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-9], element.cosecha)" class="w3-button">{{element.mes9}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes10">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-10]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,2)" [ngStyle]="{'color': colorText(element.mes10)}" class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes10) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-10], element.cosecha)" class="w3-button">{{element.mes10}} %</span>
                        <span *ngIf="pintarPorcent(element.mes10) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-10], element.cosecha)" class="w3-button">{{element.mes10}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes11">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-11]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,1)"  [ngStyle]="{'color': colorText(element.mes11)}"class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes11) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-11], element.cosecha)" class="w3-button">{{element.mes11}} %</span>
                        <span *ngIf="pintarPorcent(element.mes11) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-11], element.cosecha)" class="w3-button">{{element.mes11}} </span> </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="mes12">
                      <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-12]}} </th>
                      <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,0)"  [ngStyle]="{'color': colorText(element.mes12)}"class=" w3-border-grey2 w3-right-align">
                        <span *ngIf="pintarPorcent(element.mes12) && porPorcent" style="margin-right: 1vh; font-weight: bold;" (click)="clickTab(nombres_mes[nombres_mes.length-12], element.cosecha)" class="w3-button">{{element.mes12}} %</span>
                        <span *ngIf="pintarPorcent(element.mes12) && !porPorcent" style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-12], element.cosecha)" class="w3-button">{{element.mes12}} </span></td>
                      </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row "></tr>
                    </table>
                </div>
                <div *ngIf="barras1" style="height: 34vh;">
                  <ngx-charts-line-chart class="w3-medium"
                    [scheme]="colorScheme"
                    [legend]="legend"
                    [legendTitle]="legendTitle"
                    [legendPosition]="legendPosition"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [results]="dataBarrasMor"
                    [curve]="curve"
                    >
                  </ngx-charts-line-chart>
                </div>
              </div>
          </div>
      </div>
  </div>
  <!-- PARTE DE ABAJO -->
  <div class="w3-row w3-white" style="height: 100%;" id="Zona2">
      <!-- PARTE DE ABAJO, HEADER DEL COMPONENTE Y FILTROS -->
      <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
        <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido" *ngIf="!tabla2"> Saldo total</span>
            <span class="w3-margin-left-contenido" *ngIf="tabla2"> {{milTab2}} Saldo total</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica2}}</span>
          </div>
        </div>
        <div class="w3-rest w3-right-align_rest ">
          <div class="w3-right hijoderechaTitle">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="ampliar('tabla2')" onclick="document.getElementById('graficasModal').style.display='block'">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu2">more_vert</mat-icon>
          </div>
          <mat-menu #menu2="matMenu">
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas2">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">assessment</mat-icon>
              <span>Gráficas</span>
            </button>
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" *ngIf="tabla2" [matMenuTriggerFor]="moneda">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">paid</mat-icon>
              <span>mil/pes</span>
            </button>
            <button mat-menu-item *ngIf="tabla2" [matMenuTriggerFor]="export2">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar</span>
            </button>
            <button mat-menu-item *ngIf="!tabla2" [matMenuTriggerFor]="export2">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar</span>
          </button>
            <button mat-menu-item [matMenuTriggerFor]="Perspectiva2">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree</mat-icon>
              <span>Perspectiva</span>
            </button>
            <!-- BOTON ELIMINAR FILTROS -->
            <button *ngIf="perspectiva" (click)="quitarFiltros()" class="mat-menu-item " mat-menu-item>
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                <img style="margin-top: -10px;" src="assets/img/graficos/sinfiltro.png">
              </mat-icon>
              <span>Eliminar Filtros</span>
            </button>
          </mat-menu>

          <mat-menu #export2="matMenu">
            <button mat-menu-item *ngIf="!tabla2" (click)="export_img(2)">
              <span>PNG</span>
            </button>
            <button mat-menu-item *ngIf="tabla2" (click)="export_csv(2)">
              <span>CSV</span>
            </button>
          </mat-menu>

          <mat-menu #Perspectiva2="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="Products2">
              <span>Productos</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="Zonas2">
              <span>Zonas</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="Sector2">
              <span>Sector</span>
            </button>
          </mat-menu>

          <mat-menu #Sector2="matMenu">
            <ng-container *ngFor="let sec of sectors">
              <button mat-menu-item (click)="cambioSec2(sec)">
                {{sec.name}}
              </button>
            </ng-container>
          </mat-menu>

          <mat-menu #Products2="matMenu">
            <ng-container *ngFor="let prod of products">
              <button mat-menu-item (click)="cambioProd2(prod)">
                {{prod.name}}
              </button>
            </ng-container>
          </mat-menu>

          <mat-menu #Zonas2="matMenu">
            <ng-container *ngFor="let zon of zonas">
              <button mat-menu-item (click)="cambioZon2(zon)">
                {{zon.name}}
              </button>
            </ng-container>
          </mat-menu>

          <mat-menu #moneda="matMenu">
            <button mat-menu-item (click)="CambMon('Mil',2)">
              <span>Miles</span>
            </button>
            <button mat-menu-item (click)="CambMon('Pesos',2)">
              <span>Pesos</span>
            </button>
          </mat-menu>

          <mat-menu #graficas2="matMenu">
            <button mat-menu-item (click)="Graficas2('T')">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
              <span>Tabla</span>
            </button>
            <button mat-menu-item (click)="Graficas2('L')">
              <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
              <span>Lineal</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <!-- FIN PARTE DE ABAJO, HEADER DEL COMPONENTE Y FILTROS -->

      <!-- PARTE DE ABAJO, CONTENDOR DE LAS GRAFICAS -->
      <div class="w3-row" >
          <!-- PARTE DE ABAJO, HEADER DEL COMPONENTE Y FILTROS -->
          <div class="w3-row w3-white" style="height: 35vh">
              <div class="w3-encabezado-table w3-scroll" >
                  <div *ngIf="tabla2">
                    <table mat-table [dataSource]="dataSource2" class="w3-table-all">
                        <!-- Position Column -->
                        <ng-container matColumnDef="cosecha">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-table-title" style="font-size: 0.8vw;"> Cosecha </th>
                        <td mat-cell style="width: 7%; font-size: 0.8vw;" *matCellDef="let element" class=""> {{element.cosecha}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="montocolocado">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> Monto colocado </th>
                        <td mat-cell style="width: 9%; font-size: 0.8vw;" *matCellDef="let element" class=" w3-light-grey w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh">{{element.montocolocado}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                      <ng-container matColumnDef="mes1">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-1]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,11)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-1], element.cosecha)" class="w3-button">{{element.mes1}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes2">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-2]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,10)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-2], element.cosecha)" class="w3-button">{{element.mes2}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes3">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-3]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,9)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-3], element.cosecha)" class="w3-button">{{element.mes3}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes4">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-4]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,8)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-4], element.cosecha)" class="w3-button">{{element.mes4}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes5">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-5]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,7)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-5], element.cosecha)" class="w3-button">{{element.mes5}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes6">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-6]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,6)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-6], element.cosecha)" class="w3-button">{{element.mes6}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes7">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-7]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,5)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-7], element.cosecha)" class="w3-button">{{element.mes7}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes8">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-8]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,4)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-8], element.cosecha)" class="w3-button">{{element.mes8}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes9">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-9]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,3)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-9], element.cosecha)" class="w3-button">{{element.mes9}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes10">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-10]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,2)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-10], element.cosecha)" class="w3-button">{{element.mes10}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes11">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-11]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,1)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-11], element.cosecha)" class="w3-button">{{element.mes11}}</span>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="mes12">
                        <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-12]}} </th>
                        <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,0)" class=" w3-border-grey2 w3-right-align">
                          <span style="margin-right: 1vh" (click)="clickTab(nombres_mes[nombres_mes.length-12], element.cosecha)" class="w3-button">{{element.mes12}}</span>
                        </td>
                        </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns2;sticky: true;" class="w3-table-title"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns2;let i = index" class="my-row "></tr>
                    </table>
                  </div>
                  <div *ngIf="barras2" style="height: 34vh;">
                    <ngx-charts-line-chart
                      [scheme]="colorScheme"
                      [legend]="legend"
                      [legendTitle]="legendTitle"
                      [legendPosition]="legendPosition"
                      [showXAxisLabel]="showXAxisLabel"
                      [showYAxisLabel]="showYAxisLabel"
                      [xAxis]="xAxis"
                      [yAxis]="yAxis"
                      [xAxisLabel]="xAxisLabel"
                      [yAxisLabel]="yAxisLabel2"
                      [results]="dataBarrasSald"
                      [curve]="curve"
                      >
                    </ngx-charts-line-chart>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<!-- The Modal -->
<div id="graficasModal" class="w3-modal">
  <div class="w3-modal-content"  style="width: 90%">
      <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
          <div class="w3-twothird">
              <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
                <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
                  <div class="hijoizquierdaTitle">
                    <span class="w3-margin-left-contenido"><b>{{tituloModal}}</b></span><span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGraficaModal}}</span>
                  </div>
                </div>
              </div>
          </div>
          <div class="w3-rest ">
              <mat-icon (click)="cerrarAmp()" onclick="document.getElementById('graficasModal').style.display='none'" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
          </div>
      </div>
      <div class=" w3-row" style="height: 65vh;">
          <div class="w3-row w3-margin" style="height: 56vh; width: 98%;">
            <div class="w3-encabezado-table w3-scroll" *ngIf="tabla1Modal">
              <table mat-table [dataSource]="dataSource" class="w3-table-all">

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
                <!-- Position Column -->
                <ng-container matColumnDef="cosecha">
                <th mat-header-cell *matHeaderCellDef class="w3-table-title w3-center" style="font-size: 0.8vw;"> Cosecha </th>
                <td mat-cell style="width: 7%;" *matCellDef="let element" class="" style="font-size: 0.8vw;"> {{element.cosecha}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="montocolocado">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> Monto colocado </th>
                <td mat-cell style="width: 9%; font-size: 0.8vw;" *matCellDef="let element" class="w3-light-grey w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.montocolocado}}</span>  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes1">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-2]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,11)" [ngStyle]="{'color': colorText(element.mes1)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes1) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes1}} %</span>
                  <span *ngIf="pintarPorcent(element.mes1) && !porPorcent" style="margin-right: 1vh">{{element.mes1}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes2">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-2]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,10)" [ngStyle]="{'color': colorText(element.mes2)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes2) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes2}} %</span>
                  <span *ngIf="pintarPorcent(element.mes2) && !porPorcent" style="margin-right: 1vh">{{element.mes2}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes3">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-3]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,9)" [ngStyle]="{'color': colorText(element.mes3)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes3) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes3}} %</span>
                  <span *ngIf="pintarPorcent(element.mes3) && !porPorcent" style="margin-right: 1vh">{{element.mes3}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes4">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-4]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,8)" [ngStyle]="{'color': colorText(element.mes4)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes4) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes4}} %</span>
                  <span *ngIf="pintarPorcent(element.mes4) && !porPorcent" style="margin-right: 1vh">{{element.mes4}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes5">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-5]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,7)" [ngStyle]="{'color': colorText(element.mes5)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes5) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes5}} %</span>
                  <span *ngIf="pintarPorcent(element.mes5) && !porPorcent" style="margin-right: 1vh">{{element.mes5}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes6">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-6]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,6)" [ngStyle]="{'color': colorText(element.mes6)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes6) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes6}} %</span>
                  <span *ngIf="pintarPorcent(element.mes6) && !porPorcent" style="margin-right: 1vh">{{element.mes6}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes7">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-7]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,5)" [ngStyle]="{'color': colorText(element.mes7)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes7) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes7}} %</span>
                  <span *ngIf="pintarPorcent(element.mes7) && !porPorcent" style="margin-right: 1vh">{{element.mes7}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes8">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-8]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,4)" [ngStyle]="{'color': colorText(element.mes8)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes8) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes8}} %</span>
                  <span *ngIf="pintarPorcent(element.mes8) && !porPorcent" style="margin-right: 1vh">{{element.mes8}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes9">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-9]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,3)" [ngStyle]="{'color': colorText(element.mes9)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes9) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes9}} %</span>
                  <span *ngIf="pintarPorcent(element.mes9) && !porPorcent" style="margin-right: 1vh">{{element.mes9}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes10">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-10]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,2)" [ngStyle]="{'color': colorText(element.mes10)}" class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes10) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes10}} %</span>
                  <span *ngIf="pintarPorcent(element.mes10) && !porPorcent" style="margin-right: 1vh">{{element.mes10}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes11">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-11]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,1)"  [ngStyle]="{'color': colorText(element.mes11)}"class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes11) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes11}} %</span>
                  <span *ngIf="pintarPorcent(element.mes11) && !porPorcent" style="margin-right: 1vh">{{element.mes11}} </span> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes12">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-12]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,0)"  [ngStyle]="{'color': colorText(element.mes12)}"class=" w3-border-grey2 w3-right-align">
                  <span *ngIf="pintarPorcent(element.mes12) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes12}} %</span>
                  <span *ngIf="pintarPorcent(element.mes12) && !porPorcent" style="margin-right: 1vh">{{element.mes12}} </span></td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row "></tr>
              </table>
            </div>
            <div class="w3-encabezado-table w3-scroll" *ngIf="tabla2Modal">
              <table mat-table [dataSource]="dataSource2" class="w3-table-all">
                <!-- Position Column -->
                <ng-container matColumnDef="cosecha">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-table-title" style="font-size: 0.8vw;"> Cosecha </th>
                <td mat-cell style="width: 7%; font-size: 0.8vw;" *matCellDef="let element" class=""> {{element.cosecha}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="montocolocado">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> Monto colocado </th>
                <td mat-cell style="width: 9%; font-size: 0.8vw;" *matCellDef="let element" class=" w3-light-grey w3-border-grey2 w3-right-align"> <span style="margin-right: 1vh">{{element.montocolocado}}</span> </td>
                </ng-container>

                <!-- Name Column -->
              <ng-container matColumnDef="mes1">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-1]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,11)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes1}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes2">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-2]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,10)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes2}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes3">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-3]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,9)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes3}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes4">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-4]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,8)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes4}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes5">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-5]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,7)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes5}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes6">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-6]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,6)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes6}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes7">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-7]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,5)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes7}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes8">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-8]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,4)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes8}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes9">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-9]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,3)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes9}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes10">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-10]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,2)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes10}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes11">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-11]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,1)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes11}}</span></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="mes12">
                <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-12]}} </th>
                <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,0)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes12}}</span></td>
                </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns2;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;let i = index" class="my-row "></tr>
              </table>
            </div>
            <div *ngIf="barras2Modal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [legend]="legend"
                [legendTitle]="legendTitle"
                [legendPosition]="legendPosition"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel2"
                [results]="dataBarrasSald"
                [curve]="curve"
                >
              </ngx-charts-line-chart>
            </div>
            <div *ngIf="barras1Modal" class="w3-rest w3-right w3-encabezado-table " style="height: 55vh; width: 100%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [legend]="legend"
                [legendTitle]="legendTitle"
                [legendPosition]="legendPosition"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [results]="dataBarrasMor"
                [curve]="curve"
                >
              </ngx-charts-line-chart>
            </div>
          </div>  
      </div>
  </div>
</div>

<div id="modalTabla" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido"><b>Cosechas</b></span><span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtituloCosechasClick}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-rest w3-header-cuadrante padreizquierda" style="height: 6vh; margin-top: 10px; margin-left: 20px;">
        <div class="w3-right hijoderechaTitle" style="margin-right: 1vh;">
          <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="exportar_cscClick()">cloud_download</mat-icon>
          <mat-icon (click)="cerrarAmp()" onclick="document.getElementById('modalTabla').style.display='none'" class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}">clear</mat-icon>
        </div>
      </div>
    </div>
    <div class="w3-row" style="height: 65vh;">
      <div class="w3-row w3-margin" style="height: 56vh;">
        <div class="w3-encabezado-table w3-scroll">
          <table mat-table [dataSource]="dataCosechas" class="w3-table-all">
            <ng-container matColumnDef="numCredito">
              <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table w3-center" style="font-weight: bold;"> Número de credito. </th>
              <td mat-cell class="w3-center" *matCellDef="let element"> {{formatearmiles(element.nCred)}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="claveSocio">
              <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table w3-center" style="font-weight: bold;"> Clave socio </th>
              <td mat-cell class="w3-center" *matCellDef="let element"> {{formatearmiles(element.cSocio)}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table w3-center" style="font-weight: bold;"> Nombre </th>
              <td mat-cell class="w3-center" *matCellDef="let element"> {{element.nom}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="diasmora">
              <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table w3-center" style="font-weight: bold;"> Días mora </th>
              <td mat-cell class="w3-center" *matCellDef="let element"> {{element.dMor}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="producto">
              <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table w3-center" style="font-weight: bold;"> Producto </th>
              <td mat-cell class="w3-center" *matCellDef="let element"> {{element.prod}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="saldo">
              <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table w3-center" style="font-weight: bold;"> Saldo </th>
              <td mat-cell class="w3-center" *matCellDef="let element"> $ {{formatearmiles(element.sal)}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="estatus">
              <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table w3-center" style="font-weight: bold;"> Estatus </th>
              <td mat-cell class="w3-center" *matCellDef="let element"> {{element.estatus}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumnsCosechas"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCosechas;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

