export class FiltroAnio {

    public cadena: string;
    public selected: boolean;

    constructor(
        cadena: string,
        selected: boolean
    ){
        this.cadena = cadena;
        this.selected = selected;
    }

}
