<div class="w3-container w3-contenedor-template  " style="height: 7vh;">
    <div class="w3-row ">
        <div class="w3-quarter padreizquierda ">
            <div class="w3-row hijoizquierda">
                <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
                <span class="w3-text-deep-orange">&bull;</span>
                <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
            </div>
        </div>

        <!-- BORRAR FILTROS-->
        <div class="w3-rest ">
          <div class="w3-noventa2 w3-row ">
            <!-- anios-->
            <div class="w3-third padreizquierda">
              <div class="w3-quarter_e hijoizquierda  ">
                Año
                <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
                  <mat-icon class="w3-tiny">expand_more</mat-icon>
                </button>
                <!--
                  Se contruye el menu de años en relación a la información que tiene la entidad
                  Composición del json:
                  data_anios : any [] = [
                        {"name":"2018","value":"2018","check":true},
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];


                -->
                  <mat-menu class="w3-tiny"  #menuanios="matMenu" >
                    <ng-container *ngFor="let anio of data_anios">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                          value="{{anio.name}}" aria-label="First checkbox"
                          (change)="event_anio(anio.name)">
                          {{anio.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
              </div>
              <div class="w3-rest  ">
                <div class="w3-row padreizquierda" id="contenedor_btnanios">
                  <div class="w3-row hijoizquierda ">
                    <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':anios.check,'w3-hide ':!anios.check}"
                            *ngFor="let anios of data_anios_selec"
                            (click)="event_anio(anios.name)"
                            style="height: 20px;width: 42px;margin-left: 10px;">
                            {{anios.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- meses -->
            <div [class]="VerificarAniosSeleccionados()" >
              <div class="w3-quarter_e hijoizquierda  ">
                Mes
                <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
                  <mat-icon class="w3-tiny">expand_more</mat-icon>
                </button>
                <!--
                  Se contruye el menu de años en relación a la información que tiene la entidad
                  Composición del json:
                  data_anios : any [] = [
                        {"name":"2018","value":"2018","check":true},
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];


                -->
                  <mat-menu class="w3-tiny"  #menumeses="matMenu" >
                    <ng-container *ngFor="let mes of data_meses">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                          value="{{mes.name}}" aria-label="First checkbox"
                          (change)="event_mes(mes.name)">
                          {{mes.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
              </div>
              <div class="w3-rest  ">
                <div class="w3-row padreizquierda" id="contenedor_btnmeses">
                  <div [class]="ContarMeses()" >
                      <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':meses.check,'w3-hide ':!meses.check}"
                              *ngFor="let meses of data_meses_selec"
                              (click)="event_mes(meses.name)"
                              style="height: 20px;width: 42px;margin-left: 10px;">
                              {{meses.name}}
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- consolidado sucursal -->
            <div class="w3-third">
              <div class="w3-half hijoizquierda  ">
                Entidad
                <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuentidad" aria-label="Example icon-button with a menu">
                  <mat-icon class="w3-tiny">expand_more</mat-icon>
                </button>
                <!--
                  Se contruye el menu de años en relación a la información que tiene la entidad
                  Composición del json:
                  data_anios : any [] = [
                        {"name":"2018","value":"2018","check":true},
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];
                -->
                  <mat-menu class="w3-tiny"  #menuentidad="matMenu" >
                    <button mat-menu-item>
                      <mat-checkbox name="consolidado" [checked]="ent_consolidado"
                        value="consolidado" aria-label="First checkbox" (change)="event_consolidado()">
                        Consolidado
                      </mat-checkbox>
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="sucursales">Sucursales</button>
                    <!--<ng-container *ngFor="let anio of data_anios">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                          value="{{anio.name}}" aria-label="First checkbox"
                          (change)="event_anio(anio.name)">
                          {{anio.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container> -->
                  </mat-menu>
                  <mat-menu #sucursales="matMenu">
                    <ng-container *ngFor="let sucursal of data_sucursales">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="sucursal.check" name="{{sucursal.name}}"
                          value="{{sucursal.name}}" aria-label="First checkbox"
                          (change)="event_sucursal(sucursal.name)">
                          {{sucursal.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
              </div>
            </div>
          </div>
          <!-- quitar filtros -->
          <div class="w3-rest w3-right ">
              <button mat-icon-button (click)="quitar_filtros()">
                  <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                      <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
                  </mat-icon>
              </button>
          </div>
        </div>
        <!-- FIN BORRAR FILTROS-->

    </div>
</div>
<div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">
    <div class="w3-row w3-contenedor-template " style="height: 41vh;">
      <div class="w3-row w3-white" style="height: 100%;">
        <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
            <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido">Tendencia</span>
                  <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                  <!--<span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>-->
                </div>
            </div>
            <div class="w3-third w3-right-align_rest ">

                <div class="w3-right hijoderechaTitle">
                  <mat-icon class="w3-margin-right-contenido" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                    more_vert</mat-icon>
                </div>
                <mat-menu #menu="matMenu">
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                      <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                      <span>Gráficas</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button *ngIf="flag_perspectivas" class="mat-menu-item " mat-menu-item [matMenuTriggerFor]="perspectivas">
                      <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
                      </mat-icon>
                      <span>Perspectiva</span>
                    </button>
                    <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
                      <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
                      </mat-icon>
                      <span>Exportar</span>
                    </button>
                    <button (click)="modal('id')" class="mat-menu-item" mat-menu-item>
                      <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                      <span>Ampliar</span>
                    </button>
                    <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" class="mat-menu-item " mat-menu-item>
                      <mat-icon [ngStyle]="{'color':'gray'}"><img style="margin-top: -10px;"
                          src="assets/img/graficos/sinfiltro.png"></mat-icon>
                      <span>Eliminar Filtros</span>
                    </button>
                    <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" mat-menu-item>
                      <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                        arrow_back
                      </mat-icon>
                      <span>Regresar</span>
                    </button>

                  </mat-menu>
                  <mat-menu #graficas="matMenu">
                    <button (click)="cambiarGrafica('barras')" mat-menu-item>
                      <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                      <span>Barras</span>
                    </button>
                    <button (click)="cambiarGrafica('lineal')" mat-menu-item>
                      <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                      <span>Lineal</span>
                    </button>
                    <!--<button (click)="cambiarGrafica('pastel')" mat-menu-item>
                      <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                      <span>Pastel</span>
                    </button>
                    <button (click)="cambiarGrafica('tabla')" mat-menu-item>
                      <mat-icon><img style="margin-top: -15px;" src="assets/img/elementotemplate/table.png"></mat-icon>
                      <span>Tabla</span>
                    </button>-->
                  </mat-menu>
                  <mat-menu #exportar="matMenu">

                  </mat-menu>
                  <mat-menu #perspectivas="matMenu">
                    <button *ngIf="isNotActiveFilter('bandas de morosidad')" class="" (click)="addPerspectiva('bandas de morosidad')" mat-menu-item>
                      <span>Bandas de morosidad</span>
                    </button>
                    <button *ngIf="isNotActiveFilter('estatus')" (click)="addPerspectiva('estatus')" mat-menu-item>
                      <span>Estatus</span>
                    </button>
                  </mat-menu>
                  <mat-menu #geografico="matMenu">
                    <button class="" (click)="addPerspectiva('geografico_estado')" mat-menu-item>
                      <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                      <span>Estado </span>
                    </button>
                    <button class="" (click)="addPerspectiva('geografico_municipio')" mat-menu-item>
                      <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                      <span>Municipio</span>
                    </button>
                    <button class="" (click)="addPerspectiva('geografico_localidad')" mat-menu-item>
                      <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                      <span>Localidad</span>
                    </button>
                  </mat-menu>
                </div>
        </div>

        <div class="w3-row" style="height: 25vh;width: 100%;">
          <div *ngIf="g1_barra" class="chart_wrap w3-scroll">
            <ngx-charts-bar-vertical
              id="chartvertical"
              class="bar-half-google w3-labels-ngx-charts"
              style="width: 99%;height: 20vh;"
              [scheme]="colorScheme"
              [results]="datosBarra"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [showGridLines]="showGridLines"
              (select)="click_grafica($event)"
              [customColors]="customColors"
              [showDataLabel]="showDataLabelT1C1"
              [dataLabelFormatting]="formatDataLabel"
              [yScaleMax]="barScaleMax"
              [yAxisTickFormatting]="yLeftTickFormat">
              <ng-template #tooltipTemplate let-model="model">
                <div style="text-align: left;">
                  <div *ngFor="let data of datosBarra; let i = index">
                    <span *ngIf="data.name === model.name">
                      {{data.extra.key_as_string}} : {{convertir_moneda(data.value)}}
                    </span>
                  </div>
                </div>
              </ng-template>
            </ngx-charts-bar-vertical>
          </div>
          <div *ngIf="g1_linea" class="chart_wrap w3-scroll">
            <ngx-charts-line-chart  id="BarLinealC2"
                class="w3-letra-graficas"
                [scheme]="colorScheme"
                [results]="datosLineal"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="visibilityLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                legendTitle="Montos"
                [showGridLines]="showGridLines"
                legendPosition="right"
                [yAxisTickFormatting]="yLeftTickFormat"
                [referenceLines]="referenceLines"
                [showRefLines]="showRefLines"
                (select)="onSelect($event)"
                [yScaleMax]="lineScaleMax"
                >
                <ng-template #tooltipTemplate let-model="model" >
                  <div style="text-align: left;">
                    <div *ngFor="let data of datosLineal; let i = index">
                        <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model" >
                <div style="text-align: left;">
                    <div *ngFor="let data of datosLineal; let i = index">
                      <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                    </div>
                </div>
                </ng-template>
              </ngx-charts-line-chart>
          </div>
        </div>
    </div>

    <!-- Parte de abajo-->
    <div class="w3-row mt-2 ">
        <div class="w3-half_e w3-white " style="height: 38vh;">
          <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh;">
            <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
              <div class="w3-row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                <span class="w3-margin-left-contenido ">Volúmenes captación / colocación</span>
                <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="puntot1c2">&bull;</span>
                <span class=" w3-margin-left-filter w3-subtitulo " >{{subtitulot1c2}}</span>
                <span class="tooltiptext ">{{subtitulot1c2}}</span>
              </div>
            </div>
            <div class="w3-rest w3-right-align_rest " >
              <div class="w3-right hijoderechaTitle" style="z-index: 1;">
                  <mat-icon *ngIf="showDataLabelActiveT1C2" class=" material-icons-outlined w3-zindex"
                  (click)="fshowDataLabelT1C2()"
                  [ngStyle]="{'color':'gray'}">
                  label
                  </mat-icon>
                  <mat-icon *ngIf="showDataLabelInactiveT1C2" class=" material-icons-outlined w3-zindex"
                  (click)="fshowDataLabelT1C2()"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                  </mat-icon>
                  <mat-icon *ngIf="piegrid2" class="w3-disabled material-icons-outlined w3-zindex"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                  </mat-icon>
                  <mat-icon *ngIf="chartlineal2" class=" w3-disabled material-icons-outlined w3-zindex"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                  </mat-icon>
                  <mat-icon class="w3-margin-right-contenido w3-zindex" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu2">more_vert</mat-icon>
              </div>
              <mat-menu #menu2="matMenu">
                  <button *ngIf="setFlagElementoSeleccionadoT1C2()" class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="filtro2t1c2">
                  <mat-icon class="material-icons-outlined"
                      [ngStyle]="{'color':'gray'}">account_tree
                  </mat-icon>
                  <span>Perspectiva</span>
                  </button>
                  <button (click)="inspeccionar()" class="mat-menu-item w3-disabled" mat-menu-item >
                  <mat-icon [ngStyle]="{'color':'gray'}">search</mat-icon>
                  <span>Inspeccionar</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button *ngIf="puntot1c2" class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar2">
                    <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                    <span>Exportar</span>
                  </button>
                  <button *ngIf="!tabla_informacion2" (click)="modal_templateT1C2()" class="mat-menu-item" mat-menu-item >
                  <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                  <span>Ampliar</span>
                  </button>
              </mat-menu>
              <mat-menu #exportar2="matMenu">
                  <button [ngClass]="{'w3-icon-visibility w3-hide ':tabla_informacion2,'w3-icon-visibility w3-show-inline-block':!tabla_informacion2}" (click)="ExportarPDFT1_C2()" mat-menu-item>
                  <span>PDF</span>
                  </button>
                  <button class="" (click)="export_csvT1C2()" mat-menu-item>
                  <span>CSV</span>
                  </button>
              </mat-menu>
              <mat-menu #filtro2t1c2="matMenu">
                  <button (click)="ClasificacionCartera()" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                    <span>Clasificación Cartera</span>
                  </button>
                  <button  (click)="ClasificacionCaptacion()" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                    <span>Clasificación Captación</span>
                  </button>
                  <button (click)="fregresart1c2()" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                    <span>Regresar</span>
                  </button>
              </mat-menu>
            </div>
          </div>
          <div class="w3-row w3-white" style="height: 32vh">
            <div id="tabla_informacion2" class="w3-encabezado-table w3-scroll" >
              <table class="w3-table-all ">
                <thead class="w3-zindex w3-light-grey" >
                  <tr class="w3-table-title w3-center  " >
                    <th class="w3-center ">
                      <span class="w3-elemento-titulo-table">Año</span>
                    </th>
                    <th class="w3-center " >
                      <span class="w3-elemento-titulo-table">Hombres</span>
                    </th>
                    <th class="w3-center " >
                      <span class="w3-elemento-titulo-table">% total</span>
                    </th>
                    <th class="w3-center ">
                      <span class="w3-elemento-titulo-table">Mujeres</span>
                    </th>
                    <th class="w3-center " >
                      <span class="w3-elemento-titulo-table">% total</span>
                    </th>
                    <th class="w3-center ">
                      <span class="w3-elemento-titulo-table">Total Socios</span>
                    </th>
                    <th class="w3-center " >
                      <span class="w3-elemento-titulo-table">% total</span>
                    </th>
                  </tr>
                </thead>
                <tbody *ngFor="let item_tabla of table_data">
                  <tr >
                    <td class=" w3-center">
                      <mat-icon (click)="mostrarocultarmes(item_tabla.name)" *ngIf="item_tabla.check && mostrandomeses" class="w3-tiny " >remove</mat-icon>
                      <mat-icon (click)="mostrarocultarmes(item_tabla.name)" *ngIf="!item_tabla.check && mostrandomeses" class="w3-tiny ">add</mat-icon>
                      <span class="w3-elemento-table ">{{item_tabla.name}}</span>
                    </td>
                    <td >
                      <div class="w3-row" >
                        <div class="w3-quarter w3-right-align">
                          <span class="w3-elemento-table">  </span>
                        </div>
                        <div class="w3-threequarter">
                          <div class="w3-half w3-right-align">
                            <span class="w3-elemento-table">{{formatearNormal(item_tabla.masculino)}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td >
                      <div class="w3-row" >
                        <div class="w3-quarter w3-right-align">
                          <span class="w3-elemento-table">  </span>
                        </div>
                        <div class="w3-threequarter">
                          <div class="w3-half w3-right-align">
                            <span class="w3-elemento-table">{{convertir_porcentaje((item_tabla.masculino*100)/(total_masculino + total_femenino))}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td >
                      <div class="w3-row" >
                        <div class="w3-quarter w3-right-align">
                          <span class="w3-elemento-table">  </span>
                        </div>
                        <div class="w3-threequarter">
                          <div class="w3-half w3-right-align">
                            <span class="w3-elemento-table">{{formatearNormal(item_tabla.femenino)}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td >
                      <div class="w3-row" >
                        <div class="w3-quarter w3-right-align">
                          <span class="w3-elemento-table">  </span>
                        </div>
                        <div class="w3-threequarter">
                          <div class="w3-half w3-right-align">
                            <span class="w3-elemento-table"> {{convertir_porcentaje((item_tabla.femenino*100)/(total_masculino + total_femenino))}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td >
                      <div class="w3-row" >
                        <div class="w3-quarter w3-right-align">
                          <span class="w3-elemento-table">  </span>
                        </div>
                        <div class="w3-threequarter">
                          <div class="w3-half w3-right-align">
                            <span class="w3-elemento-table">{{convertir_moneda(item_tabla.femenino + item_tabla.masculino)}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td >
                      <div class="w3-row" >
                        <div class="w3-quarter w3-right-align">
                          <span class="w3-elemento-table">  </span>
                        </div>
                        <div class="w3-threequarter">
                          <div class="w3-half w3-right-align">
                            <span class="w3-elemento-table">{{convertir_porcentaje((item_tabla.femenino + item_tabla.masculino)*100/(total_femenino + total_masculino))}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="w3-center" >
                      <!--
                      <div class="w3-row" *ngIf="!item_tabla.check && mostrandomeses">
                        <div class="w3-quarter w3-right-align">
                          <span class="w3-elemento-table"> $ </span>
                        </div>
                        <div class="w3-threequarter">
                          <div class="w3-half w3-right-align">
                            <span class="w3-elemento-table">{{convertir_moneda(item_tabla.colocacion)}}</span>
                          </div>
                        </div>
                      </div>
                      -->
                    </td>
                  </tr>
                  <!--<tr (click)="filtro_tabla2(item_tabla.name,item.name)"
                      [ngClass]="{'w3-table-trtd':!buscar_filaseleccionadaT1C2(item.name,item_tabla.name),'w3-table-trtd w3-colortablaT1':buscar_filaseleccionadaT1C2(item.name,item_tabla.name)}"
                      id="{{item_tabla.name+'_'+item.name}}"
                      *ngFor="let item of item_tabla.series"
                      >
                    <td class=" w3-center">
                      <div class="w3-row">
                        <div class="w3-half">

                        </div>
                        <div class="w3-half w3-right">
                          <span *ngIf="setEstadoT1C2()" class="w3-elemento-table w3-left">{{mes_abreviado(item.name)}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="w3-center" >
                      <div class="w3-quarter w3-right-align">
                        <span class="w3-elemento-table"> $ </span>
                      </div>
                      <div class="w3-threequarter">
                        <div class="w3-half w3-right-align">
                          <span class="w3-elemento-table">{{convertir_moneda(item.captacion)}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="w3-center" >
                      <div class="w3-quarter w3-right-align">
                        <span class="w3-elemento-table"> $ </span>
                      </div>
                      <div class="w3-threequarter">
                        <div class="w3-half w3-right-align">
                          <span class="w3-elemento-table">{{convertir_moneda(item.colocacion)}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="w3-half_i w3-right w3-contenedor-template " style="height: 38vh;">
            <div class="w3-row " style="height: 18vh;">
                <div class="w3-half_e w3-row w3-white  " style="height: 18vh;">
                    <div class="w3-row padreTendencia ">
                      <div class="w3-quarter hijoTendencia" >
                        <mat-icon [ngStyle]="{'color':'#7DC3DD'}" class="w3-icon-display w3-icon-template4 w3-right" >account_balance</mat-icon>
                      </div>
                      <div class="w3-rest hijoTendenciaV2 " >
                        <span class="w3-text-cifras w3-margin-left-contenido">
                          {{formatearNormal(total_afiliacioninstitucionFM)}}
                        </span><br>
                        <span class="w3-tipografia-cifras w3-margin-left-contenido">
                          SUCURSALES
                        </span>
                      </div>
                    </div>
                </div>
                <div class="w3-half_e w3-white  w3-right"  style="height: 18vh;">
                  <div class="w3-row padreTendencia ">
                    <div class="w3-quarter hijoTendencia" >
                      <mat-icon [ngStyle]="{'color':'#7DC3DD'}" class="w3-icon-display w3-icon-template4 w3-right" >view_quilt</mat-icon>
                    </div>
                    <div class="w3-rest hijoTendenciaV2 " >
                      <span class="w3-text-cifras w3-margin-left-contenido">
                        {{formatearNormal(total_afiliacioninstitucionFM)}}
                      </span><br>
                      <span class="w3-tipografia-cifras w3-margin-left-contenido">
                        CAPITAL SOCIAL
                      </span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="w3-row " style="margin-top: 5px; height: 18vh;margin-top: 2vh;">
                <div class="w3-half_e w3-white " style="height: 18vh;" >
                  <div class="w3-row padreTendencia ">
                    <div class="w3-quarter hijoTendencia" >
                      <mat-icon [ngStyle]="{'color':'#7DC3DD'}" class="w3-icon-display w3-icon-template4 w3-right" >person</mat-icon>
                    </div>
                    <div class="w3-rest hijoTendenciaV2 " >
                      <span class="w3-text-cifras w3-margin-left-contenido">
                        {{formatearNormal(total_afiliacioninstitucionFM)}}
                      </span><br>
                      <span class="w3-tipografia-cifras w3-margin-left-contenido">
                        SOCIOS
                      </span>
                    </div>
                  </div>
                </div>
                <div class="w3-half_e w3-white w3-right"  style="height: 18vh;">
                  <div class="w3-row padreTendencia ">
                    <div class="w3-quarter hijoTendencia" >
                      <mat-icon [ngStyle]="{'color':'#7DC3DD'}" class="w3-icon-display w3-icon-template4 w3-right" >connect_without_contact</mat-icon>
                    </div>
                    <div class="w3-rest hijoTendenciaV2" >
                      <span class="w3-text-cifras w3-margin-left-contenido">
                        {{formatearNormal(total_afiliacioninstitucionFM)}}
                      </span><br>
                      <span class="w3-tipografia-cifras w3-margin-left-contenido">
                        ACTIVIDAD
                      </span>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>


</div>
