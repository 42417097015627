import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {MessageService} from './../base/message.service'

import {Service} from './../base/service'
import {Loggers} from './../../util/app-util'
import { concentracionEntity } from './../../modules/riesgos/entities_riesgos/entities_riesgos';

@Injectable({
  providedIn: 'root'
})
export class ConcentracionService extends Service<concentracionEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers,
  ) { 
    super('concentracion','',http, messageService, logger);
    this.httpClient = http;
  }
  protected getParams(entity: concentracionEntity):HttpParams{
    let params: HttpParams = new HttpParams();
    if (null == entity){return params;}
    if (null != entity.Id_Institucion){params = params.set('Id_Institucion', entity.Id_Institucion.toString())}
    if (null != entity.Periodo){params = params.set('Periodo', entity.Periodo.toString())}
    if (null != entity.ElementoAgrupador){params = params.set('ElementoAgrupador', entity.ElementoAgrupador.toString())}
    if (null != entity.NPeriodos){params = params.set('NPeriodos', entity.NPeriodos.toString())}
    if (null != entity.F_NDatosLimite){params = params.set('F_NDatosLimite', entity.F_NDatosLimite.toString())}
    if (null != entity.BnConIntereses){params = params.set('BnConIntereses', entity.BnConIntereses.toString())}
    return params;
  }
}
