import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router'
import * as $ from 'jquery';

import pdfMake from "pdfmake/build/pdfmake.js";
import html2canvas from 'html2canvas';
import { SucursalEntity } from './../../../entities/base/BaseEntities';
import { SucursalService } from './../../../services/sucursal/sucursal.service';
import { InfoAnioMesEntitty } from './../../../entities/base/DashboardEntities';
import { InfoaniomesService } from './../../../services/dashboard/infoaniomes.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { FiltroAnio } from 'src/app/model/filtro-anio';
import { FiltroMes } from 'src/app/model/filtro-mes';
import { RangoFecha } from 'src/app/model/rango-fechas';
import { ElasticSociosService } from 'src/app/services/elastic-socios/elastic-socios.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { AfiliacionServiceFunciones } from '../../shared/funciones-afiliacion/serviciosafiliacion';
import { FuncionesAfiliacion, AfiliacionVariablesConstantes, ConstantesAfiliacion, FuncionesArreglos, FuncionesMes, FuncionesCadenaAnioMes } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesBubble, FuncionAgruparSucursales, FuncionesExportCSV, FuncionesGenero, FuncionesBusquedasT1 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesTemplate4 } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { FuncionesFiltrosTemplate2 } from "../../shared/funciones-afiliacion/filtros_template2";
import { FuncionesTemplateIII } from "../../shared/funciones-afiliacion/filtros_template3";
import { PDF, PDFSecond } from './../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import { FuncionesCartera } from './../funciones_cartera';
import { Datosdummy} from './../funciones_c_pruebas';
import { CommonFunctions } from './../../../util/app-util';
import { concat } from 'rxjs';
/* Importación de Funciones */



@Component({
  selector: 'app-altas-bajas',
  templateUrl: './altas-bajas.component.html',
  styleUrls: ['./altas-bajas.component.css']
})
export class AltasBajasComponent implements OnInit {

  constructor(
    private sucursalService: SucursalService,
    public dataService: DataService,
    private elasticSearch: ElasticSociosService
  ) { 
    FuncionesColores.inicioColores();

    this.colorScheme = FuncionesColores.setpaleta_azul('paleta_azul');

    // Se inicializan arreglos
    //this.arreglo_anios = new Array<FiltroAnio>();
    //this.arreglo_meses = new Array<FiltroMes>();

    this.anios_seleccionados = new Array<FiltroAnio>();
    this.meses_seleccionados = new Array<FiltroMes>();
    this.datosBarra = new Array<any>();
    //this.data_piechart = new Array<any>();
    this.filtrosPerspectivasActivos = new Array<string>();
    this.filtrosPerspectivasAnidados = new Array<any>();
    //this.table_data = new Array<string>();
    //this.datosBarra_producto = new Array<any>();
    //this.data_piechart_genero = new Array<any>();

    this.inicializarValores();

    //this.rangoFecha = new RangoFecha();
    this.anios_seleccionados= this.arreglo_anios;
    //this.meses_seleccionados = this.arreglo_meses;
    this.meses_seleccionados.push(this.arreglo_meses[this.arreglo_meses.length - 1]);
    this.table_data = [
      {"name":"2019","masculino":18945,"femenino":15653},
      {"name":"2020","masculino":22343,"femenino":18459},
      {"name":"2021","masculino":24354,"femenino":21342}
    ];
    this.datosBarra = [
      {"name":"2019","value":78789,"extra":{"key_as_string":2019}},
      {"name":"2020","value":85789,"extra":{"key_as_string":2020}},
      {"name":"2021","value":91086,"extra":{"key_as_string":2021}},
    ];
    this.contador_anios = this.anios_seleccionados.length;
    this.contador_meses = this.meses_seleccionados.length;
    console.log("Contador anios --->  " + this.contador_anios);
    //this.ObtenerTotalMasculinoFemenino(this.table_data);
  }
  inicializarValores(){
    this.getGrafica();
  }

  ngOnInit(): void {
  }

  // Arreglos anio y mes
  contador_anios: number = 0;
  contador_meses: number = 0;
  arreglo_anios: any [] = [
    {"name":"2019","value":"2019","check":true},
    {"name":"2020","value":"2020","check":true},
    {"name":"2021","value":"2021","check":true}
    ];
  arreglo_meses : any [] = [
        {"name":"ene","value":"ene","check":false},
        {"name":"feb","value":"feb","check":false},
        {"name":"mar","value":"mar","check":false},
        {"name":"abr","value":"abr","check":false},
        {"name":"may","value":"ene","check":false},
        {"name":"jun","value":"jun","check":false},
        {"name":"jul","value":"jul","check":false},
        {"name":"ago","value":"ago","check":true}
        ];                        
  anios_seleccionados: any [] = [];
  meses_seleccionados: any [] = [];
  data_sucursales: any [] = [
      {"name":"suc0","value":"suc0","check":false},
      {"name":"suc1","value":"suc1","check":false},
      {"name":"suc2","value":"suc2","check":false},
      {"name":"suc3","value":"suc3","check":false},
      {"name":"suc4","value":"suc4","check":false},
      {"name":"suc5","value":"suc5","check":false},
      {"name":"suc6","value":"suc6","check":false},
      {"name":"suc7","value":"suc7","check":false}
  ];
  data_sucursales_select: any [] = [];
  ent_consolidado:boolean = false;

  public colorScheme: string;
  datosBarra = new Array<any>();
  table_data = new Array<any>();
  filtrosPerspectivasActivos = new Array<string>();
  filtrosPerspectivasAnidados = new Array<any>();

  quitar_filtros(){}
  modal(id: string): void {}
  isNotActiveFilter(perspectiva: string): boolean {
    return this.filtrosPerspectivasActivos.filter(filtro => filtro == perspectiva).length == 0;
  }
  addPerspectiva(perspectiva: string) {
    /*this.getGraficaPerspectiva(perspectiva);
    this.getTablaPerspectiva(perspectiva);
    this.getGraficaLinealPerspectiva(perspectiva);
    this.filtrosPerspectivasActivos.push(perspectiva);
    this.filtrosPerspectivasAnidados.push({ perspectiva: perspectiva, value: this.valor_seleccionado });
    this.setSubtitulo();
    this.flag_perspectivas = false;*/

  }
  cambiarGrafica(grafica: string): void {
    switch(grafica){
      case 'barras':
        /*this.g1_barra = true;
        this.g1_linea = false;*/
      break;
      case 'lineal':
        /*this.g1_barra = false;
        this.g1_linea = true;*/
      break;
    }
  }

  event_consolidado(){}
  event_mes(e){}
  event_anio(e){}
  ContarMeses(){
    if(this.meses_seleccionados.length < 5 ){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny ";
    }
  }
  VerificarAniosSeleccionados(){
    if(this.contador_anios > 1)
      return "w3-third w3-disabled";
    else
      return "w3-third ";
  }

  /* Cuadrante */
  flag_perspectivas: boolean = false;
  getGrafica() {
    // Obtener datos de la gráfica inicial
    this.elasticSearch.consultaGeneral('grafica_inicial').subscribe(
      response => {

        //this.datosBarra = this.elasticSearch.elasticToLinealSimpleChart(response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets, 'total_contratos');

        //this.barScaleMax = FuncionesScaleMax.BarScaleMax(this.datosBarra);

        // Versión manual por si falla servicio Elastictobarchart
        let datosBarraAux = [];
        this.data_sucursales = [];

        //this.sucursales = response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets;
        //this.sucursales = response.message.agrupar_por_sucursal.buckets;
        var cont = 1;
        //response.message.agrupar_por_creditos.agrupar_por_sucursal.buckets.forEach(elemento => {
        response.message.agrupar_por_sucursal.buckets.forEach(elemento => {
          //let elementoCadena = elemento.key[0];
          //let elementoCadena = elemento.key;
          let elementoCadena = "suc" + cont.toString();
          //console.log("abre--->" + elementoCadena + " ---> " + cont );
          this.data_sucursales.push({"name":elemento.key,"pro":1,"reac":3,"so_new":12,"to_ingre":12,"inac_mes":1,"proyectado":3,"logrado":4,"men_adu":5});
          let newElemento = {
            name: elementoCadena,
            value: elemento.doc_count,
            extra: {
              //key_as_string: elemento.key_as_string 
              key_as_string: elemento.key
            }
          }
          datosBarraAux.push(newElemento);
          cont++;
        });

        //this.datosBarra = datosBarraAux;
        //this.datosLineal.push({"name":"entidad","series":this.datosBarra});
      }
    )
  }


  displayedColumns: string[] = [
    'name',//0
    'pro',//1
    'reac',//2
    'so_new',//3
    'to_ingre',//4
    'inac_mes',//5
    'proyectado',//6
    'logrado',//7
    'men_adu'//8
  ];
  dataSource = [
    {
      position: 1,
      name: 'Hydrogen',
      weight: 1.0079,
      symbol: 'H',
      camp: 1.0079,
      camps: 'H',
      segundo: 'H',
    },
    {
      position: 2,
      name: 'Helium',
      weight: 4.0026,
      symbol: 'He',
      camp: 4.0026,
      camps: 'He',
      segundo: 'He',
    },
    {
      position: 3,
      name: 'Lithium',
      weight: 6.941,
      symbol: 'Li',
      camp: 6.941,
      camps: 'Li',
      segundo: 'Li',
    },
    {
      position: 4,
      name: 'Beryllium',
      weight: 9.0122,
      symbol: 'Be',
      camp: 9.0122,
      camps: 'Be',
      segundo: 'Be',
    },
    {
      position: 5,
      name: 'Boron',
      weight: 10.811,
      symbol: 'B',
      camp: 10.811,
      camps: 'B',
      segundo: 'B',
    },
    {
      position: 6,
      name: 'Carbon',
      weight: 12.0107,
      symbol: 'C',
      camp: 12.0107,
      camps: 'C',
      segundo: 'C',
    },
    {
      position: 7,
      name: 'Nitrogen',
      weight: 14.0067,
      symbol: 'N',
      camp: 14.0067,
      camps: 'N',
      segundo: 'N',
    },
    {
      position: 8,
      name: 'Oxygen',
      weight: 15.9994,
      symbol: 'O',
      camp: 15.9994,
      camps: 'O',
      segundo: 'O',
    },
    {
      position: 9,
      name: 'Fluorine',
      weight: 18.9984,
      symbol: 'F',
      camp: 18.9984,
      camps: 'F',
      segundo: 'F',
    },
    {
      position: 10,
      name: 'Neon',
      weight: 20.1797,
      symbol: 'Ne',
      camp: 20.1797,
      camps: 'Ne',
      segundo: 'Ne',
    },
  ];

  CalculoTotalElementos(ele){
    var suma = 0;
    console.log("ConsoleElement A --->  " + ele);
    for(var i = 0; i < this.data_sucursales.length;i++){
      switch(ele){
        case 1:
          suma += this.data_sucursales[i].pro;
        break;
        case 2:
          suma += this.data_sucursales[i].reac;
        break;
        case 3:
          suma += this.data_sucursales[i].so_new;
        break;
        case 4:
          suma += this.data_sucursales[i].to_ingre;
        break;
        case 5:
          suma += this.data_sucursales[i].inac_mes;
        break;
        case 6:
          suma += this.data_sucursales[i].proyectado;
        break;
        case 7:
          suma += this.data_sucursales[i].logrado;
        break;
        case 8:
          suma += this.data_sucursales[i].men_adu;
        break;
      }
    }
    console.log("ConsoleElement S --->  " + suma);
    return suma;
  }
  /* Cuadrante */
}
