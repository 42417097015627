export class FuncionesFiltrosAnioMes{
    public static informacion_datos: any [] = [];
    public static informacion_datos_aux: any [] = [];
    public static data_arreglomes: any [] = [];
    public static data_arreglomesaux: any [] = [];
    public static checked_mes: boolean = false;
    public static checked_anio: boolean = false;
    public static contador_anios: number = 0;
    public static contador_mes: number = 0;
    public static arreglo_anio: string = "";
    public static arreglo_mes: string = "";
    public static ult_mes: string = "";
    public static ult_anio: string = "";
    public static setAnios(val){this.informacion_datos = val;}
    public static getAnios(){return this.informacion_datos;}
    public static setMes(val){this.data_arreglomes = val;}
    public static getMes(){return this.data_arreglomes;}
    public static setAniosAux(val){this.informacion_datos_aux = val;}
    public static getAniosAux(){return this.informacion_datos_aux;}
    public static setMesAux(val){this.data_arreglomesaux = val;}
    public static getMesAux(){return this.data_arreglomesaux;}
    public static setUltMes(val){this.ult_mes = val;}
    public static getUltMes(){return this.ult_mes;}
    public static setUltAnio(val){this.ult_anio = val;}
    public static getUltAnio(){return this.ult_anio;}
    public static AnioSeleccionado(e){  
        if(e == 0){
            if(this.checked_anio){
              this.checked_anio = false;
            }else{
              this.checked_anio = true;
            }
            for(var i = 0; i < this.informacion_datos.length; i++){
              if(this.checked_anio){
                this.informacion_datos[i].dbCheck = true;
              }else{
                this.informacion_datos[i].dbCheck = false;
                this.contador_anios ++;
              }
            }
        }else{
            for(var i = 0; i < this.informacion_datos.length;i++){
                if(e == this.informacion_datos[i].strName){
                    this.informacion_datos[i].dbCheck = true;
                    this.contador_anios ++;
                    /*
                    if(this.informacion_datos[i].dbCheck){
                        this.informacion_datos[i].dbCheck = false;
                    }else{
                        this.informacion_datos[i].dbCheck = true;
                        this.contador_anios ++;
                    }
                    */
                }else{
                    this.informacion_datos[i].dbCheck = false;
                }
            }
        }
        this.AuxAnios();
    }
    public static MesSeleccionado(mes){
        if(mes == 0){
            if(this.checked_mes){
              this.checked_mes = false;
            }else{
              this.checked_mes = true;
            }
            for(var i = 0; i < this.data_arreglomes.length; i++){
              if(this.checked_mes)
                this.data_arreglomes[i].dbCheck = true;
              else
                this.data_arreglomes[i].dbCheck = false;
            }
        }else{
            for(var i = 0; i < this.data_arreglomes.length; i++){
                if(mes == this.data_arreglomes[i].MesDsc){
                    if(this.data_arreglomes[i].dbCheck){
                        this.data_arreglomes[i].dbCheck = false;
                    }else{
                        this.data_arreglomes[i].dbCheck = true;
                        this.ult_mes = this.data_arreglomes[i].MesId;
                    }
                }
            }
        }
        this.AuxMes();
    }
    public static AuxMes(){
        this.arreglo_mes = "";
        this.contador_mes = 0;
        this.data_arreglomesaux = [];
        for(var i = 0; i < this.data_arreglomes.length; i++){
            if(this.data_arreglomes[i].dbCheck){
                if(this.arreglo_mes.search(this.data_arreglomes[i].MesId) < 0){
                    if(this.contador_mes == 0){
                        this.arreglo_mes = FuncionesMes.f_switchmes3(this.data_arreglomes[i].MesId);
                    }else{
                        this.arreglo_mes = this.arreglo_mes + "," + FuncionesMes.f_switchmes3(this.data_arreglomes[i].MesId);
                    }
                    this.contador_mes ++; 
                }
            }
        }
        if(this.contador_mes == 0){
            //si es mes igual a 0 and sucursaldesc == ""
            for(var i = 0; i < this.data_arreglomes.length;i++){
                this.data_arreglomes[i].dbCheck = true;
                if(this.contador_mes == 0){
                    this.arreglo_mes = this.data_arreglomes[i].MesId;
                }else{
                    this.arreglo_mes = this.arreglo_mes + "," + this.data_arreglomes[i].MesId;
                }
                this.contador_mes ++;
            }
        }
        for(var i = 0; i < this.data_arreglomes.length; i++){
            if(this.data_arreglomes[i].dbCheck){
                this.data_arreglomesaux.push(this.data_arreglomes[i]);
            }
        }
    }
    public static AuxAnios(){
        this.arreglo_anio = "";
        this.contador_anios = 0;
        this.setAniosAux([]);
        for(var i = 0; i < this.informacion_datos.length; i++){
            if(this.informacion_datos[i].dbCheck){
                if(this.contador_anios == 0){
                    this.arreglo_anio = this.informacion_datos[i].strName;
                }else{
                    this.arreglo_anio = this.arreglo_anio + " , " + this.informacion_datos[i].strName;
                }
                this.contador_anios ++;
            }
        }
        if(this.contador_anios == 0){
            this.informacion_datos[this.informacion_datos.length - 1].dbCheck = true;
            this.contador_anios = 1;
            this.arreglo_anio = this.informacion_datos[this.informacion_datos.length - 1].strName;
        }
        for(var i = 0; i < this.informacion_datos.length; i++){
            if(this.informacion_datos[i].dbCheck){
                this.informacion_datos_aux.push(this.informacion_datos[i]);
            }
        }
        if(this.contador_anios == 1){
            for(var i = 0; i < this.informacion_datos.length;i++){
                if(this.informacion_datos[i].dbCheck){
                    this.setUltAnio(this.informacion_datos[i].strName);
                }
            }
        }
    }
    public static ResetAnioMes(){
        this.setAniosAux([]);
        this.setMesAux([]);
        for(var i = 0; i < this.informacion_datos.length;i++){
            this.informacion_datos[i].dnCheck = false;
            if((this.informacion_datos.length - 1) == i){
                this.informacion_datos[i].dbCheck = true;
                this.setUltAnio(this.informacion_datos[i].strName);
            }
        }
        for(var i = 0; i < this.data_arreglomes.length;i++){
            this.data_arreglomes[i].dbCheck = false;
            if((this.getMes().length - 1) == i){
                this.data_arreglomes[i].dbCheck = true;
                this.setUltMes(this.data_arreglomes[i].MesId);
            }
        }
    }
    public static Anios(){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < this.informacion_datos.length;i++){
            if(this.informacion_datos[i].dbCheck){
                if(contador == 0){
                    cadena = this.informacion_datos[i].strName;
                }else{
                    cadena = cadena + " . " + this.informacion_datos[i].strName;
                }
                contador ++;
            }
        }
        return cadena;
    }
    public static Mes(){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < this.data_arreglomes.length;i++){
            if(this.data_arreglomes[i].dbCheck){
                if(contador == 0){
                    cadena = this.data_arreglomes[i].MesDscAbre;
                }else{
                    cadena = cadena + " . " + this.data_arreglomes[i].MesDscAbre;
                }
                contador ++;
            }
        }
        return cadena;
    }
}
export class FuncionesCartera {
    public static SucursalesInformacion: any [] = [];
    public static InformacionSaldoColocacion: any [] = [];
    public static SaldoTotalColocacion: number = 0;
    public static MontoOriginalColocacion: number = 0;
    public static ClasificacionEdad: string [] = ["[0 - 17]","[18 - 24]","[25 - 34]","[35 - 49]","[50 - 69]","[70+]"];
    public static ClasificacionMes: string[] = ["ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic"];
    public static data_RangoEdad: any [] = [];
    public static setDataRangoEdad(val){
        this.data_RangoEdad = val;
    }
    public static getDataRangoEdad(){
        return this.data_RangoEdad;
    }
    public static setSucursalesInformacion(val){
        this.SucursalesInformacion = val;
    }
    public static getSucursalesInformacion(){
        return this.SucursalesInformacion;
    }
    public static getSaldoTotalColocacion(val){
        this.SaldoTotalColocacion = val;
    }
    public static setSaldoTotalColocacion(){
        return this.SaldoTotalColocacion;
    }
    public static getMontoOriginalColocacion(val){
        this.MontoOriginalColocacion = val;
    }
    public static setMontoOriginalColocacion(){
        return this.MontoOriginalColocacion;
    }
    public static buscarsucursal(name){
        var sucursalbuscada = "";
        for(var i = 0; i < this.getSucursalesInformacion().length;i ++){
            if(this.getSucursalesInformacion()[i].dbId == name){
                sucursalbuscada = this.getSucursalesInformacion()[i].strName;
            }
          }
        return sucursalbuscada;
    }
    public static SumaSaldoTotalMontoOriginal(arreglo){
        this.getSaldoTotalColocacion(0);
        this.getMontoOriginalColocacion(0);
        for(var i = 0; i < arreglo.length;i++){
            this.getSaldoTotalColocacion(this.setSaldoTotalColocacion() + arreglo[i].value);
            this.getMontoOriginalColocacion(this.setMontoOriginalColocacion() + arreglo[i].value);
        }
    }
    public static SumaColocacionProducto(arreglo){
        var suma = 0;
        for(var i = 0; i < arreglo.length; i++){
            suma = suma + arreglo[i].value;
        }
        suma = 1;
        for(var i = 0 ; i < arreglo.length; i++){
            arreglo[i].value = ((arreglo[i].value/suma)*100);
        }
        return arreglo;
    }
    public static setInformacionSaldoColocacion(){
        return this.InformacionSaldoColocacion;
    }
    public static getInformacionSaldoColocacion(val){
        this.InformacionSaldoColocacion = val;
    }
    public static ConvertirGraficaBarras(arreglo){
        this.getInformacionSaldoColocacion([]);
        for(var i = 0; i < arreglo.length; i++){
            this.InformacionSaldoColocacion.push({"name":arreglo[i].name,series:[{"name":"saldo","value":arreglo[i].value},{"name":"colocacion","value":arreglo[i].value2}]});
        }
        return this.setInformacionSaldoColocacion();
    }
    public static ConvertirGraficaBarrasPagination(arreglo,nopagina,norows){
        this.getInformacionSaldoColocacion([]);
        var inicio = (nopagina - 1)*norows;
        var fin = nopagina*norows;
        if(fin > arreglo.length){
            fin = arreglo.length;
        }
        for(var i = inicio; i < fin; i++){
            this.InformacionSaldoColocacion.push({"name":arreglo[i].name,series:[{"name":"saldo","value":arreglo[i].saldo},{"name":"colocacion","value":arreglo[i].colocacion}]});
        }
        return this.InformacionSaldoColocacion;
    }
    public static ConvertirGraficaLinealPagination(arreglo,nopagina,norows){
        this.getInformacionSaldoColocacion([]);
        var inicio = (nopagina - 1)*norows;
        var fin = nopagina*norows;
        if(fin > arreglo.length){
            fin = arreglo.length;
        }
        var aux = [];
        for(var i = inicio; i < fin; i++){
            aux.push({"name":arreglo[i].name,"value":arreglo[i].saldo});
        }
        this.InformacionSaldoColocacion.push({"name":"Saldo",series:aux});
        aux = [];
        for(var i = inicio; i < fin; i++){
            aux.push({"name":arreglo[i].name,"value":arreglo[i].colocacion});
        }
        this.InformacionSaldoColocacion.push({"name":"Colocación",series:aux});
        return this.InformacionSaldoColocacion;
    }
    public static ConvertirGraficaBurbujaPagination(nopagina,norows){
        var arreglo_burbuja = [];
        var inicio = (nopagina - 1)*norows;
        var fin = nopagina*norows;
        if(fin > FuncionesGraficas.setBubble().length){
            fin = FuncionesGraficas.setBubble().length;
        }
        var aux = [];
            for(var i = inicio; i < fin; i++){
                arreglo_burbuja.push(FuncionesGraficas.setBubble()[i]);
                //aux.push({"name":FuncionesGraficas.setBubble()[i].series[0].name,"x":FuncionesGraficas.setBubble()[i].series[0].name,"y":FuncionesGraficas.setBubble()[i].series[0].y,"r":FuncionesGraficas.setBubble()[i].series[0].y});
            }
            //arreglo_burbuja.push({"name":,"series":aux});
            aux = [];
        return arreglo_burbuja;
    }
    public static OrdenarRangoEdad(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].name > arreglo[j + 1].name){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        this.data_RangoEdad = arreglo;
        return arreglo;
    }
    public static ConvertirGraficaBurbujaBarra(arreglo){
        var barra = [];
        for(var i = 0; i < arreglo.length;i++){
            for(var j = 0; j < arreglo[i].series.length; j++){
                barra.push({"name":arreglo[i].name,"value":arreglo[i].series[j].y})
            }
        }
        return barra;

    }
    public static ConvertirGraficaBurbujaLineal(arreglo){
        var lineal = [];
        var aux = [];
        for(var i = 0; i < arreglo.length;i++){
            for(var j = 0; j < arreglo[i].series.length; j++){
                aux.push({"name":arreglo[i].series[j].name,"value":arreglo[i].series[j].y})
            }
        }
        lineal.push({"name":"IMOR","series":aux})
        return lineal;
    }
    public static ConvertirGraficaGoogleComboPagination(arreglo,nopagina,norows){
        var dataArr = [];
        var inicio = (nopagina - 1)*norows;
        var fin = nopagina*norows;
        if(fin > arreglo.length){
            fin = arreglo.length;
        }
        for(var i = inicio; i < fin; i++){
            dataArr.push(arreglo[i]);
        }
        console.log("ComboGoogle ---> " + JSON.stringify(dataArr) + " ----> " + JSON.stringify(arreglo));
        return dataArr;
    }
}

import { colorSets,colorSetsUsuario } from './../../util/color-sets';
import { FuncionesArreglos } from './funcionesAfiliacion';
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { FLAGS } from 'html2canvas/dist/types/dom/element-container';
import { timestamp } from 'rxjs/operators';
export class FuncionesColores{
    static selectedColorScheme_paleta_azul: string = "";
    static paleta_azul: any;
    static colorScheme: any;
    static colorbubble: any;
    static colorSets: any;
    static defaultColorScheme = 'aqua';
    public static setStackedAzul(color){
        //{"name":"saldocolocacion","selectable":true,"group":"Ordinal","domain":["#007298","#008FBE","#3BB0C9","#76C4D5","#A1DBEB","#B2E6F3","#0075C9","#33AFE5","#004A97","#004A97"]}
        const colors = {   "name":color.name,
                            "selectable": true,
                            "group": "Ordinal",
                            "domain":[color.domain[color.domain.length - 1]]
                        };
        return colors;
    }
    public static setBarStacked(color,barStacked){
        var elementos = [];
        var colorStacked = color;
        for(var i = 0; i < barStacked.length; i++){
            for(var j = 0; j < barStacked[i].series.length;j++){
                if(!elementos.includes(barStacked[i].series[j].name)){
                    elementos.push(barStacked[i].series[j].name);
                }
            }
        }
        var col = [];
        if(color.domain.length > elementos.length){
            for(var i = 0; i < elementos.length; i++){
                col.push(color.domain[i]);
            }
            colorStacked = {"name":color.name,
                            "selectable": true,
                            "group": "Ordinal",
                            "domain": col
                        };

        }
        
        //console.log("Elementos ---> " + JSON.stringify(elementos) + " color ---> " + JSON.stringify(col) + "\n---> " + JSON.stringify(colorStacked));
        return colorStacked;
    }
    static setpaleta_azul(name) {
        this.inicioColores();
        this.selectedColorScheme_paleta_azul = name;
        this.colorScheme = this.colorSets.find(s => s.name === name);
        if(this.colorScheme === undefined ){
          this.selectedColorScheme_paleta_azul = this.defaultColorScheme;
          this.colorScheme = this.colorSets.find(s => s.name === this.defaultColorScheme);
        }
        return this.colorScheme;
    }
    static inicioColores(){
        this.colorSets = colorSets;
    }
    public static setpaletaBubble(colores){
        var color = "";
        for(var i = 0; i < colores.domain.length;i++){
            if(i == 0){
                color = colores.domain[i];
            }
        }
        //this.colorbubble.push({"name":colores.name,"selectable":true,"group":colores.group,"domain":[color]});
        //this.colorbubble = {"name":colores.name,"selectable":true,"group":colores.group,"domain":[color]};
        this.colorbubble = {"name":colores.name,"selectable":true,"group":colores.group,"domain":["#3FAE2A"]};
        return this.colorbubble;
    }
    public static setcolorsGoogleCharts(colores){
        var color = [];
        for(var i = 0; i < colores.domain.length;i++){
            color.push(colores.domain[i]);
        }
        return color;
    }
}
export class FuncionesGraficas{
    static bubble: any [] = [];
    static ActivosInactivos:  any [] = [];
    static anios: any [] = [];
    static separador:string =  ","; // separador para los miles
    static sepDecimal:string = '.'; // separador para los decimales
    static getBubble(bubble){
        this.bubble = bubble;
    }
    static setBubble(){
        return this.bubble;
    }
    public static formatearnormal(num){
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return  splitLeft +splitRight;
    }
    public static retornarformatolefty(valor){
        if(valor >= 1000000){
            valor =  this.formatearnormal(valor/1000000) + ' M';
        }else{
            if(valor >= 10000){
                valor =  this.formatearnormal(valor/1000) + ' K';
            }else{
                if(valor >= 1000){
                    valor = this.formatearnormal(valor/1000) + ' K';
                }
            }
        }
        return valor;
    }
    public static crearBubble(arreglo){
        this.getBubble([]);
        var aux = [];
        for(var i = 0; i < arreglo.length; i++){
            aux = [];
            var radio = 1*arreglo[i].value;
            aux.push({"name":arreglo[i].name,"x":arreglo[i].name,"y":arreglo[i].value,"r":radio});
            //this.bubble.push({"name":"burbuja","series":aux});
            this.bubble.push({"name":arreglo[i].name,"series":aux});
            aux = [];
        }
        return this.setBubble();
    }
}

export class FuncionesFiltros{
    public static OrdenarMayorMenorSaldos(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].saldo < arreglo[j + 1].saldo){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldos(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].saldo > arreglo[j + 1].saldo){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosVG(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vg < arreglo[j + 1].vg){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosVG(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vg > arreglo[j + 1].vg){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosVC(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vc < arreglo[j + 1].vc){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosVC(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vc > arreglo[j + 1].vc){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosImor(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].imor < arreglo[j + 1].imor){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosImor(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].imor > arreglo[j + 1].imor){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorColocacion(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].colocacion < arreglo[j + 1].colocacion){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        
        return arreglo;
    }
    public static OrdenarMenorMayorColocacion(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].colocacion > arreglo[j + 1].colocacion){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosPE(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].pe < arreglo[j + 1].pe){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosPE(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].pe > arreglo[j + 1].pe){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosMT(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].mt < arreglo[j + 1].mt){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosMT(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].mt > arreglo[j + 1].mt){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }


    public static OrdenarMayorMenorSaldosNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].saldo < arreglo[j + 1].saldo){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"saldo":arreglo_aux[i].saldo,"colocacion":arreglo_aux[i].colocacion});
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].saldo > arreglo[j + 1].saldo){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"saldo":arreglo_aux[i].saldo,"colocacion":arreglo_aux[i].colocacion});
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosNumVG(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vg < arreglo[j + 1].vg){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosNumVG(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vg > arreglo[j + 1].vg){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosNumVC(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vc < arreglo[j + 1].vc){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosNumVC(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].vc > arreglo[j + 1].vc){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosNumImor(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].imor < arreglo[j + 1].imor){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosNumImor(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].imor > arreglo[j + 1].imor){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosNumPE(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].pe < arreglo[j + 1].pe){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosNumPE(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].pe > arreglo[j + 1].pe){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMayorMenorSaldosNumMT(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].mt < arreglo[j + 1].mt){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorSaldosNumMT(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].mt > arreglo[j + 1].mt){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"vg":arreglo_aux[i].vg,"vc":arreglo_aux[i].vc,"imor":arreglo_aux[i].imor,"pe":arreglo_aux[i].pe,"mt":arreglo_aux[i].mt});
            }
        }
        return arreglo;
    }

    public static OrdenarMayorMenorColocacionNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].colocacion < arreglo[j + 1].colocacion){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"saldo":arreglo_aux[i].saldo,"colocacion":arreglo_aux[i].colocacion});
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorColocacionNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].colocacion > arreglo[j + 1].colocacion){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"saldo":arreglo_aux[i].saldo,"colocacion":arreglo_aux[i].colocacion});
            }
        }
        return arreglo;
    }

    public static OrdenarMayorMenorNombre(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(this.BuscarSucursal(arreglo[j].name,FuncionesCartera.getSucursalesInformacion()) > this.BuscarSucursal(arreglo[j + 1].name,FuncionesCartera.getSucursalesInformacion())){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorNombre(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(this.BuscarSucursal(arreglo[j].name,FuncionesCartera.getSucursalesInformacion()) < this.BuscarSucursal(arreglo[j + 1].name,FuncionesCartera.getSucursalesInformacion())){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }

    public static OrdenarMayorMenorNombrePerspectiva(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].name > arreglo[j + 1].name){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static OrdenarMenorMayorNombrePerspectiva(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].name < arreglo[j + 1].name){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    public static BuscarSucursal(name, arreglo){
        var retorno = "";
        for(var i = 0; i < arreglo.length;i ++){
            if(arreglo[i].dbId == name){
                retorno = arreglo[i].strName;
            }
        }
        return retorno;
    }
}

export class FuncionesPerspectivasC2{
    
    public static ArregloFiltros: any [] = [];//contendrá los filtros aplicados en un arreglo
    public static cadenaFiltro: string = "";
    public static ocupacion: boolean = false;
    public static estado: boolean = false;
    public static estadocivil: boolean = false;
    public static genero: boolean = false;
    public static localidad: boolean = false;
    public static municipio: boolean = false;
    public static rangoedad: boolean = false;
    public static sucursal: boolean = false;

    public static getArregloFiltros(valor){
        this.ArregloFiltros = valor;
    }
    public static setArregloFiltros(){
        return this.ArregloFiltros;
    }
    public static getCadenaFiltro(valor){
        this.cadenaFiltro = valor;
    }
    public static setCadenaFiltro(){
        return this.cadenaFiltro;
    }
    public static getOcupacion(valor){
        this.ocupacion = valor;
    }
    public static setOcupacion(){
        return this.ocupacion;
    }
    public static getEstado(valor){
        this.estado = valor;
    }
    public static setEstado(){
        return this.estado;
    }
    public static getEstadoCivil(valor){
        this.estadocivil = valor;
    }
    public static setEstadoCivil(){
        return this.estadocivil;
    }
    public static getGenero(valor){
        this.genero = valor;
    }
    public static setGenero(){
        return this.genero;
    }
    public static getLocalidad(valor){
        this.localidad = valor;
    }
    public static setLocalidad(){
        return this.localidad;
    }
    public static getMunicipio(valor){
        this.municipio = valor;
    }
    public static setMunicipio(){
        return this.municipio;
    }
    public static getRangoEdad(valor){
        this.rangoedad = valor;
    }
    public static setRangoEdad(){
        return this.rangoedad;
    }
    public static getSucursal(valor){
        this.sucursal = valor;
    }
    public static setSucursal(){
        return this.sucursal;
    }
    static ReiniciarVariables(){
        this.getOcupacion(false);
        this.getEstado(false);
        this.getEstadoCivil(false);
        this.getGenero(false);
        this.getLocalidad(false);
        this.getMunicipio(false);
        this.getRangoEdad(false);
        this.getSucursal(false);
    }
    public static EliminarUltElemento(colocacionEntitty){
        var ult_elemt = "";
        for(var i = 0; i < this.setArregloFiltros().length;i++){
            ult_elemt = this.setArregloFiltros()[i].cadena;
        }
        colocacionEntitty = this.SwitchFuntion(colocacionEntitty,ult_elemt);
        this.setArregloFiltros().pop();
        return colocacionEntitty;
    }
    public static SwitchFuntion(colocacionEntitty,cadena){
        switch (cadena) {
            case 'municipio':
                colocacionEntitty.municipioid = null;
                colocacionEntitty.municipiodesc = null;                
            break;
            case 'sucursal':
                colocacionEntitty.sucursal = null;
                colocacionEntitty.sucursaldesc = null;                
            break;
            case 'localidad':
                colocacionEntitty.localidadid = null;
                colocacionEntitty.localidaddesc = null;                
            break;
        }
        colocacionEntitty.sexoid = null;
        colocacionEntitty.sexodesc = null;
        return colocacionEntitty;
    }
    public static RevisionFlags(){
        if(this.setOcupacion() || this.setEstadoCivil() || this.setLocalidad() || this.setMunicipio() || this.setRangoEdad() || this.setGenero() || this.setEstado() || this.setSucursal()){
            return true;
        }else{
            return false
        }
    }
    static ConstruirArregloFiltros(valor_click_grafica){
        if(this.RevisionFlags()){
            if(!this.VerificarArregloFiltros()){
                this.ArregloFiltros.push({"cadena":this.setCadenaFiltro(),"valor":valor_click_grafica});
            }else{
                this.ActualizarArregloFiltros(valor_click_grafica);
            }
        }
    }
    static ActualizarArregloFiltros(valor){
        for(var i = 0; i < this.setArregloFiltros().length;i++){
            if(this.setArregloFiltros()[i].cadena == this.setCadenaFiltro()){
                this.setArregloFiltros()[i].valor = valor;
            }
        }
    }
    static VerificarArregloFiltros(){
        var flag_existe = false;
        for(var i = 0; i < this.setArregloFiltros().length;i++){
            if(this.setArregloFiltros()[i].cadena == this.setCadenaFiltro()){
                flag_existe = true;
            }
        }
        return flag_existe;
    }
    static ContenidoArreglos(){
        var cadena = "";
        for(var i = 0; i < this.setArregloFiltros().length; i++){
            if(i == 0){
                cadena = " | " + this.setArregloFiltros()[i].valor;
            }else{
                cadena = cadena + " | " + this.setArregloFiltros()[i].valor;
            }
        }
        return cadena;
    }
}


export class FuncionesExportarCSVColocacion{
    public static EncabezadoCartera: string [] = ['Año','Mes','Clave Socio Cliente','No Contrato','Sucursal','Clasificación','Tipo Producto','Monto Original','Plazo','Días Mora','Cápital Vignete','Cápital Vencido','Int. Dev. Vigente','Int. Dev. Vencido','Nombre','Primer Apellido','Segundo Apellido'];

    public static ArrayColocacion: any [] = [];
    public static DimensionEncabezado: number = 0;
    public static Abecedario: string [] = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
    public static getDimensionEncabezado(value){
        this.DimensionEncabezado = value;
    }
    public static setDimensionEncabezado(){
        return this.DimensionEncabezado;
    }
    public static getArrayColocacion(arreglo){
        this.ArrayColocacion = arreglo;
    }
    public static setArrayColocacion(){
        return this.ArrayColocacion;
    }
    static ExportarCSVInspeccionarColocacion(colocacion){
        this.getArrayColocacion([]);
        for(var i = 0; i < colocacion.length; i++){
            this.ArrayColocacion.push(this.PushArrrayColocacion(colocacion[i]));
        }
        this.getArrayColocacion(this.ArrayColocacion);
        return this.setArrayColocacion();
    }
    static PushArrrayColocacion(arreglo){
        var array = [];
        array.push(arreglo.anio);
        array.push(arreglo.mes);
        array.push(arreglo.clavesociocliente);
        array.push(arreglo.nocontrato);
        array.push(arreglo.sucursaldesc);
        array.push(arreglo.clasificacion);
        array.push(arreglo.tipoproducto);
        array.push(arreglo.montooriginal);
        array.push(arreglo.plazo);
        array.push(arreglo.diasmora);
        array.push(arreglo.capitalvigente);
        array.push(arreglo.capitalvencido);
        array.push(arreglo.intdevnocobvig);
        array.push(arreglo.intdevnocobvenc);
        array.push(arreglo.nombre);
        array.push(arreglo.primerap);
        array.push(arreglo.segundoap);
        return array;
    }
    static DimensionEncabezadoOrdenAlfabetico(encabezado){
        this.getDimensionEncabezado(encabezado.length);
        if(this.setDimensionEncabezado() <= 26){
            return this.Abecedario[this.setDimensionEncabezado() - 1];
        }
    }
}

export class FuncionesAnioMes{
    public static arreglo_mes: any [] = [];
    public static ContruirArregloMes(arreglo){
        var cadena = [];
        for(var i = 0; i < arreglo.length; i++){
            for(var j = 0; j < arreglo[i].arrSeries.length; j++){
                if(!this.existemes(cadena,arreglo[i].arrSeries[j].strName)){
                    cadena.push({'MesId': arreglo[i].arrSeries[j].strName, 'MesDsc': FuncionesMes.f_switchmes(arreglo[i].arrSeries[j].strName),'dbCheck':true,'MesDscAbre':FuncionesMes.f_switchmes2(arreglo[i].arrSeries[j].strName)});
                }
            }
        }
        cadena[cadena.length - 1].dbCheck = true;
        return cadena;
    }
    public static ContruirArregloMes2(arreglo){
        var cadena = [];
        for(var i = 0; i < arreglo.length; i++){
            for(var j = 0; j < arreglo[i].arrSeries.length; j++){
                if(!this.existemes(cadena,arreglo[i].arrSeries[j].strName)){
                    cadena.push({'MesId': arreglo[i].arrSeries[j].strName, 'MesDsc': FuncionesMes.f_switchmes(arreglo[i].arrSeries[j].strName),'dbCheck':false,'MesDscAbre':FuncionesMes.f_switchmes2(arreglo[i].arrSeries[j].strName)});
                }
            }
        }
        cadena[cadena.length - 1].dbCheck = true;
        return cadena;
    }
    public static existemes(arreglo,mes){
        var flag = false;
        for(var i = 0; i < arreglo.length; i ++){
            if(arreglo[i].MesId == mes){
                flag = true;
            }
        }
        return flag;
    }
    public static ArregloMeses(arreglomes){
        var cadena = "";
        var contador_meses = 0;
        for(var i = 0; i < arreglomes.length; i++){
            if(arreglomes[i].dbCheck){
              if(cadena.search(arreglomes[i].MesId) < 0){
                if(contador_meses == 0){
                  cadena = "'" + FuncionesMes.f_switchmes3(arreglomes[i].MesId) + "'";
                }else{
                  cadena = cadena + ",'" + FuncionesMes.f_switchmes3(arreglomes[i].MesId) + "'";
                }
              }
              contador_meses ++;
            }
        }
        return cadena;
    }
    public static ArregloAnios(arregloanios){
        var contador_anios = 0;
        var cadena = "";
        for(var i = 0; i < arregloanios.length; i++){
            if(arregloanios[i].dbCheck){
                if(contador_anios == 0){
                    cadena = "'" + arregloanios[i].strName + "'";
                }else{
                    cadena = cadena + " ,'" + arregloanios[i].strName +"'";
                }
                contador_anios ++;
            }
        }
        return cadena;
    }
    public static SubtituloMes(arreglomes){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < arreglomes.length; i++){
            if(arreglomes[i].dbCheck){
              if(cadena.search(arreglomes[i].MesDscAbre) < 0){
                if(contador == 0){
                  cadena = "" + arreglomes[i].MesDscAbre + "";
                }else{
                  cadena = cadena + " . " + arreglomes[i].MesDscAbre;
                }
              }
              contador ++;
            }
        }
        return cadena;
    }
    public static SubtituloAnio(arregloanio){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < arregloanio.length; i++){
            if(arregloanio[i].dbCheck){
              if(cadena.search(arregloanio[i].strName) < 0){
                if(contador == 0){
                  cadena = "" + arregloanio[i].strName + "";
                }else{
                  cadena = cadena + " . " + arregloanio[i].strName;
                }
              }
              contador ++;
            }
        }
        return cadena;
    }
    public static UltMes(arreglomes){
        var mes = "";
        for(var i = 0; i < arreglomes.length; i++){
            if(arreglomes[i].dbCheck){
                mes = arreglomes[i].MesId;
            }
        }
        console.log("UltMesSeleccionado ----> " + mes);
        return mes;
    }
    public static SeleccionarTodosMeses(arreglo){
        for(var i = 0; i < arreglo.length; i++){
            arreglo[i].dbCheck = true;
        }
        return arreglo;
    }
    public static ConstruirArregloMesAux(arreglo){
        this.arreglo_mes = [];
        for(var i = 0; i < arreglo.length; i++){
            if(arreglo[i].dbCheck){
                this.arreglo_mes.push(arreglo[i]);
            }
        }
        return this.arreglo_mes;
    }
    public static ConstuirArregloAniosAux(arreglo){
        var arreglo_anio = [];
        for(var i = 0; i < arreglo.length; i++){
            if(arreglo[i].dbCheck){
                arreglo_anio.push(arreglo[i]);
            }
        }
        return arreglo_anio;
    }
    public static UltAnio(arreglo){
        var anio = "";
        for(var i = 0; i < arreglo.length;i++){
            if(arreglo[i].dbCheck){
                anio = arreglo[i].strName;
            }
        }
        return anio;
    }
    public static AniosVolumenes(arreglo){
        var cadena = "";
        var contador = 0;
        for(var j = 0; j < arreglo.length;j++){
            if(contador == 0){
                cadena = arreglo[j].name;
            }else{
                cadena = cadena + " . " + arreglo[j].name;
            }
            contador++;
        }
        return cadena;
    }
    public static MesesVolumenes(arreglo){
        var cadena = "";
        var contador = 0;
        //console.log("ConsoleMesesVolumenes ---> " + JSON.stringify(arreglo));
        for(var j = 0; j < arreglo.length;j++){
            if(arreglo[j].dbCheck){
                if(contador == 0){
                    cadena = arreglo[j].MesId;
                }else{
                    cadena = cadena + " . " + arreglo[j].MesId;
                }
                contador++;
            }
        }
        return cadena;
    }
}
export class FuncionesMes{
    public static f_switchmes(mes){
        switch(mes){
            case '01': mes = 'enero';break;
            case '02': mes = 'febrero';break;
            case '03': mes = 'marzo';break;
            case '04': mes = 'abril';break;
            case '05': mes = 'mayo';break;
            case '06': mes = 'junio';break;
            case '07': mes = 'julio';break;
            case '08': mes = 'agosto';break;
            case '09': mes = 'septiembre';break;
            case '10': mes = 'octubre';break;
            case '11': mes = 'noviembre';break;
            case '12': mes = 'diciembre';break;
        }
        return mes;
    }
    public static f_switchmes2(mes){
        switch(mes){
            case '01': mes = 'ene';break;
            case '02': mes = 'feb';break;
            case '03': mes = 'mar';break;
            case '04': mes = 'abr';break;
            case '05': mes = 'may';break;
            case '06': mes = 'jun';break;
            case '07': mes = 'jul';break;
            case '08': mes = 'ago';break;
            case '09': mes = 'sep';break;
            case '10': mes = 'oct';break;
            case '11': mes = 'nov';break;
            case '12': mes = 'dic';break;
        }
        return mes;
    }
    public static f_switchmes3(mes){
        switch(mes){
            case 'ene': mes = '01';break;
            case 'feb': mes = '02';break;
            case 'mar': mes = '03';break;
            case 'abr': mes = '04';break;
            case 'may': mes = '05';break;
            case 'jun': mes = '06';break;
            case 'jul': mes = '07';break;
            case 'ago': mes = '08';break;
            case 'sep': mes = '09';break;
            case 'oct': mes = '10';break;
            case 'nov': mes = '11';break;
            case 'dic': mes = '12';break;
        }
        return mes;
    }
    public static f_switchmesi(mes){
        switch(mes){
            case 'ENERO': mes = '01';break;
            case 'FEBRERO': mes = '02';break;
            case 'MARZO': mes = '03';break;
            case 'ABRIL': mes = '04';break;
            case 'MAYO': mes = '05';break;
            case 'JUNIO': mes = '06';break;
            case 'JULIO': mes = '07';break;
            case 'AGOSTO': mes = '08';break;
            case 'SEPTIEMBRE': mes = '09';break;
            case 'OCTUBRE': mes = '10';break;
            case 'NOVIEMBRE': mes = '11';break;
            case 'DICIEMBRE': mes = '12';break;
        }
        return mes;
    }
    public static obteneranios(arreglo){
        var cadena = "";
        for(var i = 0; i < arreglo.length; i++){
            if(i == 0){
                cadena = arreglo[i].name;
            }else{
                cadena = cadena + " . " + arreglo[i].name;
            }
        }
        return cadena;
    }
}

export class FuncionesIMOR{
    public static imormaximo: number = 0;
    public static dataIndiceMorosidad: any [] = [];
    public static setDataIndiceMorosidad(val){
        this.dataIndiceMorosidad = val;
    }   
    public static getDataIndiceMorosidad(){
        return this.dataIndiceMorosidad;
    } 
    public static getIMORMaximo(){
        return this.imormaximo;
    }
    public static setIMORMaximo(val){
        this.imormaximo = val;
    }
    public static imorminimo: number = 0;
    public static getIMORMinimo(){
        return this.imorminimo;
    }
    public static setIMORMinimo(val){
        this.imorminimo = val;
    }
    public static IMORMaximoMinimo(arreglo){
        var array = arreglo;
        var array2 = arreglo;
        this.setIMORMinimo(arreglo[0].value);
        this.setIMORMaximo(arreglo[0].value);
        for(var i = 1; i < arreglo.length;i++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(this.getIMORMinimo() > arreglo[j + 1].value){
                    this.setIMORMinimo(array[j + 1].value);
                }
            }
        }

        for(var i = 1; i < arreglo.length;i++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(this.getIMORMaximo() < arreglo[j + 1].value){
                    this.setIMORMaximo(arreglo[j + 1].value);
                }
            }
        }
        if(this.getIMORMaximo() == 0){
            this.setIMORMaximo(arreglo[arreglo.length - 1].value);
        }
    }
    public static updateImorMaximoMinimo(arreglo){
        var cadena = [];
        //console.log("IMORMaximoMinimo Series----> " + JSON.stringify(arreglo));
        for(var i = 0; i < arreglo.length;i++){
            if(arreglo[i].series){
                for(var j = 0; j < arreglo[i].series.length;j++){
                    arreglo[i].series[j].value = arreglo[i].series[j].value + (Math.random()*(10) + 1)/10;
                    arreglo[i].series[j].value = arreglo[i].series[j].value.toFixed(2);
                }
            }
        }
        cadena = arreglo;
        //console.log("IMORMaximoMinimo ----> " + JSON.stringify(cadena));
        return cadena;
    }
    public static IMORMaximoMinimoComboGoogle(arreglo){
        //console.log("ComboGoogle  ----> " + JSON.stringify(arreglo));
        var array = [];
        var array2 = arreglo;
        for(var i = 0; i < arreglo.length;i++){
            //console.log("ComboGoogle  ----> " + JSON.stringify(arreglo[i]) + " ----> " + arreglo[i][arreglo[i].length -1] );
            array.push(arreglo[i][arreglo[i].length -1]);
        }
        //console.log("ComboGoogle array  ----> " + JSON.stringify(array) + " ----> ");
        this.setIMORMinimo(array[0].toFixed(2));
        this.setIMORMaximo(array[0].toFixed(2));
        for(var i = 1; i < array.length;i++){
            for(var j = 0; j < (array.length - i ); j++){
                if(this.getIMORMinimo() > array[j + 1]){
                    this.setIMORMinimo(array[j + 1].toFixed(2));
                }
            }
        }

        for(var i = 1; i < array.length;i++){
            for(var j = 0; j < (array.length - i ); j++){
                if(this.getIMORMaximo() < array[j + 1]){
                    this.setIMORMaximo(array[j + 1].toFixed(2));
                }
            }
        }
    }
}

export class FuncionesCobertura{
    public static BandaMorosidad: any [] = [];
    public static CarteraVGVCSucursal:  any [] = [];
    public static SumBandaMorosidad: number = 0;
    public static SumaBandaMorosidadSaldoVG: number = 0;
    public static SumaBandaMorosidadSaldoVC: number = 0;
    public static ArrayLimpio: any [] = [];
    public static SumaBandaMorosidad(arreglo){
        this.SumBandaMorosidad = 0; 
        for(var i = 0 ; i < arreglo.length; i++){
            this.SumBandaMorosidad += arreglo[i].value;
        }    
    }
    public static CarteraVigenteMorosidadSaldoVencido(arreglo){
        this.SumaBandaMorosidadSaldoVG = 0;
        this.SumaBandaMorosidadSaldoVC = 0;
        for(var i = 0 ; i < arreglo.length; i++){
            for(var j = 0; j < arreglo[i].series.length; j++){
                if(arreglo[i].series[j].name == "VG"){
                    this.SumaBandaMorosidadSaldoVG += arreglo[i].series[j].value;
                }
                if(arreglo[i].series[j].name == "VC"){
                    this.SumaBandaMorosidadSaldoVC += arreglo[i].series[j].value;
                }
            }            
        }
    }
    public static ConvertirBandaMorosidad(arreglo){
        this.BandaMorosidad = [];
        this.SumaBandaMorosidad(arreglo);
        for(var i = 0 ; i < arreglo.length; i++){
            this.BandaMorosidad.push({"name":arreglo[i].name,"banda":(arreglo[i].value/this.SumBandaMorosidad)*100,"perdidapor":(arreglo[i].value/this.SumBandaMorosidad)*100,"perdidasaldo":arreglo[i].value});
        }
        return this.BandaMorosidad;
    }
    public static ConvertirBandaMorosidadVGVC(arreglo){
        this.BandaMorosidad = [];
        this.CarteraVigenteMorosidadSaldoVencido(arreglo);
        for(var i = 0 ; i < arreglo.length; i++){
            //for(var j = 0; j < arreglo[i].series.length; j++){
                this.BandaMorosidad.push({  "name":arreglo[i].name,
                                            //"banda":(this.BuscarVGVCBandaMorosidad(arreglo[i].series,"VG")/this.SumaBandaMorosidadSaldoVG)*100,//saldovigente
                                            "banda":this.BuscarVGVCBandaMorosidad(arreglo[i].series,"VG"),//saldovigente
                                            "perdidapor":(this.BuscarVGVCBandaMorosidad(arreglo[i].series,"VC")/this.SumaBandaMorosidadSaldoVC)*100,//perdida esperada ---> cartera vencida
                                            "perdidasaldo":this.BuscarVGVCBandaMorosidad(arreglo[i].series,"VC")});
            //}
        }
        return this.BandaMorosidad;
    }
    public static BuscarVGVCBandaMorosidad(arreglo,estatus){
        var valor = 0; 
        for(var i = 0; i < arreglo.length; i++){
            if(arreglo[i].name == estatus){
                valor = arreglo[i].value;
            }
        }
        return valor;

    }
    public static ConvertirRiesgoPorSucursal(arreglo,arreglo2){
        this.CarteraVGVCSucursal = [];
        var carteratotal = 0;
        for(var i = 0; i < arreglo.length; i++){
            for(var j = 0; j < arreglo[i].series.length; j++){
                if(arreglo[i].series[j].name == "VG"){
                    carteratotal += arreglo[i].series[j].value;
                }
            }
        }

        //this.UnirOrdenarRegistros(arreglo);

        for(var i = 0; i < arreglo.length; i++){
            this.CarteraVGVCSucursal.push({
                "name":arreglo[i].name,
                "vg":this.buscarVGVC(arreglo,arreglo[i].name,"VG"),
                "vc":this.buscarVGVC(arreglo,arreglo[i].name,"VC"),
                "imor":this.IMOR(arreglo,arreglo[i].name).toFixed(2),
                "pe":0,
                "mt":((this.buscarsucursal(arreglo2,arreglo[i].name)/carteratotal)*100).toFixed(2)
            });
        }
        return this.CarteraVGVCSucursal;
    }
    public static buscarsucursal(arreglo,sucursal){
        var mora = 0;
        for(var i = 0; i < arreglo.length; i ++){
            if(arreglo[i].name == sucursal){
                mora = arreglo[i].value;
            }
        }
        return mora;
    }
    public static buscarVGVC(arreglo,name,val){
        var suma = 0; 
        for(var i = 0; i < arreglo.length; i++){
            if(arreglo[i].name == name){
                for(var j = 0; j < arreglo[i].series.length; j++){
                    if(arreglo[i].series[j].name == val){
                        suma = arreglo[i].series[j].value;
                    }
                }
            }
        }
        return suma;
    }
    public static IMOR(arreglo,name){
        var imor = 0; 
        var vg = 0, vc = 0;
        for(var i = 0 ; i < arreglo.length; i++){
            if(arreglo[i].name == name){
                for(var j = 0; j < arreglo[i].series.length; j++){
                    if(arreglo[i].series[j].name == "VG"){
                        vg = arreglo[i].series[j].value;
                    }
                    if(arreglo[i].series[j].name == "VC"){
                        vc = arreglo[i].series[j].value;
                    }
                }
            }
        }
        imor = vc/vg*100;
        return imor;
    }
    public static ConvertirBarraCombo(arreglo){
        var cadena = [];
        for(var i = 0; i < arreglo.length; i++){
            cadena.push({"name":arreglo[i].name,"value":arreglo[i].perdidasaldo});
        }
        return cadena;
    }
    public static ConvertirLinealCombo(arreglo){
        var cadena = [];
        var aux = [];
        //banda
        for(var i = 0; i < arreglo.length; i ++){
            aux.push({"name":arreglo[i].name,"value":arreglo[i].banda.toFixed(2)});
        }
        cadena.push({"name":"bandamorosidad",series:aux});
        //perdida
        aux = [];
        for(var i = 0; i < arreglo.length; i ++){
            aux.push({"name":arreglo[i].name,"value":arreglo[i].perdidapor.toFixed(2)});
        }
        cadena.push({"name":"perdidaesperada",series:aux});
        return cadena;
    }
    public static AuxArreglo: any [] = [];
    public static UnirOrdenarRegistros(arreglo){
        this.AuxArreglo = arreglo;
        for(var i = 0; i < this.AuxArreglo.length; i++){
            var flag = this.BuscarDuplicado(this.AuxArreglo,i,arreglo[i].name);
            if(flag != -1){
                this.CombinarSeries(this.AuxArreglo,i,flag);
            }
        }
    }
    public static BuscarDuplicado(arreglo,position,clasificacion){
        var flag = -1;
        for(var i = 0; i < this.AuxArreglo.length; i++){
            if(this.AuxArreglo[i].name == clasificacion && position != i){
                flag = i;
            }
        }
        return flag;
    }
    public static CombinarSeries(arreglo,original,duplicado){
        for(var i = 0; i < this.AuxArreglo[original].series.length; i++){
            if(this.AuxArreglo[original].series[i].name != this.AuxArreglo[duplicado].series[i].name){
                this.AuxArreglo[original].series.push(this.AuxArreglo[duplicado].series[i]);
            }
        }
        this.AuxArreglo.splice(duplicado, 1 );
        return arreglo[original].series;
    }
    public static ExisteEnSerie(original,){

    }
}
export class FuncionesCoberturaAnioMes{
    public static informacion_datos: any [] = [];
    public static getInformacionDatos(val){this.informacion_datos = val;}
    public static setInformacionDatos(){return this.informacion_datos;}
    public static informacion_datos_aux: any [] = [];
    public static getInformacionDatosAux(val){this.informacion_datos_aux = val;}
    public static setInformacionDatosAux(){return this.informacion_datos_aux;}
    public static data_arreglomes: any [] = [];
    public static getArregloMes(val){this.data_arreglomes = val;}
    public static setArregloMes(){return this.data_arreglomes;}
    public static data_arreglomesaux: any [] = [];
    public static getArregloMesAux(val){this.data_arreglomesaux = val;}
    public static setArregloMesAux(){return this.data_arreglomesaux;}
    public static contador_anios: number = 0;
    public static contador_meses: number = 0;
    public static checked_anio: boolean = false;
    public static checked_mes: boolean = false;
    public static ult_anio: string = "";
    public static getUltAnio(val){this.ult_anio = val;}
    public static setUltAnio(){return this.ult_anio;}
    public static ult_mes: string = "";
    public static getUltMes(val){this.ult_mes = val;}
    public static setUltMes(){return this.ult_mes;}
    public static arreglo_anio: string = "";
    public static arreglo_meses: string = "";
    public static Anio(anio){
        this.informacion_datos_aux = [];
        var id = "";
        this.contador_anios = 0;
        if(anio == 0){
            if(this.checked_anio){
                this.checked_anio = false;
            }else{
                this.checked_anio = true;
            }
            for(var i = 0; i < this.informacion_datos.length; i++){
                if(this.checked_anio){
                    this.informacion_datos[i].dbCheck = true;
                }else{
                    this.informacion_datos[i].dbCheck = false;
                    this.contador_anios ++;
                }
            }
        }else{
            for(var i = 0; i < this.informacion_datos.length;i++){
                id = "";
                id = this.informacion_datos[i].strName;
                if(anio == this.informacion_datos[i].strName){
                    this.informacion_datos[i].dbCheck = true;
                    this.getUltAnio(this.informacion_datos[i].strName);
                }else{
                    this.informacion_datos[i].dbCheck = false;
                }
            }
        }
        //this.informacion_datos_aux = this.informacion_datos;
    }
    public static Mes(mes){
        this.data_arreglomesaux = [];
        if(mes == 0){
            if(this.checked_mes){
                this.checked_mes = false;
            }else{
                this.checked_mes = true;
            }
            for(var i = 0; i < this.data_arreglomes.length; i++){
                if(this.checked_mes)
                   this.data_arreglomes[i].dbCheck = true;
                else
                    this.data_arreglomes[i].dbCheck = false;
            }
        }else{
            for(var i = 0; i < this.data_arreglomes.length; i++){
                if(mes == this.data_arreglomes[i].MesDsc){
                    this.data_arreglomes[i].dbCheck = true;
                    this.ult_mes = this.data_arreglomes[i].MesId;
                }else{
                    this.data_arreglomes[i].dbCheck = false;
                }
            }
        }
    }
    public static ObtenerUltAnio(){
        this.informacion_datos[this.informacion_datos.length - 1 ].dbCheck = true;
        for(var i = 0;i < this.informacion_datos.length;i++){
          this.ult_anio = this.informacion_datos[i].strName;
          var id = "";
          id = this.informacion_datos[i].strName;
          for(var j = 0; j < this.informacion_datos[i].arrSeries.length;j ++){
            this.ult_mes = this.informacion_datos[i].arrSeries[j].strName;
            this.informacion_datos[i].arrSeries[j].strName = FuncionesMes.f_switchmes(this.informacion_datos[i].arrSeries[j].strName);
          }
        }
    }
    public static StringAnio(){
        this.arreglo_anio = "";
        this.contador_anios = 0;
        for(var i = 0; i < this.informacion_datos.length; i++){
            if(this.informacion_datos[i].dbCheck){
                if(this.contador_anios == 0){
                    this.arreglo_anio = this.informacion_datos[i].strName;
                }else{
                    this.arreglo_anio = this.arreglo_anio + " , " + this.informacion_datos[i].strName;
                }
                this.contador_anios ++;
            }
        }
        if(this.contador_anios == 0){
            this.informacion_datos[this.informacion_datos.length - 1].dbCheck = true;
            this.contador_anios = 1;
            this.arreglo_anio = this.informacion_datos[this.informacion_datos.length - 1].strName;
        }
        for(var i = 0; i < this.informacion_datos.length; i++){
            if(this.informacion_datos[i].dbCheck){
                this.informacion_datos_aux.push(this.informacion_datos[i]);
            }
        }
    }
    public static StringMes(){
        this.arreglo_meses = "";
        this.contador_meses = 0;
        for(var i = 0; i < this.data_arreglomes.length; i++){
            if(this.data_arreglomes[i].dbCheck){
                if(this.arreglo_meses.search(this.data_arreglomes[i].MesId) < 0){
                    if(this.contador_meses == 0){
                        this.arreglo_meses = this.data_arreglomes[i].MesId;
                    }else{
                        this.arreglo_meses = this.arreglo_meses + "," + this.data_arreglomes[i].MesId;
                    }
                    this.contador_meses ++;
                }
            }
        }
        if(this.contador_meses == 0){
            for(var i = 0; i < this.data_arreglomes.length;i++){
                this.data_arreglomes[i].dbCheck = true;
                if(this.contador_meses == 0){
                    this.arreglo_meses = this.data_arreglomes[i].MesId;
                }else{
                    this.arreglo_meses = this.arreglo_meses + "," + this.data_arreglomes[i].MesId;
                }
                this.contador_meses ++;
            }
        }
        for(var i = 0; i < this.data_arreglomes.length; i++){
            if(this.data_arreglomes[i].dbCheck){
                this.data_arreglomesaux.push(this.data_arreglomes[i]);
            }
        }
    }
}

export class FuncionesDestino{
    public static GoogleComboData: any [] = [];
    public static GoogleComboDataAux: any [] = [];
    public static BarraVigenteVencida: any [] = [];
    public static ArrayStackedVigenteVencida: any [] = [];
    public static LineaVigenteVencida: any [] = [];
    public static ArregloPagination: any [] = [];
    public static getArregloPagination(val){
        this.ArregloPagination = val;
    }
    public static setArregloPagination(){
        return this.ArregloPagination;
    }
    public static ConvertirVigenteVencida(vigente,vencida){
        this.BarraVigenteVencida = [];
        this.LineaVigenteVencida = [];
        this.GoogleComboDataAux = [];
        var auxgoogle = [];
        for(var i = 0; i < vigente.length; i++){
            if(this.BuscarMesVencido(vigente[i].name,vencida)){
                for(var j = 0; j < vigente[i].series.length; j ++){
                    this.BarraVigenteVencida.push({
                            "name":vigente[i].series[j].name + " " + vigente[i].name,
                            "series":[{
                                    "name":"vigente",
                                    "value":vigente[i].series[j].value
                                },
                                {
                                    "name":"vencido",
                                    "value":vencida[i].series[j].value
                                }
                            ]
                        });
                    auxgoogle.push({
                            "name":vigente[i].series[j].name,
                            "series":[{
                                    "name":"vigente",
                                    "value":vigente[i].series[j].value
                                },
                                {
                                    "name":"vencido",
                                    "value":vencida[i].series[j].value
                                }
                            ]
                        });
                }
            }
            auxgoogle = [];
            this.GoogleComboDataAux.push({"name":vigente[i].name,"series":auxgoogle});
        }

        var aux = [];
        for(var i = 0; i < vigente.length; i++){
            for(var j = 0; j < vigente[i].series.length;j++){
                aux.push({"name":vigente[i].name + " " + vigente[i].series[j].name,"value":vigente[i].series[j].value});
            }
        }
        this.LineaVigenteVencida.push({"name":"vigente","series":aux});
        aux = [];
        for(var i = 0; i < vencida.length; i++){
            for(var j = 0; j < vencida[i].series.length;j++){
                aux.push({"name":vencida[i].name + " " + vencida[i].series[j].name,"value":vencida[i].series[j].value});
            }
        }
        this.LineaVigenteVencida.push({"name":"vencida","series":aux});
        console.log("CarteraVigenteVencidaActividadOcupación Aux --> " + JSON.stringify(this.BarraVigenteVencida));
        return this.BarraVigenteVencida;
    }
    public static ArrayStackedGroupVigenteVencida(vigente,vencida){
        var aux = [];
        this.ArrayStackedVigenteVencida = [];
        for(var i = 0; i < vigente.length;i++){
            if(this.BuscarVencidaName(vigente[i].name,vencida)){
                aux = [];
                for(var j = 0; j < vigente[i].series.length;j++){
                    var arreglorest = this.BuscarVencidaSeriesName(vigente[i].name,vigente[i].series[j].name,vencida);
                    if(arreglorest[0].flag){
                        aux.push({
                            'name': vigente[i].series[j].name,
                            'series':[{
                                'name':'vigente',
                                'value':vigente[i].series[j].value,
                            },{
                                'name':'vencido',
                                'value':arreglorest[0].valor
                            }]});
                    }
                }
                this.ArrayStackedVigenteVencida.push({'name':vigente[i].name,'series':aux});
            }
        }
    }
    public static BuscarVencidaSeriesName(name,seriesname,vencida){
        var arregloflag = [{"flag":false,"valor":0}];
        for(var i = 0; i < vencida.length; i++){
            if(name == vencida[i].name){
                for(var j = 0; j < vencida[i].series.length;j++){
                    if(seriesname == vencida[i].series[j].name){
                        arregloflag[0].flag = true;
                        arregloflag[0].valor = vencida[i].series[j].value;
                    }
                }
            }
        }
        return arregloflag;
    }
    public static BuscarVencidaName(name,vencida){
        var flag = false;
        for(var i = 0; i < vencida.length; i++){
            if(name == vencida[i].name){
                flag = true;
            }
        }
        return flag;
    }
    public static BuscarMesVencido(mes,vencida){
        var flag = false;
        for(var i = 0; i < vencida.length; i++){
            if(vencida[i].name == mes){
                flag = true
            }
        }
        return flag;
    }
    public static LabelsClasificacionEdad(arreglo){
        var labels = [];
        for(var i = 0 ; i < arreglo.length; i ++){
            labels.push(arreglo[i].name);
        }
        return labels;
    }
    public static DataClasificacionEdad(arreglo){
        var Data = [];
        var aux = [];
        for(var i = 0; i < arreglo.length; i ++){
            aux.push(arreglo[i].value);
        }
        Data.push({"data":aux,"label":"IndiceMorosidad"})
        return Data;
    }
    public static DataIMOR_RangoEdad(arreglo,carteravitente){
        var Data = [];
        var aux = [];
        FuncionesCartera.setDataRangoEdad([]);
        FuncionesCartera.setDataRangoEdad(arreglo);
        for(var i = 0; i < arreglo.length; i ++){
            console.log("Operación RangoEdad----((" + arreglo[i].value + "/" + carteravitente + ")*" + 100 + ").toFixed(2)" + " Resultado ---> " + ((arreglo[i].value/carteravitente)*100).toFixed(2));
            aux.push(((arreglo[i].value/carteravitente)*100).toFixed(2));
            FuncionesCartera.getDataRangoEdad()[i].value = ((arreglo[i].value/carteravitente)*100).toFixed(2);
        }
        Data.push({"data":aux,"label":"IndiceMorosidad"})
        return Data;
    }


    public static DiezMejoresOcupacion(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].name < arreglo[j + 1].name){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = [];
        if(arreglo.length > 10){
            for(var i = 0; i < 10; i++){
                arreglo_aux.push(arreglo[i]);
            }
            arreglo = arreglo_aux;
        }
        return arreglo;
    }
    public static IMORCarteraVencidaOcupacion(arregloocupacion,carteratotal){
        var data = [];
        console.log("ArregloOcupacion ----> " + JSON.stringify(arregloocupacion) + "  CarteraTotal --->  " + carteratotal);
        for(var i = 0; i < arregloocupacion.length;i++){
            data.push({
                "name":arregloocupacion[i].name,
                "imor":((arregloocupacion[i].value/carteratotal)*100).toFixed(2),
                "peres": 0,
                "imortec":-.01
                //"imortec":(this.OpeMorosidadTecnica(arregloocupacion[i].name,arreglomayor0,arreglosaldototal)/100).toFixed(2)
            });
            console.log("ArregloOcupacion ----> " + arregloocupacion[i].value + " / " + carteratotal + ") + CarteraTotal --->  " + ((arregloocupacion[i].value/carteratotal)*100).toFixed(2));
        }
        return data;
    }




    public static CarteraOcupacion(arregloocupacion,arreglomayor0,arreglosaldototal){
        var data = [];
        for(var i = 0; i < arregloocupacion.length; i++){
            data.push({
                "name":arregloocupacion[i].name,
                "imor":arregloocupacion[i].value,
                "peres": 0,
                "imortec":(this.OpeMorosidadTecnica(arregloocupacion[i].name,arreglomayor0,arreglosaldototal)/100).toFixed(2)
            });
        }
        return data;
    }
    public static OpeMorosidadTecnica(ocupacion, arreglomayor0,arreglosaldototal){
        var var1 = 0, var2 = 0;
        for(var i = 0; i < arreglomayor0.length; i++){
            if(ocupacion == arreglomayor0[i].name){
                var1 = arreglomayor0[i].value;
            }
        }
        for(var i = 0; i < arreglosaldototal.length; i++){
            if(ocupacion == arreglosaldototal[i].name){
                var2 = arreglosaldototal[i].value;
            }
        }
        if(var2 != 0){
            return var1/var2;
        }else{
            return 0;
        }
    }
    public static ConvertirStackedGraficaPagination(nopagina,norows){
        var arreglo_pagination = [];
        this.BarraVigenteVencida = [];
        var inicio = (nopagina - 1)*norows;
        var fin = nopagina*norows;
        if(fin > this.setArregloPagination().length){
            fin = this.setArregloPagination().length;
        }
        for(var i = inicio; i < fin; i++){
            this.BarraVigenteVencida.push(this.setArregloPagination()[i]);
        }
        this.ConvertirStackedGraficaPaginationLineal();
        return this.BarraVigenteVencida;
    }
    public static ConvertirStackedGraficaPaginationLineal(){
        this.LineaVigenteVencida = [];
        var vigente = [];
        var vencido = [];
        for(var i = 0 ; i < this.BarraVigenteVencida.length;i++){
            for(var j = 0; j < this.BarraVigenteVencida[i].series.length; j++){
                if(this.BarraVigenteVencida[i].series[j].name == 'vigente'){
                    vigente.push({"name":this.BarraVigenteVencida[i].name,"value":this.BarraVigenteVencida[i].series[j].value});
                }
                if(this.BarraVigenteVencida[i].series[j].name == 'vencido'){
                    vencido.push({"name":this.BarraVigenteVencida[i].name,"value":this.BarraVigenteVencida[i].series[j].value});
                }
            }
        }
        this.LineaVigenteVencida.push({"name":"vigente","series":vigente});
        this.LineaVigenteVencida.push({"name":"vencido","series":vencido});
    }
    public static ConvertirGraficaBarrasPagination(arreglo,nopagina,norows){
        var arregloRes = [];
        var inicio = (nopagina - 1)*norows;
        var fin = nopagina*norows;
        if(fin > arreglo.length){
            fin = arreglo.length;
        }
        for(var i = inicio; i < fin; i++){
            arregloRes.push(arreglo[i]);
        }
        return arregloRes;
    }
    public static ConvertirGraficaLinealPagination(arreglo){
        console.log("Lineal ---> " + JSON.stringify(arreglo));
    }
    public static GoogleDataCombo(arreglo){
        this.GoogleComboData = [];
        var arregloMes = [];
        for(var i = 0; i < arreglo.length;i++){
            if(!arregloMes.includes(arreglo[i].name)){
                arregloMes.push(arreglo[i].name);
            }
        }
        console.log("ArregloCadenaX ---> " + JSON.stringify(arregloMes));
        var arregloaux = [];
        for(var i = 0; i < arregloMes.length;i++){
            arregloaux.push(arregloMes[i]);
            arregloaux.push(this.CapitalVigenteGoogleCombo(arreglo,"F",arregloMes[i],"vigente"));
            arregloaux.push(this.CapitalVigenteGoogleCombo(arreglo,"M",arregloMes[i],"vigente"));
            arregloaux.push(this.PorcentajeCapitalVigenteGoogleCombo(arreglo,"F",arregloMes[i],"vencido"));
            arregloaux.push(this.PorcentajeCapitalVigenteGoogleCombo(arreglo,"M",arregloMes[i],"vencido"));
            this.GoogleComboData.push(arregloaux);
            arregloaux = [];
        }
        console.log("Arreglo CarteraVigenteVencida ---> " + JSON.stringify(arregloMes) + " ---> " + JSON.stringify(this.GoogleComboData));
        return this.GoogleComboData;
    }
    public static PorcentajeCapitalVigenteGoogleCombo(arreglo,genero,mes,estado){
        var suma = 0;
        var val = 0;
        for(var i = 0; i < arreglo.length;i++){
            for(var j = 0; j < arreglo[i].series.length;j++){
                if(arreglo[i].series[j].name == genero){
                    for(var k = 0; k < arreglo[i].series[j].series.length;k++){
                        if(arreglo[i].name == mes && arreglo[i].series[j].series[k].name == estado && arreglo[i].series[j].name == genero){
                            val = arreglo[i].series[j].series[k].value;
                        }
                        if(arreglo[i].series[j].series[k].name == estado){
                            suma += arreglo[i].series[j].series[k].value;
                        }
                    }
                }
            }
        }
        return (val/suma)*100;

    }
    public static CapitalVigenteGoogleCombo(arreglo,genero,mes,estado){
        var val = 0;
        for(var i = 0; i < arreglo.length;i++){
            if(arreglo[i].name == mes){
                for(var j = 0; j < arreglo[i].series.length;j++){
                    if(arreglo[i].series[j].name == genero){
                        for(var k = 0; k < arreglo[i].series[j].series.length;k++){
                            if(arreglo[i].series[j].series[k].name == estado && estado == "vigente"){
                                console.log("Arreglo CarteraVigenteVencida Vigente ---> " + arreglo[i].series[j].series[k].name + " " + genero + " ---> " + arreglo[i].series[j].name + " --- " + arreglo[i].name + " --> " + mes);
                                val = arreglo[i].series[j].series[k].value;
                            }
                            if(arreglo[i].series[j].series[k].name == estado && estado == "vencido"){
                                console.log("Arreglo CarteraVigenteVencida Vencido ---> " + arreglo[i].series[j].series[k].name + " " + genero + " ---> " + arreglo[i].series[j].name + " --- " + arreglo[i].name + " --> " + mes);
                                val = arreglo[i].series[j].series[k].value;
                            }
                        }
                    }
                }
            }
        }
        return val;
    }
}

export class FuncionesEvolucion{
    //{ data: [1, 4, 3], label: 'Approved', type: 'line',yAxisID: 'y-axis-1' },
    public static SumaSaldoSucursal: number = 0;
    public static SumaColocacionSucursal: number = 0;
    public static TablaSaldoColocacion: any [] = [];
    public static data_resultStacked: any [] = [];
    public static data_lineStacked: any [] = [];
    public static data_resultStackedAnios: any [] = [];
    public static data_lineStackedAnios: any [] = [];
    public static data_google: any [] = [];
    public static data_googleAnios: any [] = [];
    
    public static FResultStacked(saldo,colocacion){
        this.data_lineStacked = [];
        this.data_resultStacked = [];
        if(saldo.length == colocacion.length){
            for(var i = 0; i < saldo.length; i++){
                this.data_resultStacked.push({"name":saldo[i].name,"series":[{"name":"saldo","value":saldo[i].value},{"name":"colocacion","value":colocacion[i].value}]});
            }
        }
        var aux = [];
        var sumaSaldo = 0;
        var sumaColocacion = 0; 
        for(var i = 0; i < this.data_resultStacked.length; i++){
            for(var j = 0; j < this.data_resultStacked[i].series.length; j++){
                if(this.data_resultStacked[i].series[j].name == "saldo"){
                    sumaSaldo += this.data_resultStacked[i].series[j].value;
                }else{
                    sumaColocacion += this.data_resultStacked[i].series[j].value;
                }
            }
        }
        for(var i = 0; i < this.data_resultStacked.length; i++){
            for(var j = 0; j < this.data_resultStacked[i].series.length; j++){
                if(this.data_resultStacked[i].series[j].name == "colocacion"){
                    aux.push({"name":this.data_resultStacked[i].name,"value": ((this.data_resultStacked[i].series[j].value/sumaColocacion)*100).toFixed(2)});
                }
            }
        }
        this.data_lineStacked.push({"name":"Porcentaje","series":aux});
        return this.data_resultStacked;
    }
    public static FResultStackedAnios(saldo,colocacion,anio){
        var auxb = [];
        this.FResultStacked(saldo,colocacion);
        console.log("Saldo " + JSON.stringify(saldo) + "\nColocacion " + JSON.stringify(colocacion) + "\anios --> " + anio);
        if(saldo.length == colocacion.length){
            for(var i = 0; i < saldo.length; i++){
                auxb.push({"name":saldo[i].name,"series":[{"name":"saldo","value":saldo[i].value},{"name":"colocacion","value":colocacion[i].value}]});
            }
        }
        this.data_resultStackedAnios.push({"name":anio,"dbCheck":true,"series":auxb});
        var aux = [];
        var sumaSaldo = 0;
        var sumaColocacion = 0; 
        for(var i = 0; i < this.data_resultStacked.length; i++){
            for(var j = 0; j < this.data_resultStacked[i].series.length; j++){
                if(this.data_resultStacked[i].series[j].name == "saldo"){
                    sumaSaldo += this.data_resultStacked[i].series[j].value;
                }else{
                    sumaColocacion += this.data_resultStacked[i].series[j].value;
                }
            }
        }
        for(var i = 0; i < this.data_resultStacked.length; i++){
            for(var j = 0; j < this.data_resultStacked[i].series.length; j++){
                if(this.data_resultStacked[i].series[j].name == "colocacion"){
                    aux.push({"name":this.data_resultStacked[i].name,"value": ((this.data_resultStacked[i].series[j].value/sumaColocacion)*100).toFixed(2)});
                }
            }
        }
        this.data_lineStackedAnios.push({"name":anio,"series":[{"name":"Porcentaje","series":aux}]});
        //console.log("Anios ---> " + JSON.stringify(this.data_lineStackedAnios));
        /*for(var i = 0; i < this.data_resultStackedAnios.length;i++){
            console.log("Anios ---> [" + i + "] " + JSON.stringify(this.data_resultStackedAnios[i].series));
        }*/
        return this.data_resultStackedAnios;
    }
    public static DataLine(saldo,colocacion){
        var por = [];
        var DataGraph = [];
        this.BuscarSaldoColocacion(saldo,colocacion);
        for(var i = 0; i < saldo.length; i ++){
            por.push(((colocacion[i].value/saldo[i].value)*100).toFixed(2));
        }
        DataGraph.push({data: por, label: '%', type: 'line',yAxisID: 'y-axis-1'})
        DataGraph.push( { data: [206413138.68, 200700209.61, 201252363.33, 192535138.32, 207063157.16, 184054078.77], label: 'Colocacion', type: 'bar' },
                        { data: [1308511405.55, 1461111262.81, 1600048054.78, 1739962442.03, 1888642179.08,2004213286.92], label: 'Saldo', type: 'bar' });
       return DataGraph;
    }
    public static BuscarSaldoColocacion(saldo,colocacion){
        this.TablaSaldoColocacion = [];
        for(var i = 0; i < saldo.length; i++){
            var saldocolocacion = this.BuscarNameColocacion(saldo[i].name,colocacion);
            if(saldocolocacion != 0){
                this.TablaSaldoColocacion.push({"name":saldo[i].name,"saldo":saldo[i].value,"colocacion":saldocolocacion});
            }
        }
    }
    public static BuscarNameColocacion(val,colocacion){
        var valor = 0;
        for(var i = 0; i < colocacion.length; i++){
            if(colocacion[i].name == val){
                valor = colocacion[i].value;
            }
        }
        return valor;
    }
    public static ChartLabels(arreglo){
        var Labels = [];
        for(var i = 0; i < arreglo.length; i ++){
            Labels.push(arreglo[i].name);
        }
        return Labels;
    }
    public static FunctionTablaSaldoColocacion(SaldoSucursal,ColocacionSucursal){
        this.TablaSaldoColocacion = [];
        this.FunctionSaldoSucursal(SaldoSucursal);
        this.FunctionColocacionSucursal(ColocacionSucursal);
        for(var i = 0; i < SaldoSucursal.length; i++){
            this.TablaSaldoColocacion.push({"name":SaldoSucursal[i].name,"saldo":SaldoSucursal[i].value,"colocacion":this.BuscarColocacion(SaldoSucursal[i].name,ColocacionSucursal)});
        }
        return this.TablaSaldoColocacion;
    }
    public static FunctionSaldoSucursal(SaldoSucursal){
        this.SumaSaldoSucursal = 0;
        for(var i = 0; i < SaldoSucursal.length; i++){
            this.SumaSaldoSucursal += SaldoSucursal[i].value;
        }
    }
    public static FunctionColocacionSucursal(ColocacionSucursal){
        this.SumaColocacionSucursal = 0;
        for(var i = 0; i < ColocacionSucursal.length; i++){
            this.SumaColocacionSucursal += ColocacionSucursal[i].value;
        }
    }
    public static BuscarColocacion(sucursal,ColocacionSucursal){
        var val = 0; 
        for(var i = 0; i < ColocacionSucursal.length; i++){
            if(ColocacionSucursal[i].name == sucursal){
                val = ColocacionSucursal[i].value;
            }
        }
        return val;

    }
    public static OrdenarStackedArray(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].name > arreglo[j + 1].name){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        this.data_resultStackedAnios = arreglo;
        return arreglo;
    }
    public static GoogleBar(arreglo){
        this.data_google = [];
        var cad = [];
        var aux = [];
        for(var i = 0; i < arreglo.length;i++){
            for(var j = 0; j < arreglo[i].series.length;j++){
                cad.push(arreglo[i].series[j].value);
            }
            aux.push(arreglo[i].name);
            for(var k = 0; k < cad.length;k++){
                aux.push(cad[k]);
            }
            aux.push(this.PorcentajeColocacion(arreglo[i].name,arreglo));
            this.data_google.push(aux);
            cad = [];
            aux = [];
        }
        //console.log("ArregloGoogleBar  ---> " + JSON.stringify(this.data_google));
        return this.data_google;
    }
    public static PorcentajeColocacion(mes,arreglo){
        var suma = 0;
        var col = 0;
        for(var i = 0; i < arreglo.length;i++){
            for(var j = 0; j < arreglo[i].series.length;j++){
                if(arreglo[i].name == mes && arreglo[i].series[j].name == "colocacion"){
                    col = arreglo[i].series[j].value;
                }
                suma += arreglo[i].series[j].value;
            }
        }
        return (col/suma)*100;
    }
    public static GoogleBarAnios(arreglo){
        this.data_googleAnios = [];
        var cad = [];
        var aux = [];
        for(var m = 0; m < arreglo.length;m++){
            for(var i = 0; i < arreglo[m].length;i++){
                for(var j = 0; j < arreglo[m].series[i].series.length;j++){
                    cad.push(arreglo[i].series[j].value);
                    //console.log(" GoogleBar ---> " + arreglo[i].name + " " + arreglo[i].series[j].value);
                }
                //console.log("GoogleBar cad ---> " + cad);
                aux.push(arreglo[i].name);
                for(var k = 0; k < cad.length;k++){
                    aux.push(cad[k]);
                }
                //console.log("GoogleBar aux cad ---> " + JSON.stringify(aux) + " cad --> " + JSON.stringify(cad));
                aux.push(this.PorcentajeColocacion(arreglo[i].name,arreglo));
                //console.log("GoogleBar aux ----> " + JSON.stringify(aux));
                this.data_google.push(aux);
                cad = [];
                aux = [];
            }
            this.data_googleAnios.push({"name":arreglo[m].name,"series":this.data_google});
            //console.log("ResultStackedAnio GoogleBarAnios ---> " + JSON.stringify(this.data_googleAnios));
        }
        return this.data_googleAnios;
    }
}

export class FuncionesIndicadores{
    public static ROA: number = 0;
    public static MargenFinanciero: number = 0;
    public static GastosAdmon: number = 0; 
    public static setROA(){
        return this.ROA;
    }
    public static getROA(val){
        this.ROA = val;
    }
    public static setMargenFinanciero(){
        return this.MargenFinanciero;
    }
    public static getMargenFinanciero(val){
        this.MargenFinanciero = val;
    }
    public static setGastosAdmon(){
        return this.GastosAdmon;
    }
    public static getGastosAdmon(val){
        this.GastosAdmon = val;
    }
    public static BuscarIndicadores(DetalleIndicadores){
        for(var i = 0; i < DetalleIndicadores.length; i++){
            this.FuncionSwitch(DetalleIndicadores[i].Id_Indicador,DetalleIndicadores[i].Valor);
        }
    }
    public static FuncionSwitch(Id_Indicador,Valor){
        switch(Id_Indicador){
            case "RT2": 
                this.getROA(Valor)
            break;
            case "RT3": 
                this.getMargenFinanciero(Valor)
            break;
            case "RT4": 
                this.getGastosAdmon(Valor)
            break;
        }

    }
}