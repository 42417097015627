import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TendenciaComponent } from './tendencia/tendencia.component';
import {ColocacionRoutingModule} from './colocacion-routing.module';
import {SharedModule} from '../shared/shared.module';
import { PerfilComponent } from './perfil/perfil.component';
import { MetaVsRealComponent } from './meta-vs-real/meta-vs-real.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { TendenciaV2Component } from './tendencia-v2/tendencia-v2.component';
import {MatSortModule} from '@angular/material/sort';
import { EstimacionesComponent } from './estimaciones/estimaciones.component';




@NgModule({
  declarations: [
    TendenciaComponent,
    PerfilComponent,
    MetaVsRealComponent,
    TendenciaV2Component,
    EstimacionesComponent
  ],
  imports: [
    CommonModule,
    ColocacionRoutingModule,
    SharedModule,
    GoogleChartsModule,
    MatSortModule
  ]
})
export class ColocacionModule { }
