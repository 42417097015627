import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WelcomeComponent} from '../../components/welcome/welcome.component';
import {PermisosSubmodulosGuard} from '../../guards/permisos-submodulos/permisos-submodulos.guard';

//import { BalanceGeneralComponent } from './balance-general/balance-general.component';
import { BalanceComponent } from './balance-general/balance-general.component';
import { EstadoDeResultadosComponent } from './estado-de-resultados/estado-de-resultados.component';
import { RazonesComponent } from './razones/razones.component';
import { RentabilidadComponent } from './rentabilidad/rentabilidad.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';

const routes: Routes = [
  {
    path: 'app/financiero',
    component: WelcomeComponent,
    children: [
      //{ path: 'balance-general', component: BalanceGeneralComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'balance-general', component: BalanceComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'estado-de-resultados', component: EstadoDeResultadosComponent, canActivate: [PermisosSubmodulosGuard] },
      //{ path: 'razones', component: RazonesComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'razones', component: IndicadoresComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'rentabilidad', component: RentabilidadComponent, canActivate: [PermisosSubmodulosGuard] },
      //{ path: 'indicadores', component: IndicadoresComponent, canActivate: [PermisosSubmodulosGuard] },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancieroRoutingModule { }
