import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EsqueletoPeticionModel} from '../../../model/esqueletoPeticion.model';
import {FiltrosService} from '../../filtros/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class EstimacionesService {

  private apiURL = environment.apiDevURL;
  private apiURL_es = environment.apiDevURL_Estimaciones;
  private headers = new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }

  /*public getConsumo(valoresAnios: string[], servi: string): Observable<any> {
    var peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'radiografia'
    );
    var valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio);
    }
    peticion.filter = {
        fechas: [{
          campo: 'fechaArchivo',
          valor: valoresMeses[0]
        }]
      };   
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }*/
  public getEstGlobal(valoresAnios: string[], servi: string): Observable<any> {
    var peticion = new EsqueletoPeticionModel(
      'colocacion',
      'estimaciones',
      'estimaciones-grafica',
      'radiografia'
    );
    var valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio);
    }
    peticion.filter = {
        aniomes: {
          campo: 'fechaArchivo',
          mayorque: valoresMeses[0]
        }
      };   
    const body = JSON.stringify(peticion);
    const apiURLL = this.apiURL_es + '/estimacionGlobal';
    return this.http.post(`${apiURLL}`, body, {headers: this.headers});
  }
  public getEstServi(valoresAnios: string[], servi: string, rangos: any[]): Observable<any> {
    var peticion = new EsqueletoPeticionModel(
      'colocacion',
      'estimaciones',
      'estimaciones-grafica',
      'radiografia'
    );
    var valoresMeses = [], camp = "diasMora";
    for (const anio of valoresAnios) {
      valoresMeses.push(anio);
    }
    peticion.filter = {
        aniomes: {
          campo: 'fechaArchivo',
          mayorque: valoresMeses[0]
        },
        campo: "servicio",
        valores: [
          {
            nombre: servi,
            campo: camp,
            rangos: rangos
          }
        ]
      };
    const apiURLL = this.apiURL_es + '/estimacionPorServicio';
    const body = JSON.stringify(peticion);
    return this.http.post(`${apiURLL}`, body, {headers: this.headers});
  }
  public getServicios(valoresAnios: string[], servi: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'perfil',
      'genero',
      'radiografia'
    );
    var valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio);
    }
    
    peticion.filter = {
      aniosmeses:{
        valores: valoresMeses
      }
    };
    
    peticion.groupby = {
      perspectiva: {
        campo: "servicio.keyword",
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
}
