import { Component, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public subject: Subject<string>;
  public indefinite: Subject<string>;
  public close: Subject<string>;

  alert: Subject<string>;
  error: Subject<string>;

  public subjectOk: Subject<string>;
  public subjectError: Subject<string>;
  public subjectWarning: Subject<string>;


  constructor(
    private snackBar: MatSnackBar
  ) {
    this.subject = new Subject();
    this.indefinite = new Subject();
    this.close = new Subject();

    this.alert = new Subject();
    this.error = new Subject();

    this.subjectOk = new Subject();
    this.subjectError = new Subject();
    this.subjectWarning = new Subject();
  }

}


@Component({
  selector: 'app-message-component-snack-done',
  templateUrl: './message.component.snack.done.html',
  styles: [`
    .message-component {
      color: white;
      text-align: center;
    }
  `]
})
export class MessageDoneComponent {
  message: any;
}

@Component({
  selector: 'app-message-component-snack-wait',
  templateUrl: './message.component.snack.wait.html',
  styles: [`
    .message-component {
      color: white;
      text-align: center;
    }
  `]
})
export class MessageWaitComponent {
  message: any;
}

@Component({
  selector: 'app-message-component-snack-error',
  templateUrl: './message.component.snack.error.html',
  styles: [`
  .component-container {
    width: 100%;
    height: 100%;
  }
  .message-component {
    width: 100%;
    color: white;
    float: left;
  }
  .icon-component {
    width: 100%;
    color: red;
    float: right;
    margin-top: 20px;
    text-align: center;
  }
`]
})
export class MessageErrorComponent {
  message: any;
  action: any;

}