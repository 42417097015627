import { Injectable, Type } from '@angular/core';
//import { Workbook } from 'exceljs';

import * as ExcelJS from 'exceljs/dist/exceljs';
import * as fs from 'file-saver';
import { color } from 'html2canvas/dist/types/css/types/color';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor() {
  }
  generateExcel() {
    
    //Excel Title, Header, Data
    const title = 'Car Sell Report';
    const header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"]
    const data = [
      [2007, 1, "Volkswagen ", "Volkswagen Passat", 1267, 10],
      [2007, 1, "   Toyota ", "Toyota Rav4", 819, 6.5],
      [2007, 1, "       Toyota ", "Toyota Avensis", 787, 6.2],
      [2007, 1, "Volkswagen ", "Volkswagen Golf", 720, 5.7],
      [2007, 1, "Toyota ", "Toyota Corolla", 691, 5.4],
      [2007, 1, "Peugeot ", "Peugeot 307", 481, 3.8],
      [2008, 1, "Toyota ", "Toyota Prius", 217, 2.2],
      [2008, 1, "Skoda ", "Skoda Octavia", 216, 2.2],
      [2008, 1, "Peugeot ", "Peugeot 308", 135, 1.4],
      [2008, 2, "Ford ", "Ford Mondeo", 624, 5.9],
      [2008, 2, "Volkswagen ", "Volkswagen Passat", 551, 5.2],
      [2008, 2, "Volkswagen ", "Volkswagen Golf", 488, 4.6],
      [2008, 2, "Volvo ", "Volvo V70", 392, 3.7],
      [2008, 2, "Toyota ", "Toyota Auris", 342, 3.2],
      [2008, 2, "Volkswagen ", "Volkswagen Tiguan", 340, 3.2],
      [2008, 2, "Toyota ", "Toyota Avensis", 315, 3],
      [2008, 2, "Nissan ", "Nissan Qashqai", 272, 2.6],
      [2008, 2, "Nissan ", "Nissan X-Trail", 271, 2.6],
      [2008, 2, "Mitsubishi ", "Mitsubishi Outlander", 257, 2.4],
      [2008, 2, "Toyota ", "Toyota Rav4", 250, 2.4],
      [2008, 2, "Ford ", "Ford Focus", 235, 2.2],
      [2008, 2, "Skoda ", "Skoda Octavia", 225, 2.1],
      [2008, 2, "Toyota ", "Toyota Yaris", 222, 2.1],
      [2008, 2, "Honda ", "Honda CR-V", 219, 2.1],
      [2008, 2, "Audi ", "Audi A4", 200, 1.9],
      [2008, 2, "BMW ", "BMW 3-serie", 184, 1.7],
      [2008, 2, "Toyota ", "Toyota Prius", 165, 1.6],
      [2008, 2, "Peugeot ", "Peugeot 207", 144, 1.4]
    ];
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('data');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
    worksheet.addRow([]);
    var fecha = '2020-06-17';
    let subTitleRow = worksheet.addRow(['Date : ' + fecha]);
    //Add Image
    /*let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.addImage(logo, 'E1:F3');
    worksheet.mergeCells('A1:D2');*/
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF757575' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
      let qty = row.getCell(5);
      let color = 'FF99FF99';
      if (+qty.value < 500) {
        color = 'FF9999'
      }
      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    }
    );
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    //Footer Row
    let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'CarData.xlsx');
    })
  }
  generateExcelEstadistico(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //titulos
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border

    headerRow.eachCell((cell, number) => {
      cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};

      if(number=>1 && number<5){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' },
          bgColor: { argb: '00000000' }
        }
      }else{
        if(number>4 && number<8){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00A3A3A3' },
            bgColor: { argb: '00000000' }
          }
        }else{
          if(number>7){
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00C4C4C4' },
              bgColor: { argb: '00000000' }
            }
          }else{
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: '00000000' }
            }
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            }
          }
        }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    //Add Header Row
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      
      row.eachCell((cell, number) =>{
        cell.numFmt = '$ #,##0.00';
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      });
    });
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelTenenciaColocacion(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva,mes) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //titulos
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border

    headerRow.eachCell((cell, number) => {
      cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};

      if(number=>1 && number<5){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' },
          bgColor: { argb: '00000000' }
        }
      }else{
        if(number>4 && number<8){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00A3A3A3' },
            bgColor: { argb: '00000000' }
          }
        }else{
          if(number>7){
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00C4C4C4' },
              bgColor: { argb: '00000000' }
            }
          }else{
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: '00000000' }
            }
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            }
          }
        }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    //Add Header Row
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      
      row.eachCell((cell, number) =>{
        if(number == 2){
          cell.numFmt = '$  #,##0.00';
        }else if(number === 3){
          cell.numFmt = '#,##0';
        }else if(number >=4 && number <= 6){
          cell.numFmt = '$  #,##0.00';
        }else{
          d[6] = d[6]/100
          cell.numFmt = '0.00';
        }
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      });
    });
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelTenenciaComercial(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva,mes) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //titulos
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border

    headerRow.eachCell((cell, number) => {
      cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};

      if(number=>1 && number<5){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' },
          bgColor: { argb: '00000000' }
        }
      }else{
        if(number>4 && number<8){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00A3A3A3' },
            bgColor: { argb: '00000000' }
          }
        }else{
          if(number>7){
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00C4C4C4' },
              bgColor: { argb: '00000000' }
            }
          }else{
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: '00000000' }
            }
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            }
          }
        }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    //Add Header Row
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      
      row.eachCell((cell, number) =>{
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      });
    });
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelIndicadores(encabezado,dataarreglo,numeroevaluaciones,institucion) {    
    //Excel Title, Header, Data
    const title = 'Indicadores Financieros';
    var header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"]
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('indicadores');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12,  bold: true }
    worksheet.addRow([]);
    var fecha = '2020-06-17';
    let subTitleRow = worksheet.addRow(['Institución : ' + institucion]);
    subTitleRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: false,};

    //worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FF757575"}};
      var fila = String(row);

      let qty = row.getCell(3);
      qty.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      qty = row.getCell(4);
      qty.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      qty = row.getCell(5);
      qty.alignment = {
        vertical: 'middle', horizontal: 'center'
      }

      for(var j = 0; j < numeroevaluaciones;j++){  
        var numcelda = 7 + j;
        let qty = row.getCell(numcelda);

        qty.alignment = {
          vertical: 'middle', horizontal: 'center'
        }

        let color = 'FFFFF';
        var celda = String(qty);

        color = "";
        for(var i = 0; i < celda.length;i++){
          if(celda[i] == 'V'){  
            color = '72D54A';
          }
          if(celda[i] == 'A'){
            color = 'FFCB03';
            }
          if(celda[i] == 'R'){
            color = 'F74C58';
          }
          if(celda[i] == '+'){
            color = '0075C9';
          }
        }
        if(color == ""){
          color ='FFFFFF';
        }
        qty.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
          bgColor: { argb: 'FFFFFF' }
        }
        qty.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FFFFFFFF"}};
      }
    });
    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.addRow([]);
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Indicadores.xlsx');
    });
  }
  generateExcelIndicadoresFormula(encabezado,dataarreglo,numeroevaluaciones) {    
    //Excel Title, Header, Data
    const title = 'Indicadores Financieros';
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('indicadores');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true }
    worksheet.addRow([]);
    var fecha = '2020-06-17';
    let subTitleRow = worksheet.addRow(['Date : ' + fecha]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, }
    //Add Image
    /*let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });*/
    //worksheet.addImage(logo, 'E1:F3');
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FF757575' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      if(celdaa1.substring(0,8) == "        "){
      }else{
        row.eachCell((cell,number) =>{
          //cell.cellFormat().bottomBorderColor("64A3EE");
          cell.border = {bottom: {style: 'thick', color: {argb:'64A3EE'}}};
        });
      }
      for(var j = 0; j < numeroevaluaciones;j++){
        var numcelda = 4 + j;
        let qty = row.getCell(numcelda);
        qty.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
        let color = 'FFFFF';
        var celda = String(qty);
        
        
        color = "";
        for(var i = 0; i < celda.length;i++){
          if(celda[i] == 'V'){
            color = '72D54A';
          }
          if(celda[i] == 'A'){
            color = 'FFCB03';
          }
          if(celda[i] == 'R'){
            color = 'F74C58';
          }
          if(celda[i] == 'A+'){
            color = '0075C9';
          }
        }
        if(color == ""){
          color ='FFFFFF';
        }
        qty.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }

      }
    });
    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.addRow([]);
    //Footer Row
    /*let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    */
    //Merge Cells
    //worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'CarData.xlsx');
    })
  }
  generatoExcelRiesgoTendencia(encabezado,dataarreglo,evaluacion){
    //Excel Title, Header, Data
    const title = 'Riesgo Tendencia';
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('balance');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = evaluacion;
    let subTitleRow = worksheet.addRow(['Fecha : ' + fecha]);
    subTitleRow.font = { name: 'Arial Narrow', family: 4, size: 12,  bold: true }
    //Add Image
    /*let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });*/
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      
      for(var j = 0; j<4; j++){
        let color = '';
        var numcelda = 1+j;
          let qty = row.getCell(numcelda);
          qty.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        color = 'FFFFF';
        var celda = String(qty);
        
        
        qty.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      }
    });
    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.addRow([]);
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'RiesgoTendencia.xlsx');
    })
  }
  generatoExcelMatrizTransicionMonto(encabezado,dataarreglo,evaluacion){
    //Excel Title, Header, Data
    const title = 'Matriz Transición Monto';
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('balance');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = evaluacion;
    let subTitleRow = worksheet.addRow(['Fecha : ' + fecha]);
    subTitleRow.font = { name: 'Arial Narrow', family: 4, size: 12,  bold: true }
    //Add Image
    /*let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });*/
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    let col = 1;
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      for(var j = 0; j<11; j++){
        let color = '';
        var numcelda = 1+j;
        let qty = row.getCell(numcelda);
        var c = String(qty);
        var flag = false;
        for(var i = 0; i < c.length;i++){
          if(c.charAt(i) == '-'){
            flag = true;
          }
        }
        if(col == j ){
          color = "f1f1f1";
        }
        if(col > j ){
          color = "ff9012";
        }
        if(col < j ){
          color = "8bc34a";
        }
        if (col == 10){
          color = "f1f1f1";
        }
        if (j == 0){
          color = "f1f1f1";
        }
      
        qty.alignment = { 
          vertical: 'middle', horizontal: 'center'
        }
        
        qty.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      }
      col += 1;
    });
    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.addRow([]);
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'MatrizTransiciónMonto.xlsx');
    })
  }
  generatoExcelMatrizTransicionCredito(encabezado,dataarreglo,evaluacion){
    //Excel Title, Header, Data
    const title = 'Matriz Transición Créditos';
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('balance');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = evaluacion;
    let subTitleRow = worksheet.addRow(['Fecha : ' + fecha]);
    subTitleRow.font = { name: 'Arial Narrow', family: 4, size: 12,  bold: true }
    //Add Image
    /*let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });*/
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    let col = 1;
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      for(var j = 0; j<11; j++){
        let color = '';
        var numcelda = 1+j;
        let qty = row.getCell(numcelda);
        var c = String(qty);
        var flag = false;
        for(var i = 0; i < c.length;i++){
          if(c.charAt(i) == '-'){
            flag = true;
          }
        }
        if(col == j ){
          color = "0075C9";
        }
        if(col > j ){
          color = "8bc34a";
        }
        if(col < j ){
          color = "ff9012";
        }
        if (col == 10){
          color = "f1f1f1";
        }
        if (j == 0){
          color = "f1f1f1";
        }

        qty.alignment = { 
          vertical: 'middle', horizontal: 'center'
        }
        
        qty.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      }
      col += 1;
    });
    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.addRow([]);
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'MatrizTransiciónCrédito.xlsx');
    })
  }
  generateExcelCosechaClick(encabezado,dataarreglo,titulo,names,porPorcent) {    
    //Excel Title, Header, Data
    const title = ''+names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(titulo);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true }
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, }
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true }
    var pos = 14
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
    })
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      row.eachCell((cell, number) =>{
        if(number >= 1 && number <= 2){
          cell.numFmt = ' #,##0';
          if( cell._value.model.value === 0)
            cell._value.model.value = '';
        }else if(number === 6){
          cell.numFmt = '$ #,##0';
        }
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      });
    });
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 50;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.addRow([]);
    var nom = titulo+'.xlsx';
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    });
  }
  generateExcelCosecha(encabezado,dataarreglo,numeroevaluaciones,names,porPorcent) {    
    //Excel Title, Header, Data
    const title = ''+names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true }
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, }
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true }
    var pos = 14
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    data.forEach(d => {
      let row = worksheet.addRow(d);
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      
      for(var j = 0; j<14; j++){
        let color = '';
        var numcelda = 1+j;
          let qty = row.getCell(numcelda);
          qty.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        //  let color = 'FFFFF';
        var celda = String(qty);
        if(j == 1)
          qty.numFmt = '#,##0';
        if(j>1 && porPorcent && names == 'Porcentaje por mora'){
          var col ='00000000'
          if(qty._value.model.value > 0 && qty._value.model.value <= 5)
            col = '00000000'//'005AB436'
          else if(qty._value.model.value > 5 && qty._value.model.value <= 10)
            col = '00000000'//'00F2C806'
          else if( qty._value.model.value > 10)
            col = '00FF495C'//'00FF495C' '#CF1A2D'
          qty.font = { name: 'Arial Narrow', family: 4, size: 11, color: { argb: col }, bold: true}
          if(qty._value.model.value+'' != '')
            qty._value.model.value = qty._value.model.value /100;
          if(qty._value.model.value == 0)
            qty.numFmt = '0 %';
          else
            qty.numFmt = '0.00 %';
        }else{
          qty.font = { name: 'Arial Narrow', family: 4, size: 11, color: { argb: '00000000' }, bold: false}
          qty.numFmt = '#,##0';
        }
        if(numcelda == 2){
          color = 'f1f1f1';
        }
        if(numcelda == pos){
          color = 'E7E6E6';
        }
        if(numcelda > pos){
          color = 'f1f1f1';
        }
        if(d[j] != undefined){
          qty.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color }
          }
          }
      }
      pos --
    });
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.addRow([]);
    var nom = ''+names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelConcentracion(encabezado,dataarreglo,numeroevaluaciones,names) {    
    //Excel Title, Header, Data
    const title = 'Concentración por '+names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true }
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, }
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Arial Narrow', family: 4, size: 11 }
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FF757575' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      if(celdaa1.substring(0,8) == "        "){
      }else{
        row.eachCell((cell,number) =>{
          //cell.cellFormat().bottomBorderColor("64A3EE");
          //cell.border = {bottom: {style: 'thick', color: {argb:'64A3EE'}}};
        });
      }
      for(var j = 0; j < numeroevaluaciones;j++){
        var numcelda = 3 + j;
        let qty = row.getCell(numcelda);
        qty.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
        let color = 'FFFFF';
        var celda = String(qty);
        color = "";
      }
    });
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.addRow([]);
    var nom = 'Cocentracion'+names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelMayDeud(encabezado,dataarreglo,numeroevaluaciones,names) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true }
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, }
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Arial Narrow', family: 4, size: 11 }
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FF757575' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      if(celdaa1.substring(0,8) == "        "){
      }else{
        row.eachCell((cell,number) =>{
          //cell.cellFormat().bottomBorderColor("64A3EE");
          //cell.border = {bottom: {style: 'thick', color: {argb:'64A3EE'}}};
        });
      }
      for(var j = 0; j < numeroevaluaciones;j++){
        var numcelda = 3 + j;
        let qty = row.getCell(numcelda);
        qty.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
        let color = 'FFFFF';
        var celda = String(qty);
        color = "";
      }
    });
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 75;
    worksheet.getColumn(3).width = 15;
    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelPerfil(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva) {    
    //Excel Title, Header, Data
    const title = perspectiva;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Arial Narrow', family: 4, size: 11 };
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FF757575' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      var fila = String(row);
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      if(celdaa1.substring(0,8) == "        "){
      }else{
        row.eachCell((cell,number) =>{
          //cell.cellFormat().bottomBorderColor("64A3EE");
          //cell.border = {bottom: {style: 'thick', color: {argb:'64A3EE'}}};
        });
      }
      for(var j = 0; j < numeroevaluaciones;j++){
        var numcelda = 3 + j;
        let qty = row.getCell(numcelda);
        qty.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
        let color = 'FFFFF';
        var celda = String(qty);
        color = "";
      }
    });
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateEstimaciones(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva,mes) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //titulos
    worksheet.addRow([]);

    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border

    headerRow.eachCell((cell, number) => {
      cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' }
      }
      
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
    })

    //Add Header Row
    var i = 0;
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }

      row.eachCell((cell, number) =>{
        if(i === data.length-1){
          cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00C4C4C4' },
            bgColor: { argb: '00000000' }
          }
        }else{
          cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: 'FF000000' }
          }
        }
        
        if(number === 2 || number === 5){
          cell.numFmt = ' % 0.00';
          cell.alignment = {
            vertical: 'middle', horizontal: 'right'
          }
          if( cell._value.model.value === 0)
            cell._value.model.value = '';
        }else if(number >= 3){
          cell.numFmt = '$ #,##0.00';
          cell.alignment = {
            vertical: 'middle', horizontal: 'right'
          }
        }else if (number === 1){
          cell.alignment = {
            vertical: 'middle', horizontal: 'left'
          }
          if( cell._value.model.value === 0)
            cell.numFmt = '##0';
        }


      });
      i++;
    });
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelMetavsReal(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva,mes) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //titulos
    worksheet.addRow([]);
    var primer_tit = ['',''+mes,'','','','','','',''];
    let tit1 = worksheet.addRow(primer_tit);
    tit1.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
    worksheet.mergeCells('B6:G6');
    tit1.eachCell((cell, number) =>{
      if(number >1 && number < 8){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' }, //A3A3A3
          bgColor: { argb: '00000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      }else{
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: '00000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      }
    });
    var segundo_tit = ['','ADULTO','','','MENORES','','','META ANUAL'];
    let tit2 = worksheet.addRow(segundo_tit);
    tit2.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
    worksheet.mergeCells('B7:D7');
    worksheet.mergeCells('E7:G7');
    worksheet.mergeCells('H7:I7');
    tit2.eachCell((cell, number) =>{
      if(number >1 && number < 5){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00A3A3A3' },
          bgColor: { argb: '00000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      }else{
        if(number >4 && number < 8){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00C4C4C4' },
            bgColor: { argb: '00000000' }
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }else{
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00000000' }
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }
      }
      
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border

    headerRow.eachCell((cell, number) => {
      if( number == 1)
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FF000000'}};
      else
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};

      if(number>1 && number<5){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' },
          bgColor: { argb: '00000000' }
        }
      }else{
        if(number>4 && number<8){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00A3A3A3' },
            bgColor: { argb: '00000000' }
          }
        }else{
          if(number>7){
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00C4C4C4' },
              bgColor: { argb: '00000000' }
            }
          }else{
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: '00000000' }
            }
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            }
          }
        }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      //cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    //Add Header Row
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      row.eachCell((cell, number) =>{
      if(number == 4 || number >= 7 ){
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FFFFFFFF'} }
        if(d[number-1] >= 90){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0072D54A' },
            bgColor: { argb: '00FFFFFF' }
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }
        if(d[number-1] >=80 && d[number-1] < 90){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFCB03' },
            bgColor: { argb: '00FFFFFF' }
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }
        if(d[number-1] < 80){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00F74C58' },
            bgColor: { argb: '00FFFFFF' }
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }
      }else{
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      }
      });
    });
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelGeografico(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva,mes) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //titulos
    worksheet.addRow([]);
    var primer_tit = [''+mes,''];
    let tit1 = worksheet.addRow(primer_tit);
    tit1.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
    worksheet.mergeCells('A6:B6');
    tit1.eachCell((cell, number) =>{
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' }, //A3A3A3
        bgColor: { argb: '00000000' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    
    headerRow.eachCell((cell, number) => {
      cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
    })
    //Add Header Row
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      row.eachCell((cell, number) =>{
        if((number >= 2 && number <= 3) || (number == 5 )){
          cell.numFmt = ' #,##0.00';
        }
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' }
        }
        if (number === 1){
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }else{
          cell.alignment = {
            vertical: 'middle', horizontal: 'right'
          }
        }
      });
    });
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 22;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelMetavsRealcomercial(encabezado,dataarreglo,numeroevaluaciones,names,perspectiva,mes) {    
    //Excel Title, Header, Data
    const title = names;
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(names);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Roboto', family: 4, size: 16,  bold: true };
    worksheet.addRow([]);
    const fecha = new Date();
    var fech = fecha.toLocaleDateString()
    let subTitleRow = worksheet.addRow(['Date : ' + fech]);
    subTitleRow.font = { name: 'Roboto', family: 4, size: 12, };
    worksheet.mergeCells('A1:C2');
    //Blank Row 
    worksheet.addRow([]);
    //titulos
    worksheet.addRow([]);
    var primer_tit = ['',''+mes,'','','','',''];
    let tit1 = worksheet.addRow(primer_tit);
    tit1.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};
    worksheet.mergeCells('B6:F6');
    tit1.eachCell((cell, number) =>{
      if(number >1 && number < 7){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' }, //A3A3A3
          bgColor: { argb: '00000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      }else{
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: '00000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    
    headerRow.eachCell((cell, number) => {
      if( number == 1 || number == 7)
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FF000000'}};
      else
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, bold: true, color: {argb: 'FFFFFFFF'}};

      if(number>1 && number<7){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' },
          bgColor: { argb: '00000000' }
        }
      }else{
        if(number>4 && number<8){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00000000' }
          }
        }else{
          if(number>7){
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00C4C4C4' },
              bgColor: { argb: '00000000' }
            }
          }else{
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: '00000000' }
            }
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            }
          }
        }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
    })
    //Add Header Row
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 }
      row.eachCell((cell, number) =>{
      if(number == 4 || number == 7 ){
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: '00000000'}};
        cell.numFmt = '0.00%';
        if(d[number-1] >= .90){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00FFFFFF' }
          }
          cell.border = {
            left: {style: 'thick', color: { argb: '0072D54A' },}
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }
        if(d[number-1] >=.80 && d[number-1] < .90){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00FFFFFF' }
          }
          cell.border = {
            left: {style: 'thick', color: { argb: '00FFCB03' },}
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }
        if(d[number-1] < .80){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00FFFFFF' }
          }
          cell.border = {
            left: {style: 'thick', color: { argb: '00F74C58' },}
          }
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          }
        }
      }else{
        
        if((number >= 2 && number <= 3) || (number == 5 )){
          cell.numFmt = '$ #,##0.00';
        }
        cell.font = { name: 'Arial Narrow', family: 4, size: 11, color: {argb: 'FF000000'} }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' }
        }
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        }
      }
      });
    });
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 22;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    worksheet.addRow([]);
    var nom = names+'.xlsx'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nom);
    })
  }
  generateExcelBalance(encabezado,dataarreglo,numeroevaluaciones,evaluacion) {
    //Excel Title, Header, Data
    const title = 'Balance General';
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('balance');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = evaluacion;
    let subTitleRow = worksheet.addRow(['Fecha : ' + fecha]);
    subTitleRow.font = { name: 'Arial Narrow', family: 4, size: 12,  bold: true }
    //Add Image
    /*let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });*/
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    
    data.forEach(d => {
      let row = worksheet.addRow(d);
      var fila = String(row);
      row.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FF757575"}};
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      if(celdaa1.length == 3){
        row.eachCell((cell,number) =>{
          cell.border = {bottom: {style: 'thick', color: {argb:'757575'}}};
        });
      }
      for(var j = 0; j < numeroevaluaciones;j++){
        var numcelda = 3 + j;
        
        let qty = row.getCell(numcelda);
        var c = String(qty);
        var flag = false;
        for(var i = 0; i < c.length;i++){
          if(c.charAt(i) == '-'){
            flag = true;
          }
        }
        if(flag){
          qty.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FFF74C58"}};
        }
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
      }
    });
    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.addRow([]);
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Balance.xlsx');
    })
  }
  generateExcelEdoResultado(encabezado,dataarreglo,numeroevaluaciones,evaluacion) {
    //Excel Title, Header, Data
    const title = 'Estado de resultados';
    var header = []
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('edo_resultado');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = '2020-06-17';
    fecha = evaluacion;
    let subTitleRow = worksheet.addRow(['Fecha : ' + fecha]);
    subTitleRow.font = { name: 'Arial Narrow', family: 4, size: 12,  bold: true }
    //Add Image
    /*let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });*/
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    data.forEach(d => {
      let row = worksheet.addRow(d);
      var fila = String(row);
      row.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FF757575"}};
      let celda1 = row.getCell(1);
      var celdaa1 = String(celda1);
      if(celdaa1.length == 0){
        row.eachCell((cell,number) =>{
          cell.border = {bottom: {style: 'thick', color: {argb:'757575'}}};
        });
      }
      for(var j = 0; j < numeroevaluaciones;j++){
        var numcelda = 3 + j;        
        let qty = row.getCell(numcelda);
        var c = String(qty);
        var flag = false;
        for(var i = 0; i < c.length;i++){
          if(c.charAt(i) == '-'){
            flag = true;
          }
        }
        if(flag){
          qty.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FFF74C58"}};;
        }
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
      }
    });
    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.addRow([]);
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'EstadoResultados.xlsx');
    })
  }
  generateExcelCaptacionColocacion(encabezado,dataarreglo,subtitulo,titulo){ 
    //Excel Title, Header, Data
    const title = titulo;
    var header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"]
    var data = [];
    data = dataarreglo;
    header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('captacioncolocacion');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = '2020-06-17';
    let subTitleRow = worksheet.addRow([subtitulo]);
    subTitleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FF757575"}};
      if(header.length == 4){
        let qty = row.getCell(3);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
        qty = row.getCell(4);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
      }else{
        let qty = row.getCell(2);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
        qty = row.getCell(3);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
      }
    });
    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.addRow([]);

    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'CarData.xlsx');
    });
  }
  generateExcelInspeccionarTodasColumnas(encabezado,dataarreglo,dimension){
    var anioreporte = [],mesreporte = [];
    for(var i = 0; i < dataarreglo.length;i++ ){
      if(!anioreporte.includes(dataarreglo[i][1])){
        anioreporte.push(dataarreglo[i][1]);
      }
      if(!mesreporte.includes(dataarreglo[i][2])){
        mesreporte.push(dataarreglo[i][2]);
      }
    }
    var fechas = anioreporte[0] + "-" + mesreporte[0] + "-" + this.funtiondiames(mesreporte[0],anioreporte[0]);
    const title = 'Base de Datos Socios';
    var header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"]
    var data = [];
    data = dataarreglo;
    header = encabezado;
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('BD');
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = fechas;
    let subTitleRow = worksheet.addRow(["Inspeccion Base de Datos"]);
    subTitleRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: false,};
    let FechaReporte = worksheet.addRow(["Fecha Reporte: " + fecha]);
    FechaReporte.font = {name: 'Arial Narrow', family: 4, size: 12, bold: false,};
    worksheet.addRow([]);

    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FF757575"}};
    });    
    worksheet.addRow([]);
   
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'CarData.xlsx');
    });
  }
  generateExcelSaldoSucursal(encabezado,arreglo,nombreexcell,subtitulo,titulo){
    //Excel Title, Header, Data
    const title = titulo;
    var data = [];
    data = arreglo;
    var header = encabezado;
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('saldo');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = '2020-06-17';
    let subTitleRow = worksheet.addRow([subtitulo]);
    subTitleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FF757575"}};
      if(header.length == 4){
        let qty = row.getCell(3);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
        qty = row.getCell(4);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
      }else{
        let qty = row.getCell(2);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
        qty = row.getCell(3);
        qty.alignment = {
          vertical: 'middle', horizontal: 'right'
        }
      }
    });
    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.addRow([]);

    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nombreexcell+'.xlsx');
    });
  }
  funtiondiames(mes,anio){
    var mesaux = "";
    if(mes == "01" || mes == "03" || mes == "05" || mes == "07" || mes == "08" || mes == "10" || mes == "12" ||
       mes == "ene" || mes == "mar" || mes == "may" || mes == "jul" || mes == "ago" || mes == "oct" || mes == "dic" 
      ){
      mesaux = "31";
    }
    if(mes == "04" || mes == "06" || mes == "09" || mes == "11" ||
      mes == "abr" || mes == "jun" || mes == "sep" || mes == "nov"
      ){
      mesaux = "30";
    }
    if(mes == "feb"){
      mesaux = (((anio % 4 == 0) && (anio % 100 != 0)) || (anio % 400 == 0)) ? "29" : "28";
    }
    return mesaux;
  }
  /* Regulatorios */
  generateExcelRegulatorioCartera(encabezado,dataarreglo,dimension,cartera){
    var anioreporte = [],mesreporte = [];
    for(var i = 0; i < dataarreglo.length;i++ ){
      if(!anioreporte.includes(dataarreglo[i][1])){
        anioreporte.push(dataarreglo[i][1]);
      }
      if(!mesreporte.includes(dataarreglo[i][2])){
        mesreporte.push(dataarreglo[i][2]);
      }
    }
    var fechas = anioreporte[0] + "-" + mesreporte[0] + "-" + this.funtiondiames(mesreporte[0],anioreporte[0]);
    const title = 'Base de Datos Socios';
    var header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"]
    var data = [];
    data = dataarreglo;
    header = encabezado;
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('cartera');
    /*let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial Narrow', family: 4, size: 12, bold: true }
    worksheet.addRow([]);
    var fecha = fechas;
    let subTitleRow = worksheet.addRow(["Inspeccion Base de Datos"]);
    subTitleRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: false,};
    let FechaReporte = worksheet.addRow(["Fecha Reporte: " + fecha]);
    FechaReporte.font = {name: 'Arial Narrow', family: 4, size: 12, bold: false,};
    worksheet.addRow([]);

    let headerRow = worksheet.addRow(header);
    headerRow.font = {name: 'Arial Narrow', family: 4, size: 12, bold: true,color: {argb: "FFFFFFFF"}};
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF99999A' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    */
    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 10, bold: false,color: {argb: "FF757575"}};
    });    
    worksheet.addRow([]);
   
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'regulatoriocartera_'+cartera+'.csv');
    });
  }
  /* Regulatorios */

}