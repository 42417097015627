<div class="w3-container w3-contenedor-template  " style="height: 7vh;">
    <div class="w3-row ">
        <div class="w3-quarter padreizquierda ">
            <div class="w3-row hijoizquierda">
                <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b>{{ dataService.currentModule }}</b></span>
                <span class="w3-text-deep-orange">&bull;</span>
                <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal">{{ dataService.currentSubmodule }}</span>
            </div>
        </div>

        <!-- BORRAR FILTROS-->
        <div class="w3-rest ">

          <div class="w3-noventa2 w3-row ">
            <!-- Anios-->
            <div class="w3-quarter">
              <!-- FILTRO AÑOS DISPONIBLES -->
              <div class="w3-half hijoizquierda">
                <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
                  Año
                  <mat-icon class="iconoMen">expand_more</mat-icon>
                </button>

                <mat-menu class="w3-medium"  #menuanios="matMenu" >
                  <ng-container *ngFor="let anio of aniosDisponibles">
                    <button mat-menu-item>
                      <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                    (click)="$event.stopPropagation();"
                                    value="{{anio.name}}" aria-label="First checkbox"
                                    (change)="anioChangeListener(anio)">
                        {{anio.value}}
                      </mat-checkbox>
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
              <!-- FIN FILTRO AÑOS DISPONIBLES -->
              <!-- AÑO SELECCIONADO -->
              <div class="w3-rest  hijoizquierda" style="height: 4vh;">
                <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                  <div class="w3-row chips">
                    <div [ngClass]="anios.check?
                        'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'" 
                        *ngFor="let anios of aniosSeleccionados"
                        class="filtroMesAnioSeleccionado">
                        <span class="contenidoChips">{{anios.name}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- FIN AÑO SELECCIONADO -->
            </div>

            <!-- Meses -->  
            <div class="w3-quarter">
              <!-- FILTRO MESES DISPONIBLES -->
              <div class="w3-half hijoizquierda">
                <button class="w3-button w3-right"  [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
                  Mes
                  <mat-icon class="iconoMen">expand_more</mat-icon>
                </button>
                <mat-menu class="w3-medium" #menumeses="matMenu" style="height: 100px;">
                  <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                    <button mat-menu-item>
                      <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                    (click)="$event.stopPropagation();"
                                    value="{{mes.name}}"
                                    (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                        <span>{{mes.name}}</span>
                      </mat-checkbox>
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
              <!-- FIN FILTRO MESES DISPONIBLES -->

              <!-- MES SELECCIONADO -->
              <div class="w3-rest  hijoizquierda">
                <div class="w3-row padreizquierda containerChips" style="height: 4vh;" id="contenedor_btnmeses">
                  <div class="w3-row  hijoizquierda chips" >
                      <div [ngClass]="meses.check?
                      'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                              *ngFor="let meses of mesesSeleccionados"
                              class="filtroMesAnioSeleccionado">
                              <span class="contenidoChips">{{meses.name}}</span>
                      </div>
                  </div>
                </div>
              </div>
              <!-- FIN MES SELECCIONADO -->
            </div>

            <!-- Activos/Inactivos-->
            <div class="w3-quarter padreizquierda">
              <div class="w3-third hijoizquierda">
                <button class="w3-button w3-right"  [matMenuTriggerFor]="menu_estadosocio" aria-label="Example icon-button with a menu">
                  Tipo
                  <mat-icon class="iconoMen">expand_more</mat-icon>
                </button>
                  <mat-menu class="w3-medium"  #menu_estadosocio="matMenu" >
                    <button mat-menu-item>
                        <mat-checkbox [checked]="tipo_socio" name="Socio"
                            value="Activo" aria-label="First checkbox"
                            (change)="event_estatus(1)">
                            Socio
                        </mat-checkbox>
                    </button>
                    <button mat-menu-item>
                        <mat-checkbox [checked]="tipo_ahorrador" name="Ahorrador"
                            value="Inactivo" aria-label="First checkbox"
                            (change)="event_estatus(2)">
                            Ahorrador menor
                        </mat-checkbox>
                    </button>
                    <button mat-menu-item>
                      <mat-checkbox [checked]="tipo_aspirante" name="Aspirante"
                          value="Inactivo" aria-label="First checkbox"
                          (change)="event_estatus(3)">
                          Aspirante
                      </mat-checkbox>
                  </button>
                  </mat-menu>
              </div>
              <div class="w3-rest hijoizquierda">
                <div class="w3-row padreizquierda containerChips" style="height: 4vh;" id="contenedor_btnanios">
                  <div class="w3-row hijoizquierda chips">
                    <div *ngIf="EstadoSocioCadena != '' " class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button">
                            <span class="contenidoChips">{{EstadoSocioCadena}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- consolidado sucursal -->
            <div class="w3-quarter padreizquierda">
              <div class="w3-half hijoizquierda  ">
                <button class="w3-button w3-right"  [matMenuTriggerFor]="menuentidad" aria-label="Example icon-button with a menu">
                  Sucursales
                  <mat-icon class="iconoMen">expand_more</mat-icon>
                </button>
                <!--
                  Se contruye el menu de años en relación a la información que tiene la entidad
                  Composición del json:
                  arreglo_anios : any [] = [
                        {"name":"2018","value":"2018","check":true},
                        {"name":"2019","value":"2019","check":true},
                        {"name":"2020","value":"2020","check":true},
                        {"name":"2021","value":"2021","check":true}
                        ];
                -->
                  <mat-menu class="w3-medium"  #menuentidad="matMenu" >
                    <button mat-menu-item>
                      <mat-checkbox name="consolidado"
                              [checked]="consolidado"
                              (click)="$event.stopPropagation();"
                              (change)="consolidadoChangeListener()"
                              value="consolidado" aria-label="First checkbox">
                        Consolidado
                      </mat-checkbox>
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="sucursales">Sucursales</button>
                  </mat-menu>
                  <mat-menu #sucursales="matMenu">
                    <ng-container>
                      <mat-form-field style="margin: 1vh; max-width: 15vh;" (click)="$event.stopPropagation();">
                        <mat-label>Buscar</mat-label>
                        <mat-icon matSuffix style="color: gray;">search</mat-icon>
                        <input matInput (keyup)="applyFilter($event)" type="text" autocomplete="off">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngFor="let sucursal of sucursalesDisponibles2.filteredData">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="sucursal.check"
                          (change)="sucursalChangeListener(sucursal)"
                          (click)="$event.stopPropagation();">
                          {{sucursal.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
              </div>
              <div class="w3-rest  hijoizquierda">
                <div class="w3-row padreizquierda containerChips" style="height: 4vh;" id="contenedor_btnmeses">
                  <div class="w3-row hijoizquierda chips" >
                    <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button">
                      <span class="contenidoChips">{{sucursal_seleccionada}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- quitar filtros -->
          <div class="w3-rest padreizquierda w3-right ">
            <div class="w3-row hijoizquierda">
              <button mat-icon-button (click)="quitar_filtros()">
                  <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                      <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
                  </mat-icon>
              </button>
            </div>
          </div>
        </div>
        <!-- FIN BORRAR FILTROS-->
    </div>
</div>

<div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">
    <!-- contenedor parte de arriba-->
    <div class="w3-row w3-contenedor-template  " style="height: 41vh;">
      <div class="w3-row" style="height: 100%;">
        <!--Componentes parte superior-->
        <div class="w3-row w3-contenedor-template " style="height: 41vh;">

            <!--primer cuadrante-->
            <div class="w3-third" id="Zona1">
              <!--encabezado del cuadrante principal-->
              <div class="w3-row w3-white w3-header-cuadrante " style="height: 7vh;width: 98%;">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido w3-tituloZonas">Género</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>
                <!--Menu-->
                <div class="w3-rest w3-right-align_rest">
                  <div class="w3-rest hijoderechaTitle w3-tiny ">
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC1()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                      more_vert
                    </mat-icon>
                    <mat-menu #menu="matMenu">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC1">
                        <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC1">
                        <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                        <span>Exportar como</span>
                      </button>
                    </mat-menu>
                    <mat-menu #exportarC1="matMenu">
                      <button mat-menu-item *ngIf="graficatablaC1" (click)="export_excel('C1')">
                        <span>CSV</span>
                      </button>
                      <button mat-menu-item *ngIf="graficatablaC1" (click)="clickexportCSV('C1')">
                        <span>CSV con sucursales</span>
                      </button>
                      <button mat-menu-item *ngIf="!graficatablaC1" (click)="export_imagen('C1')">
                        <span>PNG</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficasC1="matMenu">
                      <button mat-menu-item (click)="cambiarGraficaC1('barra')">
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC1('lineal')">
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC1('pastel')">
                        <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                        <span>Pastel</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC1('tabla')">
                        <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                        <span>Tabla</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <!--Graficas-->
              <div class="w3-row w3-white w3-contenedor-grafica " style="width: 98%;height: 34vh;">
                <div class="w3-row" *ngIf="graficapieC1" style="height: 33vh;width: 95%; margin: auto;">
                  <google-chart id="graficapieC1" style="height: 31vh; margin: auto;"
                  [type]="typev2"
                  [data]="data"
                  [options]="options"
                  (select)="selcetPieC1($event)"
                  >
                  </google-chart>
                </div>
                <div class="w3-row" *ngIf="graficabarraC1" style="height: 31vh;width: 90%; margin: auto;">
                  <ngx-charts-bar-vertical 
                  [scheme]="colorBarras"
                  [results]="data_barra"
                  [xAxis]="true"
                  [yAxis]="true"
                  [legend]="false"
                  [showGridLines]="false"
                  (select)="selcetPieC1($event)"
                  >
                  </ngx-charts-bar-vertical>
                </div>
                <div  class="w3-row" *ngIf="graficalinealC1" style="height: 31vh;width: 90%; margin: auto;">
                  <ngx-charts-line-chart  
                  [scheme]="colorScheme"
                  [results]="data_lineal"
                  [legend]="legend"
                  [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel"
                  [xAxis]="xAxis"
                  [yAxis]="yAxis"
                  [xAxisLabel]="xAxisLabel"
                  [yAxisLabel]="yAxisLabel"
                  [showGridLines]="false"
                  (select)="selcetPieC1($event)"
                  >
                    <!--
                    <ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of data_lineal; let i = index">
                            <span>{{model.value}} </span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                        <div *ngFor="let data of data_lineal; let i = index">
                          <span>{{model[0].value}}</span>
                        </div>
                    </div>
                    </ng-template>-->
                  </ngx-charts-line-chart>
                </div>
                <div class="w3-row" *ngIf="graficatablaC1" style="height: 31vh; will-change: 90%; margin: auto;">
                  <div class="w3-table-all">
                    <table mat-table [dataSource]="datatablaC1" class=" w3-table" >
                        <!-- Position Column -->
                        <ng-container matColumnDef="genero">
                        <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Género </th>
                        <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>              
                        <!-- Name Column -->
                        <ng-container matColumnDef="valor">
                        <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                        <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="porcentaje">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                          <td mat-cell style="width: 30%;" *matCellDef="let element">  {{element.porcentaje}} % </td>
                          </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columnasC1; sticky: true" class="w3-elemento-titulo-table"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnasC1;"></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--Fin del primer cuadrante-->

            <!--segundo cuadrante-->
            <div class="w3-third" id="Zona2">
              <!--encabezado del cuadrante principal-->
              <div class="w3-row w3-white w3-header-cuadrante " style="height: 7vh;width: 98%;">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido w3-tituloZonas">Rango edad</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>
                <!--Menu-->
                <div class="w3-rest w3-right-align_rest">
                  <div class="w3-rest hijoderechaTitle w3-tiny ">
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC2()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC2">
                      more_vert
                    </mat-icon>
                    <mat-menu #menuC2="matMenu">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC2">
                        <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC2">
                        <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                        <span>Exportar como</span>
                      </button>
                    </mat-menu>
                    <mat-menu #exportarC2="matMenu">
                      <button mat-menu-item *ngIf="graficatablaC2" (click)="export_excel('C2')">
                        <span>CSV</span>
                      </button>
                      <button mat-menu-item *ngIf="graficatablaC2" (click)="clickexportCSV('C2')">
                        <span>CSV por sucursales</span>
                      </button>
                      <button mat-menu-item *ngIf="!graficatablaC2" (click)="export_imagen('C2')">
                        <span>PNG</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficasC2="matMenu">
                      <button mat-menu-item (click)="cambiarGraficaC2('barra')">
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC2('lineal')">
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC2('mapacalor')">
                        <mat-icon [ngStyle]="{'color':'gray'}">view_quilt</mat-icon>
                        <span>Mapa de calor</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC2('tabla')">
                        <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                        <span>Tabla</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <!--Graficas-->
              <div class="w3-row w3-white" style="width: 98%;">
                <div class="w3-row w3-white" *ngIf="mapacalorC2" style="height: 34vh; width: 90%; margin: auto;">
                  <ngx-charts-tree-map
                    [scheme]="colorScheme"
                    [results]="data_rangoedad"
                    [gradient]="gradient"
                    [animations]="animations"
                    [labelFormatting]="labelFormatting"
                    (select)="selcetC2($event)">
                  </ngx-charts-tree-map>
                </div>
                <div class="w3-row" *ngIf="graficabarraC2" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-bar-vertical 
                    [scheme]="colorBarras"
                    [results]="data_rangoedad"
                    [xAxis]="true"
                    [yAxis]="true"
                    [legend]="false"
                    [showGridLines]="false"
                    (select)="selcetC2($event)">
                  </ngx-charts-bar-vertical>
                </div>
                <div  class="w3-row" *ngIf="graficalinealC2" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-line-chart  
                    [scheme]="colorScheme"
                    [results]="data_rangoedad_lineal"
                    [legend]="legend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabelC2"
                    [yAxisLabel]="yAxisLabel"
                    [showGridLines]="false"
                    (select)="selcetC2($event)"
                    >
                    <!--<ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                            <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                        <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                          <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                        </div>
                    </div>
                    </ng-template>-->
                  </ngx-charts-line-chart>
                </div>
                <div class="w3-row" *ngIf="graficatablaC2" style="height: 34vh; width: 90%; margin: auto;">
                  <div class="w3-encabezado-table w3-scroll" >
                    <div class="w3-table-all">
                      <table mat-table [dataSource]="datatablaC2" class=" w3-table" >
                          <!-- Position Column -->
                          <ng-container matColumnDef="edad">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Rango de edad </th>
                          <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                          </ng-container>              
                          <!-- Name Column -->
                          <ng-container matColumnDef="valor">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                          <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                          </ng-container>
                          <!-- Name Column -->
                          <ng-container matColumnDef="porcentaje">
                            <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                            <td mat-cell style="width: 30%;" *matCellDef="let element">  {{element.porcentaje}} % </td>
                            </ng-container>
                          <tr mat-header-row *matHeaderRowDef="columnasC2; sticky: true" class="w3-elemento-titulo-table"></tr>
                          <tr mat-row *matRowDef="let row; columns: columnasC2;"></tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Tercer cuadrante-->
            <div class="w3-third" id="Zona3">
              <!--encabezado del cuadrante principal-->
              <div class="w3-row w3-white w3-header-cuadrante " style="height: 7vh;">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido w3-tituloZonas">Generacional</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>
                <!--Menu-->
                <div class="w3-rest w3-right-align_rest">
                  <div class="w3-rest hijoderechaTitle w3-tiny ">
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC3()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC3">
                      more_vert
                    </mat-icon>
                    <mat-menu #menuC3="matMenu">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC3">
                        <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC3">
                        <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">assessment</mat-icon>
                        <span>Exportar como</span>
                      </button>
                    </mat-menu>
                    <mat-menu #exportarC3="matMenu">
                      <button mat-menu-item *ngIf="graficatablaC3" (click)="export_excel('C3')">
                        <span>CSV</span>
                      </button>
                      <button mat-menu-item *ngIf="graficatablaC3" (click)="clickexportCSV('C3')">
                        <span>CSV por sucursales</span>
                      </button>
                      <button mat-menu-item *ngIf="!graficatablaC3" (click)="export_imagen('C3')">
                        <span>PNG</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficasC3="matMenu">
                      <button mat-menu-item (click)="cambiarGraficaC3('barra')">
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC3('lineal')">
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC3('pastel')">
                        <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                        <span>Pastel</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC3('tabla')">
                        <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                        <span>Tabla</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <!--Graficas-->
              <div class="w3-row w3-white">
                <div class="w3-row " *ngIf="graficapieC3" style="height: 34vh;width: 95%; margin: auto;">
                  <google-chart id="graficapieC3"  style="height: 31vh; margin: auto;"
                  [type]="typev2"
                  [data]="datav2"
                  [options]="optionsv2"
                  (select)="selcetC3($event)">
                  </google-chart>
                </div>
                <div class="w3-row" *ngIf="graficabarraC3" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-bar-vertical 
                    [scheme]="colorBarras"
                    [results]="datav2_barra"
                    [xAxis]="true"
                    [yAxis]="true"
                    [legend]="false"
                    [xAxisLabel]="xAxisLabelC3"
                    [showGridLines]="false"
                    (select)="selcetC3($event)">
                  </ngx-charts-bar-vertical>
                </div>
                <div  class="w3-row" *ngIf="graficalinealC3" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-line-chart  
                    [scheme]="colorScheme"
                    [results]="datav2_lineal"
                    [legend]="legend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabelC3"
                    [yAxisLabel]="yAxisLabel"
                    [showGridLines]="false"
                    (select)="selcetC3($event)"
                    >
                    <!--<ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                            <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                        <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                          <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                        </div>
                    </div>
                    </ng-template>-->
                  </ngx-charts-line-chart>
                </div>
                <div class="w3-row" *ngIf="graficatablaC3" style="height: 34vh; width: 90; margin: auto;">
                  <div class="w3-table-all">
                    <table mat-table [dataSource]="datatablaC3" class=" w3-table" >
                        <!-- Position Column -->
                        <ng-container matColumnDef="generacion">
                        <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Generación </th>
                        <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>              
                        <!-- Name Column -->
                        <ng-container matColumnDef="valor">
                        <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                        <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="porcentaje">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                          <td mat-cell style="width: 30%;" *matCellDef="let element"> {{element.porcentaje}} % </td>
                          </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columnasC3; sticky: true" class="w3-elemento-titulo-table"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnasC3;"></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

        </div>
        <!--componentes-->
      </div>
    </div>
    <!-- contenedor parte de arriba-->

    <!--Contenedor inferior-->
    <div class="w3-row mt-2 " style="height: 41vh;">
      <div class="w3-row " style="height: 100%;">
        <!--Componentes parte inferior-->
        <div class="w3-row w3-contenedor-template " style="height: 100%;">

            <!--Primer cuadrante-->
            <div class="w3-third" id="Zona4">
              <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;width: 98%;">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido w3-tituloZonas">Ocupación</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>
                <!--Menu-->
                <div class="w3-rest w3-right-align_rest">
                  <div class="w3-rest hijoderechaTitle w3-tiny ">
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC4()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC4">
                      more_vert
                    </mat-icon>
                    <mat-menu #menuC4="matMenu">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC4">
                        <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="cantDat4">
                        <mat-icon [ngStyle]="{'color':'gray'}">tag</mat-icon>
                        <span>Datos</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC4">
                        <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                        <span>Exportar</span>
                      </button>
                    </mat-menu>
                    <mat-menu #cantDat4="matMenu">
                      <button mat-menu-item (click)="cambioCant4(1)">
                        <span>15</span>
                      </button>
                      <button mat-menu-item (click)="cambioCant4(2)">
                        <span>Todos</span>
                      </button>
                    </mat-menu>
                    <mat-menu #exportarC4="matMenu">
                      <button mat-menu-item *ngIf="graficatablaC4" (click)="export_excel('C4')">
                        <span>CSV</span>
                      </button>
                      <button mat-menu-item *ngIf="graficatablaC4" (click)="clickexportCSV('C4')">
                        <span>CSV por sucursales</span>
                      </button>
                      <button mat-menu-item *ngIf="!graficatablaC4" (click)="export_imagen('C4')">
                        <span>PNG</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficasC4="matMenu">
                      <button mat-menu-item (click)="cambiarGraficaC4('barra')">
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC4('lineal')">
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC4('pastel')">
                        <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                        <span>Pastel</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC4('tabla')">
                        <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                        <span>Tabla</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <!--Graficas-->
              <div class="w3-row w3-white" style="width: 98%;">
                <div class="w3-row  w3-left " *ngIf="graficabarraC4" style="height: 34vh; width: 90%; margin: auto;">
                  <ngx-charts-bar-vertical
                    [scheme]="colorBarras"
                    [results]="data_ocupacion"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [legend]="showLegend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabelC4"
                    [yAxisLabel]="yAxisLabel"
                    [showGridLines]="showGridLines"
                    (select)="selcetC4($event)"
                    >
                  </ngx-charts-bar-vertical>
                </div>
                <div  class="w3-row" *ngIf="graficalinealC4" style="height: 34vh; width: 90%; margin: auto;">
                  <ngx-charts-line-chart  
                    [scheme]="colorScheme"
                    [results]="data_ocupacion_lineal"
                    [legend]="legend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabelC4"
                    [yAxisLabel]="yAxisLabel"
                    [showGridLines]="false"
                    (select)="selcetC4($event)"
                    >
                    <!--<ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                            <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                        <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                          <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                        </div>
                    </div>
                    </ng-template>-->
                  </ngx-charts-line-chart>
                </div>
                <div class="w3-row " *ngIf="graficapieC4" style="height: 34vh; width: 95%; margin: auto;">
                  <google-chart id="graficapieC4"  style="height: 31vh; margin: auto;"
                  [type]="type"
                  [data]="data_pieOcupacion"
                  [options]="options2"
                  (select)="selcetC4($event)"
                  >
                  </google-chart>
                </div>
                <div class="w3-row" *ngIf="graficatablaC4" style="height: 34vh; width: 90%; margin: auto;">
                  <div class="w3-encabezado-table w3-scroll" >
                    <div class="w3-table-all">
                      <table mat-table [dataSource]="datatablaC4" class=" w3-table" >
                          <!-- Position Column -->
                          <ng-container matColumnDef="ocupacion">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Ocupación </th>
                          <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                          </ng-container>              
                          <!-- Name Column -->
                          <ng-container matColumnDef="valor">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                          <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                          </ng-container>
                          <!-- Name Column -->
                          <ng-container matColumnDef="porcentaje">
                            <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                            <td mat-cell style="width: 30%;" *matCellDef="let element"> {{element.porcentaje}} % </td>
                            </ng-container>
                          <tr mat-header-row *matHeaderRowDef="columnasC4; sticky: true" class="w3-elemento-titulo-table"></tr>
                          <tr mat-row *matRowDef="let row; columns: columnasC4;"></tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Segundo cuadrante-->
            <div class="w3-third" id="Zona5">
              <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;width: 98%;">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido w3-tituloZonas">Escolaridad</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>
                <!--Menu-->
                <div class="w3-rest w3-right-align_rest">
                  <div class="w3-rest hijoderechaTitle w3-tiny ">
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC5()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC5">
                      more_vert
                    </mat-icon>
                    <mat-menu #menuC5="matMenu">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC5">
                        <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC5">
                        <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                        <span>Exportar como</span>
                      </button>
                    </mat-menu>
                    <mat-menu #exportarC5="matMenu">
                      <button mat-menu-item *ngIf="graficatablaC5" (click)="export_excel('C5')">
                        <span>CSV</span>
                      </button>
                      <button mat-menu-item *ngIf="graficatablaC5" (click)="clickexportCSV('C5')">
                        <span>CSV por sucursales</span>
                      </button>
                      <button mat-menu-item *ngIf="!graficatablaC5" (click)="export_imagen('C5')">
                        <span>PNG</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficasC5="matMenu">
                      <button mat-menu-item (click)="cambiarGraficaC5('barra')">
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC5('lineal')">
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC5('pastel')">
                        <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                        <span>Pastel</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC5('tabla')">
                        <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                        <span>Tabla</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <!--Graficas-->
              <div class="w3-row w3-white " style="width: 98%;">
                <div class="w3-row " *ngIf="graficapastelC5" style="height: 34vh;width: 95%; margin: auto;">
                  <google-chart id="piechartGoogleT1C3"  style="height: 31vh; margin: auto;" 
                  [type]="typev3"
                  [data]="datav3"
                  [options]="optionsv32"
                  (select)="selcetC5($event)">
                  </google-chart>
                </div>
                <div class="w3-row" *ngIf="graficabarraC5" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-bar-vertical
                    [scheme]="colorBarras"
                    [results]="datav3_barra"
                    [xAxis]="true"
                    [yAxis]="true"
                    [legend]="false"
                    [showGridLines]="false"
                    (select)="selcetC5($event)">
                  </ngx-charts-bar-vertical>
                </div>
                <div  class="w3-row" *ngIf="graficalinealC5" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-line-chart  
                    [scheme]="colorScheme"
                    [results]="datav3_lineal"
                    [legend]="legend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabelC5"
                    [yAxisLabel]="yAxisLabel"
                    [showGridLines]="false"
                    (select)="selcetC5($event)"
                    >
                    <!--<ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                            <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                        <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                          <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                        </div>
                    </div>
                    </ng-template>-->
                  </ngx-charts-line-chart>
                </div>
                <div class="w3-row" *ngIf="graficatablaC5" style="height: 34vh; width: 90%; margin: auto;">
                  <div class="w3-encabezado-table w3-scroll" >
                    <div class="w3-table-all">
                      <table mat-table [dataSource]="datatablaC5" class=" w3-table" >
                          <!-- Position Column -->
                          <ng-container matColumnDef="escolaridad">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Escolaridad </th>
                          <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                          </ng-container>              
                          <!-- Name Column -->
                          <ng-container matColumnDef="valor">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                          <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                          </ng-container>
                          <!-- Name Column -->
                          <ng-container matColumnDef="porcentaje">
                            <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                            <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                            </ng-container>
                          <tr mat-header-row *matHeaderRowDef="columnasC5; sticky: true" class="w3-elemento-titulo-table"></tr>
                          <tr mat-row *matRowDef="let row; columns: columnasC5;"></tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--tercer cuadrante-->
            <div class="w3-third" id="Zona6">
              <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
                <!--Titulo-->
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido w3-tituloZonas">Ingreso</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                </div>
                <!--Menu-->
                <div class="w3-rest w3-right-align_rest">
                  <div class="w3-rest hijoderechaTitle w3-tiny ">
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC6()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC6">
                      more_vert
                    </mat-icon>
                    <mat-menu #menuC6="matMenu">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC6">
                        <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC6">
                        <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                        <span>Exportar como</span>
                      </button>
                    </mat-menu>
                    <mat-menu #exportarC6="matMenu">
                      <button mat-menu-item *ngIf="graficatablaC6" (click)="export_excel('C6')">
                        <span>CSV</span>
                      </button>
                      <button mat-menu-item *ngIf="graficatablaC6" (click)="clickexportCSV('C6')">
                        <span>CSV por sucursales</span>
                      </button>
                      <button mat-menu-item *ngIf="!graficatablaC6" (click)="export_imagen('C6')">
                        <span>PNG</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficasC6="matMenu">
                      <button mat-menu-item (click)="cambiarGraficaC6('barra')">
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC6('lineal')">
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC6('pastel')">
                        <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                        <span>Pastel</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC6('tabla')">
                        <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                        <span>Tabla</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <!--Graficas-->
              <div class="w3-row w3-white">
                <div class="w3-row " *ngIf="graficabarraC6" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-bar-horizontal
                    [scheme]="colorBarras"
                    [results]="data_ingreso"
                    [gradient]="gradient"
                    [xAxis]="showXAxis_t3"
                    [yAxis]="showYAxis_t3"
                    [legend]="showLegend_t3"
                    [showXAxisLabel]="showXAxisLabel_t3"
                    [showYAxisLabel]="showYAxisLabel_t3"
                    [xAxisLabel]="xAxisLabel_t3"
                    [yAxisLabel]="yAxisLabel_t3"
                    [showGridLines]="showGridLines_t3"
                  >
                  </ngx-charts-bar-horizontal>
                </div>
                <div  class="w3-row" *ngIf="graficalinealC6" style="height: 34vh;width: 90%; margin: auto;">
                  <ngx-charts-line-chart  
                    [scheme]="colorScheme"
                    [results]="data_ingreso_lineal"
                    [legend]="legend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabelC6"
                    [yAxisLabel]="yAxisLabel"
                    [showGridLines]="false"
                    >
                    <!--<ng-template #tooltipTemplate let-model="model" >
                      <div style="text-align: left;">
                        <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                            <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model" >
                    <div style="text-align: left;">
                        <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                          <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                        </div>
                    </div>
                    </ng-template>-->
                  </ngx-charts-line-chart>
                </div>
                <div class="w3-row " *ngIf="graficapieC6" style="height: 34vh;width: 95%; margin: auto;">
                  <google-chart id="graficapieC4"  style="height: 31vh; margin: auto;"
                  [type]="type"
                  [data]="data_pieIngreso"
                  [options]="options2"
                  >
                  </google-chart>
                </div>
                <div class="w3-row" *ngIf="graficatablaC6" style="height: 34vh; width: 90%; margin: auto;">
                  <div class="w3-encabezado-table w3-scroll" >
                    <div class="w3-table-all">
                      <table mat-table [dataSource]="datatablaC6" class=" w3-table" >
                          <!-- Position Column -->
                          <ng-container matColumnDef="ingreso">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Ingreso </th>
                          <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                          </ng-container>              
                          <!-- Name Column -->
                          <ng-container matColumnDef="valor">
                          <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                          <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                          </ng-container>
                          <!-- Name Column -->
                          <ng-container matColumnDef="porcentaje">
                            <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                            <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                            </ng-container>
                          <tr mat-header-row *matHeaderRowDef="columnasC6; sticky: true" class="w3-elemento-titulo-table"></tr>
                          <tr mat-row *matRowDef="let row; columns: columnasC6;"></tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
        <!--CONTENEDOR DE LAS GRÀFICAS PARTE DE ABAJO-->
      </div>
    </div>
</div>



<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{titulomodal}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" >&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulomodal}}</span>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <div class="w3-row " *ngIf="graficapieC1_modal">
          <google-chart class="w3-row w3-center" id="graficapieC1"  style="height: 52vh;width: 96%;"
              [type]="type"
              [data]="data"
              [options]="options">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficabarraC1_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical 
            [scheme]="colorBarras"
            [results]="data_barra"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div  class="w3-row" *ngIf="graficalinealC1_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="data_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of data_lineal; let i = index">
                    <span>{{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of data_lineal; let i = index">
                  <span>{{model[0].value}}</span>
                </div>
            </div>
            </ng-template>
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC1_modal" style="height: 52vh;">
          <div class="w3-table-all">
            <table mat-table [dataSource]="datatablaC1" class=" w3-table" >
                <!-- Position Column -->
                <ng-container matColumnDef="genero">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Género </th>
                <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>              
                <!-- Name Column -->
                <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                  <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnasC1; sticky: true" class="w3-elemento-titulo-table"></tr>
                <tr mat-row *matRowDef="let row; columns: columnasC1;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-row w3-white" *ngIf="mapacalorC2_modal" style="height: 52vh;">
          <ngx-charts-tree-map
            [scheme]="colorScheme"
            [results]="data_rangoedad"
            [gradient]="gradient"
            [animations]="animations"
            [labelFormatting]="labelFormatting"
            (select)="onSelect($event)">
          </ngx-charts-tree-map>
        </div>
        <div class="w3-row" *ngIf="graficabarraC2_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical 
            [scheme]="colorBarras"
            [results]="data_rangoedad"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div  class="w3-row" *ngIf="graficalinealC2_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="data_rangoedad_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC2"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC2_modal" style="height: 52vh;">
          <div class="w3-table-all">
            <table mat-table [dataSource]="datatablaC2" class=" w3-table" >
                <!-- Position Column -->
                <ng-container matColumnDef="edad">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Rango de edad </th>
                <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>              
                <!-- Name Column -->
                <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                  <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnasC2; sticky: true" class="w3-elemento-titulo-table"></tr>
                <tr mat-row *matRowDef="let row; columns: columnasC2;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-row " *ngIf="graficapieC3_modal" style="height: 52vh;">
          <google-chart class="w3-row w3-center" id="graficapieC3"  style="height: 52vh;width: 99%;"
              [type]="typev2"
              [data]="datav2"
              [options]="optionsv2">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficabarraC3_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical 
            [scheme]="colorBarras"
            [results]="datav2_barra"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [xAxisLabel]="xAxisLabelC3"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div  class="w3-row" *ngIf="graficalinealC3_modal" style="height: 52h;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="datav2_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC3"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC3_modal" style="height: 52vh;">
          <div class="w3-table-all">
            <table mat-table [dataSource]="datatablaC3" class=" w3-table" >
                <!-- Position Column -->
                <ng-container matColumnDef="generacion">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Generación </th>
                <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>              
                <!-- Name Column -->
                <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                  <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnasC3; sticky: true" class="w3-elemento-titulo-table"></tr>
                <tr mat-row *matRowDef="let row; columns: columnasC3;"></tr>
            </table>
          </div>
        </div>
        <div class="w3-row  w3-left " *ngIf="graficabarraC4_modal" style="height: 52vh;width: 100%;">
          <ngx-charts-bar-vertical
            [scheme]="colorBarras"
            [results]="data_ocupacion"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabelC4"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="showGridLines"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>
        </div>
        <div  class="w3-row" *ngIf="graficalinealC4_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="data_ocupacion_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC4"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row " *ngIf="graficapieC4_modal" style="height: 52vh;">
          <google-chart class="w3-row w3-center" id="graficapieC4"  style="height: 52vh;width: 96%;"
          [type]="type"
          [data]="data_pieOcupacion"
          [options]="options"
          >
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC4_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="w3-table-all">
              <table mat-table [dataSource]="datatablaC4" class=" w3-table" >
                  <!-- Position Column -->
                  <ng-container matColumnDef="ocupacion">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Ocupación </th>
                  <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                  <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                    <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                    </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnasC4; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC4;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row " *ngIf="graficapastelC5_modal" style="height: 52vh;">
          <google-chart class="w3-row w3-center" id="piechartGoogleT1C3"  style="height: 33vh;width: 96%;"
              [type]="typev3"
              [data]="datav3"
              [options]="optionsv3">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficabarraC5_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical
            [scheme]="colorBarras"
            [results]="datav3_barra"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div  class="w3-row" *ngIf="graficalinealC5_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="datav3_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC5"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC5_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="w3-table-all">
              <table mat-table [dataSource]="datatablaC5" class=" w3-table" >
                  <!-- Position Column -->
                  <ng-container matColumnDef="escolaridad">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Escolaridad </th>
                  <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                  <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                    <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                    </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnasC5; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC5;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row " *ngIf="graficabarraC6_modal" style="height: 52vh;">
          <ngx-charts-bar-horizontal
            [scheme]="colorBarras"
            [results]="data_ingreso"
            [gradient]="gradient"
            [xAxis]="showXAxis_t3"
            [yAxis]="showYAxis_t3"
            [legend]="showLegend_t3"
            [showXAxisLabel]="showXAxisLabel_t3"
            [showYAxisLabel]="showYAxisLabel_t3"
            [xAxisLabel]="xAxisLabel_t3"
            [yAxisLabel]="yAxisLabel_t3"
            [showGridLines]="showGridLines_t3"


            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)">
          </ngx-charts-bar-horizontal>
        </div>
        <div  class="w3-row" *ngIf="graficalinealC6_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="data_ingreso_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC6"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row " *ngIf="graficapieC6_modal" style="height: 52vh;">
          <google-chart class="w3-row w3-center" id="graficapieC4"  style="height: 52vh;width: 96%;"
          [type]="type"
          [data]="data_pieIngreso"
          [options]="options"
          >
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC6_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="w3-table-all">
              <table mat-table [dataSource]="datatablaC6" class=" w3-table" >
                  <!-- Position Column -->
                  <ng-container matColumnDef="ingreso">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Ingreso </th>
                  <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Número de socios  </th>
                  <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table"> Porcentaje  </th>
                    <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                    </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnasC6; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC6;"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
