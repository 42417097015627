/*export class UsuarioEntity {
  UsuarioId: number;
  Login: string;
  Nombre: string;
  Email: string;
  InstitucionId: number;
  Password: string;
  Activo: boolean;
  InstitucionPatmirId: string;

  institucionEntity: InstitucionEntity;
  Acronimo: string;

  flag_busqueda: boolean;
  perfilid: number;
}*/
export class Detalle_Evaluacion_IndicadorEntity{
  Id_Evaluacion: string;
  Id_Indicador: string;
  Valor: number;
  Respuesta: number;
  EstadoSemaforo: number;
  ResultadoIndicador: string;
  Rojos: number;
  Amarillos: number;
  Verdes: number;
  Nota: string;
  TextoSemaforo: string;
  ArregloID_evaluacion:string;

  indicadorEntity: IndicadorEntity;
  //evaluacionEntity: EvaluacionEntity;
}

export class IndicadorEntity{
  Id_Indicador: string;
  Descripcion: string;
  Nivel1: number;
  Nivel2: number;
  Nivel3: number;
  Nivel4: number;
  Nivel: number;
  Formula: string;
  R0_Usar: number;
  R2_Variable1: string;
  Id_Plantilla: number;
  R0_Opcomparacion1: string;
  R0_Valor1: number;
  R0_Oplogico1: number;
  R0_Opcomparacion2: number;
  R0_Valor2: number;
  R1_Usar: number;
  R1_Opcomparacion1: number;
  R1_Valor1: number;
  R1_Oplogico1: number;
  R1_Opcomparacion2: number;
  R1_Valor2: number;
  R2_Usar: number;
  R2_Opcomparacion1: number;
  R2_Valor1: number;
  R2_Oplogico1: number;
  R2_Opcomparacion2: number;
  R2_Valor2: number;
  R3_Usar: number;
  R4_Usar: number;
  R5_Usar: number;
  R5_Opcomparacion1: number;
  R5_Valor1: number;
  R5_Oplogico1: number;
  R5_Opcomparacion2: number;
  R5_Valor2: number;

}

export class EvaluacionEntity{
  Id_Evaluacion: number;
  Id_Institucion: number;
  Id_Usuario: number;
  Fecha_Evaluacion: string;
  Fecha_Contable: string;
  Descripcion: string;
  Observacion: string;
  Id_Tipo_Evaluacion: number;
  Id_Plantilla: number;
  Id_Pais: number;
  Id_Tipo_Institucion: number;
  Estado: number;
  VistoBueno: number;
  check: boolean;

}

export class CuentaEntity{
  Id_Cuenta: string;
  Descripcion: string;
  Tipo: number;
  Id_Plantilla: number;
  Id_Pais: number;
}

export class Detalle_Evaluacion_CuentaEntity{
  Id_Evaluacion: number;
  Id_Cuenta: string;
  Valor: number;
  ArregloID_evaluacion:string;

  cuentaEntity: CuentaEntity;
  //evaluacionEntity: EvaluacionEntity;
}
export class VariableEntity{
  Id_Variable: string;
  Descripcion: string;
  Id_Tipo_Variable: number;
  Id_Plantilla: number;
  Id_Pais: number;
  Id_Tipo_Cooperativa: number;
}
export class VarTempEntity{
  Id_VarTemp: string;
  Descripcion: string;
  Formula: string;
  Id_Plantilla: number;
  Id_Pais: number;
}
export class InstitucionEntity{
  Id_Institucion: number;
  Descripcion: string;
  Id_Tipo_Cooperativa: number;
  Id_Tipo_Institucion: number;
  Id_Federacion: number;
  Id_Cooperativa: number;
  Id_Sucursal: number;
  Id_Pais: number;
  Permitir_Bloqueo: number;
  Acronimo: string;
  Codigo_Auxiliar: string;
}