<div class="w3-container w3-contenedor-template" style="height: 7vh;">
    <div class="w3-row ">
      <!-- TITULO DEL COMPONENTE -->
        <div class="w3-third padreizquierda ">
            <div class="w3-row hijoizquierda">
                <!--<span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }} </b></span>-->
                <span class="w3-text-deep-orange w3-margin-right-filter"><b> Riesgo de crédito </b></span>
                <span class="w3-text-deep-orange">&bull;</span>
                <span class=" w3-margin-left-filter w3-text-orange">{{dataService.currentSubmodule}}</span>
            </div>
        </div>
        <div class="w3-rest ">
            <!-- APARTADO DE LOS AÑOS-->
            <div class="w3-third padreizquierda">
                <div class="w3-third hijoizquierda">
                  Año
                  <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios">
                    <mat-icon class="w3-tiny">expand_more</mat-icon>
                  </button>
                  <mat-menu class="w3-tiny"  #menuanios="matMenu" >
                    <ng-container *ngFor="let anio of aniosDisponibles">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                      (click)="$event.stopPropagation();"
                                      value="{{anio.name}}" aria-label="First checkbox"
                                      (change)="anioChangeListener(anio)">
                          {{anio.value}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
                </div>
                <!-- FIN FILTRO AÑOS DISPONIBLES -->
                <!-- AÑO SELECCIONADO -->
                <div class="w3-rest" style="margin-top: 1vh">
                  <mat-chip-list class="example-chip" style="height: 2vh;" cdkDropList cdkDropListOrientation="horizontal">
                    <span *ngFor="let anfil of aniosDisponibles">
                        <mat-chip class="example-box" cdkDrag *ngIf="anfil.check == true" (click)="anioSeleccionado(anfil.name)">  {{anfil.name}} </mat-chip>
                    </span>
                  </mat-chip-list>
                </div>
                <!-- FIN AÑO SELECCIONADO -->
            </div>
            <!-- APARTADO DE LOS MESES-->
            <div class="w3-third " >
                <div class="w3-third hijoizquierda">
                  Mes<button class="w3-button w3-tiny"
                          [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
                    <mat-icon class="w3-tiny">expand_more</mat-icon>
                  </button>
                  <mat-menu class="w3-tiny"  #menumeses="matMenu" >
                    <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                      <button mat-menu-item>
                        <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                      (click)="$event.stopPropagation();"
                                      value="{{mes.name}}"
                                      (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                          {{mes.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
                </div>
                <div class="w3-rest" style="margin-top: 1vh;">
                  <mat-chip-list class="example-chip" style="height: 2vh;" cdkDropList cdkDropListOrientation="horizontal">
                    <span >
                        <mat-chip class="example-box" cdkDrag > {{mesSeleccionado.name}}</mat-chip>
                    </span>
                  </mat-chip-list>
                </div>
            </div>
            <!-- Apartado para descargar -->
            <div class="w3-rest"> 
              <div class="w3-row hijoizquierda">
                  <!--<div class="example-button-container hijoizquierda">-->
                    Descargar reporte<button class="w3-button w3-tiny">
                      <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}" (click)="donwload_pdfMaker('download')">cloud_download</mat-icon>
                    </button>
                  <!--</div>-->
              </div>
            </div>
            <!-- Apartado para descargar -->
        </div>
    </div>
</div>
<div id="cont_template1" class="w3-container w3-contenedor-template w3-centered" style="height: 85vh;display: flex;justify-content: center;">
    <div class="w3-twothird w3-contenedor-template " style="height: 41vh;margin: 0 auto;">
        <div class="w3-row ">
            <div class="w3-container" style="height: 10vh;">
                <div class="w3-row">
                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Introducción
                            </mat-panel-title>
                            <mat-panel-description>
                              Introducción del reporte
                              <mat-icon *ngIf="expand_intro">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_intro">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="w3-row w3-border-top w3-border-expander">
                            <mat-form-field class="w3-input">
                                <mat-label>Introducción</mat-label>
                                <textarea id="txt_introduccion" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" >
De acuerdo a lo establecido en el Artículo 86 Bis 23, de las Disposiciones de carácter general aplicables a las organizaciones auxiliares del crédito, casas de cambio, uniones de crédito, sociedades financieras de objeto limitado y sociedades financieras de objeto múltiple reguladas publicadas el 10 de noviembre de 2017, procedo a rendir el informe de administración de riesgos a la Dirección General.

                                </textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>
                      
                        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Concentración de la cartera
                            </mat-panel-title>
                            <mat-panel-description>
                              Concentración de la cartera
                              <mat-icon *ngIf="expand_concen_cartera">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_concen_cartera">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          
                          <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step2 === 0" (opened)="setStep2(0)" hideToggle>
                              <mat-expansion-panel-header class="w3-light-gray">
                                <mat-panel-title>
                                  Concentración de la cartera
                                </mat-panel-title>
                                <mat-panel-description>
                                  Productos de cartera
                                  <mat-icon *ngIf="expand_productcartera">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!expand_productcartera">keyboard_arrow_up</mat-icon>
                                </mat-panel-description>
                              </mat-expansion-panel-header>

                              <div class="w3-row w3-border-top w3-border-expander">
                                <div class="w3-row" style="height: 6vh;">
                                  <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                    <div class="hijoizquierdaTitle">
                                      <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                      <span class="w3-margin-left-contenido">Productos de cartera</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w3-row w3-centered" style="width: 100%;height: auto;">
                                  <div id="grafica_productos" class="w3-content w3-centered " style="height: 39vh;width: 100%;" >
                                    <div class="w3-content w3-center" style="height: 38vh;width: 85%;">
                                      <ngx-charts-bar-vertical class="w3-center w3-letragrafica" style="height: 36vh;width: 85%;"
                                        [scheme]="colorSchemeBarras2" 
                                        [results]="DatosProduct"
                                        [legend]="showLegend" 
                                        [legendTitle]="legendTitle" 
                                        [legendPosition]="legendPosition"
                                        [xAxis]="yAxis" 
                                        [yAxis]="xAxis" 
                                        [showXAxisLabel]="showXAxisLabel" 
                                        [showYAxisLabel]="showYAxisLabel"
                                        [xAxisLabel]="yAxisLabel" 
                                        [yAxisLabel]="xAxisLabel"
                                        [showGridLines]="showGridLines"
                                      >
                                      </ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                  <div class="w3-row" style="height: 6vh;">
                                    <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                      <div class="hijoizquierdaTitle">
                                        <span class="w3-margin-left-contenido">Interpretación de resultados</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="tabla_productos" class="w3-row w3-centered" style="height: auto;width: 100%;">
                                    <table mat-table [dataSource]="DatosProductTabla" class="w3-table-all-reportes" style="height: auto;width: 80%;">
                                      <ng-container matColumnDef="name">
                                        <th class="w3-elemento-titulo-table w3-table-title"  mat-header-cell *matHeaderCellDef> Producto </th>
                                        <td [class]="PintarTotales(i)" mat-cell *matCellDef="let element; let i = index"> {{element.name}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="value">
                                        <th class="w3-elemento-titulo-table w3-table-title" mat-header-cell *matHeaderCellDef> Saldo: </th>
                                        <td [class]="PintarTotales(i)" mat-cell *matCellDef="let element; let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                      </ng-container>
                                    
                                      <ng-container matColumnDef="porcentaje">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                        <td [class]="PintarTotales(i)" mat-cell *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.porcentaje)}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="ihh">
                                        <th class="w3-elemento-titulo-table w3-table-title"  mat-header-cell *matHeaderCellDef> IHH: </th>
                                        <td [class]="PintarTotales(i)" mat-cell *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.ihh)}} </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsProductos;sticky: true;" class="w3-table-title"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsProductos;let i = index"></tr>
                                    </table>                               
                                  </div>
                                  <div class="w3-row" style="height: 6vh;">
                                    <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                      <div class="hijoizquierdaTitle">
                                        <span class="w3-margin-left-contenido">Análisis</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w3-row">
                                    <mat-form-field class="w3-input">
                                        <textarea id="txt_productocartera" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                                    </mat-form-field>
                                  </div>             
                                </div>
                              </div>
                              

                              <mat-action-row>
                                <button mat-button color="primary" (click)="nextStep2()">Next</button>
                              </mat-action-row>
                            </mat-expansion-panel>
                          
                            <mat-expansion-panel [expanded]="step2 === 1" (opened)="setStep2(1)" hideToggle>
                              <mat-expansion-panel-header class="w3-light-gray">
                                <mat-panel-title>
                                  Concentración de la cartera
                                </mat-panel-title>
                                <mat-panel-description>
                                  Distribución cartera por sector
                                  <mat-icon *ngIf="expand_districartera">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!expand_districartera">keyboard_arrow_up</mat-icon>
                                </mat-panel-description>
                              </mat-expansion-panel-header>

                              <div class="w3-row">
                                <div class="w3-row" style="height: 6vh;">
                                  <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                    <div class="hijoizquierdaTitle">
                                      <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                      <span class="w3-margin-left-contenido">Distribución cartera por sector</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w3-row" style="width: 100%;height: auto;"><br>
                                  <div id="grafica_Sector" class="w3-half w3-left" style="height: 36vh; " >
                                    <google-chart style="margin-left: -1vh;"
                                      class="graficaPie"
                                      [type]="'PieChart'"
                                      [data]="dataPieChart"
                                      [options]="optionsSector">
                                    </google-chart>
                                  </div><br><br>
                                  <div id="tabla_Sector" class="w3-half w3-right" style="height: 36vh;" >
                                    <table mat-table [dataSource]="DatosSectorTabla" class="w3-table-all-reportes w3-centered" style="height: auto;width: 100%;margin-top: 1vh;">
                                      <ng-container matColumnDef="name">
                                        <th class="w3-elemento-titulo-table w3-table-title" mat-header-cell *matHeaderCellDef> Producto </th>
                                        <td [class]="PintarTotalesSector(i)" *matCellDef="let element; let i = index"> {{element.name}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="value">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Saldo: </th>
                                        <td [class]="PintarTotalesSector(i)" *matCellDef="let element; let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                      </ng-container>
                                    
                                      <ng-container matColumnDef="porcentaje">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                        <td [class]="PintarTotalesSector(i)" *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.porcentaje)}} </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsSector;sticky: true;"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsSector;let i = index"></tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Análisis</span>
                                  </div>
                                </div>
                              </div>
                              <div class="w3-row">
                                <mat-form-field class="w3-input">
                                    <textarea id="txt_carterasector" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                                </mat-form-field>
                              </div>
                              
                          
                              <mat-action-row>
                                <button mat-button color="warn" (click)="prevStep2()">Previous</button>
                                <button mat-button color="primary" (click)="nextStep2()">Next</button>
                              </mat-action-row>
                            </mat-expansion-panel>
                          
                            <mat-expansion-panel [expanded]="step2 === 2" (opened)="setStep2(2)" hideToggle>
                              <mat-expansion-panel-header class="w3-light-gray">
                                <mat-panel-title>
                                  Concentración de la cartera
                                </mat-panel-title>
                                <mat-panel-description>
                                  Distribución por bandas de morosidad
                                  <mat-icon *ngIf="expand_districarteramora">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!expand_districarteramora">keyboard_arrow_up</mat-icon>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              
                              <div class="w3-row">
                                <div class="w3-row" style="height: 6vh;">
                                  <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                    <div class="hijoizquierdaTitle">
                                      <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                      <span class="w3-margin-left-contenido">Banda morosidad</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w3-row" style="width: 100%;height: auto;">
                                  <div id="grafica_BandaMorosidad" class="w3-row w3-content" style="height: 37vh;width: 90%;" >
                                    <ngx-charts-bar-vertical style="height: 36vh;width: 100%;"
                                    [scheme]="colorSchemeBarras2" 
                                    [results]="data" 
                                    [legend]="showLegend" 
                                    [legendTitle]="legendTitle" 
                                    [legendPosition]="legendPosition"
                                    [xAxis]="yAxis" 
                                    [yAxis]="xAxis" 
                                    [showXAxisLabel]="showXAxisLabel" 
                                    [showYAxisLabel]="showYAxisLabel"
                                    [xAxisLabel]="yAxisLabelBandaMorosidad" 
                                    [yAxisLabel]="xAxisLabel"
                                    [showGridLines]="showGridLines"
                                    >
                                    </ngx-charts-bar-vertical>
                                  </div>
                                  <div class="w3-row" style="height: 6vh;">
                                    <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                      <div class="hijoizquierdaTitle">
                                        <span class="w3-margin-left-contenido">Interpretación de resultados</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="tabla_BandaMorosidad" class="w3-row w3-centered" style="height: auto;width: 100%;">
                                    <br>
                                    <table mat-table [dataSource]="dataBandaMorosidad" class="w3-table-all-reportes" style="height: auto;width: 80%;">
                                      <ng-container matColumnDef="name">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Banda </th>
                                        <td [class]="PintarTotalesBandaMorosidad(i)" *matCellDef="let element; let i = index"> {{element.name}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="value">
                                        <th class="w3-elemento-titulo-table w3-table-title" mat-header-cell *matHeaderCellDef> Saldo: </th>
                                        <td [class]="PintarTotalesBandaMorosidad(i)" *matCellDef="let element; let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                      </ng-container>
                                    
                                      <ng-container matColumnDef="porcentaje">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                        <td [class]="PintarTotalesBandaMorosidad(i)" *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.porcentaje)}} </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsBandaMorosidad;sticky: true;"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsBandaMorosidad;let i = index"></tr>
                                    </table>                               
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Análisis</span>
                                  </div>
                                </div>
                              </div>
                              <div class="w3-row">
                                <mat-form-field class="w3-input">
                                    <textarea id="txt_BandaMorosidad" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                                </mat-form-field>
                              </div>


                              <mat-action-row>
                                <button mat-button color="warn" (click)="prevStep2()">Previous</button>
                                <button mat-button color="primary" (click)="nextStep2()">Next</button>
                              </mat-action-row>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step2 === 3" (opened)="setStep2(3)" hideToggle>
                              <mat-expansion-panel-header class="w3-light-gray">
                                <mat-panel-title>
                                  Concentración de la cartera
                                </mat-panel-title>
                                <mat-panel-description>
                                  Distribución por Zona
                                  <mat-icon *ngIf="expand_distrizona">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!expand_distrizona">keyboard_arrow_up</mat-icon>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              
                              <div class="w3-row">
                                <div class="w3-row" style="height: 6vh;">
                                  <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                    <div class="hijoizquierdaTitle">
                                      <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                      <span class="w3-margin-left-contenido">Distribución por Zona</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w3-row" style="width: 100%;height: auto;">
                                  <div id="tabla_Zona" class="w3-row w3-centered" style="height: auto;width: 100%;">
                                    <br>
                                    <table mat-table [dataSource]="dataZona" class="w3-table-all-reportes" style="height: 34vh;width: 80%;">
                                      <ng-container matColumnDef="name">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Zona </th>
                                        <td [class]="PintarTotalesZona(i)" *matCellDef="let element; let i = index"> {{element.name}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="value">
                                        <th class="w3-elemento-titulo-table w3-table-title "  mat-header-cell *matHeaderCellDef> Saldo: </th>
                                        <td [class]="PintarTotalesZona(i)" *matCellDef="let element; let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                      </ng-container>
                                    
                                      <ng-container matColumnDef="porcentaje">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                        <td [class]="PintarTotalesZona(i)" *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.porcentaje)}} </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsZona;sticky: true;"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsZona; let i = index"></tr>
                                    </table>                               
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Análisis</span>
                                  </div>
                                </div>
                              </div>
                              <div class="w3-row">
                                <mat-form-field class="w3-input">
                                    <textarea id="txt_distribucionZona" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                                </mat-form-field>
                              </div>


                              <mat-action-row>
                                <button mat-button color="warn" (click)="prevStep2()">Previous</button>
                                <button mat-button color="primary" (click)="nextStep2()">Next</button>
                              </mat-action-row>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step2 === 4" (opened)="setStep2(4)" hideToggle>
                              <mat-expansion-panel-header class="w3-light-gray">
                                <mat-panel-title>
                                  Concentración de la cartera
                                </mat-panel-title>
                                <mat-panel-description>
                                  Distribución por Garantía
                                  <mat-icon *ngIf="expand_distrigarantia">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!expand_distrigarantia">keyboard_arrow_up</mat-icon>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              
                              <div class="w3-row">
                                <div class="w3-row" style="height: 6vh;">
                                  <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                    <div class="hijoizquierdaTitle">
                                      <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                      <span class="w3-margin-left-contenido">Distribución por Garantía</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w3-row w3-centered" style="width: 100%;height: auto;">
                                  <div id="grafica_Garantia" class="w3-content w3-centered " style="height: 38vh;width: 100%;" >
                                    <div class="w3-content w3-center" style="height: 36vh;width: 85%;">
                                      <ngx-charts-bar-vertical
                                        [scheme]="colorSchemeBarras2"
                                        [results]="dataGarantia"
                                        [legend]="showLegend"
                                        legendTitle="Garantía"
                                        [xAxis]="xAxis"
                                        [yAxis]="yAxis"
                                        [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel"
                                        [xAxisLabel]="xAxisLabelGarantia"
                                        [yAxisLabel]="yAxisLabelGarantia"
                                        [showGridLines]="showGridLines">
                                      </ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                  <div class="w3-row" style="height: 6vh;">
                                    <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                      <div class="hijoizquierdaTitle">
                                        <span class="w3-margin-left-contenido">Interpretación de resultados</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="tabla_Garantia" class="w3-row w3-centered" style="height: auto;width: 100%;">
                                    <table mat-table [dataSource]="DatosGarantiaTabla" class="w3-table-all-reportes" style="height: auto;width: 80%;">
                                      <ng-container matColumnDef="name">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Garantía </th>
                                        <td [class]="PintarTotalesGarantia(i)" *matCellDef="let element;let i = index"> {{element.name}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="value">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Saldo: </th>
                                        <td [class]="PintarTotalesGarantia(i)" *matCellDef="let element;let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                      </ng-container>
                                    
                                      <ng-container matColumnDef="porcentaje">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                        <td [class]="PintarTotalesGarantia(i)" *matCellDef="let element;let i = index"> {{formatearNumeroNormal(element.porcentaje)}} </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsGarantia;sticky: true;"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsGarantia;let i = index"></tr>
                                    </table>                               
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Análisis</span>
                                  </div>
                                </div>
                              </div>
                              <div class="w3-row">
                                <mat-form-field class="w3-input">
                                    <textarea id="txt_garantia" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                                </mat-form-field>
                              </div>


                              <mat-action-row>
                                <button mat-button color="warn" (click)="prevStep2()">Previous</button>
                                <button mat-button color="primary" (click)="nextStep2()">Next</button>
                              </mat-action-row>
                            </mat-expansion-panel>
                            
                            <mat-expansion-panel [expanded]="step2 === 5" (opened)="setStep2(5)" hideToggle>
                              <mat-expansion-panel-header class="w3-light-gray">
                                <mat-panel-title>
                                  Distribución cartera por género
                                </mat-panel-title>
                                <mat-panel-description>
                                  Distribución cartera por género
                                  <mat-icon *ngIf="expand_distrigenero">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!expand_distrigenero">keyboard_arrow_up</mat-icon>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              
                              <div class="w3-row">
                                <div class="w3-row" style="height: 6vh;">
                                  <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                    <div class="hijoizquierdaTitle">
                                      <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                      <span class="w3-margin-left-contenido">Distribución cartera por género</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w3-row" style="width: 100%;height: auto;"><br>
                                  <div id="grafica_Genero" class="w3-half w3-left" style="height: 38vh;">
                                    <google-chart style="margin-left: -1vh;"
                                      class="graficaPie"
                                      [type]="'PieChart'"
                                      [data]="dataPieChartGenero"
                                      [options]="optionsGenero">
                                    </google-chart>
                                  <!--
                                    <ngx-charts-pie-chart
                                      [scheme]="colorSchemeBarras2"
                                      [results]="datosgenero"
                                      [legend]="showLegendPastel"
                                      [legendPosition]="legendPositionPastel"
                                      [labels]="showLabels"
                                      [doughnut]="isDoughnut"
                                      [legendTitle]="legendTitlePastel"
                                      >
                                    </ngx-charts-pie-chart>
                                  -->
                                  </div><br>
                                  <div id="tabla_Genero" class="w3-half w3-right" style="height: auto;">
                                    <table mat-table [dataSource]="DatosGeneroTabla" class="w3-table-all-reportes w3-centered" style="height: auto;width: 95%;">
                                      <ng-container matColumnDef="name">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Género </th>
                                        <td [class]="PintarTotalesGenero(i)" *matCellDef="let element; let i = index"> {{element.name}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="value">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Saldo: </th>
                                        <td [class]="PintarTotalesGenero(i)" *matCellDef="let element; let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                      </ng-container>
                                    
                                      <ng-container matColumnDef="porcentaje">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                        <td [class]="PintarTotalesGenero(i)" *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.porcentaje)}} </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsGenero;sticky: true;"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsGenero;"></tr>
                                    </table>                               
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Análisis</span>
                                  </div>
                                </div>
                              </div>
                              <div class="w3-row">
                                <mat-form-field class="w3-input">
                                    <textarea id="txt_genero" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                                </mat-form-field>
                              </div>


                              <mat-action-row>
                                <button mat-button color="warn" (click)="prevStep2()">Previous</button>
                                <button mat-button color="primary" (click)="nextStep2()">Next</button>
                              </mat-action-row>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step2 === 6" (opened)="setStep2(6)" hideToggle>
                              <mat-expansion-panel-header class="w3-light-gray">
                                <mat-panel-title>
                                  Distribución cartera por estatus
                                </mat-panel-title>
                                <mat-panel-description>
                                  Distribución cartera por estatus
                                  <mat-icon *ngIf="expand_distriestatus">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!expand_distriestatus">keyboard_arrow_up</mat-icon>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              
                              <div class="w3-row">
                                <div class="w3-row" style="height: 6vh;">
                                  <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                    <div class="hijoizquierdaTitle">
                                      <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                      <span class="w3-margin-left-contenido">Distribución cartera por estatus</span>
                                    </div>
                                  </div>
                                </div>
                                <br>
                                <div class="w3-row w3-container" style="width: 100%;height: auto;">
                                  <div id="tabla_EstatusCredito" class="w3-content w3-centered" style="height: auto;">
                                    <table  mat-table [dataSource]="DatosEstatusCreditoTabla" class="w3-table-all-reportes" style="height: auto;width: 80%;">
                                      <ng-container matColumnDef="name">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Estatus </th>
                                        <td [class]="PintarTotalesEstatus(i)" *matCellDef="let element; let i = index"> {{element.name}} </td>
                                      </ng-container>
  
                                      <ng-container matColumnDef="value">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Saldo: </th>
                                        <td [class]="PintarTotalesEstatus(i)" *matCellDef="let element; let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                      </ng-container>
                                    
                                      <ng-container matColumnDef="porcentaje">
                                        <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                        <td [class]="PintarTotalesEstatus(i)" *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.porcentaje)}} </td>
                                      </ng-container>
                                    
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsEstatusCredito;sticky: true;"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsEstatusCredito;let i = index"></tr>
                                    </table>                               
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Análisis</span>
                                  </div>
                                </div>
                              </div>
                              <div class="w3-row">
                                <mat-form-field class="w3-input">
                                    <textarea id="txt_estatuscredito" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                                </mat-form-field>
                              </div>


                              <mat-action-row>
                                <button mat-button color="warn" (click)="prevStep2()">Previous</button>
                                <button mat-button color="primary" (click)="nextStep2()">End</button>
                              </mat-action-row>
                            </mat-expansion-panel>
                          </mat-accordion>
                          
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Mayores deudores
                            </mat-panel-title>
                            <mat-panel-description>
                              Mayores deudores
                              <mat-icon *ngIf="expand_mayoresdeudores">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_mayoresdeudores">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="w3-row">
                            <div class="w3-row" style="height: 6vh;">
                              <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                <div class="hijoizquierdaTitle">
                                  <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                  <span class="w3-margin-left-contenido">Mayores deudores</span>
                                </div>
                              </div>
                            </div>
                            <div class="w3-row" style="width: 100%;height: auto;">
                              <div id="grafica_mayoresdeudores" class="w3-row" style="height: 64vh;width: 90%;" >
                                <ngx-charts-bar-horizontal 
                                  [scheme]="colorScheme2"
                                  [results]="data_mayoresdeudores"
                                  [trimXAxisTicks]="trimXAxisTicks"
                                  [trimYAxisTicks]="trimYAxisTicks"
                                  [maxYAxisTickLength]="maxYAxisTickLength"
                                  [xAxis]="xAxis"
                                  [yAxis]="yAxis"
                                  [legendPosition]="legendPosition"
                                  [showXAxisLabel]="showXAxisLabelMayoresDeudores"
                                  [rotateXAxisTicks]="rotateXAxisTicks"
                                  [showYAxisLabel]="showYAxisLabelMayoresDeudores"
                                  [xAxisLabel]="xAxisLabelMayoresDeudores"
                                  [yAxisLabel]="yAxisLabelMayoresDeudores"
                                  >
                                </ngx-charts-bar-horizontal>
                              </div>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Interpretación de resultados</span>
                                  </div>
                                </div>
                              </div>
                              <div id="tabla_mayoresdeudores" class="w3-row w3-centered" style="height: auto;width: 100%;">
                                <table mat-table [dataSource]="DatosMayoresDeudoresTabla" class="w3-table-all-reportes" style="height: auto;width: 80%;">
                                  <ng-container matColumnDef="name">
                                    <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Producto </th>
                                    <td [class]="PintarTotalesMayores(i)" *matCellDef="let element; let i = index"> {{element.name}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="value">
                                    <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Saldo: </th>
                                    <td [class]="PintarTotalesMayores(i)" *matCellDef="let element; let i = index">$ {{formatearNumeroNormal(element.value)}} </td>
                                  </ng-container>
                                
                                  <ng-container matColumnDef="porcentaje">
                                    <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> % participación </th>
                                    <td [class]="PintarTotalesMayores(i)" *matCellDef="let element; let i = index"> {{formatearNumeroNormal(element.porcentaje)*100}} </td>
                                  </ng-container>
                                
                                  <tr mat-header-row *matHeaderRowDef="displayedColumnsMayoresDeudores;sticky: true;"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumnsMayoresDeudores; let i = index"></tr>
                                </table>                               
                              </div>
                            </div>
                          </div><br>
                          <div class="w3-row" style="height: 6vh;">
                            <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                              <div class="hijoizquierdaTitle">
                                <span class="w3-margin-left-contenido">Análisis</span>
                              </div>
                            </div>
                          </div>
                          <div class="w3-row">
                            <mat-form-field class="w3-input">
                                <textarea id="txt_mayoresdeudores" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Tendencia de pérdida
                            </mat-panel-title>
                            <mat-panel-description>
                              Tendencia de pérdida
                              <mat-icon *ngIf="expand_tendenciaperdida">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_tendenciaperdida">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="w3-row">
                            <div class="w3-row" style="height: 6vh;">
                              <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                <div class="hijoizquierdaTitle">
                                  <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                  <span class="w3-margin-left-contenido">Tendencia de pérdidad</span>
                                </div>
                              </div>
                            </div>
                            <div class="w3-row" style="width: 100%;height: auto;"><br>
                              <div id="grafica_tendenciaperdida" class="w3-row" style="height: 38vh;width: 100%;" >
                                <google-chart style="height: 36vh;width: 105%;"
                                    [title]="title" 
                                    [type]="type" 
                                    [data]="dataGraficaPerdida" 
                                    [columns]="columnNames" 
                                    [options]="options">
                                </google-chart>
                              </div>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Interpretación de resultados</span>
                                  </div>
                                </div>
                              </div>
                              <div id="tabla_tendenciaperdida" class="w3-row w3-centered" style="height: auto;width: 100%;">
                                <table mat-table [dataSource]="dataPerdidaEsperada" class="w3-table-all-reportes w3-tiny" style="height: 34vh;width: 60%;">
                                  <ng-container matColumnDef="Periodo">
                                    <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Periodo </th>
                                    <td class="w3-elemento-tableMat" *matCellDef="let element;let i = index"> {{element.periodo}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="PerdidaEsperada">
                                    <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Pérdida esperada </th>
                                    <td class="w3-elemento-tableMat" *matCellDef="let element;let i = index">$ {{formatearNumeroNormal(element.perdidaesperada)}} </td>
                                  </ng-container>
                                
                                  <ng-container matColumnDef="PerdidaNoEsperada95">
                                    <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> Pérdida no esperada </th>
                                    <td class="w3-elemento-tableMat" *matCellDef="let element;let i = index"> $ {{formatearNumeroNormal(element.perdidanoesperada)}} </td>
                                  </ng-container>
                                
                                  <ng-container matColumnDef="VaR95">
                                    <th class="w3-elemento-titulo-table w3-table-title " mat-header-cell *matHeaderCellDef> VaR </th>
                                    <td class="w3-elemento-tableMat" *matCellDef="let element;let i = index"> $ {{formatearNumeroNormal(element.var90)}} </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTendenciaPerdida;sticky: true;"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumnsTendenciaPerdida;let i = index"></tr>
                                </table>                               
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="w3-row" style="height: 6vh;">
                            <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                              <div class="hijoizquierdaTitle">
                                <span class="w3-margin-left-contenido">Análisis</span>
                              </div>
                            </div>
                          </div>
                          <div class="w3-row">
                            <mat-form-field class="w3-input">
                                <textarea id="txt_tendenciaperdida" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Matríz de transición por monto
                            </mat-panel-title>
                            <mat-panel-description>
                              Matríz de transición por monto
                              <mat-icon *ngIf="expand_matrizmonto">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_matrizmonto">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="w3-row">
                            <div class="w3-row" style="height: 6vh;">
                              <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                <div class="hijoizquierdaTitle">
                                  <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                  <span class="w3-margin-left-contenido">Matríz de transición por monto</span>
                                </div>
                              </div>
                            </div>
                            <div class="w3-row" style="width: 100%;height: auto;"><br>
                              <div id="tabla_matriztransicion" class="w3-row w3-centered w3-small" style="height: auto;width: 100%;">
                                <table mat-table [dataSource]="dataSource" class="w3-table-all-reportes" style="height: auto;width: 85%;">
                                  
                                  <!-- Position Column -->
                                  <ng-container style="width: 6vh;" matColumnDef="name">
                                    <th class="w3-table-title w3-center  w3-small" mat-header-cell *matHeaderCellDef></th>
                                    <td class="w3-table-title  w3-small" mat-cell *matCellDef="let element"> {{element.name}} </td>
                                  </ng-container>
                                  <!-- Position Column -->
                                  <ng-container matColumnDef="A-1">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> A-1 </th>
                                      <td [class]="PintarCelda(0,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles " *ngIf="formatearnormal(element.A1) != 0"> {{formatearnormal(element.A1)}} </div> </td>
                                  </ng-container>
                              
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="A-2">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> A-2 </th>
                                      <td [class]="PintarCelda(1,i)" *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles " *ngIf="formatearnormal(element.A2) != 0">{{formatearnormal(element.A2)}}</div> </td>
                                  </ng-container>
                              
                                  <!-- Weight Column -->
                                  <ng-container  matColumnDef="B-1">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> B-1 </th>
                                      <td [class]="PintarCelda(2,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B1) != 0">{{formatearnormal(element.B1)}}</div> </td>
                                  </ng-container>
                              
                                  <!-- Symbol Column -->
                                  <ng-container  matColumnDef="B-2">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> B-2 </th>
                                      <td [class]="PintarCelda(3,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B2) != 0">{{formatearnormal(element.B2)}}</div> </td>
                                  </ng-container>
                                  
                                  <!-- Symbol Column -->
                                  <ng-container  matColumnDef="B-3">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> B-3 </th>
                                    <td [class]="PintarCelda(4,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.B3) != 0">{{formatearnormal(element.B3)}}</div> </td>
                                  </ng-container>
            
                                  <!-- Symbol Column -->
                                  <ng-container matColumnDef="C-1">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> C-1 </th>
                                    <td [class]="PintarCelda(5,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.C1) != 0">{{formatearnormal(element.C1)}}</div> </td>
                                  </ng-container>
            
                                  <!-- Symbol Column -->
                                  <ng-container  matColumnDef="C-2">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> C-2 </th>
                                    <td [class]="PintarCelda(6,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.C2) != 0">{{formatearnormal(element.C2)}}</div> </td>
                                  </ng-container>
            
                                  <!-- Symbol Column -->
                                  <ng-container  matColumnDef="D">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> D </th>
                                    <td [class]="PintarCelda(7,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.D) != 0">{{formatearnormal(element.D)}}</div> </td>
                                  </ng-container>
            
                                  <!-- Symbol Column -->
                                  <ng-container matColumnDef="E">
                                    <th  class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> E </th>
                                    <td [class]="PintarCelda(8,i)" mat-cell *matCellDef="let element; let i = index"> <div class="w3-right w3-margin-right-niveles" *ngIf="formatearnormal(element.E) != 0">{{formatearnormal(element.E)}}</div> </td>
                                  </ng-container>
            
                                  <!-- Symbol Column -->
                                  <ng-container  matColumnDef="total">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> Total </th>
                                    <td mat-cell *matCellDef="let element"> <div class="w3-right w3-margin-right-niveles w3-small" *ngIf="formatearnormal(element.total) != 0">{{element.total}}</div> </td>
                                  </ng-container>
            
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title w3-small"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row w3-small " ></tr>
                              </table>                          
                              </div>
                            </div>
                            <div class="w3-row w3-white" style="height: 15vh;">
                              <div class="w3-row w3-margin-top w3-margin-left-contenido">
                                <div class="w3-row w3-tipografia-cifras hijoizquierda4 w3-titulo-contenidoRiesgos ">
                                  {{mesAnio}}
                                </div>
                              </div>
                              <div id="transicionmanita1" class="w3-col m5 w3-row w3-margin-right-matrices">
                                <div class="w3-row w3-white w3-margin-top w3-border w3-border-gray" style="height: 8vh;">
                                  <div class="w3-third padreizquierda">
                                    <div id="icon_manitaverde" class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
                                        <mat-icon class="material-icons-outlined w3-xlarge" style="height: 24px;width: 24px;" 
                                            [ngStyle]="{'color':'#8bc34a'}">
                                            thumb_up
                                        </mat-icon>
                                    </div>
                                  </div>
                                  <div class="w3-rest">
                                    <div class="w3-row hijoRentabilidad_Reporte w3-total-cantidadRiesgos w3-balance-bluep" style="width: 98%;">
                                      <div class="w3-right w3-margin-right-niveles">
                                        $ {{formatearnormal(total_abajodiagonal.toFixed(2))}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div id="transicionmanita2" class="w3-col m5 w3-row">
                                  <div class="w3-row w3-white w3-margin-top w3-border w3-border-gray" style="height: 8vh;">
                                    <div class="w3-third padreizquierda">
                                      <div id="icon_manitaazul" class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
                                          <mat-icon class="material-icons-outlined w3-xlarge" [ngStyle]="{'color':'#0075C9'}">touch_app</mat-icon>
                                      </div>
                                    </div>
                                    <div class="w3-rest">
                                      <div class="w3-row hijoRentabilidad_Reporte w3-total-cantidadRiesgos w3-balance-bluep" style="width: 98%;">
                                        <div class="w3-right w3-margin-right-niveles">
                                          $ {{formatearnormal(total_diagonal.toFixed(2))}} 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                            <div class="w3-row w3-centered">                              
                              <div id="transicionmanita3" class="w3-col m5 w3-row">
                                <div class="w3-row w3-white w3-margin-top w3-border w3-border-gray" style="height: 8vh;">
                                  <div class="w3-third padreizquierda">
                                    <div id="icon_manitanaranja" class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
                                        <mat-icon class="material-icons-outlined w3-xlarge" [ngStyle]="{'color':'#ff9012'}">thumb_down</mat-icon>
                                    </div>
                                  </div>
                                  <div class="w3-rest ">
                                    <div class="w3-row hijoRentabilidad_Reporte w3-total-cantidadRiesgos w3-balance-bluep" style="width: 98%;">
                                      <div class="w3-right w3-margin-right-niveles">
                                        $ {{formatearnormal(total_arribadiagonal.toFixed(2))}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="w3-row" style="height: 6vh;">
                            <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                              <div class="hijoizquierdaTitle">
                                <span class="w3-margin-left-contenido">Análisis</span>
                              </div>
                            </div>
                          </div>
                          <div class="w3-row">
                            <mat-form-field class="w3-input">
                                <textarea id="txt_matriztransicion" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Matríz de transición créditos
                            </mat-panel-title>
                            <mat-panel-description>
                              Matríz de transición créditos
                              <mat-icon *ngIf="expand_matrizcreditos">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_matrizcreditos">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="w3-row">
                            <div class="w3-row" style="height: 6vh;">
                              <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                <div class="hijoizquierdaTitle">
                                  <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                  <span class="w3-margin-left-contenido">Matríz de transición créditos</span>
                                </div>
                              </div>
                            </div>
                            <div class="w3-row" style="width: 100%;height: auto;"><br>
                              <div id="tabla_matriztransicionc" class="w3-row w3-centered" style="height: auto;width: 100%;">
                                <table mat-table [dataSource]="dataSourceII" class="w3-table-all-reportes" style="height: auto;width: 85%;">

                                  <ng-container matColumnDef="name">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> </th>
                                      <td class="w3-table-title w3-center w3-small" mat-cell *matCellDef="let element">  {{element.name}} </td>
                                  </ng-container>                    
                                
                                  <ng-container matColumnDef="A-1">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> A-1 </th>
                                      <td [class]="PintarBorder(0,i)" *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.A1 != 0">{{element.A1}}</div> </td>
                                  </ng-container>                    
                                  
                                  <ng-container matColumnDef="A-2">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> A-2 </th>
                                      <td [class]="PintarBorder(1,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.A2 != 0">{{element.A2}}</div> </td>
                                  </ng-container>                    
                                  
                                  <ng-container matColumnDef="B-1">
                                      <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> B-1 </th>
                                      <td [class]="PintarBorder(2,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B1 != 0">{{element.B1}}</div> </td>
                                  </ng-container>                  
                                  
                                  <ng-container matColumnDef="B-2">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> B-2 </th>
                                    <td [class]="PintarBorder(3,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B2 != 0">{{element.B2}}</div> </td>
                                  </ng-container>                    
          
                                  <ng-container matColumnDef="B-3">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> B-3 </th>
                                    <td [class]="PintarBorder(4,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.B3 != 0">{{element.B3}}</div> </td>
                                  </ng-container>                    
          
                                  <ng-container matColumnDef="C-1">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> C-1 </th>
                                    <td [class]="PintarBorder(5,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.C1 != 0">{{element.C1}}</div> </td>
                                  </ng-container>                    
                                  
                                  <ng-container matColumnDef="C-2">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> C-2 </th>
                                    <td [class]="PintarBorder(6,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.C2 != 0">{{element.C2}}</div> </td>
                                  </ng-container>                    
          
                                  <ng-container matColumnDef="D">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> D </th>
                                    <td [class]="PintarBorder(7,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.D != 0">{{element.D}}</div> </td>
                                  </ng-container>                    
          
                                  <ng-container matColumnDef="E">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> E </th>
                                    <td [class]="PintarBorder(8,i)" mat-cell *matCellDef="let element; let i = index">  <div class="w3-right w3-margin-right-niveles" *ngIf="element.E != 0">{{element.E}}</div> </td>
                                  </ng-container>              
                                  
                                  <ng-container matColumnDef="total">
                                    <th class="w3-table-title w3-center w3-small" mat-header-cell *matHeaderCellDef> Total </th>
                                    <td  mat-cell *matCellDef="let element"> <div class="w3-right w3-margin-right-niveles" *ngIf="element.total != 0"> {{element.total}}</div> </td>
                                  </ng-container>
          
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title w3-small"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row w3-small" ></tr>
                                </table>                        
                              </div>
                            </div>
                            <div class="w3-row w3-white" style="height: 15vh;">
                              <div class="w3-row w3-margin-top w3-margin-left-contenido">
                                <div class="w3-row w3-tipografia-cifras hijoizquierda4 w3-titulo-contenidoRiesgos ">
                                  {{mesAnio}}
                                </div>
                              </div>
                              <div id="transicion_cmanita1" class="w3-col m5 w3-row w3-margin-right-matrices">
                                <div class="w3-row w3-white w3-margin-top w3-border w3-border-gray" style="height: 8vh;">
                                  <div class="w3-third padreizquierda">
                                    <div class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
                                        <mat-icon class="material-icons-outlined w3-xlarge" style="height: 24px;width: 24px;" 
                                            [ngStyle]="{'color':'#8bc34a'}">
                                            thumb_up
                                        </mat-icon>
                                    </div>
                                  </div>
                                  <div class="w3-rest">
                                    <div class="w3-row hijoRentabilidad_Reporte w3-total-cantidadRiesgos w3-balance-bluep" style="width: 98%;">
                                      <div class="w3-right w3-margin-right-niveles">
                                         {{formatearnormalCredito(total_abajodiagonalII)}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div id="transicion_cmanita2" class="w3-col m5 w3-row">
                                <div class="w3-row w3-white w3-margin-top w3-border w3-border-gray" style="height: 8vh;">
                                  <div class="w3-third padreizquierda">
                                    <div class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
                                        <mat-icon class="material-icons-outlined w3-xlarge" [ngStyle]="{'color':'#0075C9'}">touch_app</mat-icon>
                                    </div>
                                  </div>
                                  <div class="w3-rest">
                                    <div class="w3-row hijoRentabilidad_Reporte w3-total-cantidadRiesgos w3-balance-bluep" style="width: 98%;">
                                      <div class="w3-right w3-margin-right-niveles">
                                         {{formatearnormalCredito(total_diagonalII)}} 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w3-row w3-centered">                              
                              <div id="transicion_cmanita3" class="w3-col m5 w3-row">
                                <div class="w3-row w3-white w3-margin-top w3-border w3-border-gray" style="height: 8vh;">
                                  <div class="w3-third padreizquierda">
                                    <div class="w3-row hijoizquierda w3-titulo-contenidoRentabilidad ">
                                        <mat-icon class="material-icons-outlined w3-xlarge" [ngStyle]="{'color':'#ff9012'}">thumb_down</mat-icon>
                                    </div>
                                  </div>
                                  <div class="w3-rest ">
                                    <div class="w3-row hijoRentabilidad_Reporte w3-total-cantidadRiesgos w3-balance-bluep" style="width: 98%;">
                                      <div class="w3-right w3-margin-right-niveles">
                                        {{formatearnormalCredito(total_arribadiagonalII)}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="w3-row" style="height: 6vh;">
                            <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                              <div class="hijoizquierdaTitle">
                                <span class="w3-margin-left-contenido">Análisis</span>
                              </div>
                            </div>
                          </div>
                          <div class="w3-row">
                            <mat-form-field class="w3-input">
                                <textarea id="txt_matriztransicionc" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 7" (opened)="setStep(7)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Cosechas por mora
                            </mat-panel-title>
                            <mat-panel-description>
                              Cosechas por mora
                              <mat-icon *ngIf="expand_cosechamora">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_cosechamora">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="w3-row">
                            <div class="w3-row" style="height: 6vh;">
                              <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                <div class="hijoizquierdaTitle">
                                  <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                  <span class="w3-margin-left-contenido">Cosechas por saldo</span>
                                </div>
                              </div>
                            </div>
                            <div class="w3-row" style="width: 100%;height: auto;">
                              <div id="grafica_cosechasmora"  class="w3-row" style="height: 54vh;width: 90%;" >
                                <ngx-charts-line-chart class="w3-medium"
                                  [scheme]="colorSchemeCosechas"
                                  [legend]="legendMora"
                                  [legendTitle]="legendTitle"
                                  [legendPosition]="legendPosition"
                                  [showXAxisLabel]="showXAxisLabel"
                                  [showYAxisLabel]="showYAxisLabel"
                                  [xAxis]="xAxis"
                                  [yAxis]="yAxis"
                                  [xAxisLabel]="xAxisLabelMora"
                                  [yAxisLabel]="yAxisLabelMora"
                                  [results]="dataBarrasMor"
                                  [curve]="curve"
                                  >
                                </ngx-charts-line-chart>
                              </div>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Interpretación de resultados</span>
                                  </div>
                                </div>
                              </div>
                              <div id="tabla_cosechasmora" class="w3-row w3-centered" style="height: auto;width: 100%;">     
                                <table mat-table [dataSource]="dataSourceMora" class="w3-table-all-reportes" style="height: auto;width: 90%;">
                                    <!-- Position Column -->
                                    <ng-container matColumnDef="cosecha">
                                    <th mat-header-cell *matHeaderCellDef class="w3-table-title w3-center" style="font-size: 0.8vw;"> Cosecha </th>
                                    <td mat-cell style="width: 7%;" *matCellDef="let element" class="" style="font-size: 0.8vw;"> {{element.cosecha}} </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="montocolocado">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> Monto colocado </th>
                                    <td mat-cell style="width: 9%; font-size: 0.8vw;" *matCellDef="let element" class="w3-light-grey w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.montocolocado}}</span>  </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes1">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-1]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,11)" [ngStyle]="{'color': colorText(element.mes1)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes1) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes1}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes1) && !porPorcent" style="margin-right: 1vh">{{element.mes1}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes2">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-2]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,10)" [ngStyle]="{'color': colorText(element.mes2)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes2) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes2}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes2) && !porPorcent" style="margin-right: 1vh">{{element.mes2}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes3">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-3]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,9)" [ngStyle]="{'color': colorText(element.mes3)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes3) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes3}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes3) && !porPorcent" style="margin-right: 1vh">{{element.mes3}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes4">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-4]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,8)" [ngStyle]="{'color': colorText(element.mes4)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes4) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes4}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes4) && !porPorcent" style="margin-right: 1vh">{{element.mes4}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes5">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-5]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,7)" [ngStyle]="{'color': colorText(element.mes5)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes5) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes5}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes5) && !porPorcent" style="margin-right: 1vh">{{element.mes5}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes6">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-6]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,6)" [ngStyle]="{'color': colorText(element.mes6)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes6) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes6}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes6) && !porPorcent" style="margin-right: 1vh">{{element.mes6}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes7">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-7]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,5)" [ngStyle]="{'color': colorText(element.mes7)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes7) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes7}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes7) && !porPorcent" style="margin-right: 1vh">{{element.mes7}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes8">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-8]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,4)" [ngStyle]="{'color': colorText(element.mes8)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes8) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes8}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes8) && !porPorcent" style="margin-right: 1vh">{{element.mes8}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes9">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-9]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,3)" [ngStyle]="{'color': colorText(element.mes9)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes9) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes9}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes9) && !porPorcent" style="margin-right: 1vh">{{element.mes9}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes10">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-10]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,2)" [ngStyle]="{'color': colorText(element.mes10)}" class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes10) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes10}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes10) && !porPorcent" style="margin-right: 1vh">{{element.mes10}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes11">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-11]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,1)"  [ngStyle]="{'color': colorText(element.mes11)}"class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes11) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes11}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes11) && !porPorcent" style="margin-right: 1vh">{{element.mes11}} </span> </td>
                                    </ng-container>
              
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="mes12">
                                    <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes[nombres_mes.length-12]}} </th>
                                    <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,0)"  [ngStyle]="{'color': colorText(element.mes12)}"class=" w3-border-grey2 w3-right-align">
                                      <span *ngIf="pintarPorcent(element.mes12) && porPorcent" style="margin-right: 1vh; font-weight: bold;">{{element.mes12}} %</span>
                                      <span *ngIf="pintarPorcent(element.mes12) && !porPorcent" style="margin-right: 1vh">{{element.mes12}} </span></td>
                                    </ng-container>
                                
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsmora;sticky: true;" class="w3-table-title"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsmora;let i = index" class="my-row "></tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="w3-row" style="height: 6vh;">
                            <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                              <div class="hijoizquierdaTitle">
                                <span class="w3-margin-left-contenido">Análisis</span>
                              </div>
                            </div>
                          </div>
                          <div class="w3-row">
                            <mat-form-field class="w3-input">
                                <textarea id="txt_cosechasmora" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 8" (opened)="setStep(8)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Cosechas por saldo
                            </mat-panel-title>
                            <mat-panel-description>
                              Cosechas por saldo
                              <mat-icon *ngIf="expand_cosechasaldo">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_cosechasaldo">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="w3-row">
                            <div class="w3-row" style="height: 6vh;">
                              <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu w3-border-bottom_cartera">
                                <div class="hijoizquierdaTitle">
                                  <!--<mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}"> multiline_chart</mat-icon>-->
                                  <span class="w3-margin-left-contenido">Cosechas por saldo</span>
                                </div>
                              </div>
                            </div>
                            <div class="w3-row" style="width: 100%;height: auto;">
                              <div id="grafica_cosechas"  class="w3-row" style="height: 54vh;width: 90%;" >
                                <ngx-charts-line-chart
                                  [scheme]="colorSchemeCosechas"
                                  [legend]="legendCosechas"
                                  [legendTitle]="legendTitleCosechas"
                                  [legendPosition]="legendPositionCosechas"
                                  [showXAxisLabel]="showXAxisLabelCosechas"
                                  [showYAxisLabel]="showYAxisLabelCosechas"
                                  [xAxis]="xAxisCosechas"
                                  [yAxis]="yAxisCosechas"
                                  [xAxisLabel]="xAxisLabelCosechas"
                                  [yAxisLabel]="yAxisLabel2Cosechas"
                                  [results]="dataBarrasSaldCosechas"
                                  [curve]="curveCosechas"
                                  >
                                </ngx-charts-line-chart>
                              </div>
                              <div class="w3-row" style="height: 6vh;">
                                <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                                  <div class="hijoizquierdaTitle">
                                    <span class="w3-margin-left-contenido">Interpretación de resultados</span>
                                  </div>
                                </div>
                              </div>
                              <div id="tabla_cosechas" class="w3-row w3-centered" style="height: auto;width: 100%;">     
                                <table mat-table [dataSource]="dataSource2" class="w3-table-all-reportes" style="height: auto;width: 90%;">
                                  <!-- Position Column -->
                                  <ng-container matColumnDef="cosecha">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-table-title" style="font-size: 0.8vw;"> Cosecha </th>
                                  <td mat-cell style="width: 7%; font-size: 0.8vw;" *matCellDef="let element" class=""> {{element.cosecha}} </td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="montocolocado">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> Monto colocado </th>
                                  <td mat-cell style="width: 9%; font-size: 0.8vw;" *matCellDef="let element" class=" w3-light-grey w3-border-grey2 w3-right-align"> <span style="margin-right: 1vh">{{element.montocolocado}}</span> </td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes1">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-1]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,11)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes1}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes2">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-2]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,10)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes2}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes3">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-3]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,9)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes3}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes4">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-4]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,8)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes4}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes5">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-5]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,7)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes5}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes6">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-6]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,6)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes6}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes7">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-7]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,5)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes7}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes8">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-8]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,4)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes8}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes9">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-9]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,3)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes9}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes10">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-10]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,2)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes10}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes11">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-11]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,1)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes11}}</span></td>
                                  </ng-container>
          
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="mes12">
                                  <th mat-header-cell *matHeaderCellDef class="w3-center w3-border-grey2 w3-table-title" style="font-size: 0.8vw;"> {{nombres_mes2[nombres_mes2.length-12]}} </th>
                                  <td mat-cell style="width: 7%; font-size: 0.75vw;" *matCellDef="let element; let i = index" [class]="pintar(i,0)" class=" w3-border-grey2 w3-right-align"><span style="margin-right: 1vh">{{element.mes12}}</span></td>
                                  </ng-container>
                            
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns2;sticky: true;" class="w3-table-title"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns2;let i = index" class="my-row "></tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="w3-row" style="height: 6vh;">
                            <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                              <div class="hijoizquierdaTitle">
                                <span class="w3-margin-left-contenido">Análisis</span>
                              </div>
                            </div>
                          </div>
                          <div class="w3-row">
                            <mat-form-field class="w3-input">
                                <textarea id="txt_cosechas" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">Next</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 9" (opened)="setStep(9)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Conclusión
                            </mat-panel-title>
                            <mat-panel-description>
                              Conclusiones
                              <mat-icon *ngIf="expand_conclusion">keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="!expand_conclusion">keyboard_arrow_up</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <div class="w3-row" style="height: 6vh;">
                            <div class="w3-row hijoIzquierdaTitle w3-titulo-contenidomenu ">
                              <div class="hijoizquierdaTitle">
                                <span class="w3-margin-left-contenido">Conclusión</span>
                              </div>
                            </div>
                          </div>
                          <div class="w3-row">
                            <mat-form-field class="w3-input">
                                <textarea id="txt_conclusion" matInput placeholder="Escriba aquí sus comentarios" cols="40" rows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      
                          <mat-action-row>
                            <button mat-button color="warn" (click)="prevStep()">Previous</button>
                            <button mat-button color="primary" (click)="nextStep()">End</button>
                          </mat-action-row>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>
</div>
