import {MessageService} from '../base/message.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {Detalle_Evaluacion_CuentaEntity} from '../../entities/base/AlertaTemprana';
import {Loggers} from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class DetalleEvaluacionCuentaService extends Service<Detalle_Evaluacion_CuentaEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('PatmirService', 'alertatemprana/Detalle_Evaluacion_Cuenta', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: Detalle_Evaluacion_CuentaEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_Evaluacion) { params = params.set('Id_Evaluacion', entity.Id_Evaluacion.toString()); }
    if (null != entity.Id_Cuenta) { params = params.set('Id_Cuenta', entity.Id_Cuenta.toString()); }
    if (null != entity.Valor) { params = params.set('Valor', entity.Valor.toString()); }
    if (null != entity.ArregloID_evaluacion) { params = params.set('ArregloID_evaluacion', entity.ArregloID_evaluacion.toString()); }
    
    return params;
  }
}
