import { Injectable } from '@angular/core';
import {MessageService} from '../base/message.service';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {Loggers} from '../../util/app-util';
import {productoEntity} from './../../modules/riesgos/entities_riesgos/entities_riesgos';
@Injectable({
  providedIn: 'root'
})
export class ProductoService extends Service<productoEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers
  ) { 
    super('producto', '', http, messageService, logger);
      this.httpClient = http;
  }
  protected getParams(entity: productoEntity):HttpParams{
    let params: HttpParams = new HttpParams();
    if (null == entity){return params;}
    if (null != entity.Id_Producto){params = params.set('Id_Producto', entity.Id_Producto.toString())}

    return params;
  }
}