import { MessageService } from '../../services/base/message.service';
import { Injectable } from '@angular/core';
import { AnioMesSocio } from './../../entities/base/DashboardEntities';
import { from } from 'rxjs';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import { ThemeService } from 'ng2-charts';
export class ConstantesAfiliacion {
    constructor() {
    }
    public static strPATH = 'app';
    public static strDashboardPATH = 'app/dashboards';
    public static estadoT1C2 = true;
    public static rangoedadT1C2 = false;
    public static municipioT1C2 = false;
    public static localidadT1C2 = false;
    public static estadocivilT1C2 = false;
    public static ocupacionT1C2 = false;

    public static mesperiodoT6C2 = true;
    public static estadoT6C2 = false;
    public static rangoedadT6C2 = false;
    public static municipioT6C2 = false;
    public static localidadT6C2 = false;
    public static estadocivilT6C2 = false;
    public static ocupacionT6C2 = false;
    public static basediasmoraT6C2 = false;
    public static asesorcreditoT6C2 = false;
    public static clasificacioncreditoT6C2 = false;
    public static inicialT6C2 = false;

    public static ColumnasSocioCliente: string [] = ["SocioClienteID","Año","Mes","Nombre","Primer Apellido","Segundo Apellido","Sucursal"];
    
    static getRangoEdadT1C2(valor){
        this.rangoedadT1C2 = valor;
    }
    public static setRangoEdadT1C2(){
        return this.rangoedadT1C2;
    }
    static getMunicipioT1C2(valor){
        this.municipioT1C2 = valor;
    }
    public static setMunicipioT1C2(){
        return this.municipioT1C2;
    }
    static getLocalidadT1C2(valor){
        this.localidadT1C2 = valor;
    }
    public static setLocalidadT1C2(){
        return this.localidadT1C2;
    }
    static getEstadoCivilT1C2(valor){
        this.estadocivilT1C2 = valor;
    }
    public static setEstadoCivilT1C2(){
        return this.estadocivilT1C2;
    }
    static getOcupacionT1C2(valor){
        this.ocupacionT1C2 = valor;
    }
    public static setOcupacionT1C2(){
        return this.ocupacionT1C2;
    }
    public static setEstadoT1C2(){
        return  this.estadoT1C2;
    }
    static getEstadoT1C2(valor){
        this.estadoT1C2 = valor;
    }
    public static reiniciarT1C2(){
        this.getEstadoCivilT1C2(false);
        this.getMunicipioT1C2(false);
        this.getLocalidadT1C2(false);
        this.getRangoEdadT1C2(false);
        this.getEstadoCivilT1C2(false);
        this.getOcupacionT1C2(false);
        this.getEstadoT1C2(false);
    }

    static getRangoEdadT6C2(valor){
        this.rangoedadT6C2 = valor;
    }
    public static setRangoEdadT6C2(){
        return this.rangoedadT6C2;
    }
    static getMunicipioT6C2(valor){
        this.municipioT6C2 = valor;
    }
    public static setMunicipioT6C2(){
        return this.municipioT6C2;
    }
    static getLocalidadT6C2(valor){
        this.localidadT6C2 = valor;
    }
    public static setLocalidadT6C2(){
        return this.localidadT6C2;
    }
    static getEstadoCivilT6C2(valor){
        this.estadocivilT6C2 = valor;
    }
    public static setEstadoCivilT6C2(){
        return this.estadocivilT6C2;
    }
    static getOcupacionT6C2(valor){
        this.ocupacionT6C2 = valor;
    }
    public static setOcupacionT6C2(){
        return this.ocupacionT6C2;
    }
    public static setEstadoT6C2(){
        return  this.estadoT6C2;
    }
    static getEstadoT6C2(valor){
        this.estadoT6C2 = valor;
    }
    public static setBaseDiasMoraT6C2(){
        return  this.basediasmoraT6C2;
    }
    static getBaseDiasMoraT6C2(valor){
        this.basediasmoraT6C2 = valor;
    }
    public static setAsesorCreditoT6C2(){
        return  this.asesorcreditoT6C2;
    }
    static getAsesorCreditoT6C2(valor){
        this.asesorcreditoT6C2 = valor;
    }
    public static setMesPeriodoT6C2(){
        return  this.mesperiodoT6C2;
    }
    static getMesPeriodoT6C2(valor){
        this.mesperiodoT6C2 = valor;
    }
    public static setClasificacionCreditoT6C2(){
        return  this.clasificacioncreditoT6C2;
    }
    static getClasificacionCreditoT6C2(valor){
        this.clasificacioncreditoT6C2 = valor;
    }
    public static setInicialT6C2(){
        return  this.inicialT6C2;
    }
    static getInicialT6C2(valor){
        this.inicialT6C2 = valor;
    }
    public static reiniciarT6C2(){
        this.getEstadoCivilT6C2(false);
        this.getMunicipioT6C2(false);
        this.getLocalidadT6C2(false);
        this.getRangoEdadT6C2(false);
        this.getEstadoCivilT6C2(false);
        this.getOcupacionT6C2(false);
        this.getEstadoT6C2(false);
        this.getMesPeriodoT6C2(false);
        this.getBaseDiasMoraT6C2(false);
        this.getAsesorCreditoT6C2(false);
        this.getClasificacionCreditoT6C2(false);
        this.getInicialT6C2(false);
    }
}

export class AfiliacionVariablesConstantes {
    static get MesesArr() { return ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']; }

    static get MesesJson() { return [
        { mes: 'ene', value: 1 },
        { mes: 'feb', value: 2 },
        { mes: 'mar', value: 3 },
        { mes: 'abr', value: 4 },
        { mes: 'may', value: 5 },
        { mes: 'jun', value: 6 },
        { mes: 'jul', value: 7 },
        { mes: 'ago', value: 8 },
        { mes: 'sep', value: 9 },
        { mes: 'oct', value: 10 },
        { mes: 'nov', value: 11 },
        { mes: 'dic', value: 12 },
    ]; }

    public static EstadosMX: any [] = [];
    public static setEstadosMX(){
        return  this.EstadosMX;
    }
    static getEstadosMX(valor){
        this.EstadosMX = valor;
    }
}
export class Elemento{
    name: string;
    value: number;
}

export class FuncionesAfiliacion {
    static separador:string =  ","; // separador para los miles
    static sepDecimal:string = '.'; // separador para los decimales
    static arreglo:string [] = [];
    static resultEncabezados: any [] = [];
    static formatearsaldos(num){
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return  splitLeft +splitRight;
    }
    static formatearnormal(num){
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return  splitLeft +splitRight;
    }
    static impresiones(){
        //console.log(ConstantesAfiliacion.setEstadoT1C2() + " " + ConstantesAfiliacion.setRangoEdadT1C2() + " " + ConstantesAfiliacion.setMunicipioT1C2() + " " + ConstantesAfiliacion.setLocalidadT1C2() + " " + ConstantesAfiliacion.setEstadoCivilT1C2() + " " + ConstantesAfiliacion.setOcupacionT1C2());
    }
    static impresionesT6(){
        //console.log(ConstantesAfiliacion.setEstadoT6C2() + " " + ConstantesAfiliacion.setRangoEdadT6C2() + " " + ConstantesAfiliacion.setMunicipioT6C2() + " " + ConstantesAfiliacion.setLocalidadT6C2() + " " + ConstantesAfiliacion.setEstadoCivilT6C2() + " " + ConstantesAfiliacion.setOcupacionT6C2());
    }
    static nombreproductos(cadena){
        this.arreglo = [];
        for(var i = 0; i < cadena[0].series.length; i++){
            this.arreglo.push(cadena[0].series[i].name);
        }
        return this.arreglo;
    }

    static getActivoIcon(bnActivo: boolean) {
        return (null == bnActivo) ? 'more_horiz'
              : bnActivo ? 'done' : 'block';
    }

    static getBnFromString(strValue: string): boolean {
        return (
                null == strValue
                || undefined === strValue
                || 'false' === strValue.toLowerCase().trim()
                || '0' === strValue.toLowerCase().trim()
              ) ? false : true;
    }
    static Encabezados(arreglo){
        this.resultEncabezados = [];
        var array = [];
        var array1 = "";
        for(var i = 0; i < 1; i++){
            array1 = "Año";
            for(var j = 0; j < arreglo[i].series.length; j++){
                //this.resultEncabezados.push({"name":arreglo[i].series[j].name});
                array.push({"name":arreglo[i].series[j].name});
            }
        }
        this.resultEncabezados.push({"name":array1,"series":array});
        return this.resultEncabezados;

    }

    static BuscarAsesoresCreditos(arreglo,codigo){
        var name = "";
        for(var i = 0; i < arreglo.length; i++){
            if(codigo == arreglo[i].AsesorCreditoId){
                name = arreglo[i].Nombre;
            }
        }
        return name;
    }

    static OrdenarMayorMenor(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value < arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    static OrdenarMenorMayor(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value > arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    static OrdenarMayorMenorNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value < arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"value":arreglo_aux[i].value});
            }
        }
        return arreglo;
    }
    static OrdenarMenorMayorNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value > arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"value":arreglo_aux[i].value});
            }
        }
        return arreglo;
    }
    static OrdenarAleatorio(arreglo){

        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value > arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    static OrdenarMayorMenorNombre(arreglo,arreglosucursales){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(this.BuscarSucursal(arreglo[j].name,arreglosucursales) > this.BuscarSucursal(arreglo[j + 1].name,arreglosucursales)){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }

        return arreglo;
    }
    static OrdenarMenorMayorNombre(arreglo,arreglosucursales){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(this.BuscarSucursal(arreglo[j].name,arreglosucursales) < this.BuscarSucursal(arreglo[j + 1].name,arreglosucursales)){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }

        return arreglo;
    }
    static BuscarSucursal(name, arreglo){
        var retorno = "";
        for(var i = 0; i < arreglo.length;i ++){
        if(arreglo[i].dbId == name){
            retorno = arreglo[i].strName;
        }
        }
        return retorno;
    }
}

export class FuncionesMes{
    static f_switchmes(mes){
        switch(mes){
            case '01': mes = 'enero';break;
            case '02': mes = 'febrero';break;
            case '03': mes = 'marzo';break;
            case '04': mes = 'abril';break;
            case '05': mes = 'mayo';break;
            case '06': mes = 'junio';break;
            case '07': mes = 'julio';break;
            case '08': mes = 'agosto';break;
            case '09': mes = 'septiembre';break;
            case '10': mes = 'octubre';break;
            case '11': mes = 'noviembre';break;
            case '12': mes = 'diciembre';break;
        }
        return mes;
    }
    static f_switchmes2(mes){
        switch(mes){
            case '01': mes = 'ene';break;
            case '02': mes = 'feb';break;
            case '03': mes = 'mar';break;
            case '04': mes = 'abr';break;
            case '05': mes = 'may';break;
            case '06': mes = 'jun';break;
            case '07': mes = 'jul';break;
            case '08': mes = 'ago';break;
            case '09': mes = 'sep';break;
            case '10': mes = 'oct';break;
            case '11': mes = 'nov';break;
            case '12': mes = 'dic';break;
        }
        return mes;
    }
    public static f_switchmes3(mes){
        switch(mes){
            case 'ene': mes = '01';break;
            case 'feb': mes = '02';break;
            case 'mar': mes = '03';break;
            case 'abr': mes = '04';break;
            case 'may': mes = '05';break;
            case 'jun': mes = '06';break;
            case 'jul': mes = '07';break;
            case 'ago': mes = '08';break;
            case 'sep': mes = '09';break;
            case 'oct': mes = '10';break;
            case 'nov': mes = '11';break;
            case 'dic': mes = '12';break;
        }
        return mes;
    }
    static f_switchmesi(mes){
        switch(mes){
            case 'ENERO': mes = '01';break;
            case 'FEBRERO': mes = '02';break;
            case 'MARZO': mes = '03';break;
            case 'ABRIL': mes = '04';break;
            case 'MAYO': mes = '05';break;
            case 'JUNIO': mes = '06';break;
            case 'JULIO': mes = '07';break;
            case 'AGOSTO': mes = '08';break;
            case 'SEPTIEMBRE': mes = '09';break;
            case 'OCTUBRE': mes = '10';break;
            case 'NOVIEMBRE': mes = '11';break;
            case 'DICIEMBRE': mes = '12';break;
        }
        return mes;
    }
    static obteneranios(arreglo){
        var cadena = "";
        for(var i = 0; i < arreglo.length; i++){
            if(i == 0){
                cadena = arreglo[i].name;
            }else{
                cadena = cadena + " . " + arreglo[i].name;
            }
        }
        return cadena;
    }
}
export class FuncionesCadenaAnioMes{
    static funcion_arregloanio(arreglo){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < arreglo.length; i ++){
          if(arreglo[i].dbCheck){
                if(contador == 0){
                cadena = arreglo[i].strName;
                }else{
                cadena = cadena + "  .  " + arreglo[i].strName;
                }
                contador ++;
            }
        }
        return cadena;
    }
    static funcion_arreglomes(arreglo){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < arreglo.length; i ++){
            if(arreglo[i].dbCheck){
                if(contador == 0){
                    cadena = arreglo[i].MesDscAbre;;
                }else{
                    cadena = cadena + " . " + arreglo[i].MesDscAbre;
                }
                contador ++;
            }
        } 
        return cadena;
    }
    static ContruirArregloMes(arreglo){
        var cadena = [];
        for(var i = 0; i < arreglo.length; i++){
            for(var j = 0; j < arreglo[i].arrSeries.length; j++){
                if(!this.existemes(cadena,arreglo[i].arrSeries[j].strName)){
                    cadena.push({'MesId': arreglo[i].arrSeries[j].strName, 'MesDsc': FuncionesMes.f_switchmes(arreglo[i].arrSeries[j].strName),'dbCheck':false,'MesDscAbre':FuncionesMes.f_switchmes2(arreglo[i].arrSeries[j].strName)});
                }
            }
        }
        cadena[cadena.length - 1].dbCheck = true;
        return cadena;
    }
    static existemes(arreglo,mes){
        var flag = false;
        for(var i = 0; i < arreglo.length; i ++){
            if(arreglo[i].MesId == mes){
                flag = true;
            }
        }
        return flag;
    }
}
export class FuncionesArreglos{
    static data_volumenes: any [] = [];
    static data_volumenesExpandir: any [] = [];
    static data_volumenes_aux: any [] = [];
    public static data_volumenesBar: any [] = [];
    static CaptacionColocacion(captacion,colocacion){
        this.CaptacionColocacionExpandir(captacion,colocacion);
        /*Primero agregar captacion*/
        this.data_volumenes = [];
        for(var i = 0; i < captacion.length;i++){
            this.data_volumenes.push({"name":captacion[i].name,"series":[{"name":"captacion","value":captacion[i].value}]});
        }
        var position = -1;
        for(var i = 0; i < this.data_volumenes.length;i++){
            position = this.exiteaniovolumen(colocacion,this.data_volumenes[i].name);
            if(position != -1){
            this.data_volumenes[i].series.push({"name":"colocacion","value":colocacion[position].value});
            }else{
            this.data_volumenes[i].series.push({"name":"colocacion","value":0});
            }
        }
        return this.data_volumenes;
    }
    static CaptacionColocacionExpandir(captacion,colocacion){
        this.data_volumenesExpandir = [];
        if(colocacion.length == captacion.length){
            for(var i = 0; i < captacion.length;i++){
                this.data_volumenesExpandir.push({"name":captacion[i].name,"captacion":captacion[i].value,"colocacion":colocacion[i].value,"series":[]});
            }
        }
        return this.data_volumenesExpandir;
    }
    static CaptacionColocacionAnioMesExpandir(captacion,colocacion){
        this.data_volumenesExpandir = [];
        var data_aux = [];
        console.log("Captación ---> " + JSON.stringify(captacion) + "\nColocación ---> " + JSON.stringify(colocacion));
        if(captacion.length == 0){
            if(colocacion.length == 0){
                this.data_volumenesExpandir = [{"name":"SD","check":true,"captacion":0,"colocacion":0,"series":[{"name":"","captacion":0,"colocacion":0}]}];
            }else{
                for(var i = 0; i < colocacion.length;i++){
                    for(var j = 0; j < colocacion[i].series.length;j++){
                        data_aux.push({"name":colocacion[i].series[j].name,"captacion":0,"colocacion":colocacion[i].series[j].value});
                    }
                    console.log(" ----> " + JSON.stringify(colocacion[i].series) + 
                                " length --> " + colocacion.length + 
                                " captacion ---> " + captacion.length);
                    this.data_volumenesExpandir.push({"name":colocacion[i].name,"check":true,"captacion":0,"colocacion":colocacion[i].series[colocacion[i].series.length - 1].value,"series":data_aux});
                    data_aux = [];
                }
                console.log("data_volumenesExpandir ---> " + JSON.stringify(this.data_volumenesExpandir));
            }
        }else{
            for(var i = 0; i < captacion.length;i++){
                for(var j = 0; j < captacion[i].series.length;j++){
                    data_aux.push({"name":captacion[i].series[j].name,"captacion":captacion[i].series[j].value,"colocacion":colocacion[i].series[j].value});
                }
                console.log(" ----> " + JSON.stringify(captacion[i].series) + 
                            " length --> " + colocacion.length + 
                            " captacion ---> " + captacion.length);
                this.data_volumenesExpandir.push({"name":captacion[i].name,"check":true,"captacion":captacion[i].series[captacion[i].series.length - 1].value,"colocacion":colocacion[i].series[colocacion[i].series.length - 1].value,"series":data_aux});
                data_aux = [];
            }
        }
        //console.log("volumanesExpandir ---> " + JSON.stringify(this.data_volumenesExpandir));
        return this.data_volumenesExpandir;
    }

    static CaptacionColocacionAux(captacion,colocacion){
        /*Primero agregar captacion*/
        this.data_volumenes_aux = [];
        var aux = [];
        for(var i = 0; i < captacion.length;i++){
            aux.push({"name":captacion[i].name,"value":captacion[i].value});
        }
        this.data_volumenes_aux.push({"name":"Captación","series":aux});
        aux = [];
        for(var i = 0; i < colocacion.length;i++){
            aux.push({"name":colocacion[i].name,"value":colocacion[i].value});
        }
        this.data_volumenes_aux.push({"name":"Colocación","series":aux});
        return this.data_volumenes_aux;
    }
    public static CaptacionColocacionAuxBar(captacion,colocacion){
        /*Primero agregar captacion*/
        this.data_volumenesBar = [];
        var aux = [];
        console.log("Captación ---> " + JSON.stringify(captacion) + "\nColocación ---> " + JSON.stringify(colocacion));
        for(var i = 0; i < captacion.length;i++){
            this.data_volumenesBar.push({"name":captacion[i].name,"series":[{"name":"captacion","value":captacion[i].value},{"name":"colocacion","value":this.BuscarColocacion(captacion[i].name,colocacion)}]});
        }
        return this.data_volumenesBar;
    }
    public static BuscarColocacion(anio,colocacion){
        var value = 0;
        for(var i = 0; i < colocacion.length; i++){
            if(colocacion[i].name == anio){
                value = colocacion[i].value;
            }
        }
        return value;
    }
    static exiteaniovolumen(colocacion,anio){
        var position = -1;
        for(var i = 0; i < colocacion.length; i++){
          if(colocacion[i].name == anio){
            position = i;
          }
        }
        return position;
      }
    static CaptacionColocacionAnioMes(captacion,colocacion){
        this.data_volumenes = [];
        for(var i = 0; i < captacion.length;i++){
            for(var j = 0; j < captacion[i].series.length;j++){
                this.data_volumenes.push({"name":captacion[i].name,"series":[{"name":"captacion","name2":captacion[i].series[j].name,"value":captacion[i].series[j].value},{"name":"colocacion","name2":captacion[i].series[j].name,"value":colocacion[i].series[j].value}]});
            }
        }
        return this.data_volumenes;
    }
    static SubtituloT1C2(arreglo){
        var cadena = "";
        for(var i = 0; i < arreglo.length;i++){
            if(i == 0){
                cadena = arreglo[i].name;
            }else{
                cadena = cadena + " . " + arreglo[i].name;
            }
        }
        return cadena;
    }
    static CaptacionColocacionCSV(arreglo){
        var retorno = [];
        var aux = [];
        for(var i = 0; i < arreglo.length; i ++){
            for(var j = 0; j < arreglo[i].series.length; j++){
                aux.push(arreglo[i].name);
                aux.push(arreglo[i].series[j].name);
                aux.push("$ "+ FuncionesAfiliacion.formatearsaldos(arreglo[i].series[j].captacion.toFixed(0)));
                aux.push("$ "+ FuncionesAfiliacion.formatearsaldos(arreglo[i].series[j].colocacion.toFixed(0)));
                retorno.push(aux);
                aux = [];
            }
        }
        return retorno;
    }
    static CaptacionColocacionCSVMes(arreglo){
        console.log("ExportarCSV CaptacionColocacion ---> " + JSON.stringify(arreglo));
        var retorno = [];
        var aux = [];
        for(var i = 0; i < arreglo.length; i ++){
            aux.push(arreglo[i].name);
            console.log("ExportarCSV CaptacionColocacion ---> " + JSON.stringify(arreglo[i].series));
            for(var k = 0; k < arreglo[i].series.length;k++){
                aux.push(arreglo[i].series[k].name);
                aux.push("$ "+ FuncionesAfiliacion.formatearsaldos(arreglo[i].series[k].captacion));
                aux.push("$ "+ FuncionesAfiliacion.formatearsaldos(arreglo[i].series[k].colocacion));
            }
            retorno.push(aux);
            aux = [];
        }
        console.log("ExportarCSV CaptacionColocacion ---> " + JSON.stringify(retorno));
        return retorno;
    }
    static ColocacionVigenteVencido(arreglo){

        console.log("CSV ColocacionVigenteVencido ---> " + JSON.stringify(arreglo));
        /*
            [{"name":"VC",
                "series":[{"name":"2017","x":"2017","y":6466184.36,"r":0.3230000559076426},
                          {"name":"2018","x":"2018","y":6466184.36,"r":0.3230000559076426},
                          {"name":"2019","x":"2019","y":6466184.36,"r":0.3230000559076426}]},
            {"name":"VG",
                "series":[{"name":"2017","x":"2017","y":1995448131.67,"r":99.67699994409236},
                          {"name":"2018","x":"2018","y":1995448131.67,"r":99.67699994409236},
                          {"name":"2019","x":"2019","y":1995448131.67,"r":99.67699994409236}]}]

            
            ["Año","Cápital Vencido","Cápital Vigente"]
        */
        var retorno = [];
        var aux = [];
        for(var i = 0; i < arreglo.length;i++){
            for(var j = 0; j < arreglo[i].series.length;j++){
                if(!this.BuscarRetorno(arreglo[i].series[j].name,retorno)){
                    aux.push(arreglo[i].series[j].name);
                    aux.push("$ "+ FuncionesAfiliacion.formatearsaldos(this.ElementoVencido(arreglo,arreglo[i].series[j].name,"VC")));
                    aux.push("$ "+ FuncionesAfiliacion.formatearsaldos(this.ElementoVencido(arreglo,arreglo[i].series[j].name,"VG")));
                    retorno.push(aux);
                    aux = [];
                }
            }
        }
        console.log("RetornoCadena ---> " + JSON.stringify(retorno));
        /*for(var i = 0; i < arreglo.length; i ++){
            aux.push(arreglo[i].name);
            for(var j = 0; j < arreglo[i].series.length; j++){
                aux.push("$ "+ FuncionesAfiliacion.formatearsaldos(arreglo[i].series[j].value.toFixed(0)));
            }
            retorno.push(aux);
            aux = [];
        }*/
        return retorno;
    }
    public static BuscarRetorno(ele,arreglo){
        var flag = false;
        console.log("Arreglo ---> " + JSON.stringify(arreglo));
        for(var i = 0; i < arreglo.length;i++){
            console.log("---> " + JSON.stringify(arreglo[i]) + "  ---> " + arreglo[i].includes(ele) + " Elemento ---> " + ele);
            flag = arreglo[i].includes(ele);
            if(flag)
                break;
        }

        return flag;
    }
    public static ElementoVencido(arreglo,anio,status){
        var ele = 0;
        for(var i = 0; i < arreglo.length;i++){
            if(arreglo[i].name == status){
                for(var j = 0; j < arreglo[i].series.length;j++){
                    if(anio == arreglo[i].series[j].name){
                        ele = arreglo[i].series[j].y;
                    }
                }
            }
        }
        return ele;
    }

    public static Activos: number = 0;
    public static Inactivos: number = 0;
    public static TotalSocios: number = 0;
    public static NivelActividad: any [] = [];

    public static setActivos(val){
        this.Activos = val;
    }
    public static getActivos(){
        return this.Activos;
    }
    public static setInactivos(val){
        this.Inactivos = val;
    }
    public static getInactivos(){
        return this.Inactivos;
    }
    public static setTotalSocios(val){
        this.TotalSocios = val;
    }
    public static getTotalSocios(){
        return this.TotalSocios;
    }
    public static setNivelActividad(val){
        this.NivelActividad = val;
    }
    public static getNivelActividad(){
        return this.NivelActividad;
    }


    public static ObtenerNivelActividad(colocacion,captacion,sociototal){
        this.setActivos(0);
        this.setInactivos(0);
        this.setTotalSocios(0);
        this.setNivelActividad([]);
        for(var i = 0; i < colocacion.length; i++){
            this.setActivos(this.getActivos() + colocacion[i].value);
        }
        for(var i = 0; i < captacion.length; i++){
            this.setActivos(this.getActivos() + captacion[i].value);
        }
        for(var i = 0; i < sociototal.length; i++){
            this.setTotalSocios(this.getTotalSocios() + sociototal[i].value);
        }
        this.NivelActividad.push({"name":"Activos","value":this.getActivos()},{"name":"Inactivos","value":this.getTotalSocios() - this.getActivos()});
        return this.getNivelActividad();
    }
    public static ConstruirNivelActividad(activos,sociototal){
        this.setNivelActividad([]);
        console.log("Activos ---> " + JSON.stringify(activos) + " - " + activos.length + " " + "\nTotalSocios --> " + JSON.stringify(sociototal));
        var act = 0;
        if(activos.length == 0){
            act = 0;
        }else{
            act = activos[0].value;
        }

        this.NivelActividad.push({"name":"Activos","value":act},{"name":"Inactivos","value":sociototal[0].value - act});
        return this.getNivelActividad();
    }
    public static ObtenerNivelActividadAnios(colocacion,captacion,sociototal){
        /*this.setActivos(0);
        this.setInactivos(0);
        this.setTotalSocios(0);
        this.setNivelActividad([]);*/
        this.NivelActividad = [];
        var activos = 0;
        var inactivos = 0;
        var totalsocios = 0;
        for(var i = 0; i < colocacion[0].series.length;i++){
            activos = this.AnioColocacion(colocacion,colocacion[0].series[i].name) + this.AnioColocacion(captacion,colocacion[0].series[i].name);
            inactivos = this.AnioSocio(sociototal,colocacion[0].series[i].name);
            this.NivelActividad.push({"name":colocacion[0].series[i].name,"series":[{"name":"Activo","value":activos},{"name":"Inactivo","value":inactivos - activos}]});
        }
        console.log("NivelActiviad ---> " + JSON.stringify(this.NivelActividad));
        return this.NivelActividad;
    }
    public static ConstruirNivelActividadAnios(activos,sociototal){
        this.NivelActividad = [];
        for(var i = 0; i < sociototal.length;i++){
            if(this.existeActivos(sociototal[i].name,activos)){
                this.NivelActividad.push({  "name":sociototal[i].name,
                                            "series":[  {"name":"Activo","value":this.BuscarActivos(sociototal[i].name,activos)},
                                                        {"name":"Inactivo","value":sociototal[i].value - this.BuscarActivos(sociototal[i].name,activos)}]});
                console.log("NivelActividad ActivoInactivo ---> " + sociototal[i].value + " ---> " + this.BuscarActivos(sociototal[i].name,activos));
            }else{
                this.NivelActividad.push({  "name":sociototal[i].name,
                                            "series":[  {"name":"Activo","value":0},
                                                        {"name":"Inactivo","value":sociototal[i].value}]});
            }
        }
        return this.NivelActividad;
    }
    public static ConstruirNivelActividadMeses(activos,sociototal){
        console.log("Activos ---> " + JSON.stringify(activos) + "\nTotalSocios ---> " + JSON.stringify(sociototal));
        this.NivelActividad = [];
        for(var i = 0; i < sociototal.length;i++){
            if(this.existeActivos(sociototal[i].name,activos)){
                this.NivelActividad.push({  "name":sociototal[i].name,
                                            "series":[  {"name":"Activo","value":this.BuscarActivos(sociototal[i].name,activos)},
                                                        {"name":"Inactivo","value":sociototal[i].value - this.BuscarActivos(sociototal[i].name,activos)}]});
            }else{
                console.log("Verficando ---> " + activos.includes(sociototal[i].name) + " " + sociototal[i].name);
                this.NivelActividad.push({  "name":sociototal[i].name,
                                            "series":[  {"name":"Activo","value":0},
                                                        {"name":"Inactivo","value":sociototal[i].value}]});
            }
        }
        console.log("NivelActividadMeses -- >" + JSON.stringify(this.NivelActividad));
        return this.NivelActividad;  
    }
    public static existeActivos(name,activos){
        var flag = false;
        for(var i = 0; i < activos.length;i++){
            if(activos[i].name == name){
                flag = true;
            }
        }
        return flag;
    }
    public static BuscarActivos(name,activos){
        var num = 0;
        if(this.existeActivos(name,activos)){
            for(var i = 0; i < activos.length;i++){
                if(activos[i].name == name){
                    num = activos[i].value;
                }
            }
            return num;
        }else{
            return 0;
        }

    }
    public static AnioSocio(socio,anio){
        var col = 0; 
        for(var i = 0; i < socio.length;i++){
            if(socio[i].name == anio){
                col = socio[i].value;
            }
        }
        return col;
    }
    public static AnioColocacion(colocacion,anio){
        var col = 0;
        for(var i = 0; i < colocacion[0].series.length;i++){
            if(colocacion[0].series[i].name == anio){
                col = colocacion[0].series[i].value;
            }
        }
        return col;
    }
}
export class FuncionesGenero{
    static fswicthgenero(genero){
        switch(genero){
            case 'F':
                genero = 'FEMENINO';
            break;
            case 'M':
                genero = 'MASCULINO';
            break;
        }
        return genero;
    }
    static fswicthgenero2(genero){
        switch(genero){
            case 'FEMENINO':
                genero = 'F';
            break;
            case 'MASCULINO':
                genero = 'M';
            break;
        }
        return genero;
    }
}
export class FuncionesBubble{
    static bubble: any [] = [];
    static ActivosInactivos:  any [] = [];
    static anios: any [] = [];
    static colorAleatorio: any;
    public static colorComboBar: any;
    static getBubble(bubble){
        this.bubble = bubble;
    }
    static setBubble(){
        return this.bubble;
    }
    static reiniciarBubble(){
        this.bubble = [];
        this.getBubble(this.bubble)
    }
    static suma(arreglo,variable){
        var suma = 0; 
        for(var i = 0; i < arreglo.length;i++){
            if(arreglo[i].name == variable){
                for(var j = 0; j < arreglo[i].series.length;j++){
                    suma += arreglo[i].series[j].value;
                }
            }
        }
        return suma;
    }
    static crearBubble(arreglo){
        this.reiniciarBubble();
        var aux = [];
        var vg = 0, vc = 0, total = 0,radio = 0;
        //console.log("burbuja ---> " + JSON.stringify(arreglo));
        for(var i = 0; i < arreglo.length;i++){
            for(var j = 0; j < arreglo[i].series.length; j++){
                if(arreglo[i].name == "VC"){
                    vc += arreglo[i].series[j].value;
                }else{
                    vg += arreglo[i].series[j].value;
                }
            }
        }
        total = vc + vg;
        for(var i = 0; i < arreglo.length;i++){
            aux = [];
            for(var j = 0; j < arreglo[i].series.length; j++){
                radio = arreglo[i].series[j].value*100/this.sumAnioBubble(arreglo,arreglo[i].series[j].name);
                aux.push({"name":arreglo[i].series[j].name,"x":arreglo[i].series[j].name,"y":arreglo[i].series[j].value,"r":radio});
            }
            this.bubble.push({"name":arreglo[i].name,"series":aux});
        }
        //console.log("Burbuja ---> " + JSON.stringify(this.bubble) + " ---> " );
        return this.bubble;
    }
    public static sumAnioBubble(arreglo,anio){
        var sum= 0;
        
        for(var i = 0; i < arreglo.length;i++){
            console.log("---> " + JSON.stringify(arreglo[i]));
            for(var j = 0; j < arreglo[i].series.length;j++){ 
                //console.log("   ---> " + JSON.stringify(arreglo[i].series[j]));
                if(anio == arreglo[i].series[j].name){
                    sum += arreglo[i].series[j].value;
                }
            }
        }
        //console.log(" ArregloAnio Bubble ---> " + JSON.stringify(arreglo) + " anio ---> " + anio + "\n Suma ---> " + sum)
        return sum;

    }
    static organizarActivosInactivos(arreglo){
        for(var i = 0; i < arreglo.length; i++){
            for(var j = 0; j < arreglo[i].series.length;j++){
                arreglo[i].series[j].name = arreglo[i].name.concat("-"+arreglo[i].series[j].name);
            }
        }
        this.ActivosInactivos = arreglo;
        return this.ActivosInactivos;
    }
    static regresarAniosenBubble(){
        this.anios = [];
        for(var i = 0; i < this.setBubble().length; i++){
            for(var j = 0; j < this.setBubble()[i].series.length; j++){
                if(!this.existeanio(this.setBubble()[i].series[j].name)){
                    this.anios.push(this.setBubble()[i].series[j].name);
                }
            }
        }
        var cadena = "";
        for(var i = 0; i < this.anios.length; i ++){
            if(i == 0){
                cadena = this.anios[i];
            }else{
                cadena = cadena + " . " + this.anios[i];
            }
        }
        return cadena;
    }
    static existeanio(anio){
        var flag = false;
        for(var i = 0 ; i < this.anios.length; i++){
            if(this.anios[i] == anio){
                flag = true;
            }
        }
        return flag;
    }
    public static AleatorioColor(schemaColor){
        var dimension = schemaColor.domain.length;
        var cod = [];
        var ale = Math.round(Math.random()*dimension) - 1;
        for(var i = 0; i < 2;i++){
            ale = Math.round(Math.random()*dimension) - 1;
            console.log("Color Aleatorio --->  " + ale );
            while(ale <= 1){
                ale = Math.round(Math.random()*dimension) - 1;
            }
            while(cod.includes(schemaColor.domain[ale])){
                ale = Math.round(Math.random()*dimension) - 1;
            }
            console.log("ColorAleatorio ---> " + schemaColor.domain[ale]);
            cod.push(schemaColor.domain[ale]);
        }
        
        this.colorAleatorio = {"name":"gama1","selectable":true,"group":"Ordinal","domain":cod};
        //this.colorAleatorio = cod;
        this.colorComboBar =  {"name":"gama1","selectable":true,"group":"Ordinal","domain":[cod[0]]};
        console.log("ColorAleatorioBubble ---> " + JSON.stringify(this.colorComboBar) + 
                    "\n--> " + JSON.stringify(this.colorAleatorio));
        return this.colorAleatorio;
    }
    public static RegresarAnioNivelActividad(arreglo){
        var cadena = "";
        for(var i = 0; i < arreglo.length; i ++){
            if(i == 0){
                cadena = arreglo[i].name;
            }else{
                cadena = cadena + " . " + arreglo[i].name;
            }
        }
        return cadena;
    }
    public static DataHorizontal(data){
        var arreglo = [];
        var meses = [];
        for(var i = 0; i < data.length;i++){
            for(var j = 0; j < data[i].series.length;j++){
                if(!meses.includes(data[i].series[j].name)){
                    meses.push(data[i].series[j].name);
                }
            }
        }
        console.log("Meses ArregloBurbuja ---> " + meses);
        for(var i = 0; i < meses.length;i++){
            arreglo.push({"name":meses[i],
                          "series":[{"name":"VC","value":this.ValorDataHorizontal(meses[i],data,"VC")},
                                    {"name":"VG","value":this.ValorDataHorizontal(meses[i],data,"VG")}]});
        }
        console.log("Meses ArregloBurbuja ---> " + JSON.stringify(arreglo));
        return arreglo;        
    }
    public static ValorDataHorizontal(mes,arreglo,estatus){
        var num = 0;
        for(var i = 0; i < arreglo.length;i++){
            if(estatus == arreglo[i].name){
                for(var j = 0; j < arreglo[i].series.length;j++){
                    if(mes == arreglo[i].series[j].name){
                        num = arreglo[i].series[j].value;
                    }
                }
            }
        }
        return num;
    }
}
export class FuncionAgruparSucursales{
    static ultmes: string = "";
    static ultanio: string = "";
    static data_sucursales: any [] = [];
    public static data_sucursalesExportar: any [] = [];
    static reiniciarDaraSucursales(){
        this.data_sucursales = [];
    }
    static getDataSucursales(data){
        this.data_sucursales = data;
    }
    static setDataSucursales(){
        return this.data_sucursales;
    }
    static crearAgrupacionSucursales(arreglo){
        this.reiniciarDaraSucursales();
        var data_sucursales = FuncionesAfiliacion.OrdenarMayorMenor(arreglo);
        var suma = 0;
        for(var i = 0; i < data_sucursales.length; i ++){
            if(i < 5){
                this.data_sucursales.push({"name":data_sucursales[i].name,"value":data_sucursales[i].value});
            }else{
                suma = suma + data_sucursales[i].value;
            }
        }
        if(arreglo.length > 10){
            this.data_sucursales.push({"name":"Suc N","value":suma});
        }
        return this.data_sucursales;
    }
    public static CrearExportarSucursalesColocacion(arreglo,sucursales){
        this.data_sucursalesExportar = [];
        var data = FuncionesAfiliacion.OrdenarMayorMenor(arreglo);
        var pro = 0;
        for(var i = 0; i < arreglo.length;i++){
            pro += arreglo[i].value;
        }
        pro = pro/arreglo.length;
        for(var i = 0; i < arreglo.length;i++){
            var arr = [arreglo[i].name,this.buscarsucursal(sucursales,arreglo[i].name), FuncionesAfiliacion.formatearnormal(arreglo[i].value),(arreglo[i].value/pro).toFixed(2)];
            this.data_sucursalesExportar.push(arr);
            arr = [];
        }
        return this.data_sucursalesExportar;
    }
    public static buscarsucursal(arreglo,suc){
        var retorno = '';
        for(var i = 0; i < arreglo.length;i ++){
            if(arreglo[i].dbId == suc){
              retorno = arreglo[i].strName;
            }
        }
        return retorno;
    }
    static FuncionDatosRegresados(arreglo){
        var cadena = "";
        for(var i = 0; i < arreglo.length;i++){
            if(i == 0){
                cadena = arreglo[i].name;
            }else{
                cadena = cadena + " . " + arreglo[i].name;
            }
        }
        return cadena;
    }
    static FuncionDatosRegresadostrName(arreglo){
        var cadena = "";
        for(var i = 0; i < arreglo.length;i++){
            if(i == 0){
                cadena = arreglo[i].strName;
            }else{
                cadena = cadena + " . " + arreglo[i].strName;
            }
        }
        return cadena;
    }
    static FuncionPromedioMensualAfiliacion(arreglo){
        var prom = 0;
        for(var i = 0; i < arreglo.length; i++){
            prom = prom + arreglo[i].value;
        }
        return prom/arreglo.length; 
    }
    static informacion_datos: any [] = [];
    static getDataInformacionAnios(arreglo){
        this.informacion_datos = arreglo;
    }
    static setDataInformacionAnios(){
        return this.informacion_datos;
    }
    static informacion_datos_aux: any [] = [];
    static getDataInformacionAniosAux(arreglo){
        this.informacion_datos_aux = arreglo;
    }
    static setDataInformacionAniosAux(){
        return this.informacion_datos_aux;
    }
    static FuncionSeleccionarTodosAnios(){
        console.log("Seleccionando todos los años ");
        for(var i = 0; i < this.informacion_datos.length;i++){
            this.informacion_datos[i].dbCheck = true;
        }
    }
    static FuncionArregloAniosSeleccionados(){
        this.informacion_datos_aux = [];
        for(var i = 0; i < this.informacion_datos.length; i++){
            if(this.informacion_datos[i].dbCheck){
              this.informacion_datos_aux.push(this.informacion_datos[i]);
            }
          }
    }
    static FuncionArregloMesesSeleccionados(){
        this.data_arreglomesaux = [];
        for(var i = 0; i < this.data_arreglomes.length; i++){
            if(this.data_arreglomes[i].dbCheck){
              this.data_arreglomesaux.push(this.data_arreglomes[i]);
            }
        }
    }
    static data_arreglomesaux: any [] = [];
    static data_arreglomes: any [] = [];
    static getDataArregloMes(arreglo){
        this.data_arreglomes = arreglo;
    }
    static setDataArregloMes(){
        return this.data_arreglomes;
    }
    static getDataArregloMesAux(arreglo){
        this.data_arreglomesaux= arreglo;
    }
    static setDataArregloMesAux(){
        return this.data_arreglomesaux;
    }
    static FuncionSeleccionarUltMes(){
        this.data_arreglomesaux = [];
        this.data_arreglomes[this.data_arreglomes.length - 1].dbCheck = true;
        this.data_arreglomesaux.push(this.data_arreglomes[this.data_arreglomes.length - 1]);
        this.ultmes = this.data_arreglomes[this.data_arreglomes.length - 1].MesId;
    }
    static FuncionSeleccionarUltAnio(){
        this.informacion_datos_aux = [];
        this.informacion_datos[this.informacion_datos.length - 1].dbCheck = true;
        this.informacion_datos_aux.push(this.informacion_datos[this.informacion_datos.length - 1]);
        this.ultanio = this.informacion_datos[this.informacion_datos.length - 1].strName;
    }
    static FuncionLimpiarMeses(){
        for(var i = 0; i < this.data_arreglomes.length; i++){
            this.data_arreglomes[i].dbCheck = false;
        }
    }
    static FuncionLimpiarAnios(){
        for(var i = 0; i < this.informacion_datos.length; i++){
            this.informacion_datos[i].dbCheck = false;
        }
    }
    static FuncionSeleccionarAnio(anio){
        for(var i = 0; i < this.informacion_datos.length; i++){
            if(this.informacion_datos[i].strName == anio){
                this.informacion_datos[i].dbCheck = true;
            }
        }
    }
    static FuncionSeleccionarMes(mes){
        for(var i = 0; i < this.data_arreglomes.length; i++){
            if(this.data_arreglomes[i].MesId == mes){
                this.data_arreglomes[i].dbCheck = true;
            }
        }
    }
    static funcion_arregloanio(arreglo){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < arreglo.length; i ++){
          if(arreglo[i].dbCheck){
            if(contador == 0){
              cadena = arreglo[i].strName;
            }else{
              cadena = cadena + "  .  " + arreglo[i].strName;
            }
            contador ++;
          }
        }
        return cadena;
    }
    static funcion_arreglomes(arreglo){
        var cadena = "";
        var contador = 0;
        for(var i = 0; i < arreglo.length; i ++){
            if(arreglo[i].dbCheck){
                if(contador == 0){
                    cadena = arreglo[i].MesDscAbre;;
                }else{
                    cadena = cadena + " . " + arreglo[i].MesDscAbre;
                }
                contador ++;
            }
        }
        return cadena;
    }
    public static FuniconSeleccionarTodosAnios(){
        var cadena = "";
        for(var i = 0; i < this.informacion_datos.length; i ++){
            if(i == 0){
                cadena = this.informacion_datos[i].strName;
            }else{
                cadena = cadena + " . " + this.informacion_datos[i].strName;
            }
        }
        return cadena;
    }
    public static FuncionSelAllYears(){
        var cadena = "";
        for(var i = 0; i < this.informacion_datos.length; i ++){
            if(i == 0){
                cadena = "'" + this.informacion_datos[i].strName + "'";
            }else{
                cadena = cadena + ",'" + this.informacion_datos[i].strName + "'";
            }
        }
        return cadena;
    }
    static FuncionUltAnioSeleccionado(arreglo){
        var anio = "";
        for(var i = 0; i < arreglo.length; i ++){
            if(arreglo[i].dbCheck){
                anio = arreglo[i].strName;
            }
        }
        return anio;
    }
    public static FuncionUltMesSeleccionado(arreglo){
        var anio = "";
        for(var i = 0; i < arreglo.length; i ++){
            if(arreglo[i].dbCheck){
                anio = arreglo[i].MesId;
            }
        }
        return anio;
    }
    public static FuncionUltMes(){
        this.data_arreglomesaux = [];
        this.data_arreglomesaux.push(this.data_arreglomes[this.data_arreglomes.length - 1]);
        return this.data_arreglomes[this.data_arreglomes.length - 1 ].MesDscAbre;
    }
    public static FuncionArregloAnios(arreglo){
        var contador = 0; 
        var cadena = "";
        for(var i = 0; i < arreglo.length;i++){
            if(arreglo[i].dbCheck){
                if(contador == 0){
                    cadena = arreglo[i].strName;
                }else{
                    cadena = cadena + "," + arreglo[i].strName;
                }
                contador ++;
            }
        } 
        return cadena;
    }
    public static FuncionContadorAnios(arreglo){
        var contador = 0; 
        for(var i = 0; i < arreglo.length;i++){
            if(arreglo[i].dbCheck){
                contador ++;
            }
        } 
        return contador;
    }
}

export class FuncionesExportCSV{
    public static ArraySocioCliente: any [] = [];
    public static DimensionEncabezado: number = 0;
    public static Abecedario: string [] = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
    public static getDimensionEncabezado(value){
        this.DimensionEncabezado = value;
    }
    public static setDimensionEncabezado(){
        return this.DimensionEncabezado;
    }
    public static getArraySocioCliente(arreglo){
        this.ArraySocioCliente = arreglo;
    }
    public static setArraySocioCliente(){
        return this.ArraySocioCliente;
    }
    static ExportarCSVInspeccionarSocios(data_sociocliente){
        this.getArraySocioCliente([]);
        for(var i = 0; i < data_sociocliente.length; i++){
            this.ArraySocioCliente.push(this.PushArrraySocioCliente(data_sociocliente[i]));
        }
        this.getArraySocioCliente(this.ArraySocioCliente);
        return this.setArraySocioCliente();
    }
    static PushArrraySocioCliente(arreglo){
        var array = [];
        array.push(arreglo.socioclienteid);
        array.push(arreglo.anio);
        array.push(FuncionesMes.f_switchmes2(arreglo.mes));
        array.push(arreglo.nombre);
        array.push(arreglo.primerap);
        array.push(arreglo.segundoap);
        array.push(arreglo.sucursaldesc);
        return array;
    }
    static DimensionEncabezadoOrdenAlfabetico(encabezado){
        this.getDimensionEncabezado(encabezado.length);
        if(this.setDimensionEncabezado() <= 26){
            return this.Abecedario[this.setDimensionEncabezado() - 1];
        }
    }
    static ExportarCSVInspeccionarRegulatorio451(data_regulatorio){
        this.getArraySocioCliente([]);
        for(var i = 0; i < data_regulatorio.length; i++){
            this.ArraySocioCliente.push(this.PushArraySocioClienteRegulatorio451(data_regulatorio[i]));
        }
        this.getArraySocioCliente(this.ArraySocioCliente);
        return this.setArraySocioCliente();
    }
    static PushArraySocioClienteRegulatorio451(arreglo){
        var array = [];
        array.push(arreglo.anio);//1
        array.push(arreglo.ifid);//2
        array.push('R04 C-0451,');//3
        array.push(arreglo.municipiodesc);//4
        array.push(arreglo.estadodesc);//5
        array.push(arreglo.clavesociocliente);//6
        array.push(1);//7--> Fisica Moral Extranjera
        array.push(arreglo.nombre);//8
        array.push(arreglo.primerap);//9
        array.push(arreglo.segundoap);//10
        array.push("RFC");//11
        array.push("CURP");//12
        array.push("GENERO");//13
        array.push(arreglo.nocontrato);//14
        array.push(arreglo.sucursal);//15
        array.push(arreglo.clasificacion);//16
        array.push(arreglo.tipoproducto);//17
        array.push(arreglo.fechaotorgamiento);//18
        array.push(arreglo.fechavencimiento);//19
        array.push(arreglo.modalidadpago);//20
        array.push(arreglo.montooriginal);//21
        array.push(arreglo.frecpagocapital);//22
        array.push(arreglo.frecpagointereses);//23
        array.push(arreglo.tasaordinaria);//24
        array.push(arreglo.fechaultpagocapital);//25
        array.push(arreglo.montoultpagocapital);//26
        array.push(arreglo.fechaultpagointereses);//27
        array.push(arreglo.montoultpagointereses);//28
        array.push("");//29---> fecha primera amortizacion no cubierta
        array.push("");//30---> monto condonación
        array.push("");//31---> fecha condonacion
        array.push(arreglo.diasmora);//32---> dias mora
        array.push(arreglo.tipo);//33---> tipo de crédito
        array.push(arreglo.estatus);//34---> situacion contable del crédito
        array.push(arreglo.capitalvigente + arreglo.capitalvencido);//35---> capital
        array.push(arreglo.intdevnocobvig);//36---> intereses ordinarios
        array.push(arreglo.intdevnocobvenc);//37---> intereses moratorios
        array.push(0);//38---> intereses ordinarios vencidos fuera de balance
        array.push(0);//39---> intereses moratorios fuera de balance
        array.push();//40---> INTERESES REFINANCIADOS O INTERESES RECAPITALIZADOS
        array.push(arreglo.capitalvigente + arreglo.intdevnocobvig + arreglo.capitalvencido + arreglo.intdevnocobvenc);//41---> SALDO INSOLUTO DEL CRÉDITO
        array.push("");//42---> TIPO DE ACREDITADO RELACIONADO
        array.push("");//43---> TIPO DE CARTERA PARA FINES DE CALIFICACIÓN
        array.push("");//44---> CALIFICACIÓN DEL DEUDOR
        array.push("");//45---> CALIFICACIÓN PARTE CUBIERTA
        array.push("");//46---> CALIFICACIÓN PARTE EXPUESTA
        array.push("");//47---> MONTO DE ESTIMACIONES PARTE CUBIERTA
        array.push("");//48---> MONTO DE ESTIMACIONES PARTE EXPUESTA
        array.push("");//49---> ESTIMACIONES PREVENTIVAS ADICIONALES DE INTERESES DEVENGADOS DE LA CARTERA VENCIDA
        array.push("");//50---> ESTIMACIONES PREVENTIVAS ADICIONALES POR RIESGOS OPERATIVOS (SIC)
        array.push("");//51---> ESTIMACIONES PREVENTIVAS ADICIONALES ORDENADAS POR LA CNBV
        array.push("");//52---> FECHA DE LA CONSULTA A LA SIC
        array.push("");//53---> CLAVE DE PREVENCIÓN
        array.push(arreglo.montogarantialiquida);//54---> GARANTÍA LÍQUIDA
        array.push("");//55---> GARANTÍA HIPOTECARIA

        return array;
    }
    static ExportarCSVInspeccionarRegulatorio458(data_regulatorio){
        this.getArraySocioCliente([]);
        for(var i = 0; i < data_regulatorio.length; i++){
            this.ArraySocioCliente.push(this.PushArraySocioClienteRegulatorio458(data_regulatorio[i]));
        }
        this.getArraySocioCliente(this.ArraySocioCliente);
        return this.setArraySocioCliente();
    }
    static PushArraySocioClienteRegulatorio458(arreglo){
        var array = [];
        array.push(arreglo.anio);
        array.push(arreglo.ifid);
        array.push('458');
        array.push(arreglo.clavesociocliente);
        array.push('_rfc');//rfcsocio
        array.push(arreglo.nombre + " " + arreglo.primerap + " " + arreglo.segundoap)        
        array.push("240");
        array.push(arreglo.ocupaciondesc);
        array.push("");
        array.push("NO");
        array.push(arreglo.localidaddesc);
        array.push(arreglo.municipiodesc);
        array.push(arreglo.estadodesc);

        array.push("MX");
        array.push("");
        array.push("");

        array.push(arreglo.fechaotorgamiento);
        array.push("220");
        array.push("DESTINO DEL CRÉDITO");
        array.push(arreglo.nocontrato);
        array.push("metología cnbv");
        array.push(arreglo.nocontrato+"_grupal");
        array.push(arreglo.montooriginal);
        array.push(arreglo.montooriginal);
        array.push(arreglo.fechaotorgamiento);
        array.push(arreglo.fechavencimiento);

        array.push("MX");
        array.push("101");
        array.push("1");
        array.push("1");
        array.push("1");
        array.push("8");
        array.push("0");


        array.push(arreglo.tasaordinaria);
        array.push(arreglo.tasamoratoria);
        array.push("110");
        array.push("37");
        array.push(arreglo.frecpagocapital);
        array.push(arreglo.frecpagointereses);
        array.push(0);
        array.push(0);
        array.push(0);
        array.push(0);//44
        array.push(0);//45


        array.push(arreglo.localidaddesc);//46        
        array.push(arreglo.municipiodesc);//47        
        array.push(arreglo.estadodesc);//48     
        array.push(arreglo.ocupaciondesc);//49    

        return array;
    }
    static ExportarCSVInspeccionarRegulatorio459(data_regulatorio){
        this.getArraySocioCliente([]);
        for(var i = 0; i < data_regulatorio.length; i++){
            this.ArraySocioCliente.push(this.PushArraySocioClienteRegulatorio459(data_regulatorio[i]));
        }
        this.getArraySocioCliente(this.ArraySocioCliente);
        return this.setArraySocioCliente();
    }
    static PushArraySocioClienteRegulatorio459(arreglo){
        var array = [];
        array.push(arreglo.anio);//01 --> PERIODO
        array.push(arreglo.ifid);//02 --> CLAVE DE LA ENTIDAD
        array.push('459');//03 --> REPORTE 
        array.push(arreglo.nocontrato);//04 --> ID CRÉDITO ASIGNADO METODOLOGÍA CNBV
        array.push('codigositi');//05 --> CLASIFICACIÓN CONTABLE (R01 CATÁLOGO MÍNIMO)
        array.push("credito")//06 --> CRÉDITO EVALUADO CON EL CRITERIO DEL ART. 224 (CONCURSO MERCANTIL)
        array.push(arreglo.fechaotorgamiento);//07 --> FECHA DE LA DISPOSICIÓN DEL CRÉDITO 
        array.push(arreglo.fechavencimiento);//08 --> FECHA DE VENCIMIENTO DE LA DISPOSICIÓN DEL CRÉDITO
        array.push("MX");//09 --> MONEDA DE LA DISPOSICIÓN
        array.push("0");//10 --> NÚMERO DE DISPOSICIÓN 
        array.push(arreglo.nombre + " " + arreglo.primerap + " " + arreglo.segundoap);//11 --> NOMBRE DEL FACTORADO
        array.push(arreglo.rfc);//12 --> RFC DEL FACTORADO
        array.push(arreglo.montooriginal);//13 --> SALDO DEL PRINCIPAL AL INICIO DEL PERIODO 
        array.push(arreglo.tasaordinaria);//14 --> TASA INTERÉS BRUTA PERIODO
        array.push(arreglo.tasaordinaria);//15 --> TASA DE INTERÉS DE LA DISPOSICIÓN
        array.push("");//16 --> DIFERENCIAL SOBRE TASA DE REFERENCIA DE LA DISPOSICIÓN
        array.push("");//17 --> OPERACIÓN DE DIFERENCIAL SOBRE TASA DE REFERENCIA (ADITIVA O FACTOR) DE LA DISPOSICIÓN
        array.push("");//18 --> FRECUENCIA REVISIÓN TASA DE LA DISPOSICIÓN
        array.push(arreglo.montooriginal);//19 --> MONTO DISPUESTO DE LA LÍNEA DE CRÉDITO EN EL MES
        array.push(arreglo.capitalvigente + arreglo.capitalvencido + arreglo.intdevnocobvenc + arreglo.intdevnocobvig);//20 -->  MONTO DEL PAGO TOTAL EXIGIBLE AL ACREDITADO EN EL PERIODO (INCLUYE CAPITAL, INTERESES Y COMISIONES)
        array.push(arreglo.montoultpagocapital);//21 --> MONTO DE CAPITAL PAGADO EFECTIVAMENTE POR EL ACREDITADO EN EL PERIODO 
        array.push(arreglo.montoultpagointereses);//22 --> MONTO DE INTERESES PAGADOS EFECTIVAMENTE POR EL ACREDITADO EN EL PERIODO
        array.push(0);//23 --> MONTO DE COMISIONES PAGADAS EFECTIVAMENTE POR EL ACREDITADO EN EL PERIODO
        array.push(arreglo.intdevnocobvenc);//24 --> MONTO DE INTERESES MORATORIOS Y OTROS ACCESORIOS PAGADOS EFECTIVAMENTE POR EL ACREDITADO EN EL PERIODO
        array.push(arreglo.fechaotorgamiento);//25 --> MONTO TOTAL PAGADO EFECTIVAMENTE POR EL ACREDITADO EN EL PERIODO
        array.push(arreglo.fechavencimiento);//26 --> MONTO RECONOCIDO POR CONDONACIÓN EN EL PERIODO
        array.push(0);//27 --> MONTO RECONOCIDO POR QUITAS O CASTIGOS EN EL PERIODO 
        array.push(0);//28 --> MONTO BONIFICADO POR LA ENTIDAD EN EL PERIODO 
        array.push(0);//29 --> MONTO RECONOCIDO POR DESCUENTOS EN EL PERIODO
        array.push(0);//30 --> MONTO DE OTROS AUMENTOS O DECREMENTOS DEL PRINCIPAL EN EL PERIODO
        array.push(0);//31 -->  SALDO DEL PRINCIPAL AL FINAL DEL PERIODO
        array.push(0);//32 --> SALDO BASE PARA EL CÁLCULO DE INTERESES
        array.push(30);//33 --> NUMERO DE DÍAS UTILIZADOS PARA EL CÁLCULO DE INTERESES
        array.push(arreglo.tasaordinaria);//34 --> INTERESES RESULTANTES DE APLICAR LA TASA AL SALDO BASE
        array.push(arreglo.tasamoratoria);//35 -->INTERESES RESULTANTES DE APLICAR LA TASA AL SALDO BASE
        array.push(0);//36 --> INTERESES RESULTANTES DE APLICAR LA TASA AL SALDO BASE
        array.push(arreglo.diasmora);//37 --> NÚMERO DE DÍAS CON ATRASO 
        array.push(arreglo.montoultpagocapital + arreglo.montoultpagointereses);//38 --> FECHA DEL ÚLTIMO PAGO COMPLETO EXIGIBLE REALIZADO POR EL ACREDITADO 
        array.push(0);//39 --> MONTO FONDEADO POR BANCO DE DESARROLLO
        array.push(0);//40 -->  INSTITUCIÓN BANCA DE DESARROLLO QUE OTORGÓ EL FONDEO 

        return array;
    }
}

export class FuncionesBusquedasT1{
    public static elemento_seleccionado_tablaT1C2 = "";
    public static flag_elementoseleccionadoT1C2: boolean = false;

    public static ElementoSeleccionadoCaptacion = "";
    public static flagElementoSeleccionadoCaptacion = false;
    public static ElementoSeleccionadoCartera = "";
    public static flagElementoSeleccionadoCartera = false;

    static getElementoSeleccionadoCaptacion(val){
        this.ElementoSeleccionadoCaptacion = val;
    }
    static setElementoSeleccionadoCaptacion(){
        return this.ElementoSeleccionadoCaptacion;
    }
    static getElementoSeleccionadoCartera(val){
        this.ElementoSeleccionadoCartera = val;
    }
    static setElementoSeleccionadoCartera(){
        return this.ElementoSeleccionadoCartera;
    }

    static getflagElementoSeleccionadoCaptacion(val){
        this.flagElementoSeleccionadoCaptacion = val;
    }
    static setflagElementoSeleccionadoCaptacion(){
        return this.flagElementoSeleccionadoCaptacion;
    }
    static getflagElementoSeleccionadoCartera(val){
        this.flagElementoSeleccionadoCartera = val;
    }
    static setflagElementoSeleccionadoCartera(){
        return this.flagElementoSeleccionadoCartera;
    }
    


    static getElementoSeleccionadoTablaT1C2(val){
        this.elemento_seleccionado_tablaT1C2 = val;
    }
    static setElementoSeleccionadoTablaT1C2(){
        return this.elemento_seleccionado_tablaT1C2;
    }
    static getFlagElementoSeleccionadoT1C2(val){
        this.flag_elementoseleccionadoT1C2 = val;
    }
    static setFlagElementoSeleccionadoT1C2(){
        return this.flag_elementoseleccionadoT1C2;
    }
    static buscar_filaseleccionadaT1C2(valor){
        if(this.setElementoSeleccionadoTablaT1C2() == valor){
            return true;
        }else{
            return false;
        }
    }
}

export class FuncionesTemplate4{
    public static ArregloSucursal: any [] = [];
    public static ArregloPaginado: any [] = [];
    public static ArregloPaginadoLineal: any [] = [];
    public static setArregloSucursal(){
        return this.ArregloSucursal;
    }
    public static getArregloSucursal(val){
        this.ArregloSucursal = val;
    }
    public static ArregloEscolaridad(nopagina,norows){
        this.ArregloPaginado = [];
        var inicio = (nopagina - 1)*norows;
        var fin = nopagina*norows;
        if(fin > this.setArregloSucursal().length){
            fin = this.setArregloSucursal().length;
        }
        for(var i = inicio; i < fin; i++){
            this.ArregloPaginado.push(this.setArregloSucursal()[i]);
        }
        return this.ArregloPaginado;
    }
    public static ArregloEscolaridadLineal(){
        this.ArregloPaginadoLineal = [];
        for(var i = 0; i < this.ArregloPaginado.length; i++){
            for(var j = 0; j < this.ArregloPaginado[i].series.length;j++){
                if(!this.BuscarEscolaridadArreglo(this.ArregloPaginado[i].series[j].name)){
                    //hacer el push
                    this.ArregloPaginadoLineal.push({"name":this.ArregloPaginado[i].series[j].name,"series":this.ArregloSucursalEscolaridad(this.ArregloPaginado[i].series[j].name)});
                }
            }
        }        
    }
    public static BuscarEscolaridadArreglo(escolaridad){
        var flag = false;
        for(var i = 0; i < this.ArregloPaginadoLineal.length;i++){
            if(this.ArregloPaginadoLineal[i].name == escolaridad){
                flag = true;
            }
        }
        return flag;
    }
    public static ArregloSucursalEscolaridad(escolaridad){
        var aux = [];
        for(var i = 0; i < this.ArregloPaginado.length;i++){
            for(var j = 0; j < this.ArregloPaginado[i].series.length;j++){
                if(escolaridad == this.ArregloPaginado[i].series[j].name){
                    aux.push({"name":this.ArregloPaginado[i].name,"value":this.ArregloPaginado[i].series[j].value});
                }
            }
        }
        return aux;
    }
}