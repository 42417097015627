<div class="w3-container w3-contenedor-template" style="height: 7vh;">
    <div class="w3-row ">
      <!-- TITULO DEL COMPONENTE -->
      <div class="w3-quarter padreizquierda ">
        <div class="w3-row hijoizquierda">
          <!--<span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }} </b></span>-->
          <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> Riesgo de crédito</b></span>
          <span class="w3-text-deep-orange">&bull;</span>
          <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal">{{ dataService.currentSubmodule }}</span>
        </div>
      </div>
      <!-- FIN TITULO DEL COMPONENTE -->
  
      <!-- FILTROS-->
      <div class="w3-rest ">
        <!-- DEFINICION FILTROS -->
        <div class="w3-noventa2 w3-row ">
          <!-- AÑOS -->
          <div class="w3-third padreizquierda">
            <!-- FILTRO AÑOS DISPONIBLES -->
            <div class="w3-quarter_e hijoizquierda">
              <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios">
                Año
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium"  #menuanios="matMenu" >
                <ng-container *ngFor="let anio of aniosDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{anio.name}}" aria-label="First checkbox"
                                  (change)="anioChangeListener(anio)">
                      {{anio.value}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO AÑOS DISPONIBLES -->
  
            <!-- AÑO SELECCIONADO -->
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda chips">
                  <div [ngClass]="anios.check?
                  'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                    *ngFor="let anios of aniosDisponibles"
                    class="filtroMesAnioSeleccionado">
                    <span class="contenidoChips">{{anios.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN AÑO SELECCIONADO -->
          </div>
          <!-- FIN AÑOS -->
  
          <!-- MESES -->
          <div class="w3-third " >
            <!-- FILTRO MESES DISPONIBLES -->
            <div class="w3-quarter_e hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
                Mes
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium"  #menumeses="matMenu" >
                <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{mes.name}}"
                                  (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                      {{mes.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO MESES DISPONIBLES -->
  
            <!-- MES SELECCIONADO -->
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda chips">
                  <div class="filtroMesAnioSeleccionado w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu">
                      <span class="contenidoChips">{{mesSeleccionado.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN MES SELECCIONADO -->
          </div>
          <!-- FIN MESES -->
  
          <!-- CONSOLIDADO Y SUCURSALES -->
          <div class="w3-rest">
            <div class="w3-third hijoizquierda">
              <button class="w3-button w3-right"  [matMenuTriggerFor]="menuentidad">
                Pérdida
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium"  #menuentidad="matMenu" >
                <button mat-menu-item>
                  <mat-checkbox name="consolidado" [checked]="todas_perdidas"
                                (click)="$event.stopPropagation();"
                                (change)="consolidadoChangeListener('todas_perdidas')"
                                aria-label="First checkbox">
                    Todas
                  </mat-checkbox>
                </button>
                <button mat-menu-item>
                  <mat-checkbox name="consolidado" [checked]="PerdidaEsperada"
                                (click)="$event.stopPropagation();"
                                (change)="consolidadoChangeListener('PerdidaEsperada')"
                                aria-label="First checkbox">
                    Pérdida Esperada
                  </mat-checkbox>
                </button>
                <button mat-menu-item>
                  <mat-checkbox name="consolidado" [checked]="PerdidaNoEsperada95"
                                (click)="$event.stopPropagation();"
                                (change)="consolidadoChangeListener('PerdidaNoEsperada95')"
                                aria-label="First checkbox">
                    Pérdida No Esperada
                  </mat-checkbox>
                </button>
                <button mat-menu-item>
                  <mat-checkbox name="consolidado" [checked]="VaR95"
                                (click)="$event.stopPropagation();"
                                (change)="consolidadoChangeListener('VaR95')"
                                aria-label="First checkbox">
                    VaR
                  </mat-checkbox>
                </button>
              </mat-menu>
            </div>
            <div class="w3-rest hijoizquierda" style="height: 4vh;">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda chips">
                  <div *ngIf="todas_perdidas" class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu filtroMesAnioSeleccionado">
                    <span class="contenidoChips">Todas</span>
                  </div>
                  <div *ngIf="PerdidaEsperada && !todas_perdidas" class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu filtroMesAnioSeleccionado">
                    <span class="contenidoChips">Pérdida Esperada</span>
                  </div>
                  <div *ngIf="PerdidaNoEsperada95 && !todas_perdidas" class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu filtroMesAnioSeleccionado">
                    <span class="contenidoChips">Pérdida No Esperada</span>
                  </div>
                  <div *ngIf="VaR95 && !todas_perdidas" class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu filtroMesAnioSeleccionado">
                    <span class="contenidoChips">VaR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN CONSOLIDADO Y SUCURSALES -->
        </div>
        <!-- FIN DEFINICION FILTROS -->
  
        <!-- BORRAR FILTROS -->
        <div class="w3-rest w3-right ">
          <button mat-icon-button>
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitarFiltros()">
              <img src="assets/img/graficos/eraser.png">
            </mat-icon>
          </button>
        </div>
        <!-- FIN BORRAR FILTROS -->
      </div>
      <!-- FIN FILTROS-->
    </div>
</div>

<!-- contenedor de las graficas del tablero -->
<div class="w3-container w3-contenedor-template " style="height: 85vh;">
    <div class="w3-row w3-contenedor-template " style="height: 41vh;">
      <!-- PARTE DE ARRIBA -->
      <div class="w3-row w3-white" style="height: 100%;">
        <!-- PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
        <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
          <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">Tendencia Pérdida</span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
              <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica}}</span>
            </div>
          </div>
          <div class="w3-rest w3-right-align_rest ">
            <div class="w3-right hijoderechaTitle">
              <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarGraficaC1()">zoom_out_map</mat-icon>
              <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                more_vert</mat-icon>
            </div>
            <mat-menu #menu="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span>Gráficas</span>
              </button>
              <button *ngIf="perspectiva" class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspectivas">
                <mat-icon [ngStyle]="{'color':'gray'}">account_tree</mat-icon>
                <span>Perspectivas</span>
              </button>
              <!-- BOTON ELIMINAR FILTROS -->
              <button *ngIf="perspectiva" (click)="quitarFiltros()" class="mat-menu-item " mat-menu-item>
                <mat-icon [ngStyle]="{'color':'gray'}">
                  <img style="margin-top: -10px;" src="assets/img/graficos/sinfiltro.png">
                </mat-icon>
                <span>Eliminar Filtros</span>
              </button>
            </mat-menu>
  
            <mat-menu #graficas="matMenu">
              <button mat-menu-item (click)="cambiarGraficaC1('lineal')">
                <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                <span>Lineal</span>
              </button>
            </mat-menu>
            <mat-menu #perspectivas="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="zonamenu" >
                <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                <span>Zona</span>
              </button>
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="productomenu" >
                <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                <span>Producto</span>
              </button>
            </mat-menu>
            <mat-menu #zonamenu="matMenu">
              <button *ngFor="let zona of dataZona" mat-menu-item (click)="filtrarZona(zona)">
                <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                <span>{{zona.Nombre_Zona}}</span>
              </button>
            </mat-menu>
            <mat-menu #productomenu="matMenu">
              <button *ngFor="let producto of dataProducto" mat-menu-item (click)="filtrarProducto(producto)">
                <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                <span>{{producto.Nombre_Producto}}</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <!-- FIN PARTE DE ARRIBA, HEADER DEL COMPONENTE Y FILTROS -->
  
        <!-- PARTE DE ARRIBA, CONTENDOR DE LAS GRAFICAS -->
        <div class="w3-row" >
          <!-- GRAFICA DE BARRAS -->
          <div *ngIf="lineal_opt1" class="chart_wrap w3-scroll">
            <google-chart style="height: 30vh;width: 105%;"
                [title]="title" 
                [type]="type" 
                [data]="data" 
                [columns]="columnNames" 
                [options]="options"
                (select)="onSelectG($event)">
            </google-chart>
          </div>
          <div class="w3-row " *ngIf="lineal_opt2" style="height: 34vh;">
            <ngx-charts-area-chart 
                [scheme]="colorScheme"
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [results]="multi"
                [showGridLines]="showGridLines"
                (select)="onSelect($event)">
            </ngx-charts-area-chart>
          </div>
          <!-- GRAFICA LINEAL -->
  <!--        Elimine esta parte puesto que no es un requerimiento funcional-->
  <!--        <div *ngIf="!esGraficaDeBarras && datosLineal" class="chart_wrap w3-scroll">-->
  <!--        </div>-->
        </div>
        <!-- FIN PARTE DE ARRIBA, CONTENDOR DE LAS GRAFICAS -->
      </div>
      <!-- FIN PARTE DE ARRIBA -->
    </div>

    <!-- PARTE DE ABAJO -->
    <div class="w3-row mt-2 ">
      <div class="w3-twothird w3-white" style="height: 41vh;">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;z-index: 100;">
              <span class="w3-margin-left-contenido ">Tendencia Pérdida</span>
              <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
              <!-- DEBE APARECEER ALGO COMO: años | consolidado -->
              <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGraficaTabla}}</span>
              <!--<span class="tooltiptext ">{{subtituloGraficaTabla}}</span>-->
            </div>
          </div>
          <div class="w3-rest w3-right">
            <button mat-icon-button [matMenuTriggerFor]="menu_tabla" [ngStyle]="{'color':'gray'}" aria-label="Example icon-button with a menu">
              <mat-icon class="w3-button w3-dropdown-click">more_vert</mat-icon>
            </button>
            <mat-menu #menu_tabla="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="export_csv()">
                <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                <span>Exportar csv</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="w3-row w3-white" style="height: 32vh">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="">
              <table class="w3-table-all" mat-table [dataSource]="dataSource" style="z-index: -1;">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="Periodo">
                  <th class="w3-table-title w3-center"  mat-header-cell  *matHeaderCellDef style="font-size: 0.8vw;"> Periodo </th>
                  <td class="w3-center" mat-cell *matCellDef="let element; "> <div style=" font-size: 0.8vw;"> {{element.periodo}} </div> </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="PerdidaEsperada">
                  <th class="w3-table-title w3-center"  mat-header-cell  *matHeaderCellDef style="font-size: 0.8vw;"> Pérdida esperada </th>
                  <td mat-cell *matCellDef="let element"> <div class="w3-twothird w3-margin-right-niveles" style="text-align: right; width: 60%; font-size: 0.8vw;"> {{formatearnormal(element.perdidaesperada)}} </div> </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="PerdidaNoEsperada">
                  <th class="w3-table-title w3-center"  mat-header-cell  *matHeaderCellDef style="font-size: 0.8vw;"> Pérdida no esperada </th>
                  <td mat-cell *matCellDef="let element"> <div class="w3-twothird w3-margin-right-niveles" style="text-align: right; width: 60%; font-size: 0.8vw;"> {{formatearnormal(element.perdidanoesperada)}} </div> </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="VaR">
                  <th class="w3-table-title w3-center" mat-header-cell  *matHeaderCellDef style="font-size: 0.8vw;"> VaR </th>
                  <td mat-cell *matCellDef="let element"> <div class="w3-twothird w3-margin-right-niveles" style="text-align: right; width: 60%; font-size: 0.8vw;"> {{formatearnormal(element.var90)}} </div> </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-rest " style="height: 41vh;">
        <div class="w3-row w3-right " style="width: 96%;">

          <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
            <div class="w3-half">
              <div class="w3-row textLeft">
                <span class="w3-total-textoEstadistico" style="color: #8B8E93;">PÉRDIDA ESPERADA</span>
              </div>
              <div class="w3-row textLeft" style="vertical-align: bottom;">
                <span class="w3-subtituloMeta"> {{aniomesSeleccionado}} </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-row w3-right textRight">
                <span class="w3-total-cantidadEstadistico w3-balance-bluep"> {{formatearnormal(suma_PerdidaEsperada)}} </span>
              </div>
            </div>
          </div>

          <div style="height: 1.5vh;"></div>

          <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
            <div class="w3-half">
              <div class="w3-row textLeft">
                <span class="w3-total-textoEstadistico" style="color: #8B8E93;">PÉRDIDA NO ESPERADA</span>
              </div>
              <div class="w3-row textLeft" style="vertical-align: bottom;">
                <span class="w3-subtituloMeta"> {{aniomesSeleccionado}} </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-row w3-right textRight">
                <span class="w3-total-cantidadEstadistico w3-balance-bluep"> {{formatearnormal(suma_PerdidaNoEsperada95)}} </span>
              </div>
            </div>
          </div>

          <div style="height: 1.5vh;"></div>

          <div class="w3-row w3-white w3-margin-topEstadistico" style="height: 12.3vh;">
            <div class="w3-half">
              <div class="w3-row textLeft">
                <span class="w3-total-textoEstadistico" style="color: #8B8E93;">VaR</span>
              </div>
              <div class="w3-row textLeft" style="vertical-align: bottom;">
                <span class="w3-subtituloMeta"> {{aniomesSeleccionado}} </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-row w3-right textRight">
                <span class="w3-total-cantidadEstadistico w3-balance-bluep"> {{formatearnormal(suma_VaR95)}} </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</div>
<!-- contenedor de las graficas del tablero -->


<!-- Modal para las gráficas -->
<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-noventa">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{titulomodal}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" >&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulomodal}}</span>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <div *ngIf="lineal_opt1_modal" class="chart_wrap w3-scroll"  style="height: 57vh;">
          <google-chart style="height: 55vh;width: 105%;"
              [title]="title" 
              [type]="type" 
              [data]="data" 
              [columns]="columnNames" 
              [options]="options"
              (select)="onSelectG($event)">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="lineal_opt2_modal" style="height: 55vh;">
          <ngx-charts-area-chart style="height: 50vh;width: 100%;"
              [scheme]="colorScheme"
              [legend]="legend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [results]="multi"
              [showGridLines]="showGridLines"
              (select)="onSelect($event)">
          </ngx-charts-area-chart>
        </div>
      </div>
    </div>
  </div>
</div>