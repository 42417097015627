import {MessageService} from './../base/message.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Service} from './../base/service';
import { InstitucionPatmirEntity } from '../../entities/base/BaseEntities';

import { Loggers } from '../../util/app-util';

@Injectable({
  providedIn: 'root'
})
export class InstitucionpatmirIIService extends Service<InstitucionPatmirEntity> {

    httpClient: HttpClient;
    currentInstitucion: InstitucionPatmirEntity;

    constructor(
        http: HttpClient,
        messageService: MessageService,
        protected logger: Loggers
    ) {
        super('InstitucionPatmirService', 'institucionpatmir', http, messageService, logger);
        this.httpClient = http;
    }

    protected getParams(entity: InstitucionPatmirEntity): HttpParams {
        let params: HttpParams = new HttpParams();
        if (null == entity) { return params; }

        if (null != entity.InstitucionPatmirId) { params = params.set('InstitucionPatmirId', entity.InstitucionPatmirId.toString()); }
        if (null != entity.Acronimo) { params = params.set('Acronimo', entity.Acronimo.toString()); }
        if (null != entity.Nombre) { params = params.set('Nombre', entity.Nombre.toString()); }
        if (null != entity.Tipo) { params = params.set('Tipo', entity.Tipo.toString()); }
        if (null != entity.FechaConvenio) { params = params.set('FechaConvenio', entity.FechaConvenio.toString()); }
        if (null != entity.Activa) { params = params.set('Activa', entity.Activa.toString()); }
        if (null != entity.Bansefi_InstitucionPatmirId) { params = params.set('Bansefi_InstitucionPatmirId', entity.Bansefi_InstitucionPatmirId.toString()); }
        if (null != entity.UltPeriodoActualizable) { params = params.set('UltPeriodoActualizable', entity.UltPeriodoActualizable.toString()); }
        if (null != entity.InstitucionPatmir_Order_By) { params = params.set('InstitucionPatmir_Order_By', entity.InstitucionPatmir_Order_By.toString()); }
        if (null != entity.Acro_bk) { params = params.set('Acro_bk', entity.Acro_bk.toString()); }
  

        return params;
    }
    public getCurrentInstitucion(): Observable<any> {
        if (!this.currentInstitucion) {
            return this.httpClient.get<InstitucionPatmirEntity>('webapi/institucion').pipe(
            //return this.httpClient.get<InstitucionPatmirEntity>(this.serviceUrl).pipe(
                tap(institucion => {
                    this.logger.log(`InstitucionService: fetched ${institucion}`, false);
                    this.currentInstitucion = institucion;
                }),
                catchError(this.handleError('get', new InstitucionPatmirEntity()))
            );
        }
        return of(this.currentInstitucion);
    }
}