<mat-accordion>

  <div class="w3-row w3-white w3-scroll" *ngFor="let menu of dataService.fillerNav; let i = index">
    <ng-container *ngIf="menu.name != 'Inicio' && menu.submodules.length > 0">

      <!-- PANEL -->
      <mat-expansion-panel class="mat-expansion-panel" hideToggle (opened)="arrowState[i] = 'keyboard_arrow_up'"
        (closed)="arrowState[i] = 'keyboard_arrow_down'">

        <!-- HEADER DEL PANEL -->
        <mat-expansion-panel-header [ngClass]="isActiveModule(menu)?
        'w3-menu-moduloS w3-row': 'w3-menu-moduloN w3-row'">

          <!-- display block para situar el borde debajo del título-->
          <mat-panel-title style="display: block">
            <span>{{menu.name}}</span>
            <!-- Borde dentro del header, en caso de que el menú esté contraído -->
            <div class="w3-menu-barrita" *ngIf="arrowState[i] == 'keyboard_arrow_down'">
              <div style="width: 19px; border: .2pt solid #616161; margin-top: 10px; margin-right: 10px;"></div>
            </div>
          </mat-panel-title>

          <!-- DESCRIPCION DEL PANEL-->
          <mat-panel-description>
            <div class="w3-row w3-right" style="width: 95%;">
              <mat-icon class="w3-medium w3-right w3-button" aria-hidden="false">{{ arrowState[i] }}</mat-icon>
            </div>
          </mat-panel-description>

        </mat-expansion-panel-header>

        <!-- CONTENIDO -->
        <div class="w3-row" *ngFor="let submenu of menu.submodules">
          <div style="padding-top: 2px;" [ngClass]="isActiveSubmodule(submenu)?
          'w3-menu-submoduloS w3-row' : 'w3-menu-submoduloN w3-row'">
            <a [routerLink]="['/app/' + submenu.route]">{{submenu.name}}</a>
          </div>
        </div>

        <!-- Borde dentro del contenido, en caso de que se expanda el menú-->
        <div class="w3-menu-barrita" *ngIf="arrowState[i] == 'keyboard_arrow_up'">
          <div style="width: 19px; border: .2pt solid #616161; margin-top: 10px; margin-right: 10px;"></div>
        </div>

      </mat-expansion-panel>

    </ng-container>

  </div>

</mat-accordion>
