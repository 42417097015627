import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { MapsAPILoader } from '@agm/core';
import { DataService } from '../../../services/data-service/data.service';
import { FiltrosService } from '../../../services/filtros/filtros.service';
import { GeograficoService } from '../../../services/geografico/geografico.service';
import { PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import * as L from 'leaflet';
@Component({
  selector: 'app-geo-referenciacion',
  templateUrl: './geo-referenciacion.component.html',
  styleUrls: ['./geo-referenciacion.component.css']
})
export class GeoReferenciacionComponent implements OnInit {
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
   /** Variables de años y meses */
   public aniosDisponibles: any [] = [];
   public aniosSeleccionados: any [] = [];
   public mesesDisponibles: any [] = [];
   public mesesDeAnioDisponibles: any[] = [];
   public mesesSeleccionados: any [] = [];
   public sucursalesDisponibles: any[];
   public ult_anio: any [] = [];
   public ult_mes: any [] = [];
   public aniosSelec = [];

  /**Variables del mapa */
  mapa : any;
  markers: any = [];
  popup: any = L.popup();

  private ancho = 25;
  private alto = 40;

  private ancho2 = 20;
  private alto2 = 35;

  public sucursalesParaMapear: Array<{ longitud: number, latitud: number, icon: string }> = [];
  
  public metricas = [
    {name: 'Cartera', check: true},
    {name: 'Créditos', check: false},
    {name: 'Morosidad', check: false},
  ]
  public municipios = [];
  public datosSuma = 0;
  public titulo_tarjeta = '';
  public data = [];
  public estados = [];
  public estSelec = '';
  public municipioSelec = '';
  /** Subtitulos */
  public subtit;
  public subtit_modal;
  public anioMes;
  public zoomLevel;
  public mayorVal = 0;
  public nEst = 0;
  public nMun = 0;
  
  constructor(
    private filtrosService: FiltrosService,
    public dataService: DataService,
    public geograficoservice: GeograficoService,
    public excelservices: ExcelService,
  ) {}
  ngOnInit(): void {
    this.mapa = L.map('mapa').setView([19.634783,-99.098737],7);
    L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',{
      maxZoom: 19,
      attribution: '© OpenStreetMap'
    }).addTo(this.mapa);
    this.mapa.on('zoomend', (e)=>{this.prueba(e)});
    this.getFiltros();
  }
  public prueba(e){
    for(const marker of this.markers){
      this.mapa.removeLayer(marker);
    }
    this.markers = [];
    this.poner_puntos();
  }
  public getFiltros(){
    this.mapa.setView([19.634783,-99.098737],7);
    var ult_anio = [], ult_mes = [];
    this.filtrosService.getAniosMesesDisponibles('radiografia').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAnioMesDisponible(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesAniosDisponibles;
      ult_anio = tiempoFiltros.ult_anio;
      ult_mes = tiempoFiltros.ult_mes;
      this.ult_anio = ult_anio;
      this.ult_mes = ult_mes;
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio[0]);
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.aniosMesesSeleccionados();
      this.aniosSelec.push(this.mesesSeleccionados[0].value);
      this.obtData();
      this.subtitulo();      
    });
  }
  private obtData(){
    if(this.metricas[0].check){
      this.titulo_tarjeta = 'Cartera total';
      this.consultaSaldo();
    }else if(this.metricas[2].check){
      this.titulo_tarjeta = 'Índice de morosidad';
      this.consultaMorosidad();
    }else{
      this.titulo_tarjeta = 'Número de creditos';
      this.consultaCreditos();
    }
  }
  private consultaMorosidad(){
    var cartVencida = [], carteraTot = [];
    this.geograficoservice.getGeograficoSaldoCap(this.aniosSelec).subscribe(response =>{
      if (response.http.code === 200) {
        var ord = this.ordenamiento(response.message);
        carteraTot = ord;
        this.geograficoservice.getGeograficoIntVig(this.aniosSelec).subscribe(response =>{
          if (response.http.code === 200) {
            ord = this.ordenamiento(response.message);
            for(var i =0; i < carteraTot.length; i++){
              for(var j =0; j < carteraTot[i].value.length; j++){
                carteraTot[i].value[j].value += ord[i].value[j].value;
              }
            }
            this.geograficoservice.getGeograficoIntVen(this.aniosSelec).subscribe(response =>{
              if (response.http.code === 200) {
                ord = this.ordenamiento(response.message);
                for(var i =0; i < carteraTot.length; i++){
                  for(var j =0; j < carteraTot[i].value.length; j++){
                    carteraTot[i].value[j].value += ord[i].value[j].value;
                  }
                }
                this.geograficoservice.getGeograficoIntMor(this.aniosSelec).subscribe(response =>{
                  if (response.http.code === 200) {
                    ord = this.ordenamiento(response.message);
                    for(var i =0; i < carteraTot.length; i++){
                      for(var j =0; j < carteraTot[i].value.length; j++){
                        carteraTot[i].value[j].value += ord[i].value[j].value;
                      }
                    }
                    this.geograficoservice.getGeograficoSaldoCap(this.aniosSelec, 'VENCIDA').subscribe(response =>{
                      if (response.http.code === 200) {
                        var ord = this.ordenamiento(response.message);
                        cartVencida = ord;
                        this.geograficoservice.getGeograficoIntVig(this.aniosSelec, 'VENCIDA').subscribe(response =>{
                          if (response.http.code === 200) {
                            ord = this.ordenamiento(response.message);
                            for(var i =0; i < cartVencida.length; i++){
                              for(var j =0; j < cartVencida[i].value.length; j++){
                                cartVencida[i].value[j].value += ord[i].value[j].value;
                              }
                            }
                            this.geograficoservice.getGeograficoIntVen(this.aniosSelec, 'VENCIDA').subscribe(response =>{
                              if (response.http.code === 200) {
                                ord = this.ordenamiento(response.message);
                                for(var i =0; i < cartVencida.length; i++){
                                  for(var j =0; j < cartVencida[i].value.length; j++){
                                    cartVencida[i].value[j].value += ord[i].value[j].value;
                                  }
                                }
                                this.geograficoservice.getGeograficoIntMor(this.aniosSelec, 'VENCIDA').subscribe(response =>{
                                  if (response.http.code === 200) {
                                    ord = this.ordenamiento(response.message);
                                    for(var i =0; i < cartVencida.length; i++){
                                      for(var j =0; j < cartVencida[i].value.length; j++){
                                        cartVencida[i].value[j].value += ord[i].value[j].value;
                                      }
                                    }                                  
                                    var cartVigente = [], aux = [], en = 0;
                                    for(var i=0; i<carteraTot.length; i++){
                                      for(var j=0; j<carteraTot[i].value.length; j++){
                                        if(i<cartVencida.length){
                                          for(const mun of cartVencida[i].value){
                                            if(carteraTot[i].value[j].key ===  mun.key){
                                              aux.push({
                                                key: mun.key,
                                                value: carteraTot[i].value[j].value - mun.value
                                              });
                                              en ++;
                                            }
                                          }
                                          if (en === 0){
                                            aux.push({
                                              key: carteraTot[i].value[j].key,
                                              value: carteraTot[i].value[j].value
                                            });
                                          }
                                          en = 0;
                                        }else{
                                          aux.push(carteraTot[i].value);
                                        }
                                      }
                                      cartVigente.push({
                                        key: carteraTot[i].key,
                                        value: aux
                                      });
                                      aux = [];
                                    }
                                    this.data = [];
                                    aux = [];
                                    for(var i=0; i<cartVigente.length; i++){
                                      for(var j=0; j<cartVigente[i].value.length; j++){
                                        if(i<cartVencida.length){
                                          for(const mun of cartVencida[i].value){
                                            if(cartVigente[i].value[j].key ===  mun.key){
                                              aux.push({
                                                key: mun.key,
                                                value: (mun.value/(mun.value + cartVigente[i].value[j].value))*100
                                              });
                                              en ++;
                                            }
                                          }
                                          if (en === 0){
                                            aux.push({
                                              key: cartVigente[i].value[j].key,
                                              value: (0/(0 + cartVigente[i].value[j].value))*100
                                            });
                                          }
                                          en = 0;
                                        }else{
                                          aux.push({
                                            key: cartVigente[i].value[j].key,
                                            value: (0/(0 + cartVigente[i].value[j].value))*100
                                          });
                                        }
                                      }
                                      this.data.push({
                                        key: cartVigente[i].key,
                                        value: aux
                                      });
                                      aux = [];
                                    }
                                    this.estados = [];
                                    this.municipios = [];
                                    var j = 0;
                                    for (const est of this.data){
                                      var vig=0, ven = 0;
                                      cartVigente[j].value.forEach(mun => vig += mun.value);
                                      if(j < cartVencida.length){
                                        cartVencida[j].value.forEach(mun => ven += mun.value);
                                      }
                                      this.estados.push(this.geograficoservice.obtener_estado(est.key));
                                      this.estados[j].data = (ven/(vig+ven))*100;
                                      j++;
                                    }
                                    for(const est of this.estados){
                                      this.geograficoservice.obtener_datos(est.estado).subscribe(response =>{
                                        //@ts-ignore
                                        if(response.http.code === 200){
                                          this.obtener_municipios(response,est);
                                          if( this.municipios.length >= 40){
                                            this.municipios;
                                            this.datosSuma = 0;
                                            var vig=0, ven = 0;
                                            for(var i = 0; i < cartVencida.length; i++){
                                              cartVigente[i].value.forEach(mun => vig += mun.value);
                                              cartVencida[i].value.forEach(mun => ven += mun.value);
                                            }
                                            this.datosSuma = (ven/(vig+ven))*100;
                                            this.nEst = this.estados.length;
                                            this.nMun = this.municipios.length;
                                            this.poner_puntos();
                                          }
                                        }
                                      });
                                    }
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  private consultaSaldo(){
    this.geograficoservice.getGeograficoSaldoCap(this.aniosSelec).subscribe(response =>{
      if (response.http.code === 200) {
        var ord = this.ordenamiento(response.message);
        this.data = ord;
        this.geograficoservice.getGeograficoIntVig(this.aniosSelec).subscribe(response =>{
          if (response.http.code === 200) {
            ord = this.ordenamiento(response.message);
            for(var i =0; i < this.data.length; i++){
              for(var j =0; j < this.data[i].value.length; j++){
                this.data[i].value[j].value += ord[i].value[j].value;
              }
            }
            this.geograficoservice.getGeograficoIntVen(this.aniosSelec).subscribe(response =>{
              if (response.http.code === 200) {
                ord = this.ordenamiento(response.message);
                for(var i =0; i < this.data.length; i++){
                  for(var j =0; j < this.data[i].value.length; j++){
                    this.data[i].value[j].value += ord[i].value[j].value;
                  }
                }
                this.geograficoservice.getGeograficoIntMor(this.aniosSelec).subscribe(response =>{
                  if (response.http.code === 200) {
                    ord = this.ordenamiento(response.message);
                    for(var i =0; i < this.data.length; i++){
                      for(var j =0; j < this.data[i].value.length; j++){
                        this.data[i].value[j].value += ord[i].value[j].value;
                      }
                    }
                    this.estados = [];
                    this.municipios = [];
                    var j = 0, tot = 0;
                    for (const est of this.data){
                      var sum = 0;
                      est.value.forEach(cuen =>{ sum += cuen.value;  tot += cuen.value });
                      this.estados.push(this.geograficoservice.obtener_estado(est.key));
                      this.estados[j].data = sum;
                      j++;
                    }
                    for(const est of this.estados){
                      this.geograficoservice.obtener_datos(est.estado).subscribe(response =>{
                        //@ts-ignore
                        if(response.http.code === 200){
                          this.obtener_municipios(response,est);
                          if( this.municipios.length >= 40){
                            this.municipios;
                            this.datosSuma = 0;
                            this.municipios.forEach(dat => {this.datosSuma += dat.data});
                            this.datosSuma = tot;
                            this.nEst = this.estados.length;
                            this.nMun = this.municipios.length;
                            this.poner_puntos();
                          }
                        }
                      });
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  private consultaCreditos(){
    this.geograficoservice.getGeograficoCodEst(this.aniosSelec).subscribe(response =>{
      if (response.http.code === 200) {
        this.data = response.message;
        this.estados = [];
        this.municipios = [];
        var j = 0;
        for (const est of this.data){
          var sum = 0;
          est.value.forEach(cuen =>{ if(cuen.key != 998){sum += cuen.value} });
          this.estados.push(this.geograficoservice.obtener_estado(est.key));
          this.estados[j].data = sum;
          j++;
        }
        var i = 0;
        for(const est of this.estados){
          this.geograficoservice.obtener_datos(est.estado).subscribe(response =>{
            //@ts-ignore
            if(response.http.code === 200){
              this.obtener_municipios(response,est);
              if( this.municipios.length >= 40){
                this.municipios;
                this.datosSuma = 0;
                this.municipios.forEach(dat => {this.datosSuma += dat.data});
                this.nEst = this.estados.length;
                this.nMun = this.municipios.length;
                this.poner_puntos();
              }
            }
          });
        }
      }
    });
  }
  public obtener_municipios(array,estado){
    var pos=0;
    for(var i = 0; i < this.data.length; i++){
      if(Number(estado.clave) === Number(this.data[i].key))
        pos = i;
    }
    for(const mun of this.data[pos].value){
      array.message.forEach(dat =>{
        if(Number(dat.ClaveMunicipio) === Number(mun.key)){ 
          this.municipios.push({
            name: dat.NombreMunicipio,
            latitud: dat.Latitud,
            longitud: dat.Longitud,
            data: mun.value,
            check: true,
            estado: estado.estado
          });
        }
      });
    }
  }
  public poner_puntos(){
    var aux;
    this.zoomLevel = this.mapa._zoom;
    if(this.mapa._zoom <= 7){
      this.mayorVal = 0;
      this.estados.forEach(est => est.data>this.mayorVal? this.mayorVal = est.data: this.mayorVal = this.mayorVal);
      this.estados;
      for(const mun of this.estados){
        if(mun.check){
          aux = L.marker([mun.latitud, mun.longitud], {icon: this.elecIcon(mun.data), opacity: this.opacidad(mun.data)}).addTo(this.mapa)
          .bindPopup('<strong>'+'Estado: '+ mun.estado +'</strong><br>' + this.titulo_tarjeta + ': '+ this.formatearnormal(mun.data))
          .bindTooltip('<span class="w3-border-blue">'+ 'Estado: '+ mun.estado + ', '+ this.titulo_tarjeta + ': '+ this.formatearnormal(mun.data) +'</span>')
          .on('click', (e)=>{this.clickMap(e,mun)});;
          this.markers.push(aux);
        }
      }
    }else{
      this.mayorVal = 0;
      this.municipios.forEach(mun => mun.data>this.mayorVal? this.mayorVal = mun.data: this.mayorVal = this.mayorVal);
      this.municipios;
      for(const mun of this.municipios){
        if(mun.check){
          aux = L.marker([mun.latitud, mun.longitud], {icon: this.elecIcon(mun.data), opacity: this.opacidad(mun.data)}).addTo(this.mapa)
          .bindPopup('<strong>'+ 'Municipio: '+ mun.name +'</strong><br>' + this.titulo_tarjeta + ': '+ this.formatearnormal(mun.data))
          .bindTooltip('<span class="w3-border-blue">' + 'Municipio: '+ mun.name + ', '+ this.titulo_tarjeta + ': '+ this.formatearnormal(mun.data)  + '</span>')
          .on('click', (e)=>{this.clickMap(e,mun)});
          this.markers.push(aux);
        }
      }
    }
    this.markers;
  }
  private elecIcon(dato){
    var iconoMorG = L.icon({
      iconUrl: '../../../assets/img/geografico/morosidad-green.png',
      iconSize:     [this.ancho2, this.alto2], // size of the icon
      iconAnchor:   [15, 40], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -38] // point from which the popup should open relative to the iconAnchor
    });
    var iconoMorR = L.icon({
      iconUrl: '../../../assets/img/geografico/morosidad-red.png',
      iconSize:     [this.ancho2, this.alto2], // size of the icon
      iconAnchor:   [15, 40], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -38] // point from which the popup should open relative to the iconAnchor
    });
    var iconoMorB = L.icon({
      iconUrl: '../../../assets/img/geografico/morosidad-blue.png',
      iconSize:     [this.ancho2, this.alto2], // size of the icon
      iconAnchor:   [15, 40], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -38] // point from which the popup should open relative to the iconAnchor
    });
    var iconoMorY = L.icon({
      iconUrl: '../../../assets/img/geografico/morosidad-yellow.png',
      iconSize:     [this.ancho2, this.alto2], // size of the icon
      iconAnchor:   [15, 40], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -38] // point from which the popup should open relative to the iconAnchor
    });
    var iconoCred = L.icon({
      iconUrl: '../../../assets/img/geografico/iconopersonas.ico',
      iconSize:     [this.ancho, this.alto], // size of the icon
      iconAnchor:   [15, 40], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -38] // point from which the popup should open relative to the iconAnchor
    });

    var iconoSal = L.icon({
      iconUrl: '../../../assets/img/geografico/iconopesos2.png',
      iconSize:     [this.ancho, this.alto], // size of the icon
      iconAnchor:   [15, 40], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -38] // point from which the popup should open relative to the iconAnchor
    });

    for(const met of this.metricas){
      if(met.check === true && met.name === 'Cartera')
        return iconoSal;
      if(met.check === true && met.name === 'Créditos')
        return iconoCred;
    }
    if(dato >= 0 && dato <= 10){
      return iconoMorG;
    }else if(dato > 10 && dato <= 20){
      return iconoMorB;
    }else if(dato > 20 && dato <= 30){
      return iconoMorY;
    }else{
      return iconoMorR;
    }
  }
  private opacidad(dato){
    return Number((dato/this.mayorVal).toFixed(2))+.6;
  }
  /** Filtros */
  // Meses
  private aniosMesesSeleccionados(): void {
    this.aniosSeleccionados = [];
    this.mesesSeleccionados = [];
    for (const valor of this.aniosDisponibles) {
      if (valor.check == true)
        this.aniosSeleccionados.push(valor);
    }
    for (const valor of this.mesesDeAnioDisponibles) {
      if (valor.check == true)
        this.mesesSeleccionados.push(valor);
    }
  }
  public cambiodeMes(mes){
    this.mesesSeleccionados = [];
    this.mesesDeAnioDisponibles.forEach(mess => mess.check = false);
    for(var i = 0; i < this.mesesDeAnioDisponibles.length; i++){
      if(this.mesesDeAnioDisponibles[i].value == mes.value){
        this.mesesDeAnioDisponibles[i].check = true;
        this.mesesSeleccionados.push(this.mesesDeAnioDisponibles[i]);
        this.ult_mes[0] = this.mesesDeAnioDisponibles[i].name;
      }
    }
    this.aniosSelec = [];
    this.aniosSelec.push(this.mesesSeleccionados[0].value);
    this.subtitulo();
    for(const marker of this.markers){
      this.mapa.removeLayer(marker);
    }
    this.markers = [];
    this.obtData();
    //this.getDatos();
  }
  public buscarMesSeleccionado(){
    for (const valor of this.mesesDeAnioDisponibles){
      valor['check'] = false;
    }
    var flag = false;
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['name'] == this.ult_mes[0]){
        valor['check'] = true;
        flag = true;
      }
    }
    if(!flag){
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
      this.ult_mes = [];
      this.ult_mes.push(this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].name);
    }
  }
  //años
  public cambiodeAnio(anio){
    this.ult_anio = [];this.aniosSeleccionados = [];
    this.aniosDisponibles.forEach(mess => mess.check = false);
    for(var i = 0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == anio.value){
        this.aniosDisponibles[i].check = true;
      }
    }
    const anio_selecc = this.aniosDisponibles.find(anios => anios.name === anio['name']);
    this.ult_anio.push(anio_selecc['name'])
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    this.buscarMesSeleccionado();
    this.aniosMesesSeleccionados();
    this.aniosSelec = [];
    this.aniosSelec.push(this.mesesSeleccionados[0].value);
    this.subtitulo();
    for(const marker of this.markers){
      this.mapa.removeLayer(marker);
    }
    this.markers = [];
    this.obtData();
    //this.getDatos();
  }
  /** Subtitulo */
  public subtitulo(){
    this.subtit = this.ult_anio[0].substring(2,4) + ' | ' + this.ult_mes[0];
    this.anioMes = this.ult_anio[0].substring(2,4) + ' | ' + this.ult_mes[0];
  }
  /** Borrar filtros */
  public borrarFil(){
    for(const marker of this.markers){
      this.mapa.removeLayer(marker);
    }
    this.estSelec = '';
    this.municipioSelec = '';
    this.aniosSelec = [];
    this.markers = [];
    this.metricas.forEach(met => { met.name === 'Cartera'? met.check = true : met.check = false });
    this.getFiltros();
  }
  public CambMet(met){
    for(const marker of this.markers){
      this.mapa.removeLayer(marker);
    }
    this.metricas.forEach(m => m.name === met.name? m.check=true : m.check=false);
    this.obtData();
  }
  /** cambio de municipio */
  public municipioChange(mun){
    var est;
    for(const m of this.municipios){
      if(mun.name === m.name){
        m.check = !m.check;
        est = mun.estado;
      }
    }
    var i =0;
    for(const m of this.municipios){
      if(est === m.estado && m.check){
        i++;
      }
    }
    for(const e of this.estados){
      if(est === e.estado){
        i===0? e.check=false:e.check=true;
      }
    }
    for(const marker of this.markers){
      this.mapa.removeLayer(marker);
    }
    this.markers = [];
    this.poner_puntos();
  }
  public clickMap(e, dat){
    this.mapa.setView([e.latlng.lat,e.latlng.lng],this.zoomLevel);
    this.nEst = 1;
    var aux = 0;
    if(this.mapa._zoom <= 7){
      this.estSelec = dat.estado;
    }else{
      this.estSelec = dat.estado;
      this.municipioSelec = dat.name
    }
    this.municipios.forEach(mun => mun.estado === dat.estado? aux++: null)
    this.nMun = aux;
    this.datosSuma = dat.data.toFixed(2);
  }
  public formatearnormal(num: any){
    num = Number(num).toFixed(2);
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if(this.metricas[2].check){
      return  splitLeft + splitRight;  
    }
    return  splitLeft;
  }
  public ordenamiento(array){
    for(const estados of array){
      estados.value.sort(function (a, b) {
        if (Number(a.key) > Number(b.key)) {
          return 1;
        }
        if (Number(a.key) < Number(b.key)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    return array;
  }
  //exportar
  public export_excel(){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    name = 'Geográfico';
    var perspectiva = '';
    var mes = this.ult_anio[0].substring(2,4) + ' | ' + this.ult_mes[0];
    if(this.mapa._zoom <= 7){
      var encabezado_excel = ['Estado',''+this.titulo_tarjeta];
      var cadena_evaluacion = encabezado_excel;
      for(const dato of this.estados){
        aux.push(dato['estado']);
        aux.push(dato['data']);
        data_excel.push(aux);
        aux = [];
      }
    }else{
      var encabezado_excel = ['Municipio',''+this.titulo_tarjeta];
      var cadena_evaluacion = encabezado_excel;
      for(const dato of this.municipios){
        aux.push(dato['name']);
        aux.push(dato['data']);
        data_excel.push(aux);
        aux = [];
      }
    }
    this.excelservices.generateExcelGeografico(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva,mes);
  }
  public export_imagen(){
    var grafica: string = "";
    var name: string = "";
    grafica = 'Mapa';
    name = 'Geografico';
    PDF.exportimagenes(grafica,name);
  }
}