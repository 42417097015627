import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RazonesComponent } from './razones/razones.component';
//import { BalanceGeneralComponent } from './balance-general/balance-general.component';
import { BalanceComponent } from './balance-general/balance-general.component';
import { EstadoDeResultadosComponent } from './estado-de-resultados/estado-de-resultados.component';
import { RentabilidadComponent } from './rentabilidad/rentabilidad.component';
import {FinancieroRoutingModule} from './financiero-routing.module';
import {SharedModule} from '../shared/shared.module';
import { IndicadoresComponent } from './indicadores/indicadores.component';



@NgModule({
  declarations: [
    RazonesComponent,
    //BalanceGeneralComponent,
    BalanceComponent,
    EstadoDeResultadosComponent,
    RentabilidadComponent,
    IndicadoresComponent
  ],
  imports: [
    CommonModule,
    FinancieroRoutingModule,
    SharedModule
  ]
})
export class FinancieroModule { }
