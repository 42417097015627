import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data-service/data.service';
import {CommonFunctions} from '../../../util/app-util';
import {FuncionesColores} from '../../shared/funciones-colores/funciones_colores';
import {MatTableDataSource} from '@angular/material/table';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import {ColocacionTendenciaService} from '../../../services/colocacion/tendencia/colocacion-tendencia.service';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

export interface UserData {
  name: string,
  value: string,
  check: boolean,
}

@Component({
  selector: 'app-tendencia-v2',
  templateUrl: './tendencia-v2.component.html',
  styleUrls: ['./tendencia-v2.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TendenciaV2Component implements OnInit {
  /** Opciones de la grafica compuesta */
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendTitle = '';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Periodo';
  showYAxisLabel = true;
  yAxisLabel1 = 'Cartera total';
  yAxisLabel2 = 'Número de cuentas';
  yAxisLabel = 'Saldos';
  showGridLines = true;
  innerPadding = '20%';
  animations: boolean = true;
  lineChartScheme = {
    domain: ['#004A97']
  };
  comboBarScheme = {
    domain: ['#3BB0C9']
  };
  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Índice de morosidad';


  

  groupPadding = 1;


  expandedDetail = 'expanded';
  expandedElement: 'expanded' | 'expanded';
  // options
  /** variables de filtros */
  public puedeFiltrarPorPerspectivas = false;
  public consolidado = true;
  public aniosDisponibles: any [];
  private mesesDisponibles: any [];
  public mesesDeAnioDisponibles: any[];
  public sucursalesDisponibles2: MatTableDataSource<UserData>;
  public sucursalesDisponibles: any[];
  public puedeFiltrarPorSucursales = false;
  public numeroDeSucursalesSeleccionadas = 0;
  private anioSeleccionado: object;
  public mesSeleccionado: object;
  public sucursalSeleccionada: object;
  public subtituloGraficaTabla = '';
  public subtituloGrafica2 = '';
  public subtituloTarjeta2 = '';
  /**
   * variables de graficas
   */
  public esGraficaDeBarras = false;
  public colorScheme: string = FuncionesColores.setpaleta_azul('paleta_azul');
  public minimo = 0;
  public minimoCuen = 0;

  public customColors: object[];
  public selecAll = false;
  public datazona2: any[] = [];

  title = '';
  type = 'ComboChart';
  data = [
    ["2012", 900, 390],
    ["2013", 1000, 400],
    ["2014", 1170, 440],
    ["2015", 1250, 480],
    ["2016", 1530, 540]
  ];
  columnNames = ['Year', 'Asia','Europe'];
  options = {     
    hAxis: {  
        title: ''  
    },  
    vAxis:{  
        title: ''  
    },  
    seriesType: 'bars',  
    series: {1: {type: 'line'}},
    colors: ['#004A97', '#3BB0C9', '#f3b49f', '#f6c7b6'],
    legend: {position: 'right', textStyle: {color: 'blue', fontSize: 16}}
  };

  /** variables de tabla */
  public tableDataSource = new MatTableDataSource<object>();
  public columns: Array<object>;
  public displayedColumns: object;

  public showModal: boolean = false;
  /* Variables de la tabla de c1 */
  public datatabla = [];
  public columna = ['anio','mes','ncuentas','saldo','cvig','cven','imor']
  public columna2 = ['per','saldCap','numeroCred']
  // grafica
  public graficatabla1 = false;
  // modal
  public  graficatabla1_modal = false;
  /** Variables de las graficas de c2 */
  public dataSalCapBar = [];
  public dataSalCapLin = [];
  public dataNumCreBar = [];
  public dataNumCreLin = [];
  /** Variables de las graficas c1 */
  public dataBarraCom = [];
  public dataLineaCom = [];
  public dataBarraComNcuen = [];
  public dataLinealNcuen = [];
  public dataLienalSal = [];

  /** Variables de las graficas */
  // C1
  public graficacompuesta = false;
  public graficabarras = true;
  public graficalineal = false;
  public graficaarea = false;
  // C2
  public graficatabla = true;
  public graficabarrasSal = false;
  public graficabarrasCre = false;
  public graficalinealSal = false;
  public graficalinealCre = false;
  /** Modal */
  // C1
  public graficabarras_modal = false;
  public graficalineal_modal = false;
  public graficacompuesta_modal = false;
  public graficaarea_modal = false;
  // C2
  public graficatabla_modal = false;
  public graficabarrasSal_modal = false;
  public graficabarrasCre_modal = false;
  public graficalinealSal_modal = false;
  public graficalinealCre_modal = false;
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /** Variables tarjetas */
  public cartera_vigente = 0;
  public cartera_vencida = 0;
  public indice_morosidad = 0;
  public cartTot = 0;

  public cartera_vigente_tot = [];
  public cartera_vencida_tot = [];
  public indice_morosidad_tot = [];

  public perspectivaTi = "";
  public perGen = "";
  public perSer = "";
  public perPro = "";
  public perEst = "";
  public perMun = "";

  public saldo = true;
  public numer = false;

  public dataBarraNum = [];
  public dataLinealNum = [];

  public periodicidad = [{name: 'Mensual', check: true},{name: 'Bimestral', check: false},{name: 'Semestral', check: false}];
  public puedeFiltrarPorPeriodicidad = true;
  public cantidad = 'quince';
  public masQuin = false;

  /** constructor y onInit (se ejecutan cada que es mandado a llamar el componente */
  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private tendenciaService: ColocacionTendenciaService,
    private excelservices: ExcelService,
  ) {
    this.getFiltros();
  }
  ngOnInit(): void {}
  /** funciones de filtros */
  private getFiltros(): void {
    this.filtrosService.getAniosMesesDisponibles('radiografia').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAniosMesesDisponibles(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesDisponibles;
      this.mesesDisponibles.forEach(mes =>{ mes.check = true });
      //this.getGraficaPorAnio();
      this.getGraficaCom();
    });
    this.filtrosService.getSucursalesDisponibles('radiografia', 'codSucCredito-sucursalCredito').subscribe(response => {
      this.sucursalesDisponibles = this.filtrosService.formatSucursalesDisponibles(response);
      this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.length;
    });
  }
  /** Tratamiento de los datos*/
  
  public datosBarras(array){
    var aux = [];
    this.dataBarraCom = [];
    if(!this.puedeFiltrarPorSucursales){
      var i = 0;
      for(const mes of array){
        this.dataBarraCom.push({
          name: this.abrebMes(mes.key.split('-')[1]) + ' '+mes.key.split('-')[0].substring(2,4),
          value: mes.value,
          extra: mes.key
        });
        aux.push({
          name: this.abrebMes(mes.key.split('-')[1]) + ' '+mes.key.split('-')[0].substring(2,4),
          value: (this.cartera_vencida_tot[i]/(this.cartera_vencida_tot[i]+this.cartera_vigente_tot[i]))*100,
          extra: mes.key
        });
        i++;
      }
    }else{
      var i = 0;
      for(const mes of array){
        this.dataBarraCom.push({
          name: mes.key,
          value: mes.value,
          extra: mes.key
        });
        if(i < this.cartera_vencida_tot.length){
          aux.push({
            name: mes.key,
            value: (this.cartera_vencida_tot[i]/(this.cartera_vencida_tot[i]+this.cartera_vigente_tot[i]))*100,
            extra: mes.key
          });
        }else{
          aux.push({
            name: mes.key,
            value: (0/(0+this.cartera_vigente_tot[i]))*100,
            extra: mes.key
          });
        }
        i++;
      }
    }
    this.dataLineaCom = [{name: 'Índice de morosidad', series: aux }];
    this.dataLienalSal = [{name: 'Cartera total', series: this.dataBarraCom }];
  }
  public getTablaZon2(){
    var ultmeses = [];
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    for(const mes of this.mesesDisponibles){
      if(aniosSeleccionados.includes(Number(mes.year)) && mes.check === true){
        ultmeses.push(mes.value);
      }
    }
    this.tendenciaService.getTendenciaTablaV2(ultmeses).subscribe( response => {
      this.datazona2 = []
      if (response.http.code === 200) {
        var aux = response.message;
        for(var i = 0; i < aux.length; i ++){
          aux[i].value[0].value = this.dataBarraCom[i].value;
        }
        this.obtdatosZon2(aux);
        this.tableDataSource.data = this.tendenciaService.datosTablaFormatoDePeticionHaciaFormatoMatTable(aux);
        this.setColumnasTabla();
      }
    });
  }
  public getTablaZon1(array){
    this.dataBarraComNcuen = [];
    this.dataLinealNcuen = [];
    this.datatabla = [];
    for(var i = 0; i< array.length; i++){
      if(this.puedeFiltrarPorPeriodicidad){
        this.datatabla.push({
          anio: array[i].key.split('-')[0],
          mes: this.abrebMes(array[i].key.split('-')[1]),
          ncuen: array[i].value,
          saldo: this.dataBarraCom[i].value,
          cvig: this.cartera_vigente_tot[i],
          cven: this.cartera_vencida_tot[i],
          imor: ((this.cartera_vencida_tot[i]/(this.cartera_vencida_tot[i]+this.cartera_vigente_tot[i]))*100).toFixed(2)
        });
        this.dataBarraComNcuen.push({
          name: this.abrebMes(array[i].key.split('-')[1]) + ' '+array[i].key.split('-')[0].substring(2,4),
          value: array[i].value,
          extra: array[i].key
        });
      }else{
        var imora = 0, cVenc = 0;
        if(i < this.cartera_vencida_tot.length){
          cVenc = this.cartera_vencida_tot[i]
          imora = Number(((this.cartera_vencida_tot[i]/(this.cartera_vencida_tot[i]+this.cartera_vigente_tot[i]))*100).toFixed(2));
        }
        var per = this.mesesDisponibles.find(d => d.check);
        this.datatabla.push({
          anio: per.value.split('-')[0].substring(2,4) + ' ' + this.abrebMes(per.value.split('-')[1]),
          mes: array[i].key,
          ncuen: array[i].value,
          saldo: this.dataBarraCom[i].value,
          cvig: this.cartera_vigente_tot[i],
          cven: cVenc,
          imor: imora
        });
        this.dataBarraComNcuen.push({
          name: array[i].key,
          value: array[i].value,
          extra: array[i].key
        });
      }
    }
    this.dataLinealNcuen.push({name: 'Número de cuentas', series: this.dataBarraComNcuen});
    this.ySacaleMinCuen();
  }
  public obtdatosZon2(array: any){
    this.datazona2 = [];
    this.dataSalCapBar = [];
    this.dataSalCapLin = [];
    this.dataNumCreBar = [];
    this.dataNumCreLin = [];
    for(const dat of array){
      if(this.datazona2.length == 0 ){
        var anio = dat['key'].split('-')[0];   
        this.datazona2.push({
          name: anio,
          anioN: anio,
          salC: undefined,
          numC: undefined,
          check: true,
          anio: true,
          exp: true,
        });
        var mes = dat['key'].split('-')[1];
        var ser = dat['value']; 
        this.datazona2.push({
          name: this.abrebMes(mes),
          anioN: anio,
          salC: (ser[0].value).toFixed(2),
          numC: (ser[1].value),
          check: true,
          anio: false,
          exp: false,
        });
      }else if(anio ==  dat['key'].split('-')[0]){
        var ser = dat['value']; 
        var mes = dat['key'].split('-')[1];
        this.datazona2.push({
          name: this.abrebMes(mes),
          anioN: anio,
          salC: (ser[0].value).toFixed(2),
          numC: (ser[1].value),
          check: true,
          anio: false,
          exp: false,
        });
      }else{
        var anio = dat['key'].split('-')[0];
        var mes = dat['key'].split('-')[1];
        this.datazona2.push({
          name: anio,
          anioN: anio,
          salC: undefined,
          numC: undefined,
          check: true,
          anio: true,
          exp: true,
        });
        var ser = dat['value']; 
        this.datazona2.push({
          name: this.abrebMes(mes),
          anioN: anio,
          salC: (ser[0].value).toFixed(2),
          numC: (ser[1].value),
          check: true,
          anio: false,
          exp: false,
        });
      }
      this.dataSalCapBar.push({
        name: this.abrebMes(dat.key.split('-')[1]) + ' '+dat.key.split('-')[0].substring(2,4),
        value: ser[0].value
      });
      this.dataNumCreBar.push({
        name: this.abrebMes(dat.key.split('-')[1]) + ' '+dat.key.split('-')[0].substring(2,4),
        value: ser[1].value
      });

    }
    this.dataSalCapLin.push({name: 'Cartera total', series: this.dataSalCapBar});
    this.dataNumCreLin.push({name: 'Número de créditos', series: this.dataNumCreBar});
  }
  private setColumnasTabla(): void {
    const rowExample = this.tableDataSource.data[0];
    var titulos = ['PERIODO', ' SALDO CAPITAL', 'NÚMERO DE CRÉDITO']
    var i = 0;
    this.columns = [];
    // tslint:disable-next-line:forin
    for (const property in rowExample) {
      this.columns.push({
        columnDef: property, header: titulos[i].toString().toUpperCase(),
        cell: (element: object) => `${element[property]}`,
      });
      i++;
    }
    // @ts-ignore
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }
  /** desactivación de filtros */
  public sinFil(){
    if(this.puedeFiltrarPorPeriodicidad)
      return ''
    return 'w3-disabled'
  }
  public hizoPers(): string {
    return this.perspectivaTi == "" ? 'w3-rest' : 'w3-rest w3-disabled';
  }
  /** eventos */
  public cliccomp(e){
    this.customColors = [{ name: e.name, value: '#9E9E9E'}];
    if(!this.puedeFiltrarPorSucursales || this.puedeFiltrarPorPeriodicidad){
      this.aniosDisponibles.forEach(an => { an.name === e.extra.split('-')[0]? an.check = true : an.check = false })
      this.mesesDisponibles.forEach(mes => { mes.value === e.extra? mes.check = true : mes.check= false  })
      this.puedeFiltrarPorSucursales = true;
      this.puedeFiltrarPorPerspectivas = true;
      this.getActualiClic(e.extra);
    }else if(this.perspectivaTi === ''){
      this.puedeFiltrarPorPerspectivas = true;
      this.sucursalesDisponibles.forEach(suc => { suc.name === e.name ? suc.check = true : suc.check = false});
      this.sucursalSeleccionada = this.sucursalesDisponibles.find(e => e.check);
      this.getActualiClicSuc();
    }else{
      switch(this.perspectivaTi){
        case 'Género':
          this.perGen = e.name;
          this.getActualiClicPer('sexo.keyword');
        break;
        case 'Servicio':
          this.perSer = e.name;
          this.getActualiClicPer('servicio.keyword');
        break;
        case 'Producto':
          this.perPro = e.name;
          this.getActualiClicPer('nombreProducto.keyword');
        break;
        case 'Estatus':
          this.perEst = e.name;
          this.getActualiClicPer('estado1.keyword');
        break;
        case 'Municipio':
          this.perMun = e.name;
          this.getActualiClicPer('municipio1.keyword');
        break;
      }
    }
  }
  
  // Cambio de periodicidad
  public camPer(e){
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    this.periodicidad.forEach(per =>{ per.name === e.name? per.check = true : per.check = false });
    switch(e.name){
      case 'Mensual':
        this.mesesDisponibles.forEach(mes => { mes.check = false });
        this.mesesDisponibles.forEach(mes => { if(aniosSeleccionados.includes(Number(mes.year))){mes.check = true} });
      break;
      case 'Bimestral':
        this.mesesDisponibles.forEach(mes => { mes.check = false });
        for(var i = 1; i< this.mesesDisponibles.length; i+=2){
          if(aniosSeleccionados.includes(Number(this.mesesDisponibles[i].year))){
            this.mesesDisponibles[i].check = true;
          }
        }
      break;
      case 'Semestral':
        this.mesesDisponibles.forEach(mes => { mes.check = false });
        for(var i = 5; i< this.mesesDisponibles.length; i+=6){
          if(aniosSeleccionados.includes(Number(this.mesesDisponibles[i].year))){
            this.mesesDisponibles[i].check = true;
          }
        }
      break;
    }
    this.puedeFiltrarPorSucursales = false;
    this.puedeFiltrarPorPerspectivas = false;
    this.getGraficaCom();
  }
  // Cambio de años
  public anioChangeListener(anioClick: object): void {
    this.filtrosService.changeElementCheckStatus(anioClick, this.aniosDisponibles);
    this.puedeFiltrarPorSucursales = false;
    this.puedeFiltrarPorPerspectivas = false;
    this.sucursalesDisponibles.forEach(suc => suc.check = false);
    this.sucursalSeleccionada = undefined;
    this.consolidado = true;
    this.perspectivaTi = '';
    this.perEst = '';
    this.perGen = '';
    this.perMun = '';
    this.perPro = '';
    this.perSer = '';
    this.masQuin = false;
    this.mesesDisponibles.forEach(mes => { mes.check = false });
    for(const per of this.periodicidad){
      if(per.check)
        this.camPer(per);
    }
    //this.getGraficaPorAnio();
    this.getGraficaCom();
    //this.mesesDeAnioDisponibles.forEach(anio => anio.check = false);
    this.perspectivaTi = '';
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  /** Actualización de tarjetas por mes y año*/
  private getActualiClic(fecha): void {
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    var suma = [],suma2 = [];
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];

    this.tendenciaService.sucursales(aniosSeleccionados,this.mesesDisponibles, fecha).subscribe(response =>{
      if(response.http.code === 200){
        var auxOrd = this.ordDatsuc(response.message)
        this.sucursalesDisponibles = this.filtrosService.formatSucursalesDisponibles(response);
        this.sucursalesDisponibles.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.sucursalesDisponibles2 = new MatTableDataSource(this.sucursalesDisponibles);
      }
    });
    
    this.tendenciaService.salCapCom(aniosSeleccionados, this.mesesDisponibles, '', fecha).subscribe( response => {
      if(response.http.code === 200){
        suma = response.message;
        this.tendenciaService.intVenCom(aniosSeleccionados, this.mesesDisponibles, '', fecha).subscribe( response => {
          if(response.http.code === 200){
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            this.tendenciaService.intVigCom(aniosSeleccionados, this.mesesDisponibles, '', fecha).subscribe( response => {
              if(response.http.code === 200){
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                this.tendenciaService.intMorCom(aniosSeleccionados, this.mesesDisponibles, '', fecha).subscribe( response => {
                  if(response.http.code === 200){
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    this.tendenciaService.salCapCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA', fecha).subscribe( response => {
                      if(response.http.code === 200){
                        suma2 = response.message;
                        this.tendenciaService.intVenCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA', fecha).subscribe( response => {
                          if(response.http.code === 200){
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            this.tendenciaService.intVigCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA', fecha).subscribe( response => {
                              if(response.http.code === 200){
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                this.tendenciaService.intMorCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA', fecha).subscribe( response => {
                                  if(response.http.code === 200){
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    this.cartera_vencida = suma2[suma2.length-1].value;
                                    this.cartera_vigente = suma[suma.length-1].value.toFixed(2) - suma2[suma2.length-1].value.toFixed(2);
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;

                                    this.getTablaZon2Act(fecha);
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.actsub()
    this.actsub2();
    this.actsub3();
  }
  public getTablaZon2Act(fecha){
    var ultmeses = [];
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    for(const mes of this.mesesDisponibles){
      if(aniosSeleccionados.includes(Number(mes.year)) && mes.check === true){
        ultmeses.push(mes.value);
      }
    }
    this.tendenciaService.getTendenciaTablaV2(ultmeses, fecha).subscribe( response => {
      this.datazona2 = []
      if (response.http.code === 200) {
        var aux = response.message, pos = 0, i =0;
        this.dataBarraCom.forEach(dat => { if(dat.extra == ultmeses){ pos = i;} i++; });
        for(var i = 0; i < aux.length; i ++){
          aux[i].value[0].value = this.dataBarraCom[pos].value;
        }
        this.obtdatosZon2(aux);
        this.tableDataSource.data = this.tendenciaService.datosTablaFormatoDePeticionHaciaFormatoMatTable(aux);
        this.setColumnasTabla();
      }
    });
  }
  /**Actualización de tarjetas sucursales */
  private getActualiClicSuc(){
    var mesSeleccionado = [], sucursales = [];
    for(const mes of this.mesesDisponibles){
      if(mes.check)
        mesSeleccionado.push(mes.value);
    }
    for(const suc of this.sucursalesDisponibles){
      if(suc.check)
        sucursales.push(suc.name);
    }
    var suma = [];

    this.tendenciaService.saldoCapSuc(mesSeleccionado, sucursales, '').subscribe( response => {
      if (response.http.code === 200) {
        var auxOrd = this.ordDatsuc(response.message)
        suma = auxOrd;
        // @ts-ignore
        this.tendenciaService.intVigSuc(mesSeleccionado, sucursales, '').subscribe( response => {
          if (response.http.code === 200) {
            auxOrd = this.ordDatsuc(response.message)
            for(var i = 0; i < suma.length; i++){
              suma[i].value += auxOrd[i].value;
            }
            // @ts-ignore
            this.tendenciaService.intVenSuc(mesSeleccionado, sucursales, '').subscribe( response => {
              if (response.http.code === 200) {
                auxOrd = this.ordDatsuc(response.message)
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += auxOrd[i].value;
                }
                // @ts-ignore
                this.tendenciaService.intMorSuc(mesSeleccionado, sucursales, '').subscribe( response => {
                  if (response.http.code === 200) {
                    auxOrd = this.ordDatsuc(response.message)
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += auxOrd[i].value;
                    }
                    var aux = [];
                    for(const d of suma){
                      for(const suc of this.sucursalesDisponibles){
                        if( d.key == suc.name && suc.check ){
                          aux.push(d);
                        }
                      }
                    }
                    var suma2 = [];
                    //@ts-ignore
                    this.tendenciaService.saldoCapSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        auxOrd = this.ordDatsuc(response.message)
                        suma2 = response.message;
                        // @ts-ignore
                        this.tendenciaService.intVigSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            auxOrd = this.ordDatsuc(response.message)
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += auxOrd[i].value;
                            }
                            // @ts-ignore
                            this.tendenciaService.intVenSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                auxOrd = this.ordDatsuc(response.message)
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += auxOrd[i].value;
                                }
                                // @ts-ignore
                                this.tendenciaService.intMorSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    auxOrd = this.ordDatsuc(response.message)
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += auxOrd[i].value;
                                    }
                                    var aux2 = [];
                                    for(const d of suma2){
                                      for(const suc of this.sucursalesDisponibles){
                                        if( d.key === suc.name && suc.check ){
                                          aux2.push(d);
                                        }
                                      }
                                    }

                                    var tot = 0;
                                    aux.forEach(dat => tot += dat.value)

                                    this.cartera_vencida = 0;
                                    this.cartera_vigente = 0;
                                    aux2.forEach(sum => this.cartera_vencida += sum.value);
                                    for(var i = 0; i < aux.length; i++){
                                      if(i < aux2.length){
                                        this.cartera_vigente += (aux[i].value - aux2[i].value);
                                      }else{
                                        this.cartera_vigente += (aux[i].value);
                                      }
                                    }
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                    this.getTablaZon2ActSuc();
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public getTablaZon2ActSuc(){
    var ultmeses = [];
    ultmeses.push(this.mesesDisponibles.find(m => m.check).value);
    this.tendenciaService.getTendenciaTablaSuc(ultmeses, this.sucursalesDisponibles).subscribe( response => {
      if (response.http.code === 200) {
        var auxOrd = this.ordDatsuc(response.message)
        var aux = [];
        for(const d of auxOrd){
          for(const suc of this.sucursalesDisponibles){
            if( d.key === suc.name && suc.check ){
              aux.push(d);
            }
          }
        }
        auxOrd = aux;
        aux = [];
        var i =0, pos = 0;
        if(auxOrd.length === 1){
          //@ts-ignore
          this.dataBarraCom.forEach(dat => { if(this.sucursalSeleccionada.name === dat.name){pos  = i} i++; });
        }
        for(var i = 0; i < auxOrd.length; i ++){
          if(auxOrd.length != 1)
            aux.push({name: auxOrd[i].key, saldo: this.dataBarraCom[i].value, ncuen: auxOrd[i].value});
          else
            aux.push({name: auxOrd[i].key, saldo: this.dataBarraCom[pos].value, ncuen: auxOrd[i].value});
        }
        var sal=0, ncuen = 0;
        aux.forEach(dat =>{ sal+= dat.saldo; ncuen+= dat.ncuen });
        this.datazona2[1].salC = sal.toFixed(2);
        this.datazona2[1].numC = ncuen;
        this.ySacaleMinCuen();
      }
    });
  }
  /** Actualizacion de tarjetas por perspectiva */
  public getActualiClicPer(perspectiva){
    this.puedeFiltrarPorPeriodicidad = false;
    switch(perspectiva.split('.')[0]){
      case 'sexo':
        this.perspectivaTi = 'Género';
      break;
      case 'servicio':
        this.perspectivaTi = 'Servicio';
      break;
      case 'nombreProducto':
        this.perspectivaTi = 'Producto';
      break;
      case 'estado1':
        this.perspectivaTi = 'Estatus';
      break;
      case 'municipio1':
        this.perspectivaTi = 'Municipio';
      break;
    }
    var suma = [], suma2 = [], sucursal = undefined;
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];
    var mesSeleccionado;
    for(const mes of this.mesesDisponibles){
      if(mes.check)
        mesSeleccionado = mes.value;
    }
    if(this.sucursalSeleccionada){
      //@ts-ignore
      sucursal = this.sucursalSeleccionada.name
    }
    this.tendenciaService.saldoCapPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
      if (response.http.code === 200) {
        var ord = this.ordDatper(response.message);
        suma = ord;
        this.tendenciaService.intVigPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
          if (response.http.code === 200) {
            ord = this.ordDatper(response.message);
            for(var i = 0; i < suma.length; i++){
              suma[i].value += ord[i].value;
            }
            this.tendenciaService.intVenPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
              if (response.http.code === 200) {
                ord = this.ordDatper(response.message);
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += ord[i].value;
                }
                this.tendenciaService.intMorPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
                  if (response.http.code === 200) {
                    ord = this.ordDatper(response.message);
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += ord[i].value;
                    }

                    this.tendenciaService.saldoCapPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                      if (response.http.code === 200) {
                        ord = this.ordDatper(response.message);
                        suma2 = ord
                        this.tendenciaService.intVigPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                          if (response.http.code === 200) {
                            ord = this.ordDatper(response.message);
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += ord[i].value;
                            }
                            this.tendenciaService.intVenPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                              if (response.http.code === 200) {
                                ord = this.ordDatper(response.message);
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += ord[i].value;
                                }
                                this.tendenciaService.intMorPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                                  if (response.http.code === 200) {
                                    ord = this.ordDatper(response.message);
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += ord[i].value;
                                    }

                                    this.cartera_vencida = 0;
                                    this.cartera_vigente = 0;
                                    this.indice_morosidad = 0;
                                    suma2.forEach(per => this.cartera_vencida += per.value);
                                    var j=0;
                                    for(i=0; i< suma.length; i++){
                                      for(const ven of suma2){
                                        if(suma[i].key === ven.key){
                                          this.cartera_vigente += suma[i].value - ven.value;
                                          j++;
                                        }
                                      }
                                      if(j === 0){
                                        this.cartera_vigente += suma[i].value;
                                      }
                                      j=0;
                                    }
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                    this.getTablaZona2ActPer();
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public getTablaZona2ActPer(){
    var perspectiva = '', buscar = '';
    switch(this.perspectivaTi){
      case 'Género':
        perspectiva = 'sexo.keyword';
        buscar = this.perGen;
      break;
      case 'Servicio':
        perspectiva = 'servicio.keyword';
        buscar = this.perSer;
      break;
      case 'Producto':
        perspectiva = 'nombreProducto.keyword';
        buscar = this.perPro;
      break;
      case 'Estatus':
        perspectiva = 'estado1.keyword';
        buscar = this.perEst;
      break;
      case 'Municipio':
        perspectiva = 'municipio1.keyword';
        buscar = this.perMun;
      break;
    }
    var sucursal = undefined;
    var mesSeleccionado;
    for(const mes of this.mesesDisponibles){
      if(mes.check)
        mesSeleccionado = mes.value;
    }
    if(this.sucursalSeleccionada){
      //@ts-ignore
      sucursal = this.sucursalSeleccionada.name
    }
    this.tendenciaService.getTendenciaTablaPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun,sucursal).subscribe(response =>{
      if (response.http.code === 200) {
        var ord = this.ordDatper(response.message)
        var aux = [];
        if(ord.length > 15 && this.cantidad == 'quince'){
          this.masQuin = true;
          for(var i = 0; i < 15; i++){
            aux.push(ord[i]);
          }
          ord = aux;
        }else if ( ord.length > 15 && this.cantidad === 'all' ){
          this.masQuin = true;
        }else{
          this.masQuin = false;
        }
        aux = [];
        var i =0, pos = 0;
        if(buscar != ''){
          //@ts-ignore
          this.dataBarraCom.forEach(dat => { if(buscar === dat.name){pos  = i} i++; });
        }
        for(var i = 0; i < ord.length; i ++){
          if(ord.length != 1)
            aux.push({name: ord[i].key, saldo: this.dataBarraCom[i].value, ncuen: ord[i].value});
          else
            aux.push({name: ord[i].key, saldo: this.dataBarraCom[pos].value, ncuen: ord[i].value});
        }
        var sal=0, ncuen = 0;
        aux.forEach(dat =>{ sal+= dat.saldo; ncuen+= dat.ncuen });
        this.datazona2[1].salC = sal.toFixed(2);
        this.datazona2[1].numC = ncuen;
        this.ySacaleMinCuen();
      }
    });
  }
  /** Extender y contraer en la tabla */
  public clictabla(anio: any,zon){
    switch(zon){
      case 2:
        for(const data of this.datazona2){
          if(data.anioN == anio.name && data.anio){
            data.exp = !data.exp;
          }
          if(data.anioN == anio.name && !data.anio){
            data.check = !data.check;
          }
        }
      break;
      case 1:
        for(const data of this.datatabla){
          if(data.anioN == anio.name && data.anio){
            data.exp = !data.exp;
          }
          if(data.anioN == anio.name && !data.anio){
            data.check = !data.check;
          }
        }
      break;
    }
  }
  public cambioCant(dat: any){
    switch(dat){
      case 1:
          this.cantidad = 'quince';
      break;
      case 2:
          this.cantidad = 'all';
      break;
    }
    if(this.perspectivaTi == 'Municipio')
      this.getGraficaPorPerspectiva('municipio1.keyword');
    else
      this.getGraficaPorPerspectiva('nombreProducto.keyword');
  }
  /** Consultas por mes y año */
  private getGraficaCom(): void {
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    var suma = [],suma2 = [];
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];
    
    this.tendenciaService.salCapCom(aniosSeleccionados, this.mesesDisponibles, '').subscribe( response => {
      if(response.http.code === 200){
        suma = response.message;
        this.tendenciaService.intVenCom(aniosSeleccionados, this.mesesDisponibles, '').subscribe( response => {
          if(response.http.code === 200){
            for(var i = 0; i < suma.length; i++){
              suma[i].value += response.message[i].value;
            }
            this.tendenciaService.intVigCom(aniosSeleccionados, this.mesesDisponibles, '').subscribe( response => {
              if(response.http.code === 200){
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += response.message[i].value;
                }
                this.tendenciaService.intMorCom(aniosSeleccionados, this.mesesDisponibles, '').subscribe( response => {
                  if(response.http.code === 200){
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += response.message[i].value;
                    }
                    this.tendenciaService.salCapCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA').subscribe( response => {
                      if(response.http.code === 200){
                        suma2 = response.message;
                        this.tendenciaService.intVenCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA').subscribe( response => {
                          if(response.http.code === 200){
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += response.message[i].value;
                            }
                            this.tendenciaService.intVigCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA').subscribe( response => {
                              if(response.http.code === 200){
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += response.message[i].value;
                                }
                                this.tendenciaService.intMorCom(aniosSeleccionados, this.mesesDisponibles, 'VENCIDA').subscribe( response => {
                                  if(response.http.code === 200){
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += response.message[i].value;
                                    }
                                    this.cartera_vencida = suma2[suma2.length-1].value;
                                    this.cartera_vigente = suma[suma.length-1].value.toFixed(2) - suma2[suma2.length-1].value.toFixed(2);
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;

                                    suma2.forEach(dat => this.cartera_vencida_tot.push(dat.value))
                                    for(var i = 0; i < suma.length; i++){
                                      this.cartera_vigente_tot.push(suma[i].value - suma2[i].value);
                                    }
                                    this.datosBarras(suma);
                                    this.getTablaZon2();
                                    this.ySacaleMin();
                                    this.esGraficaDeBarras = true;
                                    var ultmeses = [];
                                    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
                                    for(const mes of this.mesesDisponibles){
                                      if(aniosSeleccionados.includes(Number(mes.year)) && mes.check === true){
                                        ultmeses.push(mes.value);
                                      }
                                    }
                                    this.tendenciaService.getTendenciaTabla(ultmeses).subscribe( response => {
                                      if(response.http.code === 200){
                                        this.getTablaZon1(response.message);
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  /** Consultas por sucursales */
  private getGraficaComSuc(): void {
    var mesSeleccionado = [], sucursales = [];
    for(const mes of this.mesesDisponibles){
      if(mes.check)
        mesSeleccionado.push(mes.value);
    }
    for(const suc of this.sucursalesDisponibles){
      if(suc.check)
        sucursales.push(suc.name);
    }
    var suma = [],suma2 = [];
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];

    this.tendenciaService.saldoCapSuc(mesSeleccionado, sucursales, '').subscribe( response => {
      if (response.http.code === 200) {
        var auxOrd = this.ordDatsuc(response.message)
        suma = auxOrd;
        // @ts-ignore
        this.tendenciaService.intVigSuc(mesSeleccionado, sucursales, '').subscribe( response => {
          if (response.http.code === 200) {
            auxOrd = this.ordDatsuc(response.message)
            for(var i = 0; i < suma.length; i++){
              suma[i].value += auxOrd[i].value;
            }
            // @ts-ignore
            this.tendenciaService.intVenSuc(mesSeleccionado, sucursales, '').subscribe( response => {
              if (response.http.code === 200) {
                auxOrd = this.ordDatsuc(response.message)
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += auxOrd[i].value;
                }
                // @ts-ignore
                this.tendenciaService.intMorSuc(mesSeleccionado, sucursales, '').subscribe( response => {
                  if (response.http.code === 200) {
                    auxOrd = this.ordDatsuc(response.message)
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += auxOrd[i].value;
                    }
                    var aux = [];
                    for(const d of suma){
                      for(const suc of this.sucursalesDisponibles){
                        if( d.key == suc.name && suc.check ){
                          aux.push(d);
                        }
                      }
                    }
                    var suma2 = [];
                    //@ts-ignore
                    this.tendenciaService.saldoCapSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                      if (response.http.code === 200) {
                        auxOrd = this.ordDatsuc(response.message)
                        suma2 = response.message;
                        // @ts-ignore
                        this.tendenciaService.intVigSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                          if (response.http.code === 200) {
                            auxOrd = this.ordDatsuc(response.message)
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += auxOrd[i].value;
                            }
                            // @ts-ignore
                            this.tendenciaService.intVenSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                              if (response.http.code === 200) {
                                auxOrd = this.ordDatsuc(response.message)
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += auxOrd[i].value;
                                }
                                // @ts-ignore
                                this.tendenciaService.intMorSuc(mesSeleccionado, sucursales, 'VENCIDA').subscribe( response => {
                                  if (response.http.code === 200) {
                                    auxOrd = this.ordDatsuc(response.message)
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += auxOrd[i].value;
                                    }
                                    var aux2 = [];
                                    for(const d of suma2){
                                      for(const suc of this.sucursalesDisponibles){
                                        if( d.key === suc.name && suc.check ){
                                          aux2.push(d);
                                        }
                                      }
                                    }

                                    var tot = 0;
                                    aux.forEach(dat => tot += dat.value)

                                    this.cartera_vencida = 0;
                                    this.cartera_vigente = 0;
                                    aux2.forEach(sum => this.cartera_vencida += sum.value);
                                    for(var i = 0; i < aux.length; i++){
                                      if(i < aux2.length){
                                        this.cartera_vigente += (aux[i].value - aux2[i].value);
                                      }else{
                                        this.cartera_vigente += (aux[i].value);
                                      }
                                    }
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                    var j=0;
                                    for(i=0; i< aux.length; i++){
                                      for(const ven of aux2){
                                        if(aux[i].key === ven.key){
                                          this.cartera_vigente_tot.push(aux[i].value - ven.value);
                                          this.cartera_vencida_tot.push(ven.value)
                                          j++;
                                        }
                                      }
                                      if(j === 0){
                                        this.cartera_vigente_tot.push(aux[i].value);
                                        this.cartera_vencida_tot.push(0)
                                      }
                                      j=0;
                                    }
                                    this.datosBarras(aux);
                                    this.getTablaZon2ActSuc();
                                    this.ySacaleMin();
                                    this.esGraficaDeBarras = true;
                                    var ultmeses = [];
                                    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
                                    for(const mes of this.mesesDisponibles){
                                      if(aniosSeleccionados.includes(Number(mes.year)) && mes.check === true){
                                        ultmeses.push(mes.value);
                                      }
                                    }
                                    this.tendenciaService.getTendenciaTablaSuc(mesSeleccionado, sucursales).subscribe(response =>{
                                      if (response.http.code === 200) {
                                        auxOrd = this.ordDatsuc(response.message)
                                        var aux = [];
                                        for(const d of response.message){
                                          for(const suc of this.sucursalesDisponibles){
                                            if( d.key == suc.name && suc.check ){
                                              aux.push(d);
                                            }
                                          }
                                        }
                                        this.getTablaZon1(aux);
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  /** Consultas por perspectiva */
  public getGraficaPorPerspectiva(perspectiva){
    this.puedeFiltrarPorPeriodicidad = false;
    switch(perspectiva.split('.')[0]){
      case 'sexo':
        this.perspectivaTi = 'Género';
      break;
      case 'servicio':
        this.perspectivaTi = 'Servicio';
      break;
      case 'nombreProducto':
        this.perspectivaTi = 'Producto';
      break;
      case 'estado1':
        this.perspectivaTi = 'Estatus';
      break;
      case 'municipio1':
        this.perspectivaTi = 'Municipio';
      break;
    }
    this.xAxisLabel = this.perspectivaTi;
    var suma = [], suma2 = [], sucursal = undefined;
    this.cartera_vencida_tot = [];
    this.cartera_vigente_tot = [];
    var mesSeleccionado;
    for(const mes of this.mesesDisponibles){
      if(mes.check)
        mesSeleccionado = mes.value;
    }
    if(this.sucursalSeleccionada){
      //@ts-ignore
      sucursal = this.sucursalSeleccionada.name
    }
    this.tendenciaService.saldoCapPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
      if (response.http.code === 200) {
        var ord = this.ordDatper(response.message);
        suma = ord;
        this.tendenciaService.intVigPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
          if (response.http.code === 200) {
            ord = this.ordDatper(response.message);
            for(var i = 0; i < suma.length; i++){
              suma[i].value += ord[i].value;
            }
            this.tendenciaService.intVenPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
              if (response.http.code === 200) {
                ord = this.ordDatper(response.message);
                for(var i = 0; i < suma.length; i++){
                  suma[i].value += ord[i].value;
                }
                this.tendenciaService.intMorPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, '', sucursal).subscribe( response => {
                  if (response.http.code === 200) {
                    ord = this.ordDatper(response.message);
                    for(var i = 0; i < suma.length; i++){
                      suma[i].value += ord[i].value;
                    }

                    this.tendenciaService.saldoCapPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                      if (response.http.code === 200) {
                        ord = this.ordDatper(response.message);
                        suma2 = ord
                        this.tendenciaService.intVigPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                          if (response.http.code === 200) {
                            ord = this.ordDatper(response.message);
                            for(var i = 0; i < suma2.length; i++){
                              suma2[i].value += ord[i].value;
                            }
                            this.tendenciaService.intVenPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                              if (response.http.code === 200) {
                                ord = this.ordDatper(response.message);
                                for(var i = 0; i < suma2.length; i++){
                                  suma2[i].value += ord[i].value;
                                }
                                this.tendenciaService.intMorPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun, 'VENCIDA', sucursal).subscribe( response => {
                                  if (response.http.code === 200) {
                                    ord = this.ordDatper(response.message);
                                    for(var i = 0; i < suma2.length; i++){
                                      suma2[i].value += ord[i].value;
                                    }
                                    var aux = []
                                    if(suma.length > 15 && this.cantidad == 'quince'){
                                      this.masQuin = true;
                                      for(var i = 0; i < 15; i++){
                                        aux.push(suma[i]);
                                      }
                                      suma = aux;
                                    }else if ( ord.length > 15 && this.cantidad === 'all' ){
                                      this.masQuin = true;
                                    }else{
                                      this.masQuin = false;
                                    }
                                    this.cartera_vencida = 0;
                                    this.cartera_vigente = 0;
                                    suma2.forEach(per => this.cartera_vencida += per.value);
                                    var j=0;
                                    for(i=0; i< suma.length; i++){
                                      for(const ven of suma2){
                                        if(suma[i].key === ven.key){
                                          this.cartera_vigente += suma[i].value - ven.value;
                                          j++;
                                        }
                                      }
                                      if(j === 0){
                                        this.cartera_vigente += suma[i].value;
                                      }
                                      j=0;
                                    }
                                    //suma2.forEach(per => this.cartera_vencida_tot.push(per.value));
                                    this.indice_morosidad = (this.cartera_vencida/(this.cartera_vencida+this.cartera_vigente))*100;
                                    j=0;
                                    for(i=0; i< suma.length; i++){for(const ven of suma2){
                                      if(suma[i].key === ven.key){
                                        this.cartera_vigente_tot.push(suma[i].value - ven.value);
                                        this.cartera_vencida_tot.push(ven.value)
                                        j++;
                                      }
                                    }
                                    if(j === 0){
                                      this.cartera_vigente_tot.push(suma[i].value);
                                      this.cartera_vencida_tot.push(0)
                                      }
                                      j=0;
                                    }
                                    this.datosBarras(suma);
                                    this.getTablaZona2ActPer();
                                    this.ySacaleMin();
                                    this.esGraficaDeBarras = true;
                                    this.tendenciaService.getTendenciaTablaPer(perspectiva, mesSeleccionado, this.perGen, this.perSer, this.perPro, this.perEst, this.perMun,sucursal).subscribe(response =>{
                                      if (response.http.code === 200) {
                                        ord = this.ordDatper(response.message);
                                        var aux = [];
                                        if(ord.length > 15 && this.cantidad == 'quince'){
                                          this.masQuin = true;
                                          for(var i = 0; i < 15; i++){
                                            aux.push(ord[i]);
                                          }
                                          ord = aux;
                                        }else if ( ord.length > 15 && this.cantidad === 'all' ){
                                          this.masQuin = true;
                                        }else{
                                          this.masQuin = false;
                                        }
                                        this.getTablaZon1(ord);
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  /** */
  public ordDatsuc(array){
    array.sort(function (a, b) {
      if (Number(a.id_key) > Number(b.id_key)) {
        return 1;
      }
      if (Number(a.id_key) < Number(b.id_key)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  public ordDatper(array){
    array.sort(function (a, b) {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  /** Consultas por perspectiva */
  public Complete(){
    var i = 0;
    if(this.mesesDeAnioDisponibles != undefined){
      for(const an of this.mesesDeAnioDisponibles){
        if(an.check)
          i++;
      }
      if(i < (this.mesesDeAnioDisponibles.length))
        return false;
      else
        return true;
    }else{
      return false;
    }
  }
  public nummessel(){
    var i = 0;
    if(this.mesesDeAnioDisponibles != undefined){
      for(const an of this.mesesDeAnioDisponibles){
        if(an.check)
          i++;
      }
      if(i == (this.mesesDeAnioDisponibles.length) || i == 1)
        return true;
      else
        return false;
    }else{
      return false;
    }
  }
  // MESES
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    if (this.anioSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.aniosDisponibles, false);
      // @ts-ignore
      this.anioSeleccionado.check = true;
    }
    this.filtrosService.changeElementCheckStatus(mesClick, elementArray);
    this.puedeFiltrarPorSucursales = (this.mesesDeAnioDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorPerspectivas = false;
    //this.getGraficaPorMes();
    if (this.puedeFiltrarPorSucursales) {
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.perspectivaTi = '';
    this.sucursalSeleccionada = [];
    for(const suc of this.sucursalesDisponibles){
      suc.check = false;
    }
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public mesChangeAll(valor: boolean): void{
    this.mesesDeAnioDisponibles.forEach(anio => anio.check = valor);
    if (this.anioSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.aniosDisponibles, false);
      // @ts-ignore
      this.anioSeleccionado.check = true;
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.puedeFiltrarPorSucursales = (this.mesesDeAnioDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorPerspectivas = false;
    //this.getGraficaPorMes();
    if (this.puedeFiltrarPorSucursales) {
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.perspectivaTi = '';
    this.sucursalSeleccionada = [];
    for(const suc of this.sucursalesDisponibles){
      suc.check = false;
    }
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  // SUCURSALES
  public consolidadoChangeListener(): void {
    this.consolidado = !this.consolidado;
    if (!this.consolidado) {
      for(const suc of this.sucursalesDisponibles){
        if(suc.name != 'ZAPOTLANEJO')
          suc.check = true;
      }
      for(const suc of this.sucursalesDisponibles2.filteredData){
        if(suc.name != 'ZAPOTLANEJO')
          suc.check = true;
      }
      if (this.mesSeleccionado) {
        this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
        // @ts-ignore
        this.mesSeleccionado.check = true;
      }
      this.puedeFiltrarPorPeriodicidad = false;
      this.puedeFiltrarPorPerspectivas = false;
      this.xAxisLabel = 'Sucursal';
      this.getGraficaComSuc();
    }else {
      this.xAxisLabel = 'Periodo';
      this.puedeFiltrarPorPeriodicidad = true;
      this.sucursalesDisponibles.forEach(suc => suc.check = false);
      this.sucursalSeleccionada = undefined;
      this.getGraficaCom();
    }
    this.perspectivaTi = '';
    this.actsub();
    this.actsub2();
    this.actsub3();
  }
  public sucursalChangeListener(sucursalClick: object): void {
    if (this.mesSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
      // @ts-ignore
      this.mesSeleccionado.check = true;
    }
    this.filtrosService.changeElementCheckStatus(sucursalClick, this.sucursalesDisponibles);
    this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.filter(e => e.check).length;
    this.puedeFiltrarPorPerspectivas = (this.numeroDeSucursalesSeleccionadas === 1);
    this.getGraficaComSuc();
    //if (this.puedeFiltrarPorPerspectivas) {
      this.sucursalSeleccionada = this.sucursalesDisponibles.find(e => e.check);
    //}
    this.perspectivaTi = '';
    this.actsub();
  }
  /** Quitar filtros */
  public quitarFiltros(): void {
    // C1
    this.graficabarras = true;
    this.graficalineal = false;
    this.graficacompuesta = false;
    this.graficaarea = false;
    this.graficatabla1 = false;
    // C2
    this.graficatabla = true;
    this.graficabarrasCre = false
    this.graficalinealCre = false;
    this.graficabarrasSal = false
    this.graficalinealSal = false;
    this.perspectivaTi = '';
    this.perGen = '';
    this.perPro = '';
    this.perSer = '';
    this.perEst = '';
    this.perMun = '';
    this.mesesDeAnioDisponibles = [];

    this.saldo = true;
    this.numer = false;
    this.masQuin = false;

    this.puedeFiltrarPorPerspectivas = false;
    this.puedeFiltrarPorSucursales = false;
    this.puedeFiltrarPorPeriodicidad = true;
    this.mesSeleccionado = undefined;
    this.anioSeleccionado = undefined;
    this.sucursalSeleccionada = undefined;
    this.consolidado = true;
    this.xAxisLabel = 'Periodo';
    this.sucursalesDisponibles = [];
    this.periodicidad.forEach(per =>{ per.name === 'Mensual'? per.check = true : per.check = false });
    this.customColors = [];
    this.getFiltros();
    this.actsub();
    this.actsub2();
    this.actsub3();
  }

  /** funciones para las graficas */
  public abrebMes(mes: any){
    switch(mes){
      case '01': return 'ene';
      case '02': return 'feb';
      case '03': return 'mar';
      case '04': return 'abr';
      case '05': return 'may';
      case '06': return 'jun';
      case '07': return 'jul';
      case '08': return 'ago';
      case '09': return 'sep';
      case '10': return 'oct';
      case '11': return 'nov';
      case '12': return 'dic';
    }
    return mes;
  }

  /**  */
  public ySacaleMin(){
    var men = 10000000000;
    this.dataBarraCom.forEach(dat => {
      //@ts-ignore
      if(dat.value < men){
        //@ts-ignore
        men = dat.value;
      }
    });
    if(!this.puedeFiltrarPorSucursales)
      this.minimo = men-men*.10;
    else
      this.minimo = men-men*.40;
  }
  public ySacaleMinCuen(){
    var men = 60000;
    this.dataBarraComNcuen.forEach(dat => {
      //@ts-ignore
      if(dat.value < men){
        //@ts-ignore
        men = dat.value;
      }
    });
    if(!this.puedeFiltrarPorSucursales)
      this.minimoCuen = men-men*.10;
    else
      this.minimoCuen = men-men*.40;
  }
  public AmpliarC1(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    if(this.graficabarras)
      this.graficabarras_modal = true;
    if(this.graficalineal)
      this.graficalineal_modal = true;
    if(this.graficaarea)
      this.graficaarea_modal = true;
    if(this.graficatabla1)
      this.graficatabla1_modal = true;
    if(this.graficacompuesta)
      this.graficacompuesta_modal = true;
  }
  public AmpliarC2(){
    this.showModal = true;
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    if(this.graficatabla)
      this.graficatabla_modal = true;
    if(this.graficabarrasSal)
      this.graficabarrasSal_modal = true;
    if(this.graficalinealSal)
      this.graficalinealSal_modal = true
      if(this.graficabarrasCre)
      this.graficabarrasCre_modal = true;
    if(this.graficalinealCre)
      this.graficalinealCre_modal = true
  }
  public hideGraficasModal(){
    // C1
    this.graficacompuesta_modal = false;
    this.graficabarras_modal = false
    this.graficalineal_modal = false;
    this.graficaarea_modal = false;
    this.graficatabla1_modal = false;
    // C2
    this.graficatabla_modal = false;
    this.graficabarrasSal_modal = false;
    this.graficabarrasCre_modal = false;
    this.graficalinealSal_modal = false;
    this.graficalinealCre_modal = false;
  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  public cambiarGrafica(grafica: string): void {
    this.esGraficaDeBarras = (grafica === 'barras');
  }
  public cambioGrafC1(graf: any){
    switch(graf){
      case 'barra':
        this.graficabarras = true;
        this.graficalineal = false;
        this.graficacompuesta = false;
        this.graficaarea = false;
        this.graficatabla1 = false;
      break;
      case 'lineal':
        this.graficalineal = true;
        this.graficacompuesta = false;
        this.graficabarras = false;
        this.graficaarea = false;
        this.graficatabla1 = false;
      break;
      case 'area':
        this.graficaarea = true;
        this.graficabarras = false;
        this.graficalineal = false;
        this.graficacompuesta = false;
        this.graficatabla1 = false;
      break;
      case 'tabla':
        this.graficatabla1 = true;
        this.graficaarea = false;
        this.graficabarras = false;
        this.graficalineal = false;
        this.graficacompuesta = false;
      break;
      case 'compuesta':
        this.graficacompuesta = true;
        this.graficalineal = false;
        this.graficatabla1 = false;
        this.graficaarea = false;
        this.graficabarras = false;
      break;
    }
  }
  public cambioGrafC2(graf: any){
    switch(graf){
      case 'barraS':
        this.graficabarrasSal = true;
        this.graficalinealSal = false;
        this.graficabarrasCre = false;
        this.graficalinealCre = false;
        this.graficatabla = false;
      break;
      case 'linealS':
        this.graficalinealSal = true;
        this.graficabarrasSal = false;
        this.graficabarrasCre = false;
        this.graficalinealCre = false;
        this.graficatabla = false;
      break;
      case 'barraC':
        this.graficabarrasCre = true;
        this.graficalinealCre = false;
        this.graficabarrasSal = false;
        this.graficalinealSal = false;
        this.graficatabla = false;
      break;
      case 'linealC':;
        this.graficalinealCre = true;
        this.graficabarrasCre = false
        this.graficalinealSal = false;
        this.graficabarrasSal = false;
        this.graficatabla = false;
      break;
      case 'tabla':
        this.graficatabla = true;
        this.graficabarrasSal = false;
        this.graficalinealSal = false;
        this.graficabarrasCre = false;
        this.graficalinealCre = false;
      break;
    }
  }
  public metrica(cambio){
    if(cambio == 'num' && this.saldo){
      if(this.graficabarras){
        //this.graficabarras = false;
        //this.graficalineal = true;
        this.saldo = false;
        this.numer = true;
      }else if(this.graficalineal){
        //this.graficabarras = true;
        //this.graficalineal = false;
        this.saldo = false;
        this.numer = true;
      }else if(this.graficaarea){
        this.saldo = false;
        this.numer = true;
      }
    }else if(cambio == 'sal' && this.numer){
      if(this.graficabarras){
        //this.graficabarras = false;
        //this.graficalineal = true;
        this.saldo = true;
        this.numer = false;
      }else if(this.graficalineal){
        //this.graficabarras = true;
        //this.graficalineal = false;
        this.saldo = true;
        this.numer = false;
      }else if(this.graficaarea){
        this.saldo = true;
        this.numer = false;
      }
    }
    //this.actsub();
  }
  /** Subtitulos */
  public actsub3(){
    var aux = '';
    this.subtituloTarjeta2 = '';
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        this.subtituloTarjeta2 = ' '+anio.name.substring(2,4);
      }
    }
    var est = this.Complete();
    for(var i = this.mesesDisponibles.length-1; i>0; i--){
      if(this.mesesDisponibles[i].check ==true){
        aux = ' | '+ this.mesesDisponibles[i].name;
        break;
      }
    }
    this.subtituloTarjeta2 += aux;
    aux = '';
    // @ts-ignore
    if(this.consolidado && this.puedeFiltrarPorSucursales){
      //this.subtituloTarjeta2 += ' | CONSOLIDADO';
    }else{
      if(this.sucursalesDisponibles != undefined){
        var sucSelec = 0;
        this.sucursalesDisponibles.forEach(suc => suc.check? sucSelec++ : '')
        if(sucSelec <= 1){
          for(const suc of this.sucursalesDisponibles){
            if(suc.check){
              aux= ' | '+suc.name;
            }
          }
        }else{
          aux = ' | SELECCIÓN';
        }
      }
    }
    this.subtituloTarjeta2 += aux;
    aux = '';
    if(this.perGen != ''){
      this.subtituloTarjeta2 += ' | ' + this.perGen;
    }
    if(this.perSer != ''){
      this.subtituloTarjeta2 += ' | ' + this.perSer;
    }
    if(this.perPro != ''){
      this.subtituloTarjeta2 += ' | ' + this.perPro;
    }
    if(this.perEst != ''){
      this.subtituloTarjeta2 += ' | ' + this.perEst;
    }
    if(this.perMun != ''){
      this.subtituloTarjeta2 += ' | ' + this.perMun;
    }
    if(this.perspectivaTi != ''){
      if( this.perspectivaTi != 'Género'){
        this.subtituloTarjeta2 += ' | '+this.perspectivaTi;
      }else{
        this.subtituloTarjeta2 += ' | Género';
      }
    }
  }
  public actsub2(){
    this.subtituloGrafica2 = '';
    this.subtituloGrafica2 += 'Cartera total';
    var i = 0;
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        if(i == 0){
          this.subtituloGrafica2 += ' | '+anio.name.substring(2,4);
          i++;
        } else{
          this.subtituloGrafica2 += ' | '+anio.name.substring(2,4);
        }
      }
    }
    
    if(this.puedeFiltrarPorSucursales){
      for(const mes of this.mesesDisponibles){
        if(mes.check)
          this.subtituloGrafica2 += ' | '+mes.name;
      }
    }

    if(this.consolidado && this.puedeFiltrarPorSucursales){
      this.subtituloGrafica2 += ' | CONSOLIDADO';
    }else{
      if(this.sucursalesDisponibles != undefined){
        for(const suc of this.sucursalesDisponibles){
          if(suc.check){
            this.subtituloGrafica2 += ' | '+suc.name;
          }
        }
      }
    }
    if(this.perGen != ''){
      this.subtituloGrafica2 += ' | ' + this.perGen;
    }
    if(this.perSer != ''){
      this.subtituloGrafica2 += ' | ' + this.perSer;
    }
    if(this.perPro != ''){
      this.subtituloGrafica2 += ' | ' + this.perPro;
    }
    if(this.perEst != ''){
      this.subtituloGrafica2 += ' | ' + this.perEst;
    }
    if(this.perMun != ''){
      this.subtituloGrafica2 += ' | ' + this.perMun;
    }
    if(this.perspectivaTi != ''){
      if( this.perspectivaTi != 'Género'){
        this.subtituloGrafica2 += ' | '+this.perspectivaTi;
      }else{
        this.subtituloGrafica2 += ' | Género';
      }
    }
  }
  public actsub(){
    this.subtituloGraficaTabla = '';
    var i = 0;
    if(this.saldo){
      this.subtituloGraficaTabla += 'Cartera total ';
    }else if(this.numer){
      this.subtituloGraficaTabla += 'Número de cuentas ';
    }
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        if(i == 0){
          this.subtituloGraficaTabla += ' | '+anio.name.substring(2,4);
          i++;
        } else{
          this.subtituloGraficaTabla += ' | '+anio.name.substring(2,4);
        }
      }
    }
    
    if(this.puedeFiltrarPorSucursales){
      for(const mes of this.mesesDisponibles){
        if(mes.check)
          this.subtituloGraficaTabla += ' | '+mes.name;
      }
    }
    
    // @ts-ignore
    if(this.consolidado && this.puedeFiltrarPorSucursales){
      this.subtituloGraficaTabla += ' | CONSOLIDADO';
    }else{
      if(this.sucursalesDisponibles != undefined){
        for(const suc of this.sucursalesDisponibles){
          if(suc.check){
            this.subtituloGraficaTabla += ' | '+suc.name;
          }
        }
      }
    }
    if(this.perGen != ''){
      this.subtituloGraficaTabla += ' | ' + this.perGen;
    }
    if(this.perSer != ''){
      this.subtituloGraficaTabla += ' | ' + this.perSer;
    }
    if(this.perPro != ''){
      this.subtituloGraficaTabla += ' | ' + this.perPro;
    }
    if(this.perEst != ''){
      this.subtituloGraficaTabla += ' | ' + this.perEst;
    }
    if(this.perMun != ''){
      this.subtituloGraficaTabla += ' | ' + this.perMun;
    }
    if(this.perspectivaTi != ''){
      if( this.perspectivaTi != 'Género'){
        this.subtituloGraficaTabla += ' | '+this.perspectivaTi;
      }else{
        this.subtituloGraficaTabla += ' | Género';
      }
    }
  }
  public tit1(){
    if(!this.consolidado && this.puedeFiltrarPorSucursales){
      return 'Periodo';
    }
    if(this.perspectivaTi != ""){
      return 'Periodo';
    }
    return 'Año';
  }
  public tit2(){
    if(!this.consolidado && this.puedeFiltrarPorSucursales){
      return 'Sucursal';
    }
    if(this.perspectivaTi != ""){
      switch(this.perspectivaTi){
        case 'Género':
          return 'Género';
        case 'Servicio':
          return 'Servicio';
        case 'Producto':
          return 'Producto';
        case 'Estatus':
          return 'Estatus';
        case 'Municipio':
          return 'Municipio';
      }
    }
    return 'Mes';
  }

  /** Exportación */
  public export_excel(zon){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    name = 'Tendencia';
    var perspectiva = '';
    switch(zon){
      case 1:
        var encabezado_excel = [this.tit1(),this.tit2(),'Número de cuentas','Cartera total','Cartera vigente','Cartera vencida','Índice de morosidad'];
        var cadena_evaluacion = encabezado_excel;
        var mes = 0;
        for(const dato of this.datatabla){
          aux.push(dato['anio']);
          aux.push(dato['mes']);
          if(dato['ncuen'] != undefined){
            aux.push(Number(dato['ncuen']));
          }else{
            aux.push('');
          }
          if(dato['saldo'] != undefined){
            aux.push(Number(dato['saldo']));
          }else{
            aux.push('');
          }
          if(dato['cvig'] != undefined){
            aux.push(Number(dato['cvig']));
          }else{
            aux.push('');
          }
          if(dato['cven'] != undefined){
            aux.push(Number(dato['cven']));
          }else{
            aux.push('');
          }
          if(dato['imor'] != undefined){
            aux.push(Number(dato['imor']));
          }else{
            aux.push('');
          }
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 2:
        var encabezado_excel = ['PERIODO','SALDO CAPITAL','NUMERO CREDITO'];
        var cadena_evaluacion = encabezado_excel;
        var mes = 0;
        for(const dato of this.datazona2){
          aux.push(dato['name'].toString());
          if(dato['salC'] != undefined){
            aux.push(Number(dato['salC']));
          }else{
            aux.push('');
          }
          if(dato['numC'] != undefined){
            aux.push(Number(dato['numC']));
          }else{
            aux.push('');
          }
            data_excel.push(aux);
          aux = [];
        }
      break;
    }
    this.excelservices.generateExcelTenenciaColocacion(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva,mes);
  }
  public export_imagen(zon: any){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 1:
        grafica = 'zona1';
        name = 'Tendencia';
      break
      case 2:
        grafica = 'zona2';
        name = 'Tendencia';
      break
    }
    PDF.exportimagenes(grafica,name);
  }
  public formatearnormal(num: any){
    if(num != undefined){
      num +='';
      var splitStr = num.split('.');
      var splitLeft = splitStr[0];
      var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
      var regx = /(\d+)(\d{3})/;
      while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
      }
      if(splitRight === '.00')
        return  splitLeft;  
      return  splitLeft + splitRight;
    } else {
      return num;
    }
  }
  public aniosSelec(){
    var i = 0;
    let element = document.getElementById('Cchip')
    this.aniosDisponibles.forEach( an => { if(an.check){ i++; }});
    if(element.offsetWidth > 265)
      return i > 4
    return i > 3
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sucursalesDisponibles2.filter = filterValue.trim().toLowerCase();
  }
}
