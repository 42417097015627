import {MessageService} from '..//base/message.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '..//base/service';
import {PermisosDimensionEntity} from '../../entities/base/QubosEntities';

import {Loggers} from '../../util/app-util';


@Injectable({
  providedIn: 'root'
})
export class PermisosDimensionService extends Service<PermisosDimensionEntity> {

  httpClient: HttpClient;

  constructor(
      http: HttpClient,
      messageService: MessageService,
      protected logger: Loggers
  ) {
      super('PatmirService', 'qubos/qubos_permisosdimension', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: PermisosDimensionEntity): HttpParams {
      let params: HttpParams = new HttpParams();
      if (null == entity) { return params; }

      if (null != entity.clavedimension) { params = params.set('clavedimension', entity.clavedimension.toString()); }
      if (null != entity.clavemodulo) { params = params.set('clavemodulo', entity.clavemodulo.toString()); }
      if (null != entity.descripciondimension) { params = params.set('descripciondimension', entity.descripciondimension.toString()); }
      if (null != entity.usuario) { params = params.set('usuario', entity.usuario.toString()); }
      if (null != entity.ifid) { params = params.set('ifid', entity.ifid.toString()); }
      if (null != entity.permiso) { params = params.set('permiso', entity.permiso.toString()); }
      return params;
  }
}

