import { Injectable } from '@angular/core';
import {MessageService} from '../base/message.service';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '../base/service';
import {Loggers} from '../../util/app-util';
import {matrizTransicionEntity} from './../../modules/riesgos/entities_riesgos/entities_riesgos';
@Injectable({
  providedIn: 'root'
})
export class MatrizTransicionService extends Service<matrizTransicionEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers,
  ) { 
    super('matrizTransicion', '', http, messageService, logger);
      this.httpClient = http;
  }
  protected getParams(entity: matrizTransicionEntity):HttpParams{
    let params: HttpParams = new HttpParams();
    if (null == entity){return params;}
    if (null != entity.Id_Institucion){params = params.set('Id_Institucion', entity.Id_Institucion.toString())}
    if (null != entity.Periodo){params = params.set('Periodo', entity.Periodo.toString())}
    if (null != entity.PeriodoAnterior){params = params.set('PeriodoAnterior', entity.PeriodoAnterior.toString())}
    if (null != entity.NDatos){params = params.set('NDatos', entity.NDatos.toString())}
    if (null != entity.BnConIntereses){params = params.set('BnConIntereses', entity.BnConIntereses.toString())}
    if (null != entity.Id_Zona){params = params.set('Id_Zona', entity.Id_Zona.toString())}
    if (null != entity.Id_Producto){params = params.set('Id_Producto', entity.Id_Producto.toString())}
    if (null != entity.Id_Sector){params = params.set('Id_Sector', entity.Id_Sector.toString())}
    return params;
  }
}
