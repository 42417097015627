import {MessageService} from './base/message.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Service} from './base/service';
import { SucursalEntity } from '../entities/base/BaseEntities';

import { Loggers } from '../util/app-util';

@Injectable({
    providedIn: 'root'
})
export class SucursalService extends Service<SucursalEntity> {

    httpClient: HttpClient;
    currentSucursal: SucursalEntity;

    constructor(
        http: HttpClient,
        messageService: MessageService,
        protected logger: Loggers
    ) {
        super('SucursalService', 'sucursal', http, messageService, logger);
        this.httpClient = http;
    }

    protected getParams(entity: SucursalEntity): HttpParams {
        let params: HttpParams = new HttpParams();
        if (null != entity.SucursalId) { params = params.set('SucursalId', entity.SucursalId.toString()); }
        if (null != entity.SucursalDsc) { params = params.set('SucursalDsc', entity.SucursalDsc.toString()); }
        if (null != entity.SucursalDsc_II) { params = params.set('SucursalDsc_II', entity.SucursalDsc_II.toString()); }
        if (null != entity.InstitucionPatmirId) { params = params.set('InstitucionPatmirId', entity.InstitucionPatmirId.toString()); }
        if (null != entity.Estatus) { params = params.set('Estatus', entity.Estatus.toString()); }
        if (null != entity.Bansefi_PA_Consecutivo) { params = params.set('Bansefi_PA_Consecutivo', entity.Bansefi_PA_Consecutivo.toString()); }
        if (null != entity.Bansefi_PAId) { params = params.set('Bansefi_PAId', entity.Bansefi_PAId.toString()); }
        if (null != entity.Meta_Total) { params = params.set('Meta_Total', entity.Meta_Total.toString()); }
        if (null != entity.EsMatriz) { params = params.set('EsMatriz', entity.EsMatriz.toString()); }
        if (null != entity.InstitucionPatmirSucursalId) { params = params.set('InstitucionPatmirSucursalId', entity.InstitucionPatmirSucursalId.toString()); }
        if (null != entity.SucursalId_Anterior) { params = params.set('SucursalId_Anterior', entity.SucursalId_Anterior.toString()); }
        if (null != entity.ElegiblePATMIR) { params = params.set('ElegiblePATMIR', entity.ElegiblePATMIR.toString()); }
        if (null != entity.EstadoId) { params = params.set('EstadoId', entity.EstadoId.toString()); }
        if (null != entity.SucID) { params = params.set('SucID', entity.SucID.toString()); }

        return params;
    }

    public getCurrentSucursal(): Observable<any> {
        if (!this.currentSucursal) {
            //return this.httpClient.get<SucursalEntity>('webapi/sucursal').pipe(
            return this.httpClient.get<SucursalEntity>(this.serviceUrl).pipe(
                tap(sucursal => {
                    this.logger.log(`SucursalService: fetched ${sucursal}`, false);
                    this.currentSucursal = sucursal;
                }),
                catchError(this.handleError('get', new SucursalEntity()))
            );
        }
        return of(this.currentSucursal);
    }


}
