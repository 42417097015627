export class OperacionesSemaforo{
    public static R0_Opcomparacion1(val){
        switch(val){
            case 1:
                return ">";
            break;
            case 2:
                return ">=";
            break;
            case 3:
                return "<";
            break;
            case 4:
                return "<=";
            break;
            default:
                return "";
        }
    }
    public static R0_Opcomparacion2(val){
        switch(val){
            case 1:
                return ">";
            break;
            case 2:
                return ">=";
            break;
            case 3:
                return "<";
            break;
            case 4:
                return "<=";
            break;
            default:
                return "";
        }
    }
    public static R0_Oplogico1(val){
        switch(val){
            case 0:
                return 'and'
            break;
            case 1:
                return 'or'
            break;
            case 2:
                return ''
            break;
            default:
                return "";
        }
    }
}
export class SemaforoRojo{
    public static BanderaSemaforoRojo(arreglo,position,idplantilla){        
        //console.log("🚀 ~ SemaforoRojo ~ BanderaSemaforoRojo ~ arreglo", arreglo)
        switch(idplantilla){
            case 3:
                return this.SwitchRed(arreglo,position);
            break;
            case 5:
                return this.SwitchRedCinco(arreglo,0);
            break;

        }
    }
    public static SwitchRed(arreglo,position){
        switch(position){
            case 0:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R0_Opcomparacion2) + " " + arreglo.R0_Valor2;
                if(arreglo.R0_Valor2 === 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion1) + " " + arreglo.R0_Valor1 + " " + cadena1;
            break;
            case 1:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R1_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R1_Opcomparacion2) + " " + arreglo.R1_Valor2;
                if(arreglo.R1_Valor2 === 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R1_Opcomparacion1) + " " + arreglo.R1_Valor1 + " " + cadena1;
            break;
        }
    }
    public static SwitchRedCinco(arreglo,position){
        switch(position){
            case 0:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico2) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion3) + " " + arreglo.R0_Valor3;
                if(arreglo.R0_Oplogico2 === 2){
                    cadena1 = "";
                }
                if(arreglo.R0_Oplogico3 != 2){
                    cadena1 += " " + OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico3) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion4) + " " + arreglo.R0_Valor4;
                }
                var cad = OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion1) + " " + arreglo.R0_Valor1;
                if(arreglo.R0_Oplogico1 != 2){
                    cad += " "+ OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion2) + " " + arreglo.R0_Valor2;
                }
                cadena1 = cad + " " + cadena1;
                return cadena1
            break;
            case 1:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R0_Opcomparacion2) + " " + arreglo.R0_Valor2;
                if(arreglo.R1_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R1_Opcomparacion1) + " " + arreglo.R1_Valor1 + " " + cadena1;
            break;
        }
    }
}
export class SemaforoYellow{
    public static BanderaSemaforoYellow(arreglo,position,idplantilla){     
        switch(idplantilla){
            case 3:
                return this.SwitchYellow(arreglo,position);
            break;
            case 5:
                return this.SwitchYellowCinco(arreglo,position);
            break;
        }
    }
    public static SwitchYellow(arreglo,position){
        switch(position){
            case 1:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R0_Opcomparacion2) + " " + arreglo.R0_Valor2;
                if(arreglo.R0_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion1) + " " + arreglo.R0_Valor1 + " " + cadena1;
            break;
            case 2:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R2_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R2_Opcomparacion2) + " " + arreglo.R2_Valor2;
                if(arreglo.R2_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R2_Opcomparacion1) + " " + arreglo.R2_Valor1 + " " + cadena1;
            break;
        }
    }
    public static SwitchYellowCinco(arreglo,position){
        switch(position){
            case 1:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R0_Opcomparacion2) + " " + arreglo.R0_Valor2;
                if(arreglo.R0_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion1) + " " + arreglo.R0_Valor1 + " " + cadena1;
            break;
            case 2:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R2_Oplogico2) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R2_Opcomparacion3) + " " + arreglo.R2_Valor3;
                if(arreglo.R2_Oplogico2 === 2){
                    cadena1 = "";
                }
                if(arreglo.R2_Oplogico3 != 2){
                    cadena1 += " " + OperacionesSemaforo.R0_Oplogico1(arreglo.R2_Oplogico3) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R2_Opcomparacion4) + " " + arreglo.R2_Valor4;
                }
                var cad = OperacionesSemaforo.R0_Opcomparacion1(arreglo.R2_Opcomparacion1) + " " + arreglo.R2_Valor1;
                if(arreglo.R2_Oplogico1 != 2){
                    cad += " "+ OperacionesSemaforo.R0_Oplogico1(arreglo.R2_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R2_Opcomparacion2) + " " + arreglo.R2_Valor2;
                }
                cadena1 = cad + " " + cadena1;
                return cadena1
            break;
        }
    }
}
export class SemaforoGreen{
    public static BanderaSemaforoGreen(arreglo,position,idplantilla){      
        switch(idplantilla){
            case 3:
                return this.SwitchGreen(arreglo,position);
            break;
            case 5:
                return this.SwitchCinco(arreglo,position);
            break;
        }
    }
    public static SwitchGreen(arreglo,position){
        switch(position){
            case 3:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R0_Opcomparacion2) + " " + arreglo.R0_Valor2;
                if(arreglo.R0_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion1) + " " + arreglo.R0_Valor1 + " " + cadena1;
            break;
            case 4:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R2_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R2_Opcomparacion2) + " " + arreglo.R2_Valor2;
                if(arreglo.R2_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R2_Opcomparacion1) + " " + arreglo.R2_Valor1 + " " + cadena1;
            break;
            case 5:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R5_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R5_Opcomparacion2) + " " + arreglo.R5_Valor2;
                if(arreglo.R5_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R5_Opcomparacion1) + " " + arreglo.R5_Valor1 + " " + cadena1;
            break;
        }
    }
    public static SwitchCinco(arreglo,position){
        switch(position){
            case 3:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R0_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R0_Opcomparacion2) + " " + arreglo.R0_Valor2;
                if(arreglo.R0_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R0_Opcomparacion1) + " " + arreglo.R0_Valor1 + " " + cadena1;
            break;
            case 4:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R3_Opcomparacion2) + " " + arreglo.R3_Valor1;
                if(arreglo.R2_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R2_Opcomparacion1) + " " + arreglo.R2_Valor1 + " " + cadena1;
            break;
            case 5:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R5_Oplogico2) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R5_Opcomparacion3) + " " + arreglo.R5_Valor3;
                if(arreglo.R5_Oplogico2 === 2){
                    cadena1 = "";
                }
                if(arreglo.R5_Oplogico3 != 2){
                    cadena1 += " " + OperacionesSemaforo.R0_Oplogico1(arreglo.R5_Oplogico3) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R5_Opcomparacion4) + " " + arreglo.R5_Valor4;
                }
                var cad = OperacionesSemaforo.R0_Opcomparacion1(arreglo.R5_Opcomparacion1) + " " + arreglo.R5_Valor1;
                if(arreglo.R5_Oplogico1 != 2){
                    cad += " "+ OperacionesSemaforo.R0_Oplogico1(arreglo.R5_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R5_Opcomparacion2) + " " + arreglo.R5_Valor2;
                }
                cadena1 = cad + " " + cadena1;
                return cadena1
                /*var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R5_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R5_Opcomparacion2) + " " + arreglo.R5_Valor2;
                if(arreglo.R5_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R5_Opcomparacion1) + " " + arreglo.R5_Valor1 + " " + cadena1;*/
            break;
        }
    }
}
export class SemaforoBlue{
    public static BanderaSemaforoBlue(arreglo,position,idplantilla){     
        switch(idplantilla){
            case 3:
                return this.SwitchBlue(arreglo,position);
            break;
            case 5:
                return this.SwitchBlueCinco(arreglo,3);
            break;
        }
    }
    public static SwitchBlue(arreglo,position){
        switch(position){
            case 3:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R3_Opcomparacion2) + " " + arreglo.R3_Valor1;
                if(arreglo.R3_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion1) + " " + arreglo.R3_Valor1 + " " + cadena1;
            break;
            case 4:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R3_Opcomparacion2) + " " + arreglo.R3_Valor2;
                if(arreglo.R3_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion1) + " " + arreglo.R3_Valor1 + " " + cadena1;
            break;
            case 5:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R3_Opcomparacion2) + " " + arreglo.R3_Valor2;
                if(arreglo.R5_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion1) + " " + arreglo.R3_Valor1 + " " + cadena1;
            break;
        }
    }
    public static SwitchBlueCinco(arreglo,position){
        switch(position){
            case 3:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico2) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion3) + " " + arreglo.R3_Valor3;
                if(arreglo.R3_Oplogico2 === 2){
                    cadena1 = "";
                }
                if(arreglo.R3_Oplogico3 != 2){
                    cadena1 += " " + OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico3) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion4) + " " + arreglo.R3_Valor4;
                }
                var cad = OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion1) + " " + arreglo.R3_Valor1;
                if(arreglo.R3_Oplogico1 != 2){
                    cad += " "+ OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion2) + " " + arreglo.R3_Valor2;
                }
                cadena1 = cad + " " + cadena1;
                return cadena1
                /*var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R3_Opcomparacion1) + " " + arreglo.R3_Valor1;
                if(arreglo.R3_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion2) + " " + arreglo.R3_Valor2 + " " + cadena1;*/
            break;
            case 4:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R3_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R3_Opcomparacion1) + " " + arreglo.R3_Valor1;
                if(arreglo.R3_Valor2 == 0){
                    cadena1 = "";
                }
                return cadena1;
                //return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R3_Opcomparacion1) + " " + arreglo.R3_Valor1 + " " + cadena1;
            break;
            case 5:
                var cadena1 = OperacionesSemaforo.R0_Oplogico1(arreglo.R5_Oplogico1) + " " + OperacionesSemaforo.R0_Opcomparacion2(arreglo.R5_Opcomparacion2) + " " + arreglo.R5_Valor2;
                if(arreglo.R5_Valor2 == 0){
                    cadena1 = "";
                }
                return OperacionesSemaforo.R0_Opcomparacion1(arreglo.R5_Opcomparacion1) + " " + arreglo.R5_Valor1 + " " + cadena1;
            break;
        }
    }
}