import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SocioclientequbosService } from './../../../services/comercial/afiliacion/afiliacion.service';
import { SocioClienteQubosEntity } from './../../../entities/base/DashboardEntities';
import { CaptacionQubosService } from './../../../services/comercial/captacion/captacion.service';
import { CarteraQubosService } from './../../../services/comercial/colocacion/cartera.service';
import { CarteraQubosEntity,CaptacionQubosEntity } from './../../../entities/base/DashboardEntities';

import {UsuarioService} from './../../../services/usuario.service';
import { SucursalService } from './../../../services/sucursal.service';
import {PermisosDimensionEntity,Info_AM } from './../../../entities/base/QubosEntities';
import {PermisosDimensionCheck,ElemMap } from './../../../entities/base/DashboardEntities';

import * as $ from 'jquery';
//import { ExportToCsv } from 'export-to-csv'; 
import { pagination } from './../../../entities/base/DashboardEntities';
import {TimeEntity, InstitucionPatmirEntity, PatmirEntity} from './../../../entities/base/DashboardEntities';
import { PatmirService } from './../../../services/dashboard/patmir.service';
import { InstitucionPatmirService } from './../../../services/dashboard/institucion-patmir.service';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';

import { SucursalEntity } from './../../../entities/base/BaseEntities';
import {CommonVariables} from './../funciones';



import html2canvas from 'html2canvas';
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import { catchError, retry } from 'rxjs/operators';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { DetalleEvaluacionIndicadorService } from './../../../services/alertatemprana/detalle-evaluacion-indicador.service';
import { Detalle_Evaluacion_IndicadorEntity } from './../../../entities/base/AlertaTemprana';
import { EvaluacionService } from './../../../services/alertatemprana/evaluacion.service';
import { EvaluacionEntity } from './../../../entities/base/AlertaTemprana';
import { IndicadorService } from './../../../services/alertatemprana/indicador.service';
import { IndicadorEntity } from './../../../entities/base/AlertaTemprana';
import { CuentaService} from './../../../services/alertatemprana/cuenta.service';
import { CuentaEntity } from './../../../entities/base/AlertaTemprana';

import { CuentaQService} from './../../../services/qubos/cuenta.service';
import { CuentaQEntity } from './../../../entities/base/QubosEntities';
import { DetalleEvaluacionCuentaService } from './../../../services/alertatemprana/detalle-evaluacion-cuenta.service';
import { Detalle_Evaluacion_CuentaEntity } from './../../../entities/base/AlertaTemprana';
import { InstitucionEntity } from './../../../entities/base/AlertaTemprana';
import { InstitucionService} from './../../../services/alertatemprana/institucion.service';


/*Librería excel*/
import { ExcelService } from './../../../services/exportar/excel.service';
import { FuncionesMes } from './..//funcionesAfiliacion';
import { FuncionesAnioMes, FuncionesColores } from './../funcionescartera';
//import { id } from '@swimlane/ngx-charts/release/utils';
/*Librería excel*/

import { InfoAnioMesEntitty} from './../../../entities/base/DashboardEntities';
import { InfoaniomesService } from './../../../services/dashboard/infoaniomes.service';

import { InstitucionpatmirIIService } from './../../../services/qubos/institucionpatmir-ii.service';


// nuevos services
import {RazonesService} from './../../../services/financiero/razones.service';
import { area } from 'd3-shape';
import { SeriesHorizontal } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-balance',
  templateUrl: './balance-general.component.html',
  styleUrls: ['./balance-general.component.scss']
})
export class BalanceComponent implements OnInit {
  informacion_datos: any[] = [];
  arreglo_complete: any[] = [];
  primer_arreglo: any[] = [];
  segundo_arreglo: any[] = [];
  tercer_arreglo: any[] = [];
  arreglo_sucursales: any [] = [];
  dataPatmir_Sucursales: any[] = [];

  info_AM: any[] = [];
  selectedPeriodo: string;
  ult_mes: string;
  suma_total: number;
  aling_der: boolean;

  carteraqubos = new CarteraQubosEntity;


  data_tipoproducto: any [] = [];
  data_tipoproducto_infomes: any [] = [];

  /* Analisis vertical-horizontal */
  analisis_vertical: boolean = false;
  analisis_horizontal: boolean = false;
  /* Analisis vertical-horizontal */
  constructor(
    private socioclientequbosservice: SocioclientequbosService,
    private carteraqubosservice: CarteraQubosService,
    private patmirService: PatmirService,
    private institucionPatmirService: InstitucionPatmirService,
    private captacionqubosservice: CaptacionQubosService,
    private detalle_evaluacion_indicadorService: DetalleEvaluacionIndicadorService,
    private evaluacionService: EvaluacionService,
    private indicadorService: IndicadorService,
    private cuentaService: CuentaService,
    private detalle_evaluacion_cuentaService: DetalleEvaluacionCuentaService,
    private cuentaqService: CuentaQService,
    private InstitucionService: InstitucionService, 
    private infoaniomesService: InfoaniomesService,
    private excelService: ExcelService,
    private institucionServiceII: InstitucionpatmirIIService,
    private usuariosService: UsuarioService,
    private sucursalservice: SucursalService,
    //nuevos servicios
    private razonesFinancieras: RazonesService,
  ) { }
  detalleevaluacionindicadorEntity = new Detalle_Evaluacion_IndicadorEntity;
  detalleevaluacioncuentaEntity = new Detalle_Evaluacion_CuentaEntity;
  evaluacionEntity = new EvaluacionEntity;
  indicadorEntity = new IndicadorEntity;
  cuentaEntity = new CuentaEntity;
  cuentaqEntity = new CuentaQEntity;
  infoaniomesEntitty = new InfoAnioMesEntitty;
  data_institucion: any [] = [];
  
  /*getInstitucion(institucionEntity: InstitucionEntity):void{
    this.InstitucionService.getByAddUrl('institucion',institucionEntity).subscribe(dataArr => {
      this.data_institucion = dataArr;

    });
  }*/
  getInstituciones(institucionEntity: InstitucionEntity):void{
    //this.InstitucionService.getByAddUrl('institucion',institucionEntity).subscribe(dataArr => {
      //this.ConstruirInstituciones(dataArr);
    //});
  }
  ConstruirInstituciones(arreglo){
    this.data_instituciones = [];
    var flag = false;
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Id_Institucion != 1){
        if(this.id_at == arreglo[i].Id_Institucion){
          flag = true;
        }else{
          flag = false;
        }
        this.data_instituciones.push({"id":arreglo[i].Id_Institucion,"name":arreglo[i].Descripcion,"checked":flag});
      }
    }

  }
  InstitucionSelect(){
    var cad = "";
    if(this.institucionid != "0000"){
      for(var i = 0; i < this.data_instituciones.length;i++){
        if(this.data_instituciones[i].id == this.id_at){
          cad = this.data_instituciones[i].name;
          this.data_instituciones[i].checked = true;
        }else{
          this.data_instituciones[i].checked = false;
        }
      }
    }else{
      for(var i = 0; i < this.data_instituciones.length;i++){
        if(this.data_instituciones[i].checked){
          cad = this.data_instituciones[i].name;
        }
      }
    }
    
    if(cad == ""){
      for(var i = 0; i < this.data_institucion.length;i++){
        if(this.data_institucion[i].Id_Institucion == this.id_at){
          cad = this.data_institucion[i].Descripcion;
        }
      }
    }

    if(this.usuarioid == 'DEMO2' || this.usuarioid == "DEMO"){
      cad = "I. DEMO";
    }
    if (this.id_at == 20){
      cad = "SAN RAFAEL";
    }

    return cad;
  }
  data_instituciones =  [
    {"id":5,"name":"Cosauqui","checked":false},
    {"id":8,"name":"ItaViko","checked":false},
    {"id":3,"name":"Ciudad Real","checked":false},
    {"id":11,"name":"Vicente Villagrán","checked":true},
    {"id":7,"name":"Horizontes Seguros","checked":false},
    {"id":4,"name":"Coroneo","checked":false},
    {"id":10,"name":"Tonaya","checked":false},
    {"id":9,"name":"La Esperanza","checked":false},
    {"id":2,"name":"Caja Occidente","checked":false},
  ];
  id_at:number = 20;
  institucionChange(name){
    $("#demoInstituciones").hide();
    for(var i = 0; i < this.data_instituciones.length;i++){
      if(name == this.data_instituciones[i].id){
        this.data_instituciones[i].checked = true;
      }else{
        this.data_instituciones[i].checked = false;
      }
    }
    this.cadena_evaluacion = [];
    this.id_at = name;

    this.evaluacionEntity.Id_Institucion = this.id_at;
    this.evaluacionEntity.Id_Plantilla = 3;
    this.data_evaluacion = [];
    this.data_evaluacionaux = [];
    this.getEvaluacion(this.evaluacionEntity);
    
    this.cuentaEntity = new CuentaEntity;
    this.cuentaEntity.Id_Plantilla = 3;
    this.getCuenta(this.cuentaEntity);
  }
  institucionEntity = new InstitucionEntity;
  ngOnInit() {
    $(document).on("click",function(e) {
      var institucion = $("#demoInstituciones");
      var periodo = $("#demoPeriodo");
      var table_balance_intituciones = $("#table_balance_intituciones");

      var maticonInstituciones = $("#maticonInstituciones");
      var maticonPeriodo = $("#maticonPeriodo");

      var dataAnioadd = [$("#add_2020"),$("#add_2019"),$("#add_2018"),$("#add_2017"),$("#add_2016"),$("#add_2015"),$("#add_2014"),$("#add_2013"),$("#add_2012"),$("#add_2011"),
      $("#add_2010"),$("#add_2009"),$("#add_2008"),$("#add_2007"),$("#add_2006"),$("#add_2005"),$("#add_2004"),$("#add_2003"),$("#add_2002"),$("#add_2001"),
      $("#add_2000"),$("#add_1999"),$("#add_1998"),$("#add_1997"),$("#add_1996"),$("#add_1995"),$("#add_1994"),$("#add_1993"),$("#add_1992"),$("#add_1991")];   
    
      var dataAnioremove = [$("#remove_2020"),$("#remove_2019"),$("#remove_2018"),$("#remove_2017"),$("#remove_2016"),$("#remove_2015"),$("#remove_2014"),$("#remove_2013"),$("#remove_2012"),$("#remove_2011"),
      $("#remove_2010"),$("#remove_2009"),$("#remove_2008"),$("#remove_2007"),$("#remove_2006"),$("#remove_2005"),$("#remove_2004"),$("#remove_2003"),$("#remove_2002"),$("#remove_2001"),
      $("#remove_2000"),$("#remove_1999"),$("#remove_1998"),$("#remove_1997"),$("#remove_1996"),$("#remove_1995"),$("#remove_1994"),$("#remove_1993"),$("#remove_1992"),$("#remove_1991")];  
          
      var InstitucionesID = [
                              $("#institucion_0"),$("#institucion_1"),$("#institucion_2"),$("#institucion_3"),$("#institucion_4"),$("#institucion_5"),$("#institucion_6"),$("#institucion_7"),$("#institucion_8"),$("#institucion_9"),$("#institucion_10"),
                              $("#institucion_11"),$("#institucion_12"),$("#institucion_13"),$("#institucion_14"),$("#institucion_15"),$("#institucion_16"),$("#institucion_17"),$("#institucion_18"),$("#institucion_19"),$("#institucion_20"),
                              $("#institucion_21"),$("#institucion_22"),$("#institucion_23"),$("#institucion_24"),$("#institucion_25"),$("#institucion_26"),$("#institucion_27"),$("#institucion_28"),$("#institucion_29"),$("#institucion_30"),
                              $("#institucion_31"),$("#institucion_32"),$("#institucion_33"),$("#institucion_34"),$("#institucion_35"),$("#institucion_36"),$("#institucion_37"),$("#institucion_38"),$("#institucion_39"),$("#institucion_30")
                            ];


      var flaganioadd = false;
      var flaganioremove = false;
      for(var i = 0; i < dataAnioadd.length;i++){
        if(dataAnioadd[i].is(e.target)){
          flaganioadd = true;
        }
      }
      for(var i = 0; i < dataAnioremove.length;i++){
        if(dataAnioremove[i].is(e.target)){
          flaganioremove = true;
        }
      }

      var flaginstitucionid = false;
      for(var i = 0; i < InstitucionesID.length;i++){
        if(InstitucionesID[i].is(e.target)){
          flaginstitucionid = true;
        }
      }


      if( maticonInstituciones.is(e.target) ||
          maticonPeriodo.is(e.target) || 
          table_balance_intituciones.is(e.target) ||
          institucion.is(e.target) ||
          flaginstitucionid || flaganioadd || flaganioremove ){
      }else{
        if (!institucion.is(e.target) && institucion.has(e.target).length === 0) { 
          $("#demoInstituciones").hide();  
          $("#demoPeriodo").hide();
        }
        if (!periodo.is(e.target) && periodo.has(e.target).length === 0) { 
          $("#demoInstituciones").hide();  
          $("#demoPeriodo").hide();
        }
      }
    });   
    this.aling_der = true;
    this.getCurrentUser();

  }
  setColorSaldoColocacion(name){
    this.colorSchemeActivo = FuncionesColores.setpaleta_azul(name);
    this.colorSchemePasivo = FuncionesColores.setpaleta_azul(name);
    this.colorSchemeCapitalContable = FuncionesColores.setpaleta_azul(name);
  }

  arreglo_anios: string = "";

  socioclientequbos = new SocioClienteQubosEntity;
  captacionqubos = new CaptacionQubosEntity;
  f_switchmes(mes){
    return CommonVariables.f_switchmesA(mes);
  }
  f_switchmesm(mes){
    return CommonVariables.f_switchmesm(mes);
  }
  checked_anio: boolean = false;
  checked_mes: boolean = false;
  puntot1c1: boolean = false;
  flag_inspeccionar: boolean = false;
  subtitulot1c1: string = "";
  flag_sucursal_piechart: boolean = false;
  visibility_legend1(){}
  
  downloadChart1(cadena){
    this.exportaimg(cadena);
  }
  exportaimg(id){
    var cadena = "Some description Entidad " ;

    setTimeout(() => {
      // Charts are now rendered
      const chart = document.getElementById(id);
      html2canvas(chart, {
        height: 500,
        width: 1000,
        scale: 2,
        backgroundColor: null,
        logging: false,
        onclone: (document) => {
          //document.getElementById(id).style.visibility = 'visible';
        }
      }).then((canvas) => {
        // Get chart data so we can append to the pdf
        const chartData = canvas.toDataURL();
        // Prepare pdf structure
        const docDefinition = { content: [],
        styles: {
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
            alignment: 'left'
          },
          subsubheader: {
            fontSize: 12,
            italics: true,
            margin: [0, 10, 0, 25],
            alignment: 'left'
          },
          table: {
            margin: [0, 5, 0, 15]
          }
        },
        defaultStyle: {
          alignment: 'justify'
        },
        pageOrientation: 'portrait',  // vertical
        //pageOrientation: 'landscape', // horizontal
      };
      
      // Add some content to the pdf
      var title = {text: 'Afiliación de socios', style: 'subheader'};
      cadena = "La colocación, o también llamada colocación bancaria, permite la puesta de dinero en circulación en la economía, es decir, la banca genera un nuevo dinero del capital o los recursos que obtiene a través de la captación y, con estos recursos, se otorgan créditos a las personas, empresas u organizaciones que los soliciten. Por dar estos préstamos el banco cobra unas cantidades de dinero que se llaman intereses, o intereses de colocación, y comisiones.";
      var description = {text: cadena, style: 'subsubheader'};
      docDefinition.content.push(title);
      docDefinition.content.push(description);
      // Push image of the chart
      
      /*docDefinition.content.push({image: chartData, width: 500});
      title = {text: 'Here is the export of charts to the PDF', style: 'subheader'};
      description = {text: cadena, style: 'subsubheader'};
      docDefinition.content.push(title);
      docDefinition.content.push(description);*/
      // Push image of the chart
      docDefinition.content.push({image: chartData, width: 500});

      // vamos aquí ----> 
      this.docDefinition = docDefinition;

      pdfMake.createPdf(docDefinition).download('chartToPdf' + '.pdf');
      });
    }, 100);
  }
  docDefinition: any;
  sucursal_seleccionada(name){
    var contador_sucursal = 0;
    this.carteraqubos.arreglo_sucursal = "";
    this.captacionqubos.arreglo_sucursal = "";
    if(name == 0){
      if(this.checked_anio){
        this.checked_anio = false;
      }else{
        this.checked_anio = true;
      }

      for(var i = 0; i < this.arreglo_sucursales.length; i ++){
        if(this.checked_anio){
          this.arreglo_sucursales[i].dbCheck = true;
        }else{
          this.arreglo_sucursales[i].dbCheck = false;
        }
      }
      this.puntobalancec1 = true;
      this.subtitulobalancec1 = "TODAS";
    }else{
      for(var i = 0; i < this.arreglo_sucursales.length; i ++){
        if(this.arreglo_sucursales[i].dbId == name.dbId){
          if(this.arreglo_sucursales[i].dbCheck){
            this.arreglo_sucursales[i].dbCheck = false;
          }else{
            this.arreglo_sucursales[i].dbCheck = true;
          }
        }
      }
      for(var i = 0; i < this.arreglo_sucursales.length; i++){
        if(this.arreglo_sucursales[i].dbCheck){
          contador_sucursal = contador_sucursal + 1;
          if(contador_sucursal == 1){

            this.carteraqubos.sucursal = this.arreglo_sucursales[i].dbId;
            this.captacionqubos.sucursaldesc = "'" + this.buscar_sucursal(this.arreglo_sucursales[i].dbId) + "'";
            this.captacionqubos.arreglo_sucursal = "'" + this.buscar_sucursal(this.arreglo_sucursales[i].dbId) + "'";
            this.carteraqubos.arreglo_sucursal = "'" + this.arreglo_sucursales[i].dbId + "'";
            this.subtitulobalancec1 = this.arreglo_sucursales[i].strName;
          }else{
            this.carteraqubos.sucursal = null;
            this.carteraqubos.arreglo_sucursal = this.carteraqubos.arreglo_sucursal + ",'" + this.arreglo_sucursales[i].dbId + "'";
            this.captacionqubos.arreglo_sucursal =  this.captacionqubos.arreglo_sucursal + ",'" + this.buscar_sucursal(this.arreglo_sucursales[i].dbId) + "'";
            this.subtitulobalancec1 = this.subtitulobalancec1 + ' | ' + this.arreglo_sucursales[i].strName;
          }
        }
      }
    }
    this.puntobalancec1 = true;
    this.subtitulobalancec1 = this.arreglo_anios + " | " + this.subtitulobalancec1;
    if(contador_sucursal > 1){
      this.carteraqubos.sucursal = null;
      this.captacionqubos.sucursaldesc = null;
    }else{
      if(contador_sucursal == 0){
        this.carteraqubos.sucursal = null;
        this.carteraqubos.arreglo_sucursal = null;
        this.captacionqubos.sucursaldesc = null;
      }else{
        this.carteraqubos.sucursal = name.dbId;
        this.captacionqubos.sucursaldesc = this.buscar_sucursal(name.dbId);
      }
    }
    this.getPrestamoTipoProducto_infomes(this.carteraqubos);
    this.getPrestamoTipoProducto(this.carteraqubos);
    this.getSaldoCaptacionMes(this.captacionqubos);
    this.getSaldoCaptacionTipoProductoMes(this.captacionqubos);
  }j
  contador_ejercicioperiodo: number = 1; 
  cadena_ejercicioperiodo: string = "";
  cadena_idevaluacion: string = "";
  Id_Evaluacion: number = 0;
  cadena_evaluacion: any [] = [];
  cadena_evaluacionHorizontal: any [] = [];
  ejercicio_periodo_seleccionado(idevaluacion){
    this.contador_ejercicioperiodo = 0;
    this.cadena_evaluacion = [];
    for(var i = 0; i < this.data_evaluacion.length; i++){
      if(this.data_evaluacion[i].Id_Evaluacion == idevaluacion){
        if(this.data_evaluacion[i].check){
          this.data_evaluacion[i].check = false;
        }else{
          this.data_evaluacion[i].check = true;
        }
      }
    }
    for(var i = 0; i < this.data_evaluacion.length;i++){
      if(this.data_evaluacion[i].check){
        if(this.contador_ejercicioperiodo == 0){
          this.cadena_idevaluacion = "" + this.data_evaluacion[i].Id_Evaluacion + "";
          this.Id_Evaluacion = this.data_evaluacion[i].Id_Evaluacion;
          this.subtitulobalancec1 = this.data_evaluacion[i].ejercicioperiodo;
        }else{
          this.cadena_idevaluacion = this.cadena_idevaluacion  + "," + this.data_evaluacion[i].Id_Evaluacion + "";
          this.subtitulobalancec1 = this.subtitulobalancec1 + " | " + this.data_evaluacion[i].ejercicioperiodo;
        }
        this.cadena_evaluacion.push({"evaluacion":this.data_evaluacion[i].ejercicioperiodo,"Id_Evaluacion":this.data_evaluacion[i].Id_Evaluacion});
        this.contador_ejercicioperiodo ++;
      }
    } 
    if(this.contador_ejercicioperiodo == 0){
      this.contador_ejercicioperiodo = 1;
      this.Id_Evaluacion = 0;
      this.cadena_evaluacion.push({"evaluacion":this.data_evaluacion[this.data_evaluacion.length - 1].Fecha_Contable.substring(0,10),"Id_Evaluacion":this.data_evaluacion[i].Id_Evaluacion});
    }
    if(this.contador_ejercicioperiodo == 1){
      this.detalleevaluacioncuentaEntity.Id_Evaluacion = this.Id_Evaluacion;
      this.detalleevaluacioncuentaEntity.ArregloID_evaluacion = null;
    }else{
      this.detalleevaluacioncuentaEntity.Id_Evaluacion = null;
      this.detalleevaluacioncuentaEntity.ArregloID_evaluacion = this.cadena_idevaluacion;
    }
    //this.detalleevaluacionindicadorEntity.Id_Evaluacion = idevaluacion;
    this.getDetalle_Evaluacion_Cuenta(this.detalleevaluacioncuentaEntity);
    $("#demoDropindicadores").hide();
    this.data_evaluacionaux = [];
    for(var i = 0; i < this.data_evaluacion.length;i++){
      if(this.data_evaluacion[i].check){
        this.data_evaluacionaux.push(this.data_evaluacion[i]);
      }
    }
  }
  reiniciar_checkIndicadores(){
    for(var i = 0; i < this.data_indicadorSeries.length; i++){
      this.data_indicadorSeries[i].check = true;
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
        }
      }
    }
  }
  puntobalancec1: boolean = true;
  subtitulobalancec1: string = "";
  getpatmir_Sucursales(socioclientequbosEntitty: SocioClienteQubosEntity):void{
    /*this.socioclientequbosservice.getByAddUrl('sucursales_informacion',socioclientequbosEntitty).subscribe(dataArr => {
        this.arreglo_sucursales = dataArr;
    });*/
  }
  data_evaluacionEntidad: any [] = [];
  getDetalle_Evaluacion_Indicador(detalle_evaluacion_indicadorEntity: Detalle_Evaluacion_IndicadorEntity):void{
    /*this.detalle_evaluacion_indicadorService.getByAddUrl('evaluacion',detalle_evaluacion_indicadorEntity).subscribe(dataArr => {
        this.data_evaluacionEntidad = dataArr;
        this.actualizartreemap(dataArr);
        //this.getCuentaOrdenado(this.indicadorEntity,dataArr);
    });*/
  }
  getDetalle_Evaluacion_Cuenta(detalle_evaluacion_cuentaEntity: Detalle_Evaluacion_CuentaEntity):void{
    this.razonesFinancieras.getDetalleEvaluacionCuenta(detalle_evaluacion_cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_evaluacionEntidad = datos;
          this.getCuentaOrdenado(this.cuentaEntity,datos);
        }
      }
    });
    /*this.detalle_evaluacion_cuentaService.getByAddUrl('evaluacion',detalle_evaluacion_cuentaEntity).subscribe(dataArr => {
        this.data_evaluacionEntidad = dataArr;
        //this.actualizartreemap(dataArr);
        this.getCuentaOrdenado(this.cuentaEntity,dataArr);
        //this.getCuentaOrdenado2(this.cuentaqEntity,dataArr);
    });*/
  }
  getDetalle_Evaluacion_CuentaTodas(detalle_evaluacion_cuentaEntity: Detalle_Evaluacion_CuentaEntity):void{
    /*this.detalle_evaluacion_cuentaService.getByAddUrl('evaluacion',detalle_evaluacion_cuentaEntity).subscribe(dataArr => {
        //this.data_evaluacionEntidad = dataArr;
        this.getCuentaOrdenadoTodas(this.cuentaEntity,dataArr);
    });*/
    this.razonesFinancieras.getDetalleEvaluacionCuenta(detalle_evaluacion_cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.getCuentaOrdenadoTodas(this.cuentaEntity,datos);
        }
      }
    });
  }
  data_evaluacion: any [] = [];
  getEvaluacion(evaluacionEntity: EvaluacionEntity):void{
    this.razonesFinancieras.getEvaluacion(evaluacionEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.obtenerultimaevaluacion(datos);
        }
      }
    });
    /*this.evaluacionService.getByAddUrl('evaluacion',evaluacionEntity).subscribe(dataArr => {
        //this.data_evaluacion = dataArr;
        this.obtenerultimaevaluacion(dataArr);
        
    });*/
  }
  cadena_evaluacionTodas: any [] = [];
  getEvaluacionTodas(evaluacionEntity: EvaluacionEntity):void{
    this.razonesFinancieras.getEvaluacion(evaluacionEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          let detalleevaluaciontodas= this.detalleevaluacioncuentaEntity;
          var cadena = "";
          this.cadena_evaluacionTodas = [];
          for(var i = 0; i < datos.length;i++){
            if(i == 0){
              cadena = "" + datos[i].Id_Evaluacion;
            }else{
              cadena = cadena + "," + datos[i].Id_Evaluacion;
            }
            this.cadena_evaluacionTodas.push({"evaluacion":FuncionesMes.f_switchmes2(datos[i].Fecha_Contable.substring(5,7)) + " " + datos[i].Fecha_Contable.substring(2,4),"Id_Evaluacion":datos[i].Id_Evaluacion});
          }
          detalleevaluaciontodas.ArregloID_evaluacion = cadena;
          this.getDetalle_Evaluacion_CuentaTodas(detalleevaluaciontodas);
        }
      }
    });
    /*this.evaluacionService.getByAddUrl('evaluacion',evaluacionEntity).subscribe(dataArr => {
      let detalleevaluaciontodas= this.detalleevaluacioncuentaEntity;
      var cadena = "";
      this.cadena_evaluacionTodas = [];
      for(var i = 0; i < dataArr.length;i++){
        if(i == 0){
          cadena = "" + dataArr[i].Id_Evaluacion;
        }else{
          cadena = cadena + "," + dataArr[i].Id_Evaluacion;
        }
        this.cadena_evaluacionTodas.push({"evaluacion":FuncionesMes.f_switchmes2(dataArr[i].Fecha_Contable.substring(5,7)) + " " + dataArr[i].Fecha_Contable.substring(2,4),"Id_Evaluacion":dataArr[i].Id_Evaluacion});
      }
      detalleevaluaciontodas.ArregloID_evaluacion = cadena
      this.getDetalle_Evaluacion_CuentaTodas(detalleevaluaciontodas);
    });*/
  }
  EvaluacionesArray(arreglo){
    var cad = "";
    for(var i = 0; i < arreglo.length;i++){
      if(i == 0){
        cad = arreglo[i].Id_Evaluacion;
      }else{
        cad = cad + "," + arreglo[i].Id_Evaluacion;
      }
    }
    return cad;
  }
  obtenerultimaevaluacion(arreglo){
    arreglo.sort(function (a, b) {
      if (a.Fecha_Contable.substring(0,7) > b.Fecha_Contable.substring(0,7)) {
        return 1;
      }
      if (a.Fecha_Contable.substring(0,7) < b.Fecha_Contable.substring(0,7)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    //this.detalleevaluacioncuentaEntity.Id_Evaluacion = arreglo[arreglo.length - 1].Id_Evaluacion;
    //this.detalleevaluacioncuentaEntity.ArregloID_evaluacion = this.EvaluacionesArray(arreglo);
    this.detalleevaluacionindicadorEntity.ArregloID_evaluacion = this.UltimasSeisEvaluaciones(arreglo);
    arreglo[arreglo.length - 1].check = true;
    for(var i = 0; i < arreglo.length;i++){
      this.data_evaluacion.push({
        "Id_Evaluacion":arreglo[i].Id_Evaluacion,
        "Id_Institucion":arreglo[i].Id_Institucion,
        "Id_Usuario":arreglo[i].Id_Usuario,
        "Fecha_Evaluacion":arreglo[i].Fecha_Evaluacion,
        "Fecha_Contable":arreglo[i].Fecha_Contable,
        "Descripcion":arreglo[i].Descripcion,
        "Observacion":arreglo[i].Observacion,
        "Id_Tipo_Evaluacion":arreglo[i].Id_Tipo_Evaluacion,
        "Id_Plantilla":arreglo[i].Id_Plantilla,
        "Id_Pais":arreglo[i].Id_Pais,
        "Id_Tipo_Institucion":arreglo[i].Id_Tipo_Institucion,
        "Estado":arreglo[i].Estado,
        "VistoBueno":arreglo[i].VistoBueno,
        "check":true,
        "ejercicioperiodo": FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4)
        });
    }


    //this.cadena_evaluacion.push({"evaluacion":FuncionesMes.f_switchmes2(this.data_evaluacion[arreglo.length - 1].Fecha_Contable.substring(5,7)) + " " + this.data_evaluacion[arreglo.length - 1].Fecha_Contable.substring(0,4)});
    this.cadena_evaluacion = this.CadenaEvaluacionInicio(this.data_evaluacion);
    this.contador_ejercicioperiodo = this.data_evaluacion.length;
    for(var i = 0; i < this.data_evaluacion.length;i++){
      if(i == 0){
        this.subtitulobalancec1 = this.data_evaluacion[i].ejercicioperiodo;
      }else{
        this.subtitulobalancec1 = this.subtitulobalancec1 + " | " + this.data_evaluacion[i].ejercicioperiodo;
      }
    }
    //this.subtitulobalancec1 = this.data_evaluacion[arreglo.length - 1].ejercicioperiodo;
    //this.data_evaluacionaux.push(this.data_evaluacion[arreglo.length - 1]);


    this.data_evaluacionaux = this.data_evaluacion;
    this.data_evaluacion = this.FuncionEjercicioPeriodo(arreglo);
    this.detalleevaluacioncuentaEntity.ArregloID_evaluacion = this.UltimasSeisEvaluaciones(arreglo);
    this.getDetalle_Evaluacion_Cuenta(this.detalleevaluacioncuentaEntity);
  }
  
  Indicador_Nivel1(id_indicador){
    this.analisis_horizontal = false;
    this.analisis_vertical = false;
    //contar los indicadores
    //$("#demoPeriodo").hide();
    var contador = 0;
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
        if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && !this.DATA_PERIODOSERIES[i].series[j].check){
          this.DATA_PERIODOSERIES[i].series[j].check = true;
        }else{
          if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador && this.DATA_PERIODOSERIES[i].series[j].check){
            this.DATA_PERIODOSERIES[i].series[j].check = false;
          }
        }
        if(this.DATA_PERIODOSERIES[i].series[j].check){
          contador++;
        }
      }
    }
    if(contador > 4){
      
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          if(this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion == id_indicador){
            this.DATA_PERIODOSERIES[i].series[j].check = false;
            //$("#check_"+this.DATA_PERIODOSERIES[i].anio+this.DATA_PERIODOSERIES[i].series[j].mes).prop('checked', false).removeAttr('checked');
            $("#check_"+this.DATA_PERIODOSERIES[i].anio+this.DATA_PERIODOSERIES[i].series[j].mes).prop('checked', false);
          }
        }
      }
      alert("Solo es posible seleccionar 4 períodos");
    }else{
      this.cadena_idevaluacion = "";
      var contador = 0;
      this.cadena_evaluacion = [];
      this.subtitulobalancec1 = "";
      this.data_evaluacionaux = [];
      for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
        for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
          if(this.DATA_PERIODOSERIES[i].series[j].check){
            if(contador == 0){
              this.cadena_idevaluacion = this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion;
              this.cadena_evaluacion.push({"evaluacion":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4),"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion});
              this.subtitulobalancec1 = this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4);
              this.data_evaluacionaux.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion,"ejercicioperiodo":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4)});
            }else{
              this.cadena_idevaluacion = this.cadena_idevaluacion + "," + this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion;
              this.cadena_evaluacion.push({"evaluacion":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4),"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion});
              this.subtitulobalancec1 = this.subtitulobalancec1 + " | " + this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4);
              this.data_evaluacionaux.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].series[j].Id_Evaluacion,"ejercicioperiodo":this.DATA_PERIODOSERIES[i].series[j].mes + " " + this.DATA_PERIODOSERIES[i].series[j].anio.substring(2,4)});
            }
            contador ++;
          }
        }
      }
      this.detalleevaluacioncuentaEntity.ArregloID_evaluacion = this.cadena_idevaluacion;
      
      this.getDetalle_Evaluacion_Cuenta(this.detalleevaluacioncuentaEntity);
      //this.getDetalle_Evaluacion_Indicador(this.detalleevaluacionindicadorEntity);
    }
  }
  CadenaEvaluacionInicio(arreglo){
    var cad = [];
    for(var i = 0; i < arreglo.length;i++){
      cad.push({"evaluacion":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4)});
    }
    return cad;
  }

  data_indicador: any [] = [];
  getIndicador(indicadorEntity: IndicadorEntity):void{
    /*this.indicadorService.getByAddUrl('indicador',indicadorEntity).subscribe(dataArr => {
        this.data_indicador = dataArr;
    });*/
  }
  getCuentaOrdenado(cuentaEntity: CuentaEntity,arreglo):void{
    this.razonesFinancieras.getCuenta(cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          var aux = [], i =0;
          for(const data of arreglo){
            for(const d of datos){
              if(data['Id_Cuenta'] == d['Id_Cuenta']){
                aux.push(data);
                aux[i].cuentaEntity = d;
              }
            }
            i++;
          }
          arreglo = aux;
          this.data_cuenta = datos;
          this.function_ordenarporniveles_CuentaEvaluacion(datos,arreglo);
        }
      }
    });
    /*this.cuentaService.getByAddUrl('cuenta',cuentaEntity).subscribe(dataArr => {
        this.data_cuenta = dataArr;
        //this.convertirIndicadorSeries(dataArr);
        this.function_ordenarporniveles_CuentaEvaluacion(dataArr,arreglo);
    });*/
  }
  getCuentaOrdenadoTodas(cuentaEntity: CuentaEntity,arreglo):void{
    this.razonesFinancieras.getCuenta(cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_cuenta = datos;
          this.function_ordenarporniveles_CuentaEvaluacionTodas(datos,arreglo);
        }
      }
    });
    /*this.cuentaService.getByAddUrl('cuenta',cuentaEntity).subscribe(dataArr => {
        this.data_cuenta = dataArr;
        //this.convertirIndicadorSeries(dataArr);
        this.function_ordenarporniveles_CuentaEvaluacionTodas(dataArr,arreglo);
    });*/
  }
  getCuentaOrdenado2(cuentaqEntity: CuentaQEntity,arreglo):void{
    this.razonesFinancieras.getCuenta(cuentaqEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_cuentaq = datos;
          this.function_ordenarporniveles_CuentaEvaluacion2(datos,arreglo);
        }
      }
    });
    /*this.cuentaService.getByAddUrl('cuenta',cuentaqEntity).subscribe(dataArr => {
        this.data_cuentaq = dataArr;
        //this.convertirIndicadorSeries(dataArr);
        this.function_ordenarporniveles_CuentaEvaluacion2(dataArr,arreglo);
    });*/
  }
  data_ordenado: any [] = [];
  data_ordenadoTodas: any [] = [];
  data_indicadorSeries: any [] = [];
  convertirIndicadorSeries(arreglo){
    this.data_indicadorSeries = [];
    var aux = [];
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Nivel == 1){

        this.data_indicadorSeries.push({"Id_Indicador":arreglo[i].Id_Indicador,
                                      "Descripcion":arreglo[i].Descripcion,
                                      "Nivel1":arreglo[i].Nivel1,
                                      "Nivel2":arreglo[i].Nivel2,
                                      "Nivel3":arreglo[i].Nivel3,
                                      "Nivel4":arreglo[i].Nivel4,
                                      "Nivel":arreglo[i].Nivel,
                                      "check":true,
                                      "SeriesNivel1": this.convertirIndicadoresSeriesNivel2(arreglo,i)
                                    });
        aux = [];
      }
    }

  }
  convertirIndicadoresSeriesNivel2(arreglo,posicion){
    var aux = [];
    for(var i = posicion; i < arreglo.length;i++){
      if(arreglo[i].Nivel == 2 && arreglo[i].Nivel1 == 1 ){
        //aux.push(arreglo[i]);
        aux.push({"Id_Indicador":arreglo[i].Id_Indicador,
                  "Descripcion":arreglo[i].Descripcion,
                  "Nivel1":arreglo[i].Nivel1,
                  "Nivel2":arreglo[i].Nivel2,
                  "Nivel3":arreglo[i].Nivel3,
                  "Nivel4":arreglo[i].Nivel4,
                  "Nivel":arreglo[i].Nivel,
                  "check":true,
                  "SeriesNivel2": this.convertirIndicadoresSeriesNivel3(arreglo,i,arreglo[i].Id_Indicador.substring(0,2))
                });
        //this.convertirIndicadoresSeriesNivel3(arreglo,i,arreglo[i].Id_Indicador.substring(0,2));
      }
    }
    return aux;
  }
  convertirIndicadoresSeriesNivel3(arreglo,posicion,subcadena){
    var aux = [];
    for(var i = posicion; i < arreglo.length;i++){
      if(arreglo[i].Nivel == 3 && arreglo[i].Nivel1 == 1 && subcadena == arreglo[i].Id_Indicador.substring(0,2)){
        //aux.push(arreglo[i]);
        aux.push({"Id_Indicador":arreglo[i].Id_Indicador,
                  "Descripcion":arreglo[i].Descripcion,
                  "Nivel1":arreglo[i].Nivel1,
                  "Nivel2":arreglo[i].Nivel2,
                  "Nivel3":arreglo[i].Nivel3,
                  "Nivel4":arreglo[i].Nivel4,
                  "Nivel":arreglo[i].Nivel,
                  "check":true
        });
      }
    }
    return aux;
  }
  Id_Plantilla: number = 0;
  function_ordenarporniveles_CuentaEvaluacion(cuenta,evaluacion){
    this.data_ordenado = [];
    if(this.contador_ejercicioperiodo == 1){
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementos(evaluacion,cuenta[i]);
      }
      this.data_ordenado.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenado)
      });
      this.OcultarIni();
      if(!this.balanceEdos){
        this.ordenEst();
      }else{
        aux = [];
        aux = this.data_ordenado.pop();
        this.data_ordenado.sort(function (a, b) {
          if (a.Id_Cuenta.substring(1,2) > b.Id_Cuenta.substring(1,2)) {
            return 1;
          }
          if (a.Id_Cuenta.substring(1,2) < b.Id_Cuenta.substring(1,2)) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.data_ordenado.push(aux);
      }
    }else{
      var contador_elementos = 0;
      var aux = [];
      for(var i = 0; i < cuenta.length; i ++){
          this.buscarmaselementos(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosG(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosI(evaluacion,cuenta[i]);
      }
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosO(evaluacion,cuenta[i]);
      }
      this.data_ordenado.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenado)
      });
      this.OcultarIni();
      if(!this.balanceEdos){
        this.ordenEst();
      }else{
        aux = [];
        aux = this.data_ordenado.pop();
        this.data_ordenado.sort(function (a, b) {
          if (a.Id_Cuenta.substring(1,2) > b.Id_Cuenta.substring(1,2)) {
            return 1;
          }
          if (a.Id_Cuenta.substring(1,2) < b.Id_Cuenta.substring(1,2)) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.data_ordenado.push(aux);
      }
    }
    /*ConvertirGráficas*/
    if(this.balanceEdos){
      this.Activos(this.data_ordenado);
      this.Pasivos(this.data_ordenado);
      this.CapitalContable(this.data_ordenado);
    }
    /*ConvertirGráficas*/
  }
  public OcultarIni(){
    for(const data of this.data_ordenado){
      var strg = '';
      for(var j = 101; j<114; j++){
        strg = 'A'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 601; j<614; j++){
        strg = 'G'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 501; j<514; j++){
        strg = 'I'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 701; j<714; j++){
        strg = 'O'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 201; j<207; j++){
        strg = 'P'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
      for(var j = 301; j<304; j++){
        strg = 'P'+j;
        if(data['Id_Cuenta'].substring(0,4) == strg && data.Id_Cuenta.length <= 4){
          this.mostrarocultarCuenta(data.Id_Cuenta);
        }
      }
    }
  }
  function_ordenarporniveles_CuentaEvaluacionTodas(cuenta,evaluacion){
    this.data_ordenadoTodas = [];
    if(this.contador_ejercicioperiodo == 1){
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementosTodas(evaluacion,cuenta[i]);
      }
      this.data_ordenadoTodas.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenadoTodas)
      });
    }else{
      var contador_elementos = 0;
      var aux = [];
      for(var i = 0; i < cuenta.length; i ++){
          this.buscarmaselementosTodas(evaluacion,cuenta[i]);
      }
      this.data_ordenadoTodas.push({
        "Id_Evaluacion":"",
        "Id_Cuenta":"",
        "Descripcion":"Pasivo+Capital",
        "Nivel": 1,
        "Check":true,
        "dbCheck":true,
        "Series": this.PasivomasCapital2(this.data_ordenadoTodas)
      });
    }



    /* Export CSV */
    var aux = [];
    this.data_excel = [];
    for(var i = 0; i < this.data_ordenadoTodas.length;i++){
      aux.push(this.data_ordenadoTodas[i].Id_Cuenta);

      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 3 || this.data_ordenadoTodas[i].Id_Cuenta.length == 0){
        aux.push(this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 5){
        aux.push("    " + this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 7){
        aux.push("        " + this.data_ordenadoTodas[i].Descripcion);
      }
      if(this.data_ordenadoTodas[i].Id_Cuenta.length == 9){
        aux.push("            " + this.data_ordenadoTodas[i].Descripcion);
      }
      
      for(var j = 0; j < this.data_ordenadoTodas[i].Series.length;j++){
        if(this.data_ordenadoTodas[i].Series[j].TextoSemaforo == "ERROR"){
          aux.push(this.data_ordenadoTodas[i].Series[j].Valor + " "+ "-");
        }else{
          if(this.data_ordenadoTodas[i].Series[j].Valor < 0){
            aux.push("$ ".concat(this.formatear(this.data_ordenadoTodas[i].Series[j].Valor)));
          }else{
            aux.push("$ ".concat(this.formatear(this.data_ordenadoTodas[i].Series[j].Valor)));
          }
        }
      }
      this.data_excel.push(aux)
      aux = [];
    }
    this.encabezado_excel = ["Código","Descripción"];
    for(var i = 0; i < this.cadena_evaluacionTodas.length; i++){
      this.encabezado_excel.push(this.cadena_evaluacionTodas[i].evaluacion);
    }
    var cadena = "";
    
    for(var i = 0; i < this.data_evaluacionaux.length;i++){
      if(i == 0){
        cadena = this.data_evaluacionaux[i].ejercicioperiodo;
      }else{
        cadena = cadena + " . " + this.data_evaluacionaux[i].ejercicioperiodo;
      }
    }
    this.excelService.generateExcelBalance(this.encabezado_excel,this.data_excel,this.cadena_evaluacionTodas.length,cadena);
    /* Exportar CSV */

  }
  PasivomasCapital2(arreglo){
    var suma = [];
    var pasivo = 0, capital = 0;
    this.contador_ejercicioperiodo = arreglo[0].Series.length;
    for(var i = 0; i < (this.contador_ejercicioperiodo);i++){
      for(var j = 0; j < arreglo.length;j++){
        if(this.TipoInstitucion == 'SO'){
          if(arreglo[j].Id_Cuenta == 'P2'){
            pasivo = arreglo[j].Series[i].Valor;
          }
          if(arreglo[j].Id_Cuenta == 'P30'){
            capital = arreglo[j].Series[i].Valor;
          }
        }else{
          if(arreglo[j].Id_Cuenta == 'P2'){
            pasivo = arreglo[j].Series[i].Valor;
          }
          if(arreglo[j].Id_Cuenta == 'P3'){
            capital = arreglo[j].Series[i].Valor;
          }
        }
      }
      suma.push({"Valor":pasivo + capital});
    }    
    //suma = [{"Valor":pasivo + capital}];
    return suma;
  }
  PasivomasCapital(arreglo){
    var suma = [{"Valor":0}];
    var pasivo = 0, capital = 0;
    for(var i = 0; i < arreglo.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(arreglo[i].Id_Cuenta == 'P2'){
          pasivo = arreglo[i].Series[0].Valor;
        }
        if(arreglo[i].Id_Cuenta == 'P400000000000'){
          capital = arreglo[i].Series[0].Valor;
        }
      }else{
        if(arreglo[i].Id_Cuenta == 'P20'){
          pasivo = arreglo[i].Series[0].Valor;
        }
        if(arreglo[i].Id_Cuenta == 'P30'){
          capital = arreglo[i].Series[0].Valor;
        }
      }
    }    
    suma = [{"Valor":pasivo + capital}];
    return suma;
  }
  function_ordenarporniveles_CuentaEvaluacion2(cuenta,evaluacion){
    this.data_ordenadoOption2 = [];
    if(this.contador_ejercicioperiodo == 1){
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementos2(evaluacion,cuenta[i]);
      }
    }else{
      var contador_elementos = 0;
      var aux = [];
      for(var i = 0; i < cuenta.length; i ++){
        this.buscarmaselementos2(evaluacion,cuenta[i]);
      }
    }    
  }
  cambTipo(tipo){
    switch(tipo){
      case 'bal':
        this.tituloTabla = 'Balance general';
        this.balanceEdos = true;
        this.xAxisLabelActivo = 'Cuenta';
        this.xAxisLabelPasivo = 'Cuenta';
        this.xAxisLabelCapitalContable = 'Cuenta';
        this.getDetalle_Evaluacion_Cuenta(this.detalleevaluacioncuentaEntity);
      break;
      case 'est':
        this.tituloTabla = 'Estado de resultados';
        this.balanceEdos = false;
        this.xAxisLabelActivo = 'Periodo';
        this.xAxisLabelPasivo = 'Periodo';
        this.xAxisLabelCapitalContable = 'Periodo';
        this.getDetalle_Evaluacion_Cuenta(this.detalleevaluacioncuentaEntity);
      break;
    }
  }

  ordenEst(){
    var aux = [];
    var i = 0, g = 1;
    var strg = '';
    for(const dat of this.data_ordenado){
      if(i == 1 || i == 2 || i == 3 || i == 5){
        for(var j = 0; j < this.data_ordenado.length; j++){
          strg = 'G60'+g;
          if(this.data_ordenado[j].Id_Cuenta == strg){
            aux.push(this.data_ordenado[j]);
            g++;
            break;    
          }
        }
      }
      if(dat['Id_Cuenta'].substring(0,3) == 'I50'){
        aux.push(dat);
        i++;
      }
    }
    this.data_ordenado = aux;
    aux = [];
    i = 0;
    var series = [];
    var anHor = [];
    for(const dat of this.data_ordenado){
      //for(var j=0; )
      aux.push(dat);
      if(dat['Id_Cuenta'] == 'I502'){
        series = [];
        anHor = [];
        for(var j=0; j<this.data_ordenado[i].Series.length; j++){
          series.push({Valor: (this.data_ordenado[i-2].Series[j].Valor - this.data_ordenado[i-1].Series[j].Valor + this.data_ordenado[i].Series[j].Valor), TextoSemaforo: undefined});
        }
        for(var j=1; j<this.data_ordenado[i].Series.length; j++){
          anHor.push({evaluacionHorizontal: (series[j].Valor - series[j-1].Valor), porcentajeValorH: ((series[j].Valor - series[j-1].Valor)/series[j-1].Valor)*100});
        }
        aux.push({
          Check: true,
          Descripcion: 'MARGEN FINANCIERO',
          EvaHorizontal: anHor,
          Id_Cuenta: '',
          Id_Evaluacion: 298,
          Nivel: 1,
          Series: series,
          dbCheck: true
        });
      }
      if(dat['Id_Cuenta'] == 'G602'){
        series = [];
        anHor = [];
        for(var j=0; j<this.data_ordenado[i].Series.length; j++){
          series.push({Valor: (this.data_ordenado[i-3].Series[j].Valor - this.data_ordenado[i-2].Series[j].Valor + this.data_ordenado[i-1].Series[j].Valor 
            - this.data_ordenado[i].Series[j].Valor), TextoSemaforo: undefined});
        }
        for(var j=1; j<this.data_ordenado[i].Series.length; j++){
          anHor.push({evaluacionHorizontal: (series[j].Valor - series[j-1].Valor), porcentajeValorH: ((series[j].Valor - series[j-1].Valor)/series[j-1].Valor)*100});
        }
        aux.push({
          Check: true,
          Descripcion: 'MARGEN FINANCIERO AJUSTADO POR RIESGOS CREDITICIOS',
          EvaHorizontal: anHor,
          Id_Cuenta: '',
          Id_Evaluacion: 298,
          Nivel: 1,
          Series: series,
          dbCheck: true
        });
      }
      if(dat['Id_Cuenta'] == 'G604'){
        series = [];
        anHor = [];
        for(var j=0; j<this.data_ordenado[i].Series.length; j++){
          series.push({Valor: (this.data_ordenado[i-8].Series[j].Valor - this.data_ordenado[i-7].Series[j].Valor + this.data_ordenado[i-6].Series[j].Valor 
            - this.data_ordenado[i-5].Series[j].Valor + this.data_ordenado[i-4].Series[j].Valor - this.data_ordenado[i-3].Series[j].Valor + this.data_ordenado[i-2].Series[j].Valor
            + this.data_ordenado[i-1].Series[j].Valor - this.data_ordenado[i].Series[j].Valor), TextoSemaforo: undefined});
        }
        for(var j=1; j<this.data_ordenado[i].Series.length; j++){
          anHor.push({evaluacionHorizontal: (series[j].Valor - series[j-1].Valor), porcentajeValorH: ((series[j].Valor - series[j-1].Valor)/series[j-1].Valor)*100});
        }
        aux.push({
          Check: true,
          Descripcion: 'RESULTADO DE OPERACIÓN',
          EvaHorizontal: anHor,
          Id_Cuenta: '',
          Id_Evaluacion: 298,
          Nivel: 1,
          Series: series,
          dbCheck: true
        });
      }
      if(dat['Id_Cuenta'] == 'I506'){
        series = [];
        anHor = [];
        for(var j=0; j<this.data_ordenado[i].Series.length; j++){
          series.push({Valor: (this.data_ordenado[i-9].Series[j].Valor - this.data_ordenado[i-8].Series[j].Valor + this.data_ordenado[i-7].Series[j].Valor 
            - this.data_ordenado[i-6].Series[j].Valor + this.data_ordenado[i-5].Series[j].Valor - this.data_ordenado[i-4].Series[j].Valor + this.data_ordenado[i-3].Series[j].Valor
            + this.data_ordenado[i-2].Series[j].Valor - this.data_ordenado[i-1].Series[j].Valor + this.data_ordenado[i].Series[j].Valor), TextoSemaforo: undefined});
        }
        for(var j=1; j<this.data_ordenado[i].Series.length; j++){
          anHor.push({evaluacionHorizontal: (series[j].Valor - series[j-1].Valor), porcentajeValorH: ((series[j].Valor - series[j-1].Valor)/series[j-1].Valor)*100});
        }
        aux.push({
          Check: true,
          Descripcion: 'RESULTADO ANTES DE OPERACIONES DISCONTINUADAS',
          EvaHorizontal: anHor,
          Id_Cuenta: '',
          Id_Evaluacion: 298,
          Nivel: 1,
          Series: series,
          dbCheck: true
        });
      }
      if(dat['Id_Cuenta'] == 'I507'){
        series = [];
        anHor = [];
        for(var j=0; j<this.data_ordenado[i].Series.length; j++){
          series.push({Valor: (this.data_ordenado[i-10].Series[j].Valor - this.data_ordenado[i-9].Series[j].Valor + this.data_ordenado[i-8].Series[j].Valor 
            - this.data_ordenado[i-7].Series[j].Valor + this.data_ordenado[i-6].Series[j].Valor - this.data_ordenado[i-5].Series[j].Valor + this.data_ordenado[i-4].Series[j].Valor
            + this.data_ordenado[i-3].Series[j].Valor - this.data_ordenado[i-2].Series[j].Valor + this.data_ordenado[i-1].Series[j].Valor + this.data_ordenado[i].Series[j].Valor), TextoSemaforo: undefined});
        }
        for(var j=1; j<this.data_ordenado[i].Series.length; j++){
          anHor.push({evaluacionHorizontal: (series[j].Valor - series[j-1].Valor), porcentajeValorH: ((series[j].Valor - series[j-1].Valor)/series[j-1].Valor)*100});
        }
        aux.push({
          Check: true,
          Descripcion: 'RESULTADO NETO',
          EvaHorizontal: anHor,
          Id_Cuenta: '',
          Id_Evaluacion: 298,
          Nivel: 1,
          Series: series,
          dbCheck: true
        });
      }
      i++;
    }
    this.data_ordenado = aux;
    aux = [];
    var aux2 = [], aux3 = [];
    for(const dat of this.data_ordenado){
      if(dat['Descripcion'] == 'MARGEN FINANCIERO'){
        for(var i=0; i<dat['Series'].length; i++){
          aux.push({name: this.cadena_evaluacion[i].evaluacion, value: dat['Series'][i].Valor});
        }
      }
      if(dat['Descripcion'] == 'RESULTADO DE OPERACIÓN'){
        for(var i=0; i<dat['Series'].length; i++){
          aux2.push({name: this.cadena_evaluacion[i].evaluacion, value: dat['Series'][i].Valor});
        }
      }
      if(dat['Descripcion'] == 'RESULTADO NETO'){
        for(var i=0; i<dat['Series'].length; i++){
          aux3.push({name: this.cadena_evaluacion[i].evaluacion, value: dat['Series'][i].Valor});
        }
      }
    }
    this.multiActivo = [], this.singleActivo = [];
    this.singleActivo = aux;
    this.multiActivo.push({name: 'MARGEN FINANCIERO',series: aux});

    this.multiPasivo = [], this.singlePasivo = [];
    this.singlePasivo = aux2;
    this.multiPasivo.push({name: 'RESULTADO DE OPERACIÓN',series: aux2});
    
    this.multiCapitalContable = [], this.singleCapitalContable = [];
    this.singleCapitalContable = aux3;
    this.multiCapitalContable.push({name: 'RESULTADO NETO',series: aux3});
  }
  export_png(zon){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 'A':
        grafica = 'A';
        if(this.balanceEdos){
          name = "Activo";
        }else{
          name = "Margen financiero"
        }
      break
      case 'P':
        grafica = 'P';
        if(this.balanceEdos){
          name = "Pasivo";
        }else{
          name = "Resultados de operción"
        }
      break
      case 'C':
        grafica = 'C';
        if(this.balanceEdos){
          name = "Capital contable";
        }else{
          name = "Resultado neto"
        }
      break
    }
    PDF.exportimagenes(grafica,name);
  }

  public tituloTabla = 'Balance General';
  public balanceEdos = true;
  buscarmaselementos(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    var evaHori = [];
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }

    let analisis_h = [];
    for( var i = 0; i < this.cadena_evaluacion.length-1;i++){
      // Analisis horizontal
      if (i == 0){
        analisis_h.push({"evaten":this.cadena_evaluacion[i].evaluacion + " vs " + this.cadena_evaluacion[i+1].evaluacion,"PorcentajeH":this.cadena_evaluacion[i].evaluacion,"porcentajeHor":"%"});
      }else{
        if (i == (arreglo.length-1)){
          analisis_h.push({"evaten":this.cadena_evaluacion[i].evaluacion + " vs " + this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion,"PorcentajeH":this.cadena_evaluacion[i].evaluacion,"porcentajeHor":"%"});
        }else{
          analisis_h.push({"evaten":this.cadena_evaluacion[i].evaluacion + " vs " + this.cadena_evaluacion[i+1].evaluacion,"PorcentajeH":this.cadena_evaluacion[i].evaluacion,"porcentajeHor":"%"}) 
        }
      }
    }
    this.cadena_evaluacionHorizontal = analisis_h;
    let diferencia  = 0;
    let divisor = 1;
    let division = 1;
    for(var i = 0; i < aux_elemento.length-1;i++){
      if (i == 0){
        diferencia = aux_elemento[i+1].Valor-aux_elemento[i].Valor;
        if(aux_elemento[i].Valor == 0)
          division = 0;
        else
          division = diferencia/aux_elemento[i].Valor*100;
        evaHori.push({"evaluacionHorizontal":diferencia,"porcentajeValorH":division}) 
      }else{
        if (i == (aux_elemento.length-1)){
          diferencia = aux_elemento[aux_elemento.length-1].Valor-aux_elemento[i].Valor
          if(aux_elemento[aux_elemento.length-1] == 0)
            division = 0;
          else
            division = diferencia/aux_elemento[aux_elemento.length-1]*100;
          evaHori.push({"evaluacionHorizontal":diferencia,"porcentajeValorH":division}) 
        }else{
          diferencia = aux_elemento[i+1].Valor-aux_elemento[i].Valor;          
          if(aux_elemento[i+1].Valor == 0)
            division = 0;
          else
            division = diferencia/aux_elemento[i+1].Valor*100;
          evaHori.push({"evaluacionHorizontal":diferencia,"porcentajeValorH":division}) 
        }
      }
    }
    var flag = true;
    if(aux.Id_Cuenta.length == 3){
      flag = true;
    }
    if(this.TipoInstitucion == 'SO'){
      if(aux.Id_Cuenta.substring(0,2) != 'G6' && aux.Id_Cuenta.substring(0,2) != 'I5' && aux.Id_Cuenta.substring(0,2) != 'O7' && aux.Id_Cuenta.substring(0,5) != 'A1010' ){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":false,
          "Series": aux_elemento,
          "EvaHorizontal":evaHori
        });
      }
    }else{
      if(this.balanceEdos){
        /*if(aux.Id_Cuenta.substring(0,3) != 'G60'     && aux.Id_Cuenta.substring(0,3) != 'I50'     && aux.Id_Cuenta.substring(0,2) != 'O7'        && aux.Id_Cuenta.substring(0,5) != 'A1010'
        && aux.Id_Cuenta.substring(0,7) != 'A102010' && aux.Id_Cuenta.substring(0,7) != 'A102020' && aux.Id_Cuenta.substring(0,7) != 'A102030'   && aux.Id_Cuenta.substring(0,7) != 'A104010'
        && aux.Id_Cuenta.substring(0,7) != 'A104020' && aux.Id_Cuenta.substring(0,7) != 'A104030' && aux.Id_Cuenta.substring(0,7) != 'A105010'   && aux.Id_Cuenta.substring(0,7) != 'A105020'
        && aux.Id_Cuenta.substring(0,7) != 'A105030' && aux.Id_Cuenta.substring(0,5) != 'A1060'   && aux.Id_Cuenta.substring(0,5) != 'A1070'     && aux.Id_Cuenta.substring(0,5) != 'A1080'
        && aux.Id_Cuenta.substring(0,5) != 'A1090'   && aux.Id_Cuenta.substring(0,5) != 'A1100'   && aux.Id_Cuenta.substring(0,5) != 'A1110'     && aux.Id_Cuenta.substring(0,5) != 'A1120'
        && aux.Id_Cuenta.substring(0,5) != 'A1130'   && aux.Id_Cuenta.substring(0,7) != 'P201010' && aux.Id_Cuenta.substring(0,7) != 'P201020'   && aux.Id_Cuenta.substring(0,7) != 'P202010'
        && aux.Id_Cuenta.substring(0,7) != 'P202020' && aux.Id_Cuenta.substring(0,7) != 'P204050' && aux.Id_Cuenta.substring(0,7) != 'P204060'   && aux.Id_Cuenta.substring(0,7) != 'P204070'
        && aux.Id_Cuenta.substring(0,7) != 'P204071' && aux.Id_Cuenta.substring(0,5) != 'P2060'   && aux.Id_Cuenta.substring(0,9) != 'P30101020' && aux.Id_Cuenta.substring(0,6) != 'P30102'
        && aux.Id_Cuenta.substring(0,6) != 'P30103'  && aux.Id_Cuenta.substring(0,6) != 'P30105'  && aux.Id_Cuenta.substring(0,6) != 'P30107'    && aux.Id_Cuenta.substring(0,6) != 'P30202'
        && aux.Id_Cuenta.substring(0,6) != 'P30203'  && aux.Id_Cuenta.substring(0,6) != 'P30204'  && aux.Id_Cuenta.substring(0,7) != 'P302050'   && aux.Id_Cuenta.substring(0,6) != 'P30206'
        && aux.Id_Cuenta.substring(0,7) != 'P302070' && aux.Id_Cuenta.substring(0,6) != 'P30209'  && aux.Id_Cuenta.substring(0,6) != 'P30208'    && aux.Id_Cuenta.substring(0,6) != 'P30210'
        && aux.Id_Cuenta.substring(0,7) != 'P302050' && aux.Id_Cuenta.substring(0,7) != 'P302110' && aux.Id_Cuenta.substring(0,6) != 'P30212'){
          this.data_ordenado.push({
            "Id_Evaluacion":aux.Id_Evaluacion,
            "Id_Cuenta":aux.Id_Cuenta,
            "Descripcion":aux.cuentaEntity.Descripcion,
            "Nivel": 1,
            "Check":flag,
            "dbCheck":false,
            "Series": aux_elemento,
            "EvaHorizontal":evaHori
          });
        }*/
        switch(aux.Id_Cuenta.length){
          case 2:
            if(aux.Id_Cuenta.substring(0,3) != 'G60' && aux.Id_Cuenta.substring(0,3) != 'I50'&& aux.Id_Cuenta.substring(0,2) != 'O7'){
              this.data_ordenado.push({
                "Id_Evaluacion":aux.Id_Evaluacion,
                "Id_Cuenta":aux.Id_Cuenta,
                "Descripcion":aux.cuentaEntity.Descripcion,
                "Nivel": 1,
                "Check":flag,
                "dbCheck":false,
                "Series": aux_elemento,
                "EvaHorizontal":evaHori
              });
            }
          break;
          case 4:
            if(aux.Id_Cuenta.substring(0,3) != 'G60'     && aux.Id_Cuenta.substring(0,3) != 'I50'     && aux.Id_Cuenta.substring(0,2) != 'O7'){
              this.data_ordenado.push({
                "Id_Evaluacion":aux.Id_Evaluacion,
                "Id_Cuenta":aux.Id_Cuenta,
                "Descripcion":aux.cuentaEntity.Descripcion,
                "Nivel": 2,
                "Check":flag,
                "dbCheck":false,
                "Series": aux_elemento,
                "EvaHorizontal":evaHori
              });
            }
          break;
          case 6:
            if(aux.Id_Cuenta.substring(0,3) != 'G60'     && aux.Id_Cuenta.substring(0,3) != 'I50'     && aux.Id_Cuenta.substring(0,2) != 'O7'        && aux.Id_Cuenta.substring(0,5) != 'A1010'
            && aux.Id_Cuenta.substring(0,7) != 'A102010' && aux.Id_Cuenta.substring(0,7) != 'A102020' && aux.Id_Cuenta.substring(0,7) != 'A102030'   && aux.Id_Cuenta.substring(0,7) != 'A104010'
            && aux.Id_Cuenta.substring(0,7) != 'A104020' && aux.Id_Cuenta.substring(0,7) != 'A104030' && aux.Id_Cuenta.substring(0,7) != 'A105010'   && aux.Id_Cuenta.substring(0,7) != 'A105020'
            && aux.Id_Cuenta.substring(0,7) != 'A105030' && aux.Id_Cuenta.substring(0,5) != 'A1060'   && aux.Id_Cuenta.substring(0,5) != 'A1070'     && aux.Id_Cuenta.substring(0,5) != 'A1080'
            && aux.Id_Cuenta.substring(0,5) != 'A1090'   && aux.Id_Cuenta.substring(0,5) != 'A1100'   && aux.Id_Cuenta.substring(0,5) != 'A1110'     && aux.Id_Cuenta.substring(0,5) != 'A1120'
            && aux.Id_Cuenta.substring(0,5) != 'A1130'   && aux.Id_Cuenta.substring(0,7) != 'P201010' && aux.Id_Cuenta.substring(0,7) != 'P201020'   && aux.Id_Cuenta.substring(0,7) != 'P202010'
            && aux.Id_Cuenta.substring(0,7) != 'P202020' && aux.Id_Cuenta.substring(0,7) != 'P204050' && aux.Id_Cuenta.substring(0,7) != 'P204060'   && aux.Id_Cuenta.substring(0,7) != 'P204070'
            && aux.Id_Cuenta.substring(0,7) != 'P204071' && aux.Id_Cuenta.substring(0,5) != 'P2060'   && aux.Id_Cuenta.substring(0,9) != 'P30101020' && aux.Id_Cuenta.substring(0,6) != 'P30102'
            && aux.Id_Cuenta.substring(0,6) != 'P30103'  && aux.Id_Cuenta.substring(0,6) != 'P30105'  && aux.Id_Cuenta.substring(0,6) != 'P30107'    && aux.Id_Cuenta.substring(0,6) != 'P30202'
            && aux.Id_Cuenta.substring(0,6) != 'P30203'  && aux.Id_Cuenta.substring(0,6) != 'P30204'  && aux.Id_Cuenta.substring(0,7) != 'P302050'   && aux.Id_Cuenta.substring(0,6) != 'P30206'
            && aux.Id_Cuenta.substring(0,7) != 'P302070' && aux.Id_Cuenta.substring(0,6) != 'P30209'  && aux.Id_Cuenta.substring(0,6) != 'P30208'    && aux.Id_Cuenta.substring(0,6) != 'P30210'
            && aux.Id_Cuenta.substring(0,7) != 'P302050' && aux.Id_Cuenta.substring(0,7) != 'P302110' && aux.Id_Cuenta.substring(0,6) != 'P30212'){
              this.data_ordenado.push({
                "Id_Evaluacion":aux.Id_Evaluacion,
                "Id_Cuenta":aux.Id_Cuenta,
                "Descripcion":aux.cuentaEntity.Descripcion,
                "Nivel": 3,
                "Check":flag,
                "dbCheck":false,
                "Series": aux_elemento,
                "EvaHorizontal":evaHori
              });
            }
          break;
        }
      }else{
        if((aux.Id_Cuenta.substring(0,3) == 'I50'  && aux.Id_Cuenta.substring(0,5) != 'I5010' && aux.Id_Cuenta.substring(0,5) != 'I5020' && aux.Id_Cuenta.substring(0,5) != 'I5030'
        && aux.Id_Cuenta.substring(0,5) != 'I5031' && aux.Id_Cuenta.substring(0,5) != 'I5051' && aux.Id_Cuenta.substring(0,5) != 'I5040' && aux.Id_Cuenta.substring(0,5) != 'I5050' 
        && aux.Id_Cuenta.substring(0,5) != 'I5060' && aux.Id_Cuenta.substring(0,5) != 'I5070' && aux.Id_Cuenta.substring(0,5) != 'I5052' && aux.Id_Cuenta.substring(0,5) != 'I5053')
        || (aux.Id_Cuenta.substring(0,3) == 'G60'  && aux.Id_Cuenta.substring(0,5) != 'G6010' && aux.Id_Cuenta.substring(0,5) != 'G6020' && aux.Id_Cuenta.substring(0,5) != 'G6030'
        && aux.Id_Cuenta.substring(0,5) != 'G6040' && aux.Id_Cuenta.substring(0,5) != 'G6041')){
          this.data_ordenado.push({
            "Id_Evaluacion":aux.Id_Evaluacion,
            "Id_Cuenta":aux.Id_Cuenta,
            "Descripcion":aux.cuentaEntity.Descripcion,
            "Nivel": 1,
            "Check":flag,
            "dbCheck":false,
            "Series": aux_elemento,
            "EvaHorizontal":evaHori
          });
        }
      }
    }
  }
  buscarmaselementosG(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }


    var flag = true;
    if(aux.Id_Cuenta.length == 2){
      flag = true;
    }
    if(this.TipoInstitucion == 'SO'){
      if(aux.Id_Cuenta.substring(0,2) == 'G6'){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":false,
          "Series": aux_elemento
        });
      }
    }
  }
  buscarmaselementosI(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }


    var flag = true;
    if(aux.Id_Cuenta.length == 2){
      flag = true;
    }
    if(this.TipoInstitucion == 'SO'){
      if(aux.Id_Cuenta.substring(0,2) == 'I5'){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":false,
          "Series": aux_elemento
        });
      }
    }
  }
  buscarmaselementosO(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacion.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacion[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta && arreglo[i].cuentaEntity.Id_Plantilla == this.Id_Plantilla){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }


    var flag = true;
    if(aux.Id_Cuenta.length == 2){
      flag = true;
    }
    if(this.TipoInstitucion == 'SO'){
      /*if(aux.Id_Cuenta.substring(0,2) == 'O7'){
        this.data_ordenado.push({
          "Id_Evaluacion":aux.Id_Evaluacion,
          "Id_Cuenta":aux.Id_Cuenta,
          "Descripcion":aux.cuentaEntity.Descripcion,
          "Nivel": 1,
          "Check":flag,
          "dbCheck":false,
          "Series": aux_elemento
        });
      }*/
    }
  }
  buscarmaselementosTodas(arreglo,elemento){
    var contador = 0;
    var aux;
    var aux_elemento = [] ;
    for(var k = 0; k < this.cadena_evaluacionTodas.length;k++){
      for(var i = 0; i < arreglo.length; i++){
        if(this.cadena_evaluacionTodas[k].Id_Evaluacion == arreglo[i].Id_Evaluacion){
          if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta){
            contador ++;
            aux_elemento.push({"Valor":arreglo[i].Valor,"TextoSemaforo":arreglo[i].TextoSemaforo});
            aux = arreglo[i];
          }
        }
      }
    }


    var flag = true;
    if(aux.Id_Cuenta.length == 3){
      flag = true;
    }
    if(aux.Id_Cuenta.substring(0,3) != 'G60' && aux.Id_Cuenta.substring(0,3) != 'I50'){
      this.data_ordenadoTodas.push({
        "Id_Evaluacion":aux.Id_Evaluacion,
        "Id_Cuenta":aux.Id_Cuenta,
        "Descripcion":aux.cuentaEntity.Descripcion,
        "Nivel": 1,
        "Check":flag,
        "dbCheck":false,
        "Series": aux_elemento
      });
    }
  }
  buscarenordenado(elemento){
    for(var i = 0; i < this.data_ordenado.length; i++){
      if(elemento.Id_Indicador == this.data_ordenado[i].Id_Indicador){
        this.data_ordenado[i].push(elemento);
      }
    }
  }
  data_constructor_indicador: any [] = [];
  
  getPrestamoTipoProducto(carteraqubosEntitty: CarteraQubosEntity):void{
    /*this.carteraqubosservice.getByAddUrl('carteraxtipoproductomes',carteraqubosEntitty).subscribe(dataArr => {
        //this.data_tipoproducto = dataArr;
        this.data_tipoproducto = this.completarvacios(this.data_tipoproducto_infomes,dataArr);
    });*/
  }
  suma: number = 0;
  data_tipoproducto_infomeslineal: any [] = [];
  data_tipoproducto_infomes_aux: any [] = [];
  data_saldoscaptacion: any[] = [];
  data_tipoproductocaptacionmes: any[] = [];
  getPrestamoTipoProducto_infomes(carteraqubosEntitty: CarteraQubosEntity):void{
    /*this.carteraqubosservice.getByAddUrl('carteraxtipoproducto_infomes',carteraqubosEntitty).subscribe(dataArr => {
        this.data_tipoproducto_infomes = dataArr;
        this.colocarlineal(dataArr);
        /*this.data_tipoproducto_infomes_aux = [];
        for(var i = 0; i < this.data_tipoproducto_infomes.length; i++){
          for(var j = 0; j < this.data_tipoproducto_infomes[i].series.length; j++){
            this.data_tipoproducto_infomes_aux.push({"name":this.data_tipoproducto_infomes[i].name + " | " + this.f_switchmes(this.data_tipoproducto_infomes[i].series[j].name),"value":this.data_tipoproducto_infomes[i].series[j].value});
          }
        }
        this.data_tipoproducto_infomes = this.data_tipoproducto_infomes_aux;
        for(var i = 0; i < this.data_tipoproducto_infomes.length; i++){
          this.suma = this.suma + this.data_tipoproducto_infomes[i].value;
        }
    });*/
  }
  completarvacios(meses,producto){
    for(var i = 0; i < meses.length; i ++){
      for(var j = 0; j < producto.length; j ++){
        if(!this.busqueda(meses[i].name,producto[j].series)){
          producto[j].series.push({"name":meses[i].name,"value":0});
        }
      }
    }
    //alert("Meses --> " + JSON.stringify(meses) + " producto --> " + JSON.stringify(producto));
    return producto;
  } 
  busqueda(mes,cadena){
    var flag = false;
    for(var i = 0; i < cadena.length; i ++){
      if(mes == cadena[i].name){
        flag = true;
      }
    }
    return flag;
  }
  saldocaptaciontotal: number = 0;
  getSaldoCaptacionMes(captacionqubosEntitty: CaptacionQubosEntity):void{
    /*this.captacionqubosservice.getByAddUrl('captacionxmes',captacionqubosEntitty).subscribe(dataArr => {
        this.data_saldoscaptacion = dataArr;
        this.saldocaptaciontotal = 0;
        for(var i = 0; i < this.data_saldoscaptacion.length; i++){
          this.saldocaptaciontotal = this.saldocaptaciontotal + this.data_saldoscaptacion[i].value;
        }
    });*/
  }
  getSaldoCaptacionTipoProductoMes(captacionqubosEntitty: CaptacionQubosEntity):void{
    /*this.captacionqubosservice.getByAddUrl('captacionxtipoproductomes',captacionqubosEntitty).subscribe(dataArr => {
        this.data_tipoproductocaptacionmes = dataArr;
        //this.contruir(this.data_tipoproducto);
    });*/
  }
  colocarlineal(data){
    this.data_tipoproducto_infomeslineal = [];
    this.data_tipoproducto_infomeslineal.push({"name":this.institucionid,"series":this.data_tipoproducto_infomes});
  }
  formatear(num){
    return CommonVariables.formatear(num.toFixed(0));
  }
  porcentaje_total(e){
    return ((e*100)/this.suma).toFixed(2);
  }

  porcentaje_totalc(e){
    return ((e*100)/this.saldocaptaciontotal).toFixed(2);
  }

  updateData(): void {
    const socio = new SocioClienteQubosEntity();
    if(this.institucionid != '0000')
      socio.ifid = this.institucionid;
    //this.getpatmir_Sucursales(socio);
  }
  institucionid: string;
  usuarioid: string;
  TipoInstitucion: string = "";
  getTipoInstitucion(int, cuentaqEntity){
    //this.institucionServiceII.getByAddUrl('',int).subscribe(data => {
      this.TipoInstitucion = 'SC';//data[0].Tipo;
      this.cuentaEntity = new CuentaEntity;
      /*if(this.TipoInstitucion == 'SO'){
        this.Id_Plantilla = 4;
        this.cuentaEntity.Id_Plantilla = 4;
        this.evaluacionEntity.Id_Plantilla = 4;
        this.selectedPeriodo = '2020';
      }else{*/
        this.Id_Plantilla = 5;
        this.cuentaEntity.Id_Plantilla = 5;
        this.evaluacionEntity.Id_Plantilla = 5;
        this.selectedPeriodo = '2019';
      //}

      this.arreglo_informaciondatos();
      this.contadores();
      this.updateData();
      //this.sucursal.InstitucionPatmirId = this.institucionid;
      //this.getCurrentSucursal(this.sucursal);
      this.socioclientequbos.ifid = this.institucionid;
      this.getpatmir_Sucursales(this.socioclientequbos);
      
      //this.getInstitucion(this.institucionEntity);
      this.evaluacionEntity.Id_Institucion = this.id_at;
      //this.evaluacionEntity.Id_Plantilla = 3;
      this.getEvaluacion(this.evaluacionEntity);
      
      
      //this.cuentaEntity.Id_Plantilla = 3;
      this.getCuenta(this.cuentaEntity);
      //this.getCuentaQ(this.cuentaqEntity);
      //this.getIndicador(this.indicadorEntity);
      this.carteraqubos.ifid = this.institucionid;
      if(this.TipoInstitucion == 'SO'){
        this.carteraqubos.anio = '2020';
      }else{
        this.carteraqubos.anio = '2019';
      }
      //this.getPrestamoTipoProducto_infomes(this.carteraqubos);
      //this.getPrestamoTipoProducto(this.carteraqubos);
      this.captacionqubos.mes = this.carteraqubos.mes;
      this.captacionqubos.anio = this.carteraqubos.anio;
      this.captacionqubos.ifid = this.carteraqubos.ifid;
      //this.getSaldoCaptacionMes(this.captacionqubos);
      //this.getSaldoCaptacionTipoProductoMes(this.captacionqubos);
      this.arreglo_anios = this.selectedPeriodo;
      this.crear_lineal();
      this.setColorSaldoColocacion('gama1');
    //});
  }
  getCurrentUser() {
    //this.usuariosService.getCurrentUser().subscribe(usuario => {
        this.institucionid = '0345';//usuario.InstitucionPatmirId;
        this.usuarioid = 'DEMO2';//usuario.Login;
        //this.id_at = usuario.Id_AT;
        this.getInstituciones(this.institucionEntity);
        let int = new InstitucionPatmirEntity();
        int.InstitucionPatmirId = this.institucionid;
        this.getTipoInstitucion(int,this.cuentaEntity);
    //});
  }
  data_SucursalesInstitucion: any [] = [];
  sucursal = new SucursalEntity;
  getCurrentSucursal(sucursalinstitucionEntity: SucursalEntity){
    /*this.sucursalservice.getByAddUrl('',sucursalinstitucionEntity).subscribe(sucursales =>{
      this.data_SucursalesInstitucion = sucursales;
    });*/
    /*this.sucursalservice.getCurrentSucursal().subscribe(sucursal => {
        this.data_SucursalesInstitucion = sucursal;
    });*/
  }


  arreglo_informaciondatos(){
    const socio = new SocioClienteQubosEntity();
    this.infoaniomesEntitty = new InfoAnioMesEntitty;
    if(this.institucionid != '0000'){
      socio.ifid = this.institucionid;
      this.infoaniomesEntitty.ifid = this.institucionid;
    }
    //this.getarreglo_informaciondatos(socio);//Se cambia infoaniomes
    this.getarreglo_informaciondatos(this.infoaniomesEntitty);
  }
  /*getarreglo_informaciondatos(socioclientequbosEntitty: SocioClienteQubosEntity):void{
    this.socioclientequbosservice.getByAddUrl('socios_aniomes',socioclientequbosEntitty).subscribe(dataArr => {*/
  getarreglo_informaciondatos(infoaniomesEntitty: InfoAnioMesEntitty):void{
    /*this.infoaniomesService.getByAddUrl('socios_aniomes',infoaniomesEntitty).subscribe(dataArr => {
      //this.informacion_datos = dataArr;
      this.seleccionar_ult_aniomes(dataArr);
    });*/
  }
  
  seleccionar_ult_aniomes(arreglo){
    this.informacion_datos = arreglo;
    var pos_anio = arreglo.length - 1;
    var pos_mes = arreglo[pos_anio].arrSeries.length - 1;
    this.selectedPeriodo = arreglo[pos_anio].strName;
    this.informacion_datos[pos_anio].dbCheck = true;
    this.ult_mes = this.informacion_datos[pos_anio].arrSeries[pos_mes].strName;
    this.informacion_datos[pos_anio].arrSeries[pos_mes].dbCheck = true;
    this.arreglo_anios = this.selectedPeriodo;
  }
    
  construir_AM(){
    var i = 1;
    for(var j = 0; j < this.primer_arreglo[i].arrSeries.length; j++){
      const infoam = new Info_AM();
      infoam.anio = this.primer_arreglo[i].arrSeries[j].anio;
      infoam.mes = this.primer_arreglo[i].arrSeries[j].mes;
      infoam.check = false;
      this.info_AM.push(infoam);
    }
  }
  aleatorio(minimo,maximo){
    return Math.floor(Math.random() * ((maximo+1)-minimo)+minimo);
  }
  
  contador_green: number = 0;
  contador_yellow: number = 0;
  contador_red: number = 0;
  img(valor){
    var cad = "";
    if(valor <= 60){
      cad = "warning";
    }
    if(valor > 60 && valor < 90 ){
      cad = "square";
    }
    if(valor >= 90 && valor <= 100){
      cad = "circle";
    }
    var img = "assets/img/graficos/"+cad+".png";
    return img;
  }
  bverde(valor){
    var retorno = false;
    if(valor <= 60){
      retorno = false;
    }
    if(valor > 60 && valor < 90 ){
      retorno = false;
    }
    if(valor >= 90 && valor <= 100){
      retorno = true;
    }
    return retorno;
  }
  bred(valor){
    var retorno = false;
    if(valor <= 60){
      retorno = true;
    }
    if(valor > 60 && valor < 90 ){
      retorno = false;
    }
    if(valor >= 90 && valor <= 100){
      retorno = false;
    }
    return retorno;
  }
  byellow(valor){
    var retorno = false;
    if(valor <= 60){
      retorno = false;
    }
    if(valor > 60 && valor < 90 ){
      retorno = true;
    }
    if(valor >= 90 && valor <= 100){
      retorno = false;
    }
    return retorno;
  }
  contadores(){
    this.contador_green = 0;
    this.contador_red = 0;
    this.contador_yellow = 0;
    for(var i = 0; i < this.primer_arreglo.length;i++){
      for(var j = 0; j < this.primer_arreglo[i].arrSeries.length; j++){
        if(this.primer_arreglo[i].arrSeries[j].value  <= 60){
          this.contador_red ++;
        }
        if(this.primer_arreglo[i].arrSeries[j].value >= 90 && this.primer_arreglo[i].arrSeries[j].value <= 100 ){
          this.contador_green ++;
        }
        if(this.primer_arreglo[i].arrSeries[j].value > 60 && this.primer_arreglo[i].arrSeries[j].value < 90 ){
          this.contador_yellow ++;
        }
      }
    }
    for(var i = 0; i < this.segundo_arreglo.length;i++){
      for(var j = 0; j < this.segundo_arreglo[i].arrSeries.length; j++){
        if(this.segundo_arreglo[i].arrSeries[j].value  <= 60){
          this.contador_red ++;
        }
        if(this.segundo_arreglo[i].arrSeries[j].value >= 90 && this.segundo_arreglo[i].arrSeries[j].value <= 100 ){
          this.contador_green ++;
        }
        if(this.segundo_arreglo[i].arrSeries[j].value > 60 && this.segundo_arreglo[i].arrSeries[j].value < 90 ){
          this.contador_yellow ++;
        }
      }
    }
    for(var i = 0; i < this.tercer_arreglo.length;i++){
      for(var j = 0; j < this.tercer_arreglo[i].arrSeries.length; j++){
        if(this.tercer_arreglo[i].arrSeries[j].value  <= 60){
          this.contador_red ++;
        }
        if(this.tercer_arreglo[i].arrSeries[j].value >= 90 && this.tercer_arreglo[i].arrSeries[j].value <= 100 ){
          this.contador_green ++;
        }
        if(this.tercer_arreglo[i].arrSeries[j].value > 60 && this.tercer_arreglo[i].arrSeries[j].value < 90 ){
          this.contador_yellow ++;
        }
      }
    }
  }
  
  flag_combosucursal: boolean = false;
  mostrar_sucursal(){
    if(!this.flag_combosucursal){
      $("#Demo").show();
      this.flag_combosucursal = true;
    }else{
      $("#Demo").hide();
      this.flag_combosucursal = false;
    }
  }
  contruir_arreglo_sucursales(){
  }
  flag_comboejercicio: boolean = false;
  mostrar_ejercicio(){
    if(!this.flag_comboejercicio){
      $("#ejercicio").show();
      this.flag_comboejercicio = true;
    }else{
      $("#ejercicio").hide();
      this.flag_comboejercicio = false;
    }
  }
  flag_comboperiodo: boolean = false;
  mostrar_periodo(){
    if(!this.flag_comboperiodo){
      $("#periodo").show();
      this.flag_comboperiodo = true;
    }else{
      $("#periodo").hide();
      this.flag_comboperiodo = false;
    }
  }
  seleccion_ejercicioperiodo(val){
    //this.info_AM = [];
    /*for(var i = 0; i < this.informacion_datos.length; i ++){
      this.informacion_datos[i].dbCheck = false;
    }*/
    for(var i = 0; i < this.info_AM.length; i ++){
      if(this.info_AM[i].mes == val){
        if(this.info_AM[i].dbCheck){
          this.info_AM[i].dbCheck = false;
        }else{
          this.info_AM[i].dbCheck = true;
        }
      }
    }
  }
  obtener_sucursal_selec(){
    var cadena = "";
    for(var i = 0;i < this.arreglo_sucursales.length;i++){
      if(this.arreglo_sucursales[i].dbCheck){
        cadena = this.arreglo_sucursales[i].strName;
      }
    }
    return cadena;
  }
  obtener_ejercicio_selec(){
    var cadena = "";
    for(var i = 0;i < this.informacion_datos.length;i++){
      if(this.informacion_datos[i].dbCheck){
        cadena = this.informacion_datos[i].strName;
      }
    }
    return cadena;
  }
  obtener_periodo_selec(){
    var cadena = "";
    var contador = 0;
    for(var j = 0; j < this.info_AM.length; j ++){
      if(this.info_AM[j].dbCheck){
        if(contador == 0){
          cadena = this.info_AM[j].mes;
        }else{
          cadena = cadena + "," + this.info_AM[j].mes;
        }
        contador ++;
      }
    }
    return cadena;
  }
  titulo_indicador: string = "";
  seleccion_ejercicio(val){
    this.info_AM = [];
    for(var i = 0; i < this.informacion_datos.length; i ++){
      this.informacion_datos[i].dbCheck = false;
    }
    for(var i = 0; i < this.informacion_datos.length; i ++){
      if(this.informacion_datos[i].strName == val){
        if(this.informacion_datos[i].dbCheck){
          this.informacion_datos[i].dbCheck = false;
        }else{
          this.informacion_datos[i].dbCheck = true;
        }
      }
    }
    this.construir_AM();
  }
  flag_dropsucursal: boolean = false;
  flag_dropmes: boolean = false;
  flag_dropindicadores: boolean = false;
  flag_dropinstitucion: boolean = false;
  DropInstituciones(){
    if(this.flag_dropinstitucion){
      $("#demoInstituciones").hide();
      this.flag_dropinstitucion = false;
    }else{
      $("#demoInstituciones").show();
      this.flag_dropinstitucion = true;
    }
  }
  myDropFuncSucursal(){
    if(this.flag_dropsucursal){
      $("#dropSucursal").hide();
      this.flag_dropsucursal = false;
    }else{
      this.flag_dropsucursal = true;
      $("#dropSucursal").show();
    }
  }
  myDropFuncmes(){
    if(this.flag_dropmes){
      $("#demoDropmes").hide();
      this.flag_dropmes = false;
    }else{
      this.flag_dropmes = true;
      $("#demoDropmes").show();
    }
  }
  myDropFuncindicadores(){
    if(this.flag_dropindicadores){
      $("#demoDropindicadores").hide();
      this.flag_dropindicadores = false;
    }else{
      this.flag_dropindicadores = true;
      $("#demoDropindicadores").show();
      for(var i = 0; i < this.data_indicadorSeries.length;i++){
        $("#remove_"+this.data_indicadorSeries[i].Id_Indicador).show();
        $("#add_"+this.data_indicadorSeries[i].Id_Indicador).hide();
        for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
          $("#subcontenedor"+this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador).hide();
          $("#remove2N_"+this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador).hide();
          $("#add2N_"+this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador).show();
        }
      }
    }
  }
  checked_sucursal: boolean = false;
  sucursal_seleccionado(e){
    if(e == 0){
      if(this.checked_sucursal){
        this.checked_sucursal = false;
      }else{
        this.checked_sucursal = true;
      }
      for(var i = 0; i < this.arreglo_sucursales.length; i++){
        if(this.checked_sucursal){
          this.arreglo_sucursales[i].dbCheck = true;
        }else{
          this.arreglo_sucursales[i].dbCheck = false;
        }
      }
    }else{
      for(var i = 0; i < this.arreglo_sucursales.length; i ++){
        if(e == this.arreglo_sucursales[i].dbId){
          if(this.arreglo_sucursales[i].dbCheck){
            this.arreglo_sucursales[i].dbCheck = false;
          }else{
            this.arreglo_sucursales[i].dbCheck = true;
          }
        }
      }
    }
  }
  porcentaje(item){
    return CommonVariables.porcentaje(this.data_tipoproducto_infomes,item).toFixed(2);
  }
  porcentajec(item_tabla,item){
    return CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item).toFixed(2);
  }
  border_verde(item){
    var verde = false;
    //tendra un promedio de 61 a 100
    /*var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    if(porce > 20 && porce <= 100){
      verde = true;
      this.contador_green = this.contador_green + 1;
    }*/
    if(item == 'V'){
      verde = true;
      //this.contador_green = this.contador_green + 1;
    }

    return verde;
  }
  border_rojo(item){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    /*var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    if(porce >= 0 && porce <= 8){
      rojo = true;
      this.contador_red = this.contador_red + 1;
      //this.contruir();
    }*/
    if(item == 'R'){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
    }
    return rojo;
  }
  border_rojo_estatico(){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    //var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    //if(porce >= 0 && porce <= 8){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
      //this.contruir();
    //}
    return rojo;
  }
  border_amarillo(item){
    var amarillo = false;
    //tendrá un promedio de 21 a 60
    /*var porce = CommonVariables.porcentaje(this.data_tipoproducto_infomes,item);
    if(porce > 8 && porce <= 20){
      amarillo = true;
      this.contador_yellow = this.contador_yellow + 1;
      //this.contruir();
    }*/

    if(item == 'A'){
      amarillo = true;
      //this.contador_yellow = this.contador_yellow + 1;
    }
    return amarillo;
  }
  tabla_informacion1: boolean = true;
  border_verde2(value){
    var verde = false;
    //tendra un promedio de 61 a 100
    var porce = (value*100)/this.suma;
    if(porce > 20 && porce <= 100){
      verde = true;
      //this.contador_green = this.contador_green + 1;
    }
    return verde;
  }
  border_rojo2(value){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    var porce = (value*100)/this.suma;
    if(porce >= 0 && porce <= 8){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
    }
    return rojo;
  }
  border_amarillo2(value){
    var amarillo = false;
    //tendrá un promedio de 21 a 60
    var porce = (value*100)/this.suma;
    if(porce > 8 && porce <= 20){
      amarillo = true;
      this.contador_yellow = this.contador_yellow + 1;
    }
    return amarillo;
  }
  border_verdec(value){
  //border_verdec(item_tabla,item){
    var verde = false;
    //tendra un promedio de 61 a 100
    var porce = (value*100)/this.saldocaptaciontotal;
    //var porce = CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item);
    
    if(porce > 20 && porce <= 100){
      verde = true;
      //this.contador_green = this.contador_green + 1;
    }
    return verde;
  }
  
  border_rojoc(value){
  //border_rojoc(item_tabla,item){
    var rojo = false;
    //tendrá un promedio de 0 a 20
    var porce = (value*100)/this.saldocaptaciontotal;
    //var porce = CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item);
    if(porce >= 0 && porce <= 8){
      rojo = true;
      //this.contador_red = this.contador_red + 1;
    }
    return rojo;
  }
  border_amarilloc(value){
  //border_amarilloc(item_tabla,item){
    var amarillo = false;
    //tendrá un promedio de 21 a 60
    var porce = (value*100)/this.saldocaptaciontotal;
    //var porce = CommonVariables.porcentajec(this.data_tipoproductocaptacionmes,item_tabla,item);
    if(porce > 8 && porce <= 20){
      amarillo = true;
      //this.contador_yellow = this.contador_yellow + 1;
    }
    return amarillo;
  }

  // options
  gradient: boolean = false;
  animations: boolean = true;

  colorScheme_balance = {
    domain: ['#72D54A', '#FFCB03','#F74C58']
  };
  balance_results = [
    {
      "name": "Alto",
      "value": 8940000
    },
    {
      "name": "Medium",
      "value": 5000000
    },
    {
      "name": "Bajo",
      "value": 7200000
    }
  ];
  //balance_results: any [] = [];
  labelFormatting(c) {
    return `<h5>${(c.label)} </h5>`;
  }
  valueFormatting(c){
    return `${(c)}`;
  }
  public showXAxis = true;
  public showYAxis = true;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel: "Producto de Crédito";
  public showYAxisLabel = true;
  public yAxisLabel: "Colocación";
  public graphDataChart: any[];
  showGridLines:boolean = false;
  legendTitle: string = "Institución";
  public colorScheme = {
    domain: ['#FF8300']
  };
  contruir(data){
    var por = 0;
    var sumak = 0;
    this.contador_green = 0;
    this.contador_red = 0;
    this.contador_yellow = 0;
    for(var i = 0; i < this.data_tipoproducto_infomes.length; i++){
      for(var j = 0; j < data.length; j++){
        for(var k = 0; k < data[j].series.length;k++){
          if(this.data_tipoproducto_infomes[i].name == data[j].series[k].name){
            sumak = sumak + data[j].series[k].value;
          }
        }
        por = (sumak*100)/this.data_tipoproducto_infomes[i].value;
        sumak = 0;
        if(por > 0 && por <= 8){
          this.contador_red = this.contador_red + 1;
        }else{
          if(por > 8 && por <= 20){
            this.contador_yellow = this.contador_yellow + 1;
          }else{
            this.contador_green = this.contador_green + 1;
          }
        }
      }
      por = (this.data_tipoproducto_infomes[i].value*100)/this.suma;
      if(por > 0 && por <= 8){
        this.contador_red = this.contador_red + 1;
      }else{
        if(por > 8 && por <= 20){
          this.contador_yellow = this.contador_yellow + 1;
        }else{
          this.contador_green = this.contador_green + 1;
        }
      }
    }

    //this.balance_results = [];
    //this.balance_results.push({"name":"Alto","value":this.contador_green},{"name":"Medio","value":this.contador_yellow},{"name":"Bajo","value":this.contador_red});
  }
  grafica_lineal: boolean = false;
  visibility_legend_balance(){
    if(this.grafica_lineal){
      this.grafica_lineal = false;
    }else{
      this.grafica_lineal = true;
    }
  }

  quitar_filtros(){
    /*this.carteraqubos = new CarteraQubosEntity;
    this.carteraqubos.anio = this.selectedPeriodo;
    this.carteraqubos.ifid = this.institucionid;
    this.puntobalancec1 = false;
    this.subtitulobalancec1 = "";
    this.arreglo_anios = this.selectedPeriodo;
    this.getPrestamoTipoProducto_infomes(this.carteraqubos);
    this.getPrestamoTipoProducto(this.carteraqubos);*/
    this.tituloTabla = 'Balance general';
    this.balanceEdos = true;
    this.xAxisLabelActivo = 'Cuenta';
    this.xAxisLabelPasivo = 'Cuenta';
    this.xAxisLabelCapitalContable = 'Cuenta';

    this.pieActivo = false;
    this.barActivo = true;
    this.lineActivo = false;

    this.piePasivo = false;
    this.barPasivo = false;
    this.linePasivo = true;

    this.pieCapitalContable = true;
    this.barCapitalContable = false;
    this.lineCapitalContable = false;

    this.getCurrentUser();
  }

  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType: string = 'line';
  public chartData: any[];
  public chartLabels: any[];
  public chartColors: any[];
  public chartOptions: any;
  chart;
  ctxL;
  crear_lineal(){

    this.chartData = [
      {data: [120, 130, 180, 70,15], label: '2017'}
    ];
    this.chartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May'];
    this.chartColors = [{
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderColor: 'rgba(0, 0, 0, 1)'
    }];
    this.chartType = 'line';
    this.chartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1
          }
        }]
      },
      annotation: {
         drawTime: 'beforeDatasetsDraw',
         annotations: [{
            type: 'box',
            id: 'a-box-1',
            yScaleID: 'y-axis-0',
            yMin: 0,
            yMax: 1,
            backgroundColor: '#4cf03b'
         }, {
            type: 'box',
            id: 'a-box-2',
            yScaleID: 'y-axis-0',
            yMin: 1,
            yMax: 2.7,
            backgroundColor: '#fefe32'
         }, {
            type: 'box',
            id: 'a-box-3',
            yScaleID: 'y-axis-0',
            yMin: 2.7,
            yMax: 5,
            backgroundColor: '#fe3232'
         }]
      }
    }
  }
  /**/
  /*public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Entidad' },
  ];
  public lineChartLabels: any[] = ['S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7'];
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'transparent',
      borderColor: "#ff8300",
    }
  ];*/
  
  public lineChartLegend = true;
  public lineChartType = 'line';
  /*public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
          color: "black"
        },
        scaleLabel: {
          display: true,
          labelString: "Sucursales",
          fontColor: "gray"
        }
      }],
      yAxes: [{
        gridLines: {
          display: false,
          color: "black",
          borderDash: [2, 5],
        },
        scaleLabel: {
          display: true,
          labelString: "Colocación",
          fontColor: "gray"
        }
      }]
    }
  };*/
  actualizartreemap(arreglo){
    this.contador_green = 0;
    this.contador_red = 0; 
    this.contador_yellow = 0; 
    for(var i = 0; i < arreglo.length; i++){
      if(arreglo[i].TextoSemaforo == 'V'){
        this.contador_green ++;
      }
      if(arreglo[i].TextoSemaforo == 'R'){
        this.contador_red ++;
      }
      if(arreglo[i].TextoSemaforo == 'A'){
        this.contador_yellow ++;
      }
    }    
    this.balance_results = [];
    this.balance_results.push({"name":"Alto","value":this.contador_green},{"name":"Medio","value":this.contador_yellow},{"name":"Bajo","value":this.contador_red});
    


  }
  buscar_sucursal(name){
    //alert("Vamos a ver " + name);
    var retorno = "";
    for(var i = 0; i < this.arreglo_sucursales.length;i ++){
      if(this.arreglo_sucursales[i].dbId == name){
        retorno = this.arreglo_sucursales[i].strName;
      }
    }
    return retorno;
  }
  mapBalance: boolean = true;
  chartverticalBalance: boolean = false;
  piechartBalance: boolean = false;
  gbarras(){
    this.mapBalance = false;
    this.chartverticalBalance = true;
    this.piechartBalance = false;
    this.flag_exportar2 = "chartverticalBalance";
    this.showDataLabelBalanceActive = false;
    this.showDataLabelBalanceInactive = true;
    this.showDataLabelBalance = false;
  }
  gmapacalor(){
    this.mapBalance = true;
    this.chartverticalBalance = false;
    this.piechartBalance = false;
    this.flag_exportar2 = "mapBalance";
    this.showDataLabelBalanceActive = false;
    this.showDataLabelBalanceInactive = false;
    this.showDataLabelBalance = false;
  }  
  schemeType = 'ordinal';
  visibilitylegendBalance: boolean = true;
  showDataLabelBalance: boolean = false;
  showDataLabelBalanceActive: boolean = false;
  showDataLabelBalanceInactive: boolean = false;
  visibility_semaforo(){
    if(this.showDataLabelBalance){
      this.showDataLabelBalance = false;
      this.showDataLabelBalanceActive = false;
      this.showDataLabelBalanceInactive = true;
    }else{
      this.showDataLabelBalance = true;
      this.showDataLabelBalanceActive = true;
      this.showDataLabelBalanceInactive = false;
    }
  }
  public doughnutChartLabels  = ['V', 'A', 'R'];
  public doughnutChartData  = [[350, 450, 100]];
  //public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartColors:any = [
    {
      backgroundColor: ['#72D54A','#FFCB03','#F74C58']
    },
    { 
      backgroundColor: '#FFCB03',
    },
    { 
      backgroundColor: '#F74C58',
    },
  ];
  public doughnutChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true,
    borderSkipped: 'bottom',
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
      fullWidth: true, 
    },
    tooltips: {
      callbacks: {
          label: function(tooltipItem, data) {
                var allData = data.datasets[tooltipItem.datasetIndex].data;
                var tooltipLabel = data.labels[tooltipItem.index];
                var tooltipData = allData[tooltipItem.index];
                var total = 0;
                for (var i in allData) {
                    total += allData[i];
                }
                var tooltipPercentage = Math.round((tooltipData / total) * 100);
                return tooltipLabel + ': ' + tooltipPercentage + '%';
          }
      }
    },
  };
  gpastel(){
    this.mapBalance = false;
    this.chartverticalBalance = false;
    this.piechartBalance = true;
    this.convertir_pastel();
    this.flag_exportar2 = "piechartBalance";
    this.showDataLabelBalanceActive = false;
    this.showDataLabelBalanceInactive = false;
    this.showDataLabelBalance = false;
  }
  convertir_pastel(){
    for(var i = 0; i < this.balance_results.length; i ++){
      this.doughnutChartData[0][i] = this.balance_results[i].value;
    }
  }
  flag_exportar: string = "linealsucursal";
  flag_exportar2: string = "mapBalance";
  existe(item,name){
    var retorno = true;
    return retorno;
  }
  glineal(){}
  gtabla(){}
  indicador_seleccionado(idindicador){
    for(var i = 0; i < this.data_indicadorSeries.length; i++){
      if(this.data_indicadorSeries[i].Id_Indicador == idindicador){
        if(this.data_indicadorSeries[i].check){
          this.data_indicadorSeries[i].check = false;
          for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
            this.data_indicadorSeries[i].SeriesNivel1[j].check = false;
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = false;
            }
          }
        }else{
          this.data_indicadorSeries[i].check = true;
          for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
            this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
            }
          }
        }
      }

    }
    this.ocultarmostrarrenglones();
  }
  indicador_seleccionadoN2(idindicador){
    var contador_j = 0;
    for(var i = 0; i < this.data_indicadorSeries.length; i++){
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        if(this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador == idindicador){
          if(this.data_indicadorSeries[i].SeriesNivel1[j].check){
            this.data_indicadorSeries[i].SeriesNivel1[j].check = false;
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = false;
            }
          }else{
            this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
            if(!this.data_indicadorSeries[i].check){
              this.data_indicadorSeries[i].check = true;
            }
            for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
            }
          }
        }
      }
      contador_j = 0;
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        if(this.data_indicadorSeries[i].SeriesNivel1[j].check){
          contador_j ++;
        }
      }
      if(contador_j == 0 && this.data_indicadorSeries[i].SeriesNivel1.length > 0){
        this.data_indicadorSeries[i].check = false;
      }
    }
    this.ocultarmostrarrenglones();
  }
  ocultarmostrarrenglones(){
    for(var i = 0; i < this.data_indicadorSeries.length;i++){
      this.ocultarmostrarfila(this.data_indicadorSeries[i].check,this.data_indicadorSeries[i].Id_Indicador);
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        this.ocultarmostrarfila(this.data_indicadorSeries[i].SeriesNivel1[j].check,this.data_indicadorSeries[i].SeriesNivel1[j].Id_Indicador);
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          this.ocultarmostrarfila(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check,this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].Id_Indicador);
        }
      }
    }
  }
  ocultarmostrarfila(flag,indicador){
    for(var i = 0; i < this.data_ordenado.length; i++){
      if(this.data_ordenado[i].Id_Indicador == indicador){
        this.data_ordenado[i].Check = flag;
      }
    }

  }
  mostrar_primernivel(id_indicador){
    $("#contenedor"+id_indicador).show();
    $("#add_"+id_indicador).hide();
    $("#remove_"+id_indicador).show();
  }
  ocultar_primernivel(id_indicador){
    $("#contenedor"+id_indicador).hide();
    $("#add_"+id_indicador).show();
    $("#remove_"+id_indicador).hide();
  }
  mostrar_segundonivel(id_indicador){
    $("#subcontenedor"+id_indicador).show();
    $("#add2N_"+id_indicador).hide();
    $("#remove2N_"+id_indicador).show();
  }
  ocultar_segundonivel(id_indicador){
    $("#subcontenedor"+id_indicador).hide();
    $("#add2N_"+id_indicador).show();
    $("#remove2N_"+id_indicador).hide();
  }
  indicador_seleccionadoN3(id_indicador){
    var contador_k = 0,contador_j = 0;
    for(var i = 0; i < this.data_indicadorSeries.length;i++){
      for(var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        contador_k = 0;
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          if(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check){
            contador_k ++;
          }
        }
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          if(id_indicador == this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].Id_Indicador){
            if(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check){
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = false;
            }else{
              this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check = true;
              if(contador_k == 0){
                //no habia ninguno seleccionado entonces se habilita el nivel anterior
                this.data_indicadorSeries[i].SeriesNivel1[j].check = true;
                if(!this.data_indicadorSeries[i].check){
                  this.data_indicadorSeries[i].check = true;
                }
              }
            }
          }
        }
        contador_k = 0;
        for(var k = 0; k < this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2.length;k++){
          if(this.data_indicadorSeries[i].SeriesNivel1[j].SeriesNivel2[k].check){
            contador_k ++;
          }
        }
        if(contador_k == 0){
          //No hay ninguno seleccionado entonces sehabilitamos el primero 
          this.data_indicadorSeries[i].SeriesNivel1[j].check = false;
        }
      }
      contador_j = 0
      for( var j = 0; j < this.data_indicadorSeries[i].SeriesNivel1.length;j++){
        if(this.data_indicadorSeries[i].SeriesNivel1[j].check){
          contador_j ++;
        }
      }
      if(contador_j == 0){
        this.data_indicadorSeries[i].check = false;
      }
    }
    this.ocultarmostrarrenglones();
  }
  data_excel: any [] = [];
  encabezado_excel: any [] = [];
  export_csv(){
    var aux = [];
    this.data_excel = [];
    for(var i = 0; i < this.data_ordenado.length;i++){
      aux.push(this.data_ordenado[i].Id_Cuenta);

      if(this.data_ordenado[i].Id_Cuenta.length == 2 || this.data_ordenado[i].Id_Cuenta.length == 0){
        aux.push(this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 4){
        aux.push("    " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 6){
        aux.push("        " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 8){
        aux.push("            " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 10){
        aux.push("                " + this.data_ordenado[i].Descripcion);
      }
      if(this.data_ordenado[i].Id_Cuenta.length == 12){
        aux.push("                    " + this.data_ordenado[i].Descripcion);
      }

      for(var j = 0; j < this.data_ordenado[i].Series.length;j++){
        if(this.data_ordenado[i].Series[j].TextoSemaforo == "ERROR"){
          aux.push(this.data_ordenado[i].Series[j].Valor + " "+ "-");
        }else{
          if(this.data_ordenado[i].Series[j].Valor < 0){
            aux.push("$ ".concat(this.formatear(this.data_ordenado[i].Series[j].Valor)));
          }else{
            aux.push("$ ".concat(this.formatear(this.data_ordenado[i].Series[j].Valor)));
          }
        }
      }
      this.data_excel.push(aux)
      aux = [];
    }
    this.encabezado_excel = ["Código","Descripción"];
    for(var i = 0; i < this.cadena_evaluacion.length; i++){
      this.encabezado_excel.push(this.cadena_evaluacion[i].evaluacion);
    }
    var cadena = "";
    
    for(var i = 0; i < this.data_evaluacionaux.length;i++){
      if(i == 0){
        cadena = this.data_evaluacionaux[i].ejercicioperiodo;
      }else{
        cadena = cadena + " . " + this.data_evaluacionaux[i].ejercicioperiodo;
      }
    }
    this.excelService.generateExcelBalance(this.encabezado_excel,this.data_excel,this.cadena_evaluacion.length,cadena);
  }
  export_csvTodos(){
    this.getEvaluacionTodas(this.evaluacionEntity);
  }
  data_cuenta: any [] = [];
  
  getCuenta(cuentaEntity: CuentaEntity):void{
    /*this.cuentaService.getByAddUrl('cuenta',cuentaEntity).subscribe(dataArr => {
        this.data_cuenta = dataArr;
    });*/
    this.razonesFinancieras.getCuenta(cuentaEntity).subscribe(response=>{
      if (response.http.code === 200) {
        let datos = response.message;
        if (datos){
          this.data_cuenta = datos;
        }
      }
    });
  }
  data_cuentaq: any [] = [];
  getCuentaQ(cuentaqEntity: CuentaQEntity):void{
    /*this.cuentaqService.getByAddUrl('cuentaqubos',cuentaqEntity).subscribe(dataArr => {
        this.data_cuentaq = dataArr;
    });*/
  }
  espaciosenblanco(description,idcuenta){
    var cadenaespaciosenblanco = "";
    for(var i = 0; i < idcuenta.length;i++){
      cadenaespaciosenblanco = cadenaespaciosenblanco.concat("__");
    }
    description = cadenaespaciosenblanco.concat(description);
    return description;
  }
  buscarnivel0(idcuenta){
    var flag = false;
    if(this.TipoInstitucion == 'SO'){
      if(idcuenta.length == 2 || idcuenta.length == 0){
        flag = true;
      }
    }else{
      if(idcuenta.length == 2 || idcuenta.length == 0){
        flag = true;
      }
    }
    return flag;
  }
  buscarnivel20(idcuenta){
    var flag = false;
    if(idcuenta.length == 3)
      flag = true;
    if(idcuenta.trim() == "I5" || idcuenta.trim() == "G6")
      flag = false;

    return flag;
  }
  buscarnivel201(idcuenta){
    if(idcuenta == "I5" || idcuenta == "G6")
      return true;
    else
      return false;
  }
  buscarnivel1(idcuenta){
    var flag = false;
    if(idcuenta.length == 4)// --> original --> if(idcuenta.length == 5 || idcuenta.length == 7)
      flag = true;
    return flag;
  }
  buscarnivel2(idcuenta){
    var flag = false;
    if(idcuenta.length == 6)
      flag = true;
    return flag;
  }
  buscarnivel3(idcuenta){
    var flag = false;
    if(idcuenta.length == 7)
      flag = true;
    return flag;
  }
  buscarnivel4(idcuenta){
    var flag = false;
    if(idcuenta.length == 8)
      flag = true;
    return flag;
  }
  buscarnivel5(idcuenta){
    var flag = false;
    if(idcuenta.length == -7)
      flag = true;
    return flag;
  }
  buscarnivelEncabezado(idcuenta){
    var flag = false;
    
    if(this.TipoInstitucion == 'SO'){
      if(idcuenta.length == 2 || idcuenta.length == 0 )
        flag = true;
    }else{
      if(idcuenta.length == 2 || idcuenta.length == 0 )
        flag = true;
    }

    return flag;
  }
  buscarnivelEncabezado2(idcuenta){
    var flag = false;
    var len = 0;
    if(this.TipoInstitucion == 'SO'){
      len = 2;
    }else{
      len = 4;
    }
    if(idcuenta.length > len)
      flag = true;
    return flag;
  }
  buscarnivelEncabezadoformula(idcuenta,formula){
    var flag = false;
    if(idcuenta.length == 3 && formula != "")
      flag = true;
    if(idcuenta == "PACA")
      flag = true;
    if(idcuenta.trim() == "I5" || idcuenta.trim() == "G6")
      flag = false;

    return flag;
  }
  buscarnivelEncabezadoformula2(idcuenta,formula){
    var flag = false;
    if(idcuenta.length > 3)
      flag = true;
    if(idcuenta == "PACA")
      flag = false;
    if(idcuenta.trim() == "I5" || idcuenta.trim() == "G6")
      flag = true;

    return flag;
  }
  buscarIdCuenta(idcuenta){
    if(idcuenta == "PACA" || idcuenta == "RF" || idcuenta == "RR" || idcuenta == "RN")
      idcuenta = "";
    return idcuenta;

  }
  option1: boolean = true;
  option2: boolean = false;
  tabla_informacion2: boolean = false;
  data_ordenadoOption2: any[] = [];
  cambiarpresentacionBalance(){
    if(this.option1){
      this.option1 = false;
      this.option2 = true;
      this.tabla_informacion1 = false;
      this.tabla_informacion2 = true;
      /*this.realizaroperacion(this.data_evaluacionEntidad,"P2+P3");
      this.realizaroperacion(this.data_evaluacionEntidad,"I51-G61");
      this.realizaroperacion(this.data_evaluacionEntidad,"I51-G61-G62");
      this.realizaroperacion(this.data_evaluacionEntidad,"I51-G61-G62-G63+I52");*/
      
    }else{
      this.option2 = false;
      this.option1 = true;
      this.tabla_informacion2 = false;
      this.tabla_informacion1 = true;
    }
  }
  buscarmaselementos2(arreglo,elemento){
    var contador = 0;
    var flag = false;
    var aux;
    var aux_elemento = [] ;
    var id_evaluacion = [];
    for(var i = 0; i < this.data_evaluacion.length;i++){
      if(this.data_evaluacion[i].check){
        id_evaluacion.push(this.data_evaluacion[i].Id_Evaluacion);
      }
    }

    for(var i = 0; i < arreglo.length; i++){
      if(elemento.Id_Cuenta == arreglo[i].cuentaEntity.Id_Cuenta){
        flag = true;
        contador ++;
        aux_elemento.push({"Valor":arreglo[i].Valor});
        aux = arreglo[i];
      }
    }
    if(flag){
      this.data_ordenadoOption2.push({
        "Id_Cuenta":aux.Id_Cuenta,
        "Descripcion":aux.cuentaEntity.Descripcion,
        "Formula":elemento.Formula,
        "Series": aux_elemento
      });
    }else{
      for(var i = 0; i < this.data_evaluacion.length;i++){
        if(this.data_evaluacion[i].check){
          aux_elemento.push({"Valor":this.realizaroperacion(this.data_evaluacionEntidad,elemento.Formula,this.data_evaluacion[i].Id_Evaluacion)});
        }
      }
      this.data_ordenadoOption2.push({
        "Id_Cuenta":elemento.Id_Cuenta,
        "Descripcion":elemento.Descripcion,
        "Formula":elemento.Formula,
        "Series": aux_elemento
      });
    }
  }
  signosmatematicos = ['(',')','/','*','+','-','<','>',','];
  buscarsignomatematico(signo){
    var flag = false;
    for(var i = 0; i < this.signosmatematicos.length; i++){
      if(this.signosmatematicos[i] == signo)
        flag = true;
    }
    return flag;
  }
  realizaroperacion(arreglo,formula,id_evaluacion){
    var inicio = 0,fin = 0;
    var cuentaid = "";
    var operacion = 0;
    var signo = '';
    for(var i = 0; i < formula.length;i++){
      if(this.buscarsignomatematico(formula[i])){
        if(operacion == 0){
          operacion = this.buscarvalorcuenta(cuentaid,arreglo,id_evaluacion);
        }else{
          if(this.buscarcuenta(cuentaid,arreglo))
            operacion = this.buscarvalorcuentaoperacion(operacion,signo,cuentaid,arreglo,id_evaluacion);
        }
        cuentaid = "";
        inicio = fin;
        signo = formula[i];
      }else{
        cuentaid = cuentaid.concat(formula[i]);
        fin = i;
      }
    }
    if(this.buscarcuenta(cuentaid,arreglo))
      operacion = this.buscarvalorcuentaoperacion(operacion,signo,cuentaid,arreglo,id_evaluacion);
    
    return operacion;
  }
  buscarcuenta(cuentaid,cuentas){
    var flag = false;
    for(var i = 0; i < cuentas.length; i ++){
      if(cuentaid == cuentas[i].Id_Cuenta){
        flag = true;
      }
    }
    return flag;
  }
  buscarvalorcuenta(cuentaid,cuentas,id_evaluacion){
    var valor = 0;
    for(var i = 0; i < cuentas.length; i ++){
      if(cuentaid == cuentas[i].Id_Cuenta && cuentas[i].Id_Evaluacion == id_evaluacion){
        valor = cuentas[i].Valor;
      }
    }
    return valor;
  }
  buscarvalorcuentaoperacion(total,signo,cuentaid,cuentas,id_evaluacion){
    switch(signo){
      case '+':
        total += this.buscarvalorcuenta(cuentaid,cuentas,id_evaluacion);
      break;
      case '-':
        total -= this.buscarvalorcuenta(cuentaid,cuentas,id_evaluacion);
      break;
    }
    return total;
  }
  data_evaluacionaux: any [] = [];
  ContarEjercicioPeriodo(){
    if(this.data_evaluacionaux.length < 7){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny";
    }    
  }
  SubCadena(cad){
    return cad.substring(0,10);
  }
  mostrarcuenta(idcuenta){
    var flag = false;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta){

      }
    }
    return flag;
  }
  mostrarocultarCuenta(idcuenta){
    var flagcuenta = false;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta){
        if(this.data_ordenado[i].dbCheck){
          this.data_ordenado[i].dbCheck = false;
          flagcuenta = false;
        }else{
          this.data_ordenado[i].dbCheck = true;
          flagcuenta = true;
        }
      }
    }
    //cuenta principal length 3
    /*
    if(this.TipoInstitucion == 'SO'){
      if(idcuenta.length == 2){
        for(var i = 0; i < this.data_ordenado.length;i++){
          if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
            if(!flagcuenta){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = false;
            }else{
              this.data_ordenado[i].Check = false;
              this.data_ordenado[i].dbCheck = true;
            }
          }
          if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == 'P4' && this.data_ordenado[i].Id_Cuenta != idcuenta ){
            if(!flagcuenta){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = false;
            }else{
              this.data_ordenado[i].Check = false;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }else{
      if(idcuenta.length == 2){
        for(var i = 0; i < this.data_ordenado.length;i++){
          if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
            if(!flagcuenta){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = false;
            }else{
              this.data_ordenado[i].Check = false;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }
    */

    //cuenta principal length 5
    /*
    if(idcuenta.length == 4){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,4) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            flagcuenta = false;
          }else{
            this.data_ordenado[i].Check = true;
            flagcuenta = true;
          }
        }
      }
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,4) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
          if(flagcuenta){
            this.data_ordenado[i].Check = true;
            this.data_ordenado[i].dbCheck = true;
          }else{
            this.data_ordenado[i].Check = false;
            this.data_ordenado[i].dbCheck = false;
          }
        }
      }
    }
    */
    //cuenta principal length 7
    /*
    if(idcuenta.length == 7){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,7) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            flagcuenta = false;
          }else{
            this.data_ordenado[i].Check = true;
            flagcuenta = true;
          }
        }
      }
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,7) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
          if(flagcuenta){
            this.data_ordenado[i].Check = true;
          }else{
            this.data_ordenado[i].Check = false;
          }
        }
      }
    }
    */
    /*
    //cuenta principal length 7
    if(idcuenta.length == 7){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,7) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta ){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
          }else{
            this.data_ordenado[i].Check = true;
          }
        }
      }
    }
    */

    if(idcuenta.length == 2){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,2) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            this.data_ordenado[i].dbCheck = false;
          }else{
            if(this.data_ordenado[i].Nivel == 2){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }
    if(idcuenta.length == 4){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,4) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            this.data_ordenado[i].dbCheck = false;
          }else{
            if(this.data_ordenado[i].Nivel == 3){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }
    if(idcuenta.length == 6){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,6) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
          if(this.data_ordenado[i].Check){ 
            this.data_ordenado[i].Check = false;
            this.data_ordenado[i].dbCheck = false;
          }else{
            if(this.data_ordenado[i].Nivel == 4){
              this.data_ordenado[i].Check = true;
              this.data_ordenado[i].dbCheck = true;
            }
          }
        }
      }
    }
  }
  ActualizarSubCuentas(flag,cuentaid){
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(!flag){
        if(this.data_ordenado[i].Id_Cuenta.substring(0,3) == cuentaid){
          this.data_ordenado[i].dbCheck = true;
        }
      }else{
        if(this.data_ordenado[i].Id_Cuenta.substring(0,3) == cuentaid){
          this.data_ordenado[i].dbCheck = false;
        }
      }
    }
  }
  cadenaIdCuenta(idcuenta){
    var cad = "";
    var flag = false;
    var len = 0;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.TipoInstitucion == 'SO'){
        len = 2;
      }else{len = 2}
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && idcuenta.length == len){
        if(this.data_ordenado[i].dbCheck){
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && idcuenta.length == 4){
        if(this.data_ordenado[i].dbCheck){
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    /*
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && idcuenta.length == 7){
        if(this.data_ordenado[i].dbCheck){
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    */
    /*for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta.substring(0,7) == idcuenta && idcuenta.length == 7){
        if(this.data_ordenado[i].dbCheck){
          flag = true;
        }else{
          flag = false;
        }
      }
    }*/
    if(flag){
      cad = "expand_more";//"add";
    }else{
      cad = "expand_less";//"remove";
    }
    return cad;
  }
  cuentaprincipal(idcuenta){
    var flag = false;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 2){
          flag = true;
        }
      }else{
        if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 2){
          flag = true;
        }
      }
    }
    var flag2 = this.CuentaPrincipalSubcuentas(idcuenta);
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 4 && flag2){
        flag = true;
      }
    }
    /*flag2 = this.CuentaPrincipalSubcuentas2(idcuenta);
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta == idcuenta && this.data_ordenado[i].Id_Cuenta.length == 7 && flag2){
        flag = true;
      }
    }*/

    return flag;
  }
  CuentaPrincipalSubcuentas(idcuenta){
    var con = 0;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta.substring(0,4) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
        con ++;
      }
    }
    if(con > 0){
      return true;
    }else{
      return false;
    }
  }
  CuentaPrincipalSubcuentas2(idcuenta){
    var con = 0;
    for(var i = 0; i < this.data_ordenado.length;i++){
      if(this.data_ordenado[i].Id_Cuenta.substring(0,7) == idcuenta && this.data_ordenado[i].Id_Cuenta != idcuenta){
        con ++;
      }
    }
    if(con > 0){
      return true;
    }else{
      return false;
    }
  }
  ClassAdministrador(){
    if(this.institucionid == '0000'){
      return "w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu ";
    }else{
      return "w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-disabled";
    }
  }
  administrador(){
    if(this.institucionid == '0000'){
      return true;
    }else{
      return false;
    }
  }
  FontBalance(idcuenta,monto){
    var len = 0;
    if(this.TipoInstitucion == 'SO'){
      len = 2;
    }else{
      len = 2;
    }
      if(idcuenta.length == len || idcuenta.length == 0){
        return "w3-elemento-titulo-tableBalance w3-encabezado-balance";
      }else{
        if(monto < 0 ){
          return "w3-elemento-tableBalance";
        }else{
          return "w3-elemento-tableBalance";
        }
      }
  }
  PorcentajeCuentasBalance(idcuenta,monto,index){
    let ele_total = this.data_ordenado.filter(ele => ele.Id_Cuenta === idcuenta);
    var total = ele_total[0].Series[index].Valor;
    return (monto/this.data_ordenado[0].Series[index].Valor)*100;
  }
  FontBalance1ro(idcuenta){
    var len = 0;
    if(this.TipoInstitucion =='SO'){
      len = 2;
    }else{
      len = 2;
    }
    if(idcuenta.length == len || idcuenta.length == 0){
      return "w3-elemento-titulo-tableBalanceBlue w3-margin-lelf-1ro";
    }else{
      return "w3-elemento-tableBalance w3-margin-lelf-1ro";
    }
  }
  /* Gráficas Balance */
  singleActivo: any [] = [];
  multiActivo: any [] = [];
  showLegendActivo: boolean = false;
  showLabelsActivo: boolean = false;
  isDoughnutActivo: boolean = true;
  legendPositionActivo: string = 'below';
  colorSchemeActivo = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  Activos(arreglo){
    this.subtituloActivo = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    this.singleActivo = [];
    for(var i = 0; i < arreglo.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(arreglo[i].Id_Cuenta.substring(0,2) == "A1" && arreglo[i].Id_Cuenta.length < 17 && arreglo[i].Id_Cuenta != 'A1' ){
          this.singleActivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }else{
        if(arreglo[i].Id_Cuenta.substring(0,3) == "A10" && arreglo[i].Id_Cuenta.length < 7 && arreglo[i].Id_Cuenta != 'A10' ){
          this.singleActivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
        /*var aux = [];
        var aux2 = [], aux3 = [];
        
        if(arreglo[i].Id_Cuenta == 'A1'){
          for(var j=0; j<arreglo[i].Series.length; j++){
            aux.push({name: this.cadena_evaluacion[j].evaluacion, value: arreglo[i].Series[j].Valor});
          }
          this.singleActivo = aux;
        }*/
        
      }
    }
    this.multiActivo = [{"name":"Cuenta","series":this.singleActivo}];
  }
  singlePasivo: any [] = [];
  multiPasivo: any [] = [];
  showLegendPasivo: boolean = false;
  showLabelsPasivo: boolean = false;
  isDoughnutPasivo: boolean = true;
  legendPositionPasivo: string = 'below';
  colorSchemePasivo = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  Pasivos(arreglo){
    this.subtituloActivo = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    this.singlePasivo = [];
    for(var i = 0; i < arreglo.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(arreglo[i].Id_Cuenta.substring(0,2) == "P2" && arreglo[i].Id_Cuenta.length < 17 && arreglo[i].Id_Cuenta != 'P2'){
          this.singlePasivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }else{
        if(arreglo[i].Id_Cuenta.substring(0,3) == "P20" && arreglo[i].Id_Cuenta.length < 7 && arreglo[i].Id_Cuenta != 'P20'){
          this.singlePasivo.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }
    }
    this.multiPasivo = [{"name":"Cuenta","series":this.singlePasivo}];
  }
  singleCapitalContable: any [] = [];
  multiCapitalContable: any [] = [];
  showLegendCapitalContable: boolean = false;
  showLabelsCapitalContable: boolean = false;
  isDoughnutCapitalContable: boolean = true;
  legendPositionCapitalContable: string = 'below';
  colorSchemeCapitalContable = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  CapitalContable(arreglo){
    this.subtituloActivo = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    this.singleCapitalContable = [];
    for(var i = 0; i < arreglo.length;i++){
      if(this.TipoInstitucion == 'SO'){
        if(arreglo[i].Id_Cuenta.substring(0,2) == "P4" && arreglo[i].Id_Cuenta.length < 17 && arreglo[i].Id_Cuenta != 'P4'){
          this.singleCapitalContable.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }else{
        if(arreglo[i].Id_Cuenta.substring(0,3) == "P30" && arreglo[i].Id_Cuenta.length < 7 && arreglo[i].Id_Cuenta != 'P30'){
          this.singleCapitalContable.push({"name":arreglo[i].Id_Cuenta,"value":arreglo[i].Series[arreglo[i].Series.length - 1].Valor});
        }
      }
    }
    this.multiCapitalContable = [{"name":"Cuenta","series":this.singleCapitalContable}];
  }
  buscarCuentasPastel(idcuenta){
    var cadena = "";
    if(this.balanceEdos){
      for(var i = 0; i < this.data_ordenado.length;i++){
        if(idcuenta == this.data_ordenado[i].Id_Cuenta){
          cadena = this.data_ordenado[i].Descripcion;
        }
      }
    }else{
      cadena = idcuenta;
    }
    return cadena;
  }
  barActivo: boolean = true;
  barPasivo: boolean = false;
  barCapitalContable: boolean = false;
  lineActivo: boolean = false;
  linePasivo: boolean = true;
  lineCapitalContable: boolean = false;
  pieActivo: boolean = false;
  piePasivo: boolean = false;
  pieCapitalContable: boolean = true;
  hideActivo(){
    this.pieActivo = false;
    this.barActivo = false;
    this.lineActivo = false;
  }
  hidePasivo(){
    this.piePasivo = false;
    this.barPasivo = false;
    this.linePasivo = false;
  }
  hideCapitalContable(){
    this.pieCapitalContable = false;
    this.barCapitalContable = false;
    this.lineCapitalContable = false;
  }
  pastelActivo(){
    this.hideActivo();
    this.pieActivo = true;
  }
  barraActivo(){
    this.hideActivo();
    this.barActivo = true;
  }
  linealActivo(){
    this.hideActivo();
    this.lineActivo = true;
  }
  pastelPasivo(){
    this.hidePasivo();
    this.piePasivo = true;
  }
  barraPasivo(){
    this.hidePasivo();
    this.barPasivo = true;
  }
  linealPasivo(){
    this.hidePasivo();
    this.linePasivo = true;
  }
  pastelCapitalContable(){
    this.hideCapitalContable();
    this.pieCapitalContable = true;
  }
  barraCapitalContable(){
    this.hideCapitalContable();
    this.barCapitalContable = true;
  }
  linealCapitalContable(){
    this.hideCapitalContable();
    this.lineCapitalContable = true;
    this.multiCapitalContable = [{"name":"Cuenta","series":this.singleCapitalContable}];
  }
  // options
  showXAxisActivo = true;
  showYAxisActivo = true;
  gradientActivo = false;
  doughnutActivo = true;
  arcWidth = 0.35;
  showXAxisLabelActivo = true;
  xAxisLabelActivo = 'Cuenta';
  showYAxisLabelActivo = true;
  yAxisLabelActivo = 'Saldo';

  showXAxisPasivo = true;
  showYAxisPasivo = true;
  gradientPasivo = false;
  showXAxisLabelPasivo = true;
  xAxisLabelPasivo = 'Cuenta';
  showYAxisLabelPasivo = true;
  yAxisLabelPasivo = 'Saldo';
  doughnutPasivo = true;

  showXAxisCapitalContable = true;
  showYAxisCapitalContable = true;
  gradientCapitalContable = false;
  showXAxisLabelCapitalContable = true;
  xAxisLabelCapitalContable = 'Cuenta';
  showYAxisLabelCapitalContable = true;
  yAxisLabelCapitalContable = 'Saldo';
  doughnutCapitalContable = true;
  buscarCuentasLineal(model){
    return this.buscarCuentasPastel(model[0].name) + " : $ " + model[0].value;
  }
  showDataLabelActivo: boolean = false;
  showDataLabelPasivo: boolean = false;
  showDataLabelCapitalContable: boolean = false;
  labelActivo(){
    if(this.showDataLabelActivo){
      return "label"
    }else{
      return "label_off"
    }
  }
  ClickActivo(){
    this.showDataLabelActivo = !this.showDataLabelActivo;
  }
  labelPasivo(){
    if(this.showDataLabelPasivo){
      return "label"
    }else{
      return "label_off"
    }
  }
  ClickPasivo(){
    this.showDataLabelPasivo = !this.showDataLabelPasivo;
  }
  labelCapitalContable(){
    if(this.showDataLabelCapitalContable){
      return "label"
    }else{
      return "label_off"
    }
  }
  showDataLabelModal: boolean = false;
  ClickLabelModal(){
    this.showDataLabelModal = !this.showDataLabelModal;
  }
  labelModal(){
    if(this.showDataLabelModal){
      return "label"
    }else{
      return "label_off"
    }
  }
  ClickCapitalContable(){
    this.showDataLabelCapitalContable = !this.showDataLabelCapitalContable;
  }
  showhide_modalAmpliar: boolean = false;
  pieActivoModal: boolean = false;
  piePasivoModal: boolean = false;
  pieCapitalContableModal: boolean = false;
  barActivoModal: boolean = false;
  barPasivoModal: boolean = false;
  barCapitalContableModal: boolean = false;
  lineActivoModal: boolean = false;
  linePasivoModal: boolean = false;
  lineCapitalContableModal: boolean = false;
  hideModalAmpliar(){
    this.pieActivoModal = false;
    this.piePasivoModal = false;
    this.pieCapitalContableModal = false;
    this.barActivoModal = false;
    this.barPasivoModal = false;
    this.barCapitalContableModal = false;
    this.lineActivoModal = false;
    this.linePasivoModal = false;
    this.lineCapitalContableModal = false;
    this.showDataLabelModal = false;
  }
  AmpliarActivo(){
    this.hideModalAmpliar();
    if(this.balanceEdos){
      this.titulomodal = "Activo";
    }else{
      this.titulomodal = "Margen financiero"
    }
    this.subtitulomodal = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    if(this.showhide_modalAmpliar){
      this.showhide_modalAmpliar = false;
      $("#showhide_modalAmpliar").hide();
    }else{
      this.showhide_modalAmpliar = true;
      $("#showhide_modalAmpliar").show();
    }
    if(this.pieActivo){
      this.pieActivoModal = true;
    }
    if(this.barActivo){
      this.barActivoModal = true;
    }
    if(this.lineActivo){
      this.lineActivoModal = true;
    }
  }
  AmpliarPasivo(){
    this.hideModalAmpliar();
    if(this.balanceEdos){
      this.titulomodal = "Pasivo";
    }else{
      this.titulomodal = "Resultados de operación"
    }
    this.subtitulomodal = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    if(this.showhide_modalAmpliar){
      this.showhide_modalAmpliar = false;
      $("#showhide_modalAmpliar").hide();
    }else{
      this.showhide_modalAmpliar = true;
      $("#showhide_modalAmpliar").show();
    }
    if(this.piePasivo){
      this.piePasivoModal = true;
    }
    if(this.barPasivo){
      this.barPasivoModal = true;
    }
    if(this.linePasivo){
      this.linePasivoModal = true;
    }
  }
  AmpliarCapitalContable(){
    if(this.balanceEdos){
      this.titulomodal = "Capital contable";
    }else{
      this.titulomodal = "Resultado neto"
    }
    this.subtitulomodal = this.cadena_evaluacion[this.cadena_evaluacion.length - 1].evaluacion;
    this.hideModalAmpliar();
    if(this.showhide_modalAmpliar){
      this.showhide_modalAmpliar = false;
      $("#showhide_modalAmpliar").hide();
    }else{
      this.showhide_modalAmpliar = true;
      $("#showhide_modalAmpliar").show();
    }
    if(this.pieCapitalContable){
      this.pieCapitalContableModal = true;
    }
    if(this.barCapitalContable){
      this.barCapitalContableModal = true;
    }
    if(this.lineCapitalContable){
      this.lineCapitalContableModal = true;
    }
  }
  showhide_modalAmpliarCerrar(){
    this.showhide_modalAmpliar = false;
    $("#showhide_modalAmpliar").hide();
  }
  titulomodal: string = "";
  puntomodal: boolean = true;
  puntoActivo: boolean = true;
  subtituloActivo: string = "";
  subtitulomodal: string = "";
  /* Gráficas Balance */

  /* NuevaModalidad*/
  flag_dropPerriodo: boolean = false;
  DATA_PERIODOSERIES: any [] = [];
  data_ejercicio: any [] = []; //representación a año
  data_ejercicioaux: any [] = []; //representación a año
  data_periodo: any = []; //representación a mes
  data_periodoaux: any = []; //representación a mes

  DropPeriodo(){
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES[i].series.length;j++){
        $("#remove_"+ this.DATA_PERIODOSERIES[i].series[j].anio).hide();
        $("#add_"+ this.DATA_PERIODOSERIES[i].series[j].anio).show();
        $("#contenedor"+ this.DATA_PERIODOSERIES[i].series[j].anio).hide();
        //$("#remove2N_"+ this.DATA_PERIODOSERIES[i].series[j].anio + this.DATA_PERIODOSERIES[i].series[j].mes).hide(); 
      }
    }
    //alert("ShowPeriodo --> " + this.flag_dropPerriodo + " --> " + JSON.stringify(this.data_periodo));
    $("#demoPeriodo").show();
    //alert("ShowPeriodo --> " + this.flag_dropPerriodo + " --> " + JSON.stringify(this.data_periodo));
    $("#demoDropindicadores").hide();
    $("#demoInstituciones").hide();
    if(this.flag_dropPerriodo){
      $("#demoPeriodo").hide();
      this.flag_dropPerriodo = false;
    }else{
      this.flag_dropPerriodo = true;
      $("#demoPeriodo").show();
      /*
      for(var i = 0; i < this.data_EjercicioPeriodo.length;i++){
        for(var j = 0; j < this.data_EjercicioPeriodo[i].series.length;j++){
          $("#subcontenedorep_"+this.data_EjercicioPeriodo[i].series[j].name).hide();
          $("#removeep2N_"+this.data_EjercicioPeriodo[i].series[j].name).hide();
          $("#addep2N_"+this.data_EjercicioPeriodo[i].series[j].name).show();
        }
      }
      */
    }
  }
  FuncionEjercicioPeriodo(arreglo){
    var AuxArreglo = [];
    for(var i = 0; i < arreglo.length;i++){
      //AuxArreglo.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":true,"ejercicioperiodo":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(0,4),"Fecha_Contable":arreglo[i].Fecha_Contable});
      AuxArreglo.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":true,"ejercicioperiodo":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4),"Fecha_Contable":arreglo[i].Fecha_Contable});

      if(!this.BuscarAnioPeriodoSeries(arreglo[i].Fecha_Contable.substring(0,4))){
        this.DATA_PERIODOSERIES.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":false,"anio":arreglo[i].Fecha_Contable.substring(0,4)});
      }
      if(!this.BuscarAnio(arreglo[i].Fecha_Contable.substring(0,4)))
        this.data_ejercicio.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":false,"anio":arreglo[i].Fecha_Contable.substring(0,4)});
    }
    this.CovertirAnioPeriodoSeries(arreglo);

    this.data_ejercicio[this.data_ejercicio.length - 1 ].check = true;
    this.data_ejercicioaux.push(this.data_ejercicio[this.data_ejercicio.length - 1]);
    this.ConstuirMesAnio(this.data_ejercicio[this.data_ejercicio.length - 1 ].anio,AuxArreglo);
    return AuxArreglo;
  }
  ConstuirMesAnio(anio,arreglo){
    this.data_periodo = [];
    for(var i = 0; i < arreglo.length;i++){
      if(arreglo[i].Fecha_Contable.substring(0,4) == anio){
        if(!this.BuscarMes(FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)))){
          this.data_periodo.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"check":true,"mes":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7))});
        }
      }
    }
    this.data_periodoaux = this.data_periodo;
  }
  BuscarMes(mes){
    var flag = false;
    for(var i = 0; i < this.data_periodo.length;i++){
      if(this.data_periodo[i].mes == mes){
        flag = true;
      }
    }
    return flag;
  }
  UltimasSeisEvaluaciones(arreglo){
    var cadena = "", cad = "";
    var inicio = 0;
    if(arreglo.length > 4){
      inicio = arreglo.length - 4;
    }
    this.cadena_evaluacion = [];
    this.cadena_evaluacionHorizontal = [];
    var contador = 0;
    for(var i = inicio; i < arreglo.length;i++){
      if(contador == 0){
        cadena = arreglo[i].Id_Evaluacion;
        cad = FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4);
      }else{
        cadena = cadena + "," + arreglo[i].Id_Evaluacion;
        cad = cad + " | " + FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4);
      }
      contador ++;
      this.cadena_evaluacion.push({"evaluacion":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4),"Id_Evaluacion":arreglo[i].Id_Evaluacion,"Porcentaje":'%'});
    }
    let analisis_h = [];
    for( var i = inicio; i < arreglo.length-1;i++){
      // Analisis horizontal
      if (i == 0){
        analisis_h.push({"evaten":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4) + " - " + FuncionesMes.f_switchmes2(arreglo[i+1].Fecha_Contable.substring(5,7)) + " " + arreglo[i+1].Fecha_Contable.substring(2,4)}) 
      }else{
        if (i == (arreglo.length-1)){
          analisis_h.push({"evaten":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4) + " - " + FuncionesMes.f_switchmes2(arreglo[arreglo.length-1].Fecha_Contable.substring(5,7)) + " " + arreglo[arreglo[i]-1].Fecha_Contable.substring(2,4)}) 
        }else{
          analisis_h.push({"evaten":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4) + " - " + FuncionesMes.f_switchmes2(arreglo[i+1].Fecha_Contable.substring(5,7)) + " " + arreglo[i+1].Fecha_Contable.substring(2,4)}) 
        }
      }
    }
    this.cadena_evaluacionHorizontal = analisis_h;
    this.subtitulobalancec1 = cad;
    return cadena;
  }
  CovertirAnioPeriodoSeries(arreglo){
    var aux = [];
    var data = [];
    for(var i = 0;i < this.DATA_PERIODOSERIES.length;i++){
      for(var j = 0; j < arreglo.length;j++){
        if(this.DATA_PERIODOSERIES[i].anio == arreglo[j].Fecha_Contable.substring(0,4)){
          aux.push({"Id_Evaluacion":arreglo[j].Id_Evaluacion,
                    "check":false,
                    "mes": FuncionesMes.f_switchmes2(arreglo[j].Fecha_Contable.substring(5,7)),
                    "anio":arreglo[j].Fecha_Contable.substring(0,4)});
        }
      }
      data.push({"Id_Evaluacion":this.DATA_PERIODOSERIES[i].Id_Evaluacion,"check":false,"anio":this.DATA_PERIODOSERIES[i].anio,"series":aux});
      aux = [];
    }
    this.DATA_PERIODOSERIES = data;
    this.data_evaluacionaux = [];
    var inicio = 0;
    if(arreglo.length > 4){
      inicio = arreglo.length - 4;
    }
    for(var i = inicio; i < arreglo.length;i++){
      for(var j = 0; j < this.DATA_PERIODOSERIES.length;j++){
        for(var k = 0; k < this.DATA_PERIODOSERIES[j].series.length;k++){
          if(arreglo[i].Id_Evaluacion == this.DATA_PERIODOSERIES[j].series[k].Id_Evaluacion){
            this.DATA_PERIODOSERIES[j].series[k].check = true;            
            this.data_evaluacionaux.push({"Id_Evaluacion":arreglo[i].Id_Evaluacion,"ejercicioperiodo":FuncionesMes.f_switchmes2(arreglo[i].Fecha_Contable.substring(5,7)) + " " + arreglo[i].Fecha_Contable.substring(2,4)});
          }
        }
      }
    }
  } 
  BuscarAnio(anio){
    var flag = false;
    for(var i = 0; i < this.data_ejercicio.length;i++){
      if(this.data_ejercicio[i].anio == anio){
        flag = true;
      }
    }
    return flag;
  }
  BuscarAnioPeriodoSeries(anio){
    var flag = false;
    for(var i = 0; i < this.DATA_PERIODOSERIES.length;i++){
      if(this.DATA_PERIODOSERIES[i].anio == anio){
        flag = true;
      }
    }
    return flag;
  }
  /* NuevaModalidad*/

  /* AnalisisVertical-Horizontal */
  AnalisisVertical(valor: boolean){
    var si = 0;
    for(const anios of this.DATA_PERIODOSERIES){
      for(const mes of anios.series){
        if(mes.check == true){
          si++;
        }
      }
    }
    if( si >= 2 && si <= 4 ){
      this.analisis_vertical = valor;
      if(this.analisis_vertical)
        this.analisis_horizontal = false;
    }else{
      alert("Seleccione solo 4 meses");
      this.analisis_vertical = false;
      $("#checkV").prop('checked', false);
    }
  }
  AnalisisHorizontal(valor: boolean){
    this.analisis_horizontal = valor;
    if(this.analisis_horizontal)
      this.analisis_vertical = false;
  }
  /* AnalisisVertical-Horizontal */


}
/*
Debe estar construido de esta manera

  [
    { "name":"2019","check":true,"captacion":81639780.18,"colocacion":48102299.39,
      "series":[
        {"name":"abr","captacion":52552198.25,"colocacion":29116491.39},
        {"name":"sep","captacion":81639780.18,"colocacion":48102299.39}
      ]
    }
  ]
*/
