import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EsqueletoPeticionModel} from '../../../model/esqueletoPeticion.model';
import {FiltrosService} from '../../filtros/filtros.service';

@Injectable({
  providedIn: 'root'
})
export class TendenciaService {

  private apiURL = environment.apiDevURL;
  private headers = new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }
  /* construcción de la gráfica por año mes compuesta */
  public getTendenciaGraficacompuesta(valoresAnios: number[], mesesDisponibles , fecha?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'tendencia',
      'aseguradora'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoPrestamo'
        }}};
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablacompuesta(valoresAnios: number[], mesesDisponibles , fecha?): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'tendencia',
      'aseguradora'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        }}};
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public sucursales(valoresAnios: number[], mesesDisponibles, fecha?): Observable<any>{
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'aseguradora'
    );
    const valoresMeses = [];
    if(!fecha){
      for(const mes of mesesDisponibles){
        if(valoresAnios.includes(Number(mes.year)) && mes.check === true){
          valoresMeses.push(mes.value);
        }
      }
    }
    else{
      valoresMeses.push(fecha);
    }
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      },
    };
    peticion.groupby = {
      sucursales: {
        campo: 'sucursal-descripcion'
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /* construcción de la gráfica por anio */
  public getTendenciaGraficaPorAnio(valoresAnios: number[], ultimoValor: string,ultanio): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'tendencia',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoPrestamo'
        }}};
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablaPorAnio(valoresAnios: number[], ultimoValor: string,ultanio): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'tendencia',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + '-12');
    }
    for(const val of valoresMeses){
      if((val.split('-')[0]) == ultanio){
        valoresMeses.pop();
        // @ts-ignore
        valoresMeses.push(ultimoValor.value);
      }
    }
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        }}};
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /* construcción de la gráfica por anio */
  public getTendenciaGraficaPorAnioMes(valoresMeses: string[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'tendencia',
      'aseguradora'
    );
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoPrestamo'
        }}};
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablaPorAnioMes(valoresMeses: string[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'tendencia',
      'aseguradora'
    );
    peticion.groupby = {
      aniomeses: {
        valores: valoresMeses,
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        }}};
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /* construccion de la grafica por sucursal */
  public getTendenciaGraficaPorSucursales(valoresMeses: string[], sucursalesSeleccionadas: object[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'tendencia',
      'tendencia-grafica',
      'aseguradora'
    );
    var sucursales = [];
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      }
    };
    peticion.groupby = {
      sucursales: {
        campo: 'sucursal-descripcion',
        subagrupacion: {
          operacion: 'sumar',
          campo: 'saldoPrestamo'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablaPorSucursales(valoresMeses: string[], sucursalesSeleccionadas: object[]): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'tendencia',
      'tendencia-grafica',
      'aseguradora'
    );
    var sucursales = [];
    peticion.filter = {
      aniosmeses: {
        valores: valoresMeses
      }
    };
    peticion.groupby = {
      sucursales: {
        campo: 'sucursal-descripcion',
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /* construcción de la tabla sucursal colocación */
  public getTendenciaTablaAnioMes(valoresAnios: number[], ultimoValor: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    peticion.filter = {
      aniosmeses: {
        valores : valoresMeses
      }
    }
    peticion.groupby = {
      sucursales: {
        campo: "sucursal-descripcion",
        subagrupacion: {
          operacion: 'sumar-productos',
          campos: ["caso","cafo","ahso","ahcc","crece","ahme","iane","hame","haex","tdsr","pagare","cede"]
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  
  /* construcción de la gráfica por anio */
  public getTendenciaActivosInactivos(valoresAnios: number[], ultimoValor: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-tendencia',
      'actividad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    peticion.filter = {
      aniosmeses: {
        valores : valoresMeses
      }
    }
    peticion.groupby = {
      perspectiva: {
        campo: "tipo.keyword",
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  /** por perspectivas */
  public getTendenciaGraficaPorPerspectivas(perspectiva: string, mesSeleccionado: string,tipo: string, genero: string, municipio: string, sucursalSeleccionada?: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'aseguradora'
    );
    
    var con : any = []
    if(sucursalSeleccionada != 'CONSOLIDADO' && sucursalSeleccionada != 'SELECCIÓN' && sucursalSeleccionada!= undefined){
      con.push({
          campo: 'descripcion.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push({
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
     per.push( {
        campo: "genero.keyword",
        valor: genero
      });
    } 
    if(municipio != ""){
      per.push( {
        campo: "municipio.keyword",
        valor: municipio
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "sumar",
            campo: "saldoPrestamo"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablaPorPerspectivas(perspectiva: string, mesSeleccionado: string,tipo: string, genero: string, municipio: string, sucursalSeleccionada?: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'colocacion',
      'tendencia',
      'tendencia-grafica',
      'aseguradora'
    );
    
    var con : any = []
    if(sucursalSeleccionada != 'CONSOLIDADO' && sucursalSeleccionada != 'SELECCIÓN' && sucursalSeleccionada!= undefined){
      con.push({
          campo: 'descripcion.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push({
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
     per.push( {
        campo: "genero.keyword",
        valor: genero
      });
    } 
    if(municipio != ""){
      per.push( {
        campo: "municipio.keyword",
        valor: municipio
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }

    peticion.groupby = {
      perspectiva: {
        campo: perspectiva,
        subagrupacion: {
            operacion: "contar",
            campo: "numeroSocio"
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaActivosInactivosPer(perspectiva: string, mesSeleccionado: string,tipo: string, genero: string, municipio: string, sucursalSeleccionada?: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-tendencia',
      'actividad',
      'aseguradora'
    );
    var con : any = []
    if(sucursalSeleccionada != 'CONSOLIDADO' && sucursalSeleccionada != 'SELECCIÓN' && sucursalSeleccionada!= undefined){
      con.push({
          campo: 'descripcion.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push({
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
     per.push( {
        campo: "genero.keyword",
        valor: genero
      });
    } 
    if(municipio != ""){
      per.push( {
        campo: "municipio.keyword",
        valor: municipio
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }
    peticion.groupby = {
      perspectiva: {
        campo: "tipo.keyword",
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablaPer(perspectiva: string, mesSeleccionado: string,tipo: string, genero: string, municipio: string, sucursalSeleccionada?: string): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'aseguradora'
    );
    var con : any = []
    if(sucursalSeleccionada != 'CONSOLIDADO' && sucursalSeleccionada != 'SELECCIÓN' && sucursalSeleccionada!= undefined){
      con.push({
          campo: 'descripcion.keyword',
          valor: sucursalSeleccionada
      });
    }
    var per :any = [];
    if(tipo != ""){
      per.push({
        campo: "tipo.keyword",
        valor: tipo
      });
    }
    if(genero != ""){
     per.push( {
        campo: "genero.keyword",
        valor: genero
      });
    } 
    if(municipio != ""){
      per.push( {
        campo: "municipio.keyword",
        valor: municipio
      });
    }
    
    if(con.length != 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
        perspectivas:per,
      };
    }else if(con.length == 0 && per.length != 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        perspectivas:per,
      };
    }else if(con.length != 0 && per.length == 0){
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
        sucursales: con,
      };
    }else{
      peticion.filter = {
        aniosmeses: {valores: [mesSeleccionado]},
      };
    }
    peticion.groupby = {
      sucursales: {
        campo: "sucursal-descripcion",
        subagrupacion: {
          operacion: 'sumar-productos',
          campos: ["caso","cafo","ahso","ahcc","crece","ahme","iane","hame","haex","tdsr","pagare","cede"]
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }

  /** Por sucursales */
  public getTendenciaActivosInactivosSuc(valoresAnios: number[], ultimoValor: string, sucursalSeleccionada): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'afiliacion-tendencia',
      'actividad',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    peticion.filter = {
      aniosmeses: {
        valores : valoresMeses
      },
      sucursales: sucursalSeleccionada
    }
    peticion.groupby = {
      perspectiva: {
        campo: "tipo.keyword",
        subagrupacion: {
          operacion: 'contar',
          campo: 'numeroSocio'
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
  public getTendenciaTablaSuc(valoresAnios: number[], ultimoValor: string, sucursalSeleccionada): Observable<any> {
    const peticion = new EsqueletoPeticionModel(
      'comercial',
      'captacion-tendencia',
      'participacionsucursales',
      'aseguradora'
    );
    const valoresMeses = [];
    for (const anio of valoresAnios) {
      valoresMeses.push(anio.toString() + "-" + ultimoValor);
    }
    peticion.filter = {
      aniosmeses: {
        valores : valoresMeses
      },
      sucursales: sucursalSeleccionada
    }
    peticion.groupby = {
      sucursales: {
        campo: "sucursal-descripcion",
        subagrupacion: {
          operacion: 'sumar-productos',
          campos: ["caso","cafo","ahso","ahcc","crece","ahme","iane","hame","haex","tdsr","pagare","cede"]
        }
      }
    };
    const body = JSON.stringify(peticion);
    return this.http.post(`${this.apiURL}`, body, {headers: this.headers});
  }
}
