<div class="w3-row w3-white" style="height: 100%;">
  <app-header
    [sidenav]="sidenav"
    [title]="menu.selectedMenu"
    [icon]="menu.selectedIcon">
  </app-header>
  <div class=" w3-row w3-white" >
    <mat-sidenav-container  [class]="menu.theme">
      <mat-sidenav position="start" #menu #sidenav
                   class="left-menu w3-white w3-border-right w3-border-gray" mode="side" style="z-index: 1;">
        <app-menu [sidenav]="sidenav"></app-menu>
      </mat-sidenav>
      <mat-sidenav-content class="w3-row">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
