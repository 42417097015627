
<div class="w3-container w3-contenedor-template  " style="height: 7vh;">
    <div class="w3-row ">
      <!-- TITULO DEL COMPONENTE -->
      <div class="w3-quarter padreizquierda ">
            <div class="w3-row hijoizquierda">
                <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
                <span class="w3-text-deep-orange">&bull;</span>
                <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
            </div>
        </div>
      <!-- FIN TITULO DEL COMPONENTE -->

      <!-- FILTROS-->
      <div class="w3-rest ">
        <!-- DEFINICION FILTROS -->
        <div class="w3-noventa2 w3-row ">
          <!-- AÑOS -->
          <div class="w3-third padreizquierda">
            <!-- FILTRO AÑOS DISPONIBLES -->
            <div class="w3-quarter_e hijoizquierda  ">
              Año
              <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios"
                      aria-label="Example icon-button with a menu">
                <mat-icon class="w3-tiny">expand_more</mat-icon>
              </button>

              <mat-menu class="w3-tiny"  #menuanios="matMenu" >
                <ng-container *ngFor="let anio of data_anios">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{anio.name}}" aria-label="First checkbox"
                                  (change)="event_anio(anio.name)">
                      {{anio.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO AÑOS DISPONIBLES -->

            <!-- AÑO SELECCIONADO -->
            <div class="w3-rest  ">
              <div class="w3-row padreizquierda" id="contenedor_btnanios">
                <div class="w3-row hijoizquierda ">
                  <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':
                  anios.check, 'w3-hide':!anios.check}"
                       *ngFor="let anios of data_anios_selec"
                       (click)="event_anio(anios.name)"
                       class="filtroMesAnioSeleccionado">
                    {{anios.name}}
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN AÑO SELECCIONADO -->
          </div>
          <!-- FIN AÑOS -->

          <!-- MESES -->
          <div [class]="VerificarAniosSeleccionados()" >
            <!-- FILTRO MESES DISPONIBLES -->
            <div class="w3-quarter_e hijoizquierda  ">
              Mes
              <button class="w3-button w3-tiny"
                      [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
                <mat-icon class="w3-tiny">expand_more</mat-icon>
              </button>

              <mat-menu class="w3-tiny"  #menumeses="matMenu" >
                <ng-container *ngFor="let mes of data_meses">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{mes.name}}" aria-label="First checkbox"
                                  (change)="event_mes(mes.name)">
                      {{mes.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <!-- FIN FILTRO MESES DISPONIBLES -->

            <!-- MES SELECCIONADO -->
            <div class="w3-rest  ">
              <div class="w3-row padreizquierda" id="contenedor_btnmeses">
                <div [class]="ContarMeses()" >
                  <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':
                  meses.check, 'w3-hide ':!meses.check}"
                       *ngFor="let meses of data_meses_selec" (click)="event_mes(meses.name)"
                       class="filtroMesAnioSeleccionado">
                    {{meses.name}}
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN MES SELECCIONADO -->
          </div>
          <!-- FIN MESES -->

          <!-- consolidado sucursal -->
          <div class="w3-third">
            <div class="w3-half hijoizquierda  ">
              Entidad
              <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuentidad" aria-label="Example icon-button with a menu">
                <mat-icon class="w3-tiny">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-tiny"  #menuentidad="matMenu" >
                <button mat-menu-item>
                  <mat-checkbox name="consolidado" [checked]="ent_consolidado"
                                value="consolidado" aria-label="First checkbox"
                                (change)="event_consolidado()">
                    Consolidado
                  </mat-checkbox>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="sucursales">Sucursales</button>
              </mat-menu>
              <mat-menu #sucursales="matMenu">
                <ng-container *ngFor="let sucursal of data_sucursales">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="sucursal.check" name="{{sucursal.name}}"
                                  (click)="$event.stopPropagation();"
                                  value="{{sucursal.name}}" aria-label="First checkbox"
                                  (change)="event_sucursal(sucursal.name)">
                      {{sucursal.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
          <!-- FIN consolidado sucursal -->
        </div>
        <!-- FIN DEFINICION FILTROS -->

        <!-- BORRAR FILTROS -->
        <div class="w3-rest w3-right padreizquierda ">
          <button class="hijoizquierdad" mat-icon-button (click)="quitar_filtros()">
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
              <img  src="assets/img/graficos/eraser.png">
            </mat-icon>
          </button>
        </div>
        <!-- FIN BORRAR FILTROS -->
      </div>
      <!-- FIN FILTROS-->
    </div>
</div>


<!-- cuerpo de la tabla financiero-->
<div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">
    <div class="w3-row w3-contenedor-template " style="height: 27vh;">
      <div class="w3-row  " style="height: 100%;">
        <!-- ENCABEZADO DEL CONTENDOR DE LAS GRAFICAS-->
        <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
            <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido">Razones financieras</span>
                  <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                  <!--<span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>-->
                </div>
            </div>
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
        </div>
        <!-- ENCABEZADO DEL CONTENDOR DE LAS GRAFICAS-->
        <!--primer row-->
        <div class="w3-row w3-contenedor-template " style="height: 18vh;">
          <div class="w3-row  w3-header-cuadrante " style="height: 6vh;">
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
            <div class="w3-twothird w3-titulo-contenidomenu padreizquierda w3-margin-left">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido">Cobertura financiera</span>
                  <!--<span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>-->
                </div>
            </div>
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
          </div>
          <div class="w3-quarter w3-contenedor-template " >
              <div class="w3-container w3-contenedor-template " style="height: 15vh;">
                <div class="w3-row padreRazones " >
                  <div class="w3-row hijoRazones w3-white borderRazones-green">
                    <div class="w3-row w3-center">
                      índice de capitalización
                    </div>
                    <div class="w3-row padreizquierda">
                      <div class="w3-row w3-center w3-titulo-contenidoRazones hijoizquierda">
                        440,6610%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="w3-quarter">
            <div class="w3-container w3-contenedor-template " style="height: 15vh;">
              <div class="w3-row padreRazones " >
                <div class="w3-row hijoRazones w3-white borderRazones-green">
                  <div class="w3-row w3-center">
                    cobertura cartera vencida
                  </div>
                  <div class="w3-row padreizquierda">
                    <div class="w3-row w3-center w3-titulo-contenidoRazones hijoizquierda">
                      120,5103%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w3-quarter">
            <div class="w3-container w3-contenedor-template " style="height: 15vh;">
              <div class="w3-row padreRazones " >
                <div class="w3-row hijoRazones w3-white borderRazones-blue">
                  <div class="w3-row w3-center">
                    solvencia
                  </div>
                  <div class="w3-row padreizquierda">
                    <div class="w3-row w3-center w3-titulo-contenidoRazones hijoizquierda">
                      102,8056%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w3-quarter">
            <div class="w3-container w3-contenedor-template " style="height: 15vh;">
              <div class="w3-row padreRazones " >
                <div class="w3-row hijoRazones w3-white borderRazones-green">
                  <div class="w3-row w3-center">
                    liquidez
                  </div>
                  <div class="w3-row padreizquierda">
                    <div class="w3-row w3-center w3-titulo-contenidoRazones hijoizquierda">
                      <div class="w3-third w3-center">
                        A
                      </div>
                      <div class="w3-rest w3-center">
                        440,6610%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--primer row-->
      </div>
    </div>

    <!-- Parte de abajo-->
    <div class="w3-row mt-2 ">
      <div class="w3-row w3-contenedor-template" style="height: 26vh;">
        <!--primer row-->
        <div class="w3-row w3-contenedor-template " style="height: 18vh;">
          <div class="w3-row  w3-header-cuadrante " style="height: 6vh;">
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
            <div class="w3-twothird w3-titulo-contenidomenu padreizquierda w3-margin-left">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido">Calidad de activos</span>
                  <!--<span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>-->
                </div>
            </div>
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
          </div>
          <div class="w3-quarter w3-contenedor-template " >
              <div class="w3-container w3-contenedor-template " style="height: 15vh;">
                <div class="w3-row padreRazones " >
                  <div class="w3-row hijoRazones w3-white borderRazones-green">
                    <div class="w3-row w3-center">
                      índice de capitalización
                    </div>
                    <div class="w3-row padreizquierda">
                      <div class="w3-row w3-center w3-titulo-contenidoRazones hijoizquierda">
                        440,6610%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="w3-quarter">
            <div class="w3-container w3-contenedor-template " style="height: 15vh;">
              <div class="w3-row padreRazones " >
                <div class="w3-row hijoRazones w3-white borderRazones-green">
                  <div class="w3-row w3-center">
                    fondeo activos improductivos
                  </div>
                  <div class="w3-row padreizquierda">
                    <div class="w3-row w3-center w3-titulo-contenidoRazones hijoizquierda">
                      120,5103%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--primer row-->
      </div>
    </div>
    <!-- Parte de abajo-->

    <!-- Parte de abajo-->
    <div class="w3-row mt-2 ">
      <div class="w3-row w3-contenedor-template" style="height: 26vh;">
        <!--primer row-->
        <div class="w3-row w3-contenedor-template " style="height: 18vh;">
          <div class="w3-row w3-header-cuadrante " style="height: 6vh;">
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
            <div class="w3-twothird w3-titulo-contenidomenu padreizquierda w3-margin-left">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido">Estructura financiera</span>
                  <!--<span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>-->
                </div>
            </div>
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
          </div>
          <div class="w3-quarter w3-contenedor-template " >
              <div class="w3-container w3-contenedor-template " style="height: 15vh;">
                <div class="w3-row padreRazones " >
                  <div class="w3-row hijoRazones w3-white borderRazones-green">
                    <div class="w3-row w3-center">
                      crédito neto
                    </div>
                    <div class="w3-row padreizquierda">
                      <div class="w3-row w3-center w3-titulo-contenidoRazones hijoizquierda">
                        440,6610%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <!--primer row-->
      </div>
    </div>
    <!-- Parte de abajo-->
</div>
<!-- cuerpo de la tabla financiero-->