<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <!-- TITULO DEL COMPONENTE -->
    <div class="w3-quarter padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter"><b>{{ dataService.currentModule }}</b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange">{{ dataService.currentSubmodule }}</span>
      </div>
    </div>
    <!-- FIN TITULO DEL COMPONENTE -->

    <!-- FILTROS-->
    <div class="w3-rest ">
      <!-- DEFINICION FILTROS -->
      <div class="w3-noventa2 w3-row ">
        <!-- AÑOS -->
        <div class="w3-third padreizquierda">
          <!-- FILTRO AÑOS DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            Año
            <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuanios">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-tiny"  #menuanios="matMenu" >
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                (change)="anioChangeListener(anio)">
                    {{anio.value}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO AÑOS DISPONIBLES -->

          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest" style="height: 4.5vh;">
            <div class="w3-row padreizquierda" style="overflow-y:auto; height: 3vh; margin-top: 2vh;">
              <div class="w3-row">
                <div *ngFor="let anio of aniosDisponibles"
                      (click)="anioChangeListener(anio)"
                     [ngClass]="anio.check?
                          'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                     class="filtroMesAnioSeleccionado">
                  {{anio.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>
        <!-- FIN AÑOS -->

        <!-- MESES -->
        <div [class]="VerificarAniosSeleccionados()" >
          <!-- FILTRO MESES DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            Mes
            <button class="w3-button w3-tiny"
                    [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-tiny"  #menumeses="matMenu" >
              <ng-container>
                <button mat-menu-item>
                  <mat-checkbox [checked]="Complete()"
                                (click)="$event.stopPropagation();"
                                (change)="mesChangeAll($event.checked)">
                    Todos los meses
                  </mat-checkbox>
                </button>
              </ng-container>
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}"
                                (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                    {{mes.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO MESES DISPONIBLES -->

          <!-- MES SELECCIONADO -->
          <div class="w3-rest" style="height: 4.5vh;">
            <div class="w3-row padreizquierda" style="overflow-y:auto; height: 3vh; margin-top: 2vh;">
              <div class="w3-row">
                <div *ngFor="let anio of mesesDeAnioDisponibles"
                    (click)="mesChangeListener(anio, mesesDeAnioDisponibles)"
                    [ngClass]="anio.check?
                    'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
               class="filtroMesAnioSeleccionado" style="margin-bottom: 0.8vh;">
                  {{anio.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        <!-- FIN MESES -->

        <!-- CONSOLIDADO Y SUCURSALES -->
        <div [class]="hizoPers()" class="w3-third" *ngIf="puedeFiltrarPorSucursales">
          <div class="w3-half hijoizquierda">
            Entidad
            <button class="w3-button w3-tiny"  [matMenuTriggerFor]="menuentidad">
              <mat-icon class="w3-tiny">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-tiny"  #menuentidad="matMenu" >
              <button mat-menu-item>
                <mat-checkbox name="consolidado" [checked]="consolidado"
                              (click)="$event.stopPropagation();"
                              (change)="consolidadoChangeListener()"
                              aria-label="First checkbox">
                  Consolidado
                </mat-checkbox>
              </button>
              <button mat-menu-item *ngIf="!consolidado" [matMenuTriggerFor]="sucursales">Sucursales</button>
            </mat-menu>
            <mat-menu #sucursales="matMenu">
              <ng-container *ngFor="let sucursal of sucursalesDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="sucursal.check"
                    (change)="sucursalChangeListener(sucursal)"
                    (click)="$event.stopPropagation();">
                    {{sucursal.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest">
            <div class="w3-row padreizquierda">
              <div class="w3-row hijoizquierda ">
                <div *ngIf="sucursalSeleccionada"
                     [ngClass]="'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu'"
                     class="filtroMesAnioSeleccionado">
                  {{sucursalSeleccionada.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIN CONSOLIDADO Y SUCURSALES -->
      </div>
      <!-- FIN DEFINICION FILTROS -->

      <!-- BORRAR FILTROS -->
      <div class="w3-rest w3-right ">
        <button mat-icon-button>
          <mat-icon class="w3-margin-right-contenido" title="limpiar filtros" (click)="quitarFiltros()">
            <img src="assets/img/graficos/eraser.png">
          </mat-icon>
        </button>
      </div>
      <!-- FIN BORRAR FILTROS -->
    </div>
    <!-- FIN FILTROS-->
  </div>
</div>

<div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">
    <div class="w3-row w3-contenedor-template " style="height: 41vh;">
      
      <div id="Zon1" class="w3-row w3-white" style="height: 100%;">
        <!-- ENCABEZADO DEL CONTENDOR DE LAS GRAFICAS-->
        <div class="w3-row w3-white w3-header-cuadrante " style="height: 6vh;">
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
            <div class="w3-twothird w3-titulo-contenidomenu padreizquierda">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido">Tendencia</span>
                  <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                  <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                </div>
            </div>
            <!-- PRIMER DIV DONDE SE PONDRÀ EL TITULO DEL CUADRANTE-->
            <!-- SEGUNDO DIV EN EL QUE SE PONDRÀ EL APARTADO DEL MENÙ Y PARA MOSTRAR LOS LABELS DE LAS GRÀFICAS-->
            <div class="w3-third w3-right-align_rest ">
              <!--DIV EN EL QUE SE PONDRÀ EL GLOBITO PARA MOSTRAR LOS LABEL DE LAS GRÀFICAS CUANDO SE TENGA-->
              <div class="w3-right hijoderechaTitle">
                <!-- LABEL PARA LAS GRÀFICAS-->
                <mat-icon class=" w3-disabled material-icons-outlined w3-zindex"
                  [ngStyle]="{'color':'gray'}">
                  label_off
                </mat-icon>
                <!-- LABEL PARA LAS GRÀFICAS-->
                <!-- MENU PARA APLICAR LOS FILTROS-->
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                  more_vert</mat-icon>
                <!-- MENU PARA APLICAR LOS FILTROS-->
              </div>
              <!-- ESTOS SON LOS CUERPOS DEL MENU DONDE SE AGREGAN LAS PERSPECTIVAS-->
              <mat-menu #menu="matMenu">
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                    <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                    <span>Gráficas</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button *ngIf="flag_perspectivas" class="mat-menu-item " mat-menu-item [matMenuTriggerFor]="perspectivas">
                    <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">account_tree
                    </mat-icon>
                    <span>Perspectiva</span>
                  </button>
                  <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar">
                    <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">description
                    </mat-icon>
                    <span>Exportar</span>
                  </button>
                  <button (click)="modalCuadrante1()" class="mat-menu-item" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                    <span>Ampliar</span>
                  </button>
                  <button *ngIf="masQuin" class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="cantDat">
                    <mat-icon [ngStyle]="{'color':'gray'}">tag</mat-icon>
                    <span>Datos</span>
                  </button>
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="metric">
                    <mat-icon [ngStyle]="{'color':'gray'}">tag</mat-icon>
                    <span>Métrica</span>
                  </button>
                  <!--<button *ngIf="mesSeleccionado.length > 0 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" class="mat-menu-item " mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"><img style="margin-top: -10px;"
                        src="assets/img/graficos/sinfiltro.png"></mat-icon>
                    <span>Eliminar Filtros</span>
                  </button>
                  <button *ngIf="meses_seleccionados.length > 1 || filtrosPerspectivasActivos.length > 0" (click)="quitar_filtros()" mat-menu-item>
                    <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">
                      arrow_back
                    </mat-icon>
                    <span>Regresar</span>
                  </button>-->

              </mat-menu>
              <mat-menu #metric="matMenu">
                <button mat-menu-item (click)="metrica('sal')">
                  <span>Saldo total</span>
                </button>
                <button mat-menu-item (click)="metrica('num')">
                  <span>Número de cuentas</span>
                </button>
              </mat-menu>
              <mat-menu #graficas="matMenu">
                <button (click)="cambiarGrafica('barras')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Barras</span>
                </button>
                <button mat-menu-item (click)="cambiarGrafica('area')">
                  <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                  <span>Área</span>
                </button>
                <button (click)="cambiarGrafica('lineal')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                  <span>Lineal</span>
                </button>
                <button mat-menu-item (click)="cambiarGrafica('tabla')">
                  <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                  <span>Tabla</span>
                </button>
              </mat-menu>
              <mat-menu #exportar="matMenu">
                <button *ngIf="!g1_tabla" (click)="export_imagen(1)" mat-menu-item>
                  <span>PNG</span>
                </button>
                <button *ngIf="g1_tabla" (click)="export_excel(1)" mat-menu-item>
                  <span>CSV</span>
                </button>
              </mat-menu>
              <mat-menu #cantDat="matMenu">
                <button mat-menu-item (click)="cambioCant(1)">
                  <span>15</span>
                </button>
                <button mat-menu-item (click)="cambioCant(2)">
                  <span>Todos</span>
                </button>
              </mat-menu>
              <mat-menu #perspectivas="matMenu">
                <button (click)="addPerspectiva('tipo.keyword')" mat-menu-item>
                  <span>Tipo</span>
                </button>
                <button (click)="addPerspectiva('genero.keyword')" mat-menu-item>
                  <span>Género</span>
                </button>
                <button (click)="addPerspectiva('municipio.keyword')" mat-menu-item>
                  <span>Municipio</span>
                </button>
              </mat-menu>
              <mat-menu #geografico="matMenu">
                <button class="" (click)="addPerspectiva('geografico_estado')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>Estado </span>
                </button>
                <button class="" (click)="addPerspectiva('geografico_municipio')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>Municipio</span>
                </button>
                <button class="" (click)="addPerspectiva('geografico_localidad')" mat-menu-item>
                  <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                  <span>Localidad</span>
                </button>
              </mat-menu>
              <!-- ESTOS SON LOS CUERPOS DEL MENU-->
            </div>
            <!-- SEGUNDO DIV EN EL QUE SE PONDRÀ EL APARTADO DEL MENÙ Y PARA MOSTRAR LOS LABELS DE LAS GRÀFICAS-->
        </div>
        <!-- ENCABEZADO DEL CONTENDOR DE LAS GRAFICAS-->
        <!-- CUADRANTE DONDE SE CONSTRUIRA LA GRAFICA-->
        <div class="w3-row" style="height: 25vh;width: 100%;">
          <div *ngIf="g1_barra" class="chart_wrap w3-scroll">
            <ngx-charts-bar-vertical *ngIf="saldo"
              id="chartvertical"
              class="bar-half-google w3-labels-ngx-charts"
              style="width: 99%;height: 20vh;"
              [scheme]="colorScheme"
              [results]="datosBarra"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [xAxisLabel]="xAxisLabel"
              [noBarWhenZero]="true"
              [yAxisLabel]="yAxisLabel"
              [showGridLines]="showGridLines"
              (select)="click_grafica($event)"
              [customColors]="customColors"
              [showDataLabel]="showDataLabelT1C1"
              [dataLabelFormatting]="formatDataLabel"
              [yScaleMax]="barScaleMax"
              [yAxisTickFormatting]="yLeftTickFormat">
            </ngx-charts-bar-vertical>
            <ngx-charts-bar-vertical *ngIf="numer"
              id="chartvertical"
              class="bar-half-google w3-labels-ngx-charts"
              style="width: 99%;height: 20vh;"
              [scheme]="colorScheme"
              [results]="dataBarraNum"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [xAxisLabel]="xAxisLabel"
              [noBarWhenZero]="true"
              [yAxisLabel]="yAxisLabel"
              [showGridLines]="showGridLines"
              (select)="click_grafica($event)"
              [customColors]="customColors"
              [showDataLabel]="showDataLabelT1C1"
              [dataLabelFormatting]="formatDataLabel"
              [yScaleMax]="barScaleMax"
              [yAxisTickFormatting]="yLeftTickFormat">
            </ngx-charts-bar-vertical>
          </div>
          <div *ngIf="g1_linea" class="chart_wrap w3-scroll">
            <ngx-charts-line-chart *ngIf="saldo" id="BarLinealC2"
              class="w3-letra-graficas"
              [scheme]="colorScheme"
              [results]="datosLineal"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              legendTitle="Montos"
              [showGridLines]="showGridLines"
              legendPosition="right"
              [yAxisTickFormatting]="yLeftTickFormat"
              [referenceLines]="referenceLines"
              [showRefLines]="showRefLines"
              (select)="click_grafica($event)"
              [yScaleMax]="lineScaleMax"
              >
            </ngx-charts-line-chart>
            <ngx-charts-line-chart *ngIf="numer" id="BarLinealC2"
              class="w3-letra-graficas"
              [scheme]="colorScheme"
              [results]="dataLinealNum"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              legendTitle="Montos"
              [showGridLines]="showGridLines"
              legendPosition="right"
              [yAxisTickFormatting]="yLeftTickFormat"
              [referenceLines]="referenceLines"
              [showRefLines]="showRefLines"
              (select)="click_grafica($event)"
              [yScaleMax]="lineScaleMax"
              >
            </ngx-charts-line-chart>
          </div>
          <div *ngIf="g1_area" class="chart_wrap w3-scroll">
            <ngx-charts-area-chart
              [scheme]="colorScheme"
              [results]="datosLineal"
              [xAxis]="showXAxis"
              [legend]="true"
              [legendTitle]="legendTitle"
              [legendPosition]="'right'"
              [yAxis]="showYAxis"
              [showYAxisLabel]="showYAxisLabel"
              [yAxisLabel]="yAxisLabel"
              (select)="click_grafica($event)"
              [customColors]="customColors"
              [yAxisTickFormatting]="yLeftTickFormat">
              </ngx-charts-area-chart>
          </div>
          <div class="w3-row" *ngIf="g1_tabla" style="height: 36vh;width: 98%; overflow-y:auto;">

            <table class="w3-table-all" mat-table [dataSource]="datatabla" >
              <ng-container matColumnDef="anio">
                <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> {{tit1()}} </th>
                <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.anio}} </td>
              </ng-container>
  
              <ng-container matColumnDef="mes">
                <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> {{tit2()}} </th>
                <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.mes}} </td>
              </ng-container>
              
              <ng-container matColumnDef="saldo">
                <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Saldo </th>
                <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearNormal(element.saldo)}} </td>
              </ng-container>
  
              <ng-container matColumnDef="ncuentas">
                <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Número de cuentas </th>
                <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearNormal(element.ncuen)}} </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="columna;sticky: true;" class="w3-table-title"></tr>
              <tr mat-row *matRowDef="let row; columns: columna;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
            </table>
  
          </div>
        </div>
        <!-- CUADRANTE DONDE SE CONSTRUIRA LA GRAFICA-->
      </div>

    <!-- Parte de abajo-->
    <div class="w3-row w3-margin-top" >
        <div id="Zon2" class="w3-twothird w3-white" style="height: 38vh;">
          <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh;">
            <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
              <div class="w3-row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                <span class="w3-margin-left-contenido ">Saldo por producto</span>
                <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica2}}</span>
              </div>
            </div>
            <div class="w3-rest w3-right-align_rest " >
              <div class="w3-right hijoderechaTitle" style="z-index: 1;">
                  <mat-icon class="w3-margin-right-contenido w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu2">more_vert</mat-icon>
              </div>
              <mat-menu #menu2="matMenu">
                  <button *ngIf="setFlagElementoSeleccionadoT1C2()" class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="filtro2t1c2">
                  <mat-icon class="material-icons-outlined"
                      [ngStyle]="{'color':'gray'}">account_tree
                  </mat-icon>
                  <span>Perspectiva</span>
                  </button>
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas2">
                    <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                    <span>Gráficas</span>
                  </button>
                  <button (click)="inspeccionar()" class="mat-menu-item w3-disabled" mat-menu-item >
                  <mat-icon [ngStyle]="{'color':'gray'}">search</mat-icon>
                  <span>Inspeccionar</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar2">
                    <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                    <span>Exportar</span>
                  </button>
                  <button (click)="modalCuadrante2()" class="mat-menu-item" mat-menu-item >
                  <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                  <span>Ampliar</span>
                  </button>
              </mat-menu>
              <mat-menu #exportar2="matMenu">
                  <button *ngIf="!gtabla" (click)="export_imagen(2)" mat-menu-item>
                    <span>PNG</span>
                  </button>
                  <button *ngIf="gtabla" (click)="export_excel(2)" mat-menu-item>
                    <span>CSV</span>
                  </button>
              </mat-menu>
              <mat-menu #filtro2t1c2="matMenu">
                  <button (click)="ClasificacionCartera()" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                    <span>Clasificación Cartera</span>
                  </button>
                  <button  (click)="ClasificacionCaptacion()" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                    <span>Clasificación Captación</span>
                  </button>
                  <button (click)="fregresart1c2()" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}"></mat-icon>
                    <span>Regresar</span>
                  </button>
              </mat-menu>
              <mat-menu #graficas2="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="tipobar">
                  <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                  <span>Barras</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="tipolin">
                  <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                  <span>Lineal</span>
                </button>
                <button (click)="cambiarGrafica2('tabla')" mat-menu-item>
                  <mat-icon><img style="margin-top: -15px;" src="assets/img/elementotemplate/table.png"></mat-icon>
                  <span>Tabla</span>
                </button>
              </mat-menu>
              <mat-menu #tipobar="matMenu">
                <button (click)="cambiarGrafica2('CASO')" mat-menu-item>
                  <span>CASO</span>
                </button>
                <button (click)="cambiarGrafica2('CAFO')" mat-menu-item>
                  <span>CAFO</span>
                </button>
                <button (click)="cambiarGrafica2('AHSO')" mat-menu-item>
                  <span>AHSO</span>
                </button>
                <button (click)="cambiarGrafica2('AHCC')" mat-menu-item>
                  <span>AHCC</span>
                </button>
                <button (click)="cambiarGrafica2('CRECE')" mat-menu-item>
                  <span>CRECE</span>
                </button>
                <button (click)="cambiarGrafica2('AHME')" mat-menu-item>
                  <span>AHME</span>
                </button>
                <button (click)="cambiarGrafica2('HAME')" mat-menu-item>
                  <span>HAME</span>
                </button>
                <button (click)="cambiarGrafica2('HAEX')" mat-menu-item>
                  <span>HAEX</span>
                </button>
                <button (click)="cambiarGrafica2('TDSR')" mat-menu-item>
                  <span>TDSR</span>
                </button>
                <button (click)="cambiarGrafica2('PAGARE')" mat-menu-item>
                  <span>PAGARE</span>
                </button>
                <button (click)="cambiarGrafica2('CEDE')" mat-menu-item>
                  <span>CEDE</span>
                </button>
              </mat-menu>
              <mat-menu #tipolin="matMenu">
                <button (click)="cambiarGrafica2('CASOL')" mat-menu-item>
                  <span>CASO</span>
                </button>
                <button (click)="cambiarGrafica2('CAFOL')" mat-menu-item>
                  <span>CAFO</span>
                </button>
                <button (click)="cambiarGrafica2('AHSOL')" mat-menu-item>
                  <span>AHSO</span>
                </button>
                <button (click)="cambiarGrafica2('AHCCL')" mat-menu-item>
                  <span>AHCC</span>
                </button>
                <button (click)="cambiarGrafica2('CRECEL')" mat-menu-item>
                  <span>CRECE</span>
                </button>
                <button (click)="cambiarGrafica2('AHMEL')" mat-menu-item>
                  <span>AHME</span>
                </button>
                <button (click)="cambiarGrafica2('HAMEL')" mat-menu-item>
                  <span>HAME</span>
                </button>
                <button (click)="cambiarGrafica2('HAEXL')" mat-menu-item>
                  <span>HAEX</span>
                </button>
                <button (click)="cambiarGrafica2('TDSRL')" mat-menu-item>
                  <span>TDSR</span>
                </button>
                <button (click)="cambiarGrafica2('PAGAREL')" mat-menu-item>
                  <span>PAGARE</span>
                </button>
                <button (click)="cambiarGrafica2('CEDEL')" mat-menu-item>
                  <span>CEDE</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="w3-row w3-white" style="height: 32vh">
            <div *ngIf="gtabla" class="w3-encabezado-table " >
              <table class="w3-table-all" mat-table [dataSource]="dataSource || sortData($event)" matSort (matSortChange)="sortData($event)"> <!--(matSortChange)="sortData($event)"-->
                <ng-container matColumnDef="sucursal">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> Sucursal</span> </th>
                  <td mat-cell *matCellDef="let element"> {{element.sucursal}} </td>
                </ng-container>

                <ng-container matColumnDef="caso">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef > <span mat-sort-header class="w3-right" style="width: 80%;"> CASO</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.caso)}} </td>
                </ng-container>
                
                <ng-container matColumnDef="cafo">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> CAFO</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.cafo)}} </td>
                </ng-container>

                <ng-container matColumnDef="ahso">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> AHSO</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.ahso)}} </td>
                </ng-container>

                <ng-container matColumnDef="ahcc">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> AHCC</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.ahcc)}} </td>
                </ng-container>

                <ng-container matColumnDef="crece">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> CRECE</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.crece)}} </td>
                </ng-container>

                <ng-container matColumnDef="ahme">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> AHME</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.ahme)}} </td>
                </ng-container>

                <ng-container matColumnDef="iane">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> INAE</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.iane)}} </td>
                </ng-container>

                <ng-container matColumnDef="hame">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> HAME</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.hame)}} </td>
                </ng-container>

                <ng-container matColumnDef="haex">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> HAEX</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.haex)}} </td>
                </ng-container>

                <ng-container matColumnDef="tdsr">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> TDSR</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.tdsr)}} </td>
                </ng-container>

                <ng-container matColumnDef="pagare">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> PAGARE</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.pagare)}} </td>
                </ng-container>

                <ng-container matColumnDef="cede">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> CEDE</span> </th>
                  <td style="font-size: 9pt;" mat-cell *matCellDef="let element"> {{formatearNormal(element.cede)}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
              </table>
            </div>
            <div class="w3-row w3-center" *ngIf="gCASO" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataCASO"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gCAFO" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataCAFO"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gAHSO" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataAHSO"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gAHCC" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataAHCC"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gCRECE" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataCRECE"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gAHME" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataAHME"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gHAME" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataHAME"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gHAEX" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataHAEX"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gTDSR" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataTDSR"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gPAGARE" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataPAGARE"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row w3-center" *ngIf="gCEDE" style="height: 30vh;width: 96%;">
              <ngx-charts-bar-vertical 
              [scheme]="colorScheme"
              [results]="dataCEDE"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>

            <div class="w3-row w3-center" *ngIf="gCASOlin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [results]="dataCASOlin"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="visibilityLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="Montos"
                [showGridLines]="showGridLines"
                [legendPosition]="right"
                >
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gCAFOlin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [results]="dataCAFOlin"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="visibilityLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="Montos"
                [showGridLines]="showGridLines"
                [legendPosition]="right"
                >
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gAHSOlin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [results]="dataAHSOlin"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="visibilityLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="Montos"
                [showGridLines]="showGridLines"
                [legendPosition]="right"
                >
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gAHCClin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [results]="dataAHCClin"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="visibilityLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="Montos"
                [showGridLines]="showGridLines"
                [legendPosition]="right"
                >
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gCRECElin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [results]="dataCRECElin"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="visibilityLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="Montos"
                [showGridLines]="showGridLines"
                [legendPosition]="right"
                >
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gAHMElin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart
                [scheme]="colorScheme"
                [results]="dataAHMElin"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="visibilityLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="Montos"
                [showGridLines]="showGridLines"
                [legendPosition]="right"
                >
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gHAMElin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart 
              [results]="dataHAMElin"
              [scheme]="colorScheme"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [legendTitle]="Montos"
              [showGridLines]="showGridLines"
              [legendPosition]="right">
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gHAEXlin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart 
              [results]="dataHAEXlin"
              [scheme]="colorScheme"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [legendTitle]="Montos"
              [showGridLines]="showGridLines"
              [legendPosition]="right">
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gTDSRlin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart 
              [results]="dataTDSRlin"
              [scheme]="colorScheme"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [legendTitle]="Montos"
              [showGridLines]="showGridLines"
              [legendPosition]="right">
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gPAGARElin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart 
              [results]="dataPAGARElin"
              [scheme]="colorScheme"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [legendTitle]="Montos"
              [showGridLines]="showGridLines"
              [legendPosition]="right">
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row w3-center" *ngIf="gCEDElin" style="height: 30vh;width: 96%;">
              <ngx-charts-line-chart 
              [results]="dataCEDElin"
              [scheme]="colorScheme"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [legendTitle]="Montos"
              [showGridLines]="showGridLines"
              [legendPosition]="right">
              </ngx-charts-line-chart>
            </div>
          </div>
        </div>

        <div id="Zon3" class="w3-rest " style="height: 38vh;">
          <div class="w3-row w3-white w3-right" style="width: 97%;">
            <div class="w3-row w3-header-cuadrante padreizquierda " style="height: 6vh;">
              <div class="w3-noventa w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
                <div class="w3-row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                  <span class="w3-margin-left-contenido ">Distribución por tipo</span>
                  <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                  <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica2}}</span>
                </div>
              </div>
              <div class="w3-rest w3-right-align_rest " >
                <div class="w3-right hijoderechaTitle" style="z-index: 1;">
                    <mat-icon class="w3-margin-right-contenido w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu3">more_vert</mat-icon>
                </div>
                <mat-menu #menu3="matMenu">
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas3">
                    <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                    <span>Gráficas</span>
                  </button>
                    <button (click)="inspeccionar()" class="mat-menu-item w3-disabled" mat-menu-item >
                      <mat-icon [ngStyle]="{'color':'gray'}">search</mat-icon>
                      <span>Inspeccionar</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="exportar3">
                      <mat-icon><img style="margin-top: -10px;" src="assets/img/elementotemplate/description.png"></mat-icon>
                      <span>Exportar</span>
                    </button>
                    <button (click)="modalCuadrante3()" class="mat-menu-item" mat-menu-item >
                      <mat-icon [ngStyle]="{'color':'gray'}">crop_free</mat-icon>
                      <span>Ampliar</span>
                    </button>
                </mat-menu>
                <mat-menu #exportar3="matMenu">
                    <button *ngIf="!gtabla3" (click)="export_imagen(3)" mat-menu-item>
                      <span>PNG</span>
                    </button>
                    <button *ngIf="gtabla3" (click)="export_excel(3)" mat-menu-item>
                      <span>CSV</span>
                    </button>
                </mat-menu>
                <mat-menu #graficas3="matMenu">
                  <button (click)="cambiarGrafica3('barras')" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                    <span>Barras</span>
                  </button>
                  <button (click)="cambiarGrafica3('mapa')" mat-menu-item>
                    <mat-icon [ngStyle]="{'color':'gray'}">view_quilt</mat-icon>
                    <span>Mapa de calor</span>
                  </button>
                  <button (click)="cambiarGrafica3('pie')" mat-menu-item>
                    <mat-icon class="material-icons-outlined" [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                    <span>Pastel</span>
                  </button>
                  <button (click)="cambiarGrafica3('tabla')" mat-menu-item>
                    <mat-icon><img style="margin-top: -15px;" src="assets/img/elementotemplate/table.png"></mat-icon>
                    <span>Tabla</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="w3-row" style="height: 32vh">
              <div class="w3-row w3-center" *ngIf="gpie" style="height: 30vh;width: 96%;">
                <google-chart style="height: 30vh;width: 96%;"
                      [type]="type"
                      [data]="data"
                      [options]="options">
                </google-chart>
              </div>
              <div class="w3-row w3-center" *ngIf="gmapa" style="height: 30vh;width: 96%;">
                <ngx-charts-tree-map
                [scheme]="colorScheme"
                [results]="datamapa"
                [gradient]="false"
                [animations]="animations"
                [labelFormatting]="labelFormatting"
                (select)="onSelect($event)">
              </ngx-charts-tree-map>
              </div>
              <div class="w3-row" *ngIf="gtabla3" style="height: 30vh;width: 96%;">
                <div class="w3-table-all">
                  <table mat-table [dataSource]="datatabla3" class=" w3-table" >
                      <!-- Position Column -->
                      <ng-container matColumnDef="generacion">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Tipo </th>
                      <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                      </ng-container>              
                      <!-- Name Column -->
                      <ng-container matColumnDef="valor">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Número de socios  </th>
                      <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearNormal(element.value)}} </td>
                      </ng-container>
                      <!-- Name Column -->
                      <ng-container matColumnDef="porcentaje">
                        <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Porcentaje  </th>
                        <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                        </ng-container>
                      <tr mat-header-row *matHeaderRowDef="columnasC3; sticky: true" class="w3-elemento-titulo-table"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnasC3;"></tr>
                  </table>
                </div>
              </div>
              <div class="w3-row w3-center" *ngIf="gbarras3" style="height: 30vh;width: 96%;">
                <ngx-charts-bar-vertical 
                [scheme]="colorScheme"
                [results]="databarra3"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="false"
                [showGridLines]="false">
                </ngx-charts-bar-vertical>
              </div>
            </div>
          </div>
        </div>
    </div>


  </div>
</div>


<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{titulomodal}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" >&bull;</span>
              <span class=" w3-margin-left-filter w3-subtitulo">{{subtitulomodal}}</span>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <div *ngIf="g1_barra_modal" class=" w3-scroll" style="height: 54vh;">
          <ngx-charts-bar-vertical
            id="chartvertical"
            class=" w3-labels-ngx-charts"
            style="width: 99%;"
            [scheme]="colorScheme"
            [results]="datosBarra"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="showGridLines"
            (select)="click_grafica($event)"
            [customColors]="customColors"
            [showDataLabel]="showDataLabelT1C1"
            [dataLabelFormatting]="formatDataLabel"
            [yScaleMax]="barScaleMax"
            [yAxisTickFormatting]="yLeftTickFormat">
            <!--<ng-template #tooltipTemplate let-model="model">
              <div style="text-align: left;">
                <div *ngFor="let data of datosBarra; let i = index">
                  <span *ngIf="data.name === model.name">
                    {{data.extra.key_as_string}} : {{convertir_moneda(data.value)}}
                  </span>
                </div>
              </div>
            </ng-template>-->
          </ngx-charts-bar-vertical>
        </div>
        <div *ngIf="g1_linea_modal" class=" w3-scroll" style="height: 54vh;">
          <ngx-charts-line-chart  id="BarLinealC2"
              class="w3-letra-graficas"
              [scheme]="colorScheme"
              [results]="datosLineal"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="visibilityLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              legendTitle="Montos"
              [showGridLines]="showGridLines"
              legendPosition="right"
              [yAxisTickFormatting]="yLeftTickFormat"
              [referenceLines]="referenceLines"
              [showRefLines]="showRefLines"
              (select)="onSelect($event)"
              [yScaleMax]="lineScaleMax"
              >
              <!--<ng-template #tooltipTemplate let-model="model" >
                <div style="text-align: left;">
                  <div *ngFor="let data of datosLineal; let i = index">
                      <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                  </div>
                </div>
              </ng-template>
              <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                  <div *ngFor="let data of datosLineal; let i = index">
                    <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                  </div>
              </div>
              </ng-template>-->
            </ngx-charts-line-chart>
        </div>
        <div *ngIf="g1_areamodal" class=" w3-scroll" style="height: 54vh;">
          <ngx-charts-area-chart
            [scheme]="colorScheme"
            [results]="datosLineal"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [customColors]="customColors"
            [yAxisTickFormatting]="yLeftTickFormat">
            </ngx-charts-area-chart>
        </div>
        <div *ngIf="g1_tabla_modal" class=" w3-scroll" style="height: 54vh;">
          <table class="w3-table-all" mat-table [dataSource]="datatabla" >
            <ng-container matColumnDef="anio">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> {{tit1()}} </th>
              <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.anio}} </td>
            </ng-container>

            <ng-container matColumnDef="mes">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> {{tit2()}} </th>
              <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.mes}} </td>
            </ng-container>
            
            <ng-container matColumnDef="saldo">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Saldo </th>
              <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearNormal(element.saldo)}} </td>
            </ng-container>

            <ng-container matColumnDef="ncuentas">
              <th class="w3-elemento-titulo-table w3-text-deep-orange" style="background-color: #F9F9F9;" mat-header-cell *matHeaderCellDef> Número de cuentas </th>
              <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearNormal(element.ncuen)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columna;sticky: true;" class="w3-table-title"></tr>
            <tr mat-row *matRowDef="let row; columns: columna;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
          </table>
        </div>

        <div *ngIf="gtabla_modal" class=" w3-scroll" style="height: 54vh;">
          <div class="w3-row w3-white" style="height: 54vh">
            <div id="tabla_informacion2" class="w3-encabezado-table " >
              <table mat-table [dataSource]="dataSource" matSort >
                <ng-container matColumnDef="sucursal">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> Sucursal</span> </th>
                  <td mat-cell *matCellDef="let element"> {{element.sucursal}} </td>
                </ng-container>

                <ng-container matColumnDef="caso">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef > <span mat-sort-header class="w3-right" style="width: 80%;"> CASO</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.caso)}} </td>
                </ng-container>
                
                <ng-container matColumnDef="cafo">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> CAFO</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.cafo)}} </td>
                </ng-container>

                <ng-container matColumnDef="ahso">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> AHSO</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.ahso)}} </td>
                </ng-container>

                <ng-container matColumnDef="ahcc">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> AHCC</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.ahcc)}} </td>
                </ng-container>

                <ng-container matColumnDef="crece">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> CRECE</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.crece)}} </td>
                </ng-container>

                <ng-container matColumnDef="ahme">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> AHME</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.ahme)}} </td>
                </ng-container>

                <ng-container matColumnDef="iane">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> INAE</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.iane)}} </td>
                </ng-container>

                <ng-container matColumnDef="hame">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> HAME</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.hame)}} </td>
                </ng-container>

                <ng-container matColumnDef="haex">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> HAEX</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.haex)}} </td>
                </ng-container>

                <ng-container matColumnDef="tdsr">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> TDSR</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.tdsr)}} </td>
                </ng-container>

                <ng-container matColumnDef="pagare">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> PAGARE</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.pagare)}} </td>
                </ng-container>

                <ng-container matColumnDef="cede">
                  <th class="w3-elemento-titulo-table w3-text-deep-orange" mat-header-cell *matHeaderCellDef> <span mat-sort-header class="w3-right" style="width: 80%;"> CEDE</span> </th>
                  <td mat-cell *matCellDef="let element"> {{formatearNormal(element.cede)}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;" class="w3-table-title"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" class="my-row" [class.my-row__alternate]="i % 2"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row w3-center" *ngIf="gCASO_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataCASO"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gCAFO_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataCAFO"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gAHSO_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataAHSO"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gAHCC_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataAHCC"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gCRECE_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataCRECE"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gAHME_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataAHME"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gHAME_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataHAME"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gHAEX_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataHAEX"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gTDSR_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataTDSR"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gPAGARE_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataPAGARE"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row w3-center" *ngIf="gCEDE_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="dataCEDE"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>

        <div class="w3-row w3-center" *ngIf="gCASOlin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataCASOlin"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="visibilityLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="Montos"
            [showGridLines]="showGridLines"
            [legendPosition]="right"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gCAFOlin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataCAFOlin"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="visibilityLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="Montos"
            [showGridLines]="showGridLines"
            [legendPosition]="right"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gAHSOlin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataAHSOlin"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="visibilityLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="Montos"
            [showGridLines]="showGridLines"
            [legendPosition]="right"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gAHCClin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataAHCClin"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="visibilityLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="Montos"
            [showGridLines]="showGridLines"
            [legendPosition]="right"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gCRECElin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataCRECElin"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="visibilityLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="Montos"
            [showGridLines]="showGridLines"
            [legendPosition]="right"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gAHMElin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart
            [scheme]="colorScheme"
            [results]="dataAHMElin"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="visibilityLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="Montos"
            [showGridLines]="showGridLines"
            [legendPosition]="right"
            >
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gHAMElin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart 
          [results]="dataHAMElin"
          [scheme]="colorScheme"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="visibilityLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [legendTitle]="Montos"
          [showGridLines]="showGridLines"
          [legendPosition]="right">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gHAEXlin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart 
          [results]="dataHAEXlin"
          [scheme]="colorScheme"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="visibilityLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [legendTitle]="Montos"
          [showGridLines]="showGridLines"
          [legendPosition]="right">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gTDSRlin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart 
          [results]="dataTDSRlin"
          [scheme]="colorScheme"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="visibilityLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [legendTitle]="Montos"
          [showGridLines]="showGridLines"
          [legendPosition]="right">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gPAGARElin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart 
          [results]="dataPAGARElin"
          [scheme]="colorScheme"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="visibilityLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [legendTitle]="Montos"
          [showGridLines]="showGridLines"
          [legendPosition]="right">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row w3-center" *ngIf="gCEDElin_modal" style="height: 54vh;width: 96%;">
          <ngx-charts-line-chart 
          [results]="dataCEDElin"
          [scheme]="colorScheme"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="visibilityLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [legendTitle]="Montos"
          [showGridLines]="showGridLines"
          [legendPosition]="right">
          </ngx-charts-line-chart>
        </div>

        <div *ngIf="gpie_modal" style="height: 54vh;">
          <google-chart class="w3-row w3-center" id="piechartGoogleT1C3" style="height: 54vh; width: 98%;"
            [type]="type"
            [data]="data"
            [options]="options">
          </google-chart>
        </div>
        <div *ngIf="gmapa_modal" style="height: 54vh; width: 98%;">
          <ngx-charts-tree-map
          [scheme]="colorScheme"
          [results]="datamapa"
          [gradient]="false"
          [animations]="animations"
          [labelFormatting]="labelFormatting"
          (select)="onSelect($event)">
        </ngx-charts-tree-map>
        </div>
        <div *ngIf="gtabla3_modal" style="height: 54vh; width: 98%;">
          <div class="w3-table-all">
            <table mat-table [dataSource]="datatabla3" class=" w3-table" >
                <!-- Position Column -->
                <ng-container matColumnDef="generacion">
                <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Tipo </th>
                <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>              
                <!-- Name Column -->
                <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Número de socios  </th>
                <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearNormal(element.value)}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-deep-orange"> Porcentaje  </th>
                  <td mat-cell style="width: 30%;" *matCellDef="let element"> % {{element.porcentaje}} </td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnasC3; sticky: true" class="w3-elemento-titulo-table"></tr>
                <tr mat-row *matRowDef="let row; columns: columnasC3;"></tr>
            </table>
          </div>
        </div>
        <div *ngIf="gbarras3_modal" style="height: 54vh; width: 98%;">
          <ngx-charts-bar-vertical 
          [scheme]="colorScheme"
          [results]="databarra3"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
  </div>
</div>
