import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data-service/data.service';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import {FuncionesColores} from '../../shared/funciones-colores/funciones_colores';
import {MatTableDataSource} from '@angular/material/table';
import { CosechasService } from '../../../services/riesgos/cosechas.service';
import { CosechaClicService } from '../../../services/riesgos/cosecha-clic.service'
import { ZonaService } from '../../../services/riesgos/zona.service'
import { ProductoService } from '../../../services/riesgos/producto.service'
import { SectorService } from '../../../services/riesgos/sector.service'
import {cosechaEntity, productoEntity, zonaEntity, sectorEntity, cosechaClickEntity} from './../entities_riesgos/entities_riesgos';
import { ExcelService } from './../../../services/exportar/excel.service';
import * as d3 from 'd3-shape';
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import { auxactiveentities } from 'src/app/entities/base/DashboardEntities';

@Component({
  selector: 'app-cosechas',
  templateUrl: './cosechas.component.html',
  styleUrls: ['./cosechas.component.css']
})
export class CosechasComponent implements OnInit {
  /* Declaración de variables */
  // **** Cambio de miles a pesos ****
  miles1 = true;
  miles2 = true;
  milTab1 = '(Miles)';
  milTab2 = '(Miles)';
  //**** variables graficas ****
  tabla1 = true;
  barras1 = false;
  tabla2 = true;
  barras2 = false;
  // **** Modal ****
  tabla1Modal = false;
  barras1Modal = false;
  tabla2Modal = false;
  barras2Modal = false;
  tituloModal = '';
  // **** Datos graficas
  legendTitle: string = '';
  legendPosition: any = 'right';
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Meses';
  yAxisLabel: string = 'Mora';
  yAxisLabel2: string = 'Saldo';
  curve = d3.curveBasis;// d3.curveNatural;//
  zonas = [];
  products = [];
  sectors = [];
  // **** Variables del web service ****
  sector = new sectorEntity;
  zona = new zonaEntity;
  producto = new productoEntity;
  cosechasclick = new cosechaClickEntity;
  cosecha = new cosechaEntity;
  cosecha2 = new cosechaEntity;
  periodo = 22022;
  // **** Variables de datos ****
  dataBarrasSald: any = [];
  dataBarrasMor: any = [];
  nombres_mes: any = [];
  displayedColumns:string [] = [];
  displayedColumnsCosechas = ['numCredito','claveSocio','nombre','diasmora','producto','saldo','estatus'];
  nombres_mes2: any = [];
  displayedColumns2:string [] = [];
  // **** Variables del metodo de separacion de datos ****
  separador:string =  ","; // separador para los miles
  sepDecimal:string = '.'; // separador para los decimales
  // **** 
  public DatosAnios = {"aniosDisponibles":[
    {"name":"2020","value":2020,"check":false,"selected":false},
    {"name":"2021","value":2021,"check":false,"selected":false},
    {"name":"2022","value":2022,"check":true,"selected":false},],
"mesesDisponibles":[
    {"name":"nov","value":"112020","year":"2020","check":false},
    {"name":"dic","value":"122020","year":"2020","check":false},
    {"name":"ene","value":"012021","year":"2021","check":false},
    {"name":"feb","value":"022021","year":"2021","check":false},
    {"name":"mar","value":"032021","year":"2021","check":false},
    {"name":"abr","value":"042021","year":"2021","check":false},
    {"name":"may","value":"052021","year":"2021","check":false},
    {"name":"jun","value":"062021","year":"2021","check":false},
    {"name":"jul","value":"072021","year":"2021","check":false},
    {"name":"ago","value":"082021","year":"2021","check":false},
    {"name":"sep","value":"092021","year":"2021","check":false},
    {"name":"oct","value":"102021","year":"2021","check":false},
    {"name":"nov","value":"112021","year":"2021","check":false},
    {"name":"dic","value":"122021","year":"2021","check":false},
    {"name":"ene","value":"012022","year":"2022","check":false},
    {"name":"feb","value":"022022","year":"2022","check":false},
    {"name":"mar","value":"032022","year":"2022","check":false},
    {"name":"abr","value":"042022","year":"2022","check":false},
    {"name":"may","value":"052022","year":"2022","check":false},
    {"name":"jun","value":"062022","year":"2022","check":false},
    {"name":"jul","value":"072022","year":"2022","check":false},
    {"name":"ago","value":"082022","year":"2022","check":false},
    {"name":"sep","value":"092022","year":"2022","check":false},
    {"name":"oct","value":"102022","year":"2022","check":false},
    {"name":"nov","value":"112022","year":"2022","check":false},]};
  public aniosDisponibles: any [];
  public mesesDisponibles: any [];
  public mesesDeAnioDisponibles: any[];
  public puedeFiltrarPorMeses = false;
  public puedeFiltrarPorSucursales = false;
  public sucursalesDisponibles: any[];
  public anioSeleccionado: object;
  public mesSeleccionado: object;
  public sucursalSeleccionada: object;
  public subtituloGraficaTabla:string = "";
  public subtituloGrafica:string = "";
  public subtituloGraficaModal:string = "";
  public subtituloGrafica2:string = "";
  public colorScheme = {
    domain: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
             '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
             '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
  public mes = 'feb';
  public anio = '2022';
  public ult_anio: any [] = [];
  public ult_mes: any [] = [];
  perspectiva :boolean = false;
  dataSource: any [] = [];
  dataSource2: any [] = [];
  public dataCosechas: any [] = [];
  public subtituloCosechasClick = '';
  public titExp = '';
  /** Variable de porcentaje o de saldo */
  public porPorcent = true;
  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private cosechaService: CosechasService,
    private cosechaclickService: CosechaClicService,
    private productoService: ProductoService,
    private zonaService: ZonaService,
    private sectorService: SectorService,
    private excelservices: ExcelService
  ){
    this.periodo = Number(this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].value);
    this.mes = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].name;
    this.anio = this.DatosAnios.mesesDisponibles[this.DatosAnios.mesesDisponibles.length-1].year;
    this.FuncionesDataSource();
    this.displayedColumns = [];
    this.dataSource = ELEMENT_DATA;
    this.dataCosechas = ELEMENT_DATA;
    this.getDatos();
  }
  ngOnInit(): void {
  }
  public FuncionesDataSource(){
    this.funcionSector()
    this.funcionProduct()
    this.funcionZona()
    this.cosecha.Id_Institucion = 1;
    this.cosecha.Periodo = this.periodo;
    this.cosecha.PAR = 8;
    this.cosecha.BnPAR_Inclusive = true;
    this.cosecha.NPeriodos= 12;
    this.cosecha.BnConIntereses = 0;
    this.funcioncosechaSector(this.cosecha);
    this.cosecha2.Id_Institucion = 1;
    this.cosecha2.Periodo = this.periodo;
    this.cosecha2.PAR = 8;
    this.cosecha2.BnPAR_Inclusive = true;
    this.cosecha2.NPeriodos= 12;
    this.cosecha2.BnConIntereses = 0;
    this.funcioncosecha(this.cosecha2);
    this.subtitulos();
  }
  public subtitulos(){
    this.subtituloGrafica = this.anio.substring(2,4)+' | '+this.mes+' | matriz | PAR 8 | Sin intereses';
    
    if(this.cosecha.Id_Zona != undefined){
      this.zonas.forEach(sec => {if(sec.id === this.cosecha.Id_Zona){this.subtituloGrafica += '| Zona '+sec.name}});
    }
    if(this.cosecha.Id_Producto != undefined){
      this.products.forEach(sec => {if(sec.id === this.cosecha.Id_Producto){this.subtituloGrafica += '| Producto '+sec.name}});
    }
    if(this.cosecha.Id_Sector != undefined){
      this.sectors.forEach(sec => {if(sec.id === this.cosecha.Id_Sector){this.subtituloGrafica += '| Sector '+sec.name}});
    }

    this.subtituloGrafica2 = this.anio.substring(2,4)+' | '+this.mes+' | matriz | PAR 8 | Sin intereses';
    if(this.cosecha2.Id_Zona != undefined){
      this.zonas.forEach(sec => {if(sec.id === this.cosecha.Id_Zona){this.subtituloGrafica2 += '| Zona '+sec.name}});
    }
    if(this.cosecha2.Id_Producto != undefined){
      this.products.forEach(sec => {if(sec.id === this.cosecha.Id_Producto){this.subtituloGrafica2 += '| Producto '+sec.name}});
    }
    if(this.cosecha2.Id_Sector != undefined){
      this.sectors.forEach(sec => {if(sec.id === this.cosecha.Id_Sector){this.subtituloGrafica2 += '| Sector '+sec.name}});
    }
  }
  getDatos(){
    this.aniosDisponibles = this.DatosAnios.aniosDisponibles;
    var aux = [];
    var pos;
    for(var i = 0; i < this.aniosDisponibles.length; i ++){
      if(this.aniosDisponibles[i].check == true){
        pos = i;
      }
    }
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i]);
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
  }
  /** Obtención de los sectores que existen */
  funcionSector(){
    this.sectorService.getByAddUrl_Riesgo('sector',this.sector).subscribe(dataArr => {
      this.sectores(dataArr);
    });
  }
  sectores(arreglo: any){
    var aux = [];
    for(var i = 0; i < arreglo.length; i++){
      aux.push({id: arreglo[i].Id_Sector, name: arreglo[i].Nombre_Sector});
    }
    this.sectors = aux;
  }
  /** Obtención de los productos existentes */
  funcionProduct(){
    this.productoService.getByAddUrl_Riesgo('producto',this.producto).subscribe(dataArr => {
      this.productos(dataArr);
    });  
  }
  productos(arreglo: any){
    var aux = [];
    for(var i = 0; i < arreglo.length; i++){
      aux.push({id: arreglo[i].Id_Producto, name: arreglo[i].Nombre_Producto});
    }
    this.products = aux;
  }
  /** Obtención de las zonas existentes */
  funcionZona(){
    this.zonaService.getByAddUrl_Riesgo('zona',this.zona).subscribe(dataArr => {
      this.Zonas(dataArr);
    });  
  }
  Zonas(arreglo: any){
    var aux = [];
    for(var i = 0; i < arreglo.length; i++){
      aux.push({id: arreglo[i].Id_Zona, name: arreglo[i].Nombre_Zona});
    }
    this.zonas = aux;
  }
  /** Obtención de datos */
  funcioncosechaSector(cosechaEntity: any){
  this.cosechaService.getByAddUrl_Riesgo('cosecha',cosechaEntity).subscribe(dataArr => {
      this.Datos(dataArr);
    });
  }
  funcioncosecha(cosechaEntity: any){
    this.cosechaService.getByAddUrl_Riesgo('cosecha',cosechaEntity).subscribe(dataArr => {
        this.DatosT2(dataArr);
      });
  }
  /** cambios en perspectivas */
  cambioSec(sec: any){
    this.cosecha.Id_Zona = undefined;
    this.cosecha.Id_Producto = undefined;
    this.cosecha.Id_Sector = sec.id;
    this.FuncionesDataSource();
  }
  cambioSec2(sec: any){
    this.cosecha2.Id_Zona = undefined;
    this.cosecha2.Id_Producto = undefined;
    this.cosecha2.Id_Sector = sec.id;
    this.FuncionesDataSource();
  }
  cambioProd(prod: any){
    this.cosecha.Id_Zona = undefined;
    this.cosecha.Id_Sector = undefined;
    this.cosecha.Id_Producto = prod.id;
    this.FuncionesDataSource();
  }
  cambioProd2(prod: any){
    this.cosecha2.Id_Zona = undefined;
    this.cosecha2.Id_Sector = undefined;
    this.cosecha2.Id_Producto = prod.id;
    this.FuncionesDataSource();
  }
  cambioZon(zon: any){
    this.cosecha.Id_Producto = undefined;
    this.cosecha.Id_Sector = undefined;
    this.cosecha.Id_Zona = zon.id;
    this.FuncionesDataSource();
  }
  cambioZon2(zon: any){
    this.cosecha2.Id_Producto = undefined;
    this.cosecha2.Id_Sector = undefined;
    this.cosecha2.Id_Zona = zon.id;
    this.FuncionesDataSource();
  }
  /** formateo de datos */
  DatosT2(arreglo: any){
    var aux = [];
    for(var i =0; i <arreglo.length; i++){
      aux.push({periodo: arreglo[i].Periodo, cosecha: arreglo[i].Cosecha, colocacion: arreglo[i].ColocacionCosecha,
                saldo: arreglo[i].Saldo, mora: arreglo[i].Mora});
    }
    this.graficas_linealesSal(aux);
    var j=0;
    var aux2 = [];
    for(var i =0; i <aux.length; i++){
      if( i>0){
        if(aux[i].cosecha == aux[(i-1)].cosecha){
          aux2[(j)].series.push( {periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
            saldo: arreglo[i].Saldo, mora: this.formatearmiles(arreglo[i].Saldo,this.miles2)} );
        }else{
          aux2.push({cosecha: aux[(i)].cosecha, series: [{periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
            saldo: arreglo[i].Saldo, mora: this.formatearmiles(arreglo[i].Saldo,this.miles2) }]});
          j++
        }
      }else{
        aux2.push({cosecha: aux[i].cosecha, series: [{periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
        saldo: arreglo[i].Saldo, mora: this.formatearmiles(arreglo[i].Saldo,this.miles2) }]});
      }
    }
    this.datosTablasaldo(aux2);
  }
  Datos(arreglo: any){
    var aux = [];
    if(!this.porPorcent){
      for(var i =0; i <arreglo.length; i++){
        aux.push({periodo: arreglo[i].Periodo, cosecha: arreglo[i].Cosecha, colocacion: arreglo[i].ColocacionCosecha,
                  saldo: arreglo[i].Saldo, mora: arreglo[i].Mora});
      }
    }else{
      for(var i =0; i <arreglo.length; i++){
        aux.push({periodo: arreglo[i].Periodo, cosecha: arreglo[i].Cosecha, colocacion: arreglo[i].ColocacionCosecha,
                  saldo: arreglo[i].Saldo, mora: arreglo[i].MorosidadSaldo*100});
      }
    }
    this.graficas_lineales(aux);
    var j=0;
    var aux2 = [];
    if(!this.porPorcent){
      for(var i =0; i <aux.length; i++){
        if( i>0){
          if(aux[i].cosecha == aux[(i-1)].cosecha){
            aux2[(j)].series.push( {periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
              saldo: arreglo[i].Saldo, mora: this.formatearmiles(arreglo[i].Mora,this.miles1)} );
          }else{
            aux2.push({cosecha: aux[(i)].cosecha, series: [{periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
              saldo: arreglo[i].Saldo, mora: this.formatearmiles(arreglo[i].Mora,this.miles1) }]});
            j++
          }
          }else{
            aux2.push({cosecha: aux[i].cosecha, series: [{periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
              saldo: arreglo[i].Saldo, mora: this.formatearmiles(arreglo[i].Mora,this.miles1) }]});
        }
      }
    }else{
      for(var i =0; i <aux.length; i++){
        if( i>0){
          if(aux[i].cosecha == aux[(i-1)].cosecha){
            aux2[(j)].series.push( {periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
              saldo: arreglo[i].Saldo, mora: Number((arreglo[i].MorosidadSaldo*100).toFixed(2))} );
          }else{
            aux2.push({cosecha: aux[(i)].cosecha, series: [{periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
              saldo: arreglo[i].Saldo, mora: Number((arreglo[i].MorosidadSaldo*100).toFixed(2)) }]});
            j++
          }
          }else{
            aux2.push({cosecha: aux[i].cosecha, series: [{periodo: arreglo[i].Periodo, colocacion: arreglo[i].ColocacionCosecha,
              saldo: arreglo[i].Saldo, mora: Number((arreglo[i].MorosidadSaldo*100).toFixed(2)) }]});
        }
      }
    }
    this.datosTablacosecha(aux2);
  }
  graficas_lineales(arreglo: any){
    var aux = [];
    var s =0;
    for(var i =0; i <arreglo.length; i++){
      if( i>0){
        if(arreglo[i].cosecha == arreglo[(i-1)].cosecha){
          aux[(s)].series.push( {periodo: this.nom(arreglo[i].periodo), colocacion: arreglo[i].colocacion,
            saldo: arreglo[i].saldo, mora: arreglo[i].mora} );
        }else{
          aux.push({cosecha: this.nom(arreglo[i].cosecha), series: [{periodo: this.nom(arreglo[i].periodo), colocacion: arreglo[i].colocacion,
            saldo: arreglo[i].saldo, mora: arreglo[i].mora }]});
          s++
        }
        }else{
          aux.push({cosecha: this.nom(arreglo[i].cosecha), series: [{periodo: this.nom(arreglo[i].periodo), colocacion: arreglo[i].colocacion,
            saldo: arreglo[i].saldo, mora: arreglo[i].mora }]});
      }
    }
    var aux2 = aux;
    aux = [];
    for(var i = 0; i < aux2.length; i++){
      for(var j = 0; j < aux2[i].series.length; j++){
        if(j>0)
          aux[i].series.push({ name: aux2[i].series[j].periodo, value: aux2[i].series[j].mora });
        else
          aux.push({name: aux2[i].cosecha, series: [{ name: aux2[i].series[j].periodo, value: aux2[i].series[j].mora }]});
      }
    }
    var aux3 = [];
    for(var i = aux.length-1; i >= 0; i--){
      aux3.push(aux[i]);
    }
    this.dataBarrasMor = aux3;
  }
  graficas_linealesSal(arreglo: any){
    var aux = [];
    var s =0;
    for(var i =0; i <arreglo.length; i++){
      if( i>0){
        if(arreglo[i].cosecha == arreglo[(i-1)].cosecha){
          aux[(s)].series.push( {periodo: this.nom(arreglo[i].periodo), colocacion: arreglo[i].colocacion,
            saldo: arreglo[i].saldo, mora: arreglo[i].mora} );
        }else{
          aux.push({cosecha: this.nom(arreglo[i].cosecha), series: [{periodo: this.nom(arreglo[i].periodo), colocacion: arreglo[i].colocacion,
            saldo: arreglo[i].saldo, mora: arreglo[i].mora }]});
          s++
        }
        }else{
          aux.push({cosecha: this.nom(arreglo[i].cosecha), series: [{periodo: this.nom(arreglo[i].periodo), colocacion: arreglo[i].colocacion,
            saldo: arreglo[i].saldo, mora: arreglo[i].mora }]});
      }
    }
    var aux2 = aux;
    aux = [];
    for(var i = 0; i < aux2.length; i++){
      for(var j = 0; j < aux2[i].series.length; j++){
        if(j>0)
          aux[i].series.push({ name: aux2[i].series[j].periodo, value: aux2[i].series[j].saldo });
        else
          aux.push({name: aux2[i].cosecha, series: [{ name: aux2[i].series[j].periodo, value: aux2[i].series[j].saldo }]});
      }
    }
    aux2 = [];
    for(var i = aux.length-1; i >= 0; i--){
      aux2.push(aux[i]);
    }
    this.dataBarrasSald = aux2;
  }
  /**  */
  nom(mes: any){
    var mesS = ''+mes,m = '',a = '';
    switch(mesS.length){
      case 5:
        m = '0'+mesS.substring(0,1);
        a = ''+mesS.substr(-2);
      break;
      case 6:
        m = ''+mesS.substring(0,2);
        a = ''+mesS.substr(-2);
      break;
      case 10:
        m = ''+mesS.substring(5,7);
        a = ''+mesS.substring(2,4);
      break;
    }
    switch(m){
      case '01': return 'ene '+a;
      case '02': return 'feb '+a;
      case '03': return 'mar '+a;
      case '04': return 'abr '+a;
      case '05': return 'may '+a;
      case '06': return 'jun '+a;
      case '07': return 'jul '+a;
      case '08': return 'ago '+a;
      case '09': return 'sep '+a;
      case '10': return 'oct '+a;
      case '11': return 'nov '+a;
      case '12': return 'dic '+a;
    }
    return '';
  }
  /** Cambio de graficas */
  Graficas1(letra: any){
    if('T' == letra){
      this.tabla1 = true;
      this.barras1 = false;
    }
    if('L' == letra){
      this.tabla1 = false;
      this.barras1 = true;
    }
  }
  Graficas2(letra: any){
    if('T' == letra){
      this.tabla2 = true;
      this.barras2 = false;
    }
    if('L' == letra){
      this.tabla2 = false;
      this.barras2 = true;
    }
  }
  /** formato de las tablas */
  datosTablacosecha(arreglo: any){
    var aux = [];
    this.displayedColumns = ['cosecha', 'montocolocado','mes1','mes2','mes3','mes4','mes5','mes6','mes7','mes8','mes9','mes10','mes11','mes12'];
    this.nombres_mes = [];
    if(arreglo.length != 0){
      for(var i=0; i<arreglo[0].series.length; i++){
        //this.displayedColumns.push('mes'+(i+1));
        this.nombres_mes.push(this.nom(arreglo[0].series[i].periodo));
      }
      for(var i =0; i <arreglo.length; i++){
        aux.push({cosecha: this.nom(arreglo[i].cosecha), montocolocado: this.formatearmiles(arreglo[i].series[0].colocacion,this.miles1),
          mes1: this.asignacion(i,1,arreglo),mes2: this.asignacion(i,2,arreglo),mes3: this.asignacion(i,3,arreglo),
          mes4: this.asignacion(i,4,arreglo),mes5: this.asignacion(i,5,arreglo),mes6: this.asignacion(i,6,arreglo),
          mes7: this.asignacion(i,7,arreglo),mes8: this.asignacion(i,8,arreglo),mes9: this.asignacion(i,9,arreglo),
          mes10: this.asignacion(i,10,arreglo),mes11: this.asignacion(i,11,arreglo),mes12: this.asignacion(i,12,arreglo)});
      }
    }else{
      for(var i =0; i <12; i++){
        aux.push({cosecha: '', montocolocado: '',mes1: '',mes2: '',mes3: '',mes4: '',mes5: '',mes6: '',mes7: '',mes8: '',mes9: '',mes10: '',mes11: '',mes12: ''});
      }
    }
    
    this.dataSource = aux;
  }
  datosTablasaldo(arreglo: any){
    var aux = [];
    this.displayedColumns2 = ['cosecha', 'montocolocado','mes1','mes2','mes3','mes4','mes5','mes6','mes7','mes8','mes9','mes10','mes11','mes12'];
    this.nombres_mes2 = []
    if(arreglo.length != 0){
      for(var i=0; i<arreglo[0].series.length; i++){
        //this.displayedColumns2.push('mes'+(i+1));
        this.nombres_mes2.push(this.nom(arreglo[0].series[i].periodo));
      }
      for(var i =0; i <arreglo.length; i++){
        aux.push({cosecha: this.nom(arreglo[i].cosecha), montocolocado: this.formatearmiles(arreglo[i].series[0].colocacion,this.miles2),
          mes1: this.asignacion(i,1,arreglo),mes2: this.asignacion(i,2,arreglo),mes3: this.asignacion(i,3,arreglo),
          mes4: this.asignacion(i,4,arreglo),mes5: this.asignacion(i,5,arreglo),mes6: this.asignacion(i,6,arreglo),
          mes7: this.asignacion(i,7,arreglo),mes8: this.asignacion(i,8,arreglo),mes9: this.asignacion(i,9,arreglo),
          mes10: this.asignacion(i,10,arreglo),mes11: this.asignacion(i,11,arreglo),mes12: this.asignacion(i,12,arreglo)});
      }
    }else{
      for(var i =0; i <12; i++){
        aux.push({cosecha: '', montocolocado: '',mes1: '',mes2: '',mes3: '',mes4: '',mes5: '',mes6: '',mes7: '',mes8: '',mes9: '',mes10: '',mes11: '',mes12: ''});
      }
    }
    
    this.dataSource2 = aux;
  }
  /** exportación */
  exportar_cscClick(){
    var aux = [];
    var data_excel = [];
    var name = ''+this.titExp;
    var encabezado_excel = ['Número de credito','Clave socio','Nombre','Días mora','Producto','Saldo','Estatus'];
    var tit = 'Cosechas';
    for(const ins of this.dataCosechas){
      aux.push(Number(ins.nCred));
      aux.push(Number(ins.cSocio));
      aux.push(ins.nom);
      aux.push(ins.dMor);
      aux.push(ins.prod);
      aux.push(ins.sal);
      aux.push(ins.estatus);
      data_excel.push(aux);
      aux = [];
    }
    this.excelservices.generateExcelCosechaClick(encabezado_excel,data_excel,tit,name,this.porPorcent);
  }
  export_csv(tab: any){
    var aux = [];
    var data_excel = [];
    var name = '';
    //this.desForm(this.dataSource[0].mes2)
    if(tab == 1){
      var encabezado_excel = ['Cosecha','Monto colocado',this.nombres_mes[this.nombres_mes.length-1],this.nombres_mes[this.nombres_mes.length-2],
      this.nombres_mes[this.nombres_mes.length-3],this.nombres_mes[this.nombres_mes.length-4],this.nombres_mes[this.nombres_mes.length-5],
      this.nombres_mes[this.nombres_mes.length-6],this.nombres_mes[this.nombres_mes.length-7],this.nombres_mes[this.nombres_mes.length-8],
      this.nombres_mes[this.nombres_mes.length-9],this.nombres_mes[this.nombres_mes.length-10],this.nombres_mes[this.nombres_mes.length-11],
      this.nombres_mes[this.nombres_mes.length-12]];
      var cadena_evaluacion = this.nombres_mes;
      if(!this.porPorcent){
        for(var i = 0; i < this.dataSource.length; i++ ){
          aux.push(this.dataSource[i].cosecha)
          aux.push(this.desForm(this.dataSource[i].montocolocado))
          if(encabezado_excel[2] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes1));
          if(encabezado_excel[3] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes2));
          if(encabezado_excel[4] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes3));
          if(encabezado_excel[5] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes4));
          if(encabezado_excel[6] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes5));
          if(encabezado_excel[7] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes6));
          if(encabezado_excel[8] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes7));
          if(encabezado_excel[9] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes8));
          if(encabezado_excel[10] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes9));
          if(encabezado_excel[11] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes10));
          if(encabezado_excel[12] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes11));
          if(encabezado_excel[13] != undefined)
            aux.push(this.desForm(this.dataSource[i].mes12));
          data_excel.push(aux);
          aux = [];
        }
        name = 'Saldo por mora';
      }else{
        for(var i = 0; i < this.dataSource.length; i++ ){
          aux.push(this.dataSource[i].cosecha)
          aux.push(this.desForm(this.dataSource[i].montocolocado))
          if(encabezado_excel[2] != undefined)
            aux.push(this.dataSource[i].mes1);
          if(encabezado_excel[3] != undefined)
            aux.push(this.dataSource[i].mes2);
          if(encabezado_excel[4] != undefined)
            aux.push(this.dataSource[i].mes3);
          if(encabezado_excel[5] != undefined)
            aux.push(this.dataSource[i].mes4);
          if(encabezado_excel[6] != undefined)
            aux.push(this.dataSource[i].mes5);
          if(encabezado_excel[7] != undefined)
            aux.push(this.dataSource[i].mes6);
          if(encabezado_excel[8] != undefined)
            aux.push(this.dataSource[i].mes7);
          if(encabezado_excel[9] != undefined)
            aux.push(this.dataSource[i].mes8);
          if(encabezado_excel[10] != undefined)
            aux.push(this.dataSource[i].mes9);
          if(encabezado_excel[11] != undefined)
            aux.push(this.dataSource[i].mes10);
          if(encabezado_excel[12] != undefined)
            aux.push(this.dataSource[i].mes11);
          if(encabezado_excel[13] != undefined)
            aux.push(this.dataSource[i].mes12);
          data_excel.push(aux);
          aux = [];
        }
        name = 'Porcentaje por mora';
      }
    }else{
      var encabezado_excel = ['Cosecha','Monto colocado',this.nombres_mes2[this.nombres_mes2.length-1],this.nombres_mes2[this.nombres_mes2.length-2],
      this.nombres_mes2[this.nombres_mes2.length-3],this.nombres_mes2[this.nombres_mes2.length-4],this.nombres_mes2[this.nombres_mes2.length-5],
      this.nombres_mes2[this.nombres_mes2.length-6],this.nombres_mes2[this.nombres_mes2.length-7],this.nombres_mes2[this.nombres_mes2.length-8],
      this.nombres_mes2[this.nombres_mes2.length-9],this.nombres_mes2[this.nombres_mes2.length-10],this.nombres_mes2[this.nombres_mes2.length-11],
      this.nombres_mes2[this.nombres_mes2.length-12]];
      var cadena_evaluacion = this.nombres_mes
      for(var i = 0; i < this.dataSource.length; i++ ){
        aux.push(this.dataSource2[i].cosecha)
        aux.push(this.desForm(this.dataSource2[i].montocolocado));
        if(encabezado_excel[2] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes1));
        if(encabezado_excel[3] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes2));
        if(encabezado_excel[4] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes3));
        if(encabezado_excel[5] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes4));
        if(encabezado_excel[6] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes5));
        if(encabezado_excel[7] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes6));
        if(encabezado_excel[8] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes7));
        if(encabezado_excel[9] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes8));
        if(encabezado_excel[10] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes9));
        if(encabezado_excel[11] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes10));
        if(encabezado_excel[12] != undefined)
    	     aux.push(this.desForm(this.dataSource2[i].mes11));
        if(encabezado_excel[13] != undefined)
          aux.push(this.desForm(this.dataSource2[i].mes12));
        data_excel.push(aux);
        aux = [];
      }
      name = 'Saldo total';
    }
    this.excelservices.generateExcelCosecha(encabezado_excel,data_excel,cadena_evaluacion.length,name,this.porPorcent);
  }
  export_img(zon: any){
    var grafica: string = "";
    var name: string = "";
    if(zon == 1){
        grafica = "Zona1";
        name = 'GraficaLineal';
    }
    if(zon == 2){
      grafica = "Zona2";
      name = 'GraficaLineal';
    }
    PDF.exportimagenes(grafica,name);
  }
  /** Pintado de la tabla */
  pintar(pos: any, col: any){
    
    if(pos == col)
      return "w3-grey-per ";
    if(pos > col)
      return "w3-light-grey ";
  }
  colorText(num){
    var aux='';
    if(this.porPorcent && num != undefined){
      if(num > 0 && num <= 5)
        return ''//'#5AB436'//'#72D54A'
      else if(num > 5 && num <= 10)
        return ''//'#F2C806'//'#FFD100'
      else if( num > 10)
        return '#FF495C'//'#FF495C' '#CF1A2D'
    }
  }
  pintarPorcent(num){
    var aux = ''+num;
    if(aux != '')
      return true;
    return false;
  }
  asignacion(i: any, nm: any, arreglo: any){
    if((arreglo[i].series.length-nm)>=0){
      return arreglo[i].series[arreglo[i].series.length-nm].mora;
    }
    else{
      return '';
    }
  }
  // **** Función de formateo de numeros ****
  formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  formatearmiles(num: any, miles: any){
    if(miles == true)
      num = (num/1000).toFixed(3)
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  desForm(num: any){
    if(num.includes(',')){  
      var pos = num.indexOf(',');
      var aux = num.slice(0,pos)+num.slice(pos+1);
      num = aux;
      while(num.includes(',')){
        var pos = num.indexOf(',');
        var aux = num.slice(0,pos)+num.slice(pos+1);
        num = aux;
      }
    }
    if(num == '')
      return num;
    num = Number(num);
    return num;
  }
  CambMon( moneda: any, tab: any ){
    if(moneda == 'Mil' && tab == 1){
      this.miles1 = true;
      this.milTab1 = '(Miles)';
    }
    if(moneda == 'Mil' && tab == 2){
      this.miles2 = true;
      this.milTab2 = '(Miles)';
    }
    if(moneda == 'Pesos' && tab == 1 ){
      this.miles1 = false;
      this.milTab1 = '';
    }
    if(moneda == 'Pesos' && tab == 2 ){
      this.miles2 = false;
      this.milTab2 = '';
    }
    this.FuncionesDataSource();
  }
  tam(){
    if(!this.miles1)
      return 'w3-small';
    if(!this.miles2)
      return 'w3-small';
  }
  /** Cambio de porcentaje a saldo de la primer grafica */
  public MostrarPor(tipo){
    if(tipo == 'saldo')
      this.porPorcent = false;
    else
      this.porPorcent = true;
    this.funcioncosechaSector(this.cosecha);
  }
  /* funciones para los filtros */
  public anioChange(anio: any){
    var pos = 0;
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == anio.value){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i])
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
    this.mes = this.mesesDisponibles[this.mesesDisponibles.length-1].name;
    this.anio = this.aniosDisponibles[pos].name;
    this.periodo = Number(this.mesesDisponibles[this.mesesDisponibles.length-1].value);
    this.FuncionesDataSource();
  }
  public mesChange(mes: any){
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false
    }
    var pos =0;
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      if(this.mesesDisponibles[i].name == mes.name){
        this.mesesDisponibles[i].check = true;
        pos = i;
      }
    }
    this.mes = this.mesesDisponibles[pos].name;
    this.periodo = Number(this.mesesDisponibles[pos].value);
    this.FuncionesDataSource();
  }
  ampliar(amp: any){
    if(amp == 'tabla1'){
      this.tituloModal = 'Saldo por mora';
      this.subtituloGraficaTabla = this.subtituloGrafica;
      this.subtituloGraficaModal = this.subtituloGrafica;
      if(this.tabla1){
        this.tabla1Modal = true;
        this.barras1Modal = false;
        this.tabla2Modal = false;
        this.barras2Modal = false;
      }
      if(this.barras1){
        this.tabla1Modal = false;
        this.barras1Modal = true;
        this.tabla2Modal = false;
        this.barras2Modal = false;
      }
    }
    if(amp == 'tabla2'){
      this.tituloModal = 'Saldo Total';
      this.subtituloGraficaTabla = this.subtituloGrafica2;
      if(this.tabla2){
        this.tabla1Modal = false;
        this.barras1Modal = false;
        this.tabla2Modal = true;
        this.barras2Modal = false;
      }
      if(this.barras2){
        this.tabla1Modal = false;
        this.barras1Modal = false;
        this.tabla2Modal = false;
        this.barras2Modal = true;
      }
    }
  }
  cerrarAmp(){
    this.tabla1Modal = false;
    this.barras1Modal = false;
    this.tabla2Modal = false;
    this.barras2Modal = false;
  }
  public anioChangeListener(anioClick: object): void {}
  public VerificarAniosSeleccionados(): string {
    return this.puedeFiltrarPorMeses ? 'w3-third' : 'w3-third w3-disabled';
  }
  // MESES
  public mesChangeListener(mesClick: object, elementArray: object[]): void {}
  // SUCURSALES
  public consolidadoChangeListener(tipo): void {}
  public quitarFiltros(): void {
    this.porPorcent = true;
    var pos = 0;
    for(var i =0; i < this.aniosDisponibles.length; i++){
      this.aniosDisponibles[i].check = false;
    }
    for(var i =0; i < this.aniosDisponibles.length; i++){
      if(this.aniosDisponibles[i].value == '2022'){
        this.aniosDisponibles[i].check = true;
        pos = i;
      }
    }
    for(var i = 0; i < this.mesesDisponibles.length; i++ ){
      this.mesesDisponibles[i].check = false
    }
    var aux = [];
    for(var i =0; i < this.DatosAnios.mesesDisponibles.length; i++){
      if(this.DatosAnios.mesesDisponibles[i].year == this.aniosDisponibles[pos].name){
        aux.push(this.DatosAnios.mesesDisponibles[i])
      }
    }
    this.mesesDisponibles=aux;
    this.mesesDisponibles[this.mesesDisponibles.length-1].check = true;
    this.mes = this.mesesDisponibles[this.mesesDisponibles.length-1].name;
    this.anio = this.aniosDisponibles[pos].name;
    this.periodo = Number(this.mesesDisponibles[this.mesesDisponibles.length-1].value);
    this.tabla1 = true;
    this.barras1 = false;
    this.tabla2 = true;
    this.barras2 = false;
    this.cosecha.Id_Zona = undefined;
    this.cosecha.Id_Producto = undefined;
    this.cosecha.Id_Sector = undefined;
    this.cosecha2.Id_Zona = undefined;
    this.cosecha2.Id_Producto = undefined;
    this.cosecha2.Id_Sector = undefined;
    this.miles1 = true;
    this.milTab1 = '(Miles)';
    this.miles2 = true;
    this.milTab2 = '(Miles)';
    this.FuncionesDataSource();
  }
  /* funciones para los filtros */
  AmpliarGraficaC1(){}
  cambiarGraficaC1(grafica){}
  /** eventos de la tabla */
  public clickTab(dat, cos){
    var per1, per2;
    per1 = this.obtener_periodo(dat);
    per2 = this.obtener_periodo(cos);
    this.cosechasclick.Id_Institucion = 1;
    this.cosechasclick.Periodo = Number(per1);
    this.cosechasclick.PeriodoCosecha = Number(per2);
    this.cosechasclick.PAR = 8;
    this.cosechasclick.BnPAR_Inclusive = true;
    this.cosechasclick.BnConIntereses = 0;
    var aux = [];
    this.cosechaclickService.getByAddUrl_Riesgo('cosecha/listado/creditos',this.cosechasclick).subscribe(response => {
      aux = this.dataclick(response)
      this.dataCosechas = aux;
      this.subtituloCosechasClick = 'Cosecha de: ' + dat + ' | Saldos a: ' + cos + ' | PAR 8 | Sin intereses';
      this.titExp = 'Cosecha de: ' + dat + ' saldos a: ' + cos;
      document.getElementById('modalTabla').style.display='block';
    });

  }
  public dataclick(response){
    var aux = [];
    for(const data of response){
      aux.push({
        nCred: data.Id_Credito,
        cSocio: data.Clave_Socio,
        nom: data.Nombre_Socio,
        dMor: data.Dias_Mora,
        prod: data.Nombre_Producto,
        sal: data.Saldo,
        estatus: data.Nombre_EstatusCredito.split('-')[1]
      });
    }
    var suma = 0;
    for(const data of aux){
      suma += data.sal;
    }
    aux.push({
      nCred: '',
      cSocio: '',
      nom: 'Número de socios',
      dMor: aux.length,
      prod: 'Total',
      sal: suma,
      estatus: ''
    });
    return aux;
  }
  public obtener_periodo(per){
    var aux, aux2;
    aux2 = per.split(' ');
    switch(aux2[0]){
      case 'ene':
        aux = 1+'20'+aux2[1];
      break;
      case 'feb':
        aux = 2+'20'+aux2[1];
      break;
      case 'mar':
        aux = 3+'20'+aux2[1];
      break;
      case 'abr':
        aux = 4+'20'+aux2[1];
      break;
      case 'may':
        aux = 5+'20'+aux2[1];
      break;
      case 'jun':
        aux = 6+'20'+aux2[1];
      break;
      case 'jul':
        aux = 7+'20'+aux2[1];
      break;
      case 'ago':
        aux = 8+'20'+aux2[1];
      break;
      case 'sep':
        aux = 9+'20'+aux2[1];
      break;
      case 'oct':
        aux = 10+'20'+aux2[1];
      break;
      case 'nov':
        aux = 11+'20'+aux2[1];
      break;
      case 'dic':
        aux = 12+'20'+aux2[1];
      break;
    }
    return aux;
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];