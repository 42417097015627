import {
  Component,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  HostListener,
  OnInit,
  OnChanges,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import {
  NgxChartsModule,
  BaseChartComponent,
  LineComponent,
  LineSeriesComponent,
  calculateViewDimensions,
  ViewDimensions,
  ColorHelper
} from '@swimlane/ngx-charts';
import { area, line, curveLinear } from 'd3-shape';
import { scaleBand, scaleLinear, scalePoint, scaleTime } from 'd3-scale';

@Component({
  selector: 'stacked-chart-component',
  template: `
  <ngx-charts-chart
    [view]="[width, height]"
    [showLegend]="legend"
    [legendOptions]="legendOptions"
    [activeEntries]="activeEntries"
    [animations]="animations"
    (legendLabelActivate)="onActivate($event, undefined, true)"
    (legendLabelDeactivate)="onDeactivate($event, undefined, true)"
    (legendLabelClick)="onClick($event)">
    <svg:g [attr.transform]="transform" class="bar-chart chart">
      <svg:g
        ngx-charts-x-axis
        *ngIf="xAxis"
        [xScale]="xScale"
        [dims]="dims"
        [showLabel]="showXAxisLabel"
        [labelText]="xAxisLabel"
        [tickFormatting]="xAxisTickFormatting"
        (dimensionsChanged)="updateXAxisHeight($event)">
      </svg:g>
      <svg:g
        ngx-charts-y-axis
        *ngIf="yAxis"
        [yScale]="yScale"
        [dims]="dims"
        [yOrient]="yOrientLeft"
        [showGridLines]="showGridLines"
        [showLabel]="showYAxisLabel"
        [labelText]="yAxisLabel"
        [tickFormatting]="yAxisTickFormatting"
        (dimensionsChanged)="updateYAxisWidth($event)">
      </svg:g>
      <svg:g
          ngx-charts-y-axis
          *ngIf="yAxis"
          [yScale]="yScaleLine"
          [dims]="dims"
          [yOrient]="yOrientRight"
          [showGridLines]="showGridLines"
          [showLabel]="showRightYAxisLabel"
          [labelText]="yAxisLabelRight"
          [tickFormatting]="yRightAxisTickFormatting"
          (dimensionsChanged)="updateYAxisWidth($event)">
      </svg:g>
      <svg:g
        *ngFor="let group of results; let index = index; trackBy: trackBy"
        [attr.transform]="groupTransform(group)">
        <svg:g
          ngx-charts-series-vertical
          type="stacked"
          [xScale]="xScale"
          [yScale]="yScale"
          [activeEntries]="activeEntries"
          [colors]="colors"
          [series]="group.series"
          [dims]="dims"
          [gradient]="gradient"
          [tooltipDisabled]="tooltipDisabled"
          [tooltipTemplate]="tooltipTemplate"
          [showDataLabel]="showDataLabel"
          [dataLabelFormatting]="dataLabelFormatting"
          [seriesName]="group.name"
          [animations]="animations"
          [noBarWhenZero]="noBarWhenZero"
          (select)="onClick($event, group)"
          (activate)="onActivate($event, group)"
          (deactivate)="onDeactivate($event, group)"
          (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"/>
      </svg:g>
      <svg:g
          ngx-stacked-charts-series-vertical
          [xScale]="xScale"
          [yScale]="yScale"
          [colors]="colors"
          [series]="results"
          [seriesLine]="lineChart"
          [dims]="dims"
          [gradient]="gradient"
          tooltipDisabled="true"
          [activeEntries]="activeEntries"
          [animations]="animations"
          [noBarWhenZero]="noBarWhenZero"
          (activate)="onActivateL($event)"
          (deactivate)="onDeactivateL($event)"
          (bandwidth)="updateLineWidth($event)"
          (select)="onClick($event)">
      </svg:g>
    </svg:g>
    <svg:g [attr.transform]="transform" class="line-chart chart">
      <svg:g
          ngx-charts-y-axis
          *ngIf="yAxis"
          [yScale]="yScale"
          [dims]="dims"
          [showGridLines]="showGridLines"
          [showLabel]="showYAxisLabel"
          [labelText]="yAxisLabel"
          [trimTicks]="trimYAxisTicks"
          [maxTickLength]="maxYAxisTickLength"
          [tickFormatting]="yAxisTickFormatting"
          [ticks]="yAxisTicks"
          [referenceLines]="referenceLines"
          [showRefLines]="showRefLines"
          [showRefLabels]="showRefLabels"
          (dimensionsChanged)="updateYAxisWidth($event)"
      ></svg:g>
      <svg:g>
        <svg:g *ngFor="let series of lineChart; trackBy: trackBy">
          <svg:g
            ngx-charts-line-series
            [xScale]="xScaleLine"
            [yScale]="yScaleLine"
            [colors]="colorsLine"
            [data]="series"
            [activeEntries]="activeEntries"
            [scaleType]="scaleType"
            [curve]="curve"
            [rangeFillOpacity]="rangeFillOpacity"
            [animations]="animations">
          </svg:g>
        </svg:g>
        <svg:g
          ngx-charts-tooltip-area
          *ngIf="!tooltipDisabled"
          [dims]="dims"
          [xSet]="xSet"
          [xScale]="xScaleLine"
          [yScale]="yScaleLine"
          [results]="combinedSeries"
          [colors]="colorsCombo"
          [tooltipDisabled]="tooltipDisabled"
          (hover)="updateHoveredVertical($event)">
        </svg:g>
        <svg:g *ngFor="let series of lineChart">
          <svg:g
            ngx-charts-circle-series
            [xScale]="xScaleLine"
            [yScale]="yScaleLine"
            [colors]="colorsLine"
            [data]="series"
            [scaleType]="scaleType"
            [visibleValue]="hoveredVertical"
            [activeEntries]="activeEntries"
            [tooltipDisabled]="tooltipDisabled"
            (select)="onClick($event, series)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)">
          </svg:g>
        </svg:g>
      </svg:g>
    </svg:g>
  </ngx-charts-chart>
  `,
  styleUrls: ['./stacked-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StackedChartComponent extends BaseChartComponent {
  @Input() curve: any = curveLinear;
  @Input() legend = false;
  @Input() legendTitle: string = 'Legend';
  @Input() legendPosition: string = 'right';
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() showRightYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() yAxisLabelRight;
  @Input() tooltipDisabled: boolean = false;
  @Input() tooltipDisabledFijo: boolean = false;
  @Input() gradient: boolean;
  @Input() showGridLines: boolean = true;
  @Input() activeEntries: any[] = [];
  @Input() schemeType: string;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() yRightAxisTickFormatting: any;
  @Input() roundDomains: boolean = false;
  @Input() colorSchemeLine: any[];
  @Input() autoScale;
  @Input() lineChart: any;
  @Input() yLeftAxisScaleFactor: any;
  @Input() yRightAxisScaleFactor: any;
  @Input() rangeFillOpacity: number;
  @Input() animations: boolean = true;
  @Input() referenceLines: any[];
  @Input() showRefLines: boolean = false;
  @Input() noBarWhenZero: boolean = true;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate', { static: true }) tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate', { static: true }) seriesTooltipTemplate: TemplateRef<any>;

  @ViewChild(LineSeriesComponent, { static: false }) lineSeriesComponent: LineSeriesComponent;

  dims: ViewDimensions;
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  colorsCombo: ColorHelper;
  margin: any[] = [20, 80, 10, 40];
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  legendOptions: any;
  scaleType = 'linear';
  xScaleLine;
  yScaleLine;
  xDomainLine;
  yDomainLine;
  seriesDomain;
  scaledAxis;
  combinedSeries;
  xSet;
  filteredDomain;
  hoveredVertical;
  yOrientLeft = 'left';
  yOrientRight = 'right';
  legendSpacing = 0;
  bandwidth;
  barPadding = 8;
  data: any [] = [];
  group: any [] = [];
  dataLabelMaxHeight = { negative: 0, positive: 0 };
  showDataLabel: boolean = false;
  groupDomain: any [];
  innerDomain: any [] = [];
  valueDomain: any [];
  yScaleMax: any;
  customColors: any[];
  update(): void {
    super.update();
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }
    this.formatDates();
    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valueDomain = this.getValueDomain();
    
    
    
    if (!this.yAxis) {
      this.legendSpacing = 0;
    } else if (this.showYAxisLabel && this.yAxis) {
      this.legendSpacing = 65;
    } else {
      this.legendSpacing = 65;
    }
    

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    // line chart
    this.xDomainLine = this.getXDomainLine();
    if (this.filteredDomain) {
      this.xDomainLine = this.filteredDomain;
    }

    this.yDomainLine = this.getYDomainLine();
    this.seriesDomain = this.getSeriesDomain();
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
    this.yScaleLine = this.getYScaleLine(this.yDomainLine, this.dims.height);

    //desactivar al reinicar o contruir la gráfica
    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.onDeactivate(this.data,this.group, false);
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
    //this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
  }
  getGroupDomain() {
    const domain = [];
    for (const group of this.results) {
        if (!domain.includes(group.label)) {
            domain.push(group.label);
        }
    }
    return domain;
  }
  getInnerDomain() {
      const domain = [];
      for (const group of this.results) {
          for (const d of group.series) {
              if (!domain.includes(d.label)) {
                  domain.push(d.label);
              }
          }
      }
      for(const group of this.lineChart){
        if(!domain.includes(group.name)){
          domain.push(group.name);
        }
      }
      return domain;
  }
  getgValueDomain() {
      const domain = [];
      let smallest = 0;
      let biggest = 0;
      for (const group of this.results) {
          let smallestSum = 0;
          let biggestSum = 0;
          for (const d of group.series) {
              if (d.value < 0) {
                  smallestSum += d.value;
              }
              else {
                  biggestSum += d.value;
              }
              smallest = d.value < smallest ? d.value : smallest;
              biggest = d.value > biggest ? d.value : biggest;
          }
          domain.push(smallestSum);
          domain.push(biggestSum);
      }
      domain.push(smallest);
      domain.push(biggest);
      const min = Math.min(0, ...domain);
      const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
      return [min, max];
  }
  getXScale() {
    const spacing = this.groupDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().rangeRound([0, this.dims.width]).paddingInner(spacing).domain(this.groupDomain);
  }
  getYScale() {
      const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valueDomain);
      return this.roundDomains ? scale.nice() : scale;
  }
  onClick(data) {
    this.showRefLines = true;
    this.referenceLines = [{"name":data.name,"value":data.value}];
    this.data = data;
    this.select.emit(data);
  }
  trackBy(index, item) {
      return item.name;
  }
  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.groupDomain;
    } else {
      domain = this.yDomain;
    }
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    this.colorsLine = new ColorHelper(this.colorSchemeLine, this.schemeType, domain, this.customColors);
    var cadena = [];
    for(var i = 0; i < this.results.length;i++){
      for(var j = 0; j < this.results[i].series.length;j++){
        if(!cadena.includes(this.results[i].series[j].name)){
          cadena.push(this.results[i].series[j].name);
        }
      }
    }
    domain = [];
    for(var i = 0; i < cadena.length;i++){
      domain.push(this.colors.colorDomain[i]);
    }
    var cadenal = [];
    for(var i = 0; i < this.lineChart.length;i++){
      if(!cadenal.includes(this.lineChart[i].name)){
        cadenal.push(this.lineChart[i].name);
      }
    }
    for(var i = 0; i < cadenal.length;i++){
      domain.push(this.colorsLine.colorDomain[i]);
    }    
    let schemeCombo = this.scheme;
    schemeCombo.domain = domain;
    this.colorsCombo = new ColorHelper(schemeCombo, this.schemeType, domain, this.customColors);
  }
  getLegendOptions(){
      const opts = {
          scaleType: this.schemeType,
          colors: undefined,
          domain: [],
          title: undefined,
          position: this.legendPosition
      };
      if (opts.scaleType === 'ordinal') {
          opts.domain = this.innerDomain;
          opts.colors = this.colorsCombo;
          opts.title = this.legendTitle;
      }else {
          opts.domain = this.valueDomain;
          opts.colors = this.colors.scale;
      }
      return opts;
  }
  updateYAxisWidth({ width }) {
      this.yAxisWidth = width;
      this.update();
  }
  updateXAxisHeight({ height }) {
      this.xAxisHeight = height;
      this.update();
  }
  deactivateAll() {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }
  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }
  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }
  updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomainLine = this.filteredDomain;
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
  }
  getSeriesDomain(): any[] {
    this.combinedSeries = this.lineChart.slice(0);
    
    /*this.combinedSeries.push({
      name: this.yAxisLabel,
      series: this.results
    });*/
    var aux = [];
    var arrSeries = [];
    for(var i = 0; i < this.results.length; i++){
      for(var j = 0; j < this.results[i].series.length;j++){
        if(!arrSeries.includes(this.results[i].series[j].name)){
          arrSeries.push(this.results[i].series[j].name);
        }
      }
    }
    for(var i = 0; i < arrSeries.length; i++ ){
      for(var j = 0; j < this.results.length;j++){
        for(var k = 0; k < this.results[j].series.length;k++){
          if(this.results[j].series[k].name == arrSeries[i])
          aux.push({"name": this.results[j].name,"value": this.results[j].series[k].value});
        }
      }
      this.combinedSeries.push({"name":arrSeries[i],"series":aux});
      aux = [];
    }
    return this.combinedSeries.map(d => d.name);
  }
  isDate(value): boolean {
    if (value instanceof Date) {
      return true;
    }

    return false;
  }
  getScaleType(values): string {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) return 'time';
    if (num) return 'linear';
    return 'ordinal';
  }
  getXDomainLine(): any[] {
    let values = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    this.scaleType = this.getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }
    this.xSet = values;
    return domain;
  }
  getYDomainLine(): any[] {
    const domain = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    let min = Math.min(...domain);
    const max = Math.max(...domain);
    if (this.yRightAxisScaleFactor) {
      const minMax = this.yRightAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      min = Math.min(0, min);
      return [min, max];
    }
  }
  getXScaleLine(domain, width): any {
    let scale;
    if (this.bandwidth === undefined) {
      this.bandwidth = this.dims.width - this.barPadding;
    }

    if (this.scaleType === 'time') {
      scale = scaleTime()
        .range([0, width])
        .domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear().range([0, width]).domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([this.bandwidth / 2, width - this.bandwidth / 2])
        .domain(domain);
    }
    return scale;
  }
  getYScaleLine(domain, height): any {
    const scale = scaleLinear()
      .range([height, 0])
      .domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }
  getXDomain(): any[] {
    return this.results.map(d => d.name);
  }
  getYDomain() {
    const values = this.results.map(d => d.value);
    const min = Math.min(0, ...values);
    const max = Math.max(...values);
    if (this.yLeftAxisScaleFactor) {
      const minMax = this.yLeftAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      return [min, max];
    }
  }
  updateLineWidth(width): void {
    this.bandwidth = width;
  }
  onActivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
        item.series = group.name;
    }
    const items = this.results
        .map(g => g.series)
        .flat()
        .filter(i => {
        if (fromLegend) {
            return i.label === item.name;
        }
        else {
            return i.name === item.name && i.series === item.series;
        }
    });
    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }
  onDeactivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
        item.series = group.name;
    }
    this.activeEntries = this.activeEntries.filter(i => {
        if (fromLegend) {
            return i.label !== item.name;
        }
        else {
            return !(i.name === item.name && i.series === item.series);
        }
    });
    this.deactivate.emit({ value: item, entries: this.activeEntries });
    this.onActivate(this.data, this.group,false);// con esto creo se corrige lo de la selección en barverticalstacked
  }
  groupTransform(group) {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
  }
  getValueDomain() {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    for (const group of this.results) {
        let smallestSum = 0;
        let biggestSum = 0;
        for (const d of group.series) {
            if (d.value < 0) {
                smallestSum += d.value;
            }
            else {
                biggestSum += d.value;
            }
            smallest = d.value < smallest ? d.value : smallest;
            biggest = d.value > biggest ? d.value : biggest;
        }
        domain.push(smallestSum);
        domain.push(biggestSum);
    }
    domain.push(smallest);
    domain.push(biggest);
    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
  }
  onActivateL(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }
  onDeactivateL(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
}