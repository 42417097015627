import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeoReferenciacionComponent } from './geo-referenciacion/geo-referenciacion.component';
import {GeograficoRoutingModule} from './geografico-routing.module';
import {SharedModule} from '../shared/shared.module';
import {AgmCoreModule} from '@agm/core';

@NgModule({
  declarations: [
    GeoReferenciacionComponent
  ],
  imports: [
    CommonModule,
    GeograficoRoutingModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyADdzz25KcgMn_N_gekLJfj7CnbLI4BjVc',//'AIzaSyADdzz25KcgMn_N_gekLJfj7CnbLI4BjVc',//"AIzaSyAjeJEPREBQFvAIqDSZliF0WjQrCld-Mh0"
      libraries: ['places']
    })
  ]
})
export class GeograficoModule { }
