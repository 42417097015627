<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <!-- TITULO DEL COMPONENTE -->
    <div class="w3-quarter padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b>{{ dataService.currentModule }}</b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal">{{ dataService.currentSubmodule }}</span>
      </div>
    </div>
    <!-- FIN TITULO DEL COMPONENTE -->

    <!-- FILTROS-->
    <div class="w3-rest">
      <!-- DEFINICION FILTROS -->
      <div class="w3-noventa2 w3-row ">
        <!-- AÑOS -->
        <div class="w3-third padreizquierda">
          <!-- FILTRO AÑOS DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Año
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-medium"  #menuanios="matMenu" >
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                (change)="anioChangeListener(anio)">
                    {{anio.value}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO AÑOS DISPONIBLES -->

          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest  hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row hijoizquierda chips">
                <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':anios.check,'w3-hide ':!anios.check}"
                            *ngFor="let anios of aniosSeleccionados"
                            class="filtroMesAnioSeleccionado">
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>
        <!-- FIN AÑOS -->

        <!-- MESES -->
        <div class="w3-third">
          <!-- FILTRO MESES DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              Mes
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menumeses="matMenu" style="height: 100px;">
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}"
                                (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                    {{mes.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO MESES DISPONIBLES -->

          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnmeses">
              <div class="w3-row hijoizquierda chips" >
                <div [ngClass]="{'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu':meses.check,'w3-hide ':!meses.check}"
                    *ngFor="let meses of mesesSeleccionados"
                    class="filtroMesAnioSeleccionado">
                    <span class="contenidoChips">{{meses.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        <!-- FIN MESES -->

        <!-- consolidado sucursal -->
        <div class="w3-third">
          <div class="w3-half hijoizquierda  ">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuentidad">
              Sucursal
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium"  #menuentidad="matMenu" >
              <button mat-menu-item>
                <mat-checkbox name="consolidado"
                              [checked]="consolidado"
                              (click)="$event.stopPropagation();"
                              (change)="consolidadoChangeListener()"
                              value="consolidado" aria-label="First checkbox">
                  Consolidado
                </mat-checkbox>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="sucursales">Sucursales</button>
            </mat-menu>
            <mat-menu #sucursales="matMenu">
              <ng-container>
                <mat-form-field style="margin: 1vh; max-width: 15vh;" (click)="$event.stopPropagation();">
                  <mat-label>Buscar</mat-label>
                  <mat-icon matSuffix style="color: gray;">search</mat-icon>
                  <input matInput (keyup)="applyFilter($event)" type="text" autocomplete="off">
                </mat-form-field>
              </ng-container>
              <ng-container *ngFor="let sucursal of sucursalesDisponibles2.filteredData">
                <button mat-menu-item>
                  <mat-checkbox [checked]="sucursal.check"
                    (change)="sucursalChangeListener(sucursal)"
                    (click)="$event.stopPropagation();">
                    {{sucursal.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnmeses">
              <div class="w3-row hijoizquierda chips" >
                <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu">
                  <span class="contenidoChips">{{sucursal_seleccionada}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
      </div>
      <!-- FIN DEFINICION FILTROS -->

      <!-- BORRAR FILTROS -->
      <div class="w3-rest padreizquierda w3-right ">
        <div class="w3-row hijoizquierda">
          <button mat-icon-button (click)="quitarFiltros()">
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
              <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
            </mat-icon>
          </button>
        </div>
      </div>
      <!-- FIN BORRAR FILTROS -->
    </div>
    <!-- FIN FILTROS-->
  </div>
</div>

<!-- CONTENIDO DEL TABLERO -->
<div class="w3-row w3-container w3-container w3-contenedor-template" style="height: 85vh;">
  <!-- CONTENEDOR ARRIBA -->
  <div class="w3-row " style="height: 41vh;">
    <!-- PRIMER CUADRANTE -->
    <div class="w3-row w3-third" style="height: 41vh;" id="GrafC1">
      <div class="w3-row " style="height: 41vh;">
        <div class="w3-row w3-white" style="width: 98%;height: 39vh;"> 
          <!--encabezado del cuadrante principal-->
          <div class="w3-row " >
              <div class="w3-row ">
                <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                  <div class="hijoizquierdaTitle p w3-tituloZonas" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                    <span>Género &nbsp;</span>
                    <span>&bull; &nbsp;</span>
                    <span class=" w3-margin-left-filter w3-subtitulo" >{{subtituloGrafica1}}</span>
                  </div>
                </div>
                <div class="w3-rest w3-right-align_rest">
                  <div class="w3-rest hijoderechaTitle w3-tiny ">
                    <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC1()">zoom_out_map</mat-icon>
                    <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
                      more_vert
                    </mat-icon>
                    <mat-menu #menu="matMenu">
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC1">
                        <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                        <span>Gráficas</span>
                      </button>
                      <mat-divider></mat-divider>
                      <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC1">
                        <mat-icon [ngStyle]="{'color':'gray'}" class="material-icons-outlined">description</mat-icon>
                        <span>exportar  como</span>
                      </button>
                    </mat-menu>
                    <mat-menu #exportarC1="matMenu">
                      <button mat-menu-item *ngIf="graficaTablaC1" (click)="export_excel('C1')">
                        <span>CSV</span>
                      </button>
                      <button mat-menu-item *ngIf="graficaTablaC1" (click)="clickexportCSV('C1')">
                        <span>CSV por sucursales</span>
                      </button>
                      <button mat-menu-item *ngIf="!graficaTablaC1" (click)="export_imagen('C1')">
                        <span>PNG</span>
                      </button>
                    </mat-menu>
                    <mat-menu #graficasC1="matMenu">
                      <button mat-menu-item (click)="cambiarGraficaC1('barra')">
                        <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                        <span>Barras</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC1('lineal')">
                        <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                        <span>Lineal</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC1('pastel')">
                        <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                        <span>Pastel</span>
                      </button>
                      <button mat-menu-item (click)="cambiarGraficaC1('tabla')">
                        <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                        <span>tabla</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
          </div>
          <!--encabezado del cuadrante principal-->
          <div class="contenedorCuadranteGraficas" style="height: 33vh; width: 99%;">
            <div class="w3-row" *ngIf="graficapieC1" style="height: 32vh; width: 95%; margin: auto;">
              <google-chart
                class="graficaPie"
                [type]="'PieChart'"
                [data]="data1"
                [options]="options"
                (select)="clickPieChartC1($event)">
              </google-chart>
            </div>
            <div class="w3-row" *ngIf="graficabarraC1" style="height: 32vh;width: 85%; margin: auto;">
              <ngx-charts-bar-vertical
                [scheme]="colorBarras"
                [results]="data1_barra"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="false"
                [showGridLines]="false"
                (select)="clickPieChartC1($event)">
              </ngx-charts-bar-vertical>
            </div>
            <div class="w3-row" *ngIf="graficalinealC1" style="height: 32vh;width: 90%; margin: auto;">
              <ngx-charts-line-chart  
                [scheme]="colorScheme"
                [results]="data1_lineal"
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [showGridLines]="false"
                (select)="clickPieChartC1($event)"
                >
                <!--<ng-template #tooltipTemplate let-model="model" >
                  <div style="text-align: left;">
                    <div *ngFor="let data of data1_lineal; let i = index">
                        <span>{{model.value}} </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model" >
                <div style="text-align: left;">
                    <div *ngFor="let data of data1_lineal; let i = index">
                      <span>{{model[0].value}}</span>
                    </div>
                </div>
                </ng-template>-->
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row" *ngIf="graficaTablaC1" style="height: 32vh;width: 90%; margin: auto;">
              <div class="w3-table-all">
                <table mat-table [dataSource]="dataTablaGenero" class=" w3-table" >
                    <!-- Position Column -->
                    <ng-container matColumnDef="genero">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Género </th>
                    <td mat-cell style="width: 40%;" *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>              
                    <!-- Name Column -->
                    <ng-container matColumnDef="valor">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Número de socios  </th>
                    <td mat-cell style="width: 30%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                    </ng-container>   
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="porcentaje">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                      <td mat-cell style="width: 30%;" *matCellDef="let element"> {{element.porcentaje}}% </td>
                      </ng-container>   
                    <tr mat-header-row *matHeaderRowDef="columnasC1; sticky: true" class="w3-elemento-titulo-table"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnasC1;"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN PRIMER CUADRANTE -->

    <!-- SEGUNDO CUADRANTE -->
    <div class="w3-row w3-third" style="height: 41vh;" id="GrafC2">
      <!--encabezado del cuadrante principal-->
      <div class="w3-row w3-white" style="width: 98%;height: 39vh;">
        <div class="w3-row ">
          <div class="w3-row" >
            <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
              <div class="hijoizquierdaTitle p w3-tituloZonas" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                <span>Clasificación &nbsp;</span>
                <span>&bull; &nbsp;</span>
                <span class="w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
              </div>
            </div>
            <div class="w3-rest w3-right-align_rest">
              <div class="w3-rest hijoderechaTitle w3-tiny ">
                <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC2()">zoom_out_map</mat-icon>
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC2">
                  more_vert
                </mat-icon>
                <mat-menu #menuC2="matMenu">
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC2">
                    <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                    <span>Gráficas</span>
                  </button>
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="cantDat2">
                    <mat-icon [ngStyle]="{'color':'gray'}">tag</mat-icon>
                    <span>Datos</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC2">
                    <mat-icon [ngStyle]="{'color':'gray'}" class="material-icons-outlined">description</mat-icon>
                    <span>exportar como</span>
                  </button>
                </mat-menu>
                <mat-menu #exportarC2="matMenu">
                  <button mat-menu-item *ngIf="graficatablaC2" (click)="export_excel('C2')">
                    <span>CSV</span>
                  </button>
                  <button mat-menu-item *ngIf="graficatablaC2" (click)="clickexportCSV('C2')">
                    <span>CSV por sucursales</span>
                  </button>
                  <button mat-menu-item *ngIf="!graficatablaC2" (click)="export_imagen('C2')">
                    <span>PNG</span>
                  </button>
                </mat-menu>
                <mat-menu #graficasC2="matMenu">
                  <button mat-menu-item (click)="cambiarGraficaC2('barra')">
                    <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                    <span>Barras</span>
                  </button>
                  <button mat-menu-item (click)="cambiarGraficaC2('lineal')">
                    <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                    <span>Lineal</span>
                  </button>
                  <button mat-menu-item (click)="cambiarGraficaC2('mapacalor')">
                    <mat-icon [ngStyle]="{'color':'gray'}">view_quilt</mat-icon>
                    <span>Mapa de calor</span>
                  </button>
                  <button mat-menu-item (click)="cambiarGraficaC2('tabla')">
                    <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                    <span>tabla</span>
                  </button>
                </mat-menu>
                <mat-menu #cantDat2="matMenu">
                  <button mat-menu-item (click)="cambioCant2(1)">
                    <span>15</span>
                  </button>
                  <button mat-menu-item (click)="cambioCant2(2)">
                    <span>Todos</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <!--encabezado del cuadrante principal-->

        <div class="contenedorCuadranteGraficas" style="height: 33vh;width: 99%">
          <div class="w3-row" *ngIf="mapacalorC2" style="height: 95%; width: 95%; margin: auto;">
            <ngx-charts-tree-map
              [scheme]="colorScheme"
              [results]="dataMapaDeCalor"
              [animations]="true"
              [labelFormatting]="labelFormatting"
              (select)="clickMapaCalor($event)"
            >
            </ngx-charts-tree-map>
          </div>
          <div class="w3-row" *ngIf="graficabarraC2" style="height: 32vh;width: 90%; margin: auto;">
            <ngx-charts-bar-vertical 
              [scheme]="colorBarras"
              [results]="dataMapaDeCalor"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showGridLines]="false"
              (select)="clickMapaCalor($event)"
              >
            </ngx-charts-bar-vertical>
          </div>
          <div  class="w3-row" *ngIf="graficalinealC2" style="height: 32vh;width: 90%; margin: auto;">
            <ngx-charts-line-chart  
              [scheme]="colorScheme"
              [results]="dataMapaDeCalor_lineal"
              [legend]="legend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [xAxisLabel]="xAxisLabelC2"
              [yAxisLabel]="yAxisLabel"
              [showGridLines]="false"
              (select)="clickMapaCalor($event)"
              >
              <!--<ng-template #tooltipTemplate let-model="model" >
                <div style="text-align: left;">
                  <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                      <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                  </div>
                </div>
              </ng-template>
              <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                  <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                  </div>
              </div>
              </ng-template>-->
            </ngx-charts-line-chart>
          </div>
          <div class="w3-row" *ngIf="graficatablaC2" style="height: 32vh;width: 90%; margin: auto;">
            <div class="w3-encabezado-table w3-scroll" >
              <div class="w3-table-all">
                <table mat-table [dataSource]="dataTablaClasificacion" class=" w3-table">
                    <!-- Position Column -->
                    <ng-container matColumnDef="nomCred">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Nombre credito </th>
                    <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>              
                    <!-- Name Column -->
                    <ng-container matColumnDef="valor">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Número de creditos </th>
                    <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="porcentaje">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.porcentaje}}% </td>
                      </ng-container>   
                    <!-- Symbol Column -->
                    <tr mat-header-row *matHeaderRowDef="columnasC2; sticky: true" class="w3-elemento-titulo-table"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnasC2;"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN SEGUNDO CUADRANTE -->

    <!-- TERCER CUADRANTE-->
    <div class="w3-row w3-third" style="height: 41vh;" id="GrafC3">
      <div class="w3-row w3-white" style="width: 98%;height: 39vh;">
        <!--encabezado del cuadrante principal-->
        <div class="w3-row">
          <div class="w3-row ">
            <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
              <div class="hijoizquierdaTitle p w3-tituloZonas" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                <span>Estatus &nbsp;</span>
                <span>&bull; &nbsp;</span>
                <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
              </div>
            </div>
            <div class="w3-rest w3-right-align_rest">
              <div class="w3-rest hijoderechaTitle w3-tiny ">
                <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC3()">zoom_out_map</mat-icon>
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC3">
                  more_vert
                </mat-icon>
                <mat-menu #menuC3="matMenu">
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC3">
                    <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                    <span>Gráficas</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC3">
                    <mat-icon [ngStyle]="{'color':'gray'}" class="material-icons-outlined">description</mat-icon>
                    <span>Exportar como</span>
                  </button>
                </mat-menu>
                <mat-menu #exportarC3="matMenu">
                  <button mat-menu-item *ngIf="graficatablaC3" (click)="export_excel('C3')">
                    <span>CSV</span>
                  </button>
                  <button mat-menu-item *ngIf="graficatablaC3" (click)="clickexportCSV('C3')">
                    <span>CSV por sucursales</span>
                  </button>
                  <button mat-menu-item *ngIf="!graficatablaC3" (click)="export_imagen('C3')">
                    <span>PNG</span>
                  </button>
                </mat-menu>
                <mat-menu #graficasC3="matMenu">
                  <button mat-menu-item (click)="cambiarGraficaC3('barra')">
                    <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                    <span>Barras</span>
                  </button>
                  <button mat-menu-item (click)="cambiarGraficaC3('lineal')">
                    <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                    <span>Lineal</span>
                  </button>
                  <button mat-menu-item (click)="cambiarGraficaC3('pastel')">
                    <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                    <span>Pastel</span>
                  </button>
                  <button mat-menu-item (click)="cambiarGraficaC3('tabla')">
                    <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                    <span>Tabla</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <!--encabezado del cuadrante principal-->
        <div class="contenedorCuadranteGraficas"  style="height: 33vh;width: 100%">
          <div class="w3-row" *ngIf="graficapieC3" style="height: 32vh;width: 90%; margin: auto;">
            <google-chart
              class="graficaPie"
              [type]="'PieChart'"
              [data]="datav3"
              [options]="optionsv3"
              (select)="clickPieC3($event)">
            </google-chart>
          </div>
          <div class="w3-row" *ngIf="graficabarraC3" style="height: 32vh;width: 90%; margin: auto;">
            <ngx-charts-bar-vertical 
              [scheme]="colorBarras"
              [results]="datav3_barra"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [xAxisLabel]="xAxisLabelC3"
              [showGridLines]="false"
              (select)="clickPieC3($event)">
            </ngx-charts-bar-vertical>
          </div>
          <div  class="w3-row" *ngIf="graficalinealC3" style="height: 32vh;width: 90%; margin: auto;">
            <ngx-charts-line-chart  
              [scheme]="colorScheme"
              [results]="datav3_lineal"
              [legend]="legend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [xAxisLabel]="xAxisLabelC3"
              [yAxisLabel]="yAxisLabel"
              [showGridLines]="false"
              (select)="clickPieC3($event)"
              >
              <!--<ng-template #tooltipTemplate let-model="model" >
                <div style="text-align: left;">
                  <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                      <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                  </div>
                </div>
              </ng-template>
              <ng-template #seriesTooltipTemplate let-model="model" >
              <div style="text-align: left;">
                  <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                  </div>
              </div>
              </ng-template>-->
            </ngx-charts-line-chart>
          </div>
          <div class="w3-row" *ngIf="graficatablaC3" style="height: 32vh;width: 90%; margin: auto;">
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataTablaEstatus" class=" w3-table" >
                  <!-- Position Column -->
                  <ng-container matColumnDef="estatus">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Estatus </th>
                  <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>   
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.porcentaje}}% </td>
                  </ng-container>   
                  <tr mat-header-row *matHeaderRowDef="columnasC3; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC3;"></tr>
              </table>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN TERCER CUADRANTE -->
  </div>
  <!-- FIN CONTENEDOR ARRIBA -->

  <!-- CONTENEDOR ABAJO -->
  <div class="w3-row" style="height: 41vh;">
    <!-- PRIMER CUADRANTE -->
    <div class="w3-row w3-third" style="height: 41vh;" id="GrafC4">
      <div class="w3-row" style="height: 41vh;">
        <div class="w3-row w3-white" style="width: 98%;height: 39vh;">
          <!--encabezado del cuadrante principal-->
          <div class="w3-row " >
            <div class="w3-row w3-white">
              <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p w3-tituloZonas" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                <span>Ocupación &nbsp;</span>
                <span>&bull; &nbsp;</span>
                <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
              </div>
              <div class="w3-rest w3-right-align_rest">
                <div class="w3-rest hijoderechaTitle w3-tiny ">
                  <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC4()">zoom_out_map</mat-icon>
                  <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC4">
                    more_vert
                  </mat-icon>
                  <mat-menu #menuC4="matMenu">
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC4">
                      <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                      <span>Gráficas</span>
                    </button>
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="cantDat4">
                      <mat-icon [ngStyle]="{'color':'gray'}">tag</mat-icon>
                      <span>Datos</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC4">
                      <mat-icon [ngStyle]="{'color':'gray'}" class="material-icons-outlined">description</mat-icon>
                      <span>Exportar como</span>
                    </button>
                  </mat-menu>
                  <mat-menu #exportarC4="matMenu">
                    <button mat-menu-item *ngIf="graficatablaC4" (click)="export_excel('C4')">
                      <span>CSV</span>
                    </button>
                    <button mat-menu-item *ngIf="graficatablaC4" (click)="clickexportCSV('C4')">
                      <span>CSV por sucursles</span>
                    </button>
                    <button mat-menu-item *ngIf="!graficatablaC4" (click)="export_imagen('C4')">
                      <span>PNG</span>
                    </button>
                  </mat-menu>
                  <mat-menu #graficasC4="matMenu">
                    <button mat-menu-item (click)="cambiarGraficaC4('barra')">
                      <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                      <span>Barras</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC4('lineal')">
                      <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                      <span>Lineal</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC4('pastel')">
                      <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                      <span>Pastel</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC4('tabla')">
                      <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                      <span>Tabla</span>
                    </button>
                  </mat-menu>
                  <mat-menu #cantDat4="matMenu">
                    <button mat-menu-item (click)="cambioCant4(1)">
                      <span>15</span>
                    </button>
                    <button mat-menu-item (click)="cambioCant4(2)">
                      <span>Todos</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <!--encabezado del cuadrante principal-->
          <div class="contenedorCuadranteGraficas w3-white" style="height: 33vh;width: 98%">
            <div class="w3-row" *ngIf="graficabarraC4" style="height: 32vh;width: 90%; margin: auto;">
              <ngx-charts-bar-vertical
                [scheme]="colorBarras"
                [results]="dataOcupacion"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="false"
                [showGridLines]="false"
                (select)="clickPieC4($event)">
              </ngx-charts-bar-vertical>
            </div>
            <div  class="w3-row" *ngIf="graficalinealC4" style="height: 32vh;width: 90%; margin: auto;">
              <ngx-charts-line-chart  
                [scheme]="colorScheme"
                [results]="dataOcupacion_lineal"
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabelC4"
                [yAxisLabel]="yAxisLabel"
                [showGridLines]="false"
                (select)="clickPieC4($event)">
                <!--<ng-template #tooltipTemplate let-model="model" >
                  <div style="text-align: left;">
                    <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                        <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model" >
                <div style="text-align: left;">
                    <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                      <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                    </div>
                </div>
                </ng-template>-->
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row" *ngIf="graficapieC4" style="height: 32vh;width: 95%; margin: auto;">
              <google-chart
                class="graficaPie"
                [type]="'PieChart'"
                [data]="dataPie4"
                [options]="options22"
                (select)="clickPieC4($event)">
              </google-chart>
            </div>
            <div class="w3-row" *ngIf="graficatablaC4" style="height: 32vh;width: 90%; margin: auto;">
              <div class="w3-encabezado-table w3-scroll" >
                <div class="w3-table-all">
                  <table mat-table [dataSource]="dataTablaOcupacion" class=" w3-table" >
                      <!-- Position Column -->
                      <ng-container matColumnDef="ocupacion">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Ocupación </th>
                      <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                      </ng-container>              
                      <!-- Name Column -->
                      <ng-container matColumnDef="valor">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                      </ng-container>
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="porcentaje">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.porcentaje}}% </td>
                      </ng-container>   
                      <tr mat-header-row *matHeaderRowDef="columnasC4; sticky: true" class="w3-elemento-titulo-table"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnasC4;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN PRIMER CUADRANTE -->

    <!-- SEGUNDO CUADRANTE -->
    <div class="w3-row w3-third" style="height: 41vh;" id="GrafC5">
      <div class="w3-row" style="height: 41vh;">
        <div class="w3-row w3-white" style="width: 98%;height: 39vh;">
          <!--encabezado del cuadrante principal-->
          <div class="w3-row">
            <div class="w3-row w3-white">
              <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p w3-tituloZonas" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                <span>Finalidad &nbsp;</span>
                <span>&bull; &nbsp;</span>
                <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
              </div>
              <div class="w3-rest w3-right-align_rest">
                <div class="w3-rest hijoderechaTitle w3-tiny ">
                  <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC5()">zoom_out_map</mat-icon>
                  <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC5">
                    more_vert
                  </mat-icon>
                  <mat-menu #menuC5="matMenu">
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC5">
                      <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                      <span>Gráficas</span>
                    </button>
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="perspectivaC5">
                      <mat-icon [ngStyle]="{'color':'gray'}">account_tree</mat-icon>
                      <span>Perspectiva</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC5">
                      <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                      <span>Exportar como</span>
                    </button>
                  </mat-menu>
                  <mat-menu #exportarC5="matMenu">
                    <button mat-menu-item *ngIf="graficatablaC5" (click)="export_excel('C5')">
                      <span>CSV</span>
                    </button>
                    <button mat-menu-item *ngIf="graficatablaC5" (click)="clickexportCSV('C5')">
                      <span>CSV por sucursales</span>
                    </button>
                    <button mat-menu-item *ngIf="!graficatablaC5" (click)="export_imagen('C5')">
                      <span>PNG</span>
                    </button>
                  </mat-menu>
                  <mat-menu #perspectivaC5="matMenu">
                    <button mat-menu-item (click)="cambioPerC5('f')">
                      <span>Finalidad</span>
                    </button>
                    <button mat-menu-item (click)="cambioPerC5('s')">
                      <span>Servicios</span>
                    </button>
                  </mat-menu>
                  <mat-menu #graficasC5="matMenu">
                    <button mat-menu-item (click)="cambiarGraficaC5('barra')">
                      <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                      <span>Barras</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC5('lineal')">
                      <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                      <span>Lineal</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC5('pastel')">
                      <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                      <span>Pastel</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC5('tabla')">
                      <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                      <span>Tabla</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <!--encabezado del cuadrante principal-->

          <div class="contenedorCuadranteGraficas">
            <div class="w3-row" *ngIf="graficapastelC5" style="height: 32vh;width: 90%; margin: auto;">
              <google-chart
                class="graficaPie"
                [type]="'PieChart'"
                [data]="dataEstadoCivil"
                [options]="optionsV5">
              </google-chart>
            </div>
            <div class="w3-row" *ngIf="graficabarraC5" style="height: 32vh;width: 90%; margin: auto;">
              <ngx-charts-bar-vertical
                [scheme]="colorBarras"
                [results]="dataEstadoCivil_barra"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="false"
                [showGridLines]="false">
              </ngx-charts-bar-vertical>
            </div>
            <div  class="w3-row" *ngIf="graficalinealC5" style="height: 32vh;width: 90%; margin: auto;">
              <ngx-charts-line-chart  
                [scheme]="colorScheme"
                [results]="dataEstadoCivil_lineal"
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabelC5"
                [yAxisLabel]="yAxisLabel"
                [showGridLines]="false"
                (select)="onSelect($event)"
                (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)"
                >
                <!--<ng-template #tooltipTemplate let-model="model" >
                  <div style="text-align: left;">
                    <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                        <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model" >
                <div style="text-align: left;">
                    <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                      <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                    </div>
                </div>
                </ng-template>-->
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row" *ngIf="graficatablaC5" style="height: 32vh;width: 90%; margin: auto;">
              <div class="w3-encabezado-table w3-scroll" >
                <div class="w3-table-all">
                  <table mat-table [dataSource]="dataTablaFinalidad" class=" w3-table" >
                      <!-- Position Column -->
                      <ng-container matColumnDef="finalidad">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Finalidad </th>
                      <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                      </ng-container>              
                      <!-- Name Column -->
                      <ng-container matColumnDef="valor">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                      </ng-container>   
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="porcentaje">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.porcentaje}}% </td>
                      </ng-container> 
                      <tr mat-header-row *matHeaderRowDef="columnasC5; sticky: true" class="w3-elemento-titulo-table"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnasC5;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN SEGUNDO CUADRANTE -->

    <!-- TERCER CUADRANTE-->
    <div class="w3-row w3-third" style="height: 41vh;" id="GrafC6">
      <div class="w3-row" style="height: 41vh;">
        <div class="w3-row w3-white" style="width: 98%;height: 39vh;">
          <!--encabezado del cuadrante principal-->
          <div class="w3-row">
            <div class="w3-row w3-white">
              <div class="w3-noventa w3-titulo-contenidomenu padreizquierda p w3-tituloZonas" matTooltip="{{subtituloGrafica1}}" matTooltipPosition="above" matTooltipShowDelay="1000">
                  <span>Municipio &nbsp;</span>
                  <span>&bull; &nbsp;</span>
                  <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
              </div>
              <div class="w3-rest w3-right-align_rest">
                <div class="w3-rest hijoderechaTitle w3-tiny ">
                  <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="AmpliarC6()">zoom_out_map</mat-icon>
                  <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC6">
                    more_vert
                  </mat-icon>
                  <mat-menu #menuC6="matMenu">
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC6">
                      <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                      <span>Gráficas</span>
                    </button>
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="cantDat6">
                      <mat-icon [ngStyle]="{'color':'gray'}">tag</mat-icon>
                      <span>Datos</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC6">
                      <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                      <span>Exportar como</span>
                    </button>
                  </mat-menu>
                  <mat-menu #exportarC6="matMenu">
                    <button mat-menu-item *ngIf="graficatablaC6" (click)="export_excel('C6')">
                      <span>CSV</span>
                    </button>
                    <button mat-menu-item *ngIf="graficatablaC6" (click)="clickexportCSV('C6')">
                      <span>CSV por sucursales</span>
                    </button>
                    <button mat-menu-item *ngIf="!graficatablaC6" (click)="export_imagen('C6')">
                      <span>PNG</span>
                    </button>
                  </mat-menu>
                  <mat-menu #graficasC6="matMenu">
                    <button mat-menu-item (click)="cambiarGraficaC6('barra')">
                      <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                      <span>Barras</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC6('lineal')">
                      <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                      <span>Lineal</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC6('pastel')">
                      <mat-icon [ngStyle]="{'color':'gray'}">pie_chart</mat-icon>
                      <span>Pastel</span>
                    </button>
                    <button mat-menu-item (click)="cambiarGraficaC6('tabla')">
                      <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                      <span>Tabla</span>
                    </button>
                  </mat-menu>
                  <mat-menu #cantDat6="matMenu">
                    <button mat-menu-item (click)="cambioCant6(1)">
                      <span>15</span>
                    </button>
                    <button mat-menu-item (click)="cambioCant6(2)">
                      <span>Todos</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <!--encabezado del cuadrante principal-->

          <div class="contenedorCuadranteGraficas">
            <div class="w3-row" *ngIf="graficabarraC6" style="height: 32vh;width: 90%; margin: auto;">
              <ngx-charts-bar-horizontal
                [scheme]="colorBarras"
                [results]="dataMunicipio"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="false"
                [showGridLines]="false">
              </ngx-charts-bar-horizontal>
            </div>
            <div  class="w3-row" *ngIf="graficalinealC6" style="height: 32vh;width: 90%; margin: auto;">
              <ngx-charts-line-chart  
                [scheme]="colorScheme"
                [results]="dataMunicipio_lineal"
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabelC6"
                [yAxisLabel]="yAxisLabel"
                [showGridLines]="false"
                (select)="onSelect($event)"
                (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)"
                >
                <!--<ng-template #tooltipTemplate let-model="model" >
                  <div style="text-align: left;">
                    <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                        <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model" >
                <div style="text-align: left;">
                    <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                      <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                    </div>
                </div>
                </ng-template>-->
              </ngx-charts-line-chart>
            </div>
            <div class="w3-row" *ngIf="graficapieC6" style="height: 32vh;width: 90%; margin: auto;">
              <google-chart
                class="graficaPie"
                [type]="'PieChart'"
                [data]="dataMunicipio_Pie"
                [options]="options">
              </google-chart>
            </div>
            <div class="w3-row" *ngIf="graficatablaC6" style="height: 32vh;width: 90%; margin: auto;">
              <div class="w3-encabezado-table w3-scroll" >
                <div class="w3-table-all">
                  <table mat-table [dataSource]="dataTablaMunicipio" class=" w3-table" >
                      <!-- Position Column -->
                      <ng-container matColumnDef="municipio">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Municipio </th>
                      <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                      </ng-container>              
                      <!-- Name Column -->
                      <ng-container matColumnDef="valor">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                      </ng-container>   
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="porcentaje">
                      <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element"> {{element.porcentaje}}% </td>
                      </ng-container> 
                      <tr mat-header-row *matHeaderRowDef="columnasC6; sticky: true" class="w3-elemento-titulo-table"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnasC6;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
    <!-- FIN TERCER CUADRANTE -->
  </div>
  <!-- FIN CONTENEDOR ABAJO -->
</div>


<!-- Modal para las gráficas -->
<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <span class="w3-margin-left-contenido">{{titulomodal}}</span><span class="w3-punto-subtitulo w3-margin-left-filter" >&bull;</span><span class=" w3-margin-left-filter w3-subtitulo">{{subtitulomodal}}</span>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <div class="w3-row" *ngIf="graficapieC1_modal" style="height: 52vh;">
          <google-chart 
            class="graficaPie"
            [type]="'PieChart'"
            [data]="data1"
            [options]="options">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficabarraC1_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical 
            [scheme]="colorBarras"
            [results]="data1_barra"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalinealC1_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="data1_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            >
            <!--<ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of data1_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of data1_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>-->
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficaTablaC1_modal" style="height: 52vh;">
          <div class="w3-table-all">
            <table mat-table [dataSource]="data1_barra" class=" w3-table" >
                <!-- Position Column -->
                <ng-container matColumnDef="genero">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Género </th>
                <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>              
                <!-- Name Column -->
                <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Número de socios  </th>
                <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                </ng-container>   
                <!-- Symbol Column -->
                <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{porcentaje(element.value,'C1')}}% </td>
                  </ng-container>  
                <tr mat-header-row *matHeaderRowDef="columnasC1; sticky: true" class="w3-elemento-titulo-table"></tr>
                <tr mat-row *matRowDef="let row; columns: columnasC1;"></tr>
            </table>
        </div>
        </div>
        <div class="w3-row" *ngIf="mapacalorC2_modal" style="height: 52vh;">
          <ngx-charts-tree-map
            [scheme]="colorScheme"
            [results]="dataMapaDeCalor"
            [animations]="true"
            [labelFormatting]="labelFormatting">
          </ngx-charts-tree-map>
        </div>
        <div class="w3-row" *ngIf="graficabarraC2_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical 
            [scheme]="colorBarras"
            [results]="dataMapaDeCalor"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalinealC2_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="dataMapaDeCalor_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC2"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <!--<ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>-->
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC2_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataMapaDeCalor" class=" w3-table">
                  <!-- Position Column -->
                  <ng-container matColumnDef="nomCred">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Nombre credito </th>
                  <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Número de creditos </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                    <td mat-cell style="width: 25%;" *matCellDef="let element"> {{porcentaje(element.value,'C2')}}% </td>
                    </ng-container>   
                  <!-- Symbol Column -->
                  <tr mat-header-row *matHeaderRowDef="columnasC2; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC2;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row" *ngIf="graficapieC3_modal" style="height: 52vh;">
          <google-chart
            class="graficaPie"
            [type]="'PieChart'"
            [data]="datav3"
            [options]="optionsv3">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficabarraC3_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical 
            [scheme]="colorBarras"
            [results]="datav3_barra"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [xAxisLabel]="xAxisLabelC3"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalinealC3_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="datav3_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC3"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <!--<ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>-->
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC3_modal" style="height: 52vh;">
          <div class="w3-table-all">
            <table mat-table [dataSource]="datav3_barra" class=" w3-table" >
                <!-- Position Column -->
                <ng-container matColumnDef="estatus">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Estatus </th>
                <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                </ng-container>              
                <!-- Name Column -->
                <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                </ng-container>   
                <!-- Symbol Column -->
                <ng-container matColumnDef="porcentaje">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{porcentaje(element.value,'C3')}}% </td>
                  </ng-container>  
                <tr mat-header-row *matHeaderRowDef="columnasC3; sticky: true" class="w3-elemento-titulo-table"></tr>
                <tr mat-row *matRowDef="let row; columns: columnasC3;"></tr>
            </table>
        </div>
        </div>
        <div class="w3-row" *ngIf="graficabarraC4_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical
            [scheme]="colorBarras"
            [results]="dataOcupacion"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalinealC4_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="dataOcupacion_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC4"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <!--<ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMapaDeCalor_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>-->
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficapieC4_modal" style="height: 52vh;">
          <google-chart
            class="graficaPie"
            [type]="'PieChart'"
            [data]="dataPie4"
            [options]="options">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC4_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataOcupacion" class=" w3-table" >
                  <!-- Position Column -->
                  <ng-container matColumnDef="ocupacion">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Ocupación </th>
                  <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>   
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                    <td mat-cell style="width: 25%;" *matCellDef="let element"> {{porcentaje(element.value,'C4')}}% </td>
                    </ng-container>  
                  <tr mat-header-row *matHeaderRowDef="columnasC4; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC4;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row" *ngIf="graficapastelC5_modal" style="height: 52vh;">
          <google-chart
            class="graficaPie"
            [type]="'PieChart'"
            [data]="dataEstadoCivil"
            [options]="options">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficabarraC5_modal" style="height: 52vh;">
          <ngx-charts-bar-vertical
            [scheme]="colorBarras"
            [results]="dataEstadoCivil_barra"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-vertical>
        </div>
        <div class="w3-row" *ngIf="graficalinealC5_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="dataEstadoCivil_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC5"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <!--<ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataEstadoCivil_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>-->
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC5_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataEstadoCivil_barra" class=" w3-table" >
                  <!-- Position Column -->
                  <ng-container matColumnDef="finalidad">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Finalidad </th>
                  <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>   
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                    <td mat-cell style="width: 25%;" *matCellDef="let element"> {{porcentaje(element.value,'C5')}}% </td>
                    </ng-container>  
                  <tr mat-header-row *matHeaderRowDef="columnasC5; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC5;"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="w3-row" *ngIf="graficabarraC6_modal" style="height: 52vh;">
          <ngx-charts-bar-horizontal
            [scheme]="colorBarras"
            [results]="dataMunicipio"
            [gradient]="false"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showGridLines]="false">
          </ngx-charts-bar-horizontal>
        </div>
        <div class="w3-row" *ngIf="graficalinealC6_modal" style="height: 52vh;">
          <ngx-charts-line-chart  
            [scheme]="colorScheme"
            [results]="dataMunicipio_lineal"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelC6"
            [yAxisLabel]="yAxisLabel"
            [showGridLines]="false"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
            <!--<ng-template #tooltipTemplate let-model="model" >
              <div style="text-align: left;">
                <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                    <span>{{model.extra.key_as_string}} : {{model.value}} </span>
                </div>
              </div>
            </ng-template>
            <ng-template #seriesTooltipTemplate let-model="model" >
            <div style="text-align: left;">
                <div *ngFor="let data of dataMunicipio_lineal; let i = index">
                  <span>{{model[0].extra.key_as_string}} :  {{model[0].value}}</span>
                </div>
            </div>
            </ng-template>-->
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row" *ngIf="graficapieC6_modal" style="height: 52vh;">
          <google-chart
            class="graficaPie"
            [type]="'PieChart'"
            [data]="dataMunicipio_Pie"
            [options]="options">
          </google-chart>
        </div>
        <div class="w3-row" *ngIf="graficatablaC6_modal" style="height: 52vh;">
          <div class="w3-encabezado-table w3-scroll" >
            <div class="w3-table-all">
              <table mat-table [dataSource]="dataMunicipio" class=" w3-table" >
                  <!-- Position Column -->
                  <ng-container matColumnDef="municipio">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Municipio </th>
                  <td mat-cell style="width: 50%;" *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>              
                  <!-- Name Column -->
                  <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Cantidad  </th>
                  <td mat-cell style="width: 25%;" *matCellDef="let element"> {{formatearnormal(element.value)}} </td>
                  </ng-container>   
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="porcentaje">
                    <th mat-header-cell *matHeaderCellDef class="w3-elemento-titulo-table "> Porcentaje  </th>
                    <td mat-cell style="width: 25%;" *matCellDef="let element"> {{porcentaje(element.value,'C6')}}% </td>
                    </ng-container>  
                  <tr mat-header-row *matHeaderRowDef="columnasC6; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnasC6;"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- The Modal -->
