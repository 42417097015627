import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../../environments/environment';
import {EsqueletoPeticionModel} from './../../model/esqueletoPeticion.model';
import {FiltrosService} from './../filtros/filtros.service';
import {EvaluacionEntity, CuentaEntity,VariableEntity, VarTempEntity, IndicadorEntity, Detalle_Evaluacion_IndicadorEntity, Detalle_Evaluacion_CuentaEntity} from '../../entities/base/AlertaTemprana';

@Injectable({
  providedIn: 'root'
})
export class RazonesService {
  private apiURL = environment.apiDevURL_Evaluacion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  
  constructor(private http: HttpClient, private filtrosService: FiltrosService) { }

  public getEvaluacion(evaluacionEntity): Observable<any> {
    const body = JSON.stringify(evaluacionEntity);
    /*this.apiURL = this.apiURL+""+this.getParamsEvaluacion(evaluacionEntity);
    console.log(this.apiURL)*/
    this.apiURL = '';
    this.apiURL = environment.apiDevURL_Evaluacion;
    this.apiURL = this.apiURL+""+this.getParamsEvaluacion(evaluacionEntity);
    return this.http.get(`${this.apiURL}`, {headers: this.headers});
  }
  
  public getCuenta(cuentaEntity): Observable<any> {
    const body = JSON.stringify(cuentaEntity);
    this.apiURL = '';
    this.apiURL = environment.apiDevURL_Cuenta;
    this.apiURL = this.apiURL+""+this.getParamsCuenta(cuentaEntity);
    return this.http.get(`${this.apiURL}`, {headers: this.headers});
  }

  public getVariable(variableEntity): Observable<any> {
    const body = JSON.stringify(variableEntity);
    this.apiURL = environment.apiDevURL_Variable;
    this.apiURL = this.apiURL+""+this.getParamsVariable(variableEntity);
    return this.http.get(`${this.apiURL}`, {headers: this.headers});
  }

  public getVariableTemporal(variableTemporalEntity): Observable<any> {
    const body = JSON.stringify(variableTemporalEntity);
    this.apiURL = environment.apiDevURL_VariableTemporal;
    this.apiURL = this.apiURL+""+this.getParamsVarTemporal(variableTemporalEntity);
    return this.http.get(`${this.apiURL}`, {headers: this.headers});
  }
  public getIndicador(indicadorEntity): Observable<any> {
    const body = JSON.stringify(indicadorEntity);
    this.apiURL = environment.apiDevURL_Indicador;
    this.apiURL = this.apiURL+""+this.getParamsIndicador(indicadorEntity);
    return this.http.get(`${this.apiURL}`, {headers: this.headers});
  }
  public getDetalleEvaluacionIndicador(detalleevaluacionindicadorEntity): Observable<any> {
    const body = JSON.stringify(detalleevaluacionindicadorEntity);
    this.apiURL = environment.apiDevURL_DetalleEvaluacionIndicador;
    this.apiURL = this.apiURL+""+this.getParamsDetalleEvaluacionIndicador(detalleevaluacionindicadorEntity);
    return this.http.get(`${this.apiURL}`, {headers: this.headers});
  }
  public getDetalleEvaluacionCuenta(detalleevaluacioncuentaEntity): Observable<any> {
    const body = JSON.stringify(detalleevaluacioncuentaEntity);
    this.apiURL = environment.apiDevURL_DetalleEvaluacionCuenta;
    this.apiURL = this.apiURL+""+this.getParamsDetalleEvaluacionCuenta(detalleevaluacioncuentaEntity);
    return this.http.get(`${this.apiURL}`, {headers: this.headers});
  }
  

  getParamsEvaluacion(entity: EvaluacionEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    
    if (null != entity.Id_Evaluacion) { params = params.set('Id_Evaluacion', entity.Id_Evaluacion.toString()); }
    if (null != entity.Id_Institucion) { params = params.set('Id_Institucion', entity.Id_Institucion.toString()); }
    if (null != entity.Id_Usuario) { params = params.set('Id_Usuario', entity.Id_Usuario.toString()); }
    if (null != entity.Fecha_Evaluacion) { params = params.set('Fecha_Evaluacion', entity.Fecha_Evaluacion.toString()); }
    if (null != entity.Fecha_Contable) { params = params.set('Fecha_Contable', entity.Fecha_Contable.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Observacion) { params = params.set('Observacion', entity.Observacion.toString()); }
    if (null != entity.Id_Tipo_Evaluacion) { params = params.set('Id_Tipo_Evaluacion', entity.Id_Tipo_Evaluacion.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }
    if (null != entity.Id_Tipo_Institucion) { params = params.set('Id_Tipo_Institucion', entity.Id_Tipo_Institucion.toString()); }
    if (null != entity.Estado) { params = params.set('Estado', entity.Estado.toString()); }
    if (null != entity.VistoBueno) { params = params.set('VistoBueno', entity.VistoBueno.toString()); }
    if (null != entity.check) { params = params.set('check', entity.check.toString()); }

    
    return params;
  }
  getParamsCuenta(entity: CuentaEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_Cuenta) { params = params.set('Id_Cuenta', entity.Id_Cuenta.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Tipo) { params = params.set('Tipo', entity.Tipo.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }

    
    return params;
  }
  getParamsVariable(entity: VariableEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_Variable) { params = params.set('Id_Variable', entity.Id_Variable.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Id_Tipo_Variable) { params = params.set('Id_Tipo_Variable', entity.Id_Tipo_Variable.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }
    if (null != entity.Id_Tipo_Cooperativa) { params = params.set('Id_Tipo_Cooperativa', entity.Id_Tipo_Cooperativa.toString()); }
    
    return params;
  }
  getParamsVarTemporal(entity: VarTempEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_VarTemp) { params = params.set('Id_VarTemp', entity.Id_VarTemp.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Formula) { params = params.set('Formula', entity.Formula.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.Id_Pais) { params = params.set('Id_Pais', entity.Id_Pais.toString()); }
    
    
    return params;
  }
  getParamsIndicador(entity: IndicadorEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    
    if (null != entity.Id_Indicador) { params = params.set('Id_Indicador', entity.Id_Indicador.toString()); }
    if (null != entity.Descripcion) { params = params.set('Descripcion', entity.Descripcion.toString()); }
    if (null != entity.Nivel1) { params = params.set('Nivel1', entity.Nivel1.toString()); }
    if (null != entity.Nivel2) { params = params.set('Nivel2', entity.Nivel2.toString()); }
    if (null != entity.Nivel3) { params = params.set('Nivel3', entity.Nivel3.toString()); }
    if (null != entity.Nivel4) { params = params.set('Nivel4', entity.Nivel4.toString()); }
    if (null != entity.Nivel) { params = params.set('Nivel', entity.Nivel.toString()); }
    if (null != entity.R0_Usar) { params = params.set('R0_Usar', entity.R0_Usar.toString()); }
    if (null != entity.Id_Plantilla) { params = params.set('Id_Plantilla', entity.Id_Plantilla.toString()); }
    if (null != entity.R0_Oplogico1) { params = params.set('R0_Oplogico1', entity.R0_Oplogico1.toString()); }
    if (null != entity.R0_Opcomparacion2) { params = params.set('R0_Opcomparacion2', entity.R0_Opcomparacion2.toString()); }
    if (null != entity.R0_Valor2) { params = params.set('R0_Valor2', entity.R0_Valor2.toString()); }
    if (null != entity.R1_Usar) { params = params.set('R1_Usar', entity.R1_Usar.toString()); }
    if (null != entity.R1_Opcomparacion1) { params = params.set('R1_Opcomparacion1', entity.R1_Opcomparacion1.toString()); }
    if (null != entity.R1_Valor1) { params = params.set('R1_Valor1', entity.R1_Valor1.toString()); }
    if (null != entity.R1_Oplogico1) { params = params.set('R1_Oplogico1', entity.R1_Oplogico1.toString()); }
    if (null != entity.R1_Opcomparacion2) { params = params.set('R1_Opcomparacion2', entity.R1_Opcomparacion2.toString()); }
    if (null != entity.R1_Valor2) { params = params.set('R1_Valor2', entity.R1_Valor2.toString()); }
    if (null != entity.R2_Usar) { params = params.set('R2_Usar', entity.R2_Usar.toString()); }
    if (null != entity.R2_Opcomparacion1) { params = params.set('R2_Opcomparacion1', entity.R2_Opcomparacion1.toString()); }
    if (null != entity.R2_Valor1) { params = params.set('R2_Valor1', entity.R2_Valor1.toString()); }
    if (null != entity.R2_Oplogico1) { params = params.set('R2_Oplogico1', entity.R2_Oplogico1.toString()); }
    if (null != entity.R2_Opcomparacion2) { params = params.set('R2_Opcomparacion2', entity.R2_Opcomparacion2.toString()); }
    if (null != entity.R2_Valor2) { params = params.set('R2_Valor2', entity.R2_Valor2.toString()); }
    if (null != entity.R2_Variable1) { params = params.set('R2_Variable1', entity.R2_Variable1.toString()); }
    if (null != entity.R3_Usar) { params = params.set('R3_Usar', entity.R3_Usar.toString()); }
    if (null != entity.R4_Usar) { params = params.set('R4_Usar', entity.R4_Usar.toString()); }
    if (null != entity.R5_Usar) { params = params.set('R5_Usar', entity.R5_Usar.toString()); }
    if (null != entity.R5_Opcomparacion1) { params = params.set('R5_Opcomparacion1', entity.R5_Opcomparacion1.toString()); }
    if (null != entity.R5_Valor1) { params = params.set('R5_Valor1', entity.R5_Valor1.toString()); }
    if (null != entity.R5_Oplogico1) { params = params.set('R5_Oplogico1', entity.R5_Oplogico1.toString()); }
    if (null != entity.R5_Opcomparacion2) { params = params.set('R5_Opcomparacion2', entity.R5_Opcomparacion2.toString()); }
    if (null != entity.R5_Valor2) { params = params.set('R5_Valor2', entity.R5_Valor2.toString()); }
        
    return params;
  }
  getParamsDetalleEvaluacionIndicador(entity: Detalle_Evaluacion_IndicadorEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    
    if (null != entity.Id_Evaluacion) { params = params.set('Id_Evaluacion', entity.Id_Evaluacion.toString()); }
    if (null != entity.Id_Indicador) { params = params.set('Id_Indicador', entity.Id_Indicador.toString()); }
    if (null != entity.Valor) { params = params.set('Valor', entity.Valor.toString()); }
    if (null != entity.Respuesta) { params = params.set('Respuesta', entity.Respuesta.toString()); }
    if (null != entity.EstadoSemaforo) { params = params.set('EstadoSemaforo', entity.EstadoSemaforo.toString()); }
    if (null != entity.ResultadoIndicador) { params = params.set('ResultadoIndicador', entity.ResultadoIndicador.toString()); }
    if (null != entity.Rojos) { params = params.set('Rojos', entity.Rojos.toString()); }
    if (null != entity.Amarillos) { params = params.set('Amarillos', entity.Amarillos.toString()); }
    if (null != entity.Verdes) { params = params.set('Verdes', entity.Verdes.toString()); }
    if (null != entity.Nota) { params = params.set('Nota', entity.Nota.toString()); }
    if (null != entity.TextoSemaforo) { params = params.set('TextoSemaforo', entity.TextoSemaforo.toString()); }
    if (null != entity.ArregloID_evaluacion) { params = params.set('Id_Evaluacion', entity.ArregloID_evaluacion.toString()); }

    
    return params;
  }
  getParamsDetalleEvaluacionCuenta(entity: Detalle_Evaluacion_CuentaEntity): HttpParams {
    let params: HttpParams = new HttpParams();
    if (null == entity) { return params; }
    if (null != entity.Id_Evaluacion) { params = params.set('Id_Evaluacion', entity.Id_Evaluacion.toString()); }
    if (null != entity.Id_Cuenta) { params = params.set('Id_Cuenta', entity.Id_Cuenta.toString()); }
    if (null != entity.Valor) { params = params.set('Valor', entity.Valor.toString()); }
    if (null != entity.ArregloID_evaluacion) { params = params.set('Id_Evaluacion', entity.ArregloID_evaluacion.toString()); }
    
    return params;
  }

}

