import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {MessageService} from './../base/message.service'

import {Service} from './../base/service'
import {Loggers} from './../../util/app-util'
import { mayoresDeudoresEntity } from './../../modules/riesgos/entities_riesgos/entities_riesgos';

@Injectable({
  providedIn: 'root'
})
export class mayoresdeudoresService extends Service<mayoresDeudoresEntity>{

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    messageService: MessageService,
    protected logger: Loggers,
  ) { 
    super('mayoresdeudores','',http, messageService, logger);
    this.httpClient = http;
  }
  protected getParams(entity: mayoresDeudoresEntity):HttpParams{
    let params: HttpParams = new HttpParams();
    if (null == entity){return params;}
    if (null != entity.Id_Institucion){params = params.set('Id_Institucion', entity.Id_Institucion.toString())}
    if (null != entity.Periodo){params = params.set('Periodo', entity.Periodo.toString())}
    if (null != entity.NDatos){params = params.set('NDatos', entity.NDatos.toString())}
    if (null != entity.BnAgruparDatos){params = params.set('BnAgruparDatos', entity.BnAgruparDatos.toString())}
    if (null != entity.BnConIntereses){params = params.set('BnConIntereses', entity.BnConIntereses.toString())}
    return params;
  }
}
