import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from '../services/authentication/authentication.service';
import { DataService } from '../services/data-service/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild { // , CanLoad {

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authenticationService.isLoggedIn()) {

      return true;

    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log(`AuthGuard --canActivateChild-- called URL:[${state.url}]`);
    if (this.authenticationService.isLoggedIn()) {

      return true;

    } else {

      this.router.navigate(['login']);
      return false;

    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<boolean> => {
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(false);
    };
  }
}
