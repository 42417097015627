import {
  Component,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  HostListener,
  OnInit,
  OnChanges,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import {
  NgxChartsModule,
  BaseChartComponent,
  LineComponent,
  LineSeriesComponent,
  calculateViewDimensions,
  ViewDimensions,
  ColorHelper
} from '@swimlane/ngx-charts';
import { area, line, curveLinear } from 'd3-shape';
import { scaleBand, scaleLinear, scalePoint, scaleTime } from 'd3-scale';

@Component({
  selector: 'group-bar',
  template: `
    <ngx-charts-chart
      [view]="[width, height]"
      [showLegend]="legend"
      [legendOptions]="legendOptions"
      [activeEntries]="activeEntries"
      [animations]="animations"
      (legendLabelActivate)="onActivate($event, undefined, true)"
      (legendLabelDeactivate)="onDeactivate($event, undefined, true)"
      (legendLabelClick)="onClickLegend($event)"
    >
      <svg:g [attr.transform]="transform" class="bar-chart chart">
        <svg:g
          ngx-charts-x-axis
          *ngIf="xAxis"
          [xScale]="xScale"
          [dims]="dims"
          [showLabel]="showXAxisLabel"
          [labelText]="xAxisLabel"
          [trimTicks]="trimXAxisTicks"
          [rotateTicks]="rotateXAxisTicks"
          [maxTickLength]="maxXAxisTickLength"
          [tickFormatting]="xAxisTickFormatting"
          [ticks]="xAxisTicks"
          [xAxisOffset]="dataLabelMaxHeight.negative"
          (dimensionsChanged)="updateXAxisHeight($event)"
        ></svg:g>
        <svg:g
          ngx-charts-y-axis
          *ngIf="yAxis"
          [yScale]="yScale"
          [dims]="dims"
          [showGridLines]="showGridLines"
          [showLabel]="showYAxisLabel"
          [labelText]="yAxisLabel"
          [trimTicks]="trimYAxisTicks"
          [maxTickLength]="maxYAxisTickLength"
          [tickFormatting]="yAxisTickFormatting"
          [ticks]="yAxisTicks"
          (dimensionsChanged)="updateYAxisWidth($event)"
        ></svg:g>
        <svg:g
          *ngFor="let group of results; let index = index; trackBy: trackBy"
          [attr.transform]="groupTransform(group)"
        >
          <svg:g
            ngx-charts-series-vertical
            type="stacked"
            [xScale]="xScale"
            [yScale]="yScale"
            [activeEntries]="activeEntries"
            [colors]="colors"
            [series]="group.series"
            [dims]="dims"
            [gradient]="gradient"
            [tooltipDisabled]="tooltipDisabled"
            [tooltipTemplate]="tooltipTemplate"
            [showDataLabel]="showDataLabel"
            [dataLabelFormatting]="dataLabelFormatting"
            [seriesName]="group.name"
            [animations]="animations"
            [noBarWhenZero]="noBarWhenZero"
            (select)="onClick($event, group)"
            (activate)="onActivate($event, group)"
            (deactivate)="onDeactivate($event, group)"
            (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"
          />
        </svg:g>
      </svg:g>
    </ngx-charts-chart>
  `,
  styleUrls: ['./group-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})



export class GroupBarComponent extends BaseChartComponent {
  @Input() legend = false;
  @Input() legendTitle: string = 'Legend';
  @Input() legendPosition: string = 'right';
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() showRightYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() yAxisLabelRight;
  @Input() tooltipDisabled: boolean = false;
  @Input() tooltipDisabledFijo: boolean = false;
  @Input() gradient: boolean;
  @Input() showGridLines: boolean = true;
  @Input() activeEntries: any[] = [];
  @Input() schemeType: string;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() yRightAxisTickFormatting: any;
  @Input() roundDomains: boolean = false;
  @Input() colorSchemeLine: any[];
  @Input() autoScale;
  @Input() lineChart: any;
  @Input() yLeftAxisScaleFactor: any;
  @Input() yRightAxisScaleFactor: any;
  @Input() rangeFillOpacity: number;
  @Input() animations: boolean = true;
  @Input() referenceLines: any[];
  @Input() showRefLines: boolean = false;
  @Input() noBarWhenZero: boolean = true;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate', { static: true }) tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate', { static: true }) seriesTooltipTemplate: TemplateRef<any>;


  dims: ViewDimensions;
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  colorsCombo: ColorHelper;
  margin: any[] = [20, 80, 10, 40];
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  legendOptions: any;
  scaleType = 'linear';
  xScaleLine;
  yScaleLine;
  xDomainLine;
  yDomainLine;
  seriesDomain;
  scaledAxis;
  combinedSeries;
  xSet;
  filteredDomain;
  hoveredVertical;
  yOrientLeft = 'left';
  yOrientRight = 'right';
  legendSpacing = 0;
  bandwidth;
  barPadding = 8;
  data: any [] = [];
  group: any [] = [];
  dataLabelMaxHeight = { negative: 0, positive: 0 };
  showDataLabel: boolean = false;
  groupDomain: any [];
  innerDomain: any [] = [];
  valueDomain: any [];
  yScaleMax: any;
  customColors: any[];

  update() {
    super.update();
    if (!this.showDataLabel) {
        this.dataLabelMaxHeight = { negative: 0, positive: 0 };
    }
    this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];
    this.dims = calculateViewDimensions({
        width: this.width,
        height: this.height,
        margins: this.margin,
        showXAxis: this.xAxis,
        showYAxis: this.yAxis,
        xAxisHeight: this.xAxisHeight,
        yAxisWidth: this.yAxisWidth,
        showXLabel: this.showXAxisLabel,
        showYLabel: this.showYAxisLabel,
        showLegend: this.legend,
        legendType: this.schemeType,
        legendPosition: this.legendPosition
    });
    if (this.showDataLabel) {
        this.dims.height -= this.dataLabelMaxHeight.negative;
    }
    this.formatDates();
    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valueDomain = this.getValueDomain();
    this.xScale = this.getXScale();
    this.yScale = this.getYScale();
    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
}
getGroupDomain() {
    const domain = [];
    for (const group of this.results) {
        if (!domain.includes(group.label)) {
            domain.push(group.label);
        }
    }
    return domain;
}
getInnerDomain() {
    const domain = [];
    for (const group of this.results) {
        for (const d of group.series) {
            if (!domain.includes(d.label)) {
                domain.push(d.label);
            }
        }
    }
    return domain;
}
getValueDomain() {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    for (const group of this.results) {
        let smallestSum = 0;
        let biggestSum = 0;
        for (const d of group.series) {
            if (d.value < 0) {
                smallestSum += d.value;
            }
            else {
                biggestSum += d.value;
            }
            smallest = d.value < smallest ? d.value : smallest;
            biggest = d.value > biggest ? d.value : biggest;
        }
        domain.push(smallestSum);
        domain.push(biggestSum);
    }
    domain.push(smallest);
    domain.push(biggest);
    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
}
getXScale() {
    const spacing = this.groupDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().rangeRound([0, this.dims.width]).paddingInner(spacing).domain(this.groupDomain);
}
getYScale() {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
}
onDataLabelMaxHeightChanged(event, groupIndex) {
    if (event.size.negative) {
        this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    }
    else {
        this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if (groupIndex === this.results.length - 1) {
        setTimeout(() => this.update());
    }
}
groupTransform(group) {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
}
onClick(data, group) {
    if (group) {
        data.series = group.name;
    }
    this.select.emit(data);
}
onClickLegend(event){
  console.log("Event ---> " + JSON.stringify(event));
}
trackBy(index, item) {
    return item.name;
}
setColors() {
    let domain;
    if (this.schemeType === 'ordinal') {
        domain = this.innerDomain;
    }
    else {
        domain = this.valueDomain;
    }
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
}
getLegendOptions() {
    const opts = {
        scaleType: this.schemeType,
        colors: undefined,
        domain: [],
        title: undefined,
        position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
        opts.domain = this.innerDomain;
        opts.colors = this.colors;
        opts.title = this.legendTitle;
    }
    else {
        opts.domain = this.valueDomain;
        opts.colors = this.colors.scale;
    }
    return opts;
}
updateYAxisWidth({ width }) {
    this.yAxisWidth = width;
    this.update();
}
updateXAxisHeight({ height }) {
    this.xAxisHeight = height;
    this.update();
}
onActivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
        item.series = group.name;
    }
    const items = this.results
        .map(g => g.series)
        .flat()
        .filter(i => {
        if (fromLegend) {
            return i.label === item.name;
        }
        else {
            return i.name === item.name && i.series === item.series;
        }
    });
    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
}
onDeactivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
        item.series = group.name;
    }
    this.activeEntries = this.activeEntries.filter(i => {
        if (fromLegend) {
            return i.label !== item.name;
        }
        else {
            return !(i.name === item.name && i.series === item.series);
        }
    });
    this.deactivate.emit({ value: item, entries: this.activeEntries });
}
}
