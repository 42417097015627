<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <!-- TITULO DEL COMPONENTE -->
    <div class="w3-quarter padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> {{ dataService.currentModule }} </b></span> <!--  -->
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span> <!--  -->
      </div>
    </div>
    <!-- FIN TITULO DEL COMPONENTE -->

    <!-- FILTROS-->
    <div class="w3-rest">
      <!-- DEFINICION FILTROS -->
      <div class="w3-noventa2 w3-row ">
        <!-- AÑOS -->
        <div class="w3-third padreizquierda">
          <!-- FILTRO AÑOS DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Año
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>

            <mat-menu class="w3-medium"  #menuanios="matMenu" >
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                (change)="anioChangeListener(anio)">
                    {{anio.value}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO AÑOS DISPONIBLES -->

          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row hijoizquierda chips">
                <div [ngClass]="anios.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let anios of aniosSeleccionados"
                            class="filtroMesAnioSeleccionado">
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>
        <!-- FIN AÑOS -->

        <!-- MESES -->
        <div class="w3-third">
          <!-- FILTRO MESES DISPONIBLES -->
          <div class="w3-quarter_e hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              Mes
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menumeses="matMenu" style="height: 100px;">
              <ng-container *ngFor="let mes of mesesDeAnioDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="mes.check" name="{{mes.name}}"
                                (click)="$event.stopPropagation();"
                                value="{{mes.name}}"
                                (change)="mesChangeListener(mes, mesesDeAnioDisponibles)">
                    {{mes.name}}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN FILTRO MESES DISPONIBLES -->

          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnmeses">
              <div class="w3-row hijoizquierda chips" >
                <div [ngClass]="meses.check?
                        'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                        *ngFor="let meses of mesesSeleccionados"
                        class="filtroMesAnioSeleccionado">
                        <span class="contenidoChips">{{meses.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        <!-- FIN MESES -->

        <!-- consolidado sucursal -->
        <div class="w3-third">
          <div class="w3-half hijoizquierda  ">
            <button class="w3-button w3-right "  [matMenuTriggerFor]="menuentidad">
              <span>Entidad</span>
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium"  #menuentidad="matMenu" >
              <button mat-menu-item>
                <mat-checkbox name="consolidado"
                              [checked]="consolidado"
                              (click)="$event.stopPropagation();"
                              (change)="consolidadoChangeListener()"
                              value="consolidado" aria-label="First checkbox"> <!---->
                  Consolidado
                </mat-checkbox>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="sucursales">Sucursales</button>
            </mat-menu>
            <mat-menu #sucursales="matMenu">
              <ng-container>
                <mat-form-field style="margin: 1vh; max-width: 15vh;" (click)="$event.stopPropagation();">
                  <mat-label>Buscar</mat-label>
                  <mat-icon matSuffix style="color: gray;">search</mat-icon>
                  <input matInput (keyup)="applyFilter($event)" type="text" autocomplete="off">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="sucursalesDisponibles2 !== undefined">
                <ng-container *ngFor="let sucursal of sucursalesDisponibles2.filteredData">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="sucursal.check"
                      (change)="sucursalChangeListener(sucursal)"
                      (click)="$event.stopPropagation();">
                      {{sucursal.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <!-- MES SELECCIONADO -->
          <div class="w3-rest hijoizquierda" style="height: 4vh;">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnmeses">
              <div class="w3-row hijoizquierda chips" >
                <div class="w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu">
                  <span class="contenidoChips">{{sucursal_seleccionada}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
      </div>
      <!-- FIN DEFINICION FILTROS -->

      <!-- BORRAR FILTROS -->
      <div class="w3-rest padreizquierda w3-right ">
        <div class="w3-row hijoizquierda">
          <button mat-icon-button (click)="borrarFil()"> <!--(click)="quitarFiltros()"-->
            <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
              <img style="margin-top: -10px;" src="assets/img/graficos/eraser.png">
            </mat-icon>
          </button>
        </div>
      </div>
      <!-- FIN BORRAR FILTROS -->
    </div>
    <!-- FIN FILTROS-->
  </div>
</div>

<!--Cuando la tabla esta visible-->
<div id="tabla" class="w3-row w3-container w3-contenedor-template" *ngIf="graficatabla" style="height: 85vh;">
  <div class="w3-threequarter">
    <div class="w3-row w3-white" style="width: 99%; height: 84vh;">
      <!--Parte superior-->
      <div class="w3-row">
        <!--Titulo-->
        <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido w3-tituloZonas">Metas vs real</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter w3-tituloZonas">adultos y menores</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
          </div>
        </div>
        <!--Menú-->
        <div class="w3-rest w3-right-align_rest">
          <div class="w3-rest hijoderechaTitle w3-tiny ">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="Ampliar(0)">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu">
              more_vert
            </mat-icon>
            <mat-menu #menu="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficas">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span>Gráficas</span>
              </button>
              <mat-divider></mat-divider>
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportar">
                <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                <span>Exportar como</span>
              </button>
            </mat-menu>
            <mat-menu #exportar="matMenu">
              <button mat-menu-item (click)="export_excel()">
                <span>CSV</span>
              </button>
            </mat-menu>
            <mat-menu #graficas="matMenu">
              <button mat-menu-item (click)="cambioGraf('barrasv')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span>Barras verticales</span>
              </button>
              <!--<button mat-menu-item (click)="cambioGraf('barrash')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span>Barras horizontales</span>
              </button>-->
              <button mat-menu-item (click)="cambioGraf('lineal')">
                <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                <span>Lineal</span>
              </button>
              <button mat-menu-item (click)="cambioGraf('area')">
                <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                <span>Área</span>
              </button>
              <button mat-menu-item (click)="cambioGraf('tabla')">
                <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                <span>tabla</span>
              </button>
            </mat-menu>
            <mat-menu #ampliar="matMenu">
              <button mat-menu-item (click)="Ampliar(1)">
                <mat-icon [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                <span>Parte de arriba</span>
              </button>
              <button mat-menu-item (click)="Ampliar(2)">
                <mat-icon [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                <span>Parte de abajo</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <!--Fin de la parte superior-->
      
      <!--Tabla-->
      <div class="contenedorCuadranteGraficas" style="height: 90%;">
        <div class="w3-row" style="height: 78vh; width: 99%;">
          <div class="w3-encabezado-table w3-scroll" >  
            <div class="w3-table-all w3-white">
              <div class="w3-row w3-white">
                <table mat-table class=" w3-table w3-white">
                  <!-- Column -->
                  <ng-container matColumnDef="sucursal">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">  </th>
                  </ng-container>              
                  <!-- Column -->
                  <ng-container matColumnDef="proyectado">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="width: 60%; font-weight: bold;"> {{mesTabla(ult_mes[0])}}  </th>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="real">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">   </th>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columsName3; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columsName3;"></tr>
                </table>
                <table mat-table class=" w3-table w3-white">
                  <!-- Column -->
                  <ng-container matColumnDef="sucursal">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">  </th>
                  </ng-container>              
                  <!-- Column -->
                  <ng-container matColumnDef="proyectado">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="width: 30%; font-weight: bold;"> ADULTO  </th>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="real">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="width: 30%; font-weight: bold;"> MENORES  </th>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="logrado">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;"> META ANUAL  </th>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columsName2; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columsName2;"></tr>
                </table>
                <table mat-table [dataSource]="dataTabla || sortData($event)" matSort (matSortChange)="sortData($event)" class="w3-table-all">
                  <!-- Column -->
                  <ng-container matColumnDef="sucursal" sticky>
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 60%;">SUCURSAL</span> </th>
                  <td mat-cell class="w3-center " style="width: 20%;" *matCellDef="let element, let i = index" [style]="textoC1(i)"> {{element.sucursal}} </td>
                  </ng-container>              
                  <!-- Column -->
                  <ng-container matColumnDef="proyectado">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">PROYECTADO</span> </th>
                  <td mat-cell class=" w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC2C5(i)"> {{formatearnormal(element.proyectado1)}} </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="real">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">REAL</span> </th>
                  <td mat-cell class=" w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC3C6(i)"> {{formatearnormal(element.real1)}} </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="logrado">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">LOGRADO</span> </th>
                  <td mat-cell [class]="pintar_borde(element.logrado1)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC1(i)"> {{formatearnormal(element.logrado1)}}% </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="proyectado1">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">PROYECTADO</span> </th>
                  <td mat-cell class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC2C5(i)"> {{formatearnormal(element.proyectado2)}} </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="real1">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">REAL</span> </th>
                  <td mat-cell class=" w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC3C6(i)"> {{formatearnormal(element.real2)}}  </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="logrado1">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">LOGRADO</span> </th>
                  <td mat-cell [class]="pintar_borde(element.logrado2)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC1(i)"> {{formatearnormal(element.logrado2)}}% </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="adulto">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">ADULTO</span> </th>
                  <td mat-cell [class]="pintar_borde(element.adulto)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC1(i)"> {{formatearnormal(element.adulto)}}% </td>
                  </ng-container>
                  <!-- Column -->
                  <ng-container matColumnDef="menor">
                  <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> <span mat-sort-header class="w3-right" style="width: 80%;">MENOR</span> </th>
                  <td mat-cell [class]="pintar_borde(element.menor)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="textoC1(i)"> {{formatearnormal(element.menor)}}% </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columsName; sticky: true" class="w3-elemento-titulo-table"></tr>
                  <tr mat-row *matRowDef="let row; columns: columsName;"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w3-rest">
    <div class="w3-row w3-right" style="width: 99%; height: 84vh;">
      <div class="w3-row" style="height: 5vh;"></div>


      <div style="height: 1vh;"></div>
        
      <div class="w3-row w3-white w3-margin-top" style="height: 16vh;">
        <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
          <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Cumplimiento adulto </span>
        </div>
        <div class="w3-row" style="margin-top: 2vh; margin-right: 1vh;">
          <div class="w3-half w3-right">
            <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">show_chart</mat-icon> 
            <span *ngIf="dataTabla.length !== 0" class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].logrado1)}} %</span>
          </div>
        </div>
        <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
          <span class="w3-subtituloMeta">{{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
        </div>
      </div>

      <div style="height: 1vh;"></div>
        
      <div class="w3-row w3-white w3-margin-top" style="height: 16vh;">
        <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
          <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Cumplimiento menor </span>
        </div>
        <div class="w3-row" style="margin-top: 2vh; margin-right: 1vh;">
          <div class="w3-half w3-right">
            <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">timeline</mat-icon> 
            <span *ngIf="dataTabla.length !== 0" class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].logrado2)}} %</span>
          </div>
        </div>
        <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
          <span class="w3-subtituloMeta">{{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
        </div>
      </div>

      <div style="height: 1vh;"></div>
        
      <div class="w3-row w3-white w3-margin-top" style="height: 16vh;">
        <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
          <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Meta anual adultos </span>
        </div>
        <div class="w3-row" style="margin-top: 2vh; margin-right: 1vh;">
          <div class="w3-half w3-right">
            <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">face_6</mat-icon> 
            <span *ngIf="dataTabla.length !== 0" class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].adulto)}} %</span>
          </div>
        </div>
        <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
          <span class="w3-subtituloMeta">ene - {{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
        </div>
      </div>

      <div style="height: 1vh;"></div>
        
      <div class="w3-row w3-white w3-margin-top" style="height: 16vh;">
        <div class="w3-row w3-margin-left" style="margin-top: 2vh;">
          <span class="w3-total-TextoMetaVsReal" style="color: #8B8E93;">Meta anual menores </span>
        </div>
        <div class="w3-row" style="margin-top: 2vh; margin-right: 1vh;">
          <div class="w3-half w3-right">
            <mat-icon [ngStyle]="{'color':'#0075C9'}" class="material-icons-outlined w3-icon-display iconos" style="margin-right: 1.5vh;">face</mat-icon> 
            <span *ngIf="dataTabla.length !== 0" class="w3-total-cantidadMetaVsReal w3-balance-bluep">{{formatearnormal(dataTabla[dataTabla.length-1].menor)}} %</span>
          </div>
        </div>
        <div class="w3-row w3-margin-left" style="margin-top: 1vh;">
          <span class="w3-subtituloMeta">ene - {{formatoMes(ult_mes[0])}} | {{formatoAnio(ult_anio[0])}}</span>
        </div>
      </div>

    </div>
  </div>
</div>

<!--Cuando no esta la tabla visible-->
<div class="w3-row w3-container w3-contenedor-template" *ngIf="!graficatabla" style="height: 85vh;">
  <!--Parte de arriba-->
  <div id="superior" class="w3-row" style="height: 41vh;">
    <div class="w3-row w3-white " style="width: 99%; height: 39vh;">
      <!--Parte superior-->
      <div class="w3-row">
        <!--Titulo-->
        <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido">Cumplimiento metas sucursales</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">adultos</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
          </div>
        </div>
        <!--Menú-->
        <div class="w3-rest w3-right-align_rest">
          <div class="w3-rest hijoderechaTitle w3-tiny ">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="Ampliar(1)">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC1">
              more_vert
            </mat-icon>
            <mat-menu #menuC1="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC1">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span>Gráficas</span>
              </button>
              <mat-divider></mat-divider>
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC1">
                <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                <span>Exportar como</span>
              </button>
            </mat-menu>
            <mat-menu #exportarC1="matMenu">
              <button mat-menu-item (click)="export_imagen(1)">
                <span>PNG</span>
              </button>
            </mat-menu>
            <mat-menu #graficasC1="matMenu">
              <button mat-menu-item (click)="cambioGraf('barrasvC1')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span>Barras verticales</span>
              </button>
              <!--<button mat-menu-item (click)="cambioGraf('barrashC1')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span>Barras horizontales</span>
              </button>-->
              <button mat-menu-item (click)="cambioGraf('linealC1')">
                <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                <span>Lineal</span>
              </button>
              <button mat-menu-item (click)="cambioGraf('areaC1')">
                <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                <span>Área</span>
              </button>
              <button mat-menu-item (click)="cambioGraf('tabla')">
                <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                <span>tabla</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <!--Fin de la parte superior-->
      <!--Gráficas-->
      <div class="contenedorCuadranteGraficas" style=" height: 90%;">
        <div class="w3-row"  *ngIf="graficabarrasverC1" style="height: 31vh;">
          <div class="w3-row" style="height: 29vh;">
            <ngx-charts-bar-vertical-2d
            [scheme]="colorSchemeAdultos"
            [results]="databarrasAdulto"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [noBarWhenZero]="false"
            [yAxis]="showYAxis"
            [groupPadding]="groupPadding"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical-2d>
          </div>
        </div>
        <div class="w3-row"  *ngIf="graficabarrashorC1" style="height: 31vh;">
          <ngx-charts-bar-horizontal-2d
          [scheme]="colorSchemeAdultos"
          [results]="databarrasAdulto"
          [xAxis]="showXAxis"
          [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
          [yAxis]="showYAxis"
          [groupPadding]="groupPadding"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-horizontal-2d>
        </div>
        <div class="w3-row"  *ngIf="graficalinealC1" style="height: 31vh;">
          <ngx-charts-line-chart
          [scheme]="colorSchemeAdultos"
          [results]="datalinealAdulto"
          [xAxis]="showXAxis"
          [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
          [yAxis]="showYAxis"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row"  *ngIf="graficaareaC1" style="height: 31vh;">
          <ngx-charts-area-chart
          [scheme]="colorSchemeAdultos"
          [results]="datalinealAdulto"
          [xAxis]="showXAxis"
          [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
          [yAxis]="showYAxis"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-area-chart>
        </div>
      </div>
      <!--Fin de las gráficas-->
    </div>
  </div>
  <!--Fin de la parte de arriba-->

  <!--Parte de abajo-->
  <div id="inferior" class="w3-row" style="height: 41vh;">
    <div class="w3-row w3-white " style="width: 99%; height: 39vh;">
      <!--Parte superior-->
      <div class="w3-row">
        <!--Titulo-->
        <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido">Cumplimiento metas sucursales</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">menores</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
            <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
          </div>
        </div>
        <!--menu--> 
        <div class="w3-rest w3-right-align_rest">
          <div class="w3-rest hijoderechaTitle w3-tiny ">
            <mat-icon class="iconoMen w3-button w3-dropdown-click" style="margin-right: 5px;" [ngStyle]="{'color':'gray'}" (click)="Ampliar(2)">zoom_out_map</mat-icon>
            <mat-icon class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuC2">
              more_vert
            </mat-icon>
            <mat-menu #menuC2="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="graficasC2">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span>Gráficas</span>
              </button>
              <mat-divider></mat-divider>
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" [matMenuTriggerFor]="exportarC2">
                <mat-icon [ngStyle]="{'color':'gray'}" class=" material-icons-outlined">description</mat-icon>
                <span>Exportar como</span>
              </button>
            </mat-menu>
            <mat-menu #exportarC2="matMenu">
              <button mat-menu-item (click)="export_imagen(2)">
                <span>PNG</span>
              </button>
            </mat-menu>
            <mat-menu #graficasC2="matMenu">
              <button mat-menu-item (click)="cambioGraf('barrasvC2')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span>Barras verticales</span>
              </button>
              <!--<button mat-menu-item (click)="cambioGraf('barrashC2')">
                <mat-icon [ngStyle]="{'color':'gray'}">bar_chart</mat-icon>
                <span>Barras horizontales</span>
              </button>-->
              <button mat-menu-item (click)="cambioGraf('linealC2')">
                <mat-icon [ngStyle]="{'color':'gray'}">show_chart</mat-icon>
                <span>Lineal</span>
              </button>
              <button mat-menu-item (click)="cambioGraf('areaC2')">
                <mat-icon [ngStyle]="{'color':'gray'}">area_chart</mat-icon>
                <span>Área</span>
              </button>
              <button mat-menu-item (click)="cambioGraf('tabla')">
                <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                <span>tabla</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <!--Fin de la parte superior-->
      <!--Gráficas-->
      <div class="contenedorCuadranteGraficas" style=" height: 90%;">
        <div class="w3-row" *ngIf="graficabarrasverC2" style="height: 31vh;">
          <ngx-charts-bar-vertical-2d
          [scheme]="colorSchemeMenores"
          [results]="databarrasMenor"
          [xAxis]="showXAxis"
          [legend]="true"
          [legendTitle]="legendTitle"
          [legendPosition]="'right'"
          [noBarWhenZero]="false"
          [yAxis]="showYAxis"
          [groupPadding]="groupPadding"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-vertical-2d>
        </div>
        <div class="w3-row" *ngIf="graficabarrashorC2" style="height: 31vh;">
          <ngx-charts-bar-horizontal-2d
          [scheme]="colorSchemeMenores"
          [results]="databarrasMenor"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [groupPadding]="groupPadding"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-bar-horizontal-2d>
        </div>
        <div class="w3-row"  *ngIf="graficalinealC2" style="height: 31vh;">
          <ngx-charts-line-chart
          [scheme]="colorSchemeMenores"
          [results]="datalinealMenor"
          [xAxis]="showXAxis"
          [legend]="true"
          [legendTitle]="legendTitle"
          [legendPosition]="'right'"
          [yAxis]="showYAxis"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-line-chart>
        </div>
        <div class="w3-row"  *ngIf="graficaareaC2" style="height: 31vh;">
          <ngx-charts-area-chart
          [scheme]="colorSchemeMenores"
          [results]="datalinealMenor"
          [xAxis]="showXAxis"
          [legend]="true"
          [legendTitle]="legendTitle"
          [legendPosition]="'right'"
          [yAxis]="showYAxis"
          [showYAxisLabel]="showYAxisLabel"
          [yAxisLabel]="yAxisLabel">
          </ngx-charts-area-chart>
        </div>
      </div>
      <!--Fin de las gráficas-->
    </div>
  </div>
  <!--Fin de la parte de abajo-->
</div>
<!-- FIN CONTENIDO DEL COMPONENTE -->


<!-- The Modal -->
<div id="ampliarGraficas" class="w3-modal">
  <div class="w3-modal-content" style="width: 90%;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu tooltip bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
              <div class="w3-row" *ngIf="graficasuperior">
                <!--Parte superior-->
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido">Cumplimiento metas sucursales</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">adultos</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                  </div>
                </div>
                <!--Fin de la parte superior-->
              </div>
              <div class="w3-row" *ngIf="graficainferior">
                <!--Parte superior-->
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido">Cumplimiento metas sucursales</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">menores</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                  </div>
                </div>
                <!--Fin de la parte superior-->
              </div>
              <div class="w3-row" *ngIf="graficatabla_modal">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido">Metas vs real</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">adultos y menores</span>
                      <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                      <span class=" w3-margin-left-filter w3-subtitulo">{{subtituloGrafica1}}</span>
                    </div>
                  </div>
                </div>
                <!--Fin de la parte superior-->
              </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="hideModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row" style="height: 60vh;" >
      <div class="w3-row w3-margin" style="height: 58vh;">
        <div class="w3-row" *ngIf="!graficatabla_modal" style="height: 52vh;">
          <div class="w3-row"  *ngIf="graficabarrasverC1_modal" style="height: 50vh;">
            <ngx-charts-bar-vertical-2d
            [scheme]="colorSchemeAdultos"
            [results]="databarrasAdulto"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [groupPadding]="groupPadding"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical-2d>
          </div>
          <div class="w3-row"  *ngIf="graficabarrashorC1_modal" style="height: 50vh;">
            <ngx-charts-bar-horizontal-2d
            [scheme]="colorSchemeAdultos"
            [results]="databarrasAdulto"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [groupPadding]="groupPadding"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-horizontal-2d>
          </div>
          <div class="w3-row"  *ngIf="graficalinealC1_modal" style="height: 50vh;">
            <ngx-charts-line-chart
            [scheme]="colorSchemeAdultos"
            [results]="datalinealAdulto"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
          </div>
          <div class="w3-row"  *ngIf="graficaareaC1_modal" style="height: 50vh;">
            <ngx-charts-area-chart
            [scheme]="colorSchemeAdultos"
            [results]="datalinealAdulto"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-area-chart>
          </div>
          <!--Fin de la parte de arriba-->  
          <!--Parte de abajo-->
          <div class="w3-row" *ngIf="graficabarrasverC2_modal" style="height: 50vh;">
            <ngx-charts-bar-vertical-2d
            [scheme]="colorSchemeMenores"
            [results]="databarrasMenor"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [groupPadding]="groupPadding"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical-2d>
          </div>
          <div class="w3-row" *ngIf="graficabarrashorC2_modal" style="height: 50vh;">
            <ngx-charts-bar-horizontal-2d
            [scheme]="colorSchemeMenores"
            [results]="databarrasMenor"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [groupPadding]="groupPadding"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-horizontal-2d>
          </div>
          <div class="w3-row"  *ngIf="graficalinealC2_modal" style="height: 50vh;">
            <ngx-charts-line-chart
            [scheme]="colorSchemeMenores"
            [results]="datalinealMenor"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
          </div>
          <div class="w3-row"  *ngIf="graficaareaC2_modal" style="height: 50vh;">
            <ngx-charts-area-chart
            [scheme]="colorSchemeMenores"
            [results]="datalinealMenor"
            [xAxis]="showXAxis"
            [legend]="true"
            [legendTitle]="legendTitle"
            [legendPosition]="'right'"
            [yAxis]="showYAxis"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel">
            </ngx-charts-area-chart>
          </div>
        </div>
        <div class="w3-row" *ngIf="graficatabla_modal" style="height: 52vh;">
          <!--Tabla-->
          <div class="contenedorCuadranteGraficas" style="height: 90%;">
            <div class="w3-row" style="height: 52vh; width: 99%;">
              <div class="w3-encabezado-table w3-scroll" >
              <div class="w3-table-all w3-white">
                  <div class="w3-row w3-white">
                    <table mat-table class=" w3-table w3-white">
                      <!-- Column -->
                      <ng-container matColumnDef="sucursal">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">  </th>
                      </ng-container>              
                      <!-- Column -->
                      <ng-container matColumnDef="proyectado">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="width: 60%; font-weight: bold;"> {{mesTabla(ult_mes[0])}}  </th>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="real">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">   </th>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="columsName3; sticky: true" class="w3-elemento-titulo-table"></tr>
                      <tr mat-row *matRowDef="let row; columns: columsName3;"></tr>
                    </table>
                    <table mat-table class=" w3-table w3-white">
                      <!-- Column -->
                      <ng-container matColumnDef="sucursal">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;">  </th>
                      </ng-container>              
                      <!-- Column -->
                      <ng-container matColumnDef="proyectado">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="width: 30%; font-weight: bold;"> ADULTO  </th>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="real">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="width: 30%; font-weight: bold;"> MENORES  </th>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="logrado">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="width: 20%; font-weight: bold;"> META ANUAL  </th>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="columsName2; sticky: true" class="w3-elemento-titulo-table"></tr>
                      <tr mat-row *matRowDef="let row; columns: columsName2;"></tr>
                    </table>
                    <table mat-table [dataSource]="dataTabla" class=" w3-table w3-white">
                      <!-- Column -->
                      <ng-container matColumnDef="sucursal">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-center" style="font-weight: bold;"> SUCURSAL </th>
                      <td mat-cell class="w3-center w3-white" style="width: 20%;" *matCellDef="let element, let i = index"> {{element.sucursal}} </td>
                      </ng-container>              
                      <!-- Column -->
                      <ng-container matColumnDef="proyectado">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> PROYECTADO  </th>
                      <td mat-cell class="w3-light-grey w3-center" style="width: 10%;" *matCellDef="let element, let i = index"> {{formatearnormal(element.proyectado1)}} </td>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="real">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> REAL  </th>
                      <td mat-cell class="w3-grey-per w3-center" style="width: 10%;" *matCellDef="let element, let i = index"> {{formatearnormal(element.real1)}} </td>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="logrado">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> LOGRADO  </th>
                      <td mat-cell [class]="pintar_borde(element.logrado1)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.logrado1)}}% </td>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="proyectado1">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="font-weight: bold;"> PROYECTADO  </th>
                      <td mat-cell class="w3-light-grey w3-center" style="width: 10%;" *matCellDef="let element, let i = index"> {{formatearnormal(element.proyectado2)}} </td>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="real1">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="font-weight: bold;"> REAL  </th>
                      <td mat-cell class="w3-grey-per w3-center" style="width: 10%;" *matCellDef="let element, let i = index"> {{formatearnormal(element.real2)}}  </td>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="logrado1">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-light-grey w3-center" style="font-weight: bold;"> LOGRADO  </th>
                      <td mat-cell [class]="pintar_borde(element.logrado2)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.logrado2)}}% </td>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="adulto">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> ADULTO </th>
                      <td mat-cell [class]="pintar_borde(element.adulto)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.adulto)}}% </td>
                      </ng-container>
                      <!-- Column -->
                      <ng-container matColumnDef="menor">
                      <th mat-header-cell *matHeaderCellDef class="w3-text-black w3-grey-per w3-center" style="font-weight: bold;"> MENOR </th>
                      <td mat-cell [class]="pintar_borde(element.menor)" class="w3-center" style="width: 10%;" *matCellDef="let element, let i = index" [style]="texto(i)"> {{formatearnormal(element.menor)}}% </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="columsName; sticky: true" class="w3-elemento-titulo-table"></tr>
                      <tr mat-row *matRowDef="let row; columns: columsName;"></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- The Modal -->