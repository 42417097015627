import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TendenciaComponent} from './tendencia/tendencia.component';
import {TendenciaV2Component} from './tendencia-v2/tendencia-v2.component';
import {PerfilComponent} from './perfil/perfil.component';
import {MetaVsRealComponent} from './../comercial/meta-vs-real/meta-vs-real.component';
import {WelcomeComponent} from '../../components/welcome/welcome.component';
import {PermisosSubmodulosGuard} from '../../guards/permisos-submodulos/permisos-submodulos.guard';
import {EstimacionesComponent} from './estimaciones/estimaciones.component';

const routes: Routes = [
  {
    path: 'app/cartera',
    component: WelcomeComponent,
    children: [
      { path: 'tendencia', component: TendenciaComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'tendenciav2', component: TendenciaV2Component, canActivate: [PermisosSubmodulosGuard] },
      { path: 'perfil', component: PerfilComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'meta-vs-real', component: MetaVsRealComponent, canActivate: [PermisosSubmodulosGuard] },
      { path: 'estimaciones', component: EstimacionesComponent, canActivate: [PermisosSubmodulosGuard] },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ColocacionRoutingModule { }
