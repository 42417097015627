import { Injectable } from '@angular/core';
import { color } from 'html2canvas/dist/types/css/types/color';
import { Dimension } from '../../../entities/base/DashboardEntities';
import { colorSets,colorSetsUsuario } from './../../../util/color-sets';
export class ConstantesAfiliacion  {
    constructor() {
    }
    public static strPATH = 'app';
    public static strDashboardPATH = 'app/dashboards';
    public static estadoT1C2 = true;
    public static rangoedadT1C2 = false;
    public static municipioT1C2 = false;
    public static localidadT1C2 = false;
    public static estadocivilT1C2 = false;
    public static ocupacionT1C2 = false;

    public static mesperiodoT6C2 = true;
    public static estadoT6C2 = false;
    public static rangoedadT6C2 = false;
    public static municipioT6C2 = false;
    public static localidadT6C2 = false;
    public static estadocivilT6C2 = false;
    public static ocupacionT6C2 = false;
    public static basediasmoraT6C2 = false;
    public static asesorcreditoT6C2 = false;
    public static clasificacioncreditoT6C2 = false;
    public static inicialT6C2 = false;
    
    static getRangoEdadT1C2(valor){
        this.rangoedadT1C2 = valor;
    }
    public static setRangoEdadT1C2(){
        return this.rangoedadT1C2;
    }
    static getMunicipioT1C2(valor){
        this.municipioT1C2 = valor;
    }
    public static setMunicipioT1C2(){
        return this.municipioT1C2;
    }
    static getLocalidadT1C2(valor){
        this.localidadT1C2 = valor;
    }
    public static setLocalidadT1C2(){
        return this.localidadT1C2;
    }
    static getEstadoCivilT1C2(valor){
        this.estadocivilT1C2 = valor;
    }
    public static setEstadoCivilT1C2(){
        return this.estadocivilT1C2;
    }
    static getOcupacionT1C2(valor){
        this.ocupacionT1C2 = valor;
    }
    public static setOcupacionT1C2(){
        return this.ocupacionT1C2;
    }
    public static setEstadoT1C2(){
        return  this.estadoT1C2;
    }
    static getEstadoT1C2(valor){
        this.estadoT1C2 = valor;
    }
    public static reiniciarT1C2(){
        this.getEstadoCivilT1C2(false);
        this.getMunicipioT1C2(false);
        this.getLocalidadT1C2(false);
        this.getRangoEdadT1C2(false);
        this.getEstadoCivilT1C2(false);
        this.getOcupacionT1C2(false);
        this.getEstadoT1C2(false);
    }

    static getRangoEdadT6C2(valor){
        this.rangoedadT6C2 = valor;
    }
    public static setRangoEdadT6C2(){
        return this.rangoedadT6C2;
    }
    static getMunicipioT6C2(valor){
        this.municipioT6C2 = valor;
    }
    public static setMunicipioT6C2(){
        return this.municipioT6C2;
    }
    static getLocalidadT6C2(valor){
        this.localidadT6C2 = valor;
    }
    public static setLocalidadT6C2(){
        return this.localidadT6C2;
    }
    static getEstadoCivilT6C2(valor){
        this.estadocivilT6C2 = valor;
    }
    public static setEstadoCivilT6C2(){
        return this.estadocivilT6C2;
    }
    static getOcupacionT6C2(valor){
        this.ocupacionT6C2 = valor;
    }
    public static setOcupacionT6C2(){
        return this.ocupacionT6C2;
    }
    public static setEstadoT6C2(){
        return  this.estadoT6C2;
    }
    static getEstadoT6C2(valor){
        this.estadoT6C2 = valor;
    }
    public static setBaseDiasMoraT6C2(){
        return  this.basediasmoraT6C2;
    }
    static getBaseDiasMoraT6C2(valor){
        this.basediasmoraT6C2 = valor;
    }
    public static setAsesorCreditoT6C2(){
        return  this.asesorcreditoT6C2;
    }
    static getAsesorCreditoT6C2(valor){
        this.asesorcreditoT6C2 = valor;
    }
    public static setMesPeriodoT6C2(){
        return  this.mesperiodoT6C2;
    }
    static getMesPeriodoT6C2(valor){
        this.mesperiodoT6C2 = valor;
    }
    public static setClasificacionCreditoT6C2(){
        return  this.clasificacioncreditoT6C2;
    }
    static getClasificacionCreditoT6C2(valor){
        this.clasificacioncreditoT6C2 = valor;
    }
    public static setInicialT6C2(){
        return  this.inicialT6C2;
    }
    static getInicialT6C2(valor){
        this.inicialT6C2 = valor;
    }
    public static reiniciarT6C2(){
        this.getEstadoCivilT6C2(false);
        this.getMunicipioT6C2(false);
        this.getLocalidadT6C2(false);
        this.getRangoEdadT6C2(false);
        this.getEstadoCivilT6C2(false);
        this.getOcupacionT6C2(false);
        this.getEstadoT6C2(false);
        this.getMesPeriodoT6C2(false);
        this.getBaseDiasMoraT6C2(false);
        this.getAsesorCreditoT6C2(false);
        this.getClasificacionCreditoT6C2(false);
        this.getInicialT6C2(false);
    }
}

export class CommonVariables {
    static get MesesArr() { return ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']; }

    static get MesesJson() { return [
        { mes: 'ene', value: 1 },
        { mes: 'feb', value: 2 },
        { mes: 'mar', value: 3 },
        { mes: 'abr', value: 4 },
        { mes: 'may', value: 5 },
        { mes: 'jun', value: 6 },
        { mes: 'jul', value: 7 },
        { mes: 'ago', value: 8 },
        { mes: 'sep', value: 9 },
        { mes: 'oct', value: 10 },
        { mes: 'nov', value: 11 },
        { mes: 'dic', value: 12 },
    ]; }
}
export class Elemento{
    name: string;
    value: number;
}

export class FuncionesAfiliacion {
    static separador:string =  ","; // separador para los miles
    static sepDecimal:string = '.'; // separador para los decimales
    static arreglo:string [] = [];
    static resultEncabezados: any [] = [];
    static formatearsaldos(num){
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return  splitLeft +splitRight;
    }
    static formatearnormal(num){
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return  splitLeft +splitRight;
    }
    static impresiones(){
        //console.log(ConstantesAfiliacion.setEstadoT1C2() + " " + ConstantesAfiliacion.setRangoEdadT1C2() + " " + ConstantesAfiliacion.setMunicipioT1C2() + " " + ConstantesAfiliacion.setLocalidadT1C2() + " " + ConstantesAfiliacion.setEstadoCivilT1C2() + " " + ConstantesAfiliacion.setOcupacionT1C2());
    }
    static impresionesT6(){
        //console.log(ConstantesAfiliacion.setEstadoT6C2() + " " + ConstantesAfiliacion.setRangoEdadT6C2() + " " + ConstantesAfiliacion.setMunicipioT6C2() + " " + ConstantesAfiliacion.setLocalidadT6C2() + " " + ConstantesAfiliacion.setEstadoCivilT6C2() + " " + ConstantesAfiliacion.setOcupacionT6C2());
    }
    static nombreproductos(cadena){
        this.arreglo = [];
        for(var i = 0; i < cadena[0].series.length; i++){
            this.arreglo.push(cadena[0].series[i].name);
        }
        return this.arreglo;
    }

    static getActivoIcon(bnActivo: boolean) {
        return (null == bnActivo) ? 'more_horiz'
              : bnActivo ? 'done' : 'block';
    }

    static getBnFromString(strValue: string): boolean {
        return (
                null == strValue
                || undefined === strValue
                || 'false' === strValue.toLowerCase().trim()
                || '0' === strValue.toLowerCase().trim()
              ) ? false : true;
    }
    static Encabezados(arreglo){
        this.resultEncabezados = [];
        var array = [];
        var array1 = "";
        for(var i = 0; i < 1; i++){
            array1 = "Año";
            for(var j = 0; j < arreglo[i].series.length; j++){
                //this.resultEncabezados.push({"name":arreglo[i].series[j].name});
                array.push({"name":arreglo[i].series[j].name});
            }
        }
        this.resultEncabezados.push({"name":array1,"series":array})
        return this.resultEncabezados;

    }

    static BuscarAsesoresCreditos(arreglo,codigo){
        var name = "";
        for(var i = 0; i < arreglo.length; i++){
            if(codigo == arreglo[i].AsesorCreditoId){
                name = arreglo[i].Nombre;
            }
        }
        return name;
    }

    static OrdenarMayorMenor(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value < arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    static OrdenarMenorMayor(arreglo){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value > arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
    static OrdenarMayorMenorNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value < arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"value":arreglo_aux[i].value});
            }
        }
        return arreglo;
    }
    static OrdenarMenorMayorNum(arreglo,num){
        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value > arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        var arreglo_aux = arreglo;
        arreglo = [];
        if(num < arreglo_aux.length){
            for(var i = 0; i < num;i++){
                arreglo.push({"name":arreglo_aux[i].name,"value":arreglo_aux[i].value});
            }
        }
        return arreglo;
    }
    static OrdenarAleatorio(arreglo){

        for(var i = 1; i < arreglo.length ; i ++){
            for(var j = 0; j < (arreglo.length - i ); j++){
                if(arreglo[j].value > arreglo[j + 1].value){
                    const elemento = arreglo[j];
                    arreglo[j] = arreglo[j + 1];
                    arreglo[j + 1] = elemento;
                }
            }
        }
        return arreglo;
    }
}
export class VariablesColores{
    static selectedColorScheme_paleta_azul = "";
}
export class FuncionesColores{
    static selectedColorScheme_paleta_azul: string = "";
    static paleta_azul: any;
    static colorScheme: any;
    static colorSets: any;
    static defaultColorScheme = 'aqua';
    static ColorsLinealCombo: any;
    static setpaleta_azul(name) {
        this.inicioColores();
        this.selectedColorScheme_paleta_azul = name;
        this.colorScheme = this.colorSets.find(s => s.name === name);
        if(this.colorScheme === undefined ){
          this.selectedColorScheme_paleta_azul = this.defaultColorScheme;
          this.colorScheme = this.colorSets.find(s => s.name === this.defaultColorScheme);
        }
        return this.colorScheme;
    }
    static inicioColores(){
        this.colorSets = colorSets;
    }
    public static ColorLinealCombo(colors,dimension){
        var aux = [];
        for(var i = 0; i < dimension;i++){
            aux.push(colors.domain[i]);
        }
        var colorLineal = {"name":colors.name,"selectable":true,"group":"Ordinal","domain":aux};
        console.log("  Color ---> " +  JSON.stringify(colorLineal) + " ----> " + dimension + " ---> " + JSON.stringify(aux));
        return colorLineal;
    }
    public static FuncionColorBarCombo(colors){
        console.log("colorBarraCombo Entrada---> " + JSON.stringify(colors));
        var aux = [];
        var dim = colors.domain.length - 1;
        aux.push(colors.domain[dim]);
        var auxli = [];
        auxli.push(colors.domain[dim - 1]);


        console.log("colorBarraCombo Colores---> " + aux + " --->  " + (colors.domain.length - 1) );
        var color = {"name":colors.name,"selectable":true,"group":"Ordinal","domain":aux};
        auxli.push(colors.domain[dim]);
        this.ColorsLinealCombo = {"name":colors.name,"selectable":true,"group":"Ordinal","domain":auxli};
        console.log("colorBarraCombo Salida---> " + JSON.stringify(this.ColorsLinealCombo));
        return color;
    }
}
