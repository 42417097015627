import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SucursalService } from '../../../services/sucursal/sucursal.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { FiltroAnio } from 'src/app/model/filtro-anio';
import { FiltroMes } from 'src/app/model/filtro-mes';
import { ElasticSociosService } from 'src/app/services/elastic-socios/elastic-socios.service';
/* Importación de Funciones */
import { FuncionesColores } from '../../shared/funciones-colores/funciones_colores';
import { FuncionesAfiliacion, MasculinoFemenino } from '../../shared/funciones-afiliacion/funcionesAfiliacion';
import { Datosdummy } from '../funciones_c_pruebas';
import { CommonFunctions } from '../../../util/app-util';
import { FiltrosService } from '../../../services/filtros/filtros.service';
import { TendenciaService } from './../../../services/comercial/tendencia/tendencia.service';
/* Importación de Funciones */

import { MatSort ,Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ThrowStmt } from '@angular/compiler';

import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';

@Component({
  selector: 'app-tendencia',
  templateUrl: './tendencia.component.html',
  styleUrls: ['./tendencia.component.css']
})

export class TendenciaComponent implements OnInit{
  // @ViewChild(MatSort) sort: MatSort;
  // dataSource:any = new MatTableDataSource<any>([]);
  dataSource = [];
  /*ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }*/

  /**
   * variables de filtros
   */
   public puedeFiltrarPorPerspectivas = false;
   public consolidado = true;
   public aniosDisponibles: any [];
   private mesesDisponibles: any [];
   public mesesDeAnioDisponibles: any[];
   public puedeFiltrarPorMeses = false;
   public sucursalesDisponibles: any[];
   public puedeFiltrarPorSucursales = false;
   public numeroDeSucursalesSeleccionadas = 0;
   private anioSeleccionado: object;
   public mesSeleccionado: object;
   public sucursalSeleccionada: object;
   public subtituloGraficaTabla = '';

   /**
   * variables de graficas
   */
  public esGraficaDeBarras = true;
  public colorScheme: string = FuncionesColores.setpaleta_azul('paleta_azul');
  private datosGrafica = [];

   // Filtro años
  public flag_filtrar_anio = false;
  public arreglo_anios: Array<FiltroAnio>;
  public anios_seleccionados: Array<FiltroAnio>;

  // Filtro meses
  public arreglo_meses: Array<FiltroMes>;
  public meses_seleccionados: Array<FiltroMes>;
  public check_todosmeses: boolean = false;

  // Filtros perspectivas
  public filtrosPerspectivasActivos: Array<any>;
  public filtrosPerspectivasAnidados: Array<any>;
  public flag_perspectivas = false;
  public sucursal_id: string;
  public valor_seleccionado;

  // grafica 1
  public contador_anios = 0;
  public contador_mes = 0;
  public sucursales: Array<any>;
  public showXAxis = true;
  public showYAxis = true;
  public showLegend = false;
  public showXAxisLabel = false;
  public xAxisLabel = '';
  public xAxisLabelBarraFiltroT5 = '';
  public xAxisLabelT2C3 = '';
  public showYAxisLabel = true;
  public yAxisLabel = 'Saldo';
  public showGridLines = false;
  public customColors: object[];
  public showDataLabelT1C1 = false;
  public barScaleMax = 5000;
  public activeEntries = [];
  public subtituloGrafica1 = 'Todos';
  public subtituloGrafica2 = '';
  public datosBarra: Array<any>;
  public datosLineal: Array<any>;
  ent_consolidado = true;
  /* variables de la tabla*/
  public tableDataSource = new MatTableDataSource<object>();
  //public dataSource: any[] = [];
  public datatablaOr: any[] =[];
  public columns: Array<object>;
  public displayedColumns = [];

  // Tabla Cuadrante 2
  public table_data: Array<any>;
  
  public total_no_contratos;
  public total_suma_cartera;
  public total_masculino = 0;
  public total_femenino = 0;
  showDataLabelActiveT1C2 = true;
  showDataLabelInactiveT1C2 = false;
  subtitulot1c2 = '';
  tabla_informacion2 = true;
  puntot1c2 = false;
  chartlineal2 = false;
  piegrid2 = false;
  // graficas barras, lineal
  public g1_barra = true;
  public g1_linea = false;
  public g1_area = false;
  public g1_tabla = false;

  public datatabla = [];
  public columna = ['anio','mes','saldo','ncuentas']
  // graficas barras, lineal
  // tercer cuadrante
  data_pie_chart: any[] = [];
  // options
  gradient = true;
  showLabels = false;
  isDoughnut = false;
  legendPosition = 'below';
  piechartGoogleT1C3 = true;
  /* GooglePieChart */
  type = 'PieChart';
  data = [
    ['Activos', 1],
    ['Inactivos', 1],
  ];
  columnNames = ['Browser', 'Percentage'];
  
  options = {
    width: '96%',
    height: '30vh',
    pieHole: 0.5,
    colors: ['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6']
  };
  /* GooglePieChart */

  /* tabla tendencia */
  display_columns: string[] = ['anio', 'hombres', 'total_hombres', 'mujeres', 'total_mujeres', 'total_socios', 'total_general'];
  /* tabla tendencia */

  /* Variables para el modal */
  public g1_barra_modal = false;
  public g1_linea_modal = false;
  public g1_tabla_modal = false;
  public g1_areamodal = false;
  public titulomodal: string = "";
  public subtitulomodal: string = "";

  // Grafica 1
  public saldo = true;
  public numer = false;

  public dataBarraNum = [];
  public dataLinealNum = [];
  /** variables graficas zona 2 */
  // Datos
  public dataCASO = [];
  public dataCAFO = [];
  public dataAHSO = [];
  public dataAHCC = [];
  public dataCRECE = [];
  public dataAHME = [];
  public dataHAME = [];
  public dataHAEX = [];
  public dataTDSR = [];
  public dataPAGARE = [];
  public dataCEDE = [];

  public dataCASOlin = [];
  public dataCAFOlin = [];
  public dataAHSOlin = [];
  public dataAHCClin = [];
  public dataCRECElin = [];
  public dataAHMElin = [];
  public dataHAMElin = [];
  public dataHAEXlin = [];
  public dataTDSRlin = [];
  public dataPAGARElin = [];
  public dataCEDElin = [];
  // Normal
  public gtabla = true;
  public gCASO = false;
  public gCAFO = false;
  public gAHSO = false;
  public gAHCC = false;
  public gCRECE = false;
  public gAHME = false;
  public gHAME = false;
  public gHAEX = false;
  public gTDSR = false;
  public gPAGARE = false;
  public gCEDE = false;
  public gCASOlin = false;
  public gCAFOlin = false;
  public gAHSOlin = false;
  public gAHCClin = false;
  public gCRECElin = false;
  public gAHMElin = false;
  public gHAMElin = false;
  public gHAEXlin = false;
  public gTDSRlin = false;
  public gPAGARElin = false;
  public gCEDElin = false;
  // Modal
  public gtabla_modal = false;
  public gCASO_modal = false;
  public gCAFO_modal = false;
  public gAHSO_modal = false;
  public gAHCC_modal = false;
  public gCRECE_modal = false;
  public gAHME_modal = false;
  public gHAME_modal = false;
  public gHAEX_modal = false;
  public gTDSR_modal = false;
  public gPAGARE_modal = false;
  public gCEDE_modal = false;
  public gtablalin_modal = false;
  public gCASOlin_modal = false;
  public gCAFOlin_modal = false;
  public gAHSOlin_modal = false;
  public gAHCClin_modal = false;
  public gCRECElin_modal = false;
  public gAHMElin_modal = false;
  public gHAMElin_modal = false;
  public gHAEXlin_modal = false;
  public gTDSRlin_modal = false;
  public gPAGARElin_modal = false;
  public gCEDElin_modal = false;

  /** variablas graficas zona 3 */
  columnasC3 = ['generacion','valor','porcentaje'];
  // Datos
  public databarra3 = [];
  public datamapa = [];
  public datatabla3 = [];
  // Normal
  public gpie = true;
  public gtabla3 = false;
  public gbarras3 = false;
  public gmapa = false;
  // Modal
  public gpie_modal = false;
  public gtabla3_modal = false;
  public gbarras3_modal = false;
  public gmapa_modal = false;
  /** Variables de perspectivas */
  public perTip = "";
  public perGen = "";
  public perMun = "";
  public perspectivaAct = "";

  public cantidad = 'quince';
  public masQuin = false;

  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  constructor(
    private sucursalService: SucursalService,
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private elasticSearch: ElasticSociosService,
    private tendenciaService: TendenciaService,
    private excelservices: ExcelService,
  ) {
    this.getFiltros();
  }
  ngOnInit(): void {}

  sortData(sort: Sort) {
    this.dataSource = [];
    const datas = this.datatablaOr;
    if (!sort.active || sort.direction === '') {
      this.dataSource = datas;
      return;
    }
    var aux = [];
    aux = datas.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sucursal': return compare(a.sucursal, b.sucursal, isAsc);
        case 'caso': return compare(a.caso, b.caso, isAsc);
        case 'cafo': return compare(a.cafo, b.cafo, isAsc);
        case 'ahso': return compare(a.ahso, b.ahso, isAsc);
        case 'ahcc': return compare(a.ahcc, b.ahcc, isAsc);
        case 'crece': return compare(a.crece, b.crece, isAsc);
        case 'ahme': return compare(a.ahme, b.ahme, isAsc);
        case 'iane': return compare(a.iane, b.iane, isAsc);
        case 'hame': return compare(a.hame, b.hame, isAsc);
        case 'haex': return compare(a.haex, b.haex, isAsc);
        case 'tdsr': return compare(a.tdsr, b.tdsr, isAsc);
        case 'pagare': return compare(a.pagare, b.pagare, isAsc);
        case 'cede': return compare(a.cede, b.cede, isAsc);
        default: return 0;
      }
    });
    setTimeout(() =>  this.dataSource = aux, 1);
    
  }

  /**
   * funciones de filtros
   */
   private getFiltros(): void {
    this.filtrosService.getAniosMesesDisponibles('radiografia').subscribe( response => {
      const tiempoFiltros = this.filtrosService.formatAniosMesesDisponibles(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesDisponibles;
      this.getGraficaPorAnio();
    });
    this.filtrosService.getSucursalesDisponibles('radiografia', 'codSucCredito-sucursalCredito').subscribe(response => {
      this.sucursalesDisponibles = this.filtrosService.formatSucursalesDisponibles(response);
      this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.length;
    });
  }
  public subtitulos2(){
    var aux = "", aux2 = "";
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        aux = ""+ anio.name.substring(2,4);
        aux2 = ""+ anio.name; 
      }
    }
    var y = aux2;
    var mes = "";
    // @ts-ignore
    if(this.mesSeleccionado == undefined || this.mesSeleccionado.name == 'Todos'){
      for(const anio of this.mesesDisponibles){
        if(anio.year == y){
          mes = ""+ anio.name;
        }
      }
    }else{
      // @ts-ignore
      mes = ""+ this.mesSeleccionado.name;
    }
    aux += " | "+mes;
    this.subtituloGrafica2 = aux;
  }
  public subtitulos(){
    var aux = '';
    var i = 0;
    if(this.saldo){
      aux += 'Saldo total ';
    }else if(this.numer){
        aux += 'Número de cuentas ';
    }
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        if(i == 0){
          aux += '| '+anio.name.substring(2,4);
          i ++;
        }else{
          aux += ' | '+anio.name.substring(2,4);
        }
      }
    }
    if(this.mesSeleccionado != undefined){
      // @ts-ignore
      aux += ' | '+this.mesSeleccionado.name;
    }
    if(this.sucursalSeleccionada != undefined){
      // @ts-ignore
      aux += ' | '+this.sucursalSeleccionada.name;
    }
    if(this.perspectivaAct != ""){
      aux += ' | '+this.perspectivaAct;
    }
    if(this.perTip != ""){
      aux += ' | '+this.perTip;
    }
    if(this.perGen != ""){
      aux += ' | '+this.perGen;
    }
    if(this.perMun != ""){
      aux += ' | '+this.perMun;
    }
    this.subtituloGrafica1 = aux; 
  }
  // Consultas para recibir los datos segun sea el filtro aplicado
  private getGraficaPorAnio(): void {
    this.masQuin = false;
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    const ult_anio = [];
    const ult_mes = this.mesesDisponibles[this.mesesDisponibles.length - 1]['value'].split('-');
    ult_anio.push(aniosSeleccionados[aniosSeleccionados.length - 1]);
    this.subtituloGraficaTabla = `Años: ${aniosSeleccionados.join(', ')} - Consolidado`;
    this.tendenciaService.getTendenciaGraficaPorAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name).subscribe( response => {
      if (response.http.code === 200) {
        this.datosGrafica = response.message;
        this.datosGrafica.forEach(e => { // @ts-ignore
          e.key = e.key.split('-')[0]; });
        this.tendenciaService.getTendenciaTablaPorAnio(aniosSeleccionados, this.mesesDisponibles[this.mesesDisponibles.length - 1],this.aniosDisponibles[this.aniosDisponibles.length-1].name).subscribe( response => {
          if (response.http.code === 200) {
            if (this.esGraficaDeBarras){
              this.formatDatosBarra();
            }
            this.obtTablaZon1(response.message);
          }
        });
        
      }
    });
    this.tendenciaService.getTendenciaTablaAnioMes(ult_anio, ult_mes[1]).subscribe( response => {
      if (response.http.code === 200) {
        this.ConstruirTabla(response.message);
      }
    });
    this.tendenciaService.getTendenciaActivosInactivos(ult_anio, ult_mes[1]).subscribe( response => {
      if (response.http.code === 200) {
        this.formatGraficasActivosInactivos(response.message);
      }
    });
    this.subtitulos();
    this.subtitulos2();
  }
  private getGraficaFiltroAnio(arr_aniomes): void {
    this.tendenciaService.getTendenciaTablaAnioMes([arr_aniomes[0]], arr_aniomes[1]).subscribe( response => {
      if (response.http.code === 200) {
        this.ConstruirTabla(response.message);
      }
    });
    this.tendenciaService.getTendenciaActivosInactivos([arr_aniomes[0]], arr_aniomes[1]).subscribe( response => {
      if (response.http.code === 200) {
        this.formatGraficasActivosInactivos(response.message);
      }
    });
    this.subtitulos();
    this.subtitulos2();
  }
  private getGraficaPorMes(): void {
    this.masQuin = false;
    this.customColors = [];
    const mesesSeleccionados = this.filtrosService.getElmentosSeleccionados(this.mesesDeAnioDisponibles);
    // @ts-ignore
    //this.subtituloGraficaTabla = `Meses de ${this.anioSeleccionado.name} - Consolidado`;
    this.tendenciaService.getTendenciaGraficaPorAnioMes(mesesSeleccionados).subscribe( response => {
      if (response.http.code === 200) {
        this.datosGrafica = response.message;
        this.tendenciaService.getTendenciaTablaPorAnioMes(mesesSeleccionados).subscribe( response => {
          if (response.http.code === 200) {
            if (this.esGraficaDeBarras){
              this.formatDatosBarra();
            }
            this.obtTablaZon1(response.message);
          }
        });
      }
    });
    this.subtitulos();
    this.subtitulos2();
  }
  private getGraficaPorSucursales(): void {
    this.masQuin = false;
    const sucursalesSeleccionadas = this.filtrosService.formatSucursalesSeleccionadas(this.sucursalesDisponibles, 'sucursalCredito');
    // @ts-ignore
    this.subtituloGraficaTabla = `${this.anioSeleccionado.name} - ${this.mesSeleccionado.name} Sucursales `;
    this.tendenciaService
      // @ts-ignore
      .getTendenciaGraficaPorSucursales([this.mesSeleccionado.value], sucursalesSeleccionadas)
      .subscribe(response => {
      if (response.http.code === 200) {
        this.datosGrafica = response.message;
        var aux = [];
        for(const d of this.datosGrafica){
          for(const suc of this.sucursalesDisponibles){
            // @ts-ignore
            if( d.key == suc.name && suc.check ){
              aux.push(d);
            }
          }
        }
        this.datosGrafica = [];
        this.datosGrafica = aux;
        //@ts-ignore
        this.tendenciaService.getTendenciaTablaPorSucursales([this.mesSeleccionado.value], sucursalesSeleccionadas).subscribe( response => {
          if (response.http.code === 200) {
            var aux2 = [];
            for(const d of response.message){
              for(const suc of this.sucursalesDisponibles){
                // @ts-ignore
                if( d.key == suc.name && suc.check ){
                  aux2.push(d);
                }
              }
            }
            if (this.esGraficaDeBarras){
              this.formatDatosBarra();
            }
            this.obtTablaZon1(aux2);
          }
        });
      }
    });
    const aniosSeleccionados = this.filtrosService.getElmentosSeleccionados(this.aniosDisponibles);
    const ult_anio = [];
    const ult_mes = this.mesesDisponibles[this.mesesDisponibles.length - 1]['value'].split('-');
    ult_anio.push(aniosSeleccionados[aniosSeleccionados.length - 1]);
    // @ts-ignore
    // @ts-ignore
    this.tendenciaService.getTendenciaTablaAnioMes(ult_anio,this.mesSeleccionado.value.split('-')[1]).subscribe( response => {
      if (response.http.code === 200) {
        this.ConstruirTabla(response.message);
      }
    });
    // @ts-ignore
    this.tendenciaService.getTendenciaActivosInactivos(ult_anio, this.mesSeleccionado.value.split('-')[1]).subscribe( response => {
      if (response.http.code === 200) {
        this.formatGraficasActivosInactivos(response.message);
      }
    });
    this.subtitulos();
    this.subtitulos2();
  }
  public hizoPers(): string {
    return this.perspectivaAct == "" ? 'w3-third' : 'w3-third w3-disabled';
  }
  private obtTablaZon1(data){
    this.datatabla = [];
    var aux = [], i =0;
    for(const d of data){
      if(d['key'].includes('-') && this.perspectivaAct == ""){
        var anio = d['key'].split('-')[0];
        var mes = d['key'].split('-')[1];
        this.datatabla.push({
          anio: anio,
          mes: this.abrebMes(mes),
          // @ts-ignore
          saldo:  this.datosBarra[i].value,
          ncuen: d['value']
        });
        if(!this.puedeFiltrarPorMeses){
          aux.push(anio);
          // @ts-ignore
          aux.push(this.datosBarra[i].value);
          aux.push(d['value']*10000);
          //this.data.push(aux);
          aux = [];
        }else{
          aux.push(this.abrebMes(mes));
          // @ts-ignore
          aux.push(this.datosBarra[i].value);
          aux.push(d['value']*10000);
          //this.data.push(aux);
          aux = [];
        }
      }else{
        // @ts-ignore
        var anio = this.anioSeleccionado.name + ' ' + this.mesSeleccionado.name;
        this.datatabla.push({
          anio: anio,
          mes: d['key'],
          // @ts-ignore
          saldo:  this.datosBarra[i].value,
          ncuen: d['value']
        });
        aux.push(anio);
        // @ts-ignore
        aux.push(this.datosBarra[i].value);
        aux.push(d['value']*10000);
        //this.data.push(aux);
        aux = [];
      }
      i++;
    }
    this.dataBarraNum = [];
    this.dataLinealNum = [];
    for(const d of data){
      if(d.key.includes('-') && !this.puedeFiltrarPorMeses){
        this.dataBarraNum.push({
          // @ts-ignore
          name:  this.abrebMes(d.key.split('-')[0]),
          // @ts-ignore
          value: d.value,
          extra:  d.key.split('-')[0]
        });
      }else if(d.key.includes('-')){
        this.dataBarraNum.push({
          // @ts-ignore
          name:  this.abrebMes(d.key.split('-')[1]),
          // @ts-ignore
          value: d.value,
          extra:  d.key
        });
      }else{
        this.dataBarraNum.push({
          // @ts-ignore
          name:  d.key,
          // @ts-ignore
          value: d.value,
          extra:  d.key
        });
      }
    }
    this.dataLinealNum.push({name: 'Número de cuentas', series: this.dataBarraNum});
  }
  public tit1(){
    if(!this.consolidado && this.puedeFiltrarPorSucursales && !this.sucursalSeleccionada){
      return 'Periodo';
    }
    if(this.perspectivaAct != ""){
      return 'Periodo';
    }
    return 'Año';
  }
  public tit2(){
    // @ts-ignore
    if(!this.consolidado && this.puedeFiltrarPorSucursales && !this.sucursalSeleccionada.name != 'CONSOLIDADO'){
      return 'Sucursal';
    }
    if(this.perspectivaAct != ""){
      switch(this.perspectivaAct){
        case 'Tipo':
          return 'tipo';
        case 'Genero':
          return 'Genero';
        case 'Producto':
          return 'Producto';
        case 'Municipio':
          return 'Municipio';
      }
    }
    return 'Mes';
  }
  /**
   * funciones de filtros
  */
  // AÑOS
  public anioChangeListener(anioClick: object): void {
    this.filtrosService.changeElementCheckStatus(anioClick, this.aniosDisponibles);
    this.puedeFiltrarPorMeses = (this.aniosDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorSucursales = false;
    this.puedeFiltrarPorPerspectivas = false;
    this.getGraficaPorAnio();
    if (this.puedeFiltrarPorMeses) {
      this.anioSeleccionado = this.aniosDisponibles.find(e => e.check);
    }
  }
  public VerificarAniosSeleccionados(): string {
    return this.puedeFiltrarPorMeses ? 'w3-third' : 'w3-third w3-disabled';
  }
  public Complete(){
    var i = 0;
    if(this.mesesDeAnioDisponibles != undefined){
      for(const an of this.mesesDeAnioDisponibles){
        if(an.check)
          i++;
      }
      if(i < (this.mesesDeAnioDisponibles.length))
        return false;
      else
        return true;
    }else{
      return false;
    }
  }
  // MESES
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    if (this.anioSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.aniosDisponibles, false);
      // @ts-ignore
      this.anioSeleccionado.check = true;
    }
    this.filtrosService.changeElementCheckStatus(mesClick, elementArray);
    this.puedeFiltrarPorSucursales = (this.mesesDeAnioDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorPerspectivas = false;
    this.getGraficaPorMes();
    if (this.puedeFiltrarPorSucursales) {
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.subtitulos();
    this.subtitulos2();
  }
  public mesChangeAll(valor: boolean): void{
    this.mesesDeAnioDisponibles.forEach(anio => anio.check = valor);
    if (this.anioSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.aniosDisponibles, false);
      // @ts-ignore
      this.anioSeleccionado.check = true;
    }
    this.puedeFiltrarPorSucursales = (this.mesesDeAnioDisponibles.filter(e => e.check).length === 1);
    this.puedeFiltrarPorPerspectivas = false;

    this.getGraficaPorMes();
    this.mesSeleccionado = {"name":"Todos"};
    if (this.puedeFiltrarPorSucursales) {
      this.mesSeleccionado = this.mesesDeAnioDisponibles.find(e => e.check);
    }
    this.check_todosmeses = true;
    this.subtitulos();
    this.subtitulos2();
  }
  // SUCURSALES
  public consolidadoChangeListener(): void {
    this.consolidado = !this.consolidado;
    if (!this.consolidado) {
      for(const suc of this.sucursalesDisponibles){
        suc.check = true;
      }
      if (this.mesSeleccionado) {
        this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
        for(const me of this.mesesDeAnioDisponibles){
          // @ts-ignore
          if(me.value == this.mesSeleccionado.value){
            me.check = true;
          }
        }
        // @ts-ignore
        this.mesSeleccionado.check = true;
        //this.getTablaPorMes();
      }
      this.sucursalSeleccionada = {name: "SELECCIÓN"};
      this.getGraficaPorSucursales();
    }else {
      // Si es consolidado entonces volvemos a la grafica por mes
      this.getGraficaPorMes();
      for(const me of this.mesesDeAnioDisponibles){
        // @ts-ignore
        if(me.value == this.mesSeleccionado.value){
          me.check = true;
        }
      }
      this.sucursalSeleccionada = {name: "CONSOLIDADO"};
    }
    this.subtitulos();
    this.subtitulos2();
  }
  public sucursalChangeListener(sucursalClick: object): void {
    if (this.mesSeleccionado) {
      this.filtrosService.changeAllElementsCheckStatus(this.mesesDeAnioDisponibles, false);
      for(const me of this.mesesDeAnioDisponibles){
        // @ts-ignore
        if(me.value == this.mesSeleccionado.value){
          me.check = true;
        }
      }
      // @ts-ignore
      this.mesSeleccionado.check = true;
    }
    this.filtrosService.changeElementCheckStatus(sucursalClick, this.sucursalesDisponibles);
    this.numeroDeSucursalesSeleccionadas = this.sucursalesDisponibles.filter(e => e.check).length;
    this.puedeFiltrarPorPerspectivas = (this.numeroDeSucursalesSeleccionadas === 1);
    this.getGraficaPorSucursales();
    if (this.puedeFiltrarPorPerspectivas) {
      this.sucursalSeleccionada = this.sucursalesDisponibles.find(e => e.check);
    }
    this.subtitulos();
    this.subtitulos2();
  }
  public quitarFiltros(): void {
    //zona 1
    this.g1_barra = true;
    this.g1_linea = false;
    this.g1_area = false;
    this.g1_tabla = false;
    //Zona 2
    this.gtabla = true;
    this.gCASO = false;
    this.gCAFO = false;
    this.gAHSO = false;
    this.gAHCC = false;
    this.gCRECE = false;
    this.gAHME = false;
    this.gCASOlin = false;
    this.gCAFOlin = false;
    this.gAHSOlin = false;
    this.gAHCClin = false;
    this.gCRECElin = false;
    this.gAHMElin = false;
    //zona 3
    this.gpie = true;
    this.gtabla3 = false;
    this.gbarras3 = false;
    this.gmapa = false;
    // perspectivas
    this.perTip = "";
    this.perGen = "";
    this.perMun = "";
    this.perspectivaAct = "";
    this.masQuin = false;

    this.saldo = true;
    this.numer = false;

    this.puedeFiltrarPorPerspectivas = false;
    this.puedeFiltrarPorMeses = false;
    this.puedeFiltrarPorSucursales = false;
    this.flag_perspectivas = false;
    this.getFiltros();
    this.mesSeleccionado = undefined;
    this.anioSeleccionado = undefined;
    this.sucursalSeleccionada = undefined;
    this.consolidado = true;
  }
  public ContarMeses(){
    if (this.meses_seleccionados.length < 5 ){
      return 'w3-row w3-tiny hijoizquierda';
    }else{
      return 'w3-row w3-tiny ';
    }
  }
  public ObtenerTotalMasculinoFemenino(data): void{
    for (const dato of data){
      this.total_masculino += dato.masculino;
      this.total_femenino += dato.femenino;
    }
  }
  public cambiarGrafica2(graf: any): void{
    switch(graf){
      case 'tabla':
        this.gtabla = true;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'CASO':
        this.gtabla = false;
        this.gCASO = true;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'CAFO':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = true;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'AHSO':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = true;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'AHCC':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = true;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'CRECE':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = true;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'AHME':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = true;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'HAME':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = true;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'HAEX':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = true;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'TDSR':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = true;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'PAGARE':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = true;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'CEDE':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = true;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;

      case 'CASOL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = true;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'CAFOL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = true;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'AHSOL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = true;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'AHCCL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = true;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'CRECEL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = true;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'AHMEL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = true;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'HAMEL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = true;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'HAEXL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = true;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'TDSRL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = true;
        this.gPAGARElin = false;
        this.gCEDElin = false;
      break;
      case 'PAGAREL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = true;
        this.gCEDElin = false;
      break;
      case 'CEDEL':
        this.gtabla = false;
        this.gCASO = false;
        this.gCAFO = false;
        this.gAHSO = false;
        this.gAHCC = false;
        this.gCRECE = false;
        this.gAHME = false;
        this.gHAME = false;
        this.gHAEX = false;
        this.gTDSR = false;
        this.gPAGARE = false;
        this.gCEDE = false;
        this.gCASOlin = false;
        this.gCAFOlin = false;
        this.gAHSOlin = false;
        this.gAHCClin = false;
        this.gCRECElin = false;
        this.gAHMElin = false;
        this.gHAMElin = false;
        this.gHAEXlin = false;
        this.gTDSRlin = false;
        this.gPAGARElin = false;
        this.gCEDElin = true;
      break;
    }
  }
  public cambiarGrafica3(graf: any): void{
    switch(graf){
      case 'pie':
        this.gpie = true;
        this.gmapa = false;
        this.gtabla3 = false;
        this.gbarras3 = false;
      break;
      case 'mapa':
        this.gpie = false;
        this.gmapa = true;
        this.gtabla3 = false;
        this.gbarras3 = false;
      break;
      case 'tabla':
        this.gpie = false;
        this.gmapa = false;
        this.gtabla3 = true;
        this.gbarras3 = false;
      break;
      case 'barras':
        this.gpie = false;
        this.gmapa = false;
        this.gtabla3 = false;
        this.gbarras3 = true;
      break;
    }
  }
  public modalCuadrante1(): void {
    document.getElementById('ampliarGraficas').style.display='block';
    this.titulomodal = 'Tendencia';
    this.subtitulomodal = this.subtituloGrafica1;
    this.hideGraficasModal();
    if(this.g1_barra)
      this.g1_barra_modal = true;
    if(this.g1_linea)
      this.g1_linea_modal = true;
    if(this.g1_area)
      this.g1_areamodal = true;
    if(this.g1_tabla)
      this.g1_tabla_modal = true;
  }
  public modalCuadrante2(): void {
    document.getElementById('ampliarGraficas').style.display='block';
    this.titulomodal = 'Saldo por producto';
    this.subtitulomodal = this.subtituloGrafica2;
    this.hideGraficasModal();

    if(this.gtabla)
      this.gtabla_modal = true;

    if(this.gCASO)
      this.gCASO_modal = true;
    if(this.gCAFO)
      this.gCAFO_modal = true;
    if(this.gAHSO)
      this.gAHSO_modal = true;
    if(this.gAHCC)
      this.gAHCC_modal = true;
    if(this.gCRECE)
      this.gCRECE_modal = true;
    if(this.gAHME)
      this.gAHME_modal = true;
    if(this.gHAME)
      this.gHAME_modal = true;
    if(this.gHAEX)
      this.gHAEX_modal = true;
    if(this.gTDSR)
      this.gTDSR_modal = true;
    if(this.gPAGARE)
      this.gPAGARE_modal = true;
    if(this.gCEDE)
      this.gCEDE_modal = true;

    if(this.gCASOlin)
      this.gCASOlin_modal = true;
    if(this.gCAFOlin)
      this.gCAFOlin_modal = true;
    if(this.gAHSOlin)
      this.gAHSOlin_modal = true;
    if(this.gAHCClin)
      this.gAHCClin_modal = true;
    if(this.gCRECElin)
      this.gCRECElin_modal = true;
    if(this.gAHMElin)
      this.gAHMElin_modal = true;
    if(this.gHAMElin)
      this.gHAMElin_modal = true;
    if(this.gHAEXlin)
      this.gHAEXlin_modal = true;
    if(this.gTDSRlin)
      this.gTDSRlin_modal = true;
    if(this.gPAGARElin)
      this.gPAGARElin_modal = true;
    if(this.gCEDElin)
      this.gCEDElin_modal = true;
  }
  public modalCuadrante3(): void {
    document.getElementById('ampliarGraficas').style.display='block';
    this.titulomodal = 'Distribución por tipo';
    this.subtitulomodal = this.subtituloGrafica2;
    this.hideGraficasModal();
    if(this.gpie)
      this.gpie_modal = true;
    if(this.gbarras3)
      this.gbarras3_modal = true;
    if(this.gmapa)
      this.gmapa_modal = true;
    if(this.gtabla3)
      this.gtabla3_modal = true;
  }
  public hideGraficasModal(){
    this.g1_barra_modal = false;
    this.g1_linea_modal = false;
    this.g1_areamodal = false;
    this.g1_tabla_modal = false;

    this.gtabla_modal = false;
    this.gCASO_modal = false;
    this.gCAFO_modal = false;
    this.gAHSO_modal = false;
    this.gAHCC_modal = false;
    this.gCRECE_modal = false;
    this.gAHME_modal = false;
    this.gCASOlin_modal = false;
    this.gCAFOlin_modal = false;
    this.gAHSOlin_modal = false;
    this.gAHCClin_modal = false;
    this.gCRECElin_modal = false;
    this.gAHMElin_modal = false;

    this.gpie_modal = false;
    this.gtabla3_modal = false;
    this.gbarras3_modal = false;
    this.gmapa_modal = false;
  }
  public hideModal(){
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  public isNotActiveFilter(perspectiva: string): boolean {
    //this.filtrosPerspectivasActivos.filter(filtro => filtro == perspectiva).length == 0;
    return true;
  }
  public addPerspectiva(perspectiva: string) {
    switch(perspectiva.split('.')[0]){
      case 'tipo':
        this.perspectivaAct = "Tipo";
      break;
      case 'genero':
        this.perspectivaAct = "Genero";
      break;
      case 'municipio':
        this.perspectivaAct = "Municipio";
      break;
    }
    this.subtitulos();
    this.subtitulos2();
    //@ts-ignore
    if (this.sucursalSeleccionada) {
      // @ts-ignore
      this.subtituloGraficaTabla = `Perspectiva: ${this.anioSeleccionado.name} - ${this.mesSeleccionado.name} - ${this.sucursalSeleccionada.name}`;
      this.filtrosService.changeAllElementsCheckStatus(this.sucursalesDisponibles, false);
      // @ts-ignore
      this.sucursalSeleccionada.check = true;
      this.numeroDeSucursalesSeleccionadas = 1;
      this.tendenciaService
        // @ts-ignore
        .getTendenciaGraficaPorPerspectivas(perspectiva, this.mesSeleccionado.value, this.perTip, this.perGen, this.perMun, this.sucursalSeleccionada.name)
        .subscribe(response => {
          if (response.http.code === 200) {
            this.datosGrafica = response.message;
            // @ts-ignore
            this.tendenciaService.getTendenciaTablaPorPerspectivas(perspectiva, this.mesSeleccionado.value, this.perTip, this.perGen, this.perMun, this.sucursalSeleccionada.name).subscribe( response => {
              if (response.http.code === 200) {
                if(response.message.length < 15){
                  this.masQuin = false;
                  if (this.esGraficaDeBarras){
                    this.formatDatosBarra();
                  }
                  this.obtTablaZon1(response.message);
                }else if(this.cantidad == 'quince'){
                  this.masQuin = true;
                  var aux1 =[], aux2 = [], i = 0;
                  for(const d of response.message){
                    if(i>=15){
                      break;
                    }
                    aux2.push({
                      key: d['key'],
                      value: d['value']
                    });
                    aux1.push({
                      key: this.datosGrafica[i].key,
                      value: this.datosGrafica[i].value
                    });
                    i++
                  }
                  this.datosGrafica = [];
                  this.datosGrafica = aux1;
                  if (this.esGraficaDeBarras){
                    this.formatDatosBarra();
                  }
                  this.obtTablaZon1(aux2);
                }else if(this.cantidad == 'all'){
                  this.masQuin = true;
                  var aux1 =[], aux2 = [], i = 0;
                  for(const d of response.message){
                    aux2.push({
                      key: d['key'],
                      value: d['value']
                    });
                    aux1.push({
                      key: this.datosGrafica[i].key,
                      value: this.datosGrafica[i].value
                    });
                    i++
                  }
                  this.datosGrafica = [];
                  this.datosGrafica = aux1;
                  if (this.esGraficaDeBarras){
                    this.formatDatosBarra();
                  }
                  this.obtTablaZon1(aux2);
                }
              }
            });
          }
        });
    } else {
      this.tendenciaService
        // @ts-ignore
        .getTendenciaGraficaPorPerspectivas(perspectiva, this.mesSeleccionado.value, this.perTip, this.perGen, this.perMun,)
        .subscribe(response => {
          if (response.http.code === 200) {
            this.datosGrafica = response.message;
            // @ts-ignore
            this.tendenciaService.getTendenciaTablaPorPerspectivas(perspectiva, this.mesSeleccionado.value, this.perTip, this.perGen, this.perMun).subscribe( response => {
              if (response.http.code === 200) {
                if(response.message.length < 15 && this.cantidad == 'quince'){
                  if(response.message.length < 15){
                    this.masQuin = false;
                    if (this.esGraficaDeBarras){
                      this.formatDatosBarra();
                    }
                    this.obtTablaZon1(response.message);
                  }else if(this.cantidad == 'quince'){
                    this.masQuin = true;
                    var aux1 =[], aux2 = [], i = 0;
                    for(const d of response.message){
                      if(i>=15){
                        break;
                      }
                      aux2.push({
                        key: d['key'],
                        value: d['value']
                      });
                      aux1.push({
                        key: this.datosGrafica[i].key,
                        value: this.datosGrafica[i].value
                      });
                      i++
                    }
                    this.datosGrafica = [];
                    this.datosGrafica = aux1;
                    if (this.esGraficaDeBarras){
                      this.formatDatosBarra();
                    }
                    this.obtTablaZon1(aux2);
                  }else if(this.cantidad == 'all'){
                    this.masQuin = true;
                    var aux1 =[], aux2 = [], i = 0;
                    for(const d of response.message){
                      aux2.push({
                        key: d['key'],
                        value: d['value']
                      });
                      aux1.push({
                        key: this.datosGrafica[i].key,
                        value: this.datosGrafica[i].value
                      });
                      i++
                    }
                    this.datosGrafica = [];
                    this.datosGrafica = aux1;
                    if (this.esGraficaDeBarras){
                      this.formatDatosBarra();
                    }
                    this.obtTablaZon1(aux2);
                  }
                }else{

                }
              }
            });
          }
        });
    }

  }
  public metrica(cambio){
    if(cambio == 'num' && this.saldo){
      if(this.g1_barra){
        this.g1_barra = false;
        this.g1_linea = true;
        this.saldo = false;
        this.numer = true;
      }else if(this.g1_linea){
        this.g1_barra = true;
        this.g1_linea = false;
        this.saldo = false;
        this.numer = true;
      }
    }else if(cambio == 'sal' && this.numer){
      if(this.g1_barra){
        this.g1_barra = false;
        this.g1_linea = true;
        this.saldo = true;
        this.numer = false;
      }else if(this.g1_linea){
        this.g1_barra = true;
        this.g1_linea = false;
        this.saldo = true;
        this.numer = false;
      }
    }
    this.subtitulos();
  }
  public cambioCant(dat: any){
    switch(dat){
      case 1:
          this.cantidad = 'quince';
      break;
      case 2:
          this.cantidad = 'all';
      break;
    }
    this.addPerspectiva('municipio.keyword');
  }
  public click_grafica(e) {
    this.customColors = [{ name: e.name, value: '#9E9E9E'}];
    if (!isNaN(e.extra)) {
      this.aniosDisponibles.forEach(e => e.check = false);
      for(var i = 0; i < this.aniosDisponibles.length; i++){
        if(this.aniosDisponibles[i].name == e.extra)
          this.aniosDisponibles[i].check = true;
      }
      this.puedeFiltrarPorMeses = true;
      this.anioSeleccionado = this.aniosDisponibles.find(anio => anio.name === e.extra);
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === e.extra);

      this.getGraficaFiltroAnio(this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length-1].value.split('-'));
    }else if (e.extra.toString().includes('-')) {
      this.aniosDisponibles.forEach(e => e.check = false);
      for(var i = 0; i < this.aniosDisponibles.length; i++){
        if(this.aniosDisponibles[i].name == e.extra.split('-')[0])
          this.aniosDisponibles[i].check = true;
      }

      this.getGraficaFiltroAnio(e.extra.split('-'));
      this.mesSeleccionado = this.mesesDisponibles.find(mes => mes.value == e.extra)
      this.mesesDeAnioDisponibles.forEach(anio => anio.check = false);
      this.check_todosmeses = false;
      this.puedeFiltrarPorSucursales = true;
      this.mesesDeAnioDisponibles = this.mesesDeAnioDisponibles.map(
        mes => mes.value == this.mesSeleccionado['value'] ? { ...mes, check: true} : mes
      );
      this.flag_perspectivas = true;
      this.sucursalSeleccionada = {name: "CONSOLIDADO"};
    }else if (this.sucursalesDisponibles.find(sucursal => sucursal.name === e.extra)) { // Es una sucursal
      for(const suc of this.sucursalesDisponibles){
        suc.check = false;
        if(suc.name == e.extra){
          suc.check = true;
        }
      }
      this.sucursalSeleccionada = this.sucursalesDisponibles.find(sucursal => sucursal.name === e.extra);
      this.puedeFiltrarPorPerspectivas = true;
    }else{
      switch(this.perspectivaAct){
        case 'Tipo':
          this.perTip = e.name;
        break;
        case 'Genero':
          this.perGen = e.name;
        break;
        case 'Municipio':
          this.perMun = e.name;
        break;
      }
    }
    this.subtitulos();
    this.subtitulos2();
  }

  public buscarSucursal(nombre) {
    return this.sucursales.filter(sucursal => sucursal.key == nombre)[0].key_as_string;
  }
  public yLeftTickFormat(valor) {
    return CommonFunctions.retornarformatolefty(valor);
  }
  public formatDataLabel(num) {
    num = num.toFixed(0);
    num += '';
    const splitStr = num.split('.');
    let splitLeft = splitStr[0];
    const splitRight = splitStr.length > 1 ? '.' + splitStr[1] : '';
    const regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + ',' + '$2');
    }
    return splitLeft + splitRight;
  }
  public cambiarGrafica(grafica: string): void {
    switch (grafica){
      case 'barras':
        this.g1_barra = true;
        this.g1_linea = false;
        this.g1_area = false;
        this.g1_tabla = false;
        break;
      case 'lineal':
        this.g1_barra = false;
        this.g1_linea = true;
        this.g1_area = false;
        this.g1_tabla = false;
        break;
      case 'area':
        this.g1_barra = false;
        this.g1_linea = false;
        this.g1_area = true;
        this.g1_tabla = false;
        break;
      case 'tabla':
        this.g1_barra = false;
        this.g1_linea = false;
        this.g1_area = false;
        this.g1_tabla = true;
        break;
    }
  }
  // grafica 1

  getTabla() {
    this.elasticSearch.consultaGeneral('cuentas/estatus').subscribe(
      response => {

        this.table_data = response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets;
        response.message.agrupar_por_creditos.agrupar_por_clasificacion.buckets.forEach(row => {
          // this.total_no_contratos += row.total_contratos.value;
          // this.total_suma_cartera += row.suma_carteratotal.value;
        });

      }
    );
  }
  // Tabla cuadrante2
  public formatearNormal(num){
    num = Number(num).toFixed(2);
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if(splitRight != '.00')
      return  splitLeft + splitRight;
    else
      return  splitLeft;
  }
  // formateo de Grafica de Barras
  private formatDatosBarra(): void {
    this.datosBarra = [];
    this.datosLineal = [];
    for (const elemento of this.datosGrafica) {
      /*this.datosBarra.push({
        // @ts-ignore
        name:  elemento.key,
        // @ts-ignore
        value: elemento.value,
        // @ts-ignore
      });*/
      if(elemento.key.includes('-')){
        this.datosBarra.push({
          // @ts-ignore
          name:  this.abrebMes(elemento.key.split('-')[1]),
          // @ts-ignore
          value: elemento.value,
          extra:  elemento.key
        });
      }else{
        this.datosBarra.push({
          // @ts-ignore
          name:  elemento.key,
          // @ts-ignore
          value: elemento.value,
          extra:  elemento.key
        });
      }
    }
    if(this.puedeFiltrarPorSucursales){
      this.ordLin(this.datosBarra);
      this.datosBarra = this.dataAux;
    }
    this.datosLineal = [{"name":"TendenciaLineal","series":this.datosBarra}];
  }
  public abrebMes(mes: any){
    switch(mes){
      case '01': return 'ene';
      case '02': return 'feb';
      case '03': return 'mar';
      case '04': return 'abr';
      case '05': return 'may';
      case '06': return 'jun';
      case '07': return 'jul';
      case '08': return 'ago';
      case '09': return 'sep';
      case '10': return 'oct';
      case '11': return 'nov';
      case '12': return 'dic';
    }
    return mes;
  }
  private ConstruirTabla(datos): void{
    this.dataSource = [];
    this.datatablaOr = [];
    this.dataCASO = [];
    this.dataCAFO = [];
    this.dataAHSO = [];
    this.dataAHCC = [];
    this.dataCRECE = [];
    this.dataAHME = [];
    this.dataHAME = [];
    this.dataHAEX = [];
    this.dataTDSR = [];
    this.dataPAGARE = [];
    this.dataCEDE = [];

    this.dataCASOlin = [];
    this.dataCAFOlin = [];
    this.dataAHSOlin = [];
    this.dataAHCClin = [];
    this.dataCRECElin = [];
    this.dataAHMElin = [];
    this.dataHAMElin = [];
    this.dataHAEXlin = [];
    this.dataTDSRlin = [];
    this.dataPAGARElin = [];
    this.dataCEDElin = [];

   this.displayedColumns = ["sucursal","caso","cafo","ahso","ahcc","crece","ahme","iane","hame","haex","tdsr","pagare","cede"];
    var filtporSuc = 0;
    for (const elemento of datos) {
      if(this.sucursalesDisponibles.length != 0){
        for(const suc of this.sucursalesDisponibles){
          if( (elemento.key == suc.name && suc.check) && this.puedeFiltrarPorSucursales){
            this.datatablaOr.push({sucursal:elemento.key, caso:elemento.value[0].value, cafo:elemento.value[1].value, ahso:elemento.value[2].value, ahcc:elemento.value[3].value, crece: elemento.value[4].value,
              ahme: elemento.value[5].value, iane: elemento.value[6].value, hame: elemento.value[7].value, haex: elemento.value[8].value, tdsr: elemento.value[9].value, pagare: elemento.value[10].value
              , cede: elemento.value[11].value});
            this.dataSource.push({sucursal:elemento.key, caso:elemento.value[0].value, cafo:elemento.value[1].value, ahso:elemento.value[2].value, ahcc:elemento.value[3].value, crece: elemento.value[4].value,
              ahme: elemento.value[5].value, iane: elemento.value[6].value, hame: elemento.value[7].value, haex: elemento.value[8].value, tdsr: elemento.value[9].value, pagare: elemento.value[10].value
              , cede: elemento.value[11].value});
            this.dataCASO.push({name: elemento.key, value: elemento.value[0].value});
            this.dataCAFO.push({name: elemento.key, value: elemento.value[1].value});
            this.dataAHSO.push({name: elemento.key, value: elemento.value[2].value});
            this.dataAHCC.push({name: elemento.key, value: elemento.value[3].value});
            this.dataCRECE.push({name: elemento.key, value: elemento.value[4].value});
            this.dataAHME.push({name: elemento.key, value: elemento.value[5].value});
            this.dataHAME.push({name: elemento.key, value: elemento.value[7].value});
            this.dataHAEX.push({name: elemento.key, value: elemento.value[8].value});
            this.dataTDSR.push({name: elemento.key, value: elemento.value[9].value});
            this.dataPAGARE.push({name: elemento.key, value: elemento.value[10].value});
            this.dataCEDE.push({name: elemento.key, value: elemento.value[11].value});
            filtporSuc ++;
          }
        }
      }
    }
    if(filtporSuc == 0){
      for (const elemento of datos) {
        this.dataSource.push({sucursal:elemento.key, caso:elemento.value[0].value, cafo:elemento.value[1].value, ahso:elemento.value[2].value, ahcc:elemento.value[3].value, crece: elemento.value[4].value,
          ahme: elemento.value[5].value, iane: elemento.value[6].value, hame: elemento.value[7].value, haex: elemento.value[8].value, tdsr: elemento.value[9].value, pagare: elemento.value[10].value
          , cede: elemento.value[11].value});
        this.datatablaOr.push({sucursal:elemento.key, caso:elemento.value[0].value, cafo:elemento.value[1].value, ahso:elemento.value[2].value, ahcc:elemento.value[3].value, crece: elemento.value[4].value,
          ahme: elemento.value[5].value, iane: elemento.value[6].value, hame: elemento.value[7].value, haex: elemento.value[8].value, tdsr: elemento.value[9].value, pagare: elemento.value[10].value
          , cede: elemento.value[11].value});
        this.dataCASO.push({name: elemento.key, value: elemento.value[0].value});
        this.dataCAFO.push({name: elemento.key, value: elemento.value[1].value});
        this.dataAHSO.push({name: elemento.key, value: elemento.value[2].value});
        this.dataAHCC.push({name: elemento.key, value: elemento.value[3].value});
        this.dataCRECE.push({name: elemento.key, value: elemento.value[4].value});
        this.dataAHME.push({name: elemento.key, value: elemento.value[5].value});
        this.dataHAME.push({name: elemento.key, value: elemento.value[7].value});
        this.dataHAEX.push({name: elemento.key, value: elemento.value[8].value});
        this.dataTDSR.push({name: elemento.key, value: elemento.value[9].value});
        this.dataPAGARE.push({name: elemento.key, value: elemento.value[10].value});
        this.dataCEDE.push({name: elemento.key, value: elemento.value[11].value});
      }
    }
    //this.dataSource.sort = this.sort;

    this.ordLin(this.dataCASO);
    this.dataCASO = this.dataAux;
    this.ordLin(this.dataCAFO);
    this.dataCAFO = this.dataAux;
    this.ordLin(this.dataAHSO);
    this.dataAHSO = this.dataAux;
    this.ordLin(this.dataAHCC);
    this.dataAHCC = this.dataAux;
    this.ordLin(this.dataCRECE);
    this.dataCRECE = this.dataAux;
    this.ordLin(this.dataAHME);
    this.dataAHME = this.dataAux;
    this.ordLin(this.dataHAME);
    this.dataHAME = this.dataAux;
    this.ordLin(this.dataHAEX);
    this.dataHAEX = this.dataAux;
    this.ordLin(this.dataTDSR);
    this.dataTDSR = this.dataAux;
    this.ordLin(this.dataPAGARE);
    this.dataPAGARE = this.dataAux;
    this.ordLin(this.dataCEDE);
    this.dataCEDE = this.dataAux;

    this.dataCASOlin.push({name: 'CASO', series: this.dataCASO});
    this.dataCAFOlin.push({name: 'ÇAFO', series: this.dataCAFO});
    this.dataAHSOlin.push({name: 'AHSO', series: this.dataAHSO});
    this.dataAHCClin.push({name: 'AHCC', series: this.dataAHCC});
    this.dataCRECElin.push({name: 'CRECE', series: this.dataCRECE});
    this.dataAHMElin.push({name: 'AHME', series:  this.dataAHME});
    this.dataHAMElin.push({name: 'HAME', series:  this.dataHAME});
    this.dataHAEXlin.push({name: 'HAEX', series:  this.dataHAEX});
    this.dataTDSRlin.push({name: 'TDSR', series:  this.dataTDSR});
    this.dataPAGARElin.push({name: 'PAGARE', series:  this.dataPAGARE});
    this.dataCEDElin.push({name: 'CEDE', series:  this.dataCEDE});
  }
  public dataAux = [];
  public ordLin(datos: any): void{
    datos.sort(function (a, b) {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    this.dataAux = [];
    this.dataAux = datos;
  }
  public formatGraficasActivosInactivos(datos): void{
    this.data = [];
    this.datatabla3 = [];
    this.databarra3 = [];
    this.datamapa = [];
    var suma = 0;
    for (const elemento of datos){
      let estatus = "";
      if(elemento['key'] == 'SOCIO'){
        estatus = elemento['key'];
        var arr = [estatus, elemento["value"]];
        suma+=elemento["value"];
        this.datamapa.push({name: estatus, value: elemento["value"]});
        this.data.push(arr);
        this.datatabla3.push({ name: elemento['key'], value: elemento["value"], porcetaje: 0});
      }else if(elemento['key'] == 'AHORRADOR MENOR'){
        estatus = elemento['key'];
        var arr = [estatus, elemento["value"]];
        suma+=elemento["value"];
        this.datamapa.push({name: estatus, value: elemento["value"]});
        this.data.push(arr);      
        this.datatabla3.push({ name: elemento['key'], value: elemento["value"], porcetaje: 0});
      }else if(elemento['key'] == 'ASPIRANTE'){
        estatus = elemento['key'];
        var arr = [estatus, elemento["value"]];
        suma+=elemento["value"];
        this.datamapa.push({name: estatus, value: elemento["value"]});
        this.data.push(arr);
        this.datatabla3.push({ name: elemento['key'], value: elemento["value"], porcetaje: 0});
      }
    }
    for(const dat of this.datatabla3){
      dat['porcentaje'] = ((dat['value']/suma)*100).toFixed(2); 
    }
    this.datatabla3.push({name: '', value: suma, porcentaje: 100})
    this.databarra3 = this.datamapa;
  }
  /** Exportación */
  public export_excel(zon){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    name = 'Tendencia';
    var perspectiva = '';
    switch(zon){
      case 1:
        var encabezado_excel = [''+this.tit1(),''+this.tit2(),'SALDO','NÚMERO DE CUENTAS'];
        var cadena_evaluacion = encabezado_excel;
        var mes = 0;
        for(const dato of this.datatabla){
          aux.push(dato['anio']);
          aux.push(dato['mes']);
          aux.push(dato['saldo']);
          aux.push(dato['ncuen']);
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 2:
        var encabezado_excel = ['SUCURSAL','CASO','CAFO','ASHO','AHCC','CRECE','AHME','IANE','HAME','HAEX','TDSR','PAGARE','CEDE'];
        var cadena_evaluacion = encabezado_excel;
        var mes = 0;
        for(const dato of this.dataSource){
          aux.push(dato['sucursal']);
          aux.push(dato['caso']);
          aux.push(dato['cafo']);
          aux.push(dato['ahso']);
          aux.push(dato['ahcc']);
          aux.push(dato['crece']);
          aux.push(dato['ahme']);
          aux.push(dato['iane']);
          aux.push(dato['hame']);
          aux.push(dato['haex']);
          aux.push(dato['tdsr']);
          aux.push(dato['pagare']);
          aux.push(dato['cede']);
          data_excel.push(aux);
          aux = [];
        }
      break;
      case 3:
        var encabezado_excel = ['TIPO','NÚMERO DE SOCIOS','PORCENTAJE'];
        var cadena_evaluacion = encabezado_excel;
        var mes = 0;
        for(const dato of this.datatabla3){
          aux.push(dato['name']);
          aux.push(dato['value']);
          aux.push(Number(dato['porcentaje']));
          data_excel.push(aux);
          aux = [];
        }
      break;
    }
    this.excelservices.generateExcelTenenciaComercial(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva,mes);
  }
  public export_imagen(zon: any){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 1:
        grafica = 'Zon1';
        name = 'Tendencia';
      break
      case 2:
        grafica = 'Zon2';
        name = 'Tendencia';
      break
      case 3:
        grafica = 'Zon3';
        name = 'Tendencia';
      break
    }
    PDF.exportimagenes(grafica,name);
  }

  fshowDataLabelT1C2(){}
  fregresart1c2(){}
  ClasificacionCaptacion(){}
  ClasificacionCartera(){}
  export_csvT1C2(){}
  ExportarPDFT1_C2(){}
  setFlagElementoSeleccionadoT1C2(){}
  inspeccionar(){}
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}