import {MessageService} from '..//base/message.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Service} from '..//base/service';
import {PatmirEntity} from '../../entities/base/DashboardEntities';

import {Loggers} from '../../util/app-util';


@Injectable({
  providedIn: 'root'
})
export class PatmirService extends Service<PatmirEntity> {

  httpClient: HttpClient;

  constructor(
      http: HttpClient,
      messageService: MessageService,
      protected logger: Loggers
  ) {
      super('PatmirService', 'dashboard/patmir', http, messageService, logger);
      this.httpClient = http;
  }

  protected getParams(entity: PatmirEntity): HttpParams {
      let params: HttpParams = new HttpParams();
      if (null == entity) { return params; }

      if (null != entity.TimeId) { params = params.set('TimeId', entity.TimeId.toString()); }
      if (null != entity.AnioDsc) { params = params.set('AnioDsc', entity.AnioDsc.toString()); }
      if (null != entity.MesDsc) { params = params.set('MesDsc', entity.MesDsc.toString()); }
      if (null != entity.SexoId) { params = params.set('SexoId', entity.SexoId.toString()); }
      if (null != entity.SexoDsc) { params = params.set('SexoDsc', entity.SexoDsc.toString()); }
      if (null != entity.SexoDsc) { params = params.set('SexoDsc', entity.SexoDsc.toString()); }
      if (null != entity.InstitucionPatmirId) { params = params.set('InstitucionPatmirId', entity.InstitucionPatmirId.toString()); }
      if (null != entity.Acronimo) { params = params.set('Acronimo', entity.Acronimo.toString()); }
      if (null != entity.SucursalId) { params = params.set('SucursalId', entity.SucursalId.toString()); }
      if (null != entity.SucursalDsc) { params = params.set('SucursalDsc', entity.SucursalDsc.toString()); }
      if (null != entity.EstatusPatmirId) { params = params.set('EstatusPatmirId', entity.EstatusPatmirId.toString()); }
      if (null != entity.EstatusPatmirDsc) { params = params.set('EstatusPatmirDsc', entity.EstatusPatmirDsc.toString()); }
      if (null != entity.TipoGradId) { params = params.set('TipoGradId', entity.TipoGradId.toString()); }
      if (null != entity.TipoGradDsc) { params = params.set('TipoGradDsc', entity.TipoGradDsc.toString()); }
      if (null != entity.EstadoId) { params = params.set('EstadoId', entity.EstadoId.toString()); }
      if (null != entity.EstadoDsc) { params = params.set('EstadoDsc', entity.EstadoDsc.toString()); }
      if (null != entity.MunicipioId) { params = params.set('MunicipioId', entity.MunicipioId.toString()); }
      if (null != entity.MunicipioDsc) { params = params.set('MunicipioDsc', entity.MunicipioDsc.toString()); }
      if (null != entity.LocalidadId) { params = params.set('LocalidadId', entity.LocalidadId.toString()); }
      if (null != entity.LocalidadDsc) { params = params.set('LocalidadDsc', entity.LocalidadDsc.toString()); }
      if (null != entity.AdultoMenorId) { params = params.set('AdultoMenorId', entity.AdultoMenorId.toString()); }
      if (null != entity.AdultoMenorDsc) { params = params.set('AdultoMenorDsc', entity.AdultoMenorDsc.toString()); }
      if (null != entity.EdadId) { params = params.set('EdadId', entity.EdadId.toString()); }
      if (null != entity.EdadDsc) { params = params.set('EdadDsc', entity.EdadDsc.toString()); }
      if (null != entity.SegmentoId) { params = params.set('SegmentoId', entity.SegmentoId.toString()); }
      if (null != entity.SegmentoDsc) { params = params.set('SegmentoDsc', entity.SegmentoDsc.toString()); }
      if (null != entity.saldo) { params = params.set('saldo', entity.saldo.toString()); }
      if (null != entity.socGradPlus) { params = params.set('socGradPlus', entity.socGradPlus.toString()); }
      if (null != entity.socGrad) { params = params.set('socGrad', entity.socGrad.toString()); }
      if (null != entity.socPre) { params = params.set('socPre', entity.socPre.toString()); }
      if (null != entity.socProc) { params = params.set('socProc', entity.socProc.toString()); }
      if (null != entity.socGradEnMes) { params = params.set('socGradEnMes', entity.socGradEnMes.toString()); }
      if (null != entity.socios) { params = params.set('socios', entity.socios.toString()); }

      return params;
  }

}
