import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data-service/data.service';
import {FuncionesColores} from '../../shared/funciones-colores/funciones_colores';
import {FiltrosService} from '../../../services/filtros/filtros.service';
import {MetaVsRealService} from './../../../services/colocacion/meta-vs-real/meta-vs-real.service'
import { PDFSecond, PDF } from '../../../services/exportar/exportpdf';
import { ExcelService } from './../../../services/exportar/excel.service';
import { MatSort ,Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface UserData {
  name: string,
  value: string,
  check: boolean,
}

@Component({
  selector: 'app-meta-vs-real',
  templateUrl: './meta-vs-real.component.html',
  styleUrls: ['./meta-vs-real.component.css']
})
export class MetaVsRealComponent implements OnInit {

  constructor(
    public dataService: DataService,
    private filtrosService: FiltrosService,
    private metavsrealservice: MetaVsRealService,
    private excelservices: ExcelService,
  ) {
    this.getInformacion();
  }

  multi = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2000",
          "value": 40632,
          "extra": {
            "code": "de"
          }
        },
        {
          "name": "1990",
          "value": 36953,
          "extra": {
            "code": "de"
          }
        }
      ]
    },
    {
      "name": "France",
      "series": [
        {
          "name": "2000",
          "value": 36745,
          "extra": {
            "code": "fr"
          }
        },
        {
          "name": "1990",
          "value": 34774,
          "extra": {
            "code": "fr"
          }
        }
      ]
    },
    {
      "name": "Guadeloupe",
      "series": [
        {
          "name": "1990",
          "value": 18469
        },
        {
          "name": "2000",
          "value": 43956
        }
      ]
    },
    {
      "name": "Sudan",
      "series": [
        {
          "name": "1990",
          "value": 12918
        },
        {
          "name": "2000",
          "value": 59563
        }
      ]
    },
    {
      "name": "Iceland",
      "series": [
        {
          "name": "1990",
          "value": 18443
        },
        {
          "name": "2000",
          "value": 41467
        }
      ]
    },
    {
      "name": "Brazil",
      "series": [
        {
          "name": "1990",
          "value": 23453
        },
        {
          "name": "2000",
          "value": 39870
        }
      ]
    },
    {
      "name": "Kenya",
      "series": [
        {
          "name": "1990",
          "value": 17360
        },
        {
          "name": "2000",
          "value": 47430
        }
      ]
    },
    {
      "name": "Tanzania",
      "series": [
        {
          "name": "1990",
          "value": 25299
        },
        {
          "name": "2000",
          "value": 49177
        }
      ]
    }
  ];
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /**
   * Variables filtros
   */
  public aniosDisponibles: any [] = [];
  public aniosSeleccionados: any [] = [];
  public mesesDisponibles: any [] = [];
  public mesesDeAnioDisponibles: any[] = [];
  public mesesSeleccionados: any [] = [];
  public sucursalesDisponibles: any[];
  public sucursalesDisponibles2: MatTableDataSource<UserData>;
  public puedeFiltrarPorSucursales = false;
  public numeroDeSucursalesSeleccionadas = 0;
  public ult_anio: any [] = ['2020'];
  public ult_mes: any [] = ['dic'];

  public consolidado = true;
  public subtituloGrafica1: string =  "";
  public sucursal_seleccionada: string = "CONSOLIDADO";
  /**
   * Variables de las gráficas
   */
  public graficatabla: boolean = true;

  public graficabarrasverC1: boolean = false;
  public graficabarrashorC1: boolean = false;
  public graficalinealC1: boolean = false;
  public graficaareaC1: boolean = false;

  public graficabarrasverC2: boolean = false;
  public graficabarrashorC2: boolean = false;
  public graficalinealC2: boolean = false;
  public graficaareaC2: boolean = false;
  // Modal
  public graficatabla_modal: boolean = true;
  public graficabarrasverC1_modal: boolean = false;
  public graficabarrashorC1_modal: boolean = false;
  public graficalinealC1_modal: boolean = false;
  public graficaareaC1_modal: boolean = false;

  public graficabarrasverC2_modal: boolean = false;
  public graficabarrashorC2_modal: boolean = false;
  public graficalinealC2_modal: boolean = false;
  public graficaareaC2_modal: boolean = false;

  public graficasuperior: boolean = false;
  public graficainferior: boolean = false;
  // Construccion de la tabla
  public columsName: string[] = ['sucursal', 'proyectado', 'real', 'logrado', 'proyectado1', 'real1', 'logrado1', 'adulto', 'menor'];
  public columsName2: string[] = ['sucursal', 'proyectado', 'real', 'logrado'];
  public columsName3: string[] = ['sucursal', 'proyectado', 'real'];
  public tableDataSource = new MatTableDataSource<object>();
  public dataTabla = [];
  public dataTabla2 = [];
  public titulo = [];
  public showModal: boolean = false
  public titulomodal = '';
  public subtitulomodal = '';
  //Construcción del pie
  public datalinealAdulto = [];
  public datalinealMenor = [];
  public databarrasAdulto = [];
  public databarrasMenor = [];
  // options
  showXAxis = true;
  showYAxis = true;
  showLegend = true;
  legendPosition = 'below';
  legendTitle = '';
  showYAxisLabel = true;
  yAxisLabel = 'Metas';
  groupPadding = 1;

  colorSchemeAdultos = {
    domain: ['#004A97', '#3BB0C9', '#ff8300', '#f3b49f', '#f6c7b6','#301CDCFF', '#67C2F6FF','#301CDCFF', '#67C2F6FF'],
  };

  colorSchemeMenores = {
    domain: ['#006F42', '#77BC1F', '#A4D55D', '#FC8A33','#002F7C', '#0075C9', '#5EAEE5', '#FC8A33'],
  };

  ngOnInit(): void {
  }
  data = [];
  public getInformacion(){
    var ult_anio = [];
    var ult_mes = [];
    this.dataTabla = [];
    this.filtrosService.getAniosMesesDisponibles('aseguradora_meta').subscribe( response =>{
      const tiempoFiltros = this.filtrosService.formatAnioMesDisponible(response);
      this.aniosDisponibles = tiempoFiltros.aniosDisponibles;
      this.mesesDisponibles = tiempoFiltros.mesesAniosDisponibles;
      ult_anio = tiempoFiltros.ult_anio;
      ult_mes = tiempoFiltros.ult_mes;
      this.ult_anio = ult_anio;
      this.ult_mes = ult_mes;
      this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === ult_anio[0]);
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length-1].check =  true;
      this.aniosMesesSeleccionados();
      this.datos1();
    });
    /*this.filtrosService.getSucursalesDisponibles('aseguradora', 'sucursal-descripcion').subscribe(response => {
      this.sucursalesDisponibles = this.filtrosService.formatSucursalesDisponibles(response);
    });*/
  }
  public datos1(){
    
    this.metavsrealservice.getLogradoAdulto(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.sucursalesDisponibles = [];
          for(const suc of this.data){
            this.sucursalesDisponibles.push({
              name: suc['sucursal'],
              value:suc['sucursal'],
              check: true,
            });
          }
          this.sucursalesDisponibles2 = new MatTableDataSource(this.sucursalesDisponibles);
          this.getTabla(this.data);
        }
        
      }
    });
  }
  public datos(){
    this.metavsrealservice.getLogradoAdulto(this.ult_anio,this.filtrosService.getMesPrefijoReves(this.ult_mes[0]),this.sucursal_seleccionada).subscribe(response => {
      if (response.http.code === 200) {
        this.data = response.message;
        if (this.data){
          this.getTabla(this.data);
        }
      }
    });
  }
  public lineal(array: any){
    /** Datos Adulto */
    this.datalinealAdulto = [];
    var dat1 = [];
    var dat2 = [];
    for(const dato of array){
      const nombres = dato.series;
        dat1.push({
          name: dato['sucursal'],
          value: dato['proyectado1']
        });
        dat2.push({
          name: dato['sucursal'],
          value: dato['real1']
        });
    }
    this.datalinealAdulto.push({
      name : 'Proyectado',
      series: dat1
    },{
      name : 'Real',
      series: dat2
    });
    /** Datos Menor */
    this.datalinealMenor = [];
    var dat1 = [];
    var dat2 = [];
    for(const dato of array){
      const nombres = dato.series;
        dat1.push({
          name: dato['sucursal'],
          value: dato['proyectado2']
        });
        dat2.push({
          name: dato['sucursal'],
          value: dato['real2']
        });
    }
    this.datalinealMenor.push({
      name : 'Proyectado',
      series: dat1
    },{
      name : 'Real',
      series: dat2
    });
  }
  public barras(array: any){
    this.databarrasAdulto = [];
    this.databarrasMenor = [];
    for(const dato of array){
      this.databarrasAdulto.push({
        name: dato['sucursal'],
        series: [{
          name: 'Proyectado',
          value: dato['proyectado1']
        },{
          name: 'Real',
          value: dato['real1']
        }]
      })
      this.databarrasMenor.push({
        name: dato['sucursal'],
        series: [{
          name: 'Proyectado',
          value: dato['proyectado2']
        },{
          name: 'Real',
          value: dato['real2']
        }]
      })
    }
  }
  public getTabla(array: any){
    this.dataTabla = [];
    this.dataTabla2 = [];
    var i = 0;
    console.log(array.length, array)
    for(const dato of array){
      const suc = this.sucursalesDisponibles.filter(s => quitarAcentos(s.name) === quitarAcentos(dato.sucursal) || quitarAcentos(dato.sucursal).includes(quitarAcentos(s.name)));
      if( suc.length !== 0 && suc[0].check === true){
        console.log('entro')
        this.dataTabla.push({
          sucursal: dato['sucursal'],
          proyectado1: Math.trunc(dato['proyectadoAdulto']),
          real1: Math.trunc(dato['realAdulto']),
          logrado1: Math.round(Number((dato['logradoAdulto']*100).toFixed(2))),
          proyectado2: Math.trunc(dato['proyectadoMenor']),
          real2: Math.trunc(dato['realMenor']),
          logrado2: Math.round(Number((dato['logradoMenor']*100).toFixed(2))),
          adulto: Math.round(Number((dato['metaAdulto']*100).toFixed(2))),
          menor: Math.round(Number((dato['metaMenor']*100).toFixed(2))),
        });
      }
      i++;
    }
    this.lineal(this.dataTabla);
    this.barras(this.dataTabla);
    var sum1 = 0,sum2 = 0,sum3 = 0,sum4 = 0,sum5 = 0,sum6 = 0,sum7 = 0,sum8 = 0;
    for(const dato of this.dataTabla){
      sum1 += dato['proyectado1'];
      sum2 += dato['real1'];
      sum3 += dato['logrado1'];
      sum4 += dato['proyectado2'];
      sum5 += dato['real2'];
      sum6 += dato['logrado2'];
      sum7 += dato['adulto'];
      sum8 += dato['menor'];
    }
    var div = this.dataTabla.length;
    this.dataTabla.push({
      sucursal: 'TOTAL',
      proyectado1: sum1,
      real1: sum2,
      logrado1: Math.round(Number(((sum2/sum1)*100).toFixed(2))),
      proyectado2: sum4,
      real2: sum5,
      logrado2: Math.round(Number(((sum5/sum4)*100).toFixed(2))),
      adulto: Math.round(Number((sum7/div).toFixed(2))),
      menor: Math.round(Number((sum8/div).toFixed(2))),
    });
  }
  public texto(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
  }
  public textoC3C6(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
    else
    return 'background-color:#E7E6E6'
  }
  public textoC2C5(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
    else
    return 'background-color:#f1f1f1'
  }
  public textoC1(indice){
    if(indice == this.dataTabla.length-1)
      return 'font-weight: bold; background-color:#E7E6E6'
    else
    return 'background-color:#fff'
  }
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft + splitRight;
  }
  // filtros
  public anioChangeListener(anioClick: object): void {
    this.ult_anio = [];
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(anioClick, this.aniosDisponibles);
    const anio_selecc = this.aniosDisponibles.find(anio => anio.name === anioClick['name']);
    this.ult_anio.push(anio_selecc['name']);
    this.mesesDeAnioDisponibles = this.mesesDisponibles.filter(mes => mes.year === this.ult_anio[0]);
    this.mesesDeAnioDisponibles.forEach(dat => dat.check = false);
    this.buscarMesSeleccionado();
    this.aniosMesesSeleccionados();
    this.FiltrosAnioMesSucursalSeleccionado();
    this.datos();
  }
  public buscarMesSeleccionado(){
    var flag = false;
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['name'] == this.ult_mes[0]){
        valor['check'] = true;
        flag = true;
      }
    }
    if(!flag){
      this.mesesDeAnioDisponibles[this.mesesDeAnioDisponibles.length - 1].check = true;
    }
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']){
        this.ult_mes = [valor['name']];
      }
    }
  }
  public borrarFil(){
    this.graficatabla = true;
    this.graficabarrasverC1 = false;
    this.graficabarrashorC1 = false;
    this.graficalinealC1 = false;
    this.graficaareaC1 = false;

    this.graficabarrasverC2 = false;
    this.graficabarrashorC2 = false;
    this.graficalinealC2 = false;
    this.graficaareaC2 = false;
    this.sucursal_seleccionada = "CONSOLIDADO";
    this.getInformacion();
  }
  //Mes
  public mesChangeListener(mesClick: object, elementArray: object[]): void {
    this.filtrosService.changeElementCheckStatusFiltroUnAnio(mesClick, elementArray);
    this.ult_mes = [];
    for (const valor of this.mesesDeAnioDisponibles){
      if (valor['check']== true){
        this.ult_mes.push(valor['name'])
      }
    }
    this.aniosMesesSeleccionados();
    this.FiltrosAnioMesSucursalSeleccionado();
    this.datos();
  }
  private aniosMesesSeleccionados(): void {
    this.aniosSeleccionados = [];
    this.mesesSeleccionados = [];
    for (const valor of this.aniosDisponibles) {
      if (valor.check == true)
        this.aniosSeleccionados.push(valor);
    }
    for (const valor of this.mesesDeAnioDisponibles) {
      if (valor.check == true)
        this.mesesSeleccionados.push(valor);
    }
    if(this.graficatabla)
      this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
    else
      this.subtituloGrafica1 = this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
  }
  public ContarMeses(){
    if(this.mesesSeleccionados.length < 5 ){
      return "w3-row w3-tiny hijoizquierda";
    }else{
      return "w3-row w3-tiny ";
    }
  }
  //Sucursales
  public consolidadoChangeListener(): void {
    if(!this.consolidado){
      this.consolidado = !this.consolidado;
      this.sucursal_seleccionada = "CONSOLIDADO";
      this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
    }
    for(const suc of this.sucursalesDisponibles){
        suc['check'] = true;
    }
    this.FiltrosAnioMesSucursalSeleccionado();
    this.datos();
  }
  public sucursalChangeListener(sucursal: any){
    this.consolidado = false;
    this.sucursal_seleccionada = "SUCURSALES MULTIPLES";
    this.subtituloGrafica1 = this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
    for(const suc of this.sucursalesDisponibles){
      if(suc['name'] == sucursal.name){
        suc['check'] = !suc['check'];
      }
    }
    this.datos();
  }
  public FiltrosAnioMesSucursalSeleccionado(){
    if(this.graficatabla)
      this.subtituloGrafica1 = this.sucursal_seleccionada + " | " + this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
    else
      this.subtituloGrafica1 = this.ult_anio[0].substring(2,4) + " | " + this.ult_mes[0];
  }
  //cambio de graficas
  public cambioGraf(tipo: any){
    switch(tipo){
      case 'tabla':
        this.graficatabla = true;
        this.graficabarrasverC1 = false;
        this.graficabarrashorC1 = false;
        this.graficabarrasverC2 = false;
        this.graficabarrashorC2 = false;
        this.graficaareaC1 = false;
        this.graficaareaC2 = false;
        this.graficalinealC1 = false;
        this.graficalinealC2 = false;
      break;
      case 'barrasv':
        this.graficabarrasverC1 = true;
        this.graficabarrasverC2 = true;
        this.graficabarrashorC1 = false;
        this.graficabarrashorC2 = false;
        this.graficaareaC1 = false;
        this.graficaareaC2 = false;
        this.graficalinealC1 = false;
        this.graficalinealC2 = false;

        this.graficatabla = false;
      break;
      case 'barrash':
        this.graficabarrashorC1 = true;
        this.graficabarrashorC2 = true;
        this.graficatabla = false;
        this.graficabarrasverC1 = false;
        this.graficabarrasverC2 = false;
        this.graficaareaC1 = false;
        this.graficaareaC2 = false;
        this.graficalinealC1 = false;
        this.graficalinealC2 = false;
      break;
      case 'lineal':
        this.graficabarrashorC1 = false;
        this.graficabarrashorC2 = false;
        this.graficatabla = false;
        this.graficabarrasverC1 = false;
        this.graficabarrasverC2 = false;
        this.graficaareaC1 = false;
        this.graficaareaC2 = false;
        this.graficalinealC1 = true;
        this.graficalinealC2 = true;
      break;
      case 'area':
        this.graficabarrashorC1 = false;
        this.graficabarrashorC2 = false;
        this.graficatabla = false;
        this.graficabarrasverC1 = false;
        this.graficabarrasverC2 = false;
        this.graficaareaC1 = true;
        this.graficaareaC2 = true;
        this.graficalinealC1 = false;
        this.graficalinealC2 = false;
      break;

      case 'barrasvC1':
        this.graficabarrasverC1 = true;
        this.graficabarrashorC1 = false;
        this.graficaareaC1 = false;
        this.graficalinealC1 = false;
        this.graficatabla = false;
      break;
      case 'barrashC1':
        this.graficabarrashorC1 = true;
        this.graficatabla = false;
        this.graficabarrasverC1 = false;
        this.graficaareaC1 = false;
        this.graficalinealC1 = false;
      break;
      case 'linealC1':
        this.graficabarrashorC1 = false;
        this.graficatabla = false;
        this.graficabarrasverC1 = false;
        this.graficaareaC1 = false;
        this.graficalinealC1 = true;
      break;
      case 'areaC1':
        this.graficabarrashorC1 = false;
        this.graficatabla = false;
        this.graficabarrasverC1 = false;
        this.graficaareaC1 = true;
        this.graficalinealC1 = false;
      break;

      case 'barrasvC2':
        this.graficabarrasverC2 = true;
        this.graficabarrashorC2 = false;
        this.graficaareaC2 = false;
        this.graficalinealC2 = false;
        this.graficatabla = false;
      break;
      case 'barrashC2':
        this.graficabarrashorC2 = true;
        this.graficatabla = false;
        this.graficabarrasverC2 = false;
        this.graficaareaC2 = false;
        this.graficalinealC2 = false;
      break;
      case 'linealC2':
        this.graficabarrashorC2 = false;
        this.graficatabla = false;
        this.graficabarrasverC2 = false;
        this.graficaareaC2 = false;
        this.graficalinealC2 = true;
      break;
      case 'areaC2':
        this.graficabarrashorC2 = false;
        this.graficatabla = false;
        this.graficabarrasverC2 = false;
        this.graficaareaC2 = true;
        this.graficalinealC2 = false;
      break;
    }
    this.aniosMesesSeleccionados();
  }
  public act(){
    if(!this.graficatabla)
      return true;
    else
      return false;
  }
  public pintar_borde(porcentaje){
    if(porcentaje >= 90)
      return "w3-border-verdee";
    if(porcentaje >=80 && porcentaje < 90)
      return "w3-border-amarilloo";
    if(porcentaje < 80)
      return "w3-border-rojoo";
  }
  //tabla
  public formatoMes(mes){
    switch(mes){
      case 'ene': return 'ene';
      case 'feb': return 'feb';
      case 'mar': return 'mar';
      case 'abr': return 'abr';
      case 'may': return 'may';
      case 'jun': return 'jun';
      case 'jul': return 'jul';
      case 'ago': return 'ago';
      case 'sep': return 'sep';
      case 'oct': return 'oct';
      case 'nov': return 'nov';
      case 'dic': return 'dic';
    }
  }
  public mesTabla(mes){
    switch(mes){
      case 'ene': return 'ENERO';
      case 'feb': return 'FEBRERO';
      case 'mar': return 'MARZO';
      case 'abr': return 'ABRIL';
      case 'may': return 'MAYO';
      case 'jun': return 'JUNIO';
      case 'jul': return 'JULIO';
      case 'ago': return 'AGOSTO';
      case 'sep': return 'SEPTIEMBRE';
      case 'oct': return 'OCTUBRE';
      case 'nov': return 'NOVIEMBRE';
      case 'dic': return 'DICIEMBRE';
    }
  }
  public formatoAnio(anio){
    return anio[anio.length-2]+''+anio[anio.length-1];
  }
  //modal
  public Ampliar(tipo){
    this.showModal = true;
    //$("#ampliarGraficas").show();
    document.getElementById('ampliarGraficas').style.display='block';
    this.hideGraficasModal();
    this.titulomodal = "Clasificación";
    this.subtitulomodal = this.subtituloGrafica1;
    if(tipo == 1)
      this.graficasuperior = true;
    if(tipo == 2)
      this.graficainferior = true;
    
    if(this.graficatabla)
      this.graficatabla_modal = true;
    if(this.graficabarrasverC1 && this.graficasuperior)
      this.graficabarrasverC1_modal = true;
    if(this.graficabarrashorC1 && this.graficasuperior)
      this.graficabarrashorC1_modal = true;
    if(this.graficalinealC1 && this.graficasuperior)
      this.graficalinealC1_modal = true;
    if(this.graficaareaC1 && this.graficasuperior)
      this.graficaareaC1_modal = true;

    if(this.graficabarrasverC2 && this.graficainferior)
      this.graficabarrasverC2_modal = true;
    if(this.graficabarrashorC2 && this.graficainferior)
      this.graficabarrashorC2_modal = true;
    if(this.graficalinealC2 && this.graficainferior)
      this.graficalinealC2_modal = true;
    if(this.graficaareaC2 && this.graficainferior)
      this.graficaareaC2_modal = true;
  }
  public hideGraficasModal(){
    //C1
    this.graficatabla_modal = false;
    this.graficabarrasverC1_modal = false;
    this.graficabarrashorC1_modal = false;
    this.graficalinealC1_modal = false;
    this.graficaareaC1_modal = false;

    this.graficabarrasverC2_modal = false;
    this.graficabarrashorC2_modal = false;
    this.graficalinealC2_modal = false;
    this.graficaareaC2_modal = false;

    this.graficainferior = false;
    this.graficasuperior = false;
  }
  public hideModal(){
    this.showModal = false;
    document.getElementById('ampliarGraficas').style.display='none';
    this.hideGraficasModal();
  }
  //exportar
  public export_excel(){
    var aux = [];
    var data_excel: any = [];
    var name = '';
    name = 'Metas vs real adultos y menores';
    var perspectiva = '';
    var encabezado_excel = ['SUCURSAL','PROYECTADO','REAL','LOGRADO','PROYECTADO','REAL','LOGRADO','ADULTO','MENOR'];
    var cadena_evaluacion = encabezado_excel;
    var mes = this.mesTabla(this.ult_mes[0]);
    for(const dato of this.dataTabla){
      aux.push(dato['sucursal']);
      aux.push(dato['proyectado1']);
      aux.push(dato['real1']);
      aux.push(dato['logrado1']);
      aux.push(dato['proyectado2']);
      aux.push(dato['real2']);
      aux.push(dato['logrado2']);
      aux.push(dato['adulto']);
      aux.push(dato['menor']);
      data_excel.push(aux);
      aux = [];
    }
    this.excelservices.generateExcelMetavsReal(encabezado_excel,data_excel,cadena_evaluacion.length,name,perspectiva,mes);
  }
  public export_imagen(zon: any){
    var grafica: string = "";
    var name: string = "";
    switch(zon){
      case 1:
        grafica = 'superior';
        name = 'Cumplimiento metas adultos';
      break
      case 2:
        grafica = 'inferior';
        name = 'Cumplimiento metas menores';
      break
    }
    PDF.exportimagenes(grafica,name);
  }
  public sortData(sort: Sort) {
    const total = this.dataTabla.pop();
    const datas = this.dataTabla;
    this.dataTabla = [];
    if (!sort.active || sort.direction === '') {
      datas.push(total);
      this.dataTabla = datas;
      return;
    }
    var aux = [];
    
    aux = datas.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sucursal': return compare(a.sucursal, b.sucursal, isAsc);
        case 'proyectado': return compare(a.proyectado1, b.proyectado1, isAsc);
        case 'real': return compare(a.real1, b.real1, isAsc);
        case 'logrado': return compare(a.logrado1, b.logrado1, isAsc);
        case 'proyectado1': return compare(a.proyectado2, b.proyectado2, isAsc);
        case 'real1': return compare(a.real2, b.real2, isAsc);
        case 'logrado1': return compare(a.logrado2, b.logrado2, isAsc);
        case 'adulto': return compare(a.adulto, b.adulto, isAsc);
        case 'menor': return compare(a.menor, b.menor, isAsc);
        default: return 0;
      }
    });

    aux.push(total);
    setTimeout(() =>  this.dataTabla = aux, 1);
    
  }
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sucursalesDisponibles2.filter = filterValue.trim().toLowerCase();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
function quitarAcentos(cadena){
	const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
	return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
}